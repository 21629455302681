import React from "react";
import MetaList from "../../components/MetaData/MetaList";

export default function MetaData() {
  return (
    <>
      <MetaList />
    </>
  );
}
