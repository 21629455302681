import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  // Checkbox,
  Switch,
  Fade,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../common/modal/Popup";
// styles
import useStyles from "../styles";

import moment from "moment";
import {
  currentUser,
  handleAddressKeyPress,
  handleAddressKeyPressPaste,
  loadState,
} from "../../../hooks/helper";
import { postPatientDisclosure } from "../../../services/ServiceLayer";
import { toast } from "react-toastify";
import ExamValidation from "../../../hooks/ExamValidation";

export default function ManageDisclosure(props) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  //
  const formElements = [
    {
      key: "dis_date",
      label: "Date",
      placeHolder: "select",
      type: "date",
      mdSize: "3",
      required:"true"
    },
    {
      key: "dis_type",
      label: "Discloure Type",
      placeHolder: "select",
      type: "select",
      options: [""],
      mdSize: "3",
    },
    {
      key: "dis_recipient",
      label: "Recipient",
      placeHolder: "Enter recipient",
      type: "text",
      mdSize: "3",
      maxLength:'20'
    },
    {
      key: "dis_providedby",
      label: "Provided By",
      placeHolder: "select",
      type: "select",
      options: [""],
      mdSize: "3",
    },
    {
      key: "dis_mode",
      label: "Mode",
      placeHolder: "select",
      type: "select",
      options: [],
      mdSize: "3",
    },
    {
      key: "dis_description",
      label: "Discloure Description",
      placeHolder: "Tye here",
      type: "text",
      mdSize: "9",
      maxLength:'50',
      onKeyPress: handleAddressKeyPress,
      onpaste: handleAddressKeyPressPaste,
    },
  ];
  console.log("props-24", props);
  const { selectclient, patientId, disclosureType, doctorList,examMode } = props;
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [actionType, setActionType] = useState("ADDDIS");
  console.log("selectclient", selectclient);
  const [isShow, setIsShow] = useState(false);
  //
  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [open, setOpen] = React.useState(false);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [disclosureDetails, setDisclosureDetails] = useState({
    pat_dis_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    dis_type: "",
    dis_mode:'',
    dis_recipient: "",
    dis_date: "",
    dis_providedby: "",
    dis_description: "",
    updated_by: currentUser(),
    access_location: "",
    opt_type: actionType,
  });
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    ExamValidation({
      dis_date: disclosureDetails?.dis_date,
    });
  const clearForm = () => {
    setDisclosureDetails({
      pat_dis_id: 0,
      patient_id: patientId,
      location_id: userData?.location_id,
      dis_type: "",
      dis_mode:'',
      dis_recipient: "",
      dis_date: "",
      dis_providedby: "",
      dis_description: "",
      updated_by: currentUser(),
      access_location: "",
      opt_type: actionType,
    });
  };
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setDisclosureDetails({
        pat_dis_id: 0,
        patient_id: patientId,
        location_id: userData?.location_id,
        dis_type: "",
        dis_recipient: "",
        dis_date: "",
        dis_mode:'',
        dis_providedby: "",
        dis_description: "",
        updated_by: currentUser(),
        access_location: "",
        opt_type: actionType,
      });
    } else {
      setDisclosureDetails({
        pat_dis_id: selectclient?.pat_dis_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        dis_type: selectclient?.dis_type,
        dis_mode:selectclient?.dis_mode,
        dis_recipient: selectclient?.dis_recipient,
        dis_date: moment(selectclient?.dis_date).format("YYYY-MM-DD"),
        dis_providedby: selectclient?.dis_providedby,
        dis_description: selectclient?.dis_description,
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: "UPDDIS",
      });
    }
  }, [selectclient]);
  const handleSubmit = () => {
    checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    if (!isError() && error !== null && isActionCalled) {

      postPatientDisclosure(disclosureDetails).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success(`Patient disclosure ${selectclient ? 'update' : 'added'}  successfully.`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          clearForm();
          props.onHide();
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    // } else {
    //   setIsActionCalled(false);
    // }

    setIsActionCalled(false);
  }, [isActionCalled]);
  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
    clearForm();
  };

 
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        // fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleSubmit}
            >
              {selectclient ? 'Update':'Add'}
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={2}
              className={classes.formBox}
              style={{ marginBottom: "4px" }}
            >
              {formElements.map((element, i) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={classes.textGroup}
                    >
                      <Typography
                        style={{ fontSize: "13px" }}
                        className={classes.formHeading}
                      >
                        {element.label}{" "}
                        {element.required === "true" ? (
                          <span className={classes.required}>*</span>
                        ) : null}
                      </Typography>

                      {element.type === "select" ? (
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            name={element.key}
                            className={classes.selectDropdown}
                            placeholder={element.placeHolder}
                            value={
                              disclosureDetails &&
                              disclosureDetails[element.key]
                                ? disclosureDetails &&
                                  disclosureDetails[element.key]
                                : ""
                            }
                            onChange={(e) => {
                              console.log("selected", e.target.value);

                              setDisclosureDetails({
                                ...disclosureDetails,
                                [element.key]: e.target.value,
                              });

                              // resetValidator(e.target.name);
                            }}
                          >
                            <MenuItem disabled value="">
                              Select
                            </MenuItem>
                            {element.key === "dis_type" && disclosureType
                              ? disclosureType.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "dis_providedby" && doctorList
                              ? doctorList.map((item) => (
                                  <MenuItem
                                    key={item.staff_id}
                                    value={item.staff_id}
                                  >
                                    {item.first_name} {item.middle_name}{" "}
                                    {item.last_name}
                                  </MenuItem>
                                ))
                              :
                              element.key === "dis_mode" && examMode
                              ? examMode.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              :
                              element.options.map((item) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      ) : element.type === "text" ? (
                        <TextField
                          required
                          name={element.key}
                          placeholder={element.placeHolder}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                            maxLength: element.maxLength,
                            onKeyPress: element.onKeyPress,
                            onPaste: element.onpaste,
                          }}
                          value={
                            disclosureDetails && disclosureDetails[element.key]
                              ? disclosureDetails &&
                                disclosureDetails[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected");
                            setDisclosureDetails({
                              ...disclosureDetails,
                              [element.key]: e.target.value,
                            });
                            // resetValidator(e.target.name);
                          }}
                        />
                      ) : element.type === "date" ||
                        element.type === "datetime-local" ? (
                        <>
                        <TextField
                          id={element.key}
                          name={element.key}
                          placeholder={element.placeHolder}
                          type={element.type}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            min: `${currentDateTime}`,
                            max: `${currentDate}`,
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onKeyDown={(e) => {
                            e.preventDefault(); // Prevent manual typing
                          }}
                          value={
                            disclosureDetails && disclosureDetails[element.key]
                              ? disclosureDetails &&
                                disclosureDetails[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected");
                            setDisclosureDetails({
                              ...disclosureDetails,
                              [element.key]: e.target.value,
                            });
                            resetValidator(e.target.name);
                          }}
                          isInvalid={error && error[element.key]}
                        />
                        <Fade in={error}>
                          <Typography
                            color="secondary"
                            className={classes.errorMessage}
                          >
                            {error && error[element.key]}
                          </Typography>
                        </Fade>
                        </>
                      ) : element.type === "textarea" ? (
                        <TextField
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          placeholder="Type here..."
                          variant="outlined"
                          multiline
                          rows={3}
                          inputProps={{
                            style: {
                              fontSize: "12px",
                            },
                            maxLength: element.maxLength,
                            onKeyPress: element.onKeyPress,
                            onPaste: element.onpaste,
                          }}
                          value={
                            disclosureDetails && disclosureDetails[element.key]
                              ? disclosureDetails &&
                                disclosureDetails[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected");
                            setDisclosureDetails({
                              ...disclosureDetails,
                              [element.key]: e.target.value,
                            });
                            // resetValidator(e.target.name);
                          }}
                        />
                      ) : element.type === "button" ? (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                          style={{
                            width: "100%",
                            fontSize: "10px",
                            marginTop: "26px",
                          }}
                        >
                          {element.btnName}
                        </Button>
                      ) : (
                        <Switch />
                      )}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
