import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Fade,
} from "@material-ui/core";

import moment from "moment";
import useStyles from "../styles";
import { formBillingElements } from "./Patient_Constant";
import { Box } from "@mui/material";

export default function AddressBilling({
  patientProfileData,
  setPatientProfileData,
  error,

  resetValidator,
  setIsProfileStateChanged,
  addressStatus,
}) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");

  var classes = useStyles();
  const handleInputChange = (event, key) => {
    const value = event.target.value;
    setPatientProfileData(prevState => ({
      ...prevState,
      [key]: value
    }));
    setIsProfileStateChanged(true);
  };
  return (
    <Grid container spacing={4} className={classes.formBox}>
      {formBillingElements.map((element, i) => {
        return (
          <>
            <Grid
              item
              xs={12}
              md={2}
              lg={2}
              sm={12}
              className={classes.textGroup}
            >
              <Typography className={classes.formHeading}>
                {element.label}{" "}
                {element.required === "true" ? (
                  <span className={classes.required}>*</span>
                ) : null}
              </Typography>

              {element.type === "select" ? (
                <>
                  <FormControl
                    fullWidth
                    // sx={{
                    //   '&.MuiFormControl-fullWidth': {
                    //     width: 'auto',
                    //   }
                    // }}
                    className={classes.dropdownFormControl}
                  >
                    <Select
                      name={element.key}
                      className={classes.selectDropdown}
                      placeholder={element.placeHolder}
                      value={
                        patientProfileData &&
                          patientProfileData[element.key] === null
                          ? ""
                          : patientProfileData &&
                          patientProfileData[element.key]
                      }
                      onChange={async (e) => {
                        handleInputChange(e, element.key)
                        resetValidator(e.target.name);
                      }}
                      isInvalid={error && error[element.key]}
                    >
                      <MenuItem disabled value="">
                        Select
                      </MenuItem>
                      {(element.key === "billing_address_verified" &&
                        addressStatus) ||
                        (element.key === "mailing_address_verified" &&
                          addressStatus) ||
                        (element.key === "shipping_address_verified" && addressStatus)
                        ? addressStatus.map((item) => (
                          <MenuItem
                            key={item.attribute_id}
                            value={item.attribute_id}
                          >
                            {item.attribute_val}
                          </MenuItem>
                        ))
                        : (element.key === "billing_state" &&
                          element.options) || (element.key === "mailing_state" &&
                            element.options) ||
                          (element.key === "shipping_state" && element.options)
                          ? element.options.map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))
                          : null}
                    </Select>
                  </FormControl>
                  <Fade in={error}>
                    <Typography
                      color="secondary"
                      className={classes.errorMessage}
                    >
                      {error && error[element.key]}
                    </Typography>
                  </Fade>
                </>
              ) : element.type === "textarea" ? (
                <>
                  <TextField
                    name={element.key}
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    placeholder="Type here..."
                    variant="outlined"
                    multiline

                    inputProps={{
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    value={
                      patientProfileData &&
                        patientProfileData[element.key] === null
                        ? ""
                        : patientProfileData && patientProfileData[element.key]
                    }
                    onChange={async (e) => {
                      handleInputChange(e, element.key)
                      resetValidator(e.target.name);
                    }}
                    isInvalid={error && error[element.key]}
                  />
                  <Fade in={error}>
                    <Typography
                      color="secondary"
                      className={classes.errorMessage}
                    >
                      {error && error[element.key]}
                    </Typography>
                  </Fade>
                </>
              ) : element.type === "date" ||
                element.type === "datetime-local" ? (
                <>
                  <TextField
                    id={element.key}
                    name={element.key}
                    placeholder={element.placeHolder}
                    type={element.type}
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      min: `${currentDateTime}`,
                      max: `${currentDate}`,
                      style: {
                        height: "12px",
                        fontSize: "12px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      patientProfileData &&
                        patientProfileData[element.key] === null
                        ? ""
                        : patientProfileData && patientProfileData[element.key]
                    }
                    onChange={async (e) => {
                      handleInputChange(e, element.key)
                      resetValidator(e.target.name);
                    }}
                    onKeyDown={(e) => {
                      e.preventDefault(); // Prevent manual typing
                    }}
                    isInvalid={error && error[element.key]}
                  />
                  <Fade in={error}>
                    <Typography
                      color="secondary"
                      className={classes.errorMessage}
                    >
                      {error && error[element.key]}
                    </Typography>
                  </Fade>
                </>
              ) : (
                <>
                  <TextField
                    type={element.type}
                    name={element.key}
                    placeholder={element.placeHolder}
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "12px",
                        fontSize: "12px",
                      },
                      maxLength: element.maxLength,

                      onKeyPress: element.onKeyPress, // Add the event handler

                      onPaste: element.onPaste,
                    }}
                    value={
                      patientProfileData &&
                        patientProfileData[element.key] === null
                        ? ""
                        : patientProfileData && patientProfileData[element.key]
                    }
                    onChange={async (e) => {
                      handleInputChange(e, element.key)
                      resetValidator(e.target.name);
                    }}
                    isInvalid={error && error[element.key]}
                  />
                  <Fade in={error}>
                    <Typography
                      color="secondary"
                      className={classes.errorMessage}
                    >
                      {error && error[element.key]}
                    </Typography>
                  </Fade>
                </>
              )}
            </Grid>
          </>
        );
      })}
    </Grid>
  );
}
