import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import {
 
  Person as AccountIcon,
 
} from "@material-ui/icons";
import classNames from "classnames"; 
import profileImg from "../../images/photo.png";
// styles
import useStyles from "./styles";
import moment from "moment";
// components
import { Typography } from "../Wrappers/Wrappers";
import Notification from "../Notification/Notification";
import UserAvatar from "../UserAvatar/UserAvatar";
import logo from "../../images/logo.jpeg";

import { loadState } from "../../hooks/helper";
import { postEmployeeAttendance ,getEmployeeAttendance} from "../../services/ServiceLayer";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { RiLogoutCircleLine } from "react-icons/ri";
import { MdLunchDining } from "react-icons/md";
import { RiLogoutCircleRLine } from "react-icons/ri";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import PatientName from "../Dashboard/PatientName";

// import { useUserDispatch, signOut } from "../../context/UserContext";
// import { getPatient } from "../../services/ServiceLayer";

const messages = [
  {
    id: 0,
    variant: "warning",
    name: "Jane Hew",
    message: "Hey! How is it going?",
    time: "9:32",
  },
  {
    id: 1,
    variant: "success",
    name: "Lloyd Brown",
    message: "Check out my new Dashboard",
    time: "9:18",
  },
  {
    id: 2,
    variant: "primary",
    name: "Mark Winstein",
    message: "I want rearrange the appointment",
    time: "9:15",
  },
  {
    id: 3,
    variant: "secondary",
    name: "Liana Dutti",
    message: "Good news from sale department",
    time: "9:09",
  },
];

const notifications = [
  { id: 0, color: "warning", message: "Check out this awesome ticket" },
  {
    id: 1,
    color: "success",
    type: "info",
    message: "What is the best way to get ...",
  },
  {
    id: 2,
    color: "secondary",
    type: "notification",
    message: "This is just a simple notification",
  },
  {
    id: 3,
    color: "primary",
    type: "e-commerce",
    message: "12 new orders has arrived today",
  },
];

export default function Header() {
  var classes = useStyles();
  const history = useHistory();
  const navigateProfile = () => {
    history.push("/app/profile");
    setProfileMenu(null);
  };

  const profileData = loadState().user;

  const eventHandler = (action) => {
    const currentDate = moment(new Date()).format("YYYY-MM-DD hh:mm A");
    if (action === "STAFCHKOT") {
      const param = {
        staff_atten_id: 0,
        staff_id: profileData.user_id,
        location_id: profileData.location_id,
        check_in_time: "",
        check_out_time: currentDate,
        lunch_st_time: "",
        lunch_et_time: "",
        updated_by: profileData.user_name,
        access_location: profileData.user_name,
        opt_type: action,
      };
      attendanceAction(param);
    } else if (action === "STAFCHKLS") {
      const param = {
        staff_atten_id: 0,
        staff_id: profileData.user_id,
        location_id: profileData.location_id,
        check_in_time: "",
        lunch_st_time: currentDate,
        check_out_time: "",
        lunch_et_time: "",
        updated_by: profileData.user_name,
        access_location: profileData.user_name,
        opt_type: action,
      };
      attendanceAction(param);
    } else if (action === "STAFCHKLE") {
      const param = {
        staff_atten_id: 0,
        staff_id: profileData.user_id,
        location_id: profileData.location_id,
        check_in_time: "",
        check_out_time: "",
        lunch_st_time: "",
        lunch_et_time: currentDate,
        updated_by: profileData.user_name,
        access_location: profileData.user_name,
        opt_type: action,
      };
      attendanceActionReturn(param);
    }
  };

  const attendanceAction = (param) => {
    postEmployeeAttendance(param).then((response) => {
      console.log(response);
      if (response.status.status) {
        // dispatch({ type: "SIGN_OUT_SUCCESS" });
        if (param.opt_type !== "STAFCHKLE") {
          sessionStorage.removeItem("id_token");
          window.location = "/login";
        } else {
          window.location.reload();
        }
      } else {
        // toast.error(response?.status?.description, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      }
    });
  };
  const attendanceActionReturn = (param) => {
    getEmployeeAttendance(param).then((response) => {
      console.log(response);
      // if (response.status.status) {
      //   // dispatch({ type: "SIGN_OUT_SUCCESS" });
      //   if (param.opt_type !== "STAFCHKLE") {
      //     sessionStorage.removeItem("id_token");
      //     window.location = "/login";
      //   } else {
      //     window.location.reload();
      //   }
      // } else {
      //   // toast.error(response?.status?.description, {
      //   //   position: toast.POSITION.TOP_RIGHT,
      //   // });
      // }
    });
  };

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  // var userDispatch = useUserDispatch();

  // local
  var [mailMenu, setMailMenu] = useState(null);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [profileMenu, setProfileMenu] = useState(null);
  var name = localStorage.getItem("savedLoginValue")
    ? localStorage.getItem("savedLoginValue")
    : "";

  return (
    <AppBar
      elevation={1}
      position="fixed"
      className={classes.appBar}
      style={{ background: "#fff" }}
    >
      <Toolbar className={classes.toolbar}>
      <Typography
          weight="medium"
          className={classes.logotype}
          onClick={() => history.push(`/app/dashboard`)}
        >
          <img className={classes.logoImg} src={logo} alt="DocMadeUSA" />
        </Typography>
        <IconButton
          onClick={() => toggleSidebar(layoutDispatch)}
        // className={classNames(
        //   classes.headerMenuButton,
        //   classes.headerMenuButtonCollapse
        // )}
        >
          {layoutState.isSidebarOpened ? (
            <>
              <AiOutlineMenuFold
                classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse
                  ),
                }}
              />
              {/* <img className={classes.logoImg} src={logo} alt="DocMadeUSA" /> */}
            </>
          ) : (
            <AiOutlineMenuUnfold
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          )}
        </IconButton>
       

        <div className={classes.grow} style={{ marginLeft: "56px" }}>
          <PatientName />
        </div>
        <IconButton
          disableFocusRipple='false'
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}

        >

          <img src={profileImg} classes={{ root: classes.headerIcon }} alt="" />
          <div className={classes.profileMenuUser}  >
            <Typography>
              {profileData.user_name}
            </Typography>
            {/* <Typography
              className={classes.profileMenuLink}
              component="a"
              color="primary"
              href="#"
            >
              {profileData.email}
            </Typography> */}
          </div>
        </IconButton>
        <Menu
          id="mail-menu"
          open={Boolean(mailMenu)}
          anchorEl={mailMenu}
          onClose={() => setMailMenu(null)}
          MenuListProps={{ className: classes.headerMenuList }}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              New Messages
            </Typography>
            <Typography
              className={classes.profileMenuLink}
              component="a"
              color="secondary"
            >
              {messages.length} New Messages
            </Typography>
          </div>
          {messages.map((message) => (
            <MenuItem key={message.id} className={classes.messageNotification}>
              <div className={classes.messageNotificationSide}>
                <UserAvatar color={message.variant} name={message.name} />
                <Typography size="sm" color="text" colorBrightness="secondary">
                  {message.time}
                </Typography>
              </div>
              <div
                className={classNames(
                  classes.messageNotificationSide,
                  classes.messageNotificationBodySide
                )}
              >
                <Typography weight="medium" gutterBottom>
                  {message.name}
                </Typography>
                <Typography color="text" colorBrightness="secondary">
                  {message.message}
                </Typography>
              </div>
            </MenuItem>
          ))}
        </Menu>
        <Menu
          id="notifications-menu"
          open={Boolean(notificationsMenu)}
          anchorEl={notificationsMenu}
          onClose={() => setNotificationsMenu(null)}
          className={classes.headerMenu}
          disableAutoFocusItem
        >
          {notifications.map((notification) => (
            <MenuItem
              key={notification.id}
              onClick={() => setNotificationsMenu(null)}
              className={classes.headerMenuItem}
            >
              <Notification {...notification} typographyVariant="inherit" />
            </MenuItem>
          ))}
        </Menu>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <MenuItem
            onClick={navigateProfile}
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Profile
          </MenuItem>
          <MenuItem
            onClick={() => eventHandler("STAFCHKLS")}
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
          >
            <MdLunchDining className={classes.profileMenuIcon} /> On Lunch
          </MenuItem>

          <MenuItem
            onClick={() => eventHandler("STAFCHKLE")}
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
          >
            <RiLogoutCircleRLine className={classes.profileMenuIcon} /> Back
            From Lunch
          </MenuItem>

          <MenuItem
            onClick={() => eventHandler("STAFCHKOT")}
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem
            )}
          >
            <RiLogoutCircleLine className={classes.profileMenuIcon} /> Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
