import React, { useState, useEffect } from "react";
import {  Grid } from "@material-ui/core";

import {  loadState } from "../../../hooks/helper";
import MedicationChart from './MedicationChart'

import TableWidget from "../../Widget/TableWidget";

import TableDesign from "../../common/table/TableDesign";
import { TableRow, TableCell,  } from "@material-ui/core";
// styles
import useStyles from "../styles";

import { getAllergyHistoryData, getMedicationList } from "../../../services/ServiceLayer";

import moment from "moment";


export default function MedicationHistory(props) {
  const tableHeadings = [
  
    {
      label: "Medicine",
    },
    {
      label: "Type",
    },
    {
      label: "Status",
    },
    {
      label: "Strength",
    },
    {
      label: "Supply",
    },
    {
      label: "Route",
    },
    {
      label: "Frequency",
    },
    {
      label: "Quantity",
    },
    {
      label: "Start Date",
    },
    {
      label: "Stop Date",
    },

  
  ];
  const { patientId} = props;
  const [medicineData, setMedicineData] = useState([]);
 
  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  useEffect(()=>{
    getMedicationList(
      `location_id=${userData?.location_id}&patient_id=${patientId}&user_role=${userRole}&opt_type=SELMED`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
       
        
        setMedicineData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  },[])



  return (
    <>
    {/* <div className={classes.pageTitleContainer} style={{marginBottom: "6px",marginTop: "10px", borderTop:'1px solid #6666' }}>
        <Typography className={classes.typo} style={{fontSize:'14px'}} weight="medium">
          Medication
        </Typography>
      </div> */}
      
      <Grid container spacing={4} style={{ width: "100%" }}>
        
        <Grid item xs={12} >
        <div style={{background:'#5DB3D9',padding:'10px',color:'#fff',textAlign:'center',borderRadius:'5px',marginBottom:'2px'}}>Medication</div>
          {/* <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {  {medicineData && medicineData?.length ? (
                medicineData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                      
                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {row?.med_sort_name}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.med_type}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.is_active}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.med_strength}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.supply}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.med_route}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.med_frequency_val}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.med_quantity}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {moment(row?.st_date).utc().format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {moment(row?.end_date).utc().format('DD-MM-YYYY')}
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}  }
            </TableDesign>
          </TableWidget> */}
          <MedicationChart />
        </Grid>
      </Grid>
    </>
  );
}
