import React, { useEffect, useState } from "react";

import {
  Grid,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,

  FormControl,
  FormGroup,
  Checkbox,
  Button,
  IconButton,
  Menu,
} from "@material-ui/core";
// data

// Model
import { internalExamSection } from "./External_Constant";
import useStyles from "../styles";
// components
import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";

import moment from "moment";
import { currentUser, loadState } from "../../../hooks/helper";
import { toast } from "react-toastify";
import {
  getInternalExam,
  postInternalExam,
} from "../../../services/ServiceLayer";
import { Autocomplete } from "@material-ui/lab";
export default function InternalExamSection({
  patientId,
  examInternalCDRatio,
  examInternalVitreous,
  examInternalLens,
  examInternalAVRatio,
  examInternalMacula,
  examInternalOpticDisc,
  examInternalAVRetina,
  examInternalVessels,setInternalBtnUnable,internalBtnUnable
}) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [actionType, setActionType] = useState("ADDINT");
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [internnalData, setInternnalData] = useState([]);
  const [internalDetails, setInternalDetails] = useState({
    pat_int_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    lens_od: "",
    vitreous_od: "",
    opticdisc_od: "",
    cdratio_od: "",
    vessels_od: "",
    avratio_od: "",
    macula_od: "",
    retina_od: "",
    lens_os: "",
    vitreous_os: "",
    opticdisc_os: "",
    cdratio_os: "",
    vessels_os: "",
    avratio_os: "",
    macula_os: "",
    retina_os: "",
    retina_notes_os: "",
    retina_notes_od: "",
    updated_by: currentUser(),
    access_location: "Y",
    opt_type: actionType,
  });
  useEffect(() => {
    getInternalExam(
      `location_id=${userData?.location_id}&patient_id=${patientId}&user_role=${userRole}&opt_type=SELINT`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setInternnalData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);
  const [discardUpdate, setDiscardUpdate] = useState(false);
  useEffect(() => {
    if (internnalData && internnalData?.length > 0) {
      setInternalDetails({
        pat_int_id: internnalData[0]?.pat_int_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        lens_od: internnalData[0]?.lens_od,
        vitreous_od: internnalData[0]?.vitreous_od,
        opticdisc_od: internnalData[0]?.opticdisc_od,
        cdratio_od: internnalData[0]?.cdratio_od,
        vessels_od: internnalData[0]?.vessels_od,
        avratio_od: internnalData[0]?.avratio_od,
        macula_od: internnalData[0]?.macula_od,
        retina_od: internnalData[0]?.retina_od,
        lens_os: internnalData[0]?.lens_os,
        vitreous_os: internnalData[0]?.vitreous_os,
        opticdisc_os: internnalData[0]?.opticdisc_os,
        cdratio_os: internnalData[0]?.cdratio_os,
        vessels_os: internnalData[0]?.vessels_os,
        avratio_os: internnalData[0]?.avratio_os,
        macula_os: internnalData[0]?.macula_os,
        retina_os:internnalData[0]?.retina_os,
        retina_notes_os: internnalData[0]?.retina_notes_os,
        retina_notes_od: internnalData[0]?.retina_notes_od,
        updated_by: currentUser(),
        access_location: "Y",
        opt_type: "UPDINT",
      });
      setDiscardUpdate(false);
    }
  }, [internnalData,discardUpdate]);
  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    // if (!isError() && error !== null && isActionCalled) {
    if (isActionCalled) {
      postInternalExam(internalDetails).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Internal exam added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setInternalBtnUnable(false);
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    // } else {
    //   setIsActionCalled(false);
    // }

    setIsActionCalled(false);
  }, [isActionCalled]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (copy, paste, e) => {
    console.log("copy", copy);
    console.log("copy to", paste);
    setAnchorEl(null);
    setInternalBtnUnable(true);
    setInternalDetails({
      ...internalDetails,
      [`lens_${paste}`]: internalDetails?.[`lens_${copy}`],
      [`vitreous_${paste}`]: internalDetails?.[`vitreous_${copy}`],
      [`opticdisc_${paste}`]: internalDetails?.[`opticdisc_${copy}`],
      [`cdratio_${paste}`]: internalDetails?.[`cdratio_${copy}`],
      [`vessels_${paste}`]: internalDetails?.[`vessels_${copy}`],
      [`avratio_${paste}`]: internalDetails?.[`avratio_${copy}`],
      [`macula_${paste}`]: internalDetails?.[`macula_${copy}`],
      [`retina_${paste}`]: internalDetails?.[`retina_${copy}`],
      [`retina_notes_${paste}`]: internalDetails?.[`retina_notes_${copy}`],
    });
  };
  const clearForm=()=>{
    setInternalDetails({
      pat_int_id: 0,
      patient_id: patientId,
      location_id: userData?.location_id,
      lens_od: "",
      vitreous_od: "",
      opticdisc_od: "",
      cdratio_od: "",
      vessels_od: "",
      avratio_od: "",
      macula_od: "",
      retina_od: "",
      lens_os: "",
      vitreous_os: "",
      opticdisc_os: "",
      cdratio_os: "",
      vessels_os: "",
      avratio_os: "",
      macula_os: "",
      retina_os: "",
      retina_notes_os: "",
      retina_notes_od: "",
      updated_by: currentUser(),
      access_location: "Y",
      opt_type: actionType,
    })
    
  }
  const[showDiscard, setShowDiscard] = useState(false);

  const discardHandler = ()=>{
    if(internnalData && internnalData.length>0){
      setInternalBtnUnable(false);
      setDiscardUpdate(true);
    }else{
      clearForm();
      setInternalBtnUnable(false);
    }
   
  }
  const optionsMap = {
    lens_od: examInternalLens,
    lens_os: examInternalLens,
    vitreous_od: examInternalVitreous,
    vitreous_os: examInternalVitreous,
    opticdisc_od:examInternalOpticDisc,
opticdisc_os:examInternalOpticDisc,
cdratio_od:examInternalCDRatio,
cdratio_os:examInternalCDRatio,
vessels_od:examInternalVessels,
vessels_os:examInternalVessels,
retina_od:examInternalAVRetina,
retina_os:examInternalAVRetina,
avratio_od:examInternalAVRatio,
avratio_os:examInternalAVRatio,
macula_od:examInternalMacula,
macula_os:examInternalMacula
  };
  const handleAutocompleteChange = (key, newValue) => {
    setInternalDetails((prev) => ({
      ...prev,
      [key]: newValue ? newValue.attribute_id : null,
    }));
    setInternalBtnUnable(true);
    setShowDiscard(true);
  };
  return (
    <>
      {/* <TableWidget noBodyPadding> */}
      <Grid
        container
        spacing={2}
        className={classes.formBox}
        // style={{ border: "1px solid #DED9D9" }}
      >
        <Grid
          item
          xs={12}
          md={12}
          className={classes.textGroup}
          style={{ padding: "0px", display: "flex", justifyContent: "end" }}
        >
          <IconButton
            id="basic-button"
            aria-controls={openDown ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openDown ? "true" : undefined}
            onClick={(e) => {
              handleClick(e);
            }}
          >
            <MoreIcon />
          </IconButton>

          <Menu
            style={{ fontSize: "12px" }}
            className={classes.menuBox}
            id="basic-menu"
            anchorEl={anchorEl}
            open={openDown}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              style={{ fontSize: "12px" }}
              onClick={() => handleCheckboxChange("od", "os")}
            >
              Copy OD to OS
            </MenuItem>

            <MenuItem
              style={{ fontSize: "12px" }}
              onClick={() => handleCheckboxChange("os", "od")}
            >
              Copy OS to OD
            </MenuItem>
          </Menu>
        </Grid>
        {internalExamSection.map((element, i) => {
          return (
            <>
              <Grid
                item
                xs={12}
                md={element.mdSize}
                className={classes.textGroup}
                style={{ border: "1px solid #DED9D9" }}
              >
                {element.label && (
                  <Typography
                    className={classes.formHeading}
                    style={{ fontWeight: "500", color: "#4E97FD" }}
                  >
                    {element.label}{" "}
                    {element.required === "true" ? (
                      <span className={classes.required}>*</span>
                    ) : null}
                    {element.copyBtn && (
                      <Button
                        style={{
                          float: "right",
                          position: "relative",
                          fontSize: "10px",
                        }}
                        classes={{ root: classes.button }}
                        variant="contained"
                        color="secondary"
                        // onClick={duplicateValue}
                      >
                        {element?.copyBtn}
                      </Button>
                    )}
                  </Typography>
                )}

                {element.type ? (
                  <>
                    {element.type === "select" ? (
                      <Autocomplete style={{marginBottom:'10px'}}
                      options={optionsMap[element.key] || []}
                      getOptionLabel={(option) => option.attribute_val || ""}
                      value={
                        (optionsMap[element.key] || []).find(
                          (option) =>
                            option.attribute_id === internalDetails[element.key]
                        ) || null
                      }
                      onChange={(e, newValue) =>
                        handleAutocompleteChange(element.key, newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={element.label}
                          variant="outlined"
                          fullWidth
                          // InputProps={{
                          //   ...params.InputProps,
                          //   style: {
                          //     height: '36px',
                          //     padding: '10 14px',
                          //   },
                          // }}
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              height: 'auto', // Adjust if you need to change the height of the inner input element
                              padding: '0 14px', 
                              
                            },
                          }}
                        />
                      )}
                    />
                    ) : element.type === "text" ? (
                      <TextField
                        required
                        name={element.key}
                        placeholder={element.placeHolder}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                        }}
                        value={
                          internalDetails && internalDetails[element.key]
                            ? internalDetails && internalDetails[element.key]
                            : ""
                        }
                        onChange={(e) => {
                          console.log("selected", e.target.value);

                          setInternalDetails({
                            ...internalDetails,
                            [element.key]: e.target.value,
                          });
                          setInternalBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                    ) : element.type === "date" ||
                      element.type === "datetime-local" ? (
                      <TextField
                        id={element.key}
                        name={element.key}
                        placeholder={element.placeHolder}
                        type={element.type}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          min: `${currentDateTime}`,
                          max: `${currentDate}`,
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    ) : element.type === "radio" ? (
                      <RadioGroup row defaultValue="">
                        {element.radioValue.map((item) => (
                          <FormControlLabel
                            size="small"
                            style={{ padding: "0px 6px" }}
                            value={item}
                            control={<Radio />}
                            label={item}
                            className={classes.smallRadioButton}
                          />
                        ))}
                      </RadioGroup>
                    ) : element.type === "checkbox" ? (
                      <>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              value={element.value}
                              control={<Checkbox />}
                              label={element.lbltitle}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </FormControl>
                      </>
                    ) : (
                      <TextField
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        placeholder="Type here..."
                        variant="outlined"
                        multiline
                        rows={3}
                        inputProps={{
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        value={
                          internalDetails && internalDetails[element.key]
                            ? internalDetails && internalDetails[element.key]
                            : ""
                        }
                        onChange={(e) => {
                          console.log("selected", e.target.value);

                          setInternalDetails({
                            ...internalDetails,
                            [element.key]: e.target.value,
                          });
                          setInternalBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Typography
                      className={classes.formHeading}
                      style={{
                        fontWeight: "600",

                        color: "#4E97FD",
                      }}
                    >
                      {element.title}
                    </Typography>
                  </>
                )}
              </Grid>
            </>
          );
        })}
        <Grid className={classes.btnGroup} item xs={12} style={{ display: "flex", justifyContent: "end" }}>
        {showDiscard && (
            <Button
              className={classes.btnleftOutline}
              variant="outlined"
              style={{ marginRight: "10px", fontSize: "12px" }}
              size="medium"
              onClick={discardHandler}
            >
              Discard
            </Button>
          )}
          <Button disabled={!internalBtnUnable}
            style={{ display: "flex", float: "right" }}
            onClick={handleSubmit}
            className={classes.btnlRightDark}
            variant="contained"
            size="medium"
            color="secondary"
          >
            Save
          </Button>
        </Grid>
      </Grid>

      {/* </TableWidget> */}
    </>
  );
}
