import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import useStyles from "./styles";
import {
  Grid,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Select,
  FormControl,
  FormGroup,
  Checkbox,
  Button,
  Avatar,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";
import { framFormElements } from "./order_constant";
import {
  postFrameLense,
  getProcedureCode,
  getSupplierDetail,
  getManufacturerDetail,
  getInvtService,
  getServiceMaster,
  postMetaData,
} from "../../services/ServiceLayer";
import {
  currentUser,
  postFlagBooleanVariable,
  loadState,
} from "../../hooks/helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function MetaDataForm() {
  const location = useLocation();
  const selectedRow = location.state ? location.state.row : null;
  // console.log("selectedRow",selectedRow);
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  var classes = useStyles();
  const [frames, setFrames] = useState([{}]); // Initialize with one empty frame
  const [listData, setListData] = useState([]);
  const [invSourceData, setInvSourceData] = useState([]);
  const [structureList, setStructureList] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [frameTypeData, setFrameTypeData] = useState([]);
  const [rimTypeData, setRimTypeData] = useState([]);
  const [frameShapeData, setFrameShapeData] = useState([]);
  const [frameMaterialData, setFrameMaterialData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [manufacturerData, setManufacturerData] = useState([]);
  const [actionType, setActionType] = useState("ADDMET");

  const removeFrame = (index) => {
    console.log("index", index);
    const newFrames = [...frames];
    newFrames.splice(index, 1);
    setFrames(newFrames);
  };
  // call meta data
  const metaData = loadState().metaData;
  useEffect(() => {
    console.log("loadState", metaData);
    setListData(metaData);
  }, []);

  useEffect(() => {
    getServiceMaster(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSTR&structure_id=0`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setStructureList(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
    getManufacturerDetail(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELMAN&manufacturer=0`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setManufacturerData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
    getSupplierDetail(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSUP&supplier_id=0`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setSupplierData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
  }, []);

  useEffect(() => {
    if (listData.length > 0) {
      const genderData = listData[0].gender;
      const invSourceData = listData[0].InventorySource;
      const frameTypeData = listData[0].FrameType;
      const rimTypeData = listData[0].RimType;
      const frameShapeData = listData[0].FrameShape;
      const frameMaterialData = listData[0].FrameMaterial;
      setGenderData(genderData);
      setInvSourceData(invSourceData);
      setFrameTypeData(frameTypeData);
      setRimTypeData(rimTypeData);
      setFrameShapeData(frameShapeData);
      setFrameMaterialData(frameMaterialData);
    }
  }, [listData]);

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [metaDataDetail, setMetaDataDetail] = useState({
    seq_id: 0,
    location_id: userData?.location_id,
    grp_meta_key: "",
    grp_meta_name: "",
    grpsub_meta_key: "",
    grpsub_meta_name: "",
    grp_attribute_key: "",
    grp_attribute_val: "",
    meta_sort_order:"",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: actionType,
  });
  useEffect(() => {
    if (selectedRow) {
      setMetaDataDetail({
        seq_id: selectedRow?.seq_id,
        location_id: userData?.location_id,
        grp_meta_key: selectedRow?.grp_meta_key,
        grp_meta_name: selectedRow?.grp_meta_name,
        grpsub_meta_key: selectedRow?.grpsub_meta_key,
        grpsub_meta_name: selectedRow?.grpsub_meta_name,
        grp_attribute_key: selectedRow?.grp_attribute_key,
        grp_attribute_val: selectedRow?.grp_attribute_val,
        meta_sort_order:selectedRow?.meta_sort_order,
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: "MODMET",
      });
    }
  }, [selectedRow]);
  const addMetaData = () => {
    postMetaData(metaDataDetail).then((response) => {
      console.log("response", response);
      if (response?.status?.status) {
        toast.success("MeatData added successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response?.status?.description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const selectFrameBySku = () => {
    // history.push(`/app/admin-details`);
    setOpen(true);
  };
  const selectPrevFrame = () => {
    setOpen1(true);
  };
  const hideHandle = () => {
    setOpen(false);
    setOpen1(false);
  };
  return (
    <>
      <ToastContainer />
      {/* <SelectFrameList open={open} onHide={hideHandle} title={"Select Frame"} /> */}

      <div className={classes.pageTitleContainer}>
        <div
          className={classes.groupBtn}
          style={{ justifyContent: "space-between" }}
        >
          <Typography className={classes.typo} weight="medium">
            Meta Data
          </Typography>
        </div>
      </div>

      <Grid
        container
        spacing={1}
        className={classes.formBox}
        style={{
          marginBottom: "16px",
          padding: "16px",
        }}
      >
        <Grid item md={12}>
          <Grid container spacing={1}>
            {framFormElements.map((element, i) => {
              return (
                <Grid
                  item
                  xs={12}
                  md={element.mdSize}
                  className={`${classes.textGroup} ${
                    element.title ? classes.highlightedItem : ""
                  }`}
                  style={{ border: "1px solid #DED9D9" }}
                >
                  <Typography
                    className={classes.formHeading}
                    style={{ fontWeight: "500", color: "#4E97FD" }}
                  >
                    {element.label}{" "}
                    {element.required === "true" ? (
                      <span className={classes.required}>*</span>
                    ) : null}
                  </Typography>
                  {element.type ? (
                    <>
                      {element.type === "select" &&
                      element.key !== "procedure_code" ? (
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            name={element.key}
                            className={classes.selectDropdown}
                            placeholder={element.placeHolder}
                            value={
                              metaDataDetail && metaDataDetail[element.key]
                                ? metaDataDetail && metaDataDetail[element.key]
                                : ""
                            }
                            onChange={(e) => {
                              console.log("selected");
                              setMetaDataDetail({
                                ...metaDataDetail,
                                [element.key]: e.target.value,
                              });
                            }}
                          >
                            <MenuItem disabled value="">
                              Select
                            </MenuItem>
                            {element.key === "supplier_id" && supplierData
                              ? supplierData.map((item, index) => (
                                  <MenuItem
                                    key={index}
                                    value={item.supplier_id}
                                  >
                                    {item.supplier_name}
                                  </MenuItem>
                                ))
                              : element.key === "manufacturer_id" &&
                                manufacturerData
                              ? manufacturerData.map((item, index) => (
                                  <MenuItem
                                    key={index}
                                    value={item.manufacturer_id}
                                  >
                                    {item.manufacturer_name}
                                  </MenuItem>
                                ))
                              : element.key === "inv_source" && invSourceData
                              ? invSourceData.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "gender" && genderData
                              ? genderData.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "frametype_id" && frameTypeData
                              ? frameTypeData.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "rim_type_id" && rimTypeData
                              ? rimTypeData.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "frame_shape_id" &&
                                frameShapeData
                              ? frameShapeData.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "material_id" &&
                                frameMaterialData
                              ? frameMaterialData.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "structure_id" && structureList
                              ? structureList.map((item, index) => (
                                  <MenuItem
                                    key={index}
                                    value={item.structure_id}
                                  >
                                    {item.structure_name}
                                  </MenuItem>
                                ))
                              : element.options.map((item) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      ) : element.type === "text" ? (
                        <div style={{ display: "flex" }}>
                          <TextField
                          disabled={selectedRow && element.key ==="grp_meta_key" ? true : false}
                            required
                            name={element.key}
                            placeholder={element.placeHolder}
                            size="small"
                            className={classes.txtForm}
                            fullWidth
                            variant="outlined"
                            inputProps={{
                              style: {
                                height: "12px",
                                fontSize: "12px",
                              },
                              maxLength: element.maxLength,
                              onKeyPress: element.onKeyPress,
                            }}
                            value={
                              metaDataDetail && metaDataDetail[element.key]
                                ? metaDataDetail && metaDataDetail[element.key]
                                : ""
                            }
                            onChange={(e) => {
                              console.log("selected");
                              setMetaDataDetail({
                                ...metaDataDetail,
                                [element.key]: e.target.value,
                              });
                            }}
                          />
                          {element.key === "sku" && (
                            <Button
                              onClick={selectFrameBySku}
                              classes={{ root: classes.button }}
                              variant="contained"
                              color="secondary"
                            >
                              Slect
                            </Button>
                          )}
                        </div>
                      ) : element.type === "commission_amount" ? (
                        <TextField
                          type="text"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          size="small"
                          inputProps={{
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                            maxLength: element.maxLength,
                            onKeyPress: element.onKeyPress,
                          }}
                        />
                      ) : element.type === "date" ||
                        element.type === "datetime-local" ? (
                        <TextField
                          id={element.key}
                          name={element.key}
                          placeholder={element.placeHolder}
                          type={element.type}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      ) : element.type === "radio" ? (
                        <RadioGroup row defaultValue="">
                          {element.radioValue.map((item) => (
                            <FormControlLabel
                              size="small"
                              style={{ padding: "0px 6px" }}
                              value={item}
                              control={<Radio />}
                              label={item}
                              className={classes.smallRadioButton}
                            />
                          ))}
                        </RadioGroup>
                      ) : element.type === "checkbox" ? (
                        <>
                          <Checkbox name={element.key} />
                        </>
                      ) : (
                        <TextField
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          placeholder="Type here..."
                          variant="outlined"
                          multiline
                          rows={3}
                          inputProps={{
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          value={
                            metaDataDetail && metaDataDetail[element.key]
                              ? metaDataDetail && metaDataDetail[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected");
                            setMetaDataDetail({
                              ...metaDataDetail,
                              [element.key]: e.target.value,
                            });
                          }}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <Typography
                        className={classes.formHeading}
                        style={{
                          fontWeight: "600",

                          color: "#4E97FD",
                        }}
                      >
                        {element.title}
                      </Typography>
                    </>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>

      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          classes={{ root: classes.button }}
          variant="contained"
          color="secondary"
          onClick={addMetaData}
        >
          {selectedRow ? "Update Meta Data" : "Save New Meta Data"}
        </Button>
      </div>
    </>
  );
}
