import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Button, Grid } from "@material-ui/core";
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";

// Model

import { MoreVert as MoreIcon, Lens } from "@material-ui/icons";
// components
import PageTitle from "../../PageTitle/PageTitle";
import TableWidget from "../../Widget/TableWidget";

// styles
import useStyles from "./styles";

import TableDesign from "../../common/table/TableDesign";
import {
  getCompanyTax,
  getCompanyStoreData,
} from "../../../services/ServiceLayer";
import { loadState } from "../../../hooks/helper";

import ManageTaxRate from "./ManageTaxRate";
import ManageEditTaxRate from "./ManageEditTaxRate";
export default function TaxRateList() {
  const history = useHistory();
  // const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [selectRow, setSelectRow] = useState(null);
  const [locationName, setLocationName] = useState([]);
  //
  var classes = useStyles();
  var [activeTabId, setActiveTabId] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = useState(false);
  const [companyTaxData, setCompanyTaxData] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [selectclient, setselectclient] = useState(null);
  const [title, setTitle] = useState(null);


  const [toggleActivateModalShow, setToggleActivateModalShow] = useState(false);

  const [filteredData, setFilteredData] = useState([]);

  const [selectedStoreIds, setSelectedStoreIds] = useState([]);
  const [selectedStoreID, setSelectedStore] = useState(0);
  const [selectedCmp, setSelectedCmp] = useState(0);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const userData = loadState().user;
  const userRole = loadState().user.user_role;

  const editTax = (row) => {
    console.log("row-61", row);
    setselectclient(row);
    setOpen1(true);
    setAnchorEl(null);
  };

  // table Header
  const tableHeadings = [
    {
      label: "S. No.",
    },
    {
      label: "Store Name",
      align: "left !important",
    },
    {
      label: "Tax Type",
    },
    {
      label: "Tax 1",
    },
    {
      label: "Tax 2",
    },
    {
      label: "Action",
    },
  ];

  const handleTabChange = (event, newTabId) => {
    setActiveTabId(newTabId);
  };
  const addClient = () => {
    if (filteredData.length > 0) {
      history.push(`/app/tax-edit/${selectedCmp}/${selectedStoreID}`);
    } else {
      console.log("add client called");
      setselectclient([selectedCmp, selectedStoreID]);
      setTitle("Add Tax");
      setOpen(true);
      setAnchorEl(null);
    }
  };

  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpen(false);
    setOpen1(false);
    setToggleActivateModalShow(false);
    setDataUpdated(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };


  useEffect(() => {
    getCompanyStoreData(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELLOC`
    ).then((response) => {
      console.log("response", response);
      const storeInfo = response?.data.map((company) => company.store_info);
      console.log("storeInfo", storeInfo);
      setLocationName(storeInfo);
      setSelectedStoreIds(storeInfo.map((item) => item.store_id));
      const storeId = storeInfo.map((item) => {
        return item[activeTabId].store_id;
      });
      setSelectedStore(storeId[0]);
      setSelectedCmp(response.data.length !== 0 ? response.data[0].comp_id : 0);
    });
  }, [activeTabId]);
  useEffect(() => {
    getCompanyTax(
      `location_id=${selectedStoreID}&user_role=${userRole}&opt_type=SELTAX`
    ).then((response) => {
      console.log(response);
      setCompanyTaxData(response.data);
    });
  }, []);
  useEffect(() => {
    getCompanyTax(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELTAX`
    ).then((response) => {
      console.log(response);
      setCompanyTaxData(response.data);
      setDataUpdated(false);
    });
  }, [dataUpdated]);

  useEffect(() => {
    if (locationName[0]) {
      const selectedStore = locationName[0];
      const selectedStoreIds = selectedStore.map((store) => store.store_id);
      const filteredData = companyTaxData?.filter((row) => {
        return row.store_id === selectedStoreIds[activeTabId];
      });

      setFilteredData(filteredData);
    } else {
      setFilteredData([]);
    }
  }, [activeTabId, locationName, companyTaxData]);
  return (
    <>
      <ManageTaxRate
        open={open}
        onHide={hideHandle}
        title={"Add Tax"}
        selectclient={selectclient}
        setModalShow={setModalShow}
      />
      <ManageEditTaxRate
        open={open1}
        onHide={hideHandle}
        title={"Update Tax"}
        selectclient={selectclient}
        setModalShow={setModalShow1}
      />
      <PageTitle
        title="Manage Tax Rate"
        button={filteredData.length>0 ?'':'Add Tax Rate'}
        onClick={addClient}
      />
     
      <Tabs TabIndicatorProps={{
            style: { background: "#4E97FD",width:"150px"},
          }}
       
        variant="scrollable"
        scrollButtons="auto"
        value={activeTabId}
        onChange={handleTabChange}
        className={classes.iconsBar}
      >
        {locationName &&
          locationName
            .flat()
            .map((item) =>
                item.store_name !== null ? (
                <Tab
                  key={item.store_id}
                  label={item.store_name}
                  classes={{ root: classes.tab }}
                />
              ) : null
            )}
      </Tabs>
      {activeTabId >= 0 && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TableWidget noBodyPadding>
              <TableDesign
                {...{
                  title: "List of All Clients",
                  tableHeadings,
                }}
              >
                {filteredData && filteredData.length ? (
                  filteredData.map((row, i) => {
                    if (row)
                      return (
                        <TableRow key={i} className={classes.tableBodyCell}>
                          <TableCell className={classes.tableBodyCell}>
                            {i + 1}
                          </TableCell>

                          <TableCell
                            align="left"
                            className={classes.tableBodyCell}
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              paddingLeft: "10px",
                            }}
                          >
                            {row.store_name}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            className={classes.tableBodyCell}
                          >
                            {row.tax_type}
                          </TableCell>
                          <TableCell className={classes.tableBodyCell}>
                            {row.tax_1}
                          </TableCell>
                          <TableCell className={classes.tableBodyCell}>
                            {row.tax_2}
                          </TableCell>

                          <TableCell className={classes.tableBodyCell}>
                            <IconButton
                              onClick={(e) => {
                                handleClick(e);
                                setSelectRow(row);
                              }}
                            >
                              <MoreIcon />
                            </IconButton>

                            <Menu
                              className={classes.menuBox}
                              anchorEl={anchorEl}
                              open={openDown}
                              onClose={handleClose}
                            >
                              <MenuItem
                                className={classes.menuItem}
                                onClick={() => editTax(selectRow)}
                              >
                                <Lens
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                    marginRight: "4px",
                                  }}
                                />{" "}
                                Edit Tax
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan="8">No Result Found</TableCell>
                  </TableRow>
                )}
              </TableDesign>
            </TableWidget>
          </Grid>
        </Grid>
      )}
    </>
  );
}
