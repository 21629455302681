import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  Typography,
  InputBase,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import * as Icons from "@material-ui/icons";
import {
  MoreVert as MoreIcon,
  Search as SearchIcon,
  Lens,
} from "@material-ui/icons";
import TableWidget from "../Widget/TableWidget";
import TableDesign from "../common/table/TableDesign";
import useStyles from "../Settings/Tax/styles";

import classNames from "classnames";
import ManageContactLens from "./Services/ManageContactLens";
import { getInvtContactLense } from "../../services/ServiceLayer";
import { loadState } from "../../hooks/helper";
import DeleteContactPopup from "./DeleteContactPopup";
import moment from "moment";
export default function LensList() {
  const history = useHistory();
  var classes = useStyles();
  const [openModel, setOpenModel] = React.useState(false);
  const [modelShow, setModalShow] = useState(false);
  const [selectclient, setselectclient] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [contactListData, setContactListData] = useState([]);
  var [isSearchOpen, setSearchOpen] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = React.useState(false);

  const [title, setTitle] = useState(null);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [selectRow, setSelectRow] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [inventoryReadValue, setInventoryReadValue] = useState();
  const readAccessData = loadState().menu;
  useEffect(() => {
    console.log("readAccessData", readAccessData);
    const inventorySection = readAccessData.find(
      (item) => item.label === "Inventory"
    );
    if (inventorySection) {
      const inventoryChildren = inventorySection.children;

      const inventoryReadAcess = inventoryChildren.find(
        (child) => child.label === "Inventory"
      ).read_access;
      setInventoryReadValue(inventoryReadAcess);
    }
  }, [readAccessData]);
  const openDown = Boolean(anchorEl);
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const deleteEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);

    setselectclient(rec);
    setDeleteModalShow(true);
    setTitle("Delete Frame");
  };
  useEffect(() => {
    getInvtContactLense(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELCNL`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setIsLoader(false);
        setContactListData(response?.data);
      } else {
        setIsLoader(false);
      }
    });
  }, []);
  useEffect(() => {
    getInvtContactLense(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELCNL`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setIsLoader(false);
        setContactListData(response?.data);
      } else {
        setIsLoader(false);
      }
    });
    setDataUpdated(false);
  }, [dataUpdated]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTopClick = (event) => {
    setAnchorPosition({
      top: event.clientY,
      left: event.clientX,
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorE2(null);
    setAnchorPosition(null);
  };

  const handleViewModify = () => {
    // Handle View Modify
    setAnchorPosition(null);
  };

  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Lens Name ",
      secondaryLabel: "(SKU)",
      align: "left !important",
    },
    {
      label: "Lens Type",
      secondaryLabel: "(Replacement Schedule)",
    },
   
    {
      label: "Manufacturer",
      secondaryLabel: "(Brand)",
    },

    {
      label: "Arrival Date",
      // secondaryLabel: "(Arrival Date)",
    },
    {
      label: "Retail Price",
      secondaryLabel: "(Returnable_val)",
    },
    // {
    //   label: "Trial Lens Name",
    // },

    {
      label: "Status",
      secondaryLabel: "(Source)",
    },
   
    {
      label: "Action",
    },
  ];
  const addContactLens = () => {
    history.push(`/app/manage-contact-lens`);
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpenModel(false);
    setOpen(false);

    setDataUpdated(true);
  };
  const handleRowClick = (row) => {
    setSelectedRow(row);
  };

  // Create a function to navigate to the edit page with selected row data
  const handleEditClick = (event) => {
    if (selectedRow) {
      console.log("selectedRow", selectedRow);
      // Use React Router to navigate to the edit page
      history.push(`/app/contact-details/${selectedRow.contlens_id}`, {
        selectedRow,
        event,
      });
    }
  };
  //searching
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredDataList = contactListData.filter((item) => {
    // Check if each property is not null or undefined before using toLowerCase()
    const lensName = item.lensname ? item.lensname.toLowerCase() : "";

    return lensName.includes(searchTerm.toLowerCase());
  });
  return (
    <>
      <DeleteContactPopup
        onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        selectclient={selectclient}
        setDeleteModalShow={setDeleteModalShow}
      />
      <ManageContactLens
        open={openModel}
        title={selectclient ? "Edit Contact Lens" : "New Contact Lens"}
        onHide={hideHandle}
        setModalShow={setModalShow}
      />
      <div className={classes.pageTitleContainer}>
        <Typography className={classes.typo} weight="medium">
          Contact Lens
        </Typography>
      </div>
      <div className={classes.pageTitleContainer}>
        <Typography className={classes.typo} weight="medium">
          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
            <div
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
              onClick={() => setSearchOpen(!isSearchOpen)}
            >
              <SearchIcon classes={{ root: classes.headerIcon }} />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onChange={handleChange}
            />
          </div>
        </Typography>
        <div className={classes.groupBtn}>
          <Tooltip title="Print">
            <Icons.LocalPrintshop />
          </Tooltip>
          <Tooltip title="Dowload">
            <Icons.Archive />
          </Tooltip>
          <Tooltip title="Mail">
            <Icons.Mail />
          </Tooltip>
          <div>
            {inventoryReadValue === "N" && (
              <IconButton onClick={handleTopClick}>
                <MoreIcon />
              </IconButton>
            )}
            <Menu
              className={classes.menuBox}
              anchorReference="anchorPosition"
              anchorPosition={anchorPosition}
              open={Boolean(anchorPosition)}
              onClose={handleClose}
            >
              <MenuItem onClick={addContactLens} className={classes.menuItem}>
                <Lens
                  style={{
                    height: "4px",
                    width: "4px",
                    marginRight: "4px",
                  }}
                />{" "}
                Add New
              </MenuItem>
              <MenuItem className={classes.menuItem} onClick={handleViewModify}>
                <Lens
                  style={{
                    height: "4px",
                    width: "4px",
                    marginRight: "4px",
                  }}
                />{" "}
                Import From File
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {filteredDataList && filteredDataList.length ? (
                filteredDataList.map((row, i) => {
                  if (row)
                    return (
                      <TableRow
                        onClick={() => handleRowClick(row)}
                        key={i}
                        className={classes.tableBodyCell}
                      >
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                          style={{ cursor: "pointer" }}
                        >
                          {row.lens_name}
                          <br />
                          {row.lense_sku && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.lense_sku})
                            </span>
                          )}
                        </TableCell>

                        <TableCell className={classes.tableBodyCell}>
                          {row.lense_units_val}
                          <br />
                          {row.replacement_schedule_val && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.replacement_schedule_val})
                            </span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row?.manufacturer_name}

                          <br />
                          {row.lense_brand_val && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.lense_brand_val})
                            </span>
                          )}
                        </TableCell>

                        <TableCell className={classes.tableBodyCell}>
                          {/* {row?.qty_inventory} */}

                          <br />
                          {row.created_dt && (
                            <span style={{ color: "#4E97FD" }}>
                              ({moment(row.created_dt).format("LLL")})
                            </span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row?.retail_price}
                          <br />
                          {row.returnable_val && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.returnable_val ==='Y' ? "Yes" : 'No'})
                            </span>
                          )}
                        </TableCell>

                        {/* <TableCell className={classes.tableBodyCell}>
                          {row?.trial_name}
                        </TableCell> */}
                        <TableCell className={classes.tableBodyCell}>
                        {row.is_active === "Y" ? (
                            <span style={{ color: "green" }}>Active</span>
                          ) : (
                            <span style={{ color: "red" }}>Inactive</span>
                          )}
                          <br />
                          {row.inv_source_val && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row?.inv_source_val})
                            </span>
                          )}
                        </TableCell>

                       
                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => handleEditClick("View/Update")}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              {inventoryReadValue === "N" ? (
                                <>View/Update</>
                              ) : (
                                <>View Details</>
                              )}
                            </MenuItem>
                            {inventoryReadValue === "N" && (
                              <>
                                <MenuItem
                                  onClick={() => deleteEventHandler(selectRow)}
                                  className={classes.menuItem}
                                >
                                  <Lens
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                      marginRight: "4px",
                                    }}
                                  />{" "}
                                  {selectRow && selectRow.is_active === "Y" ? (
                                    <>Delete</>
                                  ) : (
                                    <>Re-activate</>
                                  )}
                                </MenuItem>
                                <MenuItem
                                  onClick={() => handleEditClick("Copy")}
                                  className={classes.menuItem}
                                >
                                  <Lens
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                      marginRight: "4px",
                                    }}
                                  />{" "}
                                  Copy
                                </MenuItem>
                                <MenuItem className={classes.menuItem}>
                                  <Lens
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                      marginRight: "4px",
                                    }}
                                  />{" "}
                                  Recall
                                </MenuItem>

                                <MenuItem className={classes.menuItem}>
                                  <Lens
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                      marginRight: "4px",
                                    }}
                                  />{" "}
                                  Invoice
                                </MenuItem>

                                <MenuItem className={classes.menuItem}>
                                  <Lens
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                      marginRight: "4px",
                                    }}
                                  />{" "}
                                  Receiving
                                </MenuItem>
                                <MenuItem className={classes.menuItem}>
                                  <Lens
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                      marginRight: "4px",
                                    }}
                                  />{" "}
                                  Returns
                                </MenuItem>
                              </>
                            )}
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
