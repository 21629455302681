import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
// styles
import useStyles from "./styles";
import Employee from "../Cases/DemographyTab/Employee";
import Gaurantor from "../Cases/DemographyTab/Gaurantor";
import Referral from "../Cases/DemographyTab/Referral";
import Gaurdian from "./DemographyTab/Guardian";
export default function Demography({
  patientOtherData,
  setPatientOtherData,
  gaurantorValues,
  relTypeValues,
  genderValues,
  referenceSourceValues,
  phoneTypeValues,
  thanksValues,
}) {
  var classes = useStyles();

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Employer</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Employee
            patientOtherData={patientOtherData}
            setPatientOtherData={setPatientOtherData}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Guarantor</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Gaurantor
            patientOtherData={patientOtherData}
            setPatientOtherData={setPatientOtherData}
            gaurantorValues={gaurantorValues}
            relTypeValues={relTypeValues}
            genderValues={genderValues}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Referral</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Referral
            patientOtherData={patientOtherData}
            setPatientOtherData={setPatientOtherData}
            referenceSourceValues={referenceSourceValues}
            phoneTypeValues={phoneTypeValues}
            genderValues={genderValues}
            thanksValues={thanksValues}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Gaurdian</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Gaurdian
            patientOtherData={patientOtherData}
            setPatientOtherData={setPatientOtherData}
            relTypeValues={relTypeValues}
            genderValues={genderValues}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
