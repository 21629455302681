import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";

import moment from "moment";
import { formEmployeElements } from "./Patient_Constant";

import useStyles from "./styles";
import { Box } from "@mui/material";
export default function Employee({ patientOtherData, setPatientOtherData }) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();
  const [employeeDetail, setEmployeeDetail] = useState({
    occuption: null,
    employer: null,
    address_line1: null,
    address_line2: null,
    city: null,
    state: null,
    zip: null,
    employee_phone: null,
  });
  return (
    <Grid container spacing={2} className={classes.formBox}>
      {formEmployeElements.map((element, i) => {
        return (
          <>
            {patientOtherData ? (
              <Grid
                item
                xs={12}
                md={2}
                lg={2}
                sm={12}
                className={classes.textGroup}
              >
                <Typography className={classes.formHeading}>
                  {element.label}{" "}
                  {element.required === "true" ? (
                    <span className={classes.required}>*</span>
                  ) : null}
                </Typography>

                {element.type === "select" ? (
                  <FormControl
                    fullWidth
                    className={classes.dropdownFormControl}
                  >
                    <Select
                      name={element.key}
                      style={{ fontSize: "12px" }}
                      className={classes.selectDropdown}
                      placeholder={element.placeHolder}
                      value={
                        patientOtherData &&
                          patientOtherData[element.key] === null
                          ? ""
                          : patientOtherData && patientOtherData[element.key]
                      }
                      disabled
                      onChange={async (e) => {
                        setPatientOtherData({
                          ...patientOtherData,
                          [element.key]: e.target.value,
                        });
                      }}
                    >
                      <MenuItem value="">Select</MenuItem>
                      {element.options.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : element.type === "textarea" ? (
                  <TextField
                    disabled
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    placeholder="Type here..."
                    variant="outlined"
                    multiline
                    rows={3}
                    inputProps={{
                      style: {
                        fontSize: "12px",
                      },
                      maxLength: `${element.maxLength}`,
                    }}
                    value={
                      patientOtherData && patientOtherData[element.key] === null
                        ? ""
                        : patientOtherData && patientOtherData[element.key]
                    }
                  />
                ) : element.type === "date" ||
                  element.type === "datetime-local" ? (
                  <TextField
                    disabled
                    id={element.key}
                    name={element.key}
                    placeholder={element.placeHolder}
                    type={element.type}
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      min: `${currentDateTime}`,
                      max: `${currentDate}`,
                      style: {
                        height: "12px",
                        fontSize: "12px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      patientOtherData && patientOtherData[element.key] === null
                        ? ""
                        : patientOtherData && patientOtherData[element.key]
                    }
                  />
                ) : (
                  <TextField
                    disabled
                    required
                    name={element.key}
                    placeholder={element.placeHolder}
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    type={element.type}
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "12px",
                        fontSize: "12px",
                      },
                      maxLength: `${element.maxLength}`,
                    }}
                    value={
                      patientOtherData && patientOtherData[element.key] === null
                        ? ""
                        : patientOtherData && patientOtherData[element.key]
                    }
                  />
                )}
              </Grid>
            ) : null}
          </>
        );
      })}
    </Grid>
  );
}
