import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@material-ui/core";
import { ReactComponent as CalIcons} from '../../assets/dashbicons/weekly-schedule-calendar-and-dates-18311.svg';
import { ReactComponent as AvailIcon} from '../../assets/dashbicons/calendar-booked587.svg'
 
 
import { ReactComponent as TotalCan}  from '../../assets/dashbicons/convert-3215.svg'
import { ReactComponent as TotalCancel}  from '../../assets/dashbicons/red-calendar-cancel-event-booking-23330.svg'
 

import * as Icons from "@material-ui/icons";
// styles
import useStyles from "./styles";

import Widget from "../../components/Widget";

import { Typography } from "../../components/Wrappers";

import BigStat from "./components/BigStat/BigStat";
// icons
 

import CurrentAppointList from "../../components/Dashboard/CurrentAppointList";
import {
  getDashboardCountByDate,
  todaysScheduleWithDate,
} from "../../services/ServiceLayer";
import Loader from "../../components/common/loader/Loader";
import { toast } from "react-toastify";
import moment from "moment";
import { loadState } from "../../hooks/helper";
import AlertPopup from "./AlertPopup";
import { Box } from "@mui/material";
import DashboardBox from "../../components/Widget/DashboardBox";

export default function Dashboard() {
  var classes = useStyles();
  toast.configure();

  const [patientData, setPatientData] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);

  const [isLoader, setIsLoader] = useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [cardShow, setCardShow] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [updateData, setParentUpdateData] = useState(false);
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  useEffect(() => {
    console.log("userData", userData);
    console.log("userData", userData?.location_id);
    console.log("role", userData?.user_role);
  }, [userData]);
  const selectCard = () => {
    setCardShow(true);
  };
  const selectTable = () => {
    setCardShow(false);
  };

  useEffect(() => {
    setIsLoader(true);

    const fetchData = async () => {
      const response = await todaysScheduleWithDate(
        `staff_id=${userData?.user_id}&location_id=${userData?.location_id}&start_date=${startDate}&end_date=${endDate}&user_role=${userRole}&opt_type=SELSCH`
      );
      console.log(response);

      setPatientData(response?.data);
      setIsLoader(false);
      setParentUpdateData(false);
      toast.error(response);
    };

    fetchData();
  }, [startDate, endDate, updateData]);

  useEffect(() => {
    getDashboardCountByDate(
      `staff_id=${userData?.user_id}&location_id=${userData?.location_id}&start_date=${startDate}&end_date=${endDate}&user_role=${userRole}&opt_type=SELSCH`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setIsLoader(false);
        setDashboardData(response?.data);
      } else {
        toast.error(response);
        setIsLoader(false);
      }
    });
  }, [startDate, endDate]);
  useEffect(() => {
    console.log("startDate", startDate);
    console.log("endDate", endDate);
  }, [startDate, endDate]);
  useEffect(() => {
    console.log("patientData", patientData);
  }, [patientData]);
  const filteredAppointments = patientData.filter(
    (appointment) => appointment.is_totalappointmnet
  );
  const filteredReschedules = patientData.filter(
    (appointment) => appointment.is_rescheduled
  );
  const filteredCancelled = patientData.filter(
    (appointment) => appointment.is_canceled
  );
  const filteredAvailabled = patientData.filter(
    (appointment) => appointment.is_available
  );

  const alertOpen = (item) => {
    console.log("item", item);
    setOpen(true);
    setDeleteModalShow(true);
  };
  const hideHandle = () => {
    setOpen(false);
    setDeleteModalShow(false);
  };
  return (
    <>
      <AlertPopup
        onHide={hideHandle}
        open={open}
        deleteModalShow={deleteModalShow}
        setParentUpdateData={setParentUpdateData}
      />
      <div
        className={classes.pageTitleContainer}
        style={{ marginBottom: "24px" }}
      >
        <Typography
          style={{ fontWeight: "600", fontSize: "20px" }}
          weight="medium"
        >
          Dashboard 
        </Typography>
       
        <div className={classes.groupBtn} style={{ display: "flex" }}>
          <span style={{ flex: "flex-1" }}>
            <Typography
              style={{
                marginRight: "16px",
                fontSize: "12px",
                fontWeight: "400",
              }}
              className={classes.formHeading}
            >
              Start Date
            </Typography>
            <TextField
              name="startDate"
              placeholder="select"
              type="date"
              size="small"
              defaultValue={startDate}
              className={classes.txtForm}
              fullWidth
              variant="outlined"
              onKeyDown={(e) => {
                e.preventDefault(); // Prevent manual typing
              }}
              inputProps={{
                // Set the maximum time to 18:00
                style: {
                  height: "12px",
                  fontSize: "12px",
                },
                max: endDate,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
            />
          </span>

          <span style={{ flex: "flex-1", marginLeft: "16px" }}>
            <Typography
              style={{ fontSize: "12px", fontWeight: "400" }}
              className={classes.formHeading}
            >
              End Date
            </Typography>
            <TextField
              name="startDate"
              placeholder="select"
              type="date"
              size="small"
              className={classes.txtForm}
              fullWidth
              defaultValue={endDate}
              variant="outlined"
              onKeyDown={(e) => {
                e.preventDefault(); // Prevent manual typing
              }}
              inputProps={{
                // Set the maximum time to 18:00
                style: {
                  height: "12px",
                  fontSize: "12px",
                },
                min: startDate,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
            // disabled={element.key === "end"} // Disable
            />
          </span>
        </div>
      </div>
      {isLoader && <Loader />}
      {dashboardData &&
        dashboardData.map((count, dashIndex) => (
          <Grid key={dashIndex} container spacing={3}>
            <Grid item lg={3} md={4} sm={6} xs={12} sx={{ bgcolor: "#000" }}>
              <DashboardBox
                style={{
                  // border: activeTab === 0 ? "2px solid #068AC5" : "",
                  cursor: "pointer",
                  background:
                    "linear-gradient(135deg, rgba(91, 228, 155, 0.2), rgba(0, 167, 111, 0.2)) rgb(255, 255, 255)",
                }}
                onChange={(e) => setActiveTab(0)}
                weight="medium"
                upperTitle
                className={classes.card}
                bodyClass={classes.fullHeightBody}
              >
                {/* <img className={classes.icons} src={cubes} alt="img"  /> */}

                <div className={classes.progressSection} style={{
                  display: 'flex',
                  gap: '12px',
                  justifyContent: 'start',
                  alignItems: 'center',
                  height: '100%',
                  marginBottom: '0',
                  padding: "3%",
                }}>
                 <CalIcons />
                  <Box sx={{
                    display: 'flex',
                    gap: '5px',
                    justifyContent: 'start',
                    alignItems: 'start',
                    flexDirection: 'column',
                  }}>
                    <Typography variant='h4'>
                      {count?.totalappointmnet ? count?.totalappointmnet : 0}
                    </Typography>
                    <Typography className={classes.progressSectionTitle}>
                      Total Appointment{" "}
                    </Typography>

                  </Box>
                </div>
              </DashboardBox>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <DashboardBox
                style={{
                  // border: activeTab === 1 ? "2px solid #068AC5" : "",
                  cursor: "pointer",
                  background:
                    "linear-gradient(135deg, rgba(97, 243, 243, 0.2), rgba(0, 184, 217, 0.2)) rgb(255, 255, 255)",
                }}
                onChange={(e) => setActiveTab(1)}

                className={classes.card}
                bodyClass={classes.fullHeightBody}
              >
                {/* <img className={classes.icons} src={cubes} alt="img" /> */}

                <div className={classes.progressSection} style={{
                  display: 'flex',
                  gap: '12px',
                  justifyContent: 'start',
                  alignItems: 'center',
                  height: '100%',
                  marginBottom: '0',
                  padding: "3%"
                }}>
               <TotalCan />
                  <Box sx={{
                    display: 'flex',
                    gap: '5px',
                    justifyContent: 'start',
                    alignItems: 'start',
                    flexDirection: 'column',
                  }}>
                    <Typography variant='h4'>
                      {count?.rescheduled ? count?.rescheduled : 0}
                    </Typography>
                    <Typography
                      size="md"
                      color="text"
                      colorBrightness="secondary"
                      className={classes.progressSectionTitle}
                    >
                      Total Rescheduled{" "}

                    </Typography>
                  </Box>
                </div>
              </DashboardBox>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <DashboardBox
                style={{
                  // border: activeTab === 2 ? "2px solid #068AC5" : "",
                  cursor: "pointer",
                  background:
                    "linear-gradient(135deg, rgba(255, 214, 102, 0.2), rgba(255, 171, 0, 0.2)) rgb(255, 255, 255)",
                }}
                onChange={(e) => setActiveTab(2)}


                className={classes.card}
                bodyClass={classes.fullHeightBody}
              >
                {/* <img className={classes.icons} src={cubes} alt="img" />// */}

                <div className={classes.progressSection} style={{
                  display: 'flex',
                  gap: '12px',
                  justifyContent: 'start',
                  alignItems: 'center',
                  height: '100%',
                  marginBottom: '0',
                  padding: "3%",
                }}>
                   <TotalCancel />

                  <Box sx={{
                    display: 'flex',
                    gap: '5px',
                    justifyContent: 'start',
                    alignItems: 'start',
                    flexDirection: 'column',
                  }}>
                    <Typography variant='h4'>
                      {count?.canceled ? count?.canceled : 0}
                    </Typography>
                    <Typography
                      size="md"
                      color="text"
                      colorBrightness="secondary"
                      className={classes.progressSectionTitle}
                    >
                      Total Cancelled
                    </Typography>

                  </Box>
                  {/* <Dot color="primary" /> */}
                </div>
              </DashboardBox>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <DashboardBox
                style={{
                  // padding:'4%',
                  cursor: "pointer",
                  background:
                    "linear-gradient(135deg, rgba(255, 172, 130, 0.2), rgba(255, 86, 48, 0.2)) rgb(255, 255, 255)",
                }}
                onChange={() => setActiveTab(3)}

                className={classes.card}
                bodyClass={classes.fullHeightBody}
              >
                {/* <img className={classes.icons} src={cubes} alt="img" />// */}

                <div className={classes.progressSection} style={{
                  display: 'flex',
                  gap: '12px',
                  justifyContent: 'start',
                  alignItems: 'center',
                  height: '100%',
                  marginBottom: '0',
                  padding: "3%",
                }}>

                  <AvailIcon />
                  <Box sx={{
                    display: 'flex',
                    gap: '5px',
                    justifyContent: 'start',
                    alignItems: 'start',
                    flexDirection: 'column',
                  }}>
                    <Typography variant='h4'>
                      {count?.available ? count?.available : 0}
                    </Typography>
                    <Typography
                      size="md"
                      color="text"
                      colorBrightness="secondary"
                      className={classes.progressSectionTitle}
                    >
                     Total Available{" "}

                    </Typography>
                  </Box>
                </div>
              </DashboardBox>
            </Grid>

            {activeTab === 0 && (
              <>
                <Grid item xs={12}>
                  <div className={classes.pageTitleContainer}>
                    <Typography
                      className={classes.typo}
                      weight="medium"
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      Total Appointment  {count?.totalappointmnet ? count?.totalappointmnet : 0}
                    </Typography>
                    <div className={classes.groupBtn}>
                      <Icons.Apps
                        onClick={selectCard}
                        style={{ marginRight: "6px", cursor: "pointer" }}
                      />
                      <Icons.Dehaze
                        onClick={selectTable}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </Grid>
                {cardShow ? (
                  <>
                    {patientData &&
                      patientData.map(
                        (item, index) =>
                          item.is_totalappointmnet && (
                            <Grid item key={index} md={3} sm={6} xs={12}>
                              <BigStat
                                onClick={() => alertOpen(item)}
                                {...item}
                              />
                            </Grid>
                          )
                      )}
                  </>
                ) : (
                  <Grid item xs={12}>
                    {filteredAppointments && (
                      <Widget
                        upperTitle
                        noBodyPadding
                        bodyClass={classes.tableWidget}
                      >
                        <CurrentAppointList
                          patientData={filteredAppointments}
                          selectAppointmentStatus="Scheduled"
                        />
                      </Widget>
                    )}
                  </Grid>
                )}
              </>
            )}
            {/*  */}
            {activeTab === 1 && (
              <>
                <Grid item xs={12}>
                  <div className={classes.pageTitleContainer}>
                    <Typography
                      className={classes.typo}
                      weight="medium"
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      Total Rescheduled  {count?.rescheduled ? count?.rescheduled : 0}
                    </Typography>
                    <div className={classes.groupBtn}>
                      <Icons.Apps
                        onClick={selectCard}
                        style={{ marginRight: "6px", cursor: "pointer" }}
                      />
                      <Icons.Dehaze
                        onClick={selectTable}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </Grid>

                {cardShow ? (
                  <>
                    {/* {patientData &&
                      patientData.map(
                        (item, index) =>
                          item.is_rescheduled && (
                            <Grid item key={index} md={3} sm={6} xs={12}>
                              <BigStat {...item} />
                            </Grid>
                          )
                      )} */}
                  </>
                ) : (
                  <Grid item xs={12}>
                    {filteredReschedules && (
                      <Widget
                        upperTitle
                        noBodyPadding
                        bodyClass={classes.tableWidget}
                      >
                        <CurrentAppointList
                          patientData={filteredReschedules}
                          selectAppointmentStatus="Scheduled"
                        />
                      </Widget>
                    )}
                  </Grid>
                )}
              </>
            )}
            {/*  */}
            {activeTab === 2 && (
              <>
                <Grid item xs={12}>
                  <div className={classes.pageTitleContainer}>
                    <Typography
                      className={classes.typo}
                      weight="medium"
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      Total Cancelled {count?.canceled ? count?.canceled : 0}
                    </Typography>
                    <div className={classes.groupBtn}>
                      <Icons.Apps
                        onClick={selectCard}
                        style={{ marginRight: "6px", cursor: "pointer" }}
                      />
                      <Icons.Dehaze
                        onClick={selectTable}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </Grid>

                {cardShow ? (
                  <>
                    {/* {patientData &&
                      patientData.map(
                        (item, index) =>
                          item.is_canceled && (
                            <Grid item key={index} md={3} sm={6} xs={12}>
                              <BigStat {...item} />
                            </Grid>
                          )
                      )} */}
                  </>
                ) : (
                  <Grid item xs={12}>
                    <Widget
                      upperTitle
                      noBodyPadding
                      bodyClass={classes.tableWidget}
                    >
                      <CurrentAppointList
                        patientData={filteredCancelled}
                        selectAppointmentStatus="Cancelled"
                      />
                    </Widget>
                  </Grid>
                )}
              </>
            )}
            {/*  */}
            {activeTab === 3 && (
              <>
                <Grid item xs={12}>
                  <div className={classes.pageTitleContainer}>
                    <Typography
                      className={classes.typo}
                      weight="medium"
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                    Total Available {count?.available ? count?.available : 0}
                    </Typography>
                    <div className={classes.groupBtn}>
                      <Icons.Apps
                        onClick={selectCard}
                        style={{ marginRight: "6px", cursor: "pointer" }}
                      />
                      <Icons.Dehaze
                        onClick={selectTable}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </Grid>

                {cardShow ? (
                  <>
                    {/* {patientData &&
                      patientData.map(
                        (item, index) =>
                          item.is_available && (
                            <Grid item key={index} md={3} sm={6} xs={12}>
                              <BigStat {...item} />
                            </Grid>
                          )
                      )} */}
                  </>
                ) : (
                  <Grid item xs={12}>
                    <Widget
                      upperTitle
                      noBodyPadding
                      bodyClass={classes.tableWidget}
                    >
                      <CurrentAppointList
                        patientData={filteredAvailabled}
                        selectAppointmentStatus="Scheduled"
                      />
                    </Widget>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        ))}
    </>
  );
}
