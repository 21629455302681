import React, { useState, useEffect } from "react";
import { Grid, Menu, MenuItem } from "@material-ui/core";

import { dateFormatConversion } from "../../hooks/helper";


import TableWidget from "../Widget/TableWidget";

import TableDesign from "../common/table/TableDesign";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
// styles
import useStyles from "./styles";
import { Button } from "@mui/material";
import ManageProblem from "./ManageProblem";
import { loadState } from "../../hooks/helper";
import { getProblemHistoryData } from "../../services/ServiceLayer";
import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";
import DeleteProblemPopup from "./DeleteProblemPopup";
import moment from "moment";
export default function Health({ examProblemStatus, patientId,setIsLoader }) {
  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Problem",
      align: "left",
    },
    {
      label: "Start Date",
      align: "left",
    },
    {
      label: "End Date",
      align: "left",
    },
    {
      label: "ICD Code",
      align: "left",
    },
    // {
    //   label: "Status",
    //   align: "left",
    // },
    {
      label: "Action",
    },
  ];
  // const { patientId } = props;
  // console.log("props", props);
  const [open, setOpen] = React.useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleDelete = () => {
    // setDeleteModalShow(true);
    setAnchorEl(null);
  };
  const [selectclient, setselectclient] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [selectRow, setSelectRow] = useState(null);
  const [patientAttachmentData, setPatientAttachmentData] = useState([]);
  const [title, setTitle] = useState(null);
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  var classes = useStyles();
  useEffect(() => {
    getProblemHistoryData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELPRB`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setIsLoader(false);
        setPatientAttachmentData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);
  useEffect(() => {
    getProblemHistoryData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELPRB`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setPatientAttachmentData(response?.data);
        setDataUpdated(false);
      } else {
        // setIsLoader(false);
      }
    });
    setDataUpdated(false);
  }, [dataUpdated]);
  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpen(false);
    setDeleteModalShow(false);
    setselectclient(null);
    setDataUpdated(true);
  };
  const deleteEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);
    setselectclient(rec);
    setDeleteModalShow(true);
    setTitle("Delete Problem");
  };
  const addProblemModel = () => {
    setOpen(true);
  };
  const handleEdit = (rec) => {
    setOpen(true);
    setAnchorEl(null);
    setselectclient(rec);
    setTitle("Edit Problem");
  };
  return (
    <>
      <ManageProblem
        open={open}
        onHide={hideHandle}
        title={selectclient ? "Update Problem":"Add Problem"}
        selectclient={selectclient}
        patientId={patientId}
        examProblemStatus={examProblemStatus}
      />
      <DeleteProblemPopup  onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        selectclient={selectclient}
        patientId={patientId}
        setDeleteModalShow={setDeleteModalShow} />
      <Grid container spacing={4} style={{ width: "100%" }}>
        <Grid>
          {" "}
          <div
            className={classes.pageTitleContainer}
            style={{ display: "flex", justifyContent: "end" }}
          >
             <Button
              style={{ 
                marginRight: "8px",
                fontSize: "10px",
                position: "absolute",
                borderRadius: "2px",
                right: "180px",
                background: "#4E97FD",
              }}
              classes={{ root: classes.button }}
              variant="contained"
              color="secondary"
              // onClick={addProblemModel}
            >
              AI Assistance
            </Button>
            <Button
              style={{
                marginRight: "8px",
                fontSize: "10px",
                position: "absolute",
                borderRadius: "2px",
                right: "60px",
                background: "#4E97FD",
              }}
              classes={{ root: classes.button }}
              variant="contained"
              color="secondary"
              onClick={addProblemModel}
            >
              Add Problem
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {patientAttachmentData && patientAttachmentData?.length ? (
                patientAttachmentData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.problem_desc}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {dateFormatConversion(row.st_date)}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {dateFormatConversion(row.end_date)}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.icd_10}
                        </TableCell>
                        {/* <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.status_val}
                        </TableCell> */}

                        <TableCell className={classes.tableBodyCell}>
                          {moment().format('YYYY-MM-DD') === moment(row.st_date).format('YYYY-MM-DD') && 
                           <IconButton
                           id="basic-button"
                           aria-controls={openDown ? "basic-menu" : undefined}
                           aria-haspopup="true"
                           aria-expanded={openDown ? "true" : undefined}
                           onClick={(e) => {
                             handleClick(e);
                             setSelectRow(row);
                           }}
                         >
                           <MoreIcon />
                         </IconButton>
                          }
                         
                          <Menu
                            className={classes.menuBox}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem onClick={()=>handleEdit(selectRow)}>Edit</MenuItem>

                            <MenuItem onClick={() => deleteEventHandler(selectRow)}>Delete</MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
