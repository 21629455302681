import React, { useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
// components
import { ArrowDropDown } from "@material-ui/icons";

import Primary from "./InsuranceTab/Primary";
// styles
import useStyles from "./styles";
import Tertiary from "./InsuranceTab/Tertiary";
import Secondary from "./InsuranceTab/Secondary";
export default function Insurance({
  patientInsuranceData,
  setPatientInsuranceData,
  insuranceValues,
  additionalInsuranceValues,
  setSubscribePatient,
  subscribePatient,
  setSubscribeVIPatient,
  subscribeVIPatient,
  setSubscribeADIPatient,
  subscribeADIPatient,
}) {
  var classes = useStyles();

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Medical Insurance</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Primary
            setSubscribePatient={setSubscribePatient}
            subscribePatient={subscribePatient}
            patientInsuranceData={patientInsuranceData}
            setPatientInsuranceData={setPatientInsuranceData}
            insuranceValues={insuranceValues}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Vision Insurance</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Secondary
            subscribeVIPatient={subscribeVIPatient}
            setSubscribeVIPatient={setSubscribeVIPatient}
            patientInsuranceData={patientInsuranceData}
            setPatientInsuranceData={setPatientInsuranceData}
            insuranceValues={insuranceValues}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Additional Insurance</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Tertiary
          setSubscribeADIPatient={setSubscribeADIPatient} subscribeADIPatient={subscribeADIPatient}
            patientInsuranceData={patientInsuranceData}
            setPatientInsuranceData={setPatientInsuranceData}
            additionalInsuranceValues={additionalInsuranceValues}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
