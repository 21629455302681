import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  Switch,
  TableRow,
  TableCell,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../common/modal/Popup";
// styles
import useStyles from "../styles";

import moment from "moment";
import {
  currentUser,
  handleAddressKeyPress,
  handleAddressKeyPressPaste,
  loadState,
} from "../../../hooks/helper";
import {
  getExamProblemData,
  postPatientDisclosure,
} from "../../../services/ServiceLayer";
import { toast } from "react-toastify";
import TableWidget from "../../Widget/TableWidget";
import TableDesign from "../../common/table/TableDesign";
import ManageDiagnosis from "./ManageDiagnosis";

export default function SearchDiagnosis(props) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  //
  const formElements = [
    {
      key: "icd_code",
      label: "Enter ICD code or description",
      placeHolder: "text",
      type: "text",

      mdSize: "6",
    },

    // {
    //   value: "btn1",
    //   btnName: "Search",
    //   type: "button",
    //   mdSize: "3",
    //   btnClick: "",
    // },
  ];
  console.log("props-24", props);
  const { selectclient, patientId, isUpdated, setIsUpdated } = props;
  const userData = loadState().user;
  const userRole = loadState().user.user_role;

  console.log("selectclient", selectclient);

  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");

  const [problemList, setProblemList] = useState([]);
  useEffect(() => {
    getExamProblemData(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELPRB`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setProblemList(response?.data);
      } else {
      }
    });
  }, []);

  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
  };

  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "ICD Code",
      align: "left",
    },
    {
      label: "Description",
      align: "left",
    },
    {
      label: "Action",
      align: "left",
    },
  ];
  const [searchQuery, setSearchQuery] = useState("");

  const filteredProblemList = problemList.filter((row) => {
    return (
      row.icd_10.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.problem_desc.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });
  const [selectedRow, setSelectedRow] = useState(null);
  const [showDiagnosis, setShowDiagnosis] = useState(false);
  const handleRowSelect = (row) => {
    setSelectedRow(row);
    setShowDiagnosis(true);
    props.onHide();
  };
const hideHandle=()=>{
  setShowDiagnosis(false);
}
  const { onHide, ...props1 } = props;
  return (
    <>
      <ManageDiagnosis
        open={showDiagnosis}
        onHide={hideHandle}
        setModalShow={showDiagnosis}
        title={"Add Diagnosis"}
        selectclient={selectedRow}
        patientId={patientId}
        problemList={problemList}
        isUpdated={isUpdated}
        setIsUpdated= {setIsUpdated}
      />
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={2}
              className={classes.formBox}
              // style={{ marginBottom: "4px" }}
            >
              {formElements.map((element, i) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={classes.textGroup}
                    >
                      {element.label && (
                        <Typography
                          style={{ fontSize: "13px" }}
                          className={classes.formHeading}
                        >
                          {element.label}{" "}
                          {element.required === "true" ? (
                            <span className={classes.required}>*</span>
                          ) : null}
                        </Typography>
                      )}
                      {element.type ? (
                        <>
                          {element.type === "text" ? (
                            <TextField
                              required
                              name={element.key}
                              placeholder="Search by ICD Code or Description"
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                                maxLength: element.maxLength,
                                onKeyPress: element.onKeyPress,
                                onPaste: element.onpaste,
                              }}
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                            />
                          ) : element.type === "button" ? (
                            <Button
                              classes={{ root: classes.button }}
                              variant="contained"
                              color="secondary"
                              style={{
                                width: "100%",
                                fontSize: "10px",
                                marginTop: "26px",
                              }}
                            >
                              {element.btnName}
                            </Button>
                          ) : (
                            <Switch />
                          )}
                        </>
                      ) : (
                        <>
                          <Typography
                            className={classes.formHeading}
                            style={{
                              fontWeight: "600",

                              color: "#4E97FD",
                            }}
                          >
                            {element.title}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </>
                );
              })}
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <TableWidget noBodyPadding>
                  <TableDesign
                    {...{
                      title: "List of All Clients",
                      tableHeadings,
                      height: "40px",
                      fontSize: "10px",
                    }}
                  >
                    {filteredProblemList && filteredProblemList?.length ? (
                      filteredProblemList.map((row, i) => {
                        if (row)
                          return (
                            <TableRow key={i} className={classes.tableBodyCell}>
                              <TableCell className={classes.tableBodyCell}>
                                {i + 1}
                              </TableCell>

                              <TableCell
                                align="left"
                                style={{
                                  cursor: "pointer",
                                  textAlign: "left",
                                  paddingLeft: "10px",
                                }}
                                className={classes.tableBodyCell}
                              >
                                {row.icd_10}
                              </TableCell>

                              <TableCell
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "10px",
                                }}
                                className={classes.tableBodyCell}
                              >
                                {row.problem_desc}
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "10px",
                                }}
                                className={classes.tableBodyCell}
                              >
                                <Button
                                  onClick={() => handleRowSelect(row)}
                                  style={{
                                    fontSize: "10px",
                                    borderRadius: "25px",
                                  }}
                                  className={classes.outlineBtn}
                                  variant="outlined"
                                  size="small"
                                >
                                  Select
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan="8">No Result Found</TableCell>
                      </TableRow>
                    )}
                  </TableDesign>
                </TableWidget>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
