import { Typography } from "@material-ui/core";

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { loadState } from "../../hooks/helper";
import { getPatient } from "../../services/ServiceLayer";

export default function PatientName() {
  const { patient_id } = useParams();
  console.log("patient_id", patient_id);
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [patientData, setPatientData] = useState([]);
  useEffect(() => {
    // patient details
    if (patient_id) {
      getPatient(
        `patient_id=${patient_id}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELHIS`
      ).then((response) => {
        console.log(response);
        setPatientData(response?.data);
      });
    }
  }, [patient_id]);
  return (
    <>
      <Typography fontSize="xl" fontWeight="lg">
        {patientData?.first_name} {patientData?.last_name}
      </Typography>
      <Typography level="body-sm" fontWeight="lg" textColor="text.tertiary">
        {patientData?.email}
      </Typography>
    </>
  );
}
