import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";

import PatientSearchResult from "../../../../hooks/PatientSearch";

import moment from "moment";
import useStyles from "../../Detail/Case/InsuranceTab/styles";
import {
  formSecondaryElements,
  formSubscribePrimaryElements,
} from "./Patient_Constant";
import {postFlagBooleanVariable, ssnFormat} from '../../../../hooks/helper'
export default function Secondary({
  patientInsuranceData,
  setPatientInsuranceData,
  insuranceValues,setSubscribeVIPatient,subscribeVIPatient
}) {
  const [checked, setChecked] = useState(true);
  const [selectclient, setselectclient] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = useState(null);
  const [patientData, setPatientData] = useState(null);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const onchangeFamily = () => {
    alert("Family");
  };
  const addFileLink = () => {
    setOpen(true);
    setModalShow(true);
    setTitle("Add Family Subscribed");
  };

  const selectedPatient = (data) => {
    setOpen(false);
    if (data) {
      console.log("Selected Patinet Data " + data[0]);
      setPatientData(data[0]);
      console.log("Selected Patinet Data " + data[0].patient_id);

      setSubscribeVIPatient({
        ...subscribeVIPatient,
        pr_subscriber_name: data[0]?.first_name,
        pr_patient_id: data[0]?.patient_id,
        pr_middle_name: data[0]?.middle_name,
        pr_last_name: data[0]?.last_name,
        pr_dateofbirth: data[0]?.dateofbirth,
        pr_ssn: data[0]?.ssn,
        pr_sex: data[0]?.gender,
        pr_subscribe_phone: data[0]?.contact_Cell,
        pr_office_phone: data[0]?.contact_office,
        subscribe_employeer: null,
        pr_address1: data[0]?.billing_address_line1,
        pr_address2: data[0]?.billing_address_line1,
        pr_city: data[0]?.billing_city,
        pr_state: data[0]?.billing_state,
        pr_zip: data[0]?.billing_zip,
        pr_emp_address1: data[0]?.shipping_address_line1,
        pr_emp_address2: data[0]?.shipping_address_line2,
        pr_emp_city: data[0]?.shipping_city,
        pr_emp_state: data[0]?.shipping_state,
        pr_emp_zip: data[0]?.shipping_zip,
      });
      setPatientInsuranceData({
        ...patientInsuranceData,
        vi_patient_parent_id:data[0]?.patient_id
      })
    }
    console.log("Selected Patinet Data " + patientData);
  };


  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();

  
  return (
    <>
      <PatientSearchResult
        open={open}
        onHide={(e) => {
          selectedPatient(e);
        }}
        title={title}
        selectclient={selectclient}
        setModalShow={setModalShow}
      />{" "}
      <Grid container spacing={2} className={classes.formBox}>
        {formSecondaryElements.map((element, i) => {
          return (
            <>
              <Grid
                item
                xs={12}
                lg={2}
                sm={2}
                md={element.mdSize}
                className={classes.textGroup}
              >
                <Typography className={classes.formHeading}>
                  {element.label}{" "}
                  {element.required === "true" ? (
                    <span className={classes.required}>*</span>
                  ) : null}
                </Typography>

                {element.type === "select" ? (
                  <FormControl
                    fullWidth
                    className={classes.dropdownFormControl}
                  >
                    <Select
                      name={element.key}
                      className={classes.selectDropdown}
                      placeholder={element.placeHolder}
                      value={
                        patientInsuranceData &&
                        patientInsuranceData[element.key] === null
                          ? ""
                          : patientInsuranceData &&
                            patientInsuranceData[element.key]
                      }
                      onChange={async (e) => {
                        setPatientInsuranceData({
                          ...patientInsuranceData,
                          [element.key]: e.target.value,
                        });
                      }}
                    >
                      <MenuItem disabled value="">
                        Select
                      </MenuItem>
                      {insuranceValues &&
                        insuranceValues.map((item) => (
                          <MenuItem
                            key={item.attribute_id}
                            value={item.attribute_id}
                          >
                            {item.attribute_val}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                ) : element.type === "checkbox" ? (
                  <Checkbox
                  value={element.key}
                  checked={
                    patientInsuranceData[element.key] !== "N"
                      ? patientInsuranceData[element.key]
                      : false
                  }
                  onChange={(e) => {
                    const value = postFlagBooleanVariable(e.target.checked);
                    if(element.key==='vi_self_subscribed' && value==='Y'){
                      setPatientInsuranceData({
                        ...patientInsuranceData,
                        [element.key]: value,
                        vi_family_subscribed: 'N'
                      });
                    }else if(element.key==='vi_family_subscribed' && value==='Y'){
                      setPatientInsuranceData({
                        ...patientInsuranceData,
                        [element.key]: value,
                        vi_self_subscribed: 'N'
                      });
                    }else{
                      setPatientInsuranceData({
                        ...patientInsuranceData,
                        [element.key]: value,
                      });
                    }
                    
                  }}
                />
                ) : element.type === "date" ||
                  element.type === "datetime-local" ? (
                  <TextField
                    id={element.key}
                    name={element.key}
                    placeholder={element.placeHolder}
                    type={element.type}
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      min: `${currentDateTime}`,
                      max: `${currentDate}`,
                      style: {
                        height: "12px",
                        fontSize: "12px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      patientInsuranceData && patientInsuranceData[element.key] === null
                        ? ""
                        : moment(
                          patientInsuranceData && patientInsuranceData[element.key]
                          ).format("YYYY-MM-DD")
                    }
                   
                    onChange={async (e) => {
                      setPatientInsuranceData({
                        ...patientInsuranceData,
                        [element.key]: e.target.value,
                      });
                    }}
                    onKeyDown={(e) => {
                      e.preventDefault(); // Prevent manual typing
                    }}
                  />
                ) : element.type === "textarea" ? (
                  <TextField
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    placeholder="Type here..."
                    variant="outlined"
                    multiline
                    rows={3}
                    inputProps={{
                      style: {
                        fontSize: "12px",
                      },
                      onPaste: element.onPaste,
                            onKeyPress: element.onKeyPress,
                    }}
                    value={
                      patientInsuranceData &&
                      patientInsuranceData[element.key] === null
                        ? ""
                        : patientInsuranceData &&
                          patientInsuranceData[element.key]
                    }
                    onChange={async (e) => {
                      setPatientInsuranceData({
                        ...patientInsuranceData,
                        [element.key]: e.target.value,
                      });
                    }}
                  />
                ) : element.type === "button" ? (
                  <Button
                    classes={{ root: classes.button }}
                    variant="contained"
                    color="secondary"
                    style={{
                      width: "100%",
                      fontSize: "10px",
                      marginTop: "26px",
                    }}
                  >
                    {element.btnName}
                  </Button>
                ) : (
                  <>
                    <TextField
                      required
                      name={element.key}
                      placeholder={element.placeHolder}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      inputProps={{
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                        maxLength: `${element.maxLength}`,
                        onPaste: element.onPaste,
                            onKeyPress: element.onKeyPress,
                            ...(element.key === "pr_ssn" && {
                              onInput: (e) => {
                                e.target.value = ssnFormat(e.target.value);
                              },
                            }),
                      }}
                      value={
                        patientInsuranceData &&
                        patientInsuranceData[element.key] === null
                          ? ""
                          : patientInsuranceData &&
                            patientInsuranceData[element.key]
                      }
                      onChange={async (e) => {
                        setPatientInsuranceData({
                          ...patientInsuranceData,
                          [element.key]: e.target.value,
                        });
                      }}
                    />
                  </>
                )}
              </Grid>
            </>
          );
        })}
        
        {patientInsuranceData?.vi_family_subscribed==='Y' && (
          <>
            <Grid item xs={12} md={5} className={classes.textGroup}>
              <Button
                classes={{ root: classes.button }}
                variant="contained"
                color="secondary"
                style={{
                  padding: "4px 10px",
                  fontSize: "10px",
                  marginTop: "26px",
                }}
                onClick={addFileLink}
              >
                Search Family Subscribed
              </Button>
            </Grid>
            <>
              {formSubscribePrimaryElements.map((element, i) => {
                return (
                  <>
                    {/* {subscribePatient &&
                      subscribePatient.map((subscribePatient) => ( */}
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={classes.textGroup}
                    >
                      <Typography className={classes.formHeading}>
                        {element.label}{" "}
                        {element.required === "true" ? (
                          <span className={classes.required}>*</span>
                        ) : null}
                      </Typography>

                      {element.type === "select" ? (
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            name={element.key}
                            className={classes.selectDropdown}
                            placeholder={element.placeHolder}
                            value={
                              subscribeVIPatient &&
                              subscribeVIPatient[element.key] === null
                                ? ""
                                : subscribeVIPatient &&
                                subscribeVIPatient[element.key]
                            }
                            onChange={async (e) => {
                              setSubscribeVIPatient({
                                ...subscribeVIPatient,
                                [element.key]: e.target.value,
                              });
                            }}
                          >
                            <MenuItem value="">Select</MenuItem>
                            {element.options.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : element.type === "text" ? (
                        <TextField
                          required
                          name={element.key}
                          placeholder={element.placeHolder}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                            maxLength: `${element.maxLength}`,
                            onPaste: element.onPaste,
                            onKeyPress: element.onKeyPress,
                            ...(element.key === "pr_ssn" && {
                              onInput: (e) => {
                                e.target.value = ssnFormat(e.target.value);
                              },
                            }),
                          }}
                          value={
                            subscribeVIPatient &&
                            subscribeVIPatient[element.key] === null
                              ? ""
                              : subscribeVIPatient &&
                              subscribeVIPatient[element.key]
                          }
                          onChange={async (e) => {
                            setSubscribeVIPatient({
                              ...subscribeVIPatient,
                              [element.key]: e.target.value,
                            });
                          }}
                        />
                      ) : element.type === "date" ||
                        element.type === "datetime-local" ? (
                        <TextField
                          id={element.key}
                          name={element.key}
                          placeholder={element.placeHolder}
                          type={element.type}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            min: `${currentDateTime}`,
                            max: `${currentDate}`,
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={
                            subscribeVIPatient && subscribeVIPatient[element.key] === null
                              ? ""
                              : moment(
                                subscribeVIPatient && subscribeVIPatient[element.key]
                                ).format("YYYY-MM-DD")
                          }
                          
                          onChange={async (e) => {
                            setSubscribeVIPatient({
                              ...subscribeVIPatient,
                              [element.key]: e.target.value,
                            });
                          }}
                        />
                      ) : element.type === "textarea" ? (
                        <TextField
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          placeholder="Type here..."
                          variant="outlined"
                          multiline
                          rows={3}
                          inputProps={{
                            style: {
                              fontSize: "12px",
                            },
                            onPaste: element.onPaste,
                            onKeyPress: element.onKeyPress,
                          }}
                          value={
                            subscribeVIPatient &&
                            subscribeVIPatient[element.key] === null
                              ? ""
                              : subscribeVIPatient &&
                              subscribeVIPatient[element.key]
                          }
                          onChange={async (e) => {
                            setSubscribeVIPatient({
                              ...subscribeVIPatient,
                              [element.key]: e.target.value,
                            });
                          }}
                        />
                      ) : element.type === "button" ? (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                          style={{
                            width: "100%",
                            fontSize: "10px",
                            marginTop: "26px",
                          }}
                        >
                          {element.btnName}
                        </Button>
                      ) : (
                        <Checkbox
                          value={element.key}
                          onChange={element.onChange}
                        />
                      )}
                    </Grid>
                    {/* ))
                     }  */}
                  </>
                );
              })}
            </>
          </>
        ) }
      </Grid>
    </>
  );
}
