import React, { useEffect, useState } from "react";

import {
  Grid,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Select,
  FormControl,
  FormGroup,
  Checkbox,
  Button,
  IconButton,
  Menu,
} from "@material-ui/core";
// data

// Model
import { pachymetryForm } from "./External_Constant";
import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";
import useStyles from "../styles";
// components

import moment from "moment";
import { getExamProblemData, getPatientExternalPch, postPatientExternalpch } from "../../../services/ServiceLayer";
import { currentUser, loadState } from "../../../hooks/helper";
import { Autocomplete } from "@mui/material";
import { toast } from "react-toastify";
export default function Pachymetry({
  patientId,
  setPachmetryBtnUnable,
  pachymetryBtnUnable,
}) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [pachymetryDetails, setPachymetryDetails] = useState({
    pat_extpch_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    inp_pachy_microns_od: "",
    pachy_testdt_od: "",
    pachy_notes_od: "",
    pachy_icd_od: "",
    pachy_microns_os: "",
    pachy_testdt_os: "",
    pachy_notes_os: "",
    pachy_icd_os: "",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: "ADDEXT",
  });
  const handleCheckboxChange = (copy, paste, e) => {
    console.log("copy", copy);
    console.log("copy to", paste);
    setAnchorEl(null);
    setPachmetryBtnUnable(true);
    setPachymetryDetails({
      ...pachymetryDetails,
      [`inp_pachy_microns_${paste}`]:
        pachymetryDetails?.[`inp_pachy_microns_${copy}`],
      [`pachy_testdt_${paste}`]: pachymetryDetails?.[`pachy_testdt_${copy}`],
      [`pachy_notes_${paste}`]: pachymetryDetails?.[`pachy_notes_${copy}`],
      [`pachy_icd_${paste}`]: pachymetryDetails?.[`pachy_icd_${copy}`],
      [`pachy_microns_${paste}`]: pachymetryDetails?.[`pachy_microns_${copy}`],
      [`pachy_testdt_${paste}`]: pachymetryDetails?.[`pachy_testdt_${copy}`],
      [`pachy_notes_${paste}`]: pachymetryDetails?.[`pachy_notes_${copy}`],
      [`pachy_icd_${paste}`]: pachymetryDetails?.[`pachy_icd_${copy}`],
    });
  };
  const [selectedProblem, setSelectedProblem] = useState(null);

  const [options, setOptions] = useState([]);
  const [problemList, setProblemList] = useState([]);
  useEffect(() => {
    getExamProblemData(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELPRB`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setProblemList(response?.data);
        setOptions(response?.data);
      } else {
      }
    });
  }, []);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    // if (!isError() && error !== null && isActionCalled) {
    if (isActionCalled) {
      postPatientExternalpch(pachymetryDetails).then((response) => {
        console.log(response);
        if (response?.status?.status) {
          toast.success("Pachymetry added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setPachmetryBtnUnable(false);
          // props.onHide();
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    

    setIsActionCalled(false);
  }, [isActionCalled]);
  const optionsMap = {
    pachy_icd_od: problemList,
    pachy_icd_os: problemList,
   
  };
  const[showDiscard, setShowDiscard] = useState(false);
  const handleAutocompleteChange = (key, newValue) => {
    setPachymetryDetails((prev) => ({
      ...prev,
      [key]: newValue ? newValue.problem_id : null,
    }));
    setPachmetryBtnUnable(true);
    setShowDiscard(true);
  };

  const [pachData, setPachData] = useState([]);
  useEffect(() => {
    getPatientExternalPch(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELEXT`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setPachData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);
  const [discardUpdate, setDiscardUpdate] = useState(false);
  useEffect(() => {
    if (pachData && pachData.length > 0) {
      setPachymetryDetails({
        pat_extpch_id: pachData[0]?.pat_extpch_id,
    patient_id: patientId,
    location_id: userData?.location_id,
    inp_pachy_microns_od: pachData[0]?.pachy_microns_od,
    pachy_testdt_od: pachData[0]?.pachy_testdt_od,
    pachy_notes_od: pachData[0]?.pachy_notes_od,
    pachy_icd_od: pachData[0]?.pachy_icd_od,
    pachy_microns_os: pachData[0]?.pachy_microns_os,
    pachy_testdt_os: pachData[0]?.pachy_testdt_os,
    pachy_notes_os: pachData[0]?.pachy_notes_os,
    pachy_icd_os: pachData[0]?.pachy_icd_os,
    updated_by: currentUser(),
    access_location: "access_location",
        opt_type: "UPDEXT",
      });
      setDiscardUpdate(false);
    }
  }, [pachData,discardUpdate]);
  return (
    <>
      {/* <TableWidget noBodyPadding> */}
      <Grid
        container
        spacing={2}
        className={classes.formBox}
        style={{ border: "1px solid #DED9D9" }}
      >
        <Grid
          item
          xs={12}
          md={12}
          className={classes.textGroup}
          style={{ padding: "0px", display: "flex", justifyContent: "end" }}
        >
          <IconButton
            id="basic-button"
            aria-controls={openDown ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openDown ? "true" : undefined}
            onClick={(e) => {
              handleClick(e);
            }}
          >
            <MoreIcon />
          </IconButton>

          <Menu
            style={{ fontSize: "12px" }}
            className={classes.menuBox}
            id="basic-menu"
            anchorEl={anchorEl}
            open={openDown}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              style={{ fontSize: "12px" }}
              onClick={() => handleCheckboxChange("od", "os")}
            >
              Copy OD to OS
            </MenuItem>

            <MenuItem
              style={{ fontSize: "12px" }}
              onClick={() => handleCheckboxChange("os", "od")}
            >
              Copy OS to OD
            </MenuItem>
          </Menu>
        </Grid>
        {pachymetryForm.map((element, i) => {
          return (
            <>
              <Grid
                item
                xs={12}
                md={element.mdSize}
                className={classes.textGroup}
                // style={{ border: "1px solid #DED9D9" }}
              >
                {element.label && (
                  <Typography
                    className={classes.formHeading}
                    style={{
                      fontWeight: "500",
                      color: "#4E97FD",
                      marginTop: "0px",
                    }}
                  >
                    {element.label}{" "}
                    {element.required === "true" ? (
                      <span className={classes.required}>*</span>
                    ) : null}
                    {element.copyBtn === "true" && (
                      <Button
                        style={{
                          float: "right",
                          position: "relative",
                          fontSize: "10px",
                          // top: "20px",
                        }}
                        classes={{ root: classes.button }}
                        variant="contained"
                        color="secondary"
                        // onClick={duplicateValue}
                      >
                        {element.btnLabel}
                      </Button>
                    )}
                  </Typography>
                )}

                {element.type ? (
                  <>
                    {element.type === "select" && (element.key === "pachy_icd_od" || element.key === "pachy_icd_os") ? (
                      <>
                         <Autocomplete style={{marginTop:'6px'}}
                      options={optionsMap[element.key] || []}
                      getOptionLabel={(option) => option.icd_10 || ""}
                      value={
                        (optionsMap[element.key] || []).find(
                          (option) =>
                            option.problem_id === pachymetryDetails[element.key]
                        ) || null
                      }
                      onChange={(e, newValue) =>
                        handleAutocompleteChange(element.key, newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // label={element.label}
                          variant="outlined"
                          fullWidth
                          // InputProps={{
                          //   ...params.InputProps,
                          //   style: {
                          //     height: '36px',
                          //     padding: '10 14px',
                          //   },
                          // }}
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              height: 'auto', // Adjust if you need to change the height of the inner input element
                              padding: '0 14px', 
                              
                            },
                          }}
                        />
                      )}
                    />
                      </>
                    ) : element.type === "select" ? (
                      <FormControl
                        fullWidth
                        className={classes.dropdownFormControl}
                      >
                        <Select
                          name={element.key}
                          className={classes.selectDropdown}
                          placeholder={element.placeHolder}
                        >
                          <MenuItem value="">Select</MenuItem>
                          {element.options.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : element.type === "text" ? (
                      <TextField
                        required
                        name={element.key}
                        placeholder={element.placeHolder}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                        }}
                        value={
                          pachymetryDetails && pachymetryDetails[element.key]
                            ? pachymetryDetails && pachymetryDetails[element.key]
                            : ""
                        }
                        onChange={(e) => {
                          console.log("selected", e.target.value);

                          setPachymetryDetails({
                            ...pachymetryDetails,
                            [element.key]: e.target.value,
                          });
                          setPachmetryBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                    ) : element.type === "date" ||
                      element.type === "datetime-local" ? (
                      <TextField
                        id={element.key}
                        name={element.key}
                        placeholder={element.placeHolder}
                        type={element.type}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          min: `${currentDateTime}`,
                          max: `${currentDate}`,
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={
                          pachymetryDetails && pachymetryDetails[element.key]
                            ? pachymetryDetails && moment(pachymetryDetails[element.key]).format('YYYY-MM-DD')
                            : ""
                        }
                        onChange={(e) => {
                          console.log("selected", e.target.value);

                          setPachymetryDetails({
                            ...pachymetryDetails,
                            [element.key]: e.target.value,
                          });
                          setPachmetryBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                    ) : element.type === "radio" ? (
                      <RadioGroup row defaultValue="">
                        {element.radioValue.map((item) => (
                          <FormControlLabel
                            size="small"
                            style={{ padding: "0px 6px" }}
                            value={item}
                            control={<Radio />}
                            label={item}
                            className={classes.smallRadioButton}
                          />
                        ))}
                      </RadioGroup>
                    ) : element.type === "checkbox" ? (
                      <>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              value={element.value}
                              control={<Checkbox />}
                              label={element.lbltitle}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </FormControl>
                      </>
                    ) : (
                      <TextField
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        placeholder="Type here..."
                        variant="outlined"
                        multiline
                        rows={3}
                        inputProps={{
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        value={
                          pachymetryDetails && pachymetryDetails[element.key]
                            ? pachymetryDetails && pachymetryDetails[element.key]
                            : ""
                        }
                        onChange={(e) => {
                          console.log("selected", e.target.value);

                          setPachymetryDetails({
                            ...pachymetryDetails,
                            [element.key]: e.target.value,
                          });
                          setPachmetryBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Typography
                      className={classes.formHeading}
                      style={{
                        fontWeight: "600",

                        color: "#4E97FD",
                      }}
                    >
                      {element.title}
                    </Typography>
                  </>
                )}
              </Grid>
            </>
          );
        })}
        <Grid className={classes.btnGroup} item xs={12}>
          <Button
            disabled={!pachymetryBtnUnable}
            style={{ display: "flex", float: "right" }}
            onClick={handleSubmit}
            className={classes.btnlRightDark}
            variant="contained"
            size="medium"
            color="secondary"
          >
            Save
          </Button>
        </Grid>
      </Grid>

      {/* </TableWidget> */}
    </>
  );
}
