import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";

import useStyles from "../styles";
import { formRefractiveElements } from "./Refractive_Constant";

import { postRefractiveData } from "../../../services/ServiceLayer";
import { toast } from "react-toastify";
import { currentUser, loadState } from "../../../hooks/helper";
import useListData from "../../common/allMetaData/useListData";

export default function Crx({
  crxListData,
  patientId,
  setResetValue,
  crxDetails,
  setCrxDetails,
  setCrxBtnUnable,
  crxBtnUnable,
  examBase,
}) {
  var classes = useStyles();
  const listData = useListData();

  const { examCurrentAcuity } = listData;
  const [isActionCalled, setIsActionCalled] = useState(false);
  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    if (isActionCalled) {
      postRefractiveData(crxDetails).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Crx added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setCrxBtnUnable(true);
          setIsActionCalled(false);
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    setIsActionCalled(false);
  }, [isActionCalled]);
  const handleCheckboxChange = (element, e) => {
    const selectedValue = e.target.checked ? "Y" : "";

    if (element.key === "balance_od" && selectedValue === "Y") {
      // If balance_od is checked, copy OS field values to OD fields
      setCrxDetails({
        ...crxDetails,
        balance_os: "N",
        balance_od: selectedValue,
        sphere_od: crxDetails?.sphere_os,
        cyl_od: crxDetails?.cyl_os,
        axis_od: crxDetails?.axis_os,
        add_od: crxDetails?.add_os,
        hprism_od: crxDetails?.hprism_os,
        hbase_od: crxDetails?.hbase_os,
        vprism_od: crxDetails?.vprism_od,
        vbase_od: crxDetails?.vbase_od,
        dva_od: crxDetails?.dva_od,
        nva_od: crxDetails?.nva_od,
        oudva_od: crxDetails?.oudva_od,
        ounva_od: crxDetails?.ounva_od,
      });
    } else if (element.key === "balance_os" && selectedValue === "Y") {
      // If balance_os is checked, copy OD field values to OS fields
      setCrxDetails({
        ...crxDetails,
        balance_od: "N",
        balance_os: selectedValue,
        sphere_os: crxDetails?.sphere_od,
        cyl_os: crxDetails?.cyl_od,
        axis_os: crxDetails?.axis_od,
        add_os: crxDetails?.add_od,
        hprism_os: crxDetails?.hprism_od,
        hbase_os: crxDetails?.hbase_od,
        vprism_os: crxDetails?.vprism_od,
        vbase_os: crxDetails?.vbase_od,
        dva_os: crxDetails?.dva_od,
        nva_os: crxDetails?.nva_od,
        oudva_os: crxDetails?.oudva_od,
        ounva_os: crxDetails?.ounva_od,
      });
    } else {
      // If neither balance_od nor balance_os is checked, update the checkbox state only
      setCrxDetails({
        ...crxDetails,
        [element.key]: selectedValue,
      });
    }
  };
  const userData = loadState().user;
  const [showDiscard, setShowDiscard] = useState(false);
  const clearForm = () => {
    setCrxDetails({
      pat_rfr_id: 0,
      patient_id: patientId,
      location_id: userData?.location_id,
      balance_od: "",
      sphere_od: "",
      cyl_od: "",
      axis_od: "",
      add_od: "",
      hprism_od: "",
      hbase_od: "",
      vprism_od: "",
      vbase_od: "",
      dva_od: "",
      nva_od: "",
      oudva_od: "",
      ounva_od: "",
      balance_os: "",
      sphere_os: "",
      cyl_os: "",
      axis_os: "",
      add_os: "",
      hprism_os: "",
      hbase_os: "",
      vprism_os: "",
      vbase_os: "",
      dva_os: "",
      nva_os: "",
      oudva_os: "",
      ounva_os: "",
      updated_by: currentUser(),
      access_location: "ggg",
      opt_type: "ADDRFC",
    });
  };
  const discardHandler = () => {
    if (crxListData && crxListData.length > 0) {
      setCrxBtnUnable(false);

      setResetValue(true);
    } else {
      clearForm();
      setCrxBtnUnable(false);
    }
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        className={classes.formBox}
        style={{ display: "flex", justifyContent: "end" }}
      >
        {formRefractiveElements.map((element, i) => {
          return (
            <Grid
              item
              xs={12}
              md={element.mdSize}
              className={`${classes.textGroup} ${
                element.title ? classes.highlightedItem : ""
              }`}
              style={{ paddingBottom:'0px',paddingTop:'0px' }}
            >
              {element.label && (
                <Typography
                  className={classes.formHeading}
                  style={{
                    fontWeight: "500",
                    color: "#4E97FD",
                    position: "relative",
                  }}
                >
                  {element.label}&nbsp;
                  {element.required === "true" ? (
                    <span className={classes.required}>*</span>
                  ) : null}
                  {element.copyBtn === "true" && (
                    <Button
                      style={{
                        float: "right",
                        position: "absolute",
                        top: "20px",
                      }}
                      classes={{ root: classes.button }}
                      variant="contained"
                      color="secondary"
                      // onClick={duplicateValue}
                    >
                      =
                    </Button>
                  )}
                </Typography>
              )}
              {element.type ? (
                <>
                  {element.type === "select" &&
                  element.key !== "procedure_code" ? (
                    <FormControl
                      fullWidth
                      className={classes.dropdownFormControl}
                    >
                      <Select
                        style={{ fontSize: "12px" }}
                        name={element.key}
                        className={classes.selectDropdown}
                        placeholder={element.placeHolder}
                        value={
                          crxDetails && crxDetails[element.key]
                            ? crxDetails && crxDetails[element.key]
                            : ""
                        }
                        onChange={(e) => {
                          console.log("selected");
                          setCrxDetails({
                            ...crxDetails,
                            [element.key]: e.target.value,
                          });
                          setCrxBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      >
                        <MenuItem disabled value="">
                          Select
                        </MenuItem>
                        {(element.key === "hbase_od" ||
                          element.key === "vbase_od" ||
                          element.key === "hbase_os" ||
                          element.key === "vbase_os" ||
                          element.key === "dva_od" ||
                          element.key === "dva_os" ||
                          element.key === "nva_od" ||
                          element.key === "nva_os" ||
                          element.key === "oudva_od" ||
                          element.key === "ounva_od") &&
                          examCurrentAcuity
                          ? examCurrentAcuity.map((item) => (
                              <MenuItem
                                key={item.attribute_id}
                                value={item.attribute_id}
                              >
                                {item.attribute_val}
                              </MenuItem>
                            ))
                          : element.options.map((item) => (
                              <MenuItem value={item.replace("20/", "").replace("+", "")}>
                                {item}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  ) : element.type === "text" ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {element.txtLabel && (
                        <span style={{ fontWeight: "500", fontSize: "10px" }}>
                          {element.txtLabel}
                        </span>
                      )}
                      <TextField
                        required
                        name={element.key}
                        placeholder={element.placeHolder}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                          maxLength: element.maxLength,
                          onKeyPress: element.onKeyPress,
                        }}
                        value={
                          crxDetails && crxDetails[element.key]
                            ? crxDetails && crxDetails[element.key]
                            : ""
                        }
                        onChange={(e) => {
                          console.log("selected");
                          setCrxDetails({
                            ...crxDetails,
                            [element.key]: e.target.value,
                          });
                          setCrxBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                      {element.key === "sku" && (
                        <Button
                          // onClick={selectFrameBySku}
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                        >
                          Slect
                        </Button>
                      )}
                    </div>
                  ) : element.type === "commission_amount" ? (
                    <TextField
                      type="text"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      size="small"
                      inputProps={{
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                        maxLength: element.maxLength,
                        onKeyPress: element.onKeyPress,
                      }}
                    />
                  ) : element.type === "date" ||
                    element.type === "datetime-local" ? (
                    <TextField
                      id={element.key}
                      name={element.key}
                      placeholder={element.placeHolder}
                      type={element.type}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                      }}
                    />
                  ) : element.type === "radio" ? (
                    <RadioGroup row defaultValue="">
                      {element.radioValue.map((item) => (
                        <FormControlLabel
                          size="small"
                          style={{ padding: "0px 6px" }}
                          value={item}
                          control={<Radio />}
                          label={item}
                          className={classes.smallRadioButton}
                        />
                      ))}
                    </RadioGroup>
                  ) : element.type === "checkbox" ? (
                    <>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value={element.value}
                            control={
                              <Checkbox
                                checked={
                                  crxDetails[element.key] &&
                                  crxDetails[element.key] === "Y"
                                }
                                onChange={(e) => {
                                  handleCheckboxChange(element, e);
                                  setCrxBtnUnable(true);
                                  setShowDiscard(true);
                                }}
                              />
                            }
                            label={element.lbltitle}
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </FormControl>
                    </>
                  ) : element.type === "button" &&
                    element?.btnlbl === "Lookup" ? (
                    <>
                      <Button
                        classes={{ root: classes.button }}
                        variant="contained"
                        color="secondary"
                        // onClick={selectPrevFrame}
                      >
                        {element.btnlbl}
                      </Button>
                    </>
                  ) : element.type === "button" &&
                    element?.btnlbl === "Update" ? (
                    <>
                      {/* {selectedRow && (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                        //   onClick={updateSelectFrame}
                        >
                          {element.btnlbl}
                        </Button>
                      )} */}
                    </>
                  ) : (
                    <TextField
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      placeholder="Type here..."
                      variant="outlined"
                      multiline
                      rows={3}
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <Typography
                    className={classes.formHeading}
                    style={{
                      fontWeight: "600",
                      color: "#4E97FD",
                      position: 'absolute',
                      marginTop: i===0 ? '24px':'10px'
                    }}
                  >
                    {element.title}
                  </Typography>
                </>
              )}
            </Grid>
          );
        })}
        <Grid
          className={classes.btnGroup}
          item
          xs={12}
          style={{ display: "flex", justifyContent: "end" }}
        >
          {showDiscard && (
            <Button
              className={classes.btnleftOutline}
              variant="outlined"
              style={{ marginRight: "10px", fontSize: "12px" }}
              size="medium"
              onClick={discardHandler}
            >
              Discard
            </Button>
          )}
          <Button
            disabled={!crxBtnUnable}
            style={{ display: "flex", float: "right" }}
            onClick={handleSubmit}
            className={classes.btnlRightDark}
            variant="contained"
            size="medium"
            color="secondary"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
