import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import { TableRow, TableCell } from "@material-ui/core";
import TableDesign from "../../../common/table/TableDesign";
import Popup from "../../../common/modal/Popup";
import { getPatientsSearch } from "../../../../services/ServiceLayer";
// styles
import useStyles from "../styles";
import moment from "moment";
import { toast } from "react-toastify";
import { handleAddressKeyPress, handleAddressKeyPressPaste, loadState } from "../../../../hooks/helper";

export default function ManageFamily(props) {
  toast.configure();
  const userData = loadState().user;
  console.log("relTypeValues", props.relTypeValues);
  const tableHeadings = [
    {
      label: "S. No.",
    },
    {
      label: "First Name",
      align: "left !important",
      
    },
    {
      label: "Last Name",
      align: "left !important",
    },
    {
      label: "Phone",
    },
    {
      label: "DOB",
    },
  ];
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  //
  const formElements = [
    {
      key: "lastName",
      label: "Last Name",
      placeHolder: "Enter last name",
      type: "text",
      mdSize: "5",
      onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
    },
    {
      key: "phone",
      label: "Mobile Number",
      placeHolder: "Enter Mobile name",
      type: "text",
      mdSize: "5",
      onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
    },
  ];
  console.log("props-24", props);
  const { selectclient,patientId } = props;
  console.log("selectclient", selectclient);

  const [patientSearchList, setPatientSearchList] = useState(null);
  const [relationValue, setRelationValue] = useState(null);

  //
  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");

  const [searchField, setSearchField] = useState({
    lastName: "",
    phone: "",
  });
  const handelRelation = (event) => {
    setRelationValue(event.target.value);
  };
  const hideHandler = () => {
    console.log("hide handler called");
    setRelationValue(null);
    props.onHide();
  };

  const createUpdateClient = (patient) => {
    console.log("patient", patient);
    // props = patient
    props.onHide([patient, relationValue]);
    setRelationValue(null);
  };

  const searchClick = () => {
    getPatientsSearch(
      `contact_cell=${
        searchField?.phone === "" ? 0 : searchField?.phone
      }&location_id=${
        userData?.location_id === undefined || userData?.location_id === ""
          ? "0"
          : userData?.location_id}&lastName=${searchField?.lastName}`
    ).then((response) => {
      console.log(response);
      if (response?.status.description === "") {
        setPatientSearchList(response.data);
      } else {
        // alert(response?.status.description);
      }
    });
  };

  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid container spacing={1} style={{ marginBottom: "10px" }}>
              <Grid item xs={4} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  Relation
                </Typography>
              </Grid>
              <Grid item xs={5} className={classes.textGroup}>
                <FormControl fullWidth className={classes.dropdownFormControl}>
                  <Select
                    className={classes.selectNoTopDropdown}
                    value={relationValue}
                    onChange={handelRelation}
                  >
                    <MenuItem disabled value="">
                      Select
                    </MenuItem>
                    {props.relTypeValues &&
                      props.relTypeValues.map((item) => (
                        <MenuItem
                          key={item.attribute_id}
                          value={item.attribute_id}
                        >
                          {item.attribute_val}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {relationValue && (
              <>
                <Typography
                  className={classes.formHeading}
                  style={{
                    borderBottom: "1px solid #d0d0d0",
                    color: "#4E97FD",
                    margin: "6px 0px",
                  }}
                >
                  Search Patient for link
                </Typography>
                <Grid container spacing={1} style={{ margin: "4px 0px" }}>
                  {formElements.map((element, i) => {
                    return (
                      <>
                        <Grid
                          item
                          xs={12}
                          md={element.mdSize}
                          className={classes.textGroup}
                        >
                          <Typography className={classes.formHeading}>
                            {element.label}{" "}
                            {element.required === "true" ? (
                              <span className={classes.required}>*</span>
                            ) : null}
                          </Typography>

                          {element.type === "select" ? (
                            <FormControl
                              fullWidth
                              className={classes.dropdownFormControl}
                            >
                              <Select
                                name={element.key}
                                className={classes.selectDropdown}
                                placeholder={element.placeHolder}
                              >
                                <MenuItem value="">Select</MenuItem>
                                {element.options.map((item) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : element.type === "text" ? (
                            <TextField
                              required
                              name={element.key}
                              placeholder={element.placeHolder}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                                onPaste: element.onPaste,
                            // onKeyPress: element.onKeyPress,
                            onKeyPress: (e) => {
                              if (e.key === 'Enter') {
                                  e.preventDefault(); // Prevent default behavior of the Enter key
                                  searchClick(); // Call the search function
                              }
                          }
                              }}
                              onChange={(e) => {
                                console.log("selected");
                                setSearchField({
                                  ...searchField,
                                  [element.key]: e.target.value,
                                });
                                searchClick();
                              }}
                            />
                          ) : element.type === "date" ||
                            element.type === "datetime-local" ? (
                            <TextField
                              id={element.key}
                              name={element.key}
                              placeholder={element.placeHolder}
                              type={element.type}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                min: `${currentDateTime}`,
                                max: `${currentDate}`,
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onKeyDown={(e) => {
                                e.preventDefault(); // Prevent manual typing
                              }}
                            />
                          ) : element.type === "textarea" ? (
                            <TextField
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              placeholder="Type here..."
                              variant="outlined"
                              multiline
                              rows={3}
                              inputProps={{
                                style: {
                                  fontSize: "12px",
                                },
                                onPaste: element.onPaste,
                            onKeyPress: element.onKeyPress,
                              }}
                            />
                          ) : element.type === "button" ? (
                            <Button
                              classes={{ root: classes.button }}
                              variant="contained"
                              color="secondary"
                              style={{
                                width: "100%",
                                fontSize: "10px",
                                marginTop: "26px",
                              }}
                            >
                              {element.btnName}
                            </Button>
                          ) : (
                            <Checkbox />
                          )}
                        </Grid>
                      </>
                    );
                  })}
                  <Grid item xs={12} md={2} className={classes.textGroup}>
                    <Typography className={classes.formHeading}>
                      &nbsp;
                    </Typography>
                    <Button
                      variant="contained"
                      size="medium"
                      color="secondary"
                      style={{ marginTop: "6px" }}
                      onClick={searchClick}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
                <div style={{ marginTop: "10px", width: "inherit" }}>
                  <>
                    {patientSearchList ? (
                      <TableDesign
                        {...{
                          title: "List of All Clients",
                          tableHeadings,
                        }}
                      >
                        {patientSearchList && patientSearchList.length ? (
                          patientSearchList.map((row, i) => {
                            if (row && row.patient_id !== patientId)
                              return (
                                <TableRow
                                  onClick={() => createUpdateClient(row)}
                                  key={i}
                                  className={classes.tableBodyCell}
                                >
                                  <TableCell className={classes.tableBodyCell}>
                                    {i + 1}
                                  </TableCell>

                                  <TableCell
                                    align="left"
                                    className={classes.tableBodyCell}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {row.first_name}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className={classes.tableBodyCell}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {row.last_name}
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    style={{ cursor: "pointer" }}
                                    className={classes.tableBodyCell}
                                  >
                                    {row.contact_cell}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ cursor: "pointer" }}
                                    className={classes.tableBodyCell}
                                  >
                                    {moment(row.dateofbirth).utc().format('DD-MM-YYYY')}
                                  </TableCell>
                                </TableRow>
                              );
                          })
                        ) : (
                          <TableRow>
                            <TableCell colSpan="8">No Result Found</TableCell>
                          </TableRow>
                        )}
                      </TableDesign>
                    ) : (
                      ""
                    )}
                  </>
                </div>
              </>
            )}
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
