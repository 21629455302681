import { handleKeyPress,handleAddressKeyPress } from "../../../../../hooks/helper";
export const formProfileElements = [
  {
    key: "title",
    label: "Title",
    placeHolder: "Select",
    type: "select",
    options: ["Mr.", "Mrs.", "Ms.", "Dr.", "Rev."],
    mdSize: "2",
  },
  {
    key: "first_name",
    label: "First Name",
    placeHolder: "Enter First Name",
    type: "text",
    mdSize: "2",
    required: "true",
    maxLength: "30",
    onKeyPress:handleAddressKeyPress,

  },
  {
    key: "middle_name",
    label: "Middle Name",
    placeHolder: "Enter Middle Name",
    type: "text",
    mdSize: "2",
    maxLength: "30",
    onKeyPress:handleAddressKeyPress,
  },
  {
    key: "last_name",
    label: "Last Name",
    placeHolder: "Enter Last Name",
    type: "text",
    mdSize: "2",
    required: "true",
    maxLength: "30",
    onKeyPress:handleAddressKeyPress,
  },
  {
    key: "suffix",
    label: "Suffix",
    placeHolder: "Enter Suffix",
    type: "text",
    mdSize: "2",
    onKeyPress: handleKeyPress,

    maxLength: "5",
  },
  {
    key: "nick_name",
    label: "Nick Name",
    placeHolder: "Enter Nike Name",
    type: "text",
    mdSize: "2",
    maxLength: "30",
    onKeyPress:handleAddressKeyPress,
  },
  {
    key: "gender",
    label: "Gender",
    placeHolder: "Select",
    type: "select",
    options: ["Male", "Female", "Unknown"],
    mdSize: "2",
  },
  {
    key: "marital",
    label: "Marital",
    placeHolder: "Select",
    type: "select",
    options: [
      "Unassigned",
      "Married",
      "Single",
      "Divorced",
      "Widowed",
      "Seprated",
      "Domestic Partner",
    ],
    mdSize: "2",
  },
  {
    key: "dateofbirth",
    label: "Date of Birth",
    placeHolder: "Enter First Name",
    type: "date",
    mdSize: "2",
  },
  {
    key: "gender_identity",
    label: "Gender Identity",
    placeHolder: "select",
    type: "select",
    options: [
      "Male",
      "Female",
      "Transgender Female.",
      "Transgender (as non-binary)*",
      " Non-binary*",
      " Gender-queer.",
      "Two-spirit*",
      "Transgender Male",
      "Other",
      "Don't want to disclose",
    ],
    mdSize: "2",
  },
  {
    key: "orientation",
    label: "Sexual Orientation",
    placeHolder: "select",
    type: "select",
    options: [
      "Woman.",
      "Man.",
      "Transgender.",
      "Non-binary/non-conforming.",
      "Prefer not to respond.",
    ],
    mdSize: "2",
  },
  {
    key: "contact_cell",
    label: "Mobile Phone",
    placeHolder: "Enter Mobile Phone",
    type: "text",
    mdSize: "2",
    onKeyPress: handleKeyPress,

    maxLength: "10",
    required: "true",
  },
  {
    key: "contact_home",
    label: "Home Phone",
    placeHolder: "Enter Home Phone",
    type: "text",
    mdSize: "2",
    onKeyPress: handleKeyPress,
    maxLength: "10",
  },
  {
    key: "contact_office",
    label: "Office Phone-Extn.",
    placeHolder: "Enter Office Phone",
    type: "text",
    mdSize: "2",
    onKeyPress: handleKeyPress,
    maxLength: "10",
  },
  {
    key: "email",
    label: "Email Id",
    placeHolder: "Enter Email Id",
    type: "text",
    mdSize: "2",
    maxLength: "30",
    onKeyPress:handleAddressKeyPress,
  },
  // {
  //   key: "fax",
  //   label: "Fax",
  //   placeHolder: "Enter Fax",
  //   type: "text",
  //   mdSize: "2",
  //   maxLength: "10",
  // },
  {
    key: "pref_contact",
    label: "Preffered Contact",
    placeHolder: "select",
    type: "select",
    options: ["Home", "Cell"],
    mdSize: "2",
  },
  {
    key: "is_opt_email",
    label: "Opt Email",
    placeHolder: "select",
    type: "select",
    optionsYN: [
      { name: "Yes", value: "Y" },
      { name: "No", value: "N" },
    ],
    mdSize: "2",
  },
  {
    key: "is_opt_sms",
    label: "Opt SMS",
    placeHolder: "select",
    type: "select",
    optionsYN: [
      { name: "Yes", value: "Y" },
      { name: "No", value: "N" },
    ],
    mdSize: "2",
  },
  {
    key: "is_opt_call",
    label: "Opt Call",
    placeHolder: "select",
    type: "select",
    optionsYN: [
      { name: "Yes", value: "Y" },
      { name: "No", value: "N" },
    ],
    mdSize: "2",
  },
  {
    key: "pref_call_hours",
    label: "Preffered Call Hours",
    placeHolder: "Enter Preffered Call",
    type: "select",
    mdSize: "2",
    options:[]
  },
  {
    key: "ssn",
    label: "SSN",
    placeHolder: "Enter SSN",
    type: "text",
    mdSize: "2",
    onKeyPress: handleKeyPress,
    maxLength: "9",
  },

  {
    key: "allergies",
    label: "Allergy",
    placeHolder: "Enter allergy",
    type: "text",
    mdSize: "2",
    maxLength: "30",
    onKeyPress:handleAddressKeyPress,
  },
  {
    key: "is_hipaa_consent",
    label: "HIPAA Consent",
    placeHolder: "select",
    type: "select",
    optionsYN: [
      { name: "Yes", value: "Y" },
      { name: "No", value: "N" },
    ],
    mdSize: "2",
  },
  {
    key: "is_opt_marketing",
    label: "Opt Marketing Message",
    placeHolder: "select",
    type: "select",
    optionsYN: [
      { name: "Yes", value: "Y" },
      { name: "No", value: "N" },
    ],
    mdSize: "2",
  },
  {
    key: "is_patient_portal_access",
    label: "Patient Portal Access",
    placeHolder: "select",
    type: "select",
    optionsYN: [
      { name: "Yes", value: "Y" },
      { name: "No", value: "N" },
    ],
    mdSize: "2",
  },
  {
    key: "allow_health_info",
    label: "Allow Health Information Exchange",
    placeHolder: "select",
    type: "select",
    options: ["Yes", "No", "Unknown"],
    mdSize: "2",
  },
  {
    key: "preffered_language",
    label: "Preffered Language",
    placeHolder: "select",
    type: "select",
    options: [
      "English",
      "spanish",
      "Hindi",
      "Baltic",
      "French",
      "German",
      "Arabic",
      "Chinese",
      "Finnish",
      "gujrati",
      "Urdu",
      "Irish",
      "Other",
    ],
    mdSize: "2",
  },

];

export const formElements = [
  {
    key: "last_paid",
    label: "Last Paid",
    placeHolder: "Last Paid",
    type: "text",
    mdSize: "2",
    maxLength: "30",
    onKeyPress:handleAddressKeyPress,

  },
  {
    key: "last_paymnet_date",
    label: "Last Payment Date",
    placeHolder: "Enter last Payment Date",
    type: "date",
    mdSize: "2",

  },
  {
    key: "last_amount_due",
    label: "Last Amount Due",
    placeHolder: "Enter Last Amount Due",
    type: "text",
    mdSize: "2",
    maxLength: "30",
    onKeyPress:handleAddressKeyPress,

  },
  {
    key: "amount_due_from",
    label: "Amount Due From",
    placeHolder: "Enter Amount Due From",
    type: "date",
    mdSize: "2",
  },
  {
    key: "patient_responsibility",
    label: "Patient Responsibility",
    placeHolder: "Enter Patient Responsibility",
    type: "number",
    mdSize: "12",
  },
  {
    key: "ins_responsibility",
    label: "Insurance Responsibility",
    placeHolder: "Enter Insurance Responsibility",
    type: "number",
    mdSize: "12",
    maxLength: "5",
    onKeyPress:handleKeyPress,

  },
];

export const formBillingElements = [
  {
    key: "billing_address_line1",
    label: "Billing Address Line 1",
    placeHolder: "Enter Billing Address Line 1",
    type: "text",
    mdSize: "2",
    onKeyPress:handleAddressKeyPress
  },
  {
    key: "billing_address_line2",
    label: "Billing Address Line 2",
    placeHolder: "Enter Billing Address Line 2",
    type: "text",
    mdSize: "2",
    maxLength: "30",
    onKeyPress:handleAddressKeyPress
  },
  {
    key: "billing_zip",
    label: "Zip",
    placeHolder: "Enter Zip",
    type: "text",
    mdSize: "2",
    onKeyPress: handleKeyPress,
    maxLength: "5",
  },
  {
    key: "billing_city",
    label: "City",
    placeHolder: "Enter City",
    type: "text",
    mdSize: "2",
  },
  {
    key: "billing_state",
    label: "State",
    placeHolder: "Enter Last Name",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "2",
  },

  {
    key: "billing_address_verified",
    label: "Status",
    placeHolder: "select",
    type: "select",
    options: ["Verified", "Unverified", "Bad Address"],
    mdSize: "2",
  },
  {
    key: "shipping_address_line1",
    label: "Mailing Address Line 1",
    placeHolder: "Enter Mailing Address Line 1",
    type: "text",
    mdSize: "2",
    maxLength: "30",
    onKeyPress:handleAddressKeyPress,
  },
  {
    key: "shipping_address_line2",
    label: "Mailing Address Line 2",
    placeHolder: "Enter Mailing Address Line 2",
    type: "text",
    mdSize: "2",
    maxLength: "30",
    onKeyPress:handleAddressKeyPress,
  },
  {
    key: "shipping_zip",
    label: "Zip",
    placeHolder: "Enter Zip",
    type: "text",
    mdSize: "2",
    onKeyPress: handleKeyPress,
    maxLength: "5",

  },
  {
    key: "shipping_city",
    label: "City",
    placeHolder: "Enter City",
    type: "text",
    mdSize: "2",
    maxLength: "30",
    onKeyPress:handleAddressKeyPress,
  },
  {
    key: "shipping_state",
    label: "State",
    placeHolder: "Enter Last Name",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "2",
  },

  {
    key: "shipping_address_verified",
    label: "Status",
    placeHolder: "select",
    type: "select",
    options: ["Verified", "Unverified", "Bad Address"],
    mdSize: "2",
  },
  {
    key: "mailing_address_line1",
    label: "Mailing Address Line 1",
    placeHolder: "Enter Mailing Address Line 1",
    type: "text",
    mdSize: "2",
    onKeyPress: handleAddressKeyPress,
    
    maxLength: "50"

  },
  {
    key: "mailing_address_line2",
    label: "Mailing Address Line 2",
    placeHolder: "Enter Mailing Address Line 2",
    type: "text",
    mdSize: "2",
    maxLength: "30",
    onKeyPress:handleAddressKeyPress,
    
  },
  {
    key: "mailing_zip",
    label: "Zip",
    placeHolder: "Enter Zip",
    type: "text",
    mdSize: "2",
    onKeyPress: handleKeyPress,
    
    maxLength: "5",
  },
  {
    key: "mailing_city",
    label: "City",
    placeHolder: "Enter City",
    type: "text",
    mdSize: "2",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    
  },
  {
    key: "mailing_state",
    label: "State",
    placeHolder: "select",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "2",
  },

  {
    key: "mailing_address_verified",
    label: "Status",
    placeHolder: "select",
    type: "select",
    options: ["Verified", "Unverified", "Bad Address"],
    mdSize: "2",
  },
];

export const formAdditionalElements = [
  {
    key: "ethnicity",
    label: "Ethinicity",
    placeHolder: "Enter Ethinicity",
    type: "select",
    options: [
      "African",
      "Caribbean",
      "Indian",
      "Melanesian",
      "Australasian/Aboriginal",
      "Chinese",
      "Guamanian",
      "Japanese",
      "Korean",
      "Polynesian",
      "European/Anglo Saxon ",
      "Other Pacific Islander ",
      "Latin American",
      "Arabic",
      "Vietnamese",
      "Micronesian",
      "Declined to Respond ",
      "Other Hispanic ",
      "US or Canadian Indian",
      "Other Asian ",
      "Puerto Rican",
      "Filipino",
      "Mexican",
      "Alaskan Native ",
      "Cuban",
    ],
    mdSize: "2",
  },
  {
    key: "race",
    label: "Race",
    placeHolder: "Enter Race",
    type: "select",
    options: [
      "American Indian or Alaska Native",
      "Asian",
      "Black or African American",
      "Hispanic or Latino",
      "Native Hawaiian or Other Pacific Islander",
      "White",
    ],
    mdSize: "2",
  },
  {
    key: "hobbies",
    label: "Hobbies",
    placeHolder: "Enter Hobbies",
    type: "text",
    mdSize: "2",
    maxLength: "30",
    onKeyPress:handleAddressKeyPress,

  },
  {
    key: "primary_physician",
    label: "Primary Physician",
    placeHolder: "Enter Primary Physician",
    type: "text",
    mdSize: "2",
  },
  {
    key: "physician_address",
    label: "Physician Address",
    placeHolder: "Enter Physician Address",
    type: "text",
    mdSize: "2",
  },
  {
    key: "physician_city",
    label: "City",
    placeHolder: "Enter City",
    type: "text",
    mdSize: "2",
  },
  {
    key: "physician_state",
    label: "State",
    placeHolder: "select",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "2",
  },
  {
    key: "physician_zip",
    label: "Zip",
    placeHolder: "Enter Zip",
    type: "text",
    mdSize: "2",
    onKeyPress: handleKeyPress,
    maxLength: "5",
  },
  {
    key: "notes",
    label: "General Notes",
    placeHolder: "Enter General Notes",
    type: "textarea",
    mdSize: "12",
  },
  {
    key: "imp_shrt_notes",
    label: "Alert",
    placeHolder: "Enter Important Short Notes",
    type: "text",
    mdSize: "2",
  },
  {
    key: "applicable_role_id",
    label: "Applicable Role",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "is_disability",
    label: "Disability",
    placeHolder: "select",
    type: "select",
    options: [
      "Blindness",
      "Low-vision",
      "Leprosy Cured persons",
      "Hearing Impairment (deaf and hard of hearing)",
      "Locomotor Disability",
      "Dwarfism",
      "Intellectual Disability",
      "Mental Illness",
      "Autism Spectrum Disorder",
      "Cerebral Palsy",
      "Muscular Dystrophy",
      "Chronic Neurological conditions",
      "Specific Learning Disabilities",
      "Multiple Sclerosis",
      "Speech and Language disability",
      "Thalassemia",
      "Hemophilia",
      "Sickle Cell disease",
      "Multiple Disabilities including deaf-blindness",
      "Acid Attack victims",
      "Parkinson’s disease",
      "Other",
    ],
    mdSize: "2",
  },
  {
    key: "is_veteran_status",
    label: "Veteran Status",
    placeHolder: "select",
    type: "select",
    options: [
      "Protected Veteran",
      "Other Veteran",
      "Not a Protected Veteran",
      "Active Military",
    ],
    mdSize: "2",
  },
];
export const formPatientStatusElements = [
  {
    key: "patient_status",
    label: "Patient Status",
    type: "select",
    options: ["Active", "Inactive"],
    mdSize: "2",
  },
  {
    key: "patient_inactive_reason",
    label: "Inactivity Reason",
    type: "select",
    options: ["Change city/state", "Change country", "Change Practice"],
    mdSize: "2",
  },
  {
    key: "inactive_date",
    label: "Inactive Date",
    type: "date",
    mdSize: "2",
  },
  {
    key: "deceased_reason",
    label: "Deceased Reason",
    type: "select",
    options: ["Change city/state", "Change country", "Change Practice"],
    mdSize: "2",
  },
  {
    key: "deceased_date",
    label: "Deceased Date",
    type: "date",
    mdSize: "2",
  },
  {
    key: "deceased_date_received",
    label: "Date",
    type: "date",
    mdSize: "2",
  },
];
export const formEmergencyElements = [
  {
    key: "first_emergency_contact_name",
    label: "First Emergency contact Full Name",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "first_emergency_contact_con",
    label: "First Emergency Phone",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: handleKeyPress,
    maxLength: "10",
  },
  {
    key: "sec_emergency_contact_name",
    label: "Second Emergency Contact Full Name",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "sec_emergency_contact_con",
    label: "Second Emergency Phone",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: handleKeyPress,
    maxLength: "10",
  },
];
