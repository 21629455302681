import React, { useState } from "react";
import CanvasImage from "./CanvasImage";
import EysImage from "../../../assets/externalEyes.png";
export default function ExternalGlyph({ patientId }) {
  const [width, setWidth] = useState("900px");
  const [height, setHeight] = useState("500px");
  return (
    <div>
      <CanvasImage
        patientId={patientId}
        image={EysImage}
        width={width}
        height={height}
      />
    </div>
  );
}
