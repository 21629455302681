import React from 'react'
import InventoryReceiving from '../../components/Inventory/InventoryReceiving'

export default function Receiving() {
  return (
    <>
    <InventoryReceiving/>
    </>
  )
}
