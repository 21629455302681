import React, { useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import moment from "moment";
// components
// styles
import useStyles from "../styles";
import ExternalGlyph from "./ExternalGlyph";
import InternalGlyph from "./InternalGlyph";
import Fundus from "./Fundus";
import ImageGlyph from "./ImageGlyph";

export default function Glyphs({patientId}) {
  var classes = useStyles();

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>External</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ExternalGlyph patientId={patientId} />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Internal</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <InternalGlyph patientId={patientId}/>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Cross Section</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Fundus patientId={patientId}/>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Image</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ImageGlyph patientId={patientId}/>
        </AccordionDetails>
      </Accordion>
      
    </>
  );
}
