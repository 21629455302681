import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  TextField,
  Checkbox,
} from "@material-ui/core";
// Model
import { Button } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";

// styles
import useStyles from "./styles";
import {
  getUserGroupAndUser,
 
  postMenuData,
  getMenuAccessPerUser,
} from "../../services/ServiceLayer";
import { currentUser } from "../../hooks/helper";
import { ToastContainer, toast } from "react-toastify";
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import "react-toastify/dist/ReactToastify.css";

export default function ManageMenu() {
  const history = useHistory();
  const { user_id } = useParams();
  const { user_role } = useParams();
  const { menu_name } = useParams();
  const { employee_name } = useParams();
  console.log("user_id", user_id);
  console.log("user_role", user_role);
  console.log("menu_name", menu_name);
  var classes = useStyles();

  const [userGroup, setUserGroup] = useState([]);
  const [userList, setUserList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [subMenu, setSubMenu] = useState([]);

  const [menuAccess, setMenuAccess] = useState({});
  const [readAccess, setReadAccess] = useState({});
  const [selectedGroup, setSelectedGroup] = useState(user_role);
  const [selectedUser, setSelectedUser] = useState(user_id);
  const [selectedMenu, setSelectedMenu] = useState(menu_name);
  const [subMenuItems, setSubMenuItems] = useState([]);
  const [selectedSubmenuItems, setSelectedSubmenuItems] = useState([]);
  const [subMenuIds, setSubMenuIds] = useState([]);

  useEffect(() => {
    getMenuAccessPerUser(`user_id=${user_id}`).then((response) => {
      console.log("response", response);
      // setSubMenu(response?.data);
      const examMenu = response?.data.find((menu) => menu.label === menu_name);

      // If the "Exam" menu is found, set it in the state
      if (examMenu) {
        console.log("examMenu", examMenu);
        if (examMenu?.children) {
          setSubMenu(examMenu?.children);
        } else {
          setSubMenu([examMenu]);
        }
      }
    });
  }, [user_id]);

  useEffect(() => {
    // Initialize menuAccess and readAccess with empty objects for all menu IDs
    const initialMenuAccess = subMenu.reduce((acc, item) => {
      acc[item.id] = item.menu_access ;
      return acc;
    }, {});
    setMenuAccess(initialMenuAccess);

    const initialReadAccess = subMenu.reduce((acc, item) => {
      acc[item.id] = item.read_access ;
      return acc;
    }, {});
    setReadAccess(initialReadAccess);
  }, [subMenu]);

  useEffect(() => {
    console.log("submenu", subMenu);
    console.log("subMenuIds", subMenuIds);
  }, [subMenu, subMenuIds]);

  useEffect(() => {
    console.log("selectedUser", selectedUser);
  }, [selectedUser]);

  const handleMenuAccessChange = (menuId, access) => {
    setMenuAccess((prevMenuAccess) => ({
      ...prevMenuAccess,
      [menuId]: access ? "Y" : "N",
    }));
  };

  useEffect(() => {
    console.log("menuAccess", menuAccess);
  }, [menuAccess]);

  const handleReadAccessChange = (menuId, access) => {
    setReadAccess((prevReadAccess) => ({
      ...prevReadAccess,
      [menuId]: access ? "Y" : "N",
    }));
  };

  const handleAddButtonClick = () => {
    const selectedSubMenuIds = Object.keys(menuAccess).filter(
      (menuId) => menuAccess[menuId] 
    );
    // Convert the menuAccessData object into a comma-separated string
    const menuAccessString = Object.values(menuAccess).join(",");

    // Convert the readAccessData object into a comma-separated string
    const readAccessString = Object.values(readAccess).join(",");

    // Construct the data object in the desired format
    const dataToSend = {
      user_id: selectedUser,
      menu_id: selectedSubMenuIds.join(","),
      menu_access: menuAccessString,
      read_access: readAccessString,
      is_active: "Y",
      updated_by: currentUser(),
      access_location: "",
      opt_type: "MODMEN",
    };

    // Now you can send the data to an API endpoint, update state in the parent component, etc.
    console.log("Data to send:", dataToSend);
    postMenuData(dataToSend).then((response) => {
      console.log(response);
      if (response?.status?.status) {
        toast.success("Menu access updated successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          history.push(`/app/menu/`);
        }, 2000);
      } else {
        toast.error("Something went wrong please try after sometime.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  return (
    <>
      <ToastContainer position="top-right" />
      <PageTitle subtitle="Menu Access >" title="Menu Access Update" />
<Widget>
      <div className={classes.widgetBox}>
        <Grid
          container
          spacing={1}
          className={classes.formBox}
          style={{ marginBottom: "4px" }}
        >
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>User Group</Typography>
            <TextField
              disabled
              type="text"
              size="small"
              className={classes.txtForm}
              fullWidth
              placeholder=""
              variant="outlined"
              inputProps={{
                style: {
                  height: "12px",
                  fontSize: "12px",
                },
              }}
              name="user_role"
              value={user_role}
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>User</Typography>
            <FormControl fullWidth className={classes.dropdownFormControl}>
              <TextField
                disabled
                type="text"
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder=""
                variant="outlined"
                inputProps={{
                  style: {
                    height: "12px",
                    fontSize: "12px",
                  },
                }}
                name="employee_name"
                value={employee_name}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Menu Group</Typography>
            <TextField
              disabled
              type="text"
              size="small"
              className={classes.txtForm}
              fullWidth
              placeholder=""
              variant="outlined"
              inputProps={{
                style: {
                  height: "12px",
                  fontSize: "12px",
                },
              }}
              name="menu_name"
              value={menu_name}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          className={classes.formBox}
          style={{
            marginTop: "4px",
            marginBottom: "4px",
            backgroundColor: "#0b6790",
            color: "#fff",
            padding: "4px",
          }}
        >
          <Grid item xs={12} md={4}>
            <Typography>Sub Menu</Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography>Menu Access</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Readonly</Typography>
          </Grid>
        </Grid>
        {subMenu &&
          subMenu.map((item) => (
            <Grid container spacing={0.5} key={item.id}>
              <Grid item xs={12} md={4}>
                <Typography>{item.label}</Typography>
                {/* Add other sub-menu item details here if needed */}
              </Grid>
              <Grid item xs={12} md={4}>
                <Checkbox
                   checked={menuAccess[item.id] === "Y" } 
                  //  checked={item.menu_access ==='Y' ? item.menu_access ==='Y' : menuAccess[item.id] === "Y" } 
                  onChange={(event) =>
                    handleMenuAccessChange(item.id, event.target.checked)
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Checkbox
                  checked={readAccess[item.id] === "Y"} // Checked if the read access is "Y"
                  onChange={(event) =>
                    handleReadAccessChange(item.id, event.target.checked)
                  }
                />
              </Grid>
            </Grid>
          ))}
      </div>
      <Grid
        style={{ marginTop: "16px" }}
        className={classes.btnGroup}
        container
        xs={12}
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Button
          style={{ marginRight: "10px" }}
          className={classes.leftButton}
          variant="outlined"
          size="large"
          onClick={() => history.push(`/app/menu/`)}
        >
          Cancel
        </Button>
        <Button
          className={classes.rightButton}
          variant="contained"
          size="large"
          color="secondary"
          onClick={handleAddButtonClick}
        >
          Update
        </Button>
      </Grid>
      </Widget>
    </>
  );
}
