import {
  handleAddressKeyPress,
  handleAddressKeyPressPaste,
  validateLargeAmount,
} from "../../../hooks/helper";
import {
  validateForAxios,
  validateForCyl,
  validateForHprism,
} from "../../../hooks/lensValidation";
export const formRefractiveElements = [
  {
    title: "OD OD",
    mdSize: "1",
    // copyBtn: "true",
  },
  {
    key: "balance_od",
    label: "Balance",
    placeHolder: "",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "sphere_od",
    label: "Sphere",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForCyl,
  },
  {
    key: "cyl_od",
    label: "Cyl",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForCyl,
  },
  {
    key: "axis_od",
    label: "Axis",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForAxios,
  },
  {
    key: "add_od",
    label: "Add",
    placeHolder: "text",
    type: "select",
    options: [
      "+0.25",
      "+0.5",
      "+0.75",
      "+1",
      "+1.25",
      "+1.5",
      "+1.75",
      "+2",
      "+2.25",
      "+2.5",
      "+2.75",
      "+3",
      "+3.25",
      "+3.5",
      "+3.75",
      "+4",
      "+4.25",
      "+4.5",
      "+4.75",
      "+5",
      "+5.25",
      "+5.5",
      "+5.75",
      "+6",
      "+6.25",
      "+6.5",
      "+6.75",
      "+7",
      "+7.25",
      "+7.5",
      "+8",
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForCyl,
  },

  {
    key: "hprism_od",
    label: "H Prism",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "2",
    onKeyPress: validateForHprism,
  },
  {
    key: "hbase_od",
    label: "H Base",
    placeHolder: "text",
    type: "select",
    options: [],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForCyl,
  },
  {
    key: "vprism_od",
    label: "V Prism",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForHprism,
  },
  {
    key: "vbase_od",
    label: "V Base",
    placeHolder: "text",
    type: "select",
    options: [],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForCyl,
  },
  {
    key: "dva_od",
    txtLabel: "20/",
    label: "DVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForCyl,
  },
  {
    key: "nva_od",
    label: "NVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForCyl,
  },

  {
    title: "OS",
    mdSize: "1",
  },
  {
    key: "balance_os",
    label: "",
    placeHolder: "",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "sphere_os",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForCyl,
  },
  {
    key: "cyl_os",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForCyl,
  },
  {
    key: "axis_os",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForAxios,
  },
  {
    key: "add_os",
    label: "",
    placeHolder: "text",
    type: "select",
    options: [
      "+0.25",
      "+0.5",
      "+0.75",
      "+1",
      "+1.25",
      "+1.5",
      "+1.75",
      "+2",
      "+2.25",
      "+2.5",
      "+2.75",
      "+3",
      "+3.25",
      "+3.5",
      "+3.75",
      "+4",
      "+4.25",
      "+4.5",
      "+4.75",
      "+5",
      "+5.25",
      "+5.5",
      "+5.75",
      "+6",
      "+6.25",
      "+6.5",
      "+6.75",
      "+7",
      "+7.25",
      "+7.5",
      "+8",
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },

  {
    key: "hprism_os",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForHprism,
  },
  {
    key: "hbase_os",
    label: "",
    placeHolder: "text",
    type: "select",
    options: [],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "vprism_os",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForHprism,
  },
  {
    key: "vbase_os",
    label: "",
    placeHolder: "text",
    type: "select",
    options: ["up", "down", "in", "out"],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "dva_os",
    txtLabel: "20/",
    label: "",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "nva_os",
    label: "",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },

  {
    title: "OU",
    mdSize: "1",
  },
  {
    title: "",
    mdSize: "9",
  },
  {
    txtLabel: "20/",
    key: "oudva_od",
    label: "",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "ounva_od",
    label: "",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
];
export const formCrxElements = [
  {
    title: "OD",
    mdSize: "1",
    // copyBtn: "true",
  },
  {
    key: "od_balance",
    label: "Balance",
    placeHolder: "",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "od_sphere",
    label: "Sphere",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateForCyl,
  },
  {
    key: "od_cyl",
    label: "Cyl",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateForCyl,
  },
  {
    key: "od_axis",
    label: "Axis",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_add",
    label: "Add",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  // {
  //   key: "od_seg_ht",
  //   label: "Seg Ht",
  //   placeHolder: "text",
  //   type: "text",
  //   mdSize: "1",
  //   onKeyPress: validateLargeAmount,
  // },
  {
    key: "od_h_prism",
    label: "H Prism",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateForHprism,
  },
  {
    key: "od_h_base",
    label: "H Base",
    placeHolder: "text",
    type: "select",
    options: ["up", "down", "in", "out"],
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_v_prism",
    label: "V Prism",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateForHprism,
  },
  {
    key: "od_v_base",
    label: "V Base",
    placeHolder: "text",
    type: "select",
    options: ["up", "down", "in", "out"],
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_dva",
    txtLabel: "20/",
    label: "DVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_nva",
    label: "NVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },

  {
    title: "OS",
    mdSize: "1",
  },
  {
    key: "os_balance",
    label: "",
    placeHolder: "",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "os_sphere",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateForCyl,
  },
  {
    key: "os_cyl",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateForCyl,
  },
  {
    key: "os_axis",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_add",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  // {
  //   key: "os_seg_ht",
  //   label: "",
  //   placeHolder: "text",
  //   type: "text",
  //   mdSize: "1",
  //   onKeyPress: validateLargeAmount,
  // },
  {
    key: "os_h_prism",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateForHprism,
  },
  {
    key: "os_h_base",
    label: "",
    placeHolder: "text",
    type: "select",
    options: ["up", "down", "in", "out"],
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_v_prism",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateForHprism,
  },
  {
    key: "os_v_base",
    label: "",
    placeHolder: "text",
    type: "select",
    options: ["up", "down", "in", "out"],
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_dva",
    txtLabel: "20/",
    label: "",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_nva",
    label: "",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    title: "OU",
    mdSize: "1",
  },
  {
    title: "",
    mdSize: "9",
  },

  {
    txtLabel: "OU 20/",
    key: "ou_dva",
    label: "",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "ou_nva",
    label: "",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
];
export const formMrxElements = [
  {
    label: "Glaucoma",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Loss of Vision",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Lazy Eye:",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Mascular Degeneration",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Color Blindness",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Retinal Detachment",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Keratoconus",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Diabetes",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Heart Disease",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "High Blood Pressure",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Low Blood Pressure",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Kidney Disease",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Lupus",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Thyroid Disease",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Nyctalopia",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Eye Pain or Soreness",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Infection of Eye or Lid",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Sties or Chalazion",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Cancer",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
];
export const formFinalRxElements = [
  {
    key: "fr_name",
    label: "Final Refraction Type",
    placeHolder: "text",
    type: "text",
    mdSize: "6",
  },
  {
    title: "",
    mdSize: "6",
  },

  {
    title: "OD",
    mdSize: "1",
    // copyBtn: "true",
  },
  {
    key: "fr_balance_od",
    label: "Balance",
    placeHolder: "",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "fr_sphere_od",
    label: "Sphere",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForCyl,
  },
  {
    key: "fr_cyl_od",
    label: "Cyl",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForCyl,
  },
  {
    key: "fr_axis_od",
    label: "Axis",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForAxios,
  },
  {
    key: "fr_add_od",
    label: "Add",
    placeHolder: "text",
    type: "select",
    options: [
      "+0.25",
      "+0.5",
      "+0.75",
      "+1",
      "+1.25",
      "+1.5",
      "+1.75",
      "+2",
      "+2.25",
      "+2.5",
      "+2.75",
      "+3",
      "+3.25",
      "+3.5",
      "+3.75",
      "+4",
      "+4.25",
      "+4.5",
      "+4.75",
      "+5",
      "+5.25",
      "+5.5",
      "+5.75",
      "+6",
      "+6.25",
      "+6.5",
      "+6.75",
      "+7",
      "+7.25",
      "+7.5",
      "+8",
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },

  {
    key: "fr_hprism_od",
    label: "H Prism",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForHprism,
  },
  {
    key: "fr_hbase_od",
    label: "H Base",
    placeHolder: "text",
    type: "select",
    options: ["up", "down", "in", "out"],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "fr_vprism_od",
    label: "V Prism",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForHprism,
  },
  {
    key: "fr_vbase_od",
    label: "V Base",
    placeHolder: "text",
    type: "select",
    options: ["up", "down", "in", "out"],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "fr_dva_od",
    txtLabel: "20/",
    label: "DVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "fr_nva_od",
    label: "NVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },

  {
    title: "OS",
    mdSize: "1",
  },
  {
    key: "fr_balance_os",
    label: "",
    placeHolder: "",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "fr_sphere_os",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForCyl,
  },
  {
    key: "fr_cyl_os",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForCyl,
  },
  {
    key: "fr_axis_os",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForAxios,
  },
  {
    key: "fr_add_os",
    label: "",
    placeHolder: "text",
    type: "select",
    options: [
      "+0.25",
      "+0.5",
      "+0.75",
      "+1",
      "+1.25",
      "+1.5",
      "+1.75",
      "+2",
      "+2.25",
      "+2.5",
      "+2.75",
      "+3",
      "+3.25",
      "+3.5",
      "+3.75",
      "+4",
      "+4.25",
      "+4.5",
      "+4.75",
      "+5",
      "+5.25",
      "+5.5",
      "+5.75",
      "+6",
      "+6.25",
      "+6.5",
      "+6.75",
      "+7",
      "+7.25",
      "+7.5",
      "+8",
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },

  {
    key: "fr_hprism_os",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForHprism,
  },
  {
    key: "fr_hbase_os",
    label: "",
    placeHolder: "text",
    type: "select",
    options: ["up", "down", "in", "out"],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "fr_vprism_os",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForHprism,
  },
  {
    key: "fr_vbase_os",
    label: "",
    placeHolder: "text",
    type: "select",
    options: ["up", "down", "in", "out"],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "fr_dva_os",
    txtLabel: "20/",
    label: "",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "fr_nva_os",
    label: "",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },

  {
    title: "OU",
    mdSize: "1",
  },
  {
    mdSize: "9",
  },
  {
    txtLabel: "20/",
    key: "ou_dva",
    label: "",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "ou_nva",
    label: "",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "fr_usage",
    label: "Usage",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "fr_lens_type",
    label: "Lens Type",
    placeHolder: "select",
    type: "select",
    options: ["Lens","Glass"],
    mdSize: "2",
  },
  {
    key: "fr_material",
    label: "Material",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "fr_status",
    label: "Status",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "fr_start_dt",
    label: "Rx Date",
    placeHolder: "select",
    type: "date",
    mdSize: "2",
  },
  {
    key: "fr_end_dt",
    label: "Valid Until Date",
    placeHolder: "select",
    type: "date",
    mdSize: "2",
  },
];
export const formHistoryRxElements = [
  {
    key: "weight",
    label: "Weight Loss/Gain",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "loss_appetitue",
    label: "Loss of Appetite",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "sleep_apnea",
    label: "Sleep Apnea",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "covid",
    label: "COVID 19",
    type: "radio",

    mdSize: "3",
  },
  {
    title: "NEUROLOGICAL",
    mdSize: "12",
  },
  {
    key: "headaches",
    label: "Headaches",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "migraine",
    label: "Migraine",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "seizures",
    label: "Seizures",
    type: "radio",

    mdSize: "3",
  },
  {
    title: "ENT",
    mdSize: "12",
  },
  {
    key: "sinusitis",
    label: "Sinusitis",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "hearing_loss",
    label: "Hearing Loss",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "laryngitis",
    label: "Laryngitis",
    type: "radio",

    mdSize: "3",
  },
  {
    title: "ENDOCRINE",
    mdSize: "12",
  },
  {
    key: "thyroid_disease",
    label: "Thyroid Disease",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "other_gland",
    label: "Other Glands",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "respiratory",
    label: "RESPIRATORY",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "asthma",
    label: "Asthma",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "chronic_bronchitis",
    label: "Chronic Bronchitis",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "emphysema",
    label: "Emphysema",
    type: "radio",

    mdSize: "3",
  },
  {
    title: "VASCULAR / CARDIOVASCULAR",
    mdSize: "12",
  },
  {
    key: "diabetes",
    label: "Diabetes",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "heart_pain",
    label: "Heart Pain",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "hbp",
    label: "High Blood Pressure",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "vascular_disease",
    label: "Vascular Disease",
    type: "radio",

    mdSize: "3",
  },
  {
    title: "GASTROINTESTINAL",

    mdSize: "12",
  },
  {
    key: "acid_reflux",
    label: "Acid Reflux",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "ulcer",
    label: "Ulcer",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "celiac_disease",
    label: "Celiac Disease",
    type: "radio",

    mdSize: "3",
  },

  {
    title: "GENITOURINARY",
    mdSize: "12",
  },
  {
    key: "genitals_disease",
    label: "Genitals Disease",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "kidney_disease",
    label: "Kidney Disease",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "bladdar_disease",
    label: "Bladdar Disease",
    type: "radio",

    mdSize: "3",
  },
  {
    title: "Musculoskeletal",
    mdSize: "12",
  },
  {
    key: "rheumatoid_arthritis",
    label: "Rheumatoid Arthritis",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "osteoporosis",
    label: "Osteoporosis",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "muscular_dystrophy",
    label: "Muscular Dystrophy",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "joint_pain",
    label: "Joint Pain",
    type: "radio",

    mdSize: "3",
  },

  {
    title: "LYMPHATIC / HEMATOLOGIC",
    mdSize: "12",
  },
  {
    key: "anemia",
    label: "Anemia",
    type: "radio",

    mdSize: "3",
  },
  {
    key: "bleeding_problems",
    label: "Bleeding Problems",
    type: "radio",

    mdSize: "3",
  },
];
export const formLensRxElements = [
  {
    key: "",
    label: "Usage",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "",
    label: "Lens Type",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "",
    label: "Material",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "",
    label: "Start Date",
    placeHolder: "select",
    type: "date",
    mdSize: "3",
  },
  {
    key: "",
    label: "End Date",
    placeHolder: "select",
    type: "date",
    mdSize: "3",
  },

  {
    key: "",
    label: "Segment Height (mm)",
    placeHolder: "Enter segment height",
    type: "text",
    mdSize: "3",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },

  {
    key: "",
    label: "Diameter (mm)",
    placeHolder: "Enter diameter",
    type: "text",
    mdSize: "3",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },

  {
    key: "",
    label: "Bevel (mm)",
    placeHolder: "Enter bevel",
    type: "text",
    mdSize: "3",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
];

export const formOtherRxElements = [
  {
    key: "office_notes",
    label: "External Notes",
    placeHolder: "Type here",
    type: "textarea",
    mdSize: "6",
  },
  {
    key: "rx_notes",
    label: "Internal Notes",
    placeHolder: "Type here",
    type: "textarea",
    mdSize: "6",
  },
];
