import React, { useState } from "react";
import { Grid, Card, CardContent } from "@material-ui/core";
// icons
import { RiGlassesLine } from "react-icons/ri";
import { GiSpectacleLenses } from "react-icons/gi";
import { LiaEyeSolid } from "react-icons/lia";
import { FaTools } from "react-icons/fa";

// styles
import useStyles from "../inventory/style";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";

import { getDashboardInventory } from "../../services/ServiceLayer";

import FrameList from "../../components/Inventory/FrameList";
import GlassList from "../../components/Inventory/GlassList";
import LensList from "../../components/Inventory/LensList";
import OtherList from "../../components/Inventory/OtherList";
import { loadState } from "../../hooks/helper";
export default function Inventory() {
  const { activeTabVal } = useParams();
  // const tabValue = activeTabVal.split('='); // This extracts the number after the '=' sign
  console.log("activeTabVal", activeTabVal);

  const url = window.location.href; // Get the current URL
  const parts = url.split("/"); // Split the URL by '/'
  const lastPart = parts[parts.length - 1]; // Get the last part of the URL

  console.log("lastPart", lastPart); // This will log "1"

  var classes = useStyles();

  const userData = loadState().user;
  const userRole = loadState().user.user_role;

  const [dashboardData, setDashboardData] = useState([]);
  const [activeTabValue, setActiveTabValue] = useState(0);
  const [isLoader, setIsLoader] = useState(true);
  const defaultActiveTabId = isNaN(lastPart) ? 0 : parseInt(lastPart, 10);
  const [activeTabId, setActiveTabId] = useState(defaultActiveTabId);

  React.useEffect(() => {
    let isMounted = true; // Flag to track whether the component is mounted
    getDashboardInventory(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSTA`
    ).then((response) => {
      console.log("response", response);
      if (isMounted) {
        if (response?.status?.status) {
          setIsLoader(false);
          setDashboardData(response?.data);
        } else {
          // toast.error(response);
          setIsLoader(false);
        }
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <PageTitle title="Inventory" />
      {/* {isLoader && <Loader />} */}

      <Grid container spacing={5}>
        {dashboardData.map((item, index) => (
          <>
            <Grid
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              style={{ cursor: "pointer" }}
            >
              <Card
                onClick={() => setActiveTabId(0)}
                className={`${classes.card} ${activeTabId === 0 ? classes.activeCard : ""
                  }`}
              >
                <CardContent
                  style={{
                    background:
                      "linear-gradient(135deg, rgba(91, 228, 155, 0.2), rgba(0, 167, 111, 0.2)), rgb(255, 255, 255)",
                  }}
                >
                  <Typography variant="h5" component="div">
                    {item.frame_count}
                  </Typography>

                  <Typography variant="body2">
                    <br />
                    Frames <RiGlassesLine style={{ fontSize: "2rem", marginLeft: "4px" }} />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              style={{ cursor: "pointer" }}
              onClick={() => setActiveTabId(2)}
            >
              <Card
                onClick={() => setActiveTabId(2)}
                className={`${classes.card} ${activeTabId === 2 ? classes.activeCard : ""
                  }`}
              >
                <CardContent
                  style={{
                    background:
                      "linear-gradient(135deg, rgba(255, 214, 102, 0.2), rgba(255, 171, 0, 0.2)) rgb(255, 255, 255)",
                  }}
                >
                  <Typography variant="h5" component="div">
                    {item.contactlense_count}
                  </Typography>

                  <Typography variant="body2">
                    <br />
                    Lens <LiaEyeSolid
                      style={{ fontSize: "2rem", marginLeft: "4px" }}
                    />
                  </Typography>
                </CardContent>
              </Card>
            </Grid><Grid
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              style={{ cursor: "pointer" }}
            >
              <Card
                onClick={() => setActiveTabId(1)}
                className={`${classes.card} ${activeTabId === 1 ? classes.activeCard : ""
                  }`}
              >
                <CardContent
                  style={{
                    background:
                      "linear-gradient(135deg, rgba(97, 243, 243, 0.2), rgba(0, 184, 217, 0.2)) rgb(255, 255, 255)",
                  }}
                >
                  <Typography variant="h5" component="div">
                    {item.spectacle_count}
                  </Typography>

                  <Typography variant="body2">
                    <br />
                    Glasses <GiSpectacleLenses style={{ fontSize: "2rem", marginLeft: "4px" }} />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              style={{ cursor: "pointer" }}
              onClick={() => setActiveTabId(3)}
            >
              <Card style={{ backgroundColor: "#FFE4DC" }}
                onClick={() => setActiveTabId(3)}
                className={`${classes.card} ${activeTabId === 3 ? classes.activeCard : ""
                  }`}
              >
                <CardContent >
                  <Typography variant="h5" component="div">
                    {item.other_count}
                  </Typography>

                  <Typography variant="body2" style={{marginTop:'7%'}}>
                    <br />
                    Accessories<FaTools style={{ fontSize: "1.2rem", marginLeft: "4px" }} />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </>
        ))}
      </Grid>

      <div
        className={classes.iconsContainer}
        style={{ marginTop: "24px", paddingBottom: "16px" }}
      >
        {/* {activeTabId === 0 && <ServicesList setActiveTabValue={setActiveTabValue} />} */}
        {activeTabId === 0 && (
          <FrameList setActiveTabValue={setActiveTabValue} />
        )}
        {activeTabId === 1 && (
          <GlassList setActiveTabValue={setActiveTabValue} />
        )}
        {activeTabId === 2 && (
          <LensList setActiveTabValue={setActiveTabValue} />
        )}
        {activeTabId === 3 && (
          <OtherList setActiveTabValue={setActiveTabValue} />
        )}
        {/* {activeTabId === 5 && <Recall />} */}
      </div>
    </>
  );
}
