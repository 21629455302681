export const manageCompanySchema = {
  comp_name: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter company name",
    },
  },
  comp_alias: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter company alias",
    },
  },
  tax_id: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter tax number",
    //   number: "Please enter number only",
     
     
    },
  },
  tax_id2: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter tax number",
    //   number: "Please enter number only",
     
    },
  },
  corp_phone: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter phone number",
      number: "Please enter number only",
      minLength: {
        limit: 10,
        msg: "Number must be minimum 20 length",
      },
      maxLength: {
        limit: 10,
        msg: "Number must be maximum 20 length",
      },
    },
  },
  phone: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter number",
      number: "Please enter number only",
      minLength: {
        limit: 10,
        msg: "Number must be minimum 10 length",
      },
      maxLength: {
        limit: 10,
        msg: "Number must be maximum 10 length",
      },
    },
  },

  email_id: {
    type: "email",
    required: true,
    properties: {
      empty: "Please enter email",
      email: "Please enter a valid Email",
    },
  },

  name: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter plan name",
    },
  },

  s3Key: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select a file",
    },
  },
  file: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select a file",
    },
  },
};
