import React, { useEffect, useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,

} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";

import useStyles from "../styles";

import Arx from "./Arx";
import Crx from "./Crx";
import Mrx from "./Mrx";
import FinalRefractive from "./FinalRefractive";
import OtherRx from "./OtherRx";

import RefractiveHistory from "./RefractiveHistory";
import { currentUser, loadState } from "../../../hooks/helper";
import Srx from "./Srx";
import {
  getFinalRefractiveData,
  getOtherRefractiveData,
  getRefractiveData,
} from "../../../services/ServiceLayer";

export default function Refractive({
  patientId,
  setArxBtnUnable,
  setCrxBtnUnable,
  setMrxBtnUnable,
  setOtherRxBtnUnable,
  arxBtnUnable,
  crxBtnUnable,
  mrxBtnUnable,
  otherRxBtnUnable,
  setSrxBtnUnable,
  srxBtnUnable,examBase,frameMaterialData
}) {
  const [isUpdated, setIsUpdated] = useState(false);
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  var classes = useStyles();
  const [arxDetails, setArxDetails] = useState({
    pat_rfr_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    balance_od: "N",
    sphere_od: "",
    cyl_od: "",
    axis_od: "",
    add_od: "",
    hprism_od: "",
    hbase_od: "",
    vprism_od: "",
    vbase_od: "",
    dva_od: "",
    nva_od: "",
    oudva_od: "",
    ounva_od: "",
    balance_os: "N",
    sphere_os: "",
    cyl_os: "",
    axis_os: "",
    add_os: "",
    hprism_os: "",
    hbase_os: "",
    vprism_os: "",
    vbase_os: "",
    dva_os: "",
    nva_os: "",
    oudva_os: "",
    ounva_os: "",
    updated_by: currentUser(),
    access_location: "ggg",
    opt_type: "ADDRFP",
  });

  const [crxDetails, setCrxDetails] = useState({
    pat_rfr_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    balance_od: "",
    sphere_od: "",
    cyl_od: "",
    axis_od: "",
    add_od: "",
    hprism_od: "",
    hbase_od: "",
    vprism_od: "",
    vbase_od: "",
    dva_od: "",
    nva_od: "",
    oudva_od: "",
    ounva_od: "",
    balance_os: "",
    sphere_os: "",
    cyl_os: "",
    axis_os: "",
    add_os: "",
    hprism_os: "",
    hbase_os: "",
    vprism_os: "",
    vbase_os: "",
    dva_os: "",
    nva_os: "",
    oudva_os: "",
    ounva_os: "",
    updated_by: currentUser(),
    access_location: "ggg",
    opt_type: "ADDRFC",
  });
  const [mrxDetails, setMrxDetails] = useState({
    pat_rfr_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    balance_od: "",
    sphere_od: "",
    cyl_od: "",
    axis_od: "",
    add_od: "",
    hprism_od: "",
    hbase_od: "",
    vprism_od: "",
    vbase_od: "",
    dva_od: "",
    nva_od: "",
    oudva_od: "",
    ounva_od: "",
    balance_os: "",
    sphere_os: "",
    cyl_os: "",
    axis_os: "",
    add_os: "",
    hprism_os: "",
    hbase_os: "",
    vprism_os: "",
    vbase_os: "",
    dva_os: "",
    nva_os: "",
    oudva_os: "",
    ounva_os: "",
    updated_by: currentUser(),
    access_location: "ggg",
    opt_type: "ADDRFM",
  });
  const [srxDetails, setSrxDetails] = useState({
    pat_rfr_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    balance_od: "",
    sphere_od: "",
    cyl_od: "",
    axis_od: "",
    add_od: "",
    hprism_od: "",
    hbase_od: "",
    vprism_od: "",
    vbase_od: "",
    dva_od: "",
    nva_od: "",
    oudva_od: "",
    ounva_od: "",
    balance_os: "",
    sphere_os: "",
    cyl_os: "",
    axis_os: "",
    add_os: "",
    hprism_os: "",
    hbase_os: "",
    vprism_os: "",
    vbase_os: "",
    dva_os: "",
    nva_os: "",
    oudva_os: "",
    ounva_os: "",
    updated_by: currentUser(),
    access_location: "ggg",
    opt_type: "ADDRFS",
  });
  const [finalRxDetails, setFinalRxDetails] = useState({
    pat_rfrfr_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    fr_name: "",
    fr_balance_od: "N",
    fr_sphere_od: "",
    fr_cyl_od: "",
    fr_axis_od: "",
    fr_add_od: "",
    fr_hprism_od: "",
    fr_hbase_od: "",
    fr_vprism_od: "",
    fr_vbase_od: "",
    fr_dva_od: "",
    fr_nva_od: "",
    fr_oudva_od: "",
    fr_ounva_od: "",
    fr_balance_os: "N",
    fr_sphere_os: "",
    fr_cyl_os: "",
    fr_axis_os: "",
    fr_add_os: "",
    fr_hprism_os: "",
    fr_hbase_os: "",
    fr_vprism_os: "",
    fr_vbase_os: "",
    fr_dva_os: "",
    fr_nva_os: "",
    fr_oudva_os: "",
    fr_ounva_os: "",
    fr_usage: 0,
    fr_lens_type: 0,
    fr_material: 0,
    fr_status: 0,
    fr_start_dt: "2024-01-01",
    fr_end_dt: "2024-12-01",
    updated_by: currentUser(),
    access_location: "ggg",
    opt_type: "ADDRFR",
  });
  const [rxOtherDetails, setRxOtherDetails] = useState({
    pat_rfrnr_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    office_notes: "",
    rx_notes: "",
    updated_by: currentUser(),
    access_location: "ggg",
    opt_type: "ADDRFR",
  });
  const [arxListData, setArxListData] = useState([]);
  const [crxListData, setCrxListData] = useState([]);
  const [mrxListData, setMrxListData] = useState([]);
  const [srxListData, setSrxListData] = useState([]);
  const [finalRxData, setFinalRxData] = useState([]);
  const [otherRxData, setOtherRxData] = useState([]);
  useEffect(() => {
    // Arx
    getRefractiveData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELRFP`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setArxListData(response?.data);
        // setDataUpdated(false);
      } else {
        // setIsLoader(false);
      }
    });

    // Crx
    getRefractiveData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELRFC`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setCrxListData(response?.data);
        // setDataUpdated(false);
      } else {
        // setIsLoader(false);
      }
    });

    // Mrx
    getRefractiveData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELRFM`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setMrxListData(response?.data);
        // setDataUpdated(false);
      } else {
        // setIsLoader(false);
      }
    });

    // Srx
    getRefractiveData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELRFS`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setSrxListData(response?.data);
        // setDataUpdated(false);
      } else {
        // setIsLoader(false);
      }
    });

    // final
    getFinalRefractiveData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELRFR`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setFinalRxData(response?.data);
        // setDataUpdated(false);
      } else {
        // setIsLoader(false);
      }
    });

    // other
    getOtherRefractiveData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELRFR`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setOtherRxData(response?.data);
        // setDataUpdated(false);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);
const [resetValue, setResetValue] = useState(false);
  useEffect(() => {
    getFinalRefractiveData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELRFR`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setFinalRxData(response?.data);
        // setIsUpdated(false);
      } else {
        // setIsLoader(false);
      }
    });
    setIsUpdated(false);
  }, [isUpdated]);
  useEffect(() => {
    if (arxListData.length > 0) {
      setArxDetails({
        pat_rfr_id: arxListData[0]?.pat_rfr_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        balance_od: arxListData[0]?.fr_balance_od,
        sphere_od: arxListData[0]?.fr_sphere_od,
        cyl_od: arxListData[0]?.fr_cyl_od,
        axis_od: arxListData[0]?.fr_axis_od,
        add_od: arxListData[0]?.fr_add_od,
        hprism_od: arxListData[0]?.fr_hprism_od,
        hbase_od: arxListData[0]?.fr_hbase_od,
        vprism_od: arxListData[0]?.fr_vprism_od,
        vbase_od: arxListData[0]?.fr_vbase_od,
        dva_od: arxListData[0]?.fr_dva_od,
        nva_od: arxListData[0]?.fr_nva_od,
        oudva_od: arxListData[0]?.fr_oudva_od,
        ounva_od: arxListData[0]?.fr_ounva_od,
        balance_os: arxListData[0]?.fr_balance_os,
        sphere_os: arxListData[0]?.fr_sphere_os,
        cyl_os: arxListData[0]?.fr_cyl_os,
        axis_os: arxListData[0]?.fr_axis_os,
        add_os: arxListData[0]?.fr_add_os,
        hprism_os: arxListData[0]?.fr_hprism_os,
        hbase_os: arxListData[0]?.fr_hbase_os,
        vprism_os: arxListData[0]?.fr_vprism_os,
        vbase_os: arxListData[0]?.fr_vbase_os,
        dva_os: arxListData[0]?.fr_dva_os,
        nva_os: arxListData[0]?.fr_nva_os,
        oudva_os: arxListData[0]?.fr_oudva_os,
        ounva_os: arxListData[0]?.fr_ounva_os,
        updated_by: currentUser(),
        access_location: "ggg",
        opt_type: "UPDRFP",
      });
    }
    if (crxListData.length > 0) {
      setCrxDetails({
        pat_rfr_id: crxListData[0]?.pat_rfr_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        balance_od: crxListData[0]?.fr_balance_od,
        sphere_od: crxListData[0]?.fr_sphere_od,
        cyl_od: crxListData[0]?.fr_cyl_od,
        axis_od: crxListData[0]?.fr_axis_od,
        add_od: crxListData[0]?.fr_add_od,
        hprism_od: crxListData[0]?.fr_hprism_od,
        hbase_od: crxListData[0]?.fr_hbase_od,
        vprism_od: crxListData[0]?.fr_vprism_od,
        vbase_od: crxListData[0]?.fr_vbase_od,
        dva_od: crxListData[0]?.fr_dva_od,
        nva_od: crxListData[0]?.fr_nva_od,
        oudva_od: crxListData[0]?.fr_oudva_od,
        ounva_od: crxListData[0]?.fr_ounva_od,
        balance_os: crxListData[0]?.fr_balance_os,
        sphere_os: crxListData[0]?.fr_sphere_os,
        cyl_os: crxListData[0]?.fr_cyl_os,
        axis_os: crxListData[0]?.fr_axis_os,
        add_os: crxListData[0]?.fr_add_os,
        hprism_os: crxListData[0]?.fr_hprism_os,
        hbase_os: crxListData[0]?.fr_hbase_os,
        vprism_os: crxListData[0]?.fr_vprism_os,
        vbase_os: crxListData[0]?.fr_vbase_os,
        dva_os: crxListData[0]?.fr_dva_os,
        nva_os: crxListData[0]?.fr_nva_os,
        oudva_os: crxListData[0]?.fr_oudva_os,
        ounva_os: crxListData[0]?.fr_ounva_os,
        updated_by: currentUser(),
        access_location: "ggg",
        opt_type: "UPDRFC",
      });
    }
    if (mrxListData.length > 0) {
      setMrxDetails({
        pat_rfr_id: mrxListData[0]?.pat_rfr_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        balance_od: mrxListData[0]?.fr_balance_od,
        sphere_od: mrxListData[0]?.fr_sphere_od,
        cyl_od: mrxListData[0]?.fr_cyl_od,
        axis_od: mrxListData[0]?.fr_axis_od,
        add_od: mrxListData[0]?.fr_add_od,
        hprism_od: mrxListData[0]?.fr_hprism_od,
        hbase_od: mrxListData[0]?.fr_hbase_od,
        vprism_od: mrxListData[0]?.fr_vprism_od,
        vbase_od: mrxListData[0]?.fr_vbase_od,
        dva_od: mrxListData[0]?.fr_dva_od,
        nva_od: mrxListData[0]?.fr_nva_od,
        oudva_od: mrxListData[0]?.fr_oudva_od,
        ounva_od: mrxListData[0]?.fr_ounva_od,
        balance_os: mrxListData[0]?.fr_balance_os,
        sphere_os: mrxListData[0]?.fr_sphere_os,
        cyl_os: mrxListData[0]?.fr_cyl_os,
        axis_os: mrxListData[0]?.fr_axis_os,
        add_os: mrxListData[0]?.fr_add_os,
        hprism_os: mrxListData[0]?.fr_hprism_os,
        hbase_os: mrxListData[0]?.fr_hbase_os,
        vprism_os: mrxListData[0]?.fr_vprism_os,
        vbase_os: mrxListData[0]?.fr_vbase_os,
        dva_os: mrxListData[0]?.fr_dva_os,
        nva_os: mrxListData[0]?.fr_nva_os,
        oudva_os: mrxListData[0]?.fr_oudva_os,
        ounva_os: mrxListData[0]?.fr_ounva_os,
        updated_by: currentUser(),
        access_location: "ggg",
        opt_type: "UPDRFM",
      });
    }
    if (srxListData.length > 0) {
      setSrxDetails({
        pat_rfr_id: srxListData[0]?.pat_rfr_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        balance_od: srxListData[0]?.fr_balance_od,
        sphere_od: srxListData[0]?.fr_sphere_od,
        cyl_od: srxListData[0]?.fr_cyl_od,
        axis_od: srxListData[0]?.fr_axis_od,
        add_od: srxListData[0]?.fr_add_od,
        hprism_od: srxListData[0]?.fr_hprism_od,
        hbase_od: srxListData[0]?.fr_hbase_od,
        vprism_od: srxListData[0]?.fr_vprism_od,
        vbase_od: srxListData[0]?.fr_vbase_od,
        dva_od: srxListData[0]?.fr_dva_od,
        nva_od: srxListData[0]?.fr_nva_od,
        oudva_od: srxListData[0]?.fr_oudva_od,
        ounva_od: srxListData[0]?.fr_ounva_od,
        balance_os: srxListData[0]?.fr_balance_os,
        sphere_os: srxListData[0]?.fr_sphere_os,
        cyl_os: srxListData[0]?.fr_cyl_os,
        axis_os: srxListData[0]?.fr_axis_os,
        add_os: srxListData[0]?.fr_add_os,
        hprism_os: srxListData[0]?.fr_hprism_os,
        hbase_os: srxListData[0]?.fr_hbase_os,
        vprism_os: srxListData[0]?.fr_vprism_os,
        vbase_os: srxListData[0]?.fr_vbase_os,
        dva_os: srxListData[0]?.fr_dva_os,
        nva_os: srxListData[0]?.fr_nva_os,
        oudva_os: srxListData[0]?.fr_oudva_os,
        ounva_os: srxListData[0]?.fr_ounva_os,
        updated_by: currentUser(),
        access_location: "ggg",
        opt_type: "UPDRFS",
      });
    }
    if (otherRxData?.length > 0) {
      setRxOtherDetails({
        pat_rfrnr_id: otherRxData[0]?.pat_rfrnr_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        office_notes: otherRxData[0]?.office_notes,
        rx_notes: otherRxData[0]?.rx_notes,
        updated_by: currentUser(),
        access_location: "ggg",
        opt_type: "UPDRFR",
      });
    }
    setResetValue(false);
  }, [resetValue,arxListData, crxListData, mrxListData, srxListData, otherRxData]);

  const handleToCRX = () => {
    setAnchorEl(null);
    setCrxDetails({
      ...crxDetails,
      balance_od: arxDetails?.balance_od,
      sphere_od: arxDetails?.sphere_od,
      cyl_od: arxDetails?.cyl_od,
      axis_od: arxDetails?.axis_od,
      add_od: arxDetails?.add_od,
      hprism_od: arxDetails?.hprism_od,
      hbase_od: arxDetails?.hbase_od,
      vprism_od: arxDetails?.vprism_od,
      vbase_od: arxDetails?.vbase_od,
      dva_od: arxDetails?.dva_od,
      nva_od: arxDetails?.nva_od,
      oudva_od: arxDetails?.oudva_od,
      ounva_od: arxDetails?.ounva_od,
      balance_os: arxDetails?.balance_os,
      sphere_os: arxDetails?.sphere_os,
      cyl_os: arxDetails?.cyl_os,
      axis_os: arxDetails?.axis_os,
      add_os: arxDetails?.add_os,
      hprism_os: arxDetails?.hprism_os,
      hbase_os: arxDetails?.hbase_os,
      vprism_os: arxDetails?.vprism_os,
      vbase_os: arxDetails?.vbase_os,
      dva_os: arxDetails?.dva_os,
      nva_os: arxDetails?.nva_os,
      oudva_os: arxDetails?.oudva_os,
      ounva_os: arxDetails?.ounva_os,
      opt_type: "ADDRFC",
    });
  };

  const handleToMRX = () => {
    setAnchorEl(null);
    setMrxDetails({
      ...mrxDetails,
      balance_od: crxDetails?.balance_od,
      sphere_od: crxDetails?.sphere_od,
      cyl_od: crxDetails?.cyl_od,
      axis_od: crxDetails?.axis_od,
      add_od: crxDetails?.add_od,
      hprism_od: crxDetails?.hprism_od,
      hbase_od: crxDetails?.hbase_od,
      vprism_od: crxDetails?.vprism_od,
      vbase_od: crxDetails?.vbase_od,
      dva_od: crxDetails?.dva_od,
      nva_od: crxDetails?.nva_od,
      oudva_od: crxDetails?.oudva_od,
      ounva_od: crxDetails?.ounva_od,
      balance_os: crxDetails?.balance_os,
      sphere_os: crxDetails?.sphere_os,
      cyl_os: crxDetails?.cyl_os,
      axis_os: crxDetails?.axis_os,
      add_os: crxDetails?.add_os,
      hprism_os: crxDetails?.hprism_os,
      hbase_os: crxDetails?.hbase_os,
      vprism_os: crxDetails?.vprism_os,
      vbase_os: crxDetails?.vbase_os,
      dva_os: crxDetails?.dva_os,
      nva_os: crxDetails?.nva_os,
      oudva_os: crxDetails?.oudva_os,
      ounva_os: crxDetails?.ounva_os,
      opt_type: "ADDRFC",
    });
  };
  const handleToSRX = () => {
   
    setSrxDetails({
      ...srxDetails,
      balance_od: mrxDetails?.balance_od,
      sphere_od: mrxDetails?.sphere_od,
      cyl_od: mrxDetails?.cyl_od,
      axis_od: mrxDetails?.axis_od,
      add_od: mrxDetails?.add_od,
      hprism_od: mrxDetails?.hprism_od,
      hbase_od: mrxDetails?.hbase_od,
      vprism_od: mrxDetails?.vprism_od,
      vbase_od: mrxDetails?.vbase_od,
      dva_od: mrxDetails?.dva_od,
      nva_od: mrxDetails?.nva_od,
      oudva_od: mrxDetails?.oudva_od,
      ounva_od: mrxDetails?.ounva_od,
      balance_os: mrxDetails?.balance_os,
      sphere_os: mrxDetails?.sphere_os,
      cyl_os: mrxDetails?.cyl_os,
      axis_os: mrxDetails?.axis_os,
      add_os: mrxDetails?.add_os,
      hprism_os: mrxDetails?.hprism_os,
      hbase_os: mrxDetails?.hbase_os,
      vprism_os: mrxDetails?.vprism_os,
      vbase_os: mrxDetails?.vbase_os,
      dva_os: mrxDetails?.dva_os,
      nva_os: mrxDetails?.nva_os,
      oudva_os: mrxDetails?.oudva_os,
      ounva_os: mrxDetails?.ounva_os,
      opt_type: "ADDRFC",
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
const handlePRXToFinal=()=>{
  setFinalRxDetails({
    pat_rfrfr_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    fr_name: '',
    fr_balance_od: arxDetails?.balance_od,
    fr_sphere_od: arxDetails?.sphere_od,
    fr_cyl_od: arxDetails?.cyl_od,
    fr_axis_od: arxDetails?.axis_od,
    fr_add_od: arxDetails?.add_od,
    fr_hprism_od: arxDetails?.hprism_od,
    fr_hbase_od: arxDetails?.hbase_od,
    fr_vprism_od: arxDetails?.vprism_od,
    fr_vbase_od: arxDetails?.vbase_od,
    fr_dva_od: arxDetails?.dva_od,
    fr_nva_od: arxDetails?.nva_od,
    fr_oudva_od: arxDetails?.oudva_od,
    fr_ounva_od: arxDetails?.ounva_od,
    fr_balance_os: arxDetails?.balance_os,
    fr_sphere_os: arxDetails?.sphere_os,
    fr_cyl_os: arxDetails?.cyl_os,
    fr_axis_os: arxDetails?.axis_os,
    fr_add_os: arxDetails?.add_os,
    fr_hprism_os: arxDetails?.hprism_os,
    fr_hbase_os: arxDetails?.hbase_os,
    fr_vprism_os: arxDetails?.vprism_os,
    fr_vbase_os: arxDetails?.vbase_os,
    fr_dva_os: arxDetails?.dva_os,
    fr_nva_os: arxDetails?.nva_os,
    fr_oudva_os: arxDetails?.oudva_os,
    fr_ounva_os: arxDetails?.ounva_os,
    fr_usage: 0,
    fr_lens_type: 0,
    fr_material: 0,
    fr_status: 0,
    fr_start_dt: "2024-01-01",
    fr_end_dt: "2024-12-01",
    updated_by: currentUser(),
    access_location: "ggg",
    opt_type: "ADDRFR",
  })
}
 
  return (
    <>
      <Grid container spacing={2} className={classes.formBox}>
        <Grid
          item
          xs={12}
          md={12}
          className={classes.textGroup}
          style={{
            // border: "1px solid #DED9D9",
            display: "flex",
            justifyContent: "end",alignItems:'center'
          }}
        >
          <Button
            className={classes.btnlRightDark}
            variant="contained"
            style={{ fontSize: "10px",height:'36px' }}
            size="small"
            color="secondary"
            onClick={() => handleToCRX()}
          >
           Copy To Forward
          </Button>
          {/* <Button
                className={classes.btnlRightDark}
                variant="contained"
                size="large"
                color="secondary"
                onClick={() => handlePRXToFinal()}
              >
                Copy To Final
              </Button> */}
          
        </Grid>
      </Grid>
      <Accordion defaultExpanded>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Presenting Refractive (PRx)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Arx
          patientId={patientId}
            arxBtnUnable={arxBtnUnable}
            setArxBtnUnable={setArxBtnUnable}
            arxDetails={arxDetails}
            setArxDetails={setArxDetails}
            arxListData={arxListData}
            setResetValue={setResetValue}
            examBase={examBase}
          />
        </AccordionDetails>
      </Accordion>
      <Grid
          item
          xs={12}
          md={12}
          className={classes.textGroup}
          style={{
            // border: "1px solid #DED9D9",
            display: "flex",
            justifyContent: "end",alignItems:'center'
          }}
        >
          <Button
            className={classes.btnlRightDark}
            variant="contained"
            style={{ fontSize: "10px",height:'36px' }}
            size="small"
            color="secondary"
            onClick={() => handleToMRX()}
          >
             Copy To Forward
          </Button>
          {/* <Button
                className={classes.btnlRightDark}
                variant="contained"
                size="large"
                color="secondary"
                onClick={() => NextRefractiveTab()}
              >
                Next
              </Button> */}
         
        </Grid>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Cyclo Reading Refractive (CRx)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Crx
          crxListData={crxListData}
          patientId={patientId}
            crxBtnUnable={crxBtnUnable}
            setCrxBtnUnable={setCrxBtnUnable}
            crxDetails={crxDetails}
            setCrxDetails={setCrxDetails}
            setResetValue={setResetValue}
            examBase={examBase}
          />
        </AccordionDetails>
      </Accordion>
      <Grid
          item
          xs={12}
          md={12}
          className={classes.textGroup}
          style={{
            // border: "1px solid #DED9D9",
            display: "flex",
            justifyContent: "end",alignItems:'center'
          }}
        >
          <Button
            className={classes.btnlRightDark}
            variant="contained"
            style={{ fontSize: "10px",height:'36px' }}
            size="small"
            color="secondary"
            onClick={() => handleToSRX()}
          >
             Copy To Forward
          </Button>
          {/* <Button
                className={classes.btnlRightDark}
                variant="contained"
                size="large"
                color="secondary"
                onClick={() => NextRefractiveTab()}
              >
                Next
              </Button> */}
         
        </Grid>
      <Accordion defaultExpanded>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Manifest Refractive (MRx)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Mrx
          patientId={patientId}
          mrxListData={mrxListData}
            mrxBtnUnable={mrxBtnUnable}
            setMrxBtnUnable={setMrxBtnUnable}
            mrxDetails={mrxDetails}
            setMrxDetails={setMrxDetails}
            setResetValue={setResetValue}
            examBase={examBase}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Subjective Refractive (SRx)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Srx
          srxListData={srxListData}
          patientId={patientId}
            setSrxBtnUnable={setSrxBtnUnable}
            srxBtnUnable={srxBtnUnable}
            srxDetails={srxDetails}
            setSrxDetails={setSrxDetails}
            setResetValue={setResetValue}
            examBase={examBase}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Final Refractive</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FinalRefractive
          patientId={patientId}
            setIsUpdated={setIsUpdated}
            finalRxData={finalRxData}
            finalRxDetails={finalRxDetails}
            setFinalRxDetails={setFinalRxDetails}
            frameMaterialData={frameMaterialData}
            examBase={examBase}
            
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Refraction History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RefractiveHistory patientId={patientId} finalRxData={finalRxData} />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Other Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <OtherRx
          patientId={patientId}
            setOtherRxBtnUnable={setOtherRxBtnUnable}
            otherRxBtnUnable={otherRxBtnUnable}
            setIsUpdated={setIsUpdated}
            rxOtherDetails={rxOtherDetails}
            setRxOtherDetails={setRxOtherDetails}
            setResetValue={setResetValue}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
