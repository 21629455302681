import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  // Checkbox,
  Switch,
  Fade,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../common/modal/Popup";
// styles
import useStyles from "../styles";

import moment from "moment";
import {
  currentUser,
  handleAddressKeyPress,
  handleAddressKeyPressPaste,
  loadState,
} from "../../../hooks/helper";
import { postAllergyData } from "../../../services/ServiceLayer";
import { toast } from "react-toastify";
import ExamValidation from "../../../hooks/ExamValidation";

export default function ManageAllergy(props) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  // filter
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  //
  const formElements = [
    {
      key: "allergy_from",
      label: "Allergy From",
      placeHolder: "Enter allerg from",
      type: "text",
      mdSize: "3",
      maxLength: "79",
      onKeyPress: handleAddressKeyPress,
      onpaste: handleAddressKeyPressPaste,
      required: "true",
    },
    // {
    //   key: "st_date",
    //   label: "Begin Date and Time",
    //   placeHolder: "select",
    //   type: "datetime-local",
    //   mdSize: "3",
    //   required:'true',
    // },

    // {
    //   key: "end_date",
    //   label: "End Date and Time",
    //   placeHolder: "select",
    //   type: "datetime-local",
    //   mdSize: "3",
    //   required:'true',
    // },

    {
      key: "outcome_id",
      label: "Outcome",
      placeHolder: "select",
      type: "select",
      options: [],
      mdSize: "3",
    },
    {
      key: "alle_reaction",
      label: "Reaction",
      placeHolder: "select",
      type: "select",
      options: [],
      mdSize: "3",
    },
    {
      key: "alle_severity",
      label: "Severity",
      placeHolder: "select",
      type: "select",
      options: [],
      mdSize: "3",
    },
    {
      key: "alle_notes",
      label: "Comments",
      placeHolder: "type here",
      type: "textarea",
      mdSize: "12",
      maxLength: "500",
      onKeyPress: handleAddressKeyPress,
      onpaste: handleAddressKeyPressPaste,
    },
  ];
  console.log("props-24", props);
  const {
    patientId,
    allergyOutcome,
    allergySeverity,
    allergyReaction,
    selectclient,
  } = props;
  console.log("selectclient", selectclient);
  const [isShow, setIsShow] = useState(false);
  //
  var classes = useStyles();
  const [actionType, setActionType] = useState("ADDALG");
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isActionCalled, setIsActionCalled] = useState(false);

  const [allergyDetail, setAllergyDetail] = useState({
    pat_alle_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    allergy_from: "",
    outcome_id: "",
    st_date: currentDate,
    end_date: currentDate,
    alle_reaction: "",
    alle_severity: "",
    alle_notes: "",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: actionType,
  });
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    ExamValidation({
      allergy_from: allergyDetail?.allergy_from,
      // st_date: allergyDetail?.st_date,
      // end_date: allergyDetail?.end_date,
    });
  const clearForm = () => {
    setAllergyDetail({
      pat_alle_id: 0,
      patient_id: patientId,
      location_id: userData?.location_id,
      allergy_from: "",
      outcome_id: "",
      st_date: currentDate,
      end_date: currentDate,
      alle_reaction: "",
      alle_severity: "",
      alle_notes: "",
      updated_by: currentUser(),
      access_location: "access_location",
      opt_type: actionType,
    });
  };
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setAllergyDetail({
        pat_alle_id: 0,
        patient_id: patientId,
        location_id: userData?.location_id,
        allergy_from: "",
        outcome_id: "",
        st_date: "",
        end_date: "",
        alle_reaction: "",
        alle_severity: "",
        alle_notes: "",
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: actionType,
      });
    } else {
      setAllergyDetail({
        pat_alle_id: selectclient?.pat_alle_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        allergy_from: selectclient?.allergy_from,
        outcome_id: selectclient?.outcome_id,
        st_date: moment(selectclient?.st_date).format("YYYY-MM-DD"),
        end_date: moment(selectclient?.end_date).format("YYYY-MM-DD"),
        alle_reaction: selectclient?.alle_reaction,
        alle_severity: selectclient?.alle_severity,
        alle_notes: selectclient?.alle_notes,
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: "UPDALG",
      });
    }
  }, [selectclient]);
  const handleSubmit = () => {
    checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    if (!isError() && error !== null && isActionCalled) {
      postAllergyData(allergyDetail).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Allergy added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          clearForm();
          props.onHide();
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }

    setIsActionCalled(false);
  }, [isActionCalled]);

  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
    resetAllValidator();
    clearForm();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        // fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleSubmit}
            >
              {selectclient ? "Update" : "Add"}
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={2}
              className={classes.formBox}
              style={{ marginBottom: "4px" }}
            >
              {formElements.map((element, i) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={classes.textGroup}
                    >
                      <Typography
                        style={{ fontSize: "13px" }}
                        className={classes.formHeading}
                      >
                        {element.label}{" "}
                        {element.required === "true" ? (
                          <span className={classes.required}>*</span>
                        ) : null}
                      </Typography>

                      {element.type === "select" ? (
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            name={element.key}
                            className={classes.selectDropdown}
                            placeholder={element.placeHolder}
                            value={
                              allergyDetail && allergyDetail[element.key]
                                ? allergyDetail && allergyDetail[element.key]
                                : ""
                            }
                            onChange={(e) => {
                              console.log("selected", e.target.value);

                              setAllergyDetail({
                                ...allergyDetail,
                                [element.key]: e.target.value,
                              });

                              // resetValidator(e.target.name);
                            }}
                          >
                            <MenuItem disabled value="">
                              Select
                            </MenuItem>
                            {element.key === "outcome_id" && allergyOutcome
                              ? allergyOutcome.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "alle_reaction" &&
                                allergyReaction
                              ? allergyReaction.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "alle_severity" &&
                                allergySeverity
                              ? allergySeverity.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.options.map((item) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      ) : element.type === "text" ? (
                        <>
                          <TextField
                            required
                            name={element.key}
                            placeholder={element.placeHolder}
                            size="small"
                            className={classes.txtForm}
                            fullWidth
                            variant="outlined"
                            inputProps={{
                              style: {
                                height: "12px",
                                fontSize: "12px",
                              },
                              maxLength: element.maxLength,
                              onKeyPress: element.onKeyPress,
                            }}
                            value={
                              allergyDetail && allergyDetail[element.key]
                                ? allergyDetail && allergyDetail[element.key]
                                : ""
                            }
                            onChange={(e) => {
                              console.log("selected", e.target.value);

                              setAllergyDetail({
                                ...allergyDetail,
                                [element.key]: e.target.value,
                              });

                              resetValidator(e.target.name);
                            }}
                            isInvalid={error && error[element.key]}
                          />
                          <Fade in={error}>
                            <Typography
                              color="secondary"
                              className={classes.errorMessage}
                            >
                              {error && error[element.key]}
                            </Typography>
                          </Fade>
                        </>
                      ) : element.type === "date" ||
                        element.type === "datetime-local" ? (
                        <>
                          <TextField
                            id={element.key}
                            name={element.key}
                            placeholder={element.placeHolder}
                            type={element.type}
                            size="small"
                            className={classes.txtForm}
                            fullWidth
                            variant="outlined"
                            inputProps={{
                              min:
                                element.key === "end_date"
                                  ? allergyDetail?.st_date
                                  : undefined,
                              max: `${currentDate}`,
                              style: {
                                height: "12px",
                                fontSize: "12px",
                              },
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onKeyDown={(e) => {
                              e.preventDefault(); // Prevent manual typing
                            }}
                            value={
                              allergyDetail && allergyDetail[element.key]
                                ? allergyDetail && allergyDetail[element.key]
                                : ""
                            }
                            onChange={(e) => {
                              console.log("selected", e.target.value);

                              setAllergyDetail({
                                ...allergyDetail,
                                [element.key]: currentDate,
                              });

                              resetValidator(e.target.name);
                            }}
                            isInvalid={error && error[element.key]}
                          />
                          <Fade in={error}>
                            <Typography
                              color="secondary"
                              className={classes.errorMessage}
                            >
                              {error && error[element.key]}
                            </Typography>
                          </Fade>
                        </>
                      ) : element.type === "textarea" ? (
                        <TextField
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          placeholder="Type here..."
                          variant="outlined"
                          multiline
                          rows={3}
                          inputProps={{
                            style: {
                              fontSize: "12px",
                            },
                            maxLength: element.maxLength,
                            onKeyPress: element.onKeyPress,
                          }}
                          value={
                            allergyDetail && allergyDetail[element.key]
                              ? allergyDetail && allergyDetail[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected", e.target.value);

                            setAllergyDetail({
                              ...allergyDetail,
                              [element.key]: e.target.value,
                            });

                            // resetValidator(e.target.name);
                          }}
                        />
                      ) : element.type === "button" ? (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                          style={{
                            width: "100%",
                            fontSize: "10px",
                            marginTop: "26px",
                          }}
                        >
                          {element.btnName}
                        </Button>
                      ) : (
                        <Switch />
                      )}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
