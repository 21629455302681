import React from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";

// styles
import useStyles from "./styles";
import Profile from "./PatientTab/Profile";
import AddressBilling from "./PatientTab/AddressBilling";
import Additional from "./PatientTab/Additional";

import Emergency from "./PatientTab/Emergency";
export default function PatientProfile({
  patientProfileData,
  setPatientProfileData,
  error,
  checkValidator,
  resetValidator,
  resetAllValidator,
  isError,
  titleValues,
  genderValues,
  maritalValues,
  genderIdentificationValues,
  sexOrientationValues,
  preferenceContactValues,
  preferenceHourValues,
  languageValues,
  addressStatus,
  ethinicityValues,
  raceValues,
  applicableRoleValues,
  disabilityValues,
  veteranValues,setIsProfileStateChanged
}) {
  var classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
     <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Profile</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Profile
            patientProfileData={patientProfileData}
            setPatientProfileData={setPatientProfileData}
            error={error}
            checkValidator={checkValidator}
            resetValidator={resetValidator}
            resetAllValidator={resetAllValidator}
            isError={isError}
            titleValues={titleValues}
            genderValues={genderValues}
            maritalValues={maritalValues}
            genderIdentificationValues={genderIdentificationValues}
            sexOrientationValues={sexOrientationValues}
            preferenceContactValues={preferenceContactValues}
            preferenceHourValues={preferenceHourValues}
            languageValues={languageValues}
            setIsProfileStateChanged={setIsProfileStateChanged}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Address</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AddressBilling
            patientProfileData={patientProfileData}
            setPatientProfileData={setPatientProfileData}
            error={error}
            checkValidator={checkValidator}
            resetValidator={resetValidator}
            resetAllValidator={resetAllValidator}
            isError={isError}
            addressStatus={addressStatus}
            setIsProfileStateChanged={setIsProfileStateChanged}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Additional</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Additional
            patientProfileData={patientProfileData}
            setPatientProfileData={setPatientProfileData}
            error={error}
            checkValidator={checkValidator}
            resetValidator={resetValidator}
            resetAllValidator={resetAllValidator}
            isError={isError}
            ethinicityValues={ethinicityValues}
            raceValues={raceValues}
            applicableRoleValues={applicableRoleValues}
            disabilityValues={disabilityValues}
            veteranValues={veteranValues}
            setIsProfileStateChanged={setIsProfileStateChanged}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion >
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Emergency Contact</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Emergency
            patientProfileData={patientProfileData}
            setPatientProfileData={setPatientProfileData}
            error={error}
            checkValidator={checkValidator}
            resetValidator={resetValidator}
            resetAllValidator={resetAllValidator}
            isError={isError}
            setIsProfileStateChanged={setIsProfileStateChanged}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
