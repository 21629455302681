import React, { useEffect, useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import moment from "moment";
// components
// styles
import useStyles from "../styles";

import VisualAcuity from "./VisualAcuity";

import { currentUser, loadState } from "../../../hooks/helper";
import { toast } from "react-toastify";
import {
  getVisualAcuity,
  postVisualAcuity,
} from "../../../services/ServiceLayer";

export default function VisualAcuityTab({
  patientId,
  examChartType,
  examCorrectionType,
  examDominantEye,
  examAcuityNotes,
  examCurrentAcuity,
  setIsActionCalled,
  isActionCalled,
  setVisualBtnUnable,
  visualBtnUnable,
  setClearVisualAcuity, clearVisualAcuity,setShowDiscard
}) {
  var classes = useStyles();

  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [actionType, setActionType] = useState("ADDVAC");

  const [visualAcuityData, setVisualAcuityData] = useState([]);
  const [visualAcuityDetails, setVisualAcuityDetails] = useState({
    pat_visacu_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    chart_type: "",
    correction_type: "",
    dominant_eye: "",
    visacu_note: "",
    unau_acu_disvis_od: "",
    unau_acu_phval_od: "",
    unau_acu_nrvis_od: "",
    unau_acu_otvis_od: "",
    unau_acu_disvis_os: "",
    unau_acu_phval_os: "",
    unau_acu_nrvis_os: "",
    unau_acu_otvis_os: "",
    unau_acu_disvis_ou: "",
    unau_acu_phval_ou: "",
    unau_acu_nrvis_ou: "",
    unau_acu_otvis_ou: "",
    au_acu_disvis_od: "",
    au_acu_phval_od: "",
    au_acu_nrvis_od: "",
    au_acu_otvis_od: "",
    au_acu_disvis_os: "",
    au_acu_phval_os: "",
    au_acu_nrvis_os: "",
    au_acu_otvis_os: "",
    au_acu_disvis_ou: "",
    au_acu_phval_ou: "",
    au_acu_nrvis_ou: "",
    au_acu_otvis_ou: "",
    col_vis_od: "",
    col_vis_os: "",
    col_vis_ou: "",
    updated_by: currentUser(),
    access_location: "",
    opt_type: actionType,
  });

  useEffect(() => {
    getVisualAcuity(
      `location_id=${userData?.location_id}&patient_id=${patientId}&user_role=${userRole}&opt_type=SELVAC`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setVisualAcuityData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);

  useEffect(() => {
    if (visualAcuityData && visualAcuityData?.length > 0) {
      setVisualAcuityDetails({
        pat_visacu_id: visualAcuityData[0]?.pat_visacu_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        chart_type: visualAcuityData[0]?.chart_type,
        correction_type: visualAcuityData[0]?.correction_type,
        dominant_eye: visualAcuityData[0]?.dominant_eye,
        visacu_note: visualAcuityData[0]?.visacu_note,
        unau_acu_disvis_od: visualAcuityData[0]?.unau_acu_disvis_od,
        unau_acu_phval_od: visualAcuityData[0]?.unau_acu_phval_od,
        unau_acu_nrvis_od: visualAcuityData[0]?.unau_acu_nrvis_od,
        unau_acu_otvis_od: visualAcuityData[0]?.unau_acu_otvis_od,
        unau_acu_disvis_os: visualAcuityData[0]?.unau_acu_disvis_os,
        unau_acu_phval_os: visualAcuityData[0]?.unau_acu_phval_os,
        unau_acu_nrvis_os: visualAcuityData[0]?.unau_acu_nrvis_os,
        unau_acu_otvis_os: visualAcuityData[0]?.unau_acu_otvis_os,
        unau_acu_disvis_ou: visualAcuityData[0]?.unau_acu_disvis_ou,
        unau_acu_phval_ou: visualAcuityData[0]?.unau_acu_phval_ou,
        unau_acu_nrvis_ou: visualAcuityData[0]?.unau_acu_nrvis_ou,
        unau_acu_otvis_ou: visualAcuityData[0]?.unau_acu_otvis_ou,
        au_acu_disvis_od: visualAcuityData[0]?.au_acu_disvis_od,
        au_acu_phval_od: visualAcuityData[0]?.au_acu_phval_od,
        au_acu_nrvis_od: visualAcuityData[0]?.au_acu_nrvis_od,
        au_acu_otvis_od: visualAcuityData[0]?.au_acu_otvis_od,
        au_acu_disvis_os: visualAcuityData[0]?.au_acu_disvis_os,
        au_acu_phval_os: visualAcuityData[0]?.au_acu_phval_os,
        au_acu_nrvis_os: visualAcuityData[0]?.au_acu_nrvis_os,
        au_acu_otvis_os: visualAcuityData[0]?.au_acu_otvis_os,
        au_acu_disvis_ou: visualAcuityData[0]?.au_acu_disvis_ou,
        au_acu_phval_ou: visualAcuityData[0]?.au_acu_phval_ou,
        au_acu_nrvis_ou: visualAcuityData[0]?.au_acu_nrvis_ou,
        au_acu_otvis_ou: visualAcuityData[0]?.au_acu_otvis_ou,
        col_vis_od: visualAcuityData[0]?.col_vis_od,
        col_vis_os: visualAcuityData[0]?.col_vis_os,
        col_vis_ou: visualAcuityData[0]?.col_vis_ou,
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: "UPDVAC",
      });
    }
  }, [visualAcuityData]);

  useEffect(() => {
    // if (!isError() && error !== null && isActionCalled) {
    if (isActionCalled) {
      postVisualAcuity(visualAcuityDetails).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Visual Acuity added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setVisualBtnUnable(false);
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    // } else {
    //   setIsActionCalled(false);
    // }

    setIsActionCalled(false);
  }, [isActionCalled]);

  useEffect(()=>{
    if(visualAcuityData && visualAcuityData?.length > 0){
      setVisualBtnUnable(false);
      setClearVisualAcuity(false);
    }else{
      clearForm();
      setVisualBtnUnable(false);
      setClearVisualAcuity(false);
    }

  },[clearVisualAcuity])
  const clearForm =()=>{
    setVisualAcuityDetails({
      pat_visacu_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    chart_type: "",
    correction_type: "",
    dominant_eye: "",
    visacu_note: "",
    unau_acu_disvis_od: "",
    unau_acu_phval_od: "",
    unau_acu_nrvis_od: "",
    unau_acu_otvis_od: "",
    unau_acu_disvis_os: "",
    unau_acu_phval_os: "",
    unau_acu_nrvis_os: "",
    unau_acu_otvis_os: "",
    unau_acu_disvis_ou: "",
    unau_acu_phval_ou: "",
    unau_acu_nrvis_ou: "",
    unau_acu_otvis_ou: "",
    au_acu_disvis_od: "",
    au_acu_phval_od: "",
    au_acu_nrvis_od: "",
    au_acu_otvis_od: "",
    au_acu_disvis_os: "",
    au_acu_phval_os: "",
    au_acu_nrvis_os: "",
    au_acu_otvis_os: "",
    au_acu_disvis_ou: "",
    au_acu_phval_ou: "",
    au_acu_nrvis_ou: "",
    au_acu_otvis_ou: "",
    col_vis_od: "",
    col_vis_os: "",
    col_vis_ou: "",
    updated_by: currentUser(),
    access_location: "",
    opt_type: actionType,
    })
  }
  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Visual Acuity</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <VisualAcuity
            patientId={patientId}
            examChartType={examChartType}
            examCorrectionType={examCorrectionType}
            examDominantEye={examDominantEye}
            examAcuityNotes={examAcuityNotes}
            examCurrentAcuity={examCurrentAcuity}
            visualAcuityDetails={visualAcuityDetails}
            setVisualAcuityDetails={setVisualAcuityDetails}
            setVisualBtnUnable={setVisualBtnUnable}
            visualBtnUnable={visualBtnUnable}
            setShowDiscard={setShowDiscard}
           
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
