import React, { useEffect, useState } from "react";
import {
  Grid,
  Modal,
  Typography,
  Box,
  TextField,
  Avatar,
} from "@material-ui/core";
// Model
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Popup from "../common/modal/Popup";
import alertIcon from "../../images/alert.png";
// styles
import useStyles from "./styles";
import { currentUser } from "../../hooks/helper";
import { postPatientData } from "../../services/ServiceLayer";
import { toast } from "react-toastify";

export default function DeletePopup({
  onHide,
  deleteModalShow,
  setDeleteModalShow,
  selectclient,setPatientUpdate
}) {
  console.log("deleteModalShow", deleteModalShow);
  //  const { selectclient } = props;
  console.log("selectclient", selectclient);

  const [open, setOpen] = React.useState(false);
  var classes = useStyles();

  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setDeleteModalShow(false);
    setOpen(false);
  };
  const handleClose = () => {
    setDeleteModalShow(false);
    setOpen(false);
  };
  const [patientOtherData, setPatientOtherData] = useState();
  useEffect(() => {
    if (selectclient) {
      setPatientOtherData({
        patient_id: selectclient?.patient_id,
        title: selectclient?.title,
        first_name: selectclient?.first_name,
        middle_name: selectclient?.middle_name,
        last_name: selectclient?.last_name,
        suffix: selectclient?.suffix,
        nick_name: selectclient?.nick_name,
        gender: selectclient?.gender,
        gender_identity: selectclient?.gender_identity,
        applicable_role_id: selectclient?.applicable_role_id,
        allow_health_info: selectclient?.allow_health_info,
        marital: selectclient?.marital,
        dateofbirth: selectclient?.dateofbirth,
        ssn: selectclient?.ssn,
        orientation: selectclient?.orientation,
        contact_cell: selectclient?.contact_cell,
        contact_home: selectclient?.contact_home,
        contact_office: selectclient?.contact_office,
        email: selectclient?.email,
        fax: selectclient?.fax,
        pref_contact: selectclient?.pref_contact,
        is_opt_email: selectclient?.is_opt_email,
        is_opt_sms: selectclient?.is_opt_sms,
        is_opt_call: selectclient?.is_opt_call,
        allergies: selectclient?.allergies,
        pref_call_hours: selectclient?.pref_call_hours,
        is_hipaa_consent: selectclient?.is_hipaa_consent,
        is_opt_marketing: selectclient?.is_opt_marketing,
        is_patient_portal_access: selectclient?.is_patient_portal_access,
        preffered_language: selectclient?.preffered_language,
        is_smoking_status: selectclient?.is_smoking_status,
        is_drinking_status: selectclient?.is_drinking_status,
        billing_address_line1: selectclient?.billing_address_line1,
        billing_address_line2: selectclient?.billing_address_line2,
        billing_city: selectclient?.billing_city,
        billing_state: selectclient?.billing_state,
        billing_country: selectclient?.billing_country,
        billing_zip: selectclient?.billing_zip,
        billing_address_verified: selectclient?.billing_address_verified,
        shipping_address_line1: selectclient?.shipping_address_line1,
        shipping_address_line2: selectclient?.shipping_address_line2,
        shipping_city: selectclient?.shipping_city,
        shipping_state: selectclient?.shipping_state,
        shipping_country: selectclient?.shipping_country,
        shipping_zip: selectclient?.shipping_zip,
        shipping_address_verified: selectclient?.shipping_address_verified,
        ethnicity: selectclient?.ethnicity,
        race: selectclient?.race,
        hobbies: selectclient?.hobbies,
        primary_physician: selectclient?.primary_physician,
        physician_address: selectclient?.physician_address,
        physician_city: selectclient?.physician_city,
        physician_state: selectclient?.physician_state,
        physician_zip: selectclient?.physician_zip,
        physician_country: selectclient?.physician_country,
        notes: selectclient?.notes,
        imp_shrt_notes: selectclient?.imp_shrt_notes,
        is_disability: selectclient?.is_disability,
        is_veteran_status: selectclient?.is_veteran_status,
        first_emergency_contact_name: selectclient?.first_emergency_contact_name,
        first_emergency_contact_con: selectclient?.first_emergency_contact_con,
        sec_emergency_contact_name: selectclient?.sec_emergency_contact_name,
        sec_emergency_contact_con: selectclient?.sec_emergency_contact_con,
        is_active: selectclient?.is_active,
        insurance_id: selectclient?.insurance_id,
        insurance_provider: selectclient?.insurance_provider,
        add_insurance_id: selectclient?.add_insurance_id,
        add_insurance_provider: selectclient?.add_insurance_provider,
        patient_status: selectclient?.patient_status,
        patient_inactive_reason: selectclient?.patient_inactive_reason,
        date: selectclient?.date,
        deceased_reason: selectclient?.deceased_reason,
        deceased_date: selectclient?.deceased_date,
        deceased_date_received: selectclient?.deceased_date_received,
        last_paid: selectclient?.last_paid,
        last_payment_date: selectclient?.last_payment_date,
        last_amount_due: selectclient?.last_amount_due,
        amount_due_from: selectclient?.amount_due_from,
        patient_responsibility: selectclient?.patient_responsibility,
        ins_responsibility: selectclient?.ins_responsibility,
        created_dt: selectclient?.created_dt,
        updated_by: selectclient?.updated_by,
        updated_dt: selectclient?.updated_dt,
        access_location: selectclient?.access_location,
        gender_val: selectclient?.gender_val,
        opt_type: "DELPAT",
      });
    }
  }, [selectclient]);

  const deleteHandle = () => {
    
    postPatientData(patientOtherData).then((response) => {
      console.log(response);
      if (response.status.status) {
        toast.success("Patient Delete Successfully");
        setPatientUpdate(true);
        hideHandler();
      } else {
        toast.error(response.status.description);
       
      }
    });
  };

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={deleteHandle}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <img src={alertIcon} alt="image"/>
            <Typography className={classes.formActiveHeading}>
              Are you sure! You want to Delete this Client?
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
