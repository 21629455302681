import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import React from "react";
import Loader from "../loader/Loader";
//import  { Pagination }  from "@material-ui/core";
// styles
import useStyles from "./styles";
export default function TableDesign({
  tableHeadings,
  filterHandler = () => {},
  pagination = null,
  isLoader = false,
  button = null,
  filterOption = null,
  height,
  fontSize,
  setFilterOption = () => {},
  ...props
}) {
  var classes = useStyles();
  const { children } = props;

  const paginationContainer = () => {
    let count = 1;

    const paginationData = [];
    for (let i = 1; i <= pagination?.totalPages; i++) {
      paginationData.push(
        <TablePagination.Item
          onClick={() =>
            setFilterOption({
              ...filterOption,
              page: i,
            })
          }
          active={i === pagination?.currentPage}
          key={i}
        >
          {i}
        </TablePagination.Item>
      );
      count = i + 1;
    }

    return (
      <TablePagination className={classes.pagination}>
        {/* <Pagination.First /> */}
        <TablePagination.Prev
          disabled={!pagination?.hasPreviousPage && "disabled"}
          onClick={() =>
            setFilterOption({
              ...filterOption,
              page: pagination?.currentPage - 1,
            })
          }
        />
        {/* <Pagination.Ellipsis /> */}
        {paginationData}

        {/* <Pagination.Ellipsis /> */}
        <TablePagination.Next
          disabled={!pagination?.hasNextPage && "disabled"}
          onClick={() =>
            setFilterOption({
              ...filterOption,
              page: pagination?.currentPage + 1,
            })
          }
        />
        {/* <Pagination.Last /> */}
      </TablePagination>
    );
  };
  return (
    <>
      {isLoader && <Loader />}
      <Table component={Paper} className="mb-0" size="small">
        <TableHead style={{ backgroundColor: "#5db3d9" }}>
          <TableRow style={{ height: height }}>
            {tableHeadings.map((heading, i) => (
              <TableCell
                key={i}
                className={classes.tableHeadCell}
                align={heading?.align ? heading?.align : "center"}
                style={{
                  color: heading.color ? heading.color : "#121212",
                  fontSize: heading.fontSize,
                }} // Apply the color here
                onClick={(e) => {
                  if (heading?.sort) {
                    setFilterOption({
                      ...filterOption,
                      orderBy: heading?.sort,
                      orderIn: filterOption?.orderIn === 1 ? -1 : 1,
                    });
                    e.target.parentElement.childNodes.forEach((n) =>
                      n.classList.remove("active")
                    );
                    e.target.classList.add("active");
                  }
                }}
              >
                {heading.label}
                <br />
                <span style={{ color: "#650606" }}>
                  {heading.secondaryLabel}
                </span>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
      {pagination && paginationContainer()}

      {/* <Pagination count={10} variant="outlined" shape="rounded" /> */}
    </>
  );
}
