import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Typography,
  TextField,
  Avatar,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
} from "@material-ui/core";
// components
import Widget from "../../components/Widget";
import PageTitle from "../PageTitle/PageTitle";
import editIcon from "../../images/imgEdit.svg";
// styles
import useStyles from "./styles";
import lock from "./lock.svg";
import { loadState } from "../../hooks/helper";

export default function ProfileDetails() {
  const history = useHistory();
  var classes = useStyles();
  const changePassword = () => {};
  const profileData = loadState().user;
  console.log("profileData", profileData);
  
  return (
    <>
      <PageTitle title="Profile" />
      <Widget>
        <Grid container spacing={4}>
          <Grid item xs={12} md={1}>
            <Avatar className={classes.avatar} src="./photo.svg" />
            {/* <IconButton
              className={classes.uploadIcon}
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input hidden accept="image/*" type="file" />
              <img src={editIcon} />
            </IconButton> */}
          </Grid>
          <Grid item xs={12} md={11}>
            <Grid container spacing={2} className={classes.formBox}>
              <Grid item xs={12} md={4}>
                <Typography className={classes.formHeading}>Name</Typography>
                <TextField
                  disabled
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder="Enter Name"
                  variant="outlined"
                  value={profileData?.employee_name}
                />
              </Grid>

              {/* <Grid item xs={12} md={4}>
                <Typography className={classes.formHeading}>Sex</Typography>
                <RadioGroup
                  row
                  className={classes.radioGroup}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Female"
                  />
                  
                </RadioGroup>
              </Grid> */}
              <Grid item xs={12} md={4}>
                <Typography className={classes.formHeading}>Email</Typography>
                <TextField
                  disabled
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder="Enter Email Id"
                  variant="outlined"
                  value={profileData?.email}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography className={classes.formHeading}>
                  Mobile no.
                </Typography>
                <TextField
                  disabled
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder="Enter Number"
                  variant="outlined"
                  value={profileData?.contact_cell}
                />
              </Grid>
              <Grid
                className={classes.btnGroup}
                container
                xs={12}
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                {/* <Button
                  className={classes.leftButton}
                  variant="outlined"
                  size="large"
                >
                  Cancel
                </Button>
                <Button
                  className={classes.rightButton}
                  variant="contained"
                  size="large"
                  color="secondary"
                >
                  Update
                </Button> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Widget>
      <Grid container className={classes.widgetBox} spacing={4}>
        {/* <Grid item xs={12} md={4}>
          <div
            className={classes.passwordBox}
            onClick={() => history.push(`/app/change-password/`)}
          >
            <img src={lock} className={classes.icons} />
            <Typography className={classes.cardHeading}>
              Change Password
            </Typography>
          </div>
        </Grid> */}
      </Grid>
    </>
  );
}
