import React, { useState, useEffect } from "react";

// Model
import {
  Button,
  Typography,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Popup from "../common/modal/Popup";
import moment from "moment";
import alertIcon from "./alertIcon.svg";
// styles
import useStyles from "./styles";
import { toast } from "react-toastify";
import { postInvtcontactLense } from "../../services/ServiceLayer";
import { currentUser, loadState } from "../../hooks/helper";

export default function DeleteContactPopup({
  onHide,
  deleteModalShow,
  setDeleteModalShow,
  selectclient,
}) {
  console.log("selectclient", selectclient);
  console.log("deleteModalShow", deleteModalShow);
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const [actionType, setActionType] = useState("DELLNS");
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [isActionCalled, setIsActionCalled] = useState(false);
  toast.configure();
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setDeleteModalShow(false);
    setOpen(false);
  };
  const [companyDetail, setCompanyDetail] = useState({
    contlens_id: "0",
      location_id: userData?.location_id,
      lensname_id: "",
      lensname: " ",
      global_product_id: " ",
      lense_units: "",
      is_trial_lens: "",
      trial_lense_name: "",
      notes: " ",
      upccode: " ",
      contlense_picture: "",
      hard_contact: "",
      proc_code: "",
      structure_id: "",
      replacement_schedule: "",
      qty_purchased: "",
      qty_sold: "",
      qty_inventory: "",
      retail_price: "",
      item_cost: "",
      is_commission_prod: "",
      commission_amount: "0",
      inv_source: "",
      manufacturer_id: "",
      contactlense_taxid1: "",
      contactlense_taxid2: "",
      is_active: "",
      created_dt: "",
      updated_by: currentUser(),
      updated_dt: '',
      access_location: " ",
      opt_type: actionType,
  });
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setCompanyDetail({
        contlens_id: "0",
      location_id: userData?.location_id,
      lensname_id: "",
      lensname: " ",
      global_product_id: " ",
      lense_units: "",
      is_trial_lens: "",
      trial_lense_name: "",
      notes: " ",
      upccode: " ",
      contlense_picture: "",
      hard_contact: "",
      proc_code: "",
      structure_id: "",
      replacement_schedule: "",
      qty_purchased: "",
      qty_sold: "",
      qty_inventory: "",
      retail_price: "",
      item_cost: "",
      is_commission_prod: "",
      commission_amount: "0",
      inv_source: "",
      manufacturer_id: "",
      contactlense_taxid1: "",
      contactlense_taxid2: "",
      is_active: "",
      created_dt: "",
      updated_by: currentUser(),
      updated_dt: '',
      access_location: " ",
      opt_type: actionType,
      });
    } else {
      setCompanyDetail({
        contlens_id: selectclient?.contlens_id,
        location_id: userData?.location_id,
        lensname_id: selectclient?.lensname_id,
        lensname: selectclient?.lensname,
        global_product_id: selectclient?.global_product_id,
        lense_units: selectclient?.lense_units,
        is_trial_lens: selectclient?.is_trial_lens,
        trial_lense_name: selectclient?.trial_lense_name,
        notes: selectclient?.notes,
        upccode: selectclient?.upccode,
        contlense_picture: selectclient?.contlense_picture,
        hard_contact: selectclient?.hard_contact,
        proc_code: selectclient?.proc_code,
        structure_id: selectclient?.structure_id,
        replacement_schedule: selectclient?.replacement_schedule,
        qty_purchased: selectclient?.qty_purchased,
        qty_sold: selectclient?.qty_sold,
        qty_inventory: selectclient?.qty_inventory,
        retail_price: selectclient?.retail_price,
        item_cost: selectclient?.item_cost,
        is_commission_prod: selectclient?.is_commission_prod,
        commission_amount: "0",
        inv_source: selectclient?.inv_source,
        manufacturer_id: selectclient?.manufacturer_id,
        contactlense_taxid1: selectclient?.contactlense_taxid1,
        contactlense_taxid2: selectclient?.contactlense_taxid2,
        is_active: selectclient?.is_active,
        created_dt: "",
        updated_by: currentUser(),
        updated_dt: '',
        access_location: " ",
        opt_type: selectclient?.is_active === "Y" ? "DELLNS" : "REALNS",
      });
    }
  }, [selectclient]);

  const deleteFormData = () => {
    setIsActionCalled(true);
    setCompanyDetail({
      ...companyDetail,
      opt_type: selectclient?.is_active === "Y" ? "DELLNS" : "REALNS",
    });
  };

  useEffect(() => {
    console.log("isActionCalled", isActionCalled);

    if (isActionCalled) {
      postInvtcontactLense(companyDetail).then((response) => {
        console.log("actionType", actionType);

        console.log(response);
        if (response.status.status) {
          toast.success("Contact deleted successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });

          onHide();
          setDeleteModalShow(false);
          setOpen(false);
          setIsActionCalled(false);
        } else {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActionCalled(false);
        }
      });
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={deleteFormData}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <img src={alertIcon} />
            <Typography className={classes.formActiveHeading}>
              Are you sure! You want to {selectclient?.is_active === "Y" ? <>Delete </> : <>Re-active </>}{" "} this Contact Lens?
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
