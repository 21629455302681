import {
  handleKeyPress,
  handleKeyPressPaste,
  handleAlphNumKeyPress,
  handleAlphNumKeyPressPaste,
  handleAddressKeyPress,
  handleAddressKeyPressPaste,
  ssnFormat
} from "../../hooks/helper";

export const formElements = [
  {
    key: "first_name",
    label: "First Name",
    placeHolder: "Enter First Name",
    type: "text",
    mdSize: "4",
    lgSize: '4',
    required: "true",
    onKeyPress: handleAlphNumKeyPress,
    onPaste: handleAlphNumKeyPressPaste
  },
  {
    key: "middle_name",
    label: "Middle Name",
    placeHolder: "Enter Middle Name",
    type: "text",
    mdSize: "4",
    lgSize: '4',
    // required: "true",
    onKeyPress: handleAlphNumKeyPress,
    onPaste: handleAlphNumKeyPressPaste

  },
  {
    key: "last_name",
    label: "Last Name",
    placeHolder: "Enter Last Name",
    type: "text",
    mdSize: "4",
    lgSize: '4',

    required: "true",
    onKeyPress: handleAlphNumKeyPress,
    onPaste: handleAlphNumKeyPressPaste

  },
  {
    key: "dateofbirth",
    label: "Date of Birth",
    placeHolder: "Enter First Name",
    type: "date",
    mdSize: "4",
    lgSize: '4',
  },
  {
    key: "ssn",
    label: "SSN",
    placeHolder: "Enter SSN",
    type: "text",
    mdSize: "4",
    lgSize: '4',
    onKeyPress: ssnFormat,
    // onPaste:handleAlphNumKeyPressPaste,
    maxLength: "11",


  },
  {
    key: "gender",
    label: "Gender",
    placeHolder: "select",
    type: "select",
    options: [
      "American Indian or Alaska Native",
      "Asian",
      "Black or African American",
      "Hispanic or Latino",
      "Native Hawaiian or Other Pacific Islander",
      "White",
    ],
    mdSize: "4",
    lgSize: '4',
  },

  {
    key: "contact_home",
    label: "Home Cell",
    placeHolder: "Enter Home Cell",
    type: "text",
    mdSize: "4",
    lgSize: '4',
    required: "true",
    onKeyPress: handleKeyPress,
    onPaste: handleKeyPressPaste,
    maxLength: "11",
  },
  {
    key: "contact_cell",
    label: "Office Cell",
    placeHolder: "Enter Office Phone Cell",
    type: "text",
    mdSize: "4",
    lgSize: '4',
    required: "true",
    onKeyPress: handleKeyPress,
    onPaste: handleKeyPressPaste,
    maxLength: "11",
  },
  {
    key: "email",
    label: "Email Id",
    placeHolder: "Enter Email Id",
    type: "text",
    mdSize: "4",
    lgSize: '4',
    required: "true",
  },
  {
    key: "billing_address_line1",
    label: "Address Line 1",
    placeHolder: "Enter Address Line 1",
    type: "text",
    mdSize: "4",
    lgSize: '4',
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "billing_address_line2",
    label: "Address Line 2",
    placeHolder: "Enter Address Line 2",
    type: "text",
    mdSize: "4",
    lgSize: '4',
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "billing_city",
    label: "City",
    placeHolder: "Enter City",
    type: "text",
    mdSize: "4",
    lgSize: '4',
    onKeyPress: handleAlphNumKeyPress,
    onPaste: handleAlphNumKeyPressPaste,
  },
  {
    key: "billing_zip",
    label: "Zip",
    placeHolder: "Enter Zip",
    type: "text",
    mdSize: "4",
    lgSize: '4',
    // onKeyPress: handleKeyPress,
    // onPaste: handleKeyPressPaste,
    maxLength: "5",
    required: "true",
  },


  {
    key: "billing_state",
    label: "State",
    placeHolder: "select",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "4",
    lgSize: '4',
  },
  {
    key: "billing_country",
    label: "Country",
    placeHolder: "Enter country",
    type: "select",
    options: ['USA'],
    mdSize: "4",
    lgSize: '4',
    
  },
  {
    key: "reasonOfVisit",
    label: "Reason for visit",
    placeHolder: "select",
    type: "select",
    required: "true",
    options: [],

    mdSize: "4",
    lgSize: '4',
  },
  {
    key: "insurance_id",
    label: "Medical Insurance",
    placeHolder: "Enter Insurance",
    type: "select",
    options: ["Anthem", "UnitedHealthcare", "EyeMed", "VSP", "Humana"],
    mdSize: "4",
    lgSize: '4',
    required: "true",
  },
  {
    key: "additionalInsurance",
    label: "Vision Insurance",
    placeHolder: "Enter Additional Insurance",
    type: "select",
    options: ["Anthem", "UnitedHealthcare", "EyeMed", "VSP", "Humana"],
    mdSize: "3",
    lgSize: '4',
  },
  {
    key: "start",
    label: "Start Time",
    placeHolder: "Enter Start Time",
    type: "datetime-local",
    mdSize: "4",
    lgSize: '4',
  },
  {
    key: "end",
    label: "End Time",
    placeHolder: "Enter End Time",
    type: "datetime-local",
    mdSize: "4",
    lgSize: '4',
  },
  {
    key: "notes",
    label: "Notes",
    placeHolder: "Enter Notes",
    type: "textarea",
    // required: "true",
  },
];
// table Header

export const formElementsManage = [
  {
    key: "first_name",
    label: "First Name",
    placeHolder: "Enter First Name",
    type: "text",
    mdSize: "4",
    lgSize: '4',
    required: "true",
    onKeyPress: handleAlphNumKeyPress,
  },
  {
    key: "middle_name",
    label: "Middle Name",
    placeHolder: "Enter Middle Name",
    type: "text",
    mdSize: "4",
    lgSize: '4',
    // required: "true",
    onKeyPress: handleAlphNumKeyPress,
    onPaste: handleAlphNumKeyPressPaste

  },
  {
    key: "last_name",
    label: "Last Name",
    placeHolder: "Enter Last Name",
    type: "text",
    mdSize: "4",
    lgSize: '4',
    required: "true",
    onKeyPress: handleAlphNumKeyPress,
  },
  {
    key: "dateofbirth",
    label: "Date of Birth",
    placeHolder: "Enter First Name",
    type: "date",
    mdSize: "4",
    lgSize: '4',
  },
  {
    key: "ssn",
    label: "SSN",
    placeHolder: "Enter SSN",
    type: "text",
    mdSize: "4",
    onKeyPress: ssnFormat,
    maxLength: "11",
    lgSize: '4',

  },
  {
    key: "gender",
    label: "Gender",
    placeHolder: "select",
    type: "select",
    options: [
      "American Indian or Alaska Native",
      "Asian",
      "Black or African American",
      "Hispanic or Latino",
      "Native Hawaiian or Other Pacific Islander",
      "White",
    ],
    mdSize: "4",
    lgSize: '4',
  },


 
  {
    key: "contact_home",
    label: "Home Cell",
    placeHolder: "Enter Home Cell",
    type: "text",
    mdSize: "4",
    lgSize: '4',
    required: "true",
    onKeyPress: handleKeyPress,
    maxLength: "11",
  },
  {
    key: "contact_cell",
    label: "Office Cell",
    placeHolder: "Enter Office Phone Cell",
    type: "text",
    mdSize: "4",
    lgSize: '4',
    required: "true",
    onKeyPress: handleKeyPress,
    onPaste: handleKeyPressPaste,
    maxLength: "11",
  },
  {
    key: "email",
    label: "Email Id",
    placeHolder: "Enter Email Id",
    type: "text",
    mdSize: "4",
    lgSize: '4',
    required: "true",
  },
  {
    key: "billing_address_line1",
    label: "Address Line 1",
    placeHolder: "Enter Address Line 1",
    type: "text",
    mdSize: "4",
    lgSize: '4',
    onKeyPress: handleAddressKeyPress,
  },
  {
    key: "billing_address_line2",
    label: "Address Line 2",
    placeHolder: "Enter Address Line 2",
    type: "text",
    mdSize: "4",
    lgSize: '4',
    onKeyPress: handleAddressKeyPress,
  },
  {
    key: "billing_city",
    label: "City",
    placeHolder: "Enter City",
    type: "text",
    mdSize: "4",
    lgSize: '4',
    onKeyPress: handleAlphNumKeyPress,
  },
  {
    key: "billing_zip",
    label: "Zip",
    placeHolder: "Enter Zip",
    type: "text",
    mdSize: "4",
    lgSize: '4',
    // onKeyPress: handleKeyPress,
    maxLength: "5",
    required: "true",
  },

 
  {
    key: "billing_state",
    label: "State",
    placeHolder: "select",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "4",
    lgSize: '4',
  },
  {
    key: "billing_country",
    label: "Country",
    placeHolder: "Enter country",
    type: "select",
    options: ['USA'],
    mdSize: "4",
    lgSize: '4',
  },
  {
    key: "reasonOfVisit",
    label: "Reason for visit",
    placeHolder: "select",
    type: "select",
    required: "true",
    options: ["Eye Checkup", "Annual physical"],
    mdSize: "3",
    lgSize: '4',
  },
  {
    key: "insurance_id",
    label: "Medical Insurance",
    placeHolder: "Enter Insurance",
    type: "select",
    options: ["Anthem", "UnitedHealthcare", "EyeMed", "VSP", "Humana"],
    mdSize: "4",
    lgSize: '4',
    required: "true",
  },
  {
    key: "additionalInsurance",
    label: "Vision Insurance",
    placeHolder: "Enter Additional Insurance",
    type: "select",
    options: ["Anthem", "UnitedHealthcare", "EyeMed", "VSP", "Humana"],
    mdSize: "4",
    lgSize: '4',
  },
  {
    key: "start",
    label: "Start Time",
    placeHolder: "Enter Start Time",
    type: "datetime-local",
    mdSize: "4",
    lgSize: '4',
  },
  {
    key: "end",
    label: "End Time",
    placeHolder: "Enter End Time",
    type: "datetime-local",
    mdSize: "4",
    lgSize: '4',
  },
  {
    key: "notes",
    label: "Notes",
    placeHolder: "Enter Notes",
    type: "textarea",
    // required: "true",
  },
];
export const tableHeadings = [
  {
    label: "S. No.",
  },
  {
    label: "First Name",
    align: "left !important",
  },
  {
    label: "Last Name",
    align: "left !important",
  },
  {
    label: "Phone",
  },
  {
    label: "DOB",
  },

  {
    label: "Status",
  },
];
export const tableHeadings1 = [
  {
    label: "S. No.",
  },
  {
    label: "First Name",
    align: "left !important",
  },
  {
    label: "Last Name",
    align: "left !important",
  },
  {
    label: "Phone",
  },
  {
    label: "DOB",
  },
  {
    label: "Appoinment Status",
  },
  {
    label: "Status",
  },
];
