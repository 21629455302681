import React, { useState, useEffect } from "react";
import {  Grid } from "@material-ui/core";

import { dateFormatConversion, loadState } from "../../../hooks/helper";

import TableWidget from "../../Widget/TableWidget";

import TableDesign from "../../common/table/TableDesign";
import { TableRow, TableCell } from "@material-ui/core";
// styles
import useStyles from "../styles";
import  MedicationChart from './MedicationChart'

import { getAllergyHistoryData, getPatientExternalIop } from "../../../services/ServiceLayer";

import moment from "moment";

export default function Iop(props) {
  const tableHeadings = [
    
    {
      label: "Eye Type",
    },
    {
      label: "Method",
    },
    {
      label: "Measure (mmhg)",
    },
    {
      label: "Reliability (mmhg)",
    },
    {
      label: "Other",
    },
  ];
  const { patientId } = props;
  console.log("props", props);

  const [allergyData, setAllergyData] = useState([]);

  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [iopData, setIopData] = useState([]);
  useEffect(() => {
    getPatientExternalIop(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELIOP`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setIopData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);

  return (
    <>
      

      <Grid container spacing={4} style={{ width: "100%" }}>
        <Grid item xs={12}>
        <div style={{background:'#5DB3D9',padding:'10px',color:'#fff',textAlign:'center',borderRadius:'5px',marginBottom:'2px'}}>IOP</div>
          {/* <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {iopData && iopData?.length ? (
                iopData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                       
                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          OD <br/> 
                          <span style={{ color: "#0586FB" }}>OS</span>
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.iop_method_od} <br/> 
                          <span style={{ color: "#0586FB" }}>{row?.iop_method_os}</span>
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.iop_measure_od} <br/> 
                          <span style={{ color: "#0586FB" }}>{row?.iop_measure_os}</span>
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.iop_reliability_od} <br/> 
                          <span style={{ color: "#0586FB" }}>{row?.iop_reliability_os}</span>
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.iop_other_od} <br/> 
                          <span style={{ color: "#0586FB" }}>{row?.iop_other_os}</span>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget> */}

<MedicationChart />
        </Grid>
      </Grid>
    </>
  );
}
