import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  TableCell,
  TableRow,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../common/modal/Popup";

import moment from "moment";

import { getEmployeeAttendance } from "../../../services/ServiceLayer";
import { dateTimeFormatConversion, loadState } from "../../../hooks/helper";

// styles
import useStyles from "./styles";

import TableDesign from "../../common/table/TableDesign";

export default function ManageStaffAttendance(props) {
  const { selectclient } = props;
  console.log("selectclient", selectclient);
  const [attendanceList, setAttendanceList] = useState([]);
  var classes = useStyles();

  const tableHeadings = [
    {
      label: "S. No.",
    },
    {
      label: "Date of attendance",
    },
    {
      label: "Office CheckIn Time",
      align: "left !important",
    },
    {
      label: "Lunch Start Time",
      align: "left !important",
    },
    {
      label: "Lunch End Time",
      align: "left !important",
    },
    {
      label: "Office CheckOut Time",
      align: "left !important",
    },

    // {
    //   label: "Total Working Hour In Minutes",
    //   align: "left !important",

    // },
  ];
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");

  const [startDate, setStartDate] = useState(moment().subtract(7, 'days').format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const [isActionCalled, setIsActionCalled] = useState(false);

  const userRole = loadState().user.user_role;

  useEffect(() => {
    getEmployeeAttendance(
      `location_id=${
        selectclient === null ? "" : selectclient.location_id
      }&staff_id=${
        selectclient === null ? "" : selectclient.staff_id
      }&user_role=${userRole}&opt_type=SELATTN`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setAttendanceList(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, [selectclient]);

  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
    setIsActionCalled(false);
  };
  const disabled = true;
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        width={1200}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox} style={{padding:'0px'}}>
            <Grid
              container
              spacing={1}
              className={classes.formBox}
              style={{ marginBottom: "4px" }}
            >
              <>
                <div
                  className={classes.pageTitleContainer}
                  style={{ marginBottom: "16px" }}
                >
                  <div style={{ display: "inline-block" }}>
                    <Typography
                      style={{
                        marginRight: "16px",
                        fontSize: "12px",
                        fontWeight: "500",marginBottom:'0px'
                      }}
                      className={classes.formHeading}
                    >
                      Staff Name :- {selectclient?.first_name}{" "}
                      {selectclient?.middle_name} {selectclient?.last_name}
                    </Typography>
                    <Typography
                      style={{
                        marginRight: "16px",
                        fontSize: "12px",
                        fontWeight: "500",marginTop:'2px',marginBottom:'0px'
                      }}
                      className={classes.formHeading}
                    >
                      Email :- {selectclient?.email}
                    </Typography>
                  </div>
                  <div
                    className={classes.groupBtn}
                    style={{
                      display: "flex",
                      position: "absolute",
                      right: "50px",
                      top: "100px",
                    }}
                  >
                    <span style={{ flex: "flex-1" }}>
                      <TextField
                        name="startDate"
                        placeholder="select"
                        type="date"
                        size="small"
                        label="Start Date"
                        defaultValue={startDate}
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        onKeyDown={(e) => {
                          e.preventDefault(); // Prevent manual typing
                        }}
                        inputProps={{
                          // Set the maximum time to 18:00
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                          max: endDate,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          setStartDate(e.target.value);
                        }}
                      />
                    </span>

                    <span style={{ flex: "flex-1", marginLeft: "16px" }}>
                      <TextField
                        name="startDate"
                        placeholder="select"
                        type="date"
                        size="small"
                        label="End Date"
                        className={classes.txtForm}
                        fullWidth
                        defaultValue={endDate}
                        variant="outlined"
                        onKeyDown={(e) => {
                          e.preventDefault(); // Prevent manual typing
                        }}
                        inputProps={{
                          // Set the maximum time to 18:00
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                          min: startDate,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          setEndDate(e.target.value);
                        }}
                        // disabled={element.key === "end"} // Disable
                      />
                    </span>
                  </div>
                </div>
                <div style={{ marginTop: "10px", width: "inherit" }}>
                  <>
                    <TableDesign
                      {...{
                        title: "List of All Clients",
                        tableHeadings,
                      }}
                    >
                      {attendanceList && attendanceList.length ? (
                        attendanceList.map((row, i) => {
                          if (row)
                            return (
                              <TableRow
                                // onClick={() => createUpdateClient(row)}
                                key={i}
                                className={classes.tableBodyCell}
                              >
                                <TableCell className={classes.tableBodyCell}>
                                  {i + 1}
                                </TableCell>

                                <TableCell
                                  align="left"
                                  className={classes.tableBodyCell}
                                  style={{
                                    cursor: "pointer",
                                    textAlign: "left",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {dateTimeFormatConversion(row.attendance_day)}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className={classes.tableBodyCell}
                                  style={{
                                    cursor: "pointer",
                                    textAlign: "left",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {dateTimeFormatConversion(row.check_in_time)}
                                </TableCell>

                                <TableCell
                                  align="left"
                                  style={{ cursor: "pointer" }}
                                  className={classes.tableBodyCell}
                                >
                                  {dateTimeFormatConversion(row.lunch_st_time)}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ cursor: "pointer" }}
                                  className={classes.tableBodyCell}
                                >
                                  {dateTimeFormatConversion(row.lunch_et_time)}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{ cursor: "pointer" }}
                                  className={classes.tableBodyCell}
                                >
                                  {dateTimeFormatConversion(row.check_out_time)}
                                </TableCell>
                                {/* <TableCell
                                      align="left"
                                      style={{ cursor: "pointer" }}
                                      className={classes.tableBodyCell}
                                    >
                                    </TableCell> */}
                              </TableRow>
                            );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan="8">No Result Found</TableCell>
                        </TableRow>
                      )}
                    </TableDesign>
                  </>
                </div>
              </>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
