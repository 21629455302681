import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Fade,
  Box,
} from "@material-ui/core";

import moment from "moment";
import useStyles from "../styles";
import { formProfileElements } from "./Patient_Constant";
import { ssnFormat } from "../../../../hooks/helper";
import { getCityStateByZip } from "../../../../services/ServiceLayer";

export default function Profile({
  // patientProfileData,
  // setPatientProfileData,
  error,
  resetValidator,
  titleValues,
  genderValues,
  maritalValues,
  genderIdentificationValues,
  sexOrientationValues,
  preferenceContactValues,
  preferenceHourValues,
  languageValues, setIsProfileStateChanged
}) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();
  // Initialize state from localStorage or use default values
  const [patientProfileData, setPatientProfileData] = useState(() => {
    const savedData = localStorage.getItem("patientProfileData");
    return savedData ? JSON.parse(savedData) : {
      title: "", first_name: "", middle_name: "", last_name: "", // more fields as necessary
    };
  });

  // Update localStorage whenever patientProfileData changes
  useEffect(() => {
    localStorage.setItem("patientProfileData", JSON.stringify(patientProfileData));
  }, [patientProfileData]);

  useEffect(() => {
    // Function to fetch city and state based on zip and update the respective fields
    const fetchAndUpdateCityState = (zipCode, type) => {
      if (!zipCode || zipCode.length < 5) {
        // If zip code is invalid, clear the city and state
        setPatientProfileData((prevData) => ({
          ...prevData,
          [`${type}_city`]: "",
          [`${type}_state`]: "",
        }));
        return;
      }

      getCityStateByZip(`zip=${zipCode}`)
        .then((response) => {
          if (
            response?.status.description === "" &&
            response?.data.length > 0
          ) {
            setPatientProfileData((prevData) => ({
              ...prevData,
              [`${type}_city`]: response.data[0].city,
              [`${type}_state`]: response.data[0].state_id,
            }));
          }
        })
        .catch((error) => {
          console.error(`Error fetching city and state for ${type}:`, error);
          // Optionally handle error, e.g., by setting an error message in state
        });
    };

    // Validate and fetch/update for each zip code
    const zipTypes = ["billing", "shipping", "mailing", "physician"];
    zipTypes.forEach((type) => {
      const zipCode = patientProfileData?.[`${type}_zip`];
      fetchAndUpdateCityState(zipCode, type);
    });
  }, [
    patientProfileData?.billing_zip,
    patientProfileData?.shipping_zip,
    patientProfileData?.mailing_zip,
    patientProfileData?.physician_zip
  ]);

  const handleInputChange = (event, key) => {
    const value = event.target.value;
    setPatientProfileData(prevState => ({
      ...prevState,
      [key]: value
    }));
    setIsProfileStateChanged(true);
  };

  return (
    <>
      <Grid container spacing={2}
        className={classes.formBox}
      >
        {formProfileElements.map((element, i) => {
          return (
            <>
              <Grid
                item
                xs={12}
                md={2}
                lg={2}
                sm={12}
                className={classes.textGroup}
              >
                <Typography className={classes.formHeading}>
                  {element.label}{" "}
                  {element.required === "true" ? (
                    <span className={classes.required}>*</span>
                  ) : null}
                </Typography>

                {element.type === "select" ? (
                  <>
                    <FormControl
                      fullWidth
                      // sx={{
                      //   '&.MuiFormControl-fullWidth': {
                      //     width: 'auto',
                      //   }
                      // }}
                      className={classes.dropdownFormControl}
                    >
                      <Select
                        name={element.key}
                        className={classes.selectDropdown}
                        placeholder={element.placeHolder}
                        value={
                          patientProfileData &&
                            patientProfileData[element.key] === null
                            ? ""
                            : patientProfileData &&
                            patientProfileData[element.key]
                        }
                        onChange={async (e) => {
                          handleInputChange(e, element.key)
                          resetValidator(e.target.name);
                        }}
                        isInvalid={error && error[element.key]}
                      >
                        <MenuItem disabled value="">
                          Select
                        </MenuItem>

                        {element.key === "title" && titleValues
                          ? titleValues.map((item) => (
                            <MenuItem
                              key={item.attribute_id}
                              value={item.attribute_id}
                            >
                              {item.attribute_val}
                            </MenuItem>
                          ))
                          : element.key === "gender" && genderValues
                            ? genderValues.map((item) => (
                              <MenuItem
                                key={item.attribute_id}
                                value={item.attribute_id}
                              >
                                {item.attribute_val}
                              </MenuItem>
                            ))
                            : element.key === "marital" && maritalValues
                              ? maritalValues.map((item) => (
                                <MenuItem
                                  key={item.attribute_id}
                                  value={item.attribute_id}
                                >
                                  {item.attribute_val}
                                </MenuItem>
                              ))
                              : element.key === "gender_identity" &&
                                genderIdentificationValues
                                ? genderIdentificationValues.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                                : element.key === "orientation" &&
                                  sexOrientationValues
                                  ? sexOrientationValues.map((item) => (
                                    <MenuItem
                                      key={item.attribute_id}
                                      value={item.attribute_id}
                                    >
                                      {item.attribute_val}
                                    </MenuItem>
                                  ))
                                  : element.key === "preffered_language" &&
                                    languageValues
                                    ? languageValues.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                    : element.key === "pref_contact" &&
                                      preferenceContactValues
                                      ? preferenceContactValues.map((item) => (
                                        <MenuItem
                                          key={item.attribute_id}
                                          value={item.attribute_id}
                                        >
                                          {item.attribute_val}
                                        </MenuItem>
                                      ))
                                      : element.key === "pref_call_hours" &&
                                        preferenceHourValues
                                        ? preferenceHourValues.map((item) => (
                                          <MenuItem
                                            key={item.attribute_id}
                                            value={item.attribute_id}
                                          >
                                            {item.attribute_val}
                                          </MenuItem>
                                        ))
                                        : element.optionsYN
                                          ? element.optionsYN.map((item) => (
                                            <MenuItem value={item?.value}>
                                              {item?.name}
                                            </MenuItem>
                                          ))
                                          : element.key === "allow_health_info" &&
                                            element.options
                                            ? element.options.map((item) => (
                                              <MenuItem value={item}> {item} </MenuItem>
                                            ))
                                            : null}
                      </Select>
                    </FormControl>
                    <Fade in={error}>
                      <Typography
                        color="secondary"
                        className={classes.errorMessage}
                      >
                        {error && error[element.key]}
                      </Typography>
                    </Fade>
                  </>
                ) : element.type === "textarea" ? (
                  <>
                    <TextField
                      name={element.key}
                      size="small"
                      className={classes.txtForm}
                      // fullWidth
                      placeholder="Type here..."
                      variant="outlined"
                      multiline
                      rows={3}
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      value={
                        patientProfileData &&
                          patientProfileData[element.key] === null
                          ? ""
                          : patientProfileData &&
                          patientProfileData[element.key]
                      }
                      onChange={async (e) => {
                        handleInputChange(e, element.key)
                        resetValidator(e.target.name);
                      }}
                      isInvalid={error && error[element.key]}
                      sx={{
                        // minWidth: '150px', // Set a reasonable minimum width
                        width: 'auto',
                      }}
                    />
                    <Fade in={error}>
                      <Typography
                        color="secondary"
                        className={classes.errorMessage}
                      >
                        {error && error[element.key]}
                      </Typography>
                    </Fade>
                  </>
                ) : element.type === "date" ||
                  element.type === "datetime-local" ? (
                  <>
                    <TextField
                      id={element.key}
                      name={element.key}
                      placeholder={element.placeHolder}
                      type={element.type}
                      size="small"
                      className={classes.txtForm}
                      // fullWidth
                      variant="outlined"
                      inputProps={{
                        min: `${currentDateTime}`,
                        max: `${currentDate}`,
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        patientProfileData &&
                          patientProfileData[element.key] === null
                          ? ""
                          : patientProfileData &&
                          moment(patientProfileData[element.key]).utc().format('YYYY-MM-DD')
                      }
                      onChange={async (e) => {
                        handleInputChange(e, element.key)
                        resetValidator(e.target.name);
                      }}
                      // onKeyDown={(e) => {
                      //   e.preventDefault(); // Prevent manual typing
                      // }}
                      isInvalid={error && error[element.key]}
                      sx={{
                        // minWidth: '150px', // Set a reasonable minimum width
                        width: 'auto',
                      }}
                    />
                    <Fade in={error}>
                      <Typography
                        color="secondary"
                        className={classes.errorMessage}
                      >
                        {error && error[element.key]}
                      </Typography>
                    </Fade>
                  </>
                ) : (
                  <>
                    <TextField
                      type={element.type}
                      name={element.key}
                      placeholder={element.placeHolder}
                      size="small"
                      className={classes.txtForm}
                      // fullWidth
                      variant="outlined"
                      autoFocus
                      inputProps={{
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                        maxLength: element.maxLength,
                        ...(element.key === "ssn" && {
                          onInput: (e) => {
                            e.target.value = ssnFormat(e.target.value);
                          },
                        }),
                        onKeyPress: element.onKeyPress, // Add the event handler
                        onPaste: element.onPaste,
                      }}
                      value={
                        patientProfileData &&
                          patientProfileData[element.key] === null
                          ? ""
                          : patientProfileData &&
                          patientProfileData[element.key]
                      }
                      onChange={async (e) => {
                        handleInputChange(e, element.key)
                        resetValidator(e.target.name);
                      }}
                      isInvalid={error && error[element.key]}
                      sx={{
                        minWidth: 'auto', // Set a reasonable minimum width
                        width: 'auto',
                      }}
                    />
                    <Fade in={error}>
                      <Typography
                        color="secondary"
                        className={classes.errorMessage}
                      >
                        {error && error[element.key]}
                      </Typography>
                    </Fade>
                  </>
                )}
              </Grid>
            </>
          );
        })}
      </Grid>
    </>
  );
}

