import React from 'react';
import LoaderImage from './loader.svg';
// styles
import useStyles from "./styles";
export default function Loader() {
    var classes = useStyles();
  return (
     
    <div className={classes.loader}>
            <img src={LoaderImage} className={classes.img} alt="" width={50} />
        </div>
  )
}
