import React, { useState, useEffect } from "react";
import { Button, Grid, Menu, MenuItem } from "@material-ui/core";



import TableWidget from "../../Widget/TableWidget";

import TableDesign from "../../common/table/TableDesign";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
// styles
import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";

import useStyles from "../styles";
import ManageRefractive from "./ManageRefractive";
import { toast } from "react-toastify";

export default function FinalRefractive({
  finalRxDetails,
  setFinalRxDetails,
  finalRxData,
  setIsUpdated,
  examBase,
  frameMaterialData,patientId
}) {
  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Exam Name",
      align: "left",
    },
    {
      label: "Type",
      align: "left",
    },
    {
      label: "Sph",
      align: "left",
    },
    {
      label: "Cyl",
      align: "left",
    },
    {
      label: "Axis",
      align: "left",
    },
    {
      label: "Add",
      align: "left",
    },
    {
      label: "HPrism",
      align: "left",
    },
    {
      label: "HBase",
      align: "left",
    },
    {
      label: "VPrism",
      align: "left",
    },
    {
      label: "VBase",
      align: "left",
    },
    {
      label: "DVA",
      align: "left",
    },
    {
      label: "NVA",
      align: "left",
    },
    {
      label: "Status",
    },
    {
      label: "Action",
    },
  ];
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const [modalShow, setModalShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectRow, setSelectRow] = useState(null);
  const [title, setTitle] = useState(null);
  const hasInactiveRows = () => {
    return finalRxData.some(row => row.is_active === "Y");
  };
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const addRefractiveModel = () => {
    if (!hasInactiveRows()) {
      setOpen(true);
    } else {
      toast.warn("Exam Active is already available. Please deactivate active exam if you want to add new refractive data");
    }
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    setModalShow(false);
    setOpen(false);
    // setDataUpdated(true);
    setselectclient(null);
  };
  const [selectclient, setselectclient] = useState(null);
  const deleteEventHandler = (rec) => {
    setselectclient(rec);
    setAnchorEl(null);
  };
  const handleEdit = (rec) => {
    setselectclient(rec);
    setModalShow(true);
    setOpen(true);
    setAnchorEl(null);
  };
 
  return (
    <>
      <ManageRefractive
        open={open}
        onHide={hideHandle}
        title={title ? title : "Final Refractive"}
        setTitle={setTitle}
        selectclient={selectclient}
        examBase={examBase}
        setModalShow={setModalShow}
        setFinalRxDetails={setFinalRxDetails}
        finalRxDetails={finalRxDetails}
        setIsUpdated={setIsUpdated}
        frameMaterialData={frameMaterialData}
       
        patientId={patientId}
      />
      <Grid container spacing={4} style={{ width: "100%" }}>
        <Grid>
          {" "}
          <div
            className={classes.pageTitleContainer}
            style={{ display: "flex", justifyContent: "end" }}
          >
           
            <Button
              style={{
                marginRight: "8px",
                fontSize: "10px",
                position: "absolute",
                borderRadius: "2px",
                right: "60px",
                background: "#4E97FD",
              }}
              classes={{ root: classes.button }}
              variant="contained"
              color="secondary"
              onClick={addRefractiveModel}
            >
              Add Final Refractive
            </Button>
            
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {finalRxData && finalRxData?.length > 0 ? (
                finalRxData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_name}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          OD
                          <br />
                          <span style={{ color: "#0586FB" }}>OS</span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_sphere_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            {row.fr_sphere_os}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_cyl_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            {row.fr_cyl_os}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_axis_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            {row.fr_axis_os}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_add_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            {row.fr_add_os}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_hprism_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            {row.fr_hprism_os}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_hbase_od_var}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                          {row.fr_hbase_os_var}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_vprism_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            {row.fr_vprism_os}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_vbase_od_var}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            {row.fr_vbase_os_var}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_dva_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            {row.fr_dva_os}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_nva_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            {row.fr_nva_os}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.is_active === "Y" ? (
                            <span
                              style={{
                                background: "green",
                                color: "#fff",
                                borderRadius: "25px",padding: '2px 6px',
                              }}
                            >
                              Active
                            </span>
                          ) : (
                            <span style={{
                              background: "gray",
                              color: "#333",
                              borderRadius: "25px",
                            }}>InActive</span>
                          )}
                        </TableCell>

                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            id="basic-button"
                            aria-controls={openDown ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openDown ? "true" : undefined}
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem onClick={() => handleEdit(selectRow)}>
                              Edit
                            </MenuItem>
                            <MenuItem
                              onClick={() => deleteEventHandler(selectRow)}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
