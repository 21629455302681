import React, { useState, useEffect } from "react";
import { Button, Grid, Menu, MenuItem, Typography } from "@material-ui/core";

import { dateFormatConversion, loadState } from "../../../hooks/helper";
import AllergyChart from './AllergyChart'
import TableWidget from "../../Widget/TableWidget";

import TableDesign from "../../common/table/TableDesign";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
// styles
import useStyles from "../styles";

import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";
import { getAddBinoTestData, getAllergyHistoryData } from "../../../services/ServiceLayer";

import moment from "moment";

export default function AddTest(props) {
  const tableHeadings = [
   
    {
      label: "Eoms ",
    },
    {
      label: "Confrontational Field ",
    },
    {
      label: "TBUT(Sec.) ",
    },
    {
      label: "Binocular Function/Seereopsis",
    },
  
    // {
    //   label: "Notes",
    // },
  ];
  const { patientId} = props;



 
  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [binoTestData, setBinoTestData] = useState([]);
  useEffect(() => {
    getAddBinoTestData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELBNO`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setBinoTestData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);
  
  return (
    <>
   
      <Grid container spacing={4} style={{ width: "100%" }}>
        <Grid item xs={12}>
        <div style={{background:'#5DB3D9',padding:'10px',color:'#fff',textAlign:'center',borderRadius:'5px',marginBottom:'2px'}}>Additional Testing</div>
        <AllergyChart />
          {/* <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {binoTestData && binoTestData?.length ? (
                binoTestData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        

                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {row?.bin_ad_eoms}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.bin_ad_confrontal}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.bin_ad_tbut_od} <br/>
                        <span style={{ color: "#0586FB" }}>{row?.bin_ad_tbut_os}</span>
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.bin_ad_bin_od} <br/>
                        <span style={{ color: "#0586FB" }}>{row?.bin_ad_bin_os}</span>
                        </TableCell>
                       
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget> */}
        </Grid>
      </Grid>
    </>
  );
}
