import React, { useState, useEffect } from "react";
import useStyles from "../../styles";
import {
  Grid,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Select,
  FormControl,

  Checkbox,
  Button,

  FormLabel,
} from "@material-ui/core";

import { serviceFormElements } from "../order_constant";


import "react-toastify/dist/ReactToastify.css";
import TableForService from "./TableForService";

import { Autocomplete } from "@mui/material";

export default function ServiceData({
  doctorList,
  labStatusData,
  selectedRowAll,
  setFrameDataDetail,
  frameDataDetail,
  setEyeTypeData,
  eyeTypeData,
  labDetailData,
}) {
  console.log("selectedRowAll", selectedRowAll);
 
  var classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  const [selectedRadio, setSelectedRadio] = useState("service");
  const [totalRetailPrice, setTotalRetailPrice] = useState(0);
  const [totalTaxPrice, setTotalTaxPrice] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [selectedItems, setSelectedItems] = useState();
  const [shippingAddressError, setShippingAddressError] = useState("");
  useEffect(() => {
    console.log("totalRetailPrice", totalRetailPrice);
  }, [totalRetailPrice]);
  const [shipToChecked, setShipToChecked] = useState(false);
  useEffect(() => {
    if (selectedRowAll) {
      setSelectedItems(selectedRowAll);
      if (selectedRowAll.ship_to === "1") {
        setShipToChecked(true);
      }
    }
  }, [selectedRowAll]);
  const selectPrevFrame = () => {
    setOpen1(true);
  };
 
  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
  };

  const handleShipToChange = () => {
    setShipToChecked(!shipToChecked);
    if (shipToChecked) {
      setFrameDataDetail({
        ...frameDataDetail,
        ship_to: "1",
      });
      // setShipToChecked(true);
    } else {
      setFrameDataDetail({
        ...frameDataDetail,
        ship_to: "0",
      });
      // setShipToChecked(false);
    }
  };

  const [options, setOptions] = useState([]);
  // const previousController = useRef();

  const getData = (searchTerm) => {
    // if (previousController.current) {
    //   previousController.current.abort();
    // }
    const controller = new AbortController();
    const signal = controller.signal;
    // previousController.current = controller;
    fetch(
      `https://api.geoapify.com/v1/geocode/autocomplete?text=${searchTerm}&apiKey=91c06bd162924c2d9b2b11d094364f49`,
      {
        signal,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(function (response) {
        console.log("response", response);
        return response.json();
      })
      .then(function (myJson) {
        console.log(
          "search term: " + searchTerm + ", results: ",
          myJson.products
        );
        const updatedOptions = myJson.features.map((p) => {
          return { title: p.properties.formatted };
        });
        setOptions(updatedOptions);
      });
  };

  const onInputChange = (event, value, reason) => {
    if (value) {
      getData(value);
    } else {
      setOptions([]);
    }
  };
  return (
    <div>
      <></>
      <Grid
        container
        item
        spacing={1}
        style={{
          marginBottom: "10px",
          borderBottom: "1px solid #A8A8B0",
        }}
      >
        <Grid item xs={12} style={{ display: "flex" }}>
          <Grid item md={4}>
           
            <FormControl style={{ fontSize: "14px" }}>
              <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
              <RadioGroup
                size="sm"
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={selectedRadio}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  size="sm"
                  value="options"
                  control={<Radio />}
                  label="Options"
                />
                <FormControlLabel
                  size="sm"
                  value="service"
                  control={<Radio />}
                  label="Services"
                />
                <FormControlLabel
                  size="sm"
                  value="misc"
                  control={<Radio />}
                  label="Misc"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item md={8}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                className={classes.typo}
                weight="medium"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <span
                  style={{
                    // background: "#4472C4",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    height: "32px",
                    padding: "6px",
                    color: "#4472C4",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Total Tax =
                </span>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // border: "1px solid #4472c4",
                    width: "64px",
                    marginRight: "10px",
                    textAlign: "center",
                    color: "#4472C4",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {totalTaxPrice}
                </div>
                <span
                  style={{
                    // background: "#4472C4",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    height: "32px",
                    padding: "6px",
                    color: "#4472C4",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Total Amount =
                </span>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // border: "1px solid #4472c4",
                    width: "64px",
                    marginRight: "10px",
                    textAlign: "center",
                    color: "#4472C4",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {!isNaN(totalRetailPrice) ? totalRetailPrice.toFixed(2) : "0"}
                </div>
                <span
                  style={{
                    color: "#4472C4",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    height: "32px",
                    padding: "6px",

                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Total Discount =
                </span>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // border: "1px solid #4472c4",
                    width: "64px",
                    marginRight: "10px",
                    textAlign: "center",
                    color: "#4472C4",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {totalDiscount}
                </div>
              </Typography>
            </div>
          </Grid>
        </Grid>
        {selectedRadio === "service" && (
          <TableForService
            eyeTypeData={eyeTypeData}
            selectedItems={selectedItems}
            frameDataDetail={frameDataDetail}
            setFrameDataDetail={setFrameDataDetail}
            totalRetailPrice={totalRetailPrice}
            setTotalRetailPrice={setTotalRetailPrice}
            totalTaxPrice={totalTaxPrice}
            setTotalTaxPrice={setTotalTaxPrice}
            totalDiscount={totalDiscount}
            setTotalDiscount={setTotalDiscount}
          />
        )}
        {selectedRadio === "misc" && (
          <></>
          // <TableForAccess
          //   frameDataDetail={frameDataDetail}
          //   setFrameDataDetail={setFrameDataDetail}
          //   totalRetailPrice={totalRetailPrice}
          //   setTotalRetailPrice={setTotalRetailPrice}
          //   totalTaxPrice={totalTaxPrice}
          //   setTotalTaxPrice={setTotalTaxPrice}
          //   totalDiscount={totalDiscount}
          //   setTotalDiscount={setTotalDiscount}
          // />
        )}
      </Grid>
      <Grid
        container
        spacing={1}
        style={{
          marginBottom: "16px",
          marginTop: "16px",
        }}
      >
        {serviceFormElements.map((element, i) => {
          return (
            <Grid
              item
              xs={12}
              md={element.mdSize}
              className={`${classes.textGroup} ${
                element.title ? classes.highlightedItem : ""
              }`}
              style={{ border: "1px solid #DED9D9" }}
            >
              <Typography
                className={classes.formHeading}
                style={{
                  fontWeight: "500",
                  color: "#4E97FD",
                  textWrap: "nowrap",
                  display:
                    (shipToChecked &&
                      (element.key === "shipping_address_1" ||
                        element.key === "state" ||
                        element.key === "shipping_address_2" ||
                        element.key === "city" ||
                        element.key === "zip")) ||
                    (element.key !== "shipping_address_1" &&
                      element.key !== "state" &&
                      element.key !== "shipping_address_2" &&
                      element.key !== "city" &&
                      element.key !== "zip")
                      ? "block"
                      : "none",
                }}
              >
                {element.label}&nbsp;{" "}
                {element.required === "true" ? (
                  <span className={classes.required}>*</span>
                ) : null}{" "}
                {element.copyBtn === "true" && (
                  <Button
                    classes={{ root: classes.button }}
                    variant="contained"
                    color="secondary"
                    onClick={selectPrevFrame}
                  >
                    =
                  </Button>
                )}
              </Typography>

              {element.type ? (
                <>
                  {element.type === "select" &&
                  element.key !== "procedure_code" ? (
                    <FormControl
                      fullWidth
                      className={classes.dropdownFormControl}
                      style={{
                        display:
                          (shipToChecked && element.key === "state") ||
                          element.key !== "state"
                            ? "block"
                            : "none",
                      }}
                    >
                      <Select
                        name={element.key}
                        className={classes.selectDropdown}
                        placeholder={element.placeHolder}
                        style={{ width: "100%" }}
                        value={
                          frameDataDetail && frameDataDetail[element.key]
                            ? frameDataDetail && frameDataDetail[element.key]
                            : ""
                        }
                        onChange={(e) => {
                          setFrameDataDetail({
                            ...frameDataDetail,
                            [element.key]: e.target.value,
                          });
                        }}
                      >
                        <MenuItem disabled value="">
                          Select
                        </MenuItem>
                        {element.key === "lab_id" && labDetailData
                          ? labDetailData.map((item, i) => (
                              <MenuItem key={i} value={item.lab_id}>
                                {item.lab_name}
                              </MenuItem>
                            ))
                          : element.key === "status" && labStatusData
                          ? labStatusData.map((item, i) => (
                              <MenuItem key={i} value={item.attribute_id}>
                                {item.attribute_val}
                              </MenuItem>
                            ))
                          : element.key === "lab_order_status" && labStatusData
                          ? labStatusData.map((item, i) => (
                              <MenuItem key={i} value={item.attribute_id}>
                                {item.attribute_val}
                              </MenuItem>
                            ))
                          : (element.key === "order_delevered_by_id" ||
                              element.key === "order_inspected_by_id") &&
                            doctorList
                          ? doctorList.map((item, i) => (
                              <MenuItem key={i} value={item.staff_id}>
                                {item.first_name} {item.middle_name}{" "}
                                {item.last_name}
                              </MenuItem>
                            ))
                          : element.options.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  ) : element.type === "text" && element.key=== "shipping_address_1" && shipToChecked ? (
                    <Autocomplete
                      id="combo-box-demo"
                      options={options}
                      onInputChange={onInputChange}
                      getOptionLabel={(option) => option.title}
                      filterOptions={(options) => options}
                      style={{ width: 300, height: 36, marginBottom: 10 }}
                      value={frameDataDetail && frameDataDetail[element.key]} 
                      onChange={(event, newValue) => {
                        console.log("newValue",newValue);
                        setFrameDataDetail({
                          ...frameDataDetail,
                            [element.key]: newValue?.title,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          style={{ height: 36 }}
                          {...params}
                          label=""
                          variant="outlined"
                        />
                      )}
                    />
                  ) : element.type === "text" ? (
                    <div style={{ display: "flex" }}>
                      <TextField
                        required={
                          shipToChecked && element.key === "shipping_address_1"
                        }
                        name={element.key}
                        placeholder={element.placeHolder}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                          maxLength: element.maxLength,
                          onKeyPress: element.onKeyPress,
                        }}
                        style={{
                          display:
                            (shipToChecked &&
                              (element.key === "shipping_address_1" ||
                                element.key === "shipping_address_2" ||
                                element.key === "city" ||
                                element.key === "zip")) ||
                            (element.key !== "shipping_address_1" &&
                              element.key !== "shipping_address_2" &&
                              element.key !== "city" &&
                              element.key !== "zip")
                              ? "block"
                              : "none",
                        }}
                        value={frameDataDetail && frameDataDetail[element.key]}
                        onChange={(e) => {
                          console.log("selected");
                          setFrameDataDetail({
                            ...frameDataDetail,
                            [element.key]: e.target.value,
                          });
                          if (
                            shipToChecked &&
                            (element.key === "shipping_address_1" ||
                              element.key === "shipping_address_2") &&
                            !e.target.value
                          ) {
                            setShippingAddressError("This field is required.");
                          } else {
                            setShippingAddressError(""); // Clear the error if the value is not null
                          }
                        }}
                        // helperText={shippingAddressError ? "This field is required" : ""}
                        // error={error}
                      />
                      {element.key === "sku" && (
                        <Button
                          // onClick={selectFrameBySku}
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                        >
                          Slect
                        </Button>
                      )}
                    </div>
                  ) : element.type === "commission_amount" ? (
                    <TextField
                      type="text"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      size="small"
                      inputProps={{
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                        maxLength: element.maxLength,
                        onKeyPress: element.onKeyPress,
                      }}
                      value={frameDataDetail && frameDataDetail[element.key]}
                      onChange={(e) => {
                        console.log("selected");
                        setFrameDataDetail({
                          ...frameDataDetail,
                          [element.key]: e.target.value,
                        });
                      }}
                    />
                  ) : element.type === "date" ||
                    element.type === "datetime-local" ? (
                    <TextField
                      id={element.key}
                      name={element.key}
                      placeholder={element.placeHolder}
                      type={element.type}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={frameDataDetail && frameDataDetail[element.key]}
                      onChange={(e) => {
                        console.log("selected");
                        setFrameDataDetail({
                          ...frameDataDetail,
                          [element.key]: e.target.value,
                        });
                      }}
                    />
                  ) : element.type === "radio" ? (
                    <RadioGroup row defaultValue="">
                      {element.radioValue.map((item) => (
                        <FormControlLabel
                          size="small"
                          style={{ padding: "0px 6px" }}
                          value={item}
                          control={<Radio />}
                          label={item}
                          className={classes.smallRadioButton}
                        />
                      ))}
                    </RadioGroup>
                  ) : element.type === "checkbox" ? (
                    <>
                      <Checkbox
                        name={element.key}
                        checked={shipToChecked}
                        onChange={handleShipToChange}
                      />
                    </>
                  ) : element.type === "button" ? (
                    <>
                      <Button
                        classes={{ root: classes.button }}
                        variant="contained"
                        color="secondary"
                        onClick={selectPrevFrame}
                      >
                        Tray Label..
                      </Button>
                    </>
                  ) : (
                    <TextField
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      placeholder="Type here..."
                      variant="outlined"
                      multiline
                      rows={3}
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      value={frameDataDetail && frameDataDetail[element.key]}
                      onChange={(e) => {
                        console.log("selected");
                        setFrameDataDetail({
                          ...frameDataDetail,
                          [element.key]: e.target.value,
                        });
                      }}
                    />
                  )}
                  {shippingAddressError &&
                    (element.key === "shipping_address_1" ||
                      element.key === "shipping_address_2") && (
                      <Typography variant="caption" color="error">
                        {shippingAddressError}
                      </Typography>
                    )}
                </>
              ) : (
                <>
                  <Typography
                    className={classes.formHeading}
                    style={{
                      fontWeight: "600",

                      color: "#4E97FD",
                    }}
                  >
                    {element.title}
                  </Typography>
                </>
              )}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
