import React from 'react'
import AddRecall from '../../components/Inventory/Services/AddRecall'

export default function newRecall() {
  return (
    <div>
        
        <AddRecall/>
    </div>
  )
}
