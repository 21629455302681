import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  link: {
    // color:'#fff',
    textDecoration: "none",
    "&:hover, &:focus": {
      color: '#000',
      backgroundColor: '#E6F4FF',
      borderRight:'2px solid #E6F4FF'
    },
   
  },
  linkActive: {
    color: '#000',
    // backgroundColor: '#FFFFFF',
    borderRight:'2px solid#4E97FD'
  },
  linkNested: {
    
    "&:hover, &:focus": {
      color: '#000',
      // backgroundColor: '#FFFFFF',
      borderRight:'2px solid#4E97FD'
    },
  },
  linkIcon: {
    // marginRight: theme.spacing(1),
    // color: theme.palette.text.secondary + "99",
    // transition: theme.transitions.create("color"),
    color:"#000",
    width: '70px',
    // height:'16px',
    display: "flex",
    justifyContent: "center",
    fontSize:"22px",
  },
  linkIconActive: {
    color:'#000',
    // backgroundColor: theme.palette.background.light,
    // color: theme.palette.primary.main,
  },
  linkText: {
    color:'#000',
    // color:'#fff !important',
    // backgroundColor: 'theme.palette.background.light',
    padding: 0,
    // color: '#201F1E',
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 16,
    fontWeight:500,
    fontFamily:'Poppins',
  
  },
  linkTextActive: {
    color: '#201F1E',
    fontWeight:500,
    fontFamily:'Poppins'
  },
  linkTextHidden: {
    opacity: 0,
  },
  nestedList: {
    paddingLeft: theme.spacing(0.5),

  },
  sectionTitle: {
    color:'#fff',
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 1,
    backgroundColor: "#D8D8D880",
  },
}));
