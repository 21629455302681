import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  // Checkbox,
  Switch,
  // IconButton,
  // Menu,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../common/modal/Popup";
// styles
import useStyles from "../styles";
// import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";

import moment from "moment";
import {
  currentUser,
  handleAddressKeyPress,
  handleAddressKeyPressPaste,
  loadState,
  validateLargeAmount,
} from "../../../hooks/helper";
import {
  // postPatientDisclosure,
  postPatientPupil,
} from "../../../services/ServiceLayer";
import { toast } from "react-toastify";

export default function ManagePupil(props) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  //
  const formElements = [
    {
      label: "OD",
      mdSize: "1",
    },
    {
      key: "od_light",
      label: "Light",
      placeHolder: "0",
      type: "text",
      mdSize: "2",
      maxLength:4,
      onKeyPress: validateLargeAmount,
    },
    {
      key: "od_dark",
      label: "Dark",
      placeHolder: "0",
      type: "text",
      mdSize: "2",
      maxLength:4,
      onKeyPress: validateLargeAmount,
    },
    {
      key: "od_near",
      label: "Near",
      placeHolder: "0",
      type: "text",
      mdSize: "2",
      maxLength:4,
      onKeyPress: validateLargeAmount,
    },
    {
      key: "od_size",
      label: "Size",
      placeHolder: "select",
      type: "select",
      options: [""],
      mdSize: "2",
    },
    {
      key: "od_shape",
      label: "shape",
      placeHolder: "select",
      type: "select",
      options: [""],
      mdSize: "3",
    },

    {
      label: "OS",
      mdSize: "1",
    },
    {
      key: "os_light",
      label: "",
      placeHolder: "0",
      type: "text",
      mdSize: "2",
      maxLength:4,
      onKeyPress: validateLargeAmount,
    },
    {
      key: "os_dark",
      label: "",
      placeHolder: "0",
      type: "text",
      mdSize: "2",
      maxLength:4,
      onKeyPress: validateLargeAmount,
    },
    {
      key: "os_near",
      label: "",
      placeHolder: "0",
      type: "text",
      mdSize: "2",
      maxLength:4,
      onKeyPress: validateLargeAmount,
    },
    {
      key: "os_size",
      label: "",
      placeHolder: "select",
      type: "select",
      options: [""],
      mdSize: "2",
    },
    {
      key: "os_shape",
      label: "",
      placeHolder: "select",
      type: "select",
      options: [""],
      mdSize: "3",
    },

    {
      label: "OD",
      mdSize: "1",
    },

    {
      key: "od_npi",
      label: "NPI",
      placeHolder: "select",
      type: "select",
      options: [""],
      mdSize: "2",
    },
    {
      key: "od_reacts",
      label: "Reacts",
      placeHolder: "select",
      type: "select",
      options: [""],
      mdSize: "2",
    },
    {
      key: "od_apd",
      label: "APD",
      placeHolder: "select",
      type: "select",
      options: [""],
      mdSize: "2",
    },
    {
      key: "od_rapd",
      label: "RAPD",
      placeHolder: "select",
      type: "select",
      options: [""],
      mdSize: "2",
    },
    {
      key: "od_others",
      label: "Other",
      placeHolder: "enter other",
      type: "text",
      mdSize: "3",
      maxLength:50,
      onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
    },
    {
      label: "OS",
      mdSize: "1",
    },

    {
      key: "os_npi",
      label: "",
      placeHolder: "select",
      type: "select",
      options: [""],
      mdSize: "2",
    },
    {
      key: "os_reacts",
      label: "",
      placeHolder: "select",
      type: "select",
      options: [""],
      mdSize: "2",
    },
    {
      key: "os_apd",
      label: "",
      placeHolder: "select",
      type: "select",
      options: [""],
      mdSize: "2",
    },
    {
      key: "os_rapd",
      label: "",
      placeHolder: "select",
      type: "select",
      options: [""],
      mdSize: "2",
    },
    {
      key: "os_others",
      label: "",
      placeHolder: "enter other",
      type: "text",
      mdSize: "3",
      maxLength:50,
      onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
    },
  ];
  console.log("props-24", props);
  const {
    selectclient,
    patientId,

    examExternalPupilAPD,
    examExternalPupilNPI,
    examExternalPupilRAPD,
    examExternalPupilReacts,
    examExternalPupilShape,
    examExternalPupilSize,
    pupilId,
    setPupilId,
  } = props;
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [actionType, setActionType] = useState("ADDDIS");
  console.log("selectclient", selectclient);
  const [isShow, setIsShow] = useState(false);
  //
  var classes = useStyles();

  const [maxWidth, setMaxWidth] = React.useState("md");
  const [open, setOpen] = React.useState(false);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [disclosureDetails, setDisclosureDetails] = useState({
    pat_extpup_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    od_light: "",
    od_dark: "",
    od_near: "",
    od_size: "",
    od_shape: "",
    os_light: "",
    os_dark: "",
    os_near: "",
    os_size: "",
    os_shape: "",
    od_npi: "",
    od_reacts: "",
    od_apd: "",
    od_rapd: "",
    od_others: "",
    os_npi: "",
    os_reacts: "",
    os_apd: "",
    os_rapd: "",
    os_others: "",
    updated_by: currentUser(),
    access_location: "ggg",
    opt_type: "ADDPLP",
  });
  const clearForm = () => {
    setDisclosureDetails({
      pat_extpup_id: 0,
      patient_id: patientId,
      location_id: userData?.location_id,
      od_light: "",
      od_dark: "",
      od_near: "",
      od_size: "",
      od_shape: "",
      os_light: "",
      os_dark: "",
      os_near: "",
      os_size: "",
      os_shape: "",
      od_npi: "",
      od_reacts: "",
      od_apd: "",
      od_rapd: "",
      od_others: "",
      os_npi: "",
      os_reacts: "",
      os_apd: "",
      os_rapd: "",
      os_others: "",
      updated_by: currentUser(),
      access_location: "ggg",
      opt_type: "ADDPLP",
    });
  };
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setDisclosureDetails({
        pat_extpup_id: 0,
        patient_id: patientId,
        location_id: userData?.location_id,
        od_light: "",
        od_dark: "",
        od_near: "",
        od_size: "",
        od_shape: "",
        os_light: "",
        os_dark: "",
        os_near: "",
        os_size: "",
        os_shape: "",
        od_npi: "",
        od_reacts: "",
        od_apd: "",
        od_rapd: "",
        od_others: "",
        os_npi: "",
        os_reacts: "",
        os_apd: "",
        os_rapd: "",
        os_others: "",
        updated_by: currentUser(),
        access_location: "ggg",
        opt_type: "ADDPLP",
      });
    } else {
      setDisclosureDetails({
        pat_extpup_id: selectclient?.pat_extpup_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        od_light: selectclient?.od_light,
        od_dark: selectclient?.od_dark,
        od_near: selectclient?.od_near,
        od_size: selectclient?.od_size,
        od_shape: selectclient?.od_shape,
        os_light: selectclient?.os_light,
        os_dark: selectclient?.os_dark,
        os_near: selectclient?.os_near,
        os_size: selectclient?.os_size,
        os_shape: selectclient?.os_shape,
        od_npi: selectclient?.od_npi,
        od_reacts: selectclient?.od_reacts,
        od_apd: selectclient?.od_apd,
        od_rapd: selectclient?.od_rapd,
        od_others: selectclient?.od_others,
        os_npi: selectclient?.os_npi,
        os_reacts: selectclient?.os_reacts,
        os_apd: selectclient?.os_apd,
        os_rapd: selectclient?.os_rapd,
        os_others: selectclient?.os_others,
        updated_by: currentUser(),
        access_location: "ggg",
        opt_type: "ADDPLP",
      });
    }
  }, [selectclient]);
  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    // if (!isError() && error !== null && isActionCalled) {
    if (isActionCalled) {
      postPatientPupil(disclosureDetails).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Patient Pupils added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          const pupilId = response?.data[0]?.fn_exam_patient_pupil.replace('SUCCESS-', '');
          setPupilId(pupilId)
          clearForm();
          props.onHide();
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    // } else {
    //   setIsActionCalled(false);
    // }

    setIsActionCalled(false);
  }, [isActionCalled]);
  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleCheckboxChange = (copy, paste, e) => {
    console.log("copy", copy);
    console.log("copy to", paste);
    setAnchorEl(null);
    setDisclosureDetails({
      ...disclosureDetails,
      [`${paste}_light`]: disclosureDetails?.[`${copy}_light`],
      [`${paste}_dark`]: disclosureDetails?.[`${copy}_dark`],
      [`${paste}_near`]: disclosureDetails?.[`${copy}_near`],
      [`${paste}_size`]: disclosureDetails?.[`${copy}_size`],
      [`${paste}_shape`]: disclosureDetails?.[`${copy}_shape`],
      [`${paste}_npi`]: disclosureDetails?.[`${copy}_npi`],
      [`${paste}_reacts`]: disclosureDetails?.[`${copy}_reacts`],
      [`${paste}_apd`]: disclosureDetails?.[`${copy}_apd`],
      [`${paste}_rapd`]: disclosureDetails?.[`${copy}_rapd`],
      [`${paste}_others`]: disclosureDetails?.[`${copy}_others`],
    });
  };
  
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        // fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleSubmit}
            >
              Add
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={2}
              className={classes.formBox}
              // style={{ marginBottom: "4px" }}
            >
               <Grid
          item
          xs={12}
          md={12}
          className={classes.textGroup}
          style={{ padding: "0px", display: "flex", justifyContent: "end",fontSize:'10px' }}
        >
           <Button
            className={classes.btnlRightDark}
            variant="contained"
            style={{ fontSize: "10px",marginRight:'10px' }}
            size="small"
            color="secondary"
            onClick={() => handleCheckboxChange("od", "os")}
          >
           Copy OD to OS
          </Button>
          <Button
            className={classes.btnlRightDark}
            variant="contained"
            style={{ fontSize: "10px", }}
            size="small"
            color="secondary"
            onClick={() => handleCheckboxChange("os", "od")}
          >
           Copy OS to OD
          </Button>
          
        </Grid>
              {formElements.map((element, i) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={classes.textGroup}
                    >
                      {element.label && (
                        <Typography
                          style={{ fontSize: "13px" }}
                          className={classes.formHeading}
                        >
                          {element.label}{" "}
                          {element.required === "true" ? (
                            <span className={classes.required}>*</span>
                          ) : null}
                        </Typography>
                      )}
                      {element.type ? (
                        <>
                          {element.type === "select" ? (
                            <FormControl 
                              fullWidth
                              className={classes.dropdownFormControl}
                            >
                              <Select style={{fontSize:'12px'}}
                                name={element.key}
                                className={classes.selectDropdown}
                                placeholder={element.placeHolder}
                                value={
                                  disclosureDetails &&
                                  disclosureDetails[element.key]
                                    ? disclosureDetails &&
                                      disclosureDetails[element.key]
                                    : ""
                                }
                               
                                onChange={(e) => {
                                  console.log("selected", e.target.value);

                                  setDisclosureDetails({
                                    ...disclosureDetails,
                                    [element.key]: e.target.value,
                                  });

                                  // resetValidator(e.target.name);
                                }}
                                
                              >
                                <MenuItem disabled value="">
                                  Select
                                </MenuItem>
                                {(element.key === "os_size" ||
                                  element.key === "od_size") &&
                                examExternalPupilSize
                                  ? examExternalPupilSize.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : (element.key === "od_shape" ||
                                      element.key === "os_shape") &&
                                    examExternalPupilShape
                                  ? examExternalPupilShape.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : (element.key === "od_npi" ||
                                      element.key === "os_npi") &&
                                    examExternalPupilNPI
                                  ? examExternalPupilNPI.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : (element.key === "od_reacts" ||
                                      element.key === "os_reacts") &&
                                    examExternalPupilReacts
                                  ? examExternalPupilReacts.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : (element.key === "od_apd" ||
                                      element.key === "os_apd") &&
                                    examExternalPupilAPD
                                  ? examExternalPupilAPD.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : (element.key === "od_rapd" ||
                                      element.key === "os_rapd") &&
                                    examExternalPupilRAPD
                                  ? examExternalPupilRAPD.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.options.map((item) => (
                                      <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                              </Select>
                            </FormControl>
                          ) : element.type === "text" ? (
                            <TextField
                              required
                              name={element.key}
                              placeholder={element.placeHolder}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                                maxLength: element.maxLength,
                                onKeyPress: element.onKeyPress,
                                onPaste: element.onpaste,
                              }}
                              value={
                                disclosureDetails &&
                                disclosureDetails[element.key]
                                  ? disclosureDetails &&
                                    disclosureDetails[element.key]
                                  : ""
                              }
                              onChange={(e) => {
                                console.log("selected");
                                setDisclosureDetails({
                                  ...disclosureDetails,
                                  [element.key]: e.target.value,
                                });
                                // resetValidator(e.target.name);
                              }}
                            />
                          ) : element.type === "date" ||
                            element.type === "datetime-local" ? (
                            <TextField
                              id={element.key}
                              name={element.key}
                              placeholder={element.placeHolder}
                              type={element.type}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                min: `${currentDateTime}`,
                                max: `${currentDate}`,
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onKeyDown={(e) => {
                                e.preventDefault(); // Prevent manual typing
                              }}
                              value={
                                disclosureDetails &&
                                disclosureDetails[element.key]
                                  ? disclosureDetails &&
                                    disclosureDetails[element.key]
                                  : ""
                              }
                              onChange={(e) => {
                                console.log("selected");
                                setDisclosureDetails({
                                  ...disclosureDetails,
                                  [element.key]: e.target.value,
                                });
                                // resetValidator(e.target.name);
                              }}
                            />
                          ) : element.type === "textarea" ? (
                            <TextField
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              placeholder="Type here..."
                              variant="outlined"
                              multiline
                              rows={3}
                              inputProps={{
                                style: {
                                  fontSize: "12px",
                                },
                                maxLength: element.maxLength,
                                onKeyPress: element.onKeyPress,
                                onPaste: element.onpaste,
                              }}
                              value={
                                disclosureDetails &&
                                disclosureDetails[element.key]
                                  ? disclosureDetails &&
                                    disclosureDetails[element.key]
                                  : ""
                              }
                              onChange={(e) => {
                                console.log("selected");
                                setDisclosureDetails({
                                  ...disclosureDetails,
                                  [element.key]: e.target.value,
                                });
                                // resetValidator(e.target.name);
                              }}
                            />
                          ) : element.type === "button" ? (
                            <Button
                              classes={{ root: classes.button }}
                              variant="contained"
                              color="secondary"
                              style={{
                                width: "100%",
                                fontSize: "10px",
                                marginTop: "26px",
                              }}
                            >
                              {element.btnName}
                            </Button>
                          ) : (
                            <Switch />
                          )}
                        </>
                      ) : (
                        <>
                          <Typography
                            className={classes.formHeading}
                            style={{
                              fontWeight: "600",

                              color: "#4E97FD",
                            }}
                          >
                            {element.title}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
