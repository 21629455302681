import React from 'react'
import ServicesList from '../../components/Inventory/ServicesList'

export default function serviceType() {
  return (
    <>
        <ServicesList />

        </>
  )
}
