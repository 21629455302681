import React, { useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { Grid, Tabs, Tab, Paper, Button } from "@material-ui/core";

import PageTitle from "../../components/PageTitle/PageTitle";

import backArrow from "../patient/Cases/backArrow.svg";

import useStyles from "../patient/Cases/styles";

import { getDoctorList, getPatient } from "../../services/ServiceLayer";

import { loadState } from "../../hooks/helper";

import { toast } from "react-toastify";
import moment from "moment";
import Problem from "../../components/ExamComponent/Problem";
import HealthTab from "../../components/ExamComponent/HealthTabs/HealthTab";
import Glyphs from "../../components/ExamComponent/GlyphsTab/Glyphs";
import ExternalMain from "../../components/ExamComponent/ExternalTab/ExternalMain";
import VisualAcuityTab from "../../components/ExamComponent/VisualTab/VisualAcuityTab";
import Refractive from "../../components/ExamComponent/RefractiveTab/Refractive";
import InternalMain from "../../components/ExamComponent/ExternalTab/InternalMain";
import MainContactTab from "../../components/ExamComponent/ContactTab/MainContactTab";
import MainBinocular from "../../components/ExamComponent/BinoTab/MainBinocular";
import { useParams } from "react-router-dom";
import MainAssessmentTab from "../../components/ExamComponent/AssessmentTab/MainAssessmentTab";

import MainChart from "../../components/ExamComponent/ChartTab/MainChart";
import clock from "../../assets/clock.png";

import AlertPopup from "./AlertPopup";
import useListData from "../../components/common/allMetaData/useListData";
import HealthChart from "../../components/ExamComponent/ChartTab/HealthChart";
export default function NewExam() {
  var classes = useStyles();
  var [activeTabId, setActiveTabId] = useState(0);

  const [isActionCalled, setIsActionCalled] = useState(false);
  const { patient_id } = useParams();

  const [doctorList, setDoctorList] = useState([]);
  const listData = useListData();

  toast.configure();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const history = useHistory();
  const [patientData, setPatientData] = useState([]);
  // const navigate = useNavigate();
  const goBack = () => {
    history.push(`/app/Exam`);
  };
  useEffect(() => {
    getDoctorList(
      `user_id=${userData?.user_id}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSCH`
    ).then((response) => {
      console.log(response);
      setDoctorList(response?.data);
    });
  }, []);
  useEffect(() => {
    // patient details
    getPatient(
      `patient_id=${patient_id}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELHIS`
    ).then((response) => {
      console.log(response);
      setPatientData(response?.data);
    });
  }, []);

  const {
    eyesType,
    relTypeValues,
    reasonType,
    impactedPart,
    frequency,
    severity,
    symptons,
    factors,
    examSince,
    occurseWhile,
    examProblemStatus,
    allergySeverity,
    allergyOutcome,
    allergyReaction,
    disclosureType,
    surgriesClassification,
    surgriesOccurance,
    surgriesOutcome,
    surgriesType,
    examInternalCDRatio,
    examInternalVitreous,
    examInternalLens,
    examInternalAVRatio,
    examInternalMacula,
    examInternalOpticDisc,
    examInternalAVRetina,
    examInternalVessels,
    examExternalDilation,
    examExternalCounsel,
    examExternalFatProlapse,
    examExternalPupilAPD,
    examExternalPupilNPI,
    examExternalBinocular,
    examExternalPupilRAPD,
    examExternalPupilReacts,
    examExternalPupilShape,
    examExternalPupilSize,
    examExternalMethod,
    examExternalEyeLid,
    examExternalCornea,
    examExternalTearFilm,
    examExternalOrbit,
    examExternalTearIris,
    examExternalLacrimal,
    examExternalAnteriorChamber,
    examExternalConjunctiva,
    examExternalSclera,
    examExternalPreauricularNode,
    examChartType,
    examCorrectionType,
    examDominantEye,
    examAcuityNotes,
    examCurrentAcuity,
    frameBrandList,
    examMode,
    examBase,
    frameMaterialData,
    examFamilyRelation,
    examEyeType,
  } = listData;
  // call meta data

  const handleTabChange = (event, newValue) => {
    console.log("newValue", newValue);
    setActiveTabId(newValue);
    window.scrollTo(0, 0);
  };
  // timer
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [timerSeconds, setTimerSeconds] = useState("");
  const [timerStopValue, setTimerStopValue] = useState("");

  useEffect(() => {
    let intervalId;

    if (isTimerRunning) {
      intervalId = setInterval(() => {
        setTimerSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isTimerRunning]);

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    return `${hours > 0 ? hours + "h " : ""}${
      minutes > 0 ? minutes + "m " : ""
    }${seconds}s`;
  };
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const handleScroll = () => {
    const scrollTop = window.scrollY;

    setIsScrollingUp(scrollTop > 40); // Change the threshold as needed
    // setIsScrollingUp(scrollTop < 120 && scrollTop > 160 ? true : '');
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [showDiscard, setShowDiscard] = useState(false);
  const submitVisualAcuity = () => {
    setIsActionCalled(true);
  };
  const [clearVisualAcuity, setClearVisualAcuity] = useState(false);
  const discardHandler = () => {
    setClearVisualAcuity(true);
  };
  const [open, setOpen] = React.useState(false);
  const [selectclient, setselectclient] = useState(null);
  const [alertShow, setAlertShow] = useState(false);
  const startDialtion = () => {
    setOpen(true);
    setAlertShow(true);
    setselectclient(isTimerRunning);
  };
  const hideHandle = () => {
    setAlertShow(false);
    setOpen(false);
  };
  const [problemBtnUnable, setProblemBtnUnable] = useState(false);
  const NextProblemTab = () => {
    if (problemBtnUnable) {
      toast.error("Please save visit reason accordian values");
    } else {
      setActiveTabId(1);
    }
  };
  const [ocularBtnUnable, setOcularBtnUnable] = useState(false);
  const [medicalBtnUnable, setMedicalBtnUnable] = useState(false);
  const [familyBtnUnable, setFamilyBtnUnable] = useState(false);
  const [substanceBtnUnable, setSubstanceBtnUnable] = useState(false);

  const NextHealthTab = () => {
    switch (true) {
      case ocularBtnUnable:
        toast.error("Please save ocular history or discard all this fields");
        break;
      case medicalBtnUnable:
        toast.error("Please save medical history or discard all this fields");
        break;
      case familyBtnUnable:
        toast.error("Please save family history or discard all this fields");
        break;
      case substanceBtnUnable:
        toast.error("Please save substance or discard all this fields");
        break;
      default:
        setActiveTabId(2);
    }
  };

  const [visualBtnUnable, setVisualBtnUnable] = useState(false);
  const NextVisualTab = () => {
    if (visualBtnUnable) {
      toast.error("Please save all accordian values");
    } else {
      setActiveTabId(3);
    }
  };

  const [arxBtnUnable, setArxBtnUnable] = useState(false);
  const [crxBtnUnable, setCrxBtnUnable] = useState(false);
  const [mrxBtnUnable, setMrxBtnUnable] = useState(false);
  const [srxBtnUnable, setSrxBtnUnable] = useState(false);
  const [otherRxBtnUnable, setOtherRxBtnUnable] = useState(false);

  const NextRefractiveTab = () => {
    switch (true) {
      case arxBtnUnable:
        toast.error("Please save arx or discard all this fields");
        break;
      case crxBtnUnable:
        toast.error("Please save crx or discard all this fields");
        break;
      case mrxBtnUnable:
        toast.error("Please save mrx or discard all this fields");
        break;
      case srxBtnUnable:
        toast.error("Please save srx or discard all this fields");
        break;
      case otherRxBtnUnable:
        toast.error("Please save other or discard all this fields");
        break;
      default:
        setActiveTabId(4);
    }
  };

  const [binoBtnUnable, setBinoBtnUnable] = useState(false);
  const [addTestBtnUnable, setAddTestBtnUnable] = useState(false);

  const NextBinocularTab = () => {
    switch (true) {
      case binoBtnUnable:
        toast.error("Please save binocular or discard all this fields");
        break;
      case addTestBtnUnable:
        toast.error(
          "Please save additional testing or discard all this fields"
        );
        break;

      default:
        setActiveTabId(5);
    }
  };

  const [examBtnUnable, setExamBtnUnable] = useState(false);
  const [iopBtnUnable, setIopBtnUnable] = useState(false);
  const [dialationBtnUnable, setDialationBtnUnable] = useState(false);
  const [pachymetryBtnUnable, setPachmetryBtnUnable] = useState(false);
  const [notesBtnUnable, setNotesBtnUnable] = useState(false);
  const NextExternalTab = () => {
    switch (true) {
      case examBtnUnable:
        toast.error("Please save exam or discard all this fields");
        break;
      case iopBtnUnable:
        toast.error("Please save iop testing or discard all this fields");
        break;
      case dialationBtnUnable:
        toast.error("Please save dialation or discard all this fields");
        break;
      case pachymetryBtnUnable:
        toast.error(
          "Please save pachymetry testing or discard all this fields"
        );
        break;
      case notesBtnUnable:
        toast.error("Please save notes testing or discard all this fields");
        break;

      default:
        setActiveTabId(6);
    }
  };

  const [internalBtnUnable, setInternalBtnUnable] = useState(false);
  const NextInternalTab = () => {
    switch (true) {
      case internalBtnUnable:
        toast.error("Please save internal or discard all this fields");
        break;

      default:
        setActiveTabId(7);
    }
  };

  const [presentingRxBtnUnable, setPresentingRxBtnUnable] = useState(false);
  const [additionContactBtnUnable, setAdditionalContactBtnUnable] =
    useState(false);

  const NextContactTab = () => {
    switch (true) {
      case presentingRxBtnUnable:
        toast.error(
          "Please save presenting contact rx or discard all this fields"
        );
        break;
      case additionContactBtnUnable:
        toast.error(
          "Please save additional details or discard all this fields"
        );
        break;

      default:
        setActiveTabId(9);
    }
  };
  const [assessmentNotesBtnUnable, setAssessmentNotesBtnUnable] =
    useState(false);
  const [assessmentRefBtnUnable, setAssessmentRefBtnUnable] = useState(false);
  const NextAssessmentTab = () => {
    switch (true) {
      case assessmentNotesBtnUnable:
        toast.error("Please save assessment notes or discard all this fields");
        break;
      case assessmentRefBtnUnable:
        toast.error(
          "Please save assessment referral or discard all this fields"
        );
        break;

      default:
        setActiveTabId(10);
    }
  };
  const startTimer = () => {
    setIsTimerRunning(true);
    setTimerSeconds(0);
    setTimerStopValue("");
  };
  useEffect(() => {
    startTimer();
  }, [isTimerRunning]);
  const [stopTimer, setStopTimer] = useState(false);
  useEffect(() => {
    setIsTimerRunning(false);
    setTimerStopValue(timerSeconds);
    // setTimerSeconds(0);

    setStopTimer(false);
  }, [stopTimer]);
  return (
    <>
      <AlertPopup
        isTimerRunning={isTimerRunning}
        setIsTimerRunning={setIsTimerRunning}
        setTimerSeconds={setTimerSeconds}
        setStopTimer={setStopTimer}
        open={open}
        onHide={hideHandle}
        title={"Alert"}
        selectclient={selectclient}
        alertShow={alertShow}
        setAlertShow={setAlertShow}
      />
      <div className={classes.titleDiv}>
        <img
          className={classes.imageicon}
          src={backArrow}
          onClick={goBack}
          alt="img"
        />
        <PageTitle title="Add New Exam" />
        {isTimerRunning && (
          <div
            style={{
              position: "absolute",
              right: "64px",
              textAlign: "center",

              background: "yellow",
              padding: "2px",
              fontWeight: "500",
            }}
          >
            Elapse Time : {formatTime(timerSeconds)}
          </div>
        )}
        {timerStopValue && (
          <div
            style={{
              position: "absolute",
              right: "64px",
              textAlign: "center",

              background: "yellow",
              padding: "2px",
              fontWeight: "500",
            }}
          >
            Elapse Time : {formatTime(timerStopValue)}
          </div>
        )}
        <span style={{ position: "absolute", right: "16px" }}>
          <img
            onClick={startDialtion}
            // onClick={isTimerRunning ? stopTimer : startTimer}
            src={clock}
            style={{ height: "36px", cursor: "pointer" }}
            alt="clock"
          />
        </span>
      </div>
      {/* <UserCard/> */}

      <Paper className={classes.iconsContainer} style={{ marginTop: "10px" }}>
        <div className={classes.fixedTabs}>
          <Tabs
            TabIndicatorProps={{
              style: { background: "#4E97FD", width: "150px" },
            }}
            style={
              isScrollingUp
                ? {
                    position: "fixed",
                    top: "50px",
                    zIndex: 1,
                    backgroundColor: "white",
                    width: "80%",
                    // boxShadow:
                    //   "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0px 0px 10px 0 rgba(0, 0, 0, 0.19)",
                    // border: "1px solid #03997B",
                  }
                : {}
            }
            variant="scrollable"
            scrollButtons="auto"
            value={activeTabId}
            // onChange={(e, id) => (patient_id ? setActiveTabId(id) : "")}
            onChange={handleTabChange}
            className={classes.iconsBar}
          >
            <Tab label="Problem" classes={{ root: classes.tab }} />
            <Tab
              label="Health"
              classes={{ root: classes.tab }}
              // style={{ cursor: patient_id ? "pointer" : "not-allowed" }}
            />
            <Tab
              label="Visual Acuity"
              classes={{ root: classes.tab }}
              // style={{ cursor: patient_id ? "pointer" : "not-allowed" }}
            />

            <Tab
              label="Refractive"
              classes={{ root: classes.tab }}
              // style={{ cursor: patient_id ? "pointer" : "not-allowed" }}
            />

            <Tab
              label="Binocular"
              classes={{ root: classes.tab }}
              // style={{ cursor: patient_id ? "pointer" : "not-allowed" }}
            />
            <Tab
              label="External"
              classes={{ root: classes.tab }}
              // style={{ cursor: patient_id ? "pointer" : "not-allowed" }}
            />

            <Tab
              label="Internal"
              classes={{ root: classes.tab }}
              // style={{ cursor: patient_id ? "pointer" : "not-allowed" }}
            />
            <Tab
              label="Glyphs"
              classes={{ root: classes.tab }}
              // style={{ cursor: patient_id ? "pointer" : "not-allowed" }}
            />
            <Tab
              label="Contacts"
              classes={{ root: classes.tab }}
              // style={{ cursor: patient_id ? "pointer" : "not-allowed" }}
            />
            <Tab
              label="Assessment"
              classes={{ root: classes.tab }}
              // style={{ cursor: patient_id ? "pointer" : "not-allowed" }}
            />

            <Tab
              label="Chart"
              classes={{ root: classes.tab }}
              // style={{ cursor: patient_id ? "pointer" : "not-allowed" }}
            />
          </Tabs>
        </div>
        {/* Personal Details */}
        {activeTabId === 0 && (
          <Grid container spacing={4}>
            <Grid className={classes.gridItem} item xs={12}>
              {/* <Widget> */}
              <Problem
                patientId={patient_id}
                reasonType={reasonType}
                impactedPart={impactedPart}
                frequency={frequency}
                severity={severity}
                symptons={symptons}
                factors={factors}
                examSince={examSince}
                occurseWhile={occurseWhile}
                examProblemStatus={examProblemStatus}
                patientData={patientData}
                setProblemBtnUnable={setProblemBtnUnable}
                problemBtnUnable={problemBtnUnable}
              />
              {/* </Widget> */}
            </Grid>
            <Grid className={classes.btnGroup} item xs={12}>
              <Button
                className={classes.btnleftOutline}
                variant="outlined"
                size="large"
              >
                Cancel
              </Button>
              <Button
                className={classes.btnlRightDark}
                variant="contained"
                size="large"
                color="secondary"
                onClick={() => NextProblemTab()}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        )}
        {/* Health */}
        {activeTabId === 1 && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {/* <Widget> */}
              <HealthTab
                enable="true"
                examMode={examMode}
                allergyOutcome={allergyOutcome}
                allergyReaction={allergyReaction}
                allergySeverity={allergySeverity}
                patientId={patient_id}
                disclosureType={disclosureType}
                surgriesType={surgriesType}
                surgriesOutcome={surgriesOutcome}
                surgriesOccurance={surgriesOccurance}
                surgriesClassification={surgriesClassification}
                frequency={frequency}
                relTypeValues={relTypeValues}
                doctorList={doctorList}
                patientData={patientData}
                setOcularBtnUnable={setOcularBtnUnable}
                setMedicalBtnUnable={setMedicalBtnUnable}
                setFamilyBtnUnable={setFamilyBtnUnable}
                setSubstanceBtnUnable={setSubstanceBtnUnable}
                ocularBtnUnable={ocularBtnUnable}
                medicalBtnUnable={medicalBtnUnable}
                familyBtnUnable={familyBtnUnable}
                substanceBtnUnable={substanceBtnUnable}
                frameBrandList={frameBrandList}
                examFamilyRelation={examFamilyRelation}
                examEyeType={examEyeType}
              />
              {/* </Widget> */}
            </Grid>
            <Grid className={classes.btnGroup} item xs={12}>
              <Button
                className={classes.btnleftOutline}
                variant="outlined"
                size="large"
                onClick={() => setActiveTabId(0)}
              >
                Previous
              </Button>
              <Button
                className={classes.btnlRightDark}
                variant="contained"
                size="large"
                color="secondary"
                onClick={() => NextHealthTab()}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        )}
        {/* Visual Acuity */}
        {activeTabId === 2 && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {/* <Widget> */}
              <VisualAcuityTab
                patientId={patient_id}
                examChartType={examChartType}
                examCorrectionType={examCorrectionType}
                examDominantEye={examDominantEye}
                examAcuityNotes={examAcuityNotes}
                examCurrentAcuity={examCurrentAcuity}
                setIsActionCalled={setIsActionCalled}
                isActionCalled={isActionCalled}
                setVisualBtnUnable={setVisualBtnUnable}
                visualBtnUnable={visualBtnUnable}
                setClearVisualAcuity={setClearVisualAcuity}
                clearVisualAcuity={clearVisualAcuity}
                setShowDiscard={setShowDiscard}
              />
              {/* </Widget> */}
            </Grid>
            <Grid className={classes.btnGroup} item xs={12}>
              <Button
                className={classes.btnleftOutline}
                variant="outlined"
                size="large"
                onClick={() => setActiveTabId(1)}
              >
                Previous
              </Button>
              <div>
                {showDiscard && (
                  <Button
                    className={classes.btnleftOutline}
                    variant="outlined"
                    style={{ marginRight: "10px", fontSize: "12px" }}
                    size="medium"
                    onClick={discardHandler}
                  >
                    Discard
                  </Button>
                )}
                <Button
                  disabled={!visualBtnUnable}
                  className={classes.btnlRightDark}
                  variant="contained"
                  style={{ marginRight: "10px" }}
                  size="large"
                  color="secondary"
                  onClick={() => submitVisualAcuity()}
                >
                  Save
                </Button>
                <Button
                  className={classes.btnlRightDark}
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={() => NextVisualTab()}
                >
                  Next
                </Button>
              </div>
            </Grid>
          </Grid>
        )}

        {/* Refractive */}
        {activeTabId === 3 && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {/* <Widget> */}
              <Refractive
                patientId={patient_id}
                setArxBtnUnable={setArxBtnUnable}
                setCrxBtnUnable={setCrxBtnUnable}
                setMrxBtnUnable={setMrxBtnUnable}
                setOtherRxBtnUnable={setOtherRxBtnUnable}
                arxBtnUnable={arxBtnUnable}
                crxBtnUnable={crxBtnUnable}
                mrxBtnUnable={mrxBtnUnable}
                otherRxBtnUnable={otherRxBtnUnable}
                setSrxBtnUnable={setSrxBtnUnable}
                srxBtnUnable={srxBtnUnable}
                frameMaterialData={frameMaterialData}
                examBase={examBase}
              />
              {/* </Widget> */}
            </Grid>
            <Grid className={classes.btnGroup} item xs={12}>
              <Button
                className={classes.btnleftOutline}
                variant="outlined"
                size="large"
                onClick={() => setActiveTabId(2)}
              >
                Previous
              </Button>
              <Button
                className={classes.btnlRightDark}
                variant="contained"
                size="large"
                color="secondary"
                onClick={() => NextRefractiveTab()}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        )}

        {/* History */}
        {activeTabId === 4 && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {/* <Widget> */}
              <MainBinocular
                examExternalBinocular={examExternalBinocular}
                patientId={patient_id}
                eyesType={eyesType}
                setBinoBtnUnable={setBinoBtnUnable}
                binoBtnUnable={binoBtnUnable}
                setAddTestBtnUnable={setAddTestBtnUnable}
                addTestBtnUnable={addTestBtnUnable}
              />
              {/* </Widget> */}
            </Grid>
            <Grid className={classes.btnGroup} item xs={12}>
              <Button
                className={classes.btnleftOutline}
                variant="outlined"
                size="large"
                onClick={() => setActiveTabId(3)}
              >
                Previous
              </Button>
              <Button
                className={classes.btnlRightDark}
                variant="contained"
                size="large"
                color="secondary"
                onClick={() => NextBinocularTab()}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        )}
        {/* Family */}
        {activeTabId === 5 && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {/* <Widget> */}
              <ExternalMain
                patientId={patient_id}
                examExternalDilation={examExternalDilation}
                examExternalCounsel={examExternalCounsel}
                examExternalFatProlapse={examExternalFatProlapse}
                examExternalPupilAPD={examExternalPupilAPD}
                examExternalPupilNPI={examExternalPupilNPI}
                examExternalBinocular={examExternalBinocular}
                examExternalPupilRAPD={examExternalPupilRAPD}
                examExternalPupilReacts={examExternalPupilReacts}
                examExternalPupilShape={examExternalPupilShape}
                examExternalPupilSize={examExternalPupilSize}
                examExternalMethod={examExternalMethod}
                examExternalEyeLid={examExternalEyeLid}
                examExternalCornea={examExternalCornea}
                examExternalTearFilm={examExternalTearFilm}
                examExternalOrbit={examExternalOrbit}
                examExternalTearIris={examExternalTearIris}
                examExternalLacrimal={examExternalLacrimal}
                examExternalAnteriorChamber={examExternalAnteriorChamber}
                examExternalConjunctiva={examExternalConjunctiva}
                examExternalSclera={examExternalSclera}
                examExternalPreauricularNode={examExternalPreauricularNode}
                doctorList={doctorList}
                examBtnUnable={examBtnUnable}
                setExamBtnUnable={setExamBtnUnable}
                iopBtnUnable={iopBtnUnable}
                setIopBtnUnable={setIopBtnUnable}
                dialationBtnUnable={dialationBtnUnable}
                setDialationBtnUnable={setDialationBtnUnable}
                pachymetryBtnUnable={pachymetryBtnUnable}
                setPachmetryBtnUnable={setPachmetryBtnUnable}
                notesBtnUnable={notesBtnUnable}
                setNotesBtnUnable={setNotesBtnUnable}
              />
              {/* </Widget> */}
            </Grid>
            <Grid className={classes.btnGroup} item xs={12}>
              <Button
                className={classes.btnleftOutline}
                variant="outlined"
                size="large"
                onClick={() => setActiveTabId(4)}
              >
                Previous
              </Button>
              <Button
                className={classes.btnlRightDark}
                variant="contained"
                size="large"
                color="secondary"
                onClick={() => NextExternalTab()}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        )}

        {/* Custom Data */}
        {activeTabId === 6 && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {/* <Widget> */}
              <InternalMain
                patientId={patient_id}
                examInternalCDRatio={examInternalCDRatio}
                examInternalVitreous={examInternalVitreous}
                examInternalLens={examInternalLens}
                examInternalAVRatio={examInternalAVRatio}
                examInternalMacula={examInternalMacula}
                examInternalOpticDisc={examInternalOpticDisc}
                examInternalAVRetina={examInternalAVRetina}
                examInternalVessels={examInternalVessels}
                setInternalBtnUnable={setInternalBtnUnable}
                internalBtnUnable={internalBtnUnable}
              />
              {/* </Widget> */}
            </Grid>
            <Grid className={classes.btnGroup} item xs={12}>
              <Button
                className={classes.btnleftOutline}
                variant="outlined"
                size="large"
                onClick={() => setActiveTabId(5)}
              >
                Previous
              </Button>
              <Button
                className={classes.btnlRightDark}
                variant="contained"
                size="large"
                color="secondary"
                onClick={() => NextInternalTab()}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        )}
        {activeTabId === 7 && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {/* <Widget> */}
              <Glyphs patientId={patient_id} />
              {/* </Widget> */}
            </Grid>
            <Grid className={classes.btnGroup} item xs={12}>
              <Button
                className={classes.btnleftOutline}
                variant="outlined"
                size="large"
                onClick={() => setActiveTabId(6)}
              >
                Previous
              </Button>
              <Button
                className={classes.btnlRightDark}
                variant="contained"
                size="large"
                color="secondary"
                onClick={() => setActiveTabId(8)}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        )}
        {activeTabId === 8 && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {/* <Widget> */}
              <MainContactTab
                patientId={patient_id}
                presentingRxBtnUnable={presentingRxBtnUnable}
                setPresentingRxBtnUnable={setPresentingRxBtnUnable}
                additionContactBtnUnable={additionContactBtnUnable}
                setAdditionalContactBtnUnable={setAdditionalContactBtnUnable}
              />
              {/* </Widget> */}
            </Grid>
            <Grid className={classes.btnGroup} item xs={12}>
              <Button
                className={classes.btnleftOutline}
                variant="outlined"
                size="large"
                onClick={() => setActiveTabId(7)}
              >
                Previous
              </Button>
              <Button
                className={classes.btnlRightDark}
                variant="contained"
                size="large"
                color="secondary"
                onClick={() => NextContactTab()}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        )}
        {activeTabId === 9 && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {/* <Widget> */}

              <MainAssessmentTab
                enable="true"
                patientId={patient_id}
                assessmentNotesBtnUnable={assessmentNotesBtnUnable}
                setAssessmentNotesBtnUnable={setAssessmentNotesBtnUnable}
                assessmentRefBtnUnable={assessmentRefBtnUnable}
                setAssessmentRefBtnUnable={setAssessmentRefBtnUnable}
                mode={examMode}
              />
              {/* </Widget> */}
            </Grid>
            <Grid className={classes.btnGroup} item xs={12}>
              <Button
                className={classes.btnleftOutline}
                variant="outlined"
                size="large"
                onClick={() => setActiveTabId(8)}
              >
                Previous
              </Button>
              <Button
                className={classes.btnlRightDark}
                variant="contained"
                size="large"
                color="secondary"
                onClick={() => NextAssessmentTab()}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        )}

        {activeTabId === 10 && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {/* <Widget> */}
              
              <MainChart patientId={patient_id} />  
              {/* </Widget> */}
            </Grid>
            <Grid className={classes.btnGroup} item xs={12}>
              <Button
                className={classes.btnleftOutline}
                variant="outlined"
                size="large"
                onClick={() => setActiveTabId(9)}
              >
                Previous
              </Button>
            </Grid>
          </Grid>
        )}
      </Paper>
    </>
  );
}
