import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid, Tabs, Tab, Paper, Button } from "@material-ui/core";
import PageTitle from "../../PageTitle/PageTitle";
import useStyles from "./styles";
import PatientProfile from "./PatientProfile";
import Demography from "./Demography";
import Insurance from "./Insurance";
import FamilyList from "../Cases/Family/FamilyList";
import {
  postPatientData,
  postPatientAdditionalData,
} from "../../../services/ServiceLayer";
import {
  paramForAddPatientProfile,
  paramForAddPatientAdditionalDetailsEdit,
  loadState,
  paramForAddPatientInsuranceDetails,
} from "../../../hooks/helper";
import CustomDataList from "../Cases/CustomDataTab/CustomeDataList";
import AttachmentList from "./AttachmentTab/AttachmentList";
import Validation from "../../../hooks/PatientValidation";
import {
  getPatient,
  getPatientAttachment,
  getAdditionalDetails,
  getInsuranceDetails,
  getPatientFamily,
  getAppointmentData,
  postPatientInsuranceData,
} from "../../../services/ServiceLayer";
import { toast } from "react-toastify";

import pageNotFound from "../../../assets/pageNotFound.png";
import useListData from "../../common/allMetaData/useListData";
import moment from "moment";
import IntractionList from "../Detail/Case/IntractionTab/IntractionList";
export default function PatientSteps(props) {
  const { patientReadValue } = props;
  toast.configure();
  const { patient_id } = useParams();
  console.log("patient_id", patient_id);
  var classes = useStyles();
  const [dataUpdated, setDataUpdated] = useState(false);
  const [patientData, setPatientData] = useState([]);
  const [patientAttachmentData, setPatientAttachmentData] = useState([]);
  // const [patientOtherData, setPatientOtherData] = useState([]);
  const [patientInsuranceData, setPatientInsuranceData] = useState([]);
  const [patientFamilyData, setPatientFamilyData] = useState([]);
  const [patientAppointmentData, setPatientAppointmentData] = useState([]);
  var [activeTabId, setActiveTabId] = useState(null);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const history = useHistory();
  const [patientProfileData, setPatientProfileData] = useState({
    title: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    suffix: "",
    nick_name: "",
    gender: "",
    marital: "",
    dateofbirth: "",
    gender_identity: "",
    orientation: "",
    contact_cell: "",
    contact_home: "",
    contact_office: "",
    email: "",
    allow_health_info: "",

    pref_contact: "",
    is_opt_email: "",
    is_opt_sms: "",
    is_opt_call: "",
    pref_call_hours: "",
    ssn: "",
    allergies: "",
    is_hipaa_consent: "",
    is_opt_marketing: "",
    is_patient_portal_access: "",
    preffered_language: "",

    //addressbilling
    billing_address_line1: "",
    billing_address_line2: "",
    billing_city: "",
    billing_state: "",
    billing_zip: "",
    billing_address_verified: "",
    shipping_address_line1: "",
    shipping_address_line2: "",
    shipping_city: "",
    shipping_state: "",
    shipping_zip: "",
    shipping_address_verified: "",
    mailing_address_line1: "",
    mailing_address_line2: "",
    mailing_city: "",
    mailing_state: "",
    mailing_zip: "",
    mailing_address_verified: "",
    // addition
    ethnicity: "",
    race: "",
    hobbies: "",
    primary_physician: "",
    physician_address: "",
    physician_city: "",
    physician_state: "",
    physician_zip: "",
    notes: "",
    imp_shrt_notes: "",
    addition_note_priority: "",
    addition_applicable_role: "",
    applicable_role_id: "",
    is_disability: "",
    is_veteran_status: "",

    // emergency
    first_emergency_contact_name: "",
    first_emergency_contact_con: "",
    sec_emergency_contact_name: "",
    sec_emergency_contact_con: "",
  });
  const [isProfileStateChanged, setIsProfileStateChanged] = useState(false);
 
  // call meta data
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const listData = useListData();
  // Destructure listData object
  const {
    languageValues,
    genderValues,
    raceValues,
    titleValues,
    maritalValues,
    genderIdentificationValues,
    sexOrientationValues,
    ethinicityValues,
    relTypeValues,
    deceasedValues,
    disabilityValues,
    gaurantorValues,
    preferenceHourValues,
    veteranValues,
    insuranceValues,
    additionalInsuranceValues,
    addressStatus,
    preferenceContactValues,
    thanksValues,
    patientStatusValues,
    inactiveValues,
    applicableRoleValues,
    phoneTypeValues,
    referenceSourceValues,
    fileCategoryValues,
  } = listData;
  // call meta data

  const [patientOtherData, setPatientOtherData] = useState({
    patient_id: patient_id,
    occupation: "",
    employer: "",
    employer_address_line1: "",
    employer_address_line2: "",
    employer_city: "",
    employer_state: "",
    employer_country: "USA",
    employer_zip: 123,
    employer_con: 2345,
    employer_email: "",
    //
    guarantor_type: "",
    guarantor_relationship: "",
    guarantor_first_name: "",
    guarantor_middle_name: "",
    guarantor_last_name: "",
    guarantor_suffix: "",
    guarantor_dob: "",
    guarantor_gender: "",
    guarantor_ssn: "",
    guarantor_employer_email: "",
    guarantor_address_line1: "",
    guarantor_address_line2: "",
    guarantor_city: "",
    guarantor_state: "FL",
    guarantor_country: "USA",
    guarantor_zip: 123,
    guarantor_con: 1234,
    guarantor_home_con: 12345,
    guarantor_office_con: 123456,
    guarantor_email: "",
    //
    referral_source: "",
    referral_first_name: "",
    referral_middle_name: "",
    referral_last_name: "",
    referral_suffix: "",
    referral_dob: "2002-01-01",
    referral_gender: "",
    referral_con: 1234,
    referral_con_type: "",
    referral_email: "",
    referral_ssn: "",
    referral_thanks_note: "Y",
    referral_address_line1: "",
    referral_address_line2: "",
    referral_city: "",
    referral_state: "",
    referral_country: "",
    referral_zip: 23478,
    //
    guardian_relationship: "",
    guardian_first_name: "",
    guardian_middle_name: "",
    guardian_last_name: "",
    guardian_suffix: "",
    guardian_dob: "",
    guardian_gender: "",
    guardian_con_type: "",
    guardian_email: "",
    guardian_thanks_note: "Y",
    guardian_address_line1: "",
    guardian_address_line2: "",
    guardian_city: "",
    guardian_state: "WA",
    guardian_country: "USA",
    guardian_zip: null,
    guardian_con: null,
    guardian_home_con: null,
    guardian_office_con: null,
    is_guardian_same_address: "Y",
    guardian1_relationship: "",
    guardian1_first_name: "",
    guardian1_middle_name: "",
    guardian1_last_name: "",
    guardian1_suffix: "",
    guardian1_dob: "1988-01-01",
    guardian1_gender: "",
    guardian1_con_type: "",
    guardian1_email: "",
    guardian1_thanks_note: "Y",
    guardian1_address_line1: "",
    guardian1_address_line2: "",
    guardian1_city: "",
    guardian1_state: "WA",
    guardian1_country: "USA",
    guardian1_zip: 1234,
    guardian1_con: 2345,
    guardian1_home_con: 34566,
    guardian1_office_con: 456666,
    is_guardian1_same_address: "Y",
    first_emergency_contact_name: "",
    first_emergency_contact_con: "",
    sec_emergency_contact_name: "",
    sec_emergency_contact_con: 0,
  });
  // getDetails
  useEffect(() => {
    // patient details
    getPatient(`patient_id=${patient_id}`).then((response) => {
      console.log(response);
      setPatientData(response?.data);
      setActiveTabId(0);
      setPatientProfileData(response?.data);
    });
  }, []);
  // get Additional Data
  useEffect(() => {
    getAdditionalDetails(patient_id).then((response) => {
      console.log(response);
      setPatientOtherData(response.data);
    });
  }, []);
  // get Attachment file
  useEffect(() => {
    getPatientAttachment(patient_id).then((response) => {
      console.log(response);

      setPatientAttachmentData(response.data);
    });
  }, []);
  useEffect(() => {
    getPatientAttachment(patient_id).then((response) => {
      console.log(response);

      setPatientAttachmentData(response.data);
    });
    // get family
    getPatientFamily(
      `patient_id=${patient_id}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELREL`
    ).then((response) => {
      console.log(response);
      setPatientFamilyData(response.data);
    });
    setDataUpdated(false);
  }, [dataUpdated]);
  //get insurance file
  useEffect(() => {
    getInsuranceDetails(patient_id).then((response) => {
      console.log(response);
      setPatientInsuranceData(response.data);
    });
  }, []);
  const [subscribePatient, setSubscribePatient] = useState(null);
  const [subscribeVIPatient, setSubscribeVIPatient] = useState(null);
  const [subscribeADIPatient, setSubscribeADIPatient] = useState(null);
  useEffect(() => {
    console.log("patientInsuranceData", patientInsuranceData);
    if (patientInsuranceData?.mi_patient_parent_id) {
      getPatient(
        `patient_id=${patientInsuranceData?.mi_patient_parent_id}`
      ).then((response) => {
        console.log("response -392", response);
        setSubscribePatient({
          ...subscribePatient,
          pr_subscriber_name: response?.data?.first_name,
          pr_patient_id: response?.data?.patient_id,
          pr_middle_name: response?.data?.middle_name,
          pr_last_name: response?.data?.last_name,
          pr_dateofbirth: response?.data?.dateofbirth,
          pr_ssn: response?.data?.ssn,
          pr_sex: response?.data?.gender,
          pr_subscribe_phone: response?.data?.contact_Cell,
          pr_office_phone: response?.data?.contact_office,
          subscribe_employeer: null,
          pr_address1: response?.data?.billing_address_line1,
          pr_address2: response?.data?.billing_address_line1,
          pr_city: response?.data?.billing_city,
          pr_state: response?.data?.billing_state,
          pr_zip: response?.data?.billing_zip,
          pr_emp_address1: response?.data?.shipping_address_line1,
          pr_emp_address2: response?.data?.shipping_address_line2,
          pr_emp_city: response?.data?.shipping_city,
          pr_emp_state: response?.data?.shipping_state,
          pr_emp_zip: response?.data?.shipping_zip,
        });
      });
    }
    if (patientInsuranceData?.vi_patient_parent_id) {
      getPatient(
        `patient_id=${patientInsuranceData?.vi_patient_parent_id}`
      ).then((response) => {
        console.log("response -392", response);
        setSubscribeVIPatient({
          ...subscribeVIPatient,
          pr_subscriber_name: response?.data?.first_name,
          pr_patient_id: response?.data?.patient_id,
          pr_middle_name: response?.data?.middle_name,
          pr_last_name: response?.data?.last_name,
          pr_dateofbirth: response?.data?.dateofbirth,
          pr_ssn: response?.data?.ssn,
          pr_sex: response?.data?.gender,
          pr_subscribe_phone: response?.data?.contact_Cell,
          pr_office_phone: response?.data?.contact_office,
          subscribe_employeer: null,
          pr_address1: response?.data?.billing_address_line1,
          pr_address2: response?.data?.billing_address_line1,
          pr_city: response?.data?.billing_city,
          pr_state: response?.data?.billing_state,
          pr_zip: response?.data?.billing_zip,
          pr_emp_address1: response?.data?.shipping_address_line1,
          pr_emp_address2: response?.data?.shipping_address_line2,
          pr_emp_city: response?.data?.shipping_city,
          pr_emp_state: response?.data?.shipping_state,
          pr_emp_zip: response?.data?.shipping_zip,
        });
      });
    }
    if (patientInsuranceData?.adi_patient_parent_id) {
      getPatient(
        `patient_id=${patientInsuranceData?.adi_patient_parent_id}`
      ).then((response) => {
        console.log("response -392", response);
        setSubscribeADIPatient({
          ...subscribeADIPatient,
          pr_subscriber_name: response?.data?.first_name,
          pr_patient_id: response?.data?.patient_id,
          pr_middle_name: response?.data?.middle_name,
          pr_last_name: response?.data?.last_name,
          pr_dateofbirth: response?.data?.dateofbirth,
          pr_ssn: response?.data?.ssn,
          pr_sex: response?.data?.gender,
          pr_subscribe_phone: response?.data?.contact_Cell,
          pr_office_phone: response?.data?.contact_office,
          subscribe_employeer: null,
          pr_address1: response?.data?.billing_address_line1,
          pr_address2: response?.data?.billing_address_line1,
          pr_city: response?.data?.billing_city,
          pr_state: response?.data?.billing_state,
          pr_zip: response?.data?.billing_zip,
          pr_emp_address1: response?.data?.shipping_address_line1,
          pr_emp_address2: response?.data?.shipping_address_line2,
          pr_emp_city: response?.data?.shipping_city,
          pr_emp_state: response?.data?.shipping_state,
          pr_emp_zip: response?.data?.shipping_zip,
        });
      });
    }
  }, [patientInsuranceData]);
  //get family file
  useEffect(() => {
    getPatientFamily(
      `patient_id=${patient_id}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELREL`
    ).then((response) => {
      console.log(response);
      setPatientFamilyData(response.data);
    });
  }, []);
  //get Appointment file
  useEffect(() => {
    getAppointmentData(
      `patient_id=${patient_id}&start_date=''&end_date=''&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELHIS`
    ).then((response) => {
      console.log(response);
      setPatientAppointmentData(response.data);
    });
  }, []);
  // post Deatils

  const saveAndExist = () => {
    // checkValidator();
    postPatientData(
      paramForAddPatientProfile([patientProfileData, "UPDPAT", patient_id])
    ).then((response) => {
      console.log(response);
      console.log(response?.status?.status);
      if (response?.status?.status) {
        toast.success("Personal Details Updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        history.push(`/app/patient`);
      } else {
        if (
          response?.status?.message === "error" &&
          response?.status?.description ===
            "Something went wrong Please try again letter"
        ) {
          toast.error("Something went wrong Please try again letter", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // Handle other error cases here
          // For example, display a generic error message
          toast.error("An error occurred. Please try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    });
  };
  const saveProfileNext = () => {
    // e.preventDefault();
    // checkValidator();
    if(isProfileStateChanged){
      setIsActionCalled(true);
    }else{
      setActiveTabId(1);
      
    }
   
  };
  useEffect(() => {
    console.log("isActionCalled", isActionCalled);
    if (isActionCalled) {
      console.log("Api Hit", isActionCalled);
      postPatientData(
        paramForAddPatientProfile([patientProfileData, "UPDPAT", patient_id])
      ).then((response) => {
        console.log(response);
        if (response?.status?.status) {
          toast.success("Personal Details Updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setActiveTabId(1);
          setIsProfileStateChanged(false);
        } else {
          if (
            response?.status?.message === "error" &&
            response?.status?.description ===
              "Something went wrong Please try again letter"
          ) {
            toast.error("Something went wrong Please try again letter", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            // Handle other error cases here
            // For example, display a generic error message
            toast.error("An error occurred. Please try again.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
      setIsProfileStateChanged(false);
      setIsActionCalled(false);
    } else {
      console.log("error", error);
    }
    setIsActionCalled(false);
    setIsProfileStateChanged(false);
  }, [isActionCalled]);
  const saveOtherDetailsExit = () => {
    postPatientAdditionalData(
      paramForAddPatientAdditionalDetailsEdit([
        patientOtherData,
        "ADDPAT",
        patient_id,
      ])
    ).then((response) => {
      console.log(response);
      if (response?.status?.status) {
        toast.success("Additional Details Updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        history.push(`/app/patient`);
      } else {
        if (
          response?.status?.message === "error" &&
          response?.status?.description ===
            "Something went wrong Please try again letter"
        ) {
          toast.error("Something went wrong Please try again letter", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // Handle other error cases here
          // For example, display a generic error message
          toast.error("An error occurred. Please try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    });
  };
  const saveOtherDetailsNext = () => {
    postPatientAdditionalData(
      paramForAddPatientAdditionalDetailsEdit([
        patientOtherData,
        "ADDPAT",
        patient_id,
      ])
    ).then((response) => {
      console.log(response);
      if (response?.status?.status) {
        toast.success("Additional Details Updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setActiveTabId(2);
      } else {
        if (
          response?.status?.message === "error" &&
          response?.status?.description ===
            "Something went wrong Please try again letter"
        ) {
          toast.error("Something went wrong Please try again letter", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // Handle other error cases here
          // For example, display a generic error message
          toast.error("An error occurred. Please try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    });
  };
  const saveInsuranceDetailsNext = () => {
    console.log("patient_id======<<<<<<<<???????" + patient_id);
    postPatientInsuranceData(
      paramForAddPatientInsuranceDetails([
        patientInsuranceData,
        "MODINS",
        patient_id,
      ])
    ).then((response) => {
      console.log(response);
      if (response.status.status === true) {
        toast.success("Insurance Information Saved");
        setActiveTabId(3);
      } else {
        toast.error(response.status.description);
      }
    });
  };

  const saveInsuranceDetailsExit = () => {
    console.log("patient_id======<<<<<<<<???????" + patient_id);
    postPatientInsuranceData(
      paramForAddPatientInsuranceDetails([
        patientInsuranceData,
        "MODINS",
        patient_id,
      ])
    ).then((response) => {
      console.log(response);
      if (response.status.status === true) {
        toast.success("Insurance Information Saved");
        history.push(`/app/patient`);
      } else {
        toast.error(response.status.description);
      }
    });
  };

  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    Validation({
      first_name: patientProfileData?.first_name,

      last_name: patientProfileData?.last_name,

      contact_cell: patientProfileData?.contact_cell,

      email: patientProfileData?.email,
    });
  return (
    <>
      <div className={classes.titleDiv}>
        {/* <img className={classes.imageicon} src={backArrow} onClick={goBack} /> */}
        <PageTitle title="Edit Patient" />
      </div>
      {patientReadValue && patientReadValue === "Y" ? (
        <Paper className={classes.iconsContainer}>
          <Tabs TabIndicatorProps={{
            style: { background: "#4E97FD",width:"150px"},
          }}
          
            variant="scrollable"
            scrollButtons="auto"
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            className={classes.iconsBar}
          >
            <Tab label="Personal Details" classes={{ root: classes.tab }} />
            <Tab label="Other Details" classes={{ root: classes.tab }} />
            <Tab label="Insurance" classes={{ root: classes.tab }} />

            <Tab label="Attachments" classes={{ root: classes.tab }} />

            <Tab label="Family" classes={{ root: classes.tab }} />

            <Tab label="Custom Data" classes={{ root: classes.tab }} />
            <Tab label="Interactions" classes={{ root: classes.tab }} />
          </Tabs>
          {/* Personal Details */}
          {activeTabId === 0 && (
            <Grid container spacing={4}>
              <Grid className={classes.gridItem} item xs={12}>
                {/* <Widget> */}
                <PatientProfile
                  patientData={patientData}
                  setPatientData={setPatientData}
                  patientProfileData={patientProfileData}
                  setPatientProfileData={setPatientProfileData}
                  error={error}
                  checkValidator={checkValidator}
                  resetValidator={resetValidator}
                  resetAllValidator={resetAllValidator}
                  isError={isError}
                  titleValues={titleValues}
                  genderValues={genderValues}
                  maritalValues={maritalValues}
                  genderIdentificationValues={genderIdentificationValues}
                  sexOrientationValues={sexOrientationValues}
                  preferenceContactValues={preferenceContactValues}
                  preferenceHourValues={preferenceHourValues}
                  languageValues={languageValues}
                  addressStatus={addressStatus}
                  ethinicityValues={ethinicityValues}
                  raceValues={raceValues}
                  disabilityValues={disabilityValues}
                  veteranValues={veteranValues}
                  patientStatusValues={patientStatusValues}
                  inactiveValues={inactiveValues}
                  deceasedValues={deceasedValues}
                  applicableRoleValues={applicableRoleValues}
                  setIsProfileStateChanged={setIsProfileStateChanged}
                />
                {/* </Widget> */}
              </Grid>
              <Grid className={classes.btnGroup} item xs={12}>
                <Button
                  className={classes.btnleftOutline}
                  onClick={saveAndExist}
                  variant="outlined"
                  size="large"
                >
                  Save & Exit
                </Button>
                <Button
                  onClick={saveProfileNext}
                  className={classes.btnlRightDark}
                  variant="contained"
                  size="large"
                  color="secondary"
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}
          {/* Other's Details */}
          {activeTabId === 1 && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {/* <Widget> */}
                <Demography
                  patientOtherData={patientOtherData}
                  setPatientOtherData={setPatientOtherData}
                  gaurantorValues={gaurantorValues}
                  relTypeValues={relTypeValues}
                  genderValues={genderValues}
                  phoneTypeValues={phoneTypeValues}
                  referenceSourceValues={referenceSourceValues}
                  thanksValues={thanksValues}
                  setIsProfileStateChanged={setIsProfileStateChanged}
                />
                {/* </Widget> */}
              </Grid>
              <Grid className={classes.btnGroup} item xs={12}>
                <Button
                  className={classes.btnleftOutline}
                  variant="outlined"
                  size="large"
                  onClick={saveOtherDetailsExit}
                >
                  Save & Exit
                </Button>
                <Button
                  onClick={saveOtherDetailsNext}
                  className={classes.btnlRightDark}
                  variant="contained"
                  size="large"
                  color="secondary"
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}
          {/* Insurance */}
          {activeTabId === 2 && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {/* <Widget> */}
                <Insurance
                  subscribePatient={subscribePatient}
                  setSubscribePatient={setSubscribePatient}
                  subscribeVIPatient={subscribeVIPatient}
                  setSubscribeVIPatient={setSubscribeVIPatient}
                  setSubscribeADIPatient={setSubscribeADIPatient}
                  subscribeADIPatient={subscribeADIPatient}
                  patientInsuranceData={patientInsuranceData}
                  setPatientInsuranceData={setPatientInsuranceData}
                  insuranceValues={insuranceValues}
                  additionalInsuranceValues={additionalInsuranceValues}
                  setIsProfileStateChanged={setIsProfileStateChanged}
                />
                {/* </Widget> */}
              </Grid>
              <Grid className={classes.btnGroup} item xs={12}>
                <Button
                  className={classes.btnleftOutline}
                  variant="outlined"
                  size="large"
                  onClick={saveInsuranceDetailsExit}
                >
                  Save & Exit
                </Button>
                <Button
                  className={classes.btnlRightDark}
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={saveInsuranceDetailsNext}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}

          {/* Attachments */}
          {activeTabId === 3 && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {/* <Widget> */}
                <AttachmentList
                  patientAttachmentData={patientAttachmentData}
                  setPatientAttachmentData={setPatientAttachmentData}
                  setDataUpdated={setDataUpdated}
                  fileCategoryValues={fileCategoryValues}
                />
                {/* </Widget> */}
              </Grid>
              <Grid className={classes.btnGroup} item xs={12}>
                <Button
                  className={classes.btnleftOutline}
                  variant="outlined"
                  size="large"
                  onClick={() => setActiveTabId(3)}
                >
                  Back
                </Button>
                <Button
                  className={classes.btnlRightDark}
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={() => setActiveTabId(5)}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}

          {/* History */}

          {activeTabId === 4 && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {/* <Widget> */}
                <FamilyList
                  patientId={patient_id}
                  patientFamilyData={patientFamilyData}
                  setPatientFamilyData={setPatientFamilyData}
                  relTypeValues={relTypeValues}
                  setDataUpdated={setDataUpdated}
                />
                {/* </Widget> */}
              </Grid>
              <Grid className={classes.btnGroup} item xs={12}>
                <Button
                  className={classes.btnleftOutline}
                  variant="outlined"
                  size="large"
                  onClick={() => setActiveTabId(6)}
                >
                  Back
                </Button>
                <Button
                  className={classes.btnlRightDark}
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={() => setActiveTabId(8)}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}

          {/* Custom Data */}
          {activeTabId === 5 && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {/* <Widget> */}
                <CustomDataList patientId={patient_id} />
                {/* </Widget> */}
              </Grid>
              <Grid className={classes.btnGroup} item xs={12}>
                <Button
                  className={classes.btnleftOutline}
                  variant="outlined"
                  size="large"
                  onClick={() => setActiveTabId(9)}
                >
                  Back
                </Button>
                <Button
                  className={classes.btnlRightDark}
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={() => setActiveTabId(6)}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}
          {activeTabId === 6 && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {/* <Widget> */}
                <IntractionList patientId={patient_id} />
                {/* </Widget> */}
              </Grid>
              <Grid className={classes.btnGroup} item xs={12}>
                <Button
                  className={classes.btnleftOutline}
                  variant="outlined"
                  size="large"
                  onClick={() => setActiveTabId(5)}
                >
                  Back
                </Button>

              </Grid>
            </Grid>
          )}
        </Paper>
      ) : (
        <>
          <center>
            <img src={pageNotFound} alt="img" />
          </center>
        </>
      )}
    </>
  );
}
