import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  titleDiv: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: "2px",
    },
  },
  imageicon: {
    position: "relative",
    marginTop: "-30px",
    marginRight: "10px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      marginTop: "-20px",
    },
  },
  imageCloseicon: {
    position: "absolute",
    marginTop: "-30px",
    marginRight: "10px",
    cursor: "pointer",
    right: 0,
    [theme.breakpoints.down("md")]: {
      display: "none",
      position: "hidden",
    },
  },
  formBox: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  avatar: {
    width: "68px",
    height: "68px",
  },
  formHeading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    textWrap: "nowrap !important",
  },
  txtForm: {
    padding: "0px 4px",
  },
  modelFooter: {
    padding: theme.spacing(2),
  },
  btnGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      display: "inline",
    },
  },
  btnlRightDark: {
    height: "42px",
    textTransform: "none",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
  },
  btnleftOutline: {
    height: "42px",
    textTransform: "none",
    border: "1px solid #0078D4",
    color: "#0078D4",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  blueButton: {
    background: "#0078D4",
    color: "#fff",
    fontWeight: 400,
    marginTop: theme.spacing(2),
    "&:hover": {
      background: "#0078D4",
      color: "#fff",
    },
  },
  btn: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  gridBox: {
    // display:"flex",
    // justifyItems:"center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  iconsBar: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#fff",
    borderBottomColor: "2px solid #4E97FD",
  },
  tab: {
    color: "#201F1E",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    textTransform: "none",
    "&:hover": {
      color: "#000",
     
    },
    "&:active": {
      color: "#fff",
     
    },
    "&:MuiTab-textColorPrimary.Mui-selected": {
      color: "#4E97FD",
    },
    "&:.MuiTab-textColorPrimary.Mui-selected ":{
      color: "#4E97FD",
  },
    // "&.Mui-selected": {
    //   color: "#fff",
    //   background: "#4E97FD",
    // },
  },
  iconsContainer: {
    background: "none",
    boxShadow: "none",
    // overflow: "hidden",
    paddingBottom: theme.spacing(2),
  },
  gridItem: {
    marginBottom: theme.spacing(4),
  },
}));
