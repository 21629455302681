import React, { useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
 
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import moment from "moment";
// components
// styles
import useStyles from "../styles";

import NewDiagnosis from "./NewDiagnosis";
import PreDiagnosis from "./PreDiagnosis";
import Notes from "./Notes";
import FollowUp from "./FollowUp";
import Referral from "./Referral";

export default function MainAssessmentTab({
  patientId,
  examChartType,
  examCorrectionType,
  examDominantEye,
  examAcuityNotes,
  examCurrentAcuity,
  enable,
  assessmentNotesBtnUnable,
  setAssessmentNotesBtnUnable,
  assessmentRefBtnUnable,
  setAssessmentRefBtnUnable,mode
}) {
  var classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
       <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>New Diagnosis</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <NewDiagnosis
            enable={enable}
            patientId={patientId}
            examChartType={examChartType}
            examCorrectionType={examCorrectionType}
            examDominantEye={examDominantEye}
            examAcuityNotes={examAcuityNotes}
          />
        </AccordionDetails>
      </Accordion>
       <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Previous Diagnosis</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PreDiagnosis
            patientId={patientId}
            examCurrentAcuity={examCurrentAcuity}
          />
        </AccordionDetails>
      </Accordion>
       <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Notes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Notes
            patientId={patientId}
            examCurrentAcuity={examCurrentAcuity}
            assessmentNotesBtnUnable={assessmentNotesBtnUnable}
            setAssessmentNotesBtnUnable={setAssessmentNotesBtnUnable}
          />
        </AccordionDetails>
      </Accordion>
       <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Follow Up</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FollowUp
            patientId={patientId}
            mode={mode}
            examCurrentAcuity={examCurrentAcuity}
          />
        </AccordionDetails>
      </Accordion>
       <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Referral</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Referral
            patientId={patientId}
            examCurrentAcuity={examCurrentAcuity}
            assessmentRefBtnUnable={assessmentRefBtnUnable}
            setAssessmentRefBtnUnable={setAssessmentRefBtnUnable}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
