import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    formBox:{
        paddingLeft:theme.spacing(4),
        paddingRight:theme.spacing(4),
    },
    avatar:{
        width:"85.34px",
        height:"85.34px"
    },
    uploadIcon: {
        position: "absolute",
        marginTop: "-36px",
        marginLeft: "36px",
      },
    formHeading:{
        marginLeft:theme.spacing(0.5),
        // marginTop:theme.spacing(2),
        marginBottom:theme.spacing(0.5),
        fontFamily:'Poppins',
        fontSize:'14px !important',
        fontWeight:500,
        color:'#101010',
    },
    txtForm:{
        padding:"0px 4px",
        fontSize:'14px',
        fontWeight:500,
        fontFamily:'Poppins',
    },
    modelFooter:{
        padding:theme.spacing(2),
        
    },
    blueButton:{
        background:"#0078D4",
        color:"#fff",
        fontWeight:400,
        marginTop:theme.spacing(2),
        "&:hover": {
            background:"#0078D4",
        color:"#fff",
        }
    },
    gridBox:{
        // display:"flex",
        // justifyItems:"center",
        paddingTop:theme.spacing(4),
        paddingBottom:theme.spacing(4)
    },
    btnGroup:{
        paddingTop:theme.spacing(4),
        paddingBottom:theme.spacing(2),
        
    },
    leftButton:{
        fontFamily:'Poppins',
        fontSize:'14px',
        fontWeight:400,
        textTransform:'none',
        height:'38px',
        borderRadius:'2px'
    },
    rightButton:{
        fontFamily:'Poppins',
        fontSize:'14px',
        fontWeight:500,
        marginLeft:theme.spacing(2),
        textTransform:'none',
        height:'38px',
        borderRadius:'2px'
    },
    widgetBox:{
        marginTop:theme.spacing(2)
    },
    
    radioGroup:{
        border:'1px solid #DADFEB',
        borderRadius:'5px',
        paddingLeft:theme.spacing(2),
        paddingRight:theme.spacing(2)
    },
    icons:{
        marginRight:theme.spacing(2)
    },
    cardHeading:{
        fontFamily:'Poppins',
        fontWeight:500,
        color:'#2A2338',
        fontSize:'14px',
        fontStyle:'normal'
    },
    passwordBox:{
        display:"flex",
        alignItems:'center',
        background:'#fff',
        padding:'10px',
        cursor:'pointer'
    },
}));
