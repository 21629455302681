import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  FormControl,
  Fade,
} from "@material-ui/core";

import { MenuItem } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../common/modal/Popup";
// styles
import useStyles from "./styles";
import {
  postScheduleData,
  getschScheduletype,
  getCityStateByZip,
} from "../../services/ServiceLayer";
import {
  scheduleFieldValidation,
  currentUser,
  loadState,
  ssnFormat,
} from "../../hooks/helper";
import { formElementsManage } from "./Schedule_Constant";
import DeletePopup from "./DeletePopup";
import moment from "moment";
import ScheduleValidation from "../../hooks/ScheduleValidation";
export default function ManageUpdateSchedule(props) {
  console.log("props-24", props);
  // const selectPatientSchedule = props.selectPatientSchedule;
  const { selectPatientSchedule, resourceId, selectedLocation } = props;
  console.log("selectPatientSchedule", selectPatientSchedule);
  // Accessing the resourceId prop
  // const resourceId = props.resourceId;
  // const selectedLocation = props.selectedLocation;
  console.log("resourceId", resourceId);
  var classes = useStyles();
  // disable past dates
  toast.configure();
  // list meta data
  const [listData, setListData] = useState([]);

  const [raceValues, setRaceValues] = useState([]);
  const [reasonValues, setReasonValues] = useState([]);
  const [genderValues, setGenderValues] = useState([]);
  const [insuranceValues, setInsuranceValues] = useState([]);
  const [addInsuranceValues, setAddInsuranceValues] = useState([]);
  // list end meta data
  const [radioValue, setRadioValue] = useState("New");

  const [maxWidth, setMaxWidth] = React.useState("md");
  const [patientSearchList, setPatientSearchList] = useState();
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  // delete model
  const [open, setOpen] = React.useState(false);
  const [selectclient, setselectclient] = useState("");
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [title, setTitle] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);

  // call meta data
  // call meta data
  const metaData = loadState().metaData;
  useEffect(() => {
    console.log("loadState", metaData);
    setListData(metaData);
  }, []);

  // get  schedule type
  useEffect(() => {
    getschScheduletype().then((response) => {
      console.log("scheduleType", response);
      setReasonValues(response?.data);
    });
  }, []);

  useEffect(() => {
    console.log("listData", listData);
    if (listData.length > 0) {
      const raceValues = listData[0].race;
      const genderValues = listData[0].gender;
      const insuranceValues = listData[0].insurance;
      const addInsuranceValues = listData[0].additionalInsurance;

      setRaceValues(raceValues);
      setGenderValues(genderValues);
      setInsuranceValues(insuranceValues);
      setAddInsuranceValues(addInsuranceValues);
    }
  }, [listData]);
  // call meta data

  const buttonText =
    props.isReschedule === true ? "Reschedule" : "Add Schedule";
  const schedulePatientData = selectPatientSchedule
    ? selectPatientSchedule[0]
    : {};
  const selectedSchedule = selectPatientSchedule
    ? selectPatientSchedule[1]
    : {};

  const address = schedulePatientData
    ? `${schedulePatientData.billing_address_line1}, ${schedulePatientData.billing_address_line2}  ${schedulePatientData.billing_city}, ${schedulePatientData.billing_state}, ${schedulePatientData.billing_country}, ${schedulePatientData.billing_zip}, `
    : "";

  //
  const [scheduleDetail, setScheduleDetail] = useState({
    patient_id: schedulePatientData?.patient_id,
    first_name: "",
    middle_name: "",
    last_name: "",
    dateofbirth: "",
    gender: "",
    reasonOfVisit: "",
    contact_home: '',
    contact_cell: "",
    email: "",
    billing_address_line1: "",
    billing_city: "",
    billing_state: "",
    billing_country: '',
    billing_zip: "",
    ssn: "",
    insurance_id: "",
    additionalInsurance: "",
    start: "",
    end: "",
    notes: "",
  });
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    ScheduleValidation({
      first_name:
        scheduleDetail?.first_name === undefined
          ? ""
          : scheduleDetail.first_name,
      last_name:
        scheduleDetail?.last_name === undefined ? "" : scheduleDetail.last_name,
      contact_home:
        scheduleDetail?.contact_home === undefined
          ? ""
          : scheduleDetail.contact_home,
      contact_cell:
        scheduleDetail?.contact_cell === undefined
          ? ""
          : scheduleDetail.contact_cell,
      email: scheduleDetail?.email === undefined ? "" : scheduleDetail.email,
      billing_zip:
        scheduleDetail?.billing_zip === undefined
          ? ""
          : scheduleDetail.billing_zip,
      end: scheduleDetail?.end === undefined ? "" : scheduleDetail.end,
      reasonOfVisit:
        scheduleDetail?.reasonOfVisit === undefined
          ? ""
          : scheduleDetail?.reasonOfVisit,
      insurance_id:
        scheduleDetail?.insurance_id === undefined
          ? ""
          : scheduleDetail?.insurance_id,
    });
  const clear = () => {
    setScheduleDetail({
      first_name: "",
      middle_name: "",
      last_name: "",
      dateofbirth: "",
      gender: "",
      contact_home: '',
      contact_cell: "",
      email: "",
      billing_address_line1: "",
      billing_city: "",
      billing_state: "",
      billing_country: "",
      billing_zip: "",
      ssn: "",
      title: "",
      insurance_id: "",
      additionalInsurance: "",
      start: "",
      end: "",
      reasonOfVisit: "",
      notes: "",
    });
    resetAllValidator();
  };

  const formatPhoneNumber = (number) => {
    if (!number) return '';
    const cleaned = ('' + number).replace(/\D/g, '');
    if (cleaned.length > 5) {
      const match = cleaned.match(/^(\d{5})(\d*)$/);
      if (match) {
        return `${match[1]}-${match[2]}`;
      }
    }
    return cleaned;
  };


  useEffect(() => {
    console.log("selectclient useEffect", selectPatientSchedule);
    console.log(
      "selectPatientSchedule?.first_name",
      selectPatientSchedule?.first_name
    );

    if (selectPatientSchedule === "") {
      setScheduleDetail({
        first_name: "",
        middle_name: "",
        last_name: "",
        dateofbirth: "",
        gender: "",
        contact_home: '',
        contact_cell: "",
        email: "",
        billing_address_line1: "",
        billing_address_line2: "",
        billing_city: "",
        billing_state: "",
        billing_country: "",
        billing_zip: "",
        ssn: "",
        title: "",
        insurance_id: "",
        additionalInsurance: "",
        start: "",
        end: "",
        reasonOfVisit: "",
        notes: "",
      });
    } else {
      setScheduleDetail({
        patient_id: schedulePatientData?.patient_id,
        first_name: schedulePatientData?.first_name,
        middle_name: schedulePatientData?.middle_name,
        last_name: schedulePatientData?.last_name,
        dateofbirth: moment(schedulePatientData?.dateofbirth).format(
          "YYYY-MM-DD"
        ),
        gender: schedulePatientData?.gender,
        title: schedulePatientData?.title,
        contact_home: formatPhoneNumber(schedulePatientData?.contact_home),
        contact_cell: formatPhoneNumber(schedulePatientData?.contact_cell),
        email: schedulePatientData?.email,
        billing_address_line1:
          schedulePatientData?.billing_address_line1,
        billing_address_line2:
          schedulePatientData?.billing_address_line2,
        billing_city: schedulePatientData?.billing_city,
        billing_state: schedulePatientData?.billing_state,
        billing_country: schedulePatientData?.billing_country,
        billing_zip: schedulePatientData?.billing_zip,
        start: moment(selectedSchedule?.start).format("YYYY-MM-DDThh:mm"),
        end: moment(selectedSchedule?.end).format("YYYY-MM-DDThh:mm"),
        ssn: schedulePatientData?.ssn,
        notes: schedulePatientData?.notes,
        insurance_id: schedulePatientData?.insurance_id,
        additionalInsurance: schedulePatientData?.add_insurance_id,
        reasonOfVisit: schedulePatientData?.schedule_type_id,
      });
    }
  }, [selectPatientSchedule]);

  const deleteEventHandler = () => {
    setOpen(false);
    setAnchorEl(null);

    setselectclient(selectPatientSchedule);
    setDeleteModalShow(true);
    setTitle("Cancel Schedule");
  };
  const [isActionCalled, setIsActionCalled] = useState(false);
  const handleSubmit = (event) => {
    checkValidator();
    setIsActionCalled(true);
  };
  useEffect(
    (event) => {
      if (!isError() && error !== null && isActionCalled) {
        const postData = {
          schedule_id:
            selectedSchedule.id === undefined ? "0" : selectedSchedule.id,
          start_time: scheduleDetail.start,
          end_time: scheduleDetail.end,
          patient_id:
            scheduleDetail.patient_id === undefined
              ? 0
              : Number(scheduleDetail.patient_id),
          first_name: scheduleDetail.first_name,
          middle_name: scheduleDetail.middle_name,
          last_name: scheduleDetail.last_name,
          gender:
            scheduleDetail.gender === undefined ? "" : scheduleDetail.gender,
          dob:
            scheduleDetail.dateofbirth === undefined
              ? ""
              : scheduleDetail.dateofbirth,
          contact_home:
            scheduleDetail.contact_home === undefined
              ? ""
              : scheduleDetail.contact_home,
          contact_cell:
            scheduleDetail.contact_cell === undefined
              ? ""
              : scheduleDetail.contact_cell,
          email_id:
            scheduleDetail.email === undefined ? "" : scheduleDetail.email,
          insurance_id:
            scheduleDetail.insurance_id === undefined
              ? ""
              : scheduleDetail.insurance_id,
          address1:
            scheduleDetail.billing_address_line1 === undefined
              ? ""
              : scheduleDetail.billing_address_line1,
          address2:
            scheduleDetail.billing_address_line2 === undefined
              ? ""
              : scheduleDetail.billing_address_line2,
          city:
            scheduleDetail.billing_city === undefined
              ? ""
              : scheduleDetail.billing_city,
          state:
            scheduleDetail.billing_state === undefined
              ? ""
              : scheduleDetail.billing_state,
          country:
            scheduleDetail.billing_country === undefined
              ? ""
              : scheduleDetail.billing_country,
          zip:
            scheduleDetail.billing_zip === undefined
              ? ""
              : scheduleDetail.billing_zip,
          ssn: scheduleDetail.ssn === undefined ? "" : scheduleDetail.ssn,
          additional_insurance:
            scheduleDetail.additionalInsurance === undefined
              ? ""
              : scheduleDetail.additionalInsurance,
          schedule_type_id: scheduleDetail?.reasonOfVisit,
          schedule_notes: scheduleDetail.notes,
          location_id: selectedLocation,
          physician_id: resourceId,
          updated_by: currentUser(),
          is_confirmed: "",
          is_rescheduled: "N",
          is_canceled: "",
          is_waitlist: "",
          access_location: "1",
          opt_type: "ADDSCH",
        };

        postScheduleData(postData).then((response) => {
          console.log("postData", postData);
          console.log(response);
          if (response !== undefined && response?.status?.status === true) {
            console.log("hide handler called");
            clear();

            toast.success("Appointment Successfully Scheduled.", {
              position: toast.POSITION.TOP_CENTER,
            });
            setRadioValue("New");
            // window.location.reload();
            setPatientSearchList("");
            props.onHide1();
            setIsActionCalled(false);
            props.setUpdateDataSchedule(true);
          } else {
            console.log("failed api");
            if (response?.status?.description) {
              toast.error(response.status.description, {
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              toast.error(scheduleFieldValidation(scheduleDetail), {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          }
        });
      }
      // else {
      //   toast.error("Please enter all required fields", {
      //     position: toast.POSITION.TOP_CENTER,
      //   });
      // }
      setIsActionCalled(false);
    },
    [isActionCalled]
  );

  const hideHandler1 = () => {
    console.log("hide handler called");
    clear();
    setRadioValue("New");
    // window.location.reload();
    setPatientSearchList(null);
    props.onHide1();
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpen(false);

    props.onHide1();
  };
  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    if (scheduleDetail?.billing_zip) {
      if (scheduleDetail?.billing_zip.length >= 5) {
        getCityStateByZip(`zip=${scheduleDetail?.billing_zip}`)
          .then((response) => {
            console.log("response-810", response);
            if (response?.status.description === "") {
              setSuggestions(response?.data);
              setScheduleDetail({
                ...scheduleDetail,
                billing_city: response?.data[0]?.city,
                billing_state: response?.data[0]?.state_id,
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching city and state:", error);
            // Handle error if necessary
          });
      } else {
        // Clear city and state if zip code is less than 5 characters
        setScheduleDetail({
          ...scheduleDetail,
          billing_city: "",
          billing_state: "",
        });
      }
    }
  }, [scheduleDetail?.billing_zip]);
  const { onHide1, ...props1 } = props;
  return (
    <>
      <DeletePopup
        onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        selectclient={selectclient}
        setDeleteModalShow={setDeleteModalShow}
      />
      <Popup
        {...props1}
        onHide1={hideHandler1}
        // fullWidth={fullWidth}
        // fullScreen={fullScreen}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.fillBtn}
              variant="outlined"
              size="medium"
              color="secondary"
              onClick={hideHandler1}
            >
              Close
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleSubmit}
            >
              {buttonText}
            </Button>

            {props.isReschedule === true && (
              <Button
                className={classes.fillBtn}
                variant="contained"
                size="medium"
                color="secondary"
                onClick={() => deleteEventHandler()}
              >
                Cancel Appointment
              </Button>
            )}
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid container spacing={1}>
              <>
                {formElementsManage.map((element, i) => {
                  return (
                    <>
                      {element.type === "select" ? (
                        <Grid
                          item
                          xs={12}
                          lg={4}
                          md={element.mdSize}
                          className={classes.textGroup}
                        >
                          <Typography className={classes.formHeading}>
                            {element.label}
                            {element.required === "true" ? (
                              <span className={classes.required}>*</span>
                            ) : null}
                          </Typography>
                          <FormControl
                            fullWidth
                            className={classes.dropdownFormControl}
                          >
                            <Select
                              name={element.key}
                              style={{ fontSize: "12px" }}
                              className={classes.selectDropdown}
                              placeholder={element.placeHolder}
                              value={
                                (scheduleDetail &&
                                  scheduleDetail[element.key]) ||
                                ""
                              }
                              onChange={(e) => {
                                if (element.key === "reasonOfVisit") {
                                  const selectedReason = e.target.value;
                                  const selectedDuration = reasonValues.find(
                                    (reason) =>
                                      reason.schedule_type_id === selectedReason
                                  )?.schedule_duration;

                                  if (selectedDuration) {
                                    const updatedEndTime = moment(
                                      scheduleDetail.start
                                    )
                                      .add(selectedDuration, "minutes")
                                      .format("YYYY-MM-DDTHH:mm:ss");

                                    setScheduleDetail({
                                      ...scheduleDetail,
                                      end: updatedEndTime,
                                      [element.key]: selectedReason,
                                    });
                                  }
                                } else {
                                  setScheduleDetail({
                                    ...scheduleDetail,
                                    [element.key]: e.target.value,
                                  });
                                }
                                resetValidator(e.target.name);
                              }}
                              isInvalid={error && error[element.key]}
                            >
                              <MenuItem disabled value="">
                                Select
                              </MenuItem>
                              {element.key == "gender" && genderValues
                                ? genderValues.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                                : element.key == "reasonOfVisit" && reasonValues
                                  ? reasonValues.map((item) => (
                                    <MenuItem
                                      key={item.schedule_type_id}
                                      value={item.schedule_type_id}
                                    >
                                      {item.schedule_name}
                                    </MenuItem>
                                  ))
                                  : element.key == "insurance_id" &&
                                    insuranceValues
                                    ? insuranceValues.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                        disabled={
                                          scheduleDetail &&
                                            scheduleDetail?.insurance_id
                                            ? true
                                            : false
                                        }
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                    : element.key == "additionalInsurance" &&
                                      addInsuranceValues
                                      ? addInsuranceValues.map((item) => (
                                        <MenuItem
                                          key={item.attribute_id}
                                          value={item.attribute_id}
                                          disabled={
                                            scheduleDetail &&
                                              scheduleDetail?.additionalInsurance
                                              ? true
                                              : false
                                          }
                                        >
                                          {item.attribute_val}
                                        </MenuItem>
                                      ))
                                      : (element.key == "billing_state" &&
                                        element.options) ||
                                        (element.key == "title" && element.options)
                                        ? element.options.map((item) => (
                                          <MenuItem key={item} value={item}>
                                            {item}
                                          </MenuItem>
                                        ))  : (element.key == "billing_country" &&
                                          element.options) ||
                                          (element.key == "title" && element.options)
                                          ? element.options.map((item) => (
                                            <MenuItem key={item} value={item}>
                                              {item}
                                            </MenuItem>
                                          ))
                                          : null}
                            </Select>
                          </FormControl>
                          <Fade in={error}>
                            <Typography
                              color="secondary"
                              className={classes.errorMessage}
                            >
                              {error && error[element.key]}
                            </Typography>
                          </Fade>
                        </Grid>
                      ) : element.type === "text" &&
                        element.key !== "billing_zip" ? (
                        <Grid
                          item
                          xs={12}
                          lg={4}
                          md={element.mdSize}
                          className={classes.textGroup}
                        >
                          <Typography className={classes.formHeading}>
                            {element.label}
                            {element.required === "true" ? (
                              <span className={classes.required}>*</span>
                            ) : null}
                          </Typography>
                          <TextField
                            required
                            name={element.key}
                            placeholder={element.placeHolder}
                            size="small"
                            className={classes.txtForm}
                            fullWidth
                            variant="outlined"
                            inputProps={{
                              style: {
                                height: "12px",
                                fontSize: "12px",
                              },
                              maxLength: element.maxLength,
                              ...(element.key === "ssn" && {
                                onInput: (e) => {
                                  e.target.value = ssnFormat(e.target.value);
                                },
                              }),
                              onKeyPress: element.onKeyPress, // Add the event handler
                            }}
                            value={
                              scheduleDetail && scheduleDetail[element.key]
                            }
                            onChange={(e) => {
                              setScheduleDetail({
                                ...scheduleDetail,
                                [element.key]: e.target.value,
                              });
                              resetValidator(e.target.name);
                            }}
                            isInvalid={error && error[element.key]}
                          />
                          <Fade in={error}>
                            <Typography
                              color="secondary"
                              className={classes.errorMessage}
                            >
                              {error && error[element.key]}
                            </Typography>
                          </Fade>
                        </Grid>
                      ) : element.type === "text" &&
                        element.key === "billing_zip" ? (
                        <Grid
                          item
                          xs={12}
                          lg={4}
                          md={element.mdSize}
                          className={classes.textGroup}
                        >
                          <Typography className={classes.formHeading}>
                            {element.label}
                            {element.required === "true" ? (
                              <span className={classes.required}>*</span>
                            ) : null}
                          </Typography>
                          <TextField
                            id={element.key}
                            name={element.key}
                            placeholder={element.placeHolder}
                            type={element.type}
                            size="small"
                            inputProps={{
                              style: {
                                height: "12px",
                                fontSize: "12px",
                              },
                              maxLength: element.maxLength,
                            }}
                            className={classes.txtForm}
                            fullWidth
                            variant="outlined"
                            value={
                              scheduleDetail && scheduleDetail[element.key]
                            }
                            onChange={(e) => {
                              setScheduleDetail({
                                ...scheduleDetail,
                                [element.key]: e.target.value,
                              });
                              resetValidator(e.target.name);
                            }}
                            isInvalid={error && error[element.key]}
                          />
                          <Fade in={error}>
                            <Typography
                              color="secondary"
                              className={classes.errorMessage}
                            >
                              {error && error[element.key]}
                            </Typography>
                          </Fade>
                        </Grid>
                      ) : element.type === "date" ? (
                        <Grid item xs={12} md={3} lg={4} className={classes.textGroup}>
                          {/* Name */}
                          <Typography className={classes.formHeading}>
                            {element.label}
                            {element.required === "true" ? (
                              <span className={classes.required}>*</span>
                            ) : null}
                          </Typography>
                          <TextField
                            id={element.key}
                            name={element.key}
                            placeholder={element.placeHolder}
                            type={element.type}
                            size="small"
                            className={classes.txtForm}
                            fullWidth
                            variant="outlined"
                            value={
                              scheduleDetail && scheduleDetail[element.key]
                            }
                            onChange={(e) => {
                              setScheduleDetail({
                                ...scheduleDetail,
                                [element.key]: e.target.value,
                              });
                              resetValidator(e.target.name);
                            }}
                            // onKeyDown={(e) => {
                            //   e.preventDefault(); // Prevent manual typing
                            // }}
                            inputProps={{
                              min: `${currentDateTime}`,
                              max: `${currentDate}`, // Set the maximum time to 18:00
                              style: {
                                height: "12px",
                                fontSize: "12px",
                              },
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            isInvalid={error && error[element.key]}
                          />
                          <Fade in={error}>
                            <Typography
                              color="secondary"
                              className={classes.errorMessage}
                            >
                              {error && error[element.key]}
                            </Typography>
                          </Fade>
                        </Grid>
                      ) : element.type === "datetime-local" ? (
                        <Grid item xs={12} md={3} lg={4} className={classes.textGroup}>
                          {/* Name */}
                          <Typography className={classes.formHeading}>
                            {element.label}
                            {element.required === "true" ? (
                              <span className={classes.required}>*</span>
                            ) : null}
                          </Typography>
                          <TextField
                            id={element.key}
                            name={element.key}
                            placeholder={element.placeHolder}
                            type={element.type}
                            size="small"
                            className={classes.txtForm}
                            fullWidth
                            variant="outlined"
                            value={
                              scheduleDetail && scheduleDetail[element.key]
                            }
                            onChange={(e) => {
                              const selectedEndTime = e.target.value;
                              // const selectedStartTime = scheduleDetail.start;
                              const selectedStartTime = moment(
                                e.target.value,
                                "YYYY-MM-DDTHH:mm"
                              );
                              const timeDifference = moment(
                                scheduleDetail.end,
                                "YYYY-MM-DDTHH:mm"
                              ).diff(
                                moment(scheduleDetail.start, "YYYY-MM-DDTHH:mm")
                              );
                              const updatedEndTime = selectedStartTime
                                .clone()
                                .add(timeDifference);
                              const endTime = moment(
                                selectedEndTime,
                                "YYYY-MM-DDTHH:mm"
                              );
                              const startTime = moment(
                                selectedStartTime,
                                "YYYY-MM-DDTHH:mm"
                              );
                              const endHour = endTime.hour();
                              const startHour = startTime.hour();

                              const maxHour = 18; // Maximum hour allowed (6 PM)

                              if (
                                endHour > maxHour ||
                                (endHour === maxHour && endTime.minute() > 0)
                              ) {
                                alert(
                                  "Please select a time before 18:00 (6 PM)."
                                );
                                return; // Prevent updating the state if time is after 6 PM
                              }
                              setScheduleDetail({
                                ...scheduleDetail,
                                start: e.target.value,
                                end: updatedEndTime.format("YYYY-MM-DDTHH:mm"),
                              });
                              resetValidator(e.target.name);
                            }}
                            onKeyDown={(e) => {
                              e.preventDefault(); // Prevent manual typing
                            }}
                            inputProps={{
                              min: `${currentDateTime}`,
                              max: `${currentDate}`,
                              style: {
                                height: "12px",
                                fontSize: "12px",
                              },
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            isInvalid={error && error[element.key]}
                          />
                          <Fade in={error}>
                            <Typography
                              color="secondary"
                              className={classes.errorMessage}
                            >
                              {error && error[element.key]}
                            </Typography>
                          </Fade>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={12}
                          md={12}

                          className={classes.textGroup}
                        >
                          <Typography className={classes.formHeading}>
                            {element.label}
                            {element.required === "true" ? (
                              <span className={classes.required}>*</span>
                            ) : null}
                          </Typography>
                          <TextField
                            size="small"
                            className={classes.txtForm}
                            fullWidth
                            placeholder="Type here..."
                            variant="outlined"
                            multiline
                            rows={3}
                            inputProps={{
                              style: {
                                fontSize: "12px",
                              },
                            }}
                            disabled={element.key === "end"} // Disable
                            value={scheduleDetail && scheduleDetail.notes}
                            onChange={(e) => {
                              setScheduleDetail({
                                ...scheduleDetail,
                                notes: e.target.value,
                              });
                              resetValidator(e.target.name);
                            }}
                            isInvalid={error && error[element.key]}
                          />
                          <Fade in={error}>
                            <Typography
                              color="secondary"
                              className={classes.errorMessage}
                            >
                              {error && error[element.key]}
                            </Typography>
                          </Fade>
                        </Grid>
                      )}
                    </>
                  );
                })}
              </>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
