import React from "react";
import AddDoctor from "../../components/Settings/Doctor/AddDoctor";

export default function Client() {
  return (
    <div>
      <AddDoctor />
    </div>
  );
}
