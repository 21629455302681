import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";

import Popup from "../../../../common/modal/Popup";

// styles
import useStyles from "./styles";
import moment from "moment";
import { toast } from "react-toastify";
import useListData from "../../../../common/allMetaData/useListData";
import { getDoctorList, postPatientInteraction } from "../../../../../services/ServiceLayer";
import { currentUser, loadState } from "../../../../../hooks/helper";

export default function ManageInteraction(props) {
  toast.configure();

  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  //
  const formElements = [
    {
      key: "intac_date",
      label: "Created Date & Time",
      placeHolder: "",
      type: "datetime-local",
      mdSize: "4",
    },
    {
      key: "intac_category",
      label: "Cateogry",
      placeHolder: "select",
      type: "select",
      options: [],
      mdSize: "4",
    },
    {
      key: "intac_status",
      label: "Status",
      placeHolder: "select",
      type: "select",
      options: [],
      mdSize: "4",
    },
    {
      key: "intac_channel",
      label: "Channel",
      placeHolder: "select",
      type: "select",
      options: [],
      mdSize: "4",
    },
    {
      key: "intac_about",
      label: "About",
      placeHolder: "select",
      type: "select",
      options: [],
      mdSize: "4",
    },
    {
      key: "intac_by",
      label: "Captured By",
      placeHolder: "select",
      type: "select",
      options: [],
      mdSize: "4",
    },
    {
      key: "intac_notes",
      label: "Notes Preview",
      placeHolder: "type here..",
      type: "text",
      mdSize: "6",
    },
    {
      key: "ntac_practice_notes",
      label: "Practice Updates",
      placeHolder: "type here..",
      type: "text",
      mdSize: "6",
    },
  ];
  const { selectclient,patientId,setIsUpdated } = props;
  console.log("selectclient", selectclient);

  const [relationValue, setRelationValue] = useState(null);

  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");

  const hideHandler = () => {
    console.log("hide handler called");
    setRelationValue(null);
    props.onHide();
  };
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [doctorList, setDoctorList] = useState([]);
  useEffect(() => {
    getDoctorList(
      `user_id=${userData?.user_id}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSCH`
    ).then((response) => {
      console.log(response);
      setDoctorList(response?.data);
    });
  }, []);
  const listData = useListData();
  const {
    patInteractionCategory,
    examMode,
    patInteractionStatus,
    patInteractionAbout,
  } = listData;

  const { onHide, ...props1 } = props;

  const [intForm, setIntForm] = useState({
    patient_id: patientId,
    location_id: userData?.location_id,
    intac_date: "",
    intac_category: "",
    intac_status: "",
    intac_channel: "",
    intac_about: "",
    intac_by: "",
    intac_notes: "",
    intac_practice_notes: "",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: "ADDPATINT",
  });
  const clearForm = () => {
    setIntForm({
      patient_id: patientId,
    location_id: userData?.location_id,
    intac_date: "",
    intac_category: "",
    intac_status: "",
    intac_channel: "",
    intac_about: "",
    intac_by: "",
    intac_notes: "",
    intac_practice_notes: "",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: "ADDPATINT",
    });
  };
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setIntForm({
        patient_id: patientId,
        location_id: userData?.location_id,
        intac_date: "",
        intac_category: "",
        intac_status: "",
        intac_channel: "",
        intac_about: "",
        intac_by: "",
        intac_notes: "",
        intac_practice_notes: "",
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: "ADDPATINT",
      });
    } else {
      setIntForm({
        patient_id: patientId,
    location_id: userData?.location_id,
    intac_date: "",
    intac_category: "",
    intac_status: "",
    intac_channel: "",
    intac_about: "",
    intac_by: "",
    intac_notes: "",
    intac_practice_notes: "",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: "ADDPATINT",
      });
    }
  }, [selectclient]);

  const [isActionCalled, setIsActionCalled] = useState(false);

  const handleSubmit = () => {
   
    setIsActionCalled(true);
  };
  useEffect(() => {
    if ( isActionCalled) {

      postPatientInteraction(intForm).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success(`Patient interaction ${selectclient ? 'update' : 'added'}  successfully.`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          clearForm();
          props.onHide();
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    
    setIsUpdated(true);
    setIsActionCalled(false);
  }, [isActionCalled]);
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>
            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleSubmit}
            >
              Add
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid container spacing={1} style={{ margin: "4px 0px" }}>
              {formElements.map((element, i) => {
                return (
                  <>
                    <Grid
                      key={i}
                      item
                      xs={12}
                      md={element.mdSize}
                      className={classes.textGroup}
                    >
                      <Typography className={classes.formHeading}>
                        {element.label}{" "}
                        {element.required === "true" ? (
                          <span className={classes.required}>*</span>
                        ) : null}
                      </Typography>

                      {element.type === "select" ? (
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            name={element.key}
                            className={classes.selectDropdown}
                            placeholder={element.placeHolder}
                            value={
                              intForm && intForm[element.key]
                                ? intForm && intForm[element.key]
                                : ""
                            }
                            onChange={(e) => {
                              console.log("selected", e.target.value);

                              setIntForm({
                                ...intForm,
                                [element.key]: e.target.value,
                              });
                            }}
                          >
                            <MenuItem disabled value="">
                              Select
                            </MenuItem>
                            {element.key === "intac_category" &&
                            patInteractionCategory
                              ? patInteractionCategory.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "intac_status" &&
                                patInteractionStatus
                              ? patInteractionStatus.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "intac_about" &&
                                patInteractionAbout
                              ? patInteractionAbout.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "intac_channel" && examMode
                              ? examMode.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "intac_by" && doctorList
                              ? doctorList.map((item) => (
                                  <MenuItem
                                    key={item.staff_id}
                                    value={item.staff_id}
                                  >
                                    {item.first_name} {item.middle_name}{" "}
                                    {item.last_name}
                                  </MenuItem>
                                ))
                              : element?.options.map((item) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      ) : element.type === "text" ? (
                        <TextField
                          required
                          name={element.key}
                          placeholder={element.placeHolder}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                          }}
                          value={
                            intForm && intForm[element.key]
                              ? intForm && intForm[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected", e.target.value);

                            setIntForm({
                              ...intForm,
                              [element.key]: e.target.value,
                            });
                          }}
                        />
                      ) : element.type === "date" ||
                        element.type === "datetime-local" ? (
                        <TextField
                          id={element.key}
                          name={element.key}
                          placeholder={element.placeHolder}
                          type={element.type}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            min: `${currentDateTime}`,
                            max: `${currentDate}`,
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={
                            intForm && intForm[element.key]
                              ? intForm && intForm[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected", e.target.value);

                            setIntForm({
                              ...intForm,
                              [element.key]: e.target.value,
                            });
                          }}
                        />
                      ) : element.type === "textarea" ? (
                        <TextField
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          placeholder="Type here..."
                          variant="outlined"
                          multiline
                          rows={3}
                          inputProps={{
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          value={
                            intForm && intForm[element.key]
                              ? intForm && intForm[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected", e.target.value);

                            setIntForm({
                              ...intForm,
                              [element.key]: e.target.value,
                            });
                          }}
                        />
                      ) : element.type === "button" ? (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                          style={{
                            width: "100%",
                            fontSize: "10px",
                            marginTop: "26px",
                          }}
                        >
                          {element.btnName}
                        </Button>
                      ) : (
                        <Checkbox />
                      )}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
