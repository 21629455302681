import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Fade,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  Popover,
  List,
} from "@material-ui/core";

import moment from "moment";
import useStyles from "../styles";
import { CheckBox } from "@material-ui/icons";
import { formProblemElements, specticalFormElements } from "./Visual_Constant";
import { Autocomplete } from "@mui/material";

export default function VisualAcuity({
  error,
  resetValidator,
  patientId,
  examChartType,
  examCorrectionType,
  examDominantEye,
  examCurrentAcuity,
  examAcuityNotes,
  visualAcuityDetails,
  setVisualAcuityDetails,setVisualBtnUnable,visualBtnUnable,setShowDiscard
}) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();

  const [options, setOptions] = useState([]);
  // const previousController = useRef();

  useEffect(() => {
    console.log("options", options);
  }, [options]);

  const optionsMap = {
    chart_type: examChartType,
    correction_type: examCorrectionType,
    dominant_eye: examDominantEye,
    visacu_note: examAcuityNotes,
    unau_acu_disvis_od:examCurrentAcuity,
    unau_acu_phval_od:examCurrentAcuity,
    unau_acu_nrvis_od:examCurrentAcuity,
    unau_acu_otvis_od:examCurrentAcuity,
    unau_acu_disvis_os:examCurrentAcuity,
    unau_acu_phval_os:examCurrentAcuity,
    unau_acu_nrvis_os:examCurrentAcuity,
    unau_acu_otvis_os:examCurrentAcuity,
    unau_acu_disvis_ou:examCurrentAcuity,
    unau_acu_phval_ou:examCurrentAcuity,
    unau_acu_nrvis_ou:examCurrentAcuity,
    unau_acu_otvis_ou:examCurrentAcuity,
    au_acu_disvis_od:examCurrentAcuity,
    au_acu_phval_od:examCurrentAcuity,
    au_acu_nrvis_od:examCurrentAcuity,
    au_acu_otvis_od:examCurrentAcuity,
    au_acu_disvis_os:examCurrentAcuity,
    au_acu_phval_os:examCurrentAcuity,
    au_acu_nrvis_os:examCurrentAcuity,
    au_acu_otvis_os:examCurrentAcuity,
    au_acu_disvis_ou:examCurrentAcuity,
    au_acu_phval_ou:examCurrentAcuity,
    au_acu_nrvis_ou:examCurrentAcuity,
    au_acu_otvis_ou:examCurrentAcuity,
    col_vis_od:examCurrentAcuity,
    col_vis_os:examCurrentAcuity,
    col_vis_ou:examCurrentAcuity,
  };
   // Central handler for all Autocomplete changes
   const handleAutocompleteChange = (key, newValue) => {
    setVisualAcuityDetails((prev) => ({
      ...prev,
      [key]: newValue ? newValue.attribute_id : null,
    }));
    setVisualBtnUnable(true);
    setShowDiscard(true);
  };

  return (
    <>
      <Grid container spacing={2} className={classes.formBox}>
        {formProblemElements.map((element, i) => {
          return (
            <>
              <Grid
                item
                xs={12}
                md={element.mdSize}
                className={classes.textGroup}
                style={{ paddingBottom:'0px',paddingTop:'0px' }}
              >
                <Typography className={classes.formHeading}>
                  {element.label}{" "}
                  {element.required === "true" ? (
                    <span className={classes.required}>*</span>
                  ) : null}
                </Typography>

                {element.type === "select" ? (
                  <>
                     <Autocomplete style={{marginBottom:'10px'}}
                        options={optionsMap[element.key] || []}
                        getOptionLabel={(option) => option.attribute_val || ""}
                        value={
                          (optionsMap[element.key] || []).find(
                            (option) =>
                              option.attribute_id === visualAcuityDetails[element.key]
                          ) || null
                        }
                        onChange={(e, newValue) =>
                          handleAutocompleteChange(element.key, newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            variant="outlined"
                            fullWidth
                            // InputProps={{
                            //   ...params.InputProps,
                            //   style: {
                            //     height: '36px',
                            //     padding: '10 14px',
                            //   },
                            // }}
                            inputProps={{
                              ...params.inputProps,
                              style: {
                                height: 'auto', // Adjust if you need to change the height of the inner input element
                                padding: '0 14px', 
                                
                              },
                            }}
                          />
                        )}
                      />
                    <Fade in={error}>
                      <Typography
                        color="secondary"
                        className={classes.errorMessage}
                      >
                        {error && error[element.key]}
                      </Typography>
                    </Fade>
                  </>
                ) : element.type === "textarea" ? (
                  <>
                    <TextField
                      name={element.key}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      placeholder="Type here..."
                      variant="outlined"
                      multiline
                      rows={3}
                      inputProps={{
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                        maxLength: element.maxLength,

                        onKeyPress: element.onKeyPress, // Add the event handler
                        onPaste: element.onPaste,
                      }}
                      value={
                        visualAcuityDetails &&
                        visualAcuityDetails[element.key] === null
                          ? ""
                          : visualAcuityDetails &&
                            visualAcuityDetails[element.key]
                      }
                      onChange={async (e) => {
                        setVisualAcuityDetails({
                          ...visualAcuityDetails,
                          [element.key]: e.target.value,
                        });
                        setVisualBtnUnable(true);
                        setShowDiscard(true);
                      }}
                      // isInvalid={error && error[element.key]}
                    />
                    {/* <Fade in={error}>
                      <Typography
                        color="secondary"
                        className={classes.errorMessage}
                      >
                        {error && error[element.key]}
                      </Typography>
                    </Fade> */}
                  </>
                ) : element.type === "date" ||
                  element.type === "datetime-local" ? (
                  <>
                    <TextField
                      id={element.key}
                      name={element.key}
                      placeholder={element.placeHolder}
                      type={element.type}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      inputProps={{
                        min: `${currentDateTime}`,
                        max: `${currentDate}`,
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        visualAcuityDetails &&
                        visualAcuityDetails[element.key] === null
                          ? ""
                          : visualAcuityDetails &&
                            visualAcuityDetails[element.key]
                      }
                      onChange={async (e) => {
                        setVisualAcuityDetails({
                          ...visualAcuityDetails,
                          [element.key]: e.target.value,
                        });
                        setVisualBtnUnable(true);
                        setShowDiscard(true);
                        // resetValidator(e.target.name);
                      }}
                      onKeyDown={(e) => {
                        e.preventDefault(); // Prevent manual typing
                      }}
                      // isInvalid={error && error[element.key]}
                    />
                    {/* <Fade in={error}>
                      <Typography
                        color="secondary"
                        className={classes.errorMessage}
                      >
                        {error && error[element.key]}
                      </Typography>
                    </Fade> */}
                  </>
                ) : (
                  <>
                    <TextField
                      type={element.type}
                      name={element.key}
                      placeholder={element.placeHolder}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      autoFocus
                      inputProps={{
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                        maxLength: element.maxLength,

                        onKeyPress: element.onKeyPress, // Add the event handler
                        onPaste: element.onPaste,
                      }}
                      value={
                        visualAcuityDetails &&
                        visualAcuityDetails[element.key] === null
                          ? ""
                          : visualAcuityDetails &&
                            visualAcuityDetails[element.key]
                      }
                      onChange={async (e) => {
                        setVisualAcuityDetails({
                          ...visualAcuityDetails,
                          [element.key]: e.target.value,
                        });
                        setVisualBtnUnable(true);
                        setShowDiscard(true);
                        // resetValidator(e.target.name);
                      }}
                      // isInvalid={error && error[element.key]}
                    />
                    {/* <Fade in={error}>
                      <Typography
                        color="secondary"
                        className={classes.errorMessage}
                      >
                        {error && error[element.key]}
                      </Typography>
                    </Fade> */}
                  </>
                )}
              </Grid>
            </>
          );
        })}
         {specticalFormElements.map((element, i) => {
          return (
            <Grid
              item
              xs={12}
              md={element.mdSize}
              className={`${classes.textGroup} ${
                element.title ? classes.highlightedItem : ""
              }`}
              // style={{ border: "1px solid #DED9D9" }}
            >
              {element.label && (
                <Typography
                  className={classes.formHeading}
                  style={{
                    fontWeight: "500",
                    color: "#4E97FD",
                    position: "relative",
                  }}
                >
                  {element.label}&nbsp;
                  {element.required === "true" ? (
                    <span className={classes.required}>*</span>
                  ) : null}
                  {element.copyBtn === "true" && (
                    <Button
                      style={{
                        float: "right",
                        position: "absolute",
                        top: "20px",
                      }}
                      classes={{ root: classes.button }}
                      variant="contained"
                      color="secondary"
                      // onClick={duplicateValue}
                    >
                      =
                    </Button>
                  )}
                </Typography>
              )}
              {element.type ? (
                <>
                  {element.type === "select"  ? (
                     <Autocomplete style={{marginBottom:'10px'}}
                     options={optionsMap[element.key] || []}
                     getOptionLabel={(option) => option.attribute_val || ""}
                     value={
                       (optionsMap[element.key] || []).find(
                         (option) =>
                           option.attribute_id === visualAcuityDetails[element.key]
                       ) || null
                     }
                     onChange={(e, newValue) =>
                       handleAutocompleteChange(element.key, newValue)
                     }
                     renderInput={(params) => (
                       <TextField
                         {...params}
                         label=""
                         variant="outlined"
                         fullWidth
                         // InputProps={{
                         //   ...params.InputProps,
                         //   style: {
                         //     height: '36px',
                         //     padding: '10 14px',
                         //   },
                         // }}
                         inputProps={{
                           ...params.inputProps,
                           style: {
                             height: 'auto', // Adjust if you need to change the height of the inner input element
                             padding: '0 14px', 
                             
                           },
                         }}
                       />
                     )}
                   />
                  ) : element.type === "text" ? (
                    <div style={{ display: "flex" }}>
                      <TextField
                        required
                        name={element.key}
                        placeholder={element.placeHolder}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                          maxLength: element.maxLength,
                          onKeyPress: element.onKeyPress,
                        }}
                        value={
                          visualAcuityDetails &&
                          visualAcuityDetails[element.key]
                            ? visualAcuityDetails &&
                              visualAcuityDetails[element.key]
                            : ""
                        }
                        onChange={(e) => {
                          console.log("selected");
                          setVisualAcuityDetails({
                            ...visualAcuityDetails,
                            [element.key]: e.target.value,
                          });
                          setVisualBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                      {element.key === "sku" && (
                        <Button
                          // onClick={selectFrameBySku}
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                        >
                          Slect
                        </Button>
                      )}
                    </div>
                  )  : element.type === "date" ||
                    element.type === "datetime-local" ? (
                    <TextField
                      id={element.key}
                      name={element.key}
                      placeholder={element.placeHolder}
                      type={element.type}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        visualAcuityDetails && visualAcuityDetails[element.key]
                          ? visualAcuityDetails &&
                            visualAcuityDetails[element.key]
                          : ""
                      }
                      onChange={(e) => {
                        console.log("selected");
                        setVisualAcuityDetails({
                          ...visualAcuityDetails,
                          [element.key]: e.target.value,
                        });
                        setVisualBtnUnable(true);
                        setShowDiscard(true);
                      }}
                    />
                  ) : element.type === "radio" ? (
                    <RadioGroup row defaultValue="">
                      {element.radioValue.map((item) => (
                        <FormControlLabel
                          size="small"
                          style={{ padding: "0px 6px" }}
                          value={item}
                          control={<Radio />}
                          label={item}
                          className={classes.smallRadioButton}
                        />
                      ))}
                    </RadioGroup>
                  ) : element.type === "checkbox" ? (
                    <>
                      <CheckBox name={element.key} />
                    </>
                  ) : element.type === "button" &&
                    element?.btnlbl === "Lookup" ? (
                    <>
                      <Button
                        classes={{ root: classes.button }}
                        variant="contained"
                        color="secondary"
                        // onClick={selectPrevFrame}
                      >
                        {element.btnlbl}
                      </Button>
                    </>
                  ) : element.type === "button" &&
                    element?.btnlbl === "Update" ? (
                    <>
                      {/* {selectedRow && (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                        //   onClick={updateSelectFrame}
                        >
                          {element.btnlbl}
                        </Button>
                      )} */}
                    </>
                  ) : (
                    <TextField
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      placeholder="Type here..."
                      variant="outlined"
                      multiline
                      rows={3}
                      inputProps={{
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                        maxLength: element.maxLength,

                        onKeyPress: element.onKeyPress, // Add the event handler
                        onPaste: element.onPaste,
                      }}
                      value={
                        visualAcuityDetails && visualAcuityDetails[element.key]
                          ? visualAcuityDetails &&
                            visualAcuityDetails[element.key]
                          : ""
                      }
                      onChange={(e) => {
                        console.log("selected");
                        setVisualAcuityDetails({
                          ...visualAcuityDetails,
                          [element.key]: e.target.value,
                        });
                        setVisualBtnUnable(true);
                        setShowDiscard(true);
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <Typography
                    className={classes.formHeading}
                    style={{
                      fontWeight: "600",
                      color: "#4E97FD",
                      // position: 'absolute',
                      // marginTop: i===0 ? '24px':'10px'
                    }}
                  >
                    {element.title}
                  </Typography>
                </>
              )}
            </Grid>
          );
        })}
        
      </Grid>
      
    </>
  );
}
