import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react"; // { Component }
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./schedule.css";

import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import image from "../../assets/schedule.svg";
import { loadState } from "../../hooks/helper";
import useStyles from "./styles";
import { Calendar } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { getNonWorkingDay, getWeekend } from "../../hooks/helper";
import {
  getLocationAndResource,
  getPatientSchedule,
  getSchedulebylocations,
  getStaffAvailability,
} from "../../services/ServiceLayer";
import ManageSchedule from "./ManageSchedule";
import { postScheduleData } from "../../services/ServiceLayer";
moment.locale("en-GB");
const localizer = momentLocalizer(moment);
// const DragAndDropCalendar = withDragAndDrop(Calendar);
const ScheduleList = ({ start, end }) => {
  toast.configure();
  var classes = useStyles();

  const DragAndDropCalendar = withDragAndDrop(Calendar);
  const userData = loadState().user;
  const [scheduleData, setScheduleData] = useState([]);
  const [locationResourceName, setLocationResourceName] = useState([]);
  const [locationName, setLocationName] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [resourceName, setResourceName] = useState([]);
  const [staffDetails, setStaffDetails] = useState([]);
  const [resources, setResources] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [updateDataSchedule, setUpdateDataSchedule] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isReschedule, setIsReschedule] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [checkIsCancelled, setCheckIsCancelled] = useState("N");
  const [checkIsRescheduled, setCheckIsRescheduled] = useState("Y");
  const [checkIsAutoRescheduled, setCheckIsAutoRescheduled] = useState("N");

  useEffect(() => {
    getLocationAndResource(
      `location_id=${userData.location_id}&&user_id=${userData.user_id}&user_role=${userData.user_role}&opt_type=SELSCH`
    ).then((response) => {
      console.log("response", response);

      if (response?.status?.status) {
        setLocationResourceName(response?.data);
        getLocation(response?.data);
        const firstLocationId = response?.data[0]?.location_id || "";
        setSelectedLocation(firstLocationId);
        const resources =
          response?.data.find(
            (location) => location.location_id === `${firstLocationId}`
          )?.doc_info || [];
        setResourceName(resources);
        const firstDocId = resources.length > 0 ? resources[0]?.doc_id : "";
        setSelectedProvider(firstDocId);
      } else {
        toast.error("Please register doctor from staff registration.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  }, []);

  const onClickLocation = (event) => {
    console.log("api-call");
    const locationId = event.target.value || userData?.location_id;
    setSelectedLocation(locationId);
    setSelectedProvider(""); // Reset selected provider

    if (locationId) {
      console.log("locationResourceName", locationResourceName);
      const selectedData = locationResourceName.find(
        (element) => element.location_id === locationId
      );

      if (selectedData) {
        console.log("selectedData", selectedData);
        setResourceName(selectedData.doc_info);
      } else {
        setResourceName([]);
      }
    }
  };
  useEffect(() => {
    onClickLocation({ target: { value: userData?.location_id.toString() } });
  }, []);

  useEffect(() => {
    handleSearchSchedule(
      userData.user_role === "Admin" ? "" : userData.user_id
    );
  }, []);

  const onClickProvider = (event) => {
    setSelectedProvider(event.target.value);
    // handleSearchSchedule(event.target.value);
  };
  const serachFormElement = [
    {
      key: "location",
      label: "Select Location",
      placeholder: "Select Location",
      type: "select",
      options: locationResourceName,
      value: selectedLocation ? selectedLocation : userData?.location_id,
      onchange: onClickLocation,
    },
    {
      key: "provider",
      label: "Select Provider",
      placeholder: "Select Provider",
      type: "select",
      options: resourceName,
      value: selectedProvider,
      onchange: onClickProvider,
    },
  ];
  useEffect(() => {
    setSelectedLocation(
      locationResourceName.length > 0
        ? locationResourceName[0]?.location_id
        : ""
    );
    setSelectedProvider(resourceName.length > 0 ? resourceName[0]?.doc_id : "");
  }, []);

  useEffect(() => {
    setSelectedLocation(userData.location_id);
  }, [userData?.location_id]);

  useEffect(() => {
    if (resourceName.length > 0) {
      setSelectedProvider(resourceName[0]?.doc_id);
      // handleSearchSchedule(resourceName[0]?.doc_id);
    }
  }, [resourceName]);

  const getLocation = (data) => {
    console.log("data", data);
    const locationArray = [];
    data.forEach((element, index) => {
      locationArray.push(element.location_name);
    });
    setLocationName(locationArray);
  };
  useEffect(() => {
    console.log("selectedProvider", selectedProvider);
    if (
      checkIsCancelled ||
      checkIsRescheduled ||
      checkIsAutoRescheduled ||
      selectedProvider
    ) {
      console.log("checkIsCancelled", checkIsCancelled);
      console.log("selectedProvider", selectedProvider);

      handleSearchSchedule(selectedProvider);
    }
  }, [
    checkIsCancelled,
    checkIsRescheduled,
    checkIsAutoRescheduled,
    selectedProvider,
  ]);
  const handleSearchSchedule = (provider) => {
    getSchedulebylocations(
      `location_id=${
        selectedLocation === "" ? userData.location_id : selectedLocation
      }&resourceId=${provider ? provider : selectedProvider}&user_role=${
        userData.user_role
      }&opt_type=SELSCH`
    ).then((response) => {
      console.log("response", response);
      console.log(response?.status);
      if (response?.status?.status) {
        const data = response.data;
        const resource = [];
        const filteredDataForResource = data.filter(
          (value, index, self) =>
            self.findIndex((v) => v.resourceId === value.resourceId) === index
        );
        var filtereddata = data.filter(
          (value, index, self) =>
            value.is_scheduled === "Y" &&
            (checkIsCancelled === "Y"
              ? value.is_canceled === "Y" || value.is_canceled === "N"
              : value.is_canceled === "N") &&
            (checkIsRescheduled === "Y"
              ? value.is_rescheduled === "Y" || value.is_rescheduled === "N"
              : value.is_rescheduled === "N") &&
            (checkIsAutoRescheduled === "Y"
              ? value.is_autoschedule === "Y" || value.is_autoschedule === "N"
              : value.is_autoschedule === "N")
        );

        // value.is_scheduled==='Y' && (value.is_rescheduled ===checkIsRescheduled ||value.is_canceled === checkIsCancelled || value.is_autoschedule === checkIsAutoRescheduled));

        filtereddata.forEach((element, index) => {
          element.start = new Date(
            moment(element.start_time).utc().format("YYYY-MM-DDTHH:mm:ss")
          );
          element.end = new Date(
            moment(element.end_time).utc().format("YYYY-MM-DDTHH:mm:ss")
          );
          element.title = `${element.first_name} ${element?.middle_name} ${element.last_name}`;
        });

        filteredDataForResource.forEach((element) => {
          resource.push({
            id: element.resourceId,
            title: element.name,
          });
        });
        getStaffAvailability().then((responses) => {
          console.log("selectedStaffAvailability -responses", responses);
          const selectedStaffAvailability = responses?.data.filter(
            (response) =>
              response.location_id === selectedLocation &&
              response.staff_id === selectedProvider
          );
          console.log("selectedStaffAvailability", selectedStaffAvailability);
          setStaffDetails(selectedStaffAvailability);
          setResources(resource);
          setScheduleData(data);
          console.log(scheduleData,'scheduleddatatss');
        });
      }
    });
  };

  useEffect(() => {
    if (updateDataSchedule) {
      getLocationAndResource(
        `location_id=${userData.location_id}&&user_id=${userData.user_id}&user_role=${userData.user_role}&opt_type=SELSCH`
      ).then((response) => {
        console.log(response);
        setLocationResourceName(response.data);
        getLocation(response.data);
        setUpdateDataSchedule(false);
      });
      handleSearchSchedule(selectedProvider);
    }

    setUpdateDataSchedule(false);
  }, [updateDataSchedule]);
  useEffect(() => {
    console.log("staffDetails", staffDetails);
  }, [staffDetails]);
  function handleSubmit(event) {
    if (
      getWeekend(event.start, staffDetails) === false &&
      resources.length > 0
    ) {
      resources.forEach((element) => {
        if (event.resourceId === element.id) {
          event.doctorName = element.title;
        }
      });
      event.selectedPatientData = null;
      setSelectedEvent(event);
      setShow(true);
      setOpen(true);
      setIsReschedule(false);
    } else if (resources.length === 0) {
      toast.error("Please select a location and provider.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.error(
        "No appointment available on this date and time. Please check the date and time and try again.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  }
  useEffect(() => {
    if (scheduleData) {
      console.log("scheduleData", scheduleData);
    }
  }, [scheduleData]);
  const dayPropGetter = (date) => {
    const nonWorkingDay = getNonWorkingDay(
      staffDetails.length !== 0 ? staffDetails[0] : { data: "" }
    );
    const day = date.getDay();
    var now = new Date();

    if (nonWorkingDay.includes(day) || date < now) {
      return {
        className: "weekend",
      };
    }
  };

  function handleSchedule(event) {
    if (event.title === "Holiday") {
      toast.error("Requested doctor not available on selected date.", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      console.log("event", event);

      getPatientSchedule(
        `patientID=${event.patient_id}&scheduleId=${event.id}&location_id=${userData.location_id}&opt_type=${userData.user_role}`
      ).then((response) => {
        console.log(response);

        if (response?.status.description === "") {
          event.selectedPatientData = response.data[0];
          setSelectedEvent(event);
          setShow(true);
          setOpen(true);
          setIsReschedule(true);
        } else {
          toast.info(response?.status.description, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    }
  }
  const hideHandle = () => {
    setOpen(false);
    setUpdateDataSchedule(true);
  };
  //
  const [updateDragSchedule, setUpdateDragSchedule] = useState(false);
  // Define the drag and drop event handlers
  const onEventDrop = ({ event, start, end }) => {
    if (event.title === "Holiday") {
        toast.error("Requested doctor not available on selected date.", {
            position: toast.POSITION.TOP_CENTER,
        });
        return; // Stop further execution for holidays
    }

    const isValidEvent = moment(start).isSameOrAfter(moment().startOf("day"));
    if (!isValidEvent) {
        toast.error("Please check the date and time and try again.", {
            position: toast.POSITION.TOP_CENTER,
        });
        return; // Stop further execution if the event is not valid
    }

    fetchPatientSchedule(event, start, end);
};

const fetchPatientSchedule = async (event, start, end) => {
    try {
        const response = await getPatientSchedule(
            `patientID=${event.patient_id}&scheduleId=${event.id}&location_id=${userData.location_id}&opt_type=${userData.user_role}`
        );

        if (response?.status.description === "") {
            // Incorporate fetched data into the event data
            updateEvent({ ...event, ...response.data[0] }, start, end);
        } else {
            toast.error(response?.status.description, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    } catch (error) {
        console.error("Error fetching patient schedule:", error);
        toast.error("Failed to fetch patient schedule.", {
            position: toast.POSITION.TOP_CENTER,
        });
    }
};


const updateEvent = (event, start, end) => {
  const postData = {
      id: event.id,
      title: event.title,
      patient_id: event.patient_id,
      first_name: event.first_name,
      last_name: event.last_name,
      state: event.state,
      country: event.country,
      zip: event.zip,
      location_id: event.location_id,
      resource_id: event.resourceId,
      physician_id: event.physician_id,
      start_time: moment(start).toISOString(),
      end_time: moment(end).toISOString(),
      opt_type: "RESCH",
      additional_insurance: event.additional_insurance || 0,
      schedule_id: event.schedule_type_id || "0",
      schedule_notes: event.schedule_notes || "",
      is_rescheduled: event.is_rescheduled || "N",
      is_canceled: event.is_canceled || "N",
      is_waitlist: event.is_waitlist || "N",
      is_scheduled: event.is_scheduled || "Y",
      is_autoschedule: event.is_autoschedule || "N",
      access_location: "1",

      // Ensure that all necessary fields are added here
  };

  postScheduleData(postData).then(response => {
      if (response?.status?.status === true) {
          const updatedEvents = scheduleData.map(evt => evt.id === event.id ? { ...evt, start, end } : evt);
          setScheduleData(updatedEvents);
          toast.success("Event updated successfully.", { position: toast.POSITION.TOP_CENTER });
          setUpdateDataSchedule(true);
      } else {
          const errorMessage = response?.status?.description || "Failed to update the event.";
          toast.error(errorMessage, { position: toast.POSITION.TOP_CENTER });
      }
  }).catch(error => {
      console.error("Failed to update event:", error);
      toast.error("Network error occurred while updating event.", { position: toast.POSITION.TOP_CENTER });
  });
};

  

  useEffect(() => {
    if (selectedEvent) {
      console.log("selectedEvent -327", selectedEvent);
    }
  }, [selectedEvent]);

  const onEventResize = ({ event, start, end }) => {
    // Logic to handle event resizing
    const updatedEvents = scheduleData.map((evt) => {
      if (evt.id === event.id) {
        return { ...evt, start, end };
      }
      return evt;
    });
    setScheduleData(updatedEvents);
  };
  const parsedScheduleData =
    scheduleData &&
    scheduleData.map((event) => ({
      ...event,
      start: new Date(event.start),
      end: new Date(event.end),
    }));
  return (
    <div>
      <ManageSchedule
        open={open}
        onHide={hideHandle}
        title={
          isReschedule === false
            ? "Add New Schedule"
            : "Update Appointment or Cancel Appointment "
        }
        selectSchedule={selectedEvent}
        isReschedule={isReschedule}
        setModalShow={setModalShow}
        setUpdateDataSchedule={setUpdateDataSchedule}
        resources={resources}
        selectedLocation={selectedLocation}
        setUpdateDragSchedule={setUpdateDragSchedule}
        updateDragSchedule={updateDragSchedule}
      />
      <div
        className={classes.pageTitleContainer}
        style={{ marginBottom: "0px" }}
      >
        <Typography className={classes.typo} weight="medium">
          Schedule
        </Typography>
        <div className="ScheduleCheckBox">
          <FormGroup
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
            row
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkIsCancelled === "Y"}
                  onChange={(e) => {
                    const selectedValue = e.target.checked ? "Y" : "N";
                    setCheckIsCancelled(selectedValue);
                  }}
                />
              }
              label="Cancel"
              className="checkFilter"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkIsRescheduled === "Y"}
                  onChange={(e) => {
                    const selectedValue = e.target.checked ? "Y" : "N";
                    setCheckIsRescheduled(selectedValue);
                  }}
                />
              }
              label="Reschedule"
              className="checkFilter"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkIsAutoRescheduled === "Y"}
                  onChange={(e) => {
                    const selectedValue = e.target.checked ? "Y" : "N";
                    setCheckIsAutoRescheduled(selectedValue);
                  }}
                />
              }
              label="Auto-Schedule"
              className="checkFilter"
            />
          </FormGroup>
        </div>
        <div className={classes.groupBtn}>
          {serachFormElement.map((element, i) => {
            return (
              <div style={{ marginRight: "4px" }}>
                {element.type === "select" ? (
                  <>
                    <FormControl
                      fullWidth
                      className={classes.dropdownFormControl}
                    >
                      <Select
                        type={element.type}
                        placeholder={element.placeholder}
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          height: "36px",
                        }}
                        className={classes.selectDropdown}
                        onChange={element.onchange}
                        defaultValue={element.label}
                        value={
                          element.key === "location"
                            ? element.value
                              ? element.value
                              : userData?.location_id
                            : element.value
                        }
                      >
                        <MenuItem
                          selected
                          disabled
                          style={{ fontSize: "14px" }}
                        >
                          {element.label}
                        </MenuItem>
                        {element.key === "location" && element.options
                          ? element.options.map((item) => (
                              <MenuItem
                                value={item?.location_id}
                                style={{ fontSize: "14px" }}
                              >
                                {item?.location_name}
                              </MenuItem>
                            ))
                          : element.key === "provider" && element.options
                          ? element.options.map((item) => (
                              <MenuItem
                                value={item?.doc_id}
                                style={{ fontSize: "14px" }}
                              >
                                {item?.doc_name}
                              </MenuItem>
                            ))
                          : element.options.map((item) => (
                              <MenuItem
                                style={{ fontSize: "14px" }}
                                value={item}
                              >
                                {item}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                    {/* <span style={{ marginLeft: "4px" }}>{element.label}</span> */}
                  </>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
      <div>
        {/* Checkbox and filter options */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="checkBoxes"
        >
   
        </div>

        {/* calender */}
        {scheduleData.length ? (
          <>
            <DragAndDropCalendar
              className="bigCalendar"
              events={scheduleData}
              onEventDrop={onEventDrop}
              onEventResize={onEventResize}
              localizer={localizer}
              step={
                staffDetails.length !== 0
                  ? staffDetails[0]?.duration === null
                    ? 20
                    : staffDetails[0]?.duration / 2
                  : 20
              }
              min={
                new Date(
                  moment(
                    staffDetails.length !== 0
                      ? staffDetails[0].start_time
                      : "1900-01-01T05:30:00.000Z"
                  )
                    .utc()
                    .format("YYYY-MM-DDTHH:mm:ss")
                )
              }
              max={
                new Date(
                  moment(
                    staffDetails.length !== 0
                      ? staffDetails[0].end_time
                      : "1900-01-01T19:30:00.000Z"
                  )
                    .utc()
                    .format("YYYY-MM-DDTHH:mm:ss")
                )
              }
              selectable
              resources={resources}
              defaultView={"week"}
              showAllEvents={false}
              views={["day", "week", "month", "agenda"]}
              defaultDate={new Date()}
              popup={true}
              startAccessor={"start"}
              endAccessor={"end"}
              onSelectEvent={(event) => handleSchedule(event)}
              onSelectSlot={(event) => handleSubmit(event)}
              style={{
                height: 660,
                fontFamily: "sans-serif",
                fontSize: 20,
                marginBottom: "16px",
                overflow: "hidden", // Hide the scrollbar
              }}
              dayPropGetter={dayPropGetter}
              eventPropGetter={(event) => ({
                style: {
                  backgroundColor:"rgb(235 235 235) ", // Custom event background color
                  color: "black", // Custom event text color
                  height: "auto",
                  minHeight: "48px",
                  fontSize: "15px",
                  border: `1px solid black`,
                },
              })}
            />
          </>
        ) : (
          <center style={{ marginTop: "10px" }}>  
            <img src={image} alt="img" />
            <h4 style={{ color: "#4E97FD" }}>
              Please select a location and provider
              <br /> to continue scheduling.
            </h4>
          </center>
        )}
      </div>
    </div>
  );
};

export default ScheduleList;