import React from "react";
import { Grid } from "@material-ui/core";

import { dateFormatConversion } from "../../../../../hooks/helper";

import TableWidget from "../../../../Widget/TableWidget";

import TableDesign from "../../../../common/table/TableDesign";
import { TableRow, TableCell } from "@material-ui/core";
// styles
import useStyles from "../../styles";

export default function AttachmentList({ patientAttachmentData }) {
  const tableHeadings = [
    {
      label: "S. No.",
    },
    {
      label: "File Name",
      align: "left !important",
    },
    {
      label: "File Category",
      align: "left !important",
    },
    {
      label: "Description",
      align: "left !important",
    },
    {
      label: "Locked",
      align: "left !important",
    },
    {
      label: "Created Date",
      align: "left !important",
    },
    {
      label: "Updated By",
      align: "left !important",
    },
  ];
  console.log("patientAttachmentData", patientAttachmentData);

  var classes = useStyles();

  return (
    <div className="container">
      <p style={{ marginLeft: "6px", paddingLeft: "10px", fontWeight: "500" }}>
        Attachment List
      </p>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Attachment",
                tableHeadings,
              }}
            >
              {patientAttachmentData && patientAttachmentData?.length ? (
                patientAttachmentData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          <a
                            href={row.file_path}
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            {row.file_name}
                          </a>
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.file_category}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.file_description}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.is_locked}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {dateFormatConversion(row.created_dt)}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.updated_by}
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </div>
  );
}
