import React from "react";
import MenuList from "../../components/Settings/MenuAccess/MenuList";

export default function Client() {
  return (
    <div>
      <MenuList />
    </div>
  );
}
