import React from "react";
import {
  ImageListItem,
  ImageListItemBar,
  Grid,
  Typography,
} from "@material-ui/core";
import { autoPlay } from "react-swipeable-views-utils";
import SwipeableViews from "react-swipeable-views";
import PageTitle from "../../../components/PageTitle";
import { makeStyles } from "@material-ui/core/styles";
import glassImg from "../../../assets/glasses.jpg";
import hydrotec from "../../../assets/hydrotec_lens.jpg";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  carousel: {
    position: "relative",
    // height: "200px", // Adjust the height as needed
    overflow: "hidden",
  },
  slide: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  slideContent: {
    textAlign: "center",
  },
  imageSize: {
    width: "100%",
    height: "360px",
  },
}));
export default function ProductImage() {
  const classes = useStyles();
  const itemData = [
    {
      img: hydrotec,
      title: "Breakfast",
      author: "@bkristastucchio",
    },
  ];
  return (
    <>
      <div style={{ marginTop: "10px" }}>
        {/* <PageTitle title="BURBERRY Eyeglasses BE 2205 3002 Dark Havana" /> */}
        {/* <AutoPlaySwipeableViews>
          <div className={classes.slide}>
            <div className={classes.slideContent}>
              <Grid container spacing={1}>
                {itemData.map((item) => (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <ImageListItem key={item.img} style={{ listStyle: "none" }}>
                      <img
                        src={`${item.img}?w=248&fit=crop&auto=format`}
                        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.title}
                        loading="lazy"
                      />
                      <div style={{ backgroundColor: "#666", color: "#fff" }}>
                        <Typography>{item.title}</Typography>
                        <Typography>{item.title}</Typography>
                      </div>
                    </ImageListItem>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </AutoPlaySwipeableViews> */}
        <img className={classes.imageSize} src={hydrotec} />
      </div>
      <div style={{ backgroundColor: "#4E97FD", color: "#fff", padding:'4px' }}>
        <Typography>SKU: FR01234</Typography>
        <Typography>UPC: 12bc2</Typography>
      </div>
    </>
  );
}
