import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Button,
  Checkbox,
} from "@material-ui/core";

import moment from "moment";
import useStyles from "./styles";
import {
  formTertiaryElements,
  formSubscribeTertiaryElements,
} from "./Patient_Constant";
import { postFlagBooleanVariable } from '../../../../../hooks/helper'
import { Box } from "@mui/material";
export default function Tertiary({
  patientInsuranceData,
  setPatientInsuranceData,
  additionalInsuranceValues,
  genderValues
}) {
  const [checked, setChecked] = useState(true);
  const [selectclient, setselectclient] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = useState(null);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const onchangeFamily = () => {
    alert("Family");
  };
  const addFileLink = () => {
    setOpen(true);
    setTitle("Add Family Subscribed");
  };

  const selectedPatient = (data) => {
    console.log("Selected Patinet Data " + data);
    setPatientData(data);
    setOpen(false);
  };
  const [radioValue, setRadioValue] = useState("New");
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  const [patientData, setPatientData] = useState(null);

  var classes = useStyles();

  return (
    <>
      <Grid container spacing={2} className={classes.formBox} >
        {
          formTertiaryElements.map((element, i) => {
            return (
              <>
                {patientInsuranceData ? (
                  <Grid
                    item
                    xs={12}
                    md={2}
                    lg={2}
                    sm={12}
                    className={classes.textGroup}
                  >
                    <Typography className={classes.formHeading}>
                      {element.label}{" "}
                      {element.required === "true" ? (
                        <span className={classes.required}>*</span>
                      ) : null}
                    </Typography>

                    {element.type === "select" ? (
                      <FormControl
                        fullWidth
                        // sx={{
                        //   '&.MuiFormControl-fullWidth': {
                        //     width: 'auto',
                        //   }
                        // }}
                        className={classes.dropdownFormControl}
                      >
                        <Select
                          name={element.key}
                          className={classes.selectDropdown}
                          placeholder={element.placeHolder}
                          value={
                            patientInsuranceData[element.key] === null
                              ? ""
                              : patientInsuranceData[element.key]
                          }
                          onChange={async (e) => {
                            const updatedData = patientInsuranceData.map(
                              (data) => {
                                if (data.id === patientInsuranceData.id) {
                                  return {
                                    ...data,
                                    [element.key]: e.target.value,
                                  };
                                }
                                return data;
                              }
                            );
                            setPatientInsuranceData(updatedData);
                          }}
                        >
                          <MenuItem value="">Select</MenuItem>
                          {element.key == "adi_insurance_provider" &&
                            additionalInsuranceValues
                            ? additionalInsuranceValues.map((item) => (
                              <MenuItem
                                key={item.attribute_id}
                                value={item.attribute_id}
                              >
                                {item.attribute_val}
                              </MenuItem>
                            ))
                            : element.key == "adi_subscriber_gender" &&
                              genderValues
                              ? genderValues.map((item) => (
                                <MenuItem
                                  key={item.attribute_id}
                                  value={item.attribute_id}
                                >
                                  {item.attribute_val}
                                </MenuItem>
                              ))
                              : element.options.map((item) => (
                                <MenuItem value={item}>{item}</MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    ) : element.type === "checkbox" ? (
                      <Checkbox
                        value={element.key}
                        disabled
                        checked={
                          patientInsuranceData[element.key] !== "N"
                            ? patientInsuranceData[element.key]
                            : false
                        }
                        onChange={(e) => {
                          const value = postFlagBooleanVariable(e.target.checked);
                          setPatientInsuranceData({
                            ...patientInsuranceData,
                            [element.key]: value,
                          });
                        }}
                      />
                    ) : element.type === "date" ||
                      element.type === "datetime-local" ? (
                      <TextField
                        disabled
                        id={element.key}
                        name={element.key}
                        placeholder={element.placeHolder}
                        type={element.type}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          min: `${currentDateTime}`,
                          max: `${currentDate}`,
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={
                          patientInsuranceData && patientInsuranceData[element.key] === null
                            ? ""
                            : moment(
                              patientInsuranceData && patientInsuranceData[element.key]
                            ).format("YYYY-MM-DD")
                        }

                        onChange={async (e) => {
                          const updatedData = patientInsuranceData.map((data) => {
                            if (data.id === patientInsuranceData.id) {
                              return {
                                ...data,
                                [element.key]: e.target.value,
                              };
                            }
                            return data;
                          });
                          setPatientInsuranceData(updatedData);
                        }}
                      />
                    ) : element.type === "textarea" ? (
                      <TextField
                        disabled
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        placeholder="Type here..."
                        variant="outlined"
                        multiline
                        rows={3}
                        inputProps={{
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        value={
                          patientInsuranceData[element.key] === null
                            ? ""
                            : patientInsuranceData[element.key]
                        }
                        onChange={async (e) => {
                          const updatedData = patientInsuranceData.map((data) => {
                            if (data.id === patientInsuranceData.id) {
                              return {
                                ...data,
                                [element.key]: e.target.value,
                              };
                            }
                            return data;
                          });
                          setPatientInsuranceData(updatedData);
                        }}
                      />
                    ) : element.type === "button" ? (
                      <Button
                        classes={{ root: classes.button }}
                        variant="contained"
                        color="secondary"
                        style={{
                          width: "100%",
                          fontSize: "10px",
                          marginTop: "26px",
                        }}
                      >
                        {element.btnName}
                      </Button>
                    ) : (
                      <>
                        <TextField
                          disabled
                          required
                          name={element.key}
                          placeholder={element.placeHolder}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                            maxLength: `${element.maxLength}`,
                          }}
                          value={
                            patientInsuranceData[element.key] === null
                              ? ""
                              : patientInsuranceData[element.key]
                          }
                          onChange={async (e) => {
                            const updatedData = patientInsuranceData.map((data) => {
                              if (data.id === patientInsuranceData.id) {
                                return {
                                  ...data,
                                  [element.key]: e.target.value,
                                };
                              }
                              return data;
                            });
                            setPatientInsuranceData(updatedData);
                          }}
                        />
                      </>
                    )}
                  </Grid>
                ) : (
                  <Typography v>No insurance data available.</Typography>
                )}
              </>
            );
          })
        }
      </Grid>
    </>
  );
}
