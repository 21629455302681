import React from 'react'

import InvtOrderList from '../../components/Inventory/Order/InvtOrderList'

export default function Order() {
  return (
    <>
    <InvtOrderList/>
    </>
  )
}
