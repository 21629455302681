import moment from "moment";
import CryptoJS from "crypto-js";
// import { currentUser, loadState } from "/lo";
const secretPass =
  "B&E)H@McQfTjWnZr4u7x!A%D*F-JaNdRgUkXp2s5v8y/B?E(H+KbPeShVmYq3t6w";

export const encryptData = (text) => {
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(text),
    secretPass
  ).toString();

  return data;
};

// age calculation
export const calculateAgeFromDate = (date) => {
  if (!date) {
    return { years: 0, months: 0 }; // Return default age if date is undefined
  }
  const birthDate = new Date(date);
  const currentDate = new Date();

  const yearDiff = currentDate.getFullYear() - birthDate.getFullYear();
  const monthDiff = currentDate.getMonth() - birthDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())
  ) {
    return { years: yearDiff - 1, months: 12 + monthDiff };
  } else {
    return { years: yearDiff, months: monthDiff };
  }
};
// ssn format
export const ssnFormat = (value) => {
  // Check if the value is not defined or null
  if (!value) return value;

  // Convert the value to a string
  const ssn = String(value);

  // Clean the input for any non-digit values.
  const cleanedSSN = ssn.replace(/[^\d]/g, "");

  // ssnLength is used to know when to apply our formatting for the ssn
  const ssnLength = cleanedSSN.length;

  // We need to return the value with no formatting if it's less than four digits
  if (ssnLength < 4) return cleanedSSN;

  // If ssnLength is greater than 4 and less than 6, we start to return
  // the formatted number
  if (ssnLength < 6) {
    return `${cleanedSSN.slice(0, 3)}-${cleanedSSN.slice(3)}`;
  }

  // Finally, if the ssnLength is greater than 6, we add the last
  // bit of formatting and return it.
  return `${cleanedSSN.slice(0, 3)}-${cleanedSSN.slice(
    3,
    5
  )}-${cleanedSSN.slice(5, 9)}`;
};
// validation for 3 digit amount
export const validateLargeAmount = (event) => {
  const input = event.target.value;
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

  // Allow backspace, delete, and navigation keys
  if (
    (event.key >= "0" && event.key <= "9") ||
    event.key === "Backspace" ||
    event.key === "Delete" ||
    event.key === "ArrowLeft" ||
    event.key === "ArrowRight" ||
    event.key === "Tab"
  ) {
    if (input === "NA") {
      // If the default value is 'NA', clear the text field
      event.target.value = "";
    }
    return;
  } else if (
    (event.key === "." || event.key === ",") &&
    input.indexOf(".") === -1
  ) {
    // Allow only one decimal point
    return;
  } else if ((event.key === "+" || event.key === "-") && input === "") {
    // Allow + and - only at the starting position
    return;
  } else if ((event.key === "+" || event.key === "-") && input.length > 0) {
    // Allow + and - only after decimal
    const decimalIndex = input.indexOf(".");
    if (decimalIndex !== -1 && (event.key === "+" || event.key === "-")) {
      const decimalPart = input.substring(decimalIndex + 1);
      if (decimalPart.length <= 2) {
        return;
      }
    }
  } else {
    // Clear the input if a non-numeric value is entered
    event.preventDefault();
    if (input === "NA") {
      // If the default value is 'NA', clear the text field
      event.target.value = "";
    }
  }

  // Check if the input only contains digits and adheres to the format rules
  const isValidFormat = /^\d{0,3}(\.\d{0,2})?$/.test(input + keyValue);
  // Check if the total length is less than or equal to 5
  if ((input + keyValue).length > 5) {
    event.preventDefault();
  }
  if (!isValidFormat) {
    event.preventDefault();
  }
};
// validation for 3 digit amount
export const validateFourDigitWithoutDecimalLargeAmount = (event) => {
  const input = event.target.value;
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

  // Allow backspace, delete, and navigation keys
  if (
    event.key === "Backspace" ||
    event.key === "Delete" ||
    event.key === "ArrowLeft" ||
    event.key === "ArrowRight" ||
    event.key === "Tab"
  ) {
    return;
  }

  // Check if the input matches the desired format (xx.yy)
  const isValidFormat = /^\d{0,4}?$/.test(input + keyValue);

  // Prevent adding dot as the first character
  const isDotAtFirstPosition = input === "" && keyValue === ".";

  if (!isValidFormat || isDotAtFirstPosition) {
    event.preventDefault();
  }
};
// validation for amount
export const validateInputAmount = (event) => {
  const input = event.target.value;
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

  // Allow backspace, delete, and navigation keys
  if (
    event.key === "Backspace" ||
    event.key === "Delete" ||
    event.key === "ArrowLeft" ||
    event.key === "ArrowRight" ||
    event.key === "Tab"
  ) {
    return;
  }

  // Check if the input matches the desired format (xx.yy)
  const isValidFormat = /^\d{0,2}(\.\d{0,2})?$/.test(input + keyValue);

  // Prevent adding dot as the first character
  const isDotAtFirstPosition = input === "" && keyValue === ".";

  if (!isValidFormat || isDotAtFirstPosition) {
    event.preventDefault();
  }
};

// validation for tax amount
export const validateTaxInputAmount = (event) => {
  const input = event.target.value;
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

  // Allow backspace, delete, and navigation keys
  if (
    event.key === "Backspace" ||
    event.key === "Delete" ||
    event.key === "ArrowLeft" ||
    event.key === "ArrowRight" ||
    event.key === "Tab"
  ) {
    return;
  }

  // Check if the input matches the desired format (xx.yy)
  const isValidFormat = /^\d{0,2}(\.\d{0,2})?$/.test(input + keyValue);

  // Prevent adding dot as the first character
  const isDotAtFirstPosition = input === "" && keyValue === ".";

  if (!isValidFormat || isDotAtFirstPosition) {
    event.preventDefault();
  }
};
export const validateTaxInputAmountPaste = (event) => {
  const clipboardData = event.clipboardData || window.clipboardData;
  const pastedText = clipboardData.getData("text");

  if (!/^\d{0,2}(\.\d{0,2})?$/.test(pastedText)) {
    event.preventDefault();
  }
};
//  only number input function

export const handleKeyPress = (event) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

  // Allow only numeric values and prevent non-numeric characters
  const isNumeric = /^\d+$/.test(keyValue);
  if (!isNumeric) {
    event.preventDefault();
    return; // Stop further execution if the key is not a digit
  }

  const input = event.target;
  const currentValue = input.value;
  const selectionStart = input.selectionStart;

  // Calculate the potential next value of the input field
  const nextValue = currentValue.slice(0, selectionStart) + keyValue + currentValue.slice(input.selectionEnd);

  // Regular expression to check if the input follows the mobile number format
  // Allows numbers with a single dash after the first 5 digits
  const formatRegex = /^\d{0,5}-?\d{0,5}$/;

  // If the next value exceeds the format regex, prevent the input
  if (!formatRegex.test(nextValue) || nextValue.replace(/-/g, '').length > 10) {
    event.preventDefault();
    return; // Stop further execution if the resulting format would be invalid
  }

  // Calculate if a dash should be added automatically after the fifth digit
  if (currentValue.length === 5 && selectionStart === 5 && keyValue !== '-' && !currentValue.includes('-')) {
    event.preventDefault(); // Prevent the default input to manage it manually

    // Manually set the value to include the digit and a dash if the next character position is the dash position
    input.value = currentValue + '-' + keyValue;
    // Adjust cursor position right after the newly added dash
    setTimeout(() => {
      input.setSelectionRange(selectionStart + 2, selectionStart + 2);
    }, 0);
  }
};



export const handleKeyPressPaste = (event) => {
  const clipboardData = event.clipboardData || window.clipboardData;
  const pastedText = clipboardData.getData("text");

  // Allow only numeric values and a single dash after the first 5 digits
  const formatRegex = /^\d{0,5}-?\d{0,5}$/;

  // Calculate the potential next value of the input field
  const input = event.target;
  const currentValue = input.value;
  const selectionStart = input.selectionStart;
  const selectionEnd = input.selectionEnd;
  const nextValue = currentValue.slice(0, selectionStart) + pastedText + currentValue.slice(selectionEnd);

  // Prevent default paste behavior if the pasted text exceeds the format regex
  if (!formatRegex.test(nextValue) || nextValue.replace(/-/g, '').length > 10) {
    event.preventDefault();
  }
};

export const handleHeightKeyPress = (event) => {
  // Regular expression for decimal validation
  const decimalRegex = /^[0-9](\.\d{0,2})?$/;

  // Check if the input matches the pattern
  if (!decimalRegex.test(event.target.value)) {
    event.preventDefault();
  }
};
export const handleAlphNumKeyPress = (e) => {
  // Allow alphanumeric characters and the Backspace/Delete keys
  // const isValidChar = /^[a-zA-Z -]*$/.test(e.key);
  const isValidChar = /^[a-zA-Z-]*$/.test(e.key);
  if (!isValidChar) {
    e.preventDefault();
  }
};

export const handleAlphNumKeyPressPaste = (event) => {
  const clipboardData = event.clipboardData || window.clipboardData;
  const pastedText = clipboardData.getData("text");

  if (!/^[a-zA-Z]*$/.test(pastedText)) {
    event.preventDefault();
  }
};

export const handleAlphNumSpaceKeyPress = (e) => {
  // Allow alphanumeric characters, space, and the Backspace/Delete keys
  const isValidChar = /^[a-zA-Z0-9 -]*$/.test(e.key);
  if (!isValidChar) {
    e.preventDefault();
  }
};

export const handleAlphNumSpaceKeyPressPaste = (event) => {
  const clipboardData = event.clipboardData || window.clipboardData;
  const pastedText = clipboardData.getData("text");

  if (!/^[a-zA-Z0-9 -]*$/.test(pastedText)) {
    event.preventDefault();
  }
};

export const handleAddressKeyPress = (e) => {
  // Allow alphanumeric characters, spaces, and other special characters except ` ' "
  const isValidChar = /^[a-zA-Z0-9 !@#$%^&*()\-=_+[\]{}|\\;:,.<>?/]*$/.test(
    e.key
  );
  if (!isValidChar) {
    e.preventDefault();
  }
};

export const handleAddressKeyPressPaste = (event) => {
  const clipboardData = event.clipboardData || window.clipboardData;
  const pastedText = clipboardData.getData("text");

  if (!/^[a-zA-Z0-9 !@#$%^&*()\-=_+[\]{}|\\;:,.<>?/]*$/.test(pastedText)) {
    event.preventDefault();
  }
};

export const handleAlphNumberKeyPress = (e) => {
  // Allow alphanumeric characters and the Backspace/Delete keys
  const isValidChar = /^[a-zA-Z0-9-]*$/.test(e.key);
  if (!isValidChar) {
    e.preventDefault();
  }
};

export const handleAlphNumberKeyPressPaste = (event) => {
  const clipboardData = event.clipboardData || window.clipboardData;
  const pastedText = clipboardData.getData("text");
  if (!/^[a-zA-Z0-9 -]*$/.test(pastedText)) {
    event.preventDefault();
  }
};

export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem("AuthData");
    if (serializedState === null) {
      return [];
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return [];
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("AuthData", serializedState);
  } catch (err) {
    return undefined;
  }
};

export const password_validate = (password) => {
  //for password
  const uppercaseRegExp = /(?=.*?[A-Z])/;
  const lowercaseRegExp = /(?=.*?[a-z])/;
  const digitsRegExp = /(?=.*?[0-9])/;
  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
  const minLengthRegExp = /.{8,}/;
  const passwordLength = password.length;
  const uppercasePassword = uppercaseRegExp.test(password);
  const lowercasePassword = lowercaseRegExp.test(password);
  const digitsPassword = digitsRegExp.test(password);
  const specialCharPassword = specialCharRegExp.test(password);
  const minLengthPassword = minLengthRegExp.test(password);
  let errMsg = "";
  if (passwordLength === 0) {
    errMsg = "Password is empty";
  } else if (!uppercasePassword) {
    errMsg = "At least one Uppercase";
  } else if (!lowercasePassword) {
    errMsg = "At least one Lowercase";
  } else if (!digitsPassword) {
    errMsg = "At least one digit";
  } else if (!specialCharPassword) {
    errMsg = "At least one Special Characters";
  } else if (!minLengthPassword) {
    errMsg = "At least minimum 8 characters";
  } else {
    errMsg = "";
  }
  return errMsg;
};

export const dateFormatConversion = (date) => {
  return moment(date).utc().format("MM/DD/YYYY");
};

export const dateTimeFormatConversion = (date) => {
  if (date === null || date === "") {
    return "N/A";
  }
  return moment(date).utc().format("YYYY-MM-DD hh:mm A");
};

export const scheduleFieldValidation = (scheduleDetailsData) => {
  // console.log("scheduleDetailsData",scheduleDetailsData);
  let errMsg = "";
  if (scheduleDetailsData.first_name === undefined) {
    errMsg = "First Name can not be blank";
  } else if (scheduleDetailsData.last_name === undefined) {
    errMsg = "Last Name can not be blank";
  } else if (scheduleDetailsData.reasonOfVisit === undefined) {
    errMsg = "Please select reason for visit";
    
  } else if (scheduleDetailsData.contact_cell === undefined) {
    errMsg = "Contact number can not be blank";
  } else if (scheduleDetailsData.contact_cell.length > 10) {
    errMsg = "Contact number should not more than 10 digit";
  } else if (scheduleDetailsData.contact_cell.length < 10) {
    errMsg = "Contact number should not less than 10 digit";
  } else if (isEmail(scheduleDetailsData.email) === false) {
    errMsg = "Please enter valid email address";
  } else if (scheduleDetailsData.insurance_id === undefined) {
    errMsg = "Please select insurance";
  } else if (scheduleDetailsData.insurance_id === undefined) {
    errMsg = "Please select insurance";
  } else if (scheduleDetailsData.contact_home === undefined) {
    errMsg = "Please select insurance";
  }else if (
    validTime(scheduleDetailsData.start, scheduleDetailsData.end) === false
  ) {
    errMsg = "Please check start and end time";
  }
  // else if (scheduleDetailsData.notes === undefined) {
  //   errMsg = "Notes can not be blank";
  // }
  // else if (isNumber(scheduleDetailsData.billing_zip) === false) {
  //   errMsg = "Zip code could be numeric only";
  // }
  else {
    errMsg = "";
  }
  return errMsg;
};

export const validateChangePassportScreen = (data) => {
  let errMsg = "";
  if (data.new_Password === undefined || data.new_Password === "") {
    errMsg = "New password can not be blank";
  } else if (
    data.confirmPassword === undefined ||
    data.confirmPassword === ""
  ) {
    errMsg = "Confirm password can not be blank";
  } else if (data.new_Password !== data.confirmPassword) {
    errMsg = " New password and Confirm password not matched";
  } else if (
    (password_validate(data.new_Password) ||
      password_validate(data.confirmPassword)) !== ""
  ) {
    errMsg = password_validate(data.new_Password);
  }

  return errMsg;
};

function isEmail(val) {
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regEmail.test(val)) {
    return false;
  }
  return true;
}
export function isNumber(value) {
  return !isNaN(Number(value)); // true
}

function validTime(start, end) {
  var diff = moment.duration(moment(end).diff(moment(start)));
  console.log("diff====>" + diff);
  return diff > 0;
}

export const getWeekend = (date, data) => {
  const day = date.getDay();
  const nonWorkingDay = getNonWorkingDay(
    data.length !== 0 ? data[0] : { data: "" }
  );
  var now = new Date();
  if (nonWorkingDay.includes(day) || date < now) {
    return true;
  } else {
    return false;
  }
};

export const constant = {
  //  path: "http://localhost:8080"
  
  
   path: "https://docmedapi.azurewebsites.net"
};

export function paramForAddPatientProfile(values) {
  const patientData = values[0];
  const userData = loadState().user;
  const patientId = values[2] === "" ? 0 : values[2];
  return {
    patient_id: patientId,
    location_id: userData?.location_id,
    title: patientData.title,
    first_name: patientData.first_name,
    middle_name: patientData.middle_name,
    last_name: patientData.last_name,
    suffix: patientData.suffix,
    nick_name: patientData.nick_name,
    gender: patientData.gender,
    marital: patientData.marital,
    applicable_role_id:
      patientData.applicable_role_id === undefined ||
      patientData.applicable_role_id === ""
        ? 0
        : patientData.applicable_role_id,
    gender_identity: patientData.gender_identity,
    allow_health_info: patientData?.allow_health_info
      ? patientData?.allow_health_info
      : "",
   
      dateofbirth:
      patientData.dateofbirth === "" ? "01-03-1870" : patientData.dateofbirth,
    ssn: patientData.ssn,
    orientation: patientData.orientation !== "" ? patientData.orientation : 0,
    contact_cell:
      patientData.contact_cell !== "" ? patientData.contact_cell : 0,
   
      contact_home:
      patientData.contact_home !== "" ? patientData.contact_home : 0,
  
      contact_office:
      patientData.contact_office !== "" ? patientData.contact_office : 0,
 
      email: patientData.email,
  
      fax: 0,
    pref_contact: patientData.pref_contact,
    is_opt_email: patientData.is_opt_email,
    is_opt_sms: patientData.is_opt_sms,
    is_opt_call: patientData.is_opt_call,
    allergies: patientData.allergies,
    pref_call_hours: patientData.pref_call_hours,
    is_opt_marketing: patientData.is_opt_marketing,
    is_hipaa_consent: patientData.is_hipaa_consent,
    is_patient_portal_access: patientData.is_patient_portal_access,
    preffered_language: patientData.preffered_language,
    is_smoking_status: "Y",
    is_drinking_status: "Y",
    billing_address_line1: patientData.billing_address_line1,
    billing_address_line2: patientData.billing_address_line2,
    billing_city: patientData.billing_city,
    billing_state: patientData.billing_state,
    billing_country: "US",
    billing_zip: patientData.billing_zip !== "" ? patientData.billing_zip : 0,
    billing_address_verified: patientData.billing_address_verified,
    shipping_address_line1: patientData.shipping_address_line1,
    shipping_address_line2: patientData.shipping_address_line2,
    shipping_city: patientData.shipping_city,
    shipping_state: patientData.shipping_state,
    shipping_country: "US",
    shipping_zip:
      patientData.shipping_zip !== "" ? patientData.shipping_zip : 0,
      shipping_address_verified: patientData.shipping_address_verified,
    mailing_address_line1: patientData.mailing_address_line1,
    mailing_address_line2: patientData.mailing_address_line2,
    mailing_city: patientData.mailing_city,
    mailing_state: patientData.mailing_state,
    mailing_country: "US",
    mailing_zip:
      patientData.mailing_zip !== "" ? patientData.mailing_zip : 0,
    mailing_address_verified: patientData.mailing_address_verified,
    ethnicity: patientData.ethnicity,
    race: patientData.race,
    hobbies: patientData.hobbies,
    primary_physician: patientData.primary_physician,
    physician_address: patientData.physician_address,
    physician_city: patientData.physician_city,
    physician_state: patientData.physician_state,
    physician_zip:
      patientData.physician_zip !== "" ? patientData.physician_zip : 0,
    physician_country: "US",
    notes: patientData.notes,
    imp_shrt_notes: patientData.imp_shrt_notes,
    is_disability: patientData.is_disability,
    is_veteran_status: patientData.is_veteran_status,
    first_emergency_contact_name:
      patientData.first_emergency_contact_name === undefined
        ? ""
        : patientData.first_emergency_contact_name,
    first_emergency_contact_con:
      patientData.first_emergency_contact_con !== ""
        ? patientData.first_emergency_contact_con
        : 0,
    sec_emergency_contact_name:
      patientData.sec_emergency_contact_name === ""
        ? ""
        : patientData.sec_emergency_contact_name,
    sec_emergency_contact_con:
      patientData.sec_emergency_contact_con !== ""
        ? patientData.sec_emergency_contact_con
        : 0,
    patient_status:
      patientData.patient_status === undefined
        ? ""
        : patientData.patient_status,
    patient_inactive_reason:
      patientData.patient_inactive_reason === undefined
        ? ""
        : patientData.patient_inactive_reason,
    inactive_date:
      patientData.inactive_date === "" ||
      patientData.inactive_date === undefined
        ? "01-03-1870"
        : patientData.inactive_date,
    deceased_reason:
      patientData.deceased_reason === undefined
        ? ""
        : patientData.deceased_reason,
    deceased_date:
      patientData.deceased_date === "" ||
      patientData.deceased_date === undefined
        ? "01-03-1870"
        : patientData.deceased_date,
    deceased_date_received:
      patientData.deceased_date_received === "" ||
      patientData.deceased_date_received === undefined
        ? "01-03-1870"
        : patientData.deceased_date_received,
    is_active: "Y",
    updated_by: currentUser(),
    access_location: "",
    opt_type: values[1],
  };
}

export function paramForAddPatientAdditionalDetails(values) {
  const patientAdditionalData = values[0];
  const patientId = values[2] === "" ? 0 : values[2];
  return {
    patient_id: patientId,
    occupation:
      patientAdditionalData.occupation !== undefined
        ? patientAdditionalData.occupation
        : "",
    employer:
      patientAdditionalData.employer !== undefined
        ? patientAdditionalData.employer
        : "",
    employer_address_line1:
      patientAdditionalData.employer_address_line1 !== undefined
        ? patientAdditionalData.employer_address_line1
        : "",
    employer_address_line2:
      patientAdditionalData.employer_address_line2 !== undefined
        ? patientAdditionalData.employer_address_line2
        : "",
    employer_city:
      patientAdditionalData.employer_city !== undefined
        ? patientAdditionalData.employer_city
        : "",
    employer_state:
      patientAdditionalData.employer_state !== undefined
        ? patientAdditionalData.employer_state
        : "",
    employer_country: "USA",
    employer_zip:
      patientAdditionalData.employer_zip !== "" ||
      patientAdditionalData.employer_zip !== undefined
        ? patientAdditionalData.employer_zip
        : 0,
    employer_con:
      patientAdditionalData.employer_con !== undefined ||
      patientAdditionalData.employer_con !== ""
        ? patientAdditionalData.employer_con
        : 0,
    employer_email:
      patientAdditionalData.employer_email !== undefined ||
      patientAdditionalData.employer_email !== ""
        ? patientAdditionalData.employer_email
        : "",
    //
    guarantor_type:
      patientAdditionalData.guarantor_type !== undefined ||
      patientAdditionalData.guarantor_type !== ""
        ? patientAdditionalData.guarantor_type
        : "",
    guarantor_relationship:
      patientAdditionalData.guarantor_relationship !== undefined
        ? patientAdditionalData.guarantor_relationship
        : "",
    guarantor_first_name:
      patientAdditionalData.guarantor_first_name !== undefined
        ? patientAdditionalData.guarantor_first_name
        : "",
    guarantor_middle_name:
      patientAdditionalData.guarantor_middle_name !== undefined
        ? patientAdditionalData.guarantor_middle_name
        : "",
    guarantor_last_name:
      patientAdditionalData.guarantor_last_name !== undefined
        ? patientAdditionalData.guarantor_last_name
        : "",
    guarantor_suffix:
      patientAdditionalData.guarantor_suffix !== undefined
        ? patientAdditionalData.guarantor_suffix
        : "",
    guarantor_dob:
      patientAdditionalData.guarantor_dob !== undefined
        ? patientAdditionalData.guarantor_dob
        : "1988-01-01",
    guarantor_gender:
      patientAdditionalData.guarantor_gender !== undefined
        ? patientAdditionalData.guarantor_gender
        : "",
    guarantor_ssn:
      patientAdditionalData.guarantor_ssn !== undefined
        ? patientAdditionalData.guarantor_ssn
        : "",
    guarantor_employer_email:
      patientAdditionalData.guarantor_employer_email !== undefined
        ? patientAdditionalData.guarantor_employer_email
        : "",
    guarantor_address_line1:
      patientAdditionalData.guarantor_address_line1 !== undefined
        ? patientAdditionalData.guarantor_address_line1
        : "",
    guarantor_address_line2:
      patientAdditionalData.guarantor_address_line2 !== undefined
        ? patientAdditionalData.guarantor_address_line2
        : "",
    guarantor_city:
      patientAdditionalData.guarantor_city !== undefined
        ? patientAdditionalData.guarantor_city
        : "",
    guarantor_state:
      patientAdditionalData.guarantor_state !== undefined
        ? patientAdditionalData.guarantor_state
        : "",
    guarantor_country: "USA",
    guarantor_zip:
      patientAdditionalData.guarantor_zip !== "" ||
      patientAdditionalData.guarantor_zip !== undefined
        ? patientAdditionalData.guarantor_zip
        : 0,
    guarantor_con:
      patientAdditionalData.guarantor_con !== "" ||
      patientAdditionalData.guarantor_con !== undefined
        ? patientAdditionalData.guarantor_con
        : 0,
    guarantor_home_con:
      patientAdditionalData.guarantor_home_con !== "" ||
      patientAdditionalData.guarantor_home_con !== undefined
        ? patientAdditionalData.guarantor_home_con
        : 0,
    guarantor_office_con:
      patientAdditionalData.guarantor_office_con !== "" ||
      patientAdditionalData.guarantor_office_con !== undefined
        ? patientAdditionalData.guarantor_office_con
        : 0,
    guarantor_email:
      patientAdditionalData.guarantor_email !== undefined
        ? patientAdditionalData.guarantor_email
        : "",
    //
    referral_source:
      patientAdditionalData.referral_source !== undefined
        ? patientAdditionalData.referral_source
        : "",
    referral_first_name:
      patientAdditionalData.referral_first_name !== undefined
        ? patientAdditionalData.referral_first_name
        : "",
    referral_middle_name:
      patientAdditionalData.referral_middle_name !== undefined
        ? patientAdditionalData.referral_middle_name
        : "",
    referral_last_name:
      patientAdditionalData.referral_last_name !== undefined
        ? patientAdditionalData.referral_last_name
        : "",
    referral_suffix:
      patientAdditionalData.referral_suffix !== undefined
        ? patientAdditionalData.referral_suffix
        : "",
    referral_dob:
      patientAdditionalData.referral_dob !== undefined
        ? patientAdditionalData.referral_dob
        : "1988-01-01",
    referral_gender:
      patientAdditionalData.referral_gender !== undefined
        ? patientAdditionalData.referral_gender
        : "",
    referral_con:
      patientAdditionalData.referral_con !== undefined
        ? patientAdditionalData.referral_con
        : 0,
    referral_con_type:
      patientAdditionalData.referral_con_type !== undefined
        ? patientAdditionalData.referral_con_type
        : "",
    referral_email:
      patientAdditionalData.referral_email !== undefined
        ? patientAdditionalData.referral_email
        : "",
    referral_ssn:
      patientAdditionalData.referral_ssn !== ""
        ? patientAdditionalData.referral_ssn
        : 0,
    referral_thanks_note:
      patientAdditionalData.referral_thanks_note !== ""
        ? patientAdditionalData.referral_thanks_note
        : "",
    referral_address_line1:
      patientAdditionalData.referral_address_line1 !== ""
        ? patientAdditionalData.referral_address_line1
        : "",
    referral_address_line2:
      patientAdditionalData.referral_address_line2 !== ""
        ? patientAdditionalData.referral_address_line2
        : "",
    referral_city:
      patientAdditionalData.referral_city !== ""
        ? patientAdditionalData.referral_city
        : "",
    referral_state:
      patientAdditionalData.referral_state !== ""
        ? patientAdditionalData.referral_state
        : "",
    referral_country: "CTRY",
    referral_zip:
      patientAdditionalData.referral_zip !== ""
        ? patientAdditionalData.referral_zip
        : 0,
    //
    guardian_relationship:
      patientAdditionalData.guardian_relationship !== undefined
        ? patientAdditionalData.guardian_relationship
        : "",
    guardian_first_name:
      patientAdditionalData.guardian_first_name !== undefined
        ? patientAdditionalData.guardian_first_name
        : "",
    guardian_middle_name:
      patientAdditionalData.guardian_middle_name !== undefined
        ? patientAdditionalData.guardian_middle_name
        : "",
    guardian_last_name:
      patientAdditionalData.guardian_last_name !== undefined
        ? patientAdditionalData.guardian_last_name
        : "",
    guardian_suffix:
      patientAdditionalData.guardian_suffix !== undefined
        ? patientAdditionalData.guardian_suffix
        : "",
    guardian_dob:
      patientAdditionalData.guardian_dob !== undefined
        ? patientAdditionalData.guardian_dob
        : "1988-01-01",
    guardian_gender:
      patientAdditionalData.guardian_gender !== undefined
        ? patientAdditionalData.guardian_gender
        : "",
    guardian_con_type:
      patientAdditionalData.guardian_con_type !== undefined
        ? patientAdditionalData.guardian_con_type
        : "",
    guardian_email:
      patientAdditionalData.guardian_email !== undefined
        ? patientAdditionalData.guardian_email
        : "",
    guardian_thanks_note:
      patientAdditionalData.guardian_thanks_note !== undefined
        ? patientAdditionalData.guardian_thanks_note
        : "",
    guardian_address_line1:
      patientAdditionalData.guardian_address_line1 !== undefined
        ? patientAdditionalData.guardian_address_line1
        : "",
    guardian_address_line2:
      patientAdditionalData.guardian_address_line2 !== undefined
        ? patientAdditionalData.guardian_address_line2
        : "",
    guardian_city:
      patientAdditionalData.guardian_city !== undefined
        ? patientAdditionalData.guardian_city
        : "",
    guardian_state:
      patientAdditionalData.guardian_state !== undefined
        ? patientAdditionalData.guardian_state
        : "",
    guardian_country: "USA",
    guardian_zip:
      patientAdditionalData.guardian_zip !== ""
        ? patientAdditionalData.guardian_zip
        : 0,
    guardian_con:
      patientAdditionalData.guardian_con !== ""
        ? patientAdditionalData.guardian_con
        : 0,
    guardian_home_con:
      patientAdditionalData.guardian_home_con !== ""
        ? patientAdditionalData.guardian_home_con
        : 0,
    guardian_office_con:
      patientAdditionalData.guardian_office_con !== ""
        ? patientAdditionalData.guardian_office_con
        : 0,
    is_guardian_same_address: "Y",
    guardian1_relationship:
      patientAdditionalData.guardian1_relationship !== undefined
        ? patientAdditionalData.guardian1_relationship
        : "",
    guardian1_first_name:
      patientAdditionalData.guardian1_first_name !== undefined
        ? patientAdditionalData.guardian1_first_name
        : "",
    guardian1_middle_name:
      patientAdditionalData.guardian1_middle_name !== undefined
        ? patientAdditionalData.guardian1_middle_name
        : "",
    guardian1_last_name:
      patientAdditionalData.guardian1_last_name !== undefined
        ? patientAdditionalData.guardian1_last_name
        : "",
    guardian1_suffix:
      patientAdditionalData.guardian1_suffix !== undefined
        ? patientAdditionalData.guardian1_suffix
        : "",
    guardian1_dob:
      patientAdditionalData.guardian1_dob !== undefined
        ? patientAdditionalData.guardian1_dob
        : "1988-01-01",
    guardian1_gender:
      patientAdditionalData.guardian1_gender !== undefined
        ? patientAdditionalData.guardian1_gender
        : "",
    guardian1_con_type:
      patientAdditionalData.guardian1_con_type !== undefined
        ? patientAdditionalData.guardian1_con_type
        : "",
    guardian1_email:
      patientAdditionalData.guardian1_email !== undefined
        ? patientAdditionalData.guardian1_email
        : "",
    guardian1_thanks_note:
      patientAdditionalData.guardian1_thanks_note !== undefined
        ? patientAdditionalData.guardian1_thanks_note
        : "",
    guardian1_address_line1:
      patientAdditionalData.guardian1_address_line1 !== undefined
        ? patientAdditionalData.guardian1_address_line1
        : "",
    guardian1_address_line2:
      patientAdditionalData.guardian1_address_line2 !== undefined
        ? patientAdditionalData.guardian1_address_line2
        : "",
    guardian1_city:
      patientAdditionalData.guardian1_city !== undefined
        ? patientAdditionalData.guardian1_city
        : "",
    guardian1_state:
      patientAdditionalData.guardian1_state !== undefined
        ? patientAdditionalData.guardian1_state
        : "",
    guardian1_country: "USA",
    guardian1_zip:
      patientAdditionalData.guardian1_zip !== ""
        ? patientAdditionalData.guardian1_zip
        : 0,
    guardian1_con:
      patientAdditionalData.guardian1_con !== ""
        ? patientAdditionalData.guardian1_con
        : 0,
    guardian1_home_con:
      patientAdditionalData.guardian1_home_con !== ""
        ? patientAdditionalData.guardian1_home_con
        : 0,
    guardian1_office_con:
      patientAdditionalData.guardian1_office_con !== ""
        ? patientAdditionalData.guardian1_office_con
        : 0,
    is_guardian1_same_address: "Y",
    //
    is_guardian1_same_address: "Y",
    first_emergency_contact_name: "Ammi",
    first_emergency_contact_con: 23456,
    sec_emergency_contact_name: "akm",
    sec_emergency_contact_con: 45666,
    //
    is_active: "Y",
    created_dt: new Date(),
    updated_by: currentUser(),
    updated_dt: new Date(),
    access_location: "ak-1.1",
    opt_type: values[1],
  };
}

export function paramForAddPatientAdditionalDetailsEdit(values) {
  const patientAdditionalData = values[0];
  const patientId = values[2] === "" ? 0 : values[2];
  return {
    patient_id: patientId,
    occupation:
      patientAdditionalData.occupation != undefined
        ? patientAdditionalData.occupation
        : "",
    employer:
      patientAdditionalData.employer != undefined
        ? patientAdditionalData.employer
        : "",
    employer_address_line1:
      patientAdditionalData.employer_address_line1 != undefined
        ? patientAdditionalData.employer_address_line1
        : "",
    employer_address_line2:
      patientAdditionalData.employer_address_line2 != undefined
        ? patientAdditionalData.employer_address_line2
        : "",
    employer_city:
      patientAdditionalData.employer_city != undefined
        ? patientAdditionalData.employer_city
        : "",
    employer_state:
      patientAdditionalData.employer_state != undefined
        ? patientAdditionalData.employer_state
        : "",
    employer_country: "USA",
    employer_zip:
      patientAdditionalData.employer_zip != "" ||
      patientAdditionalData.employer_zip != undefined
        ? patientAdditionalData.employer_zip
        : 0,
    employer_con:
      patientAdditionalData.employer_con != "" ||
      patientAdditionalData.employer_con != undefined
        ? patientAdditionalData.employer_con
        : 0,
    employer_email:
      patientAdditionalData.employer_email != undefined ||
      patientAdditionalData.employer_email != ""
        ? patientAdditionalData.employer_email
        : "",
    //
    guarantor_type:
      patientAdditionalData.guarantor_type != undefined ||
      patientAdditionalData.guarantor_zip != ""
        ? patientAdditionalData.guarantor_type
        : "",
    guarantor_relationship:
      patientAdditionalData.guarantor_relationship != undefined
        ? patientAdditionalData.guarantor_relationship
        : "",
    guarantor_first_name:
      patientAdditionalData.guarantor_first_name != undefined
        ? patientAdditionalData.guarantor_first_name
        : "",
    guarantor_middle_name:
      patientAdditionalData.guarantor_middle_name != undefined
        ? patientAdditionalData.guarantor_middle_name
        : "",
    guarantor_last_name:
      patientAdditionalData.guarantor_last_name != undefined
        ? patientAdditionalData.guarantor_last_name
        : "",
    guarantor_suffix:
      patientAdditionalData.guarantor_suffix != undefined
        ? patientAdditionalData.guarantor_suffix
        : "",
    guarantor_dob:
      patientAdditionalData.guarantor_dob != undefined
        ? patientAdditionalData.guarantor_dob
        : "2001-01-01",
    guarantor_gender:
      patientAdditionalData.guarantor_gender != undefined
        ? patientAdditionalData.guarantor_gender
        : "",
    guarantor_ssn:
      patientAdditionalData.guarantor_ssn != undefined
        ? patientAdditionalData.guarantor_ssn
        : "",
    guarantor_employer_email:
      patientAdditionalData.guarantor_employer_email != undefined
        ? patientAdditionalData.guarantor_employer_email
        : "",
    guarantor_address_line1:
      patientAdditionalData.guarantor_address_line1 != undefined
        ? patientAdditionalData.guarantor_address_line1
        : "",
    guarantor_address_line2:
      patientAdditionalData.guarantor_address_line2 != undefined
        ? patientAdditionalData.guarantor_address_line2
        : "",
    guarantor_city:
      patientAdditionalData.guarantor_city != undefined
        ? patientAdditionalData.guarantor_city
        : "",
    guarantor_state:
      patientAdditionalData.guarantor_state != undefined
        ? patientAdditionalData.guarantor_state
        : "",
    guarantor_country: "USA",
    guarantor_zip:
      patientAdditionalData.guarantor_zip != "" ||
      patientAdditionalData.guarantor_zip != undefined
        ? patientAdditionalData.guarantor_zip
        : 0,
    guarantor_con:
      patientAdditionalData.guarantor_con != "" ||
      patientAdditionalData.guarantor_con != undefined
        ? patientAdditionalData.guarantor_con
        : 0,
    guarantor_home_con:
      patientAdditionalData.guarantor_home_con != "" ||
      patientAdditionalData.guarantor_home_con != undefined
        ? patientAdditionalData.guarantor_home_con
        : 0,
    guarantor_office_con:
      patientAdditionalData.guarantor_office_con != "" ||
      patientAdditionalData.guarantor_office_con != undefined
        ? patientAdditionalData.guarantor_office_con
        : 0,
    guarantor_email:
      patientAdditionalData.guarantor_email !== undefined
        ? patientAdditionalData.guarantor_email
        : "",
    //
    referral_source:
      patientAdditionalData.referral_source !== undefined
        ? patientAdditionalData.referral_source
        : "",
    referral_first_name:
      patientAdditionalData.referral_first_name !== undefined
        ? patientAdditionalData.referral_first_name
        : "",
    referral_middle_name:
      patientAdditionalData.referral_middle_name !== undefined
        ? patientAdditionalData.referral_middle_name
        : "",
    referral_last_name:
      patientAdditionalData.referral_last_name !== undefined
        ? patientAdditionalData.referral_last_name
        : "",
    referral_suffix:
      patientAdditionalData.referral_suffix !== undefined
        ? patientAdditionalData.referral_suffix
        : "",
    referral_dob:
      patientAdditionalData.referral_dob !== undefined
        ? patientAdditionalData.referral_dob
        : "1988-01-01",
    referral_gender:
      patientAdditionalData.referral_gender !== undefined
        ? patientAdditionalData.referral_gender
        : "",
    referral_con:
      patientAdditionalData.referral_con !== ""
        ? patientAdditionalData.referral_con
        : 0,
    referral_con_type:
      patientAdditionalData.referral_con_type !== undefined
        ? patientAdditionalData.referral_con_type
        : "",
    referral_email:
      patientAdditionalData.referral_email !== undefined
        ? patientAdditionalData.referral_email
        : "",
    referral_ssn:
      patientAdditionalData.referral_ssn !== ""
        ? patientAdditionalData.referral_ssn
        : 0,
    referral_thanks_note:
      patientAdditionalData.referral_thanks_note !== ""
        ? patientAdditionalData.referral_thanks_note
        : "",
    referral_address_line1:
      patientAdditionalData.referral_address_line1 !== undefined
        ? patientAdditionalData.referral_address_line1
        : "",
    referral_address_line2:
      patientAdditionalData.referral_address_line2 !== undefined
        ? patientAdditionalData.referral_address_line2
        : "",
    referral_city:
      patientAdditionalData.referral_city !== undefined
        ? patientAdditionalData.referral_city
        : "",
    referral_state:
      patientAdditionalData.referral_state !== undefined
        ? patientAdditionalData.referral_state
        : "",
    referral_country: "CTRY",
    referral_zip:
      patientAdditionalData.referral_zip !== undefined
        ? patientAdditionalData.referral_zip
        : 0,
    //
    guardian_relationship:
      patientAdditionalData.guardian_relationship !== undefined
        ? patientAdditionalData.guardian_relationship
        : "",
    guardian_first_name:
      patientAdditionalData.guardian_first_name !== undefined
        ? patientAdditionalData.guardian_first_name
        : "",
    guardian_middle_name:
      patientAdditionalData.guardian_middle_name !== undefined
        ? patientAdditionalData.guardian_middle_name
        : "",
    guardian_last_name:
      patientAdditionalData.guardian_last_name !== undefined
        ? patientAdditionalData.guardian_last_name
        : "",
    guardian_suffix:
      patientAdditionalData.guardian_suffix !== undefined
        ? patientAdditionalData.guardian_suffix
        : "",
    guardian_dob:
      patientAdditionalData.guardian_dob !== undefined
        ? patientAdditionalData.guardian_dob
        : "1988-01-01",
    guardian_gender:
      patientAdditionalData.guardian_gender !== undefined
        ? patientAdditionalData.guardian_gender
        : "",
    guardian_con_type:
      patientAdditionalData.guardian_con_type !== undefined
        ? patientAdditionalData.guardian_con_type
        : "",
    guardian_email:
      patientAdditionalData.guardian_email !== undefined
        ? patientAdditionalData.guardian_email
        : "",
    guardian_thanks_note:
      patientAdditionalData.guardian_thanks_note !== undefined
        ? patientAdditionalData.guardian_thanks_note
        : "",
    guardian_address_line1:
      patientAdditionalData.guardian_address_line1 !== undefined
        ? patientAdditionalData.guardian_address_line1
        : "",
    guardian_address_line2:
      patientAdditionalData.guardian_address_line2 !== undefined
        ? patientAdditionalData.guardian_address_line2
        : "",
    guardian_city:
      patientAdditionalData.guardian_city !== undefined
        ? patientAdditionalData.guardian_city
        : "",
    guardian_state:
      patientAdditionalData.guardian_state !== undefined
        ? patientAdditionalData.guardian_state
        : "",
    guardian_country: "USA",
    guardian_zip:
      patientAdditionalData.guardian_zip !== undefined
        ? patientAdditionalData.guardian_zip
        : 0,
    guardian_con:
      patientAdditionalData.guardian_con !== undefined
        ? patientAdditionalData.guardian_con
        : 0,
    guardian_home_con:
      patientAdditionalData.guardian_home_con !== undefined
        ? patientAdditionalData.guardian_home_con
        : 0,
    guardian_office_con:
      patientAdditionalData.guardian_office_con !== undefined
        ? patientAdditionalData.guardian_office_con
        : 0,
    is_guardian_same_address: "Y",
    guardian1_relationship:
      patientAdditionalData.guardian1_relationship !== undefined
        ? patientAdditionalData.guardian1_relationship
        : "",
    guardian1_first_name:
      patientAdditionalData.guardian1_first_name !== undefined
        ? patientAdditionalData.guardian1_first_name
        : "",
    guardian1_middle_name:
      patientAdditionalData.guardian1_middle_name !== undefined
        ? patientAdditionalData.guardian1_middle_name
        : "",
    guardian1_last_name:
      patientAdditionalData.guardian1_last_name !== undefined
        ? patientAdditionalData.guardian1_last_name
        : "",
    guardian1_suffix:
      patientAdditionalData.guardian1_suffix !== undefined
        ? patientAdditionalData.guardian1_suffix
        : 0,
    guardian1_dob:
      patientAdditionalData.guardian1_dob !== undefined
        ? patientAdditionalData.guardian1_dob
        : "1988-01-01",
    guardian1_gender:
      patientAdditionalData.guardian1_gender !== undefined
        ? patientAdditionalData.guardian1_gender
        : "",
    guardian1_con_type:
      patientAdditionalData.guardian1_con_type !== undefined
        ? patientAdditionalData.guardian1_con_type
        : "",
    guardian1_email:
      patientAdditionalData.guardian1_email !== undefined
        ? patientAdditionalData.guardian1_email
        : "",
    guardian1_thanks_note:
      patientAdditionalData.guardian1_thanks_note !== undefined
        ? patientAdditionalData.guardian1_thanks_note
        : "",
    guardian1_address_line1:
      patientAdditionalData.guardian1_address_line1 !== undefined
        ? patientAdditionalData.guardian1_address_line1
        : "",
    guardian1_address_line2:
      patientAdditionalData.guardian1_address_line2 !== undefined
        ? patientAdditionalData.guardian1_address_line2
        : "",
    guardian1_city:
      patientAdditionalData.guardian1_city !== undefined
        ? patientAdditionalData.guardian1_city
        : "",
    guardian1_state:
      patientAdditionalData.guardian1_state !== undefined
        ? patientAdditionalData.guardian1_state
        : "",
    guardian1_country: "USA",
    guardian1_zip:
      patientAdditionalData.guardian1_zip !== undefined
        ? patientAdditionalData.guardian1_zip
        : 0,
    guardian1_con:
      patientAdditionalData.guardian1_con !== undefined
        ? patientAdditionalData.guardian1_con
        : 0,
    guardian1_home_con:
      patientAdditionalData.guardian1_home_con !== undefined
        ? patientAdditionalData.guardian1_home_con
        : 0,
    guardian1_office_con:
      patientAdditionalData.guardian1_office_con !== undefined
        ? patientAdditionalData.guardian1_office_con
        : 0,
    is_guardian1_same_address: "Y",
    //
    is_guardian1_same_address: "Y",
    first_emergency_contact_name: "Ammi",
    first_emergency_contact_con: 23456,
    sec_emergency_contact_name: "akm",
    sec_emergency_contact_con: 45666,
    //
    is_active: "Y",
    created_dt: new Date(),
    updated_by: currentUser(),
    updated_dt: new Date(),
    access_location: "ak-1.1",
    opt_type: values[1],
  };
}
// insurance
export function paramForAddPatientInsuranceDetails(insuranceData) {
  console.log("insuranceData 1303", insuranceData);
  const patientInsuranceData = insuranceData[0];
  return {
    patient_id: insuranceData[2],
    mi_insurance_provider:
      patientInsuranceData.mi_insurance_provider === null ||
      patientInsuranceData.mi_insurance_provider === undefined
        ? 0
        : patientInsuranceData.mi_insurance_provider,
    mi_insurance_plan:
      patientInsuranceData.mi_insurance_plan === null ||
      patientInsuranceData.mi_insurance_plan === undefined
        ? ""
        : patientInsuranceData.mi_insurance_plan,
    mi_insurance_policynumber:
      patientInsuranceData.mi_insurance_policynumber === null ||
      patientInsuranceData.mi_insurance_policynumber === undefined
        ? ""
        : patientInsuranceData.mi_insurance_policynumber,
    mi_insurance_groupnumber:
      patientInsuranceData.mi_insurance_groupnumber === null ||
      patientInsuranceData.mi_insurance_groupnumber === undefined
        ? ""
        : patientInsuranceData.mi_insurance_groupnumber,
    mi_effective_date:
      patientInsuranceData.mi_effective_date === null ||
      patientInsuranceData.mi_effective_date === "" ||
      patientInsuranceData.mi_effective_date === undefined
        ? "1870-06-08"
        : patientInsuranceData.mi_effective_date,
    mi_is_active:
      patientInsuranceData.mi_is_active === null ||
      patientInsuranceData.mi_is_active === undefined
        ? "Y"
        : patientInsuranceData.mi_is_active,
    mi_co_pay:
      patientInsuranceData.mi_co_pay === null ||
      patientInsuranceData.mi_co_pay === undefined
        ? ""
        : patientInsuranceData.mi_co_pay,
    mi_accept_assignment:
      patientInsuranceData.mi_accept_assignment === null ||
      patientInsuranceData.mi_accept_assignment === undefined
        ? ""
        : patientInsuranceData.mi_accept_assignment,
    mi_self_subscribed:
      patientInsuranceData.mi_self_subscribed === null ||
      patientInsuranceData.mi_self_subscribed === undefined
        ? ""
        : patientInsuranceData.mi_self_subscribed,
    mi_family_subscribed:
      patientInsuranceData.mi_family_subscribed === null ||
      patientInsuranceData.mi_family_subscribed === undefined
        ? ""
        : patientInsuranceData.mi_family_subscribed,
    mi_patient_parent_id:
      patientInsuranceData.mi_patient_parent_id === null ||
      patientInsuranceData.mi_patient_parent_id === undefined
        ? 0
        : patientInsuranceData.mi_patient_parent_id,
    vi_insurance_provider:
      patientInsuranceData.vi_insurance_provider === null ||
      patientInsuranceData.vi_insurance_provider === undefined
        ? 0
        : patientInsuranceData.vi_insurance_provider,
    vi_insurance_plan:
      patientInsuranceData.vi_insurance_plan === null ||
      patientInsuranceData.vi_insurance_plan === undefined
        ? ""
        : patientInsuranceData.vi_insurance_plan,
    vi_insurance_policynumber:
      patientInsuranceData.vi_insurance_policynumber === null ||
      patientInsuranceData.vi_insurance_policynumber === undefined
        ? ""
        : patientInsuranceData.vi_insurance_policynumber,
    vi_insurance_groupnumber:
      patientInsuranceData.vi_insurance_groupnumber === null ||
      patientInsuranceData.vi_insurance_groupnumber === undefined
        ? ""
        : patientInsuranceData.vi_insurance_groupnumber,
    vi_effective_date:
      patientInsuranceData.vi_effective_date === null ||
      patientInsuranceData.vi_effective_date === "" ||
      patientInsuranceData.vi_effective_date === undefined
        ? "1870-06-08"
        : patientInsuranceData.vi_effective_date,
    vi_is_active:
      patientInsuranceData.vi_is_active === null ||
      patientInsuranceData.vi_is_active === undefined
        ? "Y"
        : patientInsuranceData.vi_is_active,
    vi_co_pay:
      patientInsuranceData.vi_co_pay === null ||
      patientInsuranceData.vi_co_pay === undefined
        ? ""
        : patientInsuranceData.vi_co_pay,
    vi_accept_assignment:
      patientInsuranceData.vi_accept_assignment === null ||
      patientInsuranceData.vi_accept_assignment === undefined
        ? ""
        : patientInsuranceData.vi_accept_assignment,
    vi_self_subscribed:
      patientInsuranceData.vi_self_subscribed === null ||
      patientInsuranceData.vi_self_subscribed === undefined
        ? ""
        : patientInsuranceData.vi_self_subscribed,
    vi_family_subscribed:
      patientInsuranceData.vi_family_subscribed === null ||
      patientInsuranceData.vi_family_subscribed === undefined
        ? ""
        : patientInsuranceData.vi_family_subscribed,
    vi_patient_parent_id:
      patientInsuranceData.vi_patient_parent_id === null ||
      patientInsuranceData.vi_patient_parent_id === undefined
        ? 0
        : patientInsuranceData.vi_patient_parent_id,
    adi_insurance_provider:
      patientInsuranceData.adi_insurance_provider === null ||
      patientInsuranceData.adi_insurance_provider === undefined
        ? 0
        : patientInsuranceData.adi_insurance_provider,
    adi_insurance_plan:
      patientInsuranceData.adi_insurance_plan === null ||
      patientInsuranceData.adi_insurance_plan === undefined
        ? ""
        : patientInsuranceData.adi_insurance_plan,
    adi_insurance_policynumber:
      patientInsuranceData.adi_insurance_policynumber === null ||
      patientInsuranceData.adi_insurance_policynumber === undefined
        ? ""
        : patientInsuranceData.adi_insurance_policynumber,
    adi_insurance_groupnumber:
      patientInsuranceData.adi_insurance_groupnumber === null ||
      patientInsuranceData.adi_insurance_groupnumber === undefined
        ? ""
        : patientInsuranceData.adi_insurance_groupnumber,
    adi_effective_date:
      patientInsuranceData.adi_effective_date === null ||
      patientInsuranceData.adi_effective_date === "" ||
      patientInsuranceData.adi_effective_date === undefined
        ? "1870-06-08"
        : patientInsuranceData.adi_effective_date,
    adi_is_active:
      patientInsuranceData.adi_is_active === null ||
      patientInsuranceData.adi_is_active === undefined
        ? "Y"
        : patientInsuranceData.adi_is_active,
    adi_co_pay:
      patientInsuranceData.adi_co_pay === null ||
      patientInsuranceData.adi_co_pay === undefined
        ? ""
        : patientInsuranceData.adi_co_pay,
    adi_accept_assignment:
      patientInsuranceData.adi_accept_assignment === null ||
      patientInsuranceData.adi_accept_assignment === undefined
        ? ""
        : patientInsuranceData.adi_accept_assignment,
    adi_self_subscribed:
      patientInsuranceData.adi_self_subscribed === null ||
      patientInsuranceData.adi_self_subscribed === undefined
        ? ""
        : patientInsuranceData.adi_self_subscribed,
    adi_family_subscribed:
      patientInsuranceData.adi_family_subscribed === null ||
      patientInsuranceData.adi_family_subscribed === undefined
        ? ""
        : patientInsuranceData.adi_family_subscribed,
    adi_patient_parent_id:
      patientInsuranceData.adi_patient_parent_id === null ||
      patientInsuranceData.adi_patient_parent_id === undefined
        ? 0
        : patientInsuranceData.adi_patient_parent_id,
    is_active: "Y",
    created_dt: new Date(),
    updated_by: currentUser(),
    updated_dt: new Date(),
    access_location: "1.0.10",
    opt_type: "ADDINS",
  };
}

export function paramForScheduleMetaData() {
  return {
    comp_id: "",
    store_id: "",
    duration: "",
    sunday_start_time: "",
    sunday_end_time: "",
    is_sunday_working: "",
    monday_start_time: "",
    monday_end_time: "",
    is_monday_working: "",
    tuesday_start_time: "",
    tuesday_end_time: "",
    is_tuesday_working: "",
    wedday_start_time: "",
    wedday_end_time: "",
    is_wedday_working: "",
    thrusday_start_time: "",
    thrusday_end_time: "",
    is_thrusday_working: "",
    friday_start_time: "",
    friday_end_time: "",
    is_friday_working: "",
    satday_start_time: "",
    satday_end_time: "",
    is_satday_working: "",
    is_active: "",
    updated_by: currentUser(),
    access_location: "",
    opt_type: "",
  };
}

export function paramForAddFamilyProfile(familyData) {
  return {
    patient_id: familyData.patient_id,
    patient_parent_id: familyData.patient_parent_id,
    patient_rel_id: familyData.patient_rel_id,
    is_active: "Y",
    updated_by: currentUser(),
    access_location: "1.0.0.0",
    opt_type: "ADDREL",
  };
}

export function getNonWorkingDay(data) {
  let nonWorkingDay = [0];
  if (data.work_monday === "N") {
    nonWorkingDay.push(1);
  }
  if (data.work_tuesday === "N") {
    nonWorkingDay.push(2);
  }
  if (data.work_wednesday === "N") {
    nonWorkingDay.push(3);
  }
  if (data.work_thursday === "N") {
    nonWorkingDay.push(4);
  }
  if (data.work_friday === "N") {
    nonWorkingDay.push(5);
  }
  if (data.work_saturday === "N") {
    nonWorkingDay.push(6);
  }
  if (data.work_sunday === "N") {
    nonWorkingDay.push(7);
  }
  return nonWorkingDay;
}

export function currentUser() {
  const profileData = loadState().user;
  return profileData !== undefined ? profileData.user_name : "Shashank";
}

export function getFlagVariable(key) {
  if (key === "" || key === "Yes") {
    return "Y";
  } else {
    return "N";
  }
}
export function getFlagVariableString(key) {
  if (key === "" || key === "Y") {
    return "Yes";
  } else {
    return "No";
  }
}
export function postFlagBooleanVariable(key) {
  if (key === "" || key === false) {
    return "N";
  } else {
    return "Y";
  }
}
export function getFlagBooleanVariable(key) {
  if (key === "" || key === "N") {
    return false;
  } else {
    return true;
  }
}
