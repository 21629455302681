import React from 'react'
import AddServices from '../../components/Inventory/Services/AddServices'
import AddOthers from '../../components/Inventory/Services/AddOthers'

export default function newOthers() {
  return (
    <>
    <AddOthers/>
    </>
  )
}
