import React from "react";
import CompanyList from "../../components/Settings/Company/CompanyList";

export default function Client() {
  return (
    <div>
      <CompanyList />
    </div>
  );
}
