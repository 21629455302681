import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Button,
} from "@material-ui/core";
import { useParams,useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Widget from "../../../components/Widget/Widget";
import useStyles from "./styles";
import {
  getCompanyTax,
  getCompanyStoreData,
  getMetaData,
  postCompanyTax,
} from "../../../services/ServiceLayer";
import { handleKeyPress, currentUser,loadState, validateTaxInputAmount, validateTaxInputAmountPaste } from "../../../hooks/helper";
import moment from "moment";

export default function TaxEditForm(props) {

  const userData = loadState().user;
  const userRole =loadState().user.user_role;

  const { comp_id } = useParams();
  const { store_id } = useParams();
  toast.configure();
  const history = useHistory();
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");

  const [storeList, setStoreList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [taxFormValues, setTaxFormValues] = useState([]);

  const classes = useStyles();

  const hideHandler = () => {
    props.onHide();
  };

  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedStore, setSelectedStore] = useState("");
  const metaData = loadState().metaData;
  useEffect(() => {
    console.log("loadState", metaData);
    setTaxFormValues(metaData[0].companyTax);
  }, []);


  useEffect(() => {
    getCompanyStoreData(`location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELLOC`).then((companyResponse) => {
      console.log(companyResponse);
      setCompanyList(companyResponse.data);
      getCompanyTax(`location_id=${store_id}&user_role=${userRole}&opt_type=SELTAX`).then((response) => {
        console.log(response);
        setTaxFormValues(response?.data);
        const selectedCompanyData = companyResponse.data.find(
          (company) => company.comp_id === response.data[0]?.comp_id
        );
        
        console.log('companyList====>>>' + companyResponse.data)
        console.log('response====>>>' + response.data)
  
        console.log('selectedCompanyData====>>>' + selectedCompanyData)
        if (selectedCompanyData) {
          setSelectedCompany(selectedCompanyData.comp_id);
          setStoreList(selectedCompanyData.store_info);
        }
  
        setSelectedStore(response.data[0]?.store_id);
      });
    });
  }, []);

  // useEffect(() => {
  
  // }, [comp_id, store_id]);

  const handleCompanySelect = (event) => {
    const selectedCompanyName = event.target.value;
    setSelectedCompany( );
    const selectedCompanyData = companyList.find(
      (company) => company.comp_id === selectedCompanyName
    );
    if (selectedCompanyData) {
      console.log("selectedCompanyData", selectedCompanyData);
      console.log("selectedCompanyData", selectedCompanyData?.store_info);
      setStoreList(selectedCompanyData?.store_info);
    } else {
      setStoreList([]);
    }
  };

  const submitFormData = () => {
    const companyTaxParams = {
      comp_id: selectedCompany,
      store_id: selectedStore,
      tax_type_id: taxFormValues.map((item) => item.tax_type_id).join(","),
      tax_type: taxFormValues.map((item) => item.tax_type).join(","),
      tax_1: taxFormValues.map((item) => item.tax_1).join(","),
      tax_2: taxFormValues.map((item) => item.tax_2).join(","),
      is_active: "Y",
      updated_by: currentUser(),
      access_location: "PB",
      opt_type: "MODTAX",
    };

    postCompanyTax(companyTaxParams).then((response) => {
      console.log(response);
      if (response?.status?.status) {
        toast.success("Company tax updated successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        history.push(`/app/tax-rate/`);
      } else {
        console.log("api failed");
        toast.error("Something went wrong. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  const deleteFormData = () => {
    const companyTaxParams = {
      comp_id: selectedCompany,
      store_id: selectedStore,
      tax_type_id: taxFormValues.map((item) => item.tax_type_id).join(","),
      tax_type: taxFormValues.map((item) => item.tax_type).join(","),
      tax_1: taxFormValues.map((item) => item.tax_1).join(","),
      tax_2: taxFormValues.map((item) => item.tax_2).join(","),
      is_active: "Y",
      updated_by: currentUser(),
      access_location: "PB",
      opt_type: "DELTAX",
    };

    postCompanyTax(companyTaxParams).then((response) => {
      console.log(response);
      if (response?.status?.status) {
        toast.success("Company tax deleted successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        history.push(`/app/tax-rate/`);
      } else {
        console.log("api failed");
        toast.error("Something went wrong. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  return (
    <>
      <PageTitle subtitle="Tax >" title="Tax Update" />
      <Widget>
        <Grid
          container
          spacing={1}
          className={classes.formBox}
          style={{ marginBottom: "4px" }}
        >
     
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Tax Types</Typography>
          </Grid>
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Tax1</Typography>
          </Grid>
          <Grid item xs={12} md={4} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Tax2</Typography>
          </Grid>
        </Grid>
        {taxFormValues.map((item) => (
          <Grid container spacing={1} className={classes.formBox}>
            <Grid item xs={12} md={4} className={classes.textGroup}>
              <Typography className={classes.formHeading}>
                {item.tax_type}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} className={classes.textGroup}>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder=""
                variant="outlined"
                inputProps={{
                  style: {
                    height: "12px",
                    fontSize: "12px",
                  },
                  maxLength: 5,

                      onKeyPress: validateTaxInputAmount,
                      onPaste:validateTaxInputAmountPaste,
                }}
                name={item.tax_type_id + "_tax_1"}
                value={item.tax_1 || ""}
                onChange={(e) => {
                  const updatedTaxFormValues = taxFormValues.map((formItem) => {
                    if (formItem.tax_type_id === item.tax_type_id) {
                      return {
                        ...formItem,
                        tax_1: e.target.value,
                      };
                    }
                    return formItem;
                  });
                  setTaxFormValues(updatedTaxFormValues);
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} className={classes.textGroup}>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder=""
                variant="outlined"
                inputProps={{
                  style: {
                    height: "12px",
                    fontSize: "12px",
                  },
                  maxLength: 5,

                      onKeyPress: validateTaxInputAmount,
                      onPaste:validateTaxInputAmountPaste,
                }}
                name={item.tax_type_id + "_tax_2"}
                value={item.tax_2 || ""}
                onChange={(e) => {
                  const updatedTaxFormValues = taxFormValues.map((formItem) => {
                    if (formItem.tax_type_id === item.tax_type_id) {
                      return {
                        ...formItem,
                        tax_2: e.target.value,
                      };
                    }
                    return formItem;
                  });
                  setTaxFormValues(updatedTaxFormValues);
                }}
              />
            </Grid>
          </Grid>
        ))}
        <Grid
        style={{marginTop:'16px'}}
          className={classes.btnGroup}
          container
          xs={12}
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Button style={{marginRight:'10px'}}
            className={classes.leftButton}
            variant="outlined"
            size="large"
            onClick={() => history.push(`/app/tax-rate/`)}
          >
            Cancel
          </Button>
          <Button
            className={classes.rightButton}
            variant="contained"
            size="large"
            color="secondary"
            onClick={submitFormData}
          >
            Update
          </Button>
          <Button style={{marginLeft:'10px'}}
            className={classes.rightButton}
            variant="contained"
            size="large"
            color="secondary"
            onClick={deleteFormData}
          >
            Delete
          </Button>
        </Grid>
        
      </Widget>
    </>
  );
}
