import React from 'react'
import AddServices from '../../components/Inventory/Services/AddServices'

export default function newService() {
  return (
    <>
    <AddServices/>
    </>
  )
}
