import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TableRow,
  TableCell,
  Typography,
  InputBase,
  Checkbox,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";

import { useHistory } from "react-router-dom";
import classNames from "classnames";
import {
  MoreVert as MoreIcon,
  Search as SearchIcon,
  Lens,
  CheckBox,
} from "@material-ui/icons";
import useStyles from "../../styles";
import TableWidget from "../../../../Widget/TableWidget";
import TableDesign from "../../../../common/table/TableDesign";
import { tableService } from "../order_constant";
import { getInvtService } from "../../../../../services/ServiceLayer";
import { loadState, validateLargeAmount } from "../../../../../hooks/helper";
export default function TableForService({
  selectedItems,
  eyeTypeData,
  setTotalRetailPrice,
  setTotalTaxPrice,
  frameDataDetail,
  setFrameDataDetail,
  setTotalDiscount,
}) {
  var classes = useStyles();
  var [isSearchOpen, setSearchOpen] = useState(true);
  console.log("selectedItems", selectedItems);
  const [serviceListData, setServiceListData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedEyes, setSelectedEyes] = useState(selectedRows.map(() => ""));
  const [insuranceDue, setInsuranceDue] = useState(selectedRows.map(() => "0"));
  const [insurancePaid, setInsurancePaid] = useState(selectedRows.map(() => "0"));
  const [quantity, setQuantity] = useState(selectedRows.map(() => "1"));
  const [discount, setDiscount] = useState(selectedRows.map(() => "0"));
  useEffect(() => {
    console.log("discount", discount);
    const sumOfDiscounts =
      discount && discount.reduce((acc, item) => acc + parseFloat(item), 0);
    console.log("sumOfDiscounts", sumOfDiscounts);
    setTotalDiscount(sumOfDiscounts.toFixed(2));
    console.log("insuranceDue", insuranceDue);
    console.log("quantity", quantity);
    console.log("discount", discount);
    console.log("insurancePaid", insurancePaid);
  }, [discount, insuranceDue]);
  useEffect(() => {
    if (selectedItems) {
      console.log("selectedItems", selectedItems);
      console.log("serviceListData", serviceListData);
      console.log(selectedItems.item_id);
      // Splitting the item_id into an array of individual IDs
      const itemIds = selectedItems.item_id.split("|");
      const selectedEyes = selectedItems.item_type && selectedItems.item_type.split("|");
      setSelectedEyes(selectedEyes ? selectedEyes : selectedRows.map(() => ""));
      const quantity = selectedItems.quantity && selectedItems.quantity.split("|");
      setQuantity(quantity ? quantity : selectedRows.map(() => "0"));
    
      const discount = selectedItems.discount && selectedItems.discount.split("|");
      setDiscount(discount ? discount : selectedRows.map(() => "0"));
    
      const insuranceDue = selectedItems.insurance_due && selectedItems.insurance_due.split("|");
      setInsuranceDue(insuranceDue ? insuranceDue : selectedRows.map(() => "0"));
    
      const insurancePaid = selectedItems.insurance_paid && selectedItems.insurance_paid.split("|");
      setInsurancePaid(insurancePaid ? insurancePaid : selectedRows.map(() => "0"));
      console.log("itemIds", itemIds);
      const selectedServices = serviceListData.filter((service) =>
        itemIds.includes(service.service_id)
      );
      console.log("Selected Services:", selectedServices);
      setSelectedRows(selectedServices);
    }
  }, [selectedItems, serviceListData]);

  const [isLoader, setIsLoader] = useState(false);
  const userData = loadState().user;
  const readAccessData = loadState().menu;
  const [rowData, setRowData] = useState([]);
  const userRole = loadState().user.user_role;
  // table Header
  const tableHeadings = [
    {
      label: "#",
      align: "left !important",
    },
    {
      label: "Service Name",
      align: "left !important",
    },

    {
      label: "Eye",
      align: "left !important",
    },
    {
      label: "Reatil Price",
      align: "left !important",
    },
    {
      label: "Quantity",
      align: "left !important",
    },
    {
      label: "Insurance Due",
      align: "left !important",
    },
    {
      label: "Insurance paid",
      align: "left !important",
    },
    {
      label: "Patient Amount",
      align: "left !important",
    },
    {
      label: "Discount (%)",
      align: "left !important",
    },
    {
      label: "Total Amount",
      align: "left !important",
    },
  ];
  const tableHeadings1 = [
    {
      label: "Select",
      align: "left !important",
    },
    {
      label: "Service Name",
      align: "left !important",
    },
    {
      label: "Reatil Price",
      align: "left !important",
    },
  ];

  useEffect(() => {
    getInvtService(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSRV`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setIsLoader(false);
        setServiceListData(response?.data);
      } else {
        setIsLoader(false);
      }
    });
  }, []);
  const handleRowSelection = (row) => {
    const updatedSelectedRows = [...selectedRows];
    const index = updatedSelectedRows.indexOf(row);
    if (index !== -1) {
      // If the service code is already selected, remove it
      updatedSelectedRows.splice(index, 1);
    } else {
      // If the service code is not selected, add it
      updatedSelectedRows.push(row);
    }
    setSelectedRows(updatedSelectedRows);
    // Collect service_id values as a string
  };
  const filteredTableService = tableService.filter((row) =>
    selectedRows.includes(row.service_code)
  );
  const [allAmount, setAllAmount] = useState(0);
  useEffect(() => {
    console.log("selectedRows", selectedRows);
    const total = selectedRows.reduce((acc, row) => {
      const selectedService = serviceListData.find(
        (service) => service.service_code === row.service_code
      );
      if (selectedService) {
        acc += parseFloat(selectedService.retail_price);
      }
      return acc;
    }, 0);
    // tax
    const tax = selectedRows.reduce((acc, row) => {
      const selectedService = serviceListData.find(
        (service) => service.service_code === row.service_code
      );
      if (selectedService) {
        acc += parseFloat(selectedService.service_tax1);
      }
      return acc;
    }, 0);

    setTotalTaxPrice(tax.toFixed(2));
    // setTotalRetailPrice(total.toFixed(2));
    const collectSelectedRows = [...selectedRows];
    console.log("collectSelectedRows", collectSelectedRows);
    const serviceIdsString = collectSelectedRows
      .map((r) => r.service_id)
      .join("|");
    console.log("insuranceDue", insuranceDue);
    console.log("selectedEyes", selectedEyes);
    console.log("quantity", quantity);
    const insuranceDueString =
    insuranceDue && insuranceDue.some((value) => value !== "")
      ? insuranceDue.join("|")
      : collectSelectedRows.map(() => "0").join("|");
      const insurancePaidString =
      insurancePaid && insurancePaid.some((value) => value !== "")
        ? insurancePaid
            .map((value) => (value !== null && value !== "" ? value : "0"))
            .join("|")
        : collectSelectedRows.map(() => "0").join("|");
    const discountString =
      discount && discount.some((value) => value !== "")
        ? discount.join("|")
        : collectSelectedRows.map(() => "0").join("|");
    const quantityString =
      quantity && quantity.some((value) => value !== "")
        ? quantity.join("|")
        : collectSelectedRows.map(() => "1").join("|");
    const eyesString = selectedEyes.join("|");
    console.log("eyesString", eyesString);
    setFrameDataDetail({
      ...frameDataDetail,
      item_type: eyesString,
      item_id: serviceIdsString,
      item_insurance_due: insuranceDueString,
      item_insurance_paid: insurancePaidString,
      item_discount: discountString,
      quantity: quantityString,
    });

    const calculatedTotalAmount = selectedRows.reduce((acc, row, i) => {
      const selectedQuantity = parseFloat(quantity[i]) || 1; // Use 1 if quantity is not entered
      const selectedInsuranceDue =
        parseFloat(insuranceDue !== undefined ? insuranceDue[i] : 0) || 0; // Use 0 if insuranceDue is not entered
      const selectedInsurancePaid =
        parseFloat(insurancePaid !== undefined ? insurancePaid[i] : 0) || 0; // Use 0 if insurancePaid is not entered
      const selectedDiscount =
        parseFloat(discount !== undefined ? discount[i] : 0) || 0; // Use 0 if discount is not entered
      const subtotal =
        parseFloat(row.retail_price) * selectedQuantity -
        selectedInsuranceDue +
        selectedInsurancePaid -
        ((parseFloat(row.retail_price) * selectedQuantity -
          selectedInsuranceDue +
          selectedInsurancePaid) *
          selectedDiscount) /
          100;
      return acc + subtotal;
    }, 0);
    setTotalRetailPrice(calculatedTotalAmount);
  }, [
    selectedRows,
    serviceListData,
    insuranceDue,
    insurancePaid,
    discount,
    quantity,
    selectedEyes,
  ]);
  const calculateTotal = (
    retailPrice,
    quantity,
    insuranceDue,
    insurancePaid
  ) => {
    const retail = parseFloat(retailPrice) || 0;
    const currentQuantity = parseFloat(quantity) || 1;
    const currentInsuranceDue = parseFloat(insuranceDue) || 0;
    const currentInsurancePaid = parseFloat(insurancePaid) || 0;

    return (
      retail * currentQuantity - currentInsuranceDue + currentInsurancePaid
    );
  };

  const calculateTotalAmount = (
    retailPrice,
    quantity,
    insuranceDue,
    insurancePaid,
    discount
  ) => {
    const retail = parseFloat(retailPrice) || 0;
    const currentQuantity = parseFloat(quantity) || 1;
    const currentInsuranceDue = parseFloat(insuranceDue) || 0;
    const currentInsurancePaid = parseFloat(insurancePaid) || 0;
    const currentDiscount = parseFloat(discount) || 0;

    const subtotal =
      retail * currentQuantity - currentInsuranceDue + currentInsurancePaid;
    const totalAmount = subtotal * (1 - currentDiscount / 100);

    return totalAmount.toFixed(2);
  };
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <div style={{ height: "40vh", overflowY: "auto" }}>
            <TableWidget noBodyPadding>
              <TableDesign
                {...{
                  title: "",
                  tableHeadings: tableHeadings1,
                  height: "40px",
                  fontSize: "10px",
                }}
              >
                {serviceListData && serviceListData.length > 0 ? (
                  serviceListData.map((row, i) => {
                    if (row)
                      return (
                        <TableRow
                          style={{ height: "40px" }}
                          key={i}
                          className={classes.tableBodyCell}
                        >
                          <TableCell
                            align="left"
                            className={classes.tableBodyCell}
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              paddingLeft: "12px",
                              fontSize: "10px",
                            }}
                          >
                            <Checkbox
                              checked={selectedRows.includes(row)}
                              onChange={() => handleRowSelection(row)}
                            />
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              paddingLeft: "12px",
                              fontSize: "10px",
                            }}
                            className={classes.tableBodyCell}
                          >
                            {row.service_name}
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              paddingLeft: "12px",
                              fontSize: "10px",
                            }}
                            className={classes.tableBodyCell}
                          >
                            {row.retail_price}
                          </TableCell>
                        </TableRow>
                      );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan="8">No Result Found</TableCell>
                  </TableRow>
                )}
              </TableDesign>
            </TableWidget>
          </div>
        </Grid>
        <Grid item xs={8} style={{ width: "100px" }}>
          <div style={{ height: "40vh", overflowY: "auto" }}>
            <TableWidget noBodyPadding>
              <TableDesign
                {...{
                  title: "List of All Clients",
                  tableHeadings,
                  height: "40px",
                  fontSize: "10px",
                }}
              >
                {selectedRows && selectedRows.length > 0 ? (
                  selectedRows.map((row, i) => {
                    if (row)
                      return (
                        <TableRow
                          style={{ height: "40px" }}
                          key={i}
                          className={classes.tableBodyCell}
                        >
                          <TableCell
                            align="left"
                            className={classes.tableBodyCell}
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              paddingLeft: "12px",
                              fontSize: "10px",
                            }}
                          >
                            {i + 1}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              paddingLeft: "12px",
                              fontSize: "10px",
                            }}
                            className={classes.tableBodyCell}
                          >
                            {row.service_name}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              paddingLeft: "12px",
                              fontSize: "10px",
                            }}
                            className={classes.tableBodyCell}
                          >
                            <FormControl
                              fullWidth
                              className={classes.dropdownFormControl}
                            >
                              <Select
                                name={`eyes-${i}`}
                                style={{ fontSize: "10px" }}
                                className={classes.selectDropdown}
                                placeholder="Select"
                                value={selectedEyes[i] ? selectedEyes[i] : ''}
                                onChange={(e) => {
                                  console.log("e.target.value", e.target.value);
                                  const newSelectedEyes = [...selectedEyes];
                                  newSelectedEyes[i] = e.target.value;
                                  console.log(
                                    "newSelectedEyes",
                                    newSelectedEyes
                                  );
                                  setSelectedEyes(newSelectedEyes);
                                }}
                              >
                                <MenuItem disabled value="">
                                  Select
                                </MenuItem>
                                {eyeTypeData &&
                                  eyeTypeData.map((item, i) => (
                                    <MenuItem value={item?.attribute_id}>
                                      {item?.attribute_val}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              paddingLeft: "12px",
                              fontSize: "10px",
                            }}
                            className={classes.tableBodyCell}
                          >
                            {row.retail_price}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              paddingLeft: "12px",
                              fontSize: "10px",
                            }}
                            className={classes.tableBodyCell}
                          >
                            <TextField
                              name={`quantity-${i}`}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              type="number"
                              value={quantity[i] ? quantity[i] : "1"}
                              inputProps={{
                                style: {
                                  height: "12px",
                                  fontSize: "10px",
                                },
                                onKeyPress: validateLargeAmount,
                                min: 1,
                              }}
                              onChange={(e) => {
                                const newQuantities = [...quantity];
                                newQuantities[i] = e.target.value;
                                setQuantity(newQuantities);
                              }}
                            />
                          </TableCell>{" "}
                          <TableCell
                            align="left"
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              paddingLeft: "12px",
                              fontSize: "10px",
                            }}
                            className={classes.tableBodyCell}
                          >
                            {" "}
                            <TextField
                              required
                              name={`insuranceDue-${i}`}
                              placeholder="Enter Value"
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                style: {
                                  height: "12px",
                                  fontSize: "10px",
                                },
                                onKeyPress: validateLargeAmount,
                                min: 1,
                              }}
                              value={
                                insuranceDue && insuranceDue[i] !== undefined
                                  ? insuranceDue[i]
                                  : "0"
                              }
                              onChange={(e) => {
                                const newQuantities = [...insuranceDue];
                                newQuantities[i] = e.target.value;
                                setInsuranceDue(newQuantities);
                              }}
                            />
                          </TableCell>{" "}
                          <TableCell
                            align="left"
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              paddingLeft: "12px",
                              fontSize: "10px",
                            }}
                            className={classes.tableBodyCell}
                          >
                            <TextField
                              required
                              name={`insurancePaid-${i}`}
                              placeholder="Enter"
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                style: {
                                  height: "12px",
                                  fontSize: "10px",
                                },
                                onKeyPress: validateLargeAmount,
                                min: 1,
                              }}
                              value={
                                insurancePaid && insurancePaid[i] !== undefined
                                  ? insurancePaid[i]
                                  : "0"
                              }
                              onChange={(e) => {
                                const newQuantities = [...insurancePaid];
                                newQuantities[i] = e.target.value;
                                setInsurancePaid(newQuantities);
                              }}
                            />
                          </TableCell>{" "}
                          <TableCell
                            align="left"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              paddingLeft: "12px",
                              fontSize: "10px",
                            }}
                            className={classes.tableBodyCell}
                          >
                            {calculateTotal(
                              row.retail_price,
                              quantity && quantity[i],
                              insuranceDue && insuranceDue[i],
                              insurancePaid && insurancePaid[i]
                            )}
                          </TableCell>{" "}
                          <TableCell
                            align="left"
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              paddingLeft: "12px",
                              fontSize: "10px",
                            }}
                            className={classes.tableBodyCell}
                          >
                            <TextField
                              required
                              name={`discount-${i}`}
                              placeholder="Enter"
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                style: {
                                  height: "12px",
                                  fontSize: "10px",
                                },
                                onKeyPress: validateLargeAmount,
                                min: 1,
                              }}
                              value={discount[i] ? discount[i] : 0}
                              onChange={(e) => {
                                const newQuantities = [...discount];
                                newQuantities[i] = e.target.value;
                                setDiscount(newQuantities);
                              }}
                            />
                          </TableCell>{" "}
                          <TableCell
                            align="left"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              paddingLeft: "12px",
                              fontSize: "10px",
                            }}
                            className={classes.tableBodyCell}
                          >
                            {calculateTotalAmount(
                              row.retail_price,
                              quantity && quantity[i],
                              insuranceDue && insuranceDue[i],
                              insurancePaid && insurancePaid[i],
                              discount && discount[i]
                            )}
                          </TableCell>{" "}
                        </TableRow>
                      );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan="8">No Result Found</TableCell>
                  </TableRow>
                )}
              </TableDesign>
            </TableWidget>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
