import { Grid, TableCell, TableRow, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "../styles";
import TableWidget from "../../Widget/TableWidget";
import TableDesign from "../../common/table/TableDesign";
import { getVisitReasonData } from "../../../services/ServiceLayer";
import { loadState } from "../../../hooks/helper";

export default function ReasonVisit({ patientId }) {
  var classes = useStyles();
  const [allergyData, setAllergyData] = useState([]);
  const tableHeadings = [
    {
      label: "Reason Visit",
    },
  ];
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [visitReasonData, setVisitReasonData] = useState();
  useEffect(() => {
    getVisitReasonData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELRIS`
    ).then((response) => {
      console.log("response-216", response);
      if (response?.data) {
        // setIsLoader(false);
        setVisitReasonData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);
  return (
    <>
      <Grid container spacing={4} style={{ width: "100%" }}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {visitReasonData ? (
                <TableRow className={classes.tableBodyCell}>
                  <TableCell className={classes.tableBodyCell}>
                    {visitReasonData?.description}
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
