import React, { useState, useEffect } from "react";
import { Button, Grid, Menu, MenuItem, Typography } from "@material-ui/core";

import { dateFormatConversion, loadState } from "../../../hooks/helper";

import TableWidget from "../../Widget/TableWidget";

import TableDesign from "../../common/table/TableDesign";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
import MedicationChart from './MedicationChart' 


// styles
import useStyles from "../styles";

import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";
import { getAllergyHistoryData, getFinalRefractiveData } from "../../../services/ServiceLayer";

import moment from "moment";

export default function FinalRx(props) {
  const tableHeadings = [
   
    {
      label: "Exam Name",
      align: "left",
    },
    {
      label: "Type",
      align: "left",
    },
    {
      label: "Sph",
      align: "left",
    },
    {
      label: "Cyl",
      align: "left",
    },
    {
      label: "Axis",
      align: "left",
    },
    {
      label: "Add",
      align: "left",
    },
    {
      label: "HPrism",
      align: "left",
    },
    {
      label: "HBase",
      align: "left",
    },
    {
      label: "VPrism",
      align: "left",
    },
    {
      label: "VBase",
      align: "left",
    },
    {
      label: "DVA",
      align: "left",
    },
    {
      label: "NVA",
      align: "left",
    },
    {
      label: "Status",
    },
   
  ];
  const { patientId } = props;


  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [finalRxData, setFinalRxData] = useState([]);
  useEffect(()=>{
    getFinalRefractiveData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELRFR`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setFinalRxData(response?.data);
        // setDataUpdated(false);
      } else {
        // setIsLoader(false);
      }
    });
  },[])
  
  return (
    <>


      <Grid container spacing={4} style={{ width: "100%" }}>
        <Grid item xs={12}>
        <div style={{background:'#5DB3D9',padding:'10px',color:'#fff',textAlign:'center',borderRadius:'5px',marginBottom:'2px'}}>Final Refractive</div>
          {/* <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {finalRxData && finalRxData?.length ? (
                finalRxData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_name}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          OD
                          <br />
                          <span style={{ color: "#0586FB" }}>OS</span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_sphere_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            {row.fr_sphere_os}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_cyl_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            {row.fr_cyl_os}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_axis_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            {row.fr_axis_os}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_add_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            {row.fr_add_os}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_hprism_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            {row.fr_hprism_os}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_hbase_od_var}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                          {row.fr_hbase_os_var}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_vprism_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            {row.fr_vprism_os}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_vbase_od_var}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            {row.fr_vbase_os_var}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_dva_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            {row.fr_dva_os}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_nva_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            {row.fr_nva_os}
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget> */}

<MedicationChart />
        </Grid>
      </Grid>
    </>
  );
}
