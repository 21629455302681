import {
  handleKeyPress,
  handleKeyPressPaste,
  handleAlphNumKeyPress,
  handleAlphNumKeyPressPaste,
  handleAlphNumberKeyPress,
  handleAlphNumberKeyPressPaste,
  handleAddressKeyPress,
  handleAddressKeyPressPaste,
} from "../../hooks/helper";
export const formProblemElements = [
  {
    key: "problem_id",
    label: "Problem",
    placeHolder: "Select",
    type: "select",
    options: [],
    mdSize: "4",
  },
  {
    key: "st_date",
    label: "Start Date",
    placeHolder: "select",
    type: "date",
    mdSize: "4",
    required:true,
  },
  {
    key: "end_date",
    label: "End Date",
    placeHolder: "select",
    type: "date",
    mdSize: "4",
    required:true,
  },
  {
    key: "icd_code",
    label: "ICD Code",
    placeHolder: "Enter Text",
    type: "text",
    mdSize: "4",
    onKeyPress: handleKeyPress,
    onPaste: handleKeyPressPaste,

    maxLength: "10",
    // required: "true",
  },
  // {
  //   key: "problem_status",
  //   label: "Status",
  //   placeHolder: "select",
  //   type: "select",
  //   options: [],
  //   mdSize: "4",
  // },
];
export const ocularHistory = [
  {
    key:'occul_glass',
    label: "",
    lbltitle: "Wear Glasses?",
    type: "checkbox",
    value: "wear",
    mdSize: "4",
  },
  {
    key:'occul_purchase_glass',
    label: " Last Purchase Date",
    type: "date",
    mdSize: "4",
  },
  {
    key:'occul_cont_lense',
    label: "",
    lbltitle: "Wear Contact Lenses?",
    type: "checkbox",
    value: "wear",
    mdSize: "4",
  },
  {
    key:'occul_cont_purchase_lense',
    label: " Last Purchase Date",
    type: "date",
    mdSize: "4",
  },
  {
    key:'occul_brand',
    label: "Brand?",
    type: "select",
    options:[],
    mdSize: "4",
  },
  {
    key:'occul_blur',
    label: "Blurred Vision",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'occul_lossofvision',
    label: "Loss of Vision",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'occul_nyctalopia',
    label: "Nyctalopia",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'occul_distortedvision',
    label: "Distorted Vision / Halos",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },

  {
    key:'occul_lossofsidevision',
    label: "Loss of Side Vision",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'occul_doublevision',
    label: "Double Vision",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'occul_dryness',
    label: "Dryness",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },

  {
    key:'occul_mucousdischarge',
    label: "Mucous Discharge",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'occul_redness',
    label: "Redness",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'occul_frontalheadache',
    label: "Frontal Headache",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },

  {
    key:'occul_itching',
    label: "Itching",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'occul_burning',
    label: "Burning",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'occul_colorblindness',
    label: "Color Blindness",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'occul_foreignbodysensation',
    label: "Foreign Body Sensation",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'occul_excesstearingwatering',
    label: "Excess Tearing / Watering",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'occul_glarelightsensitivity',
    label: "Glare / Light Sensitivity",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },

  {
    key:'occul_eyepainorsoreness',
    label: "Eye Pain or Soreness",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'occul_infectionofeyeorlid',
    label: "Infection of Eye or Lid",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'occul_stiesorchalazion',
    label: "Sties or Chalazion",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'occul_flashesfloatersinvision',
    label: "Flashes/Floaters in Vision",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },

  {
    key:'occul_tiredeyes',
    label: "Tired Eyes",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'occul_cataract',
    label: "Cataract",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'occul_crossedeyes',
    label: "Crossed Eyes",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
];
export const familyHistoryForm = [
  {
    key:'fmh_glaucoma',
    label: "Glaucoma",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'fmh_lossofvision',
    label: "Loss of Vision",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'fmh_lazyeye',
    label: "Lazy Eye:",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'fmh_masculardegeneration',
    label: "Macular Degenaration",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'fmh_colorblindness',
    label: "Color Blindness",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'fmh_retinaldetachment',
    label: "Retinal Detachment",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'fmh_keratoconus',
    label: "Keratoconus",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'fmh_diabetes',
    label: "Diabetes",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'fmh_heartdisease',
    label: "Heart Disease",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key:'fmh_highbloodpressure',
    label: "High Blood Pressure",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  // {
  //   key:'fmh_lowbloodpressure',
  //   label: "Low Blood Pressure",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
  // {
  //   key:'fmh_kidneydisease',
  //   label: "Kidney Disease",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
  // {
  //   key:'fmh_lupus',
  //   label: "Lupus",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
  // {
  //   key:'fmh_thyroiddisease',
  //   label: "Thyroid Disease",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
  // {
  //   key:'fmh_nyctalopia',
  //   label: "Nyctalopia",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
  // {
  //   key:'fmh_eyepainorsoreness',
  //   label: "Eye Pain or Soreness",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
  // {
  //   key:'fmh_infectionofeyeorlid',
  //   label: "Infection of Eye or Lid",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
  // {
  //   key:'fmh_stiesorchalazion',
  //   label: "Sties or Chalazion",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
  // {
  //   key:'fmh_cancer',
  //   label: "Cancer",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
];
export const formReasonVisitElements = [
  {
    key: "reason_id",
    label: "Reason",
    placeHolder: "Select",
    type: "select",
    options: [],
    mdSize: "4",
  },
  {
    key: "impacted_part_id",
    label: "Impacted Part",
    placeHolder: "Select",
    type: "select",
    options: [],
    mdSize: "4",
  },
  {
    key: "frequency_id",
    label: "Frequency",
    placeHolder: "Select",
    type: "select",
    options: [],
    mdSize: "4",
  },
  {
    key: "severity_id",
    label: "Severity",
    placeHolder: "Select",
    type: "select",
    options: [],
    mdSize: "4",
  },
  {
    key: "occurrence_id",
    label: "Occurs while",
    placeHolder: "Select",
    type: "select",
    options: [],
    mdSize: "4",
  },
  {
    key: "factor_id",
    label: "Factors",
    placeHolder: "Select",
    type: "select",
    options: [],
    mdSize: "4",
  },
  {
    key: "symptom_id",
    label: "Symptoms",
    placeHolder: "Select",
    type: "select",
    options: [],
    mdSize: "4",
  },
  {
    key: "reason_from_date",
    label: "Since",
    placeHolder: "Select",
    type: "select",
    options: [],
    mdSize: "4",
  },
  {
    key: "description",
    label: "Description",
    placeHolder: "Enter Text",
    type: "textarea",
    mdSize: "42",
    //   onKeyPress: handleKeyPress,
    //   onPaste: handleKeyPressPaste,

    //   maxLength: "10",
    // required: "true",
  },
];
export const medicalHistory = [
  {
    key: "med_weightloss",
    label: "Weight Loss/Gain",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key: "med_lossofappetite",
    label: "Loss of Appetite",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key: "med_sleepapnea",
    label: "Sleep Apnea",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key: "med_covid19",
    label: "COVID 19",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    title: "NEUROLOGICAL",
    mdSize: "42",
  },
  {
    key: "neu_headaches",
    label: "Headaches",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key: "neu_migraine",
    label: "Migraine",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key: "neu_seizures",
    label: "Seizures",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  // {
  //   title: "ENT",
  //   mdSize: "42",
  // },
  // {
  //   key: "ent_sinusitis",
  //   label: "Sinusitis",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
  // {
  //   key: "ent_hearingloss",
  //   label: "Hearing Loss",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
  // {
  //   key: "ent_laryngitis",
  //   label: "Laryngitis",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
  {
    title: "ENDOCRINE",
    mdSize: "42",
  },
  {
    key: "end_thyroiddisease",
    label: "Thyroid Disease",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  // {
  //   key: "end_otherglands",
  //   label: "Other Glands",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
  // {
  //   key: "end_respiratory",
  //   label: "RESPIRATORY",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
  // {
  //   key: "end_asthma",
  //   label: "Asthma",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
  // {
  //   key: "end_chronicbronchitis",
  //   label: "Chronic Bronchitis",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
  // {
  //   key: "end_emphysema",
  //   label: "Emphysema",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
  {
    title: "VASCULAR / CARDIOVASCULAR",
    mdSize: "42",
  },
  {
    key: "vas_diabetes",
    label: "Diabetes",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key: "vas_heartpain",
    label: "Heart Pain",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key: "vas_highbp",
    label: "High Blood Pressure",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key: "vas_vasculardisease",
    label: "Vascular Disease",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  // {
  //   title: "GASTROINTESTINAL",

  //   mdSize: "42",
  // },
  // {
  //   key: "gas_acidreflux",
  //   label: "Acid Reflux",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
  // {
  //   key: "gas_ulcer",
  //   label: "Ulcer",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
  // {
  //   key: "gas_celiacdisease",
  //   label: "Celiac Disease",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },

  // {
  //   title: "GENITOURINARY",
  //   mdSize: "42",
  // },
  // {
  //   key: "gen_genitalsdisease",
  //   label: "Genitals Disease",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
  // {
  //   key: "gen_kidneydisease",
  //   label: "Kidney Disease",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
  // {
  //   key: "gen_bladdardisease",
  //   label: "Bladdar Disease",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
  {
    title: "Musculoskeletal",
    mdSize: "42",
  },
  {
    key: "mus_rheumatoidarthritis",
    label: "Rheumatoid Arthritis",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key: "mus_osteoporosis",
    label: "Osteoporosis",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key: "mus_musculardystrophy",
    label: "Muscular Dystrophy",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
  {
    key: "mus_jointpain",
    label: "Joint Pain",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },

  {
    title: "LYMPHATIC / HEMATOLOGIC",
    mdSize: "42",
  },
  // {
  //   key: "lym_anemia",
  //   label: "Anemia",
  //   type: "radio",
  //   radioValue: ["Yes", "No", "Unknown"],
  //   mdSize: "4",
  // },
  {
    key: "lym_bleedingproblems",
    label: "Bleeding Problems",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "4",
  },
];
export const formBillingElements = [
  {
    key: "billing_address_line1",
    label: "Billing Address Line 1",
    placeHolder: "Enter Billing Address Line 1",
    type: "text",
    mdSize: "4",
    maxLength: "30",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "billing_address_line2",
    label: "Billing Address Line 2",
    placeHolder: "Enter Billing Address Line 2",
    type: "text",
    mdSize: "4",
    maxLength: "30",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "billing_zip",
    label: "Zip",
    placeHolder: "Enter Zip",
    type: "text",
    mdSize: "4",
    onKeyPress: handleKeyPress,
    onPaste: handleKeyPressPaste,
    maxLength: "5",
  },
  {
    key: "billing_city",
    label: "City",
    placeHolder: "Enter City",
    type: "text",
    mdSize: "4",
  },
  {
    key: "billing_state",
    label: "State",
    placeHolder: "Enter Last Name",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "4",
  },

  {
    key: "billing_address_verified",
    label: "Status",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "4",
  },
  {
    key: "shipping_address_line1",
    label: "Mailing Address Line 1",
    placeHolder: "Enter Mailing Address Line 1",
    type: "text",
    mdSize: "4",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "shipping_address_line2",
    label: "Mailing Address Line 2",
    placeHolder: "Enter Mailing Address Line 2",
    type: "text",
    mdSize: "4",
    maxLength: "30",
    onKeyPress:handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "shipping_zip",
    label: "Zip",
    placeHolder: "Enter Zip",
    type: "text",
    mdSize: "4",
    onKeyPress: handleKeyPress,
    onPaste: handleKeyPressPaste,
    maxLength: "5",
  },
  {
    key: "shipping_city",
    label: "City",
    placeHolder: "Enter City",
    type: "text",
    mdSize: "4",
  },
  {
    key: "shipping_address_verified",
    label: "State",
    placeHolder: "Enter Last Name",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "4",
  },

  {
    key: "shipping_status",
    label: "Status",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "4",
  },
];

export const formAdditionalElements = [
  {
    key: "ethnicity",
    label: "Ethinicity",
    placeHolder: "Enter Ethinicity",
    type: "select",
    options: [],
    mdSize: "4",
  },
  {
    key: "race",
    label: "Race",
    placeHolder: "Enter Race",
    type: "select",
    options: [],
    mdSize: "4",
  },
  {
    key: "hobbies",
    label: "Hobbies",
    placeHolder: "Enter Hobbies",
    type: "text",
    mdSize: "4",
  },
  {
    value: "btn1",
    btnName: "Search Physician",
    type: "button",
    mdSize: "4",
    btnClick: "searchguardian",
  },
  {
    key: "primary_physician",
    label: "Primary Physician",
    placeHolder: "Enter Primary Physician",
    type: "text",
    mdSize: "4",
  },
  {
    key: "physician_address",
    label: "Physician Address",
    placeHolder: "Enter Physician Address",
    type: "text",
    mdSize: "4",
  },
  {
    key: "physician_city",
    label: "City",
    placeHolder: "Enter City",
    type: "text",
    mdSize: "4",
  },
  {
    key: "physician_state",
    label: "State",
    placeHolder: "select",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "4",
  },
  {
    key: "physician_zip",
    label: "Zip",
    placeHolder: "Enter Zip",
    type: "text",
    mdSize: "4",
    onKeyPress: handleKeyPress,
    onPaste: handleKeyPress,
    maxLength: "5",
  },
  {
    key: "notes",
    label: "General Notes",
    placeHolder: "Enter General Notes",
    type: "textarea",
    mdSize: "4",
  },
  {
    key: "imp_shrt_notes",
    label: "Alert",
    placeHolder: "Enter Important Short Notes",
    type: "text",
    mdSize: "4",
  },
  {
    key: "applicable_role_id",
    label: "Applicable Role ",
    placeHolder: "select",
    type: "select",
    options: ["Reception", "Doctor", "Admin", "Optician"],
    mdSize: "4",
  },
  {
    key: "is_disability",
    label: "Disability",
    placeHolder: "select",
    type: "select",
    options: [
      "Blindness",
      "Low-vision",
      "Leprosy Cured persons",
      "Hearing Impairment (deaf and hard of hearing)",
      "Locomotor Disability",
      "Dwarfism",
      "Intellectual Disability",
      "Mental Illness",
      "Autism Spectrum Disorder",
      "Cerebral Palsy",
      "Muscular Dystrophy",
      "Chronic Neurological conditions",
      "Specific Learning Disabilities",
      "Multiple Sclerosis",
      "Speech and Language disability",
      "Thalassemia",
      "Hemophilia",
      "Sickle Cell disease",
      "Multiple Disabilities including deaf-blindness",
      "Acid Attack victims",
      "Parkinson’s disease",
      "Other",
    ],
    mdSize: "4",
  },
  {
    key: "is_veteran_status",
    label: "Veteran Status",
    placeHolder: "select",
    type: "select",
    options: [
      "Protected Veteran",
      "Other Veteran",
      "Not a Protected Veteran",
      "Active Military",
    ],
    mdSize: "4",
  },
];
export const formPatientStatusElements = [
  {
    key: "patient_status",
    label: "Patient Status",
    type: "select",
    options: ["Active", "Inactive"],
    mdSize: "4",
  },
  {
    key: "patient_inactive_reason",
    label: "Inactivity Reason",
    type: "select",
    options: ["Change city/state", "Change country", "Change Practice"],
    mdSize: "4",
  },
  {
    key: "inactive_date",
    label: "Inactive Date",
    type: "date",
    mdSize: "4",
  },
  {
    key: "deceased_reason",
    label: "Deceased Reason",
    type: "select",
    options: ["Change city/state", "Change country", "Change Practice"],
    mdSize: "4",
  },
  {
    key: "deceased_date",
    label: "Deceased Date",
    type: "date",
    mdSize: "4",
  },
  {
    key: "deceased_date_received",
    label: "Date",
    type: "date",
    mdSize: "4",
  },
];
export const formEmergencyElements = [
  {
    key: "first_emergency_contact_name",
    label: "First Emergency contact Full Name",
    placeHolder: "type here",
    type: "text",
    mdSize: "4",
  },
  {
    key: "first_emergency_contact_con",
    label: "First Emergency Phone",
    placeHolder: "type here",
    type: "text",
    mdSize: "4",
    onKeyPress: handleKeyPress,
    onPaste: handleKeyPressPaste,
    maxLength: "10",
  },
  {
    key: "sec_emergency_contact_name",
    label: "Second Emergency Contact Full Name",
    placeHolder: "type here",
    type: "text",
    mdSize: "4",
  },
  {
    key: "sec_emergency_contact_con",
    label: "Second Emergency Phone",
    placeHolder: "type here",
    type: "text",
    mdSize: "4",
    onKeyPress: handleKeyPress,
    onPaste: handleKeyPressPaste,
    maxLength: "10",
  },
];
export const substanceForm = [
  {
    key:'subs_alcohol',
    label: "Use of Alcohol ?",
    // lbltitle: "Use of Alcohol ?",
    type: "radio",
    radioValue: ["Yes", "No"],
    mdSize: "4",
  },

  {
    key:'subs_alcohol_times',
    label: "Times / Week",
    type: "text",
    mdSize: "4",
    maxLength:'20'
  },
  // {
  //   title: "",
  //   mdSize: "4",
  // },
  // 2
  {
    key:'subs_tobacco',
    label: "Use of Tobacco ?",
    // lbltitle: "Use of Tobacco ?",
    type: "radio",
    radioValue: ["Yes", "No"],
    mdSize: "4",
  },

  {
    key:"subs_tobacco_times",
    label: "Times / Week",
    type: "text",
    mdSize: "4",
    maxLength:'20'
  },
  // {
  //   title: "",
  //   mdSize: "4",
  // },
  // 3
  {
    key:'subs_drugs',
    label: "Use of Drugs ?",
    // lbltitle: "Use of Drugs ?",
    type: "radio",
    radioValue: ["Yes", "No"],
    mdSize: "4",
  },

  {
    key:'subs_drugs_times',
    label: "Times / Week",
    type: "text",
    mdSize: "4",
    maxLength:'20'
  },
  {
    key:'subs_injdrugs',
    label: "Ever used needles to inject drugs ?",
    // lbltitle: "Ever used needles to inject drugs ?",
    type: "radio",
    radioValue: ["Yes", "No"],
    mdSize: "4",
  },
  {
    key:'subs_comments',
    label: "Comments",
    type: "textarea",
    mdSize: "4",
    maxLength:'50'
  },
  // {
  //   title: "",
  //   mdSize: "4",
  // },
];
