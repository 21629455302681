import React, { useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import moment from "moment";
// components
// styles
import useStyles from "../styles";
import BinoTesting from "./BinoTesting";
import AddTest from "./AddTest";

export default function MainBinocular({
  patientId,
  eyesType,
  examExternalBinocular,
  setBinoBtnUnable,
  binoBtnUnable,
  setAddTestBtnUnable,
  addTestBtnUnable,
}) {
  var classes = useStyles();
  console.log("eyesType -23", eyesType);
  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Binocular Testing</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BinoTesting
            setBinoBtnUnable={setBinoBtnUnable}
            binoBtnUnable={binoBtnUnable}
            eyesType={eyesType}
            patientId={patientId}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Additional Testing </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AddTest
            examExternalBinocular={examExternalBinocular}
            patientId={patientId}
            setAddTestBtnUnable={setAddTestBtnUnable}
            addTestBtnUnable={addTestBtnUnable}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
