import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import moment from "moment";
// components
// styles
import useStyles from "../styles";
import Medication from "./Medication";
import Allergy from "./Allergy";
import Vitals from "./Vitals";
import OcularHistory from "./OcularHistory";
import MedicalHistory from "./MedicalHistory";
import FamilyHistory from "./FamilyHistory";
import Disclosure from "./Disclosure";
import Substance from "./Substance";
import MajorSurgries from "./MajorSurgries";
import AllergyHistory from "./AllergyHistory";
import MedicationHistory from "./MedicationHistory";
import Loader from "../../common/loader/Loader";

export default function HealthTab({
  patientId,
  allergyOutcome,
  allergySeverity,
  allergyReaction,
  disclosureType,
  surgriesType,
  surgriesOutcome,
  surgriesOccurance,
  surgriesClassification,
  relTypeValues,
  doctorList,
  patientData,
  enable,
  frequency,
  setOcularBtnUnable,
  ocularBtnUnable,
  setMedicalBtnUnable,
  medicalBtnUnable,
  setFamilyBtnUnable,
  familyBtnUnable,
  setSubstanceBtnUnable,
  substanceBtnUnable,frameBrandList,examMode,examFamilyRelation,examEyeType
}) {
  var classes = useStyles();
  console.log("patientData", patientData);
  console.log("patientId", patientId);
  const [isLoader, setIsLoader] = useState(true);
 
  return (
    <>
      {isLoader && <Loader />}
      <Accordion defaultExpanded >
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Medication</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MedicationHistory
            setIsLoader={setIsLoader}
            enable={enable}
            patientId={patientId}
            frequency={frequency}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Allergy</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AllergyHistory
            enable={enable}
            allergyOutcome={allergyOutcome}
            allergyReaction={allergyReaction}
            allergySeverity={allergySeverity}
            patientId={patientId}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Vitals</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Vitals enable={enable} patientId={patientId} />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Ocular History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <OcularHistory
          frameBrandList={frameBrandList}
            enable={enable}
            patientId={patientId}
            setOcularBtnUnable={setOcularBtnUnable}
            ocularBtnUnable={ocularBtnUnable}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Medical History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MedicalHistory
            enable={enable}
            patientId={patientId}
            setMedicalBtnUnable={setMedicalBtnUnable}
            medicalBtnUnable={medicalBtnUnable}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Family History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FamilyHistory
            enable={enable}
            patientId={patientId}
            relTypeValues={relTypeValues}
            setFamilyBtnUnable={setFamilyBtnUnable}
            familyBtnUnable={familyBtnUnable}
            examFamilyRelation={examFamilyRelation}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Major Surgeries/Injuries</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MajorSurgries
            enable={enable}
            patientId={patientId}
            doctorList={doctorList}
            surgriesType={surgriesType}
            surgriesOutcome={surgriesOutcome}
            surgriesOccurance={surgriesOccurance}
            surgriesClassification={surgriesClassification}
            examEyeType={examEyeType}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Substance Use</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Substance
            enable={enable}
            patientId={patientId}
            setSubstanceBtnUnable={setSubstanceBtnUnable}
            substanceBtnUnable ={substanceBtnUnable}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Disclosure</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Disclosure
            enable={enable}
            patientData={patientData}
            patientId={patientId}
            disclosureType={disclosureType}
            doctorList={doctorList}
            examMode={examMode}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
