import React, { useState, useEffect } from "react";

// Model
import {
  Button,
  Typography,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Popup from "../../common/modal/Popup";
import moment from "moment";
import alertIcon from "../../../assets/alertIcon.svg";
// styles
import useStyles from "../styles";
import { toast } from "react-toastify";

import { currentUser, loadState } from "../../../hooks/helper";
import { postAllergyData, postPatientDisclosure, postSurgeryDetails, postVitalData } from "../../../services/ServiceLayer";

export default function DeleteSurgryPopup({
  onHide,
  deleteModalShow,
  setDeleteModalShow,
  selectclient,
  patientId,
}) {
  console.log("selectclient", selectclient);
  console.log("deleteModalShow", deleteModalShow);
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const [actionType, setActionType] = useState("DELSRG");
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [isActionCalled, setIsActionCalled] = useState(false);
  toast.configure();
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setDeleteModalShow(false);
    setOpen(false);
  };
  const [companyDetail, setCompanyDetail] = useState({
    patient_id: patientId,
    location_id: userData?.location_id,
    pat_surg_id: 0,
    sur_type: 0,
    sur_description: "",
    st_date: "",
    end_date: "",
    sur_occurance: 0,
    sur_outcome: 0,
    sur_classification: 0,
    sur_referredby: 0,
    sur_comments: "",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: actionType,
  });
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setCompanyDetail({
        patient_id: patientId,
        location_id: userData?.location_id,
        pat_surg_id: 0,
        sur_type: 0,
        sur_description: "",
        st_date: "",
        end_date: "",
        sur_occurance: 0,
        sur_outcome: 0,
        sur_classification: 0,
        sur_referredby: 0,
        sur_comments: "",
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: actionType,
      });
    } else {
      setCompanyDetail({
        patient_id: patientId,
        location_id: userData?.location_id,
        pat_surg_id: selectclient?.pat_surg_id,
        sur_type: selectclient?.sur_type,
        sur_description: selectclient?.sur_description,
        st_date: moment(selectclient?.st_date).format('YYYY-MM-DD'),
        end_date: moment(selectclient?.end_date).format('YYYY-MM-DD'),
        sur_occurance: selectclient?.sur_occurance,
        sur_outcome: selectclient?.sur_outcome,
        sur_classification: selectclient?.sur_classification,
        sur_referredby: selectclient?.sur_referredby,
        sur_comments: selectclient?.sur_comments,
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: actionType,
      });
    }
  }, [selectclient]);

  const deleteFormData = () => {
    setIsActionCalled(true);
    setCompanyDetail({
      ...companyDetail,
      opt_type: actionType,
    });
  };

  useEffect(() => {
    console.log("isActionCalled", isActionCalled);

    if (isActionCalled) {
      postSurgeryDetails(companyDetail).then((response) => {
        console.log("actionType", actionType);

        console.log(response);
        if (response.status.status) {
          toast.success("Surgery deleted successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });

          onHide();
          setDeleteModalShow(false);
          setOpen(false);
          setIsActionCalled(false);
        } else {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActionCalled(false);
        }
      });
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={deleteFormData}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <img src={alertIcon} alt="img" />
            <Typography className={classes.formActiveHeading}>
              Are you sure! You want to Delete this Surgery?
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
