import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  Typography,
  InputBase,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import * as Icons from "@material-ui/icons";
import {
  MoreVert as MoreIcon,
  Search as SearchIcon,
  Lens,
} from "@material-ui/icons";
import TableWidget from "../Widget/TableWidget";
import TableDesign from "../common/table/TableDesign";
import useStyles from "../Settings/Tax/styles";

import { getAccessories } from "../../services/ServiceLayer";
import { loadState } from "../../hooks/helper";
import classNames from "classnames";
import moment from "moment";
import DeleteFramePopup from "./DeleteFramePopup";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";
import DeleteOtherPopup from "./DeleteOtherPopup";
export default function OtherList() {
  const history = useHistory();
  var classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [isLoader, setIsLoader] = useState(false);

  const [frameListData, setFrameListData] = useState([]);
  const [anchorPosition, setAnchorPosition] = useState(null);
  const openDown = Boolean(anchorEl);
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [selectclient, setselectclient] = useState(null);
  const [title, setTitle] = useState(null);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [selectRow, setSelectRow] = useState(null);
  const [inventoryReadValue, setInventoryReadValue] = useState();
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  var [isSearchOpen, setSearchOpen] = useState(true);
  const [statusFilterModelNo, setStatusFilterModelNo] = useState("");
  const [statusFilterModel, setStatusFilterModel] = useState("");
  const [statusFilterBrand, setStatusFilterBrand] = useState("");
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const readAccessData = loadState().menu;
  useEffect(() => {
    console.log("readAccessData", readAccessData);
    const inventorySection = readAccessData.find(
      (item) => item.label === "Inventory"
    );
    if (inventorySection) {
      const inventoryChildren = inventorySection.children;

      const inventoryReadAcess = inventoryChildren.find(
        (child) => child.label === "Inventory"
      ).read_access;
      setInventoryReadValue(inventoryReadAcess);
    }
  }, [readAccessData]);
  const deleteEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);

    setselectclient(rec);
    setDeleteModalShow(true);
    setTitle("Delete Frame");
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    getAccessories(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELACS`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setIsLoader(false);
        setFrameListData(response?.data);
      } else {
        setIsLoader(false);
      }
    });
  }, []);
  useEffect(() => {
    getAccessories(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELACS`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setIsLoader(false);
        setFrameListData(response?.data);
      } else {
        setIsLoader(false);
      }
    });
    setDataUpdated(false);
  }, [dataUpdated]);
  const handleTopClick = (event) => {
    setAnchorPosition({
      top: event.clientY,
      left: event.clientX,
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorE2(null);
    setAnchorPosition(null);
  };
  const handleView = () => {
    // Handle View
    // setAnchorPosition(null);
    history.push("/app/manage-other");
  };

  const handleViewModify = () => {
    // Handle View Modify
    setAnchorPosition(null);
    history.push("/app/frame-modify");
  };

  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Name ",
      // align: "left !important",
      // secondaryLabel: "(SKU)",
    },
    {
      label: "Manufacturer ",
      secondaryLabel: "(Type)",
    },
    {
      label: "Material ",
      secondaryLabel: "(Color)",
    },
    {
      label: "Feature",
      secondaryLabel: "(Pack Qty.)",
    },
    {
      label: "Unit ",
      secondaryLabel: "(Height)",
    },
    {
      label: "Length",
      secondaryLabel: "(Width)",
    },
    {
      label: "Size ",
      secondaryLabel: "(Return Allowed)",
    },
    {
      label: "Retail Price ",
      secondaryLabel: "(Item Cost)",
    },
    // {
    //   label: "On Hand ",
    //   secondaryLabel: "(Recall Date)",
    // },
    {
      label: "Arrival Date ",
      secondaryLabel: "(Best Seller)",
    },

    // {
    //   label: "Discount ",
    //   secondaryLabel: "(Discount Untill)",
    // },
    {
      label: "Status",
    },
    {
      label: "Action",
    },
  ];
  const handleRowClick = (row) => {
    setSelectedRow(row);
  };

  // Create a function to navigate to the edit page with selected row data
  const handleEditClick = (event) => {
    if (selectedRow) {
      console.log("selectedRow", selectedRow);
      // Use React Router to navigate to the edit page
      history.push(`/app/manage-other/${selectedRow.accessries_id}`, {
        selectedRow,
        event,
      });
    }
  };
  const hideHandle = () => {
    console.log("hidehandle called");

    setOpen(false);

    setDataUpdated(true);
  };
  //searching
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const filteredFrameList = frameListData.filter((item) => {
    // Check if each property is not null or undefined before using toLowerCase()
    const frameName = item.accessries_name ? item.accessries_name.toLowerCase() : "";
    const frameBrand = item.frame_brand ? item.frame_brand.toLowerCase() : "";
    const frameModel = item.frame_model ? item.frame_model.toLowerCase() : "";
    const manufacturerName = item.manufacturer_name
      ? item.manufacturer_name.toLowerCase()
      : "";

    return (
      frameName.includes(searchTerm.toLowerCase()) ||
      frameBrand.includes(searchTerm.toLowerCase()) ||
      frameModel.includes(searchTerm.toLowerCase()) ||
      manufacturerName.includes(searchTerm.toLowerCase())
    );
  });
  const [statusFilter, setStatusFilter] = useState("");
  // Filtering function
  const filterData = () => {
    let filteredData = frameListData;

    if (statusFilter) {
      // Apply status filter if a filter is selected
      filteredData = filteredData.filter(
        (item) => item.is_active.toLowerCase() === statusFilter.toLowerCase()
      );
    }
    if (statusFilterModelNo) {
      // Apply Model No. filter if a value is selected
      filteredData = filteredData.filter(
        (item) =>
          item.frame_name.toLowerCase() === statusFilterModelNo.toLowerCase()
      );
    }

    if (statusFilterModel) {
      // Apply Model filter if a value is selected
      filteredData = filteredData.filter(
        (item) =>
          item.manufacturer_name.toLowerCase() ===
          statusFilterModel.toLowerCase()
      );
    }

    if (statusFilterBrand) {
      // Apply Brand filter if a value is selected
      filteredData = filteredData.filter(
        (item) => item.brand.toLowerCase() === statusFilterBrand.toLowerCase()
      );
    }
    console.log("Filtered Data:", filteredData);
    // Return the filtered data
    return filteredData;
  };
  useEffect(() => {
    const filteredData = filterData();
    setFrameListData(filteredData);
    // Update the filtered data in the state
    // if(filteredData.length>0){
    //   setFrameListData(filteredData);
    // }else{
    //   setFrameListData(frameListData);
    // }
    // Uncomment this line to update the data in the state
  }, [statusFilter]);

  // Assuming frameListData is an array of frame objects
  const uniqueModelNos = [
    ...new Set(frameListData.map((item) => item.frame_name)),
  ];
  const uniqueModels = [
    ...new Set(frameListData.map((item) => item.manufacturer_name)),
  ];
  const uniqueBrands = [...new Set(frameListData.map((item) => item.brand))];
  return (
    <>
      <DeleteOtherPopup
        onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        selectclient={selectclient}
        setDeleteModalShow={setDeleteModalShow}
      />
      <div className={classes.pageTitleContainer}>
        <Typography className={classes.typo} weight="medium">
          Other List
        </Typography>
      </div>

      <div
        className={classes.pageTitleContainer}
        style={{ alignItems: "center" }}
      >
        <Typography className={classes.typo} weight="medium">
          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
            <div
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
              onClick={() => setSearchOpen(!isSearchOpen)}
            >
              <SearchIcon classes={{ root: classes.headerIcon }} />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onChange={handleChange}
            />
          </div>
        </Typography>
        {/* <div>
          <label>Filter by Model No.:</label>
          <select
            value={statusFilterModelNo}
            onChange={(e) => setStatusFilterModelNo(e.target.value)}
          >
            <option value="">All</option>
            {uniqueModelNos.map((modelNo, i) => (
              <option key={i} value={modelNo}>
                {modelNo}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label>Filter by Model:</label>
          <select
            value={statusFilterModel}
            onChange={(e) => setStatusFilterModel(e.target.value)}
          >
            <option value="">All</option>
            {uniqueModels.map((model, i) => (
              <option key={i} value={model}>
                {model}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label>Filter by Brand:</label>
          <select
            value={statusFilterBrand}
            onChange={(e) => setStatusFilterBrand(e.target.value)}
          >
            <option value="">All</option>
            {uniqueBrands.map((brand, i) => (
              <option key={i} value={brand}>
                {brand}
              </option>
            ))}
          </select>
        </div> */}
        <div className={classes.groupBtn}>
          <Tooltip title="Print">
            <Icons.LocalPrintshop />
          </Tooltip>
          <CSVLink
            data={filteredFrameList}
            filename="frame_data.csv"
            className={classes.menuItem}
          >
            <Tooltip title="Download">
              <Icons.Archive />
            </Tooltip>
          </CSVLink>
          <Tooltip title="Mail">
            <Icons.Mail />
          </Tooltip>
          {inventoryReadValue === "N" && (
            <div>
              <IconButton onClick={handleTopClick}>
                <MoreIcon />
              </IconButton>
              <Menu
                className={classes.menuBox}
                anchorReference="anchorPosition"
                anchorPosition={anchorPosition}
                open={Boolean(anchorPosition)}
                onClose={handleClose}
              >
                <MenuItem className={classes.menuItem} onClick={handleView}>
                  <Lens
                    style={{
                      height: "4px",
                      width: "4px",
                      marginRight: "4px",
                    }}
                  />{" "}
                  Add New
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={handleViewModify}
                >
                  <Lens
                    style={{
                      height: "4px",
                      width: "4px",
                      marginRight: "4px",
                    }}
                  />{" "}
                  Import From File
                </MenuItem>
              </Menu>
            </div>
          )}
        </div>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {/* <MaterialReactTable columns={tableHeadings} data={filteredFrameList} /> */}
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {filteredFrameList && filteredFrameList.length ? (
                filteredFrameList.map((row, i) => {
                  if (row)
                    return (
                      <TableRow
                        key={i}
                        className={classes.tableBodyCell}
                        onClick={() => handleRowClick(row)}
                      >
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                          style={{ cursor: "pointer" }}
                        >
                          {row.accessries_name}
                          {/* <br />
                          {row.sku && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.sku})
                            </span>
                          )} */}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {row.manufacturer_name}
                          <br />
                          {row.accessries_type && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.accessries_type})
                            </span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.material_val}
                          <br />
                          {row.accessries_color && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.accessries_color})
                            </span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.feature_val}
                          <br />
                          {row.qty_purchased && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.qty_purchased})
                            </span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.unit_val}
                          <br />
                          {row.height && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.height})
                            </span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.usage}
                          <br />
                          {row.width && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.width})
                            </span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.size_val}
                          <br />
                          {row.return_allowed && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.return_allowed})
                            </span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.retail_price}
                          <br />
                          {row.item_cost && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.item_cost})
                            </span>
                          )}
                        </TableCell>
                        {/* <TableCell className={classes.tableBodyCell}>
                          {row.availability}
                          <br />
                          {row.recall_date && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.recall_date})
                            </span>
                          )}
                        </TableCell> */}
                        <TableCell className={classes.tableBodyCell}>
                          {moment(row.created).format("DD-MM-YYYY")}
                          <br />
                          {row.best_seller && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.best_seller})
                            </span>
                          )}
                        </TableCell>

                        {/* <TableCell className={classes.tableBodyCell}>
                          {row.discount_amt}
                          <br />
                          {row.discount_until && (
                            <span style={{ color: "#4E97FD" }}>
                              ({moment(row.discount_until).format("DD-MM-YYYY")})
                             
                            </span>
                          )}
                        </TableCell> */}
                        <TableCell className={classes.tableBodyCell}>
                          {row.is_active === "Y" ? (
                            <span style={{ color: "green" }}>Active</span>
                          ) : (
                            <span style={{ color: "red" }}>Inactive</span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => handleEditClick("View/Update")}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              {inventoryReadValue === "N" ? (
                                <>View/Update</>
                              ) : (
                                <>View Details</>
                              )}
                            </MenuItem>
                            {inventoryReadValue === "N" && (
                              <>
                                <MenuItem
                                  onClick={() => deleteEventHandler(selectRow)}
                                  className={classes.menuItem}
                                >
                                  <Lens
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                      marginRight: "4px",
                                    }}
                                  />{" "}
                                  {selectRow && selectRow.is_active === "Y" ? (
                                    <>Delete</>
                                  ) : (
                                    <>Re-activate</>
                                  )}
                                </MenuItem>
                                <MenuItem
                                  onClick={() => handleEditClick("Copy")}
                                  className={classes.menuItem}
                                >
                                  <Lens
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                      marginRight: "4px",
                                    }}
                                  />{" "}
                                  Copy
                                </MenuItem>

                                <MenuItem className={classes.menuItem}>
                                  <Lens
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                      marginRight: "4px",
                                    }}
                                  />{" "}
                                  Invoice
                                </MenuItem>

                                <MenuItem className={classes.menuItem}>
                                  <Lens
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                      marginRight: "4px",
                                    }}
                                  />{" "}
                                  Receiving
                                </MenuItem>
                                <MenuItem className={classes.menuItem}>
                                  <Lens
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                      marginRight: "4px",
                                    }}
                                  />{" "}
                                  Returns
                                </MenuItem>
                              </>
                            )}
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
