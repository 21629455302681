import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";

import { dateFormatConversion } from "../../../../../hooks/helper";

import TableWidget from "../../../../Widget/TableWidget";

import TableDesign from "../../../../common/table/TableDesign";
import { TableRow, TableCell } from "@material-ui/core";
// styles
import useStyles from "../../styles";
import moment from "moment";
export default function AppointmentList({
  patientAppointmentData,
  setPatientAppointmentData,
}) {
  console.log("patientAppointmentData",patientAppointmentData);
  const tableHeadings = [
    {
      label: "S. No.",
    },
    {
      label: "Schedule ID",
      align: "left !important",
    },
    {
      label: "Name",
      align: "left !important",
    },
    {
      label: "Start Date & Time",
      align: "left !important",
    },
    {
      label: "Email ID",
      align: "left !important",
    },
    {
      label: "Contact",
      align: "left !important",
    },
    {
      label: "Physician Id",
      align: "left !important",
    },
    {
      label: "Schedule Notes",
      align: "left !important",
    },
  ];

  var classes = useStyles();

  return (
    <div className="container">
      <p style={{ marginLeft: "6px", paddingLeft: "10px", fontWeight: "500" }}>
        Appointment List
      </p>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Attachment",
                tableHeadings,
              }}
            >
              {patientAppointmentData && patientAppointmentData?.length ? (
                patientAppointmentData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell} >
                        <TableCell align="center" className={classes.tableBodyCell} style={{fontSize:'12px', paddingLeft:'10px'}}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" ,fontSize:'12px'}}
                          className={classes.tableBodyCell}
                        >
                          {row.schedule_id}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell} style={{fontSize:'12px'}}>
                          {row.first_name} {row.last_name}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell} style={{fontSize:'12px'}}>
                          {dateFormatConversion(row.start_time) } {moment(row.start_time).format("LT")}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell} style={{fontSize:'12px'}}>
                          {row.email}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell} style={{fontSize:'12px'}}>
                          {row.contact_cell}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell} style={{fontSize:'12px'}}>
                          {row.physician_id}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell} style={{fontSize:'12px'}}>
                          {row.schedule_notes}
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </div>
  );
}
