import React from "react";

import { Grid, TableRow, TableCell } from "@material-ui/core";

import TableWidget from "../../Widget/TableWidget";
import TableDesign from "../../common/table/TableDesign";
import useStyles from "../../Settings/Tax/styles";
// data
import mock from "../frame";

export default function TransactionList() {
  var classes = useStyles();

  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Cost ",
    },
    {
      label: "Group Cost ",
    },
    {
      label: "Source ",
    },
    {
      label: "Consiqnment",
    },
    {
      label: "Purchase ",
    },
    {
      label: "Sold",
    },
    {
      label: "On Hand ",
    },
    {
      label: "On Order",
    },
    {
      label: "Committed",
    },
    {
      label: "Record",
    },
  ];

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {mock.table && mock.table.length ? (
                mock.table.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                          style={{ cursor: "pointer" }}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {row.collection}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.color}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.frametype}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.shape}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.frameusage}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.source}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.retail_price}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.availability}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.arrival_date}
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
