import { constant } from "../hooks/helper";
import DataService from "./DataService";
const { path } = constant;

export const getCalenderData = async (arg) => {
  return await DataService.get(path + "/scheduleData");
};
export const getSchedulebylocations = async (queryParam) => {
  return await DataService.get(path + `/getSchedulebylocations?${queryParam}`);
};

export const postCheckStatus = async (arg) => {
  return await DataService.post(arg, path + "/postCheckStatus");
};

export const loginWithCredential = async (arg) => {
  return await DataService.post(arg, path + "/authentication");
};

export const changePassword = async (arg) => {
  return await DataService.post(arg, path + "/changePassword");
};

export const postPatientData = async (arg) => {
  return await DataService.post(arg, path + "/patientsProfile");
};
export const postPatientAdditionalData = async (arg) => {
  return await DataService.post(arg, path + "/patientsOther");
};

export const postPatientInsuranceData = async (arg) => {
  return await DataService.post(arg, path + "/postInsuranceData");
};

export const postScheduleData = async (arg) => {
  return await DataService.post(arg, path + "/addSchedule");
};

export const getPatientList = async (queryParam) => {
  return await DataService.get(path + `/patientsList?${queryParam}`);
};

export const getPatient = async (queryParam) => {
  return await DataService.get(path + `/patients?${queryParam}`);
};
export const getPatientsSearch = async (queryParam) => {
  return await DataService.get(path + `/patientsSearch?${queryParam}`);
};

export const getPatientSchedule = async (queryParam) => {
  return await DataService.get(path + `/patientSchedule?${queryParam}`);
};

export const getLocationAndResource = async (queryParam) => {
  return await DataService.get(path + `/locationInfo?${queryParam}`);
};

export const getCompanyLocationByCompId = async (queryParam) => {
  return await DataService.get(path + `/getCompanyLocation?${queryParam}`);
};

export const getTodayScheduleList = async (pageNumber) => {
  return await DataService.get(
    path + `/todaysSchedule?pageNumber=${pageNumber}`
  );
};
export const getTodayScheduleCount = async () => {
  return await DataService.get(path + `/todaysScheduleCount`);
};

export const postAttachmentData = async (formData) => {
  return await DataService.postFormData(formData, path + `/postAttachment`);
};


export const postPatientGlyphs = async (formData) => {
  return await DataService.postFormData(formData, path + `/postPatientGlyphs`);
};

export const getPatientAttachment = async (patient_id) => {
  return await DataService.get(path + `/attachment?patient_id=${patient_id}`);
};
export const getPatientFamily = async (queryParam) => {
  return await DataService.get(path + `/getPatientFamily?${queryParam}`);
};
export const getAdditionalDetails = async (patient_id) => {
  return await DataService.get(
    path + `/additionalDetails?patient_id=${patient_id}`
  );
};
export const getAppointmentData = async (queryParam) => {
  return await DataService.get(path + `/scheduleByPatient?${queryParam}`);
};

export const getInsuranceDetails = async (patient_id) => {
  return await DataService.get(
    path + `/insuranceData?patient_id=${patient_id}`
  );
};

export const getCompanyList = async (queryParam) => {
  return await DataService.get(path + `/getCompany?${queryParam}`);
};

export const getschScheduletype = async () => {
  return await DataService.get(path + `/getschScheduletype`);
};

export const getScheduleMetaData = async (queryParam) => {
  return await DataService.get(path + `/getScheduleMetaData?${queryParam}`);
};
export const postScheduleMetaData = async (arg) => {
  return await DataService.post(arg, path + `/postScheduleMetaData`);
};
export const postCompanyData = async (arg) => {
  return await DataService.post(arg, path + `/postCompany`);
};
export const getDoctorList = async (queryParam) => {
  return await DataService.get(path + `/getEmpList?${queryParam}`);
};
export const postDoctorData = async (arg) => {
  return await DataService.post(arg, path + `/postEmployee`);
};
export const getCompanyTax = async (queryParam) => {
  return await DataService.get(path + `/getCompanyTax?${queryParam}`);
};
export const postCompanyTax = async (arg) => {
  return await DataService.post(arg, path + `/postCompanyTax`);
};
export const getCustomData = async (queryParam) => {
  return await DataService.get(path + `/getCustomData?${queryParam}`);
};
export const postCustomData = async (arg) => {
  return await DataService.post(arg, path + `/postCustomData`);
};

export const postFamilyData = async (arg) => {
  return await DataService.post(arg, path + "/addPatientFamily");
};

export const getMetaData = async () => {
  return await DataService.get(path + `/getMetaData`);
};
export const getCompanyStoreData = async (queryParam) => {
  return await DataService.get(path + `/getCompanyAndStore?${queryParam}`);
};
export const getUserGroupAndUser = async () => {
  return await DataService.get(path + `/getUserGroupAndUser`);
};

export const getMenuAndSubmenu = async (queryParam) => {
  return await DataService.get(path + `/getMenuAndSubmenu?${queryParam}`);
};

export const getStaffAvailability = async () => {
  return await DataService.get(path + `/getStaffAvailability`);
};

export const getHistoryConfig = async () => {
  return await DataService.get(path + `/getHistoryConfig`);
};

export const postMenuData = async (arg) => {
  return await DataService.post(arg, path + "/postMenuData");
};

export const getUserMenuDataList = async (queryParam) => {
  return await DataService.get(path + `/getUserMenuDataList?${queryParam}`);
};

export const getHistoryConfigQuestion = async () => {
  return await DataService.get(path + `/getHistoryConfig`);
};

export const getMenuAccessPerUser = async (queryParam) => {
  return await DataService.get(path + `/menuAccessPerUser?${queryParam}`);
};

export const postFamilyQuestionnairs = async (arg) => {
  return await DataService.post(arg, path + `/postFamilyQuestionnaire`);
};

export const getDashboardCountByDate = async (queryParam) => {
  return await DataService.get(
    path + `/todaysScheduleCountWithDate?${queryParam}`
  );
};
export const todaysScheduleWithDate = async (queryParam) => {
  return await DataService.get(path + `/todaysScheduleWithDate?${queryParam}`);
};

export const getScheduleWithDate = async (queryParam) => {
  return await DataService.get(path + `/getScheduleWithDate?${queryParam}`);
};

// Get Inventory List
export const getInvtService = async (queryParam) => {
  return await DataService.get(path + `/invt_service?${queryParam}`);
};

export const getinvtFrame = async (queryParam) => {
  return await DataService.get(path + `/invt_frame?${queryParam}`);
};

export const getInvtSpectacle = async (queryParam) => {
  return await DataService.get(path + `/invt_spectacle?${queryParam}`);
};

export const getInvtContactLense = async (queryParam) => {
  return await DataService.get(path + `/invt_contactlense?${queryParam}`);
};

//
export const postInvtcontactLense = async (arg) => {
  return await DataService.post(arg, path + `/invt_contactlense`);
};

export const postServiceLense = async (arg) => {
  return await DataService.post(arg, path + `/invt_service`);
};

export const postFrameLense = async (arg) => {
  return await DataService.post(arg, path + `/invt_frame`);
};

export const postSpectacleLense = async (arg) => {
  return await DataService.post(arg, path + `/invt_spectacle`);
};

export const getProcedureCode = async (queryParam) => {
  return await DataService.get(path + `/procedure_code_master?${queryParam}`);
};

export const getServiceMaster = async (queryParam) => {
  return await DataService.get(path + `/structure_master?${queryParam}`);
};

export const getSupplierDetail = async (queryParam) => {
  return await DataService.get(path + `/supplier_detail?${queryParam}`);
};

export const getDashboardInventory = async (queryParam) => {
  return await DataService.get(path + `/dashboard_inventory?${queryParam}`);
};
export const getManufacturerDetail = async (queryParam) => {
  return await DataService.get(path + `/manufacturer_detail?${queryParam}`);
};

export const getProblemCode = async (queryParam) => {
  return await DataService.get(path + `/problem_code_master?${queryParam}`);
};

export const  getFrameFilter = async (queryParam) => {
  return await DataService.get(path + `/invt_frame_filter?${queryParam}`);
};

export const getSpectacleService = async (queryParam) => {
  return await DataService.get(path + `/invt_spectacle_service?${queryParam}`);
};
export const getAccessories = async (queryParam) => {
  return await DataService.get(path + `/invt_accessories?${queryParam}`);
};

export const getOrder = async (queryParam) => {
  return await DataService.get(path + `/getOrder?${queryParam}`);
};

export const postAccessories = async (arg) => {
  return await DataService.post(arg, path + `/invt_accessories`);
};
// order
export const postFrameOrder = async (arg) => {
  return await DataService.post(arg, path + `/frame_order`);
};

export const getMetaDataList = async (queryParam) => {
  return await DataService.get(path + `/getmetadata?${queryParam}`);
};

export const postMetaData = async (arg) => {
  return await DataService.post(arg, path + `/add_metadata`);
};

export const getLabDetail = async (queryParam) => {
  return await DataService.get(path + `/lab_detail?${queryParam}`);
};
export const get_invt_contactlense_dtl = async (queryParam) => {
  return await DataService.get(path + `/get_invt_contactlense_dtl?${queryParam}`);
};
// exam apis
export const getExamProblemData = async (queryParam) => {
  return await DataService.get(path + `/getExamProblem?${queryParam}`);
};

export const postProblemData = async (arg) => {
  return await DataService.post(arg, path + `/postPatientProblem`);
};

export const getProblemHistoryData = async (queryParam) => {
  return await DataService.get(path + `/getPatientProblem?${queryParam}`);
};

export const getMedicineListData = async (queryParam) => {
  return await DataService.get(path + `/getMedicineList?${queryParam}`);
};
export const postMedicineData = async (arg) => {
  return await DataService.post(arg, path + `/postPatientMedicine`);
};
export const getAllergyHistoryData = async (queryParam) => {
  return await DataService.get(path + `/getPatientAllergy?${queryParam}`);
};

export const postAllergyData = async (arg) => {
  return await DataService.post(arg, path + `/postPatientAllergy`);
};
export const getVisitReasonData = async (queryParam) => {
  return await DataService.get(path + `/getPatientVisitReasons?${queryParam}`);
};
export const postVisitReasonData = async (arg) => {
  return await DataService.post(arg, path + `/postExamVisitReason`);
};
export const getVisitReasonDropdown = async (queryParam) => {
  return await DataService.get(path + `/getPatientVisitReason?${queryParam}`);
};

export const getVitalHistoryData = async (queryParam) => {
  return await DataService.get(path + `/getPatientVitals?${queryParam}`);
};
export const postVitalData = async (arg) => {
  return await DataService.post(arg, path + `/postPatientVitals`);
};
export const postPatientDisclosure = async (arg) => {
  return await DataService.post(arg, path + `/postPatientDisclosure`);
};
export const getPatientDisclosureData = async (queryParam) => {
  return await DataService.get(path + `/getPatientDisclosure?${queryParam}`);
};

export const postPatientOcularHistory = async (arg) => {
  return await DataService.post(arg, path + `/postPatientOcularHistory`);
};

export const getPatientOcularHistory = async (queryParam) => {
  return await DataService.get(path + `/getPatientsOcularData?${queryParam}`);
};

export const postSurgeryDetails = async (arg) => {
  return await DataService.post(arg, path + `/postPatientSurgeries`);
};

export const getSurgeryDetails = async (queryParam) => {
  return await DataService.get(path + `/getPatientSurgeries?${queryParam}`);
};

export const getFamilyHistory = async (queryParam) => {
  return await DataService.get(path + `/getPatientFamilyHistory?${queryParam}`);
};
export const postFamilyHistory = async (arg) => {
  return await DataService.post(arg, path + `/postPatientFamilyHistory`);
};

export const getMedicalHistory = async (queryParam) => {
  return await DataService.get(path + `/getPatientMedicalHistory?${queryParam}`);
};
export const postMedicalHistory = async (arg) => {
  return await DataService.post(arg, path + `/postPatientMedicalHistory`);
};

export const getMedicationList = async (queryParam) => {
  return await DataService.get(path + `/getPatientMedicationHistory?${queryParam}`);
};

export const getSubstanceDetails = async (queryParam) => {
  return await DataService.get(path + `/getPatientSubstance?${queryParam}`);
};

export const postSubstanceDetails = async (arg) => {
  return await DataService.post(arg, path + `/postPatientSubstance`);
};

export const postInternalExam = async (arg) => {
  return await DataService.post(arg, path + `/postPatientInternal`);
};
export const getInternalExam = async (queryParam) => {
  return await DataService.get(path + `/getPatientInternal?${queryParam}`);
};
// external
export const getPatientExternalEs = async (queryParam) => {
  return await DataService.get(path + `/getPatientExternalEs?${queryParam}`);
};
export const postExternalExam = async (arg) => {
  return await DataService.post(arg, path + `/postPatientExternalEywLid`);
};
export const postExternalIop = async (arg) => {
  return await DataService.post(arg, path + `/postPatientExternalIop`);
};
export const getPatientExternalIop = async (queryParam) => {
  return await DataService.get(path + `/getPatientExternalIop?${queryParam}`);
};
export const postExternalDialation = async (arg) => {
  return await DataService.post(arg, path + `/postPatientExternalDil`);
};
export const getPatientExternalDil = async (queryParam) => {
  return await DataService.get(path + `/getPatientExternalDil?${queryParam}`);
};
export const postPatientExternalpch = async (arg) => {
  return await DataService.post(arg, path + `/postPatientExternalpch`);
};
export const postExternalPachymetry = async (arg) => {
  return await DataService.post(arg, path + `/postPatientExternalIop`);
};
export const getPatientExternalPch = async (queryParam) => {
  return await DataService.get(path + `/getPatientExternalPch?${queryParam}`);
};
export const postExternalNotes = async (arg) => {
  return await DataService.post(arg, path + `/postPatientExternalNotes`);
};
export const getPatientExternalNt = async (queryParam) => {
  return await DataService.get(path + `/getPatientExternalNt?${queryParam}`);
};
export const postPatientPupil = async (arg) => {
  return await DataService.post(arg, path + `/postPatientPupil`);
};
export const getPatientExternalPupils = async (queryParam) => {
  return await DataService.get(path + `/getPatientExternalPupils?${queryParam}`);
};
export const postVisualAcuity = async (arg) => {
  return await DataService.post(arg, path + `/postPatientVisualACuity`);
};

export const postEmployeeAttendance = async (arg) => {
  return await DataService.post(arg, path + `/postEmployeeAttendance`);
};

export const getEmployeeAttendance = async (queryParam) => {
  return await DataService.get(path + `/getEmployeeAttendance?${queryParam}`);
};
export const getVisualAcuity = async (queryParam) => {
  return await DataService.get(path + `/getPatientVisualAcuity?${queryParam}`);
};

export const postRefractiveData = async (arg) => {
  return await DataService.post(arg, path + `/postPatientRefractive`);
};
export const getRefractiveData = async (queryParam) => {
  return await DataService.get(path + `/getPatientRefractive?${queryParam}`);
};

export const postOtherRefractiveData = async (arg) => {
  return await DataService.post(arg, path + `/postPatientRefractiveNotes`);
};
export const getOtherRefractiveData = async (queryParam) => {
  return await DataService.get(path + `/getPatientRefractiveNotes?${queryParam}`);
};

export const postFinalRefractiveData = async (arg) => {
  return await DataService.post(arg, path + `/postPatientRefractiveFinal`);
};

export const getFinalRefractiveData = async (queryParam) => {
  return await DataService.get(path + `/getPatientRefractiveFinal?${queryParam}`);
};
export const postBinoTestData = async (arg) => {
  return await DataService.post(arg, path + `/postPatientBinocularTest`);
};

export const getBinoTestData = async (queryParam) => {
  return await DataService.get(path + `/getPatientsBinocularTest?${queryParam}`);
};

export const postAddBinoTestData = async (arg) => {
  return await DataService.post(arg, path + `/postPatientBinocularAdTest`);
};

export const getAddBinoTestData = async (queryParam) => {
  return await DataService.get(path + `/getPatientsBinocularAddTest?${queryParam}`);
};
export const postPatientInteraction = async (arg) => {
  return await DataService.post(arg, path + `/postPatientInteraction`);
};

export const getPatientInteraction = async (queryParam) => {
  return await DataService.get(path + `/getPatientInteraction?${queryParam}`);
};
//  Exam Contact APi start
export const postContactPrx = async (arg) => {
  return await DataService.post(arg, path + `/postPatientContactPR`);
};
export const getPatientContactPR = async (queryParam) => {
  return await DataService.get(path + `/getPatientContactPR?${queryParam}`);
};
export const postContactFinal = async (arg) => {
  return await DataService.post(arg, path + `/postPatientContactFR`);
};
export const getPatientContactFR = async (queryParam) => {
  return await DataService.get(path + `/getPatientContactFR?${queryParam}`);
};

export const postContactTrial = async (arg) => {
  return await DataService.post(arg, path + `/postPatientContactTR`);
};
export const getPatientContactTR = async (queryParam) => {
  return await DataService.get(path + `/getPatientContactTR?${queryParam}`);
};

export const getPatientContactAD = async (queryParam) => {
  return await DataService.get(path + `/getPatientContactAD?${queryParam}`);
};
// Exam contact API end
// Exam Assessment API start
export const postPatientAssessmentReferral = async (arg) => {
  return await DataService.post(arg, path + `/postPatientAssessmentReferral`);
};
export const getPatientAssessmentReferral = async (queryParam) => {
  return await DataService.get(path + `/getPatientAssessmentReferral?${queryParam}`);
};
export const postPatientAssessmentNotes = async (arg) => {
  return await DataService.post(arg, path + `/postPatientAssessmentNotes`);
};
export const getPatientAssessmentNotes = async (queryParam) => {
  return await DataService.get(path + `/getPatientAssessmentNotes?${queryParam}`);
};
export const postPatientAssessmentFollowup = async (arg) => {
  return await DataService.post(arg, path + `/postPatientAssessmentFollowup`);
};
export const getPatientAssessmentFollowup = async (queryParam) => {
  return await DataService.get(path + `/getPatientAssessmentFollowup?${queryParam}`);
};
export const postPatientAssessmentDiagnosis = async (arg) => {
  return await DataService.post(arg, path + `/postPatientAssessmentDiagnosis`);
};
export const getPatientAssessmentDiagnosis = async (queryParam) => {
  return await DataService.get(path + `/getPatientAssessmentDiagnosis?${queryParam}`);
};
export const postPatientContactAD = async (arg) => {
  return await DataService.post(arg, path + `/postPatientContactAD`);
};

export const getExamList = async (queryParam) => {
  return await DataService.get(path + `/getExamList?${queryParam}`);
};
// Exam Assessment API end
export const getCityStateByZip = async (queryParam) => {
  return await DataService.get(path + `/getCityState?${queryParam}`);
};