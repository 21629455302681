import React, { useState, useEffect } from "react";

// Model
import {
  Button,
  Typography,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Popup from "../../common/modal/Popup";
import moment from "moment";
import alertIcon from "../alertIcon.svg";
// styles
import useStyles from "./styles";
import { toast } from "react-toastify";
import { postFrameOrder, postInvtcontactLense } from "../../../services/ServiceLayer";
import { currentUser, loadState } from "../../../hooks/helper";

export default function DeleteOrderPopup({
  onHide,
  deleteModalShow,
  setDeleteModalShow,
  selectclient,setRefreshList
}) {
  console.log("selectclient", selectclient);
  console.log("deleteModalShow", deleteModalShow);
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const [actionType, setActionType] = useState("DELORD");
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [isActionCalled, setIsActionCalled] = useState(false);
  toast.configure();
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setDeleteModalShow(false);
    setOpen(false);
  };
  const currentDate = moment().format("YYYY-MM-DD");
  const [companyDetail, setCompanyDetail] = useState({
    order_id: 0,
    location_id: userData?.location_id,
    patient_id: "",
    
    physician_id: "",
    order_type: "",
    order_date: currentDate,
    employee_id: "",
    diagonsis_id: "",
    prescription_id: "",
    patient_due: "",
    insurance_due: "",
    insurance_paid: "",
    order_status: "",
    supplier_id: "",
    exam_id: "",
    ship_to: "",
    frame_id: "",
    frame_src:"",
    contlens_id: "",
    contlens_dtl_id: "",
    spec_id: "",
    canceled_reasonid: "",
    canceled_date: currentDate,
    total_order_value: "",
    total_order_value_discount: "",
    total_order_value_tax: "",
    notes: "" /* Service notes* */,
    order_item_id: "",
    item_type: "",
    item_id: "",
    quantity: "",
    item_discount: "",
    item_insurance_due: "",
    item_insurance_paid: "",
    item_patient_due: "",
    item_canceled_reasonid: "",
    item_canceled_date: "",
    item_total_order_value: "",
    item_order_value_discount: "",
    item_order_value_tax: "",
    lab_id: "",
    promised_date: "",
    lab_order_id: "",
    lab_order_status: "",
    order_inspected_by_id: "",
    order_delevered_by_id: "",
    order_reprocess_reason: "",
    shipping_address_1: "",
    shipping_address_2: "",
    state: "",
    city: "",
    zip: "",
    item_notes: "",
    updated_by: "",
    user_role: userRole,
    opt_type: actionType,
  });
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setCompanyDetail({
        order_id: 0,
        location_id: userData?.location_id,
        patient_id: "",
        
        physician_id: "",
        order_type: "",
        order_date: currentDate,
        employee_id: "",
        diagonsis_id: "",
        prescription_id: "",
        patient_due: "",
        insurance_due: "",
        insurance_paid: "",
        order_status: "",
        supplier_id: "",
        exam_id: "",
        ship_to: "",
        frame_id: "",
        frame_src:"",
        contlens_id: "",
        contlens_dtl_id: "",
        spec_id: "",
        canceled_reasonid: "",
        canceled_date: currentDate,
        total_order_value: "",
        total_order_value_discount: "",
        total_order_value_tax: "",
        notes: "" /* Service notes* */,
        order_item_id: "",
        item_type: "",
        item_id: "",
        quantity: "",
        item_discount: "",
        item_insurance_due: "",
        item_insurance_paid: "",
        item_patient_due: "",
        item_canceled_reasonid: "",
        item_canceled_date: "",
        item_total_order_value: "",
        item_order_value_discount: "",
        item_order_value_tax: "",
        lab_id: "",
        promised_date: "",
        lab_order_id: "",
        lab_order_status: "",
        order_inspected_by_id: "",
        order_delevered_by_id: "",
        order_reprocess_reason: "",
        shipping_address_1: "",
        shipping_address_2: "",
        state: "",
        city: "",
        zip: "",
        item_notes: "",
        updated_by: "",
        user_role: userRole,
        opt_type: actionType,
      });
    } else {
      setCompanyDetail({
        order_id: selectclient?.order_id,
        location_id: userData?.location_id,
        patient_id: selectclient?.patient_id,
        physician_id: selectclient?.physician_id === undefined ? 0 : selectclient?.physician_id,
        order_type: selectclient?.order_type,
        order_date: currentDateTime,
        employee_id: selectclient?.employee_id === undefined ? 0 : selectclient?.employee_id,
        diagonsis_id: selectclient?.diagonsis_id === undefined ? 0 : selectclient?.diagonsis_id,
        prescription_id: selectclient?.prescription_id === undefined ? 0 : selectclient?.prescription_id,
        patient_due: selectclient?.patient_due === undefined ? '' : selectclient?.patient_due,
        insurance_due: selectclient?.insurance_due === undefined ? '' : selectclient?.insurance_due,
        insurance_paid: selectclient?.insurance_paid === undefined ? '' : selectclient?.insurance_paid,
        order_status: selectclient?.order_status,
        supplier_id: selectclient?.supplier_id === undefined ? 0 : selectclient?.supplier_id,
        exam_id: selectclient?.exam_id === undefined ? 0 : selectclient?.exam_id,
        ship_to: selectclient?.ship_to === undefined ? '0' : selectclient?.ship_to,
        frame_id: selectclient?.frame_id === undefined ? 0 : selectclient?.frame_id,
        contlens_id: selectclient?.contlens_id === undefined ? 0 : selectclient?.contlens_id,
        contlens_dtl_id: selectclient?.contlens_dtl_id === undefined ? 0 : selectclient?.contlens_dtl,
        spec_id: selectclient?.spec_id === undefined ? 0 : selectclient?.spec_id,
        canceled_reasonid: selectclient?.canceled_reasonid ===undefined ? 0 : selectclient?.canceled_reasonid,
        canceled_date: currentDateTime,
        total_order_value: selectclient?.total_order_value ===undefined ? "" : selectclient?.total_order_value,
        total_order_value_discount: selectclient?.total_order_value_discount ===undefined ? "" : selectclient?.total_order_value,
        total_order_value_tax: selectclient?.total_order_value_tax ===undefined ? "" : selectclient?.total_order_value,
        notes: selectclient?.notes === undefined ? "" : selectclient?.notes,
        order_item_id: selectclient?.order_item_id === undefined ? 0 : selectclient?.order_item_id,
        item_type: selectclient?.item_type === undefined ? "" : selectclient?.item_type,
        item_id: selectclient?.item_id === undefined ? 0 : selectclient?.item_id,
        quantity: selectclient?.quantity === undefined ? '' : selectclient?.quantity,
        item_discount: selectclient?.item_discount === undefined ? '' : selectclient?.item_discount,
        item_insurance_due: selectclient?.item_insurance_due === undefined ? '' : selectclient?.item_insurance_due,
        item_insurance_paid: selectclient?.item_insurance_paid === undefined ? '' : selectclient?.item_insurance_paid,
        item_patient_due: selectclient?.item_patient_due === undefined ? '' : selectclient?.item_patient_due,
        item_canceled_reasonid: selectclient?.item_canceled_reasonid === undefined ? 0 : selectclient?.item_canceled_reasonid,
        item_canceled_date: selectclient?.item_canceled_date === undefined ? '2032-12-31' : selectclient?.item_canceled_date,
        item_total_order_value: selectclient?.item_total_order_value === undefined ? '' : selectclient?.item_total_order_value,
        item_order_value_discount: selectclient?.item_order_value_discount === undefined ? '' : selectclient?.item_order_value_discount,
        item_order_value_tax: selectclient?.item_order_value_tax === undefined ? '' : selectclient?.item_order_value_tax,
        lab_id:
        selectclient?.lab_id === undefined ? "" : selectclient?.lab_id,
        promised_date:
        selectclient?.promised_date === undefined
            ? "2032-12-31"
            : moment(selectclient?.promised_date).format("YYYY-MM-DD"),
        lab_order_id:
        selectclient?.lab_order_id === undefined
            ? ""
            : selectclient?.lab_order_id,
        lab_order_status:
        selectclient?.lab_order_status === undefined
            ? ""
            : selectclient?.lab_order_status,
        order_inspected_by_id:
        selectclient?.order_inspected_by_id === undefined
            ? ""
            : selectclient?.order_inspected_by_id,
        order_delevered_by_id:
        selectclient?.order_delevered_by_id === undefined
            ? ""
            : selectclient?.order_delevered_by_id,
        order_reprocess_reason:
        selectclient?.order_reprocess_reason === undefined
            ? ""
            : selectclient?.order_reprocess_reason,
        shipping_address_1:
        selectclient?.shipping_address_1 === undefined
            ? ""
            : selectclient?.shipping_address_1,
        shipping_address_2:
        selectclient?.shipping_address_2 === undefined
            ? ""
            : selectclient?.shipping_address_2,
        state: selectclient?.state === undefined ? "" : selectclient?.state,
        city: selectclient?.city === undefined ? "" : selectclient?.city,
        zip: selectclient?.zip === undefined ? "" : selectclient?.zip,
        item_notes: selectclient?.item_notes === undefined ? '' : selectclient?.item_notes,
        user_role: userRole,
        updated_by: currentUser(),
        updated_dt: "",
        access_location: " ",
        opt_type: actionType,
      });
    }
  }, [selectclient]);

  const deleteFormData = () => {
    setIsActionCalled(true);
    setCompanyDetail({
      ...companyDetail,
      opt_type: actionType,
    });
  };

  useEffect(() => {
    console.log("isActionCalled", isActionCalled);

    if (isActionCalled) {
      postFrameOrder(companyDetail).then((response) => {
        console.log("actionType", actionType);

        console.log(response);
        if (response.status.status) {
          toast.success("Order deleted successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });

          onHide();
          setDeleteModalShow(false);
          setOpen(false);
          setIsActionCalled(false);
          setRefreshList(true);
        } else {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActionCalled(false);
        }
      });
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={deleteFormData}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <img src={alertIcon} alt="img" />
            <Typography className={classes.formActiveHeading}>
              Are you sure! You want to Cancel this Order?
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
