import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Fade,
} from "@material-ui/core";
import { formElements } from "./Patient_Constant";

import moment from "moment";
import useStyles from "./styles";
export default function LatestBilling({
  patientData,
  setPatientData,
  setIsProfileStateChanged
}) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.formBox}>
      {formElements.map((element, i) => {
        return (
          <>
            {/* {Array.isArray(patientData) && ( */}
            <>
              {patientData ? (
                <Grid
                  item
                  xs={12}
                  md={element.mdSize}
                  className={classes.textGroup}
                >
                  <Typography className={classes.formHeading}>
                    {element.label}{" "}
                    {element.required === "true" ? (
                      <span className={classes.required}>*</span>
                    ) : null}
                  </Typography>

                  {element.type === "select" ? (
                    <FormControl
                      fullWidth
                      className={classes.dropdownFormControl}
                    >
                      <Select disabled
                        name={element.key}
                        className={classes.selectDropdown}
                        placeholder={element.placeHolder}
                        value={
                          patientData && patientData[element.key] === null
                            ? ""
                            : patientData && patientData[element.key]
                        }
                        onChange={async (e) => {
                          setPatientData({
                            ...patientData,
                            [element.key]: e.target.value,
                          });
                        }}
                      >
                        <MenuItem disabled value="">Select</MenuItem>
                        {element.options.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : element.type === "text" ? (
                    <TextField disabled
                      required
                      name={element.key}
                      placeholder={element.placeHolder}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      inputProps={{
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                        maxLength: element.maxLength,

                        onKeyPress: element.onKeyPress, // Add the event handler
                      }}
                      value={
                        patientData && patientData[element.key] === null
                          ? ""
                          : patientData && patientData[element.key]
                      }
                      onChange={async (e) => {
                        setPatientData({
                          ...patientData,
                          [element.key]: e.target.value,
                        });
                      }}
                    />
                  ) : element.type === "date" ||
                    element.type === "datetime-local" ? (
                    <TextField disabled
                      id={element.key}
                      name={element.key}
                      placeholder={element.placeHolder}
                      type={element.type}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      inputProps={{
                        min: `${currentDateTime}`,
                        max: `${currentDate}`,
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        patientData && patientData[element.key] === null
                          ? ""
                          : patientData && patientData[element.key]
                      }
                      onChange={async (e) => {
                        setPatientData({
                          ...patientData,
                          [element.key]: e.target.value,
                        });
                      }}
                      onKeyDown={(e) => {
                        e.preventDefault(); // Prevent manual typing
                      }}
                    />
                  ) : (
                    <TextField disabled
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      placeholder="Type here..."
                      variant="outlined"
                      multiline
                      rows={3}
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      value={
                        patientData && patientData[element.key] === null
                          ? ""
                          : patientData && patientData[element.key]
                      }
                      onChange={async (e) => {
                        setPatientData({
                          ...patientData,
                          [element.key]: e.target.value,
                        });
                      }}
                    />
                  )}
                </Grid>
              ) : null}
            </>
            {/* )} */}
          </>
        );
      })}
    </Grid>
  );
}
