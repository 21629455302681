import React, { useEffect, useState } from "react";

import {
  Grid,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Select,
  FormControl,
  FormGroup,
  Checkbox,
  Button,
} from "@material-ui/core";
// data

// Model
import { notesForm } from "./External_Constant";
import useStyles from "../styles";
// components

import moment from "moment";
import { currentUser, loadState } from "../../../hooks/helper";
import { toast } from "react-toastify";
import {
  getPatientExternalNt,
  postExternalNotes,
} from "../../../services/ServiceLayer";

export default function Notes({ patientId,setNotesBtnUnable,notesBtnUnable }) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [actionType, setActionType] = useState("ADDEXT");
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [notesDetails, setNotesDetails] = useState({
    pat_extnt_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    internal_notes: "",
    external_notes: "",
    updated_by: currentUser(),
    access_location: "Y",
    opt_type: "ADDEXT",
  });
  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    // if (!isError() && error !== null && isActionCalled) {
    if (isActionCalled) {
      postExternalNotes(notesDetails).then((response) => {
        console.log(response);
        if (response?.status?.status) {
          toast.success("Notes added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setNotesBtnUnable(false);
          // props.onHide();
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    // } else {
    //   setIsActionCalled(false);
    // }

    setIsActionCalled(false);
  }, [isActionCalled]);

  const [examNotesData, setExamNotesData] = useState([]);
  useEffect(() => {
    getPatientExternalNt(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELEXT`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setExamNotesData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);
  const [discardUpdate, setDiscardUpdate] = useState(false);
  useEffect(() => {
    if (examNotesData && examNotesData.length > 0) {
      setNotesDetails({
        pat_extnt_id: examNotesData[0]?.pat_extnt_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        internal_notes: examNotesData[0]?.internal_notes,
        external_notes: examNotesData[0]?.external_notes,
        updated_by: currentUser(),
        access_location: "Y",
        opt_type: "UPDEXT",
      });
      setDiscardUpdate(false);
    }
  }, [examNotesData,discardUpdate]);
  const clearForm=()=>{
    setNotesDetails({
      pat_extnt_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    internal_notes: "",
    external_notes: "",
    updated_by: currentUser(),
    access_location: "Y",
    opt_type: "ADDEXT",
    })
    
  }
  const[showDiscard, setShowDiscard] = useState(false);

  const discardHandler = ()=>{
    if(examNotesData && examNotesData.length>0){
      setNotesBtnUnable(false);
      setDiscardUpdate(true);
    }else{
      clearForm();
      setNotesBtnUnable(false);
    }
   
  }
  return (
    <>
      {/* <TableWidget noBodyPadding> */}
      <Grid
        container
        spacing={2}
        className={classes.formBox}
        // style={{ border: "1px solid #DED9D9" }}
      >
        {notesForm.map((element, i) => {
          return (
            <>
              <Grid
                item
                xs={12}
                md={element.mdSize}
                className={classes.textGroup}
                // style={{ border: "1px solid #DED9D9" }}
              >
                {element.label && (
                  <Typography
                    className={classes.formHeading}
                    style={{ fontWeight: "500", color: "#4E97FD" }}
                  >
                    {element.label}{" "}
                    {element.required === "true" ? (
                      <span className={classes.required}>*</span>
                    ) : null}
                  </Typography>
                )}

                {element.type ? (
                  <>
                    {element.type === "select" ? (
                      <FormControl
                        fullWidth
                        className={classes.dropdownFormControl}
                      >
                        <Select
                          name={element.key}
                          className={classes.selectDropdown}
                          placeholder={element.placeHolder}
                        >
                          <MenuItem value="">Select</MenuItem>
                          {element.options.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : element.type === "text" ? (
                      <TextField
                        required
                        name={element.key}
                        placeholder={element.placeHolder}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    ) : element.type === "date" ||
                      element.type === "datetime-local" ? (
                      <TextField
                        id={element.key}
                        name={element.key}
                        placeholder={element.placeHolder}
                        type={element.type}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          min: `${currentDateTime}`,
                          max: `${currentDate}`,
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    ) : element.type === "radio" ? (
                      <RadioGroup row defaultValue="">
                        {element.radioValue.map((item) => (
                          <FormControlLabel
                            size="small"
                            style={{ padding: "0px 6px" }}
                            value={item}
                            control={<Radio />}
                            label={item}
                            className={classes.smallRadioButton}
                          />
                        ))}
                      </RadioGroup>
                    ) : element.type === "checkbox" ? (
                      <>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              value={element.value}
                              control={<Checkbox />}
                              label={element.lbltitle}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </FormControl>
                      </>
                    ) : (
                      <TextField
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        placeholder="Type here..."
                        variant="outlined"
                        multiline
                        rows={3}
                        inputProps={{
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        value={
                          notesDetails && notesDetails[element.key] === null
                            ? ""
                            : notesDetails && notesDetails[element.key]
                        }
                        onChange={async (e) => {
                          setNotesDetails({
                            ...notesDetails,
                            [element.key]: e.target.value,
                          });
                          setNotesBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Typography
                      className={classes.formHeading}
                      style={{
                        fontWeight: "600",

                        color: "#4E97FD",
                      }}
                    >
                      {element.title}
                    </Typography>
                  </>
                )}
              </Grid>
            </>
          );
        })}
        <Grid className={classes.btnGroup} item xs={12} style={{ display: "flex", justifyContent: "end" }}>
        {showDiscard && (
            <Button
              className={classes.btnleftOutline}
              variant="outlined"
              style={{ marginRight: "10px", fontSize: "12px" }}
              size="medium"
              onClick={discardHandler}
            >
              Discard
            </Button>
          )}
          <Button disabled={!notesBtnUnable}
            style={{ display: "flex", float: "right" }}
            onClick={handleSubmit}
            className={classes.btnlRightDark}
            variant="contained"
            size="medium"
            color="secondary"
          >
            Save
          </Button>
        </Grid>
      </Grid>

      {/* </TableWidget> */}
    </>
  );
}
