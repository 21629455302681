export const manageAdminSchema = {

    name: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter admin name"
        }
    },
    adminEmail: {
        type: "email",
        required: true,
        properties: {
            empty: "Please enter email",
            email: "Please enter a valid Email"
        }
    },
    organization: {
        type: "string",
        required: true,
        properties: {
            empty: "Please select organization",
        }
    },
    newPassword: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter password",
            // length: 8,
            // lowercase: true,
            // uppercase: true,
            // special: true,
        }
    },
    confirmPassword: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter password",
            minLength: {
                limit: 8,
                msg: "Password must be minimum 8 length",
            },
            match: "Passwords in the two sections must match" //"Password should be match"
        }
    },
    
}