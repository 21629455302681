import React, { useState, useEffect } from "react";

// Model
import {
  Button,
  Typography,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Popup from "../../../common/modal/Popup";
import alertIcon from "../../../../assets/alertIcon.svg";
// styles
import useStyles from "../styles";
import { toast } from "react-toastify";
import { postFamilyData } from "../../../../services/ServiceLayer";
import { currentUser } from "../../../../hooks/helper";

currentUser()
export default function DeletePopup({
  onHide,
  deleteModalShow,
  setDeleteModalShow,
  selectclient,
}) {
  console.log("selectclient", selectclient);
  console.log("deleteModalShow", deleteModalShow);

  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const [actionType, setActionType] = useState("DELREL");

  const [isActionCalled, setIsActionCalled] = useState(false);
  toast.configure();
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setDeleteModalShow(false);
    setOpen(false);
  };
  const [companyDetail, setCompanyDetail] = useState({
    patient_id: 0,
    patient_parent_id: 0,
    patient_rel_id: '',
    is_active: "Y",
    updated_by: currentUser(),
    access_location: "",
    opt_type: actionType,
  });
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setCompanyDetail({
        patient_id: 0,
        patient_parent_id: 0,
        patient_rel_id: '',
        is_active: "Y",
        updated_by: '',
        access_location: "",
        opt_type: "DELREL",
      });
    } else {
      setCompanyDetail({
        patient_id: selectclient !== null ? selectclient.patient_id : 0,
        patient_parent_id: selectclient !== null ? selectclient.patient_parent_id : 0,
        patient_rel_id: selectclient !== null ? selectclient.patient_rel_id : "",
        is_active: "Y",
        updated_by: currentUser(),
        access_location: "1.1.1.1",
        opt_type: actionType,
      });
    }
  }, [selectclient]);

  const deleteFormData = () => {
    setIsActionCalled(true);
    setCompanyDetail({
      ...companyDetail,
      opt_type: "DELREL",
    });
  };

  useEffect(() => {
    console.log("isActionCalled", isActionCalled);

    if (isActionCalled) {
      postFamilyData(companyDetail).then((response) => {
        console.log("actionType", actionType);

        console.log(response);
        if (response.status.status) {
          toast.success("Family data deleted successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });

          onHide();
          setDeleteModalShow(false);
          setOpen(false);
          setIsActionCalled(false);
        } else {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActionCalled(false);
        }
      });
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={deleteFormData}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <img src={alertIcon} />
            <Typography className={classes.formActiveHeading}>
              Are you sure! You want to Remove this Family Member?
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
