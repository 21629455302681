import React, { useState, useEffect } from "react";
import { Grid, Menu, MenuItem, Typography } from "@material-ui/core";

import { dateFormatConversion } from "../../../hooks/helper";

import TableWidget from "../../Widget/TableWidget";

import TableDesign from "../../common/table/TableDesign";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
// styles
import useStyles from "../styles";
import { Button } from "@mui/material";

import { loadState } from "../../../hooks/helper";
import { getProblemHistoryData } from "../../../services/ServiceLayer";
import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";

import moment from "moment";
import HealthChart from "./HealthChart";

export default function Health({ patientId }) {
  const tableHeadings = [
   
    {
      label: "Problem (Status)",
      align: "left",
    },
    {
      label: "Start Date",
      align: "left",
    },
    {
      label: "End Date",
      align: "left",
    },
  ];
  // const { patientId } = props;
  // console.log("props", props);
  const [open, setOpen] = React.useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleDelete = () => {
    // setDeleteModalShow(true);
    setAnchorEl(null);
  };
  const [selectclient, setselectclient] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [selectRow, setSelectRow] = useState(null);
  const [patientAttachmentData, setPatientAttachmentData] = useState([]);
  const [title, setTitle] = useState(null);
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  var classes = useStyles();
  useEffect(() => {
    getProblemHistoryData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELPRB`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setPatientAttachmentData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);
  useEffect(() => {
    getProblemHistoryData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELPRB`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setPatientAttachmentData(response?.data);
        setDataUpdated(false);
      } else {
        // setIsLoader(false);
      }
    });
    setDataUpdated(false);
  }, [dataUpdated]);

  const deleteEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);
    setselectclient(rec);
    setDeleteModalShow(true);
    setTitle("Delete Problem");
  };

  const handleEdit = (rec) => {
    setOpen(true);
    setAnchorEl(null);
    setselectclient(rec);
    setTitle("Edit Problem");
  };
  return (
    <>
      {/* <div
        className={classes.pageTitleContainer}
        style={{ marginBottom: "6px", marginTop: "2px" }}
      >
        <Typography
          className={classes.typo}
          style={{ fontSize: "14px" }}
          
        >
          Problem
        </Typography>
      </div> */}
      <Grid container spacing={4} style={{ width: "100%" }}>
        <Grid item xs={12}>
          <div style={{background:'#5DB3D9',padding:'10px',color:'#fff',textAlign:'center',borderRadius:'5px',marginBottom:'2px'}}>Problem</div>
          {/* <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "13px",
              }}
            >
              {patientAttachmentData && patientAttachmentData?.length ? (
                patientAttachmentData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                       
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.problem_desc} {`(${row.status_val})`}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {dateFormatConversion(row.st_date)}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {dateFormatConversion(row.end_date)}
                        </TableCell>
                       
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget> */}
          <HealthChart />
        </Grid>
      </Grid>
    </>
  );
}
