import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  OutlinedInput,
  ListItemText,
  Fade,
} from "@material-ui/core";
// Model
import {
  Button,
  DialogContent,
  DialogContentText,
  Divider,
} from "@material-ui/core";
import Popup from "../../common/modal/Popup";
import { serviceFormElements } from "../inventory_constant";

import moment from "moment";
import { toast } from "react-toastify";

// styles
import useStyles from "./styles";

export default function ManagerService(props) {
  const { selectclient } = props;

  var classes = useStyles();
  // const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  const [modalShow, setModalShow] = useState(false);

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [open, setOpen] = React.useState(false);

  const [selectedValues, setSelectedValues] = useState([]);
  const [isActionCalled, setIsActionCalled] = useState(false);
  // list meta data
  const [listData, setListData] = useState([]);

  const [languageValues, setLanguageValues] = useState([]);
  const [cmpTypeValues, setCmpTypeValues] = useState([]);
  const [empTypeValues, setEmpTypeValues] = useState([]);
  const [genderValues, setGenderValues] = useState([]);
  const [hospitalValues, setHospitalValues] = useState([]);
 
  // list end meta data
  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();

    setIsActionCalled(false);
  };

  const currentDate = moment().format("YYYY-MM-DD");
  const minDate = moment().subtract(18, "years").format("YYYY-MM-DD");

  const handleChange2 = (event) => {
    setSelectedValues(event.target.value);
  };

  const disabled = true;
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={hideHandler}
            >
              Add Service
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={1}
              className={classes.formBox}
              style={{ marginBottom: "4px" }}
            >
              {serviceFormElements.map((element, i) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={classes.textGroup}
                    >
                      <Typography className={classes.formHeading}>
                        {element.label}{" "}
                        {element.required === "true" ? (
                          <span className={classes.required}>*</span>
                        ) : null}
                      </Typography>

                      {element.type === "select" ? (
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            name={element.key}
                            style={{ fontSize: "12px" }}
                            className={classes.selectDropdown}
                            placeholder={element.placeHolder}
                          >
                            <MenuItem disabled value="">
                              Select
                            </MenuItem>
                            {element.options
                              ? element.options.map((item) => (
                                  <MenuItem key={item} value={item}>
                                    {item}
                                  </MenuItem>
                                ))
                              : null}
                          </Select>
                        </FormControl>
                      ) : element.type === "text" ? (
                        <>
                          <TextField
                           fullWidth
                            required
                            name={element.key}
                            placeholder={element.placeHolder}
                            size="small"
                            className={classes.txtForm}
                            
                            variant="outlined"
                            inputProps={{
                             
                              style: {
                                height: "12px",
                                fontSize: "12px",
                              },
                              maxLength: element.maxLength,

                              onKeyPress: element.onKeyPress, // Add the event handler
                            }}
                          />
                        </>
                      ) : element.type === "date" ||
                        element.type === "time" ||
                        element.type === "datetime-local" ? (
                        <>
                          <TextField
                            id={element.key}
                            name={element.key}
                            placeholder={element.placeHolder}
                            type={element.type}
                            size="small"
                            className={classes.txtForm}
                            fullWidth
                            variant="outlined"
                            inputProps={{
                              max:
                                element.key === "date_of_birth"
                                  ? minDate
                                  : undefined,
                              min:
                                element.key === "joining_date"
                                  ? minDate
                                  : undefined,
                              style: {
                                height: "12px",
                                fontSize: "12px",
                              },
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </>
                      ) : element.type === "textarea" ? (
                        <>
                          <TextField
                            size="small"
                            className={classes.txtForm}
                            fullWidth
                            placeholder="Type here..."
                            variant="outlined"
                            multiline
                            rows={3}
                            inputProps={{
                              style: {
                                fontSize: "12px",
                              },
                            }}
                          />
                        </>
                      ) : element.type === "checkbox" ? (
                        <Grid
                          item
                          xs={12}
                          md={element.mdSize}
                          className={classes.textGroup}
                        >
                          <Checkbox name={element.key} />
                        </Grid>
                      ) : element.type === "button" ? (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                          style={{
                            width: "100%",
                            fontSize: "10px",
                            marginTop: "-10px",
                          }}
                        >
                          {element.btnName}
                        </Button>
                      ) : element.type === "label" ? (
                        <Divider />
                      ) : element.type === "multiselect" ? (
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                          style={{ padding: "4px" }}
                        >
                          <Select
                            style={{ fontSize: "12px" }}
                            multiple
                            className={classes.selectDropdown}
                            input={<OutlinedInput />}
                          >
                            <MenuItem disabled value="">
                              <em>Select</em>
                            </MenuItem>
                            {element.options.map((name) => (
                              <MenuItem key={name} value={name}>
                                <Checkbox
                                  checked={selectedValues.indexOf(name) > -1}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <Checkbox />
                      )}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
