import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  formBox: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    },
  },
  avatar: {
    width: "64px",
    height: "64px",
  },
  noteIcons: {
    position: "absolute",
    right: '10px',
    marginRight: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  Headingdivider:{
    color: "#fff",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 600,
    textAlign: "left",
    marginLeft:theme.spacing(4),
    marginBottom:theme.spacing(2),
    background:'#73d7f0',
    padding:theme.spacing(0.5)
  },
  formHeading: {
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    color: "#2A2338",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 500,
    textAlign: "left",
    flexGrow: 1,
    paddingLeft: "3px",
    paddingRight: "4px",
  },
  formHeadingValue: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    color: "#2A2338",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 500,
    textAlign: "left",
  },

  txtForm: {
    padding: "0px 2px",
    fontSize:`12px !important`,
    marginTop: theme.spacing(1),
    
   
  },
  errorMessage:{
    fontSize:'12px',
    color:'red',
    paddingLeft:'6px'
  },
  dropdownFormControl: {
    // marginTop: theme.spacing(1),
  },
  selectDropdown: {
    border: "1px solid #DADFEB",
    padding: "4px 10px",
    borderRadius: "5px",
    marginTop: theme.spacing(1),
    height: "32px",
    background: "#fff",
    fontSize:'12px',
    "&:before": {
      border: theme.palette.primary.light,
    },
    "&:after": {
      border: theme.palette.primary.main,
    },
    "&:hover:before": {
      border: `${theme.palette.primary.light} !important`,
    },
  },
  selectNoTopDropdown:{
    border: "1px solid #DADFEB",
    padding: "4px 10px",
    borderRadius: "5px",
    marginTop: theme.spacing(0),
    height: "32px",
    background: "#fff",
    fontSize:'12px',
    "&:before": {
      border: theme.palette.primary.light,
    },
    "&:after": {
      border: theme.palette.primary.main,
    },
    "&:hover:before": {
      border: `${theme.palette.primary.light} !important`,
    },
  },
  formlabel:{
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(-2),
    },
  },
  textGroup: {
    display: "flex-row",
    justifyContent: "flex-start",
  },
  modelFooter: {
    padding: theme.spacing(2),
  },
  blueButton: {
    background: "#0445AF",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
    marginTop: theme.spacing(2),
    "&:hover": {
      background: "#0078D4",
      color: "#fff",
    },
  },
  gridBox: {
    // display:"flex",
    // justifyItems:"center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  btnGroup: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  buttonGroup: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  rightButton: {
    marginLeft: theme.spacing(2),
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
    height: "38px",
    borderRadius: "2px",
  },
  leftBluetButton: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 500,

    textTransform: "none",
    height: "38px",
    borderRadius: "2px",
    background: "#0445AF",
    color: "#fff",
    "&:hover": {
      background: "#0078D4",
      color: "#fff",
    },
  },
  largeBtn: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 500,
    height: "42px",
    textTransform: "none",
    borderRadius: "2px",
    color:'#fff',
    "&:hover": {
        background: "#038C71",
        color: "#fff",
      },
      "&:focus": {
        background: "#037962",
        color: "#fff",
      },
      "&:active": {
        background: "#037962",
        color: "#fff",
      },
  },
  widgetBox: {
    marginTop: theme.spacing(2),
  },
  passwordBox: {
    display: "flex",
    alignItems: "center",
  },
  Link: {
    color: "#0445AF",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
  AssignHeading: {
    marginBottom: theme.spacing(-2),
    marginTop: theme.spacing(2),
  },
  icons: {
    marginRight: theme.spacing(2),
  },
  footerBox: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
  },
  cancelBtn: {
    marginRight: theme.spacing(2),
    background: "#fff",
  },
  imageUpload:{
    width:'136px',
    height:'164px'
  },
  // AccordionSummary:{
  //   backgroundColor:'#18AACE',
  //   color:'#fff',
  //   padding:'0px 6px',
    
  // }
  required:{
    color:'red'
  },
  titleDiv:{
    display:'flex',
    justifyContent:'flex-start',
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: '2px',
    },
    
  },
  imageicon:{
    position: 'relative',
    marginTop: '-30px',
    marginRight: '10px',
    cursor:'pointer',
    [theme.breakpoints.down("md")]: {
      marginTop: '-20px',
    },
  },
  imageCloseicon:{
    position: 'absolute',
    marginTop: '-30px',
    marginRight: '10px',
    cursor:'pointer',
    right:0,
    [theme.breakpoints.down("md")]: {
      display:'none',
      position:'hidden'
    },
  },
  formBox: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  avatar: {
    width: "68px",
    height: "68px",
  },
  formHeading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
  },
  txtForm: {
    padding: "0px 4px",
  },
  modelFooter: {
    padding: theme.spacing(2),
  },
  btnGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      display:'inline'
    },
    
  },
  btnlRightDark:{
    height:'42px',
    textTransform:'none',
    [theme.breakpoints.down("md")]: {
      width:'100%',
      marginTop:theme.spacing(1)
    },
  },
  btnleftOutline:{
    height:'42px',
    textTransform:'none',
    border:'1px solid #0078D4',
    color:'#0078D4',
    [theme.breakpoints.down("md")]: {
      width:'100%'
    },
  },

  blueButton: {
    background: "#0078D4",
    color: "#fff",
    fontWeight: 400,
    marginTop: theme.spacing(2),
    "&:hover": {
      background: "#0078D4",
      color: "#fff",
    },
  },
  btn:{
    paddingTop:theme.spacing(0),
    paddingBottom:theme.spacing(0)
  },
  gridBox: {
    // display:"flex",
    // justifyItems:"center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  iconsBar: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#fff",
    borderBottomColor:'2px solid #4E97FD'
   
  },
  tab: {
    color: '#000',
    fontFamily:'Poppins',
    fontSize:'14px',
    fontWeight:500,
    textTransform:'none',
    "&:hover": {
      
      color: "#fff",
      background:'#4E97FD',
    },
    "&:active": {
      
      color: "#fff",
      background:'#4E97FD',
    },
    "&.Mui-selected":{
      color: "#fff",
      background:'#4E97FD',
    }
  },
  iconsContainer: {
    background: "none",
    boxShadow: "none",
    // overflow: "hidden",
    paddingBottom: theme.spacing(2),
  },
  gridItem:{
    marginBottom:theme.spacing(4),
  },
}));
