import React, { useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
// styles
import useStyles from "./styles";
import { ArrowDropDown } from "@material-ui/icons";
import ProfileForm from "./ProfileForm";
export default function AddDoctor() {
  var classes = useStyles();
  return (
    <>
      <Accordion>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Profile</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ProfileForm />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Profile</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ProfileForm />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Profile</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ProfileForm />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Profile</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ProfileForm />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
