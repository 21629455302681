import React, { useState, useEffect } from "react";
import { Button, Grid, Menu, MenuItem, Typography } from "@material-ui/core";

import { dateFormatConversion, loadState } from "../../../hooks/helper";

import TableWidget from "../../Widget/TableWidget";

import TableDesign from "../../common/table/TableDesign";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
// styles
import useStyles from "../styles";
import AllergyChart from './AllergyChart'
import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";
import { getAllergyHistoryData } from "../../../services/ServiceLayer";

import moment from "moment";

export default function AllergyHistory({patientId}) {
  const tableHeadings = [
   
    {
      label: "Allergy From",
    },
    {
      label: "Begin Date",
    },
    {
      label: "End Date",
    },
    {
      label: "Outcome",
    },
  ];
  
  console.log("patientId", patientId);
  
  const [allergyData, setAllergyData] = useState([]);
 
  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  useEffect(() => {
    getAllergyHistoryData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELALE`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setAllergyData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);
 

  return (
    <>
    {/* <div className={classes.pageTitleContainer} style={{marginBottom: "6px",marginTop: "10px", borderTop:'1px solid #6666' }}>
        <Typography className={classes.typo} style={{fontSize:'14px'}} weight="medium">
          Allergy
        </Typography>
      </div> */}
      <Grid container spacing={4} style={{ width: "100%" }}>
        <Grid item xs={12}>
        <div style={{background:'#5DB3D9',padding:'10px',color:'#fff',textAlign:'center',borderRadius:'5px',marginBottom:'2px'}}>Allergy</div>
          {/* <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {allergyData && allergyData?.length ? (
                allergyData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        

                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {row.allergy_from}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {dateFormatConversion(row.st_date)}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {dateFormatConversion(row.end_date)}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.outcome_val}
                        </TableCell>
                       
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget> */}
          <AllergyChart />
        </Grid>
      </Grid>
    </>
  );
}
