import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Switch,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../components/common/modal/Popup";
// styles
import useStyles from "./styles";
import moment from "moment";
import { toast } from "react-toastify";
import { postCustomData } from "../../services/ServiceLayer";

import {
  currentUser,
  postFlagBooleanVariable,
  loadState,
  handleAddressKeyPress,
  handleAddressKeyPressPaste,
} from "../../hooks/helper";

export default function ManageCustomData(props) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  //
  const [isActionCalled, setIsActionCalled] = useState(false);
  const formElements = [
    {
      key: "associated_tbl_id",
      label: "Associated Table",
      placeHolder: "select",
      type: "select",
      options: [],
      mdSize: "12",
    },
    {
      key: "data_name",
      label: "Name",
      placeHolder: "type here",
      type: "text",
      mdSize: "6",
      maxLength: "40",
      onKeyPress: handleAddressKeyPress,
      onPaste: handleAddressKeyPressPaste,
    },
    {
      key: "associated_role_id",
      label: "Associate Role",
      placeHolder: "select",
      type: "select",
      options: ["Doctor", "Nurse", "Admin"],
      mdSize: "6",
    },

    {
      key: "data_default_val",
      label: "Sample Value",
      placeHolder: "type here",
      type: "text",
      maxLength: "20",
      mdSize: "6",
    },
    {
      key: "data_type_id",
      label: "Data Type",
      placeHolder: "select",
      type: "select",
      options: ["Boolean", "AlphaNumeric", "Date"],
      mdSize: "6",
    },
    {
      key: "data_status",
      label: "Status",
      placeHolder: "select",
      type: "switch",
      // options: ["Active","Inactive"],
      mdSize: "4",
    },

    {
      key: "data_mandotory",
      label: "Mandatory",
      placeHolder: "select",
      type: "switch",
      // options: ["true","false"],
      mdSize: "4",
    },
    {
      key: "is_active",
      label: "Editable",
      placeHolder: "select",
      type: "switch",
      // options: ["true","false"],
      mdSize: "4",
    },
  ];
  console.log("props-24", props);
  const { selectclient } = props;
  console.log("selectclient", selectclient);

  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  const [actionType, setActionType] = useState("MADDCSD");

  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
    setIsActionCalled(false);
    setCustomDetail({});
  };

  // list meta data
  const [listData, setListData] = useState([]);

  const [tableValues, setTableValues] = useState([]);
  const [empTypeValues, setEmpTypeValues] = useState([]);
  const [dataTypeValues, setDataTypeValues] = useState([]);
  // call meta data
  const metaData = loadState().metaData;

  useEffect(() => {
    console.log("loadState", metaData);
    setListData(metaData);
  }, []);
  useEffect(() => {
    console.log("listData", listData);
    if (listData.length > 0) {
      const empTypeValues = listData[0].empType;
      const tableValues = listData[0].tableName;
      const dataTypeValues = listData[0].CustomDatatype;
      setEmpTypeValues(empTypeValues);
      setTableValues(tableValues);
      setDataTypeValues(dataTypeValues);
    }
  }, [listData]);

  const [customDetail, setCustomDetail] = useState({
    patient_id: 0,
    custome_data_id: 0,
    data_name: "",
    associated_tbl_id: 0,
    associated_tbl_name: "",
    associated_role_id: 1,
    data_type: "",
    data_type_id: 0,
    data_default_val: "",
    data_status: "N",
    data_mandotory: "N",
    data_priority: "N",
    is_active: "N",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: "MADDCSD",
  });
  useEffect(() => {
    console.log("selectclient", selectclient);
    if (selectclient === null) {
      setCustomDetail({
        patient_id: 0,
        custome_data_id: 0,
        data_name: "",
        associated_tbl_id: 0,
        associated_tbl_name: "",
        associated_role_id: 1,
        data_type: "",
        data_type_id: 0,
        data_default_val: "",
        data_status: "N",
        data_mandotory: "N",
        data_priority: "N",
        is_active: "N",
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: "MADDCSD",
      });
    } else {
      setCustomDetail({
        patient_id: selectclient?.patient_id,
        custome_data_id: selectclient?.custome_data_id,
        data_name: selectclient?.data_name,
        associated_tbl_id: selectclient?.associated_tbl_id,
        associated_tbl_name: selectclient?.associated_tbl_name,
        associated_role_id: selectclient?.associated_role_id,
        data_type: selectclient?.data_type,
        data_type_id: selectclient?.data_type_id,
        data_default_val: selectclient?.data_default_val,
        data_status: selectclient?.data_status,
        data_mandotory: selectclient?.data_mandotory,
        data_priority: selectclient?.data_priority,
        is_active: selectclient?.is_active,
        updated_by: currentUser(),
        access_location: selectclient?.access_location,

        opt_type: actionType,
      });
    }
  }, [selectclient]);
  const submitFormData = () => {
    // checkValidator();
    setIsActionCalled(true);
  };
  useEffect(() => {
    console.log("isActionCalled", isActionCalled);
    // console.log("error", error);
    if (isActionCalled) {
      const postObject = {
        patient_id: 0,
        custome_data_id: customDetail.custome_data_id,
        data_name: customDetail.data_name,
        associated_tbl_id: customDetail.associated_tbl_id,
        associated_tbl_name: "",
        associated_role_id: customDetail.associated_role_id,
        data_type: customDetail.data_type,
        data_type_id: customDetail.data_type_id,
        data_default_val: customDetail.data_default_val,
        data_status: customDetail.data_status,
        data_mandotory: customDetail.data_mandotory,
        data_priority: customDetail.data_priority,
        is_active: customDetail.is_active,
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: "MADDCSD",
      };

      postCustomData(postObject).then((response) => {
        console.log(response);
        if (response?.status?.status) {
          toast.success("Custom data registed successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });

          props.onHide();
        }
        setIsActionCalled(false);
        setCustomDetail({});
      });
    } else {
      setIsActionCalled(false);
      setCustomDetail({});
    }
    setIsActionCalled(false);
  }, [isActionCalled]);
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={submitFormData}
            >
              Add
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={2}
              className={classes.formBox}
              style={{ marginBottom: "4px" }}
            >
              {formElements.map((element, i) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={classes.textGroup}
                    >
                      <Typography className={classes.formHeading}>
                        {element.label}{" "}
                        {element.required === "true" ? (
                          <span className={classes.required}>*</span>
                        ) : null}
                      </Typography>

                      {element.type === "select" ? (
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            name={element.key}
                            style={{ fontSize: "12px" }}
                            className={classes.selectDropdown}
                            placeholder={element.placeHolder}
                            value={
                              customDetail && customDetail[element.key]
                                ? customDetail && customDetail[element.key]
                                : ""
                            }
                            onChange={(e) => {
                              console.log("selected");
                              setCustomDetail({
                                ...customDetail,
                                [element.key]: e.target.value,
                              });
                            }}
                          >
                            <MenuItem disabled value="">
                              Select
                            </MenuItem>
                            {element.key === "associated_tbl_id" && tableValues
                              ? tableValues.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "associated_role_id" &&
                                empTypeValues
                              ? empTypeValues.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "data_type_id" && dataTypeValues
                              ? dataTypeValues.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.options.map((item) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      ) : element.type === "text" ? (
                        <TextField
                          required
                          name={element.key}
                          placeholder={element.placeHolder}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                            maxLength: element.maxLength,

                            onKeyPress: element.onKeyPress, // Add the event handler
                            onPaste: element.onPaste,
                          }}
                          value={
                            customDetail && customDetail[element.key]
                              ? customDetail && customDetail[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected");
                            setCustomDetail({
                              ...customDetail,
                              [element.key]: e.target.value,
                            });
                          }}
                        />
                      ) : element.type === "date" ||
                        element.type === "datetime-local" ? (
                        <TextField
                          id={element.key}
                          name={element.key}
                          placeholder={element.placeHolder}
                          type={element.type}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            min: `${currentDateTime}`,
                            max: `${currentDate}`,
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={
                            customDetail && customDetail[element.key]
                              ? customDetail && customDetail[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected");
                            setCustomDetail({
                              ...customDetail,
                              [element.key]: e.target.value,
                            });
                          }}
                        />
                      ) : element.type === "textarea" ? (
                        <TextField
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          placeholder="Type here..."
                          variant="outlined"
                          multiline
                          rows={3}
                          inputProps={{
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          value={
                            customDetail && customDetail[element.key]
                              ? customDetail && customDetail[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected");
                            setCustomDetail({
                              ...customDetail,
                              [element.key]: e.target.value,
                            });
                          }}
                        />
                      ) : element.type === "button" ? (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                          style={{
                            width: "100%",
                            fontSize: "10px",
                            marginTop: "26px",
                          }}
                        >
                          {element.btnName}
                        </Button>
                      ) : (
                        // <Checkbox />
                        <Switch
                          name={element.key}
                          checked={customDetail[element.key]}
                          onChange={(e) => {
                            const value = postFlagBooleanVariable(
                              e.target.checked
                            );
                            setCustomDetail({
                              ...customDetail,
                              [element.key]: value,
                            });
                          }}
                        />
                      )}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
