export const manageEmployeeSchema = {
    businessName: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter business name"
        }
    },
    clientName: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter organization name"
        }
    },
    clientShortName: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter organization short name"
        }
    },
    contactName: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter contact name"
        }
    },
    location_id:{
        type: "string",
        required: true,
        properties: {
            empty: "Please select office location"
        }
    },
    clientEmail: {
        type: "email",
        required: true,
        properties: {
            empty: "Please enter email",
            email: "Please enter a valid Email"
        }
    },
    location: {
        type: "string",
        required: true,
        properties: {
            empty: "Please select location",
        }
    },
    email: {
        type: "email",
        required: true,
        properties: {
            empty: "Please enter email",
            email: "Please enter a valid Email"
        }
    },
    numPlans: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter no. of plans"
        }
    },
    password: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter password",
            minLength: {
                limit: 8,
                msg: "Password must be minimum 8 length",
            }
        }
    },
    contact_cell: {
        type: "string",
        required: true,
        properties: {
          empty: "Please enter mobile number",
          number: "Please enter number only",
          minLength: {
            limit: 10,
            msg: "Number must be minimum 10 length",
          },
          maxLength: {
            limit: 10,
            msg: "Number must be maximum 10 length",
          },
        },
      },
      ssn: {
        type: "string",
        required: true,
        properties: {
          empty: "Please enter ssn value",
          number: "Please enter number only",
          minLength: {
            limit: 9,
            msg: "Number must be minimum 9 length",
          },
          maxLength: {
            limit: 9,
            msg: "Number must be maximum 9 length",
          },
        },
      },
      zip: {
        type: "string",
        required: true,
        properties: {
          empty: "Please enter zip value",
          number: "Please enter number only",
          minLength: {
            limit: 6,
            msg: "Number must be minimum 6 length",
          },
          maxLength: {
            limit: 6,
            msg: "Number must be maximum 6 length",
          },
        },
      },
      contact_office: {
        type: "string",
        required: true,
        properties: {
          empty: "Please enter mobile number",
          number: "Please enter number only",
          minLength: {
            limit: 10,
            msg: "Number must be minimum 10 length",
          },
          maxLength: {
            limit: 10,
            msg: "Number must be maximum 10 length",
          },
        },
      },
      contact_home: {
        type: "string",
        required: true,
        properties: {
          empty: "Please enter mobile number",
          number: "Please enter number only",
          minLength: {
            limit: 10,
            msg: "Number must be minimum 10 length",
          },
          maxLength: {
            limit: 10,
            msg: "Number must be maximum 10 length",
          },
        },
      },
    oldPassword: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter password",
            minLength: {
                limit: 8,
                msg: "Password must be minimum 8 length",
            }
        }
    },
    newPassword: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter password",
            // length: 8,
            // lowercase: true,
            // uppercase: true,
            // special: true,
        }
    },
    confirmPassword: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter password",
            minLength: {
                limit: 8,
                msg: "Password must be minimum 8 length",
            },
            match: "Passwords in the two sections must match" //"Password should be match"
        }
    },
    first_name: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter first name"
        }
    },
    last_name: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter last name"
        }
    },
    user_name: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter user name"
        }
    },
    name: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter plan name"
        }
    },
    description: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter description"
        }
    },
    dayZIndicator: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter Day-0 indicator name"
        }
    },
    minAge: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter min. time from Day-0"
        }
    },
    maxAge: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter max. time from Day-0"
        }
    },
    minGap: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter min. gap from previous event"
        }
    },
    subEvents: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter list of sub events"
        }
    },
    joining_date: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter joining date"
        }
    },
    start_time:{
        type: "string",
        required: true,
        properties: {
            empty: "Please enter start time"
        }
    },
    end_time:{
        type: "string",
        required: true,
        properties: {
            empty: "Please enter end time"
        }
    },
    endDate: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter end start"
        }
    },
    reportGroupType: {
        type: "string",
        required: true,
        properties: {
            empty: "Please select report group type"
        }
    },
    practice: {
        type: "string",
        required: true,
        properties: {
            empty: "Please select a location"
        }
    },
    s3Key: {
        type: "string",
        required: true,
        properties: {
            empty: "Please select a file"
        }
    },
    file: {
        type: "string",
        required: true,
        properties: {
            empty: "Please select a file"
        }
    },
}