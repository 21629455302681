import React from 'react'
import AddContactLens from '../../components/Inventory/Services/AddContactLens'

export default function newContact() {
  return (
    <>
    <AddContactLens/>
    </>
  )
}
