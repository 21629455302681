import React, { useEffect,useState } from "react";

import {
  Grid,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Select,
  FormControl,
  FormGroup,
  Checkbox,
  Button,
  IconButton,
  Menu,
} from "@material-ui/core";
// data
import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";

// Model
import { iopForm } from "./External_Constant";
import useStyles from "../styles";
// components

import moment from "moment";
import { currentUser, loadState } from "../../../hooks/helper";

import { toast } from "react-toastify";
import { getPatientExternalIop, postExternalIop } from "../../../services/ServiceLayer";
export default function Iop({ examExternalMethod,patientId,iopBtnUnable,setIopBtnUnable }) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;

  const [isActionCalled, setIsActionCalled] = useState(false);
  const [externalIopDetails, setExternalIopDetails] = useState({
    pat_extiop_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    iop_method_od: '',
    iop_measure_od: '',
    iop_reliability_od: '',
    iop_other_od: "",
    iop_method_os: '',
    iop_measure_os: '',
    iop_reliability_os: '',
    iop_other_os: "",
    updated_by: currentUser(),
    access_location: "Y",
    opt_type: "ADDEXT",
  });
  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    // if (!isError() && error !== null && isActionCalled) {
    if (isActionCalled) {
      postExternalIop(externalIopDetails).then((response) => {
        console.log(response);
        if (response?.status?.status) {
          toast.success("Iop added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIopBtnUnable(false);
          // props.onHide();
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    // } else {
    //   setIsActionCalled(false);
    // }

    setIsActionCalled(false);
  }, [isActionCalled]);
  const [iopData, setIopData] = useState([]);
  useEffect(() => {
    getPatientExternalIop(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELIOP`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setIopData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);

  const [discardUpdate, setDiscardUpdate] = useState(false);
  useEffect(() => {
    if (iopData && iopData.length > 0) {
 

      setExternalIopDetails({
        pat_extiop_id: iopData[0]?.pat_ext_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        iop_method_od: iopData[0]?.iop_method_od,
        iop_measure_od: iopData[0]?.iop_measure_od,
        iop_reliability_od: iopData[0]?.iop_reliability_od,
        iop_other_od: iopData[0]?.iop_other_od,
        iop_method_os: iopData[0]?.iop_method_os,
        iop_measure_os: iopData[0]?.iop_measure_os,
        iop_reliability_os: iopData[0]?.iop_reliability_os,
        iop_other_os: iopData[0]?.iop_other_os,
        updated_by: currentUser(),
        access_location: "Y",
        opt_type: "UPDEXT",
      });
      setDiscardUpdate(false);
    }
  }, [iopData,discardUpdate]);

const handleCopyODValue=()=>{
  setExternalIopDetails({
    ...externalIopDetails,
    iop_method_os: externalIopDetails?.iop_method_od,
    iop_measure_os: externalIopDetails?.iop_measure_od,
    iop_reliability_os: externalIopDetails?.iop_reliability_od,
    iop_other_os: externalIopDetails?.iop_other_od,
  })
  setIopBtnUnable(true);
}

const handleCopyOSValue=()=>{
  setExternalIopDetails({
    ...externalIopDetails,
    iop_method_od: externalIopDetails?.iop_method_os,
    iop_measure_od: externalIopDetails?.iop_measure_os,
    iop_reliability_od: externalIopDetails?.iop_reliability_os,
    iop_other_od: externalIopDetails?.iop_other_os,
  })
  setIopBtnUnable(true);
}

  // 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const clearForm=()=>{
    setExternalIopDetails({
      pat_extiop_id: 0,
      patient_id: patientId,
      location_id: userData?.location_id,
      iop_method_od: '',
      iop_measure_od: '',
      iop_reliability_od: '',
      iop_other_od: "",
      iop_method_os: '',
      iop_measure_os: '',
      iop_reliability_os: '',
      iop_other_os: "",
      updated_by: currentUser(),
      access_location: "Y",
      opt_type: "ADDEXT",
    })
    
  }
  const[showDiscard, setShowDiscard] = useState(false);

  const discardHandler = ()=>{
    if(iopData && iopData.length>0){
      setIopBtnUnable(false);
      setDiscardUpdate(true);
    }else{
      clearForm();
      setIopBtnUnable(false);
    }
   
  }
  return (
    <>
      {/* <TableWidget noBodyPadding> */}
      <Grid
        container
        spacing={2}
        className={classes.formBox}
        style={{ border: "1px solid #DED9D9" }}
      >
         <Grid
          item
          xs={12}
          md={12}
          className={classes.textGroup}
          style={{ padding: "0px", display: "flex", justifyContent: "end" }}
        >
          <IconButton
            id="basic-button"
            aria-controls={openDown ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openDown ? "true" : undefined}
            onClick={(e) => {
              handleClick(e);
            }}
          >
            <MoreIcon />
          </IconButton>

          <Menu
            style={{ fontSize: "12px" }}
            className={classes.menuBox}
            id="basic-menu"
            anchorEl={anchorEl}
            open={openDown}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              style={{ fontSize: "12px" }}
              onClick={() => handleCopyODValue()}
            >
              Copy OD to OS
            </MenuItem>

            <MenuItem
              style={{ fontSize: "12px" }}
              onClick={() => handleCopyOSValue()}
            >
              Copy OS to OD
            </MenuItem>
          </Menu>
        </Grid>
        {iopForm.map((element, i) => {
          return (
            <>
              <Grid
                item
                xs={12}
                md={element.mdSize}
                className={classes.textGroup}
                // style={{ border: "1px solid #DED9D9" }}
              >
                {element.label && (
                  <Typography
                    className={classes.formHeading}
                    style={{
                      fontWeight: "500",
                      color: "#4E97FD",
                      marginTop: "0px",
                    }}
                  >
                    {element.label}{" "}
                    {element.required === "true" ? (
                      <span className={classes.required}>*</span>
                    ) : null}
                    {element.copyBtn === "true" && (
                      <Button
                        style={{
                          float: "right",
                          position: "absolute",
                          fontSize: "10px",
                          top: "20px",
                        }}
                        classes={{ root: classes.button }}
                        variant="contained"
                        color="secondary"
                        // onClick={duplicateValue}
                      >
                        =
                      </Button>
                    )}
                  </Typography>
                )}

                {element.type ? (
                  <>
                    {element.type === "select" ? (
                      <FormControl
                        fullWidth
                        className={classes.dropdownFormControl}
                      >
                        <Select
                          name={element.key}
                          className={classes.selectDropdown}
                          placeholder={element.placeHolder}
                          value={
                            externalIopDetails &&
                            externalIopDetails[element.key] === null
                              ? ""
                              : externalIopDetails &&
                                externalIopDetails[element.key]
                          }
                          onChange={async (e) => {
                            setExternalIopDetails({
                              ...externalIopDetails,
                              [element.key]: e.target.value,
                            });
                            setIopBtnUnable(true);
                            setShowDiscard(true);
                          }}
                        >
                          <MenuItem disabled value="">
                            Select
                          </MenuItem>
                          {(element.key === "iop_method_od" || element.key==="iop_method_os" )&& examExternalMethod
                            ? examExternalMethod.map((item) => (
                                <MenuItem
                                  key={item.attribute_id}
                                  value={item.attribute_id}
                                >
                                  {item.attribute_val}
                                </MenuItem>
                              ))
                            : element.options.map((item) => (
                                <MenuItem value={item}>{item}</MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    ) : element.type === "text" ? (
                      <TextField
                        required
                        name={element.key}
                        placeholder={element.placeHolder}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                          maxLength: element.maxLength,
                          onKeyPress: element.onKeyPress,
                          onPaste: element.onpaste,
                        }}
                        value={
                          externalIopDetails &&
                          externalIopDetails[element.key] === null
                            ? ""
                            : externalIopDetails &&
                              externalIopDetails[element.key]
                        }
                        onChange={async (e) => {
                          setExternalIopDetails({
                            ...externalIopDetails,
                            [element.key]: e.target.value,
                          });
                          setIopBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                    ) : element.type === "date" ||
                      element.type === "datetime-local" ? (
                      <TextField
                        id={element.key}
                        name={element.key}
                        placeholder={element.placeHolder}
                        type={element.type}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          min: `${currentDateTime}`,
                          max: `${currentDate}`,
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={
                          externalIopDetails &&
                          externalIopDetails[element.key] === null
                            ? ""
                            : externalIopDetails &&
                              externalIopDetails[element.key]
                        }
                        onChange={async (e) => {
                          setExternalIopDetails({
                            ...externalIopDetails,
                            [element.key]: e.target.value,
                          });
                          setIopBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                    ) : element.type === "radio" ? (
                      <RadioGroup row defaultValue="">
                        {element.radioValue.map((item) => (
                          <FormControlLabel
                            size="small"
                            style={{ padding: "0px 6px" }}
                            value={item}
                            control={<Radio />}
                            label={item}
                            className={classes.smallRadioButton}
                          />
                        ))}
                      </RadioGroup>
                    ) : element.type === "checkbox" ? (
                      <>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              value={element.value}
                              control={<Checkbox />}
                              label={element.lbltitle}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </FormControl>
                      </>
                    ) : element.type === "button" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "10px",
                        }}
                      >
                        <Button
                          style={{ fontSize: "10px" }}
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                          // onClick={selectPrevFrame}
                        >
                          {element.btnlbl}
                        </Button>
                      </div>
                    ) : (
                      <TextField
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        placeholder="Type here..."
                        variant="outlined"
                        multiline
                        rows={3}
                        inputProps={{
                          style: {
                            fontSize: "12px",
                          },
                          maxLength: element.maxLength,
                          onKeyPress: element.onKeyPress,
                          onPaste: element.onpaste,
                        }}
                        value={
                          externalIopDetails &&
                          externalIopDetails[element.key] === null
                            ? ""
                            : externalIopDetails &&
                              externalIopDetails[element.key]
                        }
                        onChange={async (e) => {
                          setExternalIopDetails({
                            ...externalIopDetails,
                            [element.key]: e.target.value,
                          });
                          setIopBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Typography
                      className={classes.formHeading}
                      style={{
                        fontWeight: "600",

                        color: "#4E97FD",
                      }}
                    >
                      {element.title}
                    </Typography>
                  </>
                )}
              </Grid>
            </>
          );
        })}
        <Grid className={classes.btnGroup} item xs={12} style={{ display: "flex", justifyContent: "end" }}>
        {showDiscard && (
            <Button
              className={classes.btnleftOutline}
              variant="outlined"
              style={{ marginRight: "10px", fontSize: "12px" }}
              size="medium"
              onClick={discardHandler}
            >
              Discard
            </Button>
          )}
          <Button disabled={!iopBtnUnable}
            style={{ display: "flex", float: "right" }}
            onClick={handleSubmit}
            className={classes.btnlRightDark}
            variant="contained"
            size="medium"
            color="secondary"
          >
            Save
          </Button>
        </Grid>
      </Grid>

      {/* </TableWidget> */}
    </>
  );
}
