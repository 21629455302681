import React from 'react'
import PatientDetail from '../../components/Patient/Detail/PatientDetail'

export default function Patient() {
  return (
    <div>
        <PatientDetail/>
    </div>
  )
}
