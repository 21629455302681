import React, { useState, useEffect } from "react";

import {
  Typography,
  Button,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Popup from "../../components/common/modal/Popup";
import alertIcon from "../../images/alert.png";
// styles
import useStyles from "./styles";
import { toast } from "react-toastify";
import moment from "moment";
import { postCustomData } from "../../services/ServiceLayer";
import { currentUser, postFlagBooleanVariable } from "../../hooks/helper";
export default function DeleteCustomPopup({
  onHide,
  deleteModalShow,
  setDeleteModalShow,
  selectclient,
}) {
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const [actionType, setActionType] = useState("MDELCSD");

  const [isActionCalled, setIsActionCalled] = useState(false);
  toast.configure();
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setDeleteModalShow(false);
    setOpen(false);
  };
  const [customDetail, setCustomDetail] = useState({
    custome_data_id:"custome_data_id",
    patient_id: "patient_id",
    data_name: "data name",
    associated_tbl_id: "associated_tbl_id",
    associated_tbl_name: "associated_tbl_name",
    associated_role_id: "associated_role_id",
    data_type: "data_type",
    data_type_id: "data_type_id",
    data_default_val: "data_default_val",
    data_status: "data_status",
    data_mandotory: "data_mandotory",
    data_priority: "data_priority",
    is_active: "is_active",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: "MDELCSD",
  });
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setCustomDetail({
        custome_data_id:0,
        patient_id: 0,
        data_name: "",
        associated_tbl_id: 0,
        associated_tbl_name: "",
        associated_role_id: 1,
        data_type: "",
        data_type_id: 0,
        data_default_val: "",
        data_status: 'N',
        data_mandotory: 'N',
        data_priority: "",
        is_active: 'Y',
        updated_by: currentUser(),
        access_location: "",
        opt_type: "MDELCSD",
      });
    } else {
      // const map1 = selectclient?.locations?.map((x) => x._id);
      // const map2 = selectclient?.wardTypes?.map((x) => x);
      setCustomDetail({
        custome_data_id:selectclient?.custome_data_id,
        patient_id: 0,
        data_name: selectclient?.data_name,
        associated_tbl_id: selectclient?.associated_tbl_id,
        associated_tbl_name: selectclient?.associated_tbl_name,
        associated_role_id: selectclient?.associated_role_id,
        data_type: selectclient?.data_type,
        data_type_id: selectclient?.data_type_id,
        data_default_val: selectclient?.data_default_val,
        data_status: false,
        data_mandotory: false,
        data_priority: selectclient?.data_priority,
        is_active: false,
        updated_by: currentUser(),
        access_location: "access_location",

        opt_type: actionType,
      });
    }
  }, [selectclient]);

  const deleteFormData = () => {
    setIsActionCalled(true);
    setCustomDetail({
      ...customDetail,
      opt_type: "MDELCSD",
    });
  };

  useEffect(() => {
    console.log("isActionCalled", isActionCalled);

    if (isActionCalled) {
      postCustomData(customDetail).then((response) => {
        console.log("actionType", actionType);

        console.log(response);
        if (response.status.status) {
          toast.success("Custom data deleted successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });

          onHide();
          setDeleteModalShow(false);
          setOpen(false);
          setIsActionCalled(false);
        } else {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActionCalled(false);
        }
      });
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={deleteFormData}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <img src={alertIcon} />
            <Typography className={classes.formActiveHeading}>
              Are you sure! You want to Delete this custom data?
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
