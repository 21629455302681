import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import { Grid, Tabs, Tab, Paper } from "@material-ui/core";

// components
import Widget from "../../Widget/Widget";
import PageTitle from "../../PageTitle/PageTitle";

import pageNotFound from "../../../assets/pageNotFound.png";
// styles
import useStyles from "./styles";

import PatientProfile from "./Case/PatientTab/PatientProfile";
import OtherTabs from "./Case/OtherTab/OtherTabs";
import InsuranceList from "./Case/InsuranceTab/InsuranceList";
import AttachmentList from "./Case/AttachmentTab/AttachmentList";

import HistoryList from "./Case/HistoryTab/HistoryList";
import FamilyList from "./Case/FamilyTab/FamilyList";
import AppointmentList from "./Case/AppointmentTab/AppointmentList";
import CustomeDataList from "../Cases/CustomDataTab/CustomeDataList";

import IntractionList from "./Case/IntractionTab/IntractionList";
import {
  getPatient,
  getPatientAttachment,
  getAdditionalDetails,
  getInsuranceDetails,
  getPatientFamily,
  getAppointmentData,
} from "../../../services/ServiceLayer";
import { loadState } from "../../../hooks/helper";
import useListData from "../../common/allMetaData/useListData";
export default function PatientDetail() {
  const { patient_id } = useParams();
  const history = useHistory();
  const [patientData, setPatientData] = useState([]);
  const [patientAttachmentData, setPatientAttachmentData] = useState([]);
  const [patientOtherData, setPatientOtherData] = useState([]);
  const [patientInsuranceData, setPatientInsuranceData] = useState([]);
  const [patientFamilyData, setPatientFamilyData] = useState([]);
  const [patientAppointmentData, setPatientAppointmentData] = useState([]);
  // const navigate = useNavigate();
  var [activeTabId, setActiveTabId] = useState(null);
  // list meta data
  const listData = useListData();

  const userData = loadState().user;
  const userRole = loadState().user.user_role;

  const [patientReadValue, setPatientReadValue] = useState();

  const readAccessData = loadState().menu;
  useEffect(() => {
    console.log("readAccessData", readAccessData);
    const patientsSection = readAccessData.find(
      (item) => item.label === "Patients"
    );

    if (patientsSection) {
      // Access the "children" array within the "Patients" section
      const patientChildren = patientsSection.children;

      // Find and store the read_access values
      const patientListReadAccess = patientChildren.find(
        (child) => child.label === "Patient List"
      ).read_access;
      const addPatientReadAccess = patientChildren.find(
        (child) => child.label === "ADD New Patient"
      ).read_access;

      // Now you can use these values as needed
      console.log("Patient List Read Access:", patientListReadAccess);
      console.log("ADD New Patient Read Access:", addPatientReadAccess);
      setPatientReadValue(addPatientReadAccess);
    }
  }, [readAccessData]);
  // Destructure listData object
  const {
    languageValues,
    genderValues,
    raceValues,
    titleValues,
    maritalValues,
    genderIdentificationValues,
    sexOrientationValues,
    ethinicityValues,
    relTypeValues,
    deceasedValues,
    disabilityValues,
    gaurantorValues,
    preferenceHourValues,
    veteranValues,
    insuranceValues,
    additionalInsuranceValues,
    addressStatus,
    preferenceContactValues,
    thanksValues,
    patientStatusValues,
    inactiveValues,
    applicableRoleValues,
    phoneTypeValues,
    referenceSourceValues,
  } = listData;

  // call meta data
  var classes = useStyles();
  const handleEdit = () => {
    history.push(`/app/edit-patient/${patient_id}`);
  };
  useEffect(() => {
    // patient details
    getPatient(
      `patient_id=${patient_id}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELHIS`
    ).then((response) => {
      console.log(response);
      setPatientData(response?.data);
      setActiveTabId(0);
    });
  }, []);

  // get Additional Data
  useEffect(() => {
    getAdditionalDetails(patient_id).then((response) => {
      console.log(response);

      setPatientOtherData(response.data);
    });
  }, []);
  // get Attachment file
  useEffect(() => {
    getPatientAttachment(patient_id).then((response) => {
      console.log(response);

      setPatientAttachmentData(response.data);
    });
  }, []);
  //get insurance file
  useEffect(() => {
    getInsuranceDetails(patient_id).then((response) => {
      console.log(response);
      setPatientInsuranceData(response.data);
    });
  }, []);
  //get family file
  useEffect(() => {
    getPatientFamily(
      `patient_id=${patient_id}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELREL`
    ).then((response) => {
      console.log(response);
      setPatientFamilyData(response.data);
    });
  }, []);
  //get Appointment file
  useEffect(() => {
    getAppointmentData(
      `patient_id=${patient_id}&start_date=&end_date=&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELHIS`
    ).then((response) => {
      console.log("response-appointment", response);
      setPatientAppointmentData(response.data);
    });
  }, []);
  return (
    <>
      <PageTitle
        subtitle="Manage Patient >"
        title="Details"
        urlLink="/app/patient"
        button={patientReadValue === "N" ? "Edit" : undefined}
        editIcon={patientReadValue === "N" ? true : undefined}
        onClick={patientReadValue === "N" ? handleEdit : undefined}
      />

      <Paper className={classes.iconsContainer}>
        <Tabs
          variant="scrollable"
          TabIndicatorProps={{
            style: { background: "#4E97FD",width:"150px" },
          }}
          scrollButtons="auto"
          value={activeTabId}
          onChange={(e, id) => setActiveTabId(id)}
          className={classes.iconsBar}
        >
          <Tab label="Personal Details" classes={{ root: classes.tab }} />
          <Tab label="Other Details" classes={{ root: classes.tab }} />
          <Tab label="Insurance" classes={{ root: classes.tab }} />
          {/* <Tab label="Prescriptions" classes={{ root: classes.tab }} /> */}
          <Tab label="Attachments" classes={{ root: classes.tab }} />
          <Tab label="Orders" classes={{ root: classes.tab }} />
          <Tab label="History" classes={{ root: classes.tab }} />
          <Tab label="Family" classes={{ root: classes.tab }} />
          <Tab label="Appointment History" classes={{ root: classes.tab }} />

          <Tab label="Custom Data" classes={{ root: classes.tab }} />
          <Tab label="Interactions" classes={{ root: classes.tab }} />
        </Tabs>
        {/* Personal Details */}
        {activeTabId === 0 && (
          <Grid container spacing={4}>
            <Grid
              className={classes.gridItem}
              item
              lg={12}
              md={12}
              xs={12}
              sm={12}   
            >
              <PatientProfile
                patientData={patientData}
                setPatientData={setPatientData}
                titleValues={titleValues}
                genderValues={genderValues}
                maritalValues={maritalValues}
                genderIdentificationValues={genderIdentificationValues}
                sexOrientationValues={sexOrientationValues}
                preferenceContactValues={preferenceContactValues}
                preferenceHourValues={preferenceHourValues}
                languageValues={languageValues}
                addressStatus={addressStatus}
                ethinicityValues={ethinicityValues}
                raceValues={raceValues}
                disabilityValues={disabilityValues}
                veteranValues={veteranValues}
                patientStatusValues={patientStatusValues}
                inactiveValues={inactiveValues}
                deceasedValues={deceasedValues}
                applicableRoleValues={applicableRoleValues}
              />
            </Grid>
          </Grid>
        )}
        {/* Other's Details */}
        {activeTabId === 1 && (
          <Grid container spacing={4}>
            <Grid item xs={12} lg={12} md={12} sm={12}>
              {/* <Widget> */}
              <OtherTabs
                patientOtherData={patientOtherData}
                setPatientOtherData={setPatientOtherData}
                relTypeValues={relTypeValues}
                genderValues={genderValues}
                phoneTypeValues={phoneTypeValues}
                referenceSourceValues={referenceSourceValues}
                thanksValues={thanksValues}
                gaurantorValues={gaurantorValues}
              />
              {/* </Widget> */}
            </Grid>
          </Grid>
        )}
        {/* Insurance */}
        {activeTabId === 2 && (
          <Grid container spacing={4}>
            <Grid item xs={12} lg={12} md={12} sm={12}>
              {/* <Widget> */}
              <InsuranceList
                patientInsuranceData={patientInsuranceData}
                setPatientInsuranceData={setPatientInsuranceData}
                genderValues={genderValues}
                insuranceValues={insuranceValues}
                additionalInsuranceValues={additionalInsuranceValues}
              />
              {/* </Widget> */}
            </Grid>
          </Grid>
        )}
        {/* Prescriptions */}
        {/* {activeTabId === 3 && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Widget>
                <center style={{ marginTop: "15px" }}>
                  <img
                    src={pageNotFound}
                    style={{ width: "360px", height: "280px" }}
                 alt="img" />
                </center>
              </Widget>
            </Grid>
          </Grid>
        )} */}
        {/* Attachments */}
        {activeTabId === 3 && (
          <Grid container spacing={4}>
            <Grid item xs={12} lg={12} md={12} sm={12}>
              {/* <Widget> */}
              <AttachmentList
                patientAttachmentData={patientAttachmentData}
                setPatientAttachmentData={setPatientAttachmentData}
              />
              {/* </Widget> */}
            </Grid>
          </Grid>
        )}
        {/* Orders */}
        {activeTabId === 4 && (
          <Grid container spacing={4}>
            <Grid item xs={12} lg={12} md={12} sm={12}>
              <Widget>
                <center style={{ marginTop: "15px" }}>
                  <img
                    src={pageNotFound}
                    style={{ width: "360px", height: "280px" }}
                    alt="img"
                  />
                </center>
              </Widget>
              {/* <Widget> */}
              {/* <OrderList /> */}
              {/* </Widget> */}
            </Grid>
          </Grid>
        )}
        {/* History */}
        {activeTabId === 5 && (
          <Grid container spacing={4}>
            <Grid item xs={12} lg={12} md={12} sm={12}>
              {/* <Widget>
                <center style={{ marginTop: "15px" }}>
                  <img
                    src={pageNotFound}
                    style={{ width: "360px", height: "280px" }}
                    alt="img" />
                </center>
              </Widget> */}
              {/* <Widget> */}
              <HistoryList patientId={patient_id} />
              {/* </Widget> */}
            </Grid>
          </Grid>
        )}
        {/* Family */}
        {activeTabId === 6 && (
          <Grid container spacing={4}>
            <Grid item xs={12} lg={12} md={12} sm={12}>
              {/* <Widget> */}
              <FamilyList
                patientFamilyData={patientFamilyData}
                setPatientFamilyData={setPatientFamilyData}
              />
              {/* </Widget> */}
            </Grid>
          </Grid>
        )}
        {/* Contact History */}
        {activeTabId === 7 && (
          <Grid container spacing={4}>
            <Grid item xs={12} lg={12} md={12} sm={12}>
              {/* <Widget> */}
              <AppointmentList
                setPatientAppointmentData={setPatientAppointmentData}
                patientAppointmentData={patientAppointmentData}
              />
              {/* </Widget> */}
            </Grid>
          </Grid>
        )}

        {/* Custom Data */}
        {activeTabId === 8 && (
          <Grid container spacing={4}>
            <Grid item xs={12} lg={12} md={12} sm={12}>
              <div style={{ padding: "0px 10px" }}>
                <CustomeDataList patientId={patient_id} />
              </div>
            </Grid>
          </Grid>
        )}
        {/* Interactions */}
        {activeTabId === 9 && (
          <Grid container spacing={4} lg={12} md={12} sm={12}>
            <Grid item xs={12}>
              {/* <Widget>
                <center style={{ marginTop: "15px" }}>
                  <img
                    src={pageNotFound}
                    style={{ width: "360px", height: "280px" }}
                    alt="img" />
                </center>
              </Widget> */}
              {/* <Widget> */}
              <IntractionList />
              {/* </Widget> */}
            </Grid>
          </Grid>
        )}
      </Paper>
    </>
  );
}
