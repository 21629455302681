import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

import { Grid } from "@material-ui/core";
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";

// Model
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { MoreVert as MoreIcon, Lens } from "@material-ui/icons";
// components
import PageTitle from "../../PageTitle/PageTitle";
import TableWidget from "../../Widget/TableWidget";


// styles
import useStyles from "./styles";

import TableDesign from "../../common/table/TableDesign";
// import ToggleActivationPopup from "./ToggleActivationPopup";
// import DeletePopup from "./DeletePopup";
import {
 
  getUserMenuDataList,
} from "../../../services/ServiceLayer";
import { loadState } from "../../../hooks/helper";

import ManageMenu from "./ManageMenu";
export default function MenuList() {
  const history = useHistory();
  // const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const createUpdateClient = (row) => {
    console.log("add client called");
    const userId = row.user_id;
    const userRole = row.user_role;
    const menuName = row.menu_name;
    const empName = row.employee_name;
    console.log("row", row);
    history.push(`/app/menu-edit/${userId}/${userRole}/${menuName}/${empName}`);
    // setselectclient(client);
    // // setModalShow(true);
    // setTitle("Edit Staff");yy
    // // setShowResult(true);
    // setOpen(true);
    // setAnchorEl(null);
  };


  // table Header
  const tableHeadings = [
    {
      label: "S. No.",
    },
    {
      label: "User Name",
      align: "left !important",
    },
    {
      label: "Employee Name",
    },
    {
      label: "User Role",
    },
    {
      label: "Menu Name",
    },
    {
      label: "Sub-Menu",
    },
    {
      label: "Menu Access",
    },
    {
      label: "Read Access",
    },
    {
      label: "Action",
    },
  ];
 
  var classes = useStyles();

  const [open, setOpen] = React.useState(false);
  //
  const [modalShow, setModalShow] = useState(false);
  const [selectclient, setselectclient] = useState(null);
  const [selectRow, setSelectRow] = useState(null);
  const [title, setTitle] = useState(null);

  const [toggleActivateModalShow, setToggleActivateModalShow] = useState(false);

  const [seubMenuList, setSubMenuList] = useState([]);

  //searching
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const filteredMenuList = seubMenuList.filter(
    (menu) =>
      (menu.user_name &&
        menu.user_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (menu.employee_name &&
        menu.employee_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (menu.menu_name &&
        menu.menu_name.toLowerCase().includes(searchTerm.toLowerCase()))
  );
  const addClient = () => {
    console.log("add client called");
    setselectclient(null);
    // setModalShow(true);
    setTitle("Add Client");
    // setShowResult(true);
    setOpen(true);
    setAnchorEl(null);
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpen(false);
    setToggleActivateModalShow(false);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

 
  useEffect(() => {
    const locationId = loadState().user.location_id;
    const userId = loadState().user.user_id;
    const userRole = loadState().user.user_role;

    getUserMenuDataList(
      `location_id=${locationId}&user_id=${userId}&user_role=${userRole}&opt_type=SELMEN`
    ).then((response) => {
      console.log(response);
      setSubMenuList(response.data);
    });
  }, []);

  return (
    <>
      <ManageMenu
        open={open}
        onHide={hideHandle}
        title={"Add Menu Access"}
        selectclient={selectclient}
        setModalShow={setModalShow}
      />

      <PageTitle
        title="Manage Menu Access"
        button="Add Menu Access"
        search="true"
        onClick={addClient}
        onChange={handleChange}
      />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {filteredMenuList && filteredMenuList.length ? (
                filteredMenuList.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          {row.user_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {row.employee_name}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.user_role}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.menu_name}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.sub_menu_name}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.menu_access}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.read_access}
                        </TableCell>

                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>

                          <Menu
                            className={classes.menuBox}
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => createUpdateClient(selectRow)}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              Edit
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
