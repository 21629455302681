import React, { useState, useEffect } from "react";
import { Button, Grid, Menu, MenuItem } from "@material-ui/core";

import { dateFormatConversion, loadState } from "../../../hooks/helper";
import PageTitle from "../../PageTitle/PageTitle";

import TableWidget from "../../Widget/TableWidget";

import TableDesign from "../../common/table/TableDesign";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
// styles
import useStyles from "../styles";
import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";
import ManageDisclosure from "./ManageDisclosure";
import { getPatientDisclosureData } from "../../../services/ServiceLayer";
import moment from "moment";
import DeleteDisclosurePopup from "./DeleteDisclosurePopup";

export default function Disclosure(props) {
  const tableHeadings = [
    {
      label: "#",
      align: "left",
    },
    {
      label: "Date",
      align: "left",
    },
    {
      label: "Discloure Type",
      align: "left",
    },
    {
      label: "Discloure Mode",
      align: "left",
    },
    {
      label: "Recipient",
      align: "left",
    },
    {
      label: "Discloure Description",
      align: "left",
    },
    {
      label: "Provided By",
      align: "left",
    },

    {
      label: "Action",
    },
  ];
  const { patientId, disclosureType, doctorList, patientData,enable,examMode } = props;
  console.log("props", props);
  const [open, setOpen] = React.useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [selectRow, setSelectRow] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const [selectclient, setselectclient] = useState(null);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [patientAttachmentData, setPatientAttachmentData] = useState([]);
  const [title, setTitle] = useState(null);
  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  useEffect(() => {
    getPatientDisclosureData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELDIS`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setPatientAttachmentData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);
  useEffect(() => {
    getPatientDisclosureData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELDIS`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setPatientAttachmentData(response?.data);
        setDataUpdated(false);
      } else {
        // setIsLoader(false);
      }
    });
  }, [dataUpdated]);
  const hideHandle = () => {
    console.log("hidehandle called");
    setDeleteModalShow(false);
    setOpen(false);
    setDataUpdated(true);
  };
  const addDiscloModel = () => {
    setOpen(true);
  };
  const handleEdit = (rec) => {
    setOpen(true);
    setAnchorEl(null);
    setselectclient(rec);
    setTitle("Edit Problem");
  };
  const deleteEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);
    setselectclient(rec);
    setDeleteModalShow(true);
    setTitle("Delete Problem");
  };
  return (
    <>
      <ManageDisclosure
      patientData={patientData}
        disclosureType={disclosureType}
        selectclient={selectclient}
        patientId={patientId}
        doctorList={doctorList}
        examMode={examMode}
        open={open}
        onHide={hideHandle}
        title={selectclient ? "Update Disclosure" : "Add Disclosure"}
      />
      <DeleteDisclosurePopup
        onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        selectclient={selectclient}
        patientId={patientId}
        setDeleteModalShow={setDeleteModalShow}
      />
      <Grid container spacing={4} style={{ width: "100%" }}>
      {enable && 
        <Grid>
          {" "}
          <div
            className={classes.pageTitleContainer}
            style={{ display: "flex", justifyContent: "end" }}
          >
            <Button
              style={{
                marginRight: "8px",
                fontSize: "10px",
                position: "absolute",
                right: "60px",
                borderRadius: "2px",
              }}
              classes={{ root: classes.button }}
              variant="contained"
              color="secondary"
              onClick={addDiscloModel}
            >
              Add Disclosure
            </Button>
          </div>
        </Grid>}
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {patientAttachmentData && patientAttachmentData?.length ? (
                patientAttachmentData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                        >
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {dateFormatConversion(row.dis_date)}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.dis_type_val}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.dis_mode_val}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row?.dis_recipient}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.dis_description}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.dis_providedby_val}
                        </TableCell>

                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            id="basic-button"
                            aria-controls={openDown ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openDown ? "true" : undefined}
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem onClick={() => handleEdit(selectRow)}>
                              Edit
                            </MenuItem>

                            <MenuItem
                              onClick={() => deleteEventHandler(selectRow)}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
