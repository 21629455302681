import React, { useState, useEffect } from "react";

import { TableRow, TableCell } from "@material-ui/core";
import TableDesign from "../../../common/table/TableDesign";

import { Grid, InputBase, Typography } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";
import { DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../common/modal/Popup";
// styles
import useStyles from "../styles";
import { getInvtContactLense } from "../../../../services/ServiceLayer";

import { loadState } from "../../../../hooks/helper";

import classNames from "classnames";
import TableWidget from "../../../Widget/TableWidget";
export default function ExsitingContactOrder(props) {
  var classes = useStyles();

  const [maxWidth, setMaxWidth] = React.useState("md");

  const [isLoader, setIsLoader] = useState(false);
  const [contactListData, setContactListData] = useState([]);
  var [isSearchOpen, setSearchOpen] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);

  const [dataUpdated, setDataUpdated] = useState(false);

  const [inventoryReadValue, setInventoryReadValue] = useState();
  const readAccessData = loadState().menu;
  useEffect(() => {
    console.log("readAccessData", readAccessData);
    const inventorySection = readAccessData.find(
      (item) => item.label === "Inventory"
    );
    if (inventorySection) {
      const inventoryChildren = inventorySection.children;

      const inventoryReadAcess = inventoryChildren.find(
        (child) => child.label === "Inventory"
      ).read_access;
      setInventoryReadValue(inventoryReadAcess);
    }
  }, [readAccessData]);

  const userData = loadState().user;
  const userRole = loadState().user.user_role;

  useEffect(() => {
    getInvtContactLense(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELCNL`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setIsLoader(false);
        setContactListData(response?.data);
      } else {
        setIsLoader(false);
      }
    });
  }, []);
  useEffect(() => {
    getInvtContactLense(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELCNL`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setIsLoader(false);
        setContactListData(response?.data);
      } else {
        setIsLoader(false);
      }
    });
    setDataUpdated(false);
  }, [dataUpdated]);

  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Lens Name ",
      secondaryLabel: "(SKU)",
      align: "left !important",
    },
    {
      label: "Lens Type",
      secondaryLabel: "(Replacement Schedule)",
    },

    {
      label: "Manufacturer",
      secondaryLabel: "(Brand)",
    },

    {
      label: "Retail Price",
      secondaryLabel: "(Returnable_val)",
    },
    // {
    //   label: "Trial Lens Name",
    // },

    {
      label: "Status",
      secondaryLabel: "(Source)",
    },

    {
      label: "Action",
    },
  ];

  const handleRowClick = (row) => {
    setSelectedRow(row);
  };

  //searching
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredDataList = contactListData.filter((item) => {
    // Check if each property is not null or undefined before using toLowerCase()
    const lensName = item.lensname ? item.lensname.toLowerCase() : "";

    return lensName.includes(searchTerm.toLowerCase());
  });

  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
  };

  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={<></>}
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <div className={classes.pageTitleContainer}>
              <Typography className={classes.typo} weight="medium">
                <div
                  className={classNames(classes.search, {
                    [classes.searchFocused]: isSearchOpen,
                  })}
                >
                  <div
                    className={classNames(classes.searchIcon, {
                      [classes.searchIconOpened]: isSearchOpen,
                    })}
                    onClick={() => setSearchOpen(!isSearchOpen)}
                  >
                    <SearchIcon classes={{ root: classes.headerIcon }} />
                  </div>
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    onChange={handleChange}
                  />
                </div>
              </Typography>
            </div>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TableWidget noBodyPadding>
                  <TableDesign
                    {...{
                      title: "List of All Clients",
                      tableHeadings,
                    }}
                  >
                    {filteredDataList && filteredDataList.length ? (
                      filteredDataList.map((row, i) => {
                        if (row)
                          return (
                            <TableRow
                              onClick={() => handleRowClick(row)}
                              key={i}
                              className={classes.tableBodyCell}
                            >
                              <TableCell className={classes.tableBodyCell}>
                                {i + 1}
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableBodyCell}
                                style={{ cursor: "pointer" }}
                              >
                                {row.lens_name}
                                <br />
                                {row.lense_sku && (
                                  <span style={{ color: "#4E97FD" }}>
                                    ({row.lense_sku})
                                  </span>
                                )}
                              </TableCell>

                              <TableCell className={classes.tableBodyCell}>
                                {row.lense_units_val}
                                <br />
                                {row.replacement_schedule_val && (
                                  <span style={{ color: "#4E97FD" }}>
                                    ({row.replacement_schedule_val})
                                  </span>
                                )}
                              </TableCell>
                              <TableCell className={classes.tableBodyCell}>
                                {row?.manufacturer_name}

                                <br />
                                {row.lense_brand_val && (
                                  <span style={{ color: "#4E97FD" }}>
                                    ({row.lense_brand_val})
                                  </span>
                                )}
                              </TableCell>

                              <TableCell className={classes.tableBodyCell}>
                                {row?.retail_price}
                                <br />
                                {row.returnable_val && (
                                  <span style={{ color: "#4E97FD" }}>
                                    ({row.returnable_val === "Y" ? "Yes" : "No"}
                                    )
                                  </span>
                                )}
                              </TableCell>

                              {/* <TableCell className={classes.tableBodyCell}>
                          {row?.trial_name}
                        </TableCell> */}
                              <TableCell className={classes.tableBodyCell}>
                                {row.is_active === "Y" ? (
                                  <span style={{ color: "green" }}>Active</span>
                                ) : (
                                  <span style={{ color: "red" }}>Inactive</span>
                                )}
                                <br />
                                {row.inv_source_val && (
                                  <span style={{ color: "#4E97FD" }}>
                                    ({row?.inv_source_val})
                                  </span>
                                )}
                              </TableCell>

                              <TableCell className={classes.tableBodyCell}>
                                <span
                                  onClick={() => handleRowClick(row)}
                                  style={{
                                    cursor: "pointer",
                                    border: "1px solid #5DB3D9",
                                    borderRadius: "25px",
                                    padding: "6px 10px",
                                    color: "#5db3d9",
                                  }}
                                >
                                  Select
                                </span>
                              </TableCell>
                            </TableRow>
                          );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan="8">No Result Found</TableCell>
                      </TableRow>
                    )}
                  </TableDesign>
                </TableWidget>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
