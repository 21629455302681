import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";


import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
// icons sets
import "font-awesome/css/font-awesome.min.css";
// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

import dashboard from '../../assets/dashboard.png';
import user from '../../assets/manageUser.png';
import report from '../../assets/report.png';
import project from '../../assets/project.png';



// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

import { loadState } from '../../hooks/helper'
import { AiOutlineMenuFold } from "react-icons/ai";
const structure = loadState() !== null ? loadState().menu :
  // const structure =
  [
    { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <img src={dashboard} alt="img" /> },

    {
      id: 1,
      label: "Schedule",
      link: "/app/schedule",
      icon: <i className="fa fa-cubes" />,

    },
    {
      id: 2,
      label: "Patient",
      link: "#",
      icon: <img src={user} alt="img" />,
      children: [
        { label: "Patient List", link: "/app/patient" },
        { label: "ADD New Patient", link: "/app/add-patient" },
      ],
    },
 
    {
      id: 4,
      label: "Inventory",
      link: "#",
      icon: <img src={report} alt="" />,
      children: [
        { label: "Inventory", link: "/app/inventory" },
        { label: "Print Inventory", link: "#" },
        { label: "Receiving", link: "/app/inventory-receiving" },
      ],
    },
    {
      id: 3,
      label: "Exam",
      link: "#",
      icon: <img src={project} alt="" />,
      children: [
        { label: "New Exam", link: "#" },
        { label: "Print Exam", link: "#" },
      ],
    },
    {
      id: 5,
      label: "Settings",
      link: "#",
      icon: <i className="fa fa-sliders" />,
      children: [
        { label: "Company Reg", link: "/app/companys" },
        { label: "Tax Rate registration", link: "/app/tax-rate" },
        { label: "Schedule Meta data", link: "/app/schedule-data" },
        { label: "Menu access", link: "/app/menu" },
        { label: "History Config", link: "/app/history-config" },
        { label: "Doctor Registration", link: "/app/doctors" },
        { label: "Custom Data", link: "/app/custom-data" },
      ],
    },


  ];





function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <AiOutlineMenuFold
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
