import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Grid } from "@material-ui/core";
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";

import { MoreVert as MoreIcon, Lens } from "@material-ui/icons";
// components
import PageTitle from "../../PageTitle/PageTitle";
import TableWidget from "../../Widget/TableWidget";

// styles
import useStyles from "./styles";

import TableDesign from "../../common/table/TableDesign";
import {
  getDoctorList,
  getCompanyStoreData,
} from "../../../services/ServiceLayer";
import { dateFormatConversion,loadState } from "../../../hooks/helper";
import ManageDoctor from "./ManageDoctor";
import DeletePopup from "./DeletePopup";
import ManageStaffAttendance from "./ManageStaffAttendance";
export default function DoctorList() {
  const userData = loadState().user;
  const userRole =loadState().user.user_role;
 
  // const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const createUpdateClient = (client) => {
    console.log("add client called");
    setselectclient(client);
    // setModalShow(true);
    setTitle("Edit Staff");
    // setShowResult(true);
    setOpen(true);
    setAnchorEl(null);
  };

  // table Header
  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "First Name",
      align: "left !important",
    },
    {
      label: "Middle Name",
      align: "left !important",
    },
    {
      label: "Last Name",
      align: "left !important",
    },
    {
      label: "Email",
      align: "left !important",
    },
    {
      label: "Company Name",
      align: "left !important",
    },
    {
      label: "Location Name",
      align: "left !important",
    },
    {
      label: "Joining Date",
    },
    {
      label: "Specialist",
    },
    {
      label: "Contact No.",
    },
    {
      label: "Action",
    },
  ];

  var classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [openTab, setOpenTab] = React.useState(false);
  //
  const [modalShow, setModalShow] = useState(false);
  const [selectclient, setselectclient] = useState(null);
  const [selectRow, setSelectRow] = useState(null);
  const [title, setTitle] = useState(null);

  const [doctorList, setDoctorList] = useState([]);
  //
  const [locationName, setLocationName] = useState([]);

  const [toggleActivateModalShow, setToggleActivateModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const [dataUpdated, setDataUpdated] = useState(false);
  //searching
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const filteredStaffList = doctorList.filter(
    (staff) =>
      staff.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      staff.middle_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      staff.last_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const addClient = () => {
    console.log("add client called");
    setselectclient(null);
    // setModalShow(true);
    setTitle("Add Client");
    // setShowResult(true);
    setOpen(true);
    setAnchorEl(null);
    // history.push(`/app/add-doctors`);
  };
  const deleteEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);

    setselectclient(rec);
    setDeleteModalShow(true);
    setTitle("Delete Staff");
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpen(false);
    setOpenTab(false);
    setToggleActivateModalShow(false);
    setDataUpdated(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenTab(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    getDoctorList(`user_id=${userData?.user_id}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSCH`).then((response) => {
      console.log(response);
      setDoctorList(response?.data);
    });
  }, []);
  useEffect(() => {
    getCompanyStoreData(`location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELLOC`).then((response) => {
      console.log("response", response);
      const storeInfo = response?.data.map((company) => company.store_info);
      setLocationName(storeInfo);
    });
  }, []);
  useEffect(() => {
    getDoctorList(`user_id=${userData?.user_id}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSCH`).then((response) => {
      console.log(response);
      setDoctorList(response.data);
      setDataUpdated(false);
    });
  }, [dataUpdated]);
const openTabModel=(client)=>{
  setselectclient(client);
  setOpenTab(true);
  setAnchorEl(null);
  setTitle("Shashank");

}

const changePassword = (row)=> {
  window.location= `/app/change-password/${row.staff_id}/${row.first_name} ${row.last_name}`
}
  return (
    <>
    <ManageStaffAttendance
    open={openTab}
    title={ "View Attendance"}
    selectclient={selectclient}

    onHide={hideHandle}
    />
      <ManageDoctor
        open={open}
        onHide={hideHandle}
        title={selectclient ? "Edit Staff" : "New Staff Registration"}
        selectclient={selectclient}
        setModalShow={setModalShow}
        locationName={locationName}
      />
      <DeletePopup
        onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        selectclient={selectclient}
        setDeleteModalShow={setDeleteModalShow}
      />

      <PageTitle
        title="Staff Registration"
        button="Staff Registration"
        searchPlaceholder="Enter name and email"
        search="true"
        onClick={addClient}
        onChange={handleChange}
      />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {filteredStaffList && filteredStaffList.length ? (
                filteredStaffList.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          {row.first_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          {row.middle_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          {row.last_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.email}
                        </TableCell>
                        <TableCell
                          className={classes.tableBodyCell}
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          {row.company_name}
                        </TableCell>
                        <TableCell
                          className={classes.tableBodyCell}
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          {row.store_name}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {dateFormatConversion(row.joining_date)}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.specialist}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.contact_cell}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>

                          <Menu
                            className={classes.menuBox}
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => createUpdateClient(selectRow)}
                              // onClick={createUpdateClient(selectRow)}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              Update
                            </MenuItem>
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => deleteEventHandler(selectRow)}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              Delete
                            </MenuItem>
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => changePassword(selectRow)}
                              
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              Reset Password
                            </MenuItem>
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => openTabModel(selectRow)}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              View Attendance
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
