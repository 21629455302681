import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";

import moment from "moment";
import { formReferralElements } from "./Patient_Constant";

import useStyles from "../styles";
export default function Referral({
  patientOtherData,
  setPatientOtherData,
  referenceSourceValues,
  phoneTypeValues,
  genderValues,
  thanksValues,
}) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.formBox}>
      {formReferralElements.map((element, i) => {
        return (
          <>
            <Grid
              item
              xs={12}
              lg={2}
              sm={2}
              md={element.mdSize}
              className={classes.textGroup}
            >
              <Typography className={classes.formHeading}>
                {element.label}{" "}
                {element.required === "true" ? (
                  <span className={classes.required}>*</span>
                ) : null}
              </Typography>

              {element.type === "select" ? (
                <FormControl fullWidth className={classes.dropdownFormControl}>
                  <Select
                    name={element.key}
                    className={classes.selectDropdown}
                    placeholder={element.placeHolder}
                    value={
                      patientOtherData && patientOtherData[element.key] === null
                        ? ""
                        : patientOtherData && patientOtherData[element.key]
                    }
                    onChange={async (e) => {
                      setPatientOtherData({
                        ...patientOtherData,
                        [element.key]: e.target.value,
                      });
                    }}
                  >
                    <MenuItem disabled value="">Select</MenuItem>
                    {element.key == "referral_gender" && genderValues
                      ? genderValues.map((item) => (
                          <MenuItem
                            key={item.attribute_id}
                            value={item.attribute_id}
                          >
                            {item.attribute_val}
                          </MenuItem>
                        ))
                      : element.key == "referral_source" &&
                        referenceSourceValues
                      ? referenceSourceValues.map((item) => (
                          <MenuItem
                            key={item.attribute_id}
                            value={item.attribute_id}
                          >
                            {item.attribute_val}
                          </MenuItem>
                        ))
                      : element.key == "referral_con_type" && phoneTypeValues
                      ? phoneTypeValues.map((item) => (
                          <MenuItem
                            key={item.attribute_id}
                            value={item.attribute_id}
                          >
                            {item.attribute_val}
                          </MenuItem>
                        ))
                      : element.key == "referral_thanks_note" && thanksValues
                      ? thanksValues.map((item) => (
                          <MenuItem
                            key={item.attribute_id}
                            value={item.attribute_id}
                          >
                            {item.attribute_val}
                          </MenuItem>
                        ))
                      : element.key == "referral_state" && element.options
                      ? element.options.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              ) : element.type === "textarea" ? (
                <TextField
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder="Type here..."
                  variant="outlined"
                  multiline
                  rows={3}
                  inputProps={{
                    style: {
                      fontSize: "12px",
                    },
                    maxLength: `${element.maxLength}`,
                  }}
                  value={
                    patientOtherData && patientOtherData[element.key] === null
                      ? ""
                      : patientOtherData && patientOtherData[element.key]
                  }
                  onChange={async (e) => {
                    setPatientOtherData({
                      ...patientOtherData,
                      [element.key]: e.target.value,
                    });
                  }}
                />
              ) : element.type === "date" ||
                element.type === "datetime-local" ? (
                <TextField
                  id={element.key}
                  name={element.key}
                  placeholder={element.placeHolder}
                  type={element.type}
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    min: `${currentDateTime}`,
                    max: `${currentDate}`,
                    style: {
                      height: "12px",
                      fontSize: "12px",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={
                    patientOtherData && patientOtherData[element.key] === null
                      ? ""
                      : patientOtherData && patientOtherData[element.key]
                  }
                  onChange={async (e) => {
                    setPatientOtherData({
                      ...patientOtherData,
                      [element.key]: e.target.value,
                    });
                  }}
                  onKeyDown={(e) => {
                    e.preventDefault(); // Prevent manual typing
                  }}
                />
              ) : (
                <TextField
                  required
                  name={element.key}
                  placeholder={element.placeHolder}
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  type={element.type}
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "12px",
                      fontSize: "12px",
                    },
                    maxLength: `${element.maxLength}`,
                    onKeyPress: element.onKeyPress, // Add the event handler
                  }}
                  value={
                    patientOtherData && patientOtherData[element.key] === null
                      ? ""
                      : patientOtherData && patientOtherData[element.key]
                  }
                  onChange={async (e) => {
                    setPatientOtherData({
                      ...patientOtherData,
                      [element.key]: e.target.value,
                    });
                  }}
                />
              )}
            </Grid>
          </>
        );
      })}
    </Grid>
  );
}
