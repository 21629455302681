import React, { useState, useEffect } from "react";
import { Button, Grid, Menu, MenuItem } from "@material-ui/core";

import { dateFormatConversion } from "../../../hooks/helper";


import TableWidget from "../../Widget/TableWidget";

import TableDesign from "../../common/table/TableDesign";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
// styles
import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";

import useStyles from "../styles";

import ManageContact from "./ManageContact";

export default function ContactHistory() {
  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Exam Name",
      align: "left",
    },
    {
      label: "OD",
      align: "left",
    },
    {
      label: "Sph",
      align: "left",
    },
    {
      label: "Cyl",
      align: "left",
    },
    {
      label: "Axis",
      align: "left",
    },
    {
      label: "Add",
      align: "left",
    },
    {
      label: "HPrism",
      align: "left",
    },
    {
      label: "HBase",
      align: "left",
    },
    {
      label: "VPrism",
      align: "left",
    },
    {
      label: "VBase",
      align: "left",
    },
    {
      label: "DVA",
      align: "left",
    },
    {
      label: "NVA",
      align: "left",
    },
    {
      label: "Updated By",
      align: "left",
    },
    {
      label: "Action",
    },
  ];
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const [modalShow, setModalShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectRow, setSelectRow] = useState(null);
  const [title, setTitle] = useState(null);
  const [patientAttachmentData, setPatientAttachmentData] = useState([]);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
 const addRefractiveModel=()=>{
  setOpen(true)
 }
 const hideHandle = () => {
  console.log("hidehandle called");
  setModalShow(false);
  setOpen(false);
  // setDataUpdated(true);
  // setselectclient(null);
};
  return (
    <>
    <ManageContact open={open}
        onHide={hideHandle}
        title={title ? title :"Trial Contact Lens"}
        setTitle={setTitle}
        // selectclient={selectclient}
        setModalShow={setModalShow}
        // allergyOutcome={allergyOutcome}
        // allergyReaction={allergyReaction}
        // allergySeverity={allergySeverity}
        // patientId={patientId}
        />
     <Grid container spacing={4} style={{ width: "100%" }}>
       
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {patientAttachmentData && patientAttachmentData?.length ? (
                patientAttachmentData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.problem_desc}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {dateFormatConversion(row.st_date)}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {dateFormatConversion(row.end_date)}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.icd_10}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.status_val}
                        </TableCell>

                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            id="basic-button"
                            aria-controls={openDown ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openDown ? "true" : undefined}
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            {/* <MenuItem onClick={()=>handleEdit(selectRow)}>Edit</MenuItem>

                            <MenuItem onClick={() => deleteEventHandler(selectRow)}>Delete</MenuItem> */}
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  )
}
