import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@material-ui/core";
import { TableRow, TableCell } from "@material-ui/core";
import TableDesign from "../../common/table/TableDesign";
import { useHistory } from "react-router-dom";
// data

import {
  dateFormatConversion,
  calculateAgeFromDate,
} from "../../../hooks/helper";
// Model
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../common/modal/Popup";
// styles
import useStyles from "./styles";
import { getPatientsSearch } from "../../../services/ServiceLayer";
import moment from "moment";
import {
  scheduleFieldValidation,
  currentUser,
  loadState,
} from "../../../hooks/helper";
import { tableHeadings, tableHeadings1 } from "./order_constant";

export default function ManageOrder(props) {
    const history = useHistory();
  const [selectedDate, setSelectedDate] = useState();
  const [age, setAge] = useState({ years: 0, months: 0 });
  useEffect(() => {
    console.log("selectedDate", selectedDate);

    const calculatedAge = calculateAgeFromDate(selectedDate);
    setAge(calculatedAge);
  }, [selectedDate]);
  console.log("props-24", props);

  toast.configure();

  const { selectSchedule, selectedLocation } = props;
  console.log("selectclient", selectSchedule);
  console.log("selectedLocation", selectedLocation);
  var classes = useStyles();
  // list meta data

  // disable past dates
  const [selectedLastName, setSelectedLastName] = useState();
  const [selectedMobileNumber, setSelectedMobileNumber] = useState("");
  // delete model

  const [radioValue, setRadioValue] = useState("New");
  const [modalShow1, setModalShow1] = useState(false);

  const [maxWidth, setMaxWidth] = React.useState("md");
  // const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [selectPatientSchedule, setSelectPatientSchedule] = useState("");
  const [patientSearchList, setPatientSearchList] = useState("");

  const hideHandler = () => {
    console.log("hide handler called");

    setSelectedLastName(null);
    setSelectedMobileNumber("");

    setPatientSearchList("");
    props.onHide();
  };

  const handleSearch = () => {
    getPatientsSearch(
      `contact_cell=${
        selectedMobileNumber === undefined || selectedMobileNumber == ""
          ? "0"
          : selectedMobileNumber
      }&location_id=${
        selectedLocation === undefined || selectedLocation === ""
          ? "0"
          : selectedLocation}&location_id=${
        selectedLocation === undefined || selectedLocation === ""
          ? "0"
          : selectedLocation}&lastName=${
        selectedLastName === undefined || selectedLastName === ""
          ? ""
          : selectedLastName
      }`
    ).then((response) => {
      console.log(response);
      if (response?.status.description === "") {
        setPatientSearchList(response?.data);
      } else {
        toast.error(response?.status.description, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const createUpdateClient = (patient) => {
    console.log("patient", patient);
    setModalShow1(true);

    setSelectPatientSchedule([patient, selectSchedule]);
    // setShow1(true);
    setOpen1(true);
    props.onHide();
  };

  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={<></>}
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid container spacing={1}>
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={5} className={classes.textGroup}>
                    {/* org name */}

                    <TextField
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      placeholder="Enter Last Name"
                      variant="outlined"
                      value={selectedLastName}
                      onChange={(e) => {
                        console.log("selectedLocation", e.target.value);
                        setSelectedLastName(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={5} className={classes.textGroup}>
                    <TextField
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      placeholder="Enter Contact Number"
                      variant="outlined"
                      value={selectedMobileNumber}
                      onChange={(e) => {
                        console.log("selectedLocation", e.target.value);
                        setSelectedMobileNumber(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} className={classes.textGroup}>
                    <Button
                      variant="contained"
                      size="medium"
                      color="secondary"
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
                <div style={{ marginTop: "10px", width: "inherit" }}>
                  <>
                    {patientSearchList ? (
                      <TableDesign
                        {...{
                          title: "List of All Clients",
                          tableHeadings,
                          tableHeadings1,
                        }}
                      >
                        {patientSearchList && patientSearchList.length ? (
                          patientSearchList.map((row, i) => {
                            if (row)
                              return (
                                <TableRow
                                  onClick={() => createUpdateClient(row)}
                                  key={i}
                                  className={classes.tableBodyCell}
                                >
                                  <TableCell className={classes.tableBodyCell}>
                                    {i + 1}
                                  </TableCell>

                                  <TableCell
                                    align="left"
                                    className={classes.tableBodyCell}
                                    style={{
                                      cursor: "pointer",
                                      textAlign: "left",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    {row.first_name} {row.last_name}
                                  </TableCell>

                                  <TableCell
                                    align="left"
                                    style={{ cursor: "pointer" }}
                                    className={classes.tableBodyCell}
                                  >
                                    {row.contact_cell}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    style={{ cursor: "pointer" }}
                                    className={classes.tableBodyCell}
                                  >
                                    {dateFormatConversion(row.dateofbirth)}
                                  </TableCell>

                                  <TableCell
                                    onClick={() =>
                                      history.push(
                                        `/app/order-details/${row.patient_id}`
                                      )
                                    }
                                    align="left"
                                    style={{ cursor: "pointer" }}
                                    className={classes.tableBodyCell}
                                  ><span style={{border:'1px solid #5DB3D9', borderRadius:'25px',padding:'6px 10px', color:'#5db3d9'}}>
                                     Order Now
                                  </span>
                                   
                                  </TableCell>
                                </TableRow>
                              );
                          })
                        ) : (
                          <TableRow>
                            <TableCell colSpan="8">No Result Found</TableCell>
                          </TableRow>
                        )}
                      </TableDesign>
                    ) : (
                      ""
                    )}
                  </>
                </div>
              </>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
