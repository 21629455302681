import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";

import { dateFormatConversion } from "../../../../hooks/helper";
import PageTitle from "../../../PageTitle/PageTitle";

import ManageAttachmentFile from "./ManageAttachmentFile";
import TableWidget from "../../../Widget/TableWidget";

import { getPatientAttachment } from "../../../../services/ServiceLayer";
import TableDesign from "../../../common/table/TableDesign";
import { TableRow, TableCell } from "@material-ui/core";
// styles
import useStyles from "../styles";

export default function AttachmentList(props) {
  const tableHeadings = [
    {
      label: "S. No.",
    },
    {
      label: "File Name",
      align: "left",
    },
    {
      label: "File Category",
      align: "left",
    },
    {
      label: "Description",
      align: "left",
    },
    {
      label: "Locked",
      align: "left",
    },
    {
      label: "Created Date",
      align: "left",
    },
    {
      label: "Updated By",
      align: "left",
    },
  ];
  const { patientId } = props;
  console.log("props", props);
  const [open, setOpen] = React.useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectclient, setselectclient] = useState(null);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [patientAttachmentData, setPatientAttachmentData] = useState([]);
  const [title, setTitle] = useState(null);
  var classes = useStyles();

  const addFile = () => {
    console.log("add client called");
    setselectclient(null);
    setTitle("Add Client");
    setOpen(true);
    setAnchorEl(null);
  };
  console.log("patientId", patientId);
  useEffect(() => {
    getPatientAttachment(patientId).then((response) => {
      console.log(response);

      setPatientAttachmentData(response.data);
    });
  }, []);

  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpen(false);
    setDataUpdated(true);
  };
  useEffect(() => {
    getPatientAttachment(patientId).then((response) => {
      console.log(response);

      setPatientAttachmentData(response.data);
      setDataUpdated(false);
    });
  }, [dataUpdated]);

  return (
    <div className="container">
      <ManageAttachmentFile
        open={open}
        patientId={patientId}
        onHide={hideHandle}
        title={"Add File"}
        selectclient={selectclient}
        setModalShow={setModalShow}
      />
      <PageTitle title="Manage File" button="Add File" onClick={addFile} />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {patientAttachmentData && patientAttachmentData?.length ? (
                patientAttachmentData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          <a
                            href={row.file_path}
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            {row.file_name}
                          </a>
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.file_category}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.file_description}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.is_locked}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {dateFormatConversion(row.created_dt)}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.updated_by}
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </div>
  );
}
