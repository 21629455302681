import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  TableRow,
  Grid,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Tabs,
  FormControlLabel,
  Radio,
  Tab,
  RadioGroup,
  Typography,
  TextField,
  Select,
  FormControl,
  Button,
} from "@material-ui/core";

import moment from "moment";
import { currentUser, loadState } from "../../../hooks/helper";
import { toast } from "react-toastify";
// Model
import { medicalHistory } from "../Exam_Constant";
import useStyles from "../styles";
import { MoreVert as MoreIcon, Lens } from "@material-ui/icons";
import {
  getMedicalHistory,
  postMedicalHistory,
} from "../../../services/ServiceLayer";

export default function MedicalHistory({ selectclient, patientId,enable,medicalBtnUnable,setMedicalBtnUnable }) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  const history = useHistory();
  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [actionType, setActionType] = useState("ADDMHI");
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [discardUpdate, setDiscardUpdate] = useState(false);
  const [showDiscard, setShowDiscard] = useState(false);
  const [medicalHistoryDetail, setMedicalHistoryDetail] = useState({
    pat_medhist_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    med_weightloss: "",
    med_lossofappetite: "",
    med_sleepapnea: "",
    med_covid19: "",
    neu_headaches: "",
    neu_migraine: "",
    neu_seizures: "",
    ent_sinusitis: "",
    ent_hearingloss: "",
    ent_laryngitis: "",
    end_thyroiddisease: "",
    end_otherglands: "",
    end_respiratory: "",
    end_asthma: "",
    end_chronicbronchitis: "",
    end_emphysema: "",
    vas_diabetes: "",
    vas_heartpain: "",
    vas_highbp: "",
    vas_vasculardisease: "",
    gas_acidreflux: "",
    gas_ulcer: "",
    gas_celiacdisease: "",
    gen_genitalsdisease: "",
    gen_kidneydisease: "",
    gen_bladdardisease: "",
    mus_rheumatoidarthritis: "",
    mus_osteoporosis: "",
    mus_musculardystrophy: "",
    mus_jointpain: "",
    lym_anemia: "",
    lym_bleedingproblems: "",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: actionType,
  });
  const [medicalData, setMedicalData] = useState([]);
  useEffect(() => {
    getMedicalHistory(
      `location_id=${userData?.location_id}&patient_id=${patientId}&user_role=${userRole}&opt_type=SELMHI`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setMedicalData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);
  useEffect(() => {
    console.log("medicalData",medicalData);
    if (medicalData && medicalData.length > 0) {
      setMedicalHistoryDetail({
        pat_medhist_id: medicalData[0]?.pat_medhist_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        med_weightloss: medicalData[0]?.med_weightloss,
        med_lossofappetite: medicalData[0]?.med_lossofappetite,
        med_sleepapnea: medicalData[0]?.med_sleepapnea,
        med_covid19: medicalData[0]?.med_covid19,
        neu_headaches: medicalData[0]?.neu_headaches,
        neu_migraine: medicalData[0]?.neu_migraine,
        neu_seizures: medicalData[0]?.neu_seizures,
        ent_sinusitis: medicalData[0]?.ent_sinusitis,
        ent_hearingloss: medicalData[0]?.ent_hearingloss,
        ent_laryngitis: medicalData[0]?.ent_laryngitis,
        end_thyroiddisease: medicalData[0]?.end_thyroiddisease,
        end_otherglands: medicalData[0]?.end_otherglands,
        end_respiratory: medicalData[0]?.end_respiratory,
        end_asthma: medicalData[0]?.end_asthma,
        end_chronicbronchitis: medicalData[0]?.end_chronicbronchitis,
        end_emphysema: medicalData[0]?.end_emphysema,
        vas_diabetes: medicalData[0]?.vas_diabetes,
        vas_heartpain: medicalData[0]?.vas_heartpain,
        vas_highbp: medicalData[0]?.vas_highbp,
        vas_vasculardisease: medicalData[0]?.vas_vasculardisease,
        gas_acidreflux: medicalData[0]?.gas_acidreflux,
        gas_ulcer: medicalData[0]?.gas_ulcer,
        gas_celiacdisease: medicalData[0]?.gas_celiacdisease,
        gen_genitalsdisease: medicalData[0]?.gen_genitalsdisease,
        gen_kidneydisease: medicalData[0]?.gen_kidneydisease,
        gen_bladdardisease: medicalData[0]?.gen_bladdardisease,
        mus_rheumatoidarthritis: medicalData[0]?.mus_rheumatoidarthritis,
        mus_osteoporosis: medicalData[0]?.mus_osteoporosis,
        mus_musculardystrophy: medicalData[0]?.mus_musculardystrophy,
        mus_jointpain: medicalData[0]?.mus_jointpain,
        lym_anemia: medicalData[0]?.lym_anemia,
        lym_bleedingproblems: medicalData[0]?.lym_bleedingproblems,
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: "UPDMHI",
      });
      setDiscardUpdate(false);
    }
  }, [medicalData,discardUpdate]);
  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    // if (!isError() && error !== null && isActionCalled) {
    if (isActionCalled) {
      postMedicalHistory(medicalHistoryDetail).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Medical history added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setMedicalBtnUnable(false);
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    // } else {
    //   setIsActionCalled(false);
    // }

    setIsActionCalled(false);
  }, [isActionCalled]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAllSelected = (selectedValue) => {
    setMedicalHistoryDetail({
      ...medicalHistoryDetail,
      med_weightloss: selectedValue,
      med_lossofappetite: selectedValue,
      med_sleepapnea: selectedValue,
      med_covid19: selectedValue,
      neu_headaches: selectedValue,
      neu_migraine: selectedValue,
      neu_seizures: selectedValue,
      ent_sinusitis: selectedValue,
      ent_hearingloss: selectedValue,
      ent_laryngitis: selectedValue,
      end_thyroiddisease: selectedValue,
      end_otherglands: selectedValue,
      end_respiratory: selectedValue,
      end_asthma: selectedValue,
      end_chronicbronchitis: selectedValue,
      end_emphysema: selectedValue,
      vas_diabetes: selectedValue,
      vas_heartpain: selectedValue,
      vas_highbp: selectedValue,
      vas_vasculardisease: selectedValue,
      gas_acidreflux: selectedValue,
      gas_ulcer: selectedValue,
      gas_celiacdisease: selectedValue,
      gen_genitalsdisease: selectedValue,
      gen_kidneydisease: selectedValue,
      gen_bladdardisease: selectedValue,
      mus_rheumatoidarthritis: selectedValue,
      mus_osteoporosis: selectedValue,
      mus_musculardystrophy: selectedValue,
      mus_jointpain: selectedValue,
      lym_anemia: selectedValue,
      lym_bleedingproblems: selectedValue,
    });
    setAnchorEl(null);
    setMedicalBtnUnable(true);
  };
  const discardHandler=()=>{
    if(medicalData && medicalData.length > 0){
      setDiscardUpdate(true);
      setMedicalBtnUnable(false);
    }else{
      clearForm();
      setMedicalBtnUnable(false);
    }
  }
  const clearForm = ()=>{
    setMedicalHistoryDetail({
      pat_medhist_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    med_weightloss: "",
    med_lossofappetite: "",
    med_sleepapnea: "",
    med_covid19: "",
    neu_headaches: "",
    neu_migraine: "",
    neu_seizures: "",
    ent_sinusitis: "",
    ent_hearingloss: "",
    ent_laryngitis: "",
    end_thyroiddisease: "",
    end_otherglands: "",
    end_respiratory: "",
    end_asthma: "",
    end_chronicbronchitis: "",
    end_emphysema: "",
    vas_diabetes: "",
    vas_heartpain: "",
    vas_highbp: "",
    vas_vasculardisease: "",
    gas_acidreflux: "",
    gas_ulcer: "",
    gas_celiacdisease: "",
    gen_genitalsdisease: "",
    gen_kidneydisease: "",
    gen_bladdardisease: "",
    mus_rheumatoidarthritis: "",
    mus_osteoporosis: "",
    mus_musculardystrophy: "",
    mus_jointpain: "",
    lym_anemia: "",
    lym_bleedingproblems: "",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: actionType,
    })
  }
  return (
    <>
      <Grid container spacing={2} className={classes.formBox} >
      
      {enable && <Grid
          item
          xs={12}
          md={12}
          className={classes.textGroup}
          style={{
            border: "1px solid #DED9D9",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <IconButton
            id="basic-button"
            aria-controls={openDown ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openDown ? "true" : undefined}
            onClick={(e) => {
              handleClick(e);
            }}
          >
            <MoreIcon />
          </IconButton>

          <Menu
            className={classes.menuBox}
            id="basic-menu"
            anchorEl={anchorEl}
            open={openDown}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => handleAllSelected("Y")}>
              Set All Yes
            </MenuItem>

            <MenuItem onClick={() => handleAllSelected("N")}>
            Set All No
            </MenuItem>
            <MenuItem onClick={() => handleAllSelected("U")}>
            Set All Unknown
            </MenuItem>
            <MenuItem onClick={() => handleAllSelected("")}>
           Clear All
            </MenuItem>
          </Menu>
        </Grid>}
        {medicalHistory.map((element, i) => {
          return (
            <>
              <Grid
                item
                xs={12}
                md={element.mdSize}
                className={classes.textGroup}
                style={{ border: "1px solid #DED9D9" }}
              >
                <Typography
                  className={classes.formHeading}
                  style={{ fontWeight: "500", color: "#4E97FD" }}
                >
                  {element.label}{" "}
                  {element.required === "true" ? (
                    <span className={classes.required}>*</span>
                  ) : null}
                </Typography>
                {element.type ? (
                  <>
                    {element.type === "select" ? (
                      <FormControl
                        fullWidth
                        className={classes.dropdownFormControl}
                      >
                        <Select disabled={!enable}
                          name={element.key}
                          className={classes.selectDropdown}
                          placeholder={element.placeHolder}
                        >
                          <MenuItem value="">Select</MenuItem>
                          {element.options.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : element.type === "text" ? (
                      <TextField disabled={!enable}
                        required
                        name={element.key}
                        placeholder={element.placeHolder}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    ) : element.type === "date" ||
                      element.type === "datetime-local" ? (
                      <TextField disabled={!enable}
                        id={element.key}
                        name={element.key}
                        placeholder={element.placeHolder}
                        type={element.type}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          min: `${currentDateTime}`,
                          max: `${currentDate}`,
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    ) : element.type === "radio" ? (
                      <RadioGroup
                        row
                        value={
                          medicalHistoryDetail[element.key] &&
                          medicalHistoryDetail[element.key] === "Y"
                            ? "Yes"
                            : medicalHistoryDetail[element.key] === "N"
                            ? "No"
                            : medicalHistoryDetail[element.key] === "U"
                            ? "Unknown"
                            : ""
                        }
                        onClick={(e) => {
                          console.log("clicked down");
                          let selectedValue;
                          switch (e.target.value) {
                            case "Yes":
                              selectedValue = "Y";
                              break;
                            case "No":
                              selectedValue = "N";
                              break;
                            case "Unknown":
                              selectedValue = "U";
                              break;
                            default:
                              selectedValue = "";
                          }

                          setMedicalHistoryDetail({
                            ...medicalHistoryDetail,
                            [element.key]: selectedValue,
                          });
                          setMedicalBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      >
                        {element.radioValue.map((item) => (
                          <FormControlLabel disabled={!enable}
                            size="small"
                            style={{ padding: "0px 6px" }}
                            value={item}
                            control={<Radio />}
                            label={item}
                            className={classes.smallRadioButton}
                          />
                        ))}
                      </RadioGroup>
                    ) : (
                      <TextField disabled={!enable}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        placeholder="Type here..."
                        variant="outlined"
                        multiline
                        rows={3}
                        inputProps={{
                          style: {
                            fontSize: "12px",
                          },
                        }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Typography
                      className={classes.formHeading}
                      style={{ fontWeight: "600", color: "#4E97FD" }}
                    >
                      {element.title}
                    </Typography>
                  </>
                )}
              </Grid>
            </>
          );
        })}
        <Grid className={classes.btnGroup} item xs={12} style={{ display: "flex", justifyContent: "end" }}>
        {showDiscard && (
            <Button
              className={classes.btnleftOutline}
              variant="outlined"
              style={{ marginRight: "10px", fontSize: "12px" }}
              size="medium"
              onClick={discardHandler}
            >
              Discard
            </Button>
          )}
          {enable &&
          <Button disabled={!medicalBtnUnable}
          style={{ display: "flex", float: "right" }}
          onClick={handleSubmit}
          className={classes.btnlRightDark}
          variant="contained"
          size="medium"
          color="secondary"
        >
          Save
        </Button>
          }
          
        </Grid>
      </Grid>
    </>
  );
}
