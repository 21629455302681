import React, { useState, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { dateFormatConversion, loadState } from "../../../../../hooks/helper";
import PageTitle from "../../../../PageTitle/PageTitle";
import Widget from "../../../../Widget/Widget";

import TableWidget from "../../../../Widget/TableWidget";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Create";
import IconButton from "@material-ui/core/IconButton";
import * as Icons from "@material-ui/icons";
import { getPatientAttachment, getPatientInteraction } from "../../../../../services/ServiceLayer";
import TableDesign from "../../../../common/table/TableDesign";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Paper,
  Menu,
  MenuItem,
} from "@material-ui/core";
import {
  MoreVert as MoreIcon,
  Search as SearchIcon,
  Lens,
} from "@material-ui/icons";
// styles
import useStyles from "../../styles";
import ManageInteraction from "./ManageInteraction";
import moment from "moment";

export default function IntractionList({
  patientId
  
}) {
  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Created Date & Time",
      align: "left !important",
    },
    {
      label: "Cateogry",
      align: "left !important",
    },
    {
      label: "Status",
      align: "left !important",
    },
    {
      label: "Channel",
      align: "left !important",
    },
    {
      label: "About",
      align: "left !important",
    },
    
    {
      label: "Captured By",
      align: "left !important",
    },
    {
      label: "Notes Preview",
      align: "left !important",
    },
    {
      label: "Practice Updates",
      align: "left !important",
    },
    
    {
      label: "Action",
      align: "left !important",
    },
  ];

  const [open, setOpen] = React.useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectclient, setselectclient] = useState(null);
 
  const [selectRow, setSelectRow] = useState(null);
 
  const openDown = Boolean(anchorEl);
  const [title, setTitle] = useState(null);
  var classes = useStyles();

const hideHandle=()=>{
  setOpen(false);
}
const handleClose = () => {
  setAnchorEl(null);
};
const addInteraction=()=>{
  setOpen(true);
}
const userData = loadState().user;
  const userRole = loadState().user.user_role;
const [isUpdated, setIsUpdated] = useState(true);
const [patientInteractionData, setPatientInteractionData] = useState([]);
useEffect(()=>{
  getPatientInteraction(
    `patient_id=${patientId}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELPAT`
  ).then((response) => {
    console.log(response);
    setPatientInteractionData(response.data);
    setIsUpdated(false);
  });
  setIsUpdated(false);
},[isUpdated])
  return (
    <div className="container">
      <ManageInteraction
       open={open}
       onHide={hideHandle}
       title={"Add Interaction"}
       selectclient={selectclient}
       setModalShow={setModalShow}
       patientId={patientId}
       setIsUpdated={setIsUpdated}
      />
      <p style={{ marginLeft: "6px", paddingLeft: "10px",fontWeight:'500' }}>Interactions</p>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "4px",
        }}
      >
      <Button
          className={classes.fillBtn}
          variant="contained"
          size="small"
          color="secondary"
          style={{ marginRight: "6px",fontSize:'14px',textTransform:'capitalize' }}
          onClick={addInteraction}
        >
          Add Interaction 
        </Button> 
        
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Attachment",
                tableHeadings,
              }}
            >
              {patientInteractionData && patientInteractionData?.length ? (
                patientInteractionData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {moment(row.intac_date).utc().format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.intac_category_val}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.intac_status_val}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.intac_channel_val}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.intac_about_val}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.intac_by_val}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.updated_by}
                        </TableCell>
                        <TableCell
                          className={classes.tableBodyCell}
                        >{row?.intac_notes}</TableCell>
                       
                        <TableCell>
                        <IconButton
                            // onClick={(e) => {
                            //   handleClick(e, row);
                            // }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            anchorEl={anchorEl}
                            open={openDown}
                            // onClose={handleClose}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              // onClick={() => handleEditClick(selectRow)}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />
                              View/Update
                            </MenuItem>
                            <MenuItem
                              className={classes.menuItem}
                              // onClick={() => deleteEventHandler(selectRow)}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />
                              Cancel
                            </MenuItem>
                            <MenuItem
                              className={classes.menuItem}
                              // onClick={() => handleEditClick(row)}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />
                              Re-Order
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </div>
  );
}
