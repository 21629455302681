import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  logo: {
    height: "75px",
    margin: "0 auto",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
  },
  logotypeContainer: {
    height: "100%",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "15px",
    paddingBottom: "25px",
  },
  loginSubTitle: {
    fontWeight: 700,
    fontSize: "25px",
    paddingTop: "55px", 
    marginBottom: "25px",
    fontFamily: "Public Sans, sans-serif",
  },
  formContainer: {
    // paddingTop: "10px",
    // paddingRight: "10px",
    // paddingLeft: "10px",
    // padding: "10px",
    // paddingLeft: "20px",
    // boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
  },
  subGreeting: {
    fontSize: "25px",
    fontWeight: 700,
    textAlign: "left",
    fontFamily: "Public Sans, sans-serif",
  },
  createAccountButton: {
    fontFamily: "Public Sans, sans-serif",
    color: "#FFFFFF",
    backgroundColor: "#1677ff",
  },
  errorMessage: {
    textAlign: "center",
  },
  txtHeading: {
    fontFamily: "Public Sans, sans-serif",
    paddingBottom: "0px",
    paddingTop: "5px",
    fontSize: "14px",
  },
}));
