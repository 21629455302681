import React from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,

} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
// styles
import useStyles from "../styles";
import ExamSection from "./ExamSection";
import Iop from "./Iop";
import Dialation from "./Dialation";
import Pachymetry from "./Pachymetry";
import Notes from "./Notes";

export default function ExternalMain({
  patientId,
  examExternalDilation,
  examExternalCounsel,
  examExternalFatProlapse,
  examExternalPupilAPD,
  examExternalPupilNPI,
  examExternalBinocular,
  examExternalPupilRAPD,
  examExternalPupilReacts,
  examExternalPupilShape,
  examExternalPupilSize,
  examExternalMethod,
  examExternalEyeLid,
  examExternalCornea,
  examExternalTearFilm,
  examExternalOrbit,
  examExternalTearIris,
  examExternalLacrimal,
  examExternalAnteriorChamber,
  examExternalConjunctiva,
  examExternalSclera,
  examExternalPreauricularNode,
  doctorList,
  examBtnUnable,
  setExamBtnUnable,
  iopBtnUnable,
  setIopBtnUnable,
  dialationBtnUnable,
  setDialationBtnUnable,
  pachymetryBtnUnable,
  setPachmetryBtnUnable,
  notesBtnUnable,
  setNotesBtnUnable,
}) {
  var classes = useStyles();

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Exam Section</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ExamSection
            patientId={patientId}
            examExternalDilation={examExternalDilation}
            examExternalCounsel={examExternalCounsel}
            examExternalFatProlapse={examExternalFatProlapse}
            examExternalPupilAPD={examExternalPupilAPD}
            examExternalPupilNPI={examExternalPupilNPI}
            examExternalBinocular={examExternalBinocular}
            examExternalPupilRAPD={examExternalPupilRAPD}
            examExternalPupilReacts={examExternalPupilReacts}
            examExternalPupilShape={examExternalPupilShape}
            examExternalPupilSize={examExternalPupilSize}
            examExternalMethod={examExternalMethod}
            examExternalEyeLid={examExternalEyeLid}
            examExternalCornea={examExternalCornea}
            examExternalTearFilm={examExternalTearFilm}
            examExternalOrbit={examExternalOrbit}
            examExternalTearIris={examExternalTearIris}
            examExternalLacrimal={examExternalLacrimal}
            examExternalAnteriorChamber={examExternalAnteriorChamber}
            examExternalConjunctiva={examExternalConjunctiva}
            examExternalSclera={examExternalSclera}
            examExternalPreauricularNode={examExternalPreauricularNode}
            examBtnUnable={examBtnUnable}
            setExamBtnUnable={setExamBtnUnable}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>IOP</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Iop
            iopBtnUnable={iopBtnUnable}
            setIopBtnUnable={setIopBtnUnable}
            patientId={patientId}
            examExternalMethod={examExternalMethod}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Dialation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Dialation
            patientId={patientId}
            doctorList={doctorList}
            examExternalCounsel={examExternalCounsel}
            examExternalDilation={examExternalDilation}
            dialationBtnUnable={dialationBtnUnable}
            setDialationBtnUnable={setDialationBtnUnable}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Pachymetry</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Pachymetry
            patientId={patientId}
            pachymetryBtnUnable={pachymetryBtnUnable}
            setPachmetryBtnUnable={setPachmetryBtnUnable}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Notes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Notes
            patientId={patientId}
            notesBtnUnable={notesBtnUnable}
            setNotesBtnUnable={setNotesBtnUnable}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
