import React, { useState, useEffect } from "react";
import { Button, Grid, Menu, MenuItem } from "@material-ui/core";

import { dateFormatConversion, loadState } from "../../../hooks/helper";
import PageTitle from "../../PageTitle/PageTitle";

import TableWidget from "../../Widget/TableWidget";

import TableDesign from "../../common/table/TableDesign";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
// styles
import useStyles from "../styles";

import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";
import { getMedicationList } from "../../../services/ServiceLayer";

import moment from "moment";

import ManageMedicationTable from "./ManageMedicationTable";
import ManageMedication from "./ManageMedication";


export default function MedicationHistory(props) {
  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Medicine",
    },
    {
      label: "Type",
    },
    {
      label: "Status",
    },
    {
      label: "Strength",
    },
    {
      label: "Supply",
    },
    {
      label: "Route",
    },
    {
      label: "Frequency",
    },
    {
      label: "Quantity",
    },
    {
      label: "Start Date",
    },
    {
      label: "Stop Date",
    },

    {
      label: "Action",
      align: "left",
    },
  ];
  const { patientId,frequency, enable,setIsLoader } = props;
  console.log("props", props);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [selectRow, setSelectRow] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const [selectclient, setselectclient] = useState(null);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [medicineData, setMedicineData] = useState([]);
  const [title, setTitle] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  
  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;

  useEffect(()=>{
    getMedicationList(
      `location_id=${userData?.location_id}&patient_id=${patientId}&user_role=${userRole}&opt_type=SELMED`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setTimeout(()=>{
          setIsLoader(false);
        },3000)
        
        setMedicineData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  },[])
  useEffect(()=>{
    getMedicationList(
      `location_id=${userData?.location_id}&patient_id=${patientId}&user_role=${userRole}&opt_type=SELMED`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setTimeout(()=>{
          setIsLoader(false);
        },3000)
        setMedicineData(response?.data);
        setDataUpdated(false);
      } else {
        // setIsLoader(false);
      }
      setDataUpdated(false);
    });
  },[dataUpdated])
  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpen(false);
    setOpen1(false);
    setDataUpdated(true);
    setEditModalShow(false)
    setselectclient(null);
  };
  const addMedicalModel = () => {
    setOpen(true);
    setselectclient(null);
  };
  const handleEdit = (rec) => {
    setOpen1(true);
    setAnchorEl(null);
    setselectclient(rec);
    setEditModalShow(true);
    setTitle("Edit Problem");
  };
  const deleteEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);
    setselectclient(rec);
    setDeleteModalShow(true);
    setTitle("Delete Problem");
  };
  return (
    <>
<ManageMedicationTable  open={open}
        onHide={hideHandle}
        title={selectclient ? "Update Medication" :"Add Medication"}
        // selectclient={selectclient}
        frequency={frequency}
        patientId={patientId}
        setDataUpdated={setDataUpdated}
        setModalShow={setModalShow}/>

    <ManageMedication  open={open1}
        onHide={hideHandle}
        title={selectclient ? "Update Medication" :"Add Medication"}
        selectclient={selectclient}
        frequency={frequency}
        patientId={patientId}
        setDataUpdated={setDataUpdated}
        setModalShow={setEditModalShow}/>
     
      
      <Grid container spacing={4} style={{ width: "100%" }}>
     {enable &&    <Grid>
          {" "}
          <div
            className={classes.pageTitleContainer}
            style={{ display: "flex", justifyContent: "end" }}
          >
            <Button
              style={{
                marginRight: "8px",
                fontSize: "10px",
                position: "absolute",
                borderRadius: "2px",
                right: "60px",
              }}
              classes={{ root: classes.button }}
              variant="contained"
              color="secondary"
              onClick={addMedicalModel}
            >
              Add Medication
            </Button>
          </div>
        </Grid> }
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {medicineData && medicineData?.length ? (
                medicineData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {row?.med_sort_name}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.med_type}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.is_active}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.med_strength}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.supply}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row?.med_route}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.med_frequency_val}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.med_quantity}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {dateFormatConversion(row.st_date)}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {dateFormatConversion(row.end_date)}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {moment().format("YYYY-MM-DD") ===
                            moment(row.st_date).format("YYYY-MM-DD") && (
                            <IconButton
                              id="basic-button"
                              aria-controls={
                                openDown ? "basic-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={openDown ? "true" : undefined}
                              onClick={(e) => {
                                handleClick(e);
                                setSelectRow(row);
                              }}
                            >
                              <MoreIcon />
                            </IconButton>
                          )}
                          <Menu
                            className={classes.menuBox}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem onClick={() => handleEdit(selectRow)}>
                              Edit
                            </MenuItem>

                            {/* <MenuItem
                              onClick={() => deleteEventHandler(selectRow)}
                            >
                              Delete
                            </MenuItem> */}
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
