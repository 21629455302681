
import React from "react";
import ApexCharts from "react-apexcharts";
import { useTheme } from "@material-ui/styles";

const series = [
    {   data: [21, 22, 10, 28, 16, 21, 13, 30]}
];

export default function HealthChart() {
    var theme = useTheme();
    return (
        <ApexCharts
            options={themeOptions(theme)}
            series={series}
            type="bar"
            height={350}
        />
    )
}

function themeOptions(theme) {
    return {
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
            }
          },
        stroke: {
            curve: "smooth",
        },
        xaxis: { 
            categories: [
                ['John', 'Doe'],
                ['Joe', 'Smith'],
                ['Jake', 'Williams'],
                'Amber',
                ['Peter', 'Brown'],
                ['Mary', 'Evans'],
                ['David', 'Wilson'],
                ['Lily', 'Roberts'], 
              ],
           
              labels: {
                style: { 
                  fontSize: '12px'
                }
              }
        },
       
        legend: {
            show: false,
        },
    };
}