import React, { useState, useEffect } from "react";
import { Button, Link, InputBase, TextField } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import download from "./download.svg";
import editIcons from "./whiteEdit.png";
// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";
import { loadState } from "../../hooks/helper";
import { getPatient } from "../../services/ServiceLayer";
import blankImage from "../../images/blank_img.jpg";
import moment from "moment";

export default function PageTitle(props) {
  var classes = useStyles();
  const { patient_id } = useParams();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [patientData, setPatientData] = useState([]);
  useEffect(() => {
    // patient details
    if (patient_id) {
      getPatient(
        `patient_id=${patient_id}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELHIS`
      ).then((response) => {
        console.log(response);
        setPatientData(response?.data);
      });
    }
  }, [patient_id]);
  var [isSearchOpen, setSearchOpen] = useState(true);
  // info model

  const calculateAge = (dateOfBirth) => {
    const today = moment();
    const birthDate = moment(dateOfBirth);
    const years = today.diff(birthDate, "years");
    birthDate.add(years, "years");
    const months = today.diff(birthDate, "months");
    return { years, months };
  };
  return (
    <>
      <div className={classes.pageTitleContainer}>
        <Typography className={classes.typo} weight="medium">
          <Link
            href={props.urlLink}
            className={classes.typoGray}
            underline="none"
          >
            {props.subtitle}{" "}
          </Link>{" "}
          {props.title}
        </Typography>

        <div className={classes.groupBtn}>
          {props.button1 && (
            <Button
              classes={{ root: classes.button1 }}
              variant="contained"
              color="secondary"
              onClick={props.onClickDownload}
            >
              <img className={classes.downloadImg} src={download} alt="img" />
              {props.button1}
            </Button>
          )}
          {props.search && (
            <div
              className={classNames(classes.search, {
                [classes.searchFocused]: isSearchOpen,
              })}
            >
              <div
                className={classNames(classes.searchIcon, {
                  [classes.searchIconOpened]: isSearchOpen,
                })}
                onClick={() => setSearchOpen(!isSearchOpen)}
              >
                <SearchIcon classes={{ root: classes.headerIcon }} />
              </div>
              <InputBase
                placeholder={props.searchPlaceholder ? props.searchPlaceholder : 'Enter any text'}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                onChange={props.onChange}
              />
            </div>
          )}
          {props.button && (
            <Button
              classes={{ root: classes.button }}
              variant="contained"
              color="secondary"
              onClick={props.onClick}
            >
              {props.editIcon && (
                <img className={classes.editicons} src={editIcons} alt="img" />
              )}
              {props.button}
            </Button>
          )}

          {props.button2 && (
            <Button
              classes={{ root: classes.button2 }}
              variant="contained"
              color="secondary"
              onClick={props.onClick2}
            >
              {props.button2}
            </Button>
          )}
          {props.button3 && (
            <Button
              classes={{ root: classes.button3 }}
              variant="contained"
              color="secondary"
              onClick={props.onClick3}
            >
              {props.button3}
            </Button>
          )}
          {props.button4 && (
            <Button
              classes={{ root: classes.button3 }}
              variant="contained"
              color="secondary"
              onClick={props.onClick4}
            >
              {props.button4}
            </Button>
          )}
          {props.button5 && (
            <Button
              classes={{ root: classes.button3 }}
              variant="contained"
              color="secondary"
              onClick={props.onClick5}
            >
              {props.button5}
            </Button>
          )}
          {props.button6 && (
            <Button
              classes={{ root: classes.button3 }}
              variant="contained"
              color="secondary"
              onClick={props.onClick6}
            >
              {props.button6}
            </Button>
          )}
          {props.startDate && (
            <span style={{ flex: "flex-1" }}>
              <Typography
                style={{
                  marginRight: "16px",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
                className={classes.formHeading}
              >
                Start Date
              </Typography>
              <TextField
                name="startDate"
                placeholder="select"
                type="date"
                size="small"
                className={classes.txtForm}
                fullWidth
                variant="outlined"
                onKeyDown={(e) => {
                  e.preventDefault(); // Prevent manual typing
                }}
                inputProps={{
                  // Set the maximum time to 18:00
                  style: {
                    height: "12px",
                    fontSize: "12px",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onClick={props.onClickStart}
                // disabled={element.key === "end"} // Disable
              />
            </span>
          )}
          {props.endDate && (
            <span style={{ flex: "flex-1", marginLeft: "16px" }}>
              <Typography
                style={{ fontSize: "12px", fontWeight: "400" }}
                className={classes.formHeading}
              >
                End Date
              </Typography>
              <TextField
                name="startDate"
                placeholder="select"
                type="date"
                size="small"
                className={classes.txtForm}
                fullWidth
                variant="outlined"
                onKeyDown={(e) => {
                  e.preventDefault(); // Prevent manual typing
                }}
                inputProps={{
                  // Set the maximum time to 18:00
                  style: {
                    height: "12px",
                    fontSize: "12px",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onClick={props.onClickEnd}
                // disabled={element.key === "end"} // Disable
              />
            </span>
          )}
        </div>
      </div>
      {patientData && patient_id && (
        <div
          style={{
            top: "77px",
            zIndex: "99",
            display: "flex",
            justifyItems: "center",
            alignItems: "center",
            position: "absolute",
            left: "45%", 
            backgroundColor: "#e6f2fe",
            padding: "6px 16px",
            borderRadius: "10px",
            fontWeight: "500",
            fontSize: "12px",
          }}
        >
          <div style={{ marginRight: "6px" }}>
            <img
              src={blankImage}
              alt="img"
              style={{ height: "48px", width: "48px", borderRadius: "50%" }}
            />
          </div>
          <div style={{ display: "inline" }}>
            <span style={{ fontSize: "14px", fontWeight: "600" }}>
              {patientData?.first_name === null ||
              patientData?.first_name === undefined
                ? ""
                : patientData?.first_name}{" "}
              {patientData?.middle_name === null ||
              patientData?.middle_name === undefined ||
              patientData?.middle_name === "undefined"
                ? ""
                : patientData?.middle_name}{" "}
              {patientData?.last_name === null ||
              patientData?.last_name === undefined
                ? ""
                : patientData?.last_name}
            </span>{" "}
            <br />
            <span>
              {calculateAge(patientData?.dateofbirth).years} years and{" "}
              {calculateAge(patientData?.dateofbirth).months} months old
            </span>{" "}
            -{" "}
            <span>
              {patientData?.gender_val === null ||
              patientData?.gender_val === undefined
                ? ""
                : patientData?.gender_val}
            </span>{" "}
            -{" "}
            <span>
              DOB:- {moment(patientData?.dateofbirth).format("DD-MM-YYYY")}
            </span>
            <br />
            <span>Contact No. :-{patientData?.contact_cell}</span>
          </div>
          {/* <>
            
            <Icons.Info
              style={{ color: "#068AC5", cursor: "pointer" }}
              onClick={toggleDrawer(patientData, true, patientData)}
            />

            <Drawer
              top={48}
              style={{ width: "240px", maxWidth: "240px" }}
              sx={{
                paddingRight: 4,
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                  top: "48px !important",
                },
              }}
              elevation={0}
              anchor="right"
              open={state[patientData]}
              onClose={toggleDrawer(patientData, false, null)}
            >
              <IconButton
                style={{ position: "absolute", marginLeft: "-14px" }}
                onClick={toggleDrawer(patientData, false, patientData)}
              >
                <ArrowForwardIosRounded />
              </IconButton>
              <p
                style={{
                  fontWeight: "500",
                  fontSize: "13px",
                  paddingLeft: "20px",
                  textAlign: "center",
                  width: "260px",
                }}
              >
                {patientData?.first_name} {patientData?.middlet_name}{" "}
                {patientData?.last_name}
              </p>
              <Divider />
              <span
                style={{
                  padding: "2px 6px",
                  width: "260px",
                  fontSize: "12px",
                }}
              >
                Email :-{patientData?.email}
              </span>
              <span
                style={{
                  padding: "2px 6px",
                  width: "260px",
                  fontSize: "12px",
                }}
              >
                Gender :-{patientData?.gender}
              </span>
              <span
                style={{
                  padding: "2px 6px",
                  width: "260px",
                  fontSize: "12px",
                }}
              >
                Contact No. :-{patientData?.contact_cell}
              </span>
              <span
                style={{
                  padding: "2px 6px",
                  width: "260px",
                  fontSize: "12px",
                }}
              >
                Date of Birth :-{" "}
                {moment(patientData?.dateofbirth).format("YYYY-MM-DD")}
              </span>
              
            </Drawer>
          </> */}
        </div>
      )}
    </>
  );
}
