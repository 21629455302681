import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";

import useStyles from "../styles";
import { formReferralElements } from "./Assessment_Constant";
import { currentUser, loadState } from "../../../hooks/helper";
import {
  getPatientAssessmentReferral,
  postPatientAssessmentReferral,
} from "../../../services/ServiceLayer";
import { toast } from "react-toastify";
import moment from "moment";

export default function Referral({
  patientId,
  assessmentRefBtnUnable,
  setAssessmentRefBtnUnable,
}) {
  var classes = useStyles();
  const userData = loadState().user;
  const [options, setOptions] = useState([]);
  // const previousController = useRef();
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
 
  const userRole =loadState().user.user_role;
  const [assessmentReferral, setAssessmentReferral] = useState({
    pat_exrf_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    rf_to: "",
    rf_res: "",
    rf_dt: currentDate,
    rf_notes: "",
    updated_by: currentUser(),
    access_location: "",
    opt_type: "ADDASM",
  });
  const [assessmentReferralList, setAssessmentReferralList] = useState([]);
  const [isActionCalled, setIsActionCalled] = useState(false);
  useEffect(() => {
    getPatientAssessmentReferral(
      `patient_id=${patientId}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELASM`
    ).then((response) => {
      console.log(response);
      setAssessmentReferralList(response.data);
    });
  }, []);
  useEffect(()=>{
if(assessmentReferralList && assessmentReferralList.length>0){
  setAssessmentReferral({
    pat_exrf_id: assessmentReferralList[0]?.pat_exrf_id,
    patient_id: patientId,
    location_id: userData?.location_id,
    rf_to: assessmentReferralList[0]?.rf_to,
    rf_res: assessmentReferralList[0]?.rf_res,
    rf_dt: moment(assessmentReferralList[0]?.rf_dt).format('YYYY-MM-DD'),
    rf_notes: assessmentReferralList[0]?.rf_notes,
    updated_by: currentUser(),
    access_location: assessmentReferralList[0]?.access_location,
    opt_type: "UPDASM",
  })
}
  },[assessmentReferralList])
  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    if (isActionCalled) {
      postPatientAssessmentReferral(assessmentReferral).then((response) => {
        console.log(response);
        if (response?.status?.status) {
          toast.success("Referral added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setAssessmentRefBtnUnable(false);
          // props.onHide();
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    setIsActionCalled(false);
  }, [isActionCalled]);
  return (
    <>
      <Grid container spacing={2} className={classes.formBox}>
        {formReferralElements.map((element, i) => {
          return (
            <Grid
              item
              xs={12}
              md={element.mdSize}
              className={`${classes.textGroup} ${
                element.title ? classes.highlightedItem : ""
              }`}
              style={{ border: "1px solid #DED9D9" }}
            >
              {element.label ||
                (element.copyBtn && (
                  <Typography
                    className={classes.formHeading}
                    style={{
                      fontWeight: "500",
                      color: "#4E97FD",
                      position: "relative",
                      marginTop: "0px",
                    }}
                  >
                    {element.label}&nbsp;
                    {element.required === "true" ? (
                      <span className={classes.required}>*</span>
                    ) : null}
                    {element.copyBtn === "true" && (
                      <Button
                        style={{
                          float: "right",
                          fontSize: "10px",
                          // position: "absolute",
                          // top: "20px",
                        }}
                        classes={{ root: classes.button }}
                        variant="contained"
                        color="secondary"
                        // onClick={duplicateValue}
                      >
                        {element.copyLabel}
                      </Button>
                    )}
                  </Typography>
                ))}
              {element.type ? (
                <>
                  {element.type === "select" &&
                  element.key !== "procedure_code" ? (
                    <FormControl
                      fullWidth
                      className={classes.dropdownFormControl}
                    >
                      <Select
                        name={element.key}
                        className={classes.selectDropdown}
                        placeholder={element.placeHolder}
                        value={
                          assessmentReferral && assessmentReferral[element.key]
                            ? assessmentReferral &&
                              assessmentReferral[element.key]
                            : ""
                        }
                        onChange={(e) => {
                          console.log("selected");
                          setAssessmentReferral({
                            ...assessmentReferral,
                            [element.key]: e.target.value,
                          });
                          setAssessmentRefBtnUnable(true);
                        }}
                      >
                        <MenuItem disabled value="">
                          Select
                        </MenuItem>
                        {element.options.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : element.type === "text" ? (
                    <div style={{ display: "flex" }}>
                      <TextField
                        required
                        name={element.key}
                        placeholder={element.placeHolder}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                          maxLength: element.maxLength,
                          onKeyPress: element.onKeyPress,
                        }}
                        value={
                          assessmentReferral && assessmentReferral[element.key]
                            ? assessmentReferral &&
                              assessmentReferral[element.key]
                            : ""
                        }
                        onChange={(e) => {
                          console.log("selected");
                          setAssessmentReferral({
                            ...assessmentReferral,
                            [element.key]: e.target.value,
                          });
                          setAssessmentRefBtnUnable(true);
                        }}
                      />
                      {element.key === "sku" && (
                        <Button
                          // onClick={selectFrameBySku}
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                        >
                          Slect
                        </Button>
                      )}
                    </div>
                  ) : element.type === "commission_amount" ? (
                    <TextField
                      type="text"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      size="small"
                      inputProps={{
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                        maxLength: element.maxLength,
                        onKeyPress: element.onKeyPress,
                      }}
                    />
                  ) : element.type === "date" ||
                    element.type === "datetime-local" ? (
                    <TextField
                      id={element.key}
                      name={element.key}
                      placeholder={element.placeHolder}
                      type={element.type}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                      }}
                      value={
                        assessmentReferral && assessmentReferral[element.key]
                          ? assessmentReferral &&
                            assessmentReferral[element.key]
                          : ""
                      }
                      onChange={(e) => {
                        console.log("selected");
                        setAssessmentReferral({
                          ...assessmentReferral,
                          [element.key]: e.target.value,
                        });
                        setAssessmentRefBtnUnable(true);
                      }}
                    />
                  ) : element.type === "radio" ? (
                    <RadioGroup row defaultValue="">
                      {element.radioValue.map((item) => (
                        <FormControlLabel
                          size="small"
                          style={{ padding: "0px 6px" }}
                          value={item}
                          control={<Radio />}
                          label={item}
                          className={classes.smallRadioButton}
                        />
                      ))}
                    </RadioGroup>
                  ) : element.type === "checkbox" ? (
                    <>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value={element.value}
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  const selectedValue = e.target.checked
                                    ? "Y"
                                    : "N";
                                  setAssessmentReferral({
                                    ...assessmentReferral,
                                    [element.key]: selectedValue,
                                  });
                                  setAssessmentRefBtnUnable(true);
                                }}
                              />
                            }
                            label={element.lbltitle}
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </FormControl>
                    </>
                  ) : element.type === "button" &&
                    element?.btnlbl === "Lookup" ? (
                    <>
                      <Button
                        classes={{ root: classes.button }}
                        variant="contained"
                        color="secondary"
                        // onClick={selectPrevFrame}
                      >
                        {element.btnlbl}
                      </Button>
                    </>
                  ) : (
                    <TextField
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      placeholder="Type here..."
                      variant="outlined"
                      multiline
                      rows={3}
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      value={
                        assessmentReferral && assessmentReferral[element.key]
                          ? assessmentReferral &&
                            assessmentReferral[element.key]
                          : ""
                      }
                      onChange={(e) => {
                        console.log("selected");
                        setAssessmentReferral({
                          ...assessmentReferral,
                          [element.key]: e.target.value,
                        });
                        setAssessmentRefBtnUnable(true);
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <Typography
                    className={classes.formHeading}
                    style={{
                      fontWeight: "600",

                      color: "#4E97FD",
                    }}
                  >
                    {element.title}
                  </Typography>
                </>
              )}
            </Grid>
          );
        })}
        <Grid className={classes.btnGroup} item xs={12}>
          <Button
            disabled={!assessmentRefBtnUnable}
            style={{ display: "flex", float: "right" }}
            onClick={handleSubmit}
            className={classes.btnlRightDark}
            variant="contained"
            size="medium"
            color="secondary"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
