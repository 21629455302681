import {
  handleKeyPress,
  handleKeyPressPaste,
  handleAlphNumKeyPress,
  handleAlphNumKeyPressPaste,
  handleAlphNumberKeyPress,
  handleAlphNumberKeyPressPaste,
  handleAddressKeyPress,
  handleAddressKeyPressPaste,
  validateLargeAmount,
} from "../../../hooks/helper";
export const formBinoElements = [
  {
    title: "Horizontal",
    label:"(Phorias)",
    mdSize: "1",
    // copyBtn: "true",
  },
 
  {
    key: "ph_hz_dis",
    label: "Distance",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
    maxLength:5
  },
  {
    key: "ph_hz_nr",
    label: "Near",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
    maxLength:5
  },
 
  {
    key: "ph_hz_eye",
    label: "Eye Position",
    placeHolder: "select",
    type: "select",
    options:[],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
    maxLength:5
  },
  {
    title: "Cover Test",
    mdSize: "1",
    // copyBtn: "true",
  },
  {
    key: "ct_dis",
    label: "Distance",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
    maxLength:5
  },
  {
    key: "ct_nr",
    label: "Near",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
    maxLength:5
  },
  
  {
    title: "Vertical",
    mdSize: "1",
    // copyBtn: "true",
  },
 
  {
    key: "ph_vt_dis",
    label: "Distance",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
    maxLength:5
  },
  {
    key: "ph_vt_nr",
    label: "Near",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
    maxLength:5
  },
 
  {
    key: "ph_vt_eye",
    label: "Eye Position",
    placeHolder: "select",
    type: "select",
    options:[],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
    maxLength:5
  },
  {
    title: "",
    mdSize: "1",
    // copyBtn: "true",
  },
  {
    key: "ct_npc",
    label: "NPC",
    placeHolder: "text",
    type: "text",
    mdSize: "4",
    onKeyPress: handleAlphNumKeyPress,
    maxLength:50
  },
  

  {
    title: "Horizontal",
    label:"(Vergence)",
    mdSize: "1",
    // copyBtn: "true",
  },
 
  {
    key: "vg_hz_dis",
    label: "Distance",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
    maxLength:5
  },
  {
    key: "vg_hz_nr",
    label: "Near",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
    maxLength:5
  },
 
  {
    key: "vg_hz_eye",
    label: "Eye Position",
    placeHolder: "select",
    type: "select",
    options:[],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
    maxLength:5
  },
  {
    title: "",
    mdSize: "1",
    // copyBtn: "true",
  },
  {
    key: "ct_pra",
    label: "PRA",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
    maxLength:5
  },
  {
    key: "ct_brake",
    label: "Brake",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
    maxLength:5
  },
  {
    key: "ct_recovery",
    label: "Recovery",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
    maxLength:5
  },
 
 
  {
    title: "Vertical",
    mdSize: "1",
    // copyBtn: "true",
  },
 
  {
    key: "vg_vt_dis",
    label: "Distance",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
    maxLength:5
  },
  {
    key: "vg_vt_nr",
    label: "Near",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
    maxLength:5
  },
 
  {
    key: "vg_vt_eye",
    label: "Eye Position",
    placeHolder: "select",
    type: "select",
    options:[],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    title: "",
    mdSize: "1",
    // copyBtn: "true",
  },
  {
    key: "binocular_notes",
    label: "Notes",
    placeHolder: "type here",
    type: "textarea",
    mdSize: "4",
    onKeyPress: handleAddressKeyPress,
    maxLength:50
  },
 
];
export const formAddElements = [

  {
    key: "bin_ad_eoms",
    label: "Eoms",
    placeHolder: "text",
    type: "text",
    mdSize: "6",
    maxLength:50,
    onKeyPress: handleAddressKeyPress,
  },
 
 
  {
    key: "bin_ad_confrontal",
    label: "Confrontational Field",
    placeHolder: "text",
    type: "text",
    mdSize: "6",
    maxLength:50,
    onKeyPress: handleAddressKeyPress,
  },
  {
    label: "TBUT(Sec.)",
    mdSize: "3",
  },
  {
    key:'bin_ad_tbut_od',
    label: "OD",
    placeHolder: "text",
    type: "text",
    onKeyPress: validateLargeAmount,
    mdSize: "4",
    maxLength:5
  },
  {
    key:'bin_ad_tbut_os',
    label: "OS",
    placeHolder: "text",
    type: "text",
    onKeyPress: validateLargeAmount,
    mdSize: "4",
    maxLength:5
  },
  {
    label: "Binocular Function/Seereopsis",
    mdSize: "3",
  },
  {
    key: "bin_ad_bin_od",
    label: "",
    type: "select",
    options: [""],
    mdSize: "4",
  },
  {
    key: "bin_ad_bin_os",
    label: "",
    type: "select",
    options: [""],
    mdSize: "4",
  },
];




