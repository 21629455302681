import React from "react";
// Model
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
// styles
import closeIcon from "./closeIcon.png";
import useStyles from "./styles";
export default function Popup({ children, ...props }) {
  const { title } = props;
  //
  var classes = useStyles();
  return (
    <Dialog
      {...props}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className={classes.popupTitle}>
        {title}
        <img
          onClick={props.onHide}
          src={closeIcon}
          className={classes.closeicons}
          alt="image"
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.formBox}>
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.modelFooter}>
        {props.footer || (
          <Button variant="outlined" size="large" onClick={props.onHide}>
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
