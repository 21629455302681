import React, { useState, useEffect } from "react";

// Model
import {
  Button,
  Typography,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Popup from "../../common/modal/Popup";
import moment from "moment";
import alertIcon from "../../../assets/alertIcon.svg";
// styles
import useStyles from "../styles";
import { toast } from "react-toastify";

import { currentUser, loadState } from "../../../hooks/helper";
import { postAllergyData } from "../../../services/ServiceLayer";

export default function DeleteAllergyPopup({
  onHide,
  deleteModalShow,
  setDeleteModalShow,
  selectclient,
  patientId,
}) {
  console.log("selectclient", selectclient);
  console.log("deleteModalShow", deleteModalShow);
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const [actionType, setActionType] = useState("DELALG");
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [isActionCalled, setIsActionCalled] = useState(false);
  toast.configure();
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setDeleteModalShow(false);
    setOpen(false);
  };
  const [companyDetail, setCompanyDetail] = useState({
    pat_alle_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    allergy_from: "",
    outcome_id: "",
    st_date: "",
    end_date: "",
    alle_reaction: "",
    alle_severity: "",
    alle_notes: "",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: actionType,
  });
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setCompanyDetail({
        pat_alle_id: 0,
        patient_id: patientId,
        location_id: userData?.location_id,
        allergy_from: "",
        outcome_id: "",
        st_date: "",
        end_date: "",
        alle_reaction: "",
        alle_severity: "",
        alle_notes: "",
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: actionType,
      });
    } else {
      setCompanyDetail({
        pat_alle_id: selectclient?.pat_alle_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        allergy_from: selectclient?.allergy_from,
        outcome_id: selectclient?.outcome_id,
        st_date: moment(selectclient?.st_date).format("YYYY-MM-DD"),
        end_date: moment(selectclient?.end_date).format("YYYY-MM-DD"),
        alle_reaction: selectclient?.alle_reaction,
        alle_severity: selectclient?.alle_severity,
        alle_notes: selectclient?.alle_notes,
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: actionType,
      });
    }
  }, [selectclient]);

  const deleteFormData = () => {
    setIsActionCalled(true);
    setCompanyDetail({
      ...companyDetail,
      opt_type: actionType,
    });
  };

  useEffect(() => {
    console.log("isActionCalled", isActionCalled);

    if (isActionCalled) {
      postAllergyData(companyDetail).then((response) => {
        console.log("actionType", actionType);

        console.log(response);
        if (response.status.status) {
          toast.success("Allergy deleted successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });

          onHide();
          setDeleteModalShow(false);
          setOpen(false);
          setIsActionCalled(false);
        } else {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActionCalled(false);
        }
      });
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={deleteFormData}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <img src={alertIcon} alt="img" />
            <Typography className={classes.formActiveHeading}>
              Are you sure! You want to Delete this allergy?
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
