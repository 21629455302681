import React, { useState, useEffect } from "react";
import { Button, Grid, Menu, MenuItem, Typography } from "@material-ui/core";

import { dateFormatConversion, loadState } from "../../../hooks/helper";

import HealthChart from './HealthChart'
import TableWidget from "../../Widget/TableWidget";

import TableDesign from "../../common/table/TableDesign";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
// styles
import useStyles from "../styles";

import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";
import { getAllergyHistoryData, getPatientExternalPch } from "../../../services/ServiceLayer";

import moment from "moment";


export default function Pachymetry(props) {
  const tableHeadings = [
    {
      label: "Type",
    },
    {
      label: "Microns (μm)",
    },
    {
      label: "Test Date",
    },
    {
      label: "Comment",
    },
    {
      label: "ICD 10 Code",
    },
    
  
  
  ];
  const { patientId, allergyOutcome, allergySeverity, allergyReaction } = props;
  console.log("props", props);



 
 
  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [pachData, setPachData] = useState([]);
  useEffect(() => {
    getPatientExternalPch(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELEXT`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setPachData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);
  
  return (
    <>
{/*     
    <div className={classes.pageTitleContainer} style={{marginBottom: "6px",marginTop: "10px", borderTop:'1px solid #6666' }}>
        <Typography className={classes.typo} style={{fontSize:'14px'}} weight="medium">
          Pachymetry
        </Typography>
      </div> */}
      <Grid container spacing={4} style={{ width: "100%" }}>
        
        <Grid item xs={12} >
        <div style={{background:'#5DB3D9',padding:'10px',color:'#fff',textAlign:'center',borderRadius:'5px',marginBottom:'2px'}}>Pachymetry</div>
          {/* <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {pachData && pachData?.length ? (
                pachData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                         <TableCell className={classes.tableBodyCell}>
                        OD<br/>
                          <span style={{color:'blue'}}>OS</span>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {row?.pachy_microns_od}<br/>
                          <span style={{color:'blue'}}>{row?.pachy_microns_os}</span>
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {moment(row?.pachy_testdt_od).utc().format('DD-MM-YYYY')}<br/>
                          <span style={{color:'blue'}}>{moment(row?.pachy_testdt_os).utc().format('DD-MM-YYYY')}</span>
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.pachy_notes_od}<br/>
                          <span style={{color:'blue'}}>{row?.pachy_notes_os}</span>
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.pachy_icd_od}<br/>
                          <span style={{color:'blue'}}>{row?.pachy_icd_os}</span>
                        </TableCell>
                        
                       
                       
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget> */}
          <HealthChart />
        </Grid>
      </Grid>
    </>
  );
}
