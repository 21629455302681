import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button } from "@material-ui/core";
import useStyles from "../styles";
import { useState } from "react";
import ManageMedication from "./ManageMedication";


function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        medicine: "Zolmitriptan",
        type: "tablet",
        status: "active",
        strength: "2.5 MG",
        supply: "5 Days",
        route: "by mouth",
        frequency: "1 /day",
        quantity: "",
        start_date: "2020-01-05",
        stop_date: "2020-01-05",
      },
      {
        medicine: "Zolmitriptan",
        type: "tablet",
        status: "active",
        strength: "2.5 MG",
        supply: "5 Days",
        route: "by mouth",
        frequency: "1 /day",
        quantity: "",
        start_date: "2020-01-05",
        stop_date: "2020-01-05",
      },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" },height:0 }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        {/* <TableCell align="right">{row.calories}</TableCell>
        <TableCell align="right">{row.fat}</TableCell>
        <TableCell align="right">{row.carbs}</TableCell>
        <TableCell align="right">{row.protein}</TableCell> */}
      </TableRow>
      <TableRow sx={{height:0}}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {/* <Typography variant="h6" gutterBottom component="div">
                History
              </Typography> */}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Medicine</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Strength</TableCell>
                    <TableCell>Supply</TableCell>
                    <TableCell>Route</TableCell>
                    <TableCell>Frequency</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>Stop Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.medicine}>
                      <TableCell component="th" scope="row">
                        {historyRow.medicine}
                      </TableCell>
                      <TableCell>{historyRow.type}</TableCell>
                      <TableCell>{historyRow.status}</TableCell>
                      <TableCell>{historyRow.strength}</TableCell>
                      <TableCell>{historyRow.supply}</TableCell>
                      <TableCell>{historyRow.route}</TableCell>
                      <TableCell>{historyRow.frequency}</TableCell>
                      <TableCell>{historyRow.quantity}</TableCell>
                      <TableCell>{historyRow.start_date}</TableCell>
                      <TableCell>{historyRow.stop_date}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData(
    "Lumify Eye Drop Active 0.25%",
    "Instruction: Take 2 drops morning and night",
    "",
    ""
  ),
  createData(
    "Tylenol Tablet Inactive 600 MG",
    "Instruction: Whenever there is pain, every 6 hours",
    "",
    ""
  ),
  // createData('Iodine (Inactive) Nausea', ""),
  // createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
  // createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
];

export default function Medication() {
  var classes = useStyles();
  const [open, setOpen] = useState(false)
const [modalShow, setModalShow] = useState(false);
  const [selectclient, setselectclient] = useState(null);
  const [title, setTitle] = useState(null);
const handleOpen = () => {
  setOpen(true);
};
const hideHandle = () => {
 
  setOpen(false);
}
  const innerHeight = "40px";
  return (
    <>
    <ManageMedication  open={open}
        onHide={hideHandle}
        title={"Add Medication"}
        selectclient={selectclient}
        setModalShow={setModalShow}/>
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow style={{height:'40px'}}>
            
            <TableCell></TableCell>
            <TableCell />
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"> <Button
              className={classes.fillBtn} style={{fontSize: "10px"}}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleOpen}
            >
              Add Medication
            </Button></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row  key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}
