import { handleKeyPress, handleKeyPressPaste, validateLargeAmount } from "../../../../hooks/helper";

export const insuranceFormElements = [
  // {
  //   title: "Insurance Details",
  //   mdSize: "12",
  // },
  {
    key: "insurnace",
    label: "Insurance",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "plan",
    label: "Plan",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
];
export const physicalFormElements = [
  // {
  //   title: "Physician Details",
  //   mdSize: "12",
  // },
  {
    key: "physician_id",
    label: "Physician Name",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "order_date",
    label: "Order Date",
    placeHolder: "type here",
    type: "date",
    mdSize: "3",
  },
  {
    key: "order_type",
    label: "Order Type",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "3",
  },
];
export const diagnosisFormElements = [
  // {
  //   title: "Diagnosis Details",
  //   mdSize: "12",
  // },
  {
    key: "a",
    label: "A",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "1",
  },
  {
    key: "b",
    label: "B",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "1",
  },
  {
    key: "c",
    label: "C",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "1",
  },
  {
    key: "d",
    label: "D",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "1",
  },
  {
    key: "e",
    label: "E",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "1",
  },
  {
    key: "f",
    label: "F",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "1",
  },
  {
    key: "g",
    label: "G",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "1",
  },
  {
    key: "h",
    label: "H",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "1",
  },
  {
    key: "i",
    label: "I",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "1",
  },
  {
    key: "j",
    label: "J",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "1",
  },
  {
    key: "k",
    label: "K",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "1",
  },
  {
    key: "l",
    label: "L",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "1",
  },
];
export const frameFormElements = [
  // {
  //   title: "Frame Details",
  //   mdSize: "12",
  // },
  {
    key: "frame_src",
    label: "Source",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "manufacturer_id",
    label: "Manufacturer",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "col",
    label: "Col:",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
  },
  {
    key: "name",
    label: "Name",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
  },
  {
    key: "upc",
    label: "SKU",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "btnLookup",
    label: "",
    placeHolder: "",
    type: "button",
    mdSize: "1",
    btnlbl: "Lookup",
    onClick: true,
  },
  {
    key: "btnUpdate",
    label: "",
    placeHolder: "",
    type: "button",
    mdSize: "1",
    btnlbl: "Update",
    onClick: true,
  },
  {
    key: "invoice_no",
    label: "Invoice No.",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
  },
  {
    key: "color",
    label: "Color",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
  },
  {
    key: "col_code",
    label: "Col #",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
  },
  {
    key: "material_id",
    label: "Material",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "frame_type_id",
    label: "Frame Type",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "gender",
    label: "Gender",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "eye",
    label: "Eye",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "bridge",
    label: "Bridge",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "temple",
    label: "Temple",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "usage",
    label: "Usage",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "frame_shape_id",
    label: "Shape",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
  },
  {
    key: "lens_color",
    label: "Lens Color",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
  },
  {
    key: "a",
    label: "A",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "b",
    label: "B",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "ed",
    label: "Ed",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "dbl",
    label: "DBL",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "rim_type",
    label: "Rim Type",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
  },
];
export const specticalFormElements = [
  // {
  //   title: "Spectical Details",
  //   mdSize: "12",
  // },


  {
    title: "OD",
    mdSize: "1",
    copyBtn: "true",
  },
  {
    key: "od_balance",
    label: "Balance",
    placeHolder: "",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "od_sphere",
    label: "Sphere",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_cyl",
    label: "Cyl",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_axis",
    label: "Axis",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_add",
    label: "Add",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_seg_ht",
    label: "Seg Ht",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_h_prism",
    label: "H Prism",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_h_base",
    label: "H Base",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_v_prism",
    label: "V Prism",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_v_base",
    label: "V Base",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    title: "",
    mdSize: "1",
  },
  {
    title: "OS",
    mdSize: "1",
  },
  {
    key: "os_balance",
    label: "",
    placeHolder: "",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "os_sphere",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_cyl",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_axis",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_add",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_seg_ht",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_h_prism",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_h_base",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_v_prism",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_v_base",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    title: "",
    mdSize: "1",
  },
  {
    title: "OD",
    mdSize: "1",
    copyBtn: "true",
  },
  {
    key: "od_oc_ht",
    label: "OC Ht",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_vertex",
    label: "Vertex",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_bc",
    label: "BC",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_dec",
    label: "Dec",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_inset",
    label: "Inset",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_total_dec",
    label: "Total Dec",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_sku",
    label: "sku",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_source",
    label: "Source",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "tint_color",
    label: "Tint Color",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "sample",
    label: "Sample",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "by",
    label: "By",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    title: "OS",
    mdSize: "1",
  },
  {
    key: "os_oc_ht",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },

  {
    key: "os_vertex",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_bc",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_dec",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_inset",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_total_dec",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_sku",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_source",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "lab_inst",
    label: "Lab Inst.",
    placeHolder: "text",
    type: "text",
    mdSize: "3",
    onKeyPress: validateLargeAmount,
  },
  {
    title: "Far",
    mdSize: "1",
  },
  {
    key: "far_od",
    label: "OD",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "far_os",
    label: "OS",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "far_ou",
    label: "OU",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    title: "Near",
    mdSize: "1",
  },

  {
    key: "near_od",
    label: "OD",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "near_os",
    label: "OS",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "near_ou",
    label: "OU",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "",
    label: "",

    mdSize: "4",
  },
  {
    key: "eye",
    label: "Eye",
    placeHolder: "select",
    type: "select",
    options: ["Left","Right","Both"],
    mdSize: "1",
  },
  {
    key: "material",
    label: "Material",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "finishing",
    label: "Finishing Ctr",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
  },
  {
    key: "stock_lens",
    label: "Stock Lens",
    placeHolder: "",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "computer_distance",
    label: "Computer Distance",
    placeHolder: "select",
    type: "select",
    options: ["Computer", "Reading", "Distance"],
    mdSize: "2",
  },
  {
    key: "diameter",
    label: "Lense Diameter",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "btnLookup",
    label: "",
    placeHolder: "",
    type: "button",
    mdSize: "1",
    btnlbl: "Lookup",
    onClick: true,
  },
  {
    key: "btnUpdate",
    label: "",
    placeHolder: "",
    type: "button",
    mdSize: "1",
    btnlbl: "Update",
    onClick: true,
  },
  {
    key: "select_attribute_1",
    label: "Spectacle Attribute",
    placeHolder: "text",
    type: "select",
    options: [],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "attribute_text_1",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "select_attribute_2",
    label: "",
    placeHolder: "text",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "attribute_text_2",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
  },
  {
    key: "select_attribute_3",
    label: "",
    placeHolder: "text",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "attribute_text_3",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
  },

  {
    key: "select_attribute_4",
    label: "",
    placeHolder: "text",
    type: "select",
    mdSize: "2",
    options: [],
  },
  {
    key: "attribute_text_4",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "select_attribute_5",
    label: "",
    placeHolder: "text",
    type: "select",
    mdSize: "2",
    options: [],
  },
  {
    key: "attribute_text_5",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
  },
  {
    key: "select_attribute_6",
    label: "",
    placeHolder: "text",
    type: "select",
    mdSize: "2",
    options: [],
  },
  {
    key: "attribute_text_6",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "select_attribute_7",
    label: "",
    placeHolder: "text",
    type: "select",
    mdSize: "2",
    options: [],
  },
  {
    key: "attribute_text_7",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "select_attribute_8",
    label: "",
    placeHolder: "text",
    type: "select",
    mdSize: "2",
    options: [],
  },
  {
    key: "attribute_text_8",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
];
export const serviceFormElements = [
  {
    key: "lab_id",
    label: "Lab",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "status",
    label: "Status",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
  },

  {
    key: "promised_date",
    label: "Promised",
    placeHolder: "select",
    type: "date",
    // options: [],
    mdSize: "2",
  },
  
  {
    key: "lab_order_id",
    label: "Lab Order",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
    onKeyPress: handleKeyPress,
    onPaste:handleKeyPressPaste,
  },
  {
    key: "lab_order_status",
    label: "Lab Status",
    placeHolder: "select",
    type: "select",
    options:[],
    mdSize: "2",
  },
  {
    key: "order_inspected_by_id",
    label: "Inspected By",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
  },
  
  {
    key: "order_reprocess_reason",
    label: "Reprocess Reason",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
  },
  // {
  //   key: "tray_label",
  //   label: "",
  //   type: "button",
  //   mdSize: "2",
  // },
  {
    key: "order_delevered_by_id",
    label: "Delivered By",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
  },
  // {
  //   key: "delivered_name",
  //   label: "",
  //   placeHolder: "text",
  //   type: "text",
  //   mdSize: "2",
  // },
  {
    key: "ship_to",
    label: "Ship To",
    placeHolder: "",
    type: "checkbox",
    mdSize: "2",
  },
    {
    key: "notes",
    label: "Notes",
    placeHolder: "text",
    type: "text",
    mdSize: "6",
  },
  {
    key: "shipping_address_1",
    label: "Shipping Address Line 1",
    placeHolder: "text",
    type: "text",
    mdSize: "4",
  },
  {
    key: "shipping_address_2",
    label: "Shipping Address Line 2",
    placeHolder: "text",
    type: "text",
    mdSize: "3",
  },
  {
    key: "state",
    label: "State",
    placeHolder: "select",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "2",
  },
  {
    key: "city",
    label: "Shipping City",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
  },
  {
    key: "zip",
    label: "Zip Code",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: handleKeyPress,
    onPaste:handleKeyPressPaste,
  }
];
export const contactFormElements = [
  // {
  //   title: "Contact Details",
  //   mdSize: "12",
  // },

  // {
  //   key: "lens_type",
  //   label: "Lens Type",
  //   placeHolder: "",
  //   type: "select",
  //   options: [],
  //   mdSize: "2",
  // },
  // {
  //   key: "trial",
  //   label: "Trial",
  //   placeHolder: "",
  //   type: "checkbox",
  //   mdSize: "2",
  // },

  {
    title: "OD",
    mdSize: "1",
    copyBtn: "true",
  },
  {
    key: "bc",
    label: "BC",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "di",
    label: "DI",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "sph",
    label: "SPH",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "cyl",
    label: "CYL",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "axis",
    label: "Axis",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "add",
    label: "Add",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "multifocal",
    label: "Multifocal",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "sku",
    label: "",
    type: "button",
    mdSize: "1",
  },
  {
    key: "",
    label: "",
    placeHolder: "",
    type: "",
    mdSize: "3",
  },
  {
    title: "OS",
    mdSize: "1",
  },
  {
    key: "bc",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "di",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "sph",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "cyl",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "axis",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "add",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "multifocal",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "",
    label: "",
    placeHolder: "",
    type: "",
    mdSize: "4",
  },
  {
    title: "OD",
    mdSize: "1",
    copyBtn: "true",
  },
  {
    key: "manufacturer",
    label: "Manufacturer",
    placeHolder: "",
    type: "text",
    mdSize: "2",
  },
  {
    key: "name",
    label: "Name",
    placeHolder: "",
    type: "text",
    mdSize: "2",
  },
  {
    key: "color",
    label: "Color",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "upc_code",
    label: "SKU",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "inv_source",
    label: "Source",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "qty",
    label: "Qty",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "",
    label: "",
    placeHolder: "",
    type: "",
    mdSize: "3",
  },
  {
    title: "OS",
    mdSize: "1",
  },
  {
    key: "manufacturer",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "2",
  },
  {
    key: "name",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "2",
  },
  {
    key: "color",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "upc_code",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "inv_source",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "qty",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "",
    label: "",
    placeHolder: "",
    type: "",
    mdSize: "3",
  },
  {
    key: "cl_care",
    label: "CL Care",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "replacement_sch",
    label: "Replacement Schedule",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "ext_notes",
    label: "External Notes",
    placeHolder: "text",
    type: "text",

    mdSize: "6",
  },
  {
    key: "notes",
    label: "Notes",
    placeHolder: "text",
    type: "textarea",

    mdSize: "12",
  },
];
export const hardContactFormElements = [
  // {
  //   title: "Contact Details",
  //   mdSize: "12",
  // },
  // {
  //   key: "sku",
  //   label: "",
  //   type: "button",
  //   mdSize: "1",
  // },

  // {
  //   key: "lens_type",
  //   label: "Lens Type",
  //   placeHolder: "",
  //   type: "select",
  //   options: [],
  //   mdSize: "2",
  // },
  // {
  //   key: "trial",
  //   label: "Trial",
  //   placeHolder: "",
  //   type: "checkbox",
  //   mdSize: "2",
  // },

  {
    title: "OD",
    mdSize: "1",
    copyBtn: "true",
  },
  {
    key: "bc",
    label: "BC",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "bc2",
    label: "BC2",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "di",
    label: "DI",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "di2",
    label: "DI2",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "sph",
    label: "SPH",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "sph2",
    label: "SPH2",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "cyl",
    label: "CYL",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "axis",
    label: "Axis",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "add",
    label: "Add",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "prism",
    label: "Prism",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "multifocal",
    label: "Multifocal",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },

  {
    title: "OS",
    mdSize: "1",
  },
  {
    key: "bc",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "bc2",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "di",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "di2",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "sph",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "sph2",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "cyl",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "axis",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "add",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "prism",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "multifocal",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    title: "OD",
    mdSize: "1",
    copyBtn: "true",
  },
  {
    key: "seg_h",
    label: "Seg Hi",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "seg_di",
    label: "Seg Di",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "ct",
    label: "CT",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "aspheric",
    label: "Aspheric",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "oz",
    label: "OZ",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "r2",
    label: "R2",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "w2",
    label: "W2",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "r3",
    label: "R3",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "w3",
    label: "W3",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "dot",
    label: "Dot",
    placeHolder: "",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "warrenty",
    label: "Warrenty",
    placeHolder: "",
    type: "checkbox",
    mdSize: "1",
  },

  {
    title: "OS",
    mdSize: "1",
  },
  {
    key: "seg_h",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "seg_di",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "ct",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "aspheric",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "oz",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "r2",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "w2",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "r3",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "w3",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "dot",
    label: "",
    placeHolder: "",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "warrenty",
    label: "",
    placeHolder: "",
    type: "checkbox",
    mdSize: "1",
  },
  {
    title: "OD",
    mdSize: "1",
    copyBtn: "true",
  },
  {
    key: "manufacturer",
    label: "Manufacturer",
    placeHolder: "",
    type: "text",
    mdSize: "2",
  },
  {
    key: "name",
    label: "Name",
    placeHolder: "",
    type: "text",
    mdSize: "2",
  },
  {
    key: "color",
    label: "Color",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "material",
    label: "Material",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "blend",
    label: "Blend",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "upc_code",
    label: "UPC",
    placeHolder: "",
    type: "text",
    mdSize: "2",
  },
  {
    key: "inv_source",
    label: "Source",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "qty",
    label: "Qty",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },

  {
    title: "OS",
    mdSize: "1",
  },
  {
    key: "manufacturer",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "2",
  },
  {
    key: "name",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "2",
  },
  {
    key: "color",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "material",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "blend",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "upc_code",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "2",
  },
  {
    key: "inv_source",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "qty",
    label: "",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },

  {
    key: "cl_care",
    label: "CL Care",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "replacement_sch",
    label: "Replacement Schedule",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "ext_notes",
    label: "External Notes",
    placeHolder: "text",
    type: "text",

    mdSize: "6",
  },
  {
    key: "notes",
    label: "Notes",
    placeHolder: "text",
    type: "textarea",

    mdSize: "12",
  },
];
export const otherFormElements = [
  // {
  //   title: "Other Details",
  //   mdSize: "12",
  // },
  {
    key: "lab",
    label: "Lab",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
  },
  {
    key: "status",
    label: "Status",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
  },
  {
    key: "sold_by",
    label: "Sold By",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
  },
  {
    key: "ship_to",
    label: "Ship To",
    placeHolder: "text",
    type: "checkbox",
    mdSize: "2",
  },
  {
    key: "promised",
    label: "Promised ",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
  },
  {
    key: "tray",
    label: "Tray",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
  },
  {
    key: "filled_by",
    label: "Filled By",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
  },
  {
    key: "lab_order",
    label: "Lab Order ",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
  },
  {
    key: "lab_status",
    label: "Lab Status",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
  },
  {
    key: "inspected_by ",
    label: "Inspected By ",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
  },
  {
    key: "reprocess_reason",
    label: "Reprocess Reason ",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
  },
  {
    key: "tray_label",
    label: "Tray Label ",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
  },
  {
    key: "delivered_by ",
    label: "Delivered By",
    placeHolder: "text",
    type: "text",
    mdSize: "2",
  },
];
export const tableService = [
  {
    charges: "Frame-see line",
    eye: "OD",
    copay: "00",
    patient: "169",
    patient_total: "169",
    insurance: "00",
    item_total: "169",
    retail: "169",
    unit_per: "169",
  },
];
