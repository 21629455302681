import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Tabs, Tab, Paper, Button, TextField } from "@material-ui/core";
import PageTitle from "../../../components/PageTitle/PageTitle";
import backArrow from "./backArrow.svg";
import useStyles from "./styles";
import PatientProfile from "../../../components/Patient/Cases/PatientProfile";
import Demography from "../../../components/Patient/Cases/Demography";
import Insurance from "../../../components/Patient/Edit/Insurance";
import FamilyList from "../../../components/Patient/Cases/Family/FamilyList";
import pageNotFound from "../../../assets/pageNotFound.png";
import {
  postPatientData,
  postPatientAdditionalData,
  postPatientInsuranceData,
  getPatient,
} from "../../../services/ServiceLayer";

import {
  paramForAddPatientProfile,
  paramForAddPatientAdditionalDetails,
  paramForAddPatientInsuranceDetails,
  currentUser,
} from "../../../hooks/helper";
import CustomDataList from "../../../components/Patient/Cases/CustomDataTab/CustomeDataList";
import { Box } from "@material-ui/core";
import AttachmentList from "../../../components/Patient/Cases/AttachmentTab/AttachmentList";
import PatientValidation from "../../../hooks/PatientValidation";
import { toast } from "react-toastify";
import moment from "moment";
import useListData from "../../../components/common/allMetaData/useListData";
import IntractionList from "../../../components/Patient/Detail/Case/IntractionTab/IntractionList";
export default function PatientSteps(props) {
  const { patientReadValue } = props;
  var classes = useStyles();
  const listData = useListData(); // Use the custom hook to fetch listData
  var [activeTabId, setActiveTabId] = useState(0);

  const [isActionCalled, setIsActionCalled] = useState(false);
  const [isActionCalledExit, setIsActionCalledExit] = useState(false);
  const [patientId, setPatientId] = useState("");

  const currentDate = moment().format("YYYY-MM-DD");
  const [patientProfileData, setPatientProfileData] = useState(() => {
    const savedData = localStorage.getItem("patientProfileData");
    console.log(savedData, "saveddataa");
    return savedData
      ? JSON.parse(savedData)
      : {
        patient_id: '',
        title: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        suffix: "",
        nick_name: "",
        gender: "",
        marital: "",
        dateofbirth: "",
        gender_identity: "",
        orientation: "",
        contact_cell: "",
        contact_home: "",
        contact_office: "",
        email: "",
        allow_health_info: "",

        pref_contact: "",
        is_opt_email: "",
        is_opt_sms: "",
        is_opt_call: "",
        pref_call_hours: "",
        ssn: "",
        allergies: "",
        is_hipaa_consent: "",
        is_opt_marketing: "",
        is_patient_portal_access: "",
        preffered_language: "",

        //addressbilling
        billing_address_line1: "",
        billing_address_line2: "",
        billing_city: "",
        billing_state: "",
        billing_zip: "",
        billing_address_verified: "",
        shipping_address_line1: "",
        shipping_address_line2: "",
        shipping_city: "",
        shipping_state: "",
        shipping_zip: "",
        shipping_address_verified: "",
        mailing_address_line1: "",
        mailing_address_line2: "",
        mailing_city: "",
        mailing_state: "",
        mailing_zip: "",
        mailing_address_verified: "",
        // addition
        ethnicity: "",
        race: "",
        hobbies: "",
        primary_physician: "",
        physician_address: "",
        physician_city: "",
        physician_state: "",
        physician_zip: "",
        notes: "",
        imp_shrt_notes: "",
        addition_note_priority: "",
        addition_applicable_role: "",
        applicable_role_id: "",
        is_disability: "",
        is_veteran_status: "",

        // emergency
        first_emergency_contact_name: "",
        first_emergency_contact_con: "",
        sec_emergency_contact_name: "",
        sec_emergency_contact_con: "",
      };
  });
  const [isProfileStateChanged, setIsProfileStateChanged] = useState(false);
  // list end meta data
  toast.configure();
  const history = useHistory();
  // const navigate = useNavigate();
  const goBack = () => {
    history.push(`/app/patient`);
  };

  // Destructure listData object
  const {
    languageValues,
    genderValues,
    raceValues,
    titleValues,
    maritalValues,
    genderIdentificationValues,
    sexOrientationValues,
    ethinicityValues,
    relTypeValues,
    deceasedValues,
    disabilityValues,
    gaurantorValues,
    preferenceHourValues,
    veteranValues,
    insuranceValues,
    additionalInsuranceValues,
    addressStatus,
    preferenceContactValues,
    thanksValues,
    patientStatusValues,
    inactiveValues,
    applicableRoleValues,
    phoneTypeValues,
    referenceSourceValues,
    fileCategoryValues,
  } = listData;

  const saveProfileNext = () => {
    // e.preventDefault();
    if (isProfileStateChanged) {
      checkValidator();
      setIsActionCalled(true);
    } else if (patientId) {
      setActiveTabId(1);
    } else {
      checkValidator();
      setIsActionCalled(true);
    }
  };

  const saveAndExist = () => {
    // e.preventDefault();
    checkValidator();
    setIsActionCalledExit(true);
    console.log("isActionCalled", isActionCalled, patientProfileData);
    console.log("error", error);
  };
  // Add profilr details
  useEffect(() => {
    console.log("isActionCalled", isActionCalled);
    console.log("error", error);
    if (!isError() && error !== null && isActionCalled) {
      postPatientData(
        paramForAddPatientProfile([patientProfileData, "ADDPAT", patientId])
      ).then((response) => {
        console.log(response);
        if (response.status.status === true) {
          // setPatientId(response.data.patient_id);
          setIsActionCalled(false);
          history.push(`/app/patient`);
        } else {
          toast.error(response.status.description);
        }
      });
    } else {
      setIsActionCalledExit(false);
    }
  }, [isActionCalledExit]);

  useEffect(() => {
    console.log("isActionCalled", isActionCalled);
    console.log("error", error);
    if (!isError() && error !== null && isActionCalled) {
      postPatientData(
        paramForAddPatientProfile([
          patientProfileData,
          patientId ? "UPDPAT" : "ADDPAT",
          patientId,
        ])
      ).then((response) => {
        console.log(response);
        if (response.status.status === true) {
          toast.success("Patient Profile Information Saved");
          setPatientId(response.data.patient_id);
          setActiveTabId(1);
          setIsActionCalled(false);
          setIsProfileStateChanged(false);
        } else {
          toast.error(response.status.description);
          setActiveTabId(0);
        }
      });
    } else {
      setIsActionCalled(false);
    }
    setIsActionCalled(false);
    setIsProfileStateChanged(false);
  }, [isActionCalled]);
  //
  const [patientOtherData, setPatientOtherData] = useState({
    patient_id: '',
    occupation: "",
    employer: "",
    employer_address_line1: "",
    employer_address_line2: "",
    employer_city: "",
    employer_state: "",
    employer_country: "USA",
    employer_zip: "",
    employer_con: "",
    employer_email: "",
    //
    guarantor_type: "",
    guarantor_relationship: "",
    guarantor_first_name: "",
    guarantor_middle_name: "",
    guarantor_last_name: "",
    guarantor_suffix: "",
    guarantor_dob: "",
    guarantor_gender: "",
    guarantor_ssn: "",
    guarantor_employer_email: "",
    guarantor_address_line1: "",
    guarantor_address_line2: "",
    guarantor_city: "",
    guarantor_state: "",
    guarantor_country: "USA",
    guarantor_zip: "",
    guarantor_con: "",
    guarantor_home_con: "",
    guarantor_office_con: "",
    guarantor_email: "",
    //
    referral_source: "",
    referral_first_name: "",
    referral_middle_name: "",
    referral_last_name: "",
    referral_suffix: "",
    referral_dob: "2002-01-01",
    referral_gender: "",
    referral_con: "",
    referral_con_type: "",
    referral_email: "",
    referral_ssn: "",
    referral_thanks_note: "",
    referral_address_line1: "",
    referral_address_line2: "",
    referral_city: "",
    referral_state: "",
    referral_country: "",
    referral_zip: "",
    //
    guardian_relationship: "",
    guardian_first_name: "",
    guardian_middle_name: "",
    guardian_last_name: "",
    guardian_suffix: "",
    guardian_dob: "",
    guardian_gender: "",
    guardian_con_type: "",
    guardian_email: "",
    guardian_thanks_note: "Y",
    guardian_address_line1: "",
    guardian_address_line2: "",
    guardian_city: "",
    guardian_state: "",
    guardian_country: "USA",
    guardian_zip: "",
    guardian_con: "",
    guardian_home_con: "",
    guardian_office_con: "",
    is_guardian_same_address: "Y",
    guardian1_relationship: "",
    guardian1_first_name: "",
    guardian1_middle_name: "",
    guardian1_last_name: "",
    guardian1_suffix: "",
    guardian1_dob: "1988-01-01",
    guardian1_gender: "",
    guardian1_con_type: "",
    guardian1_email: "",
    guardian1_thanks_note: "",
    guardian1_address_line1: "",
    guardian1_address_line2: "",
    guardian1_city: "",
    guardian1_state: "",
    guardian1_country: "USA",
    guardian1_zip: "",
    guardian1_con: "",
    guardian1_home_con: "",
    guardian1_office_con: "",
    is_guardian1_same_address: "Y",
    first_emergency_contact_name: "",
    first_emergency_contact_con: "",
    sec_emergency_contact_name: "",
    sec_emergency_contact_con: "",
    is_active: "Y",
    created_dt: currentDate,
    updated_by: currentUser(),
    updated_dt: currentDate,
    access_location: "ak-1.1",
    opt_type: "ADDPAT",
  });

  // add additional details
  const saveOtherDetailsNext = () => {
    console.log("patientId======<<<<<<<<???????" + patientId);
    postPatientAdditionalData(
      paramForAddPatientAdditionalDetails([
        patientOtherData,
        "ADDPAT",
        patientId,
      ])
    ).then((response) => {
      console.log(response);
      if (response.status.status === true) {
        toast.success("Additional Details Saved");
        setActiveTabId(2);
      } else {
        toast.error(response.status.description);
      }
    });
    // setActiveTabId(2);
  };
  const saveOtherDetailsExit = () => {
    console.log("patientId======<<<<<<<<???????" + patientId);
    postPatientAdditionalData(
      paramForAddPatientAdditionalDetails([
        patientOtherData,
        "ADDPAT",
        patientId,
      ])
    ).then((response) => {
      console.log(response);
      if (response.status.status === true) {
        // setPatientId(response?.data?.patient_id);
        history.push(`/app/patient`);
      } else {
        toast.error(response.status.description);
      }
    });
    // setActiveTabId(2);
  };

  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    PatientValidation({
      first_name: patientProfileData?.first_name,
      title: patientProfileData?.title,
      last_name: patientProfileData?.last_name,
      // email: patientProfileData?.email,
      // contact_cell: patientProfileData?.contact_cell,
    });

  // insurance
  const [patientInsuranceData, setPatientInsuranceData] = useState({
    patient_id: patientId,
    mi_insurance_provider: "",
    mi_insurance_plan: "",
    mi_insurance_policynumber: "",
    mi_insurance_groupnumber: "",
    mi_effective_date: "",
    mi_is_active: "",
    mi_co_pay: 0,
    mi_accept_assignment: "",
    mi_self_subscribed: "",
    mi_family_subscribed: "",
    mi_patient_parent_id: 0,
    vi_insurance_provider: "",
    vi_insurance_plan: "",
    vi_insurance_policynumber: "",
    vi_insurance_groupnumber: "",
    vi_effective_date: "",
    vi_is_active: "",
    vi_co_pay: 0,
    vi_accept_assignment: "",
    vi_self_subscribed: "",
    vi_family_subscribed: "",
    vi_patient_parent_id: 0,
    adi_insurance_provider: "",
    adi_insurance_plan: "",
    adi_insurance_policynumber: "",
    adi_insurance_groupnumber: "",
    adi_effective_date: "",
    adi_is_active: "",
    adi_co_pay: 0,
    adi_accept_assignment: "",
    adi_self_subscribed: "",
    adi_family_subscribed: "",
    adi_patient_parent_id: 0,
    is_active: "",
    created_dt: "",
    updated_by: currentUser(),
    updated_dt: "",
    access_location: "",
    opt_type: "",
  });
  const saveInsuranceDetailsNext = () => {
    console.log("patientId======<<<<<<<<???????" + patientId);
    postPatientInsuranceData(
      paramForAddPatientInsuranceDetails([
        patientInsuranceData,
        "ADDPAT",
        patientId,
      ])
    ).then((response) => {
      console.log(response);
      if (response.status.status === true) {
        toast.success("Insurance Information Saved");
        setActiveTabId(3);
      } else {
        toast.error(response.status.description);
      }
    });
  };
  const saveInsuranceDetailsExit = () => {
    console.log("patientId======<<<<<<<<???????" + patientId);
    postPatientInsuranceData(
      paramForAddPatientInsuranceDetails([
        patientInsuranceData,
        "ADDPAT",
        patientId,
      ])
    ).then((response) => {
      console.log(response);
      if (response.status.status === true) {
        toast.success("Insurance Information Saved");
        history.push(`/app/patient`);
      } else {
        toast.error(response.status.description);
      }
    });
  };
  useEffect(() => {
    console.log("patientId 1", patientId);
    if (patientId) {
      console.log("patientId 2", patientId);
      // patient details
      getPatient(`patient_id=${patientId}`).then((response) => {
        console.log(response);
        setPatientProfileData(response?.data);
      });
    }
  }, [patientId]);
  const [subscribePatient, setSubscribePatient] = useState(null);
  const [subscribeVIPatient, setSubscribeVIPatient] = useState(null);
  const [subscribeADIPatient, setSubscribeADIPatient] = useState(null);
  useEffect(() => {
    console.log("patientInsuranceData", patientInsuranceData);
    if (patientInsuranceData?.mi_patient_parent_id) {
      getPatient(
        `patient_id=${patientInsuranceData?.mi_patient_parent_id}`
      ).then((response) => {
        console.log("response -392", response);
        setSubscribePatient({
          ...subscribePatient,
          pr_subscriber_name: response?.data?.first_name,
          pr_patient_id: response?.data?.patient_id,
          pr_middle_name: response?.data?.middle_name,
          pr_last_name: response?.data?.last_name,
          pr_dateofbirth: response?.data?.dateofbirth,
          pr_ssn: response?.data?.ssn,
          pr_sex: response?.data?.gender,
          pr_subscribe_phone: response?.data?.contact_Cell,
          pr_office_phone: response?.data?.contact_office,
          subscribe_employeer: null,
          pr_address1: response?.data?.billing_address_line1,
          pr_address2: response?.data?.billing_address_line1,
          pr_city: response?.data?.billing_city,
          pr_state: response?.data?.billing_state,
          pr_zip: response?.data?.billing_zip,
          pr_emp_address1: response?.data?.shipping_address_line1,
          pr_emp_address2: response?.data?.shipping_address_line2,
          pr_emp_city: response?.data?.shipping_city,
          pr_emp_state: response?.data?.shipping_state,
          pr_emp_zip: response?.data?.shipping_zip,
        });
      });
    }
    if (patientInsuranceData?.vi_patient_parent_id) {
      getPatient(
        `patient_id=${patientInsuranceData?.vi_patient_parent_id}`
      ).then((response) => {
        console.log("response -392", response);
        setSubscribeVIPatient({
          ...subscribeVIPatient,
          pr_subscriber_name: response?.data?.first_name,
          pr_patient_id: response?.data?.patient_id,
          pr_middle_name: response?.data?.middle_name,
          pr_last_name: response?.data?.last_name,
          pr_dateofbirth: response?.data?.dateofbirth,
          pr_ssn: response?.data?.ssn,
          pr_sex: response?.data?.gender,
          pr_subscribe_phone: response?.data?.contact_Cell,
          pr_office_phone: response?.data?.contact_office,
          subscribe_employeer: null,
          pr_address1: response?.data?.billing_address_line1,
          pr_address2: response?.data?.billing_address_line1,
          pr_city: response?.data?.billing_city,
          pr_state: response?.data?.billing_state,
          pr_zip: response?.data?.billing_zip,
          pr_emp_address1: response?.data?.shipping_address_line1,
          pr_emp_address2: response?.data?.shipping_address_line2,
          pr_emp_city: response?.data?.shipping_city,
          pr_emp_state: response?.data?.shipping_state,
          pr_emp_zip: response?.data?.shipping_zip,
        });
      });
    }
    if (patientInsuranceData?.adi_patient_parent_id) {
      getPatient(
        `patient_id=${patientInsuranceData?.adi_patient_parent_id}`
      ).then((response) => {
        console.log("response -392", response);
        setSubscribeADIPatient({
          ...subscribeADIPatient,
          pr_subscriber_name: response?.data?.first_name,
          pr_patient_id: response?.data?.patient_id,
          pr_middle_name: response?.data?.middle_name,
          pr_last_name: response?.data?.last_name,
          pr_dateofbirth: response?.data?.dateofbirth,
          pr_ssn: response?.data?.ssn,
          pr_sex: response?.data?.gender,
          pr_subscribe_phone: response?.data?.contact_Cell,
          pr_office_phone: response?.data?.contact_office,
          subscribe_employeer: null,
          pr_address1: response?.data?.billing_address_line1,
          pr_address2: response?.data?.billing_address_line1,
          pr_city: response?.data?.billing_city,
          pr_state: response?.data?.billing_state,
          pr_zip: response?.data?.billing_zip,
          pr_emp_address1: response?.data?.shipping_address_line1,
          pr_emp_address2: response?.data?.shipping_address_line2,
          pr_emp_city: response?.data?.shipping_city,
          pr_emp_state: response?.data?.shipping_state,
          pr_emp_zip: response?.data?.shipping_zip,
        });
      });
    }
  }, [patientInsuranceData]);
  useEffect(() => {
    const dataToStore = {
      patientProfileData,
      patientOtherData,
      patientId
    };
    localStorage.setItem("patientData", JSON.stringify(dataToStore));
  }, [patientProfileData, patientOtherData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPatientProfileData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [patientData, setPatientData] = useState(() => {
    const savedData = localStorage.getItem('patientData');
    return savedData ? JSON.parse(savedData) : {
      patientOtherData: {
        // default other fields
      },
      patientInsuranceData: {
        // default insurance fields
      }
    };
  });
  // useEffect(() => {
  //   localStorage.setItem('patientData', JSON.stringify(patientOtherData));
  // }, [patientData]);


  return (
    <>
      <div className={classes.titleDiv}>
        <img
          className={classes.imageicon}
          src={backArrow}
          onClick={goBack}
          alt="img"
        />
        <PageTitle title="Add New Patient" />
      </div>
      {patientReadValue && patientReadValue === "Y" ? (
        <Paper className={classes.iconsContainer}>
          <Tabs
            TabIndicatorProps={{
              style: { background: "#4E97FD", width: "150px" },
            }}
            variant="scrollable"
            scrollButtons="auto"
            value={activeTabId}
            onChange={(e, id) => (patientId ? setActiveTabId(id) : "")}
            // onChange={(id) => tabClickEvent(id)}
            className={classes.iconsBar}
          >
            <Tab label="Personal Details" classes={{ root: classes.tab }} />
            <Tab
              label="Other Details"
              classes={{ root: classes.tab }}
              style={{ cursor: patientId ? "pointer" : "not-allowed" }}
            />
            <Tab
              label="Insurance"
              classes={{ root: classes.tab }}
              style={{ cursor: patientId ? "pointer" : "not-allowed" }}
            />

            <Tab
              label="Attachments"
              classes={{ root: classes.tab }}
              style={{ cursor: patientId ? "pointer" : "not-allowed" }}
            />

            <Tab
              label="Family"
              classes={{ root: classes.tab }}
              style={{ cursor: patientId ? "pointer" : "not-allowed" }}
            />

            <Tab
              label="Custom Data"
              classes={{ root: classes.tab }}
              style={{ cursor: patientId ? "pointer" : "not-allowed" }}
            />
            <Tab
              label="Interactions"
              classes={{ root: classes.tab }}
              style={{ cursor: patientId ? "pointer" : "not-allowed" }}
            />
          </Tabs>
          {/* Personal Details */}
          {activeTabId === 0 && (
            <Grid container spacing={4}>
              <Grid className={classes.gridItem} item xs={12}>
                {/* <Widget> */}
                <PatientProfile
                  patientProfileData={patientProfileData}
                  setPatientProfileData={setPatientProfileData}
                  error={error}
                  checkValidator={checkValidator}
                  resetValidator={resetValidator}
                  resetAllValidator={resetAllValidator}
                  isError={isError}
                  titleValues={titleValues}
                  genderValues={genderValues}
                  maritalValues={maritalValues}
                  genderIdentificationValues={genderIdentificationValues}
                  sexOrientationValues={sexOrientationValues}
                  preferenceContactValues={preferenceContactValues}
                  preferenceHourValues={preferenceHourValues}
                  languageValues={languageValues}
                  addressStatus={addressStatus}
                  ethinicityValues={ethinicityValues}
                  raceValues={raceValues}
                  disabilityValues={disabilityValues}
                  veteranValues={veteranValues}
                  patientStatusValues={patientStatusValues}
                  inactiveValues={inactiveValues}
                  deceasedValues={deceasedValues}
                  applicableRoleValues={applicableRoleValues}
                  setIsProfileStateChanged={setIsProfileStateChanged}
                />
                {/* <Box sx={{ mt: 4 }}>  <TextField
                  label="Patient Profile Data"
                  variant="outlined"
                  defaultValue='  '
                  patientProfileData={patientProfileData}
                  onChange={handleChange}
                  fullWidth
                  style={{}}
                /></Box> */}

                {/* </Widget> */}
              </Grid>
              <Grid className={classes.btnGroup} item xs={12}>
                <Button
                  className={classes.btnleftOutline}
                  onClick={saveAndExist}
                  variant="outlined"
                  size="large"
                >
                  Save & Exit
                </Button>
                <Button
                  onClick={saveProfileNext}
                  className={classes.btnlRightDark}
                  variant="contained"
                  size="large"
                  color="secondary"
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}
          {/* Other's Details && Additional */}
          {activeTabId === 1 && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {/* <Widget> */}
                <Demography
                  patientOtherData={patientOtherData}
                  setPatientOtherData={setPatientOtherData}
                  gaurantorValues={gaurantorValues}
                  relTypeValues={relTypeValues}
                  genderValues={genderValues}
                  phoneTypeValues={phoneTypeValues}
                  referenceSourceValues={referenceSourceValues}
                  thanksValues={thanksValues}
                  setIsProfileStateChanged={setIsProfileStateChanged}
                />
                {/* </Widget> */}
              </Grid>
              <Grid className={classes.btnGroup} item xs={12}>
                <Button
                  className={classes.btnleftOutline}
                  onClick={saveOtherDetailsExit}
                  variant="outlined"
                  size="large"
                >
                  Save & Exit
                </Button>
                <Button
                  onClick={saveOtherDetailsNext}
                  className={classes.btnlRightDark}
                  variant="contained"
                  size="large"
                  color="secondary"
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}
          {/* Insurance */}
          {activeTabId === 2 && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {/* <Widget> */}
                <Insurance
                  subscribePatient={subscribePatient}
                  setSubscribePatient={setSubscribePatient}
                  subscribeVIPatient={subscribeVIPatient}
                  setSubscribeVIPatient={setSubscribeVIPatient}
                  setSubscribeADIPatient={setSubscribeADIPatient}
                  subscribeADIPatient={subscribeADIPatient}
                  patientInsuranceData={patientInsuranceData}
                  setPatientInsuranceData={setPatientInsuranceData}
                  insuranceValues={insuranceValues}
                  additionalInsuranceValues={additionalInsuranceValues}
                  genderValues={genderValues}
                  setIsProfileStateChanged={setIsProfileStateChanged}
                />
                {/* </Widget> */}
              </Grid>
              <Grid className={classes.btnGroup} item xs={12}>
                <Button
                  onClick={saveInsuranceDetailsExit}
                  className={classes.btnleftOutline}
                  variant="outlined"
                  size="large"
                >
                  Save & Exit
                </Button>
                <Button
                  onClick={saveInsuranceDetailsNext}
                  className={classes.btnlRightDark}
                  variant="contained"
                  size="large"
                  color="secondary"
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}

          {/* Attachments */}
          {activeTabId === 3 && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {/* <Widget> */}
                <AttachmentList
                  patientId={patientId}
                  fileCategoryValues={fileCategoryValues}
                />
                {/* </Widget> */}
              </Grid>
              <Grid className={classes.btnGroup} item xs={12}>
                <Button
                  className={classes.btnleftOutline}
                  variant="outlined"
                  size="large"
                  onClick={() => setActiveTabId(2)}
                >
                  Back
                </Button>
                <Button
                  className={classes.btnlRightDark}
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={() => setActiveTabId(4)}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}

          {/* Family */}
          {activeTabId === 4 && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {/* <Widget> */}
                <FamilyList
                  patientId={patientId}
                  relTypeValues={relTypeValues}
                />
                {/* </Widget> */}
              </Grid>
              <Grid className={classes.btnGroup} item xs={12}>
                <Button
                  className={classes.btnleftOutline}
                  variant="outlined"
                  size="large"
                  onClick={() => setActiveTabId(4)}
                >
                  Back
                </Button>
                <Button
                  className={classes.btnlRightDark}
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={() => setActiveTabId(5)}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}

          {/* Custom Data */}
          {activeTabId === 5 && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {/* <Widget> */}
                <CustomDataList patientId={patientId} />
                {/* </Widget> */}
              </Grid>
              <Grid className={classes.btnGroup} item xs={12}>
                <Button
                  className={classes.btnleftOutline}
                  variant="outlined"
                  size="large"
                  onClick={() => setActiveTabId(5)}
                >
                  Back
                </Button>
                <Button
                  className={classes.btnlRightDark}
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={() => setActiveTabId(6)}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}
          {activeTabId === 6 && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <IntractionList patientId={patientId} />
              </Grid>
            </Grid>
          )}
        </Paper>
      ) : (
        <>
          <center>
            <img src={pageNotFound} alt="img" />
          </center>
        </>
      )}
    </>
  );
}