import React, { useState, useEffect } from "react";

// Model
import {
  Button,
  Typography,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Popup from "../common/modal/Popup";
import moment from "moment";
import alertIcon from "./alertIcon.svg";
// styles
import useStyles from "./styles";
import { toast } from "react-toastify";
import { postAccessories } from "../../services/ServiceLayer";
import { currentUser, loadState } from "../../hooks/helper";

export default function DeleteOtherPopup({
  onHide,
  deleteModalShow,
  setDeleteModalShow,
  selectclient,
}) {
  console.log("selectclient", selectclient);
  console.log("deleteModalShow", deleteModalShow);
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const [actionType, setActionType] = useState("DELACS");
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [isActionCalled, setIsActionCalled] = useState(false);
  toast.configure();
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setDeleteModalShow(false);
    setOpen(false);
  };
  const [accesseriesDetail, setAccesseriesDetail] = useState({
    accessries_id: "0",
    accessries_name: "",
    location_id: userData?.location_id,
    accessries_type: "",
    accessries_color: "",
    qty_purchased: "",
    qty_sold: "",
    qty_inventory: "",
    retail_price: "",
    item_cost: "",
    manufacturer_id: "",
    material_id: "",
    feature_id: "",
    size_id: "",
    width: "",
    height: "",
    unit_id: "",
    custome_field1: "",
    custome_field2: "",
    custome_field3: "",
    custome_field4: "",
    custome_field5: "",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: actionType,
  });;
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setAccesseriesDetail({
        accessries_id: "0",
        accessries_name: "",
        location_id: userData?.location_id,
        accessries_type: "",
        accessries_color: "",
        qty_purchased: "",
        qty_sold: "",
        qty_inventory: "",
        retail_price: "",
        item_cost: "",
        manufacturer_id: "",
        material_id: "",
        feature_id: "",
        size_id: "",
        width: "",
        height: "",
        unit_id: "",
        custome_field1: "",
        custome_field12: "",
        custome_field13: "",
        custome_field14: "",
        custome_field5: "",
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: actionType,
      });
    } else {
      setAccesseriesDetail({
        accessries_id: selectclient?.accessries_id,
        accessries_name: selectclient?.accessries_name,
        location_id: userData?.location_id,
        accessries_type: selectclient?.accessries_type,
        accessries_color: selectclient?.accessries_color,
        qty_purchased: selectclient?.qty_purchased,
        qty_sold: selectclient?.qty_sold,
        qty_inventory: selectclient?.qty_inventory,
        retail_price: selectclient?.retail_price,
        item_cost: selectclient?.item_cost,
        manufacturer_id: selectclient?.manufacturer_id,
        material_id: selectclient?.material_id,
        feature_id: selectclient?.feature_id,
        size_id: selectclient?.size_id,
        width: selectclient?.width,
        height: selectclient?.height,
        unit_id: selectclient?.unit_id,
        custome_field1: selectclient?.custome_field1,
        custome_field2: selectclient?.custome_field12,
        custome_field3: selectclient?.custome_field13,
        custome_field4: selectclient?.custome_field14,
        custome_field5: selectclient?.custome_field5,
        updated_by: currentUser(),
        updated_dt: selectclient,
        access_location: "access_location",

        opt_type: actionType,
      });
    }
  }, [selectclient]);

  const deleteFormData = () => {
    setIsActionCalled(true);
    setAccesseriesDetail({
      ...accesseriesDetail,
      opt_type:selectclient?.is_active === "Y" ?  "DELACS" : "REAACS",
    });
  };

  useEffect(() => {
    console.log("isActionCalled", isActionCalled);

    if (isActionCalled) {
      postAccessories(accesseriesDetail).then((response) => {
        console.log("actionType", actionType);

        console.log(response);
        if (response.status.status) {
          toast.success("Accesseries deleted successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });

          onHide();
          setDeleteModalShow(false);
          setOpen(false);
          setIsActionCalled(false);
        } else {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActionCalled(false);
        }
      });
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={deleteFormData}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <img src={alertIcon} alt="icons" />
            <Typography className={classes.formActiveHeading}>
              Are you sure! You want to{" "}
              {selectclient?.is_active === "Y" ? <>Delete </> : <>Re-active </>}{" "}
              this Accesseries?
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
