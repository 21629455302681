import React from 'react'
import ScheduleList from '../../components/Schedule/ScheduleList'

export default function Schedule() {
  return (
    <div>
      <ScheduleList/>
    </div>
  )
}
