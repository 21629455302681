import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
} from "@material-ui/core";

import axios from "axios";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import { TableRow, TableCell } from "@material-ui/core";
import TableDesign from "../components/common/table/TableDesign";
import Popup from "../components/common/modal/Popup";
import { getPatient } from "../services/ServiceLayer";

// styles
import useStyles from "../components/Patient/Cases/styles";
import moment from "moment";
import { toast } from "react-toastify";

export default function NpiLookupSearch(props) {
  const tableHeadings = [
    {
      label: "Physician Name ",
      align: "left !important",
    },
    {
      label: "Npi Number",
      align: "left !important",
    },
    {
      label: "Physician Type",
      align: "left !important",
    },
    {
      label: "Address",
      align: "left !important",
    },
  ];
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  //
  const formElements = [
    {
      key: "search_value",
      label: "Physician Name",
      placeHolder: "Enter Physician Name",
      type: "text",
      mdSize: "5",
    },
    {
      key: "state",
      label: "State",
      placeHolder: "Select",
      type: "select",
      options: [
        "AK",
        "AL",
        "AR",
        "AS",
        "AZ",
        "CA",
        "CO",
        "CT",
        "DC",
        "DE",
        "FL",
        "GA",
        "GU",
        "HI",
        "IA",
        "ID",
        "IL",
        "IN",
        "KS",
        "KY",
        "LA",
        "MA",
        "MD",
        "ME",
        "MI",
        "MN",
        "MO",
        "MS",
        "MT",
        "NC",
        "ND",
        "NE",
        "NH",
        "NJ",
        "NM",
        "NV",
        "NY",
        "OH",
        "OK",
        "OR",
        "PA",
        "PR",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VA",
        "VI",
        "VT",
        "WA",
        "WI",
        "WV",
        "WY",
      ],
      mdSize: "2",
    },
  ];
  console.log("props-24", props);
  const { selectclient } = props;
  console.log("selectclient", selectclient);

  var classes = useStyles();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("lg");
  const [searchResult, setSearch] = React.useState("");
  const [searchResultState, setSearchState] = React.useState("");

  const [result, setResult] = useState([]);
  toast.configure();
  const hideHandler = () => {
    setSearch('');
    setResult([]);
    setSearchState("");
    console.log("hide handler called");
    props.onHide();
  };

  const createUpdateClient = (physician) => {
    // props = patient
    props.onHide(addressParsing(physician));
  };

  const addressParsing = (physician) => {
    var address = physician[3];
    var addressArr = address.split(", ");
    var stateZipStr = addressArr[addressArr.length - 1];
    var stateZipArr = stateZipStr.split(" ");
    return [
      physician[0],
      physician[1],
      addressArr[0],
      addressArr[1],
      stateZipArr[0],
      stateZipArr[1],
    ];
  };

  const searchClick = () => {
    if(searchResult || searchResultState){
      const url = `https://clinicaltables.nlm.nih.gov/api/npi_org/v3/search?terms=${searchResult} ${searchResultState}`;
      const encoded = encodeURI(url);
      axios.get(encoded).then((response) => {
        setResult(response.data[3]);
      });
    }else{
      toast.error("Please enter physician name or select any state");
    }
   
  };

  
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid container spacing={1} style={{ marginBottom: "4px" }}>
              {formElements.map((element, i) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={classes.textGroup}
                    >
                      <Typography className={classes.formHeading}>
                        {element.label}{" "}
                        {element.required === "true" ? (
                          <span className={classes.required}>*</span>
                        ) : null}
                      </Typography>

                      {element.type === "select" ? (
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            name={element.key}
                            className={classes.selectDropdown}
                            placeholder={element.placeHolder}
                            onChange={(e) => {
                              setSearchState(e.target.value);
                            }}
                          >
                            <MenuItem value="">Select</MenuItem>
                            {element.options.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : element.type === "text" ? (
                        <TextField
                          required
                          name={element.key}
                          placeholder={element.placeHolder}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          value={searchResult}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                          inputProps={{
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                          }}
                        />
                      ) : element.type === "date" ||
                        element.type === "datetime-local" ? (
                        <TextField
                          id={element.key}
                          name={element.key}
                          placeholder={element.placeHolder}
                          type={element.type}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            min: `${currentDateTime}`,
                            max: `${currentDate}`,
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      ) : element.type === "textarea" ? (
                        <TextField
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          placeholder="Type here..."
                          variant="outlined"
                          multiline
                          rows={3}
                          inputProps={{
                            style: {
                              fontSize: "12px",
                            },
                          }}
                        />
                      ) : element.type === "button" ? (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                          style={{
                            width: "100%",
                            fontSize: "10px",
                            marginTop: "26px",
                          }}
                          onClick={searchClick}
                        >
                          {element.btnName}
                        </Button>
                      ) : (
                        <Checkbox />
                      )}
                    </Grid>
                  </>
                );
              })}
              <Grid item xs={12} md={2} className={classes.textGroup}>
                <Typography className={classes.formHeading}>&nbsp;</Typography>
                <Button
                  variant="contained"
                  size="medium"
                  color="secondary"
                  style={{ marginTop: "6px" }}
                  onClick={searchClick}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
            <div style={{ marginTop: "10px", width: "inherit" }}>
              <>
                {result ? (
                  <TableDesign
                    {...{
                      title: "List of All Clients",
                      tableHeadings,
                    }}
                  >
                    {result && result.length ? (
                      result.map((row, i) => {
                        if (row)
                          return (
                            <TableRow
                              onClick={() => createUpdateClient(row)}
                              key={i}
                              className={classes.tableBodyCell}
                            >
                              <TableCell
                                align="left"
                                className={classes.tableBodyCell}
                                style={{ cursor: "pointer" }}
                              >
                                {row[0]}
                              </TableCell>
                              <TableCell
                                align="left"
                                className={classes.tableBodyCell}
                                style={{ cursor: "pointer" }}
                              >
                                {row[1]}
                              </TableCell>

                              <TableCell
                                align="left"
                                style={{ cursor: "pointer" }}
                                className={classes.tableBodyCell}
                              >
                                {row[2]}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ cursor: "pointer" }}
                                className={classes.tableBodyCell}
                              >
                                {row[3]}
                              </TableCell>
                            </TableRow>
                          );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan="8">No Result Found</TableCell>
                      </TableRow>
                    )}
                  </TableDesign>
                ) : (
                  ""
                )}
              </>
            </div>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
