import React, { useState, useEffect } from "react";

// Model
import {
  Button,
  Typography,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Popup from "../common/modal/Popup";
import moment from "moment";
import alertIcon from "./alertIcon.svg";
// styles
import useStyles from "./styles";
import { toast } from "react-toastify";
import { postSpectacleLense } from "../../services/ServiceLayer";
import { currentUser, loadState } from "../../hooks/helper";

export default function DeleteSpectaclePopup({
  onHide,
  deleteModalShow,
  setDeleteModalShow,
  selectclient,
}) {
  console.log("selectclient", selectclient);
  console.log("deleteModalShow", deleteModalShow);
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const [actionType, setActionType] = useState("DELSPC");
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [isActionCalled, setIsActionCalled] = useState(false);
  toast.configure();
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setDeleteModalShow(false);
    setOpen(false);
  };
  const [companyDetail, setCompanyDetail] = useState({
    spec_id: "0",
    location_id: userData?.location_id,
    speclens_style: "",
    material_id: "",
    material_name: "",
    spec_lens_name: "",
    material_brand: "",
    product_name: "",
    style_name: "",
    vsp_code: "",
    vsp_code_description: "",
    is_od: "",
    is_os: "",
    is_ou: "",
    upc: "",
    unit_id: "",
    spec_sku: "",
    spec_color: "",
    is_returnable: "",
    structure_id: "",
    structure_type: "",
    spiff: "",
    notes: "",
    qty_purchased: "",
    qty_sold: "",
    qty_inventory: "",
    retail_price: "",
    item_cost: "",
    is_commission_prod: "",
    commission_amount: "0",
    manufacturer_id: "",
    spec_tax1: "",
    spec_tax2: "",
    is_active: "",
    updated_by: currentUser(),
    updated_dt: "",
    access_location: "access_location",
    opt_type: actionType,
  });
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setCompanyDetail({
        spec_id: "0",
        location_id: userData?.location_id,
        speclens_style: "",
        material_id: "",
        material_name: "",
        spec_lens_name: "",
        material_brand: "",
        product_name: "",
        style_name: "",
        vsp_code: "",
        vsp_code_description: "",
        is_od: "",
        is_os: "",
        is_ou: "",
        upc: "",
        unit_id: "",
        spec_sku: "",
        spec_color: "",
        is_returnable: "",
        structure_id: "",
        structure_type: "",
        spiff: "",
        notes: "",
        qty_purchased: "",
        qty_sold: "",
        qty_inventory: "",
        retail_price: "",
        item_cost: "",
        is_commission_prod: "",
        commission_amount: "0",
        manufacturer_id: "",
        spec_tax1: "",
        spec_tax2: "",
        is_active: "",
        updated_by: currentUser(),
        updated_dt: "",
        access_location: "access_location",
        opt_type: actionType,
      });
    } else {
      setCompanyDetail({
        spec_id: selectclient?.spec_id,
        location_id: userData?.location_id,
        speclens_style: selectclient?.speclens_style,
        material_id: selectclient?.material_id,
        material_name: selectclient?.material_name,
        spec_lens_name: selectclient?.spec_lens_name,
        material_brand: selectclient?.material_brand,
        product_name: selectclient?.product_name,
        style_name: selectclient?.style_name,
        vsp_code: selectclient?.vsp_code,
        vsp_code_description: selectclient?.vsp_code_description,
        is_od: selectclient?.is_od,
        is_os: selectclient?.is_os,
        is_ou: selectclient?.is_ou,
        upc: selectclient?.upc,
        unit_id: selectclient?.unit_id,
        spec_sku: selectclient?.spec_color,
        spec_color: selectclient,
        is_returnable: selectclient?.is_returnable,
        structure_id: selectclient?.structure_id,
        structure_type: selectclient?.structure_type,
        spiff: selectclient?.spiff,
        notes: selectclient?.notes,
        qty_purchased: selectclient?.qty_purchased,
        qty_sold: selectclient?.qty_sold,
        qty_inventory: selectclient?.qty_inventory,
        retail_price: selectclient?.retail_price,
        item_cost: selectclient?.item_cost,
        is_commission_prod: selectclient?.is_commission_prod,
        commission_amount: "0",
        manufacturer_id: selectclient?.manufacturer_id,
        spec_tax1: selectclient?.spec_tax1,
        spec_tax2: selectclient?.spec_tax2,
        is_active: selectclient?.is_active,
        updated_by: currentUser(),
        updated_dt: "",
        access_location: "",
        opt_type: selectclient?.is_active === "Y" ? "DELSPC" : "REASPC",
       
      });
    }
  }, [selectclient]);

  const deleteFormData = () => {
    setIsActionCalled(true);
    setCompanyDetail({
      ...companyDetail,
      opt_type: selectclient?.is_active === "Y" ? "DELSPC" : "REASPC",
    });
  };

  useEffect(() => {
    console.log("isActionCalled", isActionCalled);

    if (isActionCalled) {
      postSpectacleLense(companyDetail).then((response) => {
        console.log("actionType", actionType);

        console.log(response);
        if (response.status.status) {
          toast.success("Spectacle deleted successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });

          onHide();
          setDeleteModalShow(false);
          setOpen(false);
          setIsActionCalled(false);
        } else {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActionCalled(false);
        }
      });
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={deleteFormData}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <img src={alertIcon} />
            <Typography className={classes.formActiveHeading}>
              Are you sure! You want to {selectclient?.is_active === "Y" ? <>Delete </> : <>Re-active </>}{" "} this Spectacle Lens?
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
