import React, { useEffect, useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import moment from "moment";
// components
// styles
import useStyles from "../styles";
import InitialContact from "./InitialContact";
import AdditionalDetails from "./AdditionalDetails";
import FinalContact from "./FinalContact";
import TrialContact from "./TrialContact";
import ContactHistory from "./ContactHistory";
import { currentUser, loadState } from "../../../hooks/helper";
import Fax from "../../../assets/fax.png";
import * as Icons from "@material-ui/icons";
import {
  getManufacturerDetail,
  getPatientContactFR,
  getPatientContactPR,
  getPatientContactTR,
  getSupplierDetail,
  get_invt_contactlense_dtl,
} from "../../../services/ServiceLayer";
import { toast } from "react-toastify";

export default function MainContactTab({
  patientId,
  presentingRxBtnUnable,
  setPresentingRxBtnUnable,
  additionContactBtnUnable,
  setAdditionalContactBtnUnable,
}) {
  const [isUpdated, setIsUpdated] = useState(false);
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();
  const [prxDetails, setPrxDetails] = useState({
    pat_extfcnt_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    cnt_final: "",
    od_bc: "",
    od_dia: "",
    od_sph: "",
    od_cyl: "",
    od_axis: "",
    od_add: "",
    od_dva: "",
    od_nva: "",
    od_tint: "",
    od_clr: "",
    od_qty: "",
    od_material: "",
    od_blend: "",
    od_manufacturer: "",
    od_prodname: "",
    os_bc: "",
    os_dia: "",
    os_sph: "",
    os_cyl: "",
    os_axis: "",
    os_add: "",
    os_dva: "",
    os_nva: "",
    os_tint: "",
    os_clr: "",
    os_qty: "",
    os_material: "",
    os_blend: "",
    os_manufacturer: "",
    os_prodname: "",
    ou_dva: "",
    ou_nva: "",
    ou_rxdate: currentDate,
    updated_by: currentUser(),
    access_location: "",
    opt_type: "ADDCNT",
  });
  const [contactFinalDetails, setContactFinalDetails] = useState({
    pat_extfcnt_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    cnt_final: "",
    od_bc: "",
    od_dia: "",
    od_sph: "",
    od_cyl: "",
    od_axis: "",
    od_add: "",
    od_dva: "",
    od_nva: "",
    od_tint: "",
    od_clr: "",
    od_qty: "",
    od_material: "",
    od_blend: "",
    od_manufacturer: "",
    od_prodname: "",
    os_bc: "",
    os_dia: "",
    os_sph: "",
    os_cyl: "",
    os_axis: "",
    os_add: "",
    os_dva: "",
    os_nva: "",
    os_tint: "",
    os_clr: "",
    os_qty: "",
    os_material: "",
    os_blend: "",
    os_manufacturer: "",
    os_prodname: "",
    ou_dva: "",
    ou_nva: "",
    ou_rxdate: currentDate,
    updated_by: currentUser(),
    access_location: "",
    opt_type: "ADDCNT",
  });
  const [contactTrialDetails, setContactTrialDetails] = useState({
    pat_exttcnt_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    cnt_trial: "",
    od_bc: "",
    od_dia: "",
    od_sph: "",
    od_cyl: "",
    od_axis: "",
    od_add: "",
    od_dva: "",
    od_nva: "",
    od_tint: "",
    od_clr: "",
    od_qty: "",
    od_material: "",
    od_blend: "",
    od_manufacturer: "",
    od_prodname: "",
    os_bc: "",
    os_dia: "",
    os_sph: "",
    os_cyl: "",
    os_axis: "",
    os_add: "",
    os_dva: "",
    os_nva: "",
    os_tint: "",
    os_clr: "",
    os_qty: "",
    os_material: "",
    os_blend: "",
    os_manufacturer: "",
    os_prodname: "",
    ou_dva: "",
    ou_nva: "",
    ou_rxdate: currentDate,
    updated_by: currentUser(),
    access_location: "",
    opt_type: "ADDCNT",
  });
  const [prxListData, setprxListData] = useState([]);
  const [frxListData, setFrxListData] = useState([]);
  const [trxListData, setTrxListData] = useState([]);
  const [dataUpdated, setDataUpdated] = useState(false);
  useEffect(() => {
    getPatientContactPR(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELCNT`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setprxListData(response?.data);
        setDataUpdated(false);
      } else {
        // setIsLoader(false);
      }
    });

    getPatientContactFR(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELCNT`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setFrxListData(response?.data);
        setDataUpdated(false);
      } else {
        // setIsLoader(false);
      }
    });

    getPatientContactTR(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELCNT`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setTrxListData(response?.data);
        setDataUpdated(false);
      } else {
        // setIsLoader(false);
      }
    });
    setDataUpdated(false);
  }, [dataUpdated]);

  useEffect(() => {
    getPatientContactPR(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELCNT`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setprxListData(response?.data);
        // setDataUpdated(false);
      } else {
        // setIsLoader(false);
      }
    });

    getPatientContactFR(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELCNT`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setFrxListData(response?.data);
        // setDataUpdated(false);
      } else {
        // setIsLoader(false);
      }
    });

    getPatientContactTR(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELCNT`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setTrxListData(response?.data);
        // setDataUpdated(false);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);
  const [resetValue, setResetValue] = useState(false);
  useEffect(() => {
    if (prxListData.length > 0) {
      setPrxDetails({
        pat_extfcnt_id: prxListData[0]?.pat_extfcnt_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        cnt_final: prxListData[0]?.cnt_final,
        od_bc: prxListData[0]?.od_bc,
        od_dia: prxListData[0]?.od_dia,
        od_sph: prxListData[0]?.od_sph,
        od_cyl: prxListData[0]?.od_cyl,
        od_axis: prxListData[0]?.od_axis,
        od_add: prxListData[0]?.od_add,
        od_dva: prxListData[0]?.od_dva,
        od_nva: prxListData[0]?.od_nva,
        od_tint: prxListData[0]?.od_tint,
        od_clr: prxListData[0]?.od_clr,
        od_qty: prxListData[0]?.od_qty,
        od_material: prxListData[0]?.od_material,
        od_blend: prxListData[0]?.od_blend,
        od_manufacturer: prxListData[0]?.od_manufacturer,
        od_prodname: prxListData[0]?.od_prodname,
        os_bc: prxListData[0]?.os_bc,
        os_dia: prxListData[0]?.os_dia,
        os_sph: prxListData[0]?.os_sph,
        os_cyl: prxListData[0]?.os_cyl,
        os_axis: prxListData[0]?.os_axis,
        os_add: prxListData[0]?.os_add,
        os_dva: prxListData[0]?.os_dva,
        os_nva: prxListData[0]?.os_nva,
        os_tint: prxListData[0]?.os_tint,
        os_clr: prxListData[0]?.os_clr,
        os_qty: prxListData[0]?.os_qty,
        os_material: prxListData[0]?.os_material,
        os_blend: prxListData[0]?.os_blend,
        os_manufacturer: prxListData[0]?.os_manufacturer,
        os_prodname: prxListData[0]?.os_prodname,
        ou_dva: prxListData[0]?.ou_dva,
        ou_nva: prxListData[0]?.ou_nva,
        ou_rxdate: prxListData[0]?.ou_rxdate,
        updated_by: currentUser(),
        access_location: "",
        opt_type: "UPDCNT",
      });
      setResetValue(false);
    }
  }, [prxListData, resetValue]);
  const [contactLensDetails, setContactLensDetails] = useState([]);
  const [manufacturerData, setManufacturerData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  useEffect(() => {
    get_invt_contactlense_dtl(
      `location_id=${userData?.location_id}&contactlense_id=${
        prxDetails?.od_prodname ? prxDetails?.od_prodname : "0"
      }&manufacturer_id=${
        prxDetails?.od_manufacturer ? prxDetails?.od_manufacturer : "0"
      }&user_role=${userRole}&opt_type=SELCNL`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setContactLensDetails(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
    // manufacturer
    getManufacturerDetail(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELMAN&manufacturer=0`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setManufacturerData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
    // product name
    getSupplierDetail(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSUP&supplier_id=0`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setSupplierData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
  }, []);
  return (
    <>
      <Grid container spacing={2} className={classes.formBox}>
        <Grid
          item
          xs={12}
          md={12}
          className={classes.textGroup}
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Button
            className={classes.btnlRightDark}
            variant="contained"
            style={{ fontSize: "10px", height: "36px" }}
            size="small"
            color="secondary"
          >
            Copy To Forward
          </Button>
        </Grid>
      </Grid>
      <Accordion defaultExpanded>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Presenting Contact Rx</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <InitialContact
            presentingRxBtnUnable={presentingRxBtnUnable}
            setPresentingRxBtnUnable={setPresentingRxBtnUnable}
            prxDetails={prxDetails}
            setPrxDetails={setPrxDetails}
            setResetValue={setResetValue}
            prxListData={prxListData}
            patientId={patientId}
            supplierData={supplierData}
            manufacturerData={manufacturerData}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Trial Contact Rx</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TrialContact
            setDataUpdated={setDataUpdated}
            trxListData={trxListData}
            contactTrialDetails={contactTrialDetails}
            setContactTrialDetails={setContactTrialDetails}
            supplierData={supplierData}
            manufacturerData={manufacturerData}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            Final Contact Rx
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Icons.Print style={{ marginRight: "4px" }} />
              <Icons.GetApp style={{ marginRight: "4px" }} />
              <Icons.Mail style={{ marginRight: "4px" }} />
              <img
                src={Fax}
                alt="fax"
                style={{ height: "24px", marginRight: "4px" }}
              />
              <Icons.Description />
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FinalContact
            frxListData={frxListData}
            setContactFinalDetails={setContactFinalDetails}
            contactFinalDetails={contactFinalDetails}
            supplierData={supplierData}
            manufacturerData={manufacturerData}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Contact History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ContactHistory />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Additional Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AdditionalDetails
            patientId={patientId}
            additionContactBtnUnable={additionContactBtnUnable}
            setAdditionalContactBtnUnable={setAdditionalContactBtnUnable}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
