import React, {  useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";

import useStyles from "../styles";
import { formAdditionalElements } from "./Contact_Constant";
import useListData from "../../common/allMetaData/useListData";
import { toast } from "react-toastify";
import { getPatientContactAD, postPatientContactAD } from "../../../services/ServiceLayer";
import { currentUser, loadState } from "../../../hooks/helper";
import moment from "moment";

export default function AdditionalDetails({patientId,setAdditionalContactBtnUnable}) {
  var classes = useStyles();
  const listData = useListData();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const { examContactAdditionalCare,examContactAddSchedule } = listData;
  const [additionalContact, setAdditionalContact] = useState({
    pat_extacnt_id :0,
      patient_id :patientId,
      location_id :userData?.location_id,
      prescription_date: '',
      expiry_date :'',
      replacement_sch: '',
      care: '',
      internal_notes:'',
      rx_notes :'',
      updated_by :currentUser(),
      access_location :'Y',
      opt_type :'ADDCNT'
  });
  const [showDiscard, setShowDiscard] = useState(false);
  
  const [isActionCalled, setIsActionCalled] = useState(false);
  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    // if (!isError() && error !== null && isActionCalled) {
    if (isActionCalled) {
      postPatientContactAD(additionalContact).then((response) => {
        console.log(response);
        if (response?.status?.status) {
          toast.success("Additional details added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setAdditionalContactBtnUnable(false);
          // props.onHide();
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    // } else {
    //   setIsActionCalled(false);
    // }

    setIsActionCalled(false);
  }, [isActionCalled]);
  const [discardUpdate, setDiscardUpdate] = useState(false);
  const [additionlData, setAdditionalData] = useState([]);
  useEffect(() => {
    getPatientContactAD(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELCNT`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setAdditionalData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);

  useEffect(() => {
    if (additionlData && additionlData.length > 0) {
      setAdditionalContact({
        pat_extacnt_id :additionlData[0]?.pat_extacnt_id,
        patient_id :patientId,
        location_id :userData?.location_id,
        prescription_date: moment(additionlData[0]?.prescription_date).utc().format('YYYY-MM-DD'),
        expiry_date :moment(additionlData[0]?.expiry_date).utc().format('YYYY-MM-DD'),
        replacement_sch: additionlData[0]?.replacement_sch,
        care: additionlData[0]?.care,
        internal_notes:additionlData[0]?.internal_notes,
        rx_notes :additionlData[0]?.rx_notes,
        updated_by :currentUser(),
        access_location :'Y',
        opt_type :'UPDCNT'
       
      });
      setDiscardUpdate(false);
    }
  }, [additionlData,discardUpdate]);
  const clearForm=()=>{
    setAdditionalContact({
      pat_extacnt_id :0,
      patient_id :patientId,
      location_id :userData?.location_id,
      prescription_date: '',
      expiry_date :'',
      replacement_sch: '',
      care: '',
      internal_notes:'',
      rx_notes :'',
      updated_by :currentUser(),
      access_location :'Y',
      opt_type :'ADDCNT'
    });
  }
  const discardHandler = () => {
    if(additionlData && additionlData.length>0){
      setAdditionalContactBtnUnable(false);
      setDiscardUpdate(true);
    }else{
      clearForm();
      setAdditionalContactBtnUnable(false);
    }
  };
  return (
    <>
      <Grid container spacing={2} className={classes.formBox}>
        {formAdditionalElements.map((element, i) => {
          return (
            <Grid
              item
              xs={12}
              md={3}
              className={`${classes.textGroup} ${
                element.title ? classes.highlightedItem : ""
              }`}
              // style={{ border: "1px solid #DED9D9" }}
            >
              {element.label ||
                (element.copyBtn && (
                  <Typography
                    className={classes.formHeading}
                    style={{
                      fontWeight: "500",
                      color: "#4E97FD",
                      position: "relative",
                      marginTop: "0px",
                    }}
                  >
                    {element.label}&nbsp;
                    {element.required === "true" ? (
                      <span className={classes.required}>*</span>
                    ) : null}
                    {element.copyBtn === "true" && (
                      <Button
                        style={{
                          float: "right",
                          fontSize: "10px",
                          // position: "absolute",
                          // top: "20px",
                        }}
                        classes={{ root: classes.button }}
                        variant="contained"
                        color="secondary"
                        // onClick={duplicateValue}
                      >
                        {element.copyLabel}
                      </Button>
                    )}
                  </Typography>
                ))}
              {element.type ? (
                <>
                  {element.type === "select" &&
                  element.key !== "procedure_code" ? (
                    <FormControl
                      fullWidth
                      className={classes.dropdownFormControl}
                    >
                      <Select
                        name={element.key}
                        className={classes.selectDropdown}
                        placeholder={element.placeHolder}
                        value={
                          additionalContact && additionalContact[element.key]
                            ? additionalContact &&
                              additionalContact[element.key]
                            : ""
                        }
                        onChange={(e) => {
                          console.log("selected");
                          setAdditionalContact({
                            ...additionalContact,
                            [element.key]: e.target.value,
                          });
                          setAdditionalContactBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      >
                        <MenuItem disabled value="">
                          Select
                        </MenuItem>
                        {element.key==='replacement_sch' && examContactAddSchedule 
                                ?examContactAddSchedule.map((item)=>(
                                  <MenuItem
                                          key={item.attribute_id}
                                          value={item.attribute_id}
                                        >
                                          {item.attribute_val}
                                        </MenuItem>
                                )):element.key==='care' && examContactAdditionalCare 
                                ?examContactAdditionalCare.map((item)=>(
                                  <MenuItem
                                          key={item.attribute_id}
                                          value={item.attribute_id}
                                        >
                                          {item.attribute_val}
                                        </MenuItem>
                                )):element.options.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : element.type === "text" ? (
                    <div style={{ display: "flex" }}>
                      <TextField
                        required
                        name={element.key}
                        placeholder={element.placeHolder}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                          maxLength: element.maxLength,
                          onKeyPress: element.onKeyPress,
                        }}
                        value={
                          additionalContact && additionalContact[element.key]
                            ? additionalContact &&
                              additionalContact[element.key]
                            : ""
                        }
                        onChange={(e) => {
                          console.log("selected");
                          setAdditionalContact({
                            ...additionalContact,
                            [element.key]: e.target.value,
                          });
                          setAdditionalContactBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                      {element.key === "sku" && (
                        <Button
                          // onClick={selectFrameBySku}
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                        >
                          Slect
                        </Button>
                      )}
                    </div>
                  ) : element.type === "commission_amount" ? (
                    <TextField
                      type="text"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      size="small"
                      inputProps={{
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                        maxLength: element.maxLength,
                        onKeyPress: element.onKeyPress,
                      }}
                    />
                  ) : element.type === "date" ||
                    element.type === "datetime-local" ? (
                    <TextField
                      id={element.key}
                      name={element.key}
                      placeholder={element.placeHolder}
                      type={element.type}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        additionalContact && additionalContact[element.key]
                          ? additionalContact &&
                            additionalContact[element.key]
                          : ""
                      }
                      onChange={(e) => {
                        console.log("selected");
                        setAdditionalContact({
                          ...additionalContact,
                          [element.key]: e.target.value,
                        });
                        setAdditionalContactBtnUnable(true);
                        setShowDiscard(true);
                      }}
                    />
                  ) : element.type === "radio" ? (
                    <RadioGroup row defaultValue="">
                      {element.radioValue.map((item) => (
                        <FormControlLabel
                          size="small"
                          style={{ padding: "0px 6px" }}
                          value={item}
                          control={<Radio />}
                          label={item}
                          className={classes.smallRadioButton}
                        />
                      ))}
                    </RadioGroup>
                  ) : element.type === "checkbox" ? (
                    <>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value={element.value}
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  const selectedValue = e.target.checked
                                    ? "Y"
                                    : "N";
                                  setAdditionalContact({
                                    ...additionalContact,
                                    [element.key]: selectedValue,
                                  });
                                  setAdditionalContactBtnUnable(true);
                                  setShowDiscard(true);
                                }}
                              />
                            }
                            label={element.lbltitle}
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </FormControl>
                    </>
                  ) : element.type === "button" &&
                    element?.btnlbl === "Lookup" ? (
                    <>
                      <Button
                        classes={{ root: classes.button }}
                        variant="contained"
                        color="secondary"
                        // onClick={selectPrevFrame}
                      >
                        {element.btnlbl}
                      </Button>
                    </>
                  ) : element.type === "button" &&
                    element?.btnlbl === "Update" ? (
                    <>
                      {/* {selectedRow && (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                        //   onClick={updateSelectFrame}
                        >
                          {element.btnlbl}
                        </Button>
                      )} */}
                    </>
                  ) : (
                    <TextField
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      placeholder="Type here..."
                      variant="outlined"
                      multiline
                      rows={3}
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      value={
                        additionalContact && additionalContact[element.key]
                          ? additionalContact &&
                            additionalContact[element.key]
                          : ""
                      }
                      onChange={(e) => {
                        console.log("selected");
                        setAdditionalContact({
                          ...additionalContact,
                          [element.key]: e.target.value,
                        });
                        setAdditionalContactBtnUnable(true);
                        setShowDiscard(true);
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <Typography
                    className={classes.formHeading}
                    style={{
                      fontWeight: "600",

                      color: "#4E97FD",
                    }}
                  >
                    {element.title}
                  </Typography>
                </>
              )}
            </Grid>
          );
        })}
        <Grid
          className={classes.btnGroup}
          item
          xs={12}
          style={{ display: "flex", justifyContent: "end" }}
        >
          {showDiscard && (
            <Button
              className={classes.btnleftOutline}
              variant="outlined"
              style={{ marginRight: "10px", fontSize: "12px" }}
              size="medium"
              onClick={discardHandler}
            >
              Discard
            </Button>
          )}
          <Button
            style={{ display: "flex", float: "right" }}
            onClick={handleSubmit}
            className={classes.btnlRightDark}
            variant="contained"
            size="medium"
            color="secondary"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
