import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";

import moment from "moment";
import { formGaurantorElements } from "./Patient_Constant";

import useStyles from "./styles";
import { Box } from "@mui/material";
export default function Gaurantor({
  patientOtherData,
  setPatientOtherData,
  gaurantorValues,
  relTypeValues,
  genderValues,
}) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.formBox}>
      {formGaurantorElements.map((element, i) => {
        return (
          <>
            {patientOtherData ? (
              <Grid
                item
                xs={12}
                md={2}
                lg={2}
                sm={12}
                className={classes.textGroup}
              >
                <Typography className={classes.formHeading}>
                  {element.label}{" "}
                  {element.required === "true" ? (
                    <span className={classes.required}>*</span>
                  ) : null}
                </Typography>

                {element.type === "select" ? (
                  <FormControl
                    fullWidth
                    // sx={{
                    //   '&.MuiFormControl-fullWidth': {
                    //     width: 'auto',
                    //   }
                    // }}
                    className={classes.dropdownFormControl}
                  >
                    <Select
                      name={element.key}
                      className={classes.selectDropdown}
                      style={{ fontSize: "12px" }}
                      placeholder={element.placeHolder}
                      value={
                        patientOtherData &&
                          patientOtherData[element.key] === null
                          ? ""
                          : patientOtherData && patientOtherData[element.key]
                      }
                      disabled
                      onChange={async (e) => {
                        setPatientOtherData({
                          ...patientOtherData,
                          [element.key]: e.target.value,
                        });
                      }}
                    >
                      <MenuItem value="">Select</MenuItem>
                      {element.key == "guarantor_relationship" && relTypeValues
                        ? relTypeValues.map((item) => (
                          <MenuItem
                            key={item.attribute_id}
                            value={item.attribute_id}
                          >
                            {item.attribute_val}
                          </MenuItem>
                        ))
                        : element.key == "guarantor_gender" && genderValues
                          ? genderValues.map((item) => (
                            <MenuItem
                              key={item.attribute_id}
                              value={item.attribute_id}
                            >
                              {item.attribute_val}
                            </MenuItem>
                          ))
                          : element.key == "guarantor_type" && gaurantorValues
                            ? gaurantorValues.map((item) => (
                              <MenuItem
                                key={item.attribute_id}
                                value={item.attribute_id}
                              >
                                {item.attribute_val}
                              </MenuItem>
                            ))
                            : element.key == "guarantor_state" && element.options
                              ? element.options.map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                              ))
                              : null}
                    </Select>
                  </FormControl>
                ) : element.type === "textarea" ? (
                  <TextField
                    disabled
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    placeholder="Type here..."
                    variant="outlined"
                    multiline
                    rows={3}
                    inputProps={{
                      style: {
                        fontSize: "12px",
                      },
                      maxLength: `${element.maxLength}`,
                    }}
                    value={
                      patientOtherData && patientOtherData[element.key] === null
                        ? ""
                        : patientOtherData && patientOtherData[element.key]
                    }
                    onChange={async (e) => {
                      setPatientOtherData({
                        ...patientOtherData,
                        [element.key]: e.target.value,
                      });
                    }}
                  />
                ) : element.type === "date" ||
                  element.type === "datetime-local" ? (
                  <TextField
                    disabled
                    id={element.key}
                    name={element.key}
                    placeholder={element.placeHolder}
                    type={element.type}
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      min: `${currentDateTime}`,
                      max: `${currentDate}`,
                      style: {
                        height: "12px",
                        fontSize: "12px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      patientOtherData && patientOtherData[element.key] === null
                        ? ""
                        : patientOtherData && patientOtherData[element.key]
                    }
                    onChange={async (e) => {
                      setPatientOtherData({
                        ...patientOtherData,
                        [element.key]: e.target.value,
                      });
                    }}
                  />
                ) : (
                  <TextField
                    disabled
                    required
                    name={element.key}
                    placeholder={element.placeHolder}
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    type={element.type}
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "12px",
                        fontSize: "12px",
                      },
                      maxLength: `${element.maxLength}`,
                    }}
                    value={
                      patientOtherData && patientOtherData[element.key] === null
                        ? ""
                        : patientOtherData && patientOtherData[element.key]
                    }
                    onChange={async (e) => {
                      setPatientOtherData({
                        ...patientOtherData,
                        [element.key]: e.target.value,
                      });
                    }}
                  />
                )}
              </Grid>
            ) : null}
          </>
        );
      })}
    </Grid>
  );
}
