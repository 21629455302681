import {
  handleKeyPress,
  handleKeyPressPaste,
  handleAlphNumKeyPress,
  handleAlphNumKeyPressPaste,
  handleAlphNumberKeyPress,
  handleAlphNumberKeyPressPaste,
  handleAddressKeyPress,
  handleAddressKeyPressPaste,
  validateLargeAmount,
  handleAlphNumSpaceKeyPress,
} from "../../../hooks/helper";
export const formProblemElements = [
  {
    key: "chart_type",
    label: "Eye Chart Type",
    placeHolder: "Select",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "correction_type",
    label: "Correction Type",
    placeHolder: "Select",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "dominant_eye",
    label: "Dominant Eye",
    placeHolder: "Select",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "visacu_note",
    label: "Notes",
    placeHolder: "Select",
    type: "select",
    options: [],
    mdSize: "3",
  },
];

export const specticalFormElements = [
  {
    title: "Unaided Acuities",
    mdSize: "12",
  },
  {
    title: "OD",
    mdSize: "1",
    // copyBtn: "true",
  },

  {
    key: "unau_acu_disvis_od",
    label: "Distance Vision",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "unau_acu_phval_od",
    label: "PH Value",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "unau_acu_nrvis_od",
    label: "Near Vision",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "unau_acu_otvis_od",
    label: "Other",
    placeHolder: "Enter other",
    type: "text",
    mdSize: "5",
    onKeyPress: handleAddressKeyPress,
    maxLength:50
  },

  {
    title: "OS",
    mdSize: "1",
    // copyBtn: "true",
  },

  {
    key: "unau_acu_disvis_os",
    label: "",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "unau_acu_phval_os",
    label: "",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "unau_acu_nrvis_os",
    label: "",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "unau_acu_otvis_os",
    label: "",
    placeHolder: "Enter Other",
    type: "text",
    maxLength:50,
    mdSize: "5",
    onKeyPress: handleAddressKeyPress,
  },
  {
    title: "OU",
    mdSize: "1",
    // copyBtn: "true",
  },

  {
    key: "unau_acu_disvis_ou",
    label: "",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "unau_acu_phval_ou",
    label: "",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "unau_acu_nrvis_ou",
    label: "",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "unau_acu_otvis_ou",
    label: "",
    placeHolder: "Enter Other",
    type: "text",
    maxLength:50,
    mdSize: "5",
    onKeyPress: handleAddressKeyPress,
  },
  // aided activities
  {
    title: "Aided Acuities",
    mdSize: "12",
  },
  {
    title: "OD",
    mdSize: "1",
    // copyBtn: "true",
  },

  {
    key: "au_acu_disvis_od",
    label: "Distance Vision",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "au_acu_phval_od",
    label: "PH Value",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "au_acu_nrvis_od",
    label: "Near Vision",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "au_acu_otvis_od",
    label: "Other",
    placeHolder: "Enter other",
    type: "text",
    mdSize: "5",
    onKeyPress: handleAddressKeyPress,
    maxLength:50
  },

  {
    title: "OS",
    mdSize: "1",
    // copyBtn: "true",
  },

  {
    key: "au_acu_disvis_os",
    label: "",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "au_acu_phval_os",
    label: "",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "au_acu_nrvis_os",
    label: "",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "au_acu_otvis_os",
    label: "",
    placeHolder: "Enter Other",
    type: "text",
   
    mdSize: "5",
    onKeyPress: handleAddressKeyPress,
    maxLength:50
  },
  {
    title: "OU",
    mdSize: "1",
    // copyBtn: "true",
  },

  {
    key: "au_acu_disvis_ou",
    label: "",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "au_acu_phval_ou",
    label: "",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "au_acu_nrvis_ou",
    label: "",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "au_acu_otvis_ou",
    label: "",
    placeHolder: "Enter Other",
    type: "text",
    maxLength:50,
    mdSize: "5",
    onKeyPress: handleAddressKeyPress,
  },
  // aided activities
  {
    title: "Color Vision",
    mdSize: "12",
  },
  {
    key: "col_vis_ou",
    label: "Color Test",
    placeHolder: "Enter test",
    type: "text",
    maxLength:50,
    mdSize: "4",
    onKeyPress: handleAlphNumSpaceKeyPress,
  },
  {
    key: "col_vis_od",
    label: "OD",
    placeHolder: "Enter od",
    type: "text",
    maxLength:50,
    mdSize: "4",
    onKeyPress: handleAlphNumSpaceKeyPress,
  },
  {
    key: "col_vis_os",
    label: "OS",
    placeHolder: "Enter os",
    type: "text",
    maxLength:50,
    mdSize: "4",
    onKeyPress: handleAlphNumSpaceKeyPress,
  },
];
