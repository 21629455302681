import React, { useState, useRef, useEffect } from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";
import { toast } from "react-toastify";
import { postPatientGlyphs } from "../../../services/ServiceLayer";
import useStyles from "../styles";
import { Button, Grid, TextField } from "@material-ui/core";

const styles = {
  border: "0.0625rem solid #9c9c9c",
  borderRadius: "0.25rem",
  marginTop: "-500px",
  zIndex: 999999,
};

const CanvasImage = ({ image, width, height, patientId }) => {
  console.log("image", image);
  const canvas = useRef(null);
  const [strokeColor, setStrokeColor] = useState("red");
  const background = `no-repeat url(${image})`;
  const [imageName, setImageName] = useState("");
  const [imageData, setImage] = useState(null);
  const [uploadedImageName, setUploadedImageName] = useState(""); // Added state for uploaded image name

  const getFormData = (data) => {
    const formData = new FormData();
    formData.append("file", data);
    formData.append("pat_extgly_id", 0);
    formData.append("patient_id", patientId);
    formData.append("location_id",0);
    formData.append("img_notes", "shashank");
    formData.append("img_mode", "EXT");
    formData.append("access_location", "122");
    formData.append("opt_type", "ADDGLY");
    formData.append("updated_by", "");
    formData.append("file_name", uploadedImageName); // Append uploaded image name to the form data
    return formData;
  };

  const changeStrokeColor = (color) => {
    setStrokeColor(color || "red");
  };

  const handleUploadAndSave = async () => {
    try {
      const data = await canvas.current.exportImage("jpeg");
      setImage(data);

      const response = await fetch(data);
      const blob = await response.blob();
      const formData = getFormData(blob);

      const uploadResponse = await postPatientGlyphs(formData);

      if (uploadResponse?.data?.status?.status) {
        toast.success("Attachment uploaded successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(uploadResponse?.data?.status?.description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("Error uploading and saving image:", error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            Change color pen:
            {"   "}
            <button
              style={{
                backgroundColor: "red",
                width: "25px",
                height: "25px",
              }}
              onClick={() => {
                changeStrokeColor("red");
              }}
            ></button>
            {"   "}
            <button
              style={{
                backgroundColor: "blue",
                width: "25px",
                height: "25px",
              }}
              onClick={() => {
                changeStrokeColor("blue");
              }}
            ></button>
            {"   "}
            <button
              style={{
                backgroundColor: "yellow",
                width: "25px",
                height: "25px",
              }}
              onClick={() => {
                changeStrokeColor("yellow");
              }}
            ></button>
            {"   "}
            <button
              style={{
                backgroundColor: "green",
                width: "25px",
                height: "25px",
              }}
              onClick={() => {
                changeStrokeColor("green");
              }}
            ></button>
            {"   "}
          </div>
          <img src={image} style={{ width: width, height: height }} alt="img" />
          <ReactSketchCanvas
            ref={canvas}
            strokeColor={strokeColor}
            style={styles}
            width="900px"
            height="500px"
            fillStyle="none"
            fillStyleColor="none"
            strokeWidth={4}
            backgroundImage={background}
            exportWithBackgroundImage={background ? true : false}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            classes={{ root: classes.button }}
            style={{
              fontSize: "12px",
              textTransform: "capitalize",
              marginRight: "10px",
            }}
            variant="contained"
            color="secondary"
            onClick={() => {
              canvas.current.undo();
            }}
          >
            run back
          </Button>
          <Button
            classes={{ root: classes.button }}
            style={{
              fontSize: "12px",
              textTransform: "capitalize",
              marginRight: "10px",
            }}
            variant="contained"
            color="secondary"
            onClick={() => {
              canvas.current.redo();
            }}
          >
            before
          </Button>
          <Button
            classes={{ root: classes.button }}
            style={{
              fontSize: "12px",
              textTransform: "capitalize",
              marginRight: "10px",
            }}
            variant="contained"
            color="secondary"
            onClick={() => {
              canvas.current.clearCanvas();
            }}
          >
            Clear drawing
          </Button>
          <hr />
          <Button
            classes={{ root: classes.button }}
            style={{
              fontSize: "12px",
              textTransform: "capitalize",
              marginRight: "10px",
            }}
            variant="contained"
            color="secondary"
            onClick={handleUploadAndSave}
          >
            Upload and Save Image
          </Button>
        </Grid>
      </Grid>
      <br />
    </>
  );
};

export default CanvasImage;
