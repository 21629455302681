import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { Grid, Menu, MenuItem, TableCell, TableRow } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Lens, MoreVert as MoreIcon } from "@material-ui/icons";
import moment from "moment";
import { currentUser } from "../../hooks/helper";
import TableWidget from "../Widget/TableWidget";
// data
// import mock from "./mock";
// styles
import useStyles from "./styles";

import { toast } from "react-toastify";
import { postCheckStatus } from "../../services/ServiceLayer";
import TableDesign from "../common/table/TableDesign";
export default function CurrentAppointList({
  selectAppointmentStatus,
  patientData,
}) {
  console.log("selectAppointmentStatus", selectAppointmentStatus);
  const history = useHistory();
  const [selectRow, setSelectRow] = useState(null);
  toast.configure();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);

  // table Header
  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Patient Name",
    },

    {
      label: "Appointment Time",
      secondaryLabel: "(Appointment Reason)",
    },

    {
      label: "Provider",
    },
    {
      label: "Slot",
    },
    {
      label: "check In",
      secondaryLabel: "(check Out)",
    },

    {
      label: "Exam Room",
    },
    {
      label: "Appointment Status",
    },
    {
      label: "Action",
    },
  ];

  var classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCheckOut = (row) => {
    console.log("row", row);
    const data = {
      schedule_id: row?.schedule_id,
      check_time: row?.start_time,
      patient_id: row?.patient_id,
      updated_by: currentUser(),
      access_location: "",
      opt_type: "CHKOUT",
    };
    postCheckStatus(data).then((response) => {
      console.log("response", response);
      if (response.status.status) {
        toast.success("CheckOut successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error("Something went wrong.Please try after sometime", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleCheckIn = (row) => {
    console.log("row", row);
    const data = {
      schedule_id: row?.schedule_id,
      check_time: row?.start_time,
      patient_id: row?.patient_id,
      updated_by: currentUser(),
      access_location: "",
      opt_type: "CHKIN",
    };
    postCheckStatus(data).then((response) => {
      console.log("response", response);
      if (response.status.status) {
        toast.success("CheckIn successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error("Something went wrong.Please try after sometime", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} style={{minHeight:'100vh'}}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {patientData && patientData?.length ? (
                patientData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.first_name} {row.last_name}
                        </TableCell>

                        <TableCell
                          align="left"
                          onClick={() =>
                            history.push(`/app/patient-details/${row._id}`)
                          }
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {moment(row.start_time).format("LT")}
                          <br />
                          <span style={{ color: "#4E97FD" }}>
                            ({row.title})
                          </span>
                        </TableCell>

                        <TableCell className={classes.tableBodyCell}>
                          {row.doc_name}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {moment(row.end_time).diff(row.start_time, "minutes")}{" "}
                          minutes
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {moment(row.start_time).format("LT")}
                          <br />
                          <span style={{ color: "#4E97FD" }}>
                            ({moment(row.end_time).format("LT")})
                          </span>
                        </TableCell>

                        <TableCell className={classes.tableBodyCell}>
                          {row.room_number}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.appointment_status}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                          >
                            {row?.patient_checkin_status === "Scheduled" ? (
                              <MenuItem
                                className={classes.menuItem}
                                onClick={() => handleCheckOut(selectRow)}
                              >
                                <Lens
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                    marginRight: "4px",
                                  }}
                                />{" "}
                                Check Out
                              </MenuItem>
                            ) : (
                              <MenuItem
                                className={classes.menuItem}
                                onClick={() => handleCheckIn(selectRow)}
                              >
                                <Lens
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                    marginRight: "4px",
                                  }}
                                />{" "}
                                Check In
                              </MenuItem>
                            )}
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
