export const managePatientSchema = {
  first_name: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter first name",
    },
  },
  last_name: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter last name",
    },
  },
  contact_cell: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter mobile number",
      // number: "Please enter number only",
      minLength: {
        limit: 10,
        msg: "Number must be minimum 10 length",
      },
      maxLength: {
        limit: 10,
        msg: "Number must be maximum 10 length",
      },
    },
  },

  email: {
    type: "email",
    required: true,
    properties: {
      empty: "Please enter email",
      email: "Please enter a valid Email",
    },
  },

  name: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter plan name",
    },
  },

  s3Key: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select a file",
    },
  },
  file: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select a file",
    },
  },
};
