import React, { useState, useEffect } from "react";
import { Button, Grid, Menu, MenuItem } from "@material-ui/core";

import { dateFormatConversion, loadState } from "../../../hooks/helper";

import TableWidget from "../../Widget/TableWidget";

import TableDesign from "../../common/table/TableDesign";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
// styles
import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";

import useStyles from "../styles";
import ManageVitals from "./ManageVitals";
import { getVitalHistoryData } from "../../../services/ServiceLayer";
import DeleteVitalsPopup from "./DeleteVitalsPopup";

export default function Vitals(props) {
  const tableHeadings = [
    {
      label: "Weight (lbs)",
      align: "left",
    },
    {
      label: "Height (feet & inches)",
      align: "left",
    },
    {
      label: "BP (Systolic) mmHg",
      align: "left",
    },
    {
      label: "BP (Diastolic) mmHg",
      align: "left",
    },
    {
      label: "Pulse (Per Min)",
      align: "left",
    },
    {
      label: "Temperature (Unit)",
      align: "left",
    },
    {
      label: "Respiration (Per Min)",
      align: "left",
    },
    {
      label: "Oxygen Saturation (% age)",
      align: "left",
    },
    {
      label: "BMI (lb/in x 703)",
      align: "left",
    },
    {
      label: "BMI status",
      align: "left",
    },
    {
      label: "Date",
      align: "left",
    },
    {
      label: "Action",
      align: "",
    },
  ];
  const { patientId,enable } = props;
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  console.log("props", props);
  const [open, setOpen] = React.useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const [selectRow, setSelectRow] = useState(null);
  const [selectclient, setSelectclient] = useState(null);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [patientVitalData, setPatientVitalData] = useState([]);
  const [title, setTitle] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  var classes = useStyles();
  useEffect(() => {
    getVitalHistoryData(
      `location_id=${userData?.location_id}&patient_id=${patientId}&user_role=${userRole}&opt_type=SELVTL`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setPatientVitalData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);
  useEffect(() => {
    getVitalHistoryData(
      `location_id=${userData?.location_id}&patient_id=${patientId}&user_role=${userRole}&opt_type=SELVTL`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setPatientVitalData(response?.data);
        setDataUpdated(false);
      } else {
        // setIsLoader(false);
      }
      setDataUpdated(false);
    });
  }, [dataUpdated]);
  const hideHandle = () => {
    console.log("hidehandle called");
    setDeleteModalShow(false);
    setOpen(false);
    setSelectclient(null);
    setDataUpdated(true);
  };
  const addVitalsModel = () => {
    setOpen(true);
  };
  const handleEdit = (rec) => {
    setOpen(true);
    setAnchorEl(null);
    setSelectclient(rec);
    setTitle("Edit Problem");
  };
  const deleteEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);
    setSelectclient(rec);
    setDeleteModalShow(true);
    setTitle("Delete Problem");
  };
  return (
    <>
      <ManageVitals
        patientId={patientId}
        open={open}
        selectclient={selectclient}
        onHide={hideHandle}
        title={selectclient ? "Update Vitals " : "Add Vitals"}
      />
      <DeleteVitalsPopup
        onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        selectclient={selectclient}
        patientId={patientId}
        setDeleteModalShow={setDeleteModalShow}
      />
      <Grid container spacing={4} style={{ width: "100%" }}>
      {enable && <Grid>
          {" "}
          <div
            className={classes.pageTitleContainer}
            style={{ display: "flex", justifyContent: "end" }}
          >
            <Button
              style={{
                marginRight: "8px",
                fontSize: "10px",
                position: "absolute",
                borderRadius: "2px",
                right: "60px",
              }}
              classes={{ root: classes.button }}
              variant="contained"
              color="secondary"
              onClick={addVitalsModel}
            >
              Add Vitals
            </Button>
          </div>
        </Grid>}
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {patientVitalData && patientVitalData?.length ? (
                patientVitalData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        {/* <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell> */}

                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {row?.pat_weight_lbs}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row?.pat_height}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row?.pat_bp_systolic}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row?.pat_bp_diastolic}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row?.pat_pulse}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row?.pat_temp}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row?.pat_respiration}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row?.pat_oxyg_sat}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row?.pat_bmi}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row?.pat_bmi_status}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {dateFormatConversion(row?.created_dt)}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            id="basic-button"
                            aria-controls={openDown ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openDown ? "true" : undefined}
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem onClick={() => handleEdit(selectRow)}>
                              Edit
                            </MenuItem>

                            <MenuItem
                              onClick={() => deleteEventHandler(selectRow)}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
