import React, { useState, useEffect } from "react";
import { Button, Grid, Menu, MenuItem, Typography } from "@material-ui/core";

import { dateFormatConversion, loadState } from "../../../hooks/helper";
import AllergyChart from './AllergyChart'

import TableWidget from "../../Widget/TableWidget";

import TableDesign from "../../common/table/TableDesign";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
// styles
import useStyles from "../styles";

import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";
import { getAllergyHistoryData, getPatientAssessmentFollowup } from "../../../services/ServiceLayer";

import moment from "moment";


export default function Followup(props) {
  const tableHeadings = [
   
    {
      label: "Follow up for",
    },
    {
      label: "In Month",
    },
    {
      label: "On Date",
    },
    {
      label: "Reason",
    },
    {
      label: "Day Until",
    },
    {
      label: "Mode",
    },
    {
      label: "Status",
    },
    {
      label: "Status Date",
    },
  
  
  ];
  const { patientId, allergyOutcome, allergySeverity, allergyReaction } = props;
  console.log("props", props);
  const [open, setOpen] = React.useState(false);
  const [selectRow, setSelectRow] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const [selectclient, setselectclient] = useState(null);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [allergyData, setAllergyData] = useState([]);
  const [title, setTitle] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [assessmentFollowupList, setAssessmentFollowupList] = useState([]);

  useEffect(() => {
    getPatientAssessmentFollowup(
      `patient_id=${patientId}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELFLU`
    ).then((response) => {
      console.log(response);
      setAssessmentFollowupList(response.data);
      
    });
    
  }, []);
  

  return (
    <>
    {/* <div className={classes.pageTitleContainer} style={{marginBottom: "6px",marginTop: "10px", borderTop:'1px solid #6666' }}>
        <Typography className={classes.typo} style={{fontSize:'14px'}} weight="medium">
          Follow Up
        </Typography>
      </div> */}
      
      <Grid container spacing={4} style={{ width: "100%" }}>
        
        <Grid item xs={12} >
        <div style={{background:'#5DB3D9',padding:'10px',color:'#fff',textAlign:'center',borderRadius:'5px',marginBottom:'2px'}}>Follow Up</div>
          {/* <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {assessmentFollowupList && assessmentFollowupList?.length ? (
                assessmentFollowupList.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                       

                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                         {row?.followup_reason}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {moment(row?.followup_date).utc().format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.followup_notes}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.days_contact}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {moment(row?.created_dt).utc().format('DD-MM-YYYY')}
                        </TableCell>
                       
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget> */}

<AllergyChart />
        </Grid>
      </Grid>
    </>
  );
}
