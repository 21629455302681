import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";

import useStyles from "../styles";
import { formOtherRxElements } from "./Refractive_Constant";
import { toast } from "react-toastify";
import { postOtherRefractiveData } from "../../../services/ServiceLayer";
import { currentUser, loadState } from "../../../hooks/helper";

export default function OtherRx({
  patientId,
  otherRxData,
  setIsUpdated,
  setResetValue,
  rxOtherDetails,
  setRxOtherDetails,
  setOtherRxBtnUnable,
  otherRxBtnUnable,
}) {
  var classes = useStyles();
  const userData = loadState().user;
  const [isActionCalled, setIsActionCalled] = useState(false);
  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    if (isActionCalled) {
      postOtherRefractiveData(rxOtherDetails).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Refractive notes added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setOtherRxBtnUnable(false);
          setIsActionCalled(false);
          setIsUpdated(true);
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    setIsActionCalled(false);
  }, [isActionCalled]);
  const [showDiscard, setShowDiscard] = useState(false);
  const clearForm = () => {
    setRxOtherDetails({
      pat_rfrnr_id: 0,
      patient_id: patientId,
      location_id: userData?.location_id,
      office_notes: "",
      rx_notes: "",
      updated_by: currentUser(),
      access_location: "ggg",
      opt_type: "ADDRFR",
    });
  };
  const discardHandler = () => {
    if (otherRxData && otherRxData.length > 0) {
      setOtherRxBtnUnable(false);

      setResetValue(true);
    } else {
      clearForm();
      setOtherRxBtnUnable(false);
    }
  };
  return (
    <>
      <Grid container spacing={2} className={classes.formBox}>
        {formOtherRxElements.map((element, i) => {
          return (
            <Grid
              item
              xs={12}
              md={element.mdSize}
              className={`${classes.textGroup} ${
                element.title ? classes.highlightedItem : ""
              }`}
              // style={{ border: "1px solid #DED9D9" }}
            >
              {element.label && (
                <Typography
                  className={classes.formHeading}
                  style={{
                    fontWeight: "500",
                    color: "#4E97FD",
                    position: "relative",
                  }}
                >
                  {element.label}&nbsp;
                  {element.required === "true" ? (
                    <span className={classes.required}>*</span>
                  ) : null}
                  {element.copyBtn === "true" && (
                    <Button
                      style={{
                        float: "right",
                        position: "absolute",
                        top: "20px",
                      }}
                      classes={{ root: classes.button }}
                      variant="contained"
                      color="secondary"
                      // onClick={duplicateValue}
                    >
                      =
                    </Button>
                  )}
                </Typography>
              )}
              {element.type ? (
                <>
                  {element.type === "select" &&
                  element.key !== "procedure_code" ? (
                    <FormControl
                      fullWidth
                      className={classes.dropdownFormControl}
                    >
                      <Select
                        name={element.key}
                        className={classes.selectDropdown}
                        placeholder={element.placeHolder}
                        value={
                          rxOtherDetails && rxOtherDetails[element.key]
                            ? rxOtherDetails && rxOtherDetails[element.key]
                            : ""
                        }
                        onChange={(e) => {
                          console.log("selected");
                          setRxOtherDetails({
                            ...rxOtherDetails,
                            [element.key]: e.target.value,
                          });
                          setOtherRxBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      >
                        <MenuItem disabled value="">
                          Select
                        </MenuItem>
                        {element.options.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : element.type === "text" ? (
                    <div style={{ display: "flex" }}>
                      <TextField
                        required
                        name={element.key}
                        placeholder={element.placeHolder}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                          maxLength: element.maxLength,
                          onKeyPress: element.onKeyPress,
                        }}
                        value={
                          rxOtherDetails && rxOtherDetails[element.key]
                            ? rxOtherDetails && rxOtherDetails[element.key]
                            : ""
                        }
                        onChange={(e) => {
                          console.log("selected");
                          setRxOtherDetails({
                            ...rxOtherDetails,
                            [element.key]: e.target.value,
                          });
                          setOtherRxBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                      {element.key === "sku" && (
                        <Button
                          // onClick={selectFrameBySku}
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                        >
                          Slect
                        </Button>
                      )}
                    </div>
                  ) : element.type === "commission_amount" ? (
                    <TextField
                      type="text"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      size="small"
                      inputProps={{
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                        maxLength: element.maxLength,
                        onKeyPress: element.onKeyPress,
                      }}
                    />
                  ) : element.type === "date" ||
                    element.type === "datetime-local" ? (
                    <TextField
                      id={element.key}
                      name={element.key}
                      placeholder={element.placeHolder}
                      type={element.type}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        rxOtherDetails && rxOtherDetails[element.key]
                          ? rxOtherDetails && rxOtherDetails[element.key]
                          : ""
                      }
                      onChange={(e) => {
                        console.log("selected");
                        setRxOtherDetails({
                          ...rxOtherDetails,
                          [element.key]: e.target.value,
                        });
                        setOtherRxBtnUnable(true);
                        setShowDiscard(true);
                      }}
                    />
                  ) : element.type === "radio" ? (
                    <RadioGroup row defaultValue="">
                      {element.radioValue.map((item) => (
                        <FormControlLabel
                          size="small"
                          style={{ padding: "0px 6px" }}
                          value={item}
                          control={<Radio />}
                          label={item}
                          className={classes.smallRadioButton}
                        />
                      ))}
                    </RadioGroup>
                  ) : element.type === "checkbox" ? (
                    <>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value={element.value}
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  const selectedValue = e.target.checked
                                    ? "Y"
                                    : "N";
                                  setRxOtherDetails({
                                    ...rxOtherDetails,
                                    [element.key]: selectedValue,
                                  });
                                  setOtherRxBtnUnable(true);
                                  setShowDiscard(true);
                                }}
                              />
                            }
                            label={element.lbltitle}
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </FormControl>
                    </>
                  ) : element.type === "button" &&
                    element?.btnlbl === "Lookup" ? (
                    <>
                      <Button
                        classes={{ root: classes.button }}
                        variant="contained"
                        color="secondary"
                        // onClick={selectPrevFrame}
                      >
                        {element.btnlbl}
                      </Button>
                    </>
                  ) : element.type === "button" &&
                    element?.btnlbl === "Update" ? (
                    <>
                      {/* {selectedRow && (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                        //   onClick={updateSelectFrame}
                        >
                          {element.btnlbl}
                        </Button>
                      )} */}
                    </>
                  ) : (
                    <TextField
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      placeholder="Type here..."
                      variant="outlined"
                      multiline
                      rows={3}
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      value={
                        rxOtherDetails && rxOtherDetails[element.key]
                          ? rxOtherDetails && rxOtherDetails[element.key]
                          : ""
                      }
                      onChange={(e) => {
                        console.log("selected");
                        setRxOtherDetails({
                          ...rxOtherDetails,
                          [element.key]: e.target.value,
                        });
                        setOtherRxBtnUnable(true);
                        setShowDiscard(true);
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <Typography
                    className={classes.formHeading}
                    style={{
                      fontWeight: "600",

                      color: "#4E97FD",
                    }}
                  >
                    {element.title}
                  </Typography>
                </>
              )}
            </Grid>
          );
        })}
        <Grid
          className={classes.btnGroup}
          item
          xs={12}
          style={{ display: "flex", justifyContent: "end" }}
        >
          {showDiscard && (
            <Button
              className={classes.btnleftOutline}
              variant="outlined"
              style={{ marginRight: "10px", fontSize: "12px" }}
              size="medium"
              onClick={discardHandler}
            >
              Discard
            </Button>
          )}
          <Button
            disabled={!otherRxBtnUnable}
            style={{ display: "flex", float: "right" }}
            onClick={handleSubmit}
            className={classes.btnlRightDark}
            variant="contained"
            size="medium"
            color="secondary"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
