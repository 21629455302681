import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";

import { loadState } from "../../../hooks/helper";

import TableWidget from "../../Widget/TableWidget";

import TableDesign from "../../common/table/TableDesign";
import { TableRow, TableCell } from "@material-ui/core";

import useStyles from "../styles";
import { getRefractiveData } from "../../../services/ServiceLayer";

export default function RefractiveHistory({ patientId }) {
  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Exam Name",
      align: "left",
    },
    {
      label: "Type",
      align: "left",
    },
    {
      label: "Sph",
      align: "left",
    },
    {
      label: "Cyl",
      align: "left",
    },
    {
      label: "Axis",
      align: "left",
    },
    {
      label: "Add",
      align: "left",
    },
    {
      label: "HPrism",
      align: "left",
    },
    {
      label: "HBase",
      align: "left",
    },
    {
      label: "VPrism",
      align: "left",
    },
    {
      label: "VBase",
      align: "left",
    },
    {
      label: "DVA",
      align: "left",
    },
    {
      label: "NVA",
      align: "left",
    },
    // {
    //   label: "Updated By",
    //   align: "left",
    // },
    // {
    //   label: "Action",
    // },
  ];
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const [modalShow, setModalShow] = useState(false);

  const [patientRefractiveData, setPatientRefractiveData] = useState([]);

  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  useEffect(() => {
    getRefractiveData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELHST`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setPatientRefractiveData(response?.data);
        // setDataUpdated(false);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);
 

  return (
    <>
      <Grid container spacing={4} style={{ width: "100%" }}>
        <Grid item xs={12} style={{ marginTop: "20px" , zIndex:'1'}}>
          <TableWidget noBodyPadding style={{height:'auto'}}>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {patientRefractiveData && patientRefractiveData?.length ? (
                patientRefractiveData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.rpt}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          OD
                          <br />
                          <span style={{ color: "#0586FB" }}>(OS)</span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_sphere_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            ({row.fr_sphere_os})
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_cyl_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            ({row.fr_cyl_os})
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_axis_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            ({row.fr_axis_os})
                          </span>
                        </TableCell>

                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_add_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            ({row.fr_add_os})
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_hprism_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            ({row.fr_hprism_os})
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_hbase_od_var}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            ({row.fr_hbase_os_var})
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_vprism_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            ({row.fr_vprism_os})
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_vbase_od_var}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            ({row.fr_vbase_os_var})
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_dva_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            ({row.fr_dva_os})
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row.fr_nva_od}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            ({row.fr_nva_os})
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
