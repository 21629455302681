import React, { useState, useEffect } from "react";
import useStyles from "../../styles";
import {
  Grid,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Select,
  FormControl,
  FormGroup,
  Checkbox,
  Button,
  Avatar,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";
import { frameFormElements } from "../order_constant";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExsitingFrameOrder from "../../FrameOrd/ExsitingFrameOrder";
import { loadState } from "../../../../../hooks/helper";
import {
  getManufacturerDetail,
  getinvtFrame,
} from "../../../../../services/ServiceLayer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function FrameData({
  frameDataDetail,
  setFrameDataDetail,
  selectedRowAll,
}) {
  console.log("selectedRowAll", selectedRowAll);
  var classes = useStyles();
  const history = useHistory();
  const [frameModalShow, setFrameModalShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [listData, setListData] = useState([]);
  const [invSourceData, setInvSourceData] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [frameTypeData, setFrameTypeData] = useState([]);
  const [rimTypeData, setRimTypeData] = useState([]);
  const [frameShapeData, setFrameShapeData] = useState([]);
  const [frameMaterialData, setFrameMaterialData] = useState([]);
  const [manufacturerData, setManufacturerData] = useState([]);
  const [frameListData, setFrameListData] = useState();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  // call meta data
  const metaData = loadState().metaData;
  const readAccessData = loadState().menu;
  useEffect(() => {
    console.log("selectedRowAll", selectedRowAll);
    if (selectedRowAll) {
      getinvtFrame(
        `location_id=${userData?.location_id}&user_role=${userRole}&&frame_id=${selectedRowAll?.frame_id}&manufacturer_id=0&opt_type=SELFRM`
      ).then((response) => {
        console.log("response", response);
        if (response?.data) {
          // setIsLoader(false);
          setFrameListData(response?.data);
        } else {
          // setIsLoader(false);
        }
      });
    }
  }, [selectedRowAll]);
  useEffect(() => {
    console.log("loadState", metaData);
    setListData(metaData);
  }, []);
  useEffect(() => {
    if (listData.length > 0) {
      const genderData = listData[0].gender;
      const invSourceData = listData[0].InventorySource;
      const frameTypeData = listData[0].FrameType;
      const rimTypeData = listData[0].RimType;
      const frameShapeData = listData[0].FrameShape;
      const frameMaterialData = listData[0].FrameMaterial;

      setGenderData(genderData);
      setInvSourceData(invSourceData);
      setFrameTypeData(frameTypeData);
      setRimTypeData(rimTypeData);
      setFrameShapeData(frameShapeData);
      setFrameMaterialData(frameMaterialData);
    }
  }, [listData]);

  useEffect(() => {
    getManufacturerDetail(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELMAN&manufacturer=0`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setManufacturerData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
  }, []);
  const selectPrevFrame = () => {
    setOpen1(true);
    setFrameModalShow(true);
  };
  const updateSelectFrame = () => {
    history.push(`/app/frame-details/${selectedRow?.frame_id}`);
  };
  const hideHandle = () => {
    setOpen(false);
    setOpen1(false);
    setFrameModalShow(false);
  };
  const [frameViewDetail, setFrameViewDetail] = useState({
    frame_src: "",
    manufacturer_id: "",
    // col: selectedRow,
    name: "",
    upc: "",
    // invoice_no: selectedRow,
    // color: selectedRow,
    col_code: "",
    material_id: "",
    frame_type_id: "",
    gender: "",
    a: "",
    b: "",
    ed: "",
    bridge: "",
    temple: "",
    usage: "",
    frame_shape_id: "",
    lens_color: "",
    rim_type: "",
  });

  useEffect(() => {
    console.log("frameListData", { ...frameListData });
    if (frameListData) {
      setSelectedRow(...frameListData);
    }
  }, [frameListData]);

  useEffect(() => {
    console.log("selectedRow", selectedRow);
    if (selectedRow) {
      setFrameDataDetail({
        ...frameDataDetail,
        frame_id: selectedRow?.frame_id,
      });
      setFrameViewDetail({
        ...frameViewDetail,
        frame_src: selectedRow.inv_source,
        manufacturer_id: selectedRow?.manufacturer_id,
        // col: selectedRow,
        name: selectedRow?.frame_name,
        upc: selectedRow?.frame_sku,
        // invoice_no: selectedRow,
        // color: selectedRow,
        col_code: selectedRow?.frame_color_code,
        material_id: selectedRow?.material_id,
        frame_type_id: selectedRow?.frame_type_id,
        gender: selectedRow?.gender,
        a: selectedRow?.a,
        b: selectedRow?.b,
        ed: selectedRow?.ed,
        bridge: selectedRow?.bridge,
        temple: selectedRow?.temple,
        usage: selectedRow?.usage,
        frame_shape_id: selectedRow?.frame_shape_id,
        lens_color: selectedRow?.frame_color_val,
        rim_type: selectedRow?.rim_type_id,
      });
    }
  }, [selectedRow]);
  useEffect(()=>{
    console.log("frameDataDetail",frameDataDetail);
    if(frameDataDetail){
      setFrameViewDetail({
        ...frameViewDetail,
        frame_src: frameDataDetail?.frame_src
      })
    }

  },[frameDataDetail])
  const handleEditClick = (event) => {
    if (selectedRow) {
      console.log("selectedRow", selectedRow);
      // Use React Router to navigate to the edit page
      history.push(`/app/frame-details/${selectedRow.frame_id}`, {
        selectedRow,
        event,
      });
    }
  };
  return (
    <>
      <ExsitingFrameOrder
        open={open1}
        onHide={hideHandle}
        title={"Select Exsiting Frame Order"}
        frameModalShow={frameModalShow}
        setSelectedRow={setSelectedRow}
      />
      <Grid
        container
        spacing={1}
        style={{
          marginBottom: "16px",
        }}
      >
        {frameFormElements.map((element, i) => {
          return (
            <Grid
              item
              xs={12}
              md={element.mdSize}
              className={`${classes.textGroup} ${
                element.title ? classes.highlightedItem : ""
              }`}
              style={{ border: "1px solid #DED9D9" }}
            >
              <Typography
                className={classes.formHeading}
                style={{ fontWeight: "500", color: "#4E97FD" }}
              >
                {element.label}{" "}
                {element.required === "true" ? (
                  <span className={classes.required}>*</span>
                ) : null}
                &nbsp;
              </Typography>
              {element.type ? (
                <>
                  {element.type === "select" &&
                  element.key !== "procedure_code" ? (
                    <FormControl
                      fullWidth
                      className={classes.dropdownFormControl}
                    >
                      <Select
                        disabled={element.key !== "frame_src"}
                        name={element.key}
                        className={classes.selectDropdown}
                        placeholder={element.placeHolder}
                        value={
                          frameViewDetail && frameViewDetail[element.key]
                            ? frameViewDetail && frameViewDetail[element.key]
                            : ""
                        }
                        onChange={(e) => {
                          console.log("selected");
                          setFrameDataDetail({
                            ...frameDataDetail,
                            [element.key]: e.target.value,
                          });
                        }}
                      >
                        <MenuItem disabled value="">
                          Select
                        </MenuItem>
                        {element.key === "frame_src" && invSourceData
                          ? invSourceData.map((item) => (
                              <MenuItem
                                key={item.attribute_id}
                                value={item.attribute_id}
                              >
                                {item.attribute_val}
                              </MenuItem>
                            ))
                          : element.key === "manufacturer_id" &&
                            manufacturerData
                          ? manufacturerData.map((item, index) => (
                              <MenuItem
                                key={index}
                                value={item.manufacturer_id}
                              >
                                {item.manufacturer_name}
                              </MenuItem>
                            ))
                          : element.key === "gender" && genderData
                          ? genderData.map((item) => (
                              <MenuItem
                                key={item.attribute_id}
                                value={item.attribute_id}
                              >
                                {item.attribute_val}
                              </MenuItem>
                            ))
                          : element.key === "frame_type_id" && frameTypeData
                          ? frameTypeData.map((item) => (
                              <MenuItem
                                key={item.attribute_id}
                                value={item.attribute_id}
                              >
                                {item.attribute_val}
                              </MenuItem>
                            ))
                          : element.key === "rim_type" && rimTypeData
                          ? rimTypeData.map((item) => (
                              <MenuItem
                                key={item.attribute_id}
                                value={item.attribute_id}
                              >
                                {item.attribute_val}
                              </MenuItem>
                            ))
                          : element.key === "frame_shape_id" && frameShapeData
                          ? frameShapeData.map((item) => (
                              <MenuItem
                                key={item.attribute_id}
                                value={item.attribute_id}
                              >
                                {item.attribute_val}
                              </MenuItem>
                            ))
                          : element.key === "material_id" && frameMaterialData
                          ? frameMaterialData.map((item) => (
                              <MenuItem
                                key={item.attribute_id}
                                value={item.attribute_id}
                              >
                                {item.attribute_val}
                              </MenuItem>
                            ))
                          : element.options.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  ) : element.type === "text" ? (
                    <div style={{ display: "flex" }}>
                      <TextField
                        disabled
                        required
                        name={element.key}
                        placeholder={element.placeHolder}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                          maxLength: element.maxLength,
                          onKeyPress: element.onKeyPress,
                        }}
                        value={frameViewDetail && frameViewDetail[element.key]}
                        // onChange={(e) => {
                        //   console.log("selected");
                        //   setFrameDataDetail({
                        //     ...frameDataDetail,
                        //     [element.key]: e.target.value,
                        //   });
                        // }}
                      />
                      {element.key === "sku" && (
                        <Button
                          // onClick={selectFrameBySku}
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                        >
                          Slect
                        </Button>
                      )}
                    </div>
                  ) : element.type === "commission_amount" ? (
                    <TextField
                      disabled
                      type="text"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      size="small"
                      inputProps={{
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                        maxLength: element.maxLength,
                        onKeyPress: element.onKeyPress,
                      }}
                    />
                  ) : element.type === "date" ||
                    element.type === "datetime-local" ? (
                    <TextField
                      id={element.key}
                      name={element.key}
                      placeholder={element.placeHolder}
                      type={element.type}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ) : element.type === "radio" ? (
                    <RadioGroup row defaultValue="">
                      {element.radioValue.map((item) => (
                        <FormControlLabel
                          size="small"
                          style={{ padding: "0px 6px" }}
                          value={item}
                          control={<Radio />}
                          label={item}
                          className={classes.smallRadioButton}
                        />
                      ))}
                    </RadioGroup>
                  ) : element.type === "checkbox" ? (
                    <>
                      <Checkbox name={element.key} />
                    </>
                  ) : element.type === "button" &&
                    element?.btnlbl === "Lookup" ? (
                    <>
                      <Button
                        classes={{ root: classes.button }}
                        variant="contained"
                        color="secondary"
                        onClick={selectPrevFrame}
                      >
                        {element.btnlbl}
                      </Button>
                    </>
                  ) : element.type === "button" &&
                    element?.btnlbl === "Update" ? (
                    <>
                      {selectedRow && (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                          onClick={() => handleEditClick("View/Update")}
                          // onClick={updateSelectFrame}
                        >
                          {element.btnlbl}
                        </Button>
                      )}
                    </>
                  ) : (
                    <TextField
                      disabled
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      placeholder="Type here..."
                      variant="outlined"
                      multiline
                      rows={3}
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <Typography
                    className={classes.formHeading}
                    style={{
                      fontWeight: "600",

                      color: "#4E97FD",
                    }}
                  >
                    {element.title}
                  </Typography>
                </>
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
