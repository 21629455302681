import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  // Checkbox,
  Switch,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../common/modal/Popup";
// styles
import useStyles from "../styles";

import moment from "moment";
import {
  currentUser,
  // handleAddressKeyPress,
  // handleAddressKeyPressPaste,
  loadState,
} from "../../../hooks/helper";
import { getschScheduletype, postPatientAssessmentFollowup, postPatientDisclosure } from "../../../services/ServiceLayer";
import { toast } from "react-toastify";
import useListData from "../../common/allMetaData/useListData";

export default function ManageFollowUp(props) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  const listData = useListData();
  const { examMode,examAssessmentFollowUpStatus,examAssessmentFollowUpMonth } = listData;
  const formElements = [
    {
      key: "followup_reason",
      label: "Follow up for",
      placeHolder: "select",
      type: "select",
      options: [],
      mdSize: "4",
    },
    {
      key: "followup_duration",
      label: "In Month",
      placeHolder: "select",
      type: "select",
      options: [],
      mdSize: "4",
    },
    {
      key: "followup_date",
      label: "On Date",
      placeHolder: "select",
      type: "date",
      mdSize: "4",
    },
    {
      key: "days_contact",
      label: "Days until contact",
      placeHolder: "text",
      type: "text",

      mdSize: "6",
    },
    {
      key: "followup_notes",
      label: "Reason",
      placeHolder: "text",
      type: "text",

      mdSize: "6",
    },

    {
      key: "contact_mode",
      label: "Mode",
      placeHolder: "select",
      type: "select",
      options: [""],
      mdSize: "6",
    },
    {
      key: "contact_status",
      label: "Status",
      placeHolder: "select",
      type: "select",
      options: [""],
      mdSize: "6",
    },
  ];
  console.log("props-24", props);
  const {
    selectclient,
    patientId,

    examExternalPupilAPD,
    examExternalPupilNPI,
    examExternalPupilRAPD,
    examExternalPupilReacts,
    examExternalPupilShape,
 
  } = props;
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [actionType, setActionType] = useState("ADDASM");
  console.log("selectclient", selectclient);

 
  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");

  const [isActionCalled, setIsActionCalled] = useState(false);
  const [reasonValues, setReasonValues] = useState([]);
  useEffect(() => {
    getschScheduletype().then((response) => {
     
      setReasonValues(response?.data);
    });
  }, []);
  const [followUpDetails, setFollowUpDetails] = useState({
    pat_exfu_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    followup_reason: '',
    followup_duration: '',
    followup_date: currentDate,
    days_contact: '',
    followup_notes: "",
    contact_mode: '',
    contact_status: '',
    updated_by: currentUser(),
    access_location: "",
    opt_type: actionType,
  });
  const clearForm = () => {
    setFollowUpDetails({
      pat_exfu_id: 0,
      patient_id: patientId,
      location_id: userData?.location_id,
      followup_reason: '',
      followup_duration: '',
      followup_date: currentDate,
      days_contact: '',
      followup_notes: "",
      contact_mode: '',
      contact_status: '',
      updated_by: currentUser(),
      access_location: "",
      opt_type: actionType,
    });
  };
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null || selectclient === undefined) {
      setFollowUpDetails({
        pat_exfu_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    followup_reason: '',
    followup_duration: '',
    followup_date: currentDate,
    days_contact: '',
    followup_notes: "",
    contact_mode: '',
    contact_status: '',
    updated_by: currentUser(),
    access_location: "",
    opt_type: actionType,
      });
    } else {
      setFollowUpDetails({
        pat_exfu_id: selectclient?.pat_exfu_id,
    patient_id: patientId,
    location_id: userData?.location_id,
    followup_reason: selectclient?.followup_reason,
    followup_duration: selectclient?.followup_duration,
    followup_date: moment(selectclient?.followup_date).format('YYYY-MM-DD'),
    days_contact: selectclient?.days_contact,
    followup_notes: selectclient?.followup_notes,
    contact_mode: selectclient?.followup_mode,
    contact_status: selectclient?.followup_status,
    updated_by: currentUser(),
    access_location: selectclient?.access_location,
    opt_type: 'UPDASM',
        
      });
    }
  }, [selectclient]);
  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    // if (!isError() && error !== null && isActionCalled) {
    if (isActionCalled) {
      postPatientAssessmentFollowup(followUpDetails).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Assessment follow-up added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          clearForm();
          props.onHide();
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    // } else {
    //   setIsActionCalled(false);
    // }

    setIsActionCalled(false);
  }, [isActionCalled]);
  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
  };

  
 
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        // fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleSubmit}
            >
              {selectclient ? 'Update' :'Add'}
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={2}
              className={classes.formBox}
              // style={{ marginBottom: "4px" }}
            >
              {formElements.map((element, i) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={classes.textGroup}
                    >
                      {element.label && (
                        <Typography
                          style={{ fontSize: "13px" }}
                          className={classes.formHeading}
                        >
                          {element.label}{" "}
                          {element.required === "true" ? (
                            <span className={classes.required}>*</span>
                          ) : null}
                        </Typography>
                      )}
                      {element.type ? (
                        <>
                          {element.type === "select" ? (
                            <FormControl
                              fullWidth
                              className={classes.dropdownFormControl}
                            >
                              <Select
                                name={element.key}
                                className={classes.selectDropdown}
                                placeholder={element.placeHolder}
                                value={
                                  followUpDetails &&
                                  followUpDetails[element.key]
                                    ? followUpDetails &&
                                      followUpDetails[element.key]
                                    : ""
                                }
                                onChange={(e) => {
                                  console.log("selected", e.target.value);

                                  setFollowUpDetails({
                                    ...followUpDetails,
                                    [element.key]: e.target.value,
                                  });

                                  // resetValidator(e.target.name);
                                }}
                              >
                                <MenuItem disabled value="">
                                  Select
                                </MenuItem>
                                { element.key === "followup_reason" &&
                                    reasonValues
                                  ? reasonValues.map((item) => (
                                      <MenuItem
                                        key={item.schedule_type_id}
                                        value={item.schedule_type_id}
                                      >
                                        {item.schedule_name}
                                      </MenuItem>
                                    )):element.key === "contact_status" && examAssessmentFollowUpStatus
                                  ? examAssessmentFollowUpStatus.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "followup_duration" &&
                                  examAssessmentFollowUpMonth
                                  ? examAssessmentFollowUpMonth.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "contact_mode" &&
                                  examMode
                                  ? examMode.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "react" &&
                                    examExternalPupilReacts
                                  ? examExternalPupilReacts.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "apd" &&
                                    examExternalPupilAPD
                                  ? examExternalPupilAPD.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "rapd" &&
                                    examExternalPupilRAPD
                                  ? examExternalPupilRAPD.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.options.map((item) => (
                                      <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                              </Select>
                            </FormControl>
                          ) : element.type === "text" ? (
                            <TextField
                              required
                              name={element.key}
                              placeholder={element.placeHolder}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                                maxLength: element.maxLength,
                                onKeyPress: element.onKeyPress,
                                onPaste: element.onpaste,
                              }}
                              value={
                                followUpDetails && followUpDetails[element.key]
                                  ? followUpDetails &&
                                    followUpDetails[element.key]
                                  : ""
                              }
                              onChange={(e) => {
                                console.log("selected");
                                setFollowUpDetails({
                                  ...followUpDetails,
                                  [element.key]: e.target.value,
                                });
                                // resetValidator(e.target.name);
                              }}
                            />
                          ) : element.type === "date" ||
                            element.type === "datetime-local" ? (
                            <TextField
                              id={element.key}
                              name={element.key}
                              placeholder={element.placeHolder}
                              type={element.type}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                min: `${currentDateTime}`,
                                max: `${currentDate}`,
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onKeyDown={(e) => {
                                e.preventDefault(); // Prevent manual typing
                              }}
                              value={
                                followUpDetails && followUpDetails[element.key]
                                  ? followUpDetails &&
                                    followUpDetails[element.key]
                                  : ""
                              }
                              onChange={(e) => {
                                console.log("selected");
                                setFollowUpDetails({
                                  ...followUpDetails,
                                  [element.key]: e.target.value,
                                });
                                // resetValidator(e.target.name);
                              }}
                            />
                          ) : element.type === "textarea" ? (
                            <TextField
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              placeholder="Type here..."
                              variant="outlined"
                              multiline
                              rows={3}
                              inputProps={{
                                style: {
                                  fontSize: "12px",
                                },
                                maxLength: element.maxLength,
                                onKeyPress: element.onKeyPress,
                                onPaste: element.onpaste,
                              }}
                              value={
                                followUpDetails && followUpDetails[element.key]
                                  ? followUpDetails &&
                                    followUpDetails[element.key]
                                  : ""
                              }
                              onChange={(e) => {
                                console.log("selected");
                                setFollowUpDetails({
                                  ...followUpDetails,
                                  [element.key]: e.target.value,
                                });
                                // resetValidator(e.target.name);
                              }}
                            />
                          ) : element.type === "button" ? (
                            <Button
                              classes={{ root: classes.button }}
                              variant="contained"
                              color="secondary"
                              style={{
                                width: "100%",
                                fontSize: "10px",
                                marginTop: "26px",
                              }}
                            >
                              {element.btnName}
                            </Button>
                          ) : (
                            <Switch />
                          )}
                        </>
                      ) : (
                        <>
                          <Typography
                            className={classes.formHeading}
                            style={{
                              fontWeight: "600",

                              color: "#4E97FD",
                            }}
                          >
                            {element.title}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
