import React from "react";
import {
  ImageListItem,
  ImageListItemBar,
  Grid,Button,
  Typography,
} from "@material-ui/core";
import { autoPlay } from "react-swipeable-views-utils";
import SwipeableViews from "react-swipeable-views";
import PageTitle from "../../../components/PageTitle";
import { makeStyles } from "@material-ui/core/styles";
import glass1 from "../../../assets/glass1.jpg";
import glass2 from "../../../assets/glass2.jpg";
import glass3 from "../../../assets/glass1.jpg";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  carousel: {
    position: "relative",
    // height: "200px", // Adjust the height as needed
    overflow: "hidden",
  },
  slide: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  slideContent: {
    textAlign: "center",
  },
}));
export default function PurchaseHistory() {
  const classes = useStyles();
  const itemData = [
    {
      img: glass1,
      title: "Hydrotac Stick-On Bifocal Lenses",
      price:'111'
    },
    {
      img: glass2,
      title: "Adult Swimming Goggles with Prescription",
      price:'96'
    },
    {
      img: glass3,
      title: "Hydrotac Stick-On Bifocal Lenses",
      price:'80'
    },
    {
      img: glass1,
      title: "Adult Swimming Goggles with Prescription",
      price:'120'
    },
  ];
  return (
    <>
      <div className={classes.carousel} style={{ marginTop: "18px" }}>
        <PageTitle title="Purchase History" />
        <AutoPlaySwipeableViews>
          <div className={classes.slide}>
            <div className={classes.slideContent}>
              <Grid container spacing={4}>
                {itemData.map((item) => (
                  // <SwipeableViews>
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <ImageListItem key={item.img} style={{ listStyle: "none" }}>
                      <img
                        style={{
                          height: "180px",
                          width: "100%",
                          border: "",
                        }}
                        src={`${item.img}?w=248&fit=crop&auto=format`}
                        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.title}
                        loading="lazy"
                      />
                      <div
                        style={{
                          backgroundColor: "#4E97FD",
                          color: "#fff",
                          textAlign: "left",
                          padding: "4px 6px",
                          height: "60px",
                        }}
                      >
                        <Typography style={{whiteSpace:'nowrap', textOverflow:'ellipsis',overflow:'hidden'}}>{item.title}</Typography>
                        <Typography style={{fontSize:'22px',fontWeight:'600'}}>${item.price}</Typography>
                       
                      </div>
                    </ImageListItem>
                  </Grid>
                  // </SwipeableViews>
                ))}
              </Grid>
            </div>
          </div>
          <div className={classes.slide}>
            <div className={classes.slideContent}>
              <Grid container spacing={4}>
                {itemData.map((item) => (
                  // <SwipeableViews>
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <ImageListItem key={item.img} style={{ listStyle: "none" }}>
                      <img
                        style={{
                          height: "180px",
                          width: "100%",
                          border: "",
                        }}
                        src={`${item.img}?w=248&fit=crop&auto=format`}
                        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.title}
                        loading="lazy"
                      />
                      <div
                        style={{
                          backgroundColor: "#4E97FD",
                          color: "#fff",
                          textAlign: "left",
                          padding: "4px 6px",
                          height: "60px",
                        }}
                      >
                        <Typography style={{whiteSpace:'nowrap', textOverflow:'ellipsis',overflow:'hidden'}}>{item.title}</Typography>
                        <Typography style={{fontSize:'22px',fontWeight:'600'}}>${item.price}</Typography>
                      </div>
                    </ImageListItem>
                  </Grid>
                  // </SwipeableViews>
                ))}
              </Grid>
            </div>
          </div>
          {/* Add more slides as needed */}
        </AutoPlaySwipeableViews>
      </div>
    </>
  );
}
