import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  popupTitle: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Poppins",
    color: "#201F1E",
    fontSize: "20px",
    fontWeight: 500,

    // paddingTop:theme.spacing(1),
    // paddingBottom:theme.spacing(1)
  },
  closeicons: {
    position: "absolute",
    marginRight: "10px",
    marginTop: "8px",
    right: 0,
    cursor: "pointer",
  },
  formBox: {
    fontFamily: "Poppins",
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  avatar: {
    width: "68px",
    height: "68px",
  },
  formHeading: {
    marginTop: theme.spacing(2),
    fontFamily: "Poppins",
    marginBottom: theme.spacing(0),
  },
  formLinkHeading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    fontFamily: "Poppins",
    fontWeight: 700,
    color: "#2A2338",
    fontSize: "12px",
    fontStyle: "normal",
  },
  formLinkHeadingValue: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#0445AF",
    fontSize: "15px",
    fontStyle: "normal",
  },
  txtForm: {
    padding: "0px 4px",
  },
  modelFooter: {
    fontFamily: "Poppins",
    padding: theme.spacing(2),
  },
  blueButton: {
    fontFamily: "Poppins",
    background: "#0078D4",
    color: "#fff",
    fontWeight: 400,
    marginTop: theme.spacing(2),
    "&:hover": {
      background: "#0078D4",
      color: "#fff",
    },
  },
  gridBox: {
    fontFamily: "Poppins",
    // display:"flex",
    // justifyItems:"center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));
