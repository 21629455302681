export const manageScheduleSchema = {
  first_name: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter first name",
    },
  },
  last_name: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter last name",
    },
  },
  notes: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter notes",
    },
  },
  schedule_notes:{
    type: "string",
    required: true,
    properties: {
      empty: "Please enter notes",
    },
  },
  contact_home: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter contact",
      // number: "Please enter number only",
      minLength: {
        limit: 11,
        msg: "Number must be minimum 10 digits",
      },
      maxLength: {
        limit: 11,
        msg: "Number must be maximum 10 digits",
      },
    },
  },
  contact_cell: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter contact",
      // number: "Please enter number only",
      minLength: {
        limit: 11,
        msg: "Number must be minimum 10 digits",
      },
      maxLength: {
        limit: 11,
        msg: "Number must be maximum 10 digits",
      },
    },
  },
  billing_zip: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter zip",
      number: "Please enter number only",
      minLength: {
        limit: 5,
        msg: "Zip must be minimum 5 length",
      },
      maxLength: {
        limit: 5,
        msg: "Zip must be maximum 5 length",
      },
    },
  },

  email: {
    type: "email",
    required: true,
    properties: {
      empty: "Please enter email",
      email: "Please enter a valid Email",
    },
  },

  name: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter plan name",
    },
  },
  reasonOfVisit:{
    type: "string",
    required: true,
    properties: {
      empty: "Please select reason for visit",
    },
  },
  insurance_id:{
    type: "string",
    required: true,
    properties: {
      empty: "Please select insurance",
    },
  },
  s3Key: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select a file",
    },
  },
  file: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select a file",
    },
  },
  end: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter end time",
      time: "Please enter a valid time",
    },
  },
};
