import React, { useState, useRef } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
} from "@material-ui/core";

import {
  handleKeyPress,
  handleKeyPressPaste,
   handleAddressKeyPress,
  handleAddressKeyPressPaste,
} from "../../../../hooks/helper";

// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../common/modal/Popup";
// styles
import useStyles from "../styles";
import moment from "moment";
import { postAttachmentData } from "../../../../services/ServiceLayer";
import { toast } from "react-toastify";
import Webcam from "react-webcam";
import { currentUser } from "../../../../hooks/helper";

export default function ManageAttachmentFile(props) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  //
  toast.configure();

  const formElements = [
    {
      key: "file_name",
      label: "File Name",
      placeHolder: "Enter File name",
      type: "text",
      mdSize: "12",
      maxLength: "30",
      onKeyPress: handleAddressKeyPress,
      onPaste: handleAddressKeyPressPaste,
    },

    {
      key: "file_category",
      label: "Category",
      placeHolder: "select",
      type: "select",
      options: ["License", "Lab Result"],
      mdSize: "12",
    },
    {
      key: "file_description",
      label: "Description",
      placeHolder: "type here",
      type: "textarea",
      mdSize: "12",
      maxLength: "30",
      onKeyPress: handleAddressKeyPress,
      onPaste: handleAddressKeyPressPaste,
    },
  ];
  const { selectclient } = props;
  var classes = useStyles();
  const [imageName, setImageName] = useState("");
  const [image, setImage] = useState(null);
  const [uploadedImageName, setUploadedImageName] = useState(""); // Added state for uploaded image name

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [open, setOpen] = React.useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [scannedFile, setScannedFile] = useState(null);
  const [isScanning, setIsScanning] = useState(false);

  // scandoc
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user", // or "environment" for rear camera
  };
  const webcamRef = useRef(null);

  const handleScan = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        const scannedImage = dataURItoBlob(imageSrc);
        setScannedFile(scannedImage);
      }
    }
  };
  const startScanning = () => {
    setIsScanning(true);
    setScannedFile(null);
  };

  const stopScanning = () => {
    setIsScanning(false);
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  };
  // scan doc
  const hideHandler = () => {
    console.log("hide handler called");
    resetFormData(); // Clear form data after success
    props.onHide();
  };

  const handleAdd = () => {
    const error = validateField();

    if (error === "") {
      const formData = getFormData();
      formData.append("file_name", uploadedImageName); // Append uploaded image name to the form data
      postAttachmentData(getFormData())
        .then((response) => {
          console.log(response);

          if (response?.data?.status?.status) {
            toast.success("Attachment uploaded successfully.", {
              position: toast.POSITION.TOP_RIGHT,
            });
            props.onHide();
            resetFormData(); // Clear form data after success
          } else {
            console.log("failed condition");
            console.log("failed condition", response?.status?.description);
            toast.error(response?.data?.status?.description, {
              position: toast.POSITION.TOP_RIGHT,
            }); // Throw an error with the response description
            resetFormData(); // Clear form data after success
          }
        })
        .catch((error) => {
          console.log("error", error);
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
    else {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });

    }
  };
  const resetFormData = () => {
    setAttachFileData({
      image: "",
      file_name: "",
      is_locked: "",
      file_category: "",
      file_description: "",
    });
    setImageName("");
    setImage(null);
    setUploadedImageName("");
  };

  const getFormData = () => {
    const formData = new FormData();
    formData.append("file", image);
    formData.append("file_name", image.name);
    formData.append("file_description", attachFileData.file_description);
    formData.append("is_locked", "Y");
    formData.append("is_active", "Y");
    formData.append("patientId", props.patientId);
    formData.append("file_category", attachFileData.file_category);
    formData.append("access_location", "122");
    formData.append("opt_type", "ADDFILE");
    formData.append("updated_by",currentUser());
    return formData;
  };

  const validateField = () => {
    if (image === null) {
      return "Please upload Image";
    }
    if (image.name === "") {
      return "Please Enter file name";
    }
    if (attachFileData.file_description === "") {
      return "Please Enter description";
    }
    if (attachFileData.file_category === "") {
      return "Please Enter category";
    }
    return "";
  };


  const [value, setValue] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [attachFileData, setAttachFileData] = useState({
    image: "",
    file_name: image === null ? "" : image.name,
    is_locked: "",
    file_category: "",
    file_description: "",
  });
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleAdd}
            >
              Add
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={1}
              className={classes.formBox}
              style={{ marginBottom: "4px" }}
            >
              <Grid item xs={12} md={5} className={classes.textGroup}>
                <Typography className={classes.formHeading}>File</Typography>
                <Button variant="contained" color="secondary" component="label">
                  Upload
                  <input
                    hidden
                    accept="*"
                    multiple
                    type="file"
                    onChange={(e) => {
                      const uploadedFile = e.target.files[0];
                      setImage(uploadedFile);
                      setUploadedImageName(uploadedFile.name); // Set uploaded image name
                    }}
                  />
                </Button>
                <br />
                {uploadedImageName && ( // Display the uploaded image name if available
                  <Typography variant="caption">{uploadedImageName}</Typography>
                )}
              </Grid>
              {/* <Grid item xs={12} md={5} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  Scan File
                </Typography>
                {isScanning ? (
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    style={{ width: "100%", marginBottom: "10px" }}
                  />
                ) : (
                  scannedFile && (
                    <img
                      src={URL.createObjectURL(new Blob([scannedFile]))}
                      alt="Scanned Document"
                      style={{ width: "100%", marginBottom: "10px" }}
                    />
                  )
                )}
                {!isScanning && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={startScanning}
                  >
                    Start Scanning
                  </Button>
                )}

                {isScanning && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={stopScanning}
                  >
                    Stop Scanning
                  </Button>
                )}
              </Grid> */}
              <Grid item xs={12} md={2} className={classes.textGroup}>
                <Typography className={classes.formHeading}>Lock</Typography>
                <Checkbox />
              </Grid>
              {formElements.map((element, i) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={classes.textGroup}
                    >
                      <Typography className={classes.formHeading}>
                        {element.label}{" "}
                        {element.required === "true" ? (
                          <span className={classes.required}>*</span>
                        ) : null}
                      </Typography>

                      {element.type === "select" ? (
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            name={element.key}
                            style={{ fontSize: "12px" }}
                            className={classes.selectDropdown}
                            placeholder={element.placeHolder}
                            value={
                              attachFileData && attachFileData[element.key]
                            }
                            onChange={async (e) => {
                              setAttachFileData({
                                ...attachFileData,
                                [element.key]: e.target.value,
                              });
                            }}
                          >
                            <MenuItem disabled value="">Select</MenuItem>
                            {element.options.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : element.type === "text" ? (
                        <TextField
                          required
                          name={element.key}
                          placeholder={element.placeHolder}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          maxLength="30"
                          inputProps={{
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                            onPaste: element.onPaste,
                            onKeyPress: element.onKeyPress,
                          }}
                          value={attachFileData && attachFileData[element.key]}
                          onChange={async (e) => {
                            setAttachFileData({
                              ...attachFileData,
                              [element.key]: e.target.value,
                            });
                          }}
                        />
                      ) : element.type === "date" ||
                        element.type === "datetime-local" ? (
                        <TextField
                          id={element.key}
                          name={element.key}
                          placeholder={element.placeHolder}
                          type={element.type}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          maxLength="30"
                          variant="outlined"
                          inputProps={{
                            min: `${currentDateTime}`,
                            max: `${currentDate}`,
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={attachFileData && attachFileData[element.key]}
                          onChange={async (e) => {
                            setAttachFileData({
                              ...attachFileData,
                              [element.key]: e.target.value,
                            });
                          }}
                          onKeyDown={(e) => {
                            e.preventDefault(); // Prevent manual typing
                          }}
                        />
                      ) : element.type === "textarea" ? (
                        <TextField
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          placeholder="Type here..."
                          variant="outlined"
                          multiline
                          rows={3}
                          maxLength="30"
                          inputProps={{
                            style: {
                              fontSize: "12px",
                            },
                            onPaste: element.onPaste,
                            onKeyPress: element.onKeyPress,
                          }}
                          value={attachFileData && attachFileData[element.key]}
                          onChange={async (e) => {
                            setAttachFileData({
                              ...attachFileData,
                              [element.key]: e.target.value,
                            });
                          }}
                        />
                      ) : element.type === "button" ? (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                          style={{
                            width: "100%",
                            fontSize: "10px",
                            marginTop: "26px",
                          }}
                        >
                          {element.btnName}
                        </Button>
                      ) : (
                        <Checkbox />
                      )}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
