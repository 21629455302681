import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import moment from "moment";
import { formEmergencyElements } from "./Patient_Constant";
import useStyles from "../styles";
import { Box } from "@mui/material";

export default function Emergency({
  patientProfileData,
  setPatientProfileData,
  setIsProfileStateChanged
}) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  const classes = useStyles();

  const handleInputChange = (event, key) => {
    const value = event.target.value;
    setPatientProfileData(prevState => ({
      ...prevState,
      [key]: value
    }));
    setIsProfileStateChanged(true);
  };

  return (
    <Grid container spacing={2} className={classes.formBox}>
      {formEmergencyElements.map((element, i) => {
        return (
          <Grid
            item
            xs={12}
            md={2}
            lg={2}
            sm={12}
            className={classes.textGroup}
            key={element.key}
          >
            <Typography className={classes.formHeading}>
              {element.label}{" "}
              {element.required === "true" ? (
                <span className={classes.required}>*</span>
              ) : null}
            </Typography>

            {element.type === "select" ? (
              <FormControl
                fullWidth
                // sx={{
                //   '&.MuiFormControl-fullWidth': {
                //     width: 'auto',
                //   }
                // }}
                className={classes.dropdownFormControl}>
                <Select
                  name={element.key}
                  style={{ fontSize: "12px" }}
                  className={classes.selectDropdown}
                  placeholder={element.placeHolder}
                  value={
                    patientProfileData &&
                      patientProfileData[element.key] === null
                      ? ""
                      : patientProfileData && patientProfileData[element.key]
                  }
                  onChange={async (e) => {
                    setPatientProfileData({
                      ...patientProfileData,
                      [element.key]: e.target.value,
                    });
                  }}
                >
                  <MenuItem disabled value="">Select</MenuItem>
                  {element.options.map((item) => (
                    <MenuItem value={item} key={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : element.type === "textarea" ? (
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="Type here..."
                variant="outlined"
                multiline
                rows={3}
                inputProps={{
                  style: {
                    fontSize: "12px",
                  },
                  maxLength: `${element.maxLength}`,
                }}
                value={
                  patientProfileData &&
                    patientProfileData[element.key] === null
                    ? ""
                    : patientProfileData && patientProfileData[element.key]
                }
                onChange={async (e) => {
                  setPatientProfileData({
                    ...patientProfileData,
                    [element.key]: e.target.value,
                  });
                }}
              />
            ) : element.type === "date" ||
              element.type === "datetime-local" ? (
              <TextField
                id={element.key}
                name={element.key}
                placeholder={element.placeHolder}
                type={element.type}
                size="small"
                className={classes.txtForm}
                fullWidth
                variant="outlined"
                inputProps={{
                  min: `${currentDateTime}`,
                  max: `${currentDate}`,
                  style: {
                    height: "12px",
                    fontSize: "12px",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={
                  patientProfileData &&
                    patientProfileData[element.key] === null
                    ? ""
                    : patientProfileData && patientProfileData[element.key]
                }
                onChange={async (e) => {
                  setPatientProfileData({
                    ...patientProfileData,
                    [element.key]: e.target.value,
                  });
                }}
                onKeyDown={(e) => {
                  e.preventDefault(); // Prevent manual typing
                }}
              />
            ) : (
              <TextField
                required
                name={element.key}
                placeholder={element.placeHolder}
                size="small"
                className={`${classes.txtForm} ${element.key === 'first_emergency_contact_con' ? classes.customMarginTop : ''}`}
                fullWidth
                type={element.type}
                variant="outlined"
                inputProps={{
                  style: {
                    height: "12px",
                    fontSize: "12px",
                  },
                  maxLength: element.maxLength,

                  onKeyPress: element.onKeyPress, // Add the event handler
                  onPaste: element.onPaste
                }}
                value={
                  patientProfileData &&
                    patientProfileData[element.key] === null
                    ? ""
                    : patientProfileData && patientProfileData[element.key]
                }
                onChange={async (e) => {
                  setPatientProfileData({
                    ...patientProfileData,
                    [element.key]: e.target.value,
                  });
                }}
              />
            )}
          </Grid>
        );
      })}
    </Grid>
  );
}
