import React from "react";
import FrameModify from "../../components/Inventory/FrameMoreDetails/FrameModify";

export default function FrameUploadCsv() {
  return (
    <>
      <FrameModify />
    </>
  );
}
