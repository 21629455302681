import React, { useState } from "react";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Grid,
} from "@material-ui/core";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
// icons sets
import "font-awesome/css/font-awesome.min.css";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// import { AiOutlineDashboard } from "react-icons/ai"; 

import { ReactComponent as DashIcon} from '../../../../assets/dashbicons/speedometer-svgrepo-com.svg';
import { ReactComponent as ScheduleIcons} from '../../../../assets/dashbicons/schedule-calendar-and-blue-clock-18292.svg';
import { ReactComponent as ExamIcons} from '../../../../assets/dashbicons/goal-target-check-list-22646.svg';
import { ReactComponent as SettIcons} from '../../../../assets/dashbicons/process-gear-14997.svg';
import { ReactComponent as OrderIcons} from '../../../../assets/dashbicons/shopping-cart.svg';
// import { ReactComponent as Reporticons} from '../../../../assets/dashbicons/report-svgrepo-com.svg';
// import { ReactComponent as PatienIcons} from '../../../../assets/dashbicons/laptop-and-health-12457.svg';
import { ReactComponent as InventoryIcons} from '../../../../assets/dashbicons/tablet-781.svg';
import PatienIcons from '../../../../assets/dashbicons/health-insurance.png';
import eyeIcons from '../../../../assets/dashbicons/medical.png';
import Reporticons from '../../../../assets/dashbicons/health-check.png';

 
// components
import Dot from "../Dot";

export default function SidebarLink({
  link,
  icon,
  label,
  children,
  location,
  isSidebarOpened,
  nested,
  type,
}) {
  var classes = useStyles();

  // local
  var [isOpen, setIsOpen] = useState(false);
  var isLinkActive =
    link &&
    (location.pathname === link || location.pathname.indexOf(link) !== -1);

  if (type === "title")
    return (
      <Typography
        className={classnames(classes.linkText, classes.sectionTitle, {
          [classes.linkTextHidden]: !isSidebarOpened,
        })}
      >
        {label}
      </Typography>
    );

  if (type === "divider") return <Divider className={classes.divider} />;

  if (!children)
    return (
      <ListItem
        button
        component={link && Link}
        to={link} 
        className={classes.link}
        classes={{
          root: classnames(classes.linkRoot, {
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        disableRipple
      >
        <ListItemIcon
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
          })}
        >
          {nested ? (
            <Dot color={isLinkActive && "primary"} />
          ) : icon === "dashboard" ? (
            <DashIcon />
          ) : icon === "schedule" ? (
            <ScheduleIcons />
          ) : icon === "patients" ? (
            <img src={PatienIcons}  style={{width: '29px'}}/>
          ) : icon === "inventory" ? (
            <InventoryIcons />
          ) : icon === "exam" ? (
            <img src={eyeIcons}  style={{width: '31px'}}/> 
          ) : icon === "orders" ? (
            <OrderIcons />

          ) : icon === "settings" ? (
            <SettIcons />
          ): icon === "report" ? (
            <img src={Reporticons}  style={{width: '31px'}}/> 
          ) : (
            ""
          )}
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classnames(classes.linkText, {
              [classes.linkTextActive]: isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened,
            }),
          }}
          primary={label}
        />
      </ListItem>
    );

  return (
    <>
      <ListItem
        sx={{ backgroundColor: "#4E97FD" }}
        button
        component={link && Link}
        onClick={toggleCollapse} 
        className={classes.link}
        to={link}
        disableRipple
      >
        <ListItemIcon
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
          })}
        >
          {nested ? (
            <Dot color={isLinkActive && "primary"} />
          ) : icon === "dashboard" ? (
            <DashIcon />
          ) : icon === "schedule" ? (
            <ScheduleIcons />
          ) : icon === "patients" ? (
            <img src={PatienIcons}  style={{width: '29px'}}/>
          ) : icon === "inventory" ? (
            <InventoryIcons />
          ) : icon === "exam" ? (
            <img src={eyeIcons}  style={{width: '31px'}}/> 
          ) : icon === "orders" ? (
            <OrderIcons />
          ) : icon === "settings" ? (
            <SettIcons />
          ) : icon === "report" ? (
            <img src={Reporticons}  style={{width: '31px'}}/> 
          ) : (
            ""
          )}
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classnames(classes.linkText, {
              [classes.linkTextActive]: isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened,
            }),
          }}
          primary={label}
        />

        {isSidebarOpened && !isOpen ? (
          <KeyboardArrowDown
            style={{ marginLeft: "10px", right: "0", position: "absolute" }}
          />
        ) : (
          ""
        )}

        {isOpen ? (
          <KeyboardArrowUp
            style={{ marginLeft: "10px", right: "0", position: "absolute" }}
          />
        ) : (
          ""
        )}
      </ListItem>
      {children && (
        <Grid sx={{ marginLeft: "50px" }}>
          <Collapse
            in={isOpen && isSidebarOpened}
            timeout="auto"
            unmountOnExit
            className={classes.nestedList}
          >
            <List component="div" disablePadding>
              {children.map((childrenLink) => (
                <SidebarLink
                  key={childrenLink && childrenLink.link}
                  location={location}
                  isSidebarOpened={isSidebarOpened}
                  classes={classes}
                  nested
                  {...childrenLink}
                />
              ))}
            </List>
          </Collapse>
        </Grid>
      )}
    </>
  );

  // ###########################################################

  function toggleCollapse(e) {
    if (isSidebarOpened) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  }
}
