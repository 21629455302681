import React from 'react'
import AddFrame from '../../components/Inventory/Services/AddFrame';

export default function frameDetails() {
  return (
    <>
    <AddFrame/>
    </>
  )
}
