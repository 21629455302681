import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  Grid,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Select,
  FormControl,
  Checkbox,
  Button,
  Avatar,
  Tabs,
  Tab,
  Paper,
  Fade,
  IconButton,
} from "@material-ui/core";
// data
import Autocomplete from "@material-ui/lab/Autocomplete";
// Model
import { contactFormElements } from "../inventory_constant";

import { image } from "../../../assets/gallery.jpg";

import useStyles from "./styles";
// components

import moment from "moment";
import PageTitle from "../../PageTitle/PageTitle";
import LocationList from "./LocationList";
import TransactionList from "./TransactionList";
import MaterialsList from "./MaterialsList";
import {
  currentUser,
  postFlagBooleanVariable,
  loadState,
} from "../../../hooks/helper";
import {
  postInvtcontactLense,
  getProcedureCode,
  getManufacturerDetail,
  getSupplierDetail,
  getInvtService,
  getServiceMaster,
} from "../../../services/ServiceLayer";
import { ToastContainer, toast } from "react-toastify";
import ManageProcedureCost from "./ManageProcedureCost";
import InventoryValidation from "../../../hooks/InventoryValidation";
import { Delete } from "@material-ui/icons";
import SubContactList from "./SubContactList";
import FullFeaturedCrudGrid from "./FullFeaturedCrudGrid";

export default function AddContactLens() {
  const history = useHistory();
  const { state } = useLocation();
  const selectedRow = state?.selectedRow;
  const eventType = state?.event;
  const inputRef = useRef();
  //
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [isWrongFileType, setIsWrongFileType] = useState(false);
  //
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();
  var [activeTabId, setActiveTabId] = useState(0);
  const [listData, setListData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModel, setOpenModel] = React.useState(false);
  const [modelShow, setModalShow] = useState(false);
  const [frameBrandData, setFrameBrandData] = useState([]);
  const [prodecureCodeData, setProdecureCodeData] = useState([]);
  const [structureList, setStructureList] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [procedureCodeExtension, setProcedureCodeExtension] = useState("");
  const [actionType, setActionType] = useState("ADDLNS");
  const [invSourceData, setInvSourceData] = useState([]);
  const [logoUrls, setLogoUrls] = useState([]);
  const [manufacturerData, setManufacturerData] = useState([]);
  const [profitValue, setProfitValue] = useState(0);
  const [grossPercentage, setGrossPercentage] = useState(0);
  const [inventoryValue, setInventoryValue] = useState(0);
  const [invtUinitData, setInvtUnitData] = useState([]);
  const [inventoryReadValue, setInventoryReadValue] = useState();
  const [isActionExit, setIsActionExit] = useState(false);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [serviceFrequencyValues, setServiceFrequencyValues] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [returnableData, setReturnableData] = useState([]);
  // call meta data
  const metaData = loadState().metaData;
  const readAccessData = loadState().menu;

  useEffect(() => {
    console.log("readAccessData", readAccessData);
    const inventorySection = readAccessData.find(
      (item) => item.label === "Inventory"
    );
    if (inventorySection) {
      const inventoryChildren = inventorySection.children;

      const inventoryReadAcess = inventoryChildren.find(
        (child) => child.label === "Inventory"
      ).read_access;
      setInventoryReadValue(inventoryReadAcess);
    }
  }, [readAccessData]);
  useEffect(() => {
    console.log("loadState", metaData);
    setListData(metaData);
  }, []);

  useEffect(() => {
    if (listData.length > 0) {
      const serviceFrequencyValues = listData[0].ServiceFrequency;
      const invtUinitData = listData[0].InventoryUnit;
      const invSourceData = listData[0].InventorySource;
      const frameBrandData = listData[0].frameBrand;
      const returnableData = listData[0].InventoryReturn;
      setInvSourceData(invSourceData);
      setInvtUnitData(invtUinitData);
      setServiceFrequencyValues(serviceFrequencyValues);
      setFrameBrandData(frameBrandData);
      setReturnableData(returnableData);
    }
  }, [listData]);
  useEffect(() => {
    getServiceMaster(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSTR&structure_id=0`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setStructureList(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });

    getInvtService(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSRV`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setProdecureCodeData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
    getManufacturerDetail(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELMAN&manufacturer=0`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setManufacturerData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
    getSupplierDetail(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSUP&supplier_id=0`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setSupplierData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
  }, []);
  const [contectLensDetail, setContectLensDetail] = useState({
    contlens_id: "0",
    location_id: userData?.location_id,
    brand_id: "",
    procedure_code: "",
    lens_name: "",
    lens_unit: "",
    replacement_schedule: "",
    structure_id: "",
    lense_sku: "",
    is_hard_contact: "",
    inv_source: "",
    lense_tax1: "",
    lense_tax2: "",
    qty_purchased: "",
    qty_sold: "",
    qty_inventory: "",
    manufacturer_id: "",
    supplier_id: "",
    is_returnable: "",
    retail_price: "",
    item_cost: "",
    is_commission_prod: "",
    commission_amount: "",
    notes: "",
    is_active: "",
    created_dt: "",
    updated_by: currentUser(),
    access_location: " ",
    opt_type: actionType,
  });

  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    InventoryValidation({
      brand_id: contectLensDetail?.brand_id,
      lens_name: contectLensDetail?.lens_name,
      retail_price: contectLensDetail?.retail_price,
      item_cost: contectLensDetail?.item_cost,
    });
  useEffect(() => {
    console.log("selectedRow", selectedRow);
    if (selectedRow) {
      setContectLensDetail({
        contlens_id: eventType === "Copy" ? "0" : selectedRow?.contlens_id,
        location_id: userData?.location_id,
        procedure_code:selectedRow?.procedure_code,
        service_id: selectedRow?.service_id,
        lens_name: selectedRow?.lens_name,
        brand_id: selectedRow?.brand_id,
       
        lens_unit: selectedRow?.lens_unit,
        supplier_id:selectedRow?.supplier_id,
        returnable_id:selectedRow?.returnable_id,
        notes: selectedRow?.notes,
        lense_sku: selectedRow?.lense_sku,
        spiff: selectedRow?.spiff,
        contlense_picture: selectedRow?.contlense_picture,
        is_hard_contact: selectedRow?.is_hard_contact,
        proc_code: selectedRow?.proc_code,
        procedure_code_extension: selectedRow?.procedure_code_extension,
        structure_id: selectedRow?.structure_id,
        replacement_schedule: selectedRow?.replacement_schedule,
        qty_purchased: selectedRow?.qty_purchased,
        qty_sold: selectedRow?.qty_sold,
        qty_inventory: selectedRow?.qty_inventory,
        retail_price: selectedRow?.retail_price,
        item_cost: selectedRow?.item_cost,
        is_commission_prod: selectedRow?.is_commission_prod,
        commission_amount: selectedRow?.commission_amount,
        inv_source: selectedRow?.inv_source,
        is_returnable: selectedRow?.is_returnable,
        manufacturer_id: selectedRow?.manufacturer_id,
        lense_tax1: selectedRow?.lense_tax1,
        lense_tax2: selectedRow?.lense_tax2,
        service_name: selectedRow?.service_name,
        service_code_extension: selectedRow?.service_code_extension,
        service_code: selectedRow?.service_code,
        
        updated_by: currentUser(),

        access_location: " ",
        opt_type: eventType === "Copy" ? "ADDLNS" : "UPDLNS",
      });
      setSelectedProcedure({
        service_name: selectedRow?.service_name,
        service_code_extension: selectedRow?.service_code_extension,
      });
    }
  }, [selectedRow]);
  useEffect(() => {
    console.log("prodecureCodeData", prodecureCodeData);
  }, [prodecureCodeData]);
  useEffect(() => {
    const retailPrice = parseFloat(contectLensDetail.retail_price) || 0;
    const itemCost = parseFloat(contectLensDetail.item_cost) || 0;
    const commissionAmount =
      parseFloat(contectLensDetail.commission_amount) || 0;

    const calculatedProfit = retailPrice - (itemCost + commissionAmount);
    setProfitValue(calculatedProfit);

    //
    const calculatedGrossPercentage = (commissionAmount / retailPrice) * 100;
    setGrossPercentage(calculatedGrossPercentage);
  }, [
    contectLensDetail.retail_price,
    contectLensDetail.item_cost,
    contectLensDetail.commission_amount,
  ]);
  const handleCommissionTypeChange = (e) => {
    console.log("Commission Type Change Triggered");
    const newComissionType = e.target.value;
    console.log("New Commission Type:", newComissionType);

    setContectLensDetail({
      ...contectLensDetail,
      is_commission_prod: newComissionType,
      commission_amount: newComissionType === "Y" ? "" : "",
    });
  };
  const clearForm = () => {
    setContectLensDetail({
      contlens_id: "0",
      location_id: userData?.location_id,
      brand_id: "",
    procedure_code: "",
    lens_name: "",
    lens_unit: "",
    replacement_schedule: "",
    structure_id: "",
    lense_sku: "",
    is_hard_contact: "",
    inv_source: "",
    lense_tax1: "",
    lense_tax2: "",
    qty_purchased: "",
    qty_sold: "",
    qty_inventory: "",
    manufacturer_id: "",
    supplier_id: "",
    is_returnable: "",
    retail_price: "",
    item_cost: "",
    is_commission_prod: "",
    commission_amount: "",
    notes: "",
    is_active: "",
    created_dt: "",
      updated_by: currentUser(),
    access_location: " ",
      opt_type: actionType,
    });
    setSelectedProcedure({
      service_name: "",
      service_code_extension: "",
    });
    // setSelectedProcedure(null);
  };

  const goBack = () => {
    setTimeout(() => {
      history.push(`/app/inventory/${activeTabVal}`);
    }, 3000);
  };

  useEffect(() => {
    if (!isError() && error !== null && isActionCalled) {
      postInvtcontactLense(contectLensDetail).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Contact Lens added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          clearForm();
          if (isActionExit) {
            goBack();
          }
          setIsActionExit(false);
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActionExit(false);
        }
      });
    } else {
      setIsActionCalled(false);
    }
    setIsActionExit(false);
    setIsActionCalled(false);
  }, [isActionCalled]);
  const handleSaveAndMore = () => {
    checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };

  const handleSaveAndExit = () => {
    console.log("contectLensDetail", contectLensDetail);
    checkValidator(); // Check for validation errors
    setIsActionCalled(true);

    setIsActionExit(true);
  };
  const activeTabVal = 2;
  // setIsActionExit(true);
  const handleCancel = () => {
    history.push(`/app/inventory/${activeTabVal}`);
  };

  useEffect(() => {
    // Your existing code here ...

    // Calculate the inventory value
    const purchased = parseFloat(contectLensDetail.qty_purchased) || 0;
    const sold = parseFloat(contectLensDetail.qty_sold) || 0;
    const inventoryValue = purchased - sold;
    setInventoryValue(inventoryValue);
  }, [
    contectLensDetail.retail_price,
    contectLensDetail.item_cost,
    contectLensDetail.commission_amount,
    contectLensDetail.qty_purchased, // Add qty_purchased here
    contectLensDetail.qty_sold, // Add qty_sold here
  ]);
  const addProcedure = () => {
    // console.log("add client called");
    setOpenModel(true);
    setAnchorEl(null);
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpenModel(false);
  };
  // logo upload
  // logo upload
  const upload = (e) => {
    setIsWrongFileType(false);
    const images = e.target.files;

    for (let i = 0; i < Math.min(images.length, 3); i++) {
      const fileName = images[i]?.name;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

      if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
        setLogoUrls((prevUrls) => [
          ...prevUrls,
          URL.createObjectURL(images[i]),
        ]);
      } else {
        console.log("Only jpg/jpeg and png files are allowed!");
        setIsWrongFileType(true);
        setTimeout(() => {
          setIsWrongFileType(false);
        }, 3000);
      }
    }
  };
  useEffect(() => {
    if (logo) {
      let urllink = URL.createObjectURL(logo);
      console.log("urllink", urllink);
      setLogoUrl(urllink);
    }
  }, [logo]);
  const removeImageByIndex = (index) => {
    // Create a copy of the logoUrls array without the image at the specified index
    const updatedLogoUrls = [...logoUrls];
    updatedLogoUrls.splice(index, 1);

    // Update the state to trigger a re-render
    setLogoUrls(updatedLogoUrls);
  };
  return (
    <>
      <ToastContainer />
      <ManageProcedureCost
        open={openModel}
        title={"New Procedure"}
        onHide={hideHandle}
        setModalShow={setModalShow}
      />
      <PageTitle
        title="Manage Contact Lens"
        subtitle="Inventory >"
        urlLink={`/app/inventory/${activeTabVal}`}
      />
      {/* <TableWidget noBodyPadding> */}
      <Grid
        container
        spacing={2}
        className={classes.formBox}
        style={{
          border: "1px solid #DED9D9",
          marginBottom: "16px",
          padding: "16px",
        }}
      >
        <Grid item md={10}>
          <Grid container spacing={1}>
            {contactFormElements.map((element, i) => {
              const isHidden =
                (element.key === "item_cost" && inventoryReadValue === "Y") ||
                (element.key === "profit" && inventoryReadValue === "Y") ||
                (element.key === "is_commission_prod" &&
                  inventoryReadValue === "Y") ||
                (element.key === "commission_amount" &&
                  inventoryReadValue === "Y") ||
                (element.key === "gross_percentage" &&
                  inventoryReadValue === "Y") ||
                (element.key === "lense_tax1" &&
                  inventoryReadValue === "Y") ||
                (element.key === "lense_tax2" &&
                  inventoryReadValue === "Y") ||
                (element.key === "grp_cost" && inventoryReadValue === "Y") ||
                (element.key === "spiff" && inventoryReadValue === "Y");
              return (
                <>
                  {!isHidden && (
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={`${classes.textGroup} ${
                        element.title ? classes.highlightedItem : ""
                      }`}
                      style={{ border: "1px solid #DED9D9" }}
                    >
                      <Typography
                        className={classes.formHeading}
                        style={{ fontWeight: "500", color: "#4E97FD" }}
                      >
                        {element.label}{" "}
                        {element.required === "true" ? (
                          <span className={classes.required}>*</span>
                        ) : null}
                      </Typography>
                      {element.type ? (
                        <>
                          {element.type === "select" &&
                          element.key !== "service_name" ? (
                            <FormControl
                              fullWidth
                              className={classes.dropdownFormControl}
                            >
                              <Select
                                disabled={inventoryReadValue === "Y"}
                                name={element.key}
                                className={classes.selectDropdown}
                                placeholder={element.placeHolder}
                                value={
                                  contectLensDetail &&
                                  contectLensDetail[element.key]
                                    ? contectLensDetail &&
                                      contectLensDetail[element.key]
                                    : ""
                                }
                                onChange={(e) => {
                                  console.log("selected");
                                  setContectLensDetail({
                                    ...contectLensDetail,
                                    [element.key]: e.target.value,
                                  });
                                  resetValidator(e.target.name);
                                }}
                                isInvalid={error && error[element.key]}
                              >
                                <MenuItem disabled value="">
                                  Select
                                </MenuItem>
                                {element.key === "proc_code" &&
                                prodecureCodeData
                                  ? prodecureCodeData.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item?.procedure_code}
                                      >
                                        {item?.procedure_code}
                                      </MenuItem>
                                    ))
                                  : element.key === "lens_unit" &&
                                    invtUinitData
                                  ? invtUinitData.map((item, index) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "inv_source" &&
                                    invSourceData
                                  ? invSourceData.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "manufacturer_id" &&
                                    manufacturerData
                                  ? manufacturerData.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.manufacturer_id}
                                      >
                                        {item.manufacturer_name}
                                      </MenuItem>
                                    ))
                                  : element.key === "structure_id" &&
                                    structureList
                                  ? structureList.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.structure_id}
                                      >
                                        {item.structure_name}
                                      </MenuItem>
                                    ))
                                  : // replacement_schedule
                                  element.key === "replacement_schedule" &&
                                    serviceFrequencyValues
                                  ? serviceFrequencyValues.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "brand_id" &&
                                    frameBrandData
                                  ? frameBrandData.map((item, index) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "supplier_id" &&
                                    supplierData
                                  ? supplierData.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.supplier_id}
                                      >
                                        {item.supplier_name}
                                      </MenuItem>
                                    ))
                                  : element.key === "returnable_id" &&
                                    returnableData
                                  ? returnableData.map((item, index) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.options
                                  ? element.options.map((item) => (
                                      <MenuItem value={item}>{item}</MenuItem>
                                    ))
                                  : element.optionsVL.map((item) => (
                                      <MenuItem value={item.value}>
                                        {item.title}
                                      </MenuItem>
                                    ))}
                              </Select>
                              <Fade in={error}>
                                <Typography
                                  color="secondary"
                                  className={classes.errorMessage}
                                >
                                  {error && error[element.key]}
                                </Typography>
                              </Fade>
                            </FormControl>
                          ) : element.type === "select" &&
                            element.key === "service_name" ? (
                            <>
                              <Autocomplete
                                disabled={inventoryReadValue === "Y"}
                                id="proc_code-code"
                                options={prodecureCodeData} // Replace with your list of options
                                getOptionLabel={(option) =>
                                  option?.service_name
                                } // Define how to display the options
                                value={
                                  selectedProcedure
                                    ? selectedProcedure
                                    : contectLensDetail[element.key]
                                }
                                // value={selectedProcedure ? selectedProcedure : serviceLensDetail?.procedure_code}
                                onChange={(event, newValue) => {
                                  setSelectedProcedure(newValue); // Update the selected Service Code
                                  // Update the Service Code extension
                                  if (newValue) {
                                    setProcedureCodeExtension(
                                      newValue?.service_name
                                    );
                                    setContectLensDetail({
                                      ...contectLensDetail,
                                      service_id: newValue?.service_id,
                                      service_code: newValue?.service_code,
                                      service_name: newValue?.service_name,
                                      retail_price: newValue?.retail_price,
                                      item_cost: newValue?.item_cost,
                                    });
                                  } else {
                                    setProcedureCodeExtension("");
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                              />
                            </>
                          ) : element.type === "is_commission_prod" ? (
                            <FormControl
                              fullWidth
                              className={classes.dropdownFormControl}
                            >
                              <Select
                                disabled={inventoryReadValue === "Y"}
                                name="is_commission_prod"
                                className={classes.selectDropdown}
                                placeholder="Select"
                                value={contectLensDetail.is_commission_prod}
                                onChange={handleCommissionTypeChange} // Connect the function here
                              >
                                <MenuItem value="Y">Yes</MenuItem>
                                <MenuItem value="N">No</MenuItem>
                              </Select>
                            </FormControl>
                          ) : element.type === "text" ? (
                            <>
                              <TextField
                                required
                                name={element.key}
                                placeholder={element.placeHolder}
                                size="small"
                                className={classes.txtForm}
                                fullWidth
                                variant="outlined"
                                inputProps={{
                                  style: {
                                    height: "12px",
                                    fontSize: "12px",
                                  },
                                  onKeyPress: element.onKeyPress,
                                }}
                                value={
                                  element.key === "profit"
                                    ? profitValue.toFixed(3)
                                    : element.key === "gross_percentage"
                                    ? grossPercentage.toFixed(3)
                                    : element.key === "qty_inventory"
                                    ? inventoryValue.toFixed(3)
                                    : contectLensDetail &&
                                      contectLensDetail[element.key]
                                }
                                onChange={(e) => {
                                  setContectLensDetail({
                                    ...contectLensDetail,
                                    [element.key]: e.target.value,
                                  });
                                  resetValidator(e.target.name);
                                }}
                                isInvalid={error && error[element.key]}
                                disabled={ element.key === "lense_sku" ||
                                  element.key === "profit" ||
                                  element.key === "service_code" ||
                                  element.key === "gross_percentage" ||
                                  element.key === "procedure_code_extension" ||
                                  (element.key === "qty_sold" && selectedRow) ||
                                  element.key === "qty_inventory" ||
                                  inventoryReadValue === "Y"
                                }
                              />
                              <Fade in={error}>
                                <Typography
                                  color="secondary"
                                  className={classes.errorMessage}
                                >
                                  {error && error[element.key]}
                                </Typography>
                              </Fade>
                            </>
                          ) : element.type === "commission_amount" ? (
                            <TextField
                              type="text"
                              className={classes.txtForm}
                              disabled={
                                contectLensDetail.is_commission_prod !== "Y" ||
                                inventoryReadValue === "Y"
                              } // Enable only when is_commission_prod is "yes"
                              value={contectLensDetail.commission_amount}
                              fullWidth
                              variant="outlined"
                              size="small"
                              inputProps={{
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                                maxLength: element.maxLength,
                                onKeyPress: element.onKeyPress,
                              }}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                setContectLensDetail({
                                  ...contectLensDetail,
                                  commission_amount: isNaN(value) ? "" : value,
                                });
                              }}
                            />
                          ) : element.type === "date" ||
                            element.type === "datetime-local" ? (
                            <TextField
                              disabled={inventoryReadValue === "Y"}
                              id={element.key}
                              name={element.key}
                              placeholder={element.placeHolder}
                              type={element.type}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                min: `${currentDateTime}`,
                                max: `${currentDate}`,
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={
                                contectLensDetail &&
                                contectLensDetail[element.key]
                              }
                              onChange={(e) => {
                                setContectLensDetail({
                                  ...contectLensDetail,
                                  [element.key]: e.target.value,
                                });
                              }}
                            />
                          ) : element.type === "radio" ? (
                            <RadioGroup row defaultValue="">
                              {element.radioValue.map((item) => (
                                <FormControlLabel
                                  size="small"
                                  style={{ padding: "0px 6px" }}
                                  value={item}
                                  control={<Radio />}
                                  label={item}
                                  className={classes.smallRadioButton}
                                />
                              ))}
                            </RadioGroup>
                          ) : element.type === "checkbox" ? (
                            <>
                              <Checkbox
                                name={element.key}
                                checked={
                                  contectLensDetail[element.key] !== "N"
                                    ? contectLensDetail[element.key]
                                    : false
                                }
                                onChange={(e) => {
                                  const value = postFlagBooleanVariable(
                                    e.target.checked
                                  );
                                  setContectLensDetail({
                                    ...contectLensDetail,
                                    [element.key]: value,
                                  });
                                }}
                              />
                            </>
                          ) : (
                            <TextField
                              disabled={inventoryReadValue === "Y"}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              placeholder="Type here..."
                              variant="outlined"
                              multiline
                              rows={3}
                              inputProps={{
                                style: {
                                  fontSize: "12px",
                                },
                                maxLength: element.maxLength,
                              }}
                              value={
                                contectLensDetail &&
                                contectLensDetail[element.key]
                              }
                              onChange={(e) => {
                                setContectLensDetail({
                                  ...contectLensDetail,
                                  [element.key]: e.target.value,
                                });
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <Typography
                            className={classes.formHeading}
                            style={{
                              fontWeight: "600",

                              color: "#4E97FD",
                            }}
                          >
                            {element.title}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  )}
                </>
              );
            })}
          </Grid>
        </Grid>
        <Grid item md={2}>
          {logoUrls ? (
            logoUrls.map((url, index) => (
              <div key={index} style={{ position: "relative" }}>
                <Avatar
                  className={classes.avatar}
                  style={{ borderRadius: "0px", border: "" }}
                  src={url}
                />{" "}
                <IconButton
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={() => removeImageByIndex(index)}
                >
                  <Delete /> {/* Use your delete icon component here */}
                </IconButton>
              </div>
            ))
          ) : (
            <Avatar
              className={classes.avatar}
              sx={{
                borderRadius: "0px",
                marginTop: "10px",
              }}
              src={image}
              variant="square"
            />
          )}

          <Button
            variant="contained"
            className={classes.blueButton}
            component="label"
            disableElevation
          >
            Upload Contact
            <input
              hidden
              onChange={(e) => upload(e)}
              ref={inputRef}
              multiple
              name="myImage"
              accept="image/*"
              type="file"
            />
          </Button>
        </Grid>
      </Grid>
      <div
        className={classes.footerBox}
        style={{ justifyContent: "space-between" }}
      >
        <div>
          {/* {inventoryReadValue === "N" && (
            <Button
              variant="outlined"
              className={classes.leftButton}
              style={{ marginRight: "12px" }}
              onClick={addProcedure}
            >
              Add Service Code
            </Button>
          )} */}
        </div>
        <div>
          <Button
            variant="outlined"
            size="large"
            className={classes.leftButton}
            style={{ marginRight: "12px" }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          {inventoryReadValue === "N" && (
            <>
              <Button
                variant="contained"
                color="secondary"
                className={classes.rightButton}
                onClick={handleSaveAndExit}
                style={{ marginRight: "12px" }}
              >
                save & Exit
              </Button>
              {!selectedRow && (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.rightButton}
                  onClick={handleSaveAndMore}
                >
                  save & More
                </Button>
              )}
            </>
          )}
        </div>
      </div>

      
        <>
          <Paper className={classes.iconsContainer}>
            <Tabs TabIndicatorProps={{
            style: { background: "#4E97FD",width:"150px"},
          }}
             
              
              variant="scrollable"
              scrollButtons="auto"
              value={activeTabId}
              onChange={(e, id) => setActiveTabId(id)}
              className={classes.iconsBar}
            >
              <Tab label="Configurations" classes={{ root: classes.tab }} />
              {selectedRow && (
                <>
              <Tab label="Locations" classes={{ root: classes.tab }} />
              <Tab label="Transactions" classes={{ root: classes.tab }} />
              {/* <Tab label="Materials List" classes={{ root: classes.tab }} /> */}
              </>
              )}
              
            </Tabs>
          </Paper>
          {activeTabId === 0 && <FullFeaturedCrudGrid />}
          {/* {activeTabId === 0 && <SubContactList />} */}
          {activeTabId === 1 && <LocationList />}
          {activeTabId === 2 && <TransactionList />}
          {activeTabId === 3 && <MaterialsList />}
        </>
     
    </>
  );
}
