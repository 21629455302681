import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  Typography,
  InputBase,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import * as Icons from "@material-ui/icons";
import {
  MoreVert as MoreIcon,
  Search as SearchIcon,
  Lens,
} from "@material-ui/icons";
import TableWidget from "../Widget/TableWidget";
import TableDesign from "../common/table/TableDesign";
import useStyles from "../Settings/Tax/styles";

import { getinvtFrame, getFrameFilter } from "../../services/ServiceLayer";
import { loadState } from "../../hooks/helper";
import classNames from "classnames";
import moment from "moment";
import DeleteFramePopup from "./DeleteFramePopup";
import { MaterialReactTable, type MRT_ColumnDef } from "material-react-table";
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";
export default function FrameList() {
  const history = useHistory();
  var classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [isLoader, setIsLoader] = useState(false);

  const [frameListData, setFrameListData] = useState([]);
  const [frameFilterListData, setFrameFilterListData] = useState([]);
  const [anchorPosition, setAnchorPosition] = useState(null);
  const openDown = Boolean(anchorEl);
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [selectclient, setselectclient] = useState(null);
  const [title, setTitle] = useState(null);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [selectRow, setSelectRow] = useState(null);
  const [inventoryReadValue, setInventoryReadValue] = useState();
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  var [isSearchOpen, setSearchOpen] = useState(true);
  const [statusFilterManufacture, setStatusFilterManufacture] = useState("");
  const [statusFilterModel, setStatusFilterModel] = useState("");
  const [statusFilterBrand, setStatusFilterBrand] = useState("");
  // filter
  const [selectBrand, setSelectBrand] = useState("");
  // filter
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const readAccessData = loadState().menu;
  useEffect(() => {
    console.log("readAccessData", readAccessData);
    const inventorySection = readAccessData.find(
      (item) => item.label === "Inventory"
    );
    if (inventorySection) {
      const inventoryChildren = inventorySection.children;

      const inventoryReadAcess = inventoryChildren.find(
        (child) => child.label === "Inventory"
      ).read_access;
      setInventoryReadValue(inventoryReadAcess);
    }
  }, [readAccessData]);
  const deleteEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);

    setselectclient(rec);
    setDeleteModalShow(true);
    setTitle("Delete Frame");
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    getFrameFilter(
      `location_id=${userData?.location_id}&user_role=${userRole}&frame_brand=${selectBrand}&frame_model=${selectBrand}&frame_id=${selectBrand}&frame_name=${selectBrand}&frame_number=${selectBrand}&frametype_id=${selectBrand}&frametype_val=${selectBrand}&sku=${selectBrand}&frame_color_code=${selectBrand}&collection=${selectBrand}&frame_color=${selectBrand}&material_val=${selectBrand}&rim_type_id=${selectBrand}&rim_type_val=${selectBrand}&frame_shape_id=${selectBrand}&frame_shape_val=${selectBrand}&gender=${selectBrand}&structure_id=${selectBrand}&structure_type=${selectBrand}&is_commission_prod=${selectBrand}&manufacturer_name=${selectBrand}&manufacturer_type=${selectBrand}&supplier_id=${selectBrand}&supplier_name=${selectBrand}&manufacturer_id=${selectBrand}&opt_type=SELFRM`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setIsLoader(false);
        setFrameFilterListData(response?.data);
        console.log(frameFilterListData,"frameFilterListData");
      } else {
        setIsLoader(false);
      }
    });
    getinvtFrame(
      `location_id=${userData?.location_id}&user_role=${userRole}&frame_brand_val=${selectBrand}&frame_model_name=${selectBrand}&frame_id=${selectBrand}&frame_name=${selectBrand}&frame_number=${selectBrand}&frametype_id=${selectBrand}&frametype_val=${selectBrand}&sku=${selectBrand}&frame_color_code=${selectBrand}&collection=${selectBrand}&frame_color=${selectBrand}&material_val=${selectBrand}&rim_type_id=${selectBrand}&rim_type_val=${selectBrand}&frame_shape_id=${selectBrand}&frame_shape_val=${selectBrand}&gender=${selectBrand}&structure_id=${selectBrand}&structure_type=${selectBrand}&is_commission_prod=${selectBrand}&manufacturer_name=${selectBrand}&manufacturer_type=${selectBrand}&supplier_id=${selectBrand}&supplier_name=${selectBrand}&manufacturer_id=${selectBrand}&opt_type=SELFRM`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setIsLoader(false);
        setFrameListData(response?.data);
      } else {
        setIsLoader(false);
      }
    });
  }, []);
  useEffect(() => {
    console.log("statusFilterBrand",statusFilterBrand);
    console.log("statusFilterManufacture",statusFilterManufacture);
    getinvtFrame(
      `location_id=${userData?.location_id}&user_role=${userRole}&frame_brand=${
        statusFilterBrand ? statusFilterBrand : ""
      }&manufacturer_id=${
        statusFilterManufacture ? statusFilterManufacture : ""
      }&frame_model=${statusFilterModel ? statusFilterModel : ''}&opt_type=SELFRM`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setIsLoader(false);
        setFrameListData(response?.data);
      } else {
        setIsLoader(false);
      }
    });
    setDataUpdated(false);
  }, [dataUpdated, statusFilterBrand,statusFilterManufacture,statusFilterModel]);
  const handleTopClick = (event) => {
    setAnchorPosition({
      top: event.clientY,
      left: event.clientX,
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorE2(null);
    setAnchorPosition(null);
  };
  const handleView = () => {
    // Handle View
    // setAnchorPosition(null);
    history.push("/app/manage-frame");
  };

  const handleViewModify = () => {
    // Handle View Modify
    setAnchorPosition(null);
    history.push("/app/frame-modify");
  };

  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Name ",
      // align: "left !important",
      secondaryLabel: "(SKU)",
    },
    {
      label: "Collection ",
      secondaryLabel: "(Brand)",
    },
    {
      label: "Model ",
      secondaryLabel: "(Manufacturer)",
    },
    {
      label: "Color ",
      secondaryLabel: "(Color Code)",
    },
    {
      label: "Frame Type ",
      secondaryLabel: "(Rim Type)",
    },
    {
      label: "Shape ",
      secondaryLabel: "(Material)",
    },
    {
      label: "Frame Usage ",
      secondaryLabel: "(Bridge)",
    },
    {
      label: "Source ",
      secondaryLabel: "(Return Allowed)",
    },
    {
      label: "Retail Price ",
      // secondaryLabel: "(Item Cost)",
    },
    // {
    //   label: "On Hand ",
    //   secondaryLabel: "(Recall Date)",
    // },
    {
      label: "Arrival Date ",
      // secondaryLabel: "(Best Seller)",
    },

    // {
    //   label: "Discount ",
    //   secondaryLabel: "(Discount Untill)",
    // },
    {
      label: "Status",
    },
    {
      label: "Action",
    },
  ];
  const handleRowClick = (row) => {
    setSelectedRow(row);
  };

  // Create a function to navigate to the edit page with selected row data
  const handleEditClick = (event) => {
    if (selectedRow) {
      console.log("selectedRow", selectedRow);
      // Use React Router to navigate to the edit page
      history.push(`/app/frame-details/${selectedRow.frame_id}`, {
        selectedRow,
        event,
      });
    }
  };
  const hideHandle = () => {
    console.log("hidehandle called");

    setOpen(false);

    setDataUpdated(true);
  };
  //searching
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredFrameList = frameListData.filter((item) => {
    // Check if each property is not null or undefined before using toLowerCase()
    const frameName = item.frame_name ? item.frame_name.toLowerCase() : "";
    const frameBrand = item.frame_brand_val ? item.frame_brand_val.toLowerCase() : "";
    const frameModel = item.frame_model_name ? item.frame_model_name.toLowerCase() : "";
    const frameType = item.frame_type_val ? item.frame_type_val.toLowerCase() :"";
    const manufacturerName = item.manufacturer_name
      ? item.manufacturer_name.toLowerCase()
      : "";

    return (
      frameName.includes(searchTerm.toLowerCase()) ||
      frameBrand.includes(searchTerm.toLowerCase()) ||
      frameModel.includes(searchTerm.toLowerCase()) ||
      frameType.includes(searchTerm.toLocaleLowerCase())||
      manufacturerName.includes(searchTerm.toLowerCase())
    );
  });
  const [statusFilter, setStatusFilter] = useState("");
  // Filtering function
  const filterData = () => {
    let filteredData = frameListData;

    if (statusFilter) {
      // Apply status filter if a filter is selected
      filteredData = filteredData.filter(
        (item) => item.is_active.toLowerCase() === statusFilter.toLowerCase()
      );
    }
    if (statusFilterManufacture) {
      // Apply Model No. filter if a value is selected
      filteredData = filteredData.filter(
        (item) =>
          item.frame_name.toLowerCase() === statusFilterManufacture.toLowerCase()
      );
    }

    if (statusFilterModel) {
      console.log("statusFilterModel",statusFilterModel);
      // Apply Model filter if a value is selected
      filteredData = filteredData.filter(
        (item) =>
          item.frame_model_name.toLowerCase() ===
          statusFilterModel.toLowerCase()
      );
    }

    if (statusFilterBrand) {
      // Apply Brand filter if a value is selected
      filteredData = filteredData.filter(
        (item) =>
          item.brand_id.toLowerCase() === statusFilterBrand.toLowerCase()
      );
    }
    console.log("Filtered Data:", filteredData);
    // Return the filtered data
    return filteredData;
  };
  useEffect(() => {
    const filteredData = filterData();
    setFrameListData(filteredData);
  }, [statusFilterBrand, statusFilterModel, statusFilterManufacture]);

  // Assuming frameListData is an array of frame objects
  const uniqueModelNos = [
    ...new Set(frameFilterListData.map((item) => item)),
  ];
  const uniqueModels = [
    ...new Set(frameFilterListData.map((item) => item)),
  ];
  const uniqueBrands = [
    ...new Set(frameFilterListData.map((item) => item)),
  ];
  useEffect(() => {
    console.log("uniqueBrands", uniqueBrands);
  }, [uniqueBrands]);
  return (
    <>
      <DeleteFramePopup
        onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        selectclient={selectclient}
        setDeleteModalShow={setDeleteModalShow}
      />
      <div className={classes.pageTitleContainer}>
        <Typography className={classes.typo} weight="medium">
          Frame List
        </Typography>
      </div>

      <div
        className={classes.pageTitleContainer}
        style={{ alignItems: "center" }}
      >
        <Typography className={classes.typo} weight="medium">
          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
            <div
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
              onClick={() => setSearchOpen(!isSearchOpen)}
            >
              <SearchIcon classes={{ root: classes.headerIcon }} />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onChange={handleChange}
            />
          </div>
        </Typography>
        <div>
          <label style={{ marginRight: "4px" }}>Manufacturer:</label>
          <select
            style={{ height: "26px", borderRadius: "5px", width: "96px" }}
            value={statusFilterManufacture}
            onChange={(e) => setStatusFilterManufacture(e.target.value)}
          >
            <option value="">All</option>
            {uniqueModelNos.map((modelNo, i) => (
              <option key={i} value={modelNo?.manufacturer_id}>
                {modelNo?.manufacturer_name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label style={{ marginRight: "4px" }}>Model:</label>
          <select
            style={{ height: "26px", borderRadius: "5px", width: "96px" }}
            value={statusFilterModel}
            onChange={(e) => setStatusFilterModel(e.target.value)}
          >
            <option value="">All</option>
            {uniqueModels.map((model, i) => (
              <option key={i} value={model?.frame_model_name}>
                {model?.frame_model_name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label style={{ marginRight: "4px" }}>Brand:</label>
          <select
            style={{ height: "26px", borderRadius: "5px", width: "96px" }}
            value={statusFilterBrand}
            onChange={(e) => setStatusFilterBrand(e.target.value)}
          >
            <option value="">All</option>
            {uniqueBrands.map((brand, i) => (
              <option key={i} value={brand?.brand_id}>
                {brand?.frame_brand_val}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.groupBtn}>
          <Tooltip title="Print">
            <Icons.LocalPrintshop />
          </Tooltip>
          <CSVLink
            data={filteredFrameList}
            filename="frame_data.csv"
            className={classes.menuItem}
          >
            <Tooltip title="Download">
              <Icons.Archive />
            </Tooltip>
          </CSVLink>
          <Tooltip title="Mail">
            <Icons.Mail />
          </Tooltip>
          {inventoryReadValue === "N" && (
            <div>
              <IconButton onClick={handleTopClick}>
                <MoreIcon />
              </IconButton>
              <Menu
                className={classes.menuBox}
                anchorReference="anchorPosition"
                anchorPosition={anchorPosition}
                open={Boolean(anchorPosition)}
                onClose={handleClose}
              >
                <MenuItem className={classes.menuItem} onClick={handleView}>
                  <Lens
                    style={{
                      height: "4px",
                      width: "4px",
                      marginRight: "4px",
                    }}
                  />{" "}
                  Add New
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  onClick={handleViewModify}
                >
                  <Lens
                    style={{
                      height: "4px",
                      width: "4px",
                      marginRight: "4px",
                    }}
                  />{" "}
                  Import From File
                </MenuItem>
              </Menu>
            </div>
          )}
        </div>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {/* <MaterialReactTable columns={tableHeadings} data={filteredFrameList} /> */}
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {frameFilterListData && frameFilterListData.length ? (
                filteredFrameList.map((row, i) => {
                  if (row)
                    return (
                      <TableRow
                        key={i}
                        className={classes.tableBodyCell}
                        onClick={() => handleRowClick(row)}
                      >
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                          style={{ cursor: "pointer" }}
                        >
                          {row.frame_name}
                          <br />
                          {row.frame_sku && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.frame_sku})
                            </span>
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {row.collection}
                          <br />
                          {row.frame_brand_val && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.frame_brand_val})
                            </span>
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {row.frame_model_name}
                          <br />
                          {row.manufacturer_name && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.manufacturer_name})
                            </span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.frame_color_val}
                          <br />
                          {row.frame_color_code && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.frame_color_code})
                            </span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.frame_type_val}
                          <br />
                          {row.rim_type_val && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.rim_type_val})
                            </span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.frame_shape_val}
                          <br />
                          {row.material_val && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.material_val})
                            </span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.usage}
                          <br />
                          {row.bridge && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.bridge})
                            </span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.inv_source_val}
                          <br />
                          {row.returnable_val && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.returnable_val})
                            </span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.retail_price}
                          {/* <br />
                          {row.item_cost && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.item_cost})
                            </span>
                          )} */}
                        </TableCell>
                        {/* <TableCell className={classes.tableBodyCell}>
                          {row.availability}
                          <br />
                          {row.recall_date && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.recall_date})
                            </span>
                          )}
                        </TableCell> */}
                        <TableCell className={classes.tableBodyCell}>
                          {moment(row.created).format("DD-MM-YYYY")}
                          {/* <br />
                          {row.best_seller && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.best_seller})
                            </span>
                          )} */}
                        </TableCell>

                        {/* <TableCell className={classes.tableBodyCell}>
                          {row.discount_amt}
                          <br />
                          {row.discount_until && (
                            <span style={{ color: "#4E97FD" }}>
                              ({moment(row.discount_until).format("DD-MM-YYYY")}
                              )
                            </span>
                          )}
                        </TableCell> */}
                        <TableCell className={classes.tableBodyCell}>
                          {row.is_active === "Y" ? (
                            <span style={{ color: "green" }}>Active</span>
                          ) : (
                            <span style={{ color: "red" }}>Inactive</span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => handleEditClick("View/Update")}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              {inventoryReadValue === "N" ? (
                                <>View/Update</>
                              ) : (
                                <>View Details</>
                              )}
                            </MenuItem>
                            {inventoryReadValue === "N" && (
                              <>
                                <MenuItem
                                  onClick={() => deleteEventHandler(selectRow)}
                                  className={classes.menuItem}
                                >
                                  <Lens
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                      marginRight: "4px",
                                    }}
                                  />{" "}
                                  {selectRow && selectRow.is_active === "Y" ? (
                                    <>Delete</>
                                  ) : (
                                    <>Re-activate</>
                                  )}
                                </MenuItem>
                                <MenuItem
                                  onClick={() => handleEditClick("Copy")}
                                  className={classes.menuItem}
                                >
                                  <Lens
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                      marginRight: "4px",
                                    }}
                                  />{" "}
                                  Copy
                                </MenuItem>

                                <MenuItem className={classes.menuItem}>
                                  <Lens
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                      marginRight: "4px",
                                    }}
                                  />{" "}
                                  Invoice
                                </MenuItem>

                                <MenuItem className={classes.menuItem}>
                                  <Lens
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                      marginRight: "4px",
                                    }}
                                  />{" "}
                                  Receiving
                                </MenuItem>
                                <MenuItem className={classes.menuItem}>
                                  <Lens
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                      marginRight: "4px",
                                    }}
                                  />{" "}
                                  Returns
                                </MenuItem>
                              </>
                            )}
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
