import React from 'react'
import PatientList from '../../components/Patient/PatientList'

export default function Patient() {
  return (
    <div>
        <PatientList/>
    </div>
  )
}
