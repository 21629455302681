import React from "react";
import DoctorList from "../../components/Settings/Doctor/DoctorList";

export default function Client() {
  return (
    <div>
      <DoctorList />
    </div>
  );
}
