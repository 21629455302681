export const manageExamSchema = {
  allergy_from: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter allergy from",
    },
  },
 
  st_date: {
    type: "string",
    required: true,
    properties: {
        empty: "Please enter start date"
    }
},
end_date: {
  type: "string",
  required: true,
  properties: {
      empty: "Please enter end date"
  }
},
dis_date:{
  type: "string",
  required: true,
  properties: {
      empty: "Please enter disclosure date"
  }
},
occul_purchase_glass: {
  type: "string",
  required: true,
  properties: {
      empty: "Please select date"
  }
},
occul_cont_purchase_lense: {
type: "string",
required: true,
properties: {
    empty: "Please select date"
}
},
  corp_phone: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter phone number",
      number: "Please enter number only",
      minLength: {
        limit: 10,
        msg: "Number must be minimum 20 length",
      },
      maxLength: {
        limit: 10,
        msg: "Number must be maximum 20 length",
      },
    },
  },
  phone: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter number",
      number: "Please enter number only",
      minLength: {
        limit: 10,
        msg: "Number must be minimum 10 length",
      },
      maxLength: {
        limit: 10,
        msg: "Number must be maximum 10 length",
      },
    },
  },

  email_id: {
    type: "email",
    required: true,
    properties: {
      empty: "Please enter email",
      email: "Please enter a valid Email",
    },
  },

  name: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter plan name",
    },
  },

  s3Key: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select a file",
    },
  },
  file: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select a file",
    },
  },
};
