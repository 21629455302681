import React, { useState, useEffect } from "react";

// Model
import { Button, Typography, DialogContent,DialogContentText } from "@material-ui/core";
import Popup from "../../common/modal/Popup";
import alertIcon from "./alertIcon.svg";
// styles
import useStyles from "./styles";
import { toast } from "react-toastify";
import { postCompanyData } from "../../../services/ServiceLayer";
import { currentUser } from "../../../hooks/helper";
export default function DeletePopup({
  onHide,
  deleteModalShow,
  setDeleteModalShow,
  selectclient,
}) {
  console.log("selectclient", selectclient);
  console.log("deleteModalShow", deleteModalShow);

  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const [actionType, setActionType] = useState("DELCMP");

  const [isActionCalled, setIsActionCalled] = useState(false);
  toast.configure();
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setDeleteModalShow(false);
    setOpen(false);
  };
  const [companyDetail, setCompanyDetail] = useState({
    store_id: 0,
    comp_id: 0,
    comp_name: "",
    comp_alias: "",
    tax_id: "",
    tax_id2: "",
    corp_phone: "",
    phone: "",
    fax: 0,
    email_id: "",
    website: "",
    social_id_1: "",
    social_id_2: "",
    social_id_3: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: 0,
    country: "",
    is_company: "Y",
    is_active: "",
    updated_by: currentUser(),
    access_location: "",
    opt_type: actionType,
  });
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setCompanyDetail({
        store_id: 0,
        comp_id: 0,
        comp_name: "",
        comp_alias: "",
        tax_id: "",
        tax_id2: "",
        corp_phone: "",
        phone: "",
        fax: 0,
        email_id: "",
        website: "",
        social_id_1: "",
        social_id_2: "",
        social_id_3: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: 0,
        country: "",
        is_company: "Y",
        is_active: "Y",
        updated_by: currentUser(),
        access_location: "",
        opt_type: "DELCPM",
      });
    } else {
      setCompanyDetail({
        store_id: selectclient !== null ? selectclient.store_id : 0,
        comp_id: selectclient !== null ? selectclient.comp_id : 0,
        comp_name: selectclient !== null ? selectclient.comp_name : "",
        comp_alias: selectclient !== null ? selectclient.comp_alias : "",
        tax_id: selectclient !== null ? selectclient.tax_id : "",
        tax_id2: selectclient !== null ? selectclient.tax_id2 : "",
        corp_phone: selectclient !== null ? selectclient.corp_phone : "",
        phone: selectclient !== null ? selectclient.phone : "",
        fax: selectclient !== null ? selectclient.fax : "",
        email_id: selectclient !== null ? selectclient.email_id : "",
        website: selectclient !== null ? selectclient.website : "",
        social_id_1: selectclient !== null ? selectclient.social_id_1 : "",
        social_id_2: selectclient !== null ? selectclient.social_id_2 : "",
        social_id_3: selectclient !== null ? selectclient.social_id_3 : "",
        address1: selectclient !== null ? selectclient.address1 : "",
        address2: selectclient !== null ? selectclient.address2 : "",
        city: selectclient !== null ? selectclient.city : "",
        state: selectclient !== null ? selectclient.state : "",
        zip: selectclient !== null ? selectclient.zip : "",
        country: selectclient !== null ? selectclient.country : "",
        is_company: "N",
        is_active: "Y",
        updated_by: currentUser(),
        access_location: "1.1.1.1",
        opt_type: actionType,
      });
    }
  }, [selectclient]);

  const deleteFormData = () => {
    setIsActionCalled(true);
    setCompanyDetail({
      ...companyDetail,
      opt_type: "DELCMP",
    });
  };

  useEffect(() => {
    console.log("isActionCalled", isActionCalled);

    if (isActionCalled) {
      postCompanyData(companyDetail).then((response) => {
        console.log("actionType", actionType);

        console.log(response);
        if (response.status.status) {
          toast.success("location deleted successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });

          onHide();
          setDeleteModalShow(false);
          setOpen(false);
          setIsActionCalled(false);
        } else {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActionCalled(false);
        }
      });
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  return (
    <>
      <Popup
        onHide={hideHandler}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={deleteFormData}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <img src={alertIcon} />
            <Typography className={classes.formActiveHeading}>
              Are you sure! You want to Delete this location?
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
