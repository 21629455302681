import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Button,
} from "@material-ui/core";

import moment from "moment";
import { formGuardianElements } from "./Patient_Constant";
import PatientSearchResult from "../../../../hooks/PatientSearch";
import PatientSearchAgain from "../../../../hooks/PatientSearchAgain";

import useStyles from "../../Detail/Case/OtherTab/styles";
import { ssnFormat } from "../../../../hooks/helper";

export default function Gaurdian({
  patientOtherData,
  setPatientOtherData,
  relTypeValues,
  genderValues,
}) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();

  const [patientDataTwo, setPatientDataTwo] = useState(null);

  const [open, setOpen] = React.useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [open1, setOpen1] = React.useState(false);

  const [modalShow1, setModalShow1] = useState(false);
  const [title, setTitle] = useState(null);
  const selectedPatient = (data) => {
    setOpen(false);
    // setPatientData(data[0]);
    // setPatientOtherData(data[0]);
    if (data) {
      setPatientOtherData({
        guardian_relationship: "",
        guardian_first_name: data[0]?.first_name,
        guardian_middle_name: data[0]?.middle_name,
        guardian_last_name: data[0]?.last_name,
        guardian_suffix: data[0]?.suffix,
        guardian_dob: data[0]?.dateofbirth,
        guardian_gender: data[0]?.gender,
        guardian_con_type: data[0]?.contact_type,
        guardian_email: data[0]?.email,
        guardian_thanks_note: data[0]?.notes,
        guardian_address_line1: data[0]?.billing_address_line1,
        guardian_address_line2: data[0]?.billing_address_line2,
        guardian_city: data[0]?.billing_city,
        guardian_state: data[0]?.billing_state,
        guardian_country: "USA",
        guardian_zip: data[0]?.billing_zip,
        guardian_con: data[0]?.contact_Cell,
        guardian_home_con: data[0]?.contact_home,
        guardian_office_con: data[0]?.contact_office,
      });
    }
  };
  const selectedPatientTwo = (data) => {
    setOpen1(false);
    if(data){

    
    setPatientDataTwo(data[0]);
    console.log("data[0]:-", data[0]);

    setPatientOtherData({
      guardian1_relationship: "",
      guardian1_first_name: data[0]?.first_name,
      guardian1_middle_name: data[0]?.middle_name,
      guardian1_last_name: data[0]?.last_name,
      guardian1_suffix: data[0]?.suffix,
      guardian1_dob: data[0]?.dateofbirth,
      guardian1_gender: data[0]?.gender,
      guardian1_con_type: data[0]?.contact_type,
      guardian1_email: data[0]?.email,
      guardian1_thanks_note: data[0]?.notes,
      guardian1_address_line1: data[0]?.billing_address_line1,
      guardian1_address_line2: data[0]?.billing_address_line2,
      guardian1_city: data[0]?.billing_city,
      guardian1_state: data[0]?.billing_state,
      guardian1_country: "USA",
      guardian1_zip: data[0]?.billing_zip,
      guardian1_con: data[0]?.contact_Cell,
      guardian1_home_con: data[0]?.contact_home,
      guardian1_office_con: data[0]?.contact_office,
    });
  }
  };

  const searchGuardian = () => {
    setOpen(true);
    setTitle("Search Existing patient");
    setModalShow(true);
  };
  const searchGuardian2 = () => {
    setOpen1(true);
    setTitle("Search Existing patient");
    setModalShow1(true);
  };
  return (
    <>
      <PatientSearchResult
        open={open}
        onHide={(e) => {
          selectedPatient(e);
        }}
        title={title}
        setModalShow={setModalShow}
      />
      <PatientSearchAgain
        open={open1}
        onHide={(e) => {
          selectedPatientTwo(e);
        }}
        title={title}
        setModalShow={setModalShow1}
      />
      <Grid container spacing={2} className={classes.formBox}>
        {formGuardianElements.map((element, i) => {
          return (
            <>
              <Grid
                item
                xs={12}
                md={element.mdSize}
                className={classes.textGroup}
              >
                <Typography className={classes.formHeading}>
                  {element.label}{" "}
                  {element.required === "true" ? (
                    <span className={classes.required}>*</span>
                  ) : null}
                </Typography>

                {element.type === "select" ? (
                  <FormControl
                    fullWidth
                    className={classes.dropdownFormControl}
                  >
                    <Select
                      name={element.key}
                      className={classes.selectDropdown}
                      placeholder={element.placeHolder}
                      value={
                        patientOtherData &&
                        patientOtherData[element.key] === null
                          ? ""
                          : patientOtherData && patientOtherData[element.key]
                      }
                      onChange={async (e) => {
                        setPatientOtherData({
                          ...patientOtherData,
                          [element.key]: e.target.value,
                        });
                      }}
                    >
                      <MenuItem disabled value="">
                        Select
                      </MenuItem>
                      {(element.key == "guardian_relationship" &&
                        relTypeValues) ||
                      (element.key == "guardian1_relationship" && relTypeValues)
                        ? relTypeValues.map((item) => (
                            <MenuItem
                              key={item.attribute_id}
                              value={item.attribute_id}
                            >
                              {item.attribute_val}
                            </MenuItem>
                          ))
                        : (element.key == "guardian_gender" && genderValues) ||
                          (element.key == "guardian1_gender" && genderValues)
                        ? genderValues.map((item) => (
                            <MenuItem
                              key={item.attribute_id}
                              value={item.attribute_id}
                            >
                              {item.attribute_val}
                            </MenuItem>
                          ))
                        : (element.key == "guardian_state" &&
                            element.options) ||
                          (element.key == "guardian1_state" && element.options)
                        ? element.options.map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                ) : element.type === "textarea" ? (
                  <TextField
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    placeholder="Type here..."
                    variant="outlined"
                    multiline
                    rows={3}
                    inputProps={{
                      style: {
                        fontSize: "12px",
                      },
                      maxLength: `${element.maxLength}`,
                    }}
                    value={
                      patientOtherData && patientOtherData[element.key] === null
                        ? ""
                        : patientOtherData && patientOtherData[element.key]
                    }
                    onChange={async (e) => {
                      setPatientOtherData({
                        ...patientOtherData,
                        [element.key]: e.target.value,
                      });
                    }}
                  />
                ) : element.type === "date" ||
                  element.type === "datetime-local" ? (
                  <TextField
                    id={element.key}
                    name={element.key}
                    placeholder={element.placeHolder}
                    type={element.type}
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      min: `${currentDateTime}`,
                      max: `${currentDate}`,
                      style: {
                        height: "12px",
                        fontSize: "12px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      patientOtherData && patientOtherData[element.key] === null
                        ? ""
                        : moment(
                            patientOtherData && patientOtherData[element.key]
                          ).format("YYYY-MM-DD")
                    }
                    
                    onChange={async (e) => {
                      setPatientOtherData({
                        ...patientOtherData,
                        [element.key]: e.target.value,
                      });
                    }}
                    onKeyDown={(e) => {
                      e.preventDefault(); // Prevent manual typing
                    }}
                  />
                ) : element.type === "label" ? (
                  <span style={{ borderBottom: "2px solid #333333" }}></span>
                ) : element.type === "button" ? (
                  <Button
                    classes={{ root: classes.button }}
                    variant="contained"
                    color="secondary"
                    onClick={
                      element.value === "btn1"
                        ? searchGuardian
                        : searchGuardian2
                    }
                    style={{
                      width: "100%",
                      fontSize: "10px",
                      marginTop: "26px",
                    }}
                  >
                    {element.btnName}
                  </Button>
                ) : (
                  <TextField
                    required
                    name={element.key}
                    placeholder={element.placeHolder}
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    type={element.type}
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "12px",
                        fontSize: "12px",
                      },
                      maxLength: element.maxLength,
                      ...((element.key === "guardian_ssn" || element.key==="guardian1_ssn") && {
                        onInput: (e) => {
                          e.target.value = ssnFormat(e.target.value);
                        },
                      }),
                      onKeyPress: element.onKeyPress, // Add the event handler
                    }}
                    value={
                      patientOtherData && patientOtherData[element.key] === null
                        ? ""
                        : patientOtherData && patientOtherData[element.key]
                    }
                    onChange={async (e) => {
                      setPatientOtherData({
                        ...patientOtherData,
                        [element.key]: e.target.value,
                      });
                    }}
                  />
                )}
              </Grid>
            </>
          );
        })}
      </Grid>
    </>
  );
}
