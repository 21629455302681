import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  Fade,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../common/modal/Popup";
// styles
import useStyles from "./styles";
import moment from "moment";
import { toast } from "react-toastify";

import CompanyValidation from "../../../hooks/CompanyValidation";
import { postCompanyData } from "../../../services/ServiceLayer";
import {
  handleKeyPress,
  handleKeyPressPaste,
  handleAlphNumKeyPress,
  handleAlphNumKeyPressPaste,
  handleAlphNumberKeyPress,
  handleAlphNumberKeyPressPaste,
  postFlagBooleanVariable,
  loadState,
  currentUser,
  handleAlphNumSpaceKeyPress,handleAlphNumSpaceKeyPressPaste,
  handleAddressKeyPress,handleAddressKeyPressPaste
} from "../../../hooks/helper";

export default function ManageCompanyLocation(props) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  //

  const formElements = [
    {
      key: "comp_name",
      label: "Location Name",
      placeHolder: "Enter Company Name",
      type: "text",
      mdSize: "6",
      required: "true",
      maxLength: "30",
      onKeyPress: handleAlphNumSpaceKeyPress,
    },
    {
      key: "comp_alias",
      label: "Location Alias",
      placeHolder: "Enter Company Alias",
      type: "text",
      mdSize: "3",
      required: "true",
      maxLength: "30",
      onKeyPress: handleAlphNumSpaceKeyPress,
    },

    {
      key: "tax_id",
      label: "Tax ID",
      placeHolder: "Enter Tax Id",
      type: "text",
      mdSize: "3",
      required: "true",
      maxLength: "20",
    },
    {
      key: "tax_id2",
      label: "Tax ID-1",
      placeHolder: "Enter Tax Id1",
      type: "text",
      mdSize: "3",
      required: "true",
      maxLength: "20",
    },
    {
      key: "corp_phone",
      label: "Corp Phone",
      placeHolder: "Enter Phone Cell",
      type: "text",
      mdSize: "3",

      onKeyPress: handleKeyPress,
      maxLength: "10",
    },
    {
      key: "phone",
      label: "Phone",
      placeHolder: "Enter Phone",
      type: "text",
      mdSize: "3",
      required: "true",
      onKeyPress: handleKeyPress,
      maxLength: "10",
    },
    {
      key: "fax",
      label: "Fax",
      placeHolder: "Enter Fax",
      type: "text",
      mdSize: "3",
      maxLength: "10",
      onKeyPress: handleKeyPress,
      onPaste:handleKeyPressPaste,

    },
    {
      key: "email_id",
      label: "Email",
      placeHolder: "Enter Email",
      type: "text",
      mdSize: "3",
      required: "true",
    },
    {
      key: "website",
      label: "Website",
      placeHolder: "Enter website",
      type: "text",
      mdSize: "3",
    },

    {
      key: "social_id_1",
      label: "Social ID (Linkedin)",
      placeHolder: "Enter Social Id",
      type: "text",
      mdSize: "3",
    },
    {
      key: "social_id_2",
      label: "Social ID (Twitter)",
      placeHolder: "Enter Social Id",
      type: "text",
      mdSize: "3",
      onKeyPress: handleAddressKeyPress,
      maxLength: "50",
    },
    {
      key: "social_id_3",
      label: "Social ID (Facebook)",
      placeHolder: "Enter Social Id",
      type: "text",
      mdSize: "3",
      onKeyPress: handleAddressKeyPress,
      maxLength: "50",
    },
    {
      key: "address1",
      label: "Address 1",
      placeHolder: "Enter Address",
      type: "text",
      mdSize: "3",
      onKeyPress: handleAddressKeyPress,
      maxLength: "50",
    },
    {
      key: "address2",
      label: "Address 2",
      placeHolder: "Enter Address",
      type: "text",
      mdSize: "3",
      onKeyPress: handleAddressKeyPress,
      maxLength: "50",
    },
    {
      key: "city",
      label: "City",
      placeHolder: "Enter city",
      type: "text",
      mdSize: "3",
      onKeyPress: handleAddressKeyPress,
      maxLength: "50",
      onPaste:handleAddressKeyPressPaste,
    },
    {
      key: "state",
      label: "State",
      placeHolder: "Enter State",
      type: "select",
      options: [
        "AK",
        "AL",
        "AR",
        "AS",
        "AZ",
        "CA",
        "CO",
        "CT",
        "DC",
        "DE",
        "FL",
        "GA",
        "GU",
        "HI",
        "IA",
        "ID",
        "IL",
        "IN",
        "KS",
        "KY",
        "LA",
        "MA",
        "MD",
        "ME",
        "MI",
        "MN",
        "MO",
        "MS",
        "MT",
        "NC",
        "ND",
        "NE",
        "NH",
        "NJ",
        "NM",
        "NV",
        "NY",
        "OH",
        "OK",
        "OR",
        "PA",
        "PR",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VA",
        "VI",
        "VT",
        "WA",
        "WI",
        "WV",
        "WY",
      ],
      mdSize: "3",
    },
    {
      key: "zip",
      label: "Zip",
      placeHolder: "Enter Zip",
      type: "text",
      mdSize: "3",
      onKeyPress: handleKeyPress,
      maxLength: "5",
    },
    {
      key: "country",
      label: "Country",
      placeHolder: "Enter country",
      type: "text",
      mdSize: "3",
      maxLength: "50",
      onKeyPress: handleAlphNumKeyPress,
    },
  ];
  console.log("props-24", props);
  const { selectclient } = props;
  console.log("selectclient", selectclient);

  var classes = useStyles();
  toast.configure();
  const [actionType, setActionType] = useState("ADDCMP");
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [isActionCalled, setIsActionCalled] = useState(false);

  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
    resetAllValidator();
    setIsActionCalled(false);
  };

  const [companyDetail, setCompanyDetail] = useState({
    store_id: 0,
    comp_id: selectclient?.comp_id,
    comp_name: "",
    comp_alias: "",
    tax_id: "",
    tax_id2: "",
    corp_phone: "",
    phone: "",
    fax: 0,
    email_id: "",
    website: "",
    social_id_1: "",
    social_id_2: "",
    social_id_3: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: 0,
    country: "",
    is_company: "N",
    is_active: "",
    updated_by: currentUser(),
    access_location: "",
    opt_type: "ADDCMP",
  });
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    CompanyValidation({
      comp_name: companyDetail?.comp_name,

      comp_alias: companyDetail?.comp_alias,
      tax_id: companyDetail?.tax_id,
      tax_id2: companyDetail?.tax_id2,

      phone: companyDetail?.phone,
      email_id: companyDetail?.email_id,
    });
  const submitFormData = () => {
    checkValidator();
    setIsActionCalled(true);
    setCompanyDetail({
      ...companyDetail,
      opt_type: "ADDCMP",
    });
  };
  const updateFormData = () => {
    checkValidator();
    setIsActionCalled(true);
    setActionType("MODSTF");
    setCompanyDetail({
      ...companyDetail,
      opt_type: "MODCMP",
    });
  };

  const deleteFormData = () => {
    checkValidator();
    setIsActionCalled(true);
    setCompanyDetail({
      ...companyDetail,
      opt_type: "DELCMP",
    });
  };
  useEffect(() => {
    console.log("isActionCalled", isActionCalled);
    console.log("error", error);
    if (!isError() && error !== null && isActionCalled) {
      postCompanyData(companyDetail).then((response) => {
        console.log(response);
        if (response.status.status) {
          if (actionType === "ADDCMP") {
            toast.success("Location added successfully.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (actionType === "MODSTF") {
            toast.success("Location updated successfully.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.success("Location deletd successfully.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }

          props.onHide();
          setCompanyDetail({
            comp_name: "",
            comp_alias: "",
          });
          setIsActionCalled(false);
        } else {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActionCalled(false);
        }
      });
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  useEffect(() => {
    console.log("selectclient useEffect", selectclient);
    if (selectclient === null) {
      setCompanyDetail({
        store_id: 0,
        comp_id: 0,
        comp_name: "",
        comp_alias: "",
        tax_id: "",
        tax_id2: "",
        corp_phone: "",
        phone: "",
        fax: 0,
        email_id: "",
        website: "",
        social_id_1: "",
        social_id_2: "",
        social_id_3: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: 0,
        country: "",
        is_company: "N",
        is_active: "",
        updated_by: currentUser(),
        access_location: "",
        opt_type: "ADDCMP",
      });
    } else {
      setCompanyDetail({
        store_id: selectclient?.store_id,
        comp_id: selectclient?.comp_id,

        comp_name:
          selectclient?.is_company === "Y" ? "" : selectclient?.comp_name,
        comp_alias:
          selectclient?.is_company === "Y" ? "" : selectclient?.comp_alias,
        tax_id: selectclient?.tax_id,
        tax_id2: selectclient?.tax_id2,
        corp_phone: selectclient?.corp_phone,
        phone: selectclient?.phone,
        fax: selectclient?.fax,
        email_id: selectclient?.email_id,
        website: selectclient?.website,
        social_id_1: selectclient?.social_id_1,
        social_id_2: selectclient?.social_id_2,
        social_id_3: selectclient?.social_id_3,
        address1: selectclient?.address1,
        address2: selectclient?.address2,
        city: selectclient?.city,
        state: selectclient?.state,
        zip: selectclient?.zip,
        country: selectclient?.country,
        is_company: "N",
        is_active: "Y",
        updated_by: currentUser(),
        access_location: "",
        opt_type: actionType,
      });
    }
  }, [selectclient]);
  const { onHide, ...props1 } = props;

  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>
            {selectclient && selectclient?.is_company === "N" ? (
              <>
                <Button
                  className={classes.fillBtn}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={updateFormData}
                >
                  Update
                </Button>
                {/* <Button
                  className={classes.fillBtn}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={deleteFormData}
                >
                  Delete Location
                </Button> */}
              </>
            ) : (
              <>
                <Button
                  className={classes.fillBtn}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={submitFormData}
                >
                  Add Location
                </Button>
              </>
            )}
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid container spacing={2}>
              {formElements.map((element, i) => {
                return (
                  <>
                    {element.type === "select" ? (
                      <Grid
                        item
                        xs={12}
                        md={element.mdSize}
                        className={classes.textGroup}
                      >
                        <Typography className={classes.formHeading}>
                          {element.label}{" "}
                          {element.required === "true" ? (
                            <span className={classes.required}>*</span>
                          ) : null}
                        </Typography>
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            name={element.key}
                            className={classes.selectDropdown}
                            style={{ fontSize: "12px" }}
                            placeholder={element.placeHolder}
                            value={
                              companyDetail && companyDetail[element.key]
                                ? companyDetail && companyDetail[element.key]
                                : ""
                            }
                            onChange={(e) => {
                              console.log("selected");
                              setCompanyDetail({
                                ...companyDetail,
                                [element.key]: e.target.value,
                              });
                              resetValidator(e.target.name);
                            }}
                            isInvalid={error && error[element.key]}
                          >
                            <MenuItem value="">Select</MenuItem>
                            {element.options.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Fade in={error}>
                          <Typography
                            color="secondary"
                            className={classes.errorMessage}
                          >
                            {error && error[element.key]}
                          </Typography>
                        </Fade>
                      </Grid>
                    ) : element.type === "text" ? (
                      <Grid
                        item
                        xs={12}
                        md={element.mdSize}
                        className={classes.textGroup}
                      >
                        <Typography className={classes.formHeading}>
                          {element.label}{" "}
                          {element.required === "true" ? (
                            <span className={classes.required}>*</span>
                          ) : null}
                        </Typography>
                        <TextField
                          required
                          name={element.key}
                          placeholder={element.placeHolder}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                            maxLength: element.maxLength,

                            onKeyPress: element.onKeyPress, // Add the event handler
                          }}
                          value={companyDetail && companyDetail[element.key]}
                          onChange={(e) => {
                            setCompanyDetail({
                              ...companyDetail,
                              [element.key]: e.target.value,
                            });
                            resetValidator(e.target.name);
                          }}
                          isInvalid={error && error[element.key]}
                        />
                        <Fade in={error}>
                          <Typography
                            color="secondary"
                            className={classes.errorMessage}
                          >
                            {error && error[element.key]}
                          </Typography>
                        </Fade>
                      </Grid>
                    ) : element.type === "date" ||
                      element.type === "datetime-local" ? (
                      <Grid item xs={12} md={3} className={classes.textGroup}>
                        {/* Name */}
                        <Typography className={classes.formHeading}>
                          {element.label}{" "}
                          {element.required === "true" ? (
                            <span className={classes.required}>*</span>
                          ) : null}
                        </Typography>
                        <TextField
                          id={element.key}
                          name={element.key}
                          placeholder={element.placeHolder}
                          type={element.type}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          value={companyDetail && companyDetail[element.key]}
                          onChange={(e) => {
                            setCompanyDetail({
                              ...companyDetail,
                              [element.key]: e.target.value,
                            });
                          }}
                          inputProps={{
                            min: `${currentDateTime}`,
                            max: `${currentDate}`,
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onKeyDown={(e) => {
                            e.preventDefault(); // Prevent manual typing
                          }}
                        />
                      </Grid>
                    ) : element.type === "checkbox" ? (
                      <Grid
                        item
                        xs={12}
                        md={element.mdSize}
                        className={classes.textGroup}
                      >
                        <Typography className={classes.formHeading}>
                          {element.label}{" "}
                          {element.required === "true" ? (
                            <span className={classes.required}>*</span>
                          ) : null}
                        </Typography>
                        <Checkbox />
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={12} className={classes.textGroup}>
                        <Typography className={classes.formHeading}>
                          {element.label}
                        </Typography>
                        <TextField
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          placeholder="Type here..."
                          variant="outlined"
                          multiline
                          rows={3}
                          inputProps={{
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          value={companyDetail && companyDetail.notes}
                          onChange={(e) => {
                            setCompanyDetail({
                              ...companyDetail,
                              notes: e.target.value,
                            });
                            resetValidator(e.target.name);
                          }}
                          isInvalid={error && error[element.key]}
                        />
                        <Fade in={error}>
                          <Typography
                            color="secondary"
                            className={classes.errorMessage}
                          >
                            {error && error[element.key]}
                          </Typography>
                        </Fade>
                      </Grid>
                    )}
                  </>
                );
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
