import React, { useState, useEffect } from "react";

import {
  Typography,
  Button,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Popup from "../../components/common/modal/Popup";

import useStyles from "./styles";
import { toast } from "react-toastify";

import { postCheckStatus } from "../../services/ServiceLayer";
import { currentUser } from "../../hooks/helper";

export default function AlertPopup({
  onHide,
  deleteModalShow,
  setDeleteModalShow,
  selectclient,
  setParentUpdateData,
}) {
  const [open, setOpen] = React.useState(false);

  var classes = useStyles();
  console.log("selectclient", selectclient);
  toast.configure();
  const hideHandler = () => {
    console.log("hide handler called");
    // setParentUpdateData(true);
    onHide();
    setDeleteModalShow(false);
    
    setOpen(false);
  };

  const handleCheckOut = () => {
    const data = {
      schedule_id: selectclient?.schedule_id,
      check_time: selectclient?.start_time,
      patient_id: selectclient?.patient_id,
      updated_by: currentUser(),
      access_location: "",
      opt_type: "CHKOUT",
    };
    postCheckStatus(data).then((response) => {
      console.log("response", response);
      if (response.status.status) {
        toast.success("CheckOut successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });

        hideHandler();
      } else {
        toast.error("Something went wrong.Please try after sometime", {
          position: toast.POSITION.TOP_RIGHT,
        });
        hideHandler();
      }
    });
  };

  const handleCheckIn = () => {
    const data = {
      schedule_id: selectclient?.schedule_id,
      check_time: selectclient?.start_time,
      patient_id: selectclient?.patient_id,
      updated_by: currentUser(),
      access_location: "",
      opt_type: "CHKIN",
    };
    postCheckStatus(data).then((response) => {
      console.log("response", response);
      if (response.status.status) {
        toast.success("CheckIn successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });

        hideHandler();
      } else {
        toast.error("Something went wrong.Please try after sometime", {
          position: toast.POSITION.TOP_RIGHT,
        });
        hideHandler();
      }
    });
  };

  return (
    <>
      {/* <ManageSchedule
      onHide={hideHandle1}
        selectSchedule={selectclient}
        open={open1}
        setModalShow={setModalShow1}
        selectedLocation={selectclient?.location_id}
        resources={0}
      /> */}
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            {/* <img src={alertIcon} /> */}
            <Typography className={classes.formActiveHeading}>
              Are you sure! You perform this actions?
            </Typography>
            <div
              className={classes.btnGroup}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "16px",
              }}
            >
              {selectclient?.patient_checkin_status === "Scheduled" ? (
                <>
                  <Button
                    style={{
                      fontSize: "12px",
                      marginRight: "6px",
                      marginLeft: "6px",
                    }}
                    className={classes.btnlRightDark}
                    variant="contained"
                    size="sm"
                    color="secondary"
                    onClick={() => handleCheckIn()}
                  >
                    Check In
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    style={{ fontSize: "12px", marginRight: "6px" }}
                    className={classes.btnleftOutline}
                    variant="contained"
                    size="md"
                    color="secondary"
                    onClick={() => handleCheckOut()}
                  >
                    Check Out
                  </Button>
                </>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
