import {
  handleAddressKeyPress,
  validateInputAmount,
  handleKeyPress,
  validateLargeAmount,
  validateFourDigitWithoutDecimalLargeAmount,
} from "../../hooks/helper";
export const tableHeadings = [
  {
    label: "S. No.",
  },
  {
    label: "Patient Name",
    align: "left !important",
  },

  {
    label: "Phone",
  },
  {
    label: "DOB",
  },

  {
    label: "Action",
  },
];
export const tableHeadings1 = [
  {
    label: "S. No.",
  },
  {
    label: "First Name",
    align: "left !important",
  },
  {
    label: "Last Name",
    align: "left !important",
  },
  {
    label: "Phone",
  },
  {
    label: "DOB",
  },
  {
    label: "Appoinment Status",
  },
  {
    label: "Status",
  },
];
export const invtOrderListElements = [
  {
    patient_name: "John Ederson",
    patient_email: "john@gamil.com",
    patient_contact: "7854785698",
    vision_ins: "EyePeyer01",
    medical_ins: "MedPayer01",
    orderDate: "23/09/2023",
    finalizeDate: "23/09/2023",
    status_order: "Done",
    payment_status: "Pending",
  },
  {
    patient_name: "Michael Ederson",
    patient_email: "john@gamil.com",
    patient_contact: "7854785698",
    vision_ins: "EyePeyer01",
    medical_ins: "MedPayer01",
    orderDate: "23/09/2023",
    finalizeDate: "23/09/2023",
    status_order: "Pending",
    payment_status: "Pending",
  },
  {
    patient_name: "Edge Ederson",
    patient_email: "john@gamil.com",
    patient_contact: "7854785698",
    vision_ins: "EyePeyer01",
    medical_ins: "MedPayer01",
    orderDate: "23/09/2023",
    finalizeDate: "23/09/2023",
    status_order: "Pending",
    payment_status: "Pending",
  },
  {
    patient_name: "Pointig Ederson",
    patient_email: "john@gamil.com",
    patient_contact: "7854785698",
    vision_ins: "EyePeyer01",
    medical_ins: "MedPayer01",
    orderDate: "23/09/2023",
    finalizeDate: "23/09/2023",
    status_order: "Done",
    payment_status: "Done",
  },
  {
    patient_name: "Johnson Ederson",
    patient_email: "john@gamil.com",
    patient_contact: "7854785698",
    vision_ins: "EyePeyer01",
    medical_ins: "MedPayer01",
    orderDate: "23/09/2023",
    finalizeDate: "23/09/2023",
    status_order: "Done",
    payment_status: "Pending",
  },
];
export const serviceFormElements = [
  {
    title: "Service Details",
    mdSize: "12",
  },
  {
    key: "procedure_code",
    label: "Service Code",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "service_name",
    label: "Service Name",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "service_type",
    label: "Service Type",
    placeHolder: "type here",
    type: "select",
    options: ["eyes exam", "contact lens replace"],
    mdSize: "3",
  },
  {
    key: "procedure_code_extension",
    label: "Service Code Extension",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "problem_code",
    label: "Problem Code",
    placeHolder: "type here",
    type: "select",
    options: ["2673"],
    mdSize: "3",
  },
  {
    key: "problem_description",
    label: "Problem description",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "icd_code",
    label: "ICD code",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },

  {
    key: "is_send_to_lab",
    label: "Send to Lab",
    placeHolder: "type here",
    type: "checkbox",
    mdSize: "2",
  },
  {
    key: "is_print_order",
    label: "Print on Order",
    placeHolder: "type here",
    type: "checkbox",
    mdSize: "2",
  },

  // {
  //   key: "not_on_claim",
  //   label: "Not on Claim",
  //   placeHolder: "type here",
  //   type: "checkbox",
  //   mdSize: "2",
  // },

  {
    title: "Cost & Tax",
    mdSize: "12",
  },
  {
    key: "retail_price",
    label: "Retail",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "item_cost",
    label: "Item Cost",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "profit",
    label: "Profit",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
    disabled: true, // Disable user input in this field
  },
  {
    key: "is_commission_prod",
    label: "Commission Type",

    type: "is_commission_prod",
    mdSize: "2",
  },

  {
    key: "commission_amount",
    label: "Commission Amount",
    placeHolder: "type here",
    type: "commission_amount",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "gross_percentage",
    label: "Gross Percentage",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "spiff_amount",
    label: "Discount",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },

  {
    key: "service_tax1",
    label: "Tax 1",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "service_tax2",
    label: "Tax 2",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    title: "Appointment",
    mdSize: "12",
  },

  {
    key: "is_appointment_require",
    label: "Appointment",
    placeHolder: "type here",
    type: "checkbox",
    mdSize: "2",
  },
  {
    key: "service_color",
    label: "Color",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "service_frequency",
    label: "Schedule Frequency",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "service_duration",
    label: "Duration",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    maxLength: "2",
    onKeyPress: handleKeyPress,
  },

  // {
  //   key: "is_favorite",
  //   label: "Favorite",
  //   placeHolder: "type here",
  //   type: "checkbox",
  //   mdSize: "2",
  // },

  {
    key: "notes",
    label: "Notes",
    placeHolder: "type here",
    type: "textarea",
    mdSize: "12",
  },
  {
    title: "Cost",
    mdSize: "12",
  },
  {
    key: "unit_price",
    label: "Unit Price",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "co-pay",
    label: "Co Pay",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "discount",
    label: "Discount",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
];
export const contactFormElements = [
  {
    key: "eyes",
    label: "Eyes",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },

  {
    key: "lens_type",
    label: "Lens Type",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },

  {
    key: "options",
    label: "Options",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "rep_schedule",
    label: "Replacement Schedule",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },

  {
    title: "OD (Right Eye)",
    mdSize: "12",
  },
  {
    key: "power",
    label: "Power",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "base_curve",
    label: "Base Curve",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },

  {
    key: "add",
    label: "Add",
    placeHolder: "type here",
    type: "text",
    mdSize: "1",
  },

  {
    key: "diameter",
    label: "Diameter",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "oc_height",
    label: "OC Height",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "water_per",
    label: "Water%",
    placeHolder: "type ",
    type: "text",
    mdSize: "1",
  },
  {
    key: "sku",
    label: "SKU",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    title: "OS (Left Eye)",
    mdSize: "12",
  },

  {
    key: "power",
    label: "Power",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "base_curve",
    label: "Base Curve",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },

  {
    key: "add",
    label: "Add",
    placeHolder: "type here",
    type: "text",
    mdSize: "1",
  },

  {
    key: "diameter",
    label: "Diameter",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "oc_height",
    label: "OC Height",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "water_per",
    label: "Water%",
    placeHolder: "type ",
    type: "text",
    mdSize: "1",
  },
  {
    key: "sku",
    label: "SKU",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    title: "Cost",
    mdSize: "12",
  },
  {
    key: "unit_price",
    label: "Unit Price",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "co-pay",
    label: "Co Pay",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "discount",
    label: "Discount",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
];
export const staffFormElements = [
  {
    key: "sold_by",
    label: "Sold By",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },

  {
    key: "sale_date",
    label: "Sale Date",
    placeHolder: "type here",
    type: "date",
    mdSize: "3",
  },

  {
    key: "note",
    label: "Internal Note",
    placeHolder: "type here",
    type: "text",
    mdSize: "6",
  },
  {
    key: "in_house_doctor",
    label: "Inhouse Doctor",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },

  {
    key: "ext_doctor",
    label: "Ext. Doctor",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "address",
    label: "Address",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },

  {
    key: "city",
    label: "City",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },

  {
    key: "state",
    label: "State",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "zip",
    label: "Zip",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "country",
    label: "Country",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },

  {
    key: "phone",
    label: "Phone",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "email",
    label: "Email",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "fitted_by",
    label: "Fitted By",
    placeHolder: "type ",
    type: "text",
    mdSize: "3",
  },
  {
    key: "inp_by",
    label: "Inspected By",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },

  {
    key: "del_by",
    label: "Delivered By",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "del_date",
    label: "Delivery Date",
    placeHolder: "type here",
    type: "date",
    mdSize: "3",
  },

  {
    key: "pre_status",
    label: "Prescription Status",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    title: "Cost",
    mdSize: "12",
  },
  {
    key: "unit_price",
    label: "Unit Price",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "co-pay",
    label: "Co Pay",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "discount",
    label: "Discount",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
];
export const prismFormElements = [
  {
    title: "OD (Right Eye)",
    mdSize: "12",
  },
  {
    key: "vertical",
    label: "Vertical",
    placeHolder: "type here",
    type: "select",
    options: ["Form 0.5 to 5", "sjiping 0.5", "NA"],
    mdSize: "3",
  },
  {
    key: "base_direction",
    label: "Base Direction",
    placeHolder: "type here",
    type: "select",
    options: ["Up", "Down"],
    mdSize: "3",
  },

  {
    key: "horizontal",
    label: "Horizontal",
    placeHolder: "type here",
    type: "select",
    options: ["Form 0.5 to 5", "sjiping 0.5", "NA"],
    mdSize: "3",
  },

  {
    key: "base_direction_in",
    label: "Base Direction",
    placeHolder: "type here",
    type: "select",
    options: ["In", "Out"],
    mdSize: "3",
  },

  {
    title: "OS (Left Eye)",
    mdSize: "12",
  },

  {
    key: "vertical",
    label: "Vertical",
    placeHolder: "type here",
    type: "select",
    options: ["Form 0.5 to 5", "sjiping 0.5", "NA"],
    mdSize: "3",
  },
  {
    key: "base_direction",
    label: "Base Direction",
    placeHolder: "type here",
    type: "select",
    options: ["Up", "Down"],
    mdSize: "3",
  },

  {
    key: "horizontal",
    label: "Horizontal",
    placeHolder: "type here",
    type: "select",
    options: ["Form 0.5 to 5", "sjiping 0.5", "NA"],
    mdSize: "3",
  },

  {
    key: "base_direction_in",
    label: "Base Direction",
    placeHolder: "type here",
    type: "select",
    options: ["In", "Out"],
    mdSize: "3",
  },
];
export const spectacleFormElements = [
  {
    key: "eyes",
    label: "Eyes",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "usage",
    label: "Usage",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "lens_type",
    label: "Lens Type",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },

  {
    key: "finishing",
    label: "Finishing",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },

  {
    title: "OD (Right Eye)",
    mdSize: "12",
  },
  {
    key: "sphere",
    label: "Sphere (SPH)",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "cyl",
    label: "Cylinder (CYL)",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },

  {
    key: "axis",
    label: "Axis",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "add",
    label: "Add",
    placeHolder: "type here",
    type: "text",
    mdSize: "1",
  },
  {
    key: "balance",
    label: "Balance",
    placeHolder: "type here",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "diameter",
    label: "Diameter",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "segment",
    label: "Segment Height",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    title: "OS (Left Eye)",
    mdSize: "12",
  },
  {
    key: "sphere",
    label: "Sphere (SPH)",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "cyl",
    label: "Cylinder (CYL)",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },

  {
    key: "axis",
    label: "Axis",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "add",
    label: "Add",
    placeHolder: "type here",
    type: "text",
    mdSize: "1",
  },
  {
    key: "balance",
    label: "Balance",
    placeHolder: "type here",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "diameter",
    label: "Diameter",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "segment",
    label: "Segment Height",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    title: "Prism OD (Right Eye)",
    mdSize: "12",
  },
  {
    key: "vertical",
    label: "Vertical",
    placeHolder: "type here",
    type: "select",
    options: ["Form 0.5 to 5", "sjiping 0.5", "NA"],
    mdSize: "3",
  },
  {
    key: "base_direction",
    label: "Base Direction",
    placeHolder: "type here",
    type: "select",
    options: ["Up", "Down"],
    mdSize: "3",
  },

  {
    key: "horizontal",
    label: "Horizontal",
    placeHolder: "type here",
    type: "select",
    options: ["Form 0.5 to 5", "sjiping 0.5", "NA"],
    mdSize: "3",
  },

  {
    key: "base_direction_in",
    label: "Base Direction",
    placeHolder: "type here",
    type: "select",
    options: ["In", "Out"],
    mdSize: "3",
  },

  {
    title: "Prism OS (Left Eye)",
    mdSize: "12",
  },

  {
    key: "vertical",
    label: "Vertical",
    placeHolder: "type here",
    type: "select",
    options: ["Form 0.5 to 5", "sjiping 0.5", "NA"],
    mdSize: "3",
  },
  {
    key: "base_direction",
    label: "Base Direction",
    placeHolder: "type here",
    type: "select",
    options: ["Up", "Down"],
    mdSize: "3",
  },

  {
    key: "horizontal",
    label: "Horizontal",
    placeHolder: "type here",
    type: "select",
    options: ["Form 0.5 to 5", "sjiping 0.5", "NA"],
    mdSize: "3",
  },

  {
    key: "base_direction_in",
    label: "Base Direction",
    placeHolder: "type here",
    type: "select",
    options: ["In", "Out"],
    mdSize: "3",
  },
];
export const labFormElements = [
  {
    key: "lab_instruction",
    label: "Lab Instruction",
    placeHolder: "type here",
    type: "text",

    mdSize: "6",
  },
  {
    key: "lab",
    label: "Lab",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },

  {
    key: "status",
    label: "Status",
    placeHolder: "type here",
    type: "select",
    options: ["Active", "Inactive"],
    mdSize: "3",
  },

  {
    key: "promised",
    label: "Promised",
    placeHolder: "type here",
    type: "date",
    // options: [],
    mdSize: "3",
  },
  {
    key: "lab_order",
    label: "Lab Order",
    placeHolder: "type here",
    type: "text",

    mdSize: "3",
  },

  {
    key: "lab_status",
    label: "Lab Status",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "reason",
    label: "Re-process Reason",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
];
export const framFormElements = [
 
  {
    key: "grp_meta_key",
    label: "Group Meta Key",
    placeHolder: "type here",
    type: "text",
    mdSize: "4",
    maxLength: "10",
  },
  {
    key: "grp_meta_name",
    label: "Group Meta Name",
    placeHolder: "type here",
    type: "text",
    mdSize: "4",
    maxLength: "100",
  },
  {
    key: "grpsub_meta_key",
    label: "Group Sub Meta Key",
    placeHolder: "type here",
    type: "text",
    mdSize: "4",
    maxLength: "10",
  },
  {
    key: "grpsub_meta_name",
    label: "Group Sub Meta Name",
    placeHolder: "type here",
    type: "text",
    mdSize: "4",
    maxLength: "100",
  },
  {
    key: "grp_attribute_key",
    label: "Group Attribute Key",
    placeHolder: "type here",
    type: "text",
    mdSize: "4",
    maxLength: "10",
  },
  {
    key: "grp_attribute_val",
    label: "Group Attribute Value",
    placeHolder: "type here",
    type: "text",
    mdSize: "4",
    maxLength: "100",
  },
  {
    key:"meta_sort_order",
    label: "Meta Sort Order",
    placeHolder: "type here",
    type: "text",
    maxLength:"4",
    mdSize: "4",
    onKeyPress: validateFourDigitWithoutDecimalLargeAmount,
  }
  
];
export const procedureFormElements = [
  {
    key: "procedure",
    label: "Procedure",
    placeHolder: "type here",
    type: "text",

    mdSize: "12",
  },
  {
    key: "retail",
    label: "Retail",
    placeHolder: "type here",
    type: "text",
    mdSize: "12",
  },
  {
    key: "Cost",
    label: "Cost",
    placeHolder: "type here",
    type: "text",
    mdSize: "12",
  },
];
export const procedureLocalFormElements = [
  {
    key: "procedure",
    label: "Procedure",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "12",
  },
  {
    key: "retail",
    label: "Retail",
    placeHolder: "type here",
    type: "text",
    mdSize: "12",
  },
  {
    key: "Cost",
    label: "Cost",
    placeHolder: "type here",
    type: "text",
    mdSize: "12",
  },
];
export const recallFormElements = [
  {
    title: "Recall Details",
    mdSize: "12",
  },
  {
    key: "type",
    label: "Type",
    placeHolder: "type here",
    type: "select",
    options: ["Service", "Frame", "Spectacle Lens", "Contact Lens"],
    mdSize: "3",
  },
  {
    key: "list_recall",
    label: "List of Recall",
    placeHolder: "type here",
    type: "select",
    options: [""],
    mdSize: "3",
  },
  {
    key: "start_date",
    label: "Start Date",
    placeHolder: "type here",
    type: "date",
    mdSize: "3",
  },
  {
    key: "end_date",
    label: "End Date",
    placeHolder: "type here",
    type: "date",
    mdSize: "3",
  },
  {
    key: "remainder_name",
    label: "Remainder Name",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "remainder_status",
    label: "Remainder Status",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },

  {
    key: "remainder_start",
    label: "Remainder Start",
    placeHolder: "type here",
    type: "date",
    mdSize: "3",
  },
  {
    key: "remainder_end",
    label: "Remainder End",
    placeHolder: "type here",
    type: "date",
    mdSize: "3",
  },
  {
    key: "remainder_frequency",
    label: "Remainder Frequency",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "recall_method",
    label: "Recall Method",
    placeHolder: "type here",
    type: "recall_method",
    options: ["Email", "Message"],
    mdSize: "3",
  },
  {
    key: "recall_email",
    label: "Recall Email",
    placeHolder: "type here",
    type: "recall_email",
    mdSize: "3",
  },
  {
    key: "recall_message",
    label: "Recall Message",
    placeHolder: "type here",
    type: "recall_message",
    mdSize: "6",
  },
  {
    key: "recall_reason",
    label: "Recall Reason",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "recall_status",
    label: "Recall Status",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "relevant_practice",
    label: "Relevant Practice",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "relevant_provider",
    label: "Relevant Provider",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "exclusion_allowed",
    label: "Exclusion Allowed",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "applicable_gender",
    label: "Applicable Gender",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "age_from",
    label: "Age From",
    placeHolder: "type here",
    type: "date",
    mdSize: "3",
  },
  {
    key: "age_to",
    label: "Age To",
    placeHolder: "type here",
    type: "date",
    mdSize: "3",
  },
];
export const insuranceFormElements = [
  {
    key: "provider",
    label: "Provider",
    placeHolder: "type here",
    type: "text",

    mdSize: "3",
  },
  {
    key: "plan",
    label: "Plan",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },

  {
    key: "auth_amount",
    label: "Authorised Amount",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },

  {
    key: "patient",
    label: "Patient Response",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    title: "Cost",
    mdSize: "12",
  },
  {
    key: "unit_price",
    label: "Unit Price",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "co-pay",
    label: "Co Pay",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "discount",
    label: "Discount",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
];
