import React, { useEffect, useState } from "react";

import {
  Grid,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Select,
  FormControl,
  FormGroup,
  Checkbox,
  Button,
  IconButton,
  Menu,
} from "@material-ui/core";
// data

// Model
import { substanceForm } from "../Exam_Constant";
import useStyles from "../styles";
// components

import moment from "moment";
import { currentUser, loadState } from "../../../hooks/helper";
import { toast } from "react-toastify";
import {
  getSubstanceDetails,
  postSubstanceDetails,
} from "../../../services/ServiceLayer";
import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";

export default function Substance({
  patientId,
  enable,
  substanceBtnUnable,
  setSubstanceBtnUnable,
}) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();
  const [discardUpdate, setDiscardUpdate] = useState(false);
  const [showDiscard, setShowDiscard] = useState(false);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [actionType, setActionType] = useState("ADDSUB");
  const [substanceData, setSubstanceData] = useState([]);
  const [substanceDetails, setSubstanceDetails] = useState({
    pat_subs_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    subs_alcohol: "",
    subs_alcohol_times: "",
    subs_tobacco: "",
    subs_tobacco_times: "",
    subs_drugs: "",
    subs_drugs_times: "",
    subs_injdrugs: "",
    subs_comments: "",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: actionType,
  });
  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    // if (!isError() && error !== null && isActionCalled) {
    if (isActionCalled) {
      postSubstanceDetails(substanceDetails).then((response) => {
        console.log(response);
        if (response?.status?.status) {
          toast.success("Substance added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setSubstanceBtnUnable(false);
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    // } else {
    //   setIsActionCalled(false);
    // }

    setIsActionCalled(false);
  }, [isActionCalled]);
  useEffect(() => {
    getSubstanceDetails(
      `location_id=${userData?.location_id}&patient_id=${patientId}&user_role=${userRole}&opt_type=SELSBS`
    ).then((response) => {
      console.log("response", response);
      if (response?.status?.status) {
        // setIsLoader(false);
        setSubstanceData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);
  useEffect(() => {
    if (substanceData && substanceData.length > 0) {
      setSubstanceDetails({
        pat_subs_id: substanceData[0]?.pat_subs_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        subs_alcohol: substanceData[0]?.subs_alcohol,
        subs_alcohol_times: substanceData[0]?.subs_alcohol_times,
        subs_tobacco: substanceData[0]?.subs_tobacco,
        subs_tobacco_times: substanceData[0]?.subs_tobacco_times,
        subs_drugs: substanceData[0]?.subs_drugs,
        subs_drugs_times: substanceData[0]?.subs_drugs_times,
        subs_injdrugs: substanceData[0]?.subs_injdrugs,
        subs_comments: substanceData[0]?.subs_comments,
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: "UPDSUB",
      });
    }
  }, [substanceData,discardUpdate]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAllSelected = (selectedValue) => {
    setSubstanceDetails({
      ...substanceDetails,

      subs_alcohol: selectedValue,

      subs_tobacco: selectedValue,

      subs_drugs: selectedValue,

      subs_injdrugs: selectedValue,
    });
    setAnchorEl(null);
    setSubstanceBtnUnable(true);
  };
  const discardHandler=()=>{
    if(substanceData && substanceData.length > 0){
      setDiscardUpdate(true);
      setSubstanceBtnUnable(false);
    }else{
      clearForm();
      setSubstanceBtnUnable(false);
    }
  }
  const clearForm = ()=>{
    setSubstanceDetails({
      pat_subs_id: 0,
      patient_id: patientId,
      location_id: userData?.location_id,
      subs_alcohol: "",
      subs_alcohol_times: "",
      subs_tobacco: "",
      subs_tobacco_times: "",
      subs_drugs: "",
      subs_drugs_times: "",
      subs_injdrugs: "",
      subs_comments: "",
      updated_by: currentUser(),
      access_location: "access_location",
      opt_type: actionType,
    })
  }
  return (
    <>
      {/* <TableWidget noBodyPadding> */}
      <Grid
        container
        spacing={2}
        className={classes.formBox}
        style={{ border: "1px solid #DED9D9" }}
      >
        {enable && (
          <Grid
            item
            xs={12}
            md={12}
            className={classes.textGroup}
            style={{
              border: "1px solid #DED9D9",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <IconButton
              id="basic-button"
              aria-controls={openDown ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openDown ? "true" : undefined}
              onClick={(e) => {
                handleClick(e);
              }}
            >
              <MoreIcon />
            </IconButton>

            <Menu
              className={classes.menuBox}
              id="basic-menu"
              anchorEl={anchorEl}
              open={openDown}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => handleAllSelected("Y")}>
                Set All Yes
              </MenuItem>

              <MenuItem onClick={() => handleAllSelected("N")}>
                Set All No
              </MenuItem>

              <MenuItem onClick={() => handleAllSelected("")}>
                Clear All
              </MenuItem>
            </Menu>
          </Grid>
        )}

        {substanceForm.map((element, i) => {
          return (
            <>
              <Grid
                item
                xs={12}
                md={element.mdSize}
                className={classes.textGroup}
                style={{ border: "1px solid #DED9D9" }}
              >
                {element.label && (
                  <Typography
                    className={classes.formHeading}
                    style={{ fontWeight: "500", color: "#4E97FD" }}
                  >
                    {element.label}{" "}
                    {element.required === "true" ? (
                      <span className={classes.required}>*</span>
                    ) : null}
                  </Typography>
                )}

                {element.type ? (
                  <>
                    {element.type === "select" ? (
                      <FormControl
                        fullWidth
                        className={classes.dropdownFormControl}
                      >
                        <Select
                          disabled={!enable}
                          name={element.key}
                          className={classes.selectDropdown}
                          placeholder={element.placeHolder}
                          value={
                            substanceDetails &&
                            substanceDetails[element.key] === null
                              ? ""
                              : substanceDetails &&
                                substanceDetails[element.key]
                          }
                          onChange={async (e) => {
                            setSubstanceDetails({
                              ...substanceDetails,
                              [element.key]: e.target.value,
                            });
                            setSubstanceBtnUnable(true);
                            setShowDiscard(true);
                          }}
                        >
                          <MenuItem value="">Select</MenuItem>
                          {element.options.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : element.type === "text" ? (
                      <TextField
                        disabled={!enable}
                        required
                        name={element.key}
                        placeholder={element.placeHolder}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                          maxLength: element.maxLength,
                            onKeyPress: element.onKeyPress,
                            onPaste: element.onpaste,
                        }}
                        value={
                          substanceDetails &&
                          substanceDetails[element.key] === null
                            ? ""
                            : substanceDetails && substanceDetails[element.key]
                        }
                        onChange={async (e) => {
                          setSubstanceDetails({
                            ...substanceDetails,
                            [element.key]: e.target.value,
                          });
                          setSubstanceBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                    ) : element.type === "date" ||
                      element.type === "datetime-local" ? (
                      <TextField
                        disabled={!enable}
                        id={element.key}
                        name={element.key}
                        placeholder={element.placeHolder}
                        type={element.type}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          min: `${currentDateTime}`,
                          max: `${currentDate}`,
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={
                          substanceDetails &&
                          substanceDetails[element.key] === null
                            ? ""
                            : substanceDetails && substanceDetails[element.key]
                        }
                        onChange={async (e) => {
                          setSubstanceDetails({
                            ...substanceDetails,
                            [element.key]: e.target.value,
                          });
                          setSubstanceBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                    ) : element.type === "radio" ? (
                      <RadioGroup
                        row
                        value={
                          substanceDetails[element.key] &&
                          substanceDetails[element.key] === "Y"
                            ? "Yes"
                            : substanceDetails[element.key] === "N"
                            ? "No"
                            : substanceDetails[element.key] === "U"
                            ? "Unknown"
                            : ""
                        }
                        onClick={(e) => {
                          console.log("clicked down");
                          let selectedValue;
                          switch (e.target.value) {
                            case "Yes":
                              selectedValue = "Y";
                              break;
                            case "No":
                              selectedValue = "N";
                              break;
                            case "Unknown":
                              selectedValue = "U";
                              break;
                            default:
                              selectedValue = "";
                          }

                          setSubstanceDetails({
                            ...substanceDetails,
                            [element.key]: selectedValue,
                          });
                          setSubstanceBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      >
                        {element.radioValue.map((item) => (
                          <FormControlLabel
                            disabled={!enable}
                            size="small"
                            style={{ padding: "0px 6px" }}
                            value={item}
                            control={<Radio />}
                            label={item}
                            className={classes.smallRadioButton}
                          />
                        ))}
                      </RadioGroup>
                    ) : element.type === "checkbox" ? (
                      <>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position">
                            <FormControlLabel
                              disabled={!enable}
                              control={
                                <Checkbox
                                  checked={
                                    substanceDetails[element.key] &&
                                    substanceDetails[element.key] === "Y"
                                  }
                                  onChange={(e) => {
                                    const selectedValue = e.target.checked
                                      ? "Y"
                                      : "N";
                                    setSubstanceDetails({
                                      ...substanceDetails,
                                      [element.key]: selectedValue,
                                    });
                                    setSubstanceBtnUnable(true);
                                    setShowDiscard(true);
                                  }}
                                />
                              }
                              // label={element.lbltitle}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </FormControl>
                      </>
                    ) : (
                      <TextField
                        disabled={!enable}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        placeholder="Type here..."
                        variant="outlined"
                        multiline
                        rows={3}
                        inputProps={{
                          style: {
                            fontSize: "12px",
                          },
                          maxLength: element.maxLength,
                            onKeyPress: element.onKeyPress,
                            onPaste: element.onpaste,
                        }}
                        value={
                          substanceDetails &&
                          substanceDetails[element.key] === null
                            ? ""
                            : substanceDetails && substanceDetails[element.key]
                        }
                        onChange={async (e) => {
                          setSubstanceDetails({
                            ...substanceDetails,
                            [element.key]: e.target.value,
                          });
                          setSubstanceBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Typography
                      className={classes.formHeading}
                      style={{
                        fontWeight: "600",

                        color: "#4E97FD",
                      }}
                    >
                      {element.title}
                    </Typography>
                  </>
                )}
              </Grid>
            </>
          );
        })}

        {enable && (
          <Grid className={classes.btnGroup} item xs={12} style={{ display: "flex", justifyContent: "end" }}>
            {showDiscard && (
            <Button
              className={classes.btnleftOutline}
              variant="outlined"
              style={{ marginRight: "10px", fontSize: "12px" }}
              size="medium"
              onClick={discardHandler}
            >
              Discard
            </Button>
          )}
            <Button disabled={!substanceBtnUnable}
              style={{ display: "flex", float: "right" }}
              onClick={handleSubmit}
              className={classes.btnlRightDark}
              variant="contained"
              size="medium"
              color="secondary"
            >
              Save
            </Button>
          </Grid>
        )}
      </Grid>

      {/* </TableWidget> */}
    </>
  );
}
