import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import { formPatientStatusElements } from "./Patient_Constant";

import moment from "moment";
import useStyles from "./styles";
import { Box } from "@mui/material";
export default function PatientStatus({
  patientData,
  setPatientData,
  patientStatusValues,
  inactiveValues,
  deceasedValues,
}) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.formBox} >
      {formPatientStatusElements.map((element, i) => {
        return (
          <>
            {patientData ? (
              <Grid
                item
                xs={12}
                lg={2}
                sm={2}
                md={element.mdSize}
                className={classes.textGroup}
              >
                <Typography className={classes.formHeading}>
                  {element.label}{" "}
                  {element.required === "true" ? (
                    <span className={classes.required}>*</span>
                  ) : null}
                </Typography>

                {element.type === "select" ? (
                  <FormControl
                    fullWidth
                    // sx={{
                    //   '&.MuiFormControl-fullWidth': {
                    //     width: 'auto',
                    //   }
                    // }}
                    className={classes.dropdownFormControl}
                  >
                    <Select disabled
                      name={element.key}
                      className={classes.selectDropdown}
                      placeholder={element.placeHolder}
                      value={
                        patientData && patientData[element.key] === null
                          ? ""
                          : patientData && patientData[element.key]
                      }
                      onChange={async (e) => {
                        setPatientData({
                          ...patientData,
                          [element.key]: e.target.value,
                        });
                      }}
                    >
                      <MenuItem value="">Select</MenuItem>
                      {element.key == "patient_status" && patientStatusValues
                        ? patientStatusValues.map((item) => (
                            <MenuItem
                              key={item.attribute_id}
                              value={item.attribute_id}
                            >
                              {item.attribute_val}
                            </MenuItem>
                          ))
                        : element.key == "patient_inactive_reason" &&
                          inactiveValues
                        ? inactiveValues.map((item) => (
                            <MenuItem
                              key={item.attribute_id}
                              value={item.attribute_id}
                            >
                              {item.attribute_val}
                            </MenuItem>
                          ))
                        : element.key == "deceased_reason" && deceasedValues
                        ? deceasedValues.map((item) => (
                            <MenuItem
                              key={item.attribute_id}
                              value={item.attribute_id}
                            >
                              {item.attribute_val}
                            </MenuItem>
                          ))
                        : element.options.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                ) : element.type === "text" ? (
                  <TextField
                    disabled
                    required
                    name={element.key}
                    placeholder={element.placeHolder}
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "12px",
                        fontSize: "12px",
                      },
                      maxLength: element.maxLength,

                      onKeyPress: element.onKeyPress, // Add the event handler
                    }}
                    value={
                      patientData && patientData[element.key] === null
                        ? ""
                        : patientData && patientData[element.key]
                    }
                    onChange={async (e) => {
                      setPatientData({
                        ...patientData,
                        [element.key]: e.target.value,
                      });
                    }}
                  />
                ) : element.type === "date" ||
                  element.type === "datetime-local" ? (
                  <TextField
                    disabled
                    id={element.key}
                    name={element.key}
                    placeholder={element.placeHolder}
                    type={element.type}
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      min: `${currentDateTime}`,
                      max: `${currentDate}`,
                      style: {
                        height: "12px",
                        fontSize: "12px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      patientData && patientData[element.key] === null
                        ? ""
                        : moment(
                            patientData && patientData[element.key]
                          ).format("YYYY-MM-DD")
                    }
                    onChange={async (e) => {
                      setPatientData({
                        ...patientData,
                        [element.key]: e.target.value,
                      });
                    }}
                  />
                ) : (
                  <TextField
                    disabled
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    placeholder="Type here..."
                    variant="outlined"
                    multiline
                    rows={3}
                    inputProps={{
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    value={
                      patientData && patientData[element.key] === null
                        ? ""
                        : patientData && patientData[element.key]
                    }
                    onChange={async (e) => {
                      setPatientData({
                        ...patientData,
                        [element.key]: e.target.value,
                      });
                    }}
                  />
                )}
              </Grid>
            ) : null}
          </>
        );
      })}
      {patientData ? (
        <>
          <Grid item xs={12} md={2} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Patient Id</Typography>
            <Typography style={{ color: "#A6A6A6" }}>
              {patientData?.patient_id}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Patient Since
            </Typography>
            <Typography style={{ color: "#A6A6A6" }}>4/15/2023</Typography>
          </Grid>
          <Grid item xs={12} md={2} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Last Updated On
            </Typography>
            <Typography style={{ color: "#A6A6A6" }}>
              {patientData?.updated_by}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Updated By</Typography>
            <Typography style={{ color: "#A6A6A6" }}>
              {moment(patientData?.updated_dt).format("YYYY-MM-DD")}
            </Typography>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
}
