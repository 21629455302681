import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import PageTitle from "../../../PageTitle/PageTitle";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import TableWidget from "../../../Widget/TableWidget";
import ManageFamily from "./ManageFamily";
import {
  dateFormatConversion,
  paramForAddFamilyProfile,loadState
} from "../../../../hooks/helper";
import useStyles from "../styles";
import PatientSearchResult from "../../../../hooks/PatientSearch";
import {
  getPatientFamily,
  postFamilyData,
} from "../../../../services/ServiceLayer";
import TableDesign from "../../../common/table/TableDesign";
import * as Icons from "@material-ui/icons";
import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";
import { toast } from "react-toastify";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Paper,
  Menu,
  MenuItem,
} from "@material-ui/core";
import DeletePopup from "./DeletePopup";

// styles

export default function FamilyList({
  relTypeValues,
 
  patientId,
}) {
  // const { patientId } = props;
  console.log("patientId", patientId);
  toast.configure();
  const userData = loadState().user;
  const userRole =loadState().user.user_role;
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [selectRow, setSelectRow] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [selectclient, setselectclient] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [title, setTitle] = useState(null);
  const [test, setTest] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const [patientFamilyData, setPatientFamilyData] = useState([]);
  var classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const tableHeadings = [
    {
      label: "S. No.",
    },
    {
      label: "Patient ID",
      align: "left !important",
    },
    {
      label: "Patient Name",
      align: "left !important",
    },
    {
      label: "Email ID",
      align: "left !important",
    },
    {
      label: "Relationship",
      align: "left !important",
    },
    {
      label: "Contact",
      align: "left !important",
    },

    {
      label: "Created Date",
      align: "left !important",
    },
    {
      label: "Updated By",
    },
    {
      label: "Action",
    },
  ];

  const addFileLink = () => {
    setOpen(true);
    setTitle("Add Family Link");
    setModalShow(true);
  };
  const deleteEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);

    setselectclient(rec);
    setDeleteModalShow(true);
    setTitle("Delete Family");
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    setOpen(false);
    setOpen1(false);
    setDataUpdated(true);
  };
  useEffect(() => {
    getPatientFamily(`patient_id=${patientId}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELREL`).then((response) => {
      console.log(response);
      setPatientFamilyData(response.data);
    });
  }, []);
  useEffect(() => {
    getPatientFamily(`patient_id=${patientId}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELREL`).then((response) => {
      console.log(response);
      setPatientFamilyData(response.data);
      setDataUpdated(false);
    });
  }, [dataUpdated]);
  const selectedPatient = (data) => {
    console.log("Selected Patinet Data " + JSON.stringify(data));
    setOpen(false);
    if (data) {
      postFamilyData(
        paramForAddFamilyProfile({
          patient_id: patientId,
          patient_parent_id: data[0].patient_id,
          patient_rel_id: data[1],
        })
      ).then((response) => {
        console.log("response", response);
        if (response?.status.status) {
          toast.success("Family member added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setDataUpdated(true);
        } else {
          toast.error("Somethings wrong please try after some time.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };
  const options = {
    filterType: "select",
    selectableRows: "single",
    print: false,

    onRowsSelect: (data) => {
      console.log(data);
    },
    textLabels: {},
    customToolbarSelect: (selectedRows) => (
      <>
        <Tooltip title="Unlink">
          <IconButton
            onClick={() => {
              console.log(test);
              setTest(!test);
              console.log(test);
            }}
            style={{
              position: "absolute",
              float: "right",
              right: "50px",
            }}
          >
            <Icons.RemoveCircle />
          </IconButton>
        </Tooltip>
        <Tooltip title="Print">
          <IconButton
            onClick={() => {
              console.log(test);
              setTest(!test);
              console.log(test);
            }}
          >
            <Icons.Print />
          </IconButton>
        </Tooltip>
      </>
    ),
  };
  return (
    <div className="container">
      <ManageFamily
        open={open}
        onHide={(e) => {
          selectedPatient(e);
        }}
        title={"Add Family"}
        selectclient={selectclient}
        setModalShow={setModalShow}
        relTypeValues={relTypeValues}
        patientId={patientId}
      />

      <DeletePopup
        onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        selectclient={selectclient}
        setDeleteModalShow={setDeleteModalShow}
      />

      <PageTitle title="Manage Family" button="Link" onClick={addFileLink} />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {patientFamilyData && patientFamilyData?.length ? (
                patientFamilyData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {row.patient_parent_id}
                        </TableCell>
                        <TableCell
                          className={classes.tableBodyCell}
                          onClick={() =>
                            history.push(
                              `/app/patient-details/${row.patient_parent_id}`
                            )
                          }
                        >
                          {row.family_name}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.email}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.relationship_type}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.contact_cell}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {dateFormatConversion(row.created_dt)}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.updated_by}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            id="basic-button"
                            aria-controls={openDown ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openDown ? "true" : undefined}
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              onClick={() =>
                                history.push(
                                  `/app/patient-details/${row.patient_parent_id}`
                                )
                              }
                            >
                              View
                            </MenuItem>

                            <MenuItem
                              onClick={() => deleteEventHandler(selectRow)}
                            >
                              Unlink
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </div>
  );
}
