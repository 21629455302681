import React from "react";
import useStyles from "../styles";
import { Grid } from "@material-ui/core";
import Health from "./Health";
import AllergyHistory from "./AllergyHistory";
import MedicationHistory from "./MedicationHistory";
import Iop from "./Iop";
import Binocular from "./Binocular";
import FinalRx from "./FinalRx";
import Assessment from "./Assessment";
import Followup from "./Followup";
import Contact from "./Contact";
import Pachymetry from "./Pachymetry";
import Dialation from "./Dialation";
import AddTest from "./AddTest";
import OcularData from "./OcularData";
import ReasonVisit from "./ReasonVisit";
import { loadState } from "../../../hooks/helper";
export default function MainChart({ patientId }) {
  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  return (
    <>
      <div className={classes.pageTitleContainer}>
        
      </div>
      
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Health
            patientId={patientId}
            userData={userData}
            userRole={userRole}
          />
          <MedicationHistory
            patientId={patientId}
            userData={userData}
            userRole={userRole}
          />
          <AllergyHistory
            patientId={patientId}
            userData={userData}
            userRole={userRole}
          />
          <Iop patientId={patientId} userData={userData} userRole={userRole} />
          <Binocular
            patientId={patientId}
            userData={userData}
            userRole={userRole}
          />
          <FinalRx
            patientId={patientId}
            userData={userData}
            userRole={userRole}
          />
          <Assessment
            patientId={patientId}
            userData={userData}
            userRole={userRole}
          />
        </Grid>
        <Grid item xs={6}>
          <ReasonVisit
            patientId={patientId}
            userData={userData}
            userRole={userRole}
          />
          <OcularData
            patientId={patientId}
            userData={userData}
            userRole={userRole}
          />
          <Dialation
            patientId={patientId}
            userData={userData}
            userRole={userRole}
          />
          <Pachymetry
            patientId={patientId}
            userData={userData}
            userRole={userRole}
          />
          <AddTest
            patientId={patientId}
            userData={userData}
            userRole={userRole}
          />
          <Contact
            patientId={patientId}
            userData={userData}
            userRole={userRole}
          />
          <Followup
            patientId={patientId}
            userData={userData}
            userRole={userRole}
          />
        </Grid>
      </Grid>
    </>
  );
}

// import React from "react";
// import { Grid, Typography } from "@material-ui/core";
// import useStyles from "../styles";

// // Import individual chart components
// import AllergyHistory from "./AllergyHistory";
// import AddTest from "./AddTest";
// import Binocular from "./Binocular";
// import Contact from "./Contact";
// import Dialation from "./Dialation";
// import FinalRx from "./FinalRx";
// import Followup from "./Followup";
// import Health from "./Health";

// const MainChart = ({ patientId }) => {
//   const classes = useStyles();

//   return (
//     <div className={classes.mainChartContainer}>
//       <Typography variant="h4" className={classes.chartTitle}>
//         Patient Health Dashboard
//       </Typography>
//       <Grid container spacing={4}>
//         <Grid item xs={12} md={6}>
//           <AllergyHistory patientId={patientId} />
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <Health patientId={patientId} />
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <AddTest patientId={patientId} />
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <Binocular patientId={patientId} />
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <Dialation patientId={patientId} />
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <FinalRx patientId={patientId} />
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <Followup patientId={patientId} />
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <Contact patientId={patientId} />
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

// export default MainChart;
