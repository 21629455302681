import {
  handleKeyPress,
  handleKeyPressPaste,
  handleAlphNumKeyPress,
  handleAlphNumKeyPressPaste,
  handleAlphNumberKeyPress,
  handleAlphNumberKeyPressPaste,
  handleAddressKeyPress,
  handleAddressKeyPressPaste,
  validateLargeAmount,
} from "../../../hooks/helper";
export const formArxElements = [
  {
    title: "OD",
    mdSize: "1",
    // copyBtn: "true",
  },
  {
    key: "od_balance",
    label: "Balance",
    placeHolder: "",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "od_sphere",
    label: "Sphere",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_cyl",
    label: "Cyl",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_axis",
    label: "Axis",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_add",
    label: "Add",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  // {
  //   key: "od_seg_ht",
  //   label: "Seg Ht",
  //   placeHolder: "text",
  //   type: "text",
  //   mdSize: "1",
  //   onKeyPress: validateLargeAmount,
  // },
  {
    key: "od_h_prism",
    label: "H Prism",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_h_base",
    label: "H Base",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_v_prism",
    label: "V Prism",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_v_base",
    label: "V Base",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_dva",
    label: "DVA",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_nva",
    label: "NVA",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  
  {
    title: "OS",
    mdSize: "1",
  },
  {
    key: "os_balance",
    label: "",
    placeHolder: "",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "os_sphere",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_cyl",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_axis",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_add",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  // {
  //   key: "os_seg_ht",
  //   label: "",
  //   placeHolder: "text",
  //   type: "text",
  //   mdSize: "1",
  //   onKeyPress: validateLargeAmount,
  // },
  {
    key: "os_h_prism",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_h_base",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_v_prism",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_v_base",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_dva",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_nva",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    title: "OU",
    mdSize: "1",
  },
  {
    title: "",
    mdSize: "9",
  },
  {
    key: "ou_dva",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "ou_nva",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
 
];
export const formCrxElements = [
  {
    title: "OD",
    mdSize: "1",
    // copyBtn: "true",
  },
  {
    key: "od_balance",
    label: "Balance",
    placeHolder: "",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "od_sphere",
    label: "Sphere",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_cyl",
    label: "Cyl",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_axis",
    label: "Axis",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_add",
    label: "Add",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  // {
  //   key: "od_seg_ht",
  //   label: "Seg Ht",
  //   placeHolder: "text",
  //   type: "text",
  //   mdSize: "1",
  //   onKeyPress: validateLargeAmount,
  // },
  {
    key: "od_h_prism",
    label: "H Prism",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_h_base",
    label: "H Base",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_v_prism",
    label: "V Prism",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_v_base",
    label: "V Base",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_dva",
    label: "DVA",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_nva",
    label: "NVA",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  
  {
    title: "OS",
    mdSize: "1",
  },
  {
    key: "os_balance",
    label: "",
    placeHolder: "",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "os_sphere",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_cyl",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_axis",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_add",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  // {
  //   key: "os_seg_ht",
  //   label: "",
  //   placeHolder: "text",
  //   type: "text",
  //   mdSize: "1",
  //   onKeyPress: validateLargeAmount,
  // },
  {
    key: "os_h_prism",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_h_base",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_v_prism",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_v_base",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_dva",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_nva",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    title: "OU",
    mdSize: "1",
  },
  {
    title: "",
    mdSize: "9",
  },
  {
    key: "ou_dva",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "ou_nva",
    label: "",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
];
export const formMrxElements = [
  {
    label: "Glaucoma",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Loss of Vision",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Lazy Eye:",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Mascular Degeneration",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Color Blindness",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Retinal Detachment",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Keratoconus",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Diabetes",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Heart Disease",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "High Blood Pressure",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Low Blood Pressure",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Kidney Disease",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Lupus",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Thyroid Disease",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Nyctalopia",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Eye Pain or Soreness",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Infection of Eye or Lid",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Sties or Chalazion",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
  {
    label: "Cancer",
    type: "radio",
    radioValue: ["Yes", "No", "Unknown"],
    mdSize: "3",
  },
];
export const formFinalRxElements = [
    {
      key: "reason",
      label: "Reason",
      placeHolder: "Select",
      type: "select",
      options: [],
      mdSize: "2",
    },
    {
        key: "impacted_part",
        label: "Impacted Part",
        placeHolder: "Select",
        type: "select",
        options: [],
        mdSize: "2",
      },
      {
        key: "frequency",
        label: "Frequency",
        placeHolder: "Select",
        type: "select",
        options: [],
        mdSize: "2",
      },
      {
        key: "severity",
        label: "Severity",
        placeHolder: "Select",
        type: "select",
        options: [],
        mdSize: "2",
      },
      {
        key: "occurs_while",
        label: "Occurs while",
        placeHolder: "Select",
        type: "select",
        options: [],
        mdSize: "2",
      },
      {
        key: "factors",
        label: "Factors",
        placeHolder: "Select",
        type: "select",
        options: [],
        mdSize: "2",
      },
      {
        key: "symptons",
        label: "Symptoms",
        placeHolder: "Select",
        type: "select",
        options: [],
        mdSize: "2",
      },
      {
        key: "since",
        label: "Since",
        placeHolder: "Select",
        type: "select",
        options: [],
        mdSize: "2",
      },
    {
      key: "description",
      label: "Description",
      placeHolder: "Enter Text",
      type: "text",
      mdSize: "8",
    //   onKeyPress: handleKeyPress,
    //   onPaste: handleKeyPressPaste,
  
    //   maxLength: "10",
      // required: "true",
    },
   
  ];
  export const formHistoryRxElements = [

    {
      key:'weight',
      label: "Weight Loss/Gain",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:'loss_appetitue',
      label: "Loss of Appetite",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:'sleep_apnea',
      label: "Sleep Apnea",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:'covid',
      label: "COVID 19",
      type: "radio",
      
      mdSize: "3",
    },
    {
      title: "NEUROLOGICAL",
      mdSize: "12",
    },
    {
      key:'headaches',
      label: "Headaches",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:'migraine',
      label: "Migraine",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:'seizures',
      label: "Seizures",
      type: "radio",
      
      mdSize: "3",
    },
    {
      title: "ENT",
      mdSize: "12",
    },
    {
      key:'sinusitis',
      label: "Sinusitis",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:'hearing_loss',
      label: "Hearing Loss",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:'laryngitis',
      label: "Laryngitis",
      type: "radio",
      
      mdSize: "3",
    },
    {
      title: "ENDOCRINE",
      mdSize: "12",
    },
    {
      key:'thyroid_disease',
      label: "Thyroid Disease",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:'other_gland',
      label: "Other Glands",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:'respiratory',
      label: "RESPIRATORY",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:'asthma',
      label: "Asthma",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:'chronic_bronchitis',
      label: "Chronic Bronchitis",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:'emphysema',
      label: "Emphysema",
      type: "radio",
      
      mdSize: "3",
    },
    {
      title: "VASCULAR / CARDIOVASCULAR",
      mdSize: "12",
    },
    {
      key:'diabetes',
      label: "Diabetes",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:"heart_pain",
      label: "Heart Pain",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:'hbp',
      label: "High Blood Pressure",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:'vascular_disease',
      label: "Vascular Disease",
      type: "radio",
      
      mdSize: "3",
    },
    {
      title: "GASTROINTESTINAL",
      
  
      mdSize: "12",
    },
    {
      key:'acid_reflux',
      label: "Acid Reflux",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:'ulcer',
      label: "Ulcer",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:'celiac_disease',
      label: "Celiac Disease",
      type: "radio",
      
      mdSize: "3",
    },
  
    {
      title: "GENITOURINARY",
      mdSize: "12",
    },
    {
      key:'genitals_disease',
      label: "Genitals Disease",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:'kidney_disease',
      label: "Kidney Disease",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:'bladdar_disease',
      label: "Bladdar Disease",
      type: "radio",
      
      mdSize: "3",
    },
    {
      title: "Musculoskeletal",
      mdSize: "12",
    },
    {
      key:'rheumatoid_arthritis',
      label: "Rheumatoid Arthritis",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:'osteoporosis',
      label: "Osteoporosis",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:'muscular_dystrophy',
      label: "Muscular Dystrophy",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:'joint_pain',
      label: "Joint Pain",
      type: "radio",
      
      mdSize: "3",
    },
  
    {
      title: "LYMPHATIC / HEMATOLOGIC",
      mdSize: "12",
    },
    {
      key:'anemia',
      label: "Anemia",
      type: "radio",
      
      mdSize: "3",
    },
    {
      key:'bleeding_problems',
      label: "Bleeding Problems",
      type: "radio",
      
      mdSize: "3",
    },
  ];
export const formLensRxElements = [
  {
    key: "",
    label: "Usage",
    placeHolder: "select",
    type: "select",
    options:[],
    mdSize: "3",
    
  },
  {
    key: "",
    label: "Lens Type",
    placeHolder: "select",
    type: "select",
    options:[],
    mdSize: "3",
    
  },
  {
    key: "",
    label: "Material",
    placeHolder: "select",
    type: "select",
    options:[],
    mdSize: "3",
    
  },
  {
    key: "",
    label: "Start Date",
    placeHolder: "select",
    type: "date",
    mdSize: "3",
    
  },
  {
    key: "",
    label: "End Date",
    placeHolder: "select",
    type: "date",
    mdSize: "3",
    
  },
  

  {
    key: "",
    label: "Segment Height (mm)",
    placeHolder: "Enter segment height",
    type: "text",
    mdSize: "3",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
 
  {
    key: "",
    label: "Diameter (mm)",
    placeHolder: "Enter diameter",
    type: "text",
    mdSize: "3",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
 
  {
    key: "",
    label: "Bevel (mm)",
    placeHolder: "Enter bevel",
    type: "text",
    mdSize: "3",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  
];

export const formReferralElements = [
  {
    key: "rf_to",
    label: "Referral To",
    placeHolder: "Type here",
    type: "text",
    mdSize: "4",
  },
  {
    key: "rf_res",
    label: "Referral Reason",
    placeHolder: "Type here",
    type: "text",
    mdSize: "4",
  },
  {
    key: "rf_dt",
    label: "Date",
    placeHolder: "select",
    type: "date",
    mdSize: "4",
  },
  {
    key: "rf_notes",
    label: "Referral Notes",
    placeHolder: "Type here",
    type: "textarea",
    mdSize: "12",
  },
 
];
export const formNotesElements = [
 
  {
    key: "asm_notes",
    label: "Assessment",
    placeHolder: "Type here",
    type: "textarea",
    mdSize: "12",
  },
 
];

