import React, { useEffect, useRef, useState } from "react";

import {
  Grid,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Select,
  FormControl,
  FormGroup,
  Checkbox,
  Button,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  MenuList,
  ClickAwayListener,
  IconButton,
  Menu,
  Fade,
} from "@material-ui/core";
// data

// Model
import { ocularHistory } from "../Exam_Constant";
import useStyles from "../styles";
// components

import moment from "moment";
import { currentUser, loadState } from "../../../hooks/helper";
import {
  getPatientOcularHistory,
  postPatientOcularHistory,
} from "../../../services/ServiceLayer";
import { toast } from "react-toastify";
import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";

import ExamValidation from "../../../hooks/ExamValidation";

export default function OcularHistory(props) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  const [isActionCalled, setIsActionCalled] = useState(false);
  var classes = useStyles();
  const [showDiscard, setShowDiscard] = useState(false);
  const { selectclient, patientId, enable,setOcularBtnUnable,frameBrandList,
    ocularBtnUnable } = props;
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [actionType, setActionType] = useState("ADDOCU");
  const [ocularData, setOcularData] = useState([]);
  const [discardUpdate, setDiscardUpdate] = useState(false);
  const [ocularDetails, setOcularDetails] = useState({
    pat_occul_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    occul_glass: "",
    occul_purchase_glass: moment(currentDate).format("YYYY-MM-DD"),
    occul_cont_lense: "",
    occul_cont_purchase_lense: moment(currentDate).format("YYYY-MM-DD"),
    occul_brand: "",
    occul_blur: "",
    occul_lossofvision: "",
    occul_nyctalopia: "",
    occul_distortedvision: "",
    occul_lossofsidevision: "",
    occul_doublevision: "",
    occul_dryness: "",
    occul_mucousdischarge: "",
    occul_redness: "",
    occul_frontalheadache: "",
    occul_itching: "",
    occul_burning: "",
    occul_colorblindness: "",
    occul_foreignbodysensation: "",
    occul_excesstearingwatering: "",
    occul_glarelightsensitivity: "",
    occul_eyepainorsoreness: "",
    occul_infectionofeyeorlid: "",
    occul_stiesorchalazion: "",
    occul_flashesfloatersinvision: "",
    occul_tiredeyes: "",
    occul_cataract: "",
    occul_crossedeyes: "",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: actionType,
  });
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
  ExamValidation({
    occul_purchase_glass: ocularDetails?.occul_purchase_glass,
    occul_cont_purchase_lense: ocularDetails?.occul_cont_purchase_lense,
  });
  useEffect(() => {
    getPatientOcularHistory(
      `location_id=${userData?.location_id}&patient_id=${patientId}&user_role=${userRole}&opt_type=SELOCL`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setOcularData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);

  useEffect(() => {
    console.log("ocularData -1", ocularData);
    if (ocularData && ocularData.length > 0) {
      console.log("ocularData -2", ocularData);
      setOcularDetails({
        pat_occul_id: ocularData[0]?.pat_occul_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        occul_glass: ocularData[0]?.occul_glass,
        occul_purchase_glass: moment(ocularData[0]?.occul_purchase_glass).format(
          "YYYY-MM-DD"
        ),
        occul_cont_lense: ocularData[0]?.occul_cont_lense,
        occul_cont_purchase_lense: moment(
          ocularData[0]?.occul_cont_purchase_lense
        ).format("YYYY-MM-DD"),
        occul_brand: ocularData[0]?.occul_brand,
        occul_blur: ocularData[0]?.occul_blur,
        occul_lossofvision: ocularData[0]?.occul_lossofvision,
        occul_nyctalopia: ocularData[0]?.occul_nyctalopia,
        occul_distortedvision: ocularData[0]?.occul_distortedvision,
        occul_lossofsidevision: ocularData[0]?.occul_lossofsidevision,
        occul_doublevision: ocularData[0]?.occul_doublevision,
        occul_dryness: ocularData[0]?.occul_dryness,
        occul_mucousdischarge: ocularData[0]?.occul_mucousdischarge,
        occul_redness: ocularData[0]?.occul_redness,
        occul_frontalheadache: ocularData[0]?.occul_frontalheadache,
        occul_itching: ocularData[0]?.occul_itching,
        occul_burning: ocularData[0]?.occul_burning,
        occul_colorblindness: ocularData[0]?.occul_colorblindness,
        occul_foreignbodysensation: ocularData[0]?.occul_foreignbodysensation,
        occul_excesstearingwatering: ocularData[0]?.occul_excesstearingwatering,
        occul_glarelightsensitivity: ocularData[0]?.occul_glarelightsensitivity,
        occul_eyepainorsoreness: ocularData[0]?.occul_eyepainorsoreness,
        occul_infectionofeyeorlid: ocularData[0]?.occul_infectionofeyeorlid,
        occul_stiesorchalazion: ocularData[0]?.occul_stiesorchalazion,
        occul_flashesfloatersinvision:
          ocularData[0]?.occul_flashesfloatersinvision,
        occul_tiredeyes: ocularData[0]?.occul_tiredeyes,
        occul_cataract: ocularData[0]?.occul_cataract,
        occul_crossedeyes: ocularData[0]?.occul_crossedeyes,
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: "UPDOCU",
      });
      setDiscardUpdate(false);
    }
  }, [ocularData,discardUpdate]);
  const handleSubmit = (e) => {
    e.preventDefault();
    checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    if (!isError() && error !== null && isActionCalled) {
    
      postPatientOcularHistory(ocularDetails).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Ocular history added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          // clearForm();
          // props.onHide();
          setOcularBtnUnable(false);
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    // } else {
    //   setIsActionCalled(false);
    // }

    setIsActionCalled(false);
  }, [isActionCalled]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAllSelected = (selectedValue) => {
    setOcularDetails({
      ...ocularDetails,
      occul_blur: selectedValue,
      occul_lossofvision: selectedValue,
      occul_nyctalopia: selectedValue,
      occul_distortedvision: selectedValue,
      occul_lossofsidevision: selectedValue,
      occul_doublevision: selectedValue,
      occul_dryness: selectedValue,
      occul_mucousdischarge: selectedValue,
      occul_redness: selectedValue,
      occul_frontalheadache: selectedValue,
      occul_itching: selectedValue,
      occul_burning: selectedValue,
      occul_colorblindness: selectedValue,
      occul_foreignbodysensation: selectedValue,
      occul_excesstearingwatering: selectedValue,
      occul_glarelightsensitivity: selectedValue,
      occul_eyepainorsoreness: selectedValue,
      occul_infectionofeyeorlid: selectedValue,
      occul_stiesorchalazion: selectedValue,
      occul_flashesfloatersinvision: selectedValue,
      occul_tiredeyes: selectedValue,
      occul_cataract: selectedValue,
      occul_crossedeyes: selectedValue,
    });
    setAnchorEl(null);
    setOcularBtnUnable(true);
  };


  const discardHandler=()=>{
    if(ocularData && ocularData.length > 0){
      setDiscardUpdate(true);
      setOcularBtnUnable(false);
    }else{
      clearForm();
      setOcularBtnUnable(false);
    }
  }

  const clearForm = ()=>{
    setOcularDetails({
      pat_occul_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    occul_glass: "",
    occul_purchase_glass: moment(currentDate).format("YYYY-MM-DD"),
    occul_cont_lense: "",
    occul_cont_purchase_lense: moment(currentDate).format("YYYY-MM-DD"),
    occul_brand: "",
    occul_blur: "",
    occul_lossofvision: "",
    occul_nyctalopia: "",
    occul_distortedvision: "",
    occul_lossofsidevision: "",
    occul_doublevision: "",
    occul_dryness: "",
    occul_mucousdischarge: "",
    occul_redness: "",
    occul_frontalheadache: "",
    occul_itching: "",
    occul_burning: "",
    occul_colorblindness: "",
    occul_foreignbodysensation: "",
    occul_excesstearingwatering: "",
    occul_glarelightsensitivity: "",
    occul_eyepainorsoreness: "",
    occul_infectionofeyeorlid: "",
    occul_stiesorchalazion: "",
    occul_flashesfloatersinvision: "",
    occul_tiredeyes: "",
    occul_cataract: "",
    occul_crossedeyes: "",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: actionType,
    })
  }
  return (
    <>
      <Grid
        container
        spacing={2}
        className={classes.formBox}
        style={{ border: "1px solid #DED9D9" }}
      >
        {enable && (
          <Grid
            item
            xs={12}
            md={12}
            className={classes.textGroup}
            style={{
              border: "1px solid #DED9D9",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <IconButton
              id="basic-button"
              aria-controls={openDown ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openDown ? "true" : undefined}
              onClick={(e) => {
                handleClick(e);
              }}
            >
              <MoreIcon />
            </IconButton>

            <Menu
              className={classes.menuBox}
              id="basic-menu"
              anchorEl={anchorEl}
              open={openDown}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => handleAllSelected("Y")}>
              Set All Yes
              </MenuItem>

              <MenuItem onClick={() => handleAllSelected("N")}>
              Set All No
              </MenuItem>
              <MenuItem onClick={() => handleAllSelected("U")}>
              Set All Unknown
              </MenuItem>
              <MenuItem onClick={() => handleAllSelected("")}>
                Clear All
              </MenuItem>
            </Menu>
          </Grid>
        )}
        {ocularHistory.map((element, i) => {
          return (
            <>
              <Grid
                item
                xs={12}
                md={element.mdSize}
                className={classes.textGroup}
                style={{ border: "1px solid #DED9D9" }}
              >
                {element.label && (
                  <Typography
                    className={classes.formHeading}
                    style={{ fontWeight: "500", color: "#4E97FD" }}
                  >
                    {element.label}{" "}
                    {element.required === "true" ? (
                      <span className={classes.required}>*</span>
                    ) : null}
                  </Typography>
                )}
                {element.type ? (
                  <>
                    {element.type === "select" ? (
                      <FormControl
                        fullWidth
                        className={classes.dropdownFormControl}
                      >
                        <Select
                          name={element.key}
                          className={classes.selectDropdown}
                          placeholder={element.placeHolder}
                          value={
                            ocularDetails && ocularDetails[element.key] === null
                              ? ""
                              : ocularDetails && ocularDetails[element.key]
                          }
                          onChange={async (e) => {
                            setOcularDetails({
                              ...ocularDetails,
                              [element.key]: e.target.value,
                            });
                            setOcularBtnUnable(true);
                          }}
                        >
                          <MenuItem disabled value="">Select</MenuItem>
                          {element.key === "occul_brand" && frameBrandList
                          ? frameBrandList.map((item) => (
                              <MenuItem
                                key={item.attribute_id}
                                value={item?.attribute_id}
                              >
                                
                                {item.attribute_val}
                              </MenuItem>
                            ))
                          :element.options.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : element.type === "text" ? (
                      <TextField disabled={!enable}
                        required
                        name={element.key}
                        placeholder={element.placeHolder}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                        }}
                        value={
                          ocularDetails && ocularDetails[element.key] === null
                            ? ""
                            : ocularDetails && ocularDetails[element.key]
                        }
                        onChange={async (e) => {
                          setOcularDetails({
                            ...ocularDetails,
                            [element.key]: e.target.value,
                          });
                          setOcularBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                    ) : element.type === "date" ||
                      element.type === "datetime-local" ? (
                      <>
                      <TextField disabled={!enable}
                        id={element.key}
                        name={element.key}
                        placeholder={element.placeHolder}
                        type={element.type}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          min: `${currentDateTime}`,
                          max: `${currentDate}`,
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={
                          ocularDetails && ocularDetails[element.key] === null
                            ? ""
                            : ocularDetails && ocularDetails[element.key]
                        }
                        onChange={async (e) => {
                          setOcularDetails({
                            ...ocularDetails,
                            [element.key]: e.target.value,
                          });
                          setOcularBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                      <Fade in={error}>
                          <Typography
                            color="secondary"
                            className={classes.errorMessage}
                          >
                            {error && error[element.key]}
                          </Typography>
                        </Fade>
                      </>
                    ) : element.type === "radio" ? (
                      <RadioGroup 
                        row
                        value={
                          ocularDetails[element.key] &&
                          ocularDetails[element.key] === "Y"
                            ? "Yes"
                            : ocularDetails[element.key] === "N"
                            ? "No"
                            : ocularDetails[element.key] === "U"
                            ? "Unknown"
                            : ""
                        }
                        onClick={(e) => {
                          console.log("clicked down");
                          let selectedValue;
                          switch (e.target.value) {
                            case "Yes":
                              selectedValue = "Y";
                              break;
                            case "No":
                              selectedValue = "N";
                              break;
                            case "Unknown":
                              selectedValue = "U";
                              break;
                            default:
                              selectedValue = "";
                          }

                          setOcularDetails({
                            ...ocularDetails,
                            [element.key]: selectedValue,
                          });
                          setOcularBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      >
                        {element.radioValue.map((item) => (
                          <FormControlLabel disabled={!enable}
                            size="small"
                            style={{ padding: "0px 6px" }}
                            value={item}
                            control={<Radio />}
                            label={item}
                            className={classes.smallRadioButton}
                          />
                        ))}
                      </RadioGroup>
                    ) : element.type === "checkbox" ? (
                      <>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              // value={ocularDetails[element.key] && ocularDetails[element.key] ==="Y" ? "Yes":ocularDetails[element.key] ==="N" ?"No" :""}
                              control={
                                <Checkbox disabled={!enable}
                                  checked={
                                    ocularDetails[element.key] &&
                                    ocularDetails[element.key] === "Y"
                                  }
                                  onChange={(e) => {
                                    const selectedValue = e.target.checked
                                      ? "Y"
                                      : "N";
                                    setOcularDetails({
                                      ...ocularDetails,
                                      [element.key]: selectedValue,
                                    });
                                    setOcularBtnUnable(true);
                                    setShowDiscard(true);
                                  }}
                                />
                              }
                              label={element.lbltitle}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </FormControl>
                      </>
                    ) : (
                      <TextField disabled={!enable}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        placeholder="Type here..."
                        variant="outlined"
                        multiline
                        rows={3}
                        inputProps={{
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        value={
                          ocularDetails && ocularDetails[element.key] === null
                            ? ""
                            : ocularDetails && ocularDetails[element.key]
                        }
                        onChange={async (e) => {
                          setOcularDetails({
                            ...ocularDetails,
                            [element.key]: e.target.value,
                          });
                          setOcularBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Typography
                      className={classes.formHeading}
                      style={{
                        fontWeight: "600",

                        color: "#4E97FD",
                      }}
                    >
                      {element.title}
                    </Typography>
                  </>
                )}
              </Grid>
            </>
          );
        })}
        <Grid className={classes.btnGroup} item xs={12} style={{ display: "flex", justifyContent: "end" }}>
        {showDiscard && (
            <Button
              className={classes.btnleftOutline}
              variant="outlined"
              style={{ marginRight: "10px", fontSize: "12px" }}
              size="medium"
              onClick={discardHandler}
            >
              Discard
            </Button>
          )}
          {enable &&
           <Button disabled={!ocularBtnUnable}
           style={{ display: "flex", float: "right" }}
           onClick={(e) => handleSubmit(e)}
           className={classes.btnlRightDark}
           variant="contained"
           size="medium"
           color="secondary"
         >
           Save
         </Button>
          }
         
        </Grid>
      </Grid>

      {/* </TableWidget> */}
    </>
  );
}
