import React, { useState, useEffect } from "react";
import {  Grid } from "@material-ui/core";

import {  loadState } from "../../../hooks/helper";
import TableWidget from "../../Widget/TableWidget";

import TableDesign from "../../common/table/TableDesign";
import { TableRow, TableCell } from "@material-ui/core";
// styles
import useStyles from "../styles";

import {  getBinoTestData } from "../../../services/ServiceLayer";
import HealthChart from './HealthChart'



export default function Binocular(props) {
  const tableHeadings = [
    {
      label: "Type",
    },
    {
      label: "Horizontal Distance",
    },
    {
      label: "Horizontal Near",
    },
    {
      label: "Vertical Distance",
    },
    {
      label: "Vertical Near",
    },
    {
      label: "Eye Position",
    },
  
  
  ];
  const { patientId } = props;
 



  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [binoTestData, setBinoTestData] = useState([]);
  useEffect(() => {
    getBinoTestData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELBNO`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setBinoTestData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);

  return (
    <>
   
      <Grid container spacing={4} style={{ width: "100%" }}>
        
        <Grid item xs={12} >
        <div style={{background:'#5DB3D9',padding:'10px',color:'#fff',textAlign:'center',borderRadius:'5px',marginBottom:'2px'}}>Binocular</div>
          {/* <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {binoTestData && binoTestData?.length ? (
                binoTestData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        

                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          Phorias <br/>
                          Vergence
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row?.ph_hz_dis} <br/>
                          {row?.vg_hz_dis}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.ph_hz_nr} <br/>
                          {row?.vg_hz_nr}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.ph_vt_dis} <br/>
                          {row?.vg_vt_dis}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.ph_vt_nr} <br/>
                          {row?.vg_vt_nr}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.ph_hz_eye_val} <br/>
                          {row?.vg_vt_eye_val}
                        </TableCell>
                       
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget> */}
          <HealthChart />
        </Grid>
      </Grid>
    </>
  );
}
