import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import { toast } from "react-toastify";
import Popup from "../../common/modal/Popup";
// styles
import useStyles from "./styles";
import {
  postCompanyTax,
  getCompanyList,
  getCompanyStoreData,
  getMetaData,
} from "../../../services/ServiceLayer";
import moment from "moment";
import {
  handleKeyPress,
  currentUser,
  validateInputAmount,
  loadState,
  validateTaxInputAmount,
  validateTaxInputAmountPaste,
} from "../../../hooks/helper";
export default function ManageEditTaxRate(props) {
  toast.configure();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const { selectclient } = props;
  console.log("selectclient", selectclient);
  const [compId, setCompId] = useState(0);
  const [storeId, setStoreId] = useState(0);

  //
  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [open, setOpen] = React.useState(false);
  const [storeList, setStoreList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  // list meta data
  const [listData, setListData] = useState([]);
  const [taxFormValues, setTaxFormValues] = useState([]);
  // call meta data
  const metaData = loadState().metaData;
  useEffect(() => {
    setListData(metaData);
    setCompId(selectclient === null ? 0 : selectclient[0]);
    setStoreId(selectclient === null ? 0 : selectclient[1]);
  }, []);

  useEffect(() => {
    console.log("listData", listData);
    if (listData.length > 0) {
      const taxFormValues = listData[0].companyTax;

      setTaxFormValues(taxFormValues);
    }
  }, [listData]);
  const hideHandler = () => {
    console.log("hide handler called");
    clearForm();
    props.onHide();
  };

  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedStore, setSelectedStore] = useState("");
  useEffect(() => {
    getCompanyStoreData(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELLOC`
    ).then((response) => {
      console.log(response);
      setCompanyList(response.data);
    });
  }, []);
  const handleCompanySelect = (event) => {
    const selectedCompanyName = event.target.value;
    setSelectedCompany(selectedCompanyName);
    const selectedCompanyData = companyList.find(
      (company) => company.comp_id === selectedCompanyName
    );
    if (selectedCompanyData) {
      console.log("selectedCompanyData", selectedCompanyData);
      console.log("selectedCompanyData", selectedCompanyData?.store_info);
      setStoreList(selectedCompanyData?.store_info);
    } else {
      setStoreList([]);
    }
  };
  const [actionType, setActionType] = useState("UPDTAX");
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [companyTaxDetail, setCompanyTaxDetail] = useState({
    comp_id: compId,
    store_id: storeId,
    tax_type_id: 2,
    tax_1_1: "7.0",
    tax_2_1: "0.0",
    tax_1_2: "7.0",
    tax_2_2: "0.0",
    tax_1_3: "7.0",
    tax_2_3: "0.0",
    tax_1_4: "7.0",
    tax_2_4: "0.0",
    tax_1_5: "7.0",
    tax_2_5: "0.0",
    tax_1_6: "7.0",
    tax_2_6: "0.0",
    tax_1_7: "7.0",
    tax_2_7: "0.0",
    is_active: "",
    updated_by: currentUser(),
    access_location: "",
    opt_type: "ADDTAX",
  });
  useEffect(() => {
    if (selectclient) {
      setCompanyTaxDetail({
        comp_id: selectclient?.comp_id,
        store_id: selectclient?.store_id,
        tax_type_id: selectclient?.tax_type_id,
        tax_type:selectclient?.tax_type,
        tax_1: selectclient?.tax_1,
        tax_2: selectclient?.tax_2,
        is_active: "Y",
        updated_by: currentUser(),
        access_location: "",
        opt_type: actionType,
      });
    }
  }, [selectclient]);
  const clearForm = () => {
    // Define a function to clear the form fields
    setCompanyTaxDetail({
      comp_id: compId,
      store_id: storeId,
      tax_type_id: 2,
      tax_type: "",
      tax_1_1: "7.0",
      tax_2_1: "0.0",
      tax_1_2: "7.0",
      tax_2_2: "0.0",
      tax_1_3: "7.0",
      tax_2_3: "0.0",
      tax_1_4: "7.0",
      tax_2_4: "0.0",
      tax_1_5: "7.0",
      tax_2_5: "0.0",
      tax_1_6: "7.0",
      tax_2_6: "0.0",
      tax_1_7: "7.0",
      tax_2_7: "0.0",
      is_active: "",
      updated_by: currentUser(),
      access_location: "",
      opt_type: "ADDTAX",
    });
  };
  const submitFormData = () => {
    setActionType("UPDTAX");
    setCompanyTaxDetail({
      ...companyTaxDetail,
      opt_type: "UPDTAX",
    });
    setIsActionCalled(true);
  };

  const deleteFormData = () => {
    setActionType("DELTAX");
    setCompanyTaxDetail({
      ...companyTaxDetail,
      opt_type: "DELTAX",
    });
   setIsActionCalled(true);

  
  };
useEffect(()=>{
  if(isActionCalled){
    postCompanyTax(companyTaxDetail).then((response) => {
      console.log(response);
      if (response?.status?.status) {
        const successMessage = actionType ==='DELTAX' ? "Company tax delete successfully." : "Company tax update successfully.";
        toast.success(successMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });

        props.onHide();
        setIsActionCalled(false);
      } else {
        console.log("api failed");
        toast.error("Something went wrong. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        props.onHide();
      }
    });
    setIsActionCalled(false);
  }
},[isActionCalled])
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={submitFormData}
            >
              Update
            </Button>
            <Button style={{marginLeft:'10px'}}
            className={classes.fillBtn}
            variant="contained"
            size="medium"
            color="secondary"
            onClick={deleteFormData}
          >
            Delete
          </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={1}
              className={classes.formBox}
              style={{ marginBottom: "4px" }}
            >
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  Tax Types
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <Typography className={classes.formHeading}>Tax1</Typography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <Typography className={classes.formHeading}>Tax2</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1} className={classes.formBox}>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  {selectclient?.tax_type}
                </Typography>
                <Typography className={classes.formHeading}></Typography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <TextField
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder=""
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "12px",
                      fontSize: "12px",
                    },
                    maxLength: 5,
                    onKeyPress: validateTaxInputAmount,
                    onPaste: validateTaxInputAmountPaste,
                  }}
                  name={companyTaxDetail?.tax_1}
                  value={companyTaxDetail.tax_1}
                  onChange={(e) => {
                    setCompanyTaxDetail({
                      ...companyTaxDetail,
                      tax_1: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <TextField
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder=""
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "12px",
                      fontSize: "12px",
                    },
                    maxLength: 5,
                    onKeyPress: validateTaxInputAmount,
                    onPaste: validateTaxInputAmountPaste,
                  }}
                  name={companyTaxDetail?.tax_2}
                  value={companyTaxDetail.tax_2}
                  onChange={(e) => {
                    setCompanyTaxDetail({
                      ...companyTaxDetail,
                      tax_2: e.target.value,
                    });
                  }}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
