import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  Grid,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Select,
  FormControl,
  FormGroup,
  Checkbox,
  Button,
  Avatar,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";
// data

// Model
import { recallFormElements } from "../inventory_constant";
import { image } from "../../../assets/gallery.jpg";
import {
  currentUser,
  postFlagBooleanVariable,
  loadState,
} from "../../../hooks/helper";
import useStyles from "./styles";
// components

import moment from "moment";
import PageTitle from "../../PageTitle/PageTitle";
import LocationList from "./LocationList";
import TransactionList from "./TransactionList";
import MaterialsList from "./MaterialsList";
import {
  postFrameLense,
  getProcedureCode,
  getSupplierDetail,
  getManufacturerDetail,
} from "../../../services/ServiceLayer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function AddRecall() {
  const history = useHistory();
  const { state } = useLocation();
  const selectedRow = state?.selectedRow;
  const eventType = state?.event;

  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();
  var [activeTabId, setActiveTabId] = useState(0);
  const [listData, setListData] = useState([]);
  const [invSourceData, setInvSourceData] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [frameTypeData, setFrameTypeData] = useState([]);
  const [rimTypeData, setRimTypeData] = useState([]);
  const [frameShapeData, setFrameShapeData] = useState([]);
  const [frameMaterialData, setFrameMaterialData] = useState([]);
  const [profitValue, setProfitValue] = useState(0);
  const [grossPercentage, setGrossPercentage] = useState(0);
  const [prodecureCodeData, setProdecureCodeData] = useState([]);
  const [manufacturerData, setManufacturerData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [actionType, setActionType] = useState("ADDFRM");
  const [selectedRecallMethod, setSelectedRecallMethod] = useState("");
  // call meta data
  const metaData = loadState().metaData;
  useEffect(() => {
    console.log("loadState", metaData);
    setListData(metaData);
  }, []);

  useEffect(() => {
    if (listData.length > 0) {
      const genderData = listData[0].gender;
      const invSourceData = listData[0].InventorySource;
      const frameTypeData = listData[0].FrameType;
      const rimTypeData = listData[0].RimType;
      const frameShapeData = listData[0].FrameShape;
      const frameMaterialData = listData[0].FrameMaterial;
      setGenderData(genderData);
      setInvSourceData(invSourceData);
      setFrameTypeData(frameTypeData);
      setRimTypeData(rimTypeData);
      setFrameShapeData(frameShapeData);
      setFrameMaterialData(frameMaterialData);
    }
  }, [listData]);

  useEffect(() => {
    getProcedureCode(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELPRC&procedure_id=0&procedure_code=`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setProdecureCodeData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
    getManufacturerDetail(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELMAN&manufacturer=0`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setManufacturerData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
  }, []);

  useEffect(() => {
    getSupplierDetail(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSUP&supplier_id=0`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setSupplierData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
  }, []);

  const [frameLensDetail, setFrameLensDetail] = useState({
    recall_id: "0",
    type: "",
    location_id: userData?.location_id,
    list_recall: "",
    start_date: "",
    end_date: "",
    remainder_status: "",
    remainder_name: "",
    remainder_start: "",
    remainder_end: "",
    remainder_frequency: "",
    recall_method: "",
    recall_email: "",
    recall_message: "",
    recall_reason: "",
    recall_status: "",
    relevant_practice: "",
    relevant_provider: "",
    exclusion_allowed: "",
    applicable_gender: "",
    age_from: "",
    age_to: "",

    updated_by: currentUser(),
    updated_dt: currentDate,
    access_location: "access_location",
    opt_type: actionType,
  });

  const clearForm = () => {
    // Define a function to clear the form fields
  };

  const handleSaveAndMore = () => {
    // submitFrameLens(); // Save data
    // clearForm(); // Clear form fields
  };

  const handleSaveAndExit = () => {
    // submitFrameLens(); // Save data
    setTimeout(() => {
      history.push("/app/inventory"); // Redirect to inventory page
    }, 3000); // Redirect to inventory page
  };
  const handleCancel = () => {
    history.push("/app/inventory");
  };
  return (
    <>
      <ToastContainer />
      <PageTitle
        title="Recall"
        subtitle="Inventory >"
        urlLink="/app/inventory"
      />
      {/* <TableWidget noBodyPadding> */}
      <Grid
        container
        spacing={2}
        className={classes.formBox}
        style={{
          border: "1px solid #DED9D9",
          marginBottom: "16px",
          padding: "16px",
        }}
      >
        <Grid item md={12}>
          <Grid container spacing={1}>
            {recallFormElements.map((element, i) => {
              return (
                <>
                  <Grid
                    key={i}
                    item
                    xs={12}
                    md={element.mdSize}
                    className={`${classes.textGroup} ${
                      element.title ? classes.highlightedItem : ""
                    }`}
                    style={{ border: "1px solid #DED9D9", padding: "10px 4px" }}
                  >
                    <Typography
                      className={classes.formHeading}
                      style={{ fontWeight: "500", color: "#4E97FD" }}
                    >
                      {element.label}{" "}
                      {element.required === "true" ? (
                        <span className={classes.required}>*</span>
                      ) : null}
                    </Typography>
                    {element.type ? (
                      <>
                        {element.type === "select" ? (
                          <FormControl
                            fullWidth
                            className={classes.dropdownFormControl}
                          >
                            <Select
                              name={element.key}
                              className={classes.selectDropdown}
                              placeholder={element.placeHolder}
                              value={
                                frameLensDetail && frameLensDetail[element.key]
                                  ? frameLensDetail &&
                                    frameLensDetail[element.key]
                                  : ""
                              }
                              onChange={(e) => {
                                console.log("selected");
                                setFrameLensDetail({
                                  ...frameLensDetail,
                                  [element.key]: e.target.value,
                                });
                              }}
                            >
                              <MenuItem disabled value="">
                                Select
                              </MenuItem>
                              {element.key === "procedure_code" &&
                              prodecureCodeData
                                ? prodecureCodeData.map((item, index) => (
                                    <MenuItem
                                      key={index}
                                      value={item.procedure_code}
                                    >
                                      {item.procedure_code}
                                    </MenuItem>
                                  ))
                                : element.key === "supplier_id" && supplierData
                                ? supplierData.map((item, index) => (
                                    <MenuItem
                                      key={index}
                                      value={item.supplier_id}
                                    >
                                      {item.supplier_name}
                                    </MenuItem>
                                  ))
                                : element.key === "manufacturer_id" &&
                                  manufacturerData
                                ? manufacturerData.map((item, index) => (
                                    <MenuItem
                                      key={index}
                                      value={item.manufacturer_id}
                                    >
                                      {item.manufacturer_name}
                                    </MenuItem>
                                  ))
                                : element.key === "inv_source" && invSourceData
                                ? invSourceData.map((item) => (
                                    <MenuItem
                                      key={item.attribute_id}
                                      value={item.attribute_id}
                                    >
                                      {item.attribute_val}
                                    </MenuItem>
                                  ))
                                : element.key === "gender" && genderData
                                ? genderData.map((item) => (
                                    <MenuItem
                                      key={item.attribute_id}
                                      value={item.attribute_id}
                                    >
                                      {item.attribute_val}
                                    </MenuItem>
                                  ))
                                : element.key === "frametype_id" &&
                                  frameTypeData
                                ? frameTypeData.map((item) => (
                                    <MenuItem
                                      key={item.attribute_id}
                                      value={item.attribute_id}
                                    >
                                      {item.attribute_val}
                                    </MenuItem>
                                  ))
                                : element.key === "rim_type_id" && rimTypeData
                                ? rimTypeData.map((item) => (
                                    <MenuItem
                                      key={item.attribute_id}
                                      value={item.attribute_id}
                                    >
                                      {item.attribute_val}
                                    </MenuItem>
                                  ))
                                : element.key === "frame_shape_id" &&
                                  frameShapeData
                                ? frameShapeData.map((item) => (
                                    <MenuItem
                                      key={item.attribute_id}
                                      value={item.attribute_id}
                                    >
                                      {item.attribute_val}
                                    </MenuItem>
                                  ))
                                : element.key === "material_id" &&
                                  frameMaterialData
                                ? frameMaterialData.map((item) => (
                                    <MenuItem
                                      key={item.attribute_id}
                                      value={item.attribute_id}
                                    >
                                      {item.attribute_val}
                                    </MenuItem>
                                  ))
                                : element.options.map((item) => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                  ))}
                            </Select>
                          </FormControl>
                        ) : element.key === "recall_method" ? (
                          <RadioGroup row
                            className={classes.radioGroup}
                            aria-label="recall_method"
                            name="recall_method"
                            value={frameLensDetail.recall_method}
                            onChange={(e) => {
                              setSelectedRecallMethod(e.target.value); // Set the selected recall method
                              setFrameLensDetail({
                                ...frameLensDetail,
                                recall_method: e.target.value,
                              });
                            }}
                          >
                            <FormControlLabel
                              value="Email"
                              className={classes.radio}
                              control={<Radio />}
                              label="Email"
                            />
                            <FormControlLabel
                              value="Message"
                              className={classes.radio}
                              control={<Radio />}
                              label="Message"
                            />
                          </RadioGroup>
                        ) : element.type === "recall_email" ? (
                          frameLensDetail.recall_method === "Email" && (
                            <TextField
                              type="text"
                              name={element.key}
                              placeholder={element.placeHolder}
                              fullWidth
                              variant="outlined"
                              value={frameLensDetail.recall_email}
                              inputProps={{
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                                maxLength: element.maxLength,
                                onKeyPress: element.onKeyPress,
                              }}
                              onChange={(e) => {
                                setFrameLensDetail({
                                  ...frameLensDetail,
                                  recall_email: e.target.value,
                                });
                              }}
                            />
                          )
                        ) : element.type === "recall_message" ? (
                          frameLensDetail.recall_method === "Message" && (
                            <TextField
                              type="text"
                              name={element.key}
                              placeholder={element.placeHolder}
                              fullWidth
                              variant="outlined"
                              value={frameLensDetail.recall_message}
                              inputProps={{
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                                maxLength: element.maxLength,
                                onKeyPress: element.onKeyPress,
                              }}
                              onChange={(e) => {
                                setFrameLensDetail({
                                  ...frameLensDetail,
                                  recall_email: e.target.value,
                                });
                              }}
                            />
                          )
                        ) : element.type === "text" ? (
                          <TextField
                            required
                            name={element.key}
                            placeholder={element.placeHolder}
                            size="small"
                            className={classes.txtForm}
                            fullWidth
                            variant="outlined"
                            inputProps={{
                              style: {
                                height: "12px",
                                fontSize: "12px",
                              },
                              maxLength: element.maxLength,
                              onKeyPress: element.onKeyPress,
                            }}
                            value={
                              element.key === "profit"
                                ? profitValue.toFixed(2)
                                : element.key === "gross_percentage"
                                ? grossPercentage.toFixed(2)
                                : frameLensDetail &&
                                  frameLensDetail[element.key]
                            }
                            onChange={(e) => {
                              setFrameLensDetail({
                                ...frameLensDetail,
                                [element.key]: e.target.value,
                              });
                            }}
                            disabled={
                              element.key === "profit" ||
                              element.key === "gross_percentage"
                            }
                          />
                        ) : element.type === "commission_amount" ? (
                          <TextField
                            type="text"
                            className={classes.txtForm}
                            disabled={frameLensDetail.commission_type !== "Yes"} // Enable only when commission_type is "yes"
                            value={frameLensDetail.commission_amount}
                            fullWidth
                            variant="outlined"
                            size="small"
                            inputProps={{
                              style: {
                                height: "12px",
                                fontSize: "12px",
                              },
                              maxLength: element.maxLength,
                              onKeyPress: element.onKeyPress,
                            }}
                            onChange={(e) => {
                              const value = parseFloat(e.target.value);
                              setFrameLensDetail({
                                ...frameLensDetail,
                                commission_amount: isNaN(value) ? "" : value,
                              });
                            }}
                          />
                        ) : element.type === "date" ||
                          element.type === "datetime-local" ? (
                          <TextField
                            id={element.key}
                            name={element.key}
                            placeholder={element.placeHolder}
                            type={element.type}
                            size="small"
                            className={classes.txtForm}
                            fullWidth
                            variant="outlined"
                            inputProps={{
                              min: `${currentDateTime}`,
                              max: `${currentDate}`,
                              style: {
                                height: "12px",
                                fontSize: "12px",
                              },
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={
                              frameLensDetail && frameLensDetail[element.key]
                            }
                            onChange={(e) => {
                              setFrameLensDetail({
                                ...frameLensDetail,
                                [element.key]: e.target.value,
                              });
                            }}
                          />
                        ) : element.type === "radio" ? (
                          <RadioGroup row defaultValue="">
                            {element.radioValue.map((item) => (
                              <FormControlLabel
                                size="small"
                                style={{ padding: "0px 6px" }}
                                value={item}
                                control={<Radio />}
                                label={item}
                                className={classes.smallRadioButton}
                              />
                            ))}
                          </RadioGroup>
                        ) : element.type === "checkbox" ? (
                          <>
                            <Checkbox
                              name={element.key}
                              checked={
                                frameLensDetail[element.key] !== "N"
                                  ? frameLensDetail[element.key]
                                  : false
                              }
                              onChange={(e) => {
                                const value = postFlagBooleanVariable(
                                  e.target.checked
                                );
                                setFrameLensDetail({
                                  ...frameLensDetail,
                                  [element.key]: value,
                                });
                              }}
                            />
                          </>
                        ) : (
                          <TextField
                            size="small"
                            className={classes.txtForm}
                            fullWidth
                            placeholder="Type here..."
                            variant="outlined"
                            multiline
                            rows={3}
                            inputProps={{
                              style: {
                                fontSize: "12px",
                              },
                            }}
                            value={
                              frameLensDetail && frameLensDetail[element.key]
                            }
                            onChange={(e) => {
                              setFrameLensDetail({
                                ...frameLensDetail,
                                [element.key]: e.target.value,
                              });
                            }}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <Typography
                          className={classes.formHeading}
                          style={{
                            fontWeight: "600",

                            color: "#4E97FD",
                          }}
                        >
                          {element.title}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.footerBox}>
        <Button
          variant="outlined"
          className={classes.leftButton}
          style={{ marginRight: "12px" }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.rightButton}
          onClick={handleSaveAndExit}
          style={{ marginRight: "12px" }}
        >
          save & Exit
        </Button>
        {!selectedRow && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.rightButton}
            onClick={handleSaveAndMore}
          >
            save & More
          </Button>
        )}
      </div>
      {selectedRow && (
        <>
          <Paper className={classes.iconsContainer}>
            <Tabs TabIndicatorProps={{
            style: { background: "#4E97FD",width:"150px"},
          }}
              
              variant="scrollable"
              scrollButtons="auto"
              value={activeTabId}
              onChange={(e, id) => setActiveTabId(id)}
              className={classes.iconsBar}
            >
              <Tab label="Locations" classes={{ root: classes.tab }} />
              <Tab label="Transactions" classes={{ root: classes.tab }} />
            </Tabs>
          </Paper>
          {activeTabId === 0 && <LocationList />}
          {activeTabId === 1 && <TransactionList />}
          {activeTabId === 2 && <MaterialsList />}
        </>
      )}

      {/* </TableWidget> */}
    </>
  );
}
