import React from 'react'
import ExamListData from '../../components/ExamComponent/ExamListData'

export default function ExamList() {
  return (
    <>
    <ExamListData/>
    </>
  )
}
