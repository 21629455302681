import React, { useEffect, useState } from "react";
import { Button, Grid, Menu, MenuItem } from "@material-ui/core";
import {  loadState } from "../../../hooks/helper";
import TableWidget from "../../Widget/TableWidget";
import TableDesign from "../../common/table/TableDesign";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";
import useStyles from "../styles";
import SearchDiagnosis from "./SearchDiagnosis";
import { getExamProblemData, getPatientAssessmentDiagnosis } from "../../../services/ServiceLayer";
import moment from "moment";
import ManageDiagnosis from "./ManageDiagnosis";
export default function NewDiagnosis({ enable, patientId }) {
  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "ICD Code",
      align: "left",
    },
    {
      label: "Diagnosis",
      align: "left",
    },
    {
      label: "Eye",
      align: "left",
    },
    {
      label: "Status",
      align: "left",
    },
    {
      label: "Start Date",
      align: "left",
    },
    {
      label: "End Date",
      align: "left",
    },
    {
      label: "Comments",
      align: "left",
    },
    {
      label: "Last Updated By",
      align: "left",
    },
    {
      label: "Last Updated Time",
      align: "left",
    },
    {
      label: "Action",
      align: "left",
    },
  ];
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const [modalShow, setModalShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectRow, setSelectRow] = useState(null);
  const [diagnosisData, setDiagnosisData] = useState([]);
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [isUpdated, setIsUpdated] = useState(true);
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    setModalShow(false);
    setOpen(false);
    setShowDiagnosis(false);
  };
  const addDiagnosisModel = () => {
    setModalShow(true);
  };

  useEffect(() => {
    getPatientAssessmentDiagnosis(
      `patient_id=${patientId}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELDIG`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setDiagnosisData(response?.data);
        setIsUpdated(false);
      } else {
        // setIsLoader(false);
      }
    });
    setIsUpdated(false);
  }, [isUpdated]);
  const [problemList, setProblemList] = useState([]);
  useEffect(() => {
    getExamProblemData(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELPRB`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setProblemList(response?.data);
      } else {
      }
    });
  }, []);
  const [selectclient, setSelectClient] = useState();
  const [showDiagnosis, setShowDiagnosis] = useState(false);
const handleEdit=(rec)=>{
  setOpen(true);
  setAnchorEl(null);
  setSelectClient(rec);
  setShowDiagnosis(true);
 
}

const deleteEventHandler=(rec)=>{

}
  return (
    <>
    <ManageDiagnosis
        open={showDiagnosis}
        onHide={hideHandle}
        setModalShow={showDiagnosis}
        title={"Update Diagnosis"}
        selectclient={selectclient}
        patientId={patientId}
        problemList={problemList}
        isUpdated={isUpdated}
        setIsUpdated= {setIsUpdated}
      />
      <SearchDiagnosis
        open={modalShow}
        onHide={hideHandle}
        setModalShow={setModalShow}
        title={"Add Diagnosis"}
        patientId={patientId}
        isUpdated={isUpdated}
        setIsUpdated={setIsUpdated}
      />
      <Grid container spacing={4} style={{ width: "100%" }}>
        {enable && (
          <Grid>
            {" "}
            <div
              className={classes.pageTitleContainer}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Button
                style={{
                  marginRight: "8px",
                  fontSize: "10px",
                  position: "absolute",
                  borderRadius: "2px",
                  right: "60px",
                  background: "#4E97FD",
                }}
                classes={{ root: classes.button }}
                variant="contained"
                color="secondary"
                onClick={addDiagnosisModel}
              >
                Add Diagnosis
              </Button>
            </div>
          </Grid>
        )}
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {diagnosisData && diagnosisData?.length ? (
                diagnosisData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row?.icd_code}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        ></TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row?.eye_var}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row?.diag_status_var}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {moment(row.start_dt).utc().format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {moment(row.end_dt).utc().format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row?.diag_notes}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        ></TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {moment(row.created_dt).utc().format("LT")}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            id="basic-button"
                            aria-controls={openDown ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openDown ? "true" : undefined}
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem onClick={() => handleEdit(selectRow)}>
                              Edit
                            </MenuItem>

                            {/* <MenuItem
                              onClick={() => deleteEventHandler(selectRow)}
                            >
                              Delete
                            </MenuItem> */}
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
