import React, { useState } from "react";
import {

  Typography,
  Box,
  TextField,
  Select,
  MenuItem,
  Avatar,
  Button,
  Link,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
// components
import { ArrowDropDown } from "@material-ui/icons";

import Primary from "./Primary";
// styles
import useStyles from "./styles";
import Tertiary from "./Tertiary";
import Secondary from "./Secondary";
export default function Insurance({
  patientInsuranceData,
  setPatientInsuranceData,
  insuranceValues,
  additionalInsuranceValues,
  genderValues
}) {
  var classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <>
       <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Medical Insurance</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Primary
            patientInsuranceData={patientInsuranceData}
            setPatientInsuranceData={setPatientInsuranceData}
            insuranceValues={insuranceValues}
            genderValues={genderValues}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Vision Insurance</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Secondary
            patientInsuranceData={patientInsuranceData}
            setPatientInsuranceData={setPatientInsuranceData}
            insuranceValues={insuranceValues}
            genderValues={genderValues}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion >
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Additional Insurance</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Tertiary
            patientInsuranceData={patientInsuranceData}
            genderValues={genderValues}
            setPatientInsuranceData={setPatientInsuranceData}
            additionalInsuranceValues={additionalInsuranceValues}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
