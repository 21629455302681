export const formGlassElements = [
  {
    key: "lens_style",
    label: "Lens Style",
    placeHolder: "Select",
    type: "select",
    options: ["Mr.", "Mrs.", "Ms.", "Dr.", "Rev."],
    mdSize: "1",
  },

  {
    key: "anti_reflective_treatment",
    label: "Anti Reflective Treatment",

    type: "checkboax",
    mdSize: "2",
  },
  {
    key: "arrival",
    label: "Arrival",
    placeHolder: "",
    type: "date",
    mdSize: "2",
  },
  {
    key: "brand",
    label: "Brand",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },

  {
    key: "anti_smudge_treatment",
    label: "Anti-Smudge Treatment",
    type: "checkboax",
    mdSize: "2",
  },
  {
    key: "best_seller",
    label: "Best Seller",
    placeHolder: "text",
    type: "date",

    mdSize: "2",
  },

  {
    key: "material",
    label: "Material",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },

  {
    key: "uv_coating",
    label: "UV Coating",
    type: "checkboax",
    mdSize: "2",
  },
  {
    key: "source",
    label: "Source",
    placeHolder: "text",
    type: "date",

    mdSize: "2",
  },

  {
    key: "lens_type",
    label: "Lens Type",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },

  {
    key: "engraving",
    label: "Engraving",
    type: "checkboax",
    mdSize: "2",
  },
  {
    key: "status",
    label: "Status",
    placeHolder: "text",
    type: "date",

    mdSize: "2",
  },

  {
    key: "additional_type",
    label: "Additional Type",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },

  {
    key: "tint",
    label: "Tint",
    placeHolder: "",
    type: "text",

    mdSize: "2",
  },
  {
    key: "engraved",
    label: "Engraved Text",
    type: "text",
    mdSize: "2",
  },
  {
    key: "on_hand",
    label: "On Hand",
    placeHolder: "text",
    type: "date",

    mdSize: "2",
  },
  {
    key: "for_eye",
    label: "For Eye",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },

  {
    key: "tint_colour",
    label: "Tint Colour",
    placeHolder: "",
    type: "text",

    mdSize: "2",
  },

  {
    key: "unit",
    label: "Unit",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },

  {
    key: "return_allowed",
    label: "Return Allowed",
    placeHolder: "text",
    type: "date",

    mdSize: "2",
  },
  {
    key: "shape",
    label: "shape",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "unit",
    label: "12 Month Warranty",
    placeHolder: "",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "recall_status",
    label: "Recall Status",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "thickness",
    label: "Thickness",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "warrenty_price",
    label: "Warrenty Price",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "discount_until",
    label: "Discount Untill",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "suitable_for",
    label: "Suitable For",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "commission",
    label: "Commission",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "usage",
    label: "Usage",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "commission_age",
    label: "Commision % Age",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
  {
    key: "diameter",
    label: "Diameter",
    placeHolder: "",
    type: "text",
    mdSize: "1",
  },
];
