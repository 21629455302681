import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputBase,
} from "@material-ui/core";
import useStyles from "./styles";
import PurchaseHistory from "./GlassMoreDetails/PurchaseHistory";
import classNames from "classnames";
import BoughtTogether from "./GlassMoreDetails/BoughtTogether";
import ProductImage from "./GlassMoreDetails/ProductImage";
import { formGlassElements } from "./viewglass_mock";
export default function ViewGlass() {
  const history = useHistory();
  var classes = useStyles();
  var [isSearchOpen, setSearchOpen] = useState(true);
  const searchInputRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div style={{ paddingBottom: "16px" }}>
      <div className={classes.pageTitleContainer}>
        <Typography className={classes.typo} weight="medium">
          View Glass
        </Typography>
        <div className={classes.groupBtn}>
          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
            <div
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
              onClick={() => setSearchOpen(!isSearchOpen)}
            >
              {/* <SearchIcon classes={{ root: classes.headerIcon }} /> */}
            </div>
            <InputBase
              ref={searchInputRef}
              placeholder="Search by last name"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
          </div>
          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
            <div
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
            >
              {/* <SearchIcon classes={{ root: classes.headerIcon }} /> */}
            </div>
            <InputBase
              ref={searchInputRef}
              placeholder="Search by phone Number"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
          </div>
          <Button
            classes={{ root: classes.button }}
            variant="contained"
            color="secondary"
            style={{ marginRight: "4px" }}
          >
            Search
          </Button>
        </div>
      </div>
      <Grid container spacing={3} className={classes.formBox}>
        <Grid item xs={12} md={3} className={classes.textGroup}>
          <ProductImage />
        </Grid>
        <Grid
          item
          xs={12}
          md={9}
          className={classes.textGroup}
          style={{ border: "" }}
        >
          <Typography className={classes.formHeading}>
            Brand: Hydrotac
          </Typography>
          <Typography className={classes.formHeading}>
            Lens Name: OPTX 20/20 Stick On Magnification Reusable Bifocal Lenses
            - +2.50 Diopter (Pack of 2){" "}
          </Typography>
          <Grid
            container
            spacing={2}
            style={{ marginTop: "10px", border: "" }}
          >
            {formGlassElements.map((element, i) => {
              return (
                <>
                  <Grid item xs={12} md={4} className={classes.textInlineGroup}>
                    <Typography className={classes.formHeading}>
                      {element.label}
                    </Typography>
                    {element.type === "select" ? (
                      <>
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            name={element.key}
                            className={classes.selectDropdown}
                          >
                            <MenuItem value="">Select</MenuItem>
                            {/* {element.options.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))} */}
                          </Select>
                        </FormControl>
                      </>
                    ) : element.type === "date" ? (
                      <>
                        <TextField
                          name={element.key}
                          size="small"
                          type="date"
                          className={classes.txtForm}
                          fullWidth
                          placeholder="type here"
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "12px",
                              fontSize: "12px",
                              width: "140px",
                            },
                          }}
                        />
                      </>
                    ) : element.type === "checkboax" ? (
                      <>
                        <TextField
                          name={element.key}
                          size="small"
                          type="checkbox"
                          className={classes.txtForm}
                          placeholder="type here"
                        />
                      </>
                    ) : (
                      <>
                        <TextField
                          name={element.key}
                          size="small"
                          type="text"
                          className={classes.txtForm}
                          fullWidth
                          placeholder="type here"
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "12px",
                              fontSize: "12px",
                              width: "140px",
                            },
                          }}
                        />
                      </>
                    )}
                  </Grid>
                </>
              );
            })}
          </Grid>
          <Grid
            container
            spacing={2}
            style={{ marginTop: "10px", border: "" }}
          >
            <Grid item xs={12} md={4} className={classes.textInlineGroup}>
              <Typography className={classes.formHeading}>
                Use Insurance
              </Typography>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="type here"
                variant="outlined"
                inputProps={{
                  style: {
                    height: "12px",
                    fontSize: "12px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} className={classes.textInlineGroup}>
              <Typography className={classes.formHeading}>
                Insurance Name
              </Typography>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="type here"
                variant="outlined"
                inputProps={{
                  style: {
                    height: "12px",
                    fontSize: "12px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} className={classes.textInlineGroup}>
              <Typography className={classes.formHeading}>Plan</Typography>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="type here"
                variant="outlined"
                inputProps={{
                  style: {
                    height: "12px",
                    fontSize: "12px",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            style={{ marginTop: "10px", border: "" }}
          >
            <Grid item xs={12} md={4} className={classes.textInlineGroup}>
              <Typography className={classes.formHeading}>Price</Typography>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="type here"
                variant="outlined"
                inputProps={{
                  style: {
                    height: "12px",
                    fontSize: "12px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} className={classes.textInlineGroup}>
              <Typography className={classes.formHeading}>
                In Network/Out Of Network
              </Typography>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="type here"
                variant="outlined"
                inputProps={{
                  style: {
                    height: "12px",
                    fontSize: "12px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} className={classes.textInlineGroup}>
              <Typography className={classes.formHeading}>Tax</Typography>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="type here"
                variant="outlined"
                inputProps={{
                  style: {
                    height: "12px",
                    fontSize: "12px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} className={classes.textInlineGroup}>
              <Typography className={classes.formHeading}>
                Total Retail Price
              </Typography>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="type here"
                variant="outlined"
                inputProps={{
                  style: {
                    height: "12px",
                    fontSize: "12px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} className={classes.textInlineGroup}>
              <Typography className={classes.formHeading}>Copay</Typography>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="type here"
                variant="outlined"
                inputProps={{
                  style: {
                    height: "12px",
                    fontSize: "12px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} className={classes.textInlineGroup}>
              <Typography className={classes.formHeading}>
                Insurance Pays
              </Typography>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="type here"
                variant="outlined"
                inputProps={{
                  style: {
                    height: "12px",
                    fontSize: "12px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} className={classes.textInlineGroup}>
              <Typography className={classes.formHeading}>
                Patient Responsiility
              </Typography>
              <TextField
                size="small"
                className={classes.txtForm}
                fullWidth
                placeholder="type here"
                variant="outlined"
                inputProps={{
                  style: {
                    height: "12px",
                    fontSize: "12px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} className={classes.textInlineGroup}></Grid>
            <Grid item xs={12} md={4} className={classes.textInlineGroup}></Grid>
            <Grid item xs={12} md={4} className={classes.textInlineGroup}>
              <Button
                classes={{ root: classes.button }}
                variant="contained"
                color="secondary"
                style={{
                  width: "100%",
                  fontSize: "10px",
                  marginTop: "26px",
                }}
              >
                Add to Cart
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              className={classes.textInlineGroup}
            ></Grid>
            <Grid item xs={12} md={4} className={classes.textInlineGroup}>
              <Button
                classes={{ root: classes.button }}
                variant="contained"
                color="secondary"
                style={{
                  width: "100%",
                  fontSize: "10px",
                  marginTop: "26px",
                }}
              >
                Select Lenses
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <BoughtTogether />
      <PurchaseHistory />
    </div>
  );
}
