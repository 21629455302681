import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  // Checkbox,
  Switch,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../common/modal/Popup";
// styles
import useStyles from "../styles";
import moment from "moment";
import { currentUser, loadState } from "../../../hooks/helper";

export default function ManageFindMedication(props) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  //
  const formElements = [
    {
      key: "medication",
      label: "Medication",
      placeHolder: "Enter Medication",
      type: "text",

      mdSize: "6",
    },
    {
      key: "type",
      label: "Type",
      placeHolder: "select",
      type: "text",

      mdSize: "3",
    },
    {
      key: "strength",
      label: "Strength",
      placeHolder: "select",
      type: "select",
      options: [],
      mdSize: "3",
    },
    {
      key: "route",
      label: "Route",
      placeHolder: "select",
      type: "select",
      options: [],
      mdSize: "3",
    },
    {
      key: "frequency",
      label: "Frequency",
      placeHolder: "select",
      type: "select",
      options: [],
      mdSize: "3",
    },
    {
      key: "supply",
      label: "Supply",
      placeHolder: "Eter Supply",
      type: "text",
      mdSize: "3",
    },
    {
      key: "st_date",
      label: "Start Date",
      placeHolder: "select",
      type: "date",
      mdSize: "3",
    },
    {
      key: "end_date",
      label: "End Date",
      placeHolder: "select",
      type: "date",
      mdSize: "3",
    },
    {
      key: "refills_allowed",
      label: "Refills Allowed",
      placeHolder: "type here",
      type: "select",
      optionsNY: [
        { name: "Yes", value: "Y" },
        { name: "No", value: "N" },
      ],
      mdSize: "3",
    },
    {
      key: "staff_id",
      label: "Prescribed By",
      placeHolder: "Enter prescribed by",
      type: "select",
      options: [],
      mdSize: "6",
    },
    {
      key: "is_med_brand",
      label: "Brand Medically Necessary",
      type: "checkbox",
      mdSize: "4",
    },
    {
      key: "is_outside_rx",
      label: "Outside Rx",
      type: "checkbox",
      mdSize: "4",
    },
    {
      key: "med_instruction",
      label: "Instructions",
      placeHolder: "type here",
      type: "textarea",
      mdSize: "6",
    },
    {
      key: "med_pharmacy_notes",
      label: "Note to Pharmacy",
      placeHolder: "type here",
      type: "textarea",
      mdSize: "6",
    },
  ];
  console.log("props-24", props);
  const { selectclient, frequency, patientId, setDataUpdated } = props;
  console.log("selectclient", selectclient);
  const [isShow, setIsShow] = useState(false);
  //
  var classes = useStyles();

  const [maxWidth, setMaxWidth] = React.useState("md");
  const [open, setOpen] = React.useState(false);

  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
  };
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [medicineDetail, setMedicineDtail] = useState({
    pat_med_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    med_id: 0,
    medication: "",
    supply: "",
    st_date: currentDate,
    end_date: currentDate,
    refills_allowed: "",
    staff_id: "",
    is_med_brand: "N",
    is_outside_rx: "N",
    pharmecy_id: "",
    med_instruction: "",
    med_pharmacy_notes: "",
    updated_by: currentUser(),
    access_location: "",
    opt_type: "ADDMED",
  });

  useEffect(() => {
    if (selectclient) {
      setMedicineDtail({
        pat_med_id: 0,
        patient_id: patientId,
        location_id: userData?.location_id,
        med_id: selectclient?.medication_id,
        medication: selectclient?.med_sort_name,
        type: selectclient?.med_type,
        strength: selectclient?.med_strength,
        route: selectclient?.med_route,
        frequency: "",
        supply: "",
        st_date: currentDate,
        end_date: currentDate,
        refills_allowed: "",
        staff_id: "",
        is_med_brand: "N",
        is_outside_rx: "N",
        pharmecy_id: "",
        med_instruction: "",
        med_pharmacy_notes: "",
        updated_by: currentUser(),
        access_location: "",
        opt_type: "ADDMED",
      });
    }
  }, [selectclient]);

  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        // fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            {/* <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleClose}
            >
              Update
            </Button> */}
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={2}
              className={classes.formBox}
              style={{ marginBottom: "4px" }}
            >
              {formElements.map((element, i) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={classes.textGroup}
                    >
                      <Typography
                        style={{ fontSize: "13px" }}
                        className={classes.formHeading}
                      >
                        {element.label}{" "}
                        {element.required === "true" ? (
                          <span className={classes.required}>*</span>
                        ) : null}
                      </Typography>

                      {element.type === "select" ? (
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            name={element.key}
                            className={classes.selectDropdown}
                            placeholder={element.placeHolder}
                          >
                            <MenuItem value="">Select</MenuItem>
                            {element.options &&
                              element.options.map((item) => (
                                <MenuItem value={item}>{item}</MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      ) : element.type === "text" ? (
                        <TextField
                          required
                          name={element.key}
                          placeholder={element.placeHolder}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                          }}
                        />
                      ) : element.type === "date" ||
                        element.type === "datetime-local" ? (
                        <TextField
                          id={element.key}
                          name={element.key}
                          placeholder={element.placeHolder}
                          type={element.type}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            min: `${currentDateTime}`,
                            max: `${currentDate}`,
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onKeyDown={(e) => {
                            e.preventDefault(); // Prevent manual typing
                          }}
                        />
                      ) : element.type === "textarea" ? (
                        <TextField
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          placeholder="Type here..."
                          variant="outlined"
                          multiline
                          rows={3}
                          inputProps={{
                            style: {
                              fontSize: "12px",
                            },
                          }}
                        />
                      ) : element.type === "button" ? (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                          style={{
                            width: "100%",
                            fontSize: "10px",
                            marginTop: "26px",
                          }}
                        >
                          {element.btnName}
                        </Button>
                      ) : (
                        <Switch />
                      )}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
