import {
  
  handleAlphNumKeyPress,
  validateLargeAmount,
} from "../../../hooks/helper";
import { validateForAxios, validateForCyl } from "../../../hooks/lensValidation";
export const formInitialElements = [
  {
    title: "OD",
    mdSize: "1",
    // copyBtn: "true",
    // copyLabel: "Copy OD to OS",
  },
 
  {
    key: "od_sph",
    label: "Sph",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_cyl",
    label: "Cyl",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_axis",
    label: "Axis",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateForAxios,
  },
  {
    key: "od_bc",
    label: "BC",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_dia",
    label: "Dia",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_add",
    label: "Add",
    placeHolder: "text",
    type: "select",
    options: [
      "+0.25",
      "+0.5",
      "+0.75",
      "+1",
      "+1.25",
      "+1.5",
      "+1.75",
      "+2",
      "+2.25",
      "+2.5",
      "+2.75",
      "+3",
      "+3.25",
      "+3.5",
      "+3.75",
      "+4",
      "+4.25",
      "+4.5",
      "+4.75",
      "+5",
      "+5.25",
      "+5.5",
      "+5.75",
      "+6",
      "+6.25",
      "+6.5",
      "+6.75",
      "+7",
      "+7.25",
      "+7.5",
      "+8",
    ],
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_dva",
    label: "DVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_nva",
    label: "NVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_tint",
    label: "Tint",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_clr",
    label: "Color",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_qty",
    label: "Qty",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    title: "",
    mdSize: "1",
   
  },
 
  
  {
    key: "od_manufacturer",
    label: "Manufacturer",
    placeHolder: "text",
    type: "select",
    options:[],
    mdSize: "3",
    // onKeyPress: validateLargeAmount,
  },
  {
    key: "od_prodname",
    label: "Product Name",
    placeHolder: "text",
    type: "select",
    options:[],
    mdSize: "3",
    // onKeyPress: validateLargeAmount,
  },
  {
    title: "",
    mdSize: "5",
   
  },
  {
    title: "OS",
    mdSize: "1",
    // copyBtn: "true",
    // copyLabel: "Copy OS to OD",
  },
  
  {
    key: "os_sph",
    label: "Sph",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_cyl",
    label: "Cyl",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_axis",
    label: "Axis",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateForAxios,
  },
  {
    key: "os_bc",
    label: "BC",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_dia",
    label: "Dia",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_add",
    label: "Add",
    placeHolder: "text",
    type: "select",
    options: [
      "+0.25",
      "+0.5",
      "+0.75",
      "+1",
      "+1.25",
      "+1.5",
      "+1.75",
      "+2",
      "+2.25",
      "+2.5",
      "+2.75",
      "+3",
      "+3.25",
      "+3.5",
      "+3.75",
      "+4",
      "+4.25",
      "+4.5",
      "+4.75",
      "+5",
      "+5.25",
      "+5.5",
      "+5.75",
      "+6",
      "+6.25",
      "+6.5",
      "+6.75",
      "+7",
      "+7.25",
      "+7.5",
      "+8",
    ],
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_dva",
    label: "DVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_nva",
    label: "NVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_tint",
    label: "Tint",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_clr",
    label: "Color",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_qty",
    label: "Qty",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    title: "",
    mdSize: "1",
   
  },
 
  
  {
    key: "os_manufacturer",
    label: "Manufacturer",
    placeHolder: "text",
    type: "select",
    options:[],
    mdSize: "3",
    // onKeyPress: validateLargeAmount,
  },
  {
    key: "os_prodname",
    label: "Product Name",
    placeHolder: "text",
    type: "select",
    options:[],
    mdSize: "3",
    // onKeyPress: validateLargeAmount,
  },
  {
    title: "",
    mdSize: "5",
   
  },
  {
    title: "OU",
    mdSize: "1",
    // copyBtn: "true",
    // copyLabel:"Copy OS to OD"
  },
  {
    key: "ou_dva",
    label: "DVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "ou_nva",
    label: "NVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "ou_rxdate",
    label: "Rx Date",
    placeHolder: "text",
    type: "date",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
];

export const formAdditionalElements = [
  {
    key: "prescription_date",
    label: "Prescription Date",
    placeHolder: "select",
    type: "date",
    mdSize: "3",
  },
  {
    key: "expiry_date",
    label: "Expiry Date",
    placeHolder: "select",
    type: "date",
    mdSize: "3",
  },
  {
    key: "replacement_sch",
    label: "Replacement Schedule",
    placeHolder: "select",
    type: "select",
    options:[],
    mdSize: "3",
  },
  
  {
    key: "care",
    label: "Care",
    placeHolder: "select",
    type: "select",
    options:[],
    mdSize: "3",
  },
  {
    key: "internal_notes",
    label: "Notes for office only",
    placeHolder: "Type hare",
    type: "textarea",
    mdSize: "6",
    maxLength:'50',
    onKeyPress:handleAlphNumKeyPress
  },
  {
    key: "rx_notes",
    label: "Rx Notes",
    placeHolder: "Type here",
    type: "textarea",
    mdSize: "6",
    maxLength:'50',
    onKeyPress:handleAlphNumKeyPress
  },
 
 
 
];

export const formTrialContactElements = [
  {
    key: "cnt_trial",
    label: "Name",
    placeHolder: "text",
    type: "text",
    mdSize: "4",
    maxLength:'50',
    onKeyPress:handleAlphNumKeyPress
    
  },
  {
    label:true,
    mdSize: "4",
    action:'od',
    copyBtn: "true",
    copyLabel: "Copy OD to OS",
  },
  {
    // title: "OD",
    label:true,
    mdSize: "4",
    action:'os',
    copyBtn: "true",
    copyLabel: "Copy OS to OD",
  },
  // {
  //   title: "",
  //   mdSize: "8",
   
  // },
  {
    title: "OD",
    mdSize: "1",
    // copyBtn: "true",
    // copyLabel: "Copy OD to OS",
  },
 
  {
    key: "od_sph",
    label: "Sph",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForCyl,
  },
  {
    key: "od_cyl",
    label: "Cyl",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForCyl,
  },
  {
    key: "od_axis",
    label: "Axis",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForAxios,
  },
  {
    key: "od_bc",
    label: "BC",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_dia",
    label: "Dia",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_add",
    label: "Add",
    placeHolder: "text",
    type: "select",
    options: [
      "+0.25",
      "+0.5",
      "+0.75",
      "+1",
      "+1.25",
      "+1.5",
      "+1.75",
      "+2",
      "+2.25",
      "+2.5",
      "+2.75",
      "+3",
      "+3.25",
      "+3.5",
      "+3.75",
      "+4",
      "+4.25",
      "+4.5",
      "+4.75",
      "+5",
      "+5.25",
      "+5.5",
      "+5.75",
      "+6",
      "+6.25",
      "+6.5",
      "+6.75",
      "+7",
      "+7.25",
      "+7.5",
      "+8",
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_dva",
    label: "DVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_nva",
    label: "NVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_tint",
    label: "Tint",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_clr",
    label: "Color",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_qty",
    label: "Qty",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    title: "",
    mdSize: "1",
   
  },
 
  
  {
    key: "od_manufacturer",
    label: "Manufacturer",
    placeHolder: "text",
    type: "select",
    options:[],
    mdSize: "3",
    // onKeyPress: validateLargeAmount,
  },
  {
    key: "od_prodname",
    label: "Product Name",
    placeHolder: "text",
    type: "select",
    options:[],
    mdSize: "3",
    // onKeyPress: validateLargeAmount,
  },
  {
    title: "",
    mdSize: "5",
   
  },
  {
    title: "OS",
    mdSize: "1",
    // copyBtn: "true",
    // copyLabel: "Copy OS to OD",
  },
  
  {
    key: "os_sph",
    label: "Sph",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForCyl,
  },
  {
    key: "os_cyl",
    label: "Cyl",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForCyl,
  },
  {
    key: "os_axis",
    label: "Axis",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForAxios,
  },
  {
    key: "os_bc",
    label: "BC",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_dia",
    label: "Dia",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_add",
    label: "Add",
    placeHolder: "text",
    type: "select",
    options: [
      "+0.25",
      "+0.5",
      "+0.75",
      "+1",
      "+1.25",
      "+1.5",
      "+1.75",
      "+2",
      "+2.25",
      "+2.5",
      "+2.75",
      "+3",
      "+3.25",
      "+3.5",
      "+3.75",
      "+4",
      "+4.25",
      "+4.5",
      "+4.75",
      "+5",
      "+5.25",
      "+5.5",
      "+5.75",
      "+6",
      "+6.25",
      "+6.5",
      "+6.75",
      "+7",
      "+7.25",
      "+7.5",
      "+8",
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_dva",
    label: "DVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_nva",
    label: "NVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_tint",
    label: "Tint",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_clr",
    label: "Color",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_qty",
    label: "Qty",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    title: "",
    mdSize: "1",
   
  },
  
  
  {
    key: "os_manufacturer",
    label: "Manufacturer",
    placeHolder: "text",
    type: "select",
    options:[],
    mdSize: "3",
    // onKeyPress: validateLargeAmount,
  },
  {
    key: "os_prodname",
    label: "Product Name",
    placeHolder: "text",
    type: "select",
    options:[],
    mdSize: "3",
    // onKeyPress: validateLargeAmount,
  },
  {
    title: "",
    mdSize: "5",
   
  },
  {
    title: "OU",
    mdSize: "1",
    // copyBtn: "true",
    // copyLabel:"Copy OS to OD"
  },
  {
    key: "ou_dva",
    label: "DVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "ou_nva",
    label: "NVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "ou_status",
    label: "Status",
    placeHolder: "select",
    type: "select",
    options:[],
    mdSize: "2",
  },
  {
    key: "ou_rxdate",
    label: "Date",
    placeHolder: "text",
    type: "datetime-local",
    mdSize: "2",
  },
  // {
  //   key: "ou_until_date",
  //   label: "Valid Until Date",
  //   placeHolder: "text",
  //   type: "date",
  //   mdSize: "2",
  // },
];
export const formFinalContactElements = [
  {
    key: "cnt_final",
    label: "Name",
    placeHolder: "text",
    type: "text",
    mdSize: "4",
    maxLength:'50',
    onKeyPress:handleAlphNumKeyPress
    
  },
  {
    label:true,
    mdSize: "4",
    action:'od',
    copyBtn: "true",
    copyLabel: "Copy OD to OS",
  },
  {
    // title: "OD",
    label:true,
    mdSize: "4",
    action:'os',
    copyBtn: "true",
    copyLabel: "Copy OS to OD",
  },
  {
    title: "OD",
    mdSize: "1",
    // copyBtn: "true",
    // copyLabel: "Copy OD to OS",
  },
 
  {
    key: "od_sph",
    label: "Sph",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForCyl,
  },
  {
    key: "od_cyl",
    label: "Cyl",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_axis",
    label: "Axis",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForAxios,
  },
  {
    key: "od_bc",
    label: "BC",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_dia",
    label: "Dia",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_add",
    label: "Add",
    placeHolder: "text",
    type: "select",
    options: [
      "+0.25",
      "+0.5",
      "+0.75",
      "+1",
      "+1.25",
      "+1.5",
      "+1.75",
      "+2",
      "+2.25",
      "+2.5",
      "+2.75",
      "+3",
      "+3.25",
      "+3.5",
      "+3.75",
      "+4",
      "+4.25",
      "+4.5",
      "+4.75",
      "+5",
      "+5.25",
      "+5.5",
      "+5.75",
      "+6",
      "+6.25",
      "+6.5",
      "+6.75",
      "+7",
      "+7.25",
      "+7.5",
      "+8",
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_dva",
    label: "DVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_nva",
    label: "NVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_tint",
    label: "Tint",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_clr",
    label: "Color",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "od_qty",
    label: "Qty",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    title: "",
    mdSize: "1",
   
  },
 
  
  {
    key: "od_manufacturer",
    label: "Manufacturer",
    placeHolder: "text",
    type: "select",
    options:[],
    mdSize: "3",
    // onKeyPress: validateLargeAmount,
  },
  {
    key: "od_prodname",
    label: "Product Name",
    placeHolder: "text",
    type: "select",
    options:[],
    mdSize: "3",
    // onKeyPress: validateLargeAmount,
  },
  {
    title: "",
    mdSize: "5",
   
  },
  {
    title: "OS",
    mdSize: "1",
    // copyBtn: "true",
    // copyLabel: "Copy OS to OD",
  },
  
  {
    key: "os_sph",
    label: "Sph",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForCyl,
  },
  {
    key: "os_cyl",
    label: "Cyl",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForCyl,
  },
  {
    key: "os_axis",
    label: "Axis",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateForAxios,
  },
  {
    key: "os_bc",
    label: "BC",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_dia",
    label: "Dia",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_add",
    label: "Add",
    placeHolder: "text",
    type: "select",
    options: [
      "+0.25",
      "+0.5",
      "+0.75",
      "+1",
      "+1.25",
      "+1.5",
      "+1.75",
      "+2",
      "+2.25",
      "+2.5",
      "+2.75",
      "+3",
      "+3.25",
      "+3.5",
      "+3.75",
      "+4",
      "+4.25",
      "+4.5",
      "+4.75",
      "+5",
      "+5.25",
      "+5.5",
      "+5.75",
      "+6",
      "+6.25",
      "+6.5",
      "+6.75",
      "+7",
      "+7.25",
      "+7.5",
      "+8",
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_dva",
    label: "DVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_nva",
    label: "NVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_tint",
    label: "Tint",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_clr",
    label: "Color",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "os_qty",
    label: "Qty",
    placeHolder: "text",
    type: "text",
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    title: "",
    mdSize: "1",
   
  },
  
  
  {
    key: "os_manufacturer",
    label: "Manufacturer",
    placeHolder: "text",
    type: "select",
    options:[],
    mdSize: "3",
    // onKeyPress: validateLargeAmount,
  },
  {
    key: "os_prodname",
    label: "Product Name",
    placeHolder: "text",
    type: "select",
    options:[],
    mdSize: "3",
    // onKeyPress: validateLargeAmount,
  },
  {
    title: "",
    mdSize: "5",
   
  },
  {
    title: "OU",
    mdSize: "1",
    // copyBtn: "true",
    // copyLabel:"Copy OS to OD"
  },
  {
    key: "ou_dva",
    label: "DVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "ou_nva",
    label: "NVA",
    placeHolder: "text",
    type: "select",
    options: [
      "20/10",
      "20/15",
      "20/20",
      "20/25",
      "20/30",
      "20/35",
      "20/40",
      "20/45",
      "20/50",
      "20/55",
      "20/60",
      "20/65",
      "20/70",
      "20/75",
      "20/80",
      "20/85",
      "20/90",
      "20/95",
      "20/100",
      "20/105",
      "20/110",
      "20/115",
      "20/120",
      "20/125",
      "20/130",
      "20/135",
      "20/140",
      "20/145",
      "20/150",
      "20/155",
      "20/160",
      "20/165",
      "20/170",
      "20/175",
      "20/180",
      "20/185",
      "20/190",
      "20/195",
      "20/200",
      "20/205",
      "20/210",
      "20/215",
      "20/220",
      "20/225",
      "20/230",
      "20/235",
      "20/240",
      "20/245",
      "20/250",
      "20/255",
      "20/260",
      "20/265",
      "20/270",
      "20/275",
      "20/280",
      "20/285",
      "20/290",
      "20/295",
      "20/300",
      "20/305",
      "20/310",
      "20/315",
      "20/320",
      "20/325",
      "20/330",
      "20/335",
      "20/340",
      "20/345",
      "20/350",
      "20/355",
      "20/360",
      "20/365",
      "20/370",
      "20/375",
      "20/380",
      "20/385",
      "20/390",
      "20/395",
      "20/400"
    ],
    mdSize: "1",
    maxLength: "6",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "ou_status",
    label: "Status",
    placeHolder: "select",
    type: "select",
    options:[],
    mdSize: "2",
  },
  {
    key: "ou_rxdate",
    label: "Rx Date",
    placeHolder: "text",
    type: "date",
    mdSize: "2",
  },
  {
    key: "ou_until_date",
    label: "Valid Until Date",
    placeHolder: "text",
    type: "date",
    mdSize: "2",
  },
];