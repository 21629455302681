import React, { useEffect, useRef, useState } from "react";
import CanvasImage from "./CanvasImage";
import EysImage from "../../../assets/eyesImage.jpeg";
import useStyles from "../styles";
import { Delete } from "@mui/icons-material";
import { image } from "../../../assets/gallery.jpg";
import { Avatar, Button, Grid, IconButton } from "@material-ui/core";
export default function ImageGlyph({patientId}) {
  var classes = useStyles();
  const inputRef = useRef();
  const [width, setWidth] = useState("900px");
  const [height, setHeight] = useState("500px");
  const [logoUrls, setLogoUrls] = useState([]);
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [isWrongFileType, setIsWrongFileType] = useState(false);
  const upload = (e) => {
    setIsWrongFileType(false);
    const images = e.target.files;

    for (let i = 0; i < Math.min(images.length, 3); i++) {
      const fileName = images[i]?.name;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

      if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
        setLogoUrls((prevUrls) => [
          ...prevUrls,
          URL.createObjectURL(images[i]),
        ]);
      } else {
        console.log("Only jpg/jpeg and png files are allowed!");
        setIsWrongFileType(true);
        setTimeout(() => {
          setIsWrongFileType(false);
        }, 3000);
      }
    }
  };
  useEffect(() => {
    if (logo) {
      let urllink = URL.createObjectURL(logo);
      console.log("urllink", urllink);
      setLogoUrl(urllink);
    }
  }, [logo]);

  const removeImageByIndex = (index) => {
    // Create a copy of the logoUrls array without the image at the specified index
    const updatedLogoUrls = [...logoUrls];
    updatedLogoUrls.splice(index, 1);

    // Update the state to trigger a re-render
    setLogoUrls(updatedLogoUrls);
  };
  return (
    <div>
      <Grid
        container
        spacing={2}
        className={classes.formBox}
        style={{
          border: "1px solid #DED9D9",
          marginBottom: "16px",
          padding: "16px",
          background: "white",
        }}
      >
        <Grid item md={12} style={{ display: "contents" }}>
          {logoUrls ? (
            logoUrls.map((url, index) => (
              <div
                key={index}
                style={{ position: "relative", display: "flex",marginRight:'8px' }}
              >
                <Avatar
                  className={classes.avatar}
                  style={{
                    borderRadius: "0px",
                    border: "",
                    width: "128px",
                    height: "128px",
                  }}
                  src={url}
                />{" "}
                <IconButton
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={() => removeImageByIndex(index)}
                >
                  <Delete /> {/* Use your delete icon component here */}
                </IconButton>
              </div>
            ))
          ) : (
            <Avatar
              className={classes.avatar}
              sx={{
                borderRadius: "0px",
                marginTop: "10px",
              }}
              src={image}
              variant="square"
            />
          )}
          <center>
            <Button
              variant="contained"
              className={classes.blueButton}
              component="label"
              disableElevation
            >
              Upload Image
              <input
                hidden
                onChange={(e) => upload(e)}
                ref={inputRef}
                multiple
                name="myImage"
                accept="image/*"
                type="file"
              />
            </Button>
          </center>
        </Grid>
      </Grid>
    </div>
  );
}
