import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import { RiGlassesLine } from "react-icons/ri";
import { GiSpectacleLenses } from "react-icons/gi";
import { LiaEyeSolid } from "react-icons/lia";
import {
  MoreVert as MoreIcon,
  Search as SearchIcon,
  Lens,
} from "@material-ui/icons";
import TableWidget from "../Widget/TableWidget";
import TableDesign from "../common/table/TableDesign";
import useStyles from "../Settings/Tax/styles";
// data
import mock from "./receivingmock";
import { getDashboardInventory } from "../../services/ServiceLayer";
import { loadState } from "../../hooks/helper";
import { useParams } from "react-router-dom/cjs/react-router-dom";

export default function InventoryReceiving() {
  const history = useHistory();
  var classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleView = () => {
    history.push(`/app/view-inventory`);
  };
  const handleViewModify = () => {
    history.push(`/app/frame-modify`);
  };

  const tableHeadings = [
    {
      label: "#",
    },

    {
      label: "Quantity ",
      secondaryLabel: "(Unit Price)",
    },

    {
      label: "Tax ",
      secondaryLabel: "(Discount)",
    },
    {
      label: "Shipping Charge ",
      secondaryLabel: "(Other Charges)",
    },

    {
      label: "Vendor City ",
      secondaryLabel: "(Vendor Zip)",
    },

    {
      label: "Vendor Conatct ",
      secondaryLabel: "(Ventor Fax)",
    },

    {
      label: "Expected Delivery Date ",
      secondaryLabel: "(Actual Delivery Date)",
    },
    {
      label: "Order Placed By ",
      secondaryLabel: "(Order Placed Date)",
    },
    {
      label: "Verified By ",
      secondaryLabel: "(Verified Date)",
    },

    {
      label: "Notes",
    },
    {
      label: "Action",
    },
  ];
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const { activeTabVal } = useParams();
  // const tabValue = activeTabVal.split('='); // This extracts the number after the '=' sign
  console.log("activeTabVal", activeTabVal);

  const url = window.location.href; // Get the current URL
  const parts = url.split("/"); // Split the URL by '/'
  const lastPart = parts[parts.length - 1];
  const [dashboardData, setDashboardData] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const defaultActiveTabId = isNaN(lastPart) ? 0 : parseInt(lastPart, 10);
  const [activeTabId, setActiveTabId] = useState(defaultActiveTabId);

  React.useEffect(() => {
    let isMounted = true; // Flag to track whether the component is mounted
    getDashboardInventory(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSTA`
    ).then((response) => {
      console.log("response", response);
      if (isMounted) {
        if (response?.status?.status) {
          setIsLoader(false);
          setDashboardData(response?.data);
        } else {
          // toast.error(response);
          setIsLoader(false);
        }
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <>
      <div className={classes.pageTitleContainer}>
        <Typography className={classes.typo} weight="medium">
          Inventory Receiving
        </Typography>
      </div>
      <Grid container spacing={5}>
        {dashboardData.map((item, index) => (
          <>
            <Grid
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              style={{ cursor: "pointer" }}
            >
              <Card
                onClick={() => setActiveTabId(0)}
                className={`${classes.card} ${
                  activeTabId === 0 ? classes.activeCard : ""
                }`}
              >
                <CardContent
                  style={{
                    background: "#D2F1E5",
                  }}
                >
                  <Typography variant="h5" component="div">
                    {item.frame_count}
                  </Typography>

                  <Typography
                    variant="body2"
                   
                  >
                    Frames{" "}
                    <RiGlassesLine
                      style={{ fontSize: "2rem", marginLeft: "4px" }}
                    />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              style={{ cursor: "pointer" }}
            >
              <Card
                onClick={() => setActiveTabId(1)}
                className={`${classes.card} ${
                  activeTabId === 1 ? classes.activeCard : ""
                }`}
              >
                <CardContent style={{ backgroundColor: "#D4F6FA" }}>
                  <Typography variant="h5" component="div">
                    {item.spectacle_count}
                  </Typography>

                  <Typography
                    variant="body2"
                   
                  >
                    <br />
                    Spectacle Lens{" "}
                    <GiSpectacleLenses
                      style={{ fontSize: "2rem", marginLeft: "4px" }}
                    />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              style={{ cursor: "pointer" }}
              onClick={() => setActiveTabId(2)}
            >
              <Card
                onClick={() => setActiveTabId(2)}
                className={`${classes.card} ${
                  activeTabId === 2 ? classes.activeCard : ""
                }`}
              >
                <CardContent style={{ backgroundColor: "#FFF2D5" }}>
                  <Typography variant="h5" component="div">
                    {item.contactlense_count}
                  </Typography>

                  <Typography
                    variant="body2"
                   
                  >
                    <br />
                    Contact Lens
                    <LiaEyeSolid
                      style={{ fontSize: "2rem", marginLeft: "4px" }}
                    />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              style={{ cursor: "pointer" }}
              onClick={() => setActiveTabId(3)}
            >
              <Card
                onClick={() => setActiveTabId(3)}
                className={`${classes.card} ${
                  activeTabId === 3 ? classes.activeCard : ""
                }`}
              >
                <CardContent style={{ backgroundColor: "#FFE4DC" }}>
                  <Typography variant="h5" component="div">
                    {item.other_count}
                  </Typography>

                  <Typography variant="body2">
                    <br />
                    Others
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </>
        ))}
      </Grid>
      <Grid container spacing={4}>
        {activeTabId === 0 && (
          <Grid item xs={12}>
            <TableWidget noBodyPadding>
              <TableDesign
                {...{
                  title: "List of All Receiving",
                  tableHeadings,
                }}
              >
                {mock.table && mock.table.length ? (
                  mock.table.map((row, i) => {
                    if (row)
                      return (
                        <TableRow key={i} className={classes.tableBodyCell}>
                          <TableCell className={classes.tableBodyCell}>
                            {i + 1}
                          </TableCell>

                          <TableCell className={classes.tableBodyCell}>
                            {row.quantity}
                            <br />
                            <span style={{ color: "#4E97FD" }}>
                              ({row.unit_price})
                            </span>
                          </TableCell>

                          <TableCell className={classes.tableBodyCell}>
                            {row.tax}
                            <br />
                            <span style={{ color: "#4E97FD" }}>
                              ({row.discount})
                            </span>
                          </TableCell>
                          <TableCell className={classes.tableBodyCell}>
                            {row.shipping_charge}
                            <br />
                            <span style={{ color: "#4E97FD" }}>
                              ({row.other_charge})
                            </span>
                          </TableCell>

                          <TableCell className={classes.tableBodyCell}>
                            {row.ventor_city}
                            <br />
                            <span style={{ color: "#4E97FD" }}>
                              ({row.ventor_zip})
                            </span>
                          </TableCell>

                          <TableCell className={classes.tableBodyCell}>
                            {row.ventor_conatct}
                            <br />
                            <span style={{ color: "#4E97FD" }}>
                              ({row.ventor_fax})
                            </span>
                          </TableCell>

                          <TableCell className={classes.tableBodyCell}>
                            {row.expect_delivery_date}
                            <br />
                            <span style={{ color: "#4E97FD" }}>
                              ({row.actual_delivery_date})
                            </span>
                          </TableCell>
                          <TableCell className={classes.tableBodyCell}>
                            {row.order_place_by}
                            <br />
                            <span style={{ color: "#4E97FD" }}>
                              ({row.order_place_date})
                            </span>
                          </TableCell>
                          <TableCell className={classes.tableBodyCell}>
                            {row.verify_by}
                            <br />
                            <span style={{ color: "#4E97FD" }}>
                              ({row.verify_date})
                            </span>
                          </TableCell>

                          <TableCell className={classes.tableBodyCell}>
                            {row.notes}
                          </TableCell>
                          <TableCell className={classes.tableBodyCell}>
                            <IconButton onClick={handleClick}>
                              <MoreIcon />
                            </IconButton>
                            <Menu
                              className={classes.menuBox}
                              anchorEl={anchorEl}
                              open={openDown}
                              onClose={handleClose}
                            >
                              <MenuItem
                                className={classes.menuItem}
                                onClick={handleView}
                              >
                                <Lens
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                    marginRight: "4px",
                                  }}
                                />{" "}
                                View
                              </MenuItem>
                              <MenuItem className={classes.menuItem}>
                                <Lens
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                    marginRight: "4px",
                                  }}
                                />{" "}
                                Add to Cart
                              </MenuItem>
                              <MenuItem
                                className={classes.menuItem}
                                onClick={handleViewModify}
                              >
                                <Lens
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                    marginRight: "4px",
                                  }}
                                />{" "}
                                Modify
                              </MenuItem>
                              <MenuItem className={classes.menuItem}>
                                <Lens
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                    marginRight: "4px",
                                  }}
                                />{" "}
                                Delete
                              </MenuItem>
                              <MenuItem className={classes.menuItem}>
                                <Lens
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                    marginRight: "4px",
                                  }}
                                />{" "}
                                Copy
                              </MenuItem>
                              <MenuItem className={classes.menuItem}>
                                <Lens
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                    marginRight: "4px",
                                  }}
                                />{" "}
                                Recall
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan="8">No Result Found</TableCell>
                  </TableRow>
                )}
              </TableDesign>
            </TableWidget>
          </Grid>
        )}
      </Grid>
    </>
  );
}
