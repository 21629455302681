
import React from "react";
import ApexCharts from "react-apexcharts";
import { useTheme } from "@material-ui/styles";

const series = [21, 22, 10, 28, 16, 21, 13, 30];

export default function HealthChart() {
    var theme = useTheme();
    return (
        <ApexCharts
            options={themeOptions(theme)}
            series={series}
            type="donut" 
            width={350}
        />
    )
}

function themeOptions(theme) {
    return {
        dataLabels: {
            enabled: false,
        }, 
        stroke: {
            curve: "smooth",
        },
       
       
        legend: {
            show: false,
        },
    };
}