import { useState, useEffect } from "react";
import { loadState } from "../../../hooks/helper";

const useListData = () => {
  const [metaData, setListData] = useState([]);

  useEffect(() => {
    const metaData = loadState().metaData;

    console.log("loadState", metaData);

    if (metaData.length > 0) {
      const eyesType = metaData[0]?.eyeType;
      const empTypeValues = metaData[0].empType;
      const languageValues = metaData[0].language;
      const genderValues = metaData[0].gender;
      const cmpTypeValues = metaData[0].cmpType;
      const raceValues = metaData[0].race;
      const titleValues = metaData[0].title;
      const maritalValues = metaData[0].marital;
      const genderIdentificationValues = metaData[0].genderIdentification;
      const sexOrientationValues = metaData[0].sexOrientation;
      const ethinicityValues = metaData[0].ethnicity;
      const relTypeValues = metaData[0].relsType;
      const deceasedValues = metaData[0].deceased;
      const disabilityValues = metaData[0].disabilty;
      const gaurantorValues = metaData[0].gaurantor;
      const preferenceHourValues = metaData[0].preferenceHour;
      const veteranValues = metaData[0].veteran;
      const insuranceValues = metaData[0].insurance;
      const additionalInsuranceValues = metaData[0].additionalInsurance;
      const referralSource = metaData[0].resource;
      const addressStatus = metaData[0].address;
      const preferenceContactValues = metaData[0].preferenceContact;
      const thanksValues = metaData[0].thanks;
      const patientStatusValues = metaData[0].PatientStatus;
      const inactiveValues = metaData[0].PatientInactiveReason;
      const applicableRoleValues = metaData[0].referenceSource;
      const phoneTypeValues = metaData[0].PhoneType;
      const referenceSourceValues = metaData[0].referenceSource;
      const fileCategoryValues = metaData[0].FileCategory;
      const reasonType = metaData[0].reasonType;
      const impactedPart = metaData[0].impactedPart;
      const frequency = metaData[0].frequency;
      const severity = metaData[0].severity;
      const symptons = metaData[0].symptons;
      const factors = metaData[0].factors;
      const examSince = metaData[0].examSince;
      const occurseWhile = metaData[0].occurseWhile;
      const examProblemStatus = metaData[0].examproblemstatus;
      const allergySeverity = metaData[0].allergySeverity;
      const allergyOutcome = metaData[0].allergyOutcome;
      const allergyReaction = metaData[0].allergyReaction;
      const disclosureType = metaData[0].examDisclosure;
      const surgriesClassification = metaData[0].surgriesClassification;
      const surgriesOccurance = metaData[0].surgriesOccurance;
      const surgriesOutcome = metaData[0].surgriesOutcome;
      const surgriesType = metaData[0].surgriesType;
      const examInternalCDRatio = metaData[0].examInternalCDRatio;
      const examInternalVitreous = metaData[0].examInternalVitreous;
      const examInternalLens = metaData[0].examInternalLens;
      const examInternalAVRatio = metaData[0].examInternalAVRatio;
      const examInternalMacula = metaData[0].examInternalMacula;
      const examInternalOpticDisc = metaData[0].examInternalOpticDisc;
      const examInternalAVRetina = metaData[0].examInternalAVRetina;
      const examInternalVessels = metaData[0].examInternalVessels;
      const examExternalDilation = metaData[0].examExternalDilation;
      const examExternalCounsel = metaData[0].examExternalCounsel;
      const examExternalFatProlapse = metaData[0].examExternalFatProlapse;
      const examExternalPupilAPD = metaData[0].examExternalPupilAPD;
      const examExternalPupilNPI = metaData[0].examExternalPupilNPI;
      const examExternalBinocular = metaData[0].examExternalBinocular;
      const examExternalPupilRAPD = metaData[0].examExternalPupilRAPD;
      const examExternalPupilReacts = metaData[0].examExternalPupilReacts;
      const examExternalPupilShape = metaData[0].examExternalPupilShape;
      const examExternalPupilSize = metaData[0].examExternalPupilSize;
      const examExternalMethod = metaData[0].examExternalMethod;
      const examExternalEyeLid = metaData[0].examExternalEyeLid;
      const examExternalCornea = metaData[0].examExternalCornea;
      const examExternalTearFilm = metaData[0].examExternalTearFilm;
      const examExternalOrbit = metaData[0].examExternalOrbit;
      const examExternalTearIris = metaData[0].examExternalTearIris;
      const examExternalLacrimal = metaData[0].examExternalLacrimal;
      const examExternalAnteriorChamber =
        metaData[0].examExternalAnteriorChamber;
      const examExternalConjunctiva = metaData[0].examExternalConjunctiva;
      const examExternalSclera = metaData[0].examExternalSclera;
      const examExternalPreauricularNode =
        metaData[0].examExternalPreauricularNode;
      const examChartType = metaData[0].examChartType;
      const examCorrectionType = metaData[0].examCorrectionType;
      const examDominantEye = metaData[0].examDominantEye;
      const examAcuityNotes = metaData[0].examAcuityNotes;
      const examCurrentAcuity = metaData[0].examCurrentAcuity;
      const frameBrandList = metaData[0].frameBrand;
      const examMode = metaData[0].mode;
      const examBase = metaData[0].examBase;
      const frameMaterialData = metaData[0].FrameMaterial;
      const examAssessmentFollowUpStatus =
        metaData[0].examAssessmentFollowUpStatus;
      const examAssessmentFollowUpMonth =
        metaData[0].examAssessmentFollowUpMonth;
      const examEyeType = metaData[0].examEyeType;
      const examContactAddSchedule = metaData[0].examContactAddSchedule;
      const examFinalRefrecutiveUsage = metaData[0]?.examFinalRefrecutiveUsage;
      const examFinalRefrecutiveLensType =
        metaData[0]?.examFinalRefrecutiveLensType;
      const examContactAdditionalCare = metaData[0]?.examContactAdditionalCare;
      const patInteractionCategory = metaData[0]?.patInteractionCategory;
      const patInteractionStatus = metaData[0]?.patInteractionStatus;
      const patInteractionAbout = metaData[0]?.patInteractionAbout;
      const examFamilyRelation = metaData[0]?.examFamilyRelation;
      const examListStatus = metaData[0]?.examListStatus;
      setListData({
        eyesType,
        empTypeValues,
        languageValues,
        genderValues,
        cmpTypeValues,
        raceValues,
        titleValues,
        maritalValues,
        genderIdentificationValues,
        sexOrientationValues,
        ethinicityValues,
        relTypeValues,
        deceasedValues,
        disabilityValues,
        gaurantorValues,
        preferenceHourValues,
        veteranValues,
        insuranceValues,
        additionalInsuranceValues,
        referralSource,
        addressStatus,
        preferenceContactValues,
        thanksValues,
        patientStatusValues,
        inactiveValues,
        applicableRoleValues,
        phoneTypeValues,
        referenceSourceValues,
        fileCategoryValues,
        reasonType,
        impactedPart,
        frequency,
        severity,
        symptons,
        factors,
        examSince,
        occurseWhile,
        examProblemStatus,
        allergySeverity,
        allergyOutcome,
        allergyReaction,
        disclosureType,
        surgriesClassification,
        surgriesOccurance,
        surgriesOutcome,
        surgriesType,
        examInternalCDRatio,
        examInternalVitreous,
        examInternalLens,
        examInternalAVRatio,
        examInternalMacula,
        examInternalOpticDisc,
        examInternalAVRetina,
        examInternalVessels,
        examExternalDilation,
        examExternalCounsel,
        examExternalFatProlapse,
        examExternalPupilAPD,
        examExternalPupilNPI,
        examExternalBinocular,
        examExternalPupilRAPD,
        examExternalPupilReacts,
        examExternalPupilShape,
        examExternalPupilSize,
        examExternalMethod,
        examExternalEyeLid,
        examExternalCornea,
        examExternalTearFilm,
        examExternalOrbit,
        examExternalTearIris,
        examExternalLacrimal,
        examExternalAnteriorChamber,
        examExternalConjunctiva,
        examExternalSclera,
        examExternalPreauricularNode,
        examChartType,
        examCorrectionType,
        examDominantEye,
        examAcuityNotes,
        examCurrentAcuity,
        frameBrandList,
        examMode,
        examBase,
        frameMaterialData,
        examAssessmentFollowUpStatus,
        examAssessmentFollowUpMonth,
        examEyeType,
        examContactAddSchedule,
        examFinalRefrecutiveUsage,
        examFinalRefrecutiveLensType,
        examContactAdditionalCare,
        patInteractionCategory,
        patInteractionStatus,
        patInteractionAbout,
        examFamilyRelation,
        examListStatus
      });
    }
  }, []);

  return metaData;
};

export default useListData;
