import React, { useState, useEffect } from "react";

// Model
import {
  Button,
  Typography,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Popup from "../common/modal/Popup";
import moment from "moment";
import alertIcon from "./alertIcon.svg";
// styles
import useStyles from "./styles";
import { toast } from "react-toastify";
import { postServiceLense } from "../../services/ServiceLayer";
import { currentUser, loadState } from "../../hooks/helper";

export default function DeleteServicePopup({
  onHide,
  deleteModalShow,
  setDeleteModalShow,
  selectclient,
}) {
  console.log("selectclient", selectclient);
  console.log("deleteModalShow", deleteModalShow);
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const [actionType, setActionType] = useState("DELSRV");
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [isActionCalled, setIsActionCalled] = useState(false);
  toast.configure();
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setDeleteModalShow(false);
    setOpen(false);
  };
  const [companyDetail, setCompanyDetail] = useState({
    service_id: "0",
    location_id: userData?.location_id,
    service_name: " ",
    is_send_to_lab: "",
    is_print_order: "",
    procedure_code: "",
    procedure_code_extension: "",
    service_duration: "",
    service_color: "",
    retail_price: "",
    item_cost: "",
    is_commission_prod: "",

    commission_amount: "0",
    spiff_amount: "",
    is_appointment_require: "",
    notes: " ",
    service_frequency: "",
    service_tax1: "",
    service_tax2: "",
    is_active: "",
    created_dt: "",
    updated_by: currentUser(),
    updated_dt: "",
    access_location: " ",
    opt_type: actionType,
  });
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setCompanyDetail({
        service_id: "0",
        location_id: userData?.location_id,
        service_name: " ",
        is_send_to_lab: "",
        is_print_order: "",
        procedure_code: "",
        procedure_code_extension: "",
        service_duration: "",
        service_color: "",
        retail_price: "",
        item_cost: "",
        is_commission_prod: "",

        commission_amount: "0",
        spiff_amount: "",
        is_appointment_require: "",
        notes: " ",
        service_frequency: "",
        service_tax1: "",
        service_tax2: "",
        is_active: "",
        created_dt: "",
        updated_by: currentUser(),
        updated_dt: "",
        access_location: " ",
        opt_type: actionType,
      });
    } else {
      setCompanyDetail({
        service_id: selectclient?.service_id,
        location_id: userData?.location_id,
        service_name: selectclient?.service_name,
        is_send_to_lab: selectclient?.is_send_to_lab,
        is_print_order: selectclient?.is_print_order,
        procedure_code: selectclient?.procedure_code,
        procedure_code_extension: selectclient?.procedure_code_extension,
        service_duration: selectclient?.service_duration,
        service_color: selectclient?.service_color,
        retail_price: selectclient?.retail_price,
        item_cost: selectclient?.item_cost,
        is_commission_prod: selectclient?.is_commission_prod,
        is_appointment_require: selectclient?.is_appointment_require,

        commission_amount: selectclient?.commission_amount,
        spiff_amount: selectclient?.spiff_amount,
        notes: selectclient?.notes,
        service_frequency: selectclient?.service_frequency,
        service_tax1: selectclient?.service_tax1,
        service_tax2: selectclient?.service_tax2,
        is_active: selectclient?.is_active,
        created_dt: "",
        updated_by: currentUser(),
        updated_dt: "",
        access_location: "",
        opt_type: selectclient?.is_active === "Y" ? "DELSRV" : "REASRV",
      });
    }
  }, [selectclient]);

  const deleteFormData = () => {
    setIsActionCalled(true);
    setCompanyDetail({
      ...companyDetail,
      opt_type: selectclient?.is_active === "Y" ? "DELSRV" : "REASRV",
    });
  };

  useEffect(() => {
    console.log("isActionCalled", isActionCalled);

    if (isActionCalled) {
      postServiceLense(companyDetail).then((response) => {
        console.log("actionType", actionType);

        console.log(response);
        if (response.status.status) {
          toast.success("Service deleted successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });

          onHide();
          setDeleteModalShow(false);
          setOpen(false);
          setIsActionCalled(false);
        } else {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActionCalled(false);
        }
      });
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={deleteFormData}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <img src={alertIcon} />
            <Typography className={classes.formActiveHeading}>
              Are you sure! You want to{" "}
              {selectclient?.is_active === "Y" ? <>Delete </> : <>Re-active </>}{" "}
              this Service?
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
