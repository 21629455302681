import React from 'react'

import AddContactLens from '../../components/Inventory/Services/AddContactLens'

export default function contactDetails() {
  return (
    <>
    <AddContactLens/>
    </>
  )
}
