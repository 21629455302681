import React from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
 
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";

// components
// styles
import useStyles from "../styles";

import InternalExamSection from "./InternalExamSection";

export default function InternalMain({
  patientId,
  examInternalCDRatio,
  examInternalVitreous,
  examInternalLens,
  examInternalAVRatio,
  examInternalMacula,
  examInternalOpticDisc,
  examInternalAVRetina,
  examInternalVessels,setInternalBtnUnable,
  internalBtnUnable
}) {
  var classes = useStyles();

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Exam Section</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <InternalExamSection
            patientId={patientId}
            examInternalCDRatio={examInternalCDRatio}
            examInternalVitreous={examInternalVitreous}
            examInternalLens={examInternalLens}
            examInternalAVRatio={examInternalAVRatio}
            examInternalMacula={examInternalMacula}
            examInternalOpticDisc={examInternalOpticDisc}
            examInternalAVRetina={examInternalAVRetina}
            examInternalVessels={examInternalVessels}
            setInternalBtnUnable={setInternalBtnUnable}
                internalBtnUnable={internalBtnUnable}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
