import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../common/modal/Popup";
// styles
import useStyles from "./styles";
import {
  getUserGroupAndUser,
  getMenuAndSubmenu,
  postMenuData,
} from "../../../services/ServiceLayer";
import { currentUser,loadState } from "../../../hooks/helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function ManageMenu(props) {
  console.log("props-24", props);
  const { selectclient } = props;
  console.log("selectclient", selectclient);

  var classes = useStyles();
  const userData = loadState().user;
  const userRole =loadState().user.user_role;
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");

  const [userGroup, setUserGroup] = useState([]);
  const [userList, setUserList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [subMenu, setSubMenu] = useState([]);

  const [menuAccess, setMenuAccess] = useState({});
  const [readAccess, setReadAccess] = useState({});
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedMenu, setSelectedMenu] = useState("");
  const [subMenuItems, setSubMenuItems] = useState([]);
  const [selectedSubmenuItems, setSelectedSubmenuItems] = useState([]);
  const [subMenuIds, setSubMenuIds] = useState([]);

  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
    setSubMenuItems([]);
    setSelectedSubmenuItems([]);
    setMenuList([]);
  };

  useEffect(() => {
    getUserGroupAndUser().then((response) => {
      console.log("response", response);
      setUserGroup(response?.data);
    });
  }, []);
  useEffect(() => {
    console.log("selectedUser",selectedUser);
    getMenuAndSubmenu(`user_id=${selectedUser}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELMNU`).then((response) => {
      console.log("response", response);
      setMenuList(response?.data);
    });
  }, [selectedUser]);

  useEffect(() => {
    // Initialize menuAccess and readAccess with empty objects for all menu IDs
    const initialMenuAccess = subMenu.reduce((acc, item) => {
      acc[item.menu_id] = "N";
      return acc;
    }, {});
    setMenuAccess(initialMenuAccess);

    const initialReadAccess = subMenu.reduce((acc, item) => {
      acc[item.menu_id] = "N";
      return acc;
    }, {});
    setReadAccess(initialReadAccess);
  }, [subMenu]);

  const handleUserSelect = (event) => {
    const selectedUserName = event.target.value;
    setSelectedGroup(selectedUserName);
    const selectedUserData = userGroup.find(
      (user) => user.user_group_id === selectedUserName
    );
    if (selectedUserData) {
      setUserList(selectedUserData?.emp_info);
    } else {
      setUserList([]);
    }
  };

  const handleMenuSelect = (event) => {
    const selectedMenuName = event.target.value;
    setSelectedMenu(selectedMenuName);
    const selectedMenuData = menuList.find(
      (item) => item.menu_name === selectedMenuName
    );
    if (selectedMenuData) {
      setSubMenu(selectedMenuData.submenu);
      setSubMenuItems(
        selectedMenuData.submenu.map((item) => item.sub_menu_name)
      );
      setSelectedSubmenuItems([]);
      // Extracting only the menu_id values from the submenu array
      const menuIds = selectedMenuData?.submenu?.map((item) => item.menu_id);
      setSubMenuIds(menuIds);
    } else {
      setSubMenu([]);
      setSubMenuItems([]);
      setSelectedSubmenuItems([]);
    }
  };
  useEffect(() => {
    console.log("submenu", subMenu);
    console.log("subMenuIds", subMenuIds);
    console.log("menuList",menuList);
  }, [subMenu, subMenuIds,menuList]);
  const handleMenuAccessChange = (menuId, access) => {
    setMenuAccess((prevMenuAccess) => ({
      ...prevMenuAccess,
      [menuId]: access ? "Y" : "N",
    }));

    console.log(menuAccess)
  };

  useEffect(() => {
    console.log("menuAccess", menuAccess);
  }, [menuAccess]);

  const handleReadAccessChange = (menuId, access) => {
    setReadAccess((prevReadAccess) => ({
      ...prevReadAccess,
      [menuId]: access ? "Y" : "N",
    }));
  };

  const handleAddButtonClick = () => {
    // Convert the menuAccessData object into a comma-separated string
    const menuAccessString = Object.values(menuAccess).join(",");

    // Convert the readAccessData object into a comma-separated string
    const readAccessString = Object.values(readAccess).join(",");

    // Construct the data object in the desired format
    const dataToSend = {
      user_id: selectedUser,
      menu_id: subMenuIds.sort().join(","),
      menu_access: menuAccessString,
      read_access: readAccessString,
      is_active: "Y",
      updated_by: currentUser(),
      access_location: "",
      opt_type: "ADDMEN",
    };

    // Now you can send the data to an API endpoint, update state in the parent component, etc.
    console.log("Data to send:", dataToSend);
    postMenuData(dataToSend).then((response) => {
      console.log(response);
      if (response?.status?.status) {
        toast.success("Menu access added successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });

        // props.onHide();
      } else {
        toast.error("Something went wrong please try after sometime.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        // props.onHide();
      }
    });
  };

  const { onHide, ...props1 } = props;
  return (
    <>
      <ToastContainer position="top-right" />
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleAddButtonClick}
            >
              Add
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={1}
              className={classes.formBox}
              style={{ marginBottom: "4px" }}
            >
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  User Group
                </Typography>
                <FormControl fullWidth className={classes.dropdownFormControl}>
                  <Select
                    className={classes.selectDropdown}
                    value={selectedGroup}
                    onChange={handleUserSelect}
                    style={{ height: "32px" }}
                  >
                    <MenuItem disabled value="">
                      Select
                    </MenuItem>
                    {userGroup &&
                      userGroup.map((item) => (
                        <MenuItem value={item?.user_group_id}>
                          {item?.user_group}{" "}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <Typography className={classes.formHeading}>User</Typography>
                <FormControl fullWidth className={classes.dropdownFormControl}>
                  <Select
                    className={classes.selectDropdown}
                    value={selectedUser}
                    onChange={(event) => setSelectedUser(event.target.value)}
                    style={{ height: "32px" }}
                  >
                    <MenuItem disabled value="">
                      Select
                    </MenuItem>
                    {userList &&
                      userList.map((item) => (
                        <MenuItem value={item?.staff_id}>
                          {item?.employee_name}{" "}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  Menu Group
                </Typography>
                <FormControl fullWidth className={classes.dropdownFormControl}>
                  <Select
                    className={classes.selectDropdown}
                    value={selectedMenu}
                    onChange={handleMenuSelect}
                    style={{ height: "32px" }}
                  >
                    <MenuItem disabled value="">
                      Select
                    </MenuItem>
                    {menuList &&
                      menuList.map((item) => (
                        <MenuItem value={item?.menu_name}>
                          {item?.menu_name}{" "}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              className={classes.formBox}
              style={{
                marginTop: "4px",
                marginBottom: "4px",
                backgroundColor: "#F3F5F9",
                color: "#fff",
                padding: "4px",
              }}
            >
              <Grid item xs={12} md={4}>
                <Typography>Sub Menu</Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography>Menu Access</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography>Readonly</Typography>
              </Grid>
            </Grid>
            {subMenu &&
              subMenu.map((item) => (
                <Grid container spacing={0.5} key={item.menu_id}>
                  <Grid item xs={12} md={4}>
                    <Typography>{item.sub_menu_name}</Typography>
                    {/* Add other sub-menu item details here if needed */}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Checkbox
                      checked={menuAccess[item.menu_id] === "Y"}
                      onChange={(event) =>
                        handleMenuAccessChange(
                          item.menu_id,
                          event.target.checked
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Checkbox
                      checked={readAccess[item.menu_id] === "Y"}
                      onChange={(event) =>
                        handleReadAccessChange(
                          item.menu_id,
                          event.target.checked
                        )
                      }
                    />
                  </Grid>
                </Grid>
              ))}
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
