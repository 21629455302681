import React from 'react'
import MetaDataForm from '../../components/MetaData/MetaDataForm'

export default function AddMetaData() {
  return (
    <>
    <MetaDataForm/>
    </>
  )
}
