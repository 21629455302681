import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import { TableRow, TableCell } from "@material-ui/core";
import TableDesign from "../components/common/table/TableDesign";
import Popup from "../components/common/modal/Popup";
import {getPatientsSearch} from '../services/ServiceLayer'

// styles
import useStyles from "../components/Patient/Cases/styles";
import moment from "moment";
import { loadState } from "./helper";

export default function PatientSearchResult(props) {
 const tableHeadings = [
  {
    label: "S. No.",
  },
  {
    label: "First Name",
    align: "left !important",
  },
  {
    label: "Last Name",
    align: "left !important",
  },
  {
    label: "Phone",
  },
  {
    label: "DOB",
  },
  {
    label: "Status",
  },

];
const userData = loadState().user;
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  //
  const formElements = [

    {
      key: "lastName",
      label: "Last Name",
      placeHolder: "Enter last name",
      type: "text",
      mdSize: "5",
    },
    {
      key: "phone",
      label: "Mobile Number",
      placeHolder: "Enter Mobile name",
      type: "text",
      mdSize: "5",
    },
  ];
  console.log("props-24", props);
  const { selectclient } = props;
  console.log("selectclient", selectclient);

  const [patientSearchList, setPatientSearchList] = useState(null);
  const [searchField, setSearchField] = useState({
    lastName: "",
    phone: "",
  });
  
  //
  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");


  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
  };


  const createUpdateClient = (patient) => {
    // props = patient
    props.onHide([patient, "relation"]);
  };


  const searchClick = () => {
    getPatientsSearch(
      `contact_cell=${
        searchField?.phone === "" ? 0 : searchField?.phone
      }&location_id=${
        userData?.location_id === undefined || userData?.location_id === ""
          ? "0"
          : userData?.location_id}&lastName=${searchField?.lastName}`
    ).then((response) => {
      console.log(response);
      if (response?.status.description === "") {
        setPatientSearchList(response.data);
      } else {
        alert(response?.status.description);
      }
    });  
  };



  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={1}
              
              style={{ marginBottom: "4px" }}
            >
              {formElements.map((element, i) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={classes.textGroup}
                    >
                      <Typography className={classes.formHeading}>
                        {element.label}{" "}
                        {element.required === "true" ? (
                          <span className={classes.required}>*</span>
                        ) : null}
                      </Typography>

                      {element.type === "select" ? (
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            name={element.key}
                            className={classes.selectDropdown}
                            placeholder={element.placeHolder}
                          >
                            <MenuItem value="">Select</MenuItem>
                            {element.options.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : element.type === "text" ? (
                        <TextField
                          required
                          name={element.key}
                          placeholder={element.placeHolder}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                          }}
                        />
                      ) : element.type === "date" ||
                        element.type === "datetime-local" ? (
                        <TextField
                          id={element.key}
                          name={element.key}
                          placeholder={element.placeHolder}
                          type={element.type}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            min: `${currentDateTime}`,
                            max: `${currentDate}`,
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      ) : element.type === "textarea" ? (
                        <TextField
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          placeholder="Type here..."
                          variant="outlined"
                          multiline
                          rows={3}
                          inputProps={{
                            style: {
                              fontSize: "12px",
                            },
                          }}
                        />
                      ) : element.type === "button" ? (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                          style={{
                            width: "100%",
                            fontSize: "10px",
                            marginTop: "26px",
                          }}
                          onClick={searchClick}
                        >
                          {element.btnName}
                        </Button>
                      ) : (
                        <Checkbox />
                      )}
                    </Grid>
                  </>
                );
              })}
              <Grid item xs={12} md={2} className={classes.textGroup}>
              <Typography className={classes.formHeading}>&nbsp;</Typography>
                <Button variant="contained" size="medium" color="secondary" style={{marginTop:'6px'}} onClick={searchClick}>
                  Search
                </Button>
              </Grid>
            </Grid>
            <div style={{ marginTop: "10px", width: "inherit" }}>
            <>
                      {patientSearchList ? (
                        <TableDesign
                          {...{
                            title: "List of All Clients",
                            tableHeadings,
                          }}
                        >
                          {patientSearchList && patientSearchList.length ? (
                            patientSearchList.map((row, i) => {
                              if (row)
                                return (
                                  <TableRow
                                    onClick={() => createUpdateClient(row)}
                                    key={i}
                                    className={classes.tableBodyCell}
                                  >
                                    <TableCell
                                      className={classes.tableBodyCell}
                                    >
                                      {i + 1}
                                    </TableCell>

                                    <TableCell
                                      align="left"
                                      className={classes.tableBodyCell}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {row.first_name}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className={classes.tableBodyCell}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {row.last_name}
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      style={{ cursor: "pointer" }}
                                      className={classes.tableBodyCell}
                                    >
                                      {row.contact_cell}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{ cursor: "pointer" }}
                                      className={classes.tableBodyCell}
                                    >
                                      {moment(row.dateofbirth).utc().format('DD-MM-YYYY')}
                                    </TableCell>
                                   

                                    <TableCell
                                      align="center"
                                      style={{ cursor: "pointer" }}
                                      className={classes.tableBodyCell}
                                    >
                                      Active
                                    </TableCell>
                                  </TableRow>
                                );
                            })
                          ) : (
                            <TableRow>
                              <TableCell colSpan="8">No Result Found</TableCell>
                            </TableRow>
                          )}
                        </TableDesign>
                      ) : (
                        ""
                      )}
                    </>
                  </div>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
