import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
// styles
import useStyles from "../../styles";
import Employee from "./Employee";
import Gaurantor from "./Gaurantor";
import Referral from "./Referral";
import Guardian from "./Guardian";

export default function OtherTabs({
  patientOtherData,
  setPatientOtherData,
  gaurantorValues,
  relTypeValues,
  genderValues,
  referenceSourceValues,
  phoneTypeValues,
  thanksValues,
}) {
  var classes = useStyles();


  console.log("patientOtherData", patientOtherData);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Employer</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Employee
            patientOtherData={patientOtherData}
            setPatientOtherData={setPatientOtherData}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Gaurantor</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Gaurantor
            patientOtherData={patientOtherData}
            setPatientOtherData={setPatientOtherData}
            gaurantorValues={gaurantorValues}
            relTypeValues={relTypeValues}
            genderValues={genderValues}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Referral</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Referral
            patientOtherData={patientOtherData}
            setPatientOtherData={setPatientOtherData}
            referenceSourceValues={referenceSourceValues}
            phoneTypeValues={phoneTypeValues}
            genderValues={genderValues}
            thanksValues={thanksValues}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion >
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Gaurdian</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Guardian
            patientOtherData={patientOtherData}
            setPatientOtherData={setPatientOtherData}
            relTypeValues={relTypeValues}
            genderValues={genderValues}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
