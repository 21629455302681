import React from "react";
import TaxRateList from "../../components/Settings/Tax/TaxRateList";

export default function Client() {
  return (
    <div>
      <TaxRateList />
    </div>
  );
}
