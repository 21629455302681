import {
  handleAddressKeyPress,
  validateInputAmount,
  handleAlphNumKeyPress,
  handleKeyPress,
  handleAddressKeyPressPaste,
  validateLargeAmount,
} from "../../hooks/helper";
export const serviceFormElements = [
  {
    title: "Service Details",
    mdSize: "12",
  },
  {
    key: "service_name",
    label: "Service Name",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "service_code",
    label: "Service Code",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "service_type_id",
    label: "Service Type",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "service_code_extension",
    label: "Service Code Extension",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  // {
  //   key: "problem_description",
  //   label: "Problem Description",
  //   placeHolder: "type here",
  //   type: "select",
  //   options: ["2673"],
  //   mdSize: "3",
  // },
  // {
  //   key: "problem_code",
  //   label: "Problem Code",
  //   placeHolder: "type here",
  //   type: "text",
  //   mdSize: "3",
  //   onKeyPress: handleAddressKeyPress,
  //   onPaste:handleAddressKeyPressPaste

  // },
  // {
  //   key: "icd_code",
  //   label: "ICD code",
  //   placeHolder: "type here",
  //   type: "text",
  //   mdSize: "3",
  //   onKeyPress: handleAddressKeyPress,
  //   onPaste:handleAddressKeyPressPaste

  // },

  {
    key: "is_active",
    label: "Status",
    placeHolder: "type here",
    type: "checkbox",
    mdSize: "3",
  },
  {
    key: "is_send_to_lab",
    label: "Send to Lab",
    placeHolder: "type here",
    type: "checkbox",
    mdSize: "2",
  },
  {
    key: "is_print_order",
    label: "Print on Order",
    placeHolder: "type here",
    type: "checkbox",
    mdSize: "2",
  },

  // {
  //   key: "not_on_claim",
  //   label: "Not on Claim",
  //   placeHolder: "type here",
  //   type: "checkbox",
  //   mdSize: "2",
  // },

  {
    title: "Cost & Tax",
    mdSize: "12",
  },
  {
    key: "retail_price",
    label: "Retail",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "item_cost",
    label: "Item Cost",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "profit",
    label: "Profit",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
    disabled: true, // Disable user input in this field
  },
  {
    key: "is_commission_prod",
    label: "Commission Type",

    type: "is_commission_prod",
    mdSize: "2",
  },

  {
    key: "commission_amount",
    label: "Commission Amount",
    placeHolder: "type here",
    type: "commission_amount",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "gross_percentage",
    label: "Gross Percentage",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  // {
  //   key: "spiff_amount",
  //   label: "Discount",
  //   placeHolder: "type here",
  //   type: "text",
  //   mdSize: "2",
  //   onKeyPress: validateLargeAmount,
  // },

  {
    key: "service_tax1",
    label: "Tax 1",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "service_tax2",
    label: "Tax 2",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    title: "Appointment",
    mdSize: "12",
  },

  {
    key: "is_appointment_require",
    label: "Appointment",
    placeHolder: "type here",
    type: "checkbox",
    mdSize: "2",
  },
  {
    key: "service_color",
    label: "Color",
    placeHolder: "type here",
    type: "select",
    optionsColor: [
      {
        name: "Red",
        code: "#FF0000",
      },
      {
        name: "Blue",
        code: "#0000FF",
      },
      {
        name: "Purple",
        code: "#800080",
      },
      {
        name: "Green",
        code: "#008000",
      },
      {
        name: "Black",
        code: "#000000",
      },
      {
        name: "Orange",
        code: "#FFA500",
      },
      {
        name: "Magenta",
        code: "#FF00FF",
      },
      {
        name: "Pink",
        code: "#FFC0CB",
      },
      {
        name: "Silver",
        code: "#C0C0C0",
      },
      {
        name: "Olive",
        code: "#808000",
      },
    ],
    mdSize: "2",
  },
  {
    key: "service_frequency",
    label: "Schedule Frequency",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "service_duration",
    label: "Duration (In minutes)",
    placeHolder: "Enter in minutes",
    type: "text",
    mdSize: "2",
    maxLength: "2",
    onKeyPress: handleKeyPress,
  },

  // {
  //   key: "is_favorite",
  //   label: "Favorite",
  //   placeHolder: "type here",
  //   type: "checkbox",
  //   mdSize: "2",
  // },

  {
    key: "notes",
    label: "Notes",
    placeHolder: "Enter notes upto 1000 characters",
    type: "textarea",
    maxLength: "1000",
    mdSize: "12",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
];
export const contactFormElements = [
  {
    title: "Contact Lens Details",
    mdSize: "12",
  },
  {
    key: "brand_id",
    label: "Brand Name",
    placeHolder: "type here",
    type: "select",
    mdSize: "2",
    options: [],
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "procedure_code",
    label: "Procedure Code",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "lens_name",
    label: "Lens Name",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "lens_unit",
    label: "Lens Unit",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "replacement_schedule",
    label: "Replacement Schedule",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "structure_id",
    label: "Structure",
    placeHolder: "type here",
    type: "select",
    optionsVL: [
      {
        title: "S1",
        value: "291",
      },
      {
        title: "S2",
        value: "292",
      },
    ],
    mdSize: "2",
  },
  {
    key: "lense_sku",
    label: "SKU",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },

  {
    key: "is_hard_contact",
    label: "Hard Contact",
    placeHolder: "type here",
    type: "checkbox",
    mdSize: "2",
  },
  {
    key: "inv_source",
    label: "Source",
    placeHolder: "type here",
    type: "select",
    optionsVL: [
      {
        title: "Source1",
        value: "51",
      },
      {
        title: "Source2",
        value: "53",
      },
    ],
    mdSize: "2",
  },
  {
    key: "lense_tax1",
    label: "Tax 1",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "lense_tax2",
    label: "Tax 2",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "qty_purchased",
    label: "Qty. Purchased",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "qty_sold",
    label: "Qty. Sold",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "qty_inventory",
    label: "Qty. Inventory",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "manufacturer_id",
    label: "Manufacturer",
    placeHolder: "type here",
    type: "select",
    optionsVL: [
      {
        title: "M1",
        value: "91",
      },
      {
        title: "M2",
        value: "92",
      },
    ],
    mdSize: "2",
  },
  {
    key: "supplier_id",
    label: "Supplier",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "returnable_id",
    label: "Returnable",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    title: "Cost",
    mdSize: "12",
  },
  {
    key: "retail_price",
    label: "Retail Price",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "item_cost",
    label: "Cost",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "profit",
    label: "Profit",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },

  {
    key: "is_commission_prod",
    label: "Commission Type",
    placeHolder: "select",
    options: [],
    type: "is_commission_prod",
    mdSize: "2",
  },
  {
    key: "commission_amount",
    label: "Amount",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "gross_percentage",
    label: "Gross Percentage",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },

  {
    key: "notes",
    label: "Notes",
    placeHolder: "Enter notes upto 1000 characters",
    type: "textarea",
    maxLength: "1000",
    mdSize: "12",
  },
];
export const spectacleFormElements = [
  {
    title: "Spectacle Lens Details",
    mdSize: "12",
  },
  {
    key: "spec_brand_id",
    label: "Brand Name",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "spec_lens_name",
    label: "Lens Name",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "spec_thickness",
    label: "Thickness",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "spec_diameter",
    label: "Diameter",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },

  // {
  //   key: "spec_code",
  //   label: "Spectacle Code",
  //   placeHolder: "type here",
  //   type: "text",
  //   mdSize: "3",
  // },
  // {
  //   key: "structure_id",
  //   label: "Structure",
  //   placeHolder: "type here",
  //   type: "select",
  //   options: ["11", "12"],
  //   mdSize: "3",
  // },

  {
    key: "material_id",
    label: "Material",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "spec_design",
    label: "Design",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "is_od",
    label: "OD",
    checklbl: "OD",
    placeHolder: "type here",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "is_os",
    label: "OS",
    checklbl: "OS",
    placeHolder: "type here",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "is_ou",
    label: "OU",
    checklbl: "OU",
    placeHolder: "type here",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "unit_id",
    label: "Unit",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "spec_sku",
    label: "SKU",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },

  {
    title: "Manufacturer Details",
    mdSize: "12",
  },
  {
    key: "manufacturer_id",
    label: "Manufacturer",
    placeHolder: "type here",
    type: "select",
    options: ["11", "12"],
    mdSize: "3",
  },
  {
    key: "vsp_code",
    label: "Manufacturer SKU",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "supplier_id",
    label: "Supplier",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "inv_source",
    label: "Source",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "returnable_id",
    label: "Returnable",
    placeHolder: "type here",
    type: "select",
    options: ["11", "12"],
    mdSize: "3",
  },
  {
    key: "warranty_",
    label: "Warranty (In Month)",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },

  {
    key: "qty_purchased",
    label: "Qty. Purchased",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "qty_sold",
    label: "Qty. Sold",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "qty_inventory",
    label: "Available Quantity",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "spec_tax1",
    label: "Date First Available",
    placeHolder: "type here",
    type: "datetime-local",
    mdSize: "3",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "spec_tax2",
    label: "Status",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
    onKeyPress: validateLargeAmount,
  },

  {
    key: "spec_tax2",
    label: "Usage",
    placeHolder: "type here",
    type: "text",

    mdSize: "3",
    onKeyPress: validateLargeAmount,
  },

  {
    title: "Cost",
    mdSize: "12",
  },
  {
    key: "retail_price",
    label: "Retail Price",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "item_cost",
    label: "Item Cost",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "profit",
    label: "Profit",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "group_cost",
    label: "Group Cost",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
    onKeyPress: validateLargeAmount,
  },

  {
    key: "is_commission_prod",
    label: "Commission Type",
    options: ["Yes", "No"],
    type: "is_commission_prod",
    mdSize: "4",
  },

  {
    key: "commission_amount",
    label: "Commission Amount",
    placeHolder: "type here",
    type: "commission_amount",
    mdSize: "4",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "gross_percentage",
    label: "Gross Percentage",
    placeHolder: "type here",
    type: "text",
    mdSize: "4",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "notes",
    label: "Notes",
    placeHolder: "Enter notes upto 1000 characters",
    type: "textarea",
    maxLength: "1000",
    mdSize: "12",
  },
];
export const framFormElements = [
  {
    title: "Frame Details",
    mdSize: "12",
  },
  {
    key: "brand_id",
    label: "Brand Name",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "frame_model_name",
    label: "Style Name",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "frame_name",
    label: "Frame Name",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "frame_type_id",
    label: "Frame Type",
    placeHolder: "type here",
    type: "select",
    options: ["11", "12"],
    mdSize: "2",
  },

  {
    key: "frame_color_id",
    label: "Frame Color",
    placeHolder: "type here",
    type: "select",
    optionsColor: [
      {
        name: "Red",
        code: "#FF0000",
      },
      {
        name: "Blue",
        code: "#0000FF",
      },
      {
        name: "Purple",
        code: "#800080",
      },
      {
        name: "Green",
        code: "#008000",
      },
      {
        name: "Black",
        code: "#000000",
      },
      {
        name: "Orange",
        code: "#FFA500",
      },
      {
        name: "Magenta",
        code: "#FF00FF",
      },
      {
        name: "Pink",
        code: "#FFC0CB",
      },
      {
        name: "Silver",
        code: "#C0C0C0",
      },
      {
        name: "Olive",
        code: "#808000",
      },
    ],
    mdSize: "2",
  },
  {
    key: "frame_color_code",
    label: "Frame Color Code",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "frame_color_code",
    label: "Frame Size",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "frame_color_code",
    label: "Style Code",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },

  {
    key: "frame_shape_id",
    label: "Shape",
    placeHolder: "type here",
    type: "select",
    options: ["14", "15"],
    mdSize: "2",
  },

  {
    key: "material_id",
    label: "Material",
    placeHolder: "type here",
    type: "select",
    options: ["16", "17"],
    mdSize: "2",
  },
  {
    key: "collection",
    label: "Lens Color",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "collection",
    label: "Temple Color",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "rim_type_id",
    label: "Rim Type",
    placeHolder: "type here",
    type: "select",
    options: ["12", "13"],
    mdSize: "2",
  },
  {
    key: "gender",
    label: "Gender",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "bridge",
    label: "Bridge",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "bridge",
    label: "Bridge Size",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "collection",
    label: "Collection",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },

  {
    key: "ed",
    label: "Ed",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "a",
    label: "A (Horizontal)",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "b",
    label: "B(Vertical)",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },

  {
    key: "feature",
    label: "Features",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },

  {
    key: "temple",
    label: "Temple",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "temple",
    label: "Temple Length",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "frame_sku",
    label: "SKU",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    title: "Manufacturer Details",
    mdSize: "12",
  },
  {
    key: "manufacturer_id",
    label: "Manufacturer",
    placeHolder: "type here",
    type: "select",
    options: ["17", "18"],
    mdSize: "2",
  },

  {
    key: "manufacturer_frame_sku",
    label: "Manufacturer SKU",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "supplier_id",
    label: "Supplier",
    placeHolder: "type here",
    type: "select",
    options: ["17", "18"],
    mdSize: "2",
  },
  {
    key: "inv_source",
    label: "Source",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "returnable_id",
    label: "Returnable",
    placeHolder: "type here",
    type: "select",
    options: ["Yes", "No"],
    mdSize: "2",
  },
  {
    key: "qty_purchased",
    label: "Qty. Purchased",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "qty_sold",
    label: "Qty. Sold",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "qty_inventory",
    label: "Qty. Inventory",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "avl_quantity",
    label: "Available Quantity",
    placeHolder: "type here",
    type: "text",

    mdSize: "2",
  },
  {
    key: "avl_quantity",
    label: "Date First Available",
    placeHolder: "type here",
    type: "datetime-local",

    mdSize: "2",
  },

  {
    key: "frame_structure_id",
    label: "Status",
    placeHolder: "type here",
    type: "select",
    options: ["1", "2"],
    mdSize: "2",
  },

  {
    key: "usage",
    label: "Usage",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },

  {
    title: "Cost",
    mdSize: "12",
  },
  {
    key: "retail_price",
    label: "Retail Price",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "item_cost",
    label: "Item Cost",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    maxLength: "5",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "profit",
    label: "Profit",
    placeHolder: "type here",
    type: "text",
    maxLength: "5",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "grp_cost",
    label: "Group Cost",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    maxLength: "5",
    onKeyPress: validateLargeAmount,
  },

  {
    key: "is_commission_prod",
    label: "Commission Type",
    placeHolder: "select",
    type: "is_commission_prod",
    mdSize: "2",
  },
  {
    key: "commission_amount",
    label: "Commission Amount",
    placeHolder: "type here",
    type: "commission_amount",
    mdSize: "2",
    maxLength: "5",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "gross_percentage",
    label: "Gross Percentage",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
    onKeyPress: validateLargeAmount,
  },

  // {
  //   key: "trace_file",
  //   label: "Trace File",
  //   placeHolder: "type here",
  //   type: "text",
  //   mdSize: "2",
  // },
  // {
  //   key: "lens_range",
  //   label: "Lens Range",
  //   placeHolder: "type here",
  //   type: "text",
  //   mdSize: "2",
  // },

  {
    key: "notes",
    label: "Notes",
    placeHolder: "Enter notes upto 1000 characters",
    type: "textarea",
    maxLength: "1000",
    mdSize: "12",
  },
];

export const otherFormElements = [
  {
    title: "Other Details",
    mdSize: "12",
  },

  {
    key: "accessries_name",
    label: "Name",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "accessries_type",
    label: "Type",
    placeHolder: "type here",
    type: "select",
    options: ["17", "18"],
    mdSize: "2",
  },
  {
    key: "accessries_color",
    label: "Colour",
    placeHolder: "type here",
    type: "select",
    optionsColor: [
      {
        name: "Red",
        code: "#FF0000",
      },
      {
        name: "Blue",
        code: "#0000FF",
      },
      {
        name: "Purple",
        code: "#800080",
      },
      {
        name: "Green",
        code: "#008000",
      },
      {
        name: "Black",
        code: "#000000",
      },
      {
        name: "Orange",
        code: "#FFA500",
      },
      {
        name: "Magenta",
        code: "#FF00FF",
      },
      {
        name: "Pink",
        code: "#FFC0CB",
      },
      {
        name: "Silver",
        code: "#C0C0C0",
      },
      {
        name: "Olive",
        code: "#808000",
      },
    ],
    mdSize: "2",
  },

  {
    key: "material_id",
    label: "Material",
    placeHolder: "type here",
    type: "select",
    options: ["16", "17"],
    mdSize: "2",
  },

  {
    key: "feature_id",
    label: "Feature",
    placeHolder: "type here",
    type: "select",
    options: ["16", "17"],
    mdSize: "2",
  },

  {
    key: "size_id",
    label: "Size",
    placeHolder: "type here",
    type: "select",
    options: ["XS", "S", "M", "L", "XL"],
    mdSize: "2",
  },
  {
    key: "width",
    label: "Width",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "height",
    label: "Height",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "unit_id",
    label: "Unit",
    placeHolder: "type here",
    type: "select",
    options: ["CM", "Inch", "Feet"],
    mdSize: "2",
  },
  {
    key: "tax_1",
    label: "Tax 1",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "tax_2",
    label: "Tax 2",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "qty_purchased",
    label: "Qty. Purchased",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "qty_sold",
    label: "Qty. Sold",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "qty_inventory",
    label: "Qty. Inventory",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "manufacturer_id",
    label: "Manufacturer",
    placeHolder: "type here",
    type: "select",
    options: ["17", "18"],
    mdSize: "2",
  },
  {
    key: "supplier_id",
    label: "Supplier",
    placeHolder: "type here",
    type: "select",
    options: ["17", "18"],
    mdSize: "2",
  },
  {
    key: "returnable_id",
    label: "Returnable",
    placeHolder: "type here",
    type: "select",
    options: ["Yes", "No"],
    mdSize: "2",
  },
  {
    title: "Cost",
    mdSize: "12",
  },
  {
    key: "retail_price",
    label: "Retail Price",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "item_cost",
    label: "Item Cost",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: validateLargeAmount,
  },
  {
    key: "custome_field1",
    label: "Custom Field 1",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "custome_field2",
    label: "Custom Field 2",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "custome_field3",
    label: "Custom Field 3",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "custome_field4",
    label: "Custom Field 4",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "custome_field5",
    label: "Custom Field 5",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
];

export const procedureFormElements = [
  {
    key: "procedure",
    label: "Procedure",
    placeHolder: "type here",
    type: "text",

    mdSize: "12",
  },
  {
    key: "retail",
    label: "Retail",
    placeHolder: "type here",
    type: "text",
    mdSize: "12",
  },
  {
    key: "Cost",
    label: "Cost",
    placeHolder: "type here",
    type: "text",
    mdSize: "12",
  },
];
export const procedureLocalFormElements = [
  {
    key: "procedure",
    label: "Procedure",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "12",
  },
  {
    key: "retail",
    label: "Retail",
    placeHolder: "type here",
    type: "text",
    mdSize: "12",
  },
  {
    key: "Cost",
    label: "Cost",
    placeHolder: "type here",
    type: "text",
    mdSize: "12",
  },
];
export const recallFormElements = [
  {
    title: "Recall Details",
    mdSize: "12",
  },
  {
    key: "type",
    label: "Type",
    placeHolder: "type here",
    type: "select",
    options: ["Service", "Frame", "Spectacle Lens", "Contact Lens"],
    mdSize: "3",
  },
  {
    key: "list_recall",
    label: "List of Recall",
    placeHolder: "type here",
    type: "select",
    options: [""],
    mdSize: "3",
  },
  {
    key: "start_date",
    label: "Start Date",
    placeHolder: "type here",
    type: "date",
    mdSize: "3",
  },
  {
    key: "end_date",
    label: "End Date",
    placeHolder: "type here",
    type: "date",
    mdSize: "3",
  },
  {
    key: "remainder_name",
    label: "Remainder Name",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "remainder_status",
    label: "Remainder Status",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },

  {
    key: "remainder_start",
    label: "Remainder Start",
    placeHolder: "type here",
    type: "date",
    mdSize: "3",
  },
  {
    key: "remainder_end",
    label: "Remainder End",
    placeHolder: "type here",
    type: "date",
    mdSize: "3",
  },
  {
    key: "remainder_frequency",
    label: "Remainder Frequency",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "recall_method",
    label: "Recall Method",
    placeHolder: "type here",
    type: "recall_method",
    options: ["Email", "Message"],
    mdSize: "3",
  },
  {
    key: "recall_email",
    label: "Recall Email",
    placeHolder: "type here",
    type: "recall_email",
    mdSize: "3",
  },
  {
    key: "recall_message",
    label: "Recall Message",
    placeHolder: "type here",
    type: "recall_message",
    mdSize: "6",
  },
  {
    key: "recall_reason",
    label: "Recall Reason",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "recall_status",
    label: "Recall Status",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "relevant_practice",
    label: "Relevant Practice",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "relevant_provider",
    label: "Relevant Provider",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "exclusion_allowed",
    label: "Exclusion Allowed",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "applicable_gender",
    label: "Applicable Gender",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "3",
  },
  {
    key: "age_from",
    label: "Age From",
    placeHolder: "type here",
    type: "date",
    mdSize: "3",
  },
  {
    key: "age_to",
    label: "Age To",
    placeHolder: "type here",
    type: "date",
    mdSize: "3",
  },
];
