import React from "react";

import HealthTab from "../../../../ExamComponent/HealthTabs/HealthTab";
export default function HistoryList({patientId}) {
  return (
    <>
      <HealthTab patientId={patientId} enable={false} />
    </>
  );
}
