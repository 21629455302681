// import React, { useState } from "react";
// import {
//   Grid,
//   Typography,
//   TextField,
//   Select,
//   MenuItem,
//   FormControl,
// } from "@material-ui/core";

// import moment from "moment";
// import useStyles from "./styles";
// // import {getFlagVariableString} from '../../../../../hooks/helper'
// import { formProfileElements } from "./Patient_Constant";
// export default function Profile({
//   patientData,
//   setPatientData,
//   titleValues,
//   genderValues,
//   maritalValues,
//   genderIdentificationValues,
//   sexOrientationValues,
//   preferenceContactValues,
//   preferenceHourValues,
//   languageValues,
// }) {
//   console.log("preferenceContactValues===", preferenceContactValues)
//   console.log("patientData", patientData);
//   const formatedDate = (val) => {
//     var options = { year: "numeric", month: "2-digit", day: "2-digit" };
//     return new Date(val).toLocaleDateString("en-GB", options);
//   };
//   const currentDate = moment().format("YYYY-MM-DD");
//   const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
//   var classes = useStyles();

//   return (
//     <>
//       <Grid container spacing={2} className={classes.formBox}>
//         {formProfileElements.map((element, i) => {
//           return (
//             <>
//               {patientData ? (
//                 <Grid
//                   item
//                   xs={12}
//                   md={element.mdSize}
//                   className={classes.textGroup}
//                 >
//                   <Typography className={classes.formHeading}>
//                     {element.label}{" "}
//                     {element.required === "true" ? (
//                       <span className={classes.required}>*</span>
//                     ) : null}
//                   </Typography>

//                   {element.type === "select" ? (
//                     <FormControl
//                       fullWidth
//                       className={classes.dropdownFormControl}
//                     >
//                       <Select
//                         name={element.key}
//                         className={classes.selectDropdown}
//                         placeholder={element.placeHolder}
//                         value={
//                           patientData && patientData[element.key] === null
//                             ? ""
//                             : patientData && patientData[element.key]
//                         }
//                         disabled
//                         onChange={async (e) => {
//                           setPatientData({
//                             ...patientData,
//                             [element.key]: e.target.value,
//                           });
//                         }}
//                       >
//                         <MenuItem disabled value="">Select</MenuItem>
//                         {element.key == "title" && titleValues
//                           ? titleValues.map((item) => (
//                             <MenuItem
//                               key={item.attribute_id}
//                               value={item.attribute_id}
//                             >
//                               {item.attribute_val}
//                             </MenuItem>
//                           ))
//                           : element.key == "gender" && genderValues
//                             ? genderValues.map((item) => (
//                               <MenuItem
//                                 key={item.attribute_id}
//                                 value={item.attribute_id}
//                               >
//                                 {item.attribute_val}
//                               </MenuItem>
//                             ))
//                             : element.key == "marital" && maritalValues
//                               ? maritalValues.map((item) => (
//                                 <MenuItem
//                                   key={item.attribute_id}
//                                   value={item.attribute_id}
//                                 >
//                                   {item.attribute_val}
//                                 </MenuItem>
//                               ))
//                               : element.key == "gender_identity" &&
//                                 genderIdentificationValues
//                                 ? genderIdentificationValues.map((item) => (
//                                   <MenuItem
//                                     key={item.attribute_id}
//                                     value={item.attribute_id}
//                                   >
//                                     {item.attribute_val}
//                                   </MenuItem>
//                                 ))
//                                 : element.key == "orientation" &&
//                                   sexOrientationValues
//                                   ? sexOrientationValues.map((item) => (
//                                     <MenuItem
//                                       key={item.attribute_id}
//                                       value={item.attribute_id}
//                                     >
//                                       {item.attribute_val}
//                                     </MenuItem>
//                                   ))
//                                   : element.key == "preffered_language" &&
//                                     languageValues
//                                     ? languageValues.map((item) => (
//                                       <MenuItem
//                                         key={item.attribute_id}
//                                         value={item.attribute_id}
//                                       >
//                                         {item.attribute_val}
//                                       </MenuItem>
//                                     ))
//                                     : element.key == "pref_contact" &&
//                                       preferenceContactValues
//                                       ? preferenceContactValues.map((item) => (
//                                         <MenuItem
//                                           key={item.attribute_id}
//                                           value={item.attribute_id}
//                                         >
//                                           {item.attribute_val}
//                                         </MenuItem>
//                                       ))
//                                       : element.key == "pref_call_hours" &&
//                                         preferenceHourValues
//                                         ? preferenceHourValues.map((item) => (
//                                           <MenuItem
//                                             key={item.attribute_id}
//                                             value={item.attribute_id}
//                                           >
//                                             {item.attribute_val}
//                                           </MenuItem>
//                                         ))
//                                         : element.optionsYN
//                                           ? element.optionsYN.map((item) => (
//                                             <MenuItem value={item?.value}>
//                                               {item?.name}
//                                             </MenuItem>
//                                           ))
//                                           : element.key == "health_info_exchange" || element.key == 'allow_health_info' &&
//                                             element.options
//                                             ? element.options.map((item) => (
//                                               <MenuItem value={item}>
//                                                 {item === "Y"
//                                                   ? "Yes"
//                                                   : item === "N"
//                                                     ? "No"
//                                                     : item}
//                                               </MenuItem>
//                                             ))
//                                             : null}
//                       </Select>
//                       {/* <Typography>{patientData[element.key]}</Typography> */}
//                     </FormControl>
//                   ) : element.type === "text" ? (
//                     <TextField
//                       disabled
//                       required
//                       name={element.key}
//                       placeholder={element.placeHolder}
//                       size="small"
//                       className={classes.txtForm}
//                       fullWidth
//                       variant="outlined"
//                       inputProps={{
//                         style: {
//                           height: "12px",
//                           fontSize: "12px",
//                         },
//                       }}
//                       value={
//                         patientData && patientData[element.key] === null
//                           ? ""
//                           : patientData && patientData[element.key]
//                       }
//                       onChange={async (e) => {
//                         setPatientData({
//                           ...patientData,
//                           [element.key]: e.target.value,
//                         });
//                       }}
//                     />
//                   ) : element.type === "date" ||
//                     element.type === "datetime-local" ? (
//                     <TextField
//                       disabled
//                       id={element.key}
//                       name={element.key}
//                       placeholder={element.placeHolder}
//                       type={element.type}
//                       size="small"
//                       className={classes.txtForm}
//                       fullWidth
//                       variant="outlined"
//                       inputProps={{
//                         min: `${currentDateTime}`,
//                         max: `${currentDate}`,
//                         style: {
//                           height: "12px",
//                           fontSize: "12px",
//                         },
//                       }}
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                       value={
//                         patientData && patientData[element.key] === null
//                           ? ""
//                           : moment(
//                             patientData && patientData[element.key]
//                           ).format("YYYY-MM-DD")
//                       }
//                       onChange={async (e) => {
//                         setPatientData({
//                           ...patientData,
//                           [element.key]: e.target.value,
//                         });
//                       }}
//                     />
//                   ) : (
//                     <TextField
//                       disabled
//                       size="small"
//                       className={classes.txtForm}
//                       fullWidth
//                       placeholder="Type here..."
//                       variant="outlined"
//                       multiline
//                       rows={3}
//                       inputProps={{
//                         style: {
//                           fontSize: "12px",
//                         },
//                       }}
//                       value={
//                         patientData && patientData[element.key] === null
//                           ? ""
//                           : patientData && patientData[element.key]
//                       }
//                       onChange={async (e) => {
//                         setPatientData({
//                           ...patientData,
//                           [element.key]: e.target.value,
//                         });
//                       }}
//                     />
//                   )}
//                 </Grid>
//               ) : null}
//             </>
//           );
//         })}
//       </Grid>
//     </>
//   );
// }

import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import moment from "moment";
import useStyles from "./styles";
import { formProfileElements } from "./Patient_Constant";
import { Box } from "@mui/material";

export default function Profile({
  patientData,
  setPatientData,
  titleValues,
  genderValues,
  maritalValues,
  genderIdentificationValues,
  sexOrientationValues,
  preferenceContactValues,
  preferenceHourValues,
  languageValues,
}) {
  console.log(patientData, "pp")
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  const classes = useStyles();

  const formatPhoneNumber = (number) => {
    if (!number) return '';
    const cleaned = ('' + number).replace(/\D/g, '');
    if (cleaned.length > 5) {
      const match = cleaned.match(/^(\d{5})(\d*)$/);
      if (match) {
        return `${match[1]}-${match[2]}`;
      }
    }
    return cleaned;
  };

  useEffect(() => {
    if (patientData && typeof patientData === 'object') {
      const formattedData = { ...patientData };
      console.log(formattedData, "formatted");
      ["contact_cell", "contact_home", "contact_office"].forEach((key) => {
        if (formattedData[key] !== undefined && formattedData[key] !== null) {
          console.log(formattedData[key], "before format");
          formattedData[key] = formatPhoneNumber(formattedData[key]);
          console.log(formattedData[key], "after format");
        }
      });
      setPatientData(formattedData);
    }
  }, [patientData, setPatientData]);

  const handleChange = (e, key) => {
    let value = e.target.value;
    if (key === "contact_cell" || key === "contact_home" || key === "contact_office") {
      value = formatPhoneNumber(value);
    }
    setPatientData({
      ...patientData,
      [key]: value,
    });
  };

  return (
    <Grid container spacing={2} className={classes.formBox}>
      {formProfileElements.map((element, i) => (
        <React.Fragment key={i}>
          {patientData && (
            <Grid
              //  sx={{
              //    display: 'flex',
              //    flexDirection: 'column',
              //    gap: '3px',

              //  }}
              item xs={12} lg={2}
              sm={2} md={element.mdSize} className={classes.textGroup}>
              <Typography className={classes.formHeading}>
                {element.label}{" "}
                {element.required === "true" ? (
                  <span className={classes.required}>*</span>
                ) : null}
              </Typography>

              {element.type === "select" ? (
                <FormControl
                  fullWidth
                  // sx={{
                  //   '&.MuiFormControl-fullWidth': {
                  //     width: 'auto',
                  //   }
                  // }}
                  className={classes.dropdownFormControl}>
                  <Select
                    name={element.key}
                    className={classes.selectDropdown}
                    placeholder={element.placeHolder}
                    value={patientData[element.key] || ""}
                    disabled
                    onChange={(e) => handleChange(e, element.key)}
                  >
                    <MenuItem disabled value="">Select</MenuItem>
                    {element.key === "title" && titleValues
                      ? titleValues.map((item) => (
                        <MenuItem key={item.attribute_id} value={item.attribute_id}>
                          {item.attribute_val}
                        </MenuItem>
                      ))
                      : element.key === "gender" && genderValues
                        ? genderValues.map((item) => (
                          <MenuItem key={item.attribute_id} value={item.attribute_id}>
                            {item.attribute_val}
                          </MenuItem>
                        ))
                        : element.key === "marital" && maritalValues
                          ? maritalValues.map((item) => (
                            <MenuItem key={item.attribute_id} value={item.attribute_id}>
                              {item.attribute_val}
                            </MenuItem>
                          ))
                          : element.key === "gender_identity" && genderIdentificationValues
                            ? genderIdentificationValues.map((item) => (
                              <MenuItem key={item.attribute_id} value={item.attribute_id}>
                                {item.attribute_val}
                              </MenuItem>
                            ))
                            : element.key === "orientation" && sexOrientationValues
                              ? sexOrientationValues.map((item) => (
                                <MenuItem key={item.attribute_id} value={item.attribute_id}>
                                  {item.attribute_val}
                                </MenuItem>
                              ))
                              : element.key === "preffered_language" && languageValues
                                ? languageValues.map((item) => (
                                  <MenuItem key={item.attribute_id} value={item.attribute_id}>
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                                : element.key === "pref_contact" && preferenceContactValues
                                  ? preferenceContactValues.map((item) => (
                                    <MenuItem key={item.attribute_id} value={item.attribute_id}>
                                      {item.attribute_val}
                                    </MenuItem>
                                  ))
                                  : element.key === "pref_call_hours" && preferenceHourValues
                                    ? preferenceHourValues.map((item) => (
                                      <MenuItem key={item.attribute_id} value={item.attribute_id}>
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                    : element.optionsYN
                                      ? element.optionsYN.map((item) => (
                                        <MenuItem value={item.value}>
                                          {item.name}
                                        </MenuItem>
                                      ))
                                      : element.key === "health_info_exchange" ||
                                        (element.key === "allow_health_info" && element.options)
                                        ? element.options.map((item) => (
                                          <MenuItem value={item}>
                                            {item === "Y" ? "Yes" : item === "N" ? "No" : item}
                                          </MenuItem>
                                        ))
                                        : null}
                  </Select>
                </FormControl>
              ) : element.type === "text" ? (
                <TextField
                  disabled
                  required
                  name={element.key}
                  placeholder={element.placeHolder}
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    style: { height: "12px", fontSize: "12px" },
                  }}
                  value={patientData[element.key] || ""}
                  onChange={(e) => handleChange(e, element.key)}
                />
              ) : element.type === "date" || element.type === "datetime-local" ? (
                <TextField
                  disabled
                  id={element.key}
                  name={element.key}
                  placeholder={element.placeHolder}
                  type={element.type}
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    min: currentDateTime,
                    max: currentDate,
                    style: { height: "12px", fontSize: "12px" },
                  }}
                  InputLabelProps={{ shrink: true }}
                  value={patientData[element.key] ? moment(patientData[element.key]).format("YYYY-MM-DD") : ""}
                  onChange={(e) => handleChange(e, element.key)}
                />
              ) : (
                <TextField
                  disabled
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder="Type here..."
                  variant="outlined"
                  multiline
                  rows={3}
                  inputProps={{ style: { fontSize: "12px" } }}
                  value={patientData[element.key] || ""}
                  onChange={(e) => handleChange(e, element.key)}
                />
              )}
            </Grid>
          )}
        </React.Fragment>
      ))}
    </Grid>
  );
}

