import React from 'react'
import PatientEdit from '../../components/Patient/Edit/PatientEdit'

export default function Patient({patientReadValue}) {
  return (
    <div>
        <PatientEdit patientReadValue={patientReadValue}/>
    </div>
  )
}
