import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  OutlinedInput,
  ListItemText,
  Fade,
} from "@material-ui/core";
// Model
import {
  Button,
  DialogContent,
  DialogContentText,
  Divider,
} from "@material-ui/core";
import Popup from "../../common/modal/Popup";
import { formElements } from "./employee_Constant";

import moment from "moment";
import { toast } from "react-toastify";
import { getCityStateByZip, postDoctorData } from "../../../services/ServiceLayer";
import {
  currentUser,
  postFlagBooleanVariable,
  loadState,
  ssnFormat,
} from "../../../hooks/helper";

import EmployeeValidation from "../../../hooks/EmployeeValidation";
// styles
import useStyles from "./styles";

import NpiLookupSearch from "../../../hooks/NpiLookUp";
import PhoneInput from "react-phone-number-input";

export default function ManageDoctor(props) {
  //
  console.log("props-24", props);
  const { selectclient } = props;
  const { locationName } = props;
  const Data = loadState();
  console.log("Data", Data);
  const [physicianData, setPhysicianData] = useState(null);
  var classes = useStyles();
  // const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  const [modalShow, setModalShow] = useState(false);
  const selectedPatient = (data) => {
    console.log("Selected Patinet Data " + data);

    setOpen(false);
    if (data) {
      setPhysicianData(data);
      setDoctorDetail({
        ...doctorDetail,
        first_name: data[0],
        npi_no: data[1],
        address_line1: data[2],
        state: data[4],
      });
    } else {
      setDoctorDetail({
        ...doctorDetail,
        first_name: "",
        npi_no: "",
        address_line1: "",
        state: "",
      });
      setPhysicianData(null);
    }
  };

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("lg");
  const [open, setOpen] = React.useState(false);

  const [selectedValues, setSelectedValues] = useState([]);
  const [isActionCalled, setIsActionCalled] = useState(false);
  // list meta data
  const [listData, setListData] = useState([]);

  const [languageValues, setLanguageValues] = useState([]);
  const [cmpTypeValues, setCmpTypeValues] = useState([]);
  const [empTypeValues, setEmpTypeValues] = useState([]);
  const [genderValues, setGenderValues] = useState([]);
  const [hospitalValues, setHospitalValues] = useState([]);
  const [actionType, setActionType] = useState("ADDSTF");
  // list end meta data
  const [doctorDetail, setDoctorDetail] = useState({
    staff_id: 0,
    // user_group: "",
    location_id: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    joining_date: "",
    ssn: 0,
    dl: "",
    departmnet_id: "",
    hospital_type_id: "",
    employee_type: "",
    specialist: "",
    medical_reg_no: "",
    date_of_birth: "",
    gender: "",
    pref_language: "",
    npi_no: "",
    user_name: "xxxxxx",
    email: "",
    contact_cell: "",
    contact_home: "",
    contact_office: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    work_monday: "Y",
    work_tuesday: "Y",
    work_wednesday: "Y",
    work_thursday: "Y",
    work_friday: "Y",
    work_saturday: "Y",
    work_sunday: "Y",
    start_time: "",
    end_time: "",
    access_location: "",
    is_active: "Y",
    updated_by: currentUser(),
    opt_type: "ADDSTF",
  });
  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
    resetAllValidator();
    setIsActionCalled(false);
    setDoctorDetail({
      staff_id: 0,
      location_id: 0,
      // user_group: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      joining_date: "",
      ssn: 0,
      dl: "",
      departmnet_id: "",
      hospital_type_id: "",
      employee_type: "",
      specialist: "",
      medical_reg_no: "",
      date_of_birth: "",
      gender: "",
      pref_language: "",
      npi_no: "",
      user_name: "xxxxxx",
      email: "",
      contact_cell: "",
      contact_home: "",
      contact_office: "",
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      work_monday: "Y",
      work_tuesday: "Y",
      work_wednesday: "Y",
      work_thursday: "Y",
      work_friday: "Y",
      work_saturday: "Y",
      work_sunday: "Y",
      start_time: "",
      end_time: "",
      access_location: "",
      is_active: "Y",
      updated_by: currentUser(),
      opt_type: actionType,
    });
  };

  const searchPhysician = () => {
    setOpen(true);
    setModalShow(true);
  };

  const currentDate = moment().format("YYYY-MM-DD");
  const minDate = moment().subtract(18, "years").format("YYYY-MM-DD");

  const handleChange2 = (event) => {
    setSelectedValues(event.target.value);
  };

  // call meta data
  const metaData = loadState().metaData;

  useEffect(() => {
    console.log("loadState", metaData);
    setListData(metaData);
  }, []);

  useEffect(() => {
    console.log("selectclient locations useEffect", selectclient?.locations);
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setDoctorDetail({
        staff_id: 0,
        location_id: "",
        // user_group: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        joining_date: "",
        ssn: 0,
        dl: "",
        departmnet_id: "",
        hospital_type_id: "",
        employee_type: "",
        specialist: "",
        medical_reg_no: "",
        date_of_birth: "",
        gender: "",
        pref_language: "",
        npi_no: "",
        user_name: "xxxxxx",
        email: "",
        contact_cell: "",
        contact_home: "",
        contact_office: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        work_monday: "Y",
        work_tuesday: "Y",
        work_wednesday: "Y",
        work_thursday: "Y",
        work_friday: "Y",
        work_saturday: "Y",
        work_sunday: "Y",
        start_time: "",
        end_time: "",
        access_location: "",
        is_active: "Y",
        updated_by: currentUser(),
        opt_type: "ADDSTF",
      });
    } else {
      setDoctorDetail({
        staff_id: selectclient?.staff_id,
        location_id: selectclient?.location_id,
        first_name: selectclient?.first_name,

        middle_name: selectclient?.middle_name,
        last_name: selectclient?.last_name,
        joining_date: moment(selectclient?.joining_date).format("YYYY-MM-DD"),
        ssn: selectclient?.ssn,
        dl: selectclient?.dl,
        departmnet_id: selectclient?.department_id,
        hospital_type_id: selectclient?.hospital_type_id,
        employee_type: selectclient?.employee_type,
        specialist: selectclient?.specialist,
        medical_reg_no: selectclient?.medical_reg_no,
        date_of_birth: moment(selectclient?.date_of_birth).format("YYYY-MM-DD"),
        gender: selectclient?.gender,
        pref_language: selectclient?.pref_language,
        npi_no: selectclient?.npi_no,
        user_name: selectclient?.user_name,
        email: selectclient?.email,
        contact_cell: selectclient?.contact_cell,
        contact_home: selectclient?.contact_home,
        contact_office: selectclient?.contact_office,
        address_line1: selectclient?.address_line1,
        address_line2: selectclient?.address_line2,
        city: selectclient?.city,
        state: selectclient?.state,
        zip: selectclient?.zip,
        country: selectclient?.country,
        work_monday: selectclient?.work_monday.trim(),
        work_tuesday: selectclient?.work_tuesday.trim(),
        work_wednesday: selectclient?.work_wednesday.trim(),
        work_thursday: selectclient?.work_thursday.trim(),
        work_friday: selectclient?.work_friday.trim(),
        work_saturday: selectclient?.work_saturday.trim(),
        work_sunday: selectclient?.work_sunday.trim(),
        start_time: selectclient?.start_time,
        end_time: selectclient?.end_time,
        access_location: selectclient?.access_location,
        is_active: "Y",
        updated_by: currentUser(),
        opt_type: actionType,
      });
    }
  }, [selectclient]);

  useEffect(() => {
    console.log("listData", listData);
    if (listData.length > 0) {
      const empTypeValues = listData[0].empType;
      const languageValues = listData[0].language;
      const genderValues = listData[0].gender;
      const cmpTypeValues = listData[0].hosdept;
      const hospitalValues = listData[0].hospitalType;
      setEmpTypeValues(empTypeValues);
      setLanguageValues(languageValues);
      setGenderValues(genderValues);
      setCmpTypeValues(cmpTypeValues);
      setHospitalValues(hospitalValues);
    }
  }, [listData]);
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    EmployeeValidation({
      first_name: doctorDetail?.first_name,
      last_name: doctorDetail?.last_name,
      email: doctorDetail?.email,
      contact_cell: doctorDetail?.contact_cell,

      joining_date: doctorDetail?.joining_date,
      start_time: doctorDetail?.start_time,
      end_time: doctorDetail?.end_time,
      location_id: doctorDetail?.location_id,
    });

  const submitFormData = async (e) => {
    console.log("action called");
    e.preventDefault();
    checkValidator();
    setIsActionCalled(true);
    setDoctorDetail({
      ...doctorDetail,
      opt_type: "ADDSTF",
    });
  };
  const updateFormData = () => {
    checkValidator();
    setIsActionCalled(true);
    setActionType("MODSTF");
    setDoctorDetail({
      ...doctorDetail,
      opt_type: "MODSTF",
    });
  };

  useEffect(() => {
    console.log("isActionCalled", isActionCalled);
    // console.log("error", error);
    if (!isError() && error !== null && isActionCalled) {
      if (doctorDetail.employee_type !== 0) {
        postDoctorData(doctorDetail).then((response) => {
          console.log(response);
          if (response?.status?.status) {
            if (actionType === "ADDSTF") {
              toast.success("Staff added successfully.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else if (actionType === "MODSTF") {
              toast.success("Staff updated successfully.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else {
              toast.success("Staff deleted successfully.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }

            props.onHide();
            setIsActionCalled(false);
          } else {
            toast.error(response?.status.description, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsActionCalled(false);
          }
        });
      } else {
        toast.error("Please select employee type before proceed ", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
      console.log("error", error);
    }
  }, [isActionCalled]);
  const [suggestions, setSuggestions] = useState([]);
 

  const getCityStateAndZip = (zipValue) => {
    console.log("zipValue",zipValue);

    if (zipValue.length === 5) {
      getCityStateByZip(`zip=${zipValue}`).then((response) => {
        console.log("response-810", response);
        if (response?.status.description === "") {
          setSuggestions(response?.data);
          setDoctorDetail({
            ...doctorDetail,
            city: response?.data[0]?.city,
            state: response?.data[0]?.state_id,
          });
        }
      });
    } else {
      setDoctorDetail({
        ...doctorDetail,
        city: "",
        state: "",
      });
    }
  };

  useEffect(()=>{
    if(doctorDetail?.zip){
      if (doctorDetail?.zip.length >= 5) {
        getCityStateByZip(`zip=${doctorDetail?.zip}`)
          .then((response) => {
            console.log("response-810", response);
            if (response?.status.description === "") {
              
              setDoctorDetail({
                ...doctorDetail,
                city: response?.data[0]?.city,
                state: response?.data[0]?.state_id,
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching city and state:", error);
            // Handle error if necessary
          });
      } else {
        // Clear city and state if zip code is less than 5 characters
        setDoctorDetail({
          ...doctorDetail,
          city: "",
          state: "",
        });
      }
    }

  },[doctorDetail?.zip])

  const disabled = true;
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <NpiLookupSearch
              open={open}
              onHide={(e) => {
                selectedPatient(e);
              }}
              // onHide={hideHandler}
              title={"Search physician Data"}
              setModalShow={setModalShow}
            />

            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>
            {selectclient ? (
              <>
                <Button
                  className={classes.fillBtn}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={updateFormData}
                >
                  Update
                </Button>
              </>
            ) : (
              <>
                <Button
                  className={classes.fillBtn}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={(e) => submitFormData(e)}
                >
                  Add Staff
                </Button>
              </>
            )}
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={1}
              className={classes.formBox}
              style={{ marginBottom: "4px" }}
            >
              {formElements.map((element, i) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={classes.textGroup}
                    >
                      <Typography className={classes.formHeading}>
                        {element.label}{" "}
                        {element.required === "true" ? (
                          <span className={classes.required}>*</span>
                        ) : null}
                      </Typography>

                      {element.type === "select" ? (
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            name={element.key}
                            style={{ fontSize: "12px" }}
                            className={classes.selectDropdown}
                            placeholder={element.placeHolder}
                            value={
                              doctorDetail && doctorDetail[element.key]
                                ? doctorDetail && doctorDetail[element.key]
                                : ""
                            }
                            onChange={(e) => {
                              console.log("selected");
                              setDoctorDetail({
                                ...doctorDetail,
                                [element.key]: e.target.value,
                              });
                              resetValidator(e.target.name);
                            }}
                            isInvalid={error && error[element.key]}
                          >
                            <MenuItem disabled value="">
                              Select
                            </MenuItem>
                            {element.key === "employee_type" && empTypeValues
                              ? empTypeValues.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "gender" && genderValues
                              ? genderValues.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "pref_language" &&
                                languageValues
                              ? languageValues.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "hospital_type_id" &&
                                hospitalValues
                              ? hospitalValues.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "departmnet_id" && cmpTypeValues
                              ? cmpTypeValues.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "location_id" && locationName
                              ? locationName.flat().map((item) =>
                                  item.store_name !== null ? (
                                    <MenuItem
                                      key={item.store_id}
                                      value={item.store_id}
                                    >
                                      {item.store_name}
                                    </MenuItem>
                                  ) : null
                                )
                              : element.key === "state" && element.options
                              ? element.options.map((item) => (
                                  <MenuItem key={item} value={item}>
                                    {item}
                                  </MenuItem>
                                ))
                              : null}
                          </Select>
                          <Fade in={error}>
                            <Typography
                              color="secondary"
                              className={classes.errorMessage}
                            >
                              {error && error[element.key]}
                            </Typography>
                          </Fade>
                        </FormControl>
                      ) : element.type === "text" && element.key !=='zip' ? (
                        <>
                          <TextField
                            disabled={
                              element.key === "user_name" ? disabled : false
                            }
                            required
                            name={element.key}
                            placeholder={element.placeHolder}
                            size="small"
                            className={classes.txtForm}
                            fullWidth
                            variant="outlined"
                            inputProps={{
                              min: moment()
                                .subtract(18, "years")
                                .format("YYYY-MM-DD"),
                              style: {
                                height: "12px",
                                fontSize: "12px",
                              },
                              maxLength: element.maxLength,
                              ...(element.key === "ssn" && {
                                onInput: (e) => {
                                  e.target.value = ssnFormat(e.target.value);
                                },
                              }),

                              onKeyPress: element.onKeyPress, // Add the event handler
                            }}
                            value={doctorDetail && doctorDetail[element.key]}
                            onChange={(e) => {
                              setDoctorDetail({
                                ...doctorDetail,
                                [element.key]: e.target.value,
                              });
                              resetValidator(e.target.name);
                            }}
                            isInvalid={error && error[element.key]}
                          />
                          <Fade in={error}>
                            <Typography
                              color="secondary"
                              className={classes.errorMessage}
                            >
                              {error && error[element.key]}
                            </Typography>
                          </Fade>
                        </>
                      ): element.type === "text" && element.key ==='zip' ? (
                        <>
                          <TextField
                            disabled={
                              element.key === "user_name" ? disabled : false
                            }
                            required
                            type={element.type}
                            name={element.key}
                            placeholder={element.placeHolder}
                            size="small"
                            className={classes.txtForm}
                            fullWidth
                            variant="outlined"
                            inputProps={{
                             
                              style: {
                                height: "12px",
                                fontSize: "12px",
                              },
                              maxLength: element.maxLength,

                              // onKeyPress: element.onKeyPress, // Add the event handler
                            }}
                            value={doctorDetail && doctorDetail[element.key]}
                            onChange={(e) => {
                              setDoctorDetail({
                                ...doctorDetail,
                                [element.key]: e.target.value,
                              });
                            
                              // resetValidator(e.target.name);
                            }}
                            isInvalid={error && error[element.key]}
                          />
                          <Fade in={error}>
                            <Typography
                              color="secondary"
                              className={classes.errorMessage}
                            >
                              {error && error[element.key]}
                            </Typography>
                          </Fade>
                        </>
                      ) : element.type === "date" ||
                        element.type === "time" ||
                        element.type === "datetime-local" ? (
                        <>
                          <TextField
                            id={element.key}
                            name={element.key}
                            placeholder={element.placeHolder}
                            type={element.type}
                            size="small"
                            className={classes.txtForm}
                            fullWidth
                            variant="outlined"
                            inputProps={{
                              max:
                                element.key === "date_of_birth"
                                  ? minDate
                                  : undefined,
                              min:
                                element.key === "joining_date"
                                  ? minDate
                                  : undefined,
                              style: {
                                height: "12px",
                                fontSize: "12px",
                              },
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={doctorDetail && doctorDetail[element.key]}
                            onChange={(e) => {
                              const { name, value } = e.target;

                              if (
                                element.key === "end_time" &&
                                value < doctorDetail.start_time
                              ) {
                                // Set end time to be not less than start time
                                setDoctorDetail({
                                  ...doctorDetail,
                                  [name]: doctorDetail.start_time,
                                });
                              } else {
                                setDoctorDetail({
                                  ...doctorDetail,
                                  [name]: value,
                                });
                              }
                              resetValidator(e.target.name);
                            }}
                            // onKeyDown={(e) => {
                            //   e.preventDefault(); // Prevent manual typing
                            // }}
                            isInvalid={error && error[element.key]}
                          />
                          <Fade in={error}>
                            <Typography
                              color="secondary"
                              className={classes.errorMessage}
                            >
                              {error && error[element.key]}
                            </Typography>
                          </Fade>
                        </>
                      ) : element.type === "textarea" ? (
                        <>
                          <TextField
                            size="small"
                            className={classes.txtForm}
                            fullWidth
                            placeholder="Type here..."
                            variant="outlined"
                            multiline
                            rows={3}
                            inputProps={{
                              style: {
                                fontSize: "12px",
                              },
                            }}
                            value={doctorDetail && doctorDetail[element.key]}
                            onChange={(e) => {
                              setDoctorDetail({
                                ...doctorDetail,
                                [element.key]: e.target.value,
                              });
                              resetValidator(e.target.name);
                            }}
                            isInvalid={error && error[element.key]}
                          />
                          <Fade in={error}>
                            <Typography
                              color="secondary"
                              className={classes.errorMessage}
                            >
                              {error && error[element.key]}
                            </Typography>
                          </Fade>
                        </>
                      ) : element.type === "checkbox" ? (
                        <Grid
                          item
                          xs={12}
                          md={element.mdSize}
                          className={classes.textGroup}
                        >
                          <Checkbox
                            name={element.key}
                            checked={
                              doctorDetail[element.key] !== "N" ? true : false
                            }
                            onChange={(e) => {
                              const value = postFlagBooleanVariable(
                                e.target.checked
                              );
                              setDoctorDetail({
                                ...doctorDetail,
                                [element.key]: value,
                              });
                            }}
                          />
                        </Grid>
                      ) : element.type === "phone" ? (
                        <>
                          <Grid
                            item
                            xs={12}
                            md={element.mdSize}
                            className={classes.textGroup}
                          >
                            <PhoneInput
                              placeholder="Enter phone number"
                              value={doctorDetail && doctorDetail[element.key]}
                              onChange={(e) => {
                                setDoctorDetail({
                                  ...doctorDetail,
                                  [element.key]: e.target.value,
                                });
                                resetValidator(e.target.name);
                              }}
                            />
                          </Grid>
                        </>
                      ) : element.type === "button" 
                      // &&
                      //   (doctorDetail?.employee_type === "1" ||
                      //     doctorDetail?.employee_type === "4") 
                          ? (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                          style={{
                            width: "100%",
                            fontSize: "10px",
                            marginTop: "-10px",
                          }}
                          onClick={searchPhysician}
                        >
                          {element.btnName}
                        </Button>
                      ) : element.type === "label" ? (
                        <Divider />
                      ) : element.type === "multiselect" ? (
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                          style={{ padding: "4px" }}
                        >
                          <Select
                            style={{ fontSize: "12px" }}
                            multiple
                            value={selectedValues}
                            onChange={handleChange2}
                            className={classes.selectDropdown}
                            input={<OutlinedInput />}
                            renderValue={(selected) => selected.join(", ")}
                          >
                            <MenuItem disabled value="">
                              <em>Select</em>
                            </MenuItem>
                            {element.options.map((name) => (
                              <MenuItem key={name} value={name}>
                                <Checkbox
                                  checked={selectedValues.indexOf(name) > -1}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
