import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Fade,
  Button,
  Checkbox, List, ListItem
} from "@material-ui/core";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import moment from "moment";
import useStyles from "./styles";
import { formReasonVisitElements } from "./Exam_Constant";
import { currentUser, loadState } from "../../hooks/helper";
import { toast } from "react-toastify";
import {
  postVisitReasonData,
  getVisitReasonDropdown,
  getVisitReasonData,
} from "../../services/ServiceLayer";

export default function VisitReason({
  patientId,
  patientData,
  error,
  setProblemBtnUnable,
  problemBtnUnable,
}) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  console.log("patientData", patientData);
  var classes = useStyles();
  const [actionType, setActionType] = useState("ADDVRG");
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [reasonDropdown, setReasonDropdown] = useState([]);
  const [impactDropdown, setImpactDropdown] = useState([]);
  const [frqDropdown, setFrqDropdown] = useState([]);
  const [severityDropdown, setSeverityDropdown] = useState([]);
  const [occularDropdown, setOccularDropdown] = useState([]);
  const [factorDropdown, setFactorDropdown] = useState([]);
  const [symptDropdown, setSymptDropdown] = useState([]);
  const [sinceDropdown, setSinceDropdown] = useState([]);
  const [reasonPara, setReasonPara] = useState("");
  const [impactPara, setImpactPara] = useState("");
  const [frqPara, setFrqPara] = useState("");
  const [severityPara, setSeverityPara] = useState("");
  const [occularPara, setOccularPara] = useState("");
  const [factorPara, setFactorPara] = useState("");
  const [symptPara, setSymptPara] = useState("");
  const [sincePara, setSincePara] = useState("");
  const [visitReasonData, setVisitReasonData] = useState();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [customReason, setCustomReason] = useState('');  // State for custom reason input
  const [openDialog, setOpenDialog] = useState(false);  // State to control dialog visibility
  const handleAddCustomReason = () => {
    const newReason = {
      exam_meta_id: `custom-${Date.now()}`,  // Ensuring a unique ID
      grp_attribute_val: customReason
    };

    if (customReason.trim() !== "") {
      setReasonDropdown([...reasonDropdown, newReason]);
      setCustomReason('');
      setOpenDialog(false);
    }
  };

  // const handleOpenDialog = () => {
  //   setOpenDialog(true);
  // };

  // const handleCloseDialog = () => {
  //   setOpenDialog(false);
  //   setCustomReason('');  // Clearing the input on dialog close
  // };
  const [draggedItemId, setDraggedItemId] = useState(null);

  const handleDragStart = (id) => {
    setDraggedItemId(id);
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Necessary for allowing drops
  };

  const handleDrop = (itemId) => {
    if (!draggedItemId || !itemId || draggedItemId === itemId) return;

    // Find the items
    const dragIndex = reasonDropdown.findIndex(item => item.exam_meta_id === draggedItemId);
    const dropIndex = reasonDropdown.findIndex(item => item.exam_meta_id === itemId);

    // Reorder the items
    const newItems = [...reasonDropdown];
    const [reorderedItem] = newItems.splice(dragIndex, 1);
    newItems.splice(dropIndex, 0, reorderedItem);

    // Update the state with new order
    setReasonDropdown(newItems);
    setDraggedItemId(null); // Reset drag state
  };


  useEffect(() => {
    getVisitReasonDropdown(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELRES`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setReasonDropdown(response?.data[0]?.reasonType);
        setImpactDropdown(response?.data[0]?.impactedPart);
        setFrqDropdown(response?.data[0]?.frequency);
        setSeverityDropdown(response?.data[0]?.severity);
        setOccularDropdown(response?.data[0]?.occurseWhile);
        setFactorDropdown(response?.data[0]?.factors);
        setSymptDropdown(response?.data[0]?.symptons);
        setSinceDropdown(response?.data[0]?.examSince);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);
  const [examVisionDetail, setExamVisionDetail] = useState({
    patient_id: patientId,
    location_id: userData?.location_id,
    pat_reason_id: "",
    reason_id: [],
    impacted_part_id: [],
    frequency_id: [],
    severity_id: [],
    occurrence_id: [],
    factor_id: [],
    symptom_id: [],
    reason_from_date: [],
    description: "",

    updated_by: currentUser(),
    access_location: "",
    opt_type: actionType,
  });
  useEffect(() => {
    console.log("reasonDropdown", reasonDropdown);
    console.log("reasonDropdown -reason_id", examVisionDetail?.reason_id);
  }, [reasonDropdown, examVisionDetail?.reason_id]);

  const [discardUpdate, setDiscardUpdate] = useState(false);

  const discardHandler = () => {
    if (visitReasonData) {
      setDiscardUpdate(true);
      setProblemBtnUnable(false);
    } else {
      clearForm();
      setProblemBtnUnable(false);
    }
  };

  const clearForm = () => {
    setExamVisionDetail({
      patient_id: 0,
      location_id: userData?.location_id,
      pat_reason_id: "",
      reason_id: [],
      impacted_part_id: [],
      frequency_id: [],
      severity_id: [],
      occurrence_id: [],
      factor_id: [],
      symptom_id: [],
      reason_from_date: [],
      description: "",

      updated_by: currentUser(),
      access_location: "",
      opt_type: actionType,
    });
    setShowDiscard(false);
  };

  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    // if (!isError() && error !== null && isActionCalled) {
    if (isActionCalled) {
      postVisitReasonData(examVisionDetail).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Visit Reason added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setProblemBtnUnable(false);
          setShowDiscard(false);
          // clearForm();
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    // } else {
    //   setIsActionCalled(false);
    // }

    setIsActionCalled(false);
  }, [isActionCalled]);

  //  multiselect dropdown
  const updateDescription = () => {
    const descccc = `${patientId} Year old ${patientData?.gender_val} ${reasonPara ? `complains of ${reasonPara}` : ""
      } ${impactPara ? `Impacted part is - ${impactPara}` : ""} ${frqPara ? `Frequency of the issue is ${frqPara}` : ""
      }  and ${severityPara ? `severity is ${severityPara}` : ""} ${factorPara ? `Factors impacting the issue is ${factorPara}` : ""
      }. ${symptPara ? `The symptoms reported are ${symptPara}.` : ""} ${sincePara ? `The issue is occurring since ${sincePara}` : ""
      }, ${occularPara ? `while ${occularPara}` : ""} `;

    console.log("descccc", descccc);
    setExamVisionDetail({
      ...examVisionDetail,
      description: descccc,
    });
  };

  useEffect(() => {
    if (
      reasonPara.length > 0 ||
      impactPara.length > 0 ||
      frqPara.length > 0 ||
      severityPara.length > 0 ||
      occularPara.length > 0 ||
      factorPara.length > 0 ||
      symptPara.length > 0 ||
      sincePara.length > 0
    ) {
      updateDescription();
    }
  }, [
    reasonPara,
    impactPara,
    frqPara,
    severityPara,
    occularPara,
    factorPara,
    symptPara,
    sincePara,
  ]);
  useEffect(() => {
    getVisitReasonData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELRIS`
    ).then((response) => {
      console.log("response-216", response);
      if (response?.data) {
        // setIsLoader(false);
        setVisitReasonData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);
  useEffect(() => {
    if (visitReasonData) {
      console.log("visitReasonData", visitReasonData);
      setExamVisionDetail({
        ...examVisionDetail,
        patient_id: patientId,
        location_id: userData?.location_id,
        pat_reason_id: visitReasonData?.pat_reason_id,
        reason_id: visitReasonData?.reason_id,
        impacted_part_id: visitReasonData?.impacted_part_id,
        frequency_id: visitReasonData?.frequency_id,
        severity_id: visitReasonData?.severity_id,
        occurrence_id: visitReasonData?.occurrence_id,
        factor_id: visitReasonData?.factor_id,
        symptom_id: visitReasonData?.symptom_id,
        reason_from_date: visitReasonData?.reason_from_date,
        description: visitReasonData?.description,
        updated_by: currentUser(),
        access_location: visitReasonData?.access_location,
        opt_type: actionType,
      });

      setDiscardUpdate(false);
    }
  }, [visitReasonData, discardUpdate]);
  const handleSelectChange = async (e, key) => {
    const selectedValues = e.target.value;
    let descriptions = "";
    let paraValue = "";
    console.log("selectedValues", selectedValues);
    const joinedValues = selectedValues.join(",");
    console.log("joinedValues", joinedValues);

    // Iterate over selected values and collect descriptions
    selectedValues.forEach((value) => {
      const selectedItem = getSelectedItem(value, key);
      if (selectedItem) {
        descriptions += `${selectedItem.attribute_val_desc}, `;
        paraValue += `${selectedItem.grp_attribute_val}, `;
      }
    });

    // Retrieve existing descriptions from the state
    // const existingDescriptions = examVisionDetail?.description || "";

    // Merge new and existing descriptions
    descriptions = `${descriptions}`.trim();

    // Remove trailing comma
    descriptions = descriptions.replace(/,$/, "");
    paraValue = paraValue.replace(/,$/, "");
    // Extract selected factor names
    // Update the para state
    switch (key) {
      case "reason_id":
        setReasonPara(paraValue);
        break;
      case "impacted_part_id":
        setImpactPara(paraValue);
        break;
      case "frequency_id":
        setFrqPara(paraValue);
        break;
      case "severity_id":
        setSeverityPara(paraValue);
        break;
      case "symptom_id":
        setSymptPara(paraValue);
        break;
      case "factor_id":
        setFactorPara(paraValue);
        break;
      case "reason_from_date":
        setSincePara(paraValue);
        break;
      case "occurrence_id":
        setOccularPara(paraValue);
        break;
      default:
        break;
    }

    setExamVisionDetail({
      ...examVisionDetail,
      [key]: selectedValues,
    });
  };

  const renderSelectedValues = (selected, key) => {
    console.log("selected", selected);
    if (!Array.isArray(selected)) {
      return ""; // or handle the non-array case appropriately
    }
    return (
      selected &&
      selected
        .map((value) => {
          const selectedItem = getSelectedItem(value, key);
          console.log("selectedItem", selectedItem);
          return selectedItem ? selectedItem.grp_attribute_val : "";
        })
        .join(", ")
    );
  };

  const getSelectedItem = (value, key) => {
    switch (key) {
      case "reason_id":
        return reasonDropdown.find((item) => item.exam_meta_id === value);
      case "impacted_part_id":
        return impactDropdown.find((item) => item.exam_meta_id === value);
      case "frequency_id":
        return frqDropdown.find((item) => item.exam_meta_id === value);
      case "severity_id":
        return severityDropdown.find((item) => item.exam_meta_id === value);
      case "symptom_id":
        return symptDropdown.find((item) => item.exam_meta_id === value);
      case "factor_id":
        return factorDropdown.find((item) => item.exam_meta_id === value);
      case "reason_from_date":
        return sinceDropdown.find((item) => item.exam_meta_id === value);
      case "occurrence_id":
        return occularDropdown.find((item) => item.exam_meta_id === value);
      default:
        return null;
    }
  };
  const [showDesc, setShowDesc] = useState(false);
  useEffect(() => {
    console.log("examVisionDetail", examVisionDetail);
  }, [examVisionDetail]);
  const [showDiscard, setShowDiscard] = useState(false);
  return (
    <>
      <Grid container spacing={2} className={classes.formBox}>
        {formReasonVisitElements.map((element, i) => {
          return (
            <>
              <Grid
                key={i}
                item
                xs={12}

                md={element.mdSize}
                className={classes.textGroup}
              >
                {element.key === "description" && (
                  <Typography className={classes.formHeading}>
                    <Checkbox onChange={() => setShowDesc(!showDesc)} /> Click
                    here for description{" "}
                  </Typography>
                )}

                <Typography className={classes.formHeading}>
                  {element.key === "description" && showDesc && element.label}{" "}
                  {element.key !== "description" && element.label}{" "}
                  {element.required === "true" ? (
                    <span className={classes.required}>*</span>
                  ) : null}
                </Typography>

                {element.type === "select" ? (
                  <>
                    <FormControl
                      fullWidth
                      className={classes.dropdownFormControl}
                    >
                      <Select
                        style={{ fontSize: "14px" }}
                        name={element.key}
                        className={classes.selectDropdown}
                        placeholder={element.placeHolder}
                        value={
                          Array.isArray(examVisionDetail[element.key])
                            ? examVisionDetail[element.key]
                            : []
                        }
                        // value={examVisionDetail[element.key] || []}
                        onChange={(e) => {
                          handleSelectChange(e, element.key);
                          setProblemBtnUnable(true);
                          setShowDiscard(true);
                        }}
                        renderValue={(selected) =>
                          renderSelectedValues(selected, element.key)
                        }
                        multiple
                        isInvalid={error && error[element.key]}
                      >
                        <MenuItem disabled value="">
                          Select
                        </MenuItem>

                        {element.key === "reason_id" && reasonDropdown
                          ? reasonDropdown.map((item) => (
                            // <ListItem
                            //   key={item.exam_meta_id}
                            //   draggable
                            //   onDragStart={() => handleDragStart(item.exam_meta_id)}
                            //   onDragOver={handleDragOver}
                            //   onDrop={() => handleDrop(item.exam_meta_id)}
                            //   style={{ cursor: 'move' }}  // Add a cursor style for better UX
                            // >
                            <MenuItem key={item.exam_meta_id} value={item.exam_meta_id} draggable
                              onDragStart={() => handleDragStart(item.exam_meta_id)}
                              onDragOver={handleDragOver}
                              onDrop={() => handleDrop(item.exam_meta_id)}
                            // style={{ cursor: 'move' }}
                            >
                              <Checkbox checked={examVisionDetail[element.key]?.includes(item.exam_meta_id)} />
                              {item.grp_attribute_val}
                            </MenuItem>
                            // </ListItem>
                          ))
                          : element.key === "impacted_part_id" && impactDropdown
                            ? impactDropdown.map((item) => (
                              <MenuItem
                                key={item.exam_meta_id}
                                value={item?.exam_meta_id}
                              >
                                <Checkbox
                                  checked={examVisionDetail[
                                    element.key
                                  ]?.includes(item.exam_meta_id)}
                                />
                                {item.grp_attribute_val}
                              </MenuItem>
                            ))
                            : element.key === "frequency_id" && frqDropdown
                              ? frqDropdown.map((item) => (
                                <MenuItem
                                  key={item.exam_meta_id}
                                  value={item?.exam_meta_id}
                                >
                                  <Checkbox
                                    checked={examVisionDetail[
                                      element.key
                                    ]?.includes(item.exam_meta_id)}
                                  />
                                  {item.grp_attribute_val}
                                </MenuItem>
                              ))
                              : element.key === "severity_id" && severityDropdown
                                ? severityDropdown.map((item) => (
                                  <MenuItem
                                    key={item.exam_meta_id}
                                    value={item?.exam_meta_id}
                                  >
                                    <Checkbox
                                      checked={examVisionDetail[
                                        element.key
                                      ]?.includes(item.exam_meta_id)}
                                    />
                                    {item.grp_attribute_val}
                                  </MenuItem>
                                ))
                                : element.key === "symptom_id" && symptDropdown
                                  ? symptDropdown.map((item) => (
                                    <MenuItem
                                      key={item.exam_meta_id}
                                      value={item?.exam_meta_id}
                                    >
                                      <Checkbox
                                        checked={examVisionDetail[
                                          element.key
                                        ]?.includes(item.exam_meta_id)}
                                      />
                                      {item.grp_attribute_val}
                                    </MenuItem>
                                  ))
                                  : element.key === "factor_id" && factorDropdown
                                    ? factorDropdown.map((item) => (
                                      <MenuItem
                                        key={item.exam_meta_id}
                                        value={item?.exam_meta_id}
                                      >
                                        <Checkbox
                                          checked={examVisionDetail[
                                            element.key
                                          ]?.includes(item.exam_meta_id)}
                                        />
                                        {item.grp_attribute_val}
                                      </MenuItem>
                                    ))
                                    : element.key === "reason_from_date" && sinceDropdown
                                      ? sinceDropdown.map((item) => (
                                        <MenuItem
                                          key={item.exam_meta_id}
                                          value={item?.exam_meta_id}
                                        >
                                          <Checkbox
                                            checked={examVisionDetail[
                                              element.key
                                            ]?.includes(item.exam_meta_id)}
                                          />
                                          {item.grp_attribute_val}
                                        </MenuItem>
                                      ))
                                      : element.key === "occurrence_id" && occularDropdown
                                        ? occularDropdown.map((item) => (
                                          <MenuItem
                                            key={item.exam_meta_id}
                                            value={item?.exam_meta_id}
                                          >
                                            <Checkbox
                                              checked={examVisionDetail[
                                                element.key
                                              ]?.includes(item.exam_meta_id)}
                                            />
                                            {item.grp_attribute_val}
                                          </MenuItem>
                                        ))
                                        : element.optionsYN
                                          ? element.optionsYN.map((item) => (
                                            <MenuItem value={item?.value}>
                                              {item?.name}
                                            </MenuItem>
                                          ))
                                          : element.key === "allow_health_info" &&
                                            element.options
                                            ? element.options.map((item) => (
                                              <MenuItem value={item}> {item} </MenuItem>
                                            ))
                                            : null}
                      </Select>
                    </FormControl>
                    <Fade in={error}>
                      <Typography
                        color="secondary"
                        className={classes.errorMessage}
                      >
                        {error && error[element.key]}
                      </Typography>
                    </Fade>
                  </>
                ) : element.type === "textarea" && showDesc ? (
                  <>
                    <TextField
                      name={element.key}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      placeholder="Type here..."
                      variant="outlined"
                      multiline
                      rows={3}
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      value={
                        examVisionDetail &&
                          examVisionDetail[element.key] === null
                          ? ""
                          : examVisionDetail && examVisionDetail[element.key]
                      }
                      onChange={async (e) => {
                        setExamVisionDetail({
                          ...examVisionDetail,
                          [element.key]: e.target.value,
                        });
                        setProblemBtnUnable(true);
                        setShowDiscard(true);
                        // resetValidator(e.target.name);
                      }}
                      isInvalid={error && error[element.key]}
                    />
                    <Fade in={error}>
                      <Typography
                        color="secondary"
                        className={classes.errorMessage}
                      >
                        {error && error[element.key]}
                      </Typography>
                    </Fade>
                  </>
                ) : element.type === "date" ||
                  element.type === "datetime-local" ? (
                  <>
                    <TextField
                      id={element.key}
                      name={element.key}
                      placeholder={element.placeHolder}
                      type={element.type}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      inputProps={{
                        min: `${currentDateTime}`,
                        max: `${currentDate}`,
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        examVisionDetail &&
                          examVisionDetail[element.key] === null
                          ? ""
                          : examVisionDetail && examVisionDetail[element.key]
                      }
                      onChange={async (e) => {
                        setExamVisionDetail({
                          ...examVisionDetail,
                          [element.key]: e.target.value,
                        });
                        setProblemBtnUnable(true);
                        // resetValidator(e.target.name);
                      }}
                      onKeyDown={(e) => {
                        e.preventDefault(); // Prevent manual typing
                      }}
                      isInvalid={error && error[element.key]}
                    />
                    <Fade in={error}>
                      <Typography
                        color="secondary"
                        className={classes.errorMessage}
                      >
                        {error && error[element.key]}
                      </Typography>
                    </Fade>
                  </>
                ) : element.type === "text" ? (
                  <>
                    <TextField
                      type={element.type}
                      name={element.key}
                      placeholder={element.placeHolder}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      autoFocus
                      inputProps={{
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                        maxLength: element.maxLength,

                        onKeyPress: element.onKeyPress, // Add the event handler
                        onPaste: element.onPaste,
                      }}
                      value={
                        examVisionDetail &&
                          examVisionDetail[element.key] === null
                          ? ""
                          : examVisionDetail && examVisionDetail[element.key]
                      }
                      onChange={async (e) => {
                        setExamVisionDetail({
                          ...examVisionDetail,
                          [element.key]: e.target.value,
                        });
                        setProblemBtnUnable(true);
                        // resetValidator(e.target.name);
                      }}
                      isInvalid={error && error[element.key]}
                    />
                    <Fade in={error}>
                      <Typography
                        color="secondary"
                        className={classes.errorMessage}
                      >
                        {error && error[element.key]}
                      </Typography>
                    </Fade>
                  </>
                ) : (
                  ""
                )}
              </Grid >
            </>
          );
        })}
        <Grid
          className={classes.btnGroup}
          style={{ display: "flex", justifyContent: "end" }}
          item
          xs={12}
        >
          {showDiscard && (
            <Button
              className={classes.btnleftOutline}
              variant="outlined"
              style={{ marginRight: "10px", fontSize: "12px" }}
              size="medium"
              onClick={discardHandler}
            >
              Discard
            </Button>
          )}
          <Button
            disabled={!problemBtnUnable}
            style={{
              display: "flex",
              float: "right",
              cursor: problemBtnUnable ? "pointer" : "not-allowed",
            }}
            onClick={handleSubmit}
            className={classes.btnlRightDark}
            variant="contained"
            size="medium"
            color="secondary"
          >
            Save
          </Button>
        </Grid>
      </Grid >
    </>
  );
}
