import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../common/modal/Popup";
// styles
import useStyles from "../styles";

import moment from "moment";
import {
  currentUser,
  handleAddressKeyPress,
  handleAddressKeyPressPaste,
  loadState,
} from "../../../hooks/helper";
import {
  postAllergyData,
  postFinalRefractiveData,
} from "../../../services/ServiceLayer";
import { toast } from "react-toastify";
import { formFinalRxElements } from "./Refractive_Constant";
import useListData from "../../common/allMetaData/useListData";

export default function ManageRefractive(props) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  // filter
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  //

  console.log("props-24", props);
  const {
    patientId,
    setFinalRxDetails,
    finalRxDetails,
    selectclient,
    setTitle,
    examBase,
    setIsUpdated,
    frameMaterialData,
  } = props;
  console.log("selectclient", selectclient);
  const listData = useListData();
  var classes = useStyles();
  const { examFinalRefrecutiveUsage, examAssessmentFollowUpStatus, examFinalRefrecutiveLensType, examCurrentAcuity } = listData;
  const [actionType, setActionType] = useState("ADDALG");

  const [maxWidth, setMaxWidth] = React.useState("lg");

  const [isActionCalled, setIsActionCalled] = useState(false);

  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    // if (!isError() && error !== null && isActionCalled) {
    if (isActionCalled) {
      postFinalRefractiveData(finalRxDetails).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Final Refractive added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActionCalled(false);
          clearForm();
          setIsUpdated(true);

          props.onHide();
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    } else {
      setIsActionCalled(false);
    }

    setIsActionCalled(false);
  }, [isActionCalled]);

  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
    clearForm();
  };
  const clearForm = () => {
    setFinalRxDetails({
      ...finalRxDetails,
      pat_rfrfr_id: 0,
      patient_id: patientId,
      location_id: userData?.location_id,
      fr_name: "",
      fr_balance_od: "N",
      fr_sphere_od: "",
      fr_cyl_od: "",
      fr_axis_od: "",
      fr_add_od: "",
      fr_hprism_od: "",
      fr_hbase_od: "",
      fr_vprism_od: "",
      fr_vbase_od: "",
      fr_dva_od: "",
      fr_nva_od: "",
      fr_oudva_od: "",
      fr_ounva_od: "",
      fr_balance_os: "N",
      fr_sphere_os: "",
      fr_cyl_os: "",
      fr_axis_os: "",
      fr_add_os: "",
      fr_hprism_os: "",
      fr_hbase_os: "",
      fr_vprism_os: "",
      fr_vbase_os: "",
      fr_dva_os: "",
      fr_nva_os: "",
      fr_oudva_os: "",
      fr_ounva_os: "",
      fr_usage: 0,
      fr_lens_type: 0,
      fr_material: 0,
      fr_status: 0,

      updated_by: currentUser(),
      access_location: "ggg",
      opt_type: "ADDRFR",
      ...props.finalRxDetails,
      fr_start_dt: moment().format("YYYY-MM-DD"), // Today's date
      fr_end_dt: moment().add(2, 'years').format("YYYY-MM-DD"), // 2 years from today for Lens by default
    });
  };
  const handleCheckboxChange = (element, e) => {
    const selectedValue = e.target.checked ? "Y" : "";

    if (element.key === "fr_balance_od" && selectedValue === "Y") {
      // If balance_od is checked, copy OS field values to OD fields
      setFinalRxDetails({
        ...finalRxDetails,
        fr_balance_os: "N",
        fr_balance_od: selectedValue,
        fr_sphere_od: finalRxDetails?.fr_sphere_os,
        fr_cyl_od: finalRxDetails?.fr_cyl_os,
        fr_axis_od: finalRxDetails?.fr_axis_os,
        add_od: finalRxDetails?.fr_add_os,
        fr_hprism_od: finalRxDetails?.fr_hprism_os,
        fr_hbase_od: finalRxDetails?.fr_hbase_os,
        fr_vprism_od: finalRxDetails?.fr_vprism_od,
        fr_vbase_od: finalRxDetails?.fr_vbase_od,
        fr_dva_od: finalRxDetails?.fr_dva_od,
        fr_nva_od: finalRxDetails?.fr_nva_od,
        fr_oudva_od: finalRxDetails?.fr_oudva_od,
        fr_ounva_od: finalRxDetails?.fr_ounva_od,
      });
    } else if (element.key === "fr_balance_os" && selectedValue === "Y") {
      // If balance_os is checked, copy OD field values to OS fields
      setFinalRxDetails({
        ...finalRxDetails,
        fr_balance_od: "N",
        fr_balance_os: selectedValue,
        fr_sphere_os: finalRxDetails?.fr_sphere_od,
        fr_cyl_os: finalRxDetails?.fr_cyl_od,
        fr_axis_os: finalRxDetails?.fr_axis_od,
        fr_add_os: finalRxDetails?.fr_add_od,
        fr_hprism_os: finalRxDetails?.fr_hprism_od,
        fr_hbase_os: finalRxDetails?.fr_hbase_od,
        fr_vprism_os: finalRxDetails?.fr_vprism_od,
        fr_vbase_os: finalRxDetails?.fr_vbase_od,
        fr_dva_os: finalRxDetails?.fr_dva_od,
        fr_nva_os: finalRxDetails?.fr_nva_od,
        fr_oudva_os: finalRxDetails?.fr_oudva_od,
        fr_ounva_os: finalRxDetails?.fr_ounva_od,
      });
    } else {
      // If neither balance_od nor balance_os is checked, update the checkbox state only
      setFinalRxDetails({
        ...finalRxDetails,
        [element.key]: selectedValue,
      });
    }
  };
  const [lensType, setLensType] = useState('Lens'); // Default to 'Lens'

  useEffect(() => {
    // Update the end date based on lens type
    const newEndDate = lensType === 'Glass'
      ? moment().add(5, 'years').format("YYYY-MM-DD") // 5 years for Glass
      : moment().add(2, 'years').format("YYYY-MM-DD"); // 2 years for Lens

    setFinalRxDetails(prevDetails => ({
      ...prevDetails,
      fr_start_dt:moment().format("YYYY-MM-DD"),
      fr_end_dt: newEndDate
    }));
  }, [lensType]);
  const handleLensTypeChange = (event) => {
    setLensType(event.target.value);
  };
  useEffect(() => {
    if (selectclient) {
      console.log("selectclient", selectclient);
      setFinalRxDetails({
        ...finalRxDetails,
        pat_rfrfr_id: selectclient?.pat_rfrfr_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        fr_name: selectclient?.fr_name,
        fr_balance_od: selectclient?.fr_balance_od,
        fr_sphere_od: selectclient?.fr_sphere_od,
        fr_cyl_od: selectclient?.fr_cyl_od,
        fr_axis_od: selectclient?.fr_axis_od,
        fr_add_od: selectclient?.fr_add_od,
        fr_hprism_od: selectclient?.fr_hprism_od,
        fr_hbase_od: selectclient?.fr_hbase_od,
        fr_vprism_od: selectclient?.fr_vprism_od,
        fr_vbase_od: selectclient?.fr_vbase_od,
        fr_dva_od: selectclient?.fr_dva_od,
        fr_nva_od: selectclient?.fr_nva_od,
        fr_oudva_od: selectclient?.fr_oudva_od,
        fr_ounva_od: selectclient?.fr_ounva_od,
        fr_balance_os: selectclient?.fr_balance_os,
        fr_sphere_os: selectclient?.fr_sphere_os,
        fr_cyl_os: selectclient?.fr_cyl_os,
        fr_axis_os: selectclient?.fr_axis_os,
        fr_add_os: selectclient?.fr_add_os,
        fr_hprism_os: selectclient?.fr_hprism_os,
        fr_hbase_os: selectclient?.fr_hbase_os,
        fr_vprism_os: selectclient?.fr_vprism_os,
        fr_vbase_os: selectclient?.fr_vbase_os,
        fr_dva_os: selectclient?.fr_dva_os,
        fr_nva_os: selectclient?.fr_nva_os,
        fr_oudva_os: selectclient?.fr_oudva_os,
        fr_ounva_os: selectclient?.fr_ounva_os,
        fr_usage: selectclient?.fr_usage,
        fr_lens_type: selectclient?.lens_type ? selectclient?.lens_type : selectclient?.fr_lens_type,
        fr_material: selectclient?.material ? selectclient?.material : selectclient?.fr_material,
        fr_status: selectclient?.fr_status,
        fr_start_dt: moment(selectclient?.fr_start_dt).format("YYYY-MM-DD"),
        fr_end_dt: moment(selectclient?.fr_end_dt).format("YYYY-MM-DD"),
        updated_by: currentUser(),
        access_location: selectclient?.access_location,
        opt_type: "UPDRFR",
      });
    }
  }, [selectclient]);
  const { onHide, ...props1 } = props;

  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        // fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleSubmit}
            >
              {selectclient ? "Update" : "Add"}
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={2}
              className={classes.formBox}
              style={{ marginBottom: "4px" }}
            >
              {formFinalRxElements.map((element, i) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={`${classes.textGroup} ${element.title ? classes.highlightedItem : ""
                        }`}
                    // style={{ border: "1px solid #DED9D9" }}
                    >
                      {element.label && (
                        <Typography
                          className={classes.formHeading}
                          style={{
                            fontWeight: "500",
                            color: "#4E97FD",
                            position: "relative",
                          }}
                        >
                          {element.label}&nbsp;
                          {element.required === "true" ? (
                            <span className={classes.required}>*</span>
                          ) : null}
                          {element.copyBtn === "true" && (
                            <Button
                              style={{
                                float: "right",
                                position: "absolute",
                                top: "20px",
                              }}
                              classes={{ root: classes.button }}
                              variant="contained"
                              color="secondary"
                            // onClick={duplicateValue}
                            >
                              =
                            </Button>
                          )}
                        </Typography>
                      )}
                      {element.type ? (
                        <>
                          {element.type === "select" &&
                            element.key !== "procedure_code" ? (
                            <FormControl
                              fullWidth
                              className={classes.dropdownFormControl}
                            >
                              <Select
                                style={{ fontSize: "12px" }}
                                name={element.key}
                                className={classes.selectDropdown}
                                placeholder={element.placeHolder}
                                value={
                                  finalRxDetails && finalRxDetails[element.key]
                                    ? finalRxDetails &&
                                    finalRxDetails[element.key]
                                    : ""
                                }
                                onChange={(e) => {
                                  console.log("selected");
                                  setFinalRxDetails({
                                    ...finalRxDetails,
                                    [element.key]: e.target.value,
                                  });
                                }}
                              >
                                <MenuItem disabled value="">
                                  Select
                                </MenuItem>
                                {(element.key === "fr_hbase_od" ||
                                  element.key === "fr_vbase_od" ||
                                  element.key === "fr_hbase_os" ||
                                  element.key === "fr_vbase_os" ||
                                  element.key === "fr_dva_od" ||
                                  element.key === "fr_dva_os" ||
                                  element.key === "fr_nva_od" ||
                                  element.key === "fr_nva_os" ||
                                  element.key === "ou_dva" ||
                                  element.key === "ou_nva"
                                ) &&
                                  examCurrentAcuity
                                  ? examCurrentAcuity.map((item) => (
                                    <MenuItem
                                      key={item.attribute_id}
                                      value={item.attribute_id}
                                    >
                                      {item.attribute_val}
                                    </MenuItem>
                                  ))
                                  : element.key === "fr_material" &&
                                    frameMaterialData
                                    ? frameMaterialData.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                    : element.key === "fr_usage" &&
                                      examFinalRefrecutiveUsage
                                      ? examFinalRefrecutiveUsage.map((item) => (
                                        <MenuItem
                                          key={item.attribute_id}
                                          value={item.attribute_id}
                                        >
                                          {item.attribute_val}
                                        </MenuItem>
                                      )) : element.key === "fr_lens_type" &&
                                        examFinalRefrecutiveLensType
                                        ? examFinalRefrecutiveLensType.map((item) => (
                                          <Select
                                            value={lensType}
                                            onChange={handleLensTypeChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                          >
                                            <MenuItem value="Lens">Lens</MenuItem>
                                            <MenuItem value="Glass">Glass</MenuItem>
                                          </Select>
                                        )) : element.key === "fr_status" &&
                                          examAssessmentFollowUpStatus
                                          ? examAssessmentFollowUpStatus.map((item) => (
                                            <MenuItem
                                              key={item.attribute_id}
                                              value={item.attribute_id}
                                            >
                                              {item.attribute_val}
                                            </MenuItem>
                                          )) : element.options.map((item) => (
                                            <MenuItem value={item.replace("20/", "").replace("+", "")}>
                                              {item}
                                            </MenuItem>
                                          ))}
                              </Select>
                            </FormControl>
                          ) : element.type === "text" ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {element.txtLabel && (
                                <span
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "10px",
                                  }}
                                >
                                  {element.txtLabel}
                                </span>
                              )}
                              <TextField
                                required
                                name={element.key}
                                placeholder={element.placeHolder}
                                size="small"
                                className={classes.txtForm}
                                fullWidth
                                variant="outlined"
                                inputProps={{
                                  style: {
                                    height: "12px",
                                    fontSize: "12px",
                                  },
                                  maxLength: element.maxLength,
                                  onKeyPress: element.onKeyPress,
                                }}
                                value={
                                  finalRxDetails && finalRxDetails[element.key]
                                    ? finalRxDetails &&
                                    finalRxDetails[element.key]
                                    : ""
                                }
                                onChange={(e) => {
                                  console.log("selected");
                                  setFinalRxDetails({
                                    ...finalRxDetails,
                                    [element.key]: e.target.value,
                                  });
                                }}
                              />
                              {element.key === "sku" && (
                                <Button
                                  // onClick={selectFrameBySku}
                                  classes={{ root: classes.button }}
                                  variant="contained"
                                  color="secondary"
                                >
                                  Slect
                                </Button>
                              )}
                            </div>
                          ) : element.type === "commission_amount" ? (
                            <TextField
                              type="text"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              size="small"
                              inputProps={{
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                                maxLength: element.maxLength,
                                onKeyPress: element.onKeyPress,
                              }}
                            />
                          ) : element.type === "checkbox" ? (
                            <>
                              <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                  <FormControlLabel
                                    value={element.value}
                                    control={
                                      <Checkbox
                                        checked={
                                          finalRxDetails[element.key] &&
                                          finalRxDetails[element.key] === "Y"
                                        }
                                        // checked={Boolean(arxDetails[element.key])}
                                        onChange={(e) =>
                                          handleCheckboxChange(element, e)
                                        }
                                      />
                                    }
                                    label={element.lbltitle}
                                    labelPlacement="start"
                                  />
                                </FormGroup>
                              </FormControl>
                            </>
                          ) : element.type === "date" ||
                            element.type === "datetime-local" ? (
                            <TextField
                              id={element.key}
                              name={element.key}
                              placeholder={element.placeHolder}
                              type={element.type}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                              }}
                              value={
                                finalRxDetails && finalRxDetails[element.key]
                                  ? finalRxDetails &&
                                  finalRxDetails[element.key]
                                  : ""
                              }
                              onChange={(e) => {
                                console.log("selected");
                                setFinalRxDetails({
                                  ...finalRxDetails,
                                  [element.key]: e.target.value,
                                });
                                // resetValidator(e.target.name);
                              }}
                            />
                          ) : element.type === "button" &&
                            element?.btnlbl === "Lookup" ? (
                            <>
                              <Button
                                classes={{ root: classes.button }}
                                variant="contained"
                                color="secondary"
                              // onClick={selectPrevFrame}
                              >
                                {element.btnlbl}
                              </Button>
                            </>
                          ) : element.type === "button" &&
                            element?.btnlbl === "Update" ? (
                            <>
                              {/* {selectedRow && (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                        //   onClick={updateSelectFrame}
                        >
                          {element.btnlbl}
                        </Button>
                      )} */}
                            </>
                          ) : (
                            <TextField
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              placeholder="Type here..."
                              variant="outlined"
                              multiline
                              rows={3}
                              inputProps={{
                                style: {
                                  fontSize: "12px",
                                },
                              }}
                              value={
                                finalRxDetails && finalRxDetails[element.key]
                                  ? finalRxDetails &&
                                  finalRxDetails[element.key]
                                  : ""
                              }
                              onChange={(e) => {
                                console.log("selected");
                                setFinalRxDetails({
                                  ...finalRxDetails,
                                  [element.key]: e.target.value,
                                });
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <Typography
                            className={classes.formHeading}
                            style={{
                              fontWeight: "600",

                              color: "#4E97FD",
                              position: "absolute",
                              marginTop: i === 0 ? "24px" : "10px",
                            }}
                          >
                            {element.title}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
