import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Grid } from "@material-ui/core";
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { getScheduleMetaData } from "../../../services/ServiceLayer";

import { MoreVert as MoreIcon, Lens } from "@material-ui/icons";
// components
import PageTitle from "../../PageTitle/PageTitle";
import TableWidget from "../../Widget/TableWidget";

// styles
import useStyles from "./styles";

import TableDesign from "../../common/table/TableDesign";
import { dateFormatConversion, loadState } from "../../../hooks/helper";

import ManageScheduleData from "./ManageScheduleData";
export default function ScheduleDataList() {

  const userData = loadState().user;
  const userRole =loadState().user.user_role;
  const history = useHistory();
  // const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const [selectRow, setSelectRow] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // table Header
  const tableHeadings = [
    {
      label: "S. No.",
    },
    {
      label: "Store Name",
      align: "left !important",
    },

    {
      label: "Company Name",
    },
    {
      label: "Duration",
    },
    {
      label: "Created on",
    },
    {
      label: "Updated on",
    },
    {
      label: "Updated By",
    },
    {
      label: "Action",
    },
  ];
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  var classes = useStyles();

  const [open, setOpen] = React.useState(false);
  //
  const [modalShow, setModalShow] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [selectclient, setselectclient] = useState(null);
  const [title, setTitle] = useState(null);
  const [ScheduleMetaDataList, setScheduleMetaDataList] = useState([]);
  //
  const [value, setValue] = React.useState("");
  const [toggleActivateModalShow, setToggleActivateModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  //searching
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const filteredMetadataList = ScheduleMetaDataList.filter((data) =>
    data.store_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const addClient = () => {
    console.log("add client called");
    setselectclient(null);
    // setModalShow(true);
    setTitle("Add Schedule Metadata");
    // setShowResult(true);
    setOpen(true);
    setAnchorEl(null);
  };

  const handleEdit = (rec) => {
    console.log("rec", rec);
    console.log("add client called");
    setselectclient(rec);
    // setModalShow(true);
    setTitle("Edit Schedule Metadata");
    // setShowResult(true);
    setOpen(true);
    setAnchorEl(null);
  };

  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpen(false);
    setToggleActivateModalShow(false);
    setDataUpdated(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleResendLink = () => {
    setToggleActivateModalShow(true);

    setAnchorEl(null);
  };
  useEffect(() => {
    getScheduleMetaData(`location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSCH`).then((response) => {
      console.log(response);
      setScheduleMetaDataList(response.data);
    });
  }, []);
  // const handleDataUpdate = () => {
  //   setDataUpdated(true);
  // };
  useEffect(() => {
    getScheduleMetaData(`location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSCH`).then((response) => {
      console.log(response);
      setScheduleMetaDataList(response.data);
      setDataUpdated(false);
    });
  }, [dataUpdated]);

  const handleDelete = () => {
    setDeleteModalShow(true);
    setAnchorEl(null);
  };

  return (
    <>
      <ManageScheduleData
        open={open}
        onHide={hideHandle}
        title={title}
        selectclient={selectclient}
        setModalShow={setModalShow}
      />
      <PageTitle
        title="Manage Schedule Data"
        button="Add Schedule"
        search="true"
        onClick={addClient}
        onChange={handleChange}
      />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {filteredMetadataList && filteredMetadataList.length ? (
                filteredMetadataList.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                          style={{ cursor: "pointer",textAlign:'left' }}
                        >
                          {row.store_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {row.company_name}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.duration}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {dateFormatConversion(row.created_dt)}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {dateFormatConversion(row.updated_dt)}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.updated_by}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>

                          <Menu
                            className={classes.menuBox}
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => handleEdit(selectRow)}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              Edit Metadata
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
