import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { MoreVert as MoreIcon, Lens } from "@material-ui/icons";
import PageTitle from "../../../PageTitle/PageTitle";
import Widget from "../../../Widget/Widget";
import ManageCustomData from "./ManageCustomData";
import TableWidget from "../../../Widget/TableWidget";
import TableDesign from "../../../common/table/TableDesign";
import {
  dateFormatConversion,
  paramForAddFamilyProfile,loadState
} from "../../../../hooks/helper";
import { getCustomData } from "../../../../services/ServiceLayer";
import useStyles from "../styles";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Paper,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import DeleteCustomPopup from "../../../../pages/settingsPage/DeleteCustomPopup";
const tableHeadings = [
  {
    label: "S. No.",
    align: "left",
  },
  {
    label: "Field Name",
    align: "left",
  },
  // {
  //   label: "Associated Table Name",
  //   align: "left",
  // },
  // {
  //   label: "Associated Role",
  //   align: "left",
  // },
  // {
  //   label: "Data Type",
  //   align: "left",
  // },

  {
    label: "Data Value",
    align: "left",
  },
  // {
  //   label: "Updated By",
  //   align: "left",
  // },
  {
    label: "Action",
    align: "left",
  },
];
const datatableData = [];
// styles

export default function CustomDataList({ patientId }) {
  console.log("patientId", patientId);
  const userData = loadState().user;
  const userRole =loadState().user.user_role;
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectclient, setselectclient] = useState(null);
  const [selectRow, setSelectRow] = useState(null);
  const [title, setTitle] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [customDataList, setCustomDataList] = useState([]);
  const [dataUpdated, setDataUpdated] = useState(false);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  var classes = useStyles();
  const createUpdateClient = (client) => {
    console.log("client",client);
    setselectclient(client);
    // setModalShow(true);
    setTitle("Edit Custom Data");
    // setShowResult(true);
    setOpen(true);
    setAnchorEl(null);
  };
  const addFile = () => {
    console.log("add client called");
    setselectclient(null);
    // setModalShow(true);
    setTitle("Add Client");
    // setShowResult(true);
    setOpen(true);
    setAnchorEl(null);
  };
  const addFileLink = () => {
    setOpen(true);
    setTitle("Edit Custom Data");
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpen(false);
  };
  useEffect(() => {
    getCustomData(`patient_id=${patientId}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELREL`).then((response) => {
      console.log(response);
      setCustomDataList(response.data);
    });
  }, []);
  useEffect(() => {
    getCustomData(`patient_id=${patientId}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELREL`).then((response) => {
      console.log(response);
      setCustomDataList(response.data);
      setDataUpdated(false);
    });
  }, [dataUpdated]);

  const deleteEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);

    setselectclient(rec);
    setDeleteModalShow(true);
    setTitle("Delete Staff");
  };
  return (
    <div className="container">

<DeleteCustomPopup
        onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        selectclient={selectclient}
        setDeleteModalShow={setDeleteModalShow}
      />

      <ManageCustomData
        open={open}
        onHide={hideHandle}
        title={"Edit Custom Data"}
        selectclient={selectclient}
        setModalShow={setModalShow}
        setDataUpdated={setDataUpdated}
      />

      <PageTitle title="Custom Data" />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {customDataList && customDataList?.length ? (
                customDataList.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {row.data_name}
                        </TableCell>
                        {/* <TableCell className={classes.tableBodyCell}>
                          {row.associated_tbl_name}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.associated_role}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.data_type}
                        </TableCell> */}
                        <TableCell className={classes.tableBodyCell}>
                          {row.data_default_val}
                        </TableCell>
                        {/* <TableCell className={classes.tableBodyCell}>
                          {row.updated_by}
                        </TableCell> */}
                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            id="basic-button"
                            aria-controls={openDown ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openDown ? "true" : undefined}
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              onClick={() => createUpdateClient(selectRow)}
                            >
                              Edit
                            </MenuItem>

                            <MenuItem
                              onClick={() => deleteEventHandler(selectRow)}
                            >
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </div>
  );
}
