import { useEffect, useState } from "react";
import { inventorySchema } from "./Inventory.schema";

const InventoryValidation = (states, ...props) => {
    const [error, setError] = useState(null);
    console.log("Validation error",error)

    useEffect(() => {
        if(error === null){
            const statesArray = Object.keys(states);
            const er = {};
            for(let i=0; i< statesArray.length; i++){
                er[statesArray[i]] = null
            }
            setError(er);
        }
    }, [states])
    const checkValidator = (keyArr=null) => {
        const statesArray = keyArr ? [keyArr] : Object.keys(states);
        let result = {};

        statesArray.map((key, i) => {
            const schema = inventorySchema[key];
            if(schema?.required) {
                if(schema.properties?.empty && (states[key] === '' || states[key] === null)) {
                    result = {
                        ...result,
                        [key]: schema.properties?.empty
                    }
                } else if(schema.properties?.minLength && (states[key] && states[key].length < schema.properties?.minLength.limit)) {
                    result = {
                        ...result,
                        [key]: schema.properties?.minLength.msg
                    }
                } else if(schema.properties?.email) {
                    const trimmedEmail = states[key]?.trim() ?? '';
                    const position = states[key].lastIndexOf('@');
                    const indexOfDot = states[key].lastIndexOf('.');
                    if (trimmedEmail !== '' && position > 0 && indexOfDot > position && states[key].length - position > 4) {
                        result = {
                            ...result,
                            [key]: null
                        };
                    } else {
                        result = {
                            ...result,
                            [key]: schema.properties?.email
                        };
                    }
                } else if(key==='newPassword') {
                    const err = {};
                 
                    if(states[key] && states[key].length < 8){
                        err.length = true
                    }
                    if(states[key] && !(/[a-z]/.test(states[key]))){
                        err.lowercase = true
                    }
                    if(states[key] && !(/[A-Z]/.test(states[key]))){
                        err.uppercase = true
                    }
                    if(states[key] && !(/[0-9]/.test(states[key]))){
                        err.number = true
                    }
                    if(states[key] && !(/[!@#\$%\^\&*\)\(+=._-]/.test(states[key]))){
                        err.special = true
                    }
                    result = {
                        ...result,
                        [key]: Object.keys(err).length ? err : null
                    };

                } else if(key==='confirmPassword') {
                    if(states[key] !== states['newPassword']){
                        result = {
                            ...result,
                            [key]: schema.properties.match
                        };
                    } else{
                        result = {
                            ...result,
                            [key]: null
                        };
                    }
                }else if (
                    schema.properties?.positive &&
                    states[key] &&
                    parseInt(states[key]) < 1
                  ) {
                    result = {
                      ...result,
                      [key]: schema.properties?.positive,
                    };
                  } else {
                    result = {
                        ...result,
                        [key]: null
                    }
                }
                return null
            } else {
                result = {
                    ...result,
                    [key]: null
                }
            }
        })
        
        setError({...error, ...result});

    }

    const resetAllValidator = key => {
        setError(null);
    }

    const resetValidator = key => {
        setError({
            ...error,
            [key]: false
        });
    }
    
    const isError = () => {
        if(error === null) return true
        const statesArray = Object.keys(states);
        for(let i=0; i< statesArray.length; i++){
            if(error[statesArray[i]]){
                return true
            }
        }
        for(let i=0; i< statesArray.length; i++){
            if(!states[statesArray[i]]){
                return true
            }
        }
        return false
    }
    return [error, checkValidator, resetValidator, resetAllValidator, isError]
}

export default InventoryValidation;