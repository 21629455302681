import React, { useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import moment from "moment";
// components
// styles
import useStyles from "./styles";
import Profile from "./Profile";
import AddressBilling from "./AddressBilling";
import Additional from "./Additional";
import LatestBilling from "./LatestBilling";
import PatientStatus from "./PatientStatus";
import Emergency from "./Emergency";
export default function PatientProfile({
  patientData,
  setPatientData,
  titleValues,
  genderValues,
  maritalValues,
  genderIdentificationValues,
  sexOrientationValues,
  preferenceContactValues,
  preferenceHourValues,
  languageValues,
  addressStatus,
  ethinicityValues,
  raceValues,
  applicableRoleValues,
  disabilityValues,
  veteranValues,
  patientStatusValues,
  inactiveValues,
  deceasedValues,
}) {
  var classes = useStyles();
  console.log("patientData", patientData);
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Profile</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Profile
            patientData={patientData}
            setPatientData={setPatientData}
            titleValues={titleValues}
            genderValues={genderValues}
            maritalValues={maritalValues}
            genderIdentificationValues={genderIdentificationValues}
            sexOrientationValues={sexOrientationValues}
            preferenceContactValues={preferenceContactValues}
            preferenceHourValues={preferenceHourValues}
            languageValues={languageValues}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Address Billing</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AddressBilling
            patientData={patientData}
            setPatientData={setPatientData}
            addressStatus={addressStatus}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion >
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Additional</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Additional
            patientData={patientData}
            setPatientData={setPatientData}
            ethinicityValues={ethinicityValues}
            raceValues={raceValues}
            applicableRoleValues={applicableRoleValues}
            disabilityValues={disabilityValues}
            veteranValues={veteranValues}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion >
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Latest Billing</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LatestBilling
            patientData={patientData}
            setPatientData={setPatientData}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion >
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Patient Status</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PatientStatus
            patientData={patientData}
            setPatientData={setPatientData}
            patientStatusValues={patientStatusValues}
            inactiveValues={inactiveValues}
            deceasedValues={deceasedValues}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{mt: 4}}>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Emergency</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Emergency
            patientData={patientData}
            setPatientData={setPatientData}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
