import React, { useState } from "react";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../common/modal/Popup";
// styles
import useStyles from "../styles";
export default function ManageUploadCsv(props) {
  //
  console.log("props-24", props);
  const { selectclient } = props;
  console.log("selectclient", selectclient);

  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleClose}
            >
              Add
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <center>
              <div className={classes.groupBtn}>
                <Button
                  classes={{ root: classes.button }}
                  variant="contained"
                  color="secondary"
                  style={{ marginRight: "4px" }}
                >
                  Upload Csv File
                </Button>
                <Button
                  classes={{ root: classes.button }}
                  variant="contained"
                  color="secondary"
                >
                  Download Csv File Sample
                </Button>
              </div>
            </center>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
