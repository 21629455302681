import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";

import useStyles from "../styles";
import { formBinoElements } from "./Bino_Constant";

import { currentUser, loadState } from "../../../hooks/helper";
import {
  getBinoTestData,
  postBinoTestData,
} from "../../../services/ServiceLayer";
import { toast } from "react-toastify";

export default function BinoTesting({
  patientId,
  eyesType,
  setBinoBtnUnable,
  binoBtnUnable,
}) {
  console.log("eyesType", eyesType);
  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  // const previousController = useRef();
  const [binoTestingData, setBinoTestingData] = useState({
    pat_bino_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    ph_hz_dis: "",
    ph_hz_nr: "",
    ph_hz_eye: 0,
    ph_vt_dis: "",
    ph_vt_nr: "",
    ph_vt_eye: 0,
    vg_hz_dis: "",
    vg_hz_nr: "",
    vg_hz_eye: 0,
    vg_vt_dis: "",
    vg_vt_nr: "",
    vg_vt_eye: 0,
    ct_dis: "",
    ct_nr: "",
    ct_nra: "",
    ct_pra: "",
    ct_npc: "",
    ct_brake:'',
    ct_recovery:'',
    binocular_notes: "",
    updated_by: currentUser(),
    access_location: "ggg",
    opt_type: "ADDBNO",
  });

  const [isActionCalled, setIsActionCalled] = useState(false);
  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    // if (!isError() && error !== null && isActionCalled) {
    if (isActionCalled) {
      postBinoTestData(binoTestingData).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Binocular testing data added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setBinoBtnUnable(false);
          // clearForm();
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    // } else {
    //   setIsActionCalled(false);
    // }

    setIsActionCalled(false);
  }, [isActionCalled]);
  const [binoTestData, setBinoTestData] = useState([]);
  useEffect(() => {
    getBinoTestData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELBNO`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setBinoTestData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);

  const [discardUpdate, setDiscardUpdate] = useState(false);
  useEffect(() => {
    if (binoTestData && binoTestData.length > 0) {
      setBinoTestingData({
        pat_bino_id: binoTestData[0]?.pat_bino_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        ph_hz_dis: binoTestData[0]?.ph_hz_dis,
        ph_hz_nr: binoTestData[0]?.ph_hz_nr,
        ph_hz_eye: binoTestData[0]?.ph_hz_eye,
        ph_vt_dis: binoTestData[0]?.ph_vt_dis,
        ph_vt_nr: binoTestData[0]?.ph_vt_nr,
        ph_vt_eye: binoTestData[0]?.ph_vt_eye,
        vg_hz_dis: binoTestData[0]?.vg_hz_dis,
        vg_hz_nr: binoTestData[0]?.vg_hz_nr,
        vg_hz_eye: binoTestData[0]?.vg_hz_eye,
        vg_vt_dis: binoTestData[0]?.vg_vt_dis,
        vg_vt_nr: binoTestData[0]?.vg_vt_nr,
        vg_vt_eye: binoTestData[0]?.vg_vt_eye,
        ct_dis: binoTestData[0]?.ct_dis,
        ct_nr: binoTestData[0]?.ct_nr,
        ct_nra: binoTestData[0]?.ct_nra,
        ct_pra: binoTestData[0]?.ct_pra,
        ct_npc: binoTestData[0]?.ct_npc,
        ct_brake:binoTestData[0]?.ct_brake,
        ct_recovery:binoTestData[0]?.ct_recovery,
        binocular_notes: binoTestData[0]?.binocular_notes,
        updated_by: currentUser(),
        access_location: "ggg",
        opt_type: "UPDBNO",
      });
      setDiscardUpdate(false);
    }
  }, [binoTestData, discardUpdate]);

  const clearForm = () => {
    setBinoTestingData({
      pat_bino_id: 0,
      patient_id: patientId,
      location_id: userData?.location_id,
      ph_hz_dis: "",
      ph_hz_nr: "",
      ph_hz_eye: 0,
      ph_vt_dis: "",
      ph_vt_nr: "",
      ph_vt_eye: 0,
      vg_hz_dis: "",
      vg_hz_nr: "",
      vg_hz_eye: 0,
      vg_vt_dis: "",
      vg_vt_nr: "",
      vg_vt_eye: 0,
      ct_dis: "",
      ct_nr: "",
      ct_nra: "",
      ct_pra: "",
      ct_npc: "",
      ct_brake:'',
      ct_recovery:'',
      binocular_notes: "",
      updated_by: currentUser(),
      access_location: "ggg",
      opt_type: "ADDBNO",
    });
  };
  const [showDiscard, setShowDiscard] = useState(false);

  const discardHandler = () => {
    if (binoTestData && binoTestData.length > 0) {
      setBinoBtnUnable(false);
      setDiscardUpdate(true);
    } else {
      clearForm();
      setBinoBtnUnable(false);
    }
  };
  return (
    <>
      <Grid container spacing={2} className={classes.formBox}>
        {formBinoElements.map((element, i) => {
          return (
            <Grid
              item
              xs={12}
              md={element.mdSize}
              className={`${classes.textGroup} ${
                element.title ? classes.highlightedItem : ""
              }`}
              // style={{ border: "1px solid #DED9D9" }}
            >
              {element.label && (
                <Typography
                  className={classes.formHeading}
                  style={{
                    fontWeight: "500",
                    color: "#4E97FD",
                    position: "relative",
                  }}
                >
                  {element.label}&nbsp;
                  {element.required === "true" ? (
                    <span className={classes.required}>*</span>
                  ) : null}
                  {element.copyBtn === "true" && (
                    <Button
                      style={{
                        float: "right",
                        position: "absolute",
                        top: "20px",
                      }}
                      classes={{ root: classes.button }}
                      variant="contained"
                      color="secondary"
                      // onClick={duplicateValue}
                    >
                      =
                    </Button>
                  )}
                </Typography>
              )}
              {element.type ? (
                <>
                  {element.type === "select" &&
                  element.key !== "procedure_code" ? (
                    <FormControl
                      fullWidth
                      className={classes.dropdownFormControl}
                    >
                      <Select
                        style={{ fontSize: "12px" }}
                        name={element.key}
                        className={classes.selectDropdown}
                        placeholder={element.placeHolder}
                        value={
                          binoTestingData && binoTestingData[element.key]
                            ? binoTestingData && binoTestingData[element.key]
                            : ""
                        }
                        onChange={(e) => {
                          console.log("selected");
                          setBinoTestingData({
                            ...binoTestingData,
                            [element.key]: e.target.value,
                          });
                          setBinoBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      >
                        <MenuItem disabled value="">
                          Select
                        </MenuItem>
                        {(element.key === "ph_hz_eye" ||
                          element.key === "ph_vt_eye" ||
                          element.key === "vg_hz_eye" ||
                          element.key === "vg_vt_eye") &&
                        eyesType
                          ? eyesType.map((item, index) => (
                              <MenuItem
                                key={item.attribute_id}
                                value={item?.attribute_id}
                              >
                                {item.attribute_val}
                              </MenuItem>
                            ))
                          : element.options.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  ) : element.type === "text" ? (
                    <div style={{ display: "flex" }}>
                      <TextField
                        required
                        name={element.key}
                        placeholder={element.placeHolder}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                          maxLength: element.maxLength,
                          onKeyPress: element.onKeyPress,
                        }}
                        value={
                          binoTestingData && binoTestingData[element.key]
                            ? binoTestingData && binoTestingData[element.key]
                            : ""
                        }
                        onChange={(e) => {
                          console.log("selected");
                          setBinoTestingData({
                            ...binoTestingData,
                            [element.key]: e.target.value,
                          });
                          setBinoBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                      {element.key === "sku" && (
                        <Button
                          // onClick={selectFrameBySku}
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                        >
                          Slect
                        </Button>
                      )}
                    </div>
                  ) : element.type === "commission_amount" ? (
                    <TextField
                      type="text"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      size="small"
                      inputProps={{
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                        maxLength: element.maxLength,
                        onKeyPress: element.onKeyPress,
                      }}
                    />
                  ) : element.type === "date" ||
                    element.type === "datetime-local" ? (
                    <TextField
                      id={element.key}
                      name={element.key}
                      placeholder={element.placeHolder}
                      type={element.type}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        binoTestingData && binoTestingData[element.key]
                          ? binoTestingData && binoTestingData[element.key]
                          : ""
                      }
                      onChange={(e) => {
                        console.log("selected");
                        setBinoTestingData({
                          ...binoTestingData,
                          [element.key]: e.target.value,
                        });
                        setBinoBtnUnable(true);
                        setShowDiscard(true);
                      }}
                    />
                  ) : element.type === "radio" ? (
                    <RadioGroup row defaultValue="">
                      {element.radioValue.map((item) => (
                        <FormControlLabel
                          size="small"
                          style={{ padding: "0px 6px" }}
                          value={item}
                          control={<Radio />}
                          label={item}
                          className={classes.smallRadioButton}
                        />
                      ))}
                    </RadioGroup>
                  ) : element.type === "checkbox" ? (
                    <>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value={element.value}
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  const selectedValue = e.target.checked
                                    ? "Y"
                                    : "N";
                                  setBinoTestingData({
                                    ...binoTestingData,
                                    [element.key]: selectedValue,
                                  });
                                  setBinoBtnUnable(true);
                                  setShowDiscard(true);
                                }}
                              />
                            }
                            label={element.lbltitle}
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </FormControl>
                    </>
                  ) : element.type === "button" &&
                    element?.btnlbl === "Lookup" ? (
                    <>
                      <Button
                        classes={{ root: classes.button }}
                        variant="contained"
                        color="secondary"
                        // onClick={selectPrevFrame}
                      >
                        {element.btnlbl}
                      </Button>
                    </>
                  ) : element.type === "button" &&
                    element?.btnlbl === "Update" ? (
                    <>
                      {/* {selectedRow && (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                        //   onClick={updateSelectFrame}
                        >
                          {element.btnlbl}
                        </Button>
                      )} */}
                    </>
                  ) : (
                    <TextField
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      placeholder="Type here..."
                      variant="outlined"
                      multiline
                      rows={2}
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      value={
                        binoTestingData && binoTestingData[element.key]
                          ? binoTestingData && binoTestingData[element.key]
                          : ""
                      }
                      onChange={(e) => {
                        console.log("selected");
                        setBinoTestingData({
                          ...binoTestingData,
                          [element.key]: e.target.value,
                        });
                        setBinoBtnUnable(true);
                        setShowDiscard(true);
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <Typography
                    className={classes.formHeading}
                    style={{
                      fontWeight: "600",

                      color: "#4E97FD",
                    }}
                  >
                    {element.title}
                  </Typography>
                </>
              )}
            </Grid>
          );
        })}
        <Grid
          className={classes.btnGroup}
          item
          xs={12}
          style={{ display: "flex", justifyContent: "end" }}
        >
          {showDiscard && (
            <Button
              className={classes.btnleftOutline}
              variant="outlined"
              style={{ marginRight: "10px", fontSize: "12px" }}
              size="medium"
              onClick={discardHandler}
            >
              Discard
            </Button>
          )}
          <Button
            disabled={!binoBtnUnable}
            style={{ display: "flex", float: "right" }}
            onClick={handleSubmit}
            className={classes.btnlRightDark}
            variant="contained"
            size="medium"
            color="secondary"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
