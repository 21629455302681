import { Grid, TableCell, TableRow, Typography } from "@material-ui/core";
import React, { useState } from "react";
import useStyles from "../styles";
import TableWidget from "../../Widget/TableWidget";
import TableDesign from "../../common/table/TableDesign";

export default function OcularData() {
  var classes = useStyles();
  const [allergyData, setAllergyData] = useState([]);
  const tableHeadings = [
    {
      label: "Ocular History",
    },
  ];
  return (
    <>
     <Grid container spacing={4} style={{ width: "100%",marginTop:'10px' }}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {allergyData && allergyData?.length ? (
                allergyData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
