import React, { useState, useEffect } from "react";
import useStyles from "../styles";
import {
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { useParams, useLocation, useHistory } from "react-router-dom";

import PageTitle from "../../../PageTitle/PageTitle";
import moment from "moment";
import {
  getDoctorList,
  getLabDetail,
  getLocationAndResource,
  getPatient,
  postFrameOrder,
} from "../../../../services/ServiceLayer";
import { currentUser, loadState } from "../../../../hooks/helper";
import { ArrowDropDown } from "@material-ui/icons";
import PhysicalData from "./Accordion/PhysicalData";
import FrameData from "./Accordion/FrameData";
import SpecticalData from "./Accordion/SpecticalData";
import ContactData from "./Accordion/ContactData";
import OtherData from "./Accordion/OtherData";
import ServiceData from "./Accordion/ServiceData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OrderValidation from "../../../../hooks/OrderValidation";
import NewDiagnosis from "../../../ExamComponent/AssessmentTab/NewDiagnosis";

export default function MainOrder() {
  // call meta data
  const metaData = loadState().metaData;
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const history = useHistory();
  const [accordionType, setAccordionType] = useState();
  const [patientData, setPatientData] = useState([]);
  const [labDetailData, setLabDetailData] = useState([]);
  const [listData, setListData] = useState([]);
  const [invSourceData, setInvSourceData] = useState([]);
  const [specticaleAttrData, setSpecticaleAttrData] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [frameTypeData, setFrameTypeData] = useState([]);
  const [rimTypeData, setRimTypeData] = useState([]);
  const [frameShapeData, setFrameShapeData] = useState([]);
  const [frameMaterialData, setFrameMaterialData] = useState([]);
  const [orderTypeData, setOrderTypeData] = useState([]);
  const [labStatusData, setLabStatusData] = useState([]);
  const [eyeTypeData, setEyeTypeData] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [selectPhyscianData, setSelectPhyscianData] = useState([]);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [actionType, setActionType] = useState("ADDORD");
  const location = useLocation();
  const selectedRowAll = location.state ? location.state.selectedRow : null;

  var [activeTabId, setActiveTabId] = useState(0);
  var classes = useStyles();
  const { patient_id } = useParams();
  const currentDate = moment().format("YYYY-MM-DD");
  // call meta data

  const readAccessData = loadState().menu;
  useEffect(() => {
    console.log("loadState", metaData);
    setListData(metaData);
  }, []);
  useEffect(() => {
    getDoctorList(
      `user_id=${userData?.user_id}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSCH`
    ).then((response) => {
      console.log(response);
      setDoctorList(response?.data);
    });
    getLocationAndResource(
      `location_id=${userData.location_id}&&user_id=${userData.user_id}&user_role=${userData.user_role}&opt_type=SELSCH`
    ).then((response) => {
      console.log("response", response);

      const locationInfo = response?.data?.filter(
        (location) => location.location_id === `${userData.location_id}`
      );
      console.log("locationInfo=====???");

      console.log(locationInfo);

      if (locationInfo.length > 0) {
        console.log("locationInfo", locationInfo);
        setSelectPhyscianData(locationInfo[0]?.doc_info);
      }
    });
  }, []);

  useEffect(() => {
    console.log("selectPhyscianData", selectPhyscianData);
  }, [selectPhyscianData]);
  useEffect(() => {
    if (listData.length > 0) {
      const genderData = listData[0].gender;
      const invSourceData = listData[0].InventorySource;
      const frameTypeData = listData[0].FrameType;
      const rimTypeData = listData[0].RimType;
      const frameShapeData = listData[0].FrameShape;
      const frameMaterialData = listData[0].FrameMaterial;
      const orderTypeData = listData[0].ordertype;
      const eyeTypeData = listData[0].eyeType;
      const labStatusData = listData[0].labStatus;
      const specticaleAttrData = listData[0].spectacleattribute;
      setGenderData(genderData);
      setInvSourceData(invSourceData);
      setFrameTypeData(frameTypeData);
      setRimTypeData(rimTypeData);
      setFrameShapeData(frameShapeData);
      setFrameMaterialData(frameMaterialData);
      setOrderTypeData(orderTypeData);
      setEyeTypeData(eyeTypeData);
      setLabStatusData(labStatusData);
      setSpecticaleAttrData(specticaleAttrData);
    }
  }, [listData]);
  useEffect(() => {
    // patient details
    getPatient(
      `patient_id=${patient_id}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELHIS`
    ).then((response) => {
      console.log(response);
      setPatientData(response?.data);
      setActiveTabId(0);
    });

    getLabDetail(
      `lab_id=0&lab_name=&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELLAB`
    ).then((response) => {
      console.log(response);
      setLabDetailData(response?.data);
      // setActiveTabId(0);
    });
  }, []);
  const [itemDiscount, setItemDiscount] = useState();
  const [itemInsuranceDue, setItemInsuranceDue] = useState();
  const [itemInsurancePaid, setItemInsurancePaid] = useState();
  // Frame Data
  const [frameDataDetail, setFrameDataDetail] = useState({
    order_id: 0,
    location_id: userData?.location_id,
    patient_id: patient_id,

    physician_id: "",
    order_type: "",
    order_date: currentDate,
    employee_id: "",
    diagonsis_id: "",
    prescription_id: "",
    patient_due: "",
    insurance_due: "",
    insurance_paid: "",
    order_status: "",
    supplier_id: "",
    exam_id: "",
    ship_to: "",
    frame_id: "",
    frame_src: "",
    contlens_id: "",
    contlens_dtl_id: "",
    spec_id: "",
    canceled_reasonid: "",
    canceled_date: currentDate,
    total_order_value: "",
    total_order_value_discount: "",
    total_order_value_tax: "",
    notes: "" /* Service notes* */,
    order_item_id: "",
    item_type: "",
    item_id: "",
    quantity: "",
    item_discount: "",
    item_insurance_due: "",
    item_insurance_paid: "",
    item_patient_due: "",
    item_canceled_reasonid: "",
    item_canceled_date: "",
    item_total_order_value: "",
    item_order_value_discount: "",
    item_order_value_tax: "",
    lab_id: "",
    promised_date: "",
    lab_order_id: "",
    lab_order_status: "",
    order_inspected_by_id: "",
    order_delevered_by_id: "",
    order_reprocess_reason: "",
    shipping_address_1: "",
    shipping_address_2: "",
    state: "",
    city: "",
    zip: "",
    item_notes: "",
    updated_by: "",
    user_role: userRole,
    opt_type: actionType,
  });

  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    OrderValidation({
      // quantity: frameDataDetail?.quantity,
      // item_discount: frameDataDetail?.item_discount,
      // item_insurance_due: frameDataDetail?.item_insurance_due,
      // item_insurance_paid: frameDataDetail?.item_insurance_paid,
      // shipping_address_1: frameDataDetail?.shipping_address_1,
      // shipping_address_2: frameDataDetail?.shipping_address_1,
      // state: frameDataDetail?.state,
      // city: frameDataDetail?.city,
      // zip: frameDataDetail?.zip,
    });
  useEffect(() => {
    if (selectedRowAll) {
      console.log("selectedRowAll in MainOrder", selectedRowAll);
      setFrameDataDetail({
        order_id: selectedRowAll?.order_id,
        location_id: userData?.location_id,
        patient_id: patient_id,
        physician_id:
          selectedRowAll?.physician_id === undefined
            ? 0
            : selectedRowAll?.physician_id,
        order_type: selectedRowAll?.order_type,
        order_date: currentDate,
        employee_id:
          selectedRowAll?.employee_id === undefined
            ? 0
            : selectedRowAll?.employee_id,
        diagonsis_id:
          selectedRowAll?.diagonsis_id === undefined
            ? 0
            : selectedRowAll?.diagonsis_id,
        prescription_id:
          selectedRowAll?.prescription_id === undefined
            ? 0
            : selectedRowAll?.prescription_id,
        patient_due:
          selectedRowAll?.patient_due === undefined
            ? ""
            : selectedRowAll?.patient_due,
        insurance_due:
          selectedRowAll?.insurance_due === undefined
            ? ""
            : selectedRowAll?.insurance_due,
        insurance_paid:
          selectedRowAll?.insurance_paid === undefined
            ? ""
            : selectedRowAll?.insurance_paid,
        order_status: selectedRowAll?.order_status,
        supplier_id:
          selectedRowAll?.supplier_id === undefined
            ? 0
            : selectedRowAll?.supplier_id,
        exam_id:
          selectedRowAll?.exam_id === undefined ? 0 : selectedRowAll?.exam_id,
        ship_to:
          selectedRowAll?.ship_to === undefined ? "" : selectedRowAll?.ship_to,
        frame_id:
          selectedRowAll?.frame_id === undefined ? 0 : selectedRowAll?.frame_id,
        frame_src:
          selectedRowAll?.frame_src === undefined
            ? 0
            : selectedRowAll?.frame_src,
        contlens_id:
          selectedRowAll?.contlens_id === undefined
            ? 0
            : selectedRowAll?.contlens_id,
        contlens_dtl_id:
          selectedRowAll?.contlens_dtl_id === undefined
            ? 0
            : selectedRowAll?.contlens_dtl,
        spec_id:
          selectedRowAll?.spec_id === undefined ? 0 : selectedRowAll?.spec_id,
        canceled_reasonid:
          selectedRowAll?.canceled_reasonid === undefined
            ? 0
            : selectedRowAll?.canceled_reasonid,
        canceled_date: currentDate,
        total_order_value:
          selectedRowAll?.total_order_value === undefined
            ? ""
            : selectedRowAll?.total_order_value,
        total_order_value_discount:
          selectedRowAll?.total_order_value_discount === undefined
            ? ""
            : selectedRowAll?.total_order_value,
        total_order_value_tax:
          selectedRowAll?.total_order_value_tax === undefined
            ? ""
            : selectedRowAll?.total_order_value,
        notes: selectedRowAll?.notes === undefined ? "" : selectedRowAll?.notes,
        order_item_id:
          selectedRowAll?.order_item_id === undefined
            ? 0
            : selectedRowAll?.order_item_id,
        item_type:
          selectedRowAll?.item_type === undefined
            ? ""
            : selectedRowAll?.item_type,
        item_id:
          selectedRowAll?.item_id === undefined ? 0 : selectedRowAll?.item_id,
        quantity:
          selectedRowAll?.quantity === undefined
            ? ""
            : selectedRowAll?.quantity,
        item_discount:
          selectedRowAll?.item_discount === undefined
            ? ""
            : selectedRowAll?.item_discount,
        item_insurance_due:
          selectedRowAll?.item_insurance_due === undefined
            ? ""
            : selectedRowAll?.item_insurance_due,
        item_insurance_paid:
          selectedRowAll?.item_insurance_paid === undefined
            ? ""
            : selectedRowAll?.item_insurance_paid,
        item_patient_due:
          selectedRowAll?.item_patient_due === undefined
            ? ""
            : selectedRowAll?.item_patient_due,
        item_canceled_reasonid:
          selectedRowAll?.item_canceled_reasonid === undefined
            ? 0
            : selectedRowAll?.item_canceled_reasonid,
        item_canceled_date:
          selectedRowAll?.item_canceled_date === undefined
            ? "2032-12-31"
            : selectedRowAll?.item_canceled_date,
        item_total_order_value:
          selectedRowAll?.item_total_order_value === undefined
            ? ""
            : selectedRowAll?.item_total_order_value,
        item_order_value_discount:
          selectedRowAll?.item_order_value_discount === undefined
            ? ""
            : selectedRowAll?.item_order_value_discount,
        item_order_value_tax:
          selectedRowAll?.item_order_value_tax === undefined
            ? ""
            : selectedRowAll?.item_order_value_tax,
        item_notes:
          selectedRowAll?.item_notes === undefined
            ? ""
            : selectedRowAll?.item_notes,
        lab_id:
          selectedRowAll?.lab_id === undefined ? "" : selectedRowAll?.lab_id,
        promised_date:
          selectedRowAll?.promised_date === undefined
            ? "2032-12-31"
            : moment(selectedRowAll?.promised_date).format("YYYY-MM-DD"),
        lab_order_id:
          selectedRowAll?.lab_order_id === undefined
            ? ""
            : selectedRowAll?.lab_order_id,
        lab_order_status:
          selectedRowAll?.lab_order_status === undefined
            ? ""
            : selectedRowAll?.lab_order_status,
        order_inspected_by_id:
          selectedRowAll?.order_inspected_by_id === undefined
            ? ""
            : selectedRowAll?.order_inspected_by_id,
        order_delevered_by_id:
          selectedRowAll?.order_delevered_by_id === undefined
            ? ""
            : selectedRowAll?.order_delevered_by_id,
        order_reprocess_reason:
          selectedRowAll?.order_reprocess_reason === undefined
            ? ""
            : selectedRowAll?.order_reprocess_reason,
        shipping_address_1:
          selectedRowAll?.shipping_address_1 === undefined
            ? ""
            : selectedRowAll?.shipping_address_1,
        shipping_address_2:
          selectedRowAll?.shipping_address_2 === undefined
            ? ""
            : selectedRowAll?.shipping_address_2,
        state: selectedRowAll?.state === undefined ? "" : selectedRowAll?.state,
        city: selectedRowAll?.city === undefined ? "" : selectedRowAll?.city,
        zip: selectedRowAll?.zip === undefined ? "" : selectedRowAll?.zip,
        updated_by: currentUser(),
        user_role: userRole,
        opt_type: "UPDORD",
      });
      setAccordionType(selectedRowAll?.order_type);
    }
  }, [selectedRowAll]);
  const handleSaveOrder = (status) => {
    console.log("status", status);
    setFrameDataDetail((prevFrameDataDetail) => ({
      ...prevFrameDataDetail,
      order_status: status,
    }));
    checkValidator();
    setIsActionCalled(true);
  };
  useEffect(() => {
    if (!isError() && error !== null && isActionCalled) {
      // Now, postFrameOrder with the updated frameDataDetail
      if (
        (frameDataDetail?.order_type === "247" &&
          frameDataDetail?.frame_id !== "") ||
        (frameDataDetail?.order_type === "249" &&
          frameDataDetail?.spec_id !== "")
      ) {
        postFrameOrder(frameDataDetail).then((response) => {
          console.log(response);
          if (response.status.status) {
            toast.success("Order added successfully.", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsActionCalled(false);
            setTimeout(() => {
              history.push(`/app/New-Orders`);
            }, 2000);

            // clearForm();
            // if (isActionExit) {
            //   goBack();
            // }
            // setIsActionExit(false);
          } else {
            toast.error(response?.status?.description, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsActionCalled(false);
            // setIsActionExit(false);
          }
        });
      } else {
        if (frameDataDetail?.order_type === "247") {
          toast.error("Please Select Frame Data");
        } else if (frameDataDetail?.order_type === "249") {
          toast.error("Please Select Spectacle lens");
        } else {
          toast.error("Please Select Order Type");
        }
      }
    }
    setIsActionCalled(false);
  }, [isActionCalled]);
  return (
    <>
      <ToastContainer />
      <PageTitle title="Orders" />

      <Accordion defaultExpanded>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Physician Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PhysicalData
            selectPhyscianData={selectPhyscianData}
            selectedRow={selectedRowAll}
            accordionType={accordionType}
            setAccordionType={setAccordionType}
            frameDataDetail={frameDataDetail}
            setFrameDataDetail={setFrameDataDetail}
            orderTypeData={orderTypeData}
            patientData={patientData}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Diagnosis</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <NewDiagnosis enable={false} />
        </AccordionDetails>
      </Accordion>
      {(accordionType === "247" || accordionType === "249") && (
        <Accordion defaultExpanded>
          <AccordionSummary
            className={classes.AccordionSummary}
            expandIcon={<ArrowDropDown />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Frame Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FrameData
              selectedRowAll={selectedRowAll}
              frameDataDetail={frameDataDetail}
              setFrameDataDetail={setFrameDataDetail}
            />
          </AccordionDetails>
        </Accordion>
      )}
      {accordionType === "249" && (
        <Accordion defaultExpanded>
          <AccordionSummary
            className={classes.AccordionSummary}
            expandIcon={<ArrowDropDown />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Spectacle Lens</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SpecticalData specticaleAttrData={specticaleAttrData} />
          </AccordionDetails>
        </Accordion>
      )}
      {accordionType === "250" && (
        <Accordion defaultExpanded>
          <AccordionSummary
            className={classes.AccordionSummary}
            expandIcon={<ArrowDropDown />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Soft Contact Lens</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ContactData />
          </AccordionDetails>
        </Accordion>
      )}

      {accordionType === "251" && (
        <Accordion defaultExpanded>
          <AccordionSummary
            className={classes.AccordionSummary}
            expandIcon={<ArrowDropDown />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Hard Contact Lens</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <OtherData />
          </AccordionDetails>
        </Accordion>
      )}

      <Accordion defaultExpanded>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Service Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ServiceData
            doctorList={doctorList}
            labDetailData={labDetailData}
            labStatusData={labStatusData}
            eyeTypeData={eyeTypeData}
            setEyeTypeData={setEyeTypeData}
            selectedRowAll={selectedRowAll}
            frameDataDetail={frameDataDetail}
            setFrameDataDetail={setFrameDataDetail}
          />
        </AccordionDetails>
      </Accordion>
      <div className={classes.footerBox}>
        <div>
          <Button
            variant="outlined"
            className={classes.leftButton}
            style={{ marginRight: "12px" }}
            // onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            className={classes.leftButton}
            style={{ marginRight: "12px" }}
            onClick={() => handleSaveOrder(0)}
          >
            Save as Draft
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.rightButton}
            onClick={() => handleSaveOrder(1)}
            style={{ marginRight: "12px" }}
          >
            Submit Order
          </Button>
        </div>
      </div>
    </>
  );
}
