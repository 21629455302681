import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../common/modal/Popup";
// styles
import useStyles from "../styles";

import moment from "moment";

import { postContactFinal } from "../../../services/ServiceLayer";
import { toast } from "react-toastify";

import { formFinalContactElements } from "./Contact_Constant";
import useListData from "../../common/allMetaData/useListData";

export default function ManageFinalContact(props) {
  const currentDate = moment().format("YYYY-MM-DD");

  //

  console.log("props-24", props);
  const {
    patientId,

    selectclient,
    setTitle,
    setContactFinalDetails,
    contactFinalDetails,
    manufacturerData,
    supplierData,
  } = props;
  console.log("selectclient", selectclient);
  const listData = useListData();
  var classes = useStyles();
  const { examAssessmentFollowUpStatus } = listData;

  const [maxWidth, setMaxWidth] = React.useState("lg");

  const [isActionCalled, setIsActionCalled] = useState(false);

  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    // if (!isError() && error !== null && isActionCalled) {
    if (isActionCalled) {
      postContactFinal(contactFinalDetails).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Conatct final added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });

          props.onHide();
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    // } else {
    //   setIsActionCalled(false);
    // }

    setIsActionCalled(false);
  }, [isActionCalled]);

  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
  };
  const handleCheckboxChange = (copy, paste, e) => {
    console.log("copy", copy);
    console.log("copy to", paste);

    setContactFinalDetails({
      ...contactFinalDetails,

      [`${paste}_bc`]: contactFinalDetails?.[`${copy}_bc`],
      [`${paste}_dia`]: contactFinalDetails?.[`${copy}_dia`],
      [`${paste}_sph`]: contactFinalDetails?.[`${copy}_sph`],
      [`${paste}_cyl`]: contactFinalDetails?.[`${copy}_cyl`],
      [`${paste}_axis`]: contactFinalDetails?.[`${copy}_axis`],
      [`${paste}_add`]: contactFinalDetails?.[`${copy}_add`],
      [`${paste}_dva`]: contactFinalDetails?.[`${copy}_dva`],
      [`${paste}_nva`]: contactFinalDetails?.[`${copy}_nva`],
      [`${paste}_tint`]: contactFinalDetails?.[`${copy}_tint`],
      [`${paste}_clr`]: contactFinalDetails?.[`${copy}_clr`],
      [`${paste}_qty`]: contactFinalDetails?.[`${copy}_qty`],
      [`${paste}_material`]: contactFinalDetails?.[`${copy}_material`],
      [`${paste}_blend`]: contactFinalDetails?.[`${copy}_blend`],
      [`${paste}_manufacturer`]: contactFinalDetails?.[`${copy}_manufacturer`],
      [`${paste}_prodname`]: contactFinalDetails?.[`${copy}_prodname`],

      // opt_type: "ADDCNT",
    });
  };
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        // fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleSubmit}
            >
              {selectclient ? "Update" : "Add"}
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={2}
              className={classes.formBox}
              style={{ marginBottom: "4px" }}
            >
              {formFinalContactElements.map((element, i) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={`${classes.textGroup} ${
                        element.title ? classes.highlightedItem : ""
                      }`}
                      // style={{ border: "1px solid #DED9D9" }}
                    >
                      {element.label && (
                        <Typography
                          className={classes.formHeading}
                          style={{
                            fontWeight: "500",
                            color: "#4E97FD",
                            position: "relative",
                          }}
                        >
                          {element.label}&nbsp;
                          {element.required === "true" ? (
                            <span className={classes.required}>*</span>
                          ) : null}
                          {element?.copyBtn === "true" && (
                            <Button
                              style={{
                                float: "right",
                                position: "relative",
                                top: "20px",
                                fontSize: "12px",
                                textTransform: "none",
                              }}
                              classes={{ root: classes.button }}
                              variant="contained"
                              size="small"
                              color="secondary"
                              onClick={() =>
                                handleCheckboxChange(
                                  element?.action === "od" ? "od" : "os",
                                  element?.action === "od" ? "os" : "od"
                                )
                              }
                            >
                              {element?.copyLabel}
                            </Button>
                          )}
                        </Typography>
                      )}
                      {element.type ? (
                        <>
                          {element.type === "select" &&
                          element.key !== "procedure_code" ? (
                            <FormControl
                              fullWidth
                              className={classes.dropdownFormControl}
                            >
                              <Select
                                name={element.key}
                                className={classes.selectDropdown}
                                placeholder={element.placeHolder}
                                value={
                                  contactFinalDetails &&
                                  contactFinalDetails[element.key]
                                    ? contactFinalDetails &&
                                      contactFinalDetails[element.key]
                                    : ""
                                }
                                onChange={(e) => {
                                  console.log("selected");
                                  setContactFinalDetails({
                                    ...contactFinalDetails,
                                    [element.key]: e.target.value,
                                  });
                                }}
                              >
                                <MenuItem disabled value="">
                                  Select
                                </MenuItem>
                                {(element.key === "od_prodname" ||
                                  element.key === "os_prodname") &&
                                supplierData
                                  ? supplierData.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.supplier_id}
                                      >
                                        {item.supplier_name}
                                      </MenuItem>
                                    ))
                                  : (element.key === "od_manufacturer" ||
                                      element.key === "os_manufacturer") &&
                                    manufacturerData
                                  ? manufacturerData.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.manufacturer_id}
                                      >
                                        {item.manufacturer_name}
                                      </MenuItem>
                                    ))
                                  : element.key === "ou_status" &&
                                    examAssessmentFollowUpStatus
                                  ? examAssessmentFollowUpStatus.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.options.map((item) => (
                                      <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                              </Select>
                            </FormControl>
                          ) : element.type === "text" ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {element.txtLabel && (
                                <span
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "10px",
                                  }}
                                >
                                  {element.txtLabel}
                                </span>
                              )}
                              <TextField
                                required
                                name={element.key}
                                placeholder={element.placeHolder}
                                size="small"
                                className={classes.txtForm}
                                fullWidth
                                variant="outlined"
                                inputProps={{
                                  style: {
                                    height: "12px",
                                    fontSize: "12px",
                                  },
                                  maxLength: element.maxLength,
                                  onKeyPress: element.onKeyPress,
                                }}
                                value={
                                  contactFinalDetails &&
                                  contactFinalDetails[element.key]
                                    ? contactFinalDetails &&
                                      contactFinalDetails[element.key]
                                    : ""
                                }
                                onChange={(e) => {
                                  console.log("selected");
                                  setContactFinalDetails({
                                    ...contactFinalDetails,
                                    [element.key]: e.target.value,
                                  });
                                }}
                              />
                              {element.key === "sku" && (
                                <Button
                                  // onClick={selectFrameBySku}
                                  classes={{ root: classes.button }}
                                  variant="contained"
                                  color="secondary"
                                >
                                  Slect
                                </Button>
                              )}
                            </div>
                          ) : element.type === "checkbox" ? (
                            <>
                              <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                  <FormControlLabel
                                    value={element.value}
                                    control={
                                      <Checkbox
                                        onChange={(e) => {
                                          const selectedValue = e.target.checked
                                            ? "Y"
                                            : "N";
                                          setContactFinalDetails({
                                            ...contactFinalDetails,
                                            [element.key]: selectedValue,
                                          });
                                        }}
                                      />
                                    }
                                    label={element.lbltitle}
                                    labelPlacement="start"
                                  />
                                </FormGroup>
                              </FormControl>
                            </>
                          ) : element.type === "date" ||
                            element.type === "datetime-local" ? (
                            <TextField
                              id={element.key}
                              name={element.key}
                              placeholder={element.placeHolder}
                              type={element.type}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                              }}
                              value={
                                contactFinalDetails &&
                                contactFinalDetails[element.key]
                                  ? contactFinalDetails &&
                                    contactFinalDetails[element.key]
                                  : currentDate
                              }
                              onChange={(e) => {
                                console.log("selected");
                                setContactFinalDetails({
                                  ...contactFinalDetails,
                                  [element.key]: e.target.value,
                                });
                              }}
                            />
                          ) : element.type === "button" &&
                            element?.btnlbl === "Lookup" ? (
                            <>
                              <Button
                                classes={{ root: classes.button }}
                                variant="contained"
                                color="secondary"
                                // onClick={selectPrevFrame}
                              >
                                {element.btnlbl}
                              </Button>
                            </>
                          ) : element.type === "button" &&
                            element?.btnlbl === "Update" ? (
                            <>
                              {/* {selectedRow && (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                        //   onClick={updateSelectFrame}
                        >
                          {element.btnlbl}
                        </Button>
                      )} */}
                            </>
                          ) : (
                            <TextField
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              placeholder="Type here..."
                              variant="outlined"
                              multiline
                              rows={3}
                              inputProps={{
                                style: {
                                  fontSize: "12px",
                                },
                              }}
                              value={
                                contactFinalDetails &&
                                contactFinalDetails[element.key]
                                  ? contactFinalDetails &&
                                    contactFinalDetails[element.key]
                                  : ""
                              }
                              onChange={(e) => {
                                console.log("selected");
                                setContactFinalDetails({
                                  ...contactFinalDetails,
                                  [element.key]: e.target.value,
                                });
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <Typography
                            className={classes.formHeading}
                            style={{
                              fontWeight: "600",

                              color: "#4E97FD",
                            }}
                          >
                            {element.title}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
