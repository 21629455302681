import React, { useState, useEffect } from "react";

// Model
import {
  Button,
  Typography,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Popup from "../../common/modal/Popup";
import moment from "moment";
import alertIcon from "../../../assets/alertIcon.svg";
// styles
import useStyles from "../styles";
import { toast } from "react-toastify";

import { currentUser, loadState } from "../../../hooks/helper";
import { postAllergyData, postVitalData } from "../../../services/ServiceLayer";

export default function DeleteVitalsPopup({
  onHide,
  deleteModalShow,
  setDeleteModalShow,
  selectclient,
  patientId,
}) {
  console.log("selectclient", selectclient);
  console.log("deleteModalShow", deleteModalShow);
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const [actionType, setActionType] = useState("DELVIT");
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [isActionCalled, setIsActionCalled] = useState(false);
  toast.configure();
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setDeleteModalShow(false);
    setOpen(false);
  };
  const [companyDetail, setCompanyDetail] = useState({
    pat_vit_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    pat_weight_lbs: "",
    pat_height: "",
    pat_bp_systolic: "",
    pat_bp_diastolic: "",
    pat_pulse: "",
    pat_temp: "",
    pat_respiration: "",
    pat_oxyg_sat: "",
    pat_bmi: "",
    pat_bmi_status: "",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: actionType,
  });
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setCompanyDetail({
        pat_vit_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    pat_weight_lbs: "",
    pat_height: "",
    pat_bp_systolic: "",
    pat_bp_diastolic: "",
    pat_pulse: "",
    pat_temp: "",
    pat_respiration: "",
    pat_oxyg_sat: "",
    pat_bmi: "",
    pat_bmi_status: "",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: actionType,
      });
    } else {
      setCompanyDetail({
        pat_vit_id: selectclient?.pat_vit_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        pat_weight_lbs: selectclient?.pat_weight_lbs,
        pat_height: selectclient?.pat_height,
        pat_bp_systolic: selectclient?.pat_bp_systolic,
        pat_bp_diastolic: selectclient?.pat_bp_diastolic,
        pat_pulse: selectclient?.pat_pulse,
        pat_temp: selectclient?.pat_temp,
        pat_respiration: selectclient?.pat_respiration,
        pat_oxyg_sat: selectclient?.pat_oxyg_sat,
        pat_bmi: selectclient?.pat_bmi,
        pat_bmi_status: selectclient?.pat_bmi_status,
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: actionType,
      });
    }
  }, [selectclient]);

  const deleteFormData = () => {
    setIsActionCalled(true);
    setCompanyDetail({
      ...companyDetail,
      opt_type: actionType,
    });
  };

  useEffect(() => {
    console.log("isActionCalled", isActionCalled);

    if (isActionCalled) {
      postVitalData(companyDetail).then((response) => {
        console.log("actionType", actionType);

        console.log(response);
        if (response.status.status) {
          toast.success("Vitals deleted successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });

          onHide();
          setDeleteModalShow(false);
          setOpen(false);
          setIsActionCalled(false);
        } else {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActionCalled(false);
        }
      });
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={deleteFormData}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <img src={alertIcon} alt="img" />
            <Typography className={classes.formActiveHeading}>
              Are you sure! You want to Delete this vitals?
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
