import React, { useState, useEffect } from "react";
import useStyles from "../../styles";
import {
  Grid,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Select,
  FormControl,
  FormGroup,
  Checkbox,
  Button,
  Avatar,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";
import { physicalFormElements } from "../order_constant";

import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLocationAndResource } from "../../../../../services/ServiceLayer";
export default function PhysicalData({
  selectPhyscianData,
  selectedRow,
  patientData,
  orderTypeData,
  frameDataDetail,
  setFrameDataDetail,
  setAccordionType,
  accordionType,
}) {
  var classes = useStyles();
  console.log("selectedRow", selectedRow);
  console.log("frameDataDetail", frameDataDetail);
  console.log("selectPhyscianData",selectPhyscianData);

  useEffect(() => {
    if (selectedRow) {
      setFrameDataDetail({
        ...frameDataDetail,
        physician_id: selectedRow?.physician_id,
        order_type: selectedRow?.order_type,
      });
      setAccordionType(selectedRow?.ordertype_val);
    }
  }, [selectedRow]);
  // useEffect(() => {
  //   if (patientData) {
  //     setFrameDataDetail({
  //       ...frameDataDetail,
  //       physician_name: patientData?.primary_physician,
  //       order_type:patientData?.order_type
  //     });
  //   }
  // }, [patientData]);
  return (
    <>
      <Grid
        container
        spacing={1}
        style={{
          marginBottom: "16px",
        }}
      >
        {physicalFormElements.map((element, i) => {
          return (
            <Grid
              item
              xs={12}
              md={element.mdSize}
              className={`${classes.textGroup} ${
                element.title ? classes.highlightedItem : ""
              }`}
              style={{ border: "1px solid #DED9D9" }}
            >
              <Typography
                className={classes.formHeading}
                style={{ fontWeight: "500", color: "#4E97FD" }}
              >
                {element.label}{" "}
                {element.required === "true" ? (
                  <span className={classes.required}>*</span>
                ) : null}
              </Typography>
              {element.type ? (
                <>
                  {element.type === "select" &&
                  element.key !== "procedure_code" ? (
                    <FormControl
                      fullWidth
                      className={classes.dropdownFormControl}
                    >
                      <Select
                        name={element.key}
                        className={classes.selectDropdown}
                        placeholder={element.placeHolder}
                        value={
                          frameDataDetail && frameDataDetail[element.key]
                            ? frameDataDetail && frameDataDetail[element.key]
                            : ""
                        }
                        onChange={(e) => {
                          console.log("selected");
                          setFrameDataDetail({
                            ...frameDataDetail,
                            [element.key]: e.target.value,
                          });
                          if(element.key==='order_type'){
                            setAccordionType(e.target.value);
                          }
                          
                        }}
                      >
                        <MenuItem disabled value="">
                          Select
                        </MenuItem>
                        {element.key === "physician_id" && selectPhyscianData
                          ? selectPhyscianData.map((item) => (
                              <MenuItem key={item.doc_id} value={item?.doc_id}>
                                {item.doc_name}
                              </MenuItem>
                            ))
                          : element.key === "order_type" && orderTypeData
                          ? orderTypeData.map((item) => (
                              <MenuItem
                                key={item.attribute_id}
                                value={item?.attribute_id}
                              >
                                {item.attribute_val}
                              </MenuItem>
                            ))
                          : element.options.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  ) : element.type === "text" ? (
                    <div style={{ display: "flex" }}>
                      <TextField
                        required
                        name={element.key}
                        placeholder={element.placeHolder}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                          maxLength: element.maxLength,
                          onKeyPress: element.onKeyPress,
                        }}
                        value={frameDataDetail && frameDataDetail[element.key]}
                        onChange={(e) => {
                          console.log("selected");
                          setFrameDataDetail({
                            ...frameDataDetail,
                            [element.key]: e.target.value,
                          });
                        }}
                      />
                      {element.key === "sku" && (
                        <Button
                          // onClick={selectFrameBySku}
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                        >
                          Slect
                        </Button>
                      )}
                    </div>
                  ) : element.type === "commission_amount" ? (
                    <TextField
                      type="text"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      size="small"
                      inputProps={{
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                        maxLength: element.maxLength,
                        onKeyPress: element.onKeyPress,
                      }}
                    />
                  ) : element.type === "date" ||
                    element.type === "datetime-local" ? (
                    <TextField disabled
                      id={element.key}
                      name={element.key}
                      placeholder={element.placeHolder}
                      type={element.type}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={frameDataDetail && frameDataDetail[element.key]}
                      onChange={(e) => {
                        console.log("selected");
                        setFrameDataDetail({
                          ...frameDataDetail,
                          [element.key]: e.target.value,
                        });
                      }}
                    />
                  ) : element.type === "radio" ? (
                    <RadioGroup row defaultValue="">
                      {element.radioValue.map((item) => (
                        <FormControlLabel
                          size="small"
                          style={{ padding: "0px 6px" }}
                          value={item}
                          control={<Radio />}
                          label={item}
                          className={classes.smallRadioButton}
                        />
                      ))}
                    </RadioGroup>
                  ) : element.type === "checkbox" ? (
                    <>
                      <Checkbox name={element.key} />
                    </>
                  ) : (
                    <TextField
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      placeholder="Type here..."
                      variant="outlined"
                      multiline
                      rows={3}
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <Typography
                    className={classes.formHeading}
                    style={{
                      fontWeight: "600",

                      color: "#4E97FD",
                    }}
                  >
                    {element.title}
                  </Typography>
                </>
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
