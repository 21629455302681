import React, { useState, useEffect } from "react";

// Model
import {
  Button,
  Typography,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Popup from "../common/modal/Popup";
import moment from "moment";
import alertIcon from "../Inventory/alertIcon.svg";
// styles
import useStyles from "./styles";
import { toast } from "react-toastify";
import { postFrameOrder, postInvtcontactLense, postMetaData } from "../../services/ServiceLayer";
import { currentUser, loadState } from "../../hooks/helper";

export default function DeleteMetaDataPopup({
  onHide,
  deleteModalShow,
  setDeleteModalShow,
  selectclient,setRefreshList
}) {
  console.log("selectclient", selectclient);
  console.log("deleteModalShow", deleteModalShow);
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const [actionType, setActionType] = useState("DELMET");
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [isActionCalled, setIsActionCalled] = useState(false);
  toast.configure();
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setDeleteModalShow(false);
    setOpen(false);
  };
  const [companyDetail, setCompanyDetail] = useState({
    seq_id: 0,
    location_id: userData?.location_id,
    grp_meta_key: "",
    grp_meta_name: "",
    grpsub_meta_key: "",
    grpsub_meta_name: "",
    grp_attribute_key: "",
    grp_attribute_val: "",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: actionType,
  });
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setCompanyDetail({
        seq_id: 0,
        location_id: userData?.location_id,
        grp_meta_key: "",
        grp_meta_name: "",
        grpsub_meta_key: "",
        grpsub_meta_name: "",
        grp_attribute_key: "",
        grp_attribute_val: "",
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: actionType,
      });
    } else {
      setCompanyDetail({
        seq_id: selectclient?.seq_id,
    location_id: userData?.location_id,
    grp_meta_key: selectclient?.grp_meta_key,
    grp_meta_name: selectclient?.grp_meta_name,
    grpsub_meta_key: selectclient?.grpsub_meta_key,
    grpsub_meta_name: selectclient?.grpsub_meta_name,
    grp_attribute_key: selectclient?.grp_attribute_key,
    grp_attribute_val: selectclient?.grp_attribute_val,
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: actionType,
      });
    }
  }, [selectclient]);

  const deleteFormData = () => {
    setIsActionCalled(true);
    setCompanyDetail({
      ...companyDetail,
      opt_type: actionType,
    });
  };

  useEffect(() => {
    console.log("isActionCalled", isActionCalled);

    if (isActionCalled) {
      postMetaData(companyDetail).then((response) => {
        console.log("actionType", actionType);

        console.log(response);
        if (response.status.status) {
          toast.success("Meta Data deleted successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });

          onHide();
          setDeleteModalShow(false);
          setOpen(false);
          setIsActionCalled(false);
          setRefreshList(true);
        } else {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActionCalled(false);
        }
      });
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={deleteFormData}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <img src={alertIcon} alt="img" />
            <Typography className={classes.formActiveHeading}>
              Are you sure! You want to Delete this MetaData?
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
