import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../common/modal/Popup";
// styles
import useStyles from "../styles";
import moment from "moment";
import { Autocomplete } from "@mui/material";
import ManageFindMedication from "./ManageFindMedication";
import {
  getMedicineListData,
  getStaffAvailability,
  postMedicineData,
} from "../../../services/ServiceLayer";
import { currentUser, loadState } from "../../../hooks/helper";
import { toast } from "react-toastify";
export default function ManageMedication(props) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  //
  const formElements = [
    {
      key: "medication",
      label: "Medication",
      placeHolder: "Enter Medication",
      type: "text",

      mdSize: "3",
    },
    {
      key: "type",
      label: "Type",
      placeHolder: "select",
      type: "text",

      mdSize: "3",
    },
    {
      key: "strength",
      label: "Strength",
      placeHolder: "select",
      type: "text",

      mdSize: "3",
    },
    {
      key: "med_quantity",
      label: "Quantity",
      placeHolder: "Enter Quantity",
      type: "text",
      mdSize: "3",
    },
    {
      key: "route",
      label: "Route",
      placeHolder: "select",
      type: "text",

      mdSize: "3",
    },
    {
      key: "med_frequency",
      label: "Frequency",
      placeHolder: "select",
      type: "select",
      options: [],
      mdSize: "3",
    },
    {
      key: "supply",
      label: "Supply",
      placeHolder: "Enter Supply",
      type: "text",
      mdSize: "3",
    },
    {
      key: "st_date",
      label: "Start Date",
      placeHolder: "select",
      type: "date",
      mdSize: "3",
    },
    {
      key: "end_date",
      label: "End Date",
      placeHolder: "select",
      type: "date",
      mdSize: "3",
    },
    {
      key: "refills_allowed",
      label: "Refills Allowed",
      placeHolder: "type here",
      type: "select",
      optionsNY: [
        { name: "Yes", value: "Y" },
        { name: "No", value: "N" },
      ],
      mdSize: "3",
    },
    {
      key: "staff_id",
      label: "Prescribed By",
      placeHolder: "Enter prescribed by",
      type: "select",
      options: [],
      mdSize: "6",
    },
    {
      key: "is_med_brand",
      label: "Brand Medically Necessary",
      type: "checkbox",
      mdSize: "4",
    },
    {
      key: "is_outside_rx",
      label: "Outside Rx",
      type: "checkbox",
      mdSize: "4",
    },
    {
      key: "med_instruction",
      label: "Instructions",
      placeHolder: "type here",
      type: "textarea",
      mdSize: "6",
    },
    {
      key: "med_pharmacy_notes",
      label: "Note to Pharmacy",
      placeHolder: "type here",
      type: "textarea",
      mdSize: "6",
    },
  ];
  console.log("props-24", props);
  const { selectclient, frequency, patientId ,setDataUpdated} = props;
  console.log("selectclient", selectclient);

   var classes = useStyles();


  const [maxWidth, setMaxWidth] = React.useState("md");

  const [searchModelShow, setSearchModalShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();

  };

 
  const [options, setOptions] = useState([]);
  const [optionsLocal, setOptionsLocal] = useState([]);
  const [medicineData, setMedicineData] = useState([]);
  const [medicineLocalList, setMedicineLocalList] = useState([]);
  const [allStaffList, setAllStaffList] = useState([]);


  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const userData = loadState().user;
 
  useEffect(() => {
    getStaffAvailability().then((response) => {
      console.log("response", response);
      setAllStaffList(response?.data);
    });
  }, []);
  const getData = (searchTerm) => {
    // if (previousController.current) {
    //   previousController.current.abort();
    // }
    const controller = new AbortController();
    const signal = controller.signal;
    // previousController.current = controller;
    fetch(
      `https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search?ef=DISPLAY_NAME,STRENGTHS_AND_FORMS,RXCUIS,SXDG_RXCUI&authenticity_token=&terms=${searchTerm}`,
      {
        signal,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(function (response) {
        console.log("response", response);
        return response.json();
      })
      .then(function (myJson) {
        console.log("search term: " + searchTerm + ", results: ", myJson);
        if (myJson) {
          setMedicineData(myJson);
          setOptions(myJson[1]);
        }
      });
  };

  useEffect(() => {
    console.log("medicineData", medicineData);
    console.log("medicineLocalList", medicineLocalList);
  }, [medicineData, medicineLocalList]);


  const [medicineDetail, setMedicineDtail] = useState({
    pat_med_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    med_id: 0,
    medication: "",
    supply: "",
    med_quantity:'',
    st_date: currentDate,
    end_date: currentDate,
    refills_allowed: "",
    staff_id: "",
    is_med_brand: "N",
    is_outside_rx: "N",
    pharmecy_id: "",
    med_instruction: "",
    med_pharmacy_notes: "",
    updated_by: currentUser(),
    access_location: "",
    opt_type: "ADDMED",
  });

  useEffect(() => {
    if (selectclient) {
      setMedicineDtail({
        pat_med_id: selectclient?.pat_med_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        med_id: selectclient?.patient_id ? selectclient?.med_id :selectclient?.medication_id,
        medication: selectclient?.med_sort_name,
        type: selectclient?.med_type,
        strength: selectclient?.med_strength,
        med_quantity:selectclient?.med_quantity,
        route: selectclient?.med_route,
        med_frequency: selectclient?.med_frequency,
        supply: selectclient?.supply,
        st_date: moment(selectclient?.st_date).format('YYYY-MM-DD'),
        end_date: moment(selectclient?.end_date).format('YYYY-MM-DD'),
        refills_allowed: selectclient?.refills_allowed,
        staff_id: selectclient?.staff_id,
        is_med_brand: selectclient?.is_med_brand,
        is_outside_rx: selectclient?.is_outside_rx,
        pharmecy_id: selectclient?.pharmecy_id,
        med_instruction: selectclient?.med_instruction,
        med_pharmacy_notes: selectclient?.med_pharmacy_notes,
        updated_by: currentUser(),
        access_location: "",
        opt_type: selectclient?.patient_id ? 'UPDMED' :"ADDMED",
      });
    }
  }, [selectclient]);

 
  const [isActionCalled, setIsActionCalled] = useState(false);

  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };

  useEffect(() => {
    if (isActionCalled) {
      postMedicineData(medicineDetail).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success(`Medicine ${selectclient?.patient_id ? 'Update' : 'Add'} successfully.`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          // clearForm();
          props.onHide();
          setDataUpdated(true);
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }

    setIsActionCalled(false);
  }, [isActionCalled]);
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        // fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleSubmit}
            >
            {selectclient?.patient_id ? 'Update' : 'Add'}
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={2}
              className={classes.formBox}
              style={{ marginBottom: "4px" }}
            >
              {formElements.map((element, i) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={classes.textGroup}
                    >
                      <Typography
                        style={{ fontSize: "13px" }}
                        className={classes.formHeading}
                      >
                        {element.label}{" "}
                        {element.required === "true" ? (
                          <span className={classes.required}>*</span>
                        ) : null}
                      </Typography>

                      {element.type === "select" &&
                      element.key !== "medication" ? (
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            name={element.key}
                            className={classes.selectDropdown}
                            placeholder={element.placeHolder}
                            value={
                              medicineDetail && medicineDetail[element.key]
                                ? medicineDetail && medicineDetail[element.key]
                                : ""
                            }
                            onChange={(e) => {
                              console.log("selected", e.target.value);

                              setMedicineDtail({
                                ...medicineDetail,
                                [element.key]: e.target.value,
                              });

                              // resetValidator(e.target.name);
                            }}
                          >
                            <MenuItem disabled value="">
                              Select
                            </MenuItem>
                            {element.key === "med_frequency" && frequency
                              ? frequency.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item?.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "staff_id" && allStaffList
                              ? allStaffList.map((item) => (
                                  <MenuItem value={item.staff_id}>
                                    {item.staff_name}
                                  </MenuItem>
                                ))
                              : element.key === "refills_allowed" &&
                                element?.optionsNY
                              ? element.optionsNY.map((item) => (
                                  <MenuItem value={item?.value}>
                                    {item?.name}
                                  </MenuItem>
                                ))
                              : element.options.map((item) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      ) : element.type === "select" &&
                        element.key === "medication" ? (
                        <Autocomplete
                          id="combo-box-demo"
                          options={optionsLocal}
                          // onInputChange={onInputLocalChange}
                          getOptionLabel={(option) =>
                            option.med_sort_name ? option.med_sort_name : ""
                          }
                          // filterOptions={(options) => options}
                          style={{ width: 300, height: 36, marginBottom: 10 }}
                          value={selectedMedicine ? selectedMedicine : ""}
                          onChange={(event, newValue) => {
                            setSelectedMedicine(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              style={{ height: 36 }}
                              {...params}
                              label=""
                              variant="outlined"
                            />
                          )}
                        />
                      ) : element.type === "text" ? (
                        <TextField
                        disabled={element.key==='medication' || element.key==='type' ||element.key==='strength'|| element.key==='route' }
                          required
                          name={element.key}
                          placeholder={element.placeHolder}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                          }}
                          value={
                            medicineDetail && medicineDetail[element.key]
                              ? medicineDetail && medicineDetail[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected", e.target.value);

                            setMedicineDtail({
                              ...medicineDetail,
                              [element.key]: e.target.value,
                            });

                            // resetValidator(e.target.name);
                          }}
                        />
                      ) : element.type === "date" ||
                        element.type === "datetime-local" ? (
                        <TextField
                          id={element.key}
                          name={element.key}
                          placeholder={element.placeHolder}
                          type={element.type}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            min: `${currentDateTime}`,
                            max: `${currentDate}`,
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onKeyDown={(e) => {
                            e.preventDefault(); // Prevent manual typing
                          }}
                          value={
                            medicineDetail && medicineDetail[element.key]
                              ? medicineDetail && medicineDetail[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected", e.target.value);

                            setMedicineDtail({
                              ...medicineDetail,
                              [element.key]: e.target.value,
                            });

                            // resetValidator(e.target.name);
                          }}
                        />
                      ) : element.type === "textarea" ? (
                        <TextField
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          placeholder="Type here..."
                          variant="outlined"
                          multiline
                          rows={3}
                          inputProps={{
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          value={
                            medicineDetail && medicineDetail[element.key]
                              ? medicineDetail && medicineDetail[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected", e.target.value);

                            setMedicineDtail({
                              ...medicineDetail,
                              [element.key]: e.target.value,
                            });

                            // resetValidator(e.target.name);
                          }}
                        />
                      ):element.type === "checkbox" ? (
                        <>
                          <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                              <FormControlLabel
                                // value={ocularDetails[element.key] && ocularDetails[element.key] ==="Y" ? "Yes":ocularDetails[element.key] ==="N" ?"No" :""}
                                control={
                                  <Checkbox
                                    checked={
                                      medicineDetail[element.key] &&
                                      medicineDetail[element.key] === "Y"
                                    }
                                    onChange={(e) => {
                                      const selectedValue = e.target.checked
                                        ? "Y"
                                        : "N";
                                      setMedicineDtail({
                                        ...medicineDetail,
                                        [element.key]: selectedValue,
                                      });
                                    }}
                                  />
                                }
                                label={element.lbltitle}
                                labelPlacement="start"
                              />
                            </FormGroup>
                          </FormControl>
                        </>
                      ) : element.type === "button" ? (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                          style={{
                            width: "100%",
                            fontSize: "10px",
                            marginTop: "26px",
                          }}
                        >
                          {element.btnName}
                        </Button>
                      ) : (
                        <Switch />
                      )}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
