import {
  handleKeyPress,
  handleKeyPressPaste,
  handleAlphNumKeyPress,
  handleAlphNumKeyPressPaste,
  handleAlphNumberKeyPress,
  handleAlphNumberKeyPressPaste,
  handleAddressKeyPress,
  handleAddressKeyPressPaste,
  handleAlphNumSpaceKeyPress
} from "../../../../../hooks/helper";

export const formPrimaryElements = [
  {
    key: "mi_insurance_provider",
    label: "Insurance Provider",
    placeHolder: "",
    type: "select",
    options: ["Anthem", "UnitedHealthcare", "EyeMed", "VSP", "Humana"],
    mdSize: "4",
  },

  {
    key: "mi_insurance_plan",
    label: "Plan Name",
    placeHolder: "Enter Plan Name",
    type: "text",
    mdSize: "4",
    maxLength: "30",  
    onKeyPress: handleAlphNumSpaceKeyPress,
    onPaste:handleAlphNumberKeyPressPaste,
  },
  {
    key: "mi_insurance_policynumber",
    label: "Policy Number",
    placeHolder: "Enter Policy Number",
    type: "text",
    mdSize: "4",
    maxLength: "30",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "mi_insurance_groupnumber",
    label: "Group Number",
    placeHolder: "Enter Group Number",
    type: "text",
    mdSize: "4",
    maxLength: "30",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "mi_effective_date",
    label: "Effective Date",
    placeHolder: "",
    type: "date",
    mdSize: "4",
  },
 

  {
    key: "mi_co_pay",
    label: "Co-pay",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "5",
    onKeyPress: handleKeyPress,
  },
  {
    key: "mi_is_active",
    label: "Active",
    type: "checkbox",
    mdSize: "2",
  },
  {
    key: "mi_accept_assignment",
    label: "Accept Assignment",
    type: "checkbox",
    mdSize: "2",
  },
];
export const formSubscribePrimaryElements = [
  {
    key: "pr_subscriber_name",
    label: "Subscriber First Name",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "30",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_middle_name",
    label: "Middle Name",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "30",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_last_name",
    label: "Last Name",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "30",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_dateofbirth",
    label: "Date of Birth",
    placeHolder: "",
    type: "date",
    mdSize: "4",
  },
  {
    key: "pr_ssn",
    label: "SSN",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "9",
    onKeyPress: handleKeyPress,
  },
  // {
  //   key: "button",
  //   label: "",
  //   type: "button",
  //   btnName: "search/add",
  //   mdSize: "4",
  // },
  {
    key: "pr_sex",
    label: "Gender",
    placeHolder: "",
    type: "select",
    options: ["Male", "Female", "Unknown"],
    mdSize: "4",
  },
  {
    key: "pr_subscribe_phone",
    label: "Subscribe Phone",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "10",
    onKeyPress: handleKeyPress,
  },
  {
    key: "pr_office_phone",
    label: "Office Phone",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "10",
    onKeyPress: handleKeyPress,
  },
  {
    key: "pr_subscribe_employeer",
    label: "Subscribe Employer",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "30",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_address1",
    label: "Subscribe Address Line 1",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_address2",
    label: "Address Line 2",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_city",
    label: "City",
    placeHolder: "Enter City",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_state",
    label: "State",
    placeHolder: "Enter Last Name",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "4",
  },
  {
    key: "pr_zip",
    label: "Zip",
    placeHolder: "Enter Zip",
    type: "text",
    mdSize: "4",
    maxLength: "5",
    onKeyPress: handleKeyPress,
    onPaste: handleKeyPressPaste,
  },
  {
    key: "pr_emp_address1",
    label: "Employe Address Line 1",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAlphNumKeyPressPaste,
  },
  {
    key: "pr_emp_address2",
    label: "Address Line 2",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAlphNumKeyPressPaste,
  },
  {
    key: "pr_emp_city",
    label: "City",
    placeHolder: "Enter City",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAlphNumKeyPressPaste,
  },
  {
    key: "pr_emp_state",
    label: "State",
    placeHolder: "Enter Last Name",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "4",
  },
  {
    key: "pr_emp_zip",
    label: "Zip",
    placeHolder: "Enter Zip",
    type: "text",
    mdSize: "4",
    maxLength: "5",
    onKeyPress: handleKeyPress,
    onPaste: handleKeyPressPaste,
  },
];
export const formSecondaryElements = [
  {
    key: "vi_insurance_provider",
    label: "Insurance Provider",
    placeHolder: "",
    type: "select",
    options: ["Anthem", "UnitedHealthcare", "EyeMed", "VSP", "Humana"],
    mdSize: "4",
  },

  {
    key: "vi_insurance_plan",
    label: "Plan Name",
    placeHolder: "Enter Plan Name",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "vi_insurance_policynumber",
    label: "Policy Number",
    placeHolder: "Enter Policy Number",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "vi_insurance_groupnumber",
    label: "Group Number",
    placeHolder: "Enter Group Number",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "vi_effective_date",
    label: "Effective Date",
    placeHolder: "",
    type: "date",
    mdSize: "4",
  },
  

  {
    key: "vi_co_pay",
    label: "Co-pay",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    onKeyPress: handleKeyPress,
    onPaste: handleKeyPressPaste,
    maxLength: "5",
  },
  {
    key: "vi_is_active",
    label: "Active",
    type: "checkbox",
    mdSize: "2",
  },
  {
    key: "vi_accept_assignment",
    label: "Accept Assignment",
    type: "checkbox",
    mdSize: "2",
  },
];
export const formSubscribeSecondaryElements = [
  {
    key: "pr_subscriber_name",
    label: "Subscriber First Name",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "15",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_middle_name",
    label: "Middle Name",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "15",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_last_name",
    label: "Last Name",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "15",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_dateofbirth",
    label: "Date of Birth",
    placeHolder: "",
    type: "date",
    mdSize: "4",
  },
  {
    key: "pr_ssn",
    label: "SSN",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "9",
    onKeyPress: handleKeyPress,
  },
  // {
  //   key: "button",
  //   label: "",
  //   type: "button",
  //   btnName: "search/add",
  //   mdSize: "4",
  // },
  {
    key: "pr_sex",
    label: "Gender",
    placeHolder: "",
    type: "select",
    options: ["Male", "Female", "Unknown"],
    mdSize: "4",
  },
  {
    key: "pr_subscribe_phone",
    label: "Subscribe Phone",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "10",
    onKeyPress: handleKeyPress,
    onPaste: handleKeyPressPaste,
  },
  {
    key: "pr_office_phone",
    label: "Office Phone",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "10",
    onKeyPress: handleKeyPress,
    onPaste: handleKeyPressPaste,
  },
  {
    key: "pr_subscribe_employeer",
    label: "Subscribe Employer",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,

  },
  {
    key: "pr_address1",
    label: "Subscribe Address Line 1",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_address2",
    label: "Address Line 2",
    placeHolder: "",
    type: "text",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
    mdSize: "4",
  },
  {
    key: "pr_city",
    label: "City",
    placeHolder: "Enter City",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_state",
    label: "State",
    placeHolder: "Enter Last Name",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "4",
  },
  {
    key: "pr_zip",
    label: "Zip",
    placeHolder: "Enter Zip",
    type: "text",
    mdSize: "4",
    maxLength: "5",
    onKeyPress: handleKeyPress,
    onPaste: handleKeyPressPaste,
  },
  {
    key: "pr_emp_address1",
    label: "Employe Address Line 1",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_emp_address2",
    label: "Address Line 2",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_emp_city",
    label: "City",
    placeHolder: "Enter City",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_emp_state",
    label: "State",
    placeHolder: "Enter Last Name",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "4",
  },
  {
    key: "pr_emp_zip",
    label: "Zip",
    placeHolder: "Enter Zip",
    type: "text",
    mdSize: "4",
    maxLength: "5",
    onKeyPress: handleKeyPress,
    onPaste: handleKeyPressPaste,
  },
];
export const formTertiaryElements = [
  {
    key: "adi_insurance_provider",
    label: "Insurance Provider",
    placeHolder: "",
    type: "select",
    options: ["Anthem", "UnitedHealthcare", "EyeMed", "VSP", "Humana"],
    mdSize: "4",
  },

  {
    key: "adi_insurance_plan",
    label: "Plan Name",
    placeHolder: "Enter Plan Name",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "adi_insurance_policynumber",
    label: "Policy Number",
    placeHolder: "Enter Policy Number",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "adi_insurance_groupnumber",
    label: "Group Number",
    placeHolder: "Enter Group Number",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "adi_effective_date",
    label: "Effective Date",
    placeHolder: "",
    type: "date",
    mdSize: "4",
  },
  

  {
    key: "adi_co_pay",
    label: "Co-pay",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "5",
    onKeyPress: handleKeyPress,
  },
  {
    key: "adi_is_active",
    label: "Active",
    type: "checkbox",
    mdSize: "2",
  },
  {
    key: "adi_accept_assignment",
    label: "Accept Assignment",
    type: "checkbox",
    mdSize: "2",
  },
];
export const formSubscribeTertiaryElements = [
  {
    key: "pr_subscriber_name",
    label: "Subscriber First Name",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "20",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_middle_name",
    label: "Middle Name",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "20",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_last_name",
    label: "Last Name",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "20",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,

  },
  {
    key: "pr_dateofbirth",
    label: "Date of Birth",
    placeHolder: "",
    type: "date",
    mdSize: "4",
  },
  {
    key: "pr_ssn",
    label: "SSN",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "9",
    onKeyPress: handleKeyPress,
  },
  // {
  //   key: "button",
  //   label: "",
  //   type: "button",
  //   btnName: "search/add",
  //   mdSize: "4",
  // },
  {
    key: "pr_sex",
    label: "Gender",
    placeHolder: "",
    type: "select",
    options: ["Male", "Female", "Unknown"],
    mdSize: "4",
  },
  {
    key: "pr_subscribe_phone",
    label: "Subscribe Phone",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "10",
    onKeyPress: handleKeyPress,
  },
  {
    key: "pr_office_phone",
    label: "Office Phone",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "10",
    onKeyPress: handleKeyPress,
  },
  {
    key: "pr_subscribe_employeer",
    label: "Subscribe Employer",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_address1",
    label: "Subscribe Address Line 1",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_address2",
    label: "Address Line 2",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_city",
    label: "City",
    placeHolder: "Enter City",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_state",
    label: "State",
    placeHolder: "Enter Last Name",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "4",
  },
  {
    key: "pr_zip",
    label: "Zip",
    placeHolder: "Enter Zip",
    type: "text",
    mdSize: "4",
    maxLength: "5",
    onKeyPress: handleKeyPress,
    onPaste: handleKeyPressPaste,
  },
  {
    key: "pr_emp_address1",
    label: "Employe Address Line 1",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_emp_address2",
    label: "Address Line 2",
    placeHolder: "",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_emp_city",
    label: "City",
    placeHolder: "Enter City",
    type: "text",
    mdSize: "4",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "pr_emp_state",
    label: "State",
    placeHolder: "Enter Last Name",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "4",
  },
  {
    key: "pr_emp_zip",
    label: "Zip",
    placeHolder: "Enter Zip",
    type: "text",
    mdSize: "4",
    maxLength: "5",
    onKeyPress: handleKeyPress,
    onPaste: handleKeyPressPaste,
  },
];
