import React from 'react'
import ProfileDetails from '../../components/Profile/ProfileDetails'

export default function Profile() {
  return (
    <div>
        <ProfileDetails/>
    </div>
  )
}
