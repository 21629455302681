import React, { useEffect, useState } from "react";
import { Grid, Button, TextField, Fade } from "@material-ui/core";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router-dom";

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import { Typography } from "../../components/Wrappers";
import * as Icons from "@material-ui/icons";
import { loadState, validateChangePassportScreen } from '../../hooks/helper';
import { changePassword } from "../../services/ServiceLayer";
import Validation from "../../hooks/AdminValidation";

const formElements = [
  {
    key: "currentPassword",
    label: "Current Password",
    placeHolder: "Enter Current password",
    type: "password",
  },
  {
    key: "newPassword",
    label: "New Password",
    placeHolder: "Enter new password",
    type: "password",
  },
  {
    key: "confirmPassword",
    label: "Confirm Password",
    placeHolder: "Confirm new password",
    type: "password",
  },
];

export default function ChangePassword() {
  const classes = useStyles();
  toast.configure();
  const profileData = loadState().user;
  const storedObject = sessionStorage.getItem('AuthData');
const user = JSON.parse(storedObject);
const userDetails = user.user;
console.log(userDetails,'useeeeeeeeeeeee');
  const { user_id, userName } = useParams();

  const [passwordDetail, setPasswordDetail] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [eyes, setEyes] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [error, checkValidator, resetValidator, resetAllValidator, isError] = Validation(passwordDetail);

  const cancelAction = () => {
    // Reset password details to initial state
    setPasswordDetail({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  
    // Optional: If you still need to redirect after clearing input
    // window.location = "/app/dashboard";
  };
  

  const submitHandler = (e) => {
    e.preventDefault();
    checkValidator();
  
    if (!isError()) {
      const passwordRequest = {
        current_Password: passwordDetail.currentPassword,
        new_Password: passwordDetail.newPassword,
        user_id: user_id === "1" ? profileData.user_id : user_id,
      };
  
      changePassword(passwordRequest)
        .then((response) => {
          if (response.data && response.data.status && response.data.status.message === "success") {
            toast.success("Password changed successfully!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // Optionally, redirect user or clear form
            setPasswordDetail({ currentPassword: "", newPassword: "", confirmPassword: "" });
            // If using react-router you might navigate to login or other page
            // history.push('/app/login');
          } else {
            throw new Error(response.data.status.description || "Unknown error");
          }
        })
        .catch((error) => {
          toast.error(error.message || "Failed to change password", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };
  
  

  useEffect(() => {
    if (isActionCalled && !isError()) {
      const passwordRequest = {
        current_Password: passwordDetail.currentPassword,
        new_Password: passwordDetail.newPassword,
        user_id: user_id === "1" ? profileData.user_id : user_id
      };

      changePassword(passwordRequest).then((payload) => {
        console.log("payload-", payload);
        if (payload?.status?.description === "") {
          sessionStorage.removeItem("id_token");
          window.location = "/app/login";
        } else {
          alert(payload?.status?.description);
        }
      });
    }
    setIsActionCalled(false);
  }, [isActionCalled, passwordDetail, user_id, profileData.user_id, isError]);

  return (
    <>
      <PageTitle title="Change Password" />
      <Grid container spacing={4}>
        <Grid item xs={12} md={5}>
          <Widget disableWidgetMenu>
            {`Staff Name : ${userDetails.user_name}`}
            {formElements.map((element, i) => (
              <div key={i}>
                <Typography variant="h6" className={classes.text}>
                  {element.label} <span className={classes.required}>*</span>
                </Typography>
                <TextField
                  name={element.key}
                  placeholder={element.placeHolder}
                  InputProps={{
                    classes: {
                      border: classes.textFieldline,
                      input: classes.textField,
                    },
                  }}
                  margin="normal"
                  type={eyes[element.key] ? "text" : element.type}
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={passwordDetail[element.key]}
                  onChange={(e) => {
                    setPasswordDetail({
                      ...passwordDetail,
                      [element.key]: e.target.value,
                    });
                  }}
                  onKeyUp={(e) => {
                    if (
                      element.key === "newPassword" &&
                      passwordDetail?.confirmPassword
                    ) {
                      checkValidator();
                    } else {
                      checkValidator(element.key);
                    }
                  }}
                  isInvalid={
                    error &&
                    error !== null &&
                    typeof error[element.key] !== "object" &&
                    error[element.key]
                  }
                />
                <div
                  className={classes.eyeIcon}
                  onClick={(e) =>
                    setEyes({
                      ...eyes,
                      [element.key]: !eyes[element.key],
                    })
                  }
                >
                  {eyes[element.key] ? <Icons.Visibility /> : <Icons.VisibilityOff />}
                </div>
                <Fade in={!!error}>
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    {error &&
                      typeof error[element.key] !== "object" &&
                      error[element.key]}
                  </Typography>
                </Fade>
                {element.key === "newPassword" && (
                  <Typography
                    className={`passwordRules ${
                      error && passwordDetail[element.key] && "valid"
                    }`}
                  >
                    <h4>Your password must have:</h4>
                    <p
                      style={{ display: "flex" }}
                      className={
                        passwordDetail[element.key]
                          ? error?.newPassword?.lowercase
                            ? "invalid"
                            : "valid"
                          : "invalid"
                      }
                    >
                      {!passwordDetail?.newPassword ? (
                        <Icons.Cancel style={{ color: "red", width: "20px" }} />
                      ) : error?.newPassword?.lowercase ? (
                        <Icons.Cancel style={{ color: "red", width: "20px" }} />
                      ) : (
                        <Icons.CheckCircle style={{ color: "green", width: "20px" }} />
                      )}
                      <span
                        style={{
                          marginTop: "0px",
                          marginLeft: "4px",
                          color: "#999999",
                        }}
                      >
                        At least one lowercase letter
                      </span>
                    </p>
                    <p
                      style={{ display: "flex" }}
                      className={
                        passwordDetail[element.key]
                          ? error?.newPassword?.uppercase
                            ? "invalid"
                            : "valid"
                          : "invalid"
                      }
                    >
                      {!passwordDetail?.newPassword ? (
                        <Icons.Cancel style={{ color: "red", width: "20px" }} />
                      ) : error?.newPassword?.uppercase ? (
                        <Icons.Cancel style={{ color: "red", width: "20px" }} />
                      ) : (
                        <Icons.CheckCircle style={{ color: "green", width: "20px" }} />
                      )}
                      <span
                        style={{
                          marginTop: "0px",
                          marginLeft: "4px",
                          color: "#999999",
                        }}
                      >
                        At least one UPPERCASE letter
                      </span>
                    </p>
                    <p
                      style={{ display: "flex" }}
                      className={
                        passwordDetail[element.key]
                          ? error?.newPassword?.number
                            ? "invalid"
                            : "valid"
                          : "invalid"
                      }
                    >
                      {!passwordDetail?.newPassword ? (
                        <Icons.Cancel style={{ color: "red", width: "20px" }} />
                      ) : error?.newPassword?.number ? (
                        <Icons.Cancel style={{ color: "red", width: "20px" }} />
                      ) : (
                        <Icons.CheckCircle style={{ color: "green", width: "20px" }} />
                      )}
                      <span
                        style={{
                          marginTop: "0px",
                          marginLeft: "4px",
                          color: "#999999",
                        }}
                      >
                        At least one number
                      </span>
                    </p>
                    <p
                      style={{ display: "flex" }}
                      className={
                        passwordDetail[element.key]
                          ? error?.newPassword?.special
                            ? "invalid"
                            : "valid"
                          : "invalid"
                      }
                    >
                      {!passwordDetail?.newPassword ? (
                        <Icons.Cancel style={{ color: "red", width: "20px" }} />
                      ) : error?.newPassword?.special ? (
                        <Icons.Cancel style={{ color: "red", width: "20px" }} />
                      ) : (
                        <Icons.CheckCircle style={{ color: "green", width: "20px" }} />
                      )}
                      <span
                        style={{
                          marginTop: "0px",
                          marginLeft: "4px",
                          color: "#999999",
                        }}
                      >
                        At least one special character
                      </span>
                    </p>
                    <p
                      style={{ display: "flex" }}
                      className={
                        passwordDetail[element.key]
                          ? error?.newPassword?.length
                            ? "invalid"
                            : "valid"
                          : "invalid"
                      }
                    >
                      {!passwordDetail?.newPassword ? (
                        <Icons.Cancel style={{ color: "red", width: "20px" }} />
                      ) : error?.newPassword?.length ? (
                        <Icons.Cancel style={{ color: "red", width: "20px" }} />
                      ) : (
                        <Icons.CheckCircle style={{ color: "green", width: "20px" }} />
                      )}
                      <span
                        style={{
                          marginTop: "0px",
                          marginLeft: "4px",
                          color: "#999999",
                        }}
                      >
                        At least 8 characters
                      </span>
                    </p>
                  </Typography>
                )}
              </div>
            ))}
            <div className={classes.btnGroup}>
              <Button
                className={classes.leftButton}
                variant="outlined"
                size="large"
                onClick={cancelAction}
              >
                Cancel
              </Button>
              <Button
                className={classes.rightButton}
                variant="contained"
                size="large"
                color="secondary"
                onClick={submitHandler}
              >
                Update
              </Button>
            </div>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
