import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import { toast } from "react-toastify";
import Popup from "../../common/modal/Popup";
// styles
import useStyles from "./styles";
import {
  postCompanyTax,
  getCompanyList,
  getCompanyStoreData,
  getMetaData,
} from "../../../services/ServiceLayer";
import moment from "moment";
import {
  handleKeyPress,
  currentUser,
  validateInputAmount,
  loadState,
  validateTaxInputAmount,
  validateTaxInputAmountPaste,
} from "../../../hooks/helper";
export default function ManageTaxRate(props) {
  toast.configure();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const { selectclient } = props;
  console.log("selectclient", selectclient);
  const [compId, setCompId] = useState(0);
  const [storeId, setStoreId] = useState(0);

  //
  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [open, setOpen] = React.useState(false);
  const [storeList, setStoreList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  // list meta data
  const [listData, setListData] = useState([]);
  const [taxFormValues, setTaxFormValues] = useState([]);
  // call meta data
  const metaData = loadState().metaData;
  useEffect(() => {
    setListData(metaData);
    setCompId(selectclient === null ? 0 : selectclient[0]);
    setStoreId(selectclient === null ? 0 : selectclient[1]);
  }, []);

  useEffect(() => {
    console.log("listData", listData);
    if (listData.length > 0) {
      const taxFormValues = listData[0].companyTax;

      setTaxFormValues(taxFormValues);
    }
  }, [listData]);
  const hideHandler = () => {
    console.log("hide handler called");
    clearForm();
    props.onHide();
  };

  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedStore, setSelectedStore] = useState("");
  useEffect(() => {
    getCompanyStoreData(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELLOC`
    ).then((response) => {
      console.log(response);
      setCompanyList(response.data);
    });
  }, []);
  const handleCompanySelect = (event) => {
    const selectedCompanyName = event.target.value;
    setSelectedCompany(selectedCompanyName);
    const selectedCompanyData = companyList.find(
      (company) => company.comp_id === selectedCompanyName
    );
    if (selectedCompanyData) {
      console.log("selectedCompanyData", selectedCompanyData);
      console.log("selectedCompanyData", selectedCompanyData?.store_info);
      setStoreList(selectedCompanyData?.store_info);
    } else {
      setStoreList([]);
    }
  };

  const [companyTaxDetail, setCompanyTaxDetail] = useState({
    comp_id: compId,
    store_id: storeId,
    tax_type_id: 2,
    tax_1_1: "7.0",
    tax_2_1: "0.0",
    tax_1_2: "7.0",
    tax_2_2: "0.0",
    tax_1_3: "7.0",
    tax_2_3: "0.0",
    tax_1_4: "7.0",
    tax_2_4: "0.0",
    tax_1_5: "7.0",
    tax_2_5: "0.0",
    tax_1_6: "7.0",
    tax_2_6: "0.0",
    tax_1_7: "7.0",
    tax_2_7: "0.0",
    is_active: "",
    updated_by: currentUser(),
    access_location: "",
    opt_type: "ADDTAX",
  });
  useEffect(() => {
    if (selectclient) {
      setCompanyTaxDetail({
        comp_id: selectclient?.comp_id,
        store_id: selectclient?.store_id,
        tax_type_id: selectclient?.tax_type_id,
        tax_1: "7.0",
        tax_2: "0.0",
        is_active: "",
    updated_by: currentUser(),
    access_location: "",
    opt_type: "UPDTAX",
      });
    }
  }, [selectclient]);
  const clearForm = () => {
    // Define a function to clear the form fields
    setCompanyTaxDetail({
      comp_id: compId,
      store_id: storeId,
      tax_type_id: 2,
      tax_type: "",
      tax_1_1: "7.0",
      tax_2_1: "0.0",
      tax_1_2: "7.0",
      tax_2_2: "0.0",
      tax_1_3: "7.0",
      tax_2_3: "0.0",
      tax_1_4: "7.0",
      tax_2_4: "0.0",
      tax_1_5: "7.0",
      tax_2_5: "0.0",
      tax_1_6: "7.0",
      tax_2_6: "0.0",
      tax_1_7: "7.0",
      tax_2_7: "0.0",
      is_active: "",
      updated_by: currentUser(),
      access_location: "",
      opt_type: "ADDTAX",
    });
  };
  const submitFormData = () => {
    const taxTypeIds = [];
    const taxTypes = [];
    const tax1Values = [];
    const tax2Values = [];
    const compIdVal = selectclient ? selectclient[0] : 0;
    const storeIdVal = selectclient ? selectclient[1] : 0;

    taxFormValues.forEach((item) => {
      const taxType = item.attribute_val;
      const tax1 = companyTaxDetail[taxType + "_tax_1"];
      const tax2 = companyTaxDetail[taxType + "_tax_2"];

      taxTypeIds.push(item.attribute_id);
      taxTypes.push(taxType);
      tax1Values.push(tax1);
      tax2Values.push(tax2);
    });

    const companyTaxParams = {
      comp_id: compIdVal,
      store_id: storeIdVal,
      tax_type_id: taxTypeIds.join(","),
      tax_type: taxTypes.join(","),
      tax_1: tax1Values.join(","),
      tax_2: tax2Values.join(","),
      is_active: "Y",
      updated_by: currentUser(),
      access_location: "PB",
      opt_type: "ADDTAX",
    };

    postCompanyTax(companyTaxParams).then((response) => {
      console.log(response);
      if (response?.status?.status) {
        toast.success("Company tax added successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });

        props.onHide();
      } else {
        console.log("api failed");
        toast.error("Something went wrong. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        props.onHide();
      }
    });
  };

  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={submitFormData}
            >
              Add
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={1}
              className={classes.formBox}
              style={{ marginBottom: "4px" }}
            >
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  Tax Types
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <Typography className={classes.formHeading}>Tax1</Typography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <Typography className={classes.formHeading}>Tax2</Typography>
              </Grid>
            </Grid>
            {taxFormValues.map((item) => (
              <Grid container spacing={1} className={classes.formBox}>
                <Grid item xs={12} md={4} className={classes.textGroup}>
                  <Typography className={classes.formHeading}>
                    {item.attribute_val}
                  </Typography>
                  <Typography className={classes.formHeading}></Typography>
                </Grid>
                <Grid item xs={12} md={4} className={classes.textGroup}>
                  <TextField
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    placeholder=""
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "12px",
                        fontSize: "12px",
                      },
                      maxLength: 5,

                      onKeyPress: validateTaxInputAmount,
                      onPaste: validateTaxInputAmountPaste,
                    }}
                    name={item.attribute_val + "_tax_1"}
                    value={companyTaxDetail[item.attribute_val + "_tax_1"]}
                    // value={companyTaxDetail[item.attribute_val + "_tax_1"] ? companyTaxDetail[item.attribute_val + "_tax_1"] : '7.0'}
                    onChange={(e) => {
                      setCompanyTaxDetail({
                        ...companyTaxDetail,
                        [item.attribute_val + "_tax_1"]: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4} className={classes.textGroup}>
                  <TextField
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    placeholder=""
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "12px",
                        fontSize: "12px",
                      },
                      maxLength: 5,

                      onKeyPress: validateTaxInputAmount,
                      onPaste: validateTaxInputAmountPaste,
                    }}
                    name={item.attribute_val + "_tax_2"}
                    value={companyTaxDetail[item.attribute_val + "_tax_2"]}
                    // value={companyTaxDetail[item.attribute_val + "_tax_2"] ? companyTaxDetail[item.attribute_val + "_tax_2"] : '0.0'}

                    onChange={(e) => {
                      setCompanyTaxDetail({
                        ...companyTaxDetail,
                        [item.attribute_val + "_tax_2"]: e.target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
