import React, { useState, useEffect } from "react";
// Model
import {
  Typography,
  Button,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Popup from "../common/modal/Popup";
import alertIcon from "../Settings/Company/alertIcon.svg";
// styles
import useStyles from "./styles";
import { toast } from "react-toastify";
import { postScheduleData } from "../../services/ServiceLayer";
import moment from "moment";
export default function DeletePopup({
  onHide,
  deleteModalShow,
  setDeleteModalShow,
  selectclient,
}) {
  console.log("selectclient", selectclient);
  console.log("deleteModalShow", deleteModalShow);


  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const [actionType, setActionType] = useState("DELSCH");

  const [isActionCalled, setIsActionCalled] = useState(false);

  toast.configure();
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setDeleteModalShow(false);
    setOpen(false);
  };
  const [scheduleDetail, setScheduleDetail] = useState({
    patient_id: "",
    first_name: "",
    last_name: "",
    dateofbirth: "",
    race: "",
    reasonOfVisit: "",
    contact_cell: "",
    email: "",
    billing_address_line1: "",
    billing_city: "",
    billing_state: "",
    billing_zip: "",
    allergies: "",
    insurance: "",
    additionalInsurance: "",
    start: "",
    end: "",
    notes: "",
  });
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient?.selectedPatientData === "") {
      setScheduleDetail({
        first_name: "",
        last_name: "",
        dateofbirth: "",
        race: "",
        contact_cell: "",
        email: "",
        billing_address_line1: "",
        billing_city: "",
        billing_state: "",
        billing_zip: "",
        allergies: "",
        title: "",
        insurance_id: "",
        additionalInsurance: "",
        start: "",
        end: "",
        reasonOfVisit: "",
        notes: "",
      });
    } else {
      setScheduleDetail({
        schedule_id: selectclient?.id,
        patient_id: selectclient?.selectedPatientData?.patient_id,
        physician_id: selectclient?.selectedPatientData?.resourceid,
        first_name: selectclient?.selectedPatientData?.first_name,
        last_name: selectclient?.selectedPatientData?.last_name,
        dob: moment(selectclient?.selectedPatientData?.dateofbirth).format(
          "YYYY-MM-DD"
        ),

        race: selectclient?.selectedPatientData?.race,
        title: selectclient?.selectedPatientData?.title,
        con_cell: selectclient?.selectedPatientData?.contact_cell,
        email_id: selectclient?.selectedPatientData?.email,
        address1: selectclient?.selectedPatientData?.billing_address_line1,
        address2: selectclient?.selectedPatientData?.billing_address_line2,
        city: selectclient?.selectedPatientData?.billing_city,
        state: selectclient?.selectedPatientData?.billing_state,
        zip: selectclient?.selectedPatientData?.billing_zip,
        // reasonOfVisit: selectSchedule?.selectedPatientData?.title,
        schedule_notes: selectclient?.selectedPatientData?.schedule_notes,
        allergies: selectclient?.selectedPatientData?.allergies,
        start_time: moment(selectclient?.start).format("YYYY-MM-DDTHH:mm:ss"),
        end_time: moment(selectclient?.end).format("YYYY-MM-DDTHH:mm:ss"),
        insurance_id: selectclient?.selectedPatientData?.insurance_id,
        additional_insurance:
          selectclient?.selectedPatientData?.add_insurance_id,
        schedule_type_id: selectclient?.selectedPatientData?.schedule_type_id,
        is_confirmed: "",
        location_id: selectclient?.location_id,
        is_rescheduled: "",
        is_canceled: "",
        is_waitlist: "",
        access_location: "1",
      });
    }
  }, [selectclient]);

  const deleteFormData = () => {
    setIsActionCalled(true);
    setScheduleDetail({
      ...scheduleDetail,
      opt_type: "DELSCH",
    });
  };

  useEffect(() => {
    console.log("isActionCalled", isActionCalled);

    if (isActionCalled) {
      postScheduleData(scheduleDetail).then((response) => {
        console.log("actionType", actionType);

        console.log(response);
        if (response.status.status) {
          toast.success("Schedule cancel successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });

          onHide();
          setDeleteModalShow(false);
          setOpen(false);
          setIsActionCalled(false);
        } else {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActionCalled(false);
        }
      });
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={deleteFormData}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <img src={alertIcon} alt="img"/>
            <Typography className={classes.formActiveHeading}>
              Are you sure! You want to Cancel this schedule?
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
