import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  card: {
   
   
    fontSize:'26px !important',
    fontWeight:500,
    fontFamily:'Poppins',
    color:'#2A2338',
    height:'120px',

   
    
    
  },
  activeCard:{
    border:'1px solid#4E97FD !important', 
    color:'#000 !important',
    backgroundColor:'#4E97FD!important'
  },
  highlightedGridItem:{
    borderColor:''
  },
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },
  progressTitle: {
    marginBottom: theme.spacing(2),
  },
  logoImg:{
    height:'64px',
    width:'64px'
  },
  pageTitleContainer:{
    display: "flex",
    justifyContent: "space-between",
    alignItems:"center",
    
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  pieChartLegendWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginRight: theme.spacing(1),
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    position:'relative',
   
    

  },
  tableWidget: {
    overflowX: "auto",
  },
  progressBar: {
    backgroundColor: theme.palette.warning.main,
  },
  performanceLegendWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  serverOverviewElement: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
  },
  serverOverviewElementText: {
    minWidth: 145,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: "100%",
  },
  mainChartBody: {
    overflowX: "auto",
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap",
    },
  },
  mainChartHeaderLabels: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      order: 3,
      width: "100%",
      justifyContent: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartHeaderLabel: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + "80 !important",
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  mainChartLegentElement: {
    fontSize: "18px !important",
    marginLeft: theme.spacing(1),
  },
  icons:{
    right: "5px",
    bottom:"40px",
   
   
    position: "absolute",
  },
  progressSectionTitle:{
    color:'#2A2338 !important',
    fontFamily:'Poppins !important',
    fontWeight:'500 !important',
    fontSize:'14px !important',
    // paddingTop: "100px",
    
  },
  // iconsBar:{
  //   backgroundColor:'#ffffff',
  //   marginBottom:'10px'
  // }
  iconsBar: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: "#fff",
    borderBottomColor:'2px solid#4E97FD'
   
  },
  tab: {
    color: '#201F1E',
    fontFamily:'Poppins',
    fontSize:'14px',
    fontWeight:500,
    textTransform:'none',
    "&:hover": {
      
      color: "#fff",
      background:'#4E97FD',
    },
    "&:active": {
      
      color: "#fff",
      background:'#4E97FD',
    },
    "&.Mui-selected":{
      color: "#fff",
      background:'#4E97FD',
    }
  },
}));
