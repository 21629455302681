import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  Grid,
  MenuItem,
  Fade,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Select,
  FormControl,
  FormGroup,
  Checkbox,
  Button,
  Avatar,
  Tabs,
  Tab,
  Paper,
  IconButton,
} from "@material-ui/core";
import { SketchPicker } from "react-color";
import Autocomplete from "@material-ui/lab/Autocomplete";
// data

// Model
import { framFormElements } from "../inventory_constant";
import { image } from "../../../assets/gallery.jpg";
import {
  currentUser,
  postFlagBooleanVariable,
  loadState,
} from "../../../hooks/helper";
import useStyles from "./styles";
// components

import moment from "moment";
import PageTitle from "../../PageTitle/PageTitle";
import LocationList from "./LocationList";
import TransactionList from "./TransactionList";
import MaterialsList from "./MaterialsList";
import {
  postFrameLense,
  getProcedureCode,
  getSupplierDetail,
  getManufacturerDetail,
  getInvtService,
  getServiceMaster,
} from "../../../services/ServiceLayer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InventoryValidation from "../../../hooks/InventoryValidation";
import ManageProcedureCost from "./ManageProcedureCost";
import { Delete } from "@mui/icons-material";
export default function AddFrame() {
  const history = useHistory();
  const { state } = useLocation();
  const inputRef = useRef();
  const selectedRow = state?.selectedRow;
  const eventType = state?.event;

  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();
  var [activeTabId, setActiveTabId] = useState(0);
  //
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const [logoUrls, setLogoUrls] = useState([]);

  const [isWrongFileType, setIsWrongFileType] = useState(false);
 
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [openModel, setOpenModel] = React.useState(false);
  const [modelShow, setModalShow] = useState(false);
  const [listData, setListData] = useState([]);
  const [invSourceData, setInvSourceData] = useState([]);
  const [structureList, setStructureList] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [frameTypeData, setFrameTypeData] = useState([]);
  const [rimTypeData, setRimTypeData] = useState([]);
  const [frameShapeData, setFrameShapeData] = useState([]);
  const [frameMaterialData, setFrameMaterialData] = useState([]);
  const [profitValue, setProfitValue] = useState(0);
  const [grossPercentage, setGrossPercentage] = useState(0);
  const [prodecureCodeData, setProdecureCodeData] = useState([]);
  const [frameBrandData, setFrameBrandData] = useState([]);
  const [colorData, setColorData] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [procedureCodeExtension, setProcedureCodeExtension] = useState("");
  const [manufacturerData, setManufacturerData] = useState([]);
  const [returnableData, setReturnableData] = useState([]);
  const [inventoryValue, setInventoryValue] = useState(0);
  const [supplierData, setSupplierData] = useState([]);
  const [inventoryReadValue, setInventoryReadValue] = useState();
  const [actionType, setActionType] = useState("ADDFRM");
  const [isActionExit, setIsActionExit] = useState(false);
 
  const [color, setColor] = useState("#000"); // Initial color, e.g., black
  const [showColorPicker, setShowColorPicker] = useState(false);
  // call meta data
  const metaData = loadState().metaData;
  const readAccessData = loadState().menu;

  useEffect(() => {
    console.log("readAccessData", readAccessData);
    const inventorySection = readAccessData.find(
      (item) => item.label === "Inventory"
    );
    if (inventorySection) {
      const inventoryChildren = inventorySection.children;

      const inventoryReadAcess = inventoryChildren.find(
        (child) => child.label === "Inventory"
      ).read_access;
      setInventoryReadValue(inventoryReadAcess);
    }
  }, [readAccessData]);
  useEffect(() => {
    console.log("loadState", metaData);
    setListData(metaData);
  }, []);

  useEffect(() => {
    if (listData.length > 0) {
      const genderData = listData[0].gender;
      const invSourceData = listData[0].InventorySource;
      const frameTypeData = listData[0].FrameType;
      const rimTypeData = listData[0].RimType;
      const frameShapeData = listData[0].FrameShape;
      const frameMaterialData = listData[0].FrameMaterial;
      const frameBrandData = listData[0].frameBrand;
      const colorData = listData[0].colorAndCode;
      const returnableData = listData[0].InventoryReturn;
      setGenderData(genderData);
      setInvSourceData(invSourceData);
      setFrameTypeData(frameTypeData);
      setRimTypeData(rimTypeData);
      setFrameShapeData(frameShapeData);
      setFrameMaterialData(frameMaterialData);
      setFrameBrandData(frameBrandData);
      setColorData(colorData);
      setReturnableData(returnableData)
    }
  }, [listData]);

  useEffect(() => {
    getServiceMaster(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSTR&structure_id=0`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setStructureList(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
    getInvtService(
      `location_id=${userData?.location_id}&user_role=${userRole}&service_type_id=204&opt_type=SELSRV`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setProdecureCodeData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
    getManufacturerDetail(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELMAN&manufacturer=0`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setManufacturerData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
  }, []);

  useEffect(() => {
    getSupplierDetail(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSUP&supplier_id=0`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setSupplierData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
  }, []);

  const [frameLensDetail, setFrameLensDetail] = useState({
    frame_id: "0",
    location_id: userData?.location_id,
    brand_id: "",
    frame_model_name: "",
    frame_type_id: "",
    frame_name: "",
    frame_color_id: "",
    frame_color_code: "",
    rim_type_id: "",
    collection: "",
    frame_shape_id: "",
    gender: "",
    material_id: "",
    frame_sku: "",
    manufacturer_frame_sku: "",
    service_id: "",
    service_name:'',
    manufacturer_id:"",
    a: "",
    b: "",
    ed: "",
    bridge: "",
    temple: "",
    usage: "",
    supplier_id: "",
    inv_source: "",
    frame_structure_id:"",
    qty_purchased: "",
    qty_sold: "",
    qty_inventory: "",
    retail_price: "",
    item_cost: "",
    grp_cost: "",
    is_commission_prod: "",
    commission_amount: "0",
    returnable_id:"",
    notes: "",
    is_active:"Y",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: actionType,
  });

  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    InventoryValidation({
      frame_name: frameLensDetail?.frame_name,
      brand_id: frameLensDetail?.brand_id,
      frame_model_name: frameLensDetail?.frame_model_name,
      frame_type_id: frameLensDetail?.frame_type_id,
      retail_price: frameLensDetail?.retail_price,
      item_cost: frameLensDetail?.item_cost,
    });
  useEffect(() => {
    if (selectedRow) {
      setFrameLensDetail({
        frame_id: eventType === "Copy" ? "0" : selectedRow?.frame_id,
        location_id: userData?.location_id,
        brand_id: selectedRow?.brand_id,
        frame_model_name: selectedRow?.frame_model_name,
        frame_name: selectedRow?.frame_name,
        frame_type_id: selectedRow?.frame_type_id,
        frame_sku: selectedRow?.frame_sku,
        manufacturer_frame_sku:selectedRow?.manufacturer_frame_sku,
        frame_color_code: selectedRow?.frame_color_code,
        frame_color_id: selectedRow?.frame_color_id,
        material_id: selectedRow?.material_id,
        rim_type_id: selectedRow?.rim_type_id,
        frame_shape_id: selectedRow?.frame_shape_id,
        gender: selectedRow?.gender,
       
        a: selectedRow?.a,
        b: selectedRow?.b,
        ed: selectedRow?.ed,
        bridge: selectedRow?.bridge,
        temple: selectedRow?.temple,
        lense_color: selectedRow?.lense_color,
        collection: selectedRow?.collection,
        usage: selectedRow?.usage,
        notes: selectedRow?.notes,
        lense_prescription: selectedRow?.lense_prescription,
        service_id: selectedRow?.service_id,
        service_name: selectedRow?.service_name,
        service_code: selectedRow?.service_code,
        service_code_extension: selectedRow?.service_code_extension,
        inv_source: selectedRow?.inv_source,
        frame_structure_id: selectedRow?.frame_structure_id,
        structure_type: selectedRow?.structure_type,
        qty_purchased: selectedRow?.qty_purchased,
        qty_sold: selectedRow?.qty_sold,
        qty_inventory: selectedRow?.qty_inventory,
        retail_price: selectedRow?.retail_price,
        item_cost: selectedRow?.item_cost,
        grp_cost: selectedRow?.grp_cost,
        is_commission_prod: selectedRow?.is_commission_prod,
        commission_amount: selectedRow?.commission_amount,
        spiff: selectedRow?.spiff,
        
        returnable_id:selectedRow?.returnable_id,
        manufacturer_id: selectedRow?.manufacturer_id,
        supplier_id: selectedRow?.supplier_id,

        updated_by: currentUser(),

        access_location: "access_location",
        opt_type: eventType === "Copy" ? "ADDFRM" : "UPDFRM",
      });
      setSelectedProcedure({
        service_id: selectedRow?.service_id,
        service_name: selectedRow?.service_name,
        service_code: selectedRow?.service_code,
        service_code_extension: selectedRow?.service_code_extension,
      });
    }
  }, [selectedRow]);
  useEffect(() => {
    const retailPrice = parseFloat(frameLensDetail.retail_price) || 0;
    const itemCost = parseFloat(frameLensDetail.item_cost) || 0;
    const commissionAmount = parseFloat(frameLensDetail.commission_amount) || 0;

    const calculatedProfit = retailPrice - (itemCost + commissionAmount);
    setProfitValue(calculatedProfit);

    //
    const calculatedGrossPercentage = (commissionAmount / retailPrice) * 100;
    setGrossPercentage(calculatedGrossPercentage);
  }, [
    frameLensDetail.retail_price,
    frameLensDetail.item_cost,
    frameLensDetail.commission_amount,
  ]);
  const handleCommissionTypeChange = (e) => {
    console.log("Commission Type Change Triggered");
    const newComissionType = e.target.value;
    console.log("New Commission Type:", newComissionType);

    setFrameLensDetail({
      ...frameLensDetail,
      is_commission_prod: newComissionType,
      commission_amount: newComissionType === "Y" ? "" : "",
    });
  };
  const clearForm = () => {
    // Define a function to clear the form fields
    setFrameLensDetail({
      frame_id: "0",
      global_frame_id: "",
      location_id: userData?.location_id,
      upccode: "",
      upc_type: "",
      brand_id: "",
      frame_model_name: "",
      frame_number: "",
      frame_name: "",
      frame_type_id: "",
      frame_sku: "",
      frame_color_code: "",
      frame_color: "",
      material_id: "",
      rim_type_id: "",
      frame_shape_id: "",
      service_id: "",
      gender: "",
      a: "",
      b: "",
      ed: "",
      bridge: "",
      temple: "",
      lense_color: "",
      collection: "",
      usage: "",
      notes: "",
      lense_prescription: "",
      service_code: "",
      service_code_extension: "",
      inv_source: "",
      structure_id: "",
      structure_type: "",
      qty_purchased: "",
      qty_sold: "",
      qty_inventory: "",
      retail_price: "",
      item_cost: "",
      grp_cost: "",
      is_commission_prod: "",
      commission_amount: "0",
      spiff: "",
      trace_file: "",
      manufacturer_id: "",
      supplier_id: "",
      returnable_id:"",
      updated_by: currentUser(),

      access_location: "access_location",
      opt_type: actionType,
    });
    setSelectedProcedure(null);
  };

  const goBack = () => {
    setTimeout(() => {
      history.push(`/app/inventory/${activeTabVal}`);
    }, 3000);
  };

  const handleSaveAndMore = () => {
    checkValidator(); // Check for validation errors
    setIsActionCalled(true);

    // clearForm(); // Clear form fields
  };
  useEffect(() => {
    if (!isError() && error !== null && isActionCalled) {
      postFrameLense(frameLensDetail).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Frame Lens added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          clearForm();
          if (isActionExit) {
            goBack();
          }
          setIsActionExit(false);
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActionExit(false);
        }
      });
    } else {
      setIsActionCalled(false);
    }
    setIsActionExit(false);
    setIsActionCalled(false);
  }, [isActionCalled]);
  const handleSaveAndExit = () => {
    checkValidator(); // Check for validation errors
    setIsActionCalled(true);
    setIsActionExit(true);
  };

  const activeTabVal = 0;
  // setIsActionExit(true);
  const handleCancel = () => {
    history.push(`/app/inventory/${activeTabVal}`)
  };
  useEffect(() => {
    // Your existing code here ...

    // Calculate the inventory value
    const purchased = parseFloat(frameLensDetail.qty_purchased) || 0;
    const sold = parseFloat(frameLensDetail.qty_sold) || 0;
    const inventoryValue = purchased - sold;
    setInventoryValue(inventoryValue);
  }, [
    frameLensDetail.retail_price,
    frameLensDetail.item_cost,
    frameLensDetail.commission_amount,
    frameLensDetail.qty_purchased, // Add qty_purchased here
    frameLensDetail.qty_sold, // Add qty_sold here
  ]);
 
  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpenModel(false);
  };

  const toggleColorPicker = () => {
    setShowColorPicker(!showColorPicker);
  };

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
  };

  // logo upload
  const upload = (e) => {
    setIsWrongFileType(false);
    const images = e.target.files;

    for (let i = 0; i < Math.min(images.length, 3); i++) {
      const fileName = images[i]?.name;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

      if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
        setLogoUrls((prevUrls) => [
          ...prevUrls,
          URL.createObjectURL(images[i]),
        ]);
      } else {
        console.log("Only jpg/jpeg and png files are allowed!");
        setIsWrongFileType(true);
        setTimeout(() => {
          setIsWrongFileType(false);
        }, 3000);
      }
    }
  };
  useEffect(() => {
    if (logo) {
      let urllink = URL.createObjectURL(logo);
      console.log("urllink", urllink);
      setLogoUrl(urllink);
    }
  }, [logo]);

  const removeImageByIndex = (index) => {
    // Create a copy of the logoUrls array without the image at the specified index
    const updatedLogoUrls = [...logoUrls];
    updatedLogoUrls.splice(index, 1);

    // Update the state to trigger a re-render
    setLogoUrls(updatedLogoUrls);
  };
  return (
    <>
      <ToastContainer />
      <ManageProcedureCost
        open={openModel}
        title={"New Procedure"}
        onHide={hideHandle}
        setModalShow={setModalShow}
      />
      <PageTitle
        title="Manage Frame"
        subtitle="Inventory >"
        urlLink={`/app/inventory/${activeTabVal}`}
      />
      {/* <TableWidget noBodyPadding> */}
      <Grid
        container
        spacing={2}
        className={classes.formBox}
        style={{
          border: "1px solid #DED9D9",
          marginBottom: "16px",
          padding: "16px",background:'white'
        }}
      >
        <Grid item md={10}>
          <Grid container spacing={1}>
            {framFormElements.map((element, i) => {
              const isHidden =
                (element.key === "item_cost" && inventoryReadValue === "Y") ||
                (element.key === "profit" && inventoryReadValue === "Y") ||
                (element.key === "is_commission_prod" &&
                  inventoryReadValue === "Y") ||
                (element.key === "commission_amount" &&
                  inventoryReadValue === "Y") ||
                (element.key === "gross_percentage" &&
                  inventoryReadValue === "Y") ||
                (element.key === "service_tax1" &&
                  inventoryReadValue === "Y") ||
                (element.key === "service_tax2" &&
                  inventoryReadValue === "Y") ||
                (element.key === "grp_cost" && inventoryReadValue === "Y") ||
                (element.key === "spiff" && inventoryReadValue === "Y");
              return (
                <>
                  {!isHidden && (
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={`${classes.textGroup} ${
                        element.title ? classes.highlightedItem : ""
                      }`}
                      style={{ border: "1px solid #DED9D9" }}
                    >
                      <Typography
                        className={classes.formHeading}
                        style={{ fontWeight: "500", color: "#4E97FD" }}
                      >
                        {element.label}{" "}
                        {element.required === "true" ? (
                          <span className={classes.required}>*</span>
                        ) : null}
                      </Typography>
                      {element.type ? (
                        <>
                          {element.type === "select" &&
                          element.key !== "service_name" ? (
                            <FormControl
                              fullWidth
                              className={classes.dropdownFormControl}
                            >
                              <Select
                                name={element.key}
                                className={classes.selectDropdown}
                                placeholder={element.placeHolder}
                                value={
                                  frameLensDetail &&
                                  frameLensDetail[element.key]
                                    ? frameLensDetail &&
                                      frameLensDetail[element.key]
                                    : ""
                                }
                                onChange={(e) => {
                                  console.log("selected");
                                  setFrameLensDetail({
                                    ...frameLensDetail,
                                    [element.key]: e.target.value,
                                  });
                                  resetValidator(e.target.name);
                                }}
                                isInvalid={error && error[element.key]}
                                disabled={inventoryReadValue === "Y"}
                              >
                                <MenuItem disabled value="">
                                  Select
                                </MenuItem>
                                {element.key === "service_code" &&
                                prodecureCodeData
                                  ? prodecureCodeData.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.service_code}
                                      >
                                        {item.service_code}
                                      </MenuItem>
                                    ))
                                  : element.key === "supplier_id" &&
                                    supplierData
                                  ? supplierData.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.supplier_id}
                                      >
                                        {item.supplier_name}
                                      </MenuItem>
                                    ))
                                  : element.key === "manufacturer_id" &&
                                    manufacturerData
                                  ? manufacturerData.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.manufacturer_id}
                                      >
                                        {item.manufacturer_name}
                                      </MenuItem>
                                    ))
                                  : element.key === "inv_source" &&
                                    invSourceData
                                  ? invSourceData.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "gender" && genderData
                                  ? genderData.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "frame_type_id" &&
                                    frameTypeData
                                  ? frameTypeData.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "rim_type_id" && rimTypeData
                                  ? rimTypeData.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "frame_shape_id" &&
                                    frameShapeData
                                  ? frameShapeData.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "material_id" &&
                                    frameMaterialData
                                  ? frameMaterialData.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "frame_structure_id" &&
                                    structureList
                                  ? structureList.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.structure_id}
                                      >
                                        {item.structure_name}
                                      </MenuItem>
                                    ))
                                  : element.key === "brand_id" &&
                                    frameBrandData
                                  ? frameBrandData.map((item, index) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    )): element.key ==="returnable_id" && returnableData ? returnableData.map((item,index)=>(
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.options
                                  ? element.options.map((item) => (
                                      <MenuItem value={item}>{item}</MenuItem>
                                    ))
                                  : element.key === "frame_color_id" &&
                                    colorData &&
                                    colorData.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.company_group_key}
                                      </MenuItem>
                                    ))}
                              </Select>
                              <Fade in={error}>
                                <Typography
                                  color="secondary"
                                  className={classes.errorMessage}
                                >
                                  {error && error[element.key]}
                                </Typography>
                              </Fade>
                            </FormControl>
                          ) : element.type === "select" &&
                            element.key === "service_name" ? (
                            <>
                              <Autocomplete
                              size="small"
                                disabled={inventoryReadValue === "Y"}
                                id="procedure-code"
                                options={prodecureCodeData} // Replace with your list of options
                                getOptionLabel={(option) => option.service_name} // Define how to display the options
                                value={
                                  selectedProcedure
                                    ? selectedProcedure
                                    : frameLensDetail[element.key]
                                }
                                // value={selectedProcedure ? selectedProcedure : serviceLensDetail?.service_code}
                                onChange={(event, newValue) => {
                                  setSelectedProcedure(newValue); // Update the selected Service Code
                                  // Update the Service Code extension
                                  if (newValue) {
                                    setProcedureCodeExtension(
                                      newValue.service_name
                                    );
                                    setFrameLensDetail({
                                      ...frameLensDetail,
                                      service_id: newValue.service_id,
                                      service_name: newValue?.service_name,
                                      service_code: newValue.service_code,
                                      service_code_extension:
                                        newValue.service_name,
                                      retail_price: newValue?.retail_price,
                                      item_cost: newValue?.item_cost,
                                    });
                                  } else {
                                    setProcedureCodeExtension("");
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                    // inputProps={{
                                    //   style: {
                                    //     height: "12px",
                                    //     fontSize: "12px",
                                    //     padding: "4px",
                                    //   },
                                    // }}
                                  />
                                )}
                              />
                            </>
                          ) : element.type === "is_commission_prod" ? (
                            <FormControl
                              disabled={inventoryReadValue === "Y"}
                              fullWidth
                              className={classes.dropdownFormControl}
                            >
                              <Select
                                name="is_commission_prod"
                                className={classes.selectDropdown}
                                placeholder="Select"
                                value={frameLensDetail.is_commission_prod}
                                onChange={handleCommissionTypeChange} // Connect the function here
                              >
                                <MenuItem value="Y">Yes</MenuItem>
                                <MenuItem value="N">No</MenuItem>
                              </Select>
                            </FormControl>
                          ) : element.type === "text" ? (
                            <>
                              <TextField
                                required
                                name={element.key}
                                placeholder={element.placeHolder}
                                size="small"
                                className={classes.txtForm}
                                fullWidth
                                variant="outlined"
                                inputProps={{
                                  style: {
                                    height: "12px",
                                    fontSize: "12px",
                                  },
                                  maxLength: element.maxLength,
                                  // onKeyPress: element.onKeyPress,
                                  onPaste: element.onPaste,
                                }}
                                value={
                                  element.key === "profit"
                                    ? profitValue.toFixed(2)
                                    : element.key === "gross_percentage"
                                    ? grossPercentage.toFixed(2)
                                    : element.key === "qty_inventory"
                                    ? inventoryValue.toFixed(3)
                                    : frameLensDetail &&
                                      frameLensDetail[element.key] ? frameLensDetail[element.key] : ''
                                }
                                onChange={(e) => {
                                  setFrameLensDetail({
                                    ...frameLensDetail,
                                    [element.key]: e.target.value,
                                  });
                                  resetValidator(e.target.name);
                                }}
                                isInvalid={error && error[element.key]}
                                disabled={element.key === "frame_sku" ||
                                  element.key === "profit" ||
                                  element.key === "service_code" ||
                                  element.key === "gross_percentage" ||
                                  element.key === "service_code_extension" ||
                                  element.key === "qty_inventory" ||
                                  inventoryReadValue === "Y"
                                }
                              />
                              <Fade in={error}>
                                <Typography
                                  color="secondary"
                                  className={classes.errorMessage}
                                >
                                  {error && error[element.key]}
                                </Typography>
                              </Fade>
                            </>
                          ) : element.type === "commission_amount" ? (
                            <TextField
                              type="text"
                              className={classes.txtForm}
                              disabled={
                                frameLensDetail.is_commission_prod !== "Y" ||
                                inventoryReadValue === "Y"
                              } // Enable only when is_commission_prod is "yes"
                              value={frameLensDetail.commission_amount}
                              fullWidth
                              variant="outlined"
                              size="small"
                              inputProps={{
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                                maxLength: element.maxLength,
                                onKeyPress: element.onKeyPress,
                              }}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                setFrameLensDetail({
                                  ...frameLensDetail,
                                  commission_amount: isNaN(value) ? "" : value,
                                });
                              }}
                            />
                          ) : element.type === "date" ||
                            element.type === "datetime-local" ? (
                            <TextField
                              disabled={inventoryReadValue === "Y"}
                              id={element.key}
                              name={element.key}
                              placeholder={element.placeHolder}
                              type={element.type}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                min: `${currentDateTime}`,
                                max: `${currentDate}`,
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={
                                frameLensDetail && frameLensDetail[element.key]
                              }
                              onChange={(e) => {
                                setFrameLensDetail({
                                  ...frameLensDetail,
                                  [element.key]: e.target.value,
                                });
                              }}
                            />
                          ) : element.type === "radio" ? (
                            <RadioGroup row defaultValue="">
                              {element.radioValue.map((item) => (
                                <FormControlLabel
                                  size="small"
                                  style={{ padding: "0px 6px" }}
                                  value={item}
                                  control={<Radio />}
                                  label={item}
                                  className={classes.smallRadioButton}
                                />
                              ))}
                            </RadioGroup>
                          ) : element.type === "checkbox" ? (
                            <>
                              <Checkbox
                                name={element.key}
                                checked={
                                  frameLensDetail[element.key] !== "N"
                                    ? frameLensDetail[element.key]
                                    : false
                                }
                                onChange={(e) => {
                                  const value = postFlagBooleanVariable(
                                    e.target.checked
                                  );
                                  setFrameLensDetail({
                                    ...frameLensDetail,
                                    [element.key]: value,
                                  });
                                }}
                              />
                            </>
                          ) : element.type === "color" ? (
                            <div style={{ display: "flex" }}>
                              <button
                                style={{ height: "32px" }}
                                onClick={toggleColorPicker}
                              >
                                Pick a Color
                              </button>
                              {showColorPicker && (
                                <div
                                  style={{
                                    position: "absolute",
                                    zIndex: "2",
                                    marginTop: "36px",
                                  }}
                                >
                                  <SketchPicker
                                    color={color}
                                    onChange={handleColorChange}
                                  />
                                </div>
                              )}
                              <div
                                style={{
                                  width: "34px",
                                  height: "34px",
                                  backgroundColor: color,
                                  marginLeft: "10px",
                                }}
                              ></div>
                            </div>
                          ) : (
                            <TextField
                              disabled={inventoryReadValue === "Y"}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              placeholder="Enter notes upto 1000 character"
                              variant="outlined"
                              multiline
                              rows={3}
                              inputProps={{
                                style: {
                                  fontSize: "12px",
                                },
                                maxLength: element.maxLength,
                              }}
                              value={
                                frameLensDetail && frameLensDetail[element.key] ? frameLensDetail[element.key] : ''
                              }
                              onChange={(e) => {
                                setFrameLensDetail({
                                  ...frameLensDetail,
                                  [element.key]: e.target.value,
                                });
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <Typography
                            className={classes.formHeading}
                            style={{
                              fontWeight: "600",

                              color: "#4E97FD",
                            }}
                          >
                            {element.title}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  )}
                </>
              );
            })}
          </Grid>
        </Grid>
        <Grid item md={2}>
          {logoUrls ? (
            logoUrls.map((url, index) => (
              <div key={index} style={{ position: "relative" }}>
                <Avatar
                  className={classes.avatar}
                  style={{ borderRadius: "0px", border: "" }}
                  src={url}
                />{" "}
                <IconButton
                  style={{ position: "absolute", top: "5px", right: "5px" }}
                  onClick={() => removeImageByIndex(index)}
                >
                  <Delete /> {/* Use your delete icon component here */}
                </IconButton>
              </div>
            ))
          ) : (
            <Avatar
              className={classes.avatar}
              sx={{
                borderRadius: "0px",
                marginTop: "10px",
              }}
              src={image}
              variant="square"
            />
          )}
          <center>
            <Button
              variant="contained"
              className={classes.blueButton}
              component="label"
              disableElevation
            >
              Upload Frame
              <input
                hidden
                onChange={(e) => upload(e)}
                ref={inputRef}
                multiple
                name="myImage"
                accept="image/*"
                type="file"
              />
            </Button>
          </center>
        </Grid>
      </Grid>
      <div
        className={classes.footerBox}
        // style={{ justifyContent: "space-between" }}
      >
        {/* <div>
          {inventoryReadValue === "N" && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.leftButton}
              style={{ marginRight: "12px" }}
              onClick={addProcedure}
            >
              Add Service Code
            </Button>
          )}
        </div> */}
        <div>
          <Button
            variant="outlined"
            className={classes.leftButton}
            style={{ marginRight: "12px" }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          {inventoryReadValue === "N" && (
            <>
              <Button
                variant="contained"
                color="secondary"
                className={classes.rightButton}
                onClick={handleSaveAndExit}
                style={{ marginRight: "12px" }}
              >
                save & Exit
              </Button>
              {!selectedRow && (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.rightButton}
                  onClick={handleSaveAndMore}
                >
                  save & More
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      {selectedRow && (
        <>
          <Paper className={classes.iconsContainer}>
            <Tabs TabIndicatorProps={{
            style: { background: "#4E97FD",width:"150px"},
          }}
            
              variant="scrollable"
              scrollButtons="auto"
              value={activeTabId}
              onChange={(e, id) => setActiveTabId(id)}
              className={classes.iconsBar}
            >
              <Tab label="Locations" classes={{ root: classes.tab }} />
              <Tab label="Transactions" classes={{ root: classes.tab }} />
            </Tabs>
          </Paper>
          {activeTabId === 0 && <LocationList />}
          {activeTabId === 1 && <TransactionList />}
          {activeTabId === 2 && <MaterialsList />}
        </>
      )}

      {/* </TableWidget> */}
    </>
  );
}
