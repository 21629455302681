import { handleKeyPress,handleAddressKeyPress, handleAddressKeyPressPaste, ssnFormat } from "../../../hooks/helper";
export const formElements = [
  {
    value: "btn1",
    btnName: "Search Physician",
    type: "button",
    mdSize: "3",
    btnClick: "searchguardian",
  },
  {
    key: "label1",
    label: "Profile",
    type: "label",
    mdSize: "12",
  },
  {
    key: "employee_type",
    label: "Staff Type",
    placeHolder: "type here",
    type: "select",
    required: "true",

    options: ["Reception", "Doctor", "Admin", "Optician"],
    mdSize: "2",
  },
  {
    key: "first_name",
    label: "First Name",
    placeHolder: "Enter Staff First name",
    type: "text",
    mdSize: "2",
    required: "true",
    maxLength: "30",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,


  },
  {
    key: "middle_name",
    label: "Middle Name",
    placeHolder: "Enter middle name",
    type: "text",
    mdSize: "2",
    maxLength: "30",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,

  },
  {
    key: "last_name",
    label: "Last Name",
    placeHolder: "Enter last name",
    type: "text",
    mdSize: "2",
    required: "true",
    maxLength: "30",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,

  },
  {
    key: "joining_date",
    label: "Joining Date",
    placeHolder: "select",
    type: "date",
    mdSize: "2",
    required: "true",
  },
  {
    key: "ssn",
    label: "SSN",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    maxLength: "11",
    onKeyPress: ssnFormat,

  },
  {
    key: "dl",
    label: "DL",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",  
    maxLength: "30",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,

  },
  {
    key: "departmnet_id",
    label: "Department",
    placeHolder: "type here",
    type: "select",
    optionsId: [
      {
        id: "1",
        name: "department 1",
      },
      {
        id: "2",
        name: "department 1",
      },
      {
        id: "3",
        name: "department 1",
      },
    ],
    mdSize: "2",
  },
  {
    key: "hospital_type_id",
    label: "Hospital Type",
    placeHolder: "type here",
    type: "select",
    optionsId: [
      {
        id: "1",
        name: "hospital 1",
      },
      {
        id: "2",
        name: "hospital 2",
      },
      {
        id: "3",
        name: "hospital 3",
      },
    ],
    mdSize: "2",
  },
  {
    key: "specialist",
    label: "Specialities",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    maxLength: "30",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,

  },
  {
    key: "medical_reg_no",
    label: "Medical Reg. No.",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    maxLength: "30",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,

  },
  {
    key: "date_of_birth",
    label: "Date of Birth",
    placeHolder: "select",
    type: "date",
    mdSize: "2",
  },
  {
    key: "gender",
    label: "Gender",
    placeHolder: "type here",
    type: "select",
    options: ["Male", "Female", "Unknown"],
    mdSize: "2",
  },
  {
    key: "pref_language",
    label: "Language",
    placeHolder: "type here",
    type: "select",
    options: [
      "English",
      "spanish",
      "Hindi",
      "Baltic",
      "French",
      "German",
      "Arabic",
      "Chinese",
      "Finnish",
      "gujrati",
      "Urdu",
      "Irish",
      "Other",
    ],
    mdSize: "2",
  },
  {
    key: "npi_no",
    label: "NPI No.",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    maxLength: "30",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,

  },

  {
    key: "user_name",
    label: "User Name",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
  },
  {
    key: "email",
    label: "Email",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    required: "true",
    maxLength: "30",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,

  },

  {
    key: "label2",
    label: "Other Details",
    type: "label",
    mdSize: "12",
    maxLength: "30",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,

  },
  {
    key: "contact_cell",
    label: "Contact Cell",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    required: "true",
    onKeyPress: handleKeyPress,
    maxLength: "10",
  },
  {
    key: "contact_home",
    label: "Contact Home",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: handleKeyPress,
    maxLength: "10",
  },
  {
    key: "contact_office",
    label: "Contact Office",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: handleKeyPress,
    maxLength: "10",
  },
  {
    key: "address_line1",
    label: "Address Line 1",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    maxLength: "30",
    onKeyPress: handleAddressKeyPress,
        onPaste: handleAddressKeyPressPaste,

  },
  {
    key: "address_line2",
    label: "Address Line 2",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    maxLength: "30",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,


  },
  {
    key: "city",
    label: "City",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    maxLength: "30",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,


  },
  {
    key: "state",
    label: "State",
    placeHolder: "type here",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "2",
  },
  {
    key: "zip",
    label: "Zip",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    onKeyPress: handleKeyPress,
    maxLength: "5",
  },
  {
    key: "country",
    label: "Country",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    maxLength: "30",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,

  },
  {
    key: "location_id",
    label: "Office Location",
    placeHolder: "type here",
    type: "select",
    options: [],
    mdSize: "2",
    required: "true",
  },

  {
    key: "start_time",
    label: "Start Time",
    placeHolder: "type here",
    type: "time",
    mdSize: "2",
    required: "true",
  },
  {
    key: "end_time",
    label: "End Time",
    placeHolder: "type here",
    type: "time",
    mdSize: "2",
    required: "true",
  },
  {
    key: "label3",
    label: "Working Day (Please select working day)",
    type: "label",
    mdSize: "12",
  },
  {
    key: "work_monday",
    label: "Monday",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "work_tuesday",
    label: "Tuesday",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "work_wednesday",
    label: "Wednesday",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "work_thursday",
    label: "Thursday",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "work_friday",
    label: "Friday",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "work_saturday",
    label: "Saturday",
    type: "checkbox",
    mdSize: "1",
  },
  {
    key: "work_sunday",
    label: "Sunday",
    type: "checkbox",
    mdSize: "1",
  },
];
