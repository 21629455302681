import React from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,

} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";

// styles
import useStyles from "./styles";
import Profile from "../Cases/PatientTab/Profile";
import AddressBilling from "../Cases/PatientTab/AddressBilling";
import Additional from "../Cases/PatientTab/Additional";
import LatestBilling from "./PatientTab/LatestBilling";
import PatientStatus from "./PatientTab/PatientStatus";
import Emergency from "../Cases/PatientTab/Emergency";
export default function PatientProfile({
  patientData,
  patientProfileData,
  setPatientData,
  setPatientProfileData,
  error,
  checkValidator,
  resetValidator,
  resetAllValidator,
  isError,
  titleValues,
  genderValues,
  maritalValues,
  genderIdentificationValues,
  sexOrientationValues,
  preferenceContactValues,
  preferenceHourValues,
  languageValues,
  addressStatus,
  ethinicityValues,
  raceValues,
  applicableRoleValues,
  disabilityValues,
  veteranValues,
  patientStatusValues,
  inactiveValues,
  deceasedValues,setIsProfileStateChanged
}) {
  var classes = useStyles();
console.log("patientProfileData",patientProfileData);
  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          className={classes.AccordionSummary}
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Profile</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Profile
            patientProfileData={patientProfileData}
            setPatientProfileData={setPatientProfileData}
            error={error}
            checkValidator={checkValidator}
            resetValidator={resetValidator}
            resetAllValidator={resetAllValidator}
            isError={isError}
            titleValues={titleValues}
            genderValues={genderValues}
            maritalValues={maritalValues}
            genderIdentificationValues={genderIdentificationValues}
            sexOrientationValues={sexOrientationValues}
            preferenceContactValues={preferenceContactValues}
            preferenceHourValues={preferenceHourValues}
            languageValues={languageValues}
            setIsProfileStateChanged={setIsProfileStateChanged}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Address</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AddressBilling
            patientProfileData={patientProfileData}
            setPatientProfileData={setPatientProfileData}
            error={error}
            checkValidator={checkValidator}
            resetValidator={resetValidator}
            resetAllValidator={resetAllValidator}
            isError={isError}
            addressStatus={addressStatus}
            setIsProfileStateChanged={setIsProfileStateChanged}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Additional</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Additional
            patientProfileData={patientProfileData}
            setPatientProfileData={setPatientProfileData}
            error={error}
            checkValidator={checkValidator}
            resetValidator={resetValidator}
            resetAllValidator={resetAllValidator}
            isError={isError}
            ethinicityValues={ethinicityValues}
            raceValues={raceValues}
            applicableRoleValues={applicableRoleValues}
            disabilityValues={disabilityValues}
            veteranValues={veteranValues}
            setIsProfileStateChanged={setIsProfileStateChanged}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Latest Billing</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LatestBilling
            patientData={patientData}
            setPatientData={setPatientData}
            setIsProfileStateChanged={setIsProfileStateChanged}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Patient Status</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PatientStatus
            patientData={patientData}
            setPatientData={setPatientData}
            patientStatusValues={patientStatusValues}
            inactiveValues={inactiveValues}
            deceasedValues={deceasedValues}
            setIsProfileStateChanged={setIsProfileStateChanged}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Emergency Contact</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Emergency
            patientProfileData={patientProfileData}
            setPatientProfileData={setPatientProfileData}
            error={error}
            checkValidator={checkValidator}
            resetValidator={resetValidator}
            resetAllValidator={resetAllValidator}
            isError={isError}
            setIsProfileStateChanged={setIsProfileStateChanged}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
