import React, { useEffect, useState } from "react";
import {
  Grid,
  Modal,
  Typography,
  Box,
  TextField,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  OutlinedInput,
  TableRow,
  TableCell,
} from "@material-ui/core";
// Model
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { Menu, InputBase } from "@material-ui/core";
import Popup from "../common/modal/Popup";
// styles
import useStyles from "./styles";

import TableWidget from "../Widget/TableWidget";
import TableDesign from "../common/table/TableDesign";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import {
  MoreVert as MoreIcon,
  Search as SearchIcon,
  Lens,
} from "@material-ui/icons";
export default function ManageAppointment(props) {
  const { patientData, onHide, ...props1 } = props;
  console.log("patientData", patientData);
  var classes = useStyles();
  // table Header
  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Patient Name",
    },

    {
      label: "Appointment Time",
      secondaryLabel: "(Appointment Reason)",
    },

    {
      label: "Provider",
    },
    {
      label: "Slot",
    },
    {
      label: "check In",
      secondaryLabel: "(check Out)",
    },

    {
      label: "Exam Room",
    },

    {
      label: "Action",
    },
  ];
  const history = useHistory();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [open, setOpen] = React.useState(false);
  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
  };
  const [selectRow, setSelectRow] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  useEffect(() => {
    console.log("selectRow", selectRow);
  }, [selectRow]);
  return (
    <Popup
      {...props1}
      onHide={hideHandler}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      footer={
        <>
          <Button
            className={classes.outlineBtn}
            variant="outlined"
            size="medium"
            onClick={hideHandler}
          >
            Cancel
          </Button>

          {/* <Button
          className={classes.fillBtn}
          variant="contained"
          size="medium"
          color="secondary"
        //   onClick={(e) => submitHandler(e)}
        >
          Add
        </Button> */}
        </>
      }
    >
      <DialogContent>
        <DialogContentText>
          <>
            <TableWidget noBodyPadding>
              <TableDesign
                {...{
                  title: "List of All Clients",
                  tableHeadings,
                }}
              >
                {patientData && patientData?.length ? (
                  patientData.map((row, i) => {
                    if (row)
                      return (
                        <TableRow key={i} className={classes.tableBodyCell}>
                          <TableCell className={classes.tableBodyCell}>
                            {i + 1}
                          </TableCell>
                          <TableCell style={{cursor: 'pointer'}}
                            onClick={() =>
                              history.push(`/app/add-Exam/${row.patient_id}`)
                            }
                            className={classes.tableBodyCell}
                          >
                            {row.first_name} {row.last_name}
                          </TableCell>

                          <TableCell
                            align="left"
                            onClick={() =>
                              history.push(`/app/add-Exam/${row.patient_id}`)
                            }
                            style={{ cursor: "pointer" }}
                            className={classes.tableBodyCell}
                          >
                            {moment(row.start_time).utc().format("LT")}
                            <br />
                            <span style={{ color: "#4E97FD" }}>
                              ({row.title})
                            </span>
                          </TableCell>

                          <TableCell className={classes.tableBodyCell}>
                            {row.doc_name}
                          </TableCell>
                          <TableCell className={classes.tableBodyCell}>
                            {moment(row.end_time).diff(
                              row.start_time,
                              "minutes"
                            )}{" "}
                            minutes
                          </TableCell>
                          <TableCell className={classes.tableBodyCell}>
                            {moment(row.start_time).utc().format("LT")}
                            <br />
                            <span style={{ color: "#4E97FD" }}>
                              ({moment(row.end_time).utc().format("LT")})
                            </span>
                          </TableCell>

                          <TableCell className={classes.tableBodyCell}>
                            {row.room_number}
                          </TableCell>

                          <TableCell className={classes.tableBodyCell}>
                            <IconButton
                              onClick={(e) => {
                                handleClick(e);
                                setSelectRow(row);
                              }}
                            >
                              <MoreIcon />
                            </IconButton>
                            <Menu
                              className={classes.menuBox}
                              anchorEl={anchorEl}
                              open={openDown}
                              onClose={handleClose}
                            >
                              <MenuItem
                                className={classes.menuItem}
                                onClick={() =>
                                  history.push(
                                    `/app/add-Exam/${selectRow?.patient_id}`
                                  )
                                }
                              >
                                <Lens
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                    marginRight: "4px",
                                  }}
                                />{" "}
                                Add Exam
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan="8">No Result Found</TableCell>
                  </TableRow>
                )}
              </TableDesign>
            </TableWidget>
          </>
        </DialogContentText>
      </DialogContent>
    </Popup>
  );
}
