import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  
  TableRow,
  TableCell,
  InputBase,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../common/modal/Popup";
// styles
import useStyles from "../styles";

import { Autocomplete } from "@mui/material";

import { getMedicineListData } from "../../../services/ServiceLayer";
import {  loadState } from "../../../hooks/helper";
import TableWidget from "../../Widget/TableWidget";
import TableDesign from "../../common/table/TableDesign";
import {
  MoreVert as MoreIcon,
  Search as SearchIcon,

} from "@material-ui/icons";
import classNames from "classnames";
import ManageMedication from "./ManageMedication";
import ManageFindMedication from "./ManageFindMedication";
export default function ManageMedicationTable(props) {
  console.log("props-24", props);
  const { patientId, frequency, setDataUpdated } = props;

  //
  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");

  const [searchModelShow, setSearchModalShow] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
 

  const [value, setValue] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
    setSelectclient(null);
    setOptionsLocal([]);
    setValue("");
  };
  var [isSearchOpen, setSearchOpen] = useState(true);
  const [options, setOptions] = useState([]);
  const [optionsLocal, setOptionsLocal] = useState([]);
  const [medicineData, setMedicineData] = useState([]);
  const [medicineLocalList, setMedicineLocalList] = useState([]);
  // const previousController = useRef();

  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const getData = (searchTerm) => {
    // if (previousController.current) {
    //   previousController.current.abort();
    // }
    const controller = new AbortController();
    const signal = controller.signal;
    // previousController.current = controller;
    fetch(
      `https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search?ef=DISPLAY_NAME,STRENGTHS_AND_FORMS,RXCUIS,SXDG_RXCUI&authenticity_token=&terms=${searchTerm}`,
      {
        signal,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(function (response) {
        console.log("response", response);
        return response.json();
      })
      .then(function (myJson) {
        console.log("search term: " + searchTerm + ", results: ", myJson);
        if (myJson) {
          setMedicineData(myJson);
          setOptions(myJson[1]);
        }
      });
  };

  const searchMedication = () => {
    getMedicineListData(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELMED&mendicationName=${value}`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setMedicineLocalList(response?.data);
        setOptionsLocal(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  };
  useEffect(() => {
    console.log("options", options);
    console.log("medicineData", medicineData);
    console.log("medicineLocalList", medicineLocalList);
  }, [medicineData, medicineLocalList, options]);

  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Medicine",
    },
    {
      label: "Medicine Doze",
    },
    {
      label: "Medicine Strength",
    },
    {
      label: "Medicine Type",
    },
  ];
  const onInputChange = (event, value, reason) => {
    if (value) {
      console.log("params",value);
      getData(value);
    } else {
      setOptions([]);
    }
  };
  const handleSearchMedicine = () => {
    setIsOpen(true);
    setSearchModalShow(true);
  };
  const handleHideSearchMedicine = () => {
    setIsOpen(false);
  };
  const [open1, setOpen1] = useState(false);
  const [medModelShow, setMedModalShow] = useState(false);
  const [selectclient, setSelectclient] = useState(null);
  const hideHandle = () => {
    setOpen1(false);
    setOpen2(false);
    setSelectclient(null);
    setOptionsLocal([]);
    setValue("");
  };
  const [open2, setOpen2] = useState(false);
  const [medModelShow1, setMedModalShow1] = useState(false);
  const handleFindSelect =(rec)=>{
    console.log("rec", rec);
   
    setOpen2(true);
    setSelectclient(rec);
    setMedModalShow1(true);
    props.onHide();
  }
  const handleSelect = (rec) => {
    console.log("rec", rec);
    setOpen1(true);
    setSelectclient(rec);
    setMedModalShow(true);
    props.onHide();
  };
  const { onHide, ...props1 } = props;
  return (
    <>
      <ManageMedication
        open={open1}
        onHide={hideHandle}
        title={"Add Medication"}
        patientId={patientId}
        setDataUpdated={setDataUpdated}
        selectclient={selectclient}
        frequency={frequency}
        setModalShow={medModelShow}
      />

      <ManageFindMedication
      open={open2}
       onHide={hideHandle}
       title={"Add Medication"}
       patientId={patientId}
       setDataUpdated={setDataUpdated}
       selectclient={selectclient}
       frequency={frequency}
       setModalShow={medModelShow1}
      />
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            {isOpen ? (
              <Grid style={{ display: "flex" }} item xs={12} md={12}>
                <>
                  <Autocomplete
                    id="combo-box-demo"
                    options={options}
                    onInputChange={onInputChange}
                    getOptionLabel={(option) => option}
                    filterOptions={(options) => options}
                    style={{
                      width: 600,
                      height: 36,
                      marginBottom: 10,
                      marginRight: "16px",
                    }}
                    renderInput={(params) => (
                      <TextField
                        style={{ height: 36 }}
                        {...params}
                        label="Select Medication"
                        variant="outlined"
                      />
                    )}
                    onChange={(event, newValue) => {
                      console.log("newValue",newValue);
                      handleFindSelect(newValue); // Update selectclient state with the selected value
                    }}
                  />
                </>
                <Button
                  className={classes.fillBtn}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={handleHideSearchMedicine}
                >
                  Close
                </Button>
              </Grid>
            ) : (
              <Grid
                style={{ display: "flex", justifyContent: "end" }}
                item
                xs={12}
                md={12}
              >
                <Button
                  className={classes.fillBtn}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={handleSearchMedicine}
                >
                  Find Other Medication
                </Button>
              </Grid>
            )}
             {!isOpen && (<>
             
            <div
              className={classes.pageTitleContainer}
              style={{ alignItems: "center", marginTop: "6px" }}
            >
              <Typography
                className={classes.typo}
                weight="medium"
                style={{ display: "flex" }}
              >
                <div
                  className={classNames(classes.search, {
                    [classes.searchFocused]: isSearchOpen,
                  })}
                >
                  <div
                    className={classNames(classes.searchIcon, {
                      [classes.searchIconOpened]: isSearchOpen,
                    })}
                    onClick={() => setSearchOpen(!isSearchOpen)}
                  >
                    <SearchIcon classes={{ root: classes.headerIcon }} />
                  </div>

                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    onChange={handleChange}
                  />
                   <div
                            className="requiredNote"
                            style={{ marginTop: "4px",fontSize:'12px',color:'blue', position: "relative" }}
                          >
                            Note: Minimum 3 characters are required.
                          </div>
                </div>
               
                {value.length > 2 && (
                  <Button
                    className={classes.btnleftOutline}
                    onClick={searchMedication}
                    variant="contained"
                    size="medium"
                    color="secondary"
                  >
                    Search
                  </Button>
                )}
              </Typography>
            </div>
            <Grid
              container
              spacing={2}
              className={classes.formBox}
              style={{ marginBottom: "4px" }}
            >
              <>
                <Grid item xs={12}>
                  <TableWidget noBodyPadding>
                    <TableDesign
                      {...{
                        title: "List of All Clients",
                        tableHeadings,
                        height: "40px",
                        fontSize: "10px",
                      }}
                    >
                      {optionsLocal && optionsLocal?.length ? (
                        optionsLocal.map((row, i) => {
                          if (row)
                            return (
                              <TableRow
                                key={i}
                                className={classes.tableBodyCell}
                              >
                                <TableCell className={classes.tableBodyCell}>
                                  {i + 1}
                                </TableCell>

                                <TableCell
                                  onClick={() => handleSelect(row)}
                                  align="left"
                                  style={{ cursor: "pointer" }}
                                  className={classes.tableBodyCell}
                                >
                                  {row.med_sort_name}
                                </TableCell>

                                <TableCell className={classes.tableBodyCell}>
                                  {row.med_dose}
                                </TableCell>
                                <TableCell className={classes.tableBodyCell}>
                                  {row.med_strength}
                                </TableCell>
                                <TableCell className={classes.tableBodyCell}>
                                  {row.med_type}
                                </TableCell>
                              </TableRow>
                            );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan="8">No Result Found</TableCell>
                        </TableRow>
                      )}
                    </TableDesign>
                  </TableWidget>
                </Grid>
              </>
            </Grid>
            </>)}
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
