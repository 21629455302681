import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  fixedDetails: {
    position: "-webkit-sticky",
    position: "sticky",
    top: "0",
    padding: "5px",
    backgroundColor: "#cae8ca",
    border: "2px solid #4CAF50",
  },
  formBox: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  avatar: {
    width: "64px",
    height: "64px",
  },
  uploadIcon: {
    position: "absolute",
    marginTop: "-36px",
    marginLeft: "36px",
  },
  formHeading: {
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    color: "#969DAB",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 400,
    textAlign: "left",
    flexGrow: 1,
    width: "40%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  formHeadingValue: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    color: "#2A2338",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 500,
    textAlign: "left",
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  txtForm: {
    padding: "0px 4px",
  },
  textGroup: {
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      display: "inline",
    },
  },
  modelFooter: {
    padding: theme.spacing(2),
  },
  blueButton: {
    background: "#0078D4",
    color: "#fff",
    fontWeight: 400,
    marginTop: theme.spacing(2),
    "&:hover": {
      background: "#0078D4",
      color: "#fff",
    },
  },
  gridBox: {
    // display:"flex",
    // justifyItems:"center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  btnGroup: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  rightButton: {
    marginLeft: theme.spacing(2),
  },
  widgetBox: {
    marginTop: theme.spacing(2),
  },
  passwordBox: {
    display: "flex",
    alignItems: "center",
  },

  AssignHeading: {
    marginBottom: theme.spacing(-2),
    marginTop: theme.spacing(2),
    fontFamily: "Poppins",
    fontWeight: 600,
    color: "#201F1E",
    fontSize: "16px",
    fontStyle: "normal",
  },
  icons: {
    marginRight: theme.spacing(2),
  },
  formLinkHeading: {
    fontFamily: "Poppins",
    fontWeight: 700,
    color: "#2A2338",
    fontSize: "12px",
    fontStyle: "normal",
  },
  formLinkHeadingValue: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4),
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#0445AF",
    fontSize: "15px",
    fontStyle: "normal",
  },
  cardHeading: {
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#2A2338",
    fontSize: "16px",
    fontStyle: "normal",
  },
  iconsBar: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: "#fff",
    borderBottomColor: "2px solid #201F1E",
  },
  tab: {
    color: "#201F1E",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    textTransform: "none",
    // "&:hover": {
    //   color: "#000",
    // },
    // "&:active": {
    //   color: "#4E97FD",
    // },
    "&.MuiTab-root.Mui-selected	": {
      color: "#4E97FD !important",
    },
    "&.MuiTabs-root.MuiTabs-indicator ":{
      backgroundColor: "#4E97FD !important",
  }
    // "&.Mui-selected":{
    //   color: "#fff",
    //   background:'#4E97FD',
    // }
  },
  iconsContainer: {
    background: "none",
    boxShadow: "none",
    // overflow: "hidden",
    paddingBottom: theme.spacing(2),
  },
  gridItem: {},
  // page title
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
    // marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      display: "inline-block",
    },
  },
  typo: {
    color: "#2A2338",
    fontWeight: 600,
    fontFamily: "Poppins",
    fontSize: "20px !important",
  },
  button: {
    boxShadow: theme.customShadows.widget,
    textTransform: "none",
    "&:active": {
      boxShadow: theme.customShadows.widgetWide,
    },
    height: "32px",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 500,
    borderRadius: "2px",
    padding: "6px, 20px, 6px, 20px",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },
  button1: {
    boxShadow: theme.customShadows.widget,
    textTransform: "none",
    "&:active": {
      boxShadow: theme.customShadows.widgetWide,
    },
    backgroundColor: "#3CCB97",
    color: "#fff",
    marginRight: theme.spacing(2),
    height: "32px",
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: 500,
    borderRadius: "4px",
    padding: "6px, 20px, 6px, 20px",
  },
  typoGray: {
    color: "#ABAFB7",
  },
  icons: {
    // width:'12px',
    // height:'44px'
    // marginTop:theme.spacing(2),
    // paddingTop:theme.spacing(2)
  },
  groupBtn: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "inline-grid",
    },
  },
  search: {
    position: "relative",
    borderRadius: "5px",
    border: "1px solid #0078D4",
    paddingLeft: theme.spacing(1.5),
    width: 48,
    // backgroundColor: fade(theme.palette.common.black, 0),
    transition: theme.transitions.create(["background-color", "width"]),
    "&:hover": {
      cursor: "pointer",
      background: "#fff",
    },
    "&:active": {
      border: "1px solid #0078D4",
    },
    "&:focus": {
      border: "1px solid #0078D4",
    },
    marginRight: theme.spacing(2),
    background: "#fff",
    height: "32px",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  searchFocused: {
    // backgroundColor: fade(theme.palette.common.black, 0.08),
    width: "100%",
    border: "1px solid #0078D4",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
    background: "#fff",
    "&:active": {
      border: "1px solid #0078D4",
    },
    "&:focus": {
      border: "1px solid #0078D4",
    },
  },
  searchIcon: {
    width: 36,
    // left: 0,
    color: "#0078D4",
    // border: "1px solid #0078D4",
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: theme.transitions.create("right"),
    "&:hover": {
      cursor: "pointer",
    },
    // backgroundColor:'#fff'
  },
  searchIconOpened: {
    right: theme.spacing(1.25),
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    // height: 36,

    paddingRight: 36 + theme.spacing(1.25),
    width: "100%",
    paddingLeft: "10px",
    fontSize: "14px",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  downloadImg: {
    marginRight: theme.spacing(1),
  },
  editicons: {
    marginRight: theme.spacing(0.5),
  },
  // page title
}));
