import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  Grid,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Select,
  FormControl,
  Checkbox,
  Button,
  Tabs,
  Tab,
  Paper,
  Fade,
} from "@material-ui/core";
// data

// Model
import { serviceFormElements } from "../inventory_constant";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useStyles from "./styles";
// components
import { SketchPicker } from "react-color";
import moment from "moment";
import PageTitle from "../../PageTitle/PageTitle";
import LocationList from "./LocationList";
import TransactionList from "./TransactionList";
import MaterialsList from "./MaterialsList";
import {
  currentUser,
  postFlagBooleanVariable,
  loadState,
} from "../../../hooks/helper";
import {
  postServiceLense,
  getProcedureCode,
  getManufacturerDetail,
  getProblemCode,
} from "../../../services/ServiceLayer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InventoryValidation from "../../../hooks/InventoryValidation";
import ManageProcedureCost from "./ManageProcedureCost";
export default function AddServices() {
  const history = useHistory();
  const { state } = useLocation();
  const selectedRow = state?.selectedRow;

  const eventType = state?.event;

  console.log("selectedRow", selectedRow);
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  const [serviceFrequencyValues, setServiceFrequencyValues] = useState([]);
  const [serviceTypeValues, setServiceTypeValues] = useState([]);
  const [listData, setListData] = useState([]);
  var classes = useStyles();
  var [activeTabId, setActiveTabId] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModel, setOpenModel] = React.useState(false);
  const [modelShow, setModalShow] = useState(false);
  const [actionType, setActionType] = useState("ADDSRV");
  const [profitValue, setProfitValue] = useState(0);
  const [grossPercentage, setGrossPercentage] = useState(0);
  const [prodecureCodeData, setProdecureCodeData] = useState([]);
  const [problemCodeData, setProblemCodeData] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [selectedProblem, setSelectedProblem] = useState(null);
  const [procedureCodeExtension, setProcedureCodeExtension] = useState("");
  const [manufacturerData, setManufacturerData] = useState([]);
  const [colorData, setColorData] = useState([]);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [inventoryReadValue, setInventoryReadValue] = useState();
  const [isActionExit, setIsActionExit] = useState(false);
  // color
  const [color, setColor] = useState("#000"); // Initial color, e.g., black
  const [showColorPicker, setShowColorPicker] = useState(false);
  // call meta data
  const readAccessData = loadState().menu;
  const metaData = loadState().metaData;
  useEffect(() => {
    console.log("readAccessData", readAccessData);
    const inventorySection = readAccessData.find(
      (item) => item.label === "Inventory"
    );
    if (inventorySection) {
      const inventoryChildren = inventorySection.children;

      const inventoryReadAcess = inventoryChildren.find(
        (child) => child.label === "Inventory"
      ).read_access;
      setInventoryReadValue(inventoryReadAcess);
    }
  }, [readAccessData]);

  useEffect(() => {
    console.log("inventoryReadValue", inventoryReadValue);
  }, [inventoryReadValue]);
  useEffect(() => {
    console.log("loadState", metaData);
    setListData(metaData);
  }, []);

  useEffect(() => {
    console.log("currentDate", currentDate);
  }, [currentDate]);

  useEffect(() => {
    if (listData.length > 0) {
      const serviceFrequencyValues = listData[0].ServiceFrequency;
      const serviceTypeValues = listData[0].serviceType;
      const colorData = listData[0].colorAndCode;
      setServiceFrequencyValues(serviceFrequencyValues);
      setServiceTypeValues(serviceTypeValues);
      setColorData(colorData);
    }
  }, [listData]);

  useEffect(() => {
    getProcedureCode(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELPRC&procedure_id=0&procedure_code=`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setProdecureCodeData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });

    getManufacturerDetail(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELMAN&manufacturer=0`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setManufacturerData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
    getProblemCode(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELPRB&problem_id=0&problem_name=`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setProblemCodeData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
  }, []);

  const [serviceLensDetail, setServiceLensDetail] = useState({
    service_id: "0",
    service_code_id: "",
    location_id: userData?.location_id,
    service_name: "",
    is_send_to_lab: "",
    is_print_order: "",
    service_code: "",
    service_code_extension: "",
    problem_code: "",
    service_type_id: "",
    service_duration: "",
    service_color: color,
    retail_price: "",
    item_cost: "",
    is_commission_prod: "",
    icd_code: "",
    problem_description: "",
    commission_amount: "0",
    spiff_amount: "",
    is_appointment_require: "",
    notes: " ",
    service_frequency: "",
    service_tax1: "",
    service_tax2: "",
    is_active: "Y",
    updated_by: currentUser(),

    access_location: " ",
    opt_type: actionType,
  });
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    InventoryValidation({
      service_name: serviceLensDetail?.service_name,
      // retail_price:serviceLensDetail?.retail_price,
      // item_cost:serviceLensDetail?.item_cost,
    });
  useEffect(() => {
    if (selectedRow) {
      console.log("selectedRow", selectedRow);
      setServiceLensDetail({
        service_id: eventType === "Copy" ? "0" : selectedRow?.service_id,
        location_id: userData?.location_id,
        service_name: selectedRow?.service_name,
        service_code_id: selectedRow?.service_code_id,
        is_send_to_lab: selectedRow?.is_send_to_lab,
        is_print_order: selectedRow?.is_print_order,
        service_code: selectedRow?.service_code,
        service_code_extension: selectedRow?.service_code_extension,
        service_duration: selectedRow?.service_duration,
        problem_code: selectedRow?.problem_code,
        problem_description: selectedRow?.problem_code_val,
        icd_code: selectedRow?.icd_code,
        service_color: selectedRow?.service_color,
        retail_price: selectedRow?.retail_price,
        item_cost: selectedRow?.item_cost,
        is_commission_prod: selectedRow?.is_commission_prod,
        is_appointment_require: selectedRow?.is_appointment_require,
        service_type_id: selectedRow?.service_type_id,
        commission_amount: selectedRow?.commission_amount,
        spiff_amount: selectedRow?.spiff_amount,
        notes: selectedRow?.notes,
        service_frequency: selectedRow?.service_frequency,
        service_tax1: selectedRow?.service_tax1,
        service_tax2: selectedRow?.service_tax2,
        is_active: selectedRow?.is_active,
        updated_by: currentUser(),

        access_location: "",
        opt_type: eventType === "Copy" ? "ADDSRV" : "UPDSRV",
      });
      setSelectedProcedure({
        procedure_name: selectedRow?.service_name,
        procedure_code: selectedRow?.service_code,
        service_code_extension: selectedRow?.service_code_extension,
      });
      setSelectedProblem({
        problem_name: selectedRow?.problem_code_val,
        problem_id: selectedRow?.problem_code,
        problem_description: selectedRow?.problem_code_val,
        icd_code: selectedRow?.icd_code,
      });
    }
  }, [selectedRow]);
  useEffect(() => {
    console.log("serviceLensDetail", serviceLensDetail);
  }, [serviceLensDetail]);
  useEffect(() => {
    console.log("selectedProcedure", selectedProcedure);
    console.log("selectedProblem", selectedProblem);
  }, [selectedProcedure, selectedProblem]);
  const clearForm = () => {
    // Define a function to clear the form fields
    setServiceLensDetail({
      service_id: "0",
      location_id: userData?.location_id,
      service_name: " ",
      is_send_to_lab: "",
      is_print_order: "",
      service_code: "",
      service_code_extension: "",
      problem_code: "",
      service_type_id: "",
      service_duration: "",
      service_color: color,
      retail_price: "",
      item_cost: "",
      is_commission_prod: "",

      commission_amount: "0",
      spiff_amount: "",
      is_appointment_require: "",
      icd_code: "",
      problem_description: "",
      notes: " ",
      service_frequency: "",
      service_tax1: "",
      service_tax2: "",
      updated_by: currentUser(),
      access_location: " ",
      opt_type: actionType,
    });
    setSelectedProcedure({
      service_code: "",
      service_code_extension: "",
    });
    setSelectedProblem({
      problem_id: "",
      problem_description: "",
      icd_code: "",
    });
  };

  useEffect(() => {
    const retailPrice = parseFloat(serviceLensDetail.retail_price) || 0;
    const itemCost = parseFloat(serviceLensDetail.item_cost) || 0;
    const commissionAmount =
      parseFloat(serviceLensDetail.commission_amount) || 0;

    const calculatedProfit = retailPrice - (itemCost + commissionAmount);
    setProfitValue(calculatedProfit);

    //
    const calculatedGrossPercentage = (commissionAmount / retailPrice) * 100;
    setGrossPercentage(calculatedGrossPercentage);
  }, [
    serviceLensDetail.retail_price,
    serviceLensDetail.item_cost,
    serviceLensDetail.commission_amount,
  ]);

  useEffect(() => {
    if (serviceLensDetail.is_commission_prod === "N") {
      setServiceLensDetail({
        ...serviceLensDetail,
        commission_amount: 0,
      });
    }
  }, [serviceLensDetail.is_commission_prod]);

  const handleCommissionTypeChange = (e) => {
    console.log("Commission Type Change Triggered");
    const newComissionType = e.target.value;
    console.log("New Commission Type:", newComissionType);

    setServiceLensDetail({
      ...serviceLensDetail,
      is_commission_prod: newComissionType,
      commission_amount: newComissionType === "Y" ? "" : "",
    });
  };
  const goBack = () => {
    setTimeout(() => {
      history.push("/app/service-type");
    }, 3000);
  };
  useEffect(() => {
    console.log("isActionCalled", isActionCalled);
    console.log("error", error);
    if (!isError() && error !== null && isActionCalled) {
      postServiceLense(serviceLensDetail).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Service added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          clearForm();
          if (isActionExit) {
            goBack();
          }
          setIsActionExit(false);
        } else {
          console.log();
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActionExit(false);
        }
      });
      setIsActionCalled(false);
    } else {
      console.log("error for validation");
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  const handleSaveAndMore = () => {
    console.log("serviceLensDetail", serviceLensDetail);
    checkValidator(); // Check for validation errors
    setIsActionCalled(true);
    // submitServiceLens(); // Save data
    // clearForm(); // Clear form fields
  };

  const handleSaveAndExit = () => {
    checkValidator();
    setIsActionCalled(true);

    setIsActionExit(true);
  };
  const handleCancel = () => {
    history.push("/app/service-type");
  };
  const addProcedure = () => {
    // console.log("add client called");
    setOpenModel(true);
    setAnchorEl(null);
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpenModel(false);
  };

  const toggleColorPicker = () => {
    setShowColorPicker(!showColorPicker);
  };

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
  };
  // useEffect(()=>{
  //   setServiceLensDetail({
  //     ...serviceLensDetail,
  //     service_color: color, // Store the color value in your data object
  //   });
  // },[color])
  return (
    <>
      <ManageProcedureCost
        open={openModel}
        title={"New Procedure"}
        onHide={hideHandle}
        setModalShow={setModalShow}
      />
      <ToastContainer />
      <PageTitle
        title="Manage Service"
        // subtitle="Inventory >"
        // urlLink="/app/inventory"
      />
      {/* <TableWidget noBodyPadding> */}
      <Grid
        container
        spacing={2}
        className={classes.formBox}
        style={{
          border: "1px solid #DED9D9",
          marginBottom: "16px",
          padding: "16px",
        }}
      >
        {serviceFormElements.map((element, i) => {
          const isHidden =
            (element.key === "item_cost" && inventoryReadValue === "Y") ||
            (element.key === "profit" && inventoryReadValue === "Y") ||
            (element.key === "is_commission_prod" &&
              inventoryReadValue === "Y") ||
            (element.key === "commission_amount" &&
              inventoryReadValue === "Y") ||
            (element.key === "gross_percentage" &&
              inventoryReadValue === "Y") ||
            (element.key === "service_tax1" && inventoryReadValue === "Y") ||
            (element.key === "service_tax2" && inventoryReadValue === "Y") ||
            (element.key === "spiff_amount" && inventoryReadValue === "Y");
          return (
            <>
              {!isHidden && (
                <Grid
                  item
                  xs={12}
                  md={element.mdSize}
                  className={`${classes.textGroup} ${
                    element.title ? classes.highlightedItem : ""
                  }`}
                  style={{ border: "1px solid #DED9D9" }}
                >
                  <Typography
                    className={classes.formHeading}
                    style={{ fontWeight: "500", color: "#4E97FD" }}
                  >
                    {element.label}{" "}
                    {element.required === "true" ? (
                      <span className={classes.required}>*</span>
                    ) : null}
                  </Typography>
                  {element.type ? (
                    <>
                      {element.type === "select" &&
                      element.key !== "service_name" &&
                      element.key !== "problem_description" ? (
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            disabled={inventoryReadValue === "Y"}
                            name={element.key}
                            className={classes.selectDropdown}
                            placeholder={element.placeHolder}
                            value={
                              serviceLensDetail &&
                              serviceLensDetail[element.key]
                                ? serviceLensDetail &&
                                  serviceLensDetail[element.key]
                                : ""
                            }
                            onChange={(e) => {
                              console.log("selected");
                              setServiceLensDetail({
                                ...serviceLensDetail,
                                [element.key]: e.target.value,
                              });
                            }}
                          >
                            <MenuItem disabled value="">
                              Select
                            </MenuItem>
                            {element.key === "service_frequency" &&
                            serviceFrequencyValues
                              ? serviceFrequencyValues.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "service_type_id" &&
                                serviceTypeValues
                              ? serviceTypeValues.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.options
                              ? element.options.map((item) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))
                                : element.key ==='service_color' && colorData ? colorData.map((item) => (
                                  <MenuItem
                                  key={item.attribute_id}
                                  value={item.attribute_id}
                                >
                                  {item.company_group_key}
                                </MenuItem>
                                ))
                              : element.optionsVL
                              && element.optionsVL.map((item) => (
                                  <MenuItem value={item.value}>
                                    {item.title}
                                  </MenuItem>
                                ))
                             }
                          </Select>
                        </FormControl>
                      ) : element.type === "select" &&
                        element.key === "service_name" ? (
                        <>
                          <Autocomplete
                            name={element.key}
                            options={prodecureCodeData} // Replace with your list of options
                            getOptionLabel={(option) => option.procedure_name} // Define how to display the options
                            value={
                              selectedProcedure
                                ? selectedProcedure
                                : serviceLensDetail[element.key]
                            }
                            // value={selectedProcedure ? selectedProcedure : serviceLensDetail?.procedure_code}
                            onChange={(event, newValue) => {
                              setSelectedProcedure(newValue); // Update the selected Service Code
                              // Update the Service Code extension
                              if (newValue) {
                                setProcedureCodeExtension(
                                  newValue.procedure_code_extension
                                );
                                setServiceLensDetail({
                                  ...serviceLensDetail,
                                  service_code_id: newValue.procedure_id,
                                  service_code: newValue.procedure_code,
                                  service_code_extension:
                                    newValue.procedure_code_extension,
                                  service_name: newValue.procedure_name,
                                  is_send_to_lab: newValue?.send_to_lab_flag,
                                  is_print_order: newValue?.print_on_order,

                                  service_duration:
                                    newValue?.appointmnet_duration,

                                  service_color: newValue?.color_code,
                                  retail_price: newValue?.retail_price,
                                  item_cost: newValue?.item_cost,

                                  is_appointment_require:
                                    newValue?.is_appointment_required,

                                  // notes: newValue?.notes,

                                  service_tax1: newValue?.taxid,
                                  service_tax2: newValue?.taxid2,
                                });
                              } else {
                                setProcedureCodeExtension("");
                              }
                              resetValidator(event.target.name);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                              />
                            )}
                            isInvalid={error && error[element.key]}
                          />
                          {error &&
                            !selectedProcedure &&
                            error[element.key] && (
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {error[element.key]}
                              </span>
                            )}
                        </>
                      ) : element.type === "select" &&
                        element.key === "problem_description" ? (
                        <>
                          <Autocomplete
                            options={problemCodeData} // Replace with your list of options
                            getOptionLabel={(option) => option.problem_name} // Define how to display the options
                            value={
                              selectedProblem
                                ? selectedProblem
                                : serviceLensDetail[element.key]
                            }
                            onChange={(event, newValue) => {
                              setSelectedProblem(newValue); // Update the selected Service Code
                              // Update the Service Code extension
                              if (newValue) {
                                setServiceLensDetail({
                                  ...serviceLensDetail,
                                  problem_code: newValue.problem_id,
                                  problem_description: newValue.problem_name,
                                  icd_code: newValue.icd_code,
                                });
                              } else {
                                setProcedureCodeExtension("");
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          />
                        </>
                      ) : element.type === "is_commission_prod" ? (
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            disabled={inventoryReadValue === "Y"}
                            name="is_commission_prod"
                            className={classes.selectDropdown}
                            placeholder="Select"
                            value={serviceLensDetail.is_commission_prod}
                            onChange={handleCommissionTypeChange} // Connect the function here
                          >
                            <MenuItem value="Y">Yes</MenuItem>
                            <MenuItem value="N">No</MenuItem>
                          </Select>
                        </FormControl>
                      ) : element.type === "text" ? (
                        <>
                          <TextField
                            required
                            name={element.key}
                            placeholder={element.placeHolder}
                            size="small"
                            className={classes.txtForm}
                            fullWidth
                            variant="outlined"
                            inputProps={{
                              style: {
                                height: "12px",
                                fontSize: "12px",
                              },
                              maxLength: element.maxLength,
                              onKeyPress: element.onKeyPress,

                              onPaste: element.onPaste,
                            }}
                            value={
                              element.key === "profit"
                                ? profitValue.toFixed(2)
                                : element.key === "gross_percentage"
                                ? grossPercentage.toFixed(2)
                                : serviceLensDetail &&
                                  serviceLensDetail[element.key]
                            }
                            onChange={(e) => {
                              setServiceLensDetail({
                                ...serviceLensDetail,
                                [element.key]: e.target.value,
                              });
                              resetValidator(e.target.name);
                            }}
                            isInvalid={error && error[element.key]}
                            disabled={
                              element.key === "profit" ||element.key ==='service_code' || element.key ==='problem_code'||element.key==='icd_code'||
                              element.key === "gross_percentage" ||
                              element.key === "service_code_extension" ||
                              inventoryReadValue === "Y"
                            }
                          />
                          <Fade in={error}>
                            <Typography
                              color="secondary"
                              className={classes.errorMessage}
                            >
                              {error && error[element.key]}
                            </Typography>
                          </Fade>
                        </>
                      ) : element.type === "commission_amount" ? (
                        <TextField
                          type="text"
                          className={classes.txtForm}
                          disabled={
                            serviceLensDetail.is_commission_prod !== "Y" ||
                            inventoryReadValue === "Y"
                          } // Enable only when is_commission_prod is "yes"
                          value={serviceLensDetail.commission_amount}
                          fullWidth
                          variant="outlined"
                          size="small"
                          inputProps={{
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                            maxLength: element.maxLength,
                            onKeyPress: element.onKeyPress,
                          }}
                          onChange={(e) => {
                            const value = parseFloat(e.target.value);
                            setServiceLensDetail({
                              ...serviceLensDetail,
                              commission_amount: isNaN(value) ? "" : value,
                            });
                          }}
                        />
                      ) : element.type === "date" ||
                        element.type === "time" ||
                        element.type === "datetime-local" ? (
                        <TextField
                          id={element.key}
                          name={element.key}
                          placeholder={element.placeHolder}
                          type={element.type}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            min: `${currentDateTime}`,
                            max: `${currentDate}`,
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={
                            serviceLensDetail && serviceLensDetail[element.key]
                          }
                          onChange={(e) => {
                            setServiceLensDetail({
                              ...serviceLensDetail,
                              [element.key]: e.target.value,
                            });
                          }}
                          disabled={inventoryReadValue === "Y"}
                        />
                      ) : element.type === "radio" ? (
                        <RadioGroup row defaultValue="">
                          {element.radioValue.map((item) => (
                            <FormControlLabel
                              size="small"
                              style={{ padding: "0px 6px" }}
                              value={item}
                              control={<Radio />}
                              label={item}
                              className={classes.smallRadioButton}
                            />
                          ))}
                        </RadioGroup>
                      ) : element.type === "checkbox" ? (
                        <>
                          <Checkbox
                            name={element.key}
                            checked={
                              serviceLensDetail[element.key] !== "N"
                                ? serviceLensDetail[element.key]
                                : false
                            }
                            onChange={(e) => {
                              const value = postFlagBooleanVariable(
                                e.target.checked
                              );
                              setServiceLensDetail({
                                ...serviceLensDetail,
                                [element.key]: value,
                              });
                            }}
                          />
                        </>
                      ) : element.type === "color" ? (
                        <div style={{ display: "flex" }}>
                          <button
                            style={{ height: "32px" }}
                            onClick={toggleColorPicker}
                          >
                            Pick a Color
                          </button>
                          {showColorPicker && (
                            <div
                              style={{
                                position: "absolute",
                                zIndex: "2",
                                marginTop: "36px",
                              }}
                            >
                              <SketchPicker
                                color={color}
                                onChange={handleColorChange}
                              />
                            </div>
                          )}
                          <div
                            style={{
                              width: "34px",
                              height: "34px",
                              backgroundColor: color,
                              marginLeft: "10px",
                            }}
                          ></div>
                        </div>
                      ) : (
                        <TextField
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          placeholder="Enter notes upto 1000 character"
                          variant="outlined"
                          multiline
                          rows={3}
                          inputProps={{
                            style: {
                              fontSize: "12px",
                            },
                            maxLength: element.maxLength,
                          }}
                          value={
                            serviceLensDetail && serviceLensDetail[element.key]
                          }
                          onChange={(e) => {
                            setServiceLensDetail({
                              ...serviceLensDetail,
                              [element.key]: e.target.value,
                            });
                          }}
                          disabled={inventoryReadValue === "Y"}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <Typography
                        className={classes.formHeading}
                        style={{
                          fontWeight: "600",

                          color: "#25282B",
                        }}
                      >
                        {element.title}
                      </Typography>
                    </>
                  )}
                </Grid>
              )}
            </>
          );
        })}
      </Grid>
      <div
        className={classes.footerBox}
        style={{ justifyContent: "space-between" }}
      >
        {inventoryReadValue === "N" && (
          <div>
            {/* <Button
              variant="outlined"
              className={classes.leftButton}
              style={{ marginRight: "12px" }}
              onClick={clearForm}
            >
              clear
            </Button> */}
            {/* <Button
              variant="outlined"
              className={classes.leftButton}
              style={{ marginRight: "12px" }}
              onClick={addProcedure}
            >
              Add Service Code
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.leftButton}
              style={{ marginRight: "12px" }}
              onClick={handleCancel}
            >
              Add Problem Code
            </Button> */}
          </div>
        )}
        <div>
          <Button
            variant="outlined"
            className={classes.leftButton}
            style={{ marginRight: "12px" }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          {inventoryReadValue === "N" && (
            <>
              <Button
                variant="contained"
                color="secondary"
                className={classes.rightButton}
                onClick={handleSaveAndExit}
                style={{ marginRight: "12px" }}
              >
                save & Exit
              </Button>
              {!selectedRow && (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.rightButton}
                  onClick={handleSaveAndMore}
                >
                  save & More
                </Button>
              )}
            </>
          )}
        </div>
      </div>

      {/* {selectedRow && (
        <>
          <Paper className={classes.iconsContainer}>
            <Tabs TabIndicatorProps={{
            style: { background: "#4E97FD",width:"150px"},
          }}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              value={activeTabId}
              onChange={(e, id) => setActiveTabId(id)}
              className={classes.iconsBar}
            >
              <Tab label="Locations" classes={{ root: classes.tab }} />
              <Tab label="Transactions" classes={{ root: classes.tab }} />
            </Tabs>
          </Paper>
          {activeTabId === 0 && <LocationList />}
          {activeTabId === 1 && <TransactionList />}
          {activeTabId === 2 && <MaterialsList />}
        </>
      )} */}
    </>
  );
}
