import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  Select,
} from "@material-ui/core";

import { useHistory } from "react-router-dom";

// components
import { loadState } from "../../hooks/helper";
import useStyles from "../../components/Patient/Detail/styles";
import TableWidget from "../../components/Widget/TableWidget";
import TableDesign from "../../components/common/table/TableDesign";

import { toast } from "react-toastify";

import IconButton from "@material-ui/core/IconButton";


import {
  MoreVert as MoreIcon,
  Search as SearchIcon,
  Lens,
} from "@material-ui/icons";

import { getMetaDataList } from "../../services/ServiceLayer";
import DeleteMetaDataPopup from "./DeleteMetaDataPopup";

export default function InvtOrderHistory() {
  const history = useHistory();

  const userRole = loadState().user?.user_role;
  var classes = useStyles();

  const [selectMetaDataList, setSelectMetaDataList] = useState([]);
  const [selectMetaDataListMain, setSelectMetaDataListMain] = useState([]);

  const [selectedFilter, setSelectedFilter] = useState("");
  const userData = loadState().user;

  // table Header
  const tableHeadings = [
    {
      label: "#",
      align: "left !important",
    },
    {
      label: "Group Key",
      align: "left !important",
    },
    {
      label: "Group Name",
      align: "left !important",
    },

    {
      label: "Sub Group Key",
      align: "left !important",
    },
    {
      label: "Sub Group Name",
      align: "left !important",
    },
    {
      label: "Attribute Key",
      align: "left !important",
    },
    {
      label: "Attribute",
      align: "left !important",
    },
    {
      label: "Sort Order",
      align: "left !important",
    },
    {
      label: "Action",
      align: "left !important",
    },
  ];

  //
  const [refreshList, setRefreshList] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const [selectRow, setSelectRow] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const createUpdateClient = (row) => {
    console.log("add client called");
    console.log("row", row);
    // const rowDataString = encodeURIComponent(JSON.stringify(row));
    // history.push(`/app/add-metadata/${rowDataString}`);
    history.push(`/app/add-metadata/${row.grp_meta_name}`, {
      row,
    });
  };
  const addMetaData = () => {
    history.push(`/app/add-metadata`);
  };
  const [open, setOpen] = React.useState(false);
  const [filterData, setFilterData] = useState([]);
  toast.configure();

  useEffect(() => {
    getMetaDataList(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELMET`
    ).then((response) => {
      if (response?.status?.status) {
        const data = response?.data;
        // setResources(resource);
        const unique = [...new Set(data.map((item) => item.grp_meta_key))];
        setFilterData(unique);
        const firstMetaData = data.filter(
          (item) => item.grp_meta_key === unique[0]
        );
        setSelectMetaDataList(firstMetaData);
        setSelectMetaDataListMain(data);
        setSelectedFilter(unique[0]);
      } else {
        toast.error("Something went wrong Please try again letter", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  }, []);

  const hideHandle = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (selectedFilter) {
      setSelectMetaDataList(
        selectMetaDataListMain.filter(
          (item) => item.grp_meta_key === selectedFilter
        )
      );
    }
  }, [selectedFilter]);
  const [selectclient, setselectclient] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const deleteEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);
    setselectclient(rec);
    setDeleteModalShow(true);
    // setTitle("Delete Spectacle lens");
  };
  return (
    <>
      <DeleteMetaDataPopup
        onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        selectclient={selectclient}
        setDeleteModalShow={setDeleteModalShow}
        setRefreshList={setRefreshList}
      />
      <div className={classes.pageTitleContainer}>
        <div>
          <Select
            style={{
              border: "1px solid #0078D4",
              padding: "2px 6px",
              width: "160px",
              borderRadius: "5px",
            }}
            value={selectedFilter}
            onChange={(e) => setSelectedFilter(e.target.value)}
            displayEmpty
            className={classes.select}
            inputProps={{ "aria-label": "Filter by" }}
          >
            <MenuItem value="" disabled>
              Filter by
            </MenuItem>
            {filterData.map((row) => {
              return <MenuItem value={row}>{row}</MenuItem>;
            })}
          </Select>
        </div>
        <div className={classes.groupBtn} style={{ float: "right" }}>
          <Button
            classes={{ root: classes.button }}
            variant="contained"
            color="secondary"
            onClick={addMetaData}
          >
            {/* <img className={classes.editicons} src={editIcons} /> */}
            Add New Meta Data
          </Button>
        </div>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {selectMetaDataList && selectMetaDataList.length ? (
                selectMetaDataList.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                        >
                          {row.seq_id}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.grp_meta_key}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.grp_meta_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                        >
                          {row.grpsub_meta_key}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.grpsub_meta_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.grp_attribute_key}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.grp_attribute_val}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.meta_sort_order}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          <IconButton
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                          >
                            <MenuItem style={{fontSize:'12px'}}
                              className={classes.menuItem}
                              onClick={() => createUpdateClient(selectRow)}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              Update
                            </MenuItem>
                            <MenuItem style={{fontSize:'12px'}}
                              className={classes.menuItem}
                              onClick={() => deleteEventHandler(selectRow)}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
