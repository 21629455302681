import React, { useState, useEffect } from "react";
import { Button, Grid, Menu, MenuItem } from "@material-ui/core";

import { dateFormatConversion } from "../../../hooks/helper";

import TableWidget from "../../Widget/TableWidget";

import TableDesign from "../../common/table/TableDesign";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
// styles
import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";

import useStyles from "../styles";

import ManageFinalContact from "./ManageFinalContact";

export default function FinalContact({
  frxListData,
  setContactFinalDetails,
  contactFinalDetails,manufacturerData,supplierData
}) {
  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Exam Name",
      align: "left",
    },
    {
      label: "OD",
      align: "left",
    },
    {
      label: "Sph",
      align: "left",
    },
    {
      label: "Cyl",
      align: "left",
    },
    {
      label: "Axis",
      align: "left",
    },
    {
      label: "Add",
      align: "left",
    },

    {
      label: "DVA",
      align: "left",
    },
    {
      label: "NVA",
      align: "left",
    },

    {
      label: "Action",
    },
  ];
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const [modalShow, setModalShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectRow, setSelectRow] = useState(null);
  const [title, setTitle] = useState(null);
  const [patientAttachmentData, setPatientAttachmentData] = useState([]);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const addRefractiveModel = () => {
    setOpen(true);
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    setModalShow(false);
    setOpen(false);
    // setDataUpdated(true);
    // setselectclient(null);
  };
  return (
    <>
      <ManageFinalContact
        open={open}
        onHide={hideHandle}
        title={title ? title : "Final Contact Lens"}
        setTitle={setTitle}
        // selectclient={selectclient}
        setModalShow={setModalShow}
        setContactFinalDetails={setContactFinalDetails}
        contactFinalDetails={contactFinalDetails}
        supplierData={supplierData}
        manufacturerData={manufacturerData}

      />
      <Grid container spacing={4} style={{ width: "100%" }}>
        <Grid>
          {" "}
          <div
            className={classes.pageTitleContainer}
            style={{ display: "flex", justifyContent: "end" }}
          >
            <Button
              style={{
                marginRight: "8px",
                fontSize: "10px",
                position: "absolute",
                borderRadius: "2px",
                right: "60px",
                background: "#4E97FD",
              }}
              classes={{ root: classes.button }}
              variant="contained"
              color="secondary"
              onClick={addRefractiveModel}
            >
              Add Final Contact
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {frxListData && frxListData?.length > 0 ? (
                frxListData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.cnt_final}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          OD
                          <br />
                          <span style={{ color: "#0586FB" }}>(OS)</span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row?.od_sph}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            ({row?.os_sph})
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row?.od_cyl}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            ( {row?.os_cyl})
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row?.od_axis}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            ( {row?.os_axis})
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row?.od_add}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            ({row?.os_add})
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row?.od_dva}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            ({row?.os_dva})
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row?.od_nva}
                          <br />
                          <span style={{ color: "#0586FB" }}>
                            ({row?.os_nva})
                          </span>
                        </TableCell>

                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            id="basic-button"
                            aria-controls={openDown ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openDown ? "true" : undefined}
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            {/* <MenuItem onClick={()=>handleEdit(selectRow)}>Edit</MenuItem>

                            <MenuItem onClick={() => deleteEventHandler(selectRow)}>Delete</MenuItem> */}
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
