import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TableRow,
  TableCell,
  Typography,
  MenuItem,
  Menu,
  FormControl,
  Select,
  TextField,
} from "@material-ui/core";

import { useHistory } from "react-router-dom";

// components

import {
  getDoctorList,
  getExamList,
  getLocationAndResource,
  todaysScheduleWithDate,
} from "../../services/ServiceLayer";
import { loadState } from "../../hooks/helper";
import useStyles from "../../components/Patient/Detail/styles";
import TableWidget from "../../components/Widget/TableWidget";
import TableDesign from "../../components/common/table/TableDesign";
import moment from "moment";

import { toast } from "react-toastify";

import IconButton from "@material-ui/core/IconButton";

import {
  MoreVert as MoreIcon,
  Search as SearchIcon,
  Lens,
} from "@material-ui/icons";

import ManageAppointment from "./ManageAppointment";
import useListData from "../common/allMetaData/useListData";

export default function ExamListData() {
  const history = useHistory();
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  const userData = loadState().user;
  const userRole = loadState().user?.user_role;
  var classes = useStyles();
  // table Header
  const tableHeadings = [
    {
      label: "Patient Id",
      secondaryLabel: "(Title)",
      align: "left !important",
    },
    {
      label: "First Name",
      secondaryLabel: "(Middle Name)",
      align: "left !important",
    },
    {
      label: "Last Name",
      secondaryLabel: "(Suffix)",
      align: "left !important",
    },

    {
      label: "DOB",
      secondaryLabel: "(Phone No.)",
      align: "left !important",
    },
    {
      label: "Exam Name",
      secondaryLabel: "(Exam Type)",
      align: "left !important",
    },
    {
      label: "Exam Status",
      secondaryLabel: "(Appointment Status)",
      align: "left !important",
    },
    {
      label: "Date of Exam",
      secondaryLabel: "(Time of Exam)",
      align: "left !important",
    },

    {
      label: "Physician",
      align: "left !important",
    },
    {
      label: "Action",
      align: "left !important",
    },
  ];

  const [open, setOpen] = React.useState(false);
  const [selectRow, setSelectRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const openDown = Boolean(anchorEl);
  toast.configure();

  const [orderList, setOrderList] = useState([]);

  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [examFrom, setExamFrom] = useState(moment().format("YYYY-MM-DD"));
  const [examTo, setExamTo] = useState(moment().format("YYYY-MM-DD"));
  const [dob, setDob] = useState("");
  const [lastName, setLastName] = useState("");
  const [contact, setContact] = useState("");
  const [examStatus, setExamStatus] = useState("");
  const [examProvider, setExamProvider] = useState("");
  const [patientData, setPatientData] = useState([]);
  console.log("userData", userData);
  const listData = useListData();
  const { patientStatusValues, examListStatus } = listData;
  useEffect(() => {
    getExamList(
      `location_id=${userData?.location_id}&last_name=${lastName}&contact_no=${contact}&dob=${dob}&exam_from_dt=${examFrom}&exam_to_dt=${examTo}&exam_status=${examStatus}&physician_id=${examProvider}&user_role=${userRole}&opt_type=SELVAC`
    ).then((response) => {
      console.log(response);
      setOrderList(response.data);
    });
  }, [dob, examFrom, examTo, lastName, contact, examStatus, examProvider]);
  useEffect(() => {
    todaysScheduleWithDate(
      `staff_id=${userData?.user_id}&location_id=${userData?.location_id}&start_date=${startDate}&end_date=${endDate}&user_role=${userRole}&opt_type=SELSCH`
    ).then((response) => {
      console.log(response);

      setPatientData(response.data);

      toast.error(response);
    });
  }, [startDate, endDate]);

  const hideHandle = () => {
    setOpen(false);
    setDeleteModalShow(false);
  };
  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    console.log("row", row);
    setSelectRow(row);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEditClick = (selectedRow) => {
    if (selectedRow) {
      console.log("selectedRow-onclick", selectedRow);
      // Use React Router to navigate to the edit page
      history.push(`/app/order-details/${selectedRow.patient_id}`, {
        selectedRow,
      });
    }
  };
  const serachFormElement = [
    {
      key: "first_name",
      label: "Enter First Name",
      placeholder: "Enter First Name",
      type: "text",
    },
    {
      key: "last_name",
      label: "Enter Last Name",
      placeholder: "Enter Last Name",
      type: "text",
    },
    {
      key: "mobile",
      label: "Enter Contact",
      placeholder: "Enter contact",
      type: "text",
    },
    {
      key: "dob",
      label: "Enter Date of Birth",
      placeholder: "select",
      type: "date",
    },
    {
      key: "provider",
      label: "Select Physician",
      placeholder: "select",
      type: "select",
      options: [],
    },
    {
      key: "from_date",
      label: "From Date",
      placeholder: "select",
      type: "date",
    },
    {
      key: "to_date",
      label: "To Date",
      placeholder: "select",
      type: "date",
    },
    {
      key: "exam_status",
      label: "Exam Status",
      placeholder: "select",
      type: "select",
      options: [],
    },
  ];

  const [doctorList, setDoctorList] = useState([]);
  const [selectPhyscianData, setSelectPhyscianData] = useState([]);
  useEffect(() => {
    getDoctorList(
      `user_id=${userData?.user_id}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSCH`
    ).then((response) => {
      console.log(response);
      setDoctorList(response?.data);
    });
    getLocationAndResource(
      `location_id=${userData.location_id}&&user_id=${userData.user_id}&user_role=${userData.user_role}&opt_type=SELSCH`
    ).then((response) => {
      console.log("response", response);

      const locationInfo = response?.data?.filter(
        (location) => location.location_id === `${userData.location_id}`
      );
      console.log("locationInfo=====???");

      console.log(locationInfo);

      if (locationInfo.length > 0) {
        console.log("locationInfo", locationInfo);
        setSelectPhyscianData(locationInfo[0]?.doc_info);
        setExamProvider(locationInfo[0]?.doc_info[0]?.doc_id);
      }
    });
  }, []);
  const showAppointment = () => {
    setOpen(true);
  };
  return (
    <>
      <ManageAppointment
        patientData={patientData}
        open={open}
        onHide={hideHandle}
      />
      <div className={classes.pageTitleContainer}>
        <Typography className={classes.typo} weight="medium">
          Exam
        </Typography>

        <div className={classes.groupBtn}>
          <Button
            classes={{ root: classes.button }}
            variant="contained"
            color="secondary"
            onClick={showAppointment}
          >
            {/* <img className={classes.editicons} src={editIcons} /> */}
            Today's Appointment
          </Button>
        </div>
      </div>
      {/* <div className={classes.pageTitleContainer}> */}
      {/* <div className={classes.groupBtn}> */}
      <div className={classes.groupBtn}>
        <Grid
          container
          spacing={{ xs: 6, md: 4 }}
        >
          {serachFormElement.map((element, i) => {
            return (
              <>
                {element.type === "select" ? (
                  <Grid item xs={6} sm={4} md={3} style={{ paddingBottom: "25px" }} >
                    <FormControl
                      fullWidth
                      style={{ width :"240px"}}
                      className={classes.dropdownFormControl}
                    >
                      <Select
                        type={element.type}
                        placeholder={element.placeholder}
                        style={{
                          width: "100%",
                          border: "1px solid #C2C2C2",
                          borderRadius: "5px",
                        }}
                        className={classes.selectDropdown}
                        onChange={async (e) => {
                          if (element.key === "provider") {
                            setExamProvider(e.target.value);
                          } else if (element.key === "exam_status") {
                            setExamStatus(e.target.value);
                          } else {
                          }
                        }}
                        value={element.value}
                      >
                        <MenuItem selected disabled>
                          Select
                        </MenuItem>
                        {element.key === "location" && element.options
                          ? element.options.map((item) => (
                              <MenuItem value={item?.location_id}>
                                {item?.location_name}
                              </MenuItem>
                            ))
                          : element.key === "provider" && selectPhyscianData
                          ? selectPhyscianData.map((item) => (
                              <MenuItem value={item?.doc_id}>
                                {item?.doc_name}
                              </MenuItem>
                            ))
                          : element.key === "exam_status" && examListStatus
                          ? examListStatus?.map((item) => (
                              <MenuItem
                                key={item.attribute_id}
                                value={item.attribute_id}
                              >
                                {item.attribute_val}
                              </MenuItem>
                            ))
                          : element.options.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : //  second box
                element.type === "date" ? (
                  <Grid item xs={6} sm={4} md={3} style={{ paddingBottom: "25px" }}>
                    <TextField
                      style={{ width: "240px" }}
                      id={element.key}
                      name={element.key}
                      placeholder={element.placeholder}
                      type={element.type}
                      label={element.label}
                      value={
                        element?.key === "from_date"
                          ? examFrom
                          : element?.key === "to_date"
                          ? examTo
                          : ""
                      }
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      inputProps={{
                        min: `${currentDateTime}`,
                        max: `${currentDate}`,
                        style: {
                          height: "12px",
                          fontSize: "10px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        console.log("selected", e.target.value);
                        if (element?.key === "from_date") {
                          setExamFrom(e.target.value);
                        } else if (element?.key === "to_date") {
                          setExamTo(e.target.value);
                        } else if (element?.key === "dob") {
                          setDob(e.target.value);
                        } else {
                        }
                      }}
                    />
                  </Grid>
                ) : (
                  // third box
                  <Grid item xs={6} sm={4} md={3} style={{ paddingBottom: "25px" }}>
                    <TextField
                      style={{ width: "240px" }}
                      type={element.type}
                      name={element.key}
                      placeholder={element.placeholder}
                      label={element.label}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      autoFocus
                      inputProps={{
                        style: {
                          height: "12px",
                          fontSize: "10px",
                        },

                        maxLength: element.maxLength,

                        onKeyPress: element.onKeyPress, // Add the event handler
                        onPaste: element.onPaste,
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      onChange={async (e) => {
                        if (element.key === "last_name") {
                          setLastName(e.target.value);
                        } else if (element.key === "mobile") {
                          setContact(e.target.value);
                        } else {
                        }
                      }}
                    />
                  </Grid>
                )}
              </>
            );
          })}
        </Grid>

        {/* <Button
            classes={{ root: classes.button }}
            variant="contained"
            color="secondary"
            style={{ marginRight: "4px" }}
            // onClick={handleSearchSchedule}
          >
            Search
          </Button> */}
      </div>
      {/* </div> */}
      {/* {isLoader && <Loader />} */}
      <Grid container spacing={4}>
        <Grid item xs={12} style={{ paddingTop: "80px" }}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {orderList && orderList.length > 0 ? (
                orderList.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                        >
                          {row?.patient_id} <br />
                          {row?.title}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.first_name}
                          <br /> {row.middle_name}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.last_name} <br />
                          {row.email}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                        >
                          {moment(row?.dateofbirth).utc().format("DD-MM-YYYY")}{" "}
                          <br />
                          {row.contact_cell}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.exam_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row?.exam_status_val} <br />
                          {row.appointement_status === "Scheduled" ? (
                            <>
                              <span
                                style={{
                                  background: "orange",
                                  borderRadius: "5px",
                                  padding: "4px 10px",
                                  color: "white",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Scheduled
                              </span>
                            </>
                          ) : row?.appointement_status === "Rescheduled" ? (
                            <>
                              <span
                                style={{
                                  background: "orange",
                                  borderRadius: "5px",
                                  padding: "4px 10px",
                                  color: "white",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Rescheduled
                              </span>
                            </>
                          ) : row?.appointement_status === "Canceled" ? (
                            <>
                              <span
                                style={{
                                  background: "red",
                                  borderRadius: "5px",
                                  padding: "4px 10px",
                                  color: "white",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Canceled
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                style={{
                                  background: "green",
                                  borderRadius: "5px",
                                  padding: "4px 10px",
                                  color: "white",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {row?.appointement_status}
                              </span>
                            </>
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {moment(row.start_time).utc().format("DD-MM-YYYY")}
                          <br />
                          {moment(row.start_time).utc().format("LT")}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row?.physician_name} <br />
                          {row.exam_status_val === "Not Started" ? (
                            <>
                              <span
                                style={{
                                  background: "orange",
                                  borderRadius: "5px",
                                  padding: "4px 10px",
                                  color: "white",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Not Started
                              </span>
                            </>
                          ) : row?.exam_status_val === "In Progress" ? (
                            <>
                              <span
                                style={{
                                  background: "orange",
                                  borderRadius: "5px",
                                  padding: "4px 10px",
                                  color: "white",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                In Progress
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                style={{
                                  background: "green",
                                  borderRadius: "5px",
                                  padding: "4px 10px",
                                  color: "white",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Complete
                              </span>
                            </>
                          )}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          <IconButton
                            onClick={(e) => {
                              handleClick(e, row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => handleEditClick(selectRow)}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />
                              View/Update
                            </MenuItem>
                            <MenuItem
                              className={classes.menuItem}
                              // onClick={() => deleteEventHandler(selectRow)}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />
                              Cancel
                            </MenuItem>
                            <MenuItem
                              className={classes.menuItem}
                              // onClick={() => handleEditClick(row)}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />
                              Re-Order
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
