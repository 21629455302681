import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";

import moment from "moment";
import { formEmployeElements } from "./Patient_Constant";

import useStyles from "../styles";
import { getCityStateByZip } from "../../../../services/ServiceLayer";
export default function Employee({ patientOtherData, setPatientOtherData }) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();
 
  useEffect(() => {
    // Function to fetch city and state based on zip and update the respective fields
    const fetchAndUpdateCityState = (zipCode, type) => {
      if (!zipCode || zipCode.length < 5) {
        // If zip code is invalid, clear the city and state
        setPatientOtherData((prevData) => ({
          ...prevData,
          [`${type}_city`]: "",
          [`${type}_state`]: "",
        }));
        return;
      }

      getCityStateByZip(`zip=${zipCode}`)
        .then((response) => {
          if (
            response?.status.description === "" &&
            response?.data.length > 0
          ) {
            setPatientOtherData((prevData) => ({
              ...prevData,
              [`${type}_city`]: response.data[0].city,
              [`${type}_state`]: response.data[0].state_id,
            }));
          }
        })
        .catch((error) => {
          console.error(`Error fetching city and state for ${type}:`, error);
          // Optionally handle error, e.g., by setting an error message in state
        });
    };

    // Validate and fetch/update for each zip code
    const zipTypes = ["employer", "guarantor", "referral","guardian","guardian1"];
    zipTypes.forEach((type) => {
      const zipCode = patientOtherData?.[`${type}_zip`];
      fetchAndUpdateCityState(zipCode, type);
    });
  }, [
    patientOtherData?.employer_zip,
    patientOtherData?.guarantor_zip,
    patientOtherData?.referral_zip,
    patientOtherData?.guardian_zip,
    patientOtherData?.guardian1_zip
  ]);

  return (
    <Grid container spacing={2} className={classes.formBox}>
      {formEmployeElements.map((element, i) => {
        return (
          <>
            <Grid
              item
              xs={12}
              lg={2}
              sm={12}
              md={element.mdSize}
              className={classes.textGroup}
            >
              <Typography className={classes.formHeading}>
                {element.label}{" "}
                {element.required === "true" ? (
                  <span className={classes.required}>*</span>
                ) : null}
              </Typography>

              {element.type === "select" ? (
                <FormControl fullWidth className={classes.dropdownFormControl}>
                  <Select
                    name={element.key}
                    style={{ fontSize: "12px" }}
                    className={classes.selectDropdown}
                    placeholder={element.placeHolder}
                    value={
                      patientOtherData && patientOtherData[element.key] === null
                        ? ""
                        : patientOtherData && patientOtherData[element.key]
                    }
                    onChange={async (e) => {
                      setPatientOtherData({
                        ...patientOtherData,
                        [element.key]: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {element.options.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : element.type === "textarea" ? (
                <TextField
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder="Type here..."
                  variant="outlined"
                  multiline
                  rows={3}
                  inputProps={{
                    style: {
                      fontSize: "12px",
                    },
                    maxLength: `${element.maxLength}`,
                  }}
                  value={
                    patientOtherData && patientOtherData[element.key] === null
                      ? ""
                      : patientOtherData && patientOtherData[element.key]
                  }
                  onChange={async (e) => {
                    setPatientOtherData({
                      ...patientOtherData,
                      [element.key]: e.target.value,
                    });
                  }}
                />
              ) : element.type === "date" ||
                element.type === "datetime-local" ? (
                <TextField
                  id={element.key}
                  name={element.key}
                  placeholder={element.placeHolder}
                  type={element.type}
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    min: `${currentDateTime}`,
                    max: `${currentDate}`,
                    style: {
                      height: "12px",
                      fontSize: "12px",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={
                    patientOtherData && patientOtherData[element.key] === null
                      ? ""
                      : patientOtherData && patientOtherData[element.key]
                  }
                  onChange={async (e) => {
                    setPatientOtherData({
                      ...patientOtherData,
                      [element.key]: e.target.value,
                    });
                  }}
                  onKeyDown={(e) => {
                    e.preventDefault(); // Prevent manual typing
                  }}
                />
              ) : (
                <TextField
                  required
                  name={element.key}
                  placeholder={element.placeHolder}
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  type={element.type}
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "12px",
                      fontSize: "12px",
                    },
                    maxLength: `${element.maxLength}`,
                    onKeyPress: element.onKeyPress, // Add the event handler
                  }}
                  value={
                    patientOtherData && patientOtherData[element.key] === null
                      ? ""
                      : patientOtherData && patientOtherData[element.key]
                  }
                  onChange={async (e) => {
                    setPatientOtherData({
                      ...patientOtherData,
                      [element.key]: e.target.value,
                    });
                  }}
                />
              )}
            </Grid>
          </>
        );
      })}
    </Grid>
  );
}
