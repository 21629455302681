import React, { useEffect, useState } from "react";
import { Grid, TableRow, TableCell } from "@material-ui/core";

import { getCompanyLocationByCompId } from "../../../services/ServiceLayer";

import useStyles from "../../../components/Patient/Detail/styles";
import TableWidget from "../../../components/Widget/TableWidget";
import TableDesign from "../../../components/common/table/TableDesign";

import { toast } from "react-toastify";

export default function LocationList() {
  var classes = useStyles();

  // table Header
  const tableHeadings = [
    {
      label: "Location Name",
      align: "left !important",
    },
    {
      label: "Location Alias",
      align: "left !important",
    },

    {
      label: "Contact",
      align: "left !important",
    },
    {
      label: "Email",
      align: "left !important",
    },

    {
      label: "City",
      align: "left !important",
    },
  ];

  const [dataUpdated, setDataUpdated] = useState(false);
  toast.configure();

  const [selectCompanyDetails, setSelectCompanyDetails] = useState([]);

  useEffect(() => {
    getCompanyLocationByCompId(`comp_id=1`).then((response) => {
      console.log("response", response);
      console.log("response?.data", response?.data);
      console.log(response?.status);
      if (response?.status?.status) {
        const data = response?.data;
        // setResources(resource);
        setSelectCompanyDetails(data);
      } else {
        toast.error("Something went wrong Please try again letter", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  }, []);
  useEffect(() => {
    getCompanyLocationByCompId(`comp_id=1`).then((response) => {
      console.log("response", response);
      console.log(response?.status);
      if (response?.status?.status) {
        const data = response.data;
        // setResources(resource);
        setSelectCompanyDetails(data);
        setDataUpdated(false);
      }
    });
  }, [dataUpdated]);
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {selectCompanyDetails && selectCompanyDetails.length ? (
                selectCompanyDetails.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                        >
                          {row.comp_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.comp_alias}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.corp_phone}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                        >
                          {row.email_id}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.city}
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
