import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,

  Switch,
  Fade,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../common/modal/Popup";
// styles
import useStyles from "./styles";
import { formProblemElements } from "./Exam_Constant";
import {
  getExamProblemData,
  postProblemData,
} from "../../services/ServiceLayer";
import moment from "moment";
import { loadState, currentUser } from "../../hooks/helper";
import { Autocomplete } from "@mui/material";
import { toast } from "react-toastify";
import ExamValidation from "../../hooks/ExamValidation";
export default function ManageProblem(props) {
  const currentDate = moment().format("YYYY-MM-DD");

  const userData = loadState().user;
  const userRole = loadState().user.user_role;

  console.log("props-24", props);
  const { selectclient, examProblemStatus, patientId } = props;
  console.log("selectclient", selectclient);

  const [options, setOptions] = useState([]);
  const [actionType, setActionType] = useState("ADDPRB");
  //
  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");

  const [selectedProblem, setSelectedProblem] = useState(null);
  const [problemList, setProblemList] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  const [isActionCalled, setIsActionCalled] = useState(false);
  const hideHandler = () => {
    console.log("hide handler called");
    resetAllValidator();
    props.onHide();
    clearForm();
  };
  useEffect(() => {
    getExamProblemData(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELPRB`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setIsLoader(false);
        setProblemList(response?.data);
        setOptions(response?.data);
      } else {
        setIsLoader(false);
      }
    });
  }, []);
  useEffect(() => {
    console.log("selectedProblem", selectedProblem);
  }, [selectedProblem]);
  const [problemDetail, setProblemDetail] = useState({
    icd_code: "",
    pat_prob_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    problem_id: 0,
    st_date: moment(currentDate).format("YYYY-MM-DD"),
    end_date: moment(currentDate).format("YYYY-MM-DD"),
    problem_status: 0,
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: actionType,
  });
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    ExamValidation({
      st_date: problemDetail?.st_date,
      end_date: problemDetail?.end_date,
    });
  const clearForm = () => {
    setProblemDetail({
      icd_code: "",
      pat_prob_id: 0,
      patient_id: patientId,
      location_id: userData?.location_id,
      problem_id: 0,
      st_date: moment(currentDate).format("YYYY-MM-DD"),
      end_date: moment(currentDate).format("YYYY-MM-DD"),
      problem_status: 0,
      updated_by: currentUser(),
      access_location: "access_location",
      opt_type: actionType,
    });
    setSelectedProblem(null);
  };
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setProblemDetail({
        icd_code: "",
        pat_prob_id: 0,
        patient_id: patientId,
        location_id: userData?.location_id,
        problem_id: 0,
        st_date: moment(currentDate).format("YYYY-MM-DD"),
        end_date: moment(currentDate).format("YYYY-MM-DD"),
        problem_status: 0,
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: actionType,
      });
      setSelectedProblem({
        problem_desc:''
      });
    } else {
      setProblemDetail({
        icd_code: selectclient?.icd_10,
        pat_prob_id: selectclient?.pat_prob_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        problem_id: selectclient?.problem_id,
        st_date: moment(selectclient?.st_date).format("YYYY-MM-DD"),
        end_date: moment(selectclient?.end_date).format("YYYY-MM-DD"),
        problem_status: selectclient?.status_id,
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: 'UPDPRB',
      });
      setSelectedProblem({
        problem_desc:selectclient?.problem_desc
      });
      
    }
  }, [selectclient]);

  const handleSubmit = () => {
    checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    if (!isError() && error !== null && isActionCalled) {
   
      postProblemData(problemDetail).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Problem added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });

          clearForm();
          props.onHide();
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    // } else {
    //   setIsActionCalled(false);
    // }

    setIsActionCalled(false);
  }, [isActionCalled]);




  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleSubmit}
            >
              {selectclient ? "Update" : "Add"}
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={2}
              className={classes.formBox}
              style={{ marginBottom: "4px" }}
            >
              {formProblemElements.map((element, i) => {
                return (
                  <React.Fragment key={i}>
                    <Grid 
                      item
                      xs={12}
                      md={element.mdSize}
                      className={classes.textGroup}
                    >
                      <Typography
                        style={{ fontSize: "13px" }}
                        className={classes.formHeading}
                      >
                        {element.label}{" "}
                        {element.required ? (
                          <span className={classes.required}>*</span>
                        ) : null}
                      </Typography>

                      {element.type === "select" &&
                      element.key === "problem_id" ? (
                        <>
                          <Autocomplete
                            id="combo-box-demo"
                            options={options}
                            // onInputChange={onInputChange}
                            getOptionLabel={(option) => option?.problem_desc === undefined ? '' : option?.problem_desc }
                            // filterOptions={(options) => options}
                            style={{
                              // width: 600,
                              height: 30,
                              marginBottom: 10,
                              marginRight: "16px",
                            }}
                            value={
                              selectedProblem
                                ? selectedProblem
                                : problemDetail[element.key]
                            }
                            onChange={(event, newValue) => {
                              console.log("selected", newValue);
                              setSelectedProblem(newValue);
                              if (newValue) {
                                setProblemDetail({
                                  ...problemDetail,
                                  [element.key]: newValue?.problem_id,
                                  // problem_id: e.target.value?.problem_id,
                                  icd_code: newValue?.icd_10,
                                });
                              } else {
                                setProblemDetail({
                                  ...problemDetail,
                                  [element.key]: newValue,
                                });
                              }

                              // resetValidator(e.target.name);
                            }}
                            renderInput={(params) => (
                              <TextField
                                style={{ height: 36 }}
                                {...params}
                                label=""
                                variant="outlined"
                              />
                            )}
                          />
                        </>
                      ) : element.type === "select" ? (
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            name={element.key}
                            className={classes.selectDropdown}
                            placeholder={element.placeHolder}
                            value={
                              problemDetail && problemDetail[element.key]
                                ? problemDetail && problemDetail[element.key]
                                : ""
                            }
                            onChange={(e) => {
                              console.log("selected", e.target.value);
                              if (e.target.value && e.target.value?.icd_10) {
                                setProblemDetail({
                                  ...problemDetail,
                                  [element.key]: e.target.value?.problem_id,
                                  //problem_id: e.target.value?.problem_id,
                                  icd_code: e.target.value?.icd_10,
                                });
                              } else {
                                setProblemDetail({
                                  ...problemDetail,
                                  [element.key]: e.target.value,
                                });
                              }

                              resetValidator(e.target.name);
                            }}
                          >
                            <MenuItem disabled value="">
                              Select
                            </MenuItem>
                            {element.key === "problem_id" && problemList
                              ? problemList.map((item, index) => (
                                  <MenuItem key={index} value={item}>
                                    {item.problem_desc}
                                  </MenuItem>
                                ))
                              : element.key === "problem_status" &&
                                examProblemStatus
                              ? examProblemStatus.map((item, index) => (
                                  <MenuItem
                                    key={index}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.options.map((item,index) => (
                                  <MenuItem key={index} value={item}>{item}</MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      ) : element.type === "text" ? (
                        <TextField
                          disabled={element.key === "icd_code"}
                          required
                          name={element.key}
                          placeholder={element.placeHolder}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                          }}
                          value={
                            problemDetail && problemDetail[element.key]
                              ? problemDetail && problemDetail[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected");
                            setProblemDetail({
                              ...problemDetail,
                              [element.key]: e.target.value,
                            });
                            resetValidator(e.target.name);
                          }}
                        />
                      ) : element.type === "date" ||
                        element.type === "datetime-local" ? (
                          <>
                        <TextField
                          id={element.key}
                          name={element.key}
                          placeholder={element.placeHolder}
                          type={element.type}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            min:
                                element.key === "end_date"
                                  ? problemDetail?.st_date
                                  : undefined,
                            max: `${currentDate}`,
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // onKeyDown={(e) => {
                          //   e.preventDefault(); // Prevent manual typing
                          // }}
                          value={
                            problemDetail && problemDetail[element.key]
                              ? problemDetail && problemDetail[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected");
                            setProblemDetail({
                              ...problemDetail,
                              [element.key]: e.target.value,
                            });
                            resetValidator(e.target.name);
                          }}
                          isInvalid={error && error[element.key]}
                        />
                        <Fade in={error && !!error[element.key]}>
                          <Typography
                            color="secondary"
                            className={classes.errorMessage}
                          >
                            {error && error[element.key]}
                          </Typography>
                        </Fade>
                        </>
                      ) : element.type === "textarea" ? (
                        <TextField
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          placeholder="Type here..."
                          variant="outlined"
                          multiline
                          rows={3}
                          inputProps={{
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          value={
                            problemDetail && problemDetail[element.key]
                              ? problemDetail && problemDetail[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected");
                            setProblemDetail({
                              ...problemDetail,
                              [element.key]: e.target.value,
                            });
                            // resetValidator(e.target.name);
                          }}
                        />
                      ) : element.type === "button" ? (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                          style={{
                            width: "100%",
                            fontSize: "10px",
                            marginTop: "26px",
                          }}
                        >
                          {element.btnName}
                        </Button>
                      ) : (
                        <Switch />
                      )}
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
