import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TableRow,
  TableCell,
  Typography,
  InputBase,
  MenuItem,
  Menu,
} from "@material-ui/core";

import { useHistory } from "react-router-dom";

// components

import {
  getCompanyLocationByCompId,
  getOrder,
} from "../../../services/ServiceLayer";
import { loadState } from "../../../hooks/helper";
import useStyles from "../../../components/Patient/Detail/styles";
import TableWidget from "../../../components/Widget/TableWidget";
import TableDesign from "../../../components/common/table/TableDesign";
import moment from "moment";
import { invtOrderListElements } from "./order_constant";
import { toast } from "react-toastify";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import * as Icons from "@material-ui/icons";
import classNames from "classnames";
import {
  MoreVert as MoreIcon,
  Search as SearchIcon,
  Lens,
} from "@material-ui/icons";
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";
import ManageOrder from "./ManageOrder";
import DeleteOrderPopup from "./DeleteOrderPopup";
import ActivationOrderPopup from "./ActivationOrderPopup";
import Loader from "../../common/loader/Loader";

export default function InvtOrderList() {
  const history = useHistory();

  const userData = loadState().user;
  const userRole = loadState().user?.user_role;
  var classes = useStyles();
  var [isSearchOpen, setSearchOpen] = useState(true);

  // table Header
  const tableHeadings = [
    {
      label: "Order Id",
      align: "left !important",
    },
    {
      label: "Patient",
      align: "left !important",
    },
    {
      label: "Email",
      align: "left !important",
    },

    {
      label: "Contact",
      align: "left !important",
    },
    {
      label: "Vision Insurance",
      align: "left !important",
    },
    {
      label: "Medical Insurance",
      align: "left !important",
    },
    {
      label: "Order Date",
      align: "left !important",
    },
    // {
    //   label: "Finalized Date",
    //   align: "left !important",
    // },

    {
      label: "Status Order",
      align: "left !important",
    },
    {
      label: "Payment Status",
      align: "left !important",
    },
    {
      label: "Action",
      align: "left !important",
    },
  ];

  const [open, setOpen] = React.useState(false);
  const [selectRow, setSelectRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const openDown = Boolean(anchorEl);
  toast.configure();

  const [orderList, setOrderList] = useState([]);
  const [selectclient, setselectclient] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  useEffect(() => {
    if (userData) {
      setSelectedLocation(userData?.location_id);
    }
  }, [userData]);
  const [refreshList, setRefreshList] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  useEffect(() => {
    getOrder(
      `patient_id=0&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELORD&order_id=`
    ).then((response) => {
      console.log("response", response);
      console.log("response?.data", response?.data);
      console.log(response?.status);
      if (response?.status?.status) {
        const data = response?.data;
        setOrderList(data);
        setIsLoader(false);
      }
    });
    setIsLoader(false);
  }, []);
  useEffect(() => {
    getOrder(
      `patient_id=0&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELORD&order_id=`
    ).then((response) => {
      console.log("response", response);
      console.log("response?.data", response?.data);
      console.log(response?.status);
      if (response?.status?.status) {
        const data = response?.data;
        setOrderList(data);
        setRefreshList(false);
        setIsLoader(false);
      }
    });
    setRefreshList(false);
    setIsLoader(false);
  }, [refreshList]);
  const addOrder = () => {
    // history.push(`/app/admin-details`);
    setOpen(true);
  };
  const hideHandle = () => {
    setOpen(false);
    setDeleteModalShow(false);
  };
  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    console.log("row", row);
    setSelectRow(row);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEditClick = (selectedRow) => {
    if (selectedRow) {
      console.log("selectedRow-onclick", selectedRow);
      // Use React Router to navigate to the edit page
      history.push(`/app/order-details/${selectedRow.patient_id}`, {
        selectedRow,
      });
    }
  };
  const deleteEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);
    setselectclient(rec);
    setDeleteModalShow(true);
    // setTitle("Delete Spectacle lens");
  };

  //searching
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredOrderList = orderList.filter((item) => {
    // Check if each property is not null or undefined before using toLowerCase()
    const frameName = item.first_name ? item.first_name.toLowerCase() : "";
    const frameEmail = item.email ? item.email.toLowerCase() : "";
    const frameContact = item.contact_cell
      ? item.contact_cell.toLowerCase()
      : "";

    return (
      frameName.includes(searchTerm.toLowerCase()) ||
      frameEmail.includes(searchTerm.toLowerCase()) ||
      frameContact.includes(searchTerm.toLowerCase())
    );
  });
  return (
    <>
      <DeleteOrderPopup
        onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        selectclient={selectclient}
        setDeleteModalShow={setDeleteModalShow}
        setRefreshList={setRefreshList}
      />
      <ActivationOrderPopup />
      <ManageOrder
        selectedLocation={selectedLocation}
        open={open}
        onHide={hideHandle}
        title={"Select Patient"}
      />
      <div className={classes.pageTitleContainer}>
        <Typography className={classes.typo} weight="medium">
          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
            <div
              style={{ left: "0px" }}
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
              onClick={() => setSearchOpen(!isSearchOpen)}
            >
              <SearchIcon classes={{ root: classes.headerIcon }} />
            </div>
            <InputBase
              style={{ marginLeft: "6px" }}
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onChange={handleChange}
            />
          </div>
        </Typography>

        <div className={classes.groupBtn}>
          <Button
            classes={{ root: classes.button }}
            variant="contained"
            color="secondary"
            onClick={addOrder}
          >
            {/* <img className={classes.editicons} src={editIcons} /> */}
            Add New Order
          </Button>
        </div>
      </div>
      {isLoader && <Loader />}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {filteredOrderList && filteredOrderList.length > 0 ? (
                filteredOrderList.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                        >
                          {row.order_id}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.first_name} {row.middle_name} {row.last_name}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.email}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                        >
                          {row.contact_cell}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.insurance_provider
                            ? row.insurance_provider
                            : "N/A"}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.add_insurance_provider
                            ? row.add_insurance_provider
                            : "N/A"}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {moment(row.order_date).format("DD/MM/YYYY")}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.order_status === 0 ? (
                            <>
                              <span
                                style={{
                                  background: "orange",
                                  borderRadius: "5px",
                                  padding: "4px 10px",
                                  color: "white",
                                }}
                              >
                                Pending
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                style={{
                                  background: "green",
                                  borderRadius: "5px",
                                  padding: "4px 10px",
                                  color: "white",
                                }}
                              >
                                Complete
                              </span>
                            </>
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.payment_status_val === "Pending" ? (
                            <>
                              <span
                                style={{
                                  background: "orange",
                                  borderRadius: "5px",
                                  padding: "4px 10px",
                                  color: "white",
                                }}
                              >
                                {row.payment_status_val}
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                style={{
                                  background: "green",
                                  borderRadius: "5px",
                                  padding: "4px 10px",
                                  color: "white",
                                }}
                              >
                                {row.payment_status_val}
                              </span>
                            </>
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          <IconButton
                            onClick={(e) => {
                              handleClick(e, row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => handleEditClick(selectRow)}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />
                              View/Update
                            </MenuItem>
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => deleteEventHandler(selectRow)}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />
                              Cancel
                            </MenuItem>
                            <MenuItem
                              className={classes.menuItem}
                              // onClick={() => handleEditClick(row)}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />
                              Re-Order
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
