import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  Switch,
  FormGroup,
  FormControlLabel,
  IconButton,
  Menu,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../common/modal/Popup";
// styles
import useStyles from "../styles";

import moment from "moment";

import { postContactTrial } from "../../../services/ServiceLayer";
import { toast } from "react-toastify";

import { formTrialContactElements } from "./Contact_Constant";
import { currentUser, loadState } from "../../../hooks/helper";
import useListData from "../../common/allMetaData/useListData";

export default function ManageContact(props) {

  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  console.log("props-24", props);
  const {
    patientId,
    selectclient,
    setTitle,
    setContactTrialDetails,
    contactTrialDetails,
    setDataUpdated,setselectclient,supplierData,
    manufacturerData
  } = props;
  const listData = useListData();
  const { examAssessmentFollowUpStatus } = listData;
  console.log("selectclient", selectclient);

  var classes = useStyles();
  const userData = loadState().user;
  const [maxWidth, setMaxWidth] = React.useState("lg");

  const [isActionCalled, setIsActionCalled] = useState(false);

  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    // if (!isError() && error !== null && isActionCalled) {
    if (isActionCalled) {
      postContactTrial(contactTrialDetails).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Conatct trial added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setDataUpdated(true);
          setIsActionCalled(false);
          setselectclient(null);
          props.onHide();
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    // } else {
    //   setIsActionCalled(false);
    // }

    setIsActionCalled(false);
  }, [isActionCalled]);

  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
    setselectclient(null);
    
  };

  const handleCheckboxChange = (copy, paste, e) => {
    console.log("copy", copy);
    console.log("copy to", paste);
    setAnchorEl(null);
    setContactTrialDetails({
      ...contactTrialDetails,

      [`${paste}_bc`]: contactTrialDetails?.[`${copy}_bc`],
      [`${paste}_dia`]: contactTrialDetails?.[`${copy}_dia`],
      [`${paste}_sph`]: contactTrialDetails?.[`${copy}_sph`],
      [`${paste}_cyl`]: contactTrialDetails?.[`${copy}_cyl`],
      [`${paste}_axis`]: contactTrialDetails?.[`${copy}_axis`],
      [`${paste}_add`]: contactTrialDetails?.[`${copy}_add`],
      [`${paste}_dva`]: contactTrialDetails?.[`${copy}_dva`],
      [`${paste}_nva`]: contactTrialDetails?.[`${copy}_nva`],
      [`${paste}_tint`]: contactTrialDetails?.[`${copy}_tint`],
      [`${paste}_clr`]: contactTrialDetails?.[`${copy}_clr`],
      [`${paste}_qty`]: contactTrialDetails?.[`${copy}_qty`],
      [`${paste}_material`]: contactTrialDetails?.[`${copy}_material`],
      [`${paste}_blend`]: contactTrialDetails?.[`${copy}_blend`],
      [`${paste}_manufacturer`]: contactTrialDetails?.[`${copy}_manufacturer`],
      [`${paste}_prodname`]: contactTrialDetails?.[`${copy}_prodname`],

      // opt_type: "ADDCNT",
    });
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (selectclient) {
      setContactTrialDetails({
        pat_exttcnt_id: selectclient?.pat_exttcnt_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        cnt_trial: selectclient?.cnt_trial,
        od_bc: selectclient?.od_bc,
        od_dia: selectclient?.od_dia,
        od_sph: selectclient?.od_sph,
        od_cyl: selectclient?.od_cyl,
        od_axis: selectclient?.od_axis,
        od_add: selectclient?.od_add,
        od_dva: selectclient?.od_dva,
        od_nva: selectclient?.od_nva,
        od_tint: selectclient?.od_tint,
        od_clr: selectclient?.od_clr,
        od_qty: selectclient?.od_qty,
        od_material: selectclient?.od_material,
        od_blend: selectclient?.od_blend,
        od_manufacturer: selectclient?.od_manufacturer,
        od_prodname: selectclient?.od_prodname,
        os_bc: selectclient?.os_bc,
        os_dia: selectclient?.os_dia,
        os_sph: selectclient?.os_sph,
        os_cyl: selectclient?.os_cyl,
        os_axis: selectclient?.os_axis,
        os_add: selectclient?.os_add,
        os_dva: selectclient?.os_dva,
        os_nva: selectclient?.os_nva,
        os_tint: selectclient?.os_tint,
        os_clr: selectclient?.os_clr,
        os_qty: selectclient?.os_qty,
        os_material: selectclient?.os_material,
        os_blend: selectclient?.os_blend,
        os_manufacturer: selectclient?.os_manufacturer,
        os_prodname: selectclient?.os_prodname,
        ou_dva: selectclient?.ou_dva,
        ou_nva: selectclient?.ou_nva,
        ou_rxdate: moment(selectclient?.ou_rxdate).format('YYYY-MM-DD'),
        updated_by: currentUser(),
        access_location: "",
        opt_type: "UPDCNT",
      });
    }
  }, [selectclient]);

  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        // fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleSubmit}
            >
              {selectclient ? "Update" : "Add"}
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={2}
              className={classes.formBox}
              style={{ marginBottom: "4px" }}
            >
              {formTrialContactElements.map((element, i) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={`${classes.textGroup} ${
                        element.title ? classes.highlightedItem : ""
                      }`}
                      // style={{ border: "1px solid #DED9D9" }}
                    >
                      {element.label && (
                        <Typography
                          className={classes.formHeading}
                          style={{
                            fontWeight: "500",
                            color: "#4E97FD",
                            position: "relative",
                          }}
                        >
                          {element.label}&nbsp;
                          {element.required === "true" ? (
                            <span className={classes.required}>*</span>
                          ) : null}
                          {element?.copyBtn === "true" && (
                            <Button
                              style={{
                                float: "right",
                                position: "relative",
                                top: "20px",
                                fontSize: "12px",
                                textTransform: "none",
                              }}
                              classes={{ root: classes.button }}
                              variant="contained"
                              size="small"
                              color="secondary"
                              onClick={() =>
                                handleCheckboxChange(
                                  element?.action === "od" ? "od" : "os",
                                  element?.action === "od" ? "os" : "od"
                                )
                              }
                            >
                              {element?.copyLabel}
                            </Button>
                          )}
                        </Typography>
                      )}
                      {element.type ? (
                        <>
                          {element.type === "select" &&
                          element.key !== "procedure_code" ? (
                            <FormControl
                              fullWidth
                              className={classes.dropdownFormControl}
                            >
                              <Select
                                name={element.key}
                                className={classes.selectDropdown}
                                placeholder={element.placeHolder}
                                value={
                                  contactTrialDetails &&
                                  contactTrialDetails[element.key]
                                    ? contactTrialDetails &&
                                      contactTrialDetails[element.key]
                                    : ""
                                }
                                onChange={(e) => {
                                  console.log("selected");
                                  setContactTrialDetails({
                                    ...contactTrialDetails,
                                    [element.key]: e.target.value,
                                  });
                                }}
                              >
                                <MenuItem disabled value="">
                                  Select
                                </MenuItem>
                                {(element.key === "od_prodname" || element.key === "os_prodname") &&
                                    supplierData
                                  ? supplierData.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.supplier_id}
                                      >
                                        {item.supplier_name}
                                      </MenuItem>
                                    ))
                                  : (element.key === "od_manufacturer" || element.key === "os_manufacturer") &&
                                    manufacturerData
                                  ? manufacturerData.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.manufacturer_id}
                                      >
                                        {item.manufacturer_name}
                                      </MenuItem>
                                    )) :element.key === "ou_status" && examAssessmentFollowUpStatus
                                  ? examAssessmentFollowUpStatus.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    )):element.options.map((item) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : element.type === "text" ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {element.txtLabel && (
                                <span
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "10px",
                                  }}
                                >
                                  {element.txtLabel}
                                </span>
                              )}
                              <TextField
                                required
                                name={element.key}
                                placeholder={element.placeHolder}
                                size="small"
                                className={classes.txtForm}
                                fullWidth
                                variant="outlined"
                                inputProps={{
                                  style: {
                                    height: "12px",
                                    fontSize: "12px",
                                  },
                                  maxLength: element.maxLength,
                                  onKeyPress: element.onKeyPress,
                                }}
                                value={
                                  contactTrialDetails &&
                                  contactTrialDetails[element.key]
                                    ? contactTrialDetails &&
                                      contactTrialDetails[element.key]
                                    : ""
                                }
                                onChange={(e) => {
                                  console.log("selected");
                                  setContactTrialDetails({
                                    ...contactTrialDetails,
                                    [element.key]: e.target.value,
                                  });
                                }}
                              />
                              {element.key === "sku" && (
                                <Button
                                  // onClick={selectFrameBySku}
                                  classes={{ root: classes.button }}
                                  variant="contained"
                                  color="secondary"
                                >
                                  Slect
                                </Button>
                              )}
                            </div>
                          ) : element.type === "checkbox" ? (
                            <>
                              <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                  <FormControlLabel
                                    value={element.value}
                                    control={
                                      <Checkbox
                                        onChange={(e) => {
                                          const selectedValue = e.target.checked
                                            ? "Y"
                                            : "N";
                                          setContactTrialDetails({
                                            ...contactTrialDetails,
                                            [element.key]: selectedValue,
                                          });
                                        }}
                                      />
                                    }
                                    label={element.lbltitle}
                                    labelPlacement="start"
                                  />
                                </FormGroup>
                              </FormControl>
                            </>
                          ) : element.type === "date" ||
                            element.type === "datetime-local" ? (
                            <TextField
                              id={element.key}
                              name={element.key}
                              placeholder={element.placeHolder}
                              type={element.type}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                max: `${currentDateTime}`,
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                              }}
                              value={
                                contactTrialDetails &&
                                contactTrialDetails[element.key]
                                  ? contactTrialDetails &&
                                    contactTrialDetails[element.key]
                                  : currentDateTime
                              }
                              onChange={(e) => {
                                console.log("selected");
                                setContactTrialDetails({
                                  ...contactTrialDetails,
                                  [element.key]: e.target.value,
                                });
                              }}
                            />
                          ) : element.type === "button" &&
                            element?.btnlbl === "Lookup" ? (
                            <>
                              <Button
                                classes={{ root: classes.button }}
                                variant="contained"
                                color="secondary"
                                // onClick={selectPrevFrame}
                              >
                                {element.btnlbl}
                              </Button>
                            </>
                          ) : element.type === "button" &&
                            element?.btnlbl === "Update" ? (
                            <>
                              {/* {selectedRow && (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                        //   onClick={updateSelectFrame}
                        >
                          {element.btnlbl}
                        </Button>
                      )} */}
                            </>
                          ) : (
                            <TextField
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              placeholder="Type here..."
                              variant="outlined"
                              multiline
                              rows={3}
                              inputProps={{
                                style: {
                                  fontSize: "12px",
                                },
                              }}
                              value={
                                contactTrialDetails &&
                                contactTrialDetails[element.key]
                                  ? contactTrialDetails &&
                                    contactTrialDetails[element.key]
                                  : ""
                              }
                              onChange={(e) => {
                                console.log("selected");
                                setContactTrialDetails({
                                  ...contactTrialDetails,
                                  [element.key]: e.target.value,
                                });
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <Typography
                            className={classes.formHeading}
                            style={{
                              fontWeight: "600",

                              color: "#4E97FD",
                            }}
                          >
                            {element.title}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
