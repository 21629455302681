import React, { useState, useEffect } from "react";
import { Button, Grid, Menu, MenuItem, Typography } from "@material-ui/core";

import { dateFormatConversion, loadState } from "../../../hooks/helper";

import TableWidget from "../../Widget/TableWidget";
import  MedicationChart from './MedicationChart'
import TableDesign from "../../common/table/TableDesign";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
// styles
import useStyles from "../styles";

import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";
import { getAllergyHistoryData, getPatientAssessmentDiagnosis } from "../../../services/ServiceLayer";

import moment from "moment";

export default function Assessment(props) {
  const tableHeadings = [
   
    {
      label: "ICD Code",
    },
    {
      label: "Diagnosis",
    },
    {
      label: "Eye",
    },
    {
      label: "Status",
    },
   
  ];
  const { patientId } = props;

  const [diagnosisData, setDiagnosisData] = useState([]);
 
  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  useEffect(() => {
    getPatientAssessmentDiagnosis(
      `patient_id=${patientId}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELDIG`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setDiagnosisData(response?.data);
       
      } else {
        // setIsLoader(false);
      }
    });
  }, []);

 
  return (
    <>
    {/* <div className={classes.pageTitleContainer} style={{marginBottom: "6px",marginTop: "10px", borderTop:'1px solid #6666' }}>
        <Typography className={classes.typo} style={{fontSize:'14px'}} weight="medium">
          Assessment
        </Typography>
      </div> */}
      <Grid container spacing={4} style={{ width: "100%" }}>
        <Grid item xs={12}>
        <div style={{background:'#5DB3D9',padding:'10px',color:'#fff',textAlign:'center',borderRadius:'5px',marginBottom:'2px'}}>Assessment</div>
          {/* <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {diagnosisData && diagnosisData?.length ? (
                diagnosisData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                       

                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {row?.icd_code}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.eye_var}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.diag_status_var}
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget> */}
           <MedicationChart />
        </Grid>
      </Grid>
    </>
  );
}
