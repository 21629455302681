import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { MoreVert as MoreIcon, Lens } from "@material-ui/icons";
// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { getCompanyLocationByCompId } from "../../services/ServiceLayer";
import { dateFormatConversion } from "../../hooks/helper";
import useStyles from "../../components/Patient/Detail/styles";
import TableWidget from "../../components/Widget/TableWidget";
import TableDesign from "../../components/common/table/TableDesign";
import DeleteLocationPop from '../../components/Settings/Company/DeleteLocationPopup'
import { toast } from "react-toastify";
import ManageCompanyLocation from "../../components/Settings/Company/ManageCompanyLocation";
export default function CompnayDetails() {
  var classes = useStyles();
  const { comp_id } = useParams();
  // table Header
  const tableHeadings = [
    {
      label: "Location Name",
      align: "left !important",
    },
    {
      label: "Location Alias",
      align: "left !important",
    },

    {
      label: "Contact",
      align: "left !important",
    },
    {
      label: "Email",
      align: "left !important",
    },

    {
      label: "City",
      align: "left !important",
    },
    {
      label: "Action",
      align: "left !important",
    },
  ];
  const [selectRow, setSelectRow] = useState(null);
  const [title, setTitle] = useState(null);
  const [selectclient, setselectclient] = useState(null);
  const [dataUpdated, setDataUpdated] = useState(false);
    //
    const [modalShow, setModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  console.log("comp_id", comp_id);
  toast.configure();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const openDown = Boolean(anchorEl);
  const [selectCompanyDetails, setSelectCompanyDetails] = useState([]);
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpen(false);
    setDataUpdated(true);
  };
  const createUpdateLocation = (location) => {
    console.log("location", location);
    setselectclient(location);
    // setModalShow(true);
    setTitle("Edit location");
    // setShowResult(true);
    setOpen(true);
    setAnchorEl(null);
  };
  const deleteEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);
    // setAnchorE2(null);
    // dispatch(resetPopup());
    setselectclient(rec);
    setDeleteModalShow(true);
    setTitle("Delete Company");
    // setMessage("Company deleted successfully!");
    // setShowResult(true);
  };
  useEffect(() => {
    getCompanyLocationByCompId(`comp_id=${comp_id}`).then((response) => {
      console.log("response", response);
      console.log(response?.status);
      if (response?.status?.status) {
        const data = response.data;
        // setResources(resource);
        setSelectCompanyDetails(data);
      } else {
        toast.error("Something went wrong Please try again letter", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  }, []);
  useEffect(() => {
    getCompanyLocationByCompId(`comp_id=${comp_id}`).then((response) => {
      console.log("response", response);
      console.log(response?.status);
      if (response?.status?.status) {
        const data = response.data;
        // setResources(resource);
        setSelectCompanyDetails(data);
        setDataUpdated(false);
      }
    });
  }, [dataUpdated]);
  return (
    <>
    <DeleteLocationPop
        onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        selectclient={selectclient}
        setDeleteModalShow={setDeleteModalShow}
      />

    <ManageCompanyLocation
     open={open}
     onHide={hideHandle}
     title={"Update Location"}
     selectclient={selectclient}
     setModalShow={setModalShow}/>
      <PageTitle subtitle="Company >" title="Company details" />
      <div style={{ minHeight: "fit-content" }}>
        {selectCompanyDetails &&
          selectCompanyDetails
            .filter((company) => company.is_company === "Y")
            .map((company) => (
              <div style={{ marginBottom: "10px" }}>
                <Widget>
                  <Grid container spacing={2} className={classes.formBox}>
                    <Grid item xs={12} md={4} className={classes.textGroup}>
                      <Typography className={classes.formHeading}>
                        Company Id:
                      </Typography>
                      <Typography className={classes.formHeadingValue}>
                        {company?.comp_id}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={4} className={classes.textGroup}>
                      <Typography className={classes.formHeading}>
                        Contact No:
                      </Typography>
                      <Typography className={classes.formHeadingValue}>
                        {company?.corp_phone}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.textGroup}>
                      <Typography className={classes.formHeading}>
                        Email Id:
                      </Typography>
                      <Typography className={classes.formHeadingValue}>
                        {company?.email_id}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.textGroup}>
                      <Typography className={classes.formHeading}>
                        Website:
                      </Typography>
                      <Typography className={classes.formHeadingValue}>
                        {company?.website}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.textGroup}>
                      <Typography className={classes.formHeading}>
                        City:
                      </Typography>
                      <Typography className={classes.formHeadingValue}>
                        {company?.city}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.textGroup}>
                      <Typography className={classes.formHeading}>
                        Zip:
                      </Typography>
                      <Typography className={classes.formHeadingValue}>
                        {company?.zip}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.textGroup}>
                      <Typography className={classes.formHeading}>
                        Address:
                      </Typography>
                      <Typography className={classes.formHeadingValue}>
                        {company?.address1}
                      </Typography>
                    </Grid>
                  </Grid>
                </Widget>
              </div>
            ))}
      </div>
      <p style={{ fontSize: "14px", fontWeight: "500" }}>Location Details</p>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {selectCompanyDetails && selectCompanyDetails.length ? (
                selectCompanyDetails
                  .filter((row) => row.is_company === "N")
                  .map((row, i) => {
                    if (row)
                      return (
                        <TableRow key={i} className={classes.tableBodyCell}>
                          <TableCell
                            align="left"
                            className={classes.tableBodyCell}
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              paddingLeft: "12px",
                              fontSize: "12px",
                            }}
                          >
                            {row.comp_name}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              paddingLeft: "12px",
                              fontSize: "12px",
                            }}
                            className={classes.tableBodyCell}
                          >
                            {row.comp_alias}
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              paddingLeft: "12px",
                              fontSize: "12px",
                            }}
                            className={classes.tableBodyCell}
                          >
                            {row.corp_phone}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.tableBodyCell}
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              paddingLeft: "12px",
                              fontSize: "12px",
                            }}
                          >
                            {row.email_id}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              paddingLeft: "12px",
                              fontSize: "12px",
                            }}
                            className={classes.tableBodyCell}
                          >
                            {row.city}
                          </TableCell>
                          <TableCell className={classes.tableBodyCell}>
                            <IconButton
                              onClick={(e) => {
                                handleClick(e);
                                setSelectRow(row);
                              }}
                            >
                              <MoreIcon />
                            </IconButton>

                            <Menu
                              className={classes.menuBox}
                              anchorEl={anchorEl}
                              open={openDown}
                              onClose={handleClose}
                            >
                              <MenuItem
                                className={classes.menuItem}
                                onClick={() => createUpdateLocation(selectRow)}
                              >
                                <Lens
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                    marginRight: "4px",
                                  }}
                                />{" "}
                                Update Location
                              </MenuItem>
                              <MenuItem
                                className={classes.menuItem}
                                onClick={() => deleteEventHandler(selectRow)}
                              >
                                <Lens
                                  style={{
                                    height: "4px",
                                    width: "4px",
                                    marginRight: "4px",
                                  }}
                                />{" "}
                                Delete Location
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
