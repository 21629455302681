import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Fade,
  Link,
  Box,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";

// styles
import useStyles from "./styles";

import lock from "./Lock.svg";

import arrow from "./arrow.png";
// context
import { useUserDispatch, loginUser } from "../../context/UserContext";

function Reset(props) {
  var classes = useStyles();

  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);

  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>&nbsp;</div>
      <div className={classes.formContainer}>
        <div elevation={1} className={classes.form}>
          <React.Fragment>
            <Box sx={{ textAlign: "center", m: 1 }}>
              <img src={lock} alt="img"/>
            </Box>
            <Typography className={classes.subGreeting}>
              <Box sx={{ textAlign: "center", m: 1 }}>Reset your password</Box>
            </Typography>
            <Typography className={classes.greeting}>
              <Box sx={{ textAlign: "center", m: 1 }}>
                Please choose your new password.
              </Box>
            </Typography>
            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                Something is wrong with your login or password :
              </Typography>
            </Fade>
            <Typography variant="h6" className={classes.txtHeading}>
              New Password
            </Typography>
            <TextField
              id="password"
              InputProps={{
                classes: {
                  border: classes.textFieldline,
                  input: classes.textField,
                },
              }}
              value={loginValue}
              onChange={(e) => setLoginValue(e.target.value)}
              margin="normal"
              placeholder="Enter New Password"
              type="password"
              fullWidth
              variant="outlined"
              size="small"
            />
            <Typography variant="h6" className={classes.txtHeading}>
              Confirm Password
            </Typography>
            <TextField
              id="confirmpassword"
              InputProps={{
                classes: {
                  border: classes.textFieldline,
                  input: classes.textField,
                },
              }}
              value={loginValue}
              onChange={(e) => setLoginValue(e.target.value)}
              margin="normal"
              placeholder="Enter Confirm Password"
              type="password"
              fullWidth
              variant="outlined"
              size="small"
            />
            <div className={classes.creatingButtonContainer}>
              <Button
                onClick={() =>
                  loginUser(
                    userDispatch,
                    loginValue,
                    passwordValue,
                    props.history,
                    setIsLoading,
                    setError
                  )
                }
                size="large"
                variant="contained"
                color="primary"
                fullWidth
                className={classes.createAccountButton}
              >
                Save New Password
              </Button>
            </div>
            <Box sx={{ textAlign: "center", m: 2 }}>
              <Link href="/login" className={classes.Link} underline="none">
                <img src={arrow} alt="img"/>
                &nbsp;&nbsp; Back to login
              </Link>
            </Box>
          </React.Fragment>
        </div>
      </div>
    </Grid>
  );
}

export default withRouter(Reset);
