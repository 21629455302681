import React from 'react'
import AddSpectacleLens from '../../components/Inventory/Services/AddSpectacleLens'

export default function spectacleDetails() {
  return (
    <>
    <AddSpectacleLens/>
    </>
  )
}
