export const validateForCyl = (event) => {
  const input = event.target.value;
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

  // Allow backspace, delete, and navigation keys
  if (
    event.key === "Backspace" ||
    event.key === "Delete" ||
    event.key === "ArrowLeft" ||
    event.key === "ArrowRight" ||
    event.key === "Tab"
  ) {
    return;
  } else if ((event.key === "+" || event.key === "-") && input === "") {
    // Allow + and - only at the starting position
    // event.preventDefault();
    return;
  } else if ((event.key === "+" || event.key === "-") && input.length > 0) {
    // Allow + and - only after digits
    const lastChar = input[input.length - 1];
    if (!/\d/.test(lastChar)) {
      event.preventDefault();
      return;
    }
    // Allow + and - only after decimal
    const decimalIndex = input.indexOf(".");
    if (decimalIndex !== -1 && (event.key === "+" || event.key === "-")) {
      const decimalPart = input.substring(decimalIndex + 1);
      if (decimalPart.length <= 2) {
        return;
      }
    }
  }

  // Check if the input matches the desired format (xx.yy)
  const isValidFormat = /^[-+]?\d{0,2}(\.\d{0,2})?$/.test(input + keyValue);

  // Check if the input value is within the desired range
  const newValue = parseFloat(input + keyValue);
  if (newValue > 20 || newValue < -20) {
    event.preventDefault();
  }

  // Prevent adding dot as the first character
  const isDotAtFirstPosition = input === "" && keyValue === ".";
  if (!isValidFormat || isDotAtFirstPosition) {
    event.preventDefault();
  }
};


export const validateForAxios = (event) => {
  const input = event.target.value;
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

  // Allow backspace, delete, and navigation keys
  if (
    event.key === "Backspace" ||
    event.key === "Delete" ||
    event.key === "ArrowLeft" ||
    event.key === "ArrowRight" ||
    event.key === "Tab"
  ) {
    return;
  } 

  // Check if the input matches the desired format (xx.yy)
  const isValidFormat = /^\d{0,3}$/.test(input + keyValue);

  // Check if the input value is within the desired range
  const newValue = parseInt(input + keyValue);
  if (newValue > 180 || newValue < 0) {
    event.preventDefault();
  }

  // Prevent adding dot
  if (!isValidFormat) {
    event.preventDefault();
    event.target.value = input; // Revert to previous value if invalid format
  } else {
    event.target.value = (input).slice(-3); // Add leading zeros to ensure three digits are displayed
  }
};

export const validateForHprism = (event) => {
  const input = event.target.value;
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

  // Allow backspace, delete, and navigation keys
  if (
    event.key === "Backspace" ||
    event.key === "Delete" ||
    event.key === "ArrowLeft" ||
    event.key === "ArrowRight" ||
    event.key === "Tab"
  ) {
    return;
  } 

  // Check if the input matches the desired format (xx.yy)
  const isValidFormat = /^\d{0,2}$/.test(input + keyValue);

  // Check if the input value is within the desired range
  // const newValue = parseInt(input + keyValue);
  // if (newValue > 180 || newValue < 0) {
  //   event.preventDefault();
  // }

  // Prevent adding dot
  if (!isValidFormat) {
    event.preventDefault();
    
  } 
};


