import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  tableCell: {
    display: "flex",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    alignContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "12px !important",
    fontWeight: 400,
    padding: "0px 2px",
    textAlign: "center",
    fontFamily:'Poppins'
  },
  imgAvtar: {
    marginRight: "10px",
  },
  tableHeadCell: {
    fontSize: "12px !important",
    fontWeight: 500,
    // textAlign: "center",
    fontFamily:'Poppins',
    textWrap:'nowrap',
    color: "#2B3445",
    backgroundColor: "#f3f5f9",
  },
  tableBodyCell: {
    fontSize: "12px !important",
    fontWeight: 400,
    padding: "0px 2px",
    textAlign: "center",
    fontFamily:'Poppins'
  },
}));
