import React, { useState, useEffect } from "react";
import { Button, Grid, Menu, MenuItem } from "@material-ui/core";

import { dateFormatConversion, loadState } from "../../../hooks/helper";


import TableWidget from "../../Widget/TableWidget";

import TableDesign from "../../common/table/TableDesign";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
// styles
import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";

import useStyles from "../styles";
import ManageFollowUp from "./ManageFollowUp";
import { getPatientAssessmentFollowup } from "../../../services/ServiceLayer";
import moment from "moment";

export default function FollowUp({patientId,mode}) {
  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Follow Up For",
      align: "left",
    },
    {
      label: "In Month",
      align: "left",
    },
    {
      label: "On Date",
      align: "left",
    },
    {
      label: "Reason",
      align: "left",
    },
    {
      label: "Days Until Contact",
      align: "left",
    },
    {
      label: "Mode",
      align: "left",
    },
    {
      label: "Status",
      align: "left",
    },
    {
      label: "Status Date",
      align: "left",
    },
    {
      label: "Action",
    
    },
   
  ];
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const userData = loadState().user;
  const userRole =loadState().user.user_role;
  const [modalShow, setModalShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectRow, setSelectRow] = useState(null);
  const [patientAttachmentData, setPatientAttachmentData] = useState([]);
  const [selectclient, setSelectclient] = useState();
  const [isUpdated, setIsUpdated] = useState(true);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const addFollowup=()=>{
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
    setIsUpdated(true);
  };
  const [assessmentFollowupList, setAssessmentFollowupList] = useState([]);

  useEffect(() => {
    getPatientAssessmentFollowup(
      `patient_id=${patientId}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELFLU`
    ).then((response) => {
      console.log(response);
      setAssessmentFollowupList(response.data);
      setIsUpdated(false);
    });
    setIsUpdated(false);
  }, [isUpdated]);
  const handleEdit=(rec)=>{
    setOpen(true);
  setAnchorEl(null);
  setSelectclient(rec);
  }
  return (
    <>
    <ManageFollowUp  open={open}
        onHide={handleClose}
        title={"Add Follow Up"}
        selectclient={selectclient}
        patientId={patientId}
        mode={mode}
         />
     <Grid container spacing={4} style={{ width: "100%" }}>
        <Grid>
          {" "}
          <div
            className={classes.pageTitleContainer}
            style={{ display: "flex", justifyContent: "end" }}
          >
            <Button
              style={{
                marginRight: "8px",
                fontSize: "10px",
                position: "absolute",
                borderRadius: "2px",
                right: "60px",
                background: "#4E97FD",
              }}
              classes={{ root: classes.button }}
              variant="contained"
              color="secondary"
              onClick={addFollowup}
            >
              Add Follow Up
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {assessmentFollowupList && assessmentFollowupList?.length ? (
                assessmentFollowupList.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row?.followup_reason}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {moment(row?.followup_date).utc().format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row?.followup_notes}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {row?.days_contact}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "10px" }}
                          className={classes.tableBodyCell}
                        >
                          {moment(row?.created_dt).utc().format('DD-MM-YYYY')}
                        </TableCell>

                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            id="basic-button"
                            aria-controls={openDown ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openDown ? "true" : undefined}
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem onClick={()=>handleEdit(selectRow)}>Edit</MenuItem>

                            {/* <MenuItem onClick={() => deleteEventHandler(selectRow)}>Delete</MenuItem> */}
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  )
}
