import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import { Grid, Typography, InputBase } from "@material-ui/core";
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Button,
} from "@material-ui/core";

import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";
// components

import TableWidget from "../Widget/TableWidget";
import { dateFormatConversion, loadState } from "../../hooks/helper";

// styles
import useStyles from "./styles";
import TableDesign from "../common/table/TableDesign";
import ToggleActivationPopup from "./ToggleActivationPopup";
import DeletePopup from "./DeletePopup";
import ManagePatient from "./ManagePatient";
import classNames from "classnames";
import { getPatientList } from "../../services/ServiceLayer";
import { getPatientsSearch } from "../../services/ServiceLayer";
import ApiResponsePopup from "../../components/common/modal/ApiResponsePopup";
import { toast } from "react-toastify";
export default function PatientList() {
  const [patientReadValue, setPatientReadValue] = useState();
  toast.configure();
  const readAccessData = loadState().menu;
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  useEffect(() => {
    console.log("readAccessData", readAccessData);
    const patientsSection = readAccessData.find(
      (item) => item.label === "Patients"
    );

    if (patientsSection) {
      // Access the "children" array within the "Patients" section
      const patientChildren = patientsSection.children;

      // Find and store the read_access values
      const patientListReadAccess = patientChildren.find(
        (child) => child.label === "Patient List"
      ).read_access;
      const addPatientReadAccess = patientChildren.find(
        (child) => child.label === "ADD New Patient"
      ).read_access;

      // Now you can use these values as needed
      console.log("Patient List Read Access:", patientListReadAccess);
      console.log("ADD New Patient Read Access:", addPatientReadAccess);
      setPatientReadValue(addPatientReadAccess);
    }
  }, [readAccessData]);

  const history = useHistory();
  var [isSearchOpen, setSearchOpen] = useState(true);
  const [selectRow, setSelectRow] = useState(null);
  const searchInputRef = useRef(null);

  const [phoneText, setPhoneText] = useState("");
  const [lastNameText, setLastNameText] = useState("");

  const [patientData, setPatientData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // table Header
  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "First Name",
      align: "left !important",
    },
    {
      label: "Last Name",
      align: "left !important",
    },
    {
      label: "Email",
      align: "left !important",
    },
    {
      label: "Phone",
    },
    {
      label: "DOB",
    },
    {
      label: "Status",
    },
    {
      label: "Action",
    },
  ];

  var classes = useStyles();
  const [open, setOpen] = React.useState(false);
  //
  const [modalShow, setModalShow] = useState(false);
  const [selectclient, setSelectclient] = useState(null);

  const [errorApiMessage, setErrorApiMessage] = useState(null);

  const [toggleActivateModalShow, setToggleActivateModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [patientUpdate, setPatientUpdate] = useState(false);
  const hideHandlerRes = () => {
    setIsShow(false);
  };
  const [isShow, setIsShow] = useState(false);

  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpen(false);
    setToggleActivateModalShow(false);
    setPatientUpdate(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleDelete = (rec) => {
    setDeleteModalShow(true);
    setAnchorEl(null);
    setSelectclient(rec);
  };
  const addPatient = () => {
    history.push(`add-patient`);
  };

  const searchPatient = () => {
    if ((phoneText || lastNameText) !== "") {
      getPatientsSearch(
        `contact_cell=${phoneText === "" ? 0 : phoneText}&location_id=${
          userData?.location_id === undefined || userData?.location_id === ""
            ? "0"
            : userData?.location_id
        }&lastName=${lastNameText}`
      ).then((response) => {
        console.log("response", response);
        console.log(response?.status);
        if (response?.status?.status) {
          console.log(response?.data);
          setPatientData(response?.data);
        } else {
          // setIsShow(true);
          // if(response?.data?.length===0){
          //   toast.error("Data not found");
          // }

          setErrorApiMessage(response?.status.description);
        }
      });
    } else {
      getPatientList(
        `start_date=&end_date=&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELPAT`
      ).then((response) => {
        console.log(response);
        setPatientData(response.data);
      });
    }
  };

  useEffect(() => {
    getPatientList(
      `start_date=&end_date=&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELPAT`
    ).then((response) => {
      console.log(response);
      if (response?.status?.status) {
        setPatientData(response?.data);
      }
    });
  }, []);
  useEffect(() => {
    getPatientList(
      `start_date=&end_date=&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELPAT`
    ).then((response) => {
      console.log(response);
      if (response?.status?.status) {
        setPatientData(response?.data);
        setPatientUpdate(false);
      }
    });
    setPatientUpdate(false);
  }, [patientUpdate]);

  return (
    <>
      <ManagePatient
        open={open}
        onHide={hideHandle}
        title={"Add Patient"}
        selectclient={selectclient}
        setModalShow={setModalShow}
      />
      <ToggleActivationPopup
        onHide={hideHandle}
        toggleActivateModalShow={toggleActivateModalShow}
        setToggleActivateModalShow={setToggleActivateModalShow}
      />
      <DeletePopup
        onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        selectclient={selectclient}
        setDeleteModalShow={setDeleteModalShow}
        setPatientUpdate={setPatientUpdate}
      />
      <div className={classes.pageTitleContainer}>
        <Typography className={classes.typo} weight="medium">
          Manage Patients
        </Typography>
        <div className={classes.groupBtn}>
          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
            <div
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
              onClick={() => setSearchOpen(!isSearchOpen)}
            >
              {/* <SearchIcon classes={{ root: classes.headerIcon }} /> */}
            </div>
            <InputBase
              ref={searchInputRef}
              placeholder="Enter Last Name"
              onChange={(e) => {
                setLastNameText(e.target.value);
                searchPatient();
              }}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
          </div>
          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
            <div
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
              onClick={() => setSearchOpen(!isSearchOpen)}
            >
              {/* <SearchIcon classes={{ root: classes.headerIcon }} /> */}
            </div>
            <InputBase
              ref={searchInputRef}
              placeholder="Enter Contact"
              onChange={(e) => {
                setPhoneText(e.target.value);
                searchPatient();
              }}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
          </div>
          <Button
            classes={{ root: classes.button }}
            variant="contained"
            color="secondary"
            style={{ marginRight: "4px" }}
            onClick={searchPatient}
          >
            Search
          </Button>
          {patientReadValue && patientReadValue === "N" && (
            <Button
              classes={{ root: classes.button }}
              variant="contained"
              color="secondary"
              onClick={addPatient}
            >
              {/* <img className={classes.editicons} src={editIcons} /> */}
              Add Patient
            </Button>
          )}
        </div>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {patientData && patientData?.length ? (
                patientData
                  .filter((row) => {
                    // Filter logic based on last name and phone number
                    const lastNameMatch = row?.last_name?.toLowerCase()?.includes(lastNameText.toLowerCase());
                    const phoneMatch = row?.contact_cell?.includes(phoneText);
                    return lastNameMatch && phoneMatch;
                  })
                  .map((row, i) => {
                    if (row) {
                      return (
                        <TableRow key={i} className={classes.tableBodyCell}>
                          <TableCell className={classes.tableBodyCell}>
                            {i + 1}
                          </TableCell>

                          <TableCell
                            align="left"
                            onClick={() =>
                              history.push(
                                `/app/patient-details/${row.patient_id}`
                              )
                            }
                            style={{
                              cursor: "pointer",
                              textAlign: "left",
                              paddingLeft: "16px",
                            }}
                            className={classes.tableBodyCell}
                          >
                            {`${row.first_name}`}
                          </TableCell>
                          <TableCell
                            className={classes.tableBodyCell}
                            style={{ textAlign: "left", paddingLeft: "16px" }}
                          >
                            {` ${row.last_name} `}
                          </TableCell>
                          <TableCell
                            className={classes.tableBodyCell}
                            style={{ textAlign: "left", paddingLeft: "16px" }}
                          >
                            {`${row.email}`}
                          </TableCell>
                          <TableCell className={classes.tableBodyCell}>
                            {row.contact_cell}
                          </TableCell>
                          <TableCell className={classes.tableBodyCell}>
                            {dateFormatConversion(row.dateofbirth)}
                          </TableCell>
                          <TableCell className={classes.tableBodyCell}>
                            {row.is_active ? (
                              row.is_active === "Y" ? (
                                <span
                                  style={{ color: "#10940D", fontWeight: 500 }}
                                >
                                  Active
                                </span>
                              ) : (
                                <span style={{ color: "red" }}>Inactive</span>
                              )
                            ) : (
                              <span style={{ color: "#F8A80C" }}>Pending</span>
                            )}
                          </TableCell>
                          <TableCell className={classes.tableBodyCell}>
                            <IconButton
                              id="basic-button"
                              aria-controls={
                                openDown ? "basic-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={openDown ? "true" : undefined}
                              onClick={(e) => {
                                handleClick(e);
                                setSelectRow(row);
                              }}
                            >
                              <MoreIcon />
                            </IconButton>
                            <Menu
                              className={classes.menuBox}
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={openDown}
                              onClose={handleClose}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                            >
                              <MenuItem
                                onClick={() =>
                                  history.push(
                                    `/app/patient-details/${selectRow?.patient_id}`
                                  )
                                }
                              >
                                View details
                              </MenuItem>
                              {patientReadValue && patientReadValue === "N" && (
                                <>
                                  <MenuItem
                                    onClick={() =>
                                      history.push(
                                        `/app/edit-patient/${selectRow?.patient_id}`
                                      )
                                    }
                                  >
                                    Edit
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => handleDelete(selectRow)}
                                  >
                                    Delete
                                  </MenuItem>
                                </>
                              )}
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
      <ApiResponsePopup
        {...{
          hideHandlerRes,
          isRecordUpdated: isShow,
          title: "Search Result",
          successMsg: successMessage,
          errorRes: errorApiMessage,
          // errorRes: apiError?.message,
        }}
      />
    </>
  );
}
