import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Link,
  Fade,
  FormGroup,
  Box,
  FormControlLabel,
  InputAdornment,
  Checkbox,
  IconButton,
} from "@material-ui/core";

import { withRouter } from "react-router-dom";
import moment from "moment";
import useStyles from "./styles";
// import logoCop from "../../images/logo.jpeg";
import logo from "../../images/logo.jpeg";
import E5 from "../../images/Eye02.jpeg";
// import logo from "../../images/logo.png";
import { saveState } from "../../hooks/helper";
import {
  loginWithCredential,
  postEmployeeAttendance,
} from "../../services/ServiceLayer";
import ApiResponsePopup from "../../components/common/modal/ApiResponsePopup";
import { toast } from "react-toastify";
import Loader from "../../components/common/loader/Loader";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

function Login(props) {
  const classes = useStyles();
  toast.configure();
  const [state, setState] = useState({
    successMessage: null,
    errorApiMessage: null,
    rememberMe: false,
    savedLoginValue: "",
    eyes: false,
    error: null,
    isShow: false,
    loginValue: "",
    passwordValue: "",
    isLoader: false,
  });

  useEffect(() => {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    if (rememberMe) {
      const savedLogin = localStorage.getItem("savedLoginValue");
      const savedPassword = localStorage.getItem("savedPasswordValue");
      if (savedLogin && savedPassword) {
        setState(prevState => ({
          ...prevState,
          loginValue: savedLogin,
          passwordValue: savedPassword,
          rememberMe: rememberMe,
        }));
      }
    }
  }, []);

  const togglePasswordVisibility = () => {
    setState(prevState => ({ ...prevState, eyes: !prevState.eyes }));
  };

  useEffect(() => {
    if (state.rememberMe) {
      console.log("Enabling 'Remember Me': Saving credentials.");
      localStorage.setItem('rememberMe', state.rememberMe);
      localStorage.setItem("savedLoginValue", state.loginValue);
      localStorage.setItem("savedPasswordValue", state.passwordValue);
    } else {
      console.log("Disabling 'Remember Me': Removing credentials.");
      localStorage.removeItem("rememberMe");
      localStorage.removeItem("savedLoginValue");
      localStorage.removeItem("savedPasswordValue");
    }
  }, [state.rememberMe, state.loginValue, state.passwordValue]);


  const handleRememberMeChange = (event) => {
    setState(prevState => ({
      ...prevState,
      rememberMe: event.target.checked,
    }));
  };


  const attendanceAction = async (profileData) => {
    const currentDate = moment(new Date()).format("YYYY-MM-DD hh:mm A");
    const param = {
      staff_atten_id: 0,
      staff_id: profileData.user_id,
      location_id: profileData.location_id,
      check_in_time: currentDate,
      check_out_time: "",
      lunch_st_time: "",
      lunch_et_time: "",
      updated_by: profileData.user_name,
      access_location: profileData.user_name,
      opt_type: "STAFCHKIN",
    };

    try {
      const response = await postEmployeeAttendance(param);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  const loginAction = async () => {
    setState((prevState) => ({ ...prevState, isLoader: true }));
    try {
      const payload = await loginWithCredential({
        userName: state.loginValue,
        password: state.passwordValue,
      });
      console.log("payload-", payload);
      if (payload?.status?.description === "") {
        sessionStorage.setItem("id_token", "1");
        saveState(payload.data);

        setTimeout(() => {
          const redirectUrl =
            state.passwordValue !== "Eth@docVision2023"
              ? "/app/dashboard"
              : `/app/change-password/${payload.data.user.user_id}/${payload.data.user.employee_name.trim()
                ? 0
                : payload.data.user.employee_name
              }`;
          window.location = redirectUrl;
          setState((prevState) => ({ ...prevState, isLoader: false }));
        }, 3000);

        attendanceAction(payload.data.user);

        if (state.rememberMe) {
          localStorage.setItem("savedLoginValue", state.loginValue);
        }
      } else {
        setState((prevState) => ({ ...prevState, isLoader: false }));
        setTimeout(() => {
          toast.error("Invalid Credential, Please try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 3000);
      }
    } catch (error) {
      setState((prevState) => ({ ...prevState, isLoader: false }));
      console.error(error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  return (
    <>
      {state.isLoader && <Loader />}

      <Grid container>
        <Grid item lg={6}
          style={{
            backgroundImage: `url(${E5})`,
            backgroundSize: "cover",
            backgroundPosition: "right",
          }}
        ></Grid>
        <Grid lg={6}
          style={{
            height: "100vh",
          }}
        >
          <div
            style={{
              // display: "flex",
              // justifyContent: "end",
            }}
          >
            <Box
              sx={{

                borderRadius: "5px",
                padding: "20px 5rem",
                boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                backgroundColor: "#FAFAFB",
                height: "100vh",
              }}
            >
              <Box sx={{ padding: "10px" }}>
                <React.Fragment>
                  <img
                    src={logo}
                    alt="logo"
                    height={"50px"}
                    className={classes.logo}
                  />
                  <Typography className={classes.loginSubTitle}>
                    Welcome to DocMed EHR
                  </Typography>
                  {/* <Typography variant="h3" className={classes.subGreeting}>Login</Typography> */}
                  {/* <Fade in={state.error}>
                    <Typography
                      color="secondary"
                      className={classes.errorMessage}
                    >
                      Something is wrong with your login or password :(
                    </Typography>
                  </Fade> */}
                  <Grid container>
                    <Grid item lg={12} md={12} xs={12}>
                      <Typography variant="h6" className={classes.txtHeading}>
                        User Name
                      </Typography>
                      <TextField
                        id="email"
                        InputProps={{
                          classes: {
                            border: classes.textFieldline,
                            input: classes.textField,
                          },
                        }}
                        name="loginValue"
                        value={state.loginValue}
                        onChange={handleChange}
                        placeholder="Enter username"
                        type="email"
                        variant="outlined"
                        fullWidth
                        size="small"
                      />
                      <Typography
                        variant="h6"
                        className={classes.txtHeading}
                        style={{ marginTop: "16px" }}
                      >
                        Password
                      </Typography>
                      <TextField
                        id="password"
                        name="passwordValue"
                        value={state.passwordValue}
                        onChange={handleChange}
                        placeholder="Password"
                        type={state.eyes ? "text" : "password"} // Dynamically setting the type based on the eyes state
                        variant="outlined"
                        fullWidth
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={togglePasswordVisibility} edge="end">
                                {state.eyes ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={0.5}>
                    <Grid item lg={12} md={12} sx={12} sm={12}>
                      <div className={classes.imageContainer}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={state.rememberMe}
                                onChange={handleRememberMeChange}
                              />
                            }
                            label="Remember me"
                          />
                        </FormGroup>
                        <Link
                          href="/forget-password"
                          className={classes.Link}
                          underline="none"
                        >
                          Forgot password?
                        </Link>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item lg={12}>
                    <div className={classes.creatingButtonContainer}>
                      <Button
                        onClick={loginAction}
                        size="large"
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.createAccountButton}
                      >
                        Login
                      </Button>
                    </div>
                  </Grid>
                </React.Fragment>
              </Box>
            </Box>
          </div>

          <ApiResponsePopup
            hideHandlerRes={() =>
              setState((prevState) => ({ ...prevState, isShow: false }))
            }
            isRecordUpdated={state.isShow}
            title="Login"
            successMsg={state.successMessage}
            errorRes={state.errorApiMessage}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default withRouter(Login);
