import React, { useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";

import useStyles from "./styles";

import Health from "./Health";
import VisitReason from "./VisitReason";
import Loader from "../common/loader/Loader";

export default function Problem({
  reasonType,
  patientId,
  impactedPart,
  frequency,
  severity,
  symptons,
  factors,
  examSince,
  occurseWhile,
  setPatientProfileData,
  examProblemStatus,
  patientData,
  setProblemBtnUnable,
  problemBtnUnable,
}) {
  const classes = useStyles();
  const [isLoader, setIsLoader] = useState(false); // Changed to false to hide loader by default
  const [expandedPanel1, setExpandedPanel1] = useState('panel1'); // Set to 'panel1' to have it open by default
  const [expandedPanel2, setExpandedPanel2] = useState('panel2'); // Set to 'panel2' to have it open by default

  const handleChangePanel1 = (event, isExpanded) => {
    setExpandedPanel1(isExpanded ? 'panel1' : false); // Changed true to false to correctly handle collapse
  };

  const handleChangePanel2 = (event, isExpanded) => {
    setExpandedPanel2(isExpanded ? 'panel2' : false); // Changed true to false to correctly handle collapse
  };

  return (
    <>
      {isLoader && <Loader />}
      <Accordion expanded={expandedPanel1 === 'panel1'} onChange={handleChangePanel1}>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Visit Reason</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <VisitReason
            patientId={patientId}
            reasonType={reasonType}
            impactedPart={impactedPart}
            frequency={frequency}
            severity={severity}
            symptons={symptons}
            factors={factors}
            examSince={examSince}
            occurseWhile={occurseWhile}
            setPatientProfileData={setPatientProfileData}
            patientData={patientData}
            setProblemBtnUnable={setProblemBtnUnable}
            problemBtnUnable={problemBtnUnable}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expandedPanel2 === 'panel2'} onChange={handleChangePanel2}>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Problem History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Health
            setIsLoader={setIsLoader}
            patientId={patientId}
            examProblemStatus={examProblemStatus}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
