import React, { useState } from "react";
import CanvasImage from "./CanvasImage";
import EysImage from "../../../assets/eyesImage.png";
import InternalEysImage from "../../../assets/fundus.png";
import Paint from "../../../assets/paint.png";
import { Button } from "@material-ui/core";
export default function InternalGlyph({ patientId }) {
  const [width, setWidth] = useState("900px");
  const [height, setHeight] = useState("500px");
  const [outer, setOuter] = useState(true);
  const [iner, setIner] = useState(false);
  const showOuter = () => {
    setOuter(true);
    setIner(false);
  };
  const showInner = () => {
    setOuter(false);
    setIner(true);
  };
  return (
    <>
    <div>
      {/* <div style={{position: 'absolute',right: '16px',}}><Button onClick={iner ? showOuter : showInner}><img style={{width:'36px',height:'36px'}} src={Paint} alt="img"/></Button></div> */}
      {outer && (
        <CanvasImage
          patientId={patientId}
          image={EysImage}
          width={width}
          height={height}
        />
      )}
      {iner && (
        <CanvasImage
          patientId={patientId}
          image={InternalEysImage}
          width={width}
          height={height}
        />
      )}
      </div>
    </>
  );
}
