import React from "react";

// Model
import {
  Button,
  Typography,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Popup from "../../components/common/modal/Popup";
import alertIcon from "../../images/alert.png";
// styles
import useStyles from "../../components/Settings/Company/styles";
import { toast } from "react-toastify";


export default function AlertPopup({
  onHide,
  alertShow,
  setAlertShow,
  selectclient,setIsTimerRunning,isTimerRunning,setStopTimer,setTimerSeconds
}) {
  console.log("selectclient", selectclient);
  console.log("alertShow", alertShow);

  const [open, setOpen] = React.useState(false);
  var classes = useStyles();

  
  toast.configure();
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setAlertShow(false);
    setOpen(false);
  };
 
const timerSetHandler=()=>{
  setIsTimerRunning(true);
 
  hideHandler();
}
 const stopTimerHandler =()=>{
  setStopTimer(true);
  // setTimerSeconds('')
  hideHandler();
 }

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={alertShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={isTimerRunning ? stopTimerHandler :timerSetHandler}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <img src={alertIcon} alt="img"/>
            <Typography className={classes.formActiveHeading}>
              Are you sure! You want to {isTimerRunning ? 'stop' :'start'} dialation?
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
