import React, { useEffect } from "react";

// Model
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
// styles
export default function ApiResponsePopup({
  hideHandlerRes = () => {},
  isRecordAdded = false,
  isRecordUpdated = false,
  isUploading = false,
  successMsg = "Successfully updated!",
  title = "",
  errorRes = null,
}) {
  useEffect(() => {
    if (isRecordAdded || isRecordUpdated || isUploading) {
      setTimeout(() => {
        hideHandlerRes();
      }, 3000);
    }
  }, [isRecordAdded,isRecordUpdated, isUploading, hideHandlerRes]);
  return (
    <Dialog
      open={isRecordAdded || isRecordUpdated}
      onClick={(e) => hideHandlerRes(e)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {errorRes !== null ? (
            <Alert severity="error">{errorRes}</Alert>
          ) : (
            <Alert severity="success">{successMsg}</Alert>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={(e) => hideHandlerRes(e)}>Disagree</Button> */}
        <Button variant="outlined" size="large" onClick={(e) => hideHandlerRes(e)} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
