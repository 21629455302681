import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  loader: {
    background: `rgba(255,255,255,0.7)`,

    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    zIndex: 1,
  },
  img: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));
