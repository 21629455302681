import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
// components

import PageTitle from "../../PageTitle/PageTitle";
import {
  currentUser,
  postFlagBooleanVariable,
  loadState,
} from "../../../hooks/helper";
import {
  getUserGroupAndUser,
  getStaffAvailability,
  getHistoryConfigQuestion,
  postFamilyQuestionnairs,
} from "../../../services/ServiceLayer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// styles
import useStyles from "./styles";
export default function HistoryConfig() {
  var classes = useStyles();

  const [doctorValue, setDoctorValue] = React.useState("");
  const [departmentValue, setDepartmentValue] = React.useState("");
  const [groupValue, setGroupValue] = React.useState("");
  const [selectsubGroup, setSelectSubGroup] = useState([]);
  const [selectQuestions, setSelectQuestions] = useState([]);
  const [allStaffList, setAllStaffList] = useState([]);
  //
  const [questionGroups, setQuestionGroups] = useState([]);
  const [selectQuestionGroup, setSelectQuestionGroup] = useState([]);
  const [selectedQuestionGroup, setSelectedQuestionGroup] = useState("");
  const [opticalOptions, setOpticalOptions] = useState([]);

  //
  const [selectSubQuestionGroup, setSelectSubQuestionGroup] = useState([]);
  const [selectAllQuestionGroup, setSelectAllQuestionGroup] = useState([]);
  // list meta data
  const [listData, setListData] = useState([]);

  const [languageValues, setLanguageValues] = useState([]);
  const [cmpTypeValues, setCmpTypeValues] = useState([]);
  const [empTypeValues, setEmpTypeValues] = useState([]);
  const [genderValues, setGenderValues] = useState([]);
  const [hospitalValues, setHospitalValues] = useState([]);
  // call meta data
  const metaData = loadState().metaData;

  useEffect(() => {
    console.log("loadState", metaData);
    setListData(metaData);
  }, []);

  useEffect(() => {
    console.log("listData", listData);
    if (listData.length > 0) {
      const empTypeValues = listData[0].empType;
      const languageValues = listData[0].language;
      const genderValues = listData[0].gender;
      const cmpTypeValues = listData[0].hosdept;
      const hospitalValues = listData[0].hospitalType;
      setEmpTypeValues(empTypeValues);
      setLanguageValues(languageValues);
      setGenderValues(genderValues);
      setCmpTypeValues(cmpTypeValues);
      setHospitalValues(hospitalValues);
    }
  }, [listData]);

  useEffect(() => {
    getStaffAvailability().then((response) => {
      console.log("response", response);
      setAllStaffList(response?.data);
    });
  }, []);
  const handleDoctor = (event) => {
    const selectedDoctorId = event.target.value;
    setDoctorValue(selectedDoctorId);

    // Find the selected doctor's department_name from the allStaffList
    const selectedDoctor = allStaffList.find(
      (item) => item.staff_id === selectedDoctorId
    );
    if (selectedDoctor) {
      setDepartmentValue(selectedDoctor.department_name);
    }
  };
  const handleDepartment = (event) => {
    setDepartmentValue(event.target.value);
  };
  const handleGroup = (event) => {
    setGroupValue(event.target.value);
  };
  const handleSubGroup = (event) => {
    console.log("event", event.target.value);
    setSelectSubGroup(event.target.value);
  };
  const handleQuestion = (event) => {
    setSelectQuestions(event.target.value);
  };

  // questions
  useEffect(() => {
    getHistoryConfigQuestion().then((response) => {
      const questionGroups = Object.keys(response?.data["OPTICAL"]);
      setSelectQuestionGroup(questionGroups);
      setSelectedQuestionGroup(questionGroups[0]); // Set the initial selected group
    });
  }, []);

  const handleQuestionGroupChange = (event) => {
    console.log("event", event.target.value);
    setSelectedQuestionGroup(event.target.value);
  };

  useEffect(() => {
    if (selectedQuestionGroup) {
      getHistoryConfigQuestion().then((response) => {
        const opticalData = response?.data?.OPTICAL;
        const neurologicalObject = opticalData[selectedQuestionGroup];
        console.log("neurologicalObject", neurologicalObject);
        setSelectSubQuestionGroup(neurologicalObject);
      });
    }
  }, [selectedQuestionGroup]);

  useEffect(() => {
    console.log("selectQuestionGroup", selectQuestionGroup);
    console.log("selectedQuestionGroup", selectedQuestionGroup);
    console.log("selectSubQuestionGroup", selectSubQuestionGroup);
  }, [selectQuestionGroup, selectedQuestionGroup, selectSubQuestionGroup]);

  useEffect(() => {
    if (selectedQuestionGroup && selectsubGroup) {
      getHistoryConfigQuestion().then((response) => {
        const opticalData = response?.data?.OPTICAL;
        console.log("opticalData", opticalData);
        console.log("selectedQuestionGroup", selectedQuestionGroup);
        console.log("selectsubGroup", selectsubGroup);
        if (selectsubGroup) {
          const selectedSubGroupObject =
            opticalData[selectedQuestionGroup][selectsubGroup];
          console.log("selectedSubGroupObject", selectedSubGroupObject);
          setSelectAllQuestionGroup(selectedSubGroupObject);
        }
      });
    }
  }, [selectedQuestionGroup, selectsubGroup]);

  useEffect(() => {
    console.log("selectsubGroup", selectsubGroup);
    console.log("selectAllQuestionGroup", selectAllQuestionGroup);
  }, [selectsubGroup, selectAllQuestionGroup]);

  const [selectedQuestionCheckboxes, setSelectedQuestionCheckboxes] = useState(
    {}
  );

  const handleQuestionCheckboxChange = (questionId, isChecked) => {
    setSelectedQuestionCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [questionId]: isChecked,
    }));
  };

  const submitFormData = () => {
    const questionStatusValue = selectAllQuestionGroup.map((item) =>
      selectedQuestionCheckboxes[item.question_id] ? "Y" : "N"
    );

    const selectedQuestionsIds = selectAllQuestionGroup
      .map((item) => item.question_id)
      .join(",");

    const formData = {
      staff_id: doctorValue,
      group_key: selectedQuestionGroup,
      question_id: selectedQuestionsIds,
      question_status: questionStatusValue.join(","),
      is_active: "Y",
      updated_by: currentUser(), // Assuming you have a function to get the current user's username
      access_location: "ip address", // Replace with the actual IP address
      opt_type: "ADDHIST",
    };
    console.log("formData", formData);
    // Send `formData` to your API endpoint
    postFamilyQuestionnairs(formData)
      .then((response) => {
        console.log("Response from API:", response);
        if (response?.status?.status) {
          toast.success("History config added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setDoctorValue("");
          setDepartmentValue("");
          setGroupValue("");
          setSelectSubGroup([]);
          setSelectQuestions([]);
          setSelectedQuestionGroup("");
          setSelectAllQuestionGroup([]);
          setSelectedQuestionCheckboxes({});
        } else {
          toast.error("Something wrong please try after some time", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        console.error("Error sending data:", error);
        // Handle error response or any other actions
      });
  };

  return (
    <>
      <ToastContainer position="top-right" />
      <PageTitle subtitle="History Config" />
      <div className={classes.widgetBox}>
        <Grid container spacing={2} className={classes.formBox}>
          <Grid item xs={12} md={3} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Doctor</Typography>
            <FormControl fullWidth className={classes.dropdownFormControl}>
              <Select
                className={classes.selectDropdown}
                value={doctorValue}
                onChange={handleDoctor}
              >
                <MenuItem disabled value="">
                  Select
                </MenuItem>
                {allStaffList &&
                  allStaffList.map((item) => (
                    <MenuItem value={item.staff_id}>{item.staff_name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3} className={classes.textGroup}>
            <Typography className={classes.formHeading}>Department</Typography>
            <TextField
              disabled
              required
              name="departmentValue"
              placeholder="Enter elements"
              size="small"
              className={classes.txtForm}
              fullWidth
              variant="outlined"
              value={departmentValue}
            />
          </Grid>
          <Grid item xs={12} md={3} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Question Group
            </Typography>
            <FormControl fullWidth className={classes.dropdownFormControl}>
              <Select
                className={classes.selectDropdown}
                value={selectedQuestionGroup}
                onChange={handleQuestionGroupChange}
              >
                <MenuItem disabled value="">
                  Select
                </MenuItem>
                {selectQuestionGroup.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3} className={classes.textGroup}>
            <Typography className={classes.formHeading}>
              Question Sub-Group
            </Typography>
            <FormControl
              fullWidth
              className={classes.dropdownFormControl}
              style={{ padding: "4px" }}
            >
              <Select
                style={{ fontSize: "12px" }}
                value={selectsubGroup}
                onChange={handleSubGroup}
                className={classes.selectDropdown}
              >
                <MenuItem disabled value="">
                  <em>Select</em>
                </MenuItem>

                {Object.keys(selectSubQuestionGroup).map((groupKey) => {
                  return (
                    <MenuItem key={groupKey} value={groupKey}>
                      {groupKey}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={12} className={classes.textGroup}>
            <Grid
              container
              spacing={0.5}
              className={classes.formBox}
              style={{
                marginTop: "6px",
                padding: "6px",
                background: "#4E97FD",
              }}
            >
              <Grid item xs={12} md={5} className={classes.textGroup}>
                <Typography
                  className={classes.formHeading}
                  style={{ color: "#fff" }}
                >
                  Questions
                </Typography>
              </Grid>
              <Grid item xs={12} md={5} className={classes.textGroup}>
                <Typography
                  className={classes.formHeading}
                  style={{ color: "#fff" }}
                >
                  Select Question
                </Typography>
              </Grid>
            </Grid>
            <>
              <Grid
                container
                spacing={0.5}
                className={classes.formBox}
                style={{ marginTop: "6px" }}
              >
                {selectAllQuestionGroup && selectAllQuestionGroup.map((item) => (
                  <>
                    <Grid item xs={12} md={5} className={classes.textGroup}>
                      <Typography className={classes.formHeading}>
                        {item.question}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={5} className={classes.textGroup}>
                      <Checkbox
                        checked={
                          selectedQuestionCheckboxes[item.question_id] || false
                        }
                        onChange={(event) =>
                          handleQuestionCheckboxChange(
                            item.question_id,
                            event.target.checked
                          )
                        }
                      />
                    </Grid>
                  </>
                ))}
              </Grid>
            </>
          </Grid>
        </Grid>
      </div>
      <div className={classes.footerBox}>
        <Button variant="outlined" size="large" className={classes.leftButton}>
          Cancel
        </Button>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          className={classes.rightButton}
          onClick={submitFormData}
        >
          Add
        </Button>
      </div>
    </>
  );
}
