import React from "react";

import TaxEditForm from "../../components/Settings/Company/TaxEditForm";

export default function TaxEdit() {
  return (
    <div>
      <TaxEditForm />
    </div>
  );
}
