import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Button,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  Typography,
  Link,
  InputBase,
  TextField,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import * as Icons from "@material-ui/icons";
import {
  MoreVert as MoreIcon,
  Search as SearchIcon,
  Lens,
} from "@material-ui/icons";
import TableWidget from "../Widget/TableWidget";
import TableDesign from "../common/table/TableDesign";
import useStyles from "../Settings/Tax/styles";

import classNames from "classnames";
import ManagerService from "./Services/ManageServices";
import { getInvtService } from "../../services/ServiceLayer";
import { loadState } from "../../hooks/helper";
import DeleteServicePopup from "./DeleteServicePopup";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";
class PrintableServiceTable extends React.Component {
  render() {
    const { data } = this.props;

    return (
      <div>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Service Name</th>
              <th>Retail Price (Item Cost)</th>
              <th>Commission Amount (Spiff Amount)</th>
              <th>Service Frequency</th>
              <th>Service Duration</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{row.service_name}</td>
                <td>
                  {row.retail_price} ({row.item_cost})
                </td>
                <td>
                  {row.commission_amount} ({row.spiff_amount})
                </td>
                <td>{row.service_frequency_val}</td>
                <td>{row.service_duration}</td>
                <td>
                  {row.is_active === "Y" ? (
                    <span style={{ color: "green" }}>Active</span>
                  ) : (
                    <span style={{ color: "red" }}>Inactive</span>
                  )}
                </td>
                <td>
                  <IconButton>
                    <MoreIcon />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
export default function ServicesList({setActiveTabValue}) {
  const history = useHistory();
  var classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openModel, setOpenModel] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [modelShow, setModalShow] = useState(false);
  var [isSearchOpen, setSearchOpen] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const [serviceListData, setServiceListData] = useState([]);
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [selectclient, setselectclient] = useState(null);
  const openDown = Boolean(anchorEl);

  const [open, setOpen] = React.useState(false);

  const [title, setTitle] = useState(null);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [selectRow, setSelectRow] = useState(null);
  const [inventoryReadValue, setInventoryReadValue] = useState();
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const userData = loadState().user;
  const readAccessData = loadState().menu;
  const userRole = loadState().user.user_role;



  useEffect(() => {
    console.log("readAccessData", readAccessData);
    const inventorySection = readAccessData.find(
      (item) => item.label === "Inventory"
    );
    if (inventorySection) {
      const inventoryChildren = inventorySection.children;

      const inventoryReadAcess = inventoryChildren.find(
        (child) => child.label === "Inventory"
      ).read_access;
      setInventoryReadValue(inventoryReadAcess);
    }
  }, [readAccessData]);
  const deleteEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);

    setselectclient(rec);
    setDeleteModalShow(true);
    setTitle("Delete Frame");
  };
  useEffect(() => {
    getInvtService(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSRV`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setIsLoader(false);
        setServiceListData(response?.data);
      } else {
        setIsLoader(false);
      }
    });
  }, []);
  useEffect(() => {
    getInvtService(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSRV`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setIsLoader(false);
        setServiceListData(response?.data);
      } else {
        setIsLoader(false);
      }
    });
    setDataUpdated(false)
  }, [dataUpdated]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // setAnchorE2(null);
    setAnchorPosition(null);
  };
  const handleTopClick = (event) => {
    setAnchorPosition({
      top: event.clientY,
      left: event.clientX,
    });
  };

  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Service Name",
      secondaryLabel: "(Code)",
      // align: "left !important",
    },
    // {
    //   label: "Problem Name",
    //   secondaryLabel: "Code",
    // },
    {
      label: "Retail Price ",
    },

    {
      label: "Service Frequency",
    },
    {
      label: "Duration ",
      secondaryLabel: "(Service Color)",
    },
    {
      label: "Status ",
    },

    {
      label: "Action",
    },
  ];

  const addService = () => {
    history.push(`/app/manage-service`);
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    setOpenModel(false);
    setOpen(false);

    setDataUpdated(true);
  };
  const handleRowClick = (row) => {
    setSelectedRow(row);
  };

  // Create a function to navigate to the edit page with selected row data
  const handleEditClick = (event) => {
    if (selectedRow) {
      console.log("selectedRow", selectedRow);
      // Use React Router to navigate to the edit page
      history.push(`/app/service-details/${selectedRow.service_id}`, {
        selectedRow,
        event,
      });
    }
  };

  //searching
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };


  const filteredServiceList = serviceListData.filter((item) => {
    // Check if each property is not null or undefined before using toLowerCase()
    const serviceName = item.service_name ? item.service_name.toLowerCase() : "";
    const serviceCode = item.service_code ? item.service_code.toLowerCase() : "";
    const serviceProblemVal = item.problem_code_val ? item.problem_code_val.toLowerCase() : "";
    

    return (
      serviceName.includes(searchTerm.toLowerCase()) ||
      serviceCode.includes(searchTerm.toLowerCase()) ||
      serviceProblemVal.includes(searchTerm.toLowerCase()) 
     
    );
  });



  return (
    <>
      <DeleteServicePopup
        onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        selectclient={selectclient}
        setDeleteModalShow={setDeleteModalShow}
      />
      <ManagerService 
        open={openModel}
        title={selectclient ? "Edit Service" : "New Service"}
        onHide={hideHandle}
        setModalShow={setModalShow}
      />
      <div className={classes.pageTitleContainer}>
        <Typography className={classes.typo} weight="medium">
          Service List
        </Typography>
      </div>
      <div className={classes.pageTitleContainer} style={{justifyContent:'flex-end'}}>
        <Typography className={classes.typo} weight="medium">
          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
            <div
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
              onClick={() => setSearchOpen(!isSearchOpen)}
            >
              <SearchIcon classes={{ root: classes.headerIcon }} />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              onChange={handleChange}
            />
          </div>
        </Typography>
        <div className={classes.groupBtn}>
          {inventoryReadValue === "N" && (
            <Tooltip title="add" onClick={addService}>
              <Icons.Add />
            </Tooltip>
          )}

          <ReactToPrint
            trigger={() => (
              <Tooltip title="Print">
                <Icons.LocalPrintshop />
              </Tooltip>
            )}
            content={() => <PrintableServiceTable data={filteredServiceList} />}
          />
          <CSVLink
            data={filteredServiceList}
            filename="service_data.csv"
            className={classes.menuItem}
          >
            <Tooltip title="Download">
              <Icons.Archive />
            </Tooltip>
          </CSVLink>
        </div>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {filteredServiceList && filteredServiceList.length ? (
                filteredServiceList.map((row, i) => {
                  if (row)
                    return (
                      <TableRow
                        key={i}
                        className={classes.tableBodyCell}
                        onClick={() => handleRowClick(row)}
                      >
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                          style={{ cursor: "pointer" }}
                          
                        >
                          {row.service_name}
                          <br />
                          {row.service_code && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.service_code})
                            </span>
                          )}
                        </TableCell>
                        {/* <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                        >
                          {row.problem_code_val}
                          <br />
                          {row.problem_code && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.problem_code})
                            </span>
                          )}
                        </TableCell> */}
                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {row.retail_price}
                        </TableCell>

                        <TableCell className={classes.tableBodyCell}>
                          {row.service_frequency_val}
                        </TableCell>

                        <TableCell className={classes.tableBodyCell}>
                          {row.service_duration}
                          <br />
                          {row.service_color && (
                            <span style={{ color: "#4E97FD" }}>
                              ({row.service_color})
                            </span>
                          )}
                        </TableCell>

                        <TableCell className={classes.tableBodyCell}>
                          {row.is_active === "Y" ? (
                            <span style={{ color: "green" }}>Active</span>
                          ) : (
                            <span style={{ color: "red" }}>Inactive</span>
                          )}
                        </TableCell>

                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                          >
                            <MenuItem
                              onClick={() => handleEditClick("View/Update")}
                              className={classes.menuItem}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                               {inventoryReadValue === "N" ? (
                                <>View/Update</>
                              ):(<>View Details</>)}
                            </MenuItem>
                            {inventoryReadValue === "N" && (
                              <>
                                <MenuItem
                                  onClick={() => deleteEventHandler(selectRow)}
                                  className={classes.menuItem}
                                >
                                  <Lens
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                      marginRight: "4px",
                                    }}
                                  />
                                  {selectRow && selectRow.is_active === "Y" ? (
                                    <>Delete</>
                                  ) : (
                                    <>Re-activate</>
                                  )}
                                </MenuItem>

                                <MenuItem
                                  onClick={() => handleEditClick("Copy")}
                                  className={classes.menuItem}
                                >
                                  <Lens
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                      marginRight: "4px",
                                    }}
                                  />{" "}
                                  Copy
                                </MenuItem>
                                <MenuItem className={classes.menuItem}>
                                  <Lens
                                    style={{
                                      height: "4px",
                                      width: "4px",
                                      marginRight: "4px",
                                    }}
                                  />{" "}
                                  Recall
                                </MenuItem>
                              </>
                            )}
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
