import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  Grid,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Select,
  FormControl,
  FormGroup,
  Checkbox,
  Button,
  Avatar,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
// data

// Model
import { otherFormElements } from "../inventory_constant";
import { image } from "../../../assets/gallery.jpg";
import {
  currentUser,
  postFlagBooleanVariable,
  loadState,
} from "../../../hooks/helper";
import useStyles from "./styles";
// components

import moment from "moment";
import PageTitle from "../../PageTitle/PageTitle";
import LocationList from "./LocationList";
import TransactionList from "./TransactionList";
import MaterialsList from "./MaterialsList";
import {
  postFrameLense,
  getProcedureCode,
  getSupplierDetail,
  getManufacturerDetail,
  getInvtService,
  getServiceMaster,
  postAccessories,
} from "../../../services/ServiceLayer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ManageProcedureCost from "./ManageProcedureCost";
export default function AddOthers() {
  const history = useHistory();
  const { state } = useLocation();
  const selectedRow = state?.selectedRow;
  const eventType = state?.event;

  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();
  var [activeTabId, setActiveTabId] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModel, setOpenModel] = React.useState(false);
  const [modelShow, setModalShow] = useState(false);
  const [listData, setListData] = useState([]);
  const [invSourceData, setInvSourceData] = useState([]);
  const [structureList, setStructureList] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [frameTypeData, setFrameTypeData] = useState([]);
  const [rimTypeData, setRimTypeData] = useState([]);
  const [frameShapeData, setFrameShapeData] = useState([]);
  const [frameMaterialData, setFrameMaterialData] = useState([]);
  const [profitValue, setProfitValue] = useState(0);
  const [grossPercentage, setGrossPercentage] = useState(0);
  const [prodecureCodeData, setProdecureCodeData] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [procedureCodeExtension, setProcedureCodeExtension] = useState("");
  const [manufacturerData, setManufacturerData] = useState([]);
  const [inventoryValue, setInventoryValue] = useState(0);
  const [supplierData, setSupplierData] = useState([]);
  const [returnableData, setReturnableData] = useState([]);
  const [accessriesUnit, setAccessriesUnit] = useState([]);
  const [accessriesFeature, setAccessriesFeature] = useState([]);
  const [serviceTypeValues, setServiceTypeValues] = useState([]);
  const [accessriesSize, setAccessriesSize] = useState([]);
  const [inventoryReadValue, setInventoryReadValue] = useState();
  const [actionType, setActionType] = useState("ADDACS");
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [isActionExit, setIsActionExit] = useState(false);
  // call meta data
  const metaData = loadState().metaData;
  const readAccessData = loadState().menu;

  useEffect(() => {
    console.log("readAccessData", readAccessData);
    const inventorySection = readAccessData.find(
      (item) => item.label === "Inventory"
    );
    if (inventorySection) {
      const inventoryChildren = inventorySection.children;

      const inventoryReadAcess = inventoryChildren.find(
        (child) => child.label === "Inventory"
      ).read_access;
      setInventoryReadValue(inventoryReadAcess);
    }
  }, [readAccessData]);
  useEffect(() => {
    console.log("loadState", metaData);
    setListData(metaData);
  }, []);

  useEffect(() => {
    if (listData.length > 0) {
      const genderData = listData[0].gender;
      const invSourceData = listData[0].InventorySource;
      const frameTypeData = listData[0].FrameType;
      const rimTypeData = listData[0].RimType;
      const frameShapeData = listData[0].FrameShape;
      const frameMaterialData = listData[0].FrameMaterial;
      const AccessriesUnitData = listData[0].AccessriesUnit;
      const AccessriesFeatureData = listData[0].AccessriesFeature;
      const AccessriesSizeData = listData[0].AccessriesSize;
      const serviceTypeValues = listData[0].serviceType;
      const returnableData = listData[0].InventoryReturn;
      setGenderData(genderData);
      setInvSourceData(invSourceData);
      setFrameTypeData(frameTypeData);
      setRimTypeData(rimTypeData);
      setFrameShapeData(frameShapeData);
      setFrameMaterialData(frameMaterialData);
      setAccessriesUnit(AccessriesUnitData);
      setAccessriesFeature(AccessriesFeatureData);
      setAccessriesSize(AccessriesSizeData);
      setServiceTypeValues(serviceTypeValues);
      setReturnableData(returnableData)
    }
  }, [listData]);

  useEffect(() => {
    getServiceMaster(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSTR&structure_id=0`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setStructureList(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
    getInvtService(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSRV`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setProdecureCodeData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
    getManufacturerDetail(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELMAN&manufacturer=0`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setManufacturerData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
  }, []);

  useEffect(() => {
    getSupplierDetail(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSUP&supplier_id=0`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setSupplierData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
  }, []);

  const [accesseriesDetail, setAccesseriesDetail] = useState({
    accessries_id: "0",
    accessries_name: "",
    location_id: userData?.location_id,
    accessries_type: "",
    accessries_color: "",
    qty_purchased: "",
    qty_sold: "",
    qty_inventory: "",
    retail_price: "",
    item_cost: "",
    manufacturer_id: "",
    material_id: "",
    feature_id: "",
    size_id: "",
    width: "",
    height: "",
    unit_id: "",
    tax_1:"",
      tax_2:"",
    custome_field1: "",
    custome_field2: "",
    custome_field3: "",
    custome_field4: "",
    custome_field5: "",
    returnable_id: "",
    supplier_id:"",
    updated_by: currentUser(),

    access_location: "access_location",
    opt_type: actionType,
  });
  useEffect(() => {
    if (selectedRow) {
      setAccesseriesDetail({
        accessries_id: eventType === "Copy" ? "0" : selectedRow?.accessries_id,
        accessries_name: selectedRow?.accessries_name,
        location_id: userData?.location_id,
        accessries_type: selectedRow?.accessries_type,
        accessries_color: selectedRow?.accessries_color,
        qty_purchased: selectedRow?.qty_purchased,
        qty_sold: selectedRow?.qty_sold,
        qty_inventory: selectedRow?.qty_inventory,
        retail_price: selectedRow?.retail_price,
        item_cost: selectedRow?.item_cost,
        manufacturer_id: selectedRow?.manufacturer_id,
        material_id: selectedRow?.material_id,
        tax_1:selectedRow?.tax_1,
        tax_2:selectedRow?.tax_2,
        
        feature_id: selectedRow?.feature_id,
        size_id: selectedRow?.size_id,
        width: selectedRow?.width,
        height: selectedRow?.height,
        unit_id: selectedRow?.unit_id,
        custome_field1: selectedRow?.custome_field1,
        custome_field2: selectedRow?.custome_field2,
        custome_field3: selectedRow?.custome_field3,
        custome_field4: selectedRow?.custome_field4,
        custome_field5: selectedRow?.custome_field5,
        returnable_id: selectedRow?.returnable_id,
        supplier_id:selectedRow?.supplier_id,
        updated_by: currentUser(),

        access_location: "access_location",
        opt_type: eventType === "Copy" ? "ADDACS" : "UPDACS",
      });
      setSelectedProcedure({
        service_code: selectedRow?.service_code,
        service_code_extension: selectedRow?.service_name,
      });
    }
  }, [selectedRow]);
  useEffect(() => {
    const retailPrice = parseFloat(accesseriesDetail.retail_price) || 0;
    const itemCost = parseFloat(accesseriesDetail.item_cost) || 0;
    const commissionAmount =
      parseFloat(accesseriesDetail.commission_amount) || 0;

    const calculatedProfit = retailPrice - (itemCost + commissionAmount);
    setProfitValue(calculatedProfit);

    //
    const calculatedGrossPercentage = (commissionAmount / retailPrice) * 100;
    setGrossPercentage(calculatedGrossPercentage);
  }, [
    accesseriesDetail.retail_price,
    accesseriesDetail.item_cost,
    accesseriesDetail.commission_amount,
  ]);
  const handleCommissionTypeChange = (e) => {
    console.log("Commission Type Change Triggered");
    const newComissionType = e.target.value;
    console.log("New Commission Type:", newComissionType);

    setAccesseriesDetail({
      ...accesseriesDetail,
      is_commission_prod: newComissionType,
      commission_amount: newComissionType === "Y" ? "" : "",
    });
  };
  const clearForm = () => {
    // Define a function to clear the form fields
    setAccesseriesDetail({
      accessries_id: "0",
      accessries_name: "",
      location_id: userData?.location_id,
      accessries_type: "",
      accessries_color: "",
      qty_purchased: "",
      qty_sold: "",
      qty_inventory: "",
      retail_price: "",
      item_cost: "",
      manufacturer_id: "",
      material_id: "",
      feature_id: "",
      size_id: "",
      width: "",
      height: "",
      unit_id: "",
      tax_1:"",
      tax_2:"",
      custome_field1: "",
      custome_field2: "",
      custome_field3: "",
      custome_field4: "",
      custome_field5: "",
      returnable_id: "",
      supplier_id:"",
      updated_by: currentUser(),

      access_location: "access_location",
      opt_type: actionType,
    });
    setSelectedProcedure(null);
  };

  const goBack = () => {
    setTimeout(() => {
      history.push(`/app/inventory/${activeTabVal}`);
    }, 3000);
  };
  
useEffect(()=>{
  if(isActionCalled){
    postAccessories(accesseriesDetail).then((response) => {
      console.log(response);
      if (response.status.status) {
        toast.success("Accesseris added successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        clearForm();
        if (isActionExit) {
          goBack();
        }
        setIsActionExit(false);
      } else {
        toast.error(response?.status?.description, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsActionExit(false);
      }
    });
  }else{
    setIsActionCalled(false);
  }
  setIsActionCalled(false);
  setIsActionExit(false);

},[isActionCalled])
  const handleSaveAndMore = () => {
    console.log("accesseriesDetail", accesseriesDetail);
   
    setIsActionCalled(true);
    // clearForm(); // Clear form fields
  };

  const handleSaveAndExit = () => {
    
    setIsActionCalled(true);
    setIsActionExit(true);
  };
  const activeTabVal = 3;
  // setIsActionExit(true);
  const handleCancel = () => {
    history.push(`/app/inventory/${activeTabVal}`)
  };
  useEffect(() => {
    // Your existing code here ...

    // Calculate the inventory value
    const purchased = parseFloat(accesseriesDetail.qty_purchased) || 0;
    const sold = parseFloat(accesseriesDetail.qty_sold) || 0;
    const inventoryValue = purchased - sold;
    setInventoryValue(inventoryValue);
  }, [
    accesseriesDetail.retail_price,
    accesseriesDetail.item_cost,
    accesseriesDetail.commission_amount,
    accesseriesDetail.qty_purchased, // Add qty_purchased here
    accesseriesDetail.qty_sold, // Add qty_sold here
  ]);
  const addProcedure = () => {
    // console.log("add client called");
    setOpenModel(true);
    setAnchorEl(null);
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpenModel(false);
  };
  return (
    <>
      <ToastContainer />
      <ManageProcedureCost
        open={openModel}
        title={"New Procedure"}
        onHide={hideHandle}
        setModalShow={setModalShow}
      />
      <PageTitle
        title="Manage Other"
        subtitle="Inventory >"
        urlLink={`/app/inventory/${activeTabVal}`}
      />
      {/* <TableWidget noBodyPadding> */}
      <Grid
        container
        spacing={2}
        className={classes.formBox}
        style={{
          border: "1px solid #DED9D9",
          marginBottom: "16px",
          padding: "16px",
        }}
      >
        <Grid item md={12}>
          <Grid container spacing={1}>
            {otherFormElements.map((element, i) => {
              const isHidden =
                (element.key === "item_cost" && inventoryReadValue === "Y") ||
                (element.key === "profit" && inventoryReadValue === "Y") ||
                (element.key === "is_commission_prod" &&
                  inventoryReadValue === "Y") ||
                (element.key === "commission_amount" &&
                  inventoryReadValue === "Y") ||
                (element.key === "gross_percentage" &&
                  inventoryReadValue === "Y") ||
                (element.key === "service_tax1" &&
                  inventoryReadValue === "Y") ||
                (element.key === "service_tax2" &&
                  inventoryReadValue === "Y") ||
                (element.key === "grp_cost" && inventoryReadValue === "Y") ||
                (element.key === "spiff" && inventoryReadValue === "Y");
              return (
                <>
                  {!isHidden && (
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={`${classes.textGroup} ${
                        element.title ? classes.highlightedItem : ""
                      }`}
                      style={{ border: "1px solid #DED9D9" }}
                    >
                      <Typography
                        className={classes.formHeading}
                        style={{ fontWeight: "500", color: "#4E97FD" }}
                      >
                        {element.label}{" "}
                        {element.required === "true" ? (
                          <span className={classes.required}>*</span>
                        ) : null}
                      </Typography>
                      {element.type ? (
                        <>
                          {element.type === "select" &&
                          element.key !== "service_code" ? (
                            <FormControl
                              fullWidth
                              className={classes.dropdownFormControl}
                            >
                              <Select
                                name={element.key}
                                className={classes.selectDropdown}
                                placeholder={element.placeHolder}
                                value={
                                  accesseriesDetail &&
                                  accesseriesDetail[element.key]
                                    ? accesseriesDetail &&
                                      accesseriesDetail[element.key]
                                    : ""
                                }
                                onChange={(e) => {
                                  console.log("selected");
                                  setAccesseriesDetail({
                                    ...accesseriesDetail,
                                    [element.key]: e.target.value,
                                  });
                                }}
                                disabled={inventoryReadValue === "Y"}
                              >
                                <MenuItem disabled value="">
                                  Select
                                </MenuItem>
                                {element.key === "service_code" &&
                                prodecureCodeData
                                  ? prodecureCodeData.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.service_code}
                                      >
                                        {item.service_code}
                                      </MenuItem>
                                    ))
                                  : element.key === "size_id" && accessriesSize
                                  ? accessriesSize.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "unit_id" && accessriesUnit
                                  ? accessriesUnit.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "feature_id" &&
                                    accessriesFeature
                                  ? accessriesFeature.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "supplier_id" &&
                                    supplierData
                                  ? supplierData.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.supplier_id}
                                      >
                                        {item.supplier_name}
                                      </MenuItem>
                                    ))
                                  : element.key === "manufacturer_id" &&
                                    manufacturerData
                                  ? manufacturerData.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.manufacturer_id}
                                      >
                                        {item.manufacturer_name}
                                      </MenuItem>
                                    ))
                                  : element.key === "inv_source" &&
                                    invSourceData
                                  ? invSourceData.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "gender" && genderData
                                  ? genderData.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    )): element.key ==="returnable_id" && returnableData ? returnableData.map((item,index)=>(
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "frametype_id" &&
                                    frameTypeData
                                  ? frameTypeData.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "rim_type_id" && rimTypeData
                                  ? rimTypeData.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "frame_shape_id" &&
                                    frameShapeData
                                  ? frameShapeData.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "material_id" &&
                                    frameMaterialData
                                  ? frameMaterialData.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "structure_id" &&
                                    structureList
                                  ? structureList.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.structure_id}
                                      >
                                        {item.structure_name}
                                      </MenuItem>
                                    ))
                                  : element.key === "accessries_type" &&
                                    serviceTypeValues
                                  ? serviceTypeValues.map((item, index) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    )):element.key === "accessries_color" && element.optionsColor ? element.optionsColor.map((item)=>(
                                      <MenuItem value={item.name}>
                                        {item.name}
                                      </MenuItem>
                                    ))
                                  : element.options.map((item) => (
                                      <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                              </Select>
                            </FormControl>
                          ) : element.type === "select" &&
                            element.key === "service_code" ? (
                            <>
                              <Autocomplete
                                disabled={inventoryReadValue === "Y"}
                                id="procedure-code"
                                options={prodecureCodeData} // Replace with your list of options
                                getOptionLabel={(option) => option.service_code} // Define how to display the options
                                value={
                                  selectedProcedure ? selectedProcedure : null
                                }
                                // value={selectedProcedure ? selectedProcedure : serviceLensDetail?.service_code}
                                onChange={(event, newValue) => {
                                  setSelectedProcedure(newValue); // Update the selected Service Code
                                  // Update the Service Code extension
                                  if (newValue) {
                                    setProcedureCodeExtension(
                                      newValue.service_name
                                    );
                                    setAccesseriesDetail({
                                      ...accesseriesDetail,
                                      service_id: newValue.service_id,
                                      service_code: newValue.service_code,
                                      service_code_extension:
                                        newValue.service_name,
                                      retail_price: newValue?.retail_price,
                                      item_cost: newValue?.item_cost,
                                    });
                                  } else {
                                    setProcedureCodeExtension("");
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                    // inputProps={{
                                    //   style: {
                                    //     height: "12px",
                                    //     fontSize: "12px",
                                    //     padding: "4px",
                                    //   },
                                    // }}
                                  />
                                )}
                              />
                            </>
                          ) : element.type === "is_commission_prod" ? (
                            <FormControl
                              disabled={inventoryReadValue === "Y"}
                              fullWidth
                              className={classes.dropdownFormControl}
                            >
                              <Select
                                name="is_commission_prod"
                                className={classes.selectDropdown}
                                placeholder="Select"
                                value={accesseriesDetail.is_commission_prod}
                                onChange={handleCommissionTypeChange} // Connect the function here
                              >
                                <MenuItem value="Y">Yes</MenuItem>
                                <MenuItem value="N">No</MenuItem>
                              </Select>
                            </FormControl>
                          ) : element.type === "text" ? (
                            <TextField
                              required
                              name={element.key}
                              placeholder={element.placeHolder}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                                maxLength: element.maxLength,
                                onKeyPress: element.onKeyPress,
                              }}
                              value={
                                element.key === "profit"
                                  ? profitValue.toFixed(2)
                                  : element.key === "gross_percentage"
                                  ? grossPercentage.toFixed(2)
                                  : element.key === "qty_inventory"
                                  ? inventoryValue.toFixed(3)
                                  : accesseriesDetail &&
                                    accesseriesDetail[element.key]
                              }
                              onChange={(e) => {
                                setAccesseriesDetail({
                                  ...accesseriesDetail,
                                  [element.key]: e.target.value,
                                });
                              }}
                              disabled={
                                element.key === "profit" ||
                                element.key === "gross_percentage" ||
                                element.key === "service_code_extension" ||
                                element.key === "qty_inventory" ||
                                inventoryReadValue === "Y"
                              }
                            />
                          ) : element.type === "commission_amount" ? (
                            <TextField
                              type="text"
                              className={classes.txtForm}
                              disabled={
                                accesseriesDetail.is_commission_prod !== "Y" ||
                                inventoryReadValue === "Y"
                              } // Enable only when is_commission_prod is "yes"
                              value={accesseriesDetail.commission_amount}
                              fullWidth
                              variant="outlined"
                              size="small"
                              inputProps={{
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                                maxLength: element.maxLength,
                                onKeyPress: element.onKeyPress,
                              }}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                setAccesseriesDetail({
                                  ...accesseriesDetail,
                                  commission_amount: isNaN(value) ? "" : value,
                                });
                              }}
                            />
                          ) : element.type === "date" ||
                            element.type === "datetime-local" ? (
                            <TextField
                              disabled={inventoryReadValue === "Y"}
                              id={element.key}
                              name={element.key}
                              placeholder={element.placeHolder}
                              type={element.type}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                min: `${currentDateTime}`,
                                max: `${currentDate}`,
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={
                                accesseriesDetail &&
                                accesseriesDetail[element.key]
                              }
                              onChange={(e) => {
                                setAccesseriesDetail({
                                  ...accesseriesDetail,
                                  [element.key]: e.target.value,
                                });
                              }}
                            />
                          ) : element.type === "radio" ? (
                            <RadioGroup row defaultValue="">
                              {element.radioValue.map((item) => (
                                <FormControlLabel
                                  size="small"
                                  style={{ padding: "0px 6px" }}
                                  value={item}
                                  control={<Radio />}
                                  label={item}
                                  className={classes.smallRadioButton}
                                />
                              ))}
                            </RadioGroup>
                          ) : element.type === "checkbox" ? (
                            <>
                              <Checkbox
                                name={element.key}
                                checked={
                                  accesseriesDetail[element.key] !== "N"
                                    ? accesseriesDetail[element.key]
                                    : false
                                }
                                onChange={(e) => {
                                  const value = postFlagBooleanVariable(
                                    e.target.checked
                                  );
                                  setAccesseriesDetail({
                                    ...accesseriesDetail,
                                    [element.key]: value,
                                  });
                                }}
                              />
                            </>
                          ) : (
                            <TextField
                              disabled={inventoryReadValue === "Y"}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              placeholder="Type here..."
                              variant="outlined"
                              multiline
                              rows={3}
                              inputProps={{
                                style: {
                                  fontSize: "12px",
                                },
                              }}
                              value={
                                accesseriesDetail &&
                                accesseriesDetail[element.key]
                              }
                              onChange={(e) => {
                                setAccesseriesDetail({
                                  ...accesseriesDetail,
                                  [element.key]: e.target.value,
                                });
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <Typography
                            className={classes.formHeading}
                            style={{
                              fontWeight: "600",

                              color: "#4E97FD",
                            }}
                          >
                            {element.title}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  )}
                </>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <div
        className={classes.footerBox}
        // style={{ justifyContent: "space-between" }}
      >
       
        <div>
          <Button
            variant="outlined"
            className={classes.leftButton}
            style={{ marginRight: "12px" }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          {inventoryReadValue === "N" && (
            <>
              <Button
                variant="contained"
                color="secondary"
                className={classes.rightButton}
                onClick={handleSaveAndExit}
                style={{ marginRight: "12px" }}
              >
                save & Exit
              </Button>
              {!selectedRow && (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.rightButton}
                  onClick={handleSaveAndMore}
                >
                  save & More
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      {selectedRow && (
        <>
          <Paper className={classes.iconsContainer}>
            <Tabs TabIndicatorProps={{
            style: { background: "#4E97FD",width:"150px"},
          }}
            
              variant="scrollable"
              scrollButtons="auto"
              value={activeTabId}
              onChange={(e, id) => setActiveTabId(id)}
              className={classes.iconsBar}
            >
              <Tab label="Locations" classes={{ root: classes.tab }} />
              <Tab label="Transactions" classes={{ root: classes.tab }} />
            </Tabs>
          </Paper>
          {activeTabId === 0 && <LocationList />}
          {activeTabId === 1 && <TransactionList />}
          {activeTabId === 2 && <MaterialsList />}
        </>
      )}

      {/* </TableWidget> */}
    </>
  );
}
