import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { dateFormatConversion } from "../../../../../hooks/helper";
import PageTitle from "../../../../PageTitle/PageTitle";
import Widget from "../../../../Widget/Widget";

import TableWidget from "../../../../Widget/TableWidget";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Create";
import IconButton from "@material-ui/core/IconButton";
import * as Icons from "@material-ui/icons";
import { getPatientAttachment } from "../../../../../services/ServiceLayer";
import TableDesign from "../../../../common/table/TableDesign";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Paper,
  Menu,
  MenuItem,
} from "@material-ui/core";
// styles
import useStyles from "../../styles";

export default function FamilyList({
  patientFamilyData,
  setPatientFamilyData,
}) {
  const tableHeadings = [
    {
      label: "S. No.",
    },
    {
      label: "Patient ID",
      align: "left !important",
    },
    {
      label: "Patient Name",
      align: "left !important",
    },
    {
      label: "Email ID",
      align: "left !important",
    },
    {
      label: "Relationship",
      align: "left !important",
    },
    {
      label: "Contact",
      align: "left !important",
    },
    
    {
      label: "Created Date",
      align: "left !important",
    },
    {
      label: "Updated By",
      align: "left !important",
    },
  ];

  const [open, setOpen] = React.useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectclient, setselectclient] = useState(null);

  const [title, setTitle] = useState(null);
  var classes = useStyles();
  const [test, setTest] = useState(false);
  const [rows, setRows] = useState();

  return (
    <div className="container">
      <p style={{ marginLeft: "6px", paddingLeft: "10px",fontWeight:'500' }}>Family List</p>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Attachment",
                tableHeadings,
              }}
            >
              {patientFamilyData && patientFamilyData?.length ? (
                patientFamilyData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {row.patient_parent_id}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.family_name}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.email}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.relationship_type}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.contact_cell}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {dateFormatConversion(row.created_dt)}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.updated_by}
                        </TableCell>
                        <TableCell
                          className={classes.tableBodyCell}
                        ></TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </div>
  );
}
