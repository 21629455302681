import React, { useEffect, useState } from "react";

import {
  Grid,
  TableRow,
  TableCell,
  Typography,
  InputBase,
  Button,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";
import TableWidget from "../../../Widget/TableWidget";
import TableDesign from "../../../common/table/TableDesign";
import useStyles from "../../../Settings/Tax/styles";

import {
  getinvtFrame,
  getFrameFilter,
} from "../../../../services/ServiceLayer";
import { loadState } from "../../../../hooks/helper";
import classNames from "classnames";

import Popup from "../../../common/modal/Popup";

export default function ExsitingFrameOrder({
  onHide,
  frameModalShow,
  setSelectedRow,
}) {
  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [isLoader, setIsLoader] = useState(false);

  const [frameListData, setFrameListData] = useState([]);
  const [frameFilterListData, setFrameFilterListData] = useState([]);

  const [open, setOpen] = React.useState(false);

  const [dataUpdated, setDataUpdated] = useState(false);

  const [inventoryReadValue, setInventoryReadValue] = useState();

  var [isSearchOpen, setSearchOpen] = useState(true);
  const [statusFilterManufacture, setStatusFilterManufacture] = useState("");
  const [statusFilterModel, setStatusFilterModel] = useState("");
  const [statusFilterBrand, setStatusFilterBrand] = useState("");
  // filter
  const [selectBrand, setSelectBrand] = useState("");
  // filter
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const readAccessData = loadState().menu;
  useEffect(() => {
    console.log("readAccessData", readAccessData);
    const inventorySection = readAccessData.find(
      (item) => item.label === "Inventory"
    );
    if (inventorySection) {
      const inventoryChildren = inventorySection.children;

      const inventoryReadAcess = inventoryChildren.find(
        (child) => child.label === "Inventory"
      ).read_access;
      setInventoryReadValue(inventoryReadAcess);
    }
  }, [readAccessData]);

  useEffect(() => {
    getFrameFilter(
      `location_id=${userData?.location_id}&user_role=${userRole}&frame_brand=${selectBrand}&frame_model=${selectBrand}&frame_id=${selectBrand}&frame_name=${selectBrand}&frame_number=${selectBrand}&frametype_id=${selectBrand}&frametype_val=${selectBrand}&sku=${selectBrand}&frame_color_code=${selectBrand}&collection=${selectBrand}&frame_color=${selectBrand}&material_val=${selectBrand}&rim_type_id=${selectBrand}&rim_type_val=${selectBrand}&frame_shape_id=${selectBrand}&frame_shape_val=${selectBrand}&gender=${selectBrand}&structure_id=${selectBrand}&structure_type=${selectBrand}&is_commission_prod=${selectBrand}&manufacturer_name=${selectBrand}&manufacturer_type=${selectBrand}&supplier_id=${selectBrand}&supplier_name=${selectBrand}&manufacturer_id=${selectBrand}&opt_type=SELFRM`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setIsLoader(false);
        setFrameFilterListData(response?.data);
      } else {
        setIsLoader(false);
      }
    });
    getinvtFrame(
      `location_id=${userData?.location_id}&user_role=${userRole}&frame_brand_val=${selectBrand}&frame_model_name=${selectBrand}&frame_id=${selectBrand}&frame_name=${selectBrand}&frame_number=${selectBrand}&frametype_id=${selectBrand}&frametype_val=${selectBrand}&sku=${selectBrand}&frame_color_code=${selectBrand}&collection=${selectBrand}&frame_color=${selectBrand}&material_val=${selectBrand}&rim_type_id=${selectBrand}&rim_type_val=${selectBrand}&frame_shape_id=${selectBrand}&frame_shape_val=${selectBrand}&gender=${selectBrand}&structure_id=${selectBrand}&structure_type=${selectBrand}&is_commission_prod=${selectBrand}&manufacturer_name=${selectBrand}&manufacturer_type=${selectBrand}&supplier_id=${selectBrand}&supplier_name=${selectBrand}&manufacturer_id=${selectBrand}&opt_type=SELFRM`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setIsLoader(false);
        setFrameListData(response?.data);
      } else {
        setIsLoader(false);
      }
    });
  }, []);
  useEffect(() => {
    console.log("statusFilterBrand", statusFilterBrand);
    console.log("statusFilterManufacture", statusFilterManufacture);
    getinvtFrame(
      `location_id=${userData?.location_id}&user_role=${userRole}&frame_brand=${
        statusFilterBrand ? statusFilterBrand : ""
      }&manufacturer_id=${
        statusFilterManufacture ? statusFilterManufacture : ""
      }&frame_model=${
        statusFilterModel ? statusFilterModel : ""
      }&opt_type=SELFRM`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setIsLoader(false);
        setFrameListData(response?.data);
      } else {
        setIsLoader(false);
      }
    });
    setDataUpdated(false);
  }, [
    dataUpdated,
    statusFilterBrand,
    statusFilterManufacture,
    statusFilterModel,
  ]);

  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Name ",
      secondaryLabel: "(SKU)",
    },

    {
      label: "Model ",
      secondaryLabel: "(Manufacturer)",
    },

    {
      label: "Frame Type ",
      secondaryLabel: "(Rim Type)",
    },

    {
      label: "Frame Usage ",
      secondaryLabel: "(Bridge)",
    },

    {
      label: "Retail Price ",
      // secondaryLabel: "(Item Cost)",
    },

    {
      label: "Status",
    },
    {
      label: "Action",
    },
  ];
  const handleRowClick = (row) => {
    setSelectedRow(row);
    onHide();
  };

  //searching
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredFrameList = frameListData.filter((item) => {
    // Check if each property is not null or undefined before using toLowerCase()
    const frameName = item.frame_name ? item.frame_name.toLowerCase() : "";
    const frameBrand = item.frame_brand_val
      ? item.frame_brand_val.toLowerCase()
      : "";
    const frameModel = item.frame_model_name
      ? item.frame_model_name.toLowerCase()
      : "";
    const frameType = item.frame_type_val
      ? item.frame_type_val.toLowerCase()
      : "";
    const manufacturerName = item.manufacturer_name
      ? item.manufacturer_name.toLowerCase()
      : "";

    return (
      frameName.includes(searchTerm.toLowerCase()) ||
      frameBrand.includes(searchTerm.toLowerCase()) ||
      frameModel.includes(searchTerm.toLowerCase()) ||
      frameType.includes(searchTerm.toLocaleLowerCase()) ||
      manufacturerName.includes(searchTerm.toLowerCase())
    );
  });
  const [statusFilter, setStatusFilter] = useState("");
  // Filtering function
  const filterData = () => {
    let filteredData = frameListData;

    if (statusFilter) {
      // Apply status filter if a filter is selected
      filteredData = filteredData.filter(
        (item) => item.is_active.toLowerCase() === statusFilter.toLowerCase()
      );
    }
    if (statusFilterManufacture) {
      // Apply Model No. filter if a value is selected
      filteredData = filteredData.filter(
        (item) =>
          item.frame_name.toLowerCase() ===
          statusFilterManufacture.toLowerCase()
      );
    }

    if (statusFilterModel) {
      console.log("statusFilterModel", statusFilterModel);
      // Apply Model filter if a value is selected
      filteredData = filteredData.filter(
        (item) =>
          item.frame_model_name.toLowerCase() ===
          statusFilterModel.toLowerCase()
      );
    }

    if (statusFilterBrand) {
      // Apply Brand filter if a value is selected
      filteredData = filteredData.filter(
        (item) =>
          item.brand_id.toLowerCase() === statusFilterBrand.toLowerCase()
      );
    }
    console.log("Filtered Data:", filteredData);
    // Return the filtered data
    return filteredData;
  };
  useEffect(() => {
    const filteredData = filterData();
    setFrameListData(filteredData);
  }, [statusFilterBrand, statusFilterModel, statusFilterManufacture]);

  // Assuming frameListData is an array of frame objects
  const uniqueModelNos = [...new Set(frameFilterListData.map((item) => item))];
  const uniqueModels = [...new Set(frameFilterListData.map((item) => item))];
  const uniqueBrands = [...new Set(frameFilterListData.map((item) => item))];
  useEffect(() => {
    console.log("uniqueBrands", uniqueBrands);
  }, [uniqueBrands]);

  const hideHandler = () => {
    console.log("hide handler called");
    onHide();
    setOpen(false);
  };

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={frameModalShow}
        keepMounted
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Close
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText>
            <>
              <div
                className={classes.pageTitleContainer}
                style={{ alignItems: "center" }}
              >
                <Typography className={classes.typo} weight="medium">
                  <div
                    className={classNames(classes.search, {
                      [classes.searchFocused]: isSearchOpen,
                    })}
                  >
                    <div
                      className={classNames(classes.searchIcon, {
                        [classes.searchIconOpened]: isSearchOpen,
                      })}
                      onClick={() => setSearchOpen(!isSearchOpen)}
                    >
                      <SearchIcon classes={{ root: classes.headerIcon }} />
                    </div>
                    <InputBase
                      placeholder="Search…"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      onChange={handleChange}
                    />
                  </div>
                </Typography>
                <div>
                  <label style={{ marginRight: "4px" }}>Manufacturer:</label>
                  <select
                    style={{
                      height: "26px",
                      borderRadius: "5px",
                      width: "96px",
                    }}
                    value={statusFilterManufacture}
                    onChange={(e) => setStatusFilterManufacture(e.target.value)}
                  >
                    <option value="">All</option>
                    {uniqueModelNos.map((modelNo, i) => (
                      <option key={i} value={modelNo?.manufacturer_id}>
                        {modelNo?.manufacturer_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label style={{ marginRight: "4px" }}>Model:</label>
                  <select
                    style={{
                      height: "26px",
                      borderRadius: "5px",
                      width: "96px",
                    }}
                    value={statusFilterModel}
                    onChange={(e) => setStatusFilterModel(e.target.value)}
                  >
                    <option value="">All</option>
                    {uniqueModels.map((model, i) => (
                      <option key={i} value={model?.frame_model_name}>
                        {model?.frame_model_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label style={{ marginRight: "4px" }}>Brand:</label>
                  <select
                    style={{
                      height: "26px",
                      borderRadius: "5px",
                      width: "96px",
                    }}
                    value={statusFilterBrand}
                    onChange={(e) => setStatusFilterBrand(e.target.value)}
                  >
                    <option value="">All</option>
                    {uniqueBrands.map((brand, i) => (
                      <option key={i} value={brand?.brand_id}>
                        {brand?.frame_brand_val}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  {/* <MaterialReactTable columns={tableHeadings} data={filteredFrameList} /> */}
                  <TableWidget noBodyPadding>
                    <TableDesign
                      {...{
                        title: "List of All Clients",
                        tableHeadings,
                      }}
                    >
                      {filteredFrameList && filteredFrameList.length ? (
                        filteredFrameList.map((row, i) => {
                          if (row)
                            return (
                              <TableRow
                                key={i}
                                className={classes.tableBodyCell}
                              >
                                <TableCell className={classes.tableBodyCell}>
                                  {i + 1}
                                </TableCell>

                                <TableCell
                                  align="left"
                                  className={classes.tableBodyCell}
                                  style={{ cursor: "pointer" }}
                                >
                                  {row.frame_name}
                                  <br />
                                  {row.frame_sku && (
                                    <span style={{ color: "#4E97FD" }}>
                                      ({row.frame_sku})
                                    </span>
                                  )}
                                </TableCell>

                                <TableCell
                                  align="left"
                                  style={{ cursor: "pointer" }}
                                  className={classes.tableBodyCell}
                                >
                                  {row.frame_model_name}
                                  <br />
                                  {row.manufacturer_name && (
                                    <span style={{ color: "#4E97FD" }}>
                                      ({row.manufacturer_name})
                                    </span>
                                  )}
                                </TableCell>

                                <TableCell className={classes.tableBodyCell}>
                                  {row.frame_type_val}
                                  <br />
                                  {row.rim_type_val && (
                                    <span style={{ color: "#4E97FD" }}>
                                      ({row.rim_type_val})
                                    </span>
                                  )}
                                </TableCell>
                                <TableCell className={classes.tableBodyCell}>
                                  {row.frame_shape_val}
                                  <br />
                                  {row.material_val && (
                                    <span style={{ color: "#4E97FD" }}>
                                      ({row.material_val})
                                    </span>
                                  )}
                                </TableCell>

                                <TableCell className={classes.tableBodyCell}>
                                  {row.retail_price}
                                </TableCell>

                                <TableCell className={classes.tableBodyCell}>
                                  {row.is_active === "Y" ? (
                                    <span style={{ color: "green" }}>
                                      Active
                                    </span>
                                  ) : (
                                    <span style={{ color: "red" }}>
                                      Inactive
                                    </span>
                                  )}
                                </TableCell>
                                <TableCell className={classes.tableBodyCell}>
                                  <span
                                    onClick={() => handleRowClick(row)}
                                    style={{
                                      cursor: "pointer",
                                      border: "1px solid #5DB3D9",
                                      borderRadius: "25px",
                                      padding: "6px 10px",
                                      color: "#5db3d9",
                                    }}
                                  >
                                    Select
                                  </span>
                                </TableCell>
                              </TableRow>
                            );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan="8">No Result Found</TableCell>
                        </TableRow>
                      )}
                    </TableDesign>
                  </TableWidget>
                </Grid>
              </Grid>
            </>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
