import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  Switch,
  Fade,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../common/modal/Popup";
// styles
import useStyles from "../styles";

import moment from "moment";
import { currentUser, handleAddressKeyPress, handleAddressKeyPressPaste, loadState } from "../../../hooks/helper";
import { toast } from "react-toastify";
import { postSurgeryDetails } from "../../../services/ServiceLayer";
import ExamValidation from "../../../hooks/ExamValidation";
import { Autocomplete } from "@mui/material";

export default function ManageSurgries(props) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  //
  const formElements = [
    {
      key: "sur_type",
      label: "Surgery Type",
      placeHolder: "select",
      type: "select",
      options: [""],
      mdSize: "3",
    },

    {
      key: "sur_eye",
      label: "Eye",
      placeHolder: "select",
      type: "select",
      options: [],
      mdSize: "3",
    },
    {
      key: "st_date",
      label: "When",
      placeHolder: "select",
      type: "date",
      mdSize: "3",
      
    },


    {
      key: "sur_referredby",
      label: "Referred By",
      placeHolder: "select",
      type: "select",
      options: [],
      mdSize: "3",
    },
    {
      key: "sur_comments",
      label: "Comments",
      placeHolder: "Type here",
      type: "textarea",
      maxLength:'50',
      mdSize: "12",
      onKeyPress: handleAddressKeyPress,
      onpaste: handleAddressKeyPressPaste,
    },
  ];
  console.log("props-24", props);
  const {
    selectclient,
    surgriesType,
    surgriesOutcome,
    surgriesOccurance,
    surgriesClassification,
    patientId,doctorList,examEyeType
  } = props;
  console.log("selectclient", selectclient);
  const [isShow, setIsShow] = useState(false);
  //
  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [actionType, setActionType] = useState("ADDSRG");
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [surgeryDetail, setSurgeryDetail] = useState({
    patient_id: patientId,
    location_id: userData?.location_id,
    pat_surg_id: 0,
    sur_type: 0,
    sur_description: "",
    st_date: "",
    end_date: "",
    sur_occurance: 0,
    sur_outcome: 0,
    sur_classification: 0,
    sur_referredby: 0,
    sur_comments: "",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: actionType,
  });
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
  ExamValidation({
   
    st_date: surgeryDetail?.st_date,
    end_date: surgeryDetail?.end_date,
  });
  const clearForm = () => {
    setSurgeryDetail({
      patient_id: patientId,
      location_id: userData?.location_id,
      pat_surg_id: 0,
      sur_type: 0,
      sur_description: "",
      st_date: moment().format("YYYY-MM-DDThh:mm"),
      end_date: moment().format("YYYY-MM-DDThh:mm"),
      sur_occurance: 0,
      sur_outcome: 0,
      sur_classification: 0,
      sur_referredby: 0,
      sur_comments: "",
      updated_by: currentUser(),
      access_location: "access_location",
      opt_type: actionType,
    });
  };
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setSurgeryDetail({
        patient_id: patientId,
        location_id: userData?.location_id,
        pat_surg_id: 0,
        sur_type: 0,
        sur_description: "",
        st_date: moment().format("YYYY-MM-DDThh:mm"),
        end_date: moment().format("YYYY-MM-DDThh:mm"),
        sur_occurance: 0,
        sur_outcome: 0,
        sur_classification: 0,
        sur_referredby: 0,
        sur_comments: "",
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: actionType,
      });
    } else {
      setSurgeryDetail({
        patient_id: patientId,
        location_id: userData?.location_id,
        pat_surg_id: selectclient?.pat_surg_id,
        sur_type: selectclient?.sur_type,
        sur_description: selectclient?.sur_description,
        st_date: moment(selectclient?.st_date).format('YYYY-MM-DDTHH:mm'),
        end_date: moment(selectclient?.end_date).format('YYYY-MM-DDTHH:mm'),
        sur_occurance: selectclient?.sur_occurance,
        sur_outcome: selectclient?.sur_outcome,
        sur_classification: selectclient?.sur_classification,
        sur_referredby: selectclient?.sur_referredby,
        sur_comments: selectclient?.sur_comments,
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: "UPDSRG",
      });
    }
  }, [selectclient]);
  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    if (isActionCalled) {
   
      postSurgeryDetails(surgeryDetail).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Surgery added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          clearForm();
          props.onHide();
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    // } else {
    //   setIsActionCalled(false);
    // }

    setIsActionCalled(false);
  }, [isActionCalled]);

  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
    clearForm();
  };
  const optionsMap = {
    sur_type: surgriesType,
  }
  const handleAutocompleteChange = (key, newValue) => {
    setSurgeryDetail((prev) => ({
      ...prev,
      [key]: newValue ? newValue.attribute_id : null,
    }));
    
  };
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleSubmit}
            >
              {selectclient ? "Update" : "Add"}
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={2}
              className={classes.formBox}
              style={{ marginBottom: "4px" }}
            >
              {formElements.map((element, i) => {
                return (
                  <>
                    <Grid key={i}
                      item
                      xs={12}
                      md={element.mdSize}
                      className={classes.textGroup}
                    >
                      <Typography
                        style={{ fontSize: "13px" }}
                        className={classes.formHeading}
                      >
                        {element.label}{" "}
                        {element.required === "true" ? (
                          <span className={classes.required}>*</span>
                        ) : null}
                      </Typography>

                      {element.type === "select" && element.key !== "sur_type" ? (
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            name={element.key}
                            className={classes.selectDropdown}
                            placeholder={element.placeHolder}
                            value={
                              surgeryDetail &&
                              surgeryDetail[element.key]
                                ? surgeryDetail &&
                                surgeryDetail[element.key]
                                : ""
                            }
                            onChange={(e) => {
                              console.log("selected", e.target.value);

                              setSurgeryDetail({
                                ...surgeryDetail,
                                [element.key]: e.target.value,
                              });

                              // resetValidator(e.target.name);
                            }}
                          >
                            <MenuItem disabled value="">
                              Select
                            </MenuItem>
                            {element.key === "sur_type" && surgriesType
                              ? surgriesType.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "sur_eye" &&
                              examEyeType
                              ? examEyeType.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "sur_outcome" && surgriesOutcome
                              ? surgriesOutcome.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                              : element.key === "sur_classification" &&
                                surgriesClassification
                              ? surgriesClassification.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                                : element.key === "sur_referredby" && doctorList
                                ? doctorList.map((item) => (
                                    <MenuItem
                                      key={item.staff_id}
                                      value={item.staff_id}
                                    >
                                      {item.first_name} {item.middle_name}{" "}
                                      {item.last_name}
                                    </MenuItem>
                                  ))
                                : element.options.map((item) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      ): element.type === "select" && element.key === "sur_type" ? (
                        <Autocomplete style={{marginTop:'6px',fontSize:'14px'}}
                        options={optionsMap[element.key] || []}
                        getOptionLabel={(option) => option.attribute_val || ""}
                        value={
                          (optionsMap[element.key] || []).find(
                            (option) =>
                              option.attribute_id === surgeryDetail[element.key]
                          ) || null
                        }
                        onChange={(e, newValue) =>
                          handleAutocompleteChange(element.key, newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label={element.label}
                            variant="outlined"
                            fullWidth
                            // InputProps={{
                            //   ...params.InputProps,
                            //   style: {
                            //     height: '36px',
                            //     padding: '10 14px',
                            //   },
                            // }}
                            inputProps={{
                              ...params.inputProps,
                              style: {
                                height: 'auto', // Adjust if you need to change the height of the inner input element
                                padding: '0 14px', fontSize:'14px'
                                
                              },
                            }}
                          />
                        )}
                      />
                      ) : element.type === "text" ? (
                        <TextField
                          required
                          name={element.key}
                          placeholder={element.placeHolder}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                            maxLength: element.maxLength,
                            onKeyPress: element.onKeyPress,
                            onPaste: element.onpaste,
                          }}
                          value={
                            surgeryDetail && surgeryDetail[element.key]
                              ? surgeryDetail &&
                              surgeryDetail[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected");
                            setSurgeryDetail({
                              ...surgeryDetail,
                              [element.key]: e.target.value,
                            });
                            // resetValidator(e.target.name);
                          }}
                        />
                      ) : element.type === "date" ||
                        element.type === "datetime-local" ? (
                        
                        <><TextField
                          id={element.key}
                          name={element.key}
                          placeholder={element.placeHolder}
                          type={element.type}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            min:
                            element.key === "end_date"
                              ? surgeryDetail?.st_date
                              : undefined,
                            max: `${currentDate}`,
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onKeyDown={(e) => {
                            e.preventDefault(); // Prevent manual typing
                          }}
                          value={
                            surgeryDetail && surgeryDetail[element.key]
                              ? surgeryDetail &&
                              surgeryDetail[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected");
                            setSurgeryDetail({
                              ...surgeryDetail,
                              [element.key]: currentDate,
                            });
                            resetValidator(e.target.name);
                          }}
                        />
                         <Fade in={error}>
                          <Typography
                            color="secondary"
                            className={classes.errorMessage}
                          >
                            {error && error[element.key]}
                          </Typography>
                        </Fade>
                        </>
                      ) : element.type === "textarea" ? (
                        <TextField
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          placeholder="Type here..."
                          variant="outlined"
                          multiline
                          rows={3}
                          inputProps={{
                            style: {
                              fontSize: "12px",
                            },
                            maxLength: element.maxLength,
                            onKeyPress: element.onKeyPress,
                            onPaste: element.onpaste,
                          }}
                          value={
                            surgeryDetail && surgeryDetail[element.key]
                              ? surgeryDetail &&
                              surgeryDetail[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected");
                            setSurgeryDetail({
                              ...surgeryDetail,
                              [element.key]: e.target.value,
                            });
                            // resetValidator(e.target.name);
                          }}
                        />
                      ) : element.type === "button" ? (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                          style={{
                            width: "100%",
                            fontSize: "10px",
                            marginTop: "26px",
                          }}
                        >
                          {element.btnName}
                        </Button>
                      ) : (
                        <Switch />
                      )}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
