export default {
  table: [
    {
      name: "Wavy Frame",
      sku: "FRO123",
      collection: "Group A",
      brand: "Wacy",
      color: "blue",
      colorCode: "#645865",
      frametype: "convex",
      rimtype: "borderRadius",
      shape: "rounded",
      material: "materilA",
      frameusage: "frameUsages",
      bridge: "bridge out",
      gender: "male",
      source: "overall",
      retail_price: "2500",
      status: "active",
      availability: "online",
      recall_date: "25/11/2023",
      arrival_date: "25/11/2023",
      best_seller: "45",
      warranty: "1 years",
      return_allowed: "6 month",
      discount: "2 %",
      discount_untill: " 2.5 %",
    },

    {
      name: "Wavy Frame",
      sku: "FRO123",
      collection: "Group A",
      brand: "Wacy",
      color: "blue",
      colorCode: "#645865",
      frametype: "convex",
      rimtype: "borderRadius",
      shape: "rounded",
      material: "materilA",
      frameusage: "frameUsages",
      bridge: "bridge out",
      gender: "male",
      source: "overall",
      retail_price: "2500",
      status: "active",
      availability: "online",
      recall_date: "25/11/2023",
      arrival_date: "25/11/2023",
      best_seller: "45",
      warranty: "1 years",
      return_allowed: "6 month",
      discount: "2 %",
      discount_untill: " 2.5 %",
    },
  ],
};
