import React, { useState, useEffect } from "react";

// Model
import {
  Button,
  Typography,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Popup from "../common/modal/Popup";
import moment from "moment";
import alertIcon from "./alertIcon.svg";
// styles
import useStyles from "./styles";
import { toast } from "react-toastify";
import { postFrameLense } from "../../services/ServiceLayer";
import { currentUser, loadState } from "../../hooks/helper";

export default function DeleteFramePopup({
  onHide,
  deleteModalShow,
  setDeleteModalShow,
  selectclient,
}) {
  console.log("selectclient", selectclient);
  console.log("deleteModalShow", deleteModalShow);
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const [actionType, setActionType] = useState("DELFRM");
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [isActionCalled, setIsActionCalled] = useState(false);
  toast.configure();
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setDeleteModalShow(false);
    setOpen(false);
  };
  const [companyDetail, setCompanyDetail] = useState({
    frame_id: "0",
    global_frame_id: "",
    location_id: userData?.location_id,
    upccode: "",
    upc_type: "",
    frame_number: "",
    frame_name: "",
    frametype_id: "",
    sku: "",
    frame_color_code: "",
    frame_color: "",
    material_id: "",
    rim_type_id: "",
    frame_shape_id: "",
    gender: "",
    a: "",
    b: "",
    ed: "",
    bridge: "",
    temple: "",
    lense_color: "",
    collection: "",
    Usage: "",
    notes: "",
    lense_prescription: "",
    procedure_code: "",
    inv_source: "",
    structure_id: "",
    structure_type: "",
    qty_purchased: "",
    qty_sold: "",
    qty_inventory: "",
    retail_price: "",
    item_cost: "",
    grp_cost: "",
    is_commission_prod: "",
    commission_amount: "0",
    spiff: "",
    trace_file: "",
    manufacturer_id: "",
    supplier_id: "",
    is_active: "",
    updated_by: currentUser(),
    access_location: "",
    opt_type: actionType,
  });
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setCompanyDetail({
        frame_id: "0",
        global_frame_id: "",
        location_id: userData?.location_id,
        upccode: "",
        upc_type: "",
        frame_number: "",
        frame_name: "",
        frametype_id: "",
        sku: "",
        frame_color_code: "",
        frame_color: "",
        material_id: "",
        rim_type_id: "",
        frame_shape_id: "",
        gender: "",
        a: "",
        b: "",
        ed: "",
        bridge: "",
        temple: "",
        lense_color: "",
        collection: "",
        Usage: "",
        notes: "",
        lense_prescription: "",
        procedure_code: "",
        inv_source: "",
        structure_id: "",
        structure_type: "",
        qty_purchased: "",
        qty_sold: "",
        qty_inventory: "",
        retail_price: "",
        item_cost: "",
        grp_cost: "",
        is_commission_prod: "",
        commission_amount: "0",
        spiff: "",
        trace_file: "",
        manufacturer_id: "",
        supplier_id: "",
        is_active: "",
        updated_by: currentUser(),
        updated_dt: "",
        access_location: "access_location",
        opt_type: actionType,
      });
    } else {
      setCompanyDetail({
        frame_id: selectclient?.frame_id,
        global_frame_id: selectclient?.global_frame_id,
        location_id: userData?.location_id,
        upccode: selectclient?.upccode,
        upc_type: selectclient?.upc_type,
        frame_number: selectclient?.frame_number,
        frame_name: selectclient?.frame_name,
        frametype_id: selectclient?.frametype_id,
        sku: selectclient?.sku,
        frame_color_code: selectclient?.frame_color_code,
        frame_color: selectclient?.frame_color,
        material_id: selectclient?.material_id,
        rim_type_id: selectclient?.rim_type_id,
        frame_shape_id: selectclient?.frame_shape_id,
        gender: selectclient?.gender,
        a: selectclient?.a,
        b: selectclient?.b,
        ed: selectclient?.ed,
        bridge: selectclient?.bridge,
        temple: selectclient?.temple,
        lense_color: selectclient?.lense_color,
        collection: selectclient?.collection,
        Usage: selectclient?.Usage,
        notes: selectclient?.notes,
        lense_prescription: selectclient?.lense_prescription,
        procedure_code: selectclient?.procedure_code,
        inv_source: selectclient?.inv_source,
        structure_id: selectclient?.structure_id,
        structure_type: selectclient?.structure_type,
        qty_purchased: selectclient?.qty_purchased,
        qty_sold: selectclient?.qty_sold,
        qty_inventory: selectclient?.qty_inventory,
        retail_price: selectclient?.retail_price,
        item_cost: selectclient?.item_cost,
        grp_cost: selectclient?.grp_cost,
        is_commission_prod: selectclient?.is_commission_prod,
        commission_amount: "0",
        spiff: selectclient?.spiff,
        trace_file: selectclient?.trace_file,
        manufacturer_id: selectclient?.manufacturer_id,
        supplier_id: selectclient?.supplier_id,
        is_active: selectclient?.is_active,
        updated_by: currentUser(),
        updated_dt: "",
        access_location: "access_location",

        opt_type: actionType,
      });
    }
  }, [selectclient]);

  const deleteFormData = () => {
    setIsActionCalled(true);
    setCompanyDetail({
      ...companyDetail,
      opt_type:selectclient?.is_active === "Y" ?  "DELFRM" : "REAFRM",
    });
  };

  useEffect(() => {
    console.log("isActionCalled", isActionCalled);

    if (isActionCalled) {
      postFrameLense(companyDetail).then((response) => {
        console.log("actionType", actionType);

        console.log(response);
        if (response.status.status) {
          toast.success("Frame deleted successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });

          onHide();
          setDeleteModalShow(false);
          setOpen(false);
          setIsActionCalled(false);
        } else {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActionCalled(false);
        }
      });
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={deleteFormData}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <img src={alertIcon} />
            <Typography className={classes.formActiveHeading}>
              Are you sure! You want to{" "}
              {selectclient?.is_active === "Y" ? <>Delete </> : <>Re-active </>}{" "}
              this Frame?
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
