import React, { useEffect, useState } from "react";

import {
  Grid,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  FormGroup,
  Checkbox,
  Button,
  Menu,
  IconButton,
} from "@material-ui/core";
// data

// Model
import { familyHistoryForm } from "../Exam_Constant";
import useStyles from "../styles";
// components

import moment from "moment";
import { currentUser, loadState } from "../../../hooks/helper";
import { toast } from "react-toastify";
import {
  getFamilyHistory,
  postFamilyHistory,
} from "../../../services/ServiceLayer";
import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";
export default function FamilyHistory({
  selectclient,
  relTypeValues,
  patientId,
  enable,setFamilyBtnUnable,familyBtnUnable,examFamilyRelation
}) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [actionType, setActionType] = useState("ADDFMH");
  const [discardUpdate, setDiscardUpdate] = useState(false);
  const [showDiscard, setShowDiscard] = useState(false);
  const [isActionCalled, setIsActionCalled] = useState(false);
  var classes = useStyles();
  const [familyDetail, setFamilyDetail] = useState({
    pat_familyhist_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    fmh_glaucoma: "",
    fmh_glaucomarel1: "",
    fmh_glaucomarel2: "",
    fmh_lossofvision: "",
    fmh_lossofvisionrel1: "",
    fmh_lossofvisionrel2: "",
    fmh_lazyeye: "",
    fmh_lazyeyerel1: "",
    fmh_lazyeyerel2: "",
    fmh_masculardegeneration: "",
    fmh_masculardegenerationrel1: "",
    fmh_masculardegenerationrel2: "",
    fmh_colorblindness: "",
    fmh_colorblindnessrel1: "",
    fmh_colorblindnessrel2: "",
    fmh_retinaldetachment: "",
    fmh_retinaldetachmentrel1: "",
    fmh_retinaldetachmentrel2: "",
    fmh_keratoconus: "",
    fmh_keratoconusrel1: "",
    fmh_keratoconusrel2: "",
    fmh_diabetes: "",
    fmh_diabetesrel1: "",
    fmh_diabetesrel2: "",
    fmh_heartdisease: "",
    fmh_heartdiseaserel1: "",
    fmh_heartdiseaserel2: "",
    fmh_highbloodpressure: "",
    fmh_highbloodpressurerel1: "",
    fmh_highbloodpressurerel2: "",
    fmh_lowbloodpressure: "",
    fmh_lowbloodpressurerel1: "",
    fmh_lowbloodpressurerel2: "",
    fmh_kidneydisease: "",
    fmh_kidneydiseaserel1: "",
    fmh_kidneydiseaserel2: "",
    fmh_lupus: "",
    fmh_lupusrel1: "",
    fmh_lupusrel2: "",
    fmh_thyroiddisease: "",
    fmh_thyroiddiseaserel1: "",
    fmh_thyroiddiseaserel2: "",
    fmh_nyctalopia: "",
    fmh_nyctalopiarel1: "",
    fmh_nyctalopiarel2: "",
    fmh_eyepainorsoreness: "",
    fmh_eyepainorsorenessrel1: "",
    fmh_eyepainorsorenessrel2: "",
    fmh_infectionofeyeorlid: "",
    fmh_infectionofeyeorlidrel1: "",
    fmh_infectionofeyeorlidrel2: "",
    fmh_stiesorchalazion: "",
    fmh_stiesorchalazionrel1: "",
    fmh_stiesorchalazionrel2: "",
    fmh_cancer: "",
    fmh_cancerrel1: "",
    fmh_cancerrel2: "",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: actionType,
  });
  const [familyData, setFamilyData] = useState([]);
  useEffect(() => {
    getFamilyHistory(
      `location_id=${userData?.location_id}&patient_id=${patientId}&user_role=${userRole}&opt_type=SELFMH`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setFamilyData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);
  useEffect(() => {
    console.log("family_data-1", familyData);
    if (familyData && familyData.length > 0) {
      console.log("family_data", familyData);
      setFamilyDetail({
        pat_familyhist_id: familyData[0]?.pat_familyhist_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        fmh_glaucoma: familyData[0]?.fmh_glaucoma,
        fmh_glaucomarel1: familyData[0]?.fmh_glaucomarel1,
        fmh_glaucomarel2: familyData[0]?.fmh_glaucomarel2,
        fmh_lossofvision: familyData[0]?.fmh_lossofvision,
        fmh_lossofvisionrel1: familyData[0]?.fmh_lossofvisionrel1,
        fmh_lossofvisionrel2: familyData[0]?.fmh_lossofvisionrel2,
        fmh_lazyeye: familyData[0]?.fmh_lazyeye,
        fmh_lazyeyerel1: familyData[0]?.fmh_lazyeyerel1,
        fmh_lazyeyerel2: familyData[0]?.fmh_lazyeyerel2,
        fmh_masculardegeneration: familyData[0]?.fmh_masculardegeneration,
        fmh_masculardegenerationrel1:
          familyData[0]?.fmh_masculardegenerationrel1,
        fmh_masculardegenerationrel2:
          familyData[0]?.fmh_masculardegenerationrel2,
        fmh_colorblindness: familyData[0]?.fmh_colorblindness,
        fmh_colorblindnessrel1: familyData[0]?.fmh_colorblindnessrel1,
        fmh_colorblindnessrel2: familyData[0]?.fmh_colorblindnessrel2,
        fmh_retinaldetachment: familyData[0]?.fmh_retinaldetachment,
        fmh_retinaldetachmentrel1: familyData[0]?.fmh_retinaldetachmentrel1,
        fmh_retinaldetachmentrel2: familyData[0]?.fmh_retinaldetachmentrel2,
        fmh_keratoconus: familyData[0]?.fmh_keratoconus,
        fmh_keratoconusrel1: familyData[0]?.fmh_keratoconusrel1,
        fmh_keratoconusrel2: familyData[0]?.fmh_keratoconusrel2,
        fmh_diabetes: familyData[0]?.fmh_diabetes,
        fmh_diabetesrel1: familyData[0]?.fmh_diabetesrel1,
        fmh_diabetesrel2: familyData[0]?.fmh_diabetesrel2,
        fmh_heartdisease: familyData[0]?.fmh_heartdisease,
        fmh_heartdiseaserel1: familyData[0]?.fmh_heartdiseaserel1,
        fmh_heartdiseaserel2: familyData[0]?.fmh_heartdiseaserel2,
        fmh_highbloodpressure: familyData[0]?.fmh_highbloodpressure,
        fmh_highbloodpressurerel1: familyData[0]?.fmh_highbloodpressurerel1,
        fmh_highbloodpressurerel2: familyData[0]?.fmh_highbloodpressurerel2,
        fmh_lowbloodpressure: familyData[0]?.fmh_lowbloodpressure,
        fmh_lowbloodpressurerel1: familyData[0]?.fmh_lowbloodpressurerel1,
        fmh_lowbloodpressurerel2: familyData[0]?.fmh_lowbloodpressurerel2,
        fmh_kidneydisease: familyData[0]?.fmh_kidneydisease,
        fmh_kidneydiseaserel1: familyData[0]?.fmh_kidneydiseaserel1,
        fmh_kidneydiseaserel2: familyData[0]?.fmh_kidneydiseaserel2,
        fmh_lupus: familyData[0]?.fmh_lupus,
        fmh_lupusrel1: familyData[0]?.fmh_lupusrel1,
        fmh_lupusrel2: familyData[0]?.fmh_lupusrel2,
        fmh_thyroiddisease: familyData[0]?.fmh_thyroiddisease,
        fmh_thyroiddiseaserel1: familyData[0]?.fmh_thyroiddiseaserel1,
        fmh_thyroiddiseaserel2: familyData[0]?.fmh_thyroiddiseaserel2,
        fmh_nyctalopia: familyData[0]?.fmh_nyctalopia,
        fmh_nyctalopiarel1: familyData[0]?.fmh_nyctalopiarel1,
        fmh_nyctalopiarel2: familyData[0]?.fmh_nyctalopiarel2,
        fmh_eyepainorsoreness: familyData[0]?.fmh_eyepainorsoreness,
        fmh_eyepainorsorenessrel1: familyData[0]?.fmh_eyepainorsorenessrel1,
        fmh_eyepainorsorenessrel2: familyData[0]?.fmh_eyepainorsorenessrel2,
        fmh_infectionofeyeorlid: familyData[0]?.fmh_infectionofeyeorlid,
        fmh_infectionofeyeorlidrel1: familyData[0]?.fmh_infectionofeyeorlidrel1,
        fmh_infectionofeyeorlidrel2: familyData[0]?.fmh_infectionofeyeorlidrel2,
        fmh_stiesorchalazion: familyData[0]?.fmh_stiesorchalazion,
        fmh_stiesorchalazionrel1: familyData[0]?.fmh_stiesorchalazionrel1,
        fmh_stiesorchalazionrel2: familyData[0]?.fmh_stiesorchalazionrel2,
        fmh_cancer: familyData[0]?.fmh_cancer,
        fmh_cancerrel1: familyData[0]?.fmh_cancerrel1,
        fmh_cancerrel2: familyData[0]?.fmh_cancerrel2,
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: "UPDFMH",
      });
    }
  }, [familyData,discardUpdate]);
  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    // if (!isError() && error !== null && isActionCalled) {
    if (isActionCalled) {
      postFamilyHistory(familyDetail).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Family history added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setFamilyBtnUnable(false);
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    // } else {
    //   setIsActionCalled(false);
    // }

    setIsActionCalled(false);
  }, [isActionCalled]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAllSelected = (selectedValue) => {
    setFamilyDetail({
      ...familyDetail,
      fmh_glaucoma: selectedValue,
      fmh_lossofvision: selectedValue,
      fmh_lazyeye: selectedValue,
      fmh_masculardegeneration: selectedValue,
      fmh_colorblindness: selectedValue,
      fmh_retinaldetachment: selectedValue,
      fmh_keratoconus: selectedValue,
      fmh_diabetes: selectedValue,
      fmh_heartdisease: selectedValue,
      fmh_highbloodpressure: selectedValue,
      fmh_lowbloodpressure: selectedValue,
      fmh_kidneydisease: selectedValue,
      fmh_lupus: selectedValue,
      fmh_thyroiddisease: selectedValue,
      fmh_nyctalopia: selectedValue,
      fmh_eyepainorsoreness: selectedValue,
      fmh_infectionofeyeorlid: selectedValue,
      fmh_stiesorchalazion: selectedValue,
      fmh_cancer: selectedValue,
    });
    setAnchorEl(null);
    setFamilyBtnUnable(true);
  };
  const discardHandler=()=>{
    if(familyData && familyData.length > 0){
      setDiscardUpdate(true);
      setFamilyBtnUnable(false);
    }else{
      clearForm();
      setFamilyBtnUnable(false);
    }
  }

  const clearForm=()=>{
    setFamilyDetail({
      pat_familyhist_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    fmh_glaucoma: "",
    fmh_glaucomarel1: "",
    fmh_glaucomarel2: "",
    fmh_lossofvision: "",
    fmh_lossofvisionrel1: "",
    fmh_lossofvisionrel2: "",
    fmh_lazyeye: "",
    fmh_lazyeyerel1: "",
    fmh_lazyeyerel2: "",
    fmh_masculardegeneration: "",
    fmh_masculardegenerationrel1: "",
    fmh_masculardegenerationrel2: "",
    fmh_colorblindness: "",
    fmh_colorblindnessrel1: "",
    fmh_colorblindnessrel2: "",
    fmh_retinaldetachment: "",
    fmh_retinaldetachmentrel1: "",
    fmh_retinaldetachmentrel2: "",
    fmh_keratoconus: "",
    fmh_keratoconusrel1: "",
    fmh_keratoconusrel2: "",
    fmh_diabetes: "",
    fmh_diabetesrel1: "",
    fmh_diabetesrel2: "",
    fmh_heartdisease: "",
    fmh_heartdiseaserel1: "",
    fmh_heartdiseaserel2: "",
    fmh_highbloodpressure: "",
    fmh_highbloodpressurerel1: "",
    fmh_highbloodpressurerel2: "",
    fmh_lowbloodpressure: "",
    fmh_lowbloodpressurerel1: "",
    fmh_lowbloodpressurerel2: "",
    fmh_kidneydisease: "",
    fmh_kidneydiseaserel1: "",
    fmh_kidneydiseaserel2: "",
    fmh_lupus: "",
    fmh_lupusrel1: "",
    fmh_lupusrel2: "",
    fmh_thyroiddisease: "",
    fmh_thyroiddiseaserel1: "",
    fmh_thyroiddiseaserel2: "",
    fmh_nyctalopia: "",
    fmh_nyctalopiarel1: "",
    fmh_nyctalopiarel2: "",
    fmh_eyepainorsoreness: "",
    fmh_eyepainorsorenessrel1: "",
    fmh_eyepainorsorenessrel2: "",
    fmh_infectionofeyeorlid: "",
    fmh_infectionofeyeorlidrel1: "",
    fmh_infectionofeyeorlidrel2: "",
    fmh_stiesorchalazion: "",
    fmh_stiesorchalazionrel1: "",
    fmh_stiesorchalazionrel2: "",
    fmh_cancer: "",
    fmh_cancerrel1: "",
    fmh_cancerrel2: "",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: actionType,
    })
  }
  const [rel2Options, setRel2Options] = useState([]);
  return (
    <>
      {/* <TableWidget noBodyPadding> */}
      <Grid container spacing={2} className={classes.formBox}>
        {enable && (
          <Grid
            item
            xs={12}
            md={12}
            className={classes.textGroup}
            style={{
              border: "1px solid #DED9D9",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <IconButton
              id="basic-button"
              aria-controls={openDown ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openDown ? "true" : undefined}
              onClick={(e) => {
                handleClick(e);
              }}
            >
              <MoreIcon />
            </IconButton>

            <Menu
              className={classes.menuBox}
              id="basic-menu"
              anchorEl={anchorEl}
              open={openDown}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => handleAllSelected("Y")}>
              Set All Yes
              </MenuItem>

              <MenuItem onClick={() => handleAllSelected("N")}>
              Set All No
              </MenuItem>
              <MenuItem onClick={() => handleAllSelected("U")}>
              Set All Unknown
              </MenuItem>
              <MenuItem onClick={() => handleAllSelected("")}>
                 Clear All
              </MenuItem>
            </Menu>
          </Grid>
        )}
        {familyHistoryForm.map((element, i) => {
          return (
            <>
              <Grid
                item
                xs={12}
                md={element.mdSize}
                className={classes.textGroup}
                style={{ border: "1px solid #DED9D9" }}
              >
                <Typography
                  className={classes.formHeading}
                  style={{ fontWeight: "500", color: "#4E97FD" }}
                >
                  {element.label}{" "}
                  {element.required === "true" ? (
                    <span className={classes.required}>*</span>
                  ) : null}
                </Typography>
                {element.type ? (
                  <>
                    {element.type === "select" ? (
                      <FormControl
                        fullWidth
                        className={classes.dropdownFormControl}
                      >
                        <Select
                          name={element.key}
                          className={classes.selectDropdown}
                          placeholder={element.placeHolder}
                        >
                          <MenuItem value="">Select</MenuItem>
                          {element.options.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : element.type === "text" ? (
                      <TextField
                        required
                        name={element.key}
                        placeholder={element.placeHolder}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                        }}
                      />
                    ) : element.type === "date" ||
                      element.type === "datetime-local" ? (
                      <TextField
                        id={element.key}
                        name={element.key}
                        placeholder={element.placeHolder}
                        type={element.type}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          min: `${currentDateTime}`,
                          max: `${currentDate}`,
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    ) : element.type === "radio" ? (
                      <>
                        <RadioGroup
                          row
                          value={
                            familyDetail[element.key] &&
                            familyDetail[element.key] === "Y"
                              ? "Yes"
                              : familyDetail[element.key] === "N"
                              ? "No"
                              : familyDetail[element.key] === "U"
                              ? "Unknown"
                              : ""
                          }
                          onClick={(e) => {
                            console.log("clicked down");
                            let selectedValue;
                            switch (e.target.value) {
                              case "Yes":
                                selectedValue = "Y";
                                break;
                              case "No":
                                selectedValue = "N";
                                break;
                              case "Unknown":
                                selectedValue = "U";
                                break;
                              default:
                                selectedValue = "";
                            }

                            setFamilyDetail({
                              ...familyDetail,
                              [element.key]: selectedValue,
                            });
                            setFamilyBtnUnable(true);
                            setShowDiscard(true);
                          }}
                        >
                          {element.radioValue.map((item) => (
                            <FormControlLabel disabled={!enable}
                              size="small"
                              style={{ padding: "0px 6px" }}
                              value={item}
                              control={<Radio />}
                              label={item}
                              className={classes.smallRadioButton}
                            />
                          ))}
                        </RadioGroup>

                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <FormHelperText>Relationship</FormHelperText>
                          <Select disabled={!enable}
                            name={element.key}
                            className={classes.selectDropdown}
                            placeholder={element.placeHolder}
                            value={
                              familyDetail &&
                              familyDetail[`${element.key}rel1`] === null
                                ? ""
                                : familyDetail &&
                                  familyDetail[`${element.key}rel1`]
                            }
                            onChange={async (e) => {
                              const selectedValue = e.target.value;

                              // Disable or hide options in rel2 based on selectedValue
                              const updatedRel2Options = examFamilyRelation.filter(
                                (item) => item.attribute_id !== selectedValue
                              );

                              setFamilyDetail({
                                ...familyDetail,
                                [`${element.key}rel1`]: selectedValue,
                                [`${element.key}rel2`]: "", // Reset rel2 value when rel1 changes
                              });

                              setRel2Options(updatedRel2Options);
                              setFamilyBtnUnable(true);
                              setShowDiscard(true);
                            }}
                          >
                            <MenuItem disabled value="">
                              Select
                            </MenuItem>
                            {examFamilyRelation && examFamilyRelation.map((item) => (
                              <MenuItem
                                key={item.attribute_id}
                                value={item.attribute_id}
                              >
                                {item.attribute_val}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <FormHelperText>Relationship</FormHelperText>
                          <Select disabled={!enable}
                            name={element.key}
                            className={classes.selectDropdown}
                            placeholder={element.placeHolder}
                            value={
                              familyDetail &&
                              familyDetail[`${element.key}rel2`] === null
                                ? ""
                                : familyDetail &&
                                  familyDetail[`${element.key}rel2`]
                            }
                            onChange={async (e) => {
                              setFamilyDetail({
                                ...familyDetail,
                                [`${element.key}rel2`]: e.target.value,
                              });
                              setFamilyBtnUnable(true);
                              setShowDiscard(true);
                            }}
                          >
                            <MenuItem disabled value="">
                              Select
                            </MenuItem>
                            {/* {rel2Options.map((item) => (
                              <MenuItem
                                key={item.attribute_id}
                                value={item.attribute_id}
                              >
                                {item.attribute_val}
                              </MenuItem>
                            ))} */}
                            {examFamilyRelation && examFamilyRelation.map((item) => (
                              <MenuItem
                                key={item.attribute_id}
                                value={item.attribute_id}
                              >
                                {item.attribute_val}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </>
                    ) : element.type === "chechbox" ? (
                      <>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              value={element.value}
                              control={<Checkbox />}
                            />
                          </FormGroup>
                        </FormControl>
                      </>
                    ) : (
                      <TextField disabled={!enable}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        placeholder="Type here..."
                        variant="outlined"
                        multiline
                        rows={3}
                        inputProps={{
                          style: {
                            fontSize: "12px",
                          },
                        }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Typography
                      className={classes.formHeading}
                      style={{
                        fontWeight: "600",
                        borderBottom: "",
                        color: "#4E97FD",
                      }}
                    >
                      {element.title}
                    </Typography>
                  </>
                )}
              </Grid>
            </>
          );
        })}
        <Grid className={classes.btnGroup} item xs={12} style={{ display: "flex", justifyContent: "end" }}>
        {showDiscard && (
            <Button
              className={classes.btnleftOutline}
              variant="outlined"
              style={{ marginRight: "10px", fontSize: "12px" }}
              size="medium"
              onClick={discardHandler}
            >
              Discard
            </Button>
          )}
          {enable && 
          <Button disabled={!familyBtnUnable}
          style={{ display: "flex", float: "right" }}
          onClick={handleSubmit}
          className={classes.btnlRightDark}
          variant="contained"
          size="medium"
          color="secondary"
        >
          Save
        </Button>
          }
          
        </Grid>
      </Grid>

      {/* </TableWidget> */}
    </>
  );
}
