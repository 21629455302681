import React, { useState } from "react";
import {
  Grid,
  Modal,
  Typography,
  Box,
  TextField,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@material-ui/core";
// Model
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Popup from "../common/modal/Popup";
// styles
import useStyles from "./styles";
import AdminValidation from '../../hooks/AdminValidation';
export default function ManagePatient(props) {
  //
  console.log("props-24", props);
  const { selectadmin } = props;
  console.log("selectadmin", selectadmin);
  const [isShow, setIsShow] = useState(false);
  //
  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [personName, setPersonName] = React.useState([]);
  const [adminDetail, setAdminDetail] = useState({
    name:null,
    email: null,
    organization: null,
  });
  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
    AdminValidation({
      name: adminDetail?.name,
      email: adminDetail?.email ,
      organization: adminDetail?.organization,
      
    });
    const submitHandler = (e) => {
      e.preventDefault();
      checkValidator();
      console.log("adminName", adminDetail?.name);
      // setIsActionCalled(true);
    }
  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState("");
  const handleChange1 = (event) => {
    setValue(event.target.value);
  };
  const names = [
    "Organisation A",
    "Organisation B",
    "Organisation C",
    "Organisation D",
  ];
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={(e) => submitHandler(e)}
            >
              Add
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            {/* org name */}
            <Typography className={classes.formHeading}>Name</Typography>
            <TextField
              size="small"
              value={adminDetail.name}
              className={classes.txtForm}
              fullWidth
              placeholder="Enter Name"
              variant="outlined"
            />
            {/* shortname */}
            <Typography className={classes.formHeading}>Email Id</Typography>
            <TextField
              size="small"
              value={adminDetail.email}
              className={classes.txtForm}
              fullWidth
              placeholder="Enter Email Id"
              variant="outlined"
            />

            {/* Modules */}
            <Typography className={classes.formHeading}>
             Sub-Organizations
            </Typography>
            <FormControl fullWidth className={classes.dropdownFormControl}>
              <Select
                multiple
                value={personName}
                style={{ fontSize: '12px' }}
                onChange={handleChange}
                className={classes.selectDropdown}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Placeholder</em>;
                  }

                  return selected.join(", ");
                }}
              >
                <MenuItem disabled value="">
                  <em>Placeholder</em>
                </MenuItem>
                {names.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={personName.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <FormControl fullWidth className={classes.dropdownFormControl}>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                className={classes.selectDropdown}
                value={value}
                onChange={handleChange1}
              >
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="a">Organisation A</MenuItem>
                <MenuItem value="b">Organisation B</MenuItem>
                <MenuItem value="c">Organisation C</MenuItem>
                <MenuItem value="d">Organisation D</MenuItem>
              </Select>
            </FormControl> */}
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
