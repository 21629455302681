import React, { useState, useEffect } from "react";
import { Grid, Select, MenuItem, Input, Badge } from "@material-ui/core";

import { useTheme } from "@material-ui/styles";

import classnames from "classnames";
import logo from "../../../../images/blank_img.jpg";
// styles
import useStyles from "./styles";

// components
import Widget from "../../../../components/Widget";
import { Typography } from "../../../../components/Wrappers";
import moment from "moment";
import AlertPopup from "../../AlertPopup";
export default function BigStat(props) {
  var {
    patient_id,
    first_name,
    last_name,
    start_time,
    end_time,
    title,
    patient_checkin_status,
  } = props;

  var classes = useStyles();
  var theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectclient, setselectclient] = useState(null);
  // local
  var [value, setValue] = useState("daily");
  const alertOpen = (item) => {
    console.log("item", item);
    setOpen(true);
    setDeleteModalShow(true);
    setselectclient(item);
  };
  const hideHandle = () => {
    setOpen(false);
    setDeleteModalShow(false);
  };
  return (
    <>
      <AlertPopup
        open={open}
        deleteModalShow={deleteModalShow}
        setDeleteModalShow={setDeleteModalShow}
        selectclient={selectclient}
        onHide={hideHandle}
      />
      <Widget
        upperTitle
        className={classes.card}
        bodyClass={classes.fullHeightBody}
      >
        <div className={classes.totalValueContainer}>
          <div className={classes.totalValue}>
            <Badge onClick={() => alertOpen(props)} style={{marginRight:'0px',width:'72px',cursor:'pointer'}}
              badgeContent={patient_checkin_status}
              color="secondary" // You can change the badge color as needed
            >
              <div style={{marginLeft:'6px'}}></div>
            </Badge>
            <Typography
              style={{ fontSize: "16px", fontWeight: "500" }}
              color="text"
              colorBrightness="secondary"
              onClick={() => alertOpen(props)}
            >
              {first_name} {last_name}
            </Typography>
            <Typography size="SM" color="text" colorBrightness="secondary">
              {title}
            </Typography>
          </div>
          {/* <img className={classes.logoImg} src={logo} alt="DocMadeUSA" /> */}
        </div>
        <div className={classes.bottomStatsContainer}>
          <div className={classnames(classes.statCell, classes.borderRight)}>
            <Grid container alignItems="center">
              <Typography
                variant="lg"
                style={{ fontSize: "14px", fontWeight: "500" }}
              >
                Time
              </Typography>
            </Grid>
            <Typography
              size="md"
              color="text"
              style={{ fontSize: "14px", fontWeight: "400" }}
              colorBrightness="secondary"
            >
              {moment(start_time).utc().format("LT")}
            </Typography>
          </div>
          <div className={classes.statCell}>
            <Grid container alignItems="center">
              <Typography
                variant="lg"
                style={{ fontSize: "14px", fontWeight: "500" }}
              >
                Date
              </Typography>
            </Grid>
            <Typography
              size="md"
              color="text"
              style={{ fontSize: "14px", fontWeight: "400" }}
              colorBrightness="secondary"
            >
              {moment(start_time).format("L")}
            </Typography>
          </div>
        </div>
      </Widget>
    </>
  );
}

// #######################################################################

function getRandomData() {
  return Array(7)
    .fill()
    .map(() => ({ value: Math.floor(Math.random() * 10) + 1 }));
}
