import React, { useEffect, useState } from "react";

import {
  Grid,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Select,
  FormControl,
  FormGroup,
  Checkbox,
  Button,
  IconButton,
  Menu,
} from "@material-ui/core";
// data

// Model
import { examSection } from "./External_Constant";
import useStyles from "../styles";
// components

import moment from "moment";
import ManagePupil from "./ManagePupil";
import { currentUser, loadState } from "../../../hooks/helper";
import { toast } from "react-toastify";
import {
  getPatientExternalEs,
  getPatientExternalPupils,
  postExternalExam,
} from "../../../services/ServiceLayer";
import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
export default function ExamSection({
  patientId,
  examExternalFatProlapse,
  examExternalBinocular,

  examExternalPupilAPD,
  examExternalPupilNPI,
  examExternalPupilRAPD,
  examExternalPupilReacts,
  examExternalPupilShape,
  examExternalPupilSize,

  examExternalEyeLid,
  examExternalCornea,
  examExternalTearFilm,
  examExternalOrbit,
  examExternalTearIris,
  examExternalLacrimal,
  examExternalAnteriorChamber,
  examExternalConjunctiva,
  examExternalSclera,
  examExternalPreauricularNode,
  examBtnUnable,
  setExamBtnUnable,
}) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;

  const [isActionCalled, setIsActionCalled] = useState(false);
  const [pupilId, setPupilId] = useState("");
  const [open, setOpen] = useState(false);
  const [examDetails, setExamDetails] = useState({
    pat_extes_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    eyelid_od: "",
    lacrimalsys_od: "",
    cornea_od: "",
    conjunctiva_od: "",
    anteriorchamber_od: "",
    ac_iris_od: "",
    tearfilm_od: "",
    sclera_od: "",
    preauricularnode_od: "",
    orbits_od: "",
    face_od: "",
    fatprolapse_od: "",
    pupil_od: '',
    binocular_function_od: "",
    eyelid_os: "",
    lacrimalsys_os: "",
    cornea_os: "",
    conjunctiva_os: "",
    anteriorchamber_os: "",
    ac_iris_os: "",
    tearfilm_os: "",
    sclera_os: "",
    preauricularnode_os: "",
    orbits_os: "",
    face_os: "",
    fatprolapse_os: "",
    pupil_os: '',
    binocular_function_os: "",
    updated_by: currentUser(),
    access_location: "Y",
    opt_type: "ADDEXT",
  });
  useEffect(()=>{
    if(pupilId){
      console.log("pupilId",pupilId);
      setExamDetails({
        ...examDetails,
        pupil_od: pupilId,
        pupil_os:pupilId
      })
    }
  },[pupilId])
  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    // if (!isError() && error !== null && isActionCalled) {
    if (isActionCalled) {
      postExternalExam(examDetails).then((response) => {
        console.log(response);
        if (response?.status?.status) {
          toast.success("Exam added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setExamBtnUnable(false);
          // clearForm();
          // props.onHide();
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    // } else {
    //   setIsActionCalled(false);
    // }

    setIsActionCalled(false);
  }, [isActionCalled]);

  const [examEsData, setExamEsData] = useState([]);
  useEffect(() => {
    getPatientExternalEs(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELEXT`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setExamEsData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
    getPatientExternalPupils(`location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELPPL`).then((response)=>{
      if(response?.data){
        console.log("response", response);
        const pupilId = response?.data[0]?.fn_exam_patient_pupil.replace('SUCCESS-', '');
          setPupilId(pupilId)
      }
    })
  }, []);

  const [discardUpdate, setDiscardUpdate] = useState(false);
  useEffect(() => {
    if (examEsData.length > 0) {
      setExamDetails({
        pat_extes_id: examEsData[0]?.pat_ext_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        eyelid_od: examEsData[0]?.eyelid_od,
        lacrimalsys_od: examEsData[0]?.lacrimalsys_od,
        cornea_od: examEsData[0]?.cornea_od,
        conjunctiva_od: examEsData[0]?.conjunctiva_od,
        anteriorchamber_od: examEsData[0]?.anteriorchamber_od,
        ac_iris_od: examEsData[0]?.ac_iris_od,
        tearfilm_od: examEsData[0]?.tearfilm_od,
        sclera_od: examEsData[0]?.sclera_od,
        preauricularnode_od: examEsData[0]?.preauricularnode_od,
        orbits_od: examEsData[0]?.orbits_od,
        face_od: examEsData[0]?.face_od,
        fatprolapse_od: examEsData[0]?.fatprolapse_od,
        pupil_od: examEsData[0]?.pupil_od,
        binocular_function_od: examEsData[0]?.binocular_function_od,
        eyelid_os: examEsData[0]?.eyelid_os,
        lacrimalsys_os: examEsData[0]?.lacrimalsys_os,
        cornea_os: examEsData[0]?.cornea_os,
        conjunctiva_os: examEsData[0]?.conjunctiva_os,
        anteriorchamber_os: examEsData[0]?.anteriorchamber_os,
        ac_iris_os: examEsData[0]?.ac_iris_os,
        tearfilm_os: examEsData[0]?.tearfilm_os,
        sclera_os: examEsData[0]?.sclera_os,
        preauricularnode_os: examEsData[0]?.preauricularnode_os,
        orbits_os: examEsData[0]?.orbits_os,
        face_os: examEsData[0]?.face_os,
        fatprolapse_os: examEsData[0]?.fatprolapse_os,
        pupil_os: examEsData[0]?.pupil_os,
        binocular_function_os: examEsData[0]?.binocular_function_os,
        updated_by: currentUser(),
        access_location: "Y",
        opt_type: "UPDEXT",
      });
      setDiscardUpdate(false);
    }
  }, [examEsData, discardUpdate]);
  const handleCheckboxChange = (copy, paste, e) => {
    console.log("copy", copy);
    console.log("copy to", paste);
    setAnchorEl(null);
    setExamDetails({
      ...examDetails,
      [`eyelid_${paste}`]: examDetails?.[`eyelid_${copy}`],
      [`lacrimalsys_${paste}`]: examDetails?.[`lacrimalsys_${copy}`],
      [`cornea_${paste}`]: examDetails?.[`cornea_${copy}`],
      [`conjunctiva_${paste}`]: examDetails?.[`conjunctiva_${copy}`],
      [`anteriorchamber_${paste}`]: examDetails?.[`anteriorchamber_${copy}`],
      [`ac_iris_${paste}`]: examDetails?.[`ac_iris_${copy}`],
      [`tearfilm_${paste}`]: examDetails?.[`tearfilm_${copy}`],
      [`sclera_${paste}`]: examDetails?.[`sclera_${copy}`],
      [`preauricularnode_${paste}`]: examDetails?.[`preauricularnode_${copy}`],
      [`orbits_${paste}`]: examDetails?.[`orbits_${copy}`],
      [`face_${paste}`]: examDetails?.[`face_${copy}`],
      [`fatprolapse_${paste}`]: examDetails?.[`fatprolapse_${copy}`],
    });
  };

  const addPupil = () => {
    setOpen(true);
  };
  const hideHandle = () => {
    setOpen(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const clearForm = () => {
    setExamDetails({
      pat_extes_id: 0,
      patient_id: patientId,
      location_id: userData?.location_id,
      eyelid_od: "",
      lacrimalsys_od: "",
      cornea_od: "",
      conjunctiva_od: "",
      anteriorchamber_od: "",
      ac_iris_od: "",
      tearfilm_od: "",
      sclera_od: "",
      preauricularnode_od: "",
      orbits_od: "",
      face_od: "",
      fatprolapse_od: "",
      pupil_od: pupilId,
      binocular_function_od: "",
      eyelid_os: "",
      lacrimalsys_os: "",
      cornea_os: "",
      conjunctiva_os: "",
      anteriorchamber_os: "",
      ac_iris_os: "",
      tearfilm_os: "",
      sclera_os: "",
      preauricularnode_os: "",
      orbits_os: "",
      face_os: "",
      fatprolapse_os: "",
      pupil_os: pupilId,
      binocular_function_os: "",
      updated_by: currentUser(),
      access_location: "Y",
      opt_type: "ADDEXT",
    });
    setDiscardUpdate(false);
  };
  const [showDiscard, setShowDiscard] = useState(false);

  const discardHandler = () => {
    if (examEsData && examEsData.length > 0) {
      setExamBtnUnable(false);
      setDiscardUpdate(true);
    } else {
      clearForm();
      setExamBtnUnable(false);
    }
  };
  // Example mapping, replace with your actual data structure
  const optionsMap = {
    eyelid_od: examExternalEyeLid,
    eyelid_os: examExternalEyeLid,
    lacrimalsys_od: examExternalLacrimal,
    lacrimalsys_os: examExternalLacrimal,
    cornea_od: examExternalCornea,
    cornea_os: examExternalCornea,
    conjunctiva_od: examExternalConjunctiva,
    conjunctiva_os: examExternalConjunctiva,
    anteriorchamber_od: examExternalAnteriorChamber,
    anteriorchamber_os: examExternalAnteriorChamber,
    ac_iris_od: examExternalTearIris,
    ac_iris_os: examExternalTearIris,
    tearfilm_od: examExternalTearFilm,
    tearfilm_os: examExternalTearFilm,
    sclera_od: examExternalSclera,
    sclera_os: examExternalSclera,
    preauricularnode_od: examExternalPreauricularNode,
    preauricularnode_os: examExternalPreauricularNode,
    orbits_od: examExternalOrbit,
    orbits_os: examExternalOrbit,
    face_od: examExternalLacrimal,
    face_os: examExternalLacrimal,
    fatprolapse_od: examExternalFatProlapse,
    fatprolapse_os: examExternalFatProlapse,
    binocular_function_od: examExternalBinocular,
    binocular_function_os: examExternalBinocular,
  };

  // Central handler for all Autocomplete changes
  const handleAutocompleteChange = (key, newValue) => {
    setExamDetails((prev) => ({
      ...prev,
      [key]: newValue ? newValue.attribute_id : null,
    }));
    setExamBtnUnable(true);
    setShowDiscard(true);
  };
  return (
    <>
      <ManagePupil
        title="Add Pupil"
        patientId={patientId}
        open={open}
        pupilId={pupilId}
        setPupilId={setPupilId}
        examExternalPupilAPD={examExternalPupilAPD}
        examExternalPupilNPI={examExternalPupilNPI}
        examExternalPupilRAPD={examExternalPupilRAPD}
        examExternalPupilReacts={examExternalPupilReacts}
        examExternalPupilShape={examExternalPupilShape}
        examExternalPupilSize={examExternalPupilSize}
        onHide={hideHandle}
      />
      {/* <TableWidget noBodyPadding> */}
      <Grid
        container
        spacing={2}
        className={classes.formBox}
        style={{ border: "1px solid #DED9D9" }}
      >
        <Grid
          item
          xs={12}
          md={12}
          className={classes.textGroup}
          style={{ padding: "0px", display: "flex", justifyContent: "end" }}
        >
          <IconButton
            id="basic-button"
            aria-controls={openDown ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openDown ? "true" : undefined}
            onClick={(e) => {
              handleClick(e);
            }}
          >
            <MoreIcon />
          </IconButton>

          <Menu
            style={{ fontSize: "12px" }}
            className={classes.menuBox}
            id="basic-menu"
            anchorEl={anchorEl}
            open={openDown}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              style={{ fontSize: "12px" }}
              onClick={() => handleCheckboxChange("od", "os")}
            >
              Copy OD to OS
            </MenuItem>

            <MenuItem
              style={{ fontSize: "12px" }}
              onClick={() => handleCheckboxChange("os", "od")}
            >
              Copy OS to OD
            </MenuItem>
          </Menu>
        </Grid>
        {examSection.map((element, i) => {
          return (
            <>
              <Grid
                item
                xs={12}
                md={element.mdSize}
                className={classes.textGroup}
                style={{ padding: "0px 8px" }}
              >
                {element.label && (
                  <Typography
                    className={classes.formHeading}
                    style={{ fontWeight: "500", color: "#4E97FD" }}
                  >
                    {element.label}{" "}
                    {element.required === "true" ? (
                      <span className={classes.required}>*</span>
                    ) : null}
                    {element.copyBtn && (
                      <Button
                        style={{
                          float: "right",
                          position: "relative",
                          fontSize: "10px",
                        }}
                        classes={{ root: classes.button }}
                        variant="contained"
                        color="secondary"
                        // onClick={duplicateValue}
                      >
                        {element?.copyBtn}
                      </Button>
                    )}
                  </Typography>
                )}

                {element.type ? (
                  <>
                    {element.type === "select" ? (
                      <Autocomplete
                        style={{ marginBottom: "10px" }}
                        options={optionsMap[element.key] || []}
                        getOptionLabel={(option) => option.attribute_val || ""}
                        value={
                          (optionsMap[element.key] || []).find(
                            (option) =>
                              option.attribute_id === examDetails[element.key]
                          ) || null
                        }
                        onChange={(e, newValue) =>
                          handleAutocompleteChange(element.key, newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={element.label}
                            variant="outlined"
                            fullWidth
                            // InputProps={{
                            //   ...params.InputProps,
                            //   style: {
                            //     height: '36px',
                            //     padding: '10 14px',
                            //   },
                            // }}
                            inputProps={{
                              ...params.inputProps,
                              style: {
                                height: "auto", // Adjust if you need to change the height of the inner input element
                                padding: "0 14px",
                              },
                            }}
                          />
                        )}
                      />
                    ) : element.type === "text" ? (
                      <TextField
                        required
                        name={element.key}
                        placeholder={element.placeHolder}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                        }}
                        value={
                          examDetails && examDetails[element.key] === null
                            ? ""
                            : examDetails && examDetails[element.key]
                        }
                        onChange={async (e) => {
                          setExamDetails({
                            ...examDetails,
                            [element.key]: e.target.value,
                          });
                          setExamBtnUnable(true);
                        }}
                      />
                    ) : element.type === "date" ||
                      element.type === "datetime-local" ? (
                      <TextField
                        id={element.key}
                        name={element.key}
                        placeholder={element.placeHolder}
                        type={element.type}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          min: `${currentDateTime}`,
                          max: `${currentDate}`,
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={
                          examDetails && examDetails[element.key] === null
                            ? ""
                            : examDetails && examDetails[element.key]
                        }
                        onChange={async (e) => {
                          setExamDetails({
                            ...examDetails,
                            [element.key]: e.target.value,
                          });
                          setExamBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                    ) : element.type === "radio" ? (
                      <RadioGroup row defaultValue="">
                        {element.radioValue.map((item) => (
                          <FormControlLabel
                            size="small"
                            style={{ padding: "0px 6px" }}
                            value={item}
                            control={<Radio />}
                            label={item}
                            className={classes.smallRadioButton}
                          />
                        ))}
                      </RadioGroup>
                    ) : element.type === "checkbox" ? (
                      <>
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              value={element.value}
                              control={<Checkbox />}
                              label={element.lbltitle}
                              labelPlacement="start"
                            />
                          </FormGroup>
                        </FormControl>
                      </>
                    ) : element.type === "button" ? (
                      <>{pupilId ? pupilId : 
                        <Button
                          style={{ fontSize: "10px", marginTop: "10px" }}
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                          onClick={addPupil}
                        >
                          {element.btnlbl}
                        </Button>
                      }
                        
                      </>
                    ) : (
                      <TextField
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        placeholder="Type here..."
                        variant="outlined"
                        multiline
                        rows={3}
                        inputProps={{
                          style: {
                            fontSize: "12px",
                          },
                        }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Typography
                      className={classes.formHeading}
                      style={{
                        fontWeight: "600",

                        color: "#4E97FD",
                      }}
                    >
                      {element.title}
                    </Typography>
                  </>
                )}
              </Grid>
            </>
          );
        })}
        <Grid
          className={classes.btnGroup}
          item
          xs={12}
          style={{ display: "flex", justifyContent: "end" }}
        >
          {showDiscard && (
            <Button
              className={classes.btnleftOutline}
              variant="outlined"
              style={{ marginRight: "10px", fontSize: "12px" }}
              size="medium"
              onClick={discardHandler}
            >
              Discard
            </Button>
          )}
          <Button
            disabled={!examBtnUnable}
            style={{ display: "flex", float: "right" }}
            onClick={handleSubmit}
            className={classes.btnlRightDark}
            variant="contained"
            size="medium"
            color="secondary"
          >
            Save
          </Button>
        </Grid>
      </Grid>

      {/* </TableWidget> */}
    </>
  );
}
