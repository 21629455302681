import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";

import useStyles from "../styles";
import { formNotesElements } from "./Assessment_Constant";

import { currentUser, loadState } from "../../../hooks/helper";
import { toast } from "react-toastify";
import { postPatientAssessmentNotes,getPatientAssessmentNotes } from "../../../services/ServiceLayer";

export default function Notes({
  patientId,
  assessmentNotesBtnUnable,
  setAssessmentNotesBtnUnable,
}) {
  var classes = useStyles();

  // const previousController = useRef();
  const userData = loadState().user;
  const [assessmentNotes, setAssessmentNotes] = useState({
    pat_extfcnt_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    asm_notes: "",
    updated_by: currentUser(),
    access_location: "",
    opt_type: "ADDASM",
  });
  const [isActionCalled, setIsActionCalled] = useState(false);
  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    if (isActionCalled) {
      postPatientAssessmentNotes(assessmentNotes).then((response) => {
        console.log(response);
        if (response?.status?.status) {
          toast.success("Notes added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setAssessmentNotesBtnUnable(false);
          // props.onHide();
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    setIsActionCalled(false);
  }, [isActionCalled]);
  const userRole =loadState().user.user_role;
  const [assessmentNotesList,setAssessmentNotesList] = useState([]);
  useEffect(() => {
    getPatientAssessmentNotes(
      `patient_id=${patientId}&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELASM`
    ).then((response) => {
      console.log(response);
      setAssessmentNotesList(response.data);
    });
  }, []);
  useEffect(()=>{
  if(assessmentNotesList && assessmentNotesList.length>0){
    setAssessmentNotes({
      pat_extfcnt_id: assessmentNotesList[0]?.pat_extfcnt_id,
    patient_id: patientId,
    location_id: userData?.location_id,
    asm_notes: assessmentNotesList[0]?.asm_notes,
    updated_by: currentUser(),
    access_location: assessmentNotesList[0]?.access_location,
    opt_type: "UPDASM",
    })
  }
    },[assessmentNotesList])
  return (
    <>
      <Grid container spacing={2} className={classes.formBox}>
        {formNotesElements.map((element, i) => {
          return (
            <Grid
              item
              xs={12}
              md={element.mdSize}
              className={`${classes.textGroup} ${
                element.title ? classes.highlightedItem : ""
              }`}
              style={{ border: "1px solid #DED9D9" }}
            >
              {element.label ||
                (element.copyBtn && (
                  <Typography
                    className={classes.formHeading}
                    style={{
                      fontWeight: "500",
                      color: "#4E97FD",
                      position: "relative",
                      marginTop: "0px",
                    }}
                  >
                    {element.label}&nbsp;
                    {element.required === "true" ? (
                      <span className={classes.required}>*</span>
                    ) : null}
                    {element.copyBtn === "true" && (
                      <Button
                        style={{
                          float: "right",
                          fontSize: "10px",
                          // position: "absolute",
                          // top: "20px",
                        }}
                        classes={{ root: classes.button }}
                        variant="contained"
                        color="secondary"
                        // onClick={duplicateValue}
                      >
                        {element.copyLabel}
                      </Button>
                    )}
                  </Typography>
                ))}
              {element.type ? (
                <>
                  {element.type === "select" &&
                  element.key !== "procedure_code" ? (
                    <FormControl
                      fullWidth
                      className={classes.dropdownFormControl}
                    >
                      <Select
                        name={element.key}
                        className={classes.selectDropdown}
                        placeholder={element.placeHolder}
                        value={
                          assessmentNotes && assessmentNotes[element.key]
                            ? assessmentNotes && assessmentNotes[element.key]
                            : ""
                        }
                        onChange={(e) => {
                          console.log("selected");
                          setAssessmentNotes({
                            ...assessmentNotes,
                            [element.key]: e.target.value,
                          });
                          setAssessmentNotesBtnUnable(true);
                        }}
                      >
                        <MenuItem disabled value="">
                          Select
                        </MenuItem>
                        {element.options.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : element.type === "text" ? (
                    <div style={{ display: "flex" }}>
                      <TextField
                        required
                        name={element.key}
                        placeholder={element.placeHolder}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                          maxLength: element.maxLength,
                          onKeyPress: element.onKeyPress,
                        }}
                        value={
                          assessmentNotes && assessmentNotes[element.key]
                            ? assessmentNotes && assessmentNotes[element.key]
                            : ""
                        }
                        onChange={(e) => {
                          console.log("selected");
                          setAssessmentNotes({
                            ...assessmentNotes,
                            [element.key]: e.target.value,
                          });
                          setAssessmentNotesBtnUnable(true);
                        }}
                      />
                      {element.key === "sku" && (
                        <Button
                          // onClick={selectFrameBySku}
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                        >
                          Slect
                        </Button>
                      )}
                    </div>
                  ) : element.type === "date" ||
                    element.type === "datetime-local" ? (
                    <TextField
                      id={element.key}
                      name={element.key}
                      placeholder={element.placeHolder}
                      type={element.type}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        assessmentNotes && assessmentNotes[element.key]
                          ? assessmentNotes && assessmentNotes[element.key]
                          : ""
                      }
                      onChange={(e) => {
                        console.log("selected");
                        setAssessmentNotes({
                          ...assessmentNotes,
                          [element.key]: e.target.value,
                        });
                        setAssessmentNotesBtnUnable(true);
                      }}
                    />
                  ) : element.type === "radio" ? (
                    <RadioGroup row defaultValue="">
                      {element.radioValue.map((item) => (
                        <FormControlLabel
                          size="small"
                          style={{ padding: "0px 6px" }}
                          value={item}
                          control={<Radio />}
                          label={item}
                          className={classes.smallRadioButton}
                        />
                      ))}
                    </RadioGroup>
                  ) : element.type === "checkbox" ? (
                    <>
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value={element.value}
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  const selectedValue = e.target.checked
                                    ? "Y"
                                    : "N";
                                  setAssessmentNotes({
                                    ...assessmentNotes,
                                    [element.key]: selectedValue,
                                  });
                                  setAssessmentNotesBtnUnable(true);
                                }}
                              />
                            }
                            label={element.lbltitle}
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </FormControl>
                    </>
                  ) : element.type === "button" &&
                    element?.btnlbl === "Lookup" ? (
                    <>
                      <Button
                        classes={{ root: classes.button }}
                        variant="contained"
                        color="secondary"
                        // onClick={selectPrevFrame}
                      >
                        {element.btnlbl}
                      </Button>
                    </>
                  ) : (
                    <TextField
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      placeholder="Type here..."
                      variant="outlined"
                      multiline
                      rows={3}
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      value={
                        assessmentNotes && assessmentNotes[element.key]
                          ? assessmentNotes && assessmentNotes[element.key]
                          : ""
                      }
                      onChange={(e) => {
                        console.log("selected");
                        setAssessmentNotes({
                          ...assessmentNotes,
                          [element.key]: e.target.value,
                        });
                        setAssessmentNotesBtnUnable(true);
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <Typography
                    className={classes.formHeading}
                    style={{
                      fontWeight: "600",

                      color: "#4E97FD",
                    }}
                  >
                    {element.title}
                  </Typography>
                </>
              )}
            </Grid>
          );
        })}
        <Grid className={classes.btnGroup} item xs={12}>
          <Button
            disabled={!assessmentNotesBtnUnable}
            style={{ display: "flex", float: "right" }}
            onClick={handleSubmit}
            className={classes.btnlRightDark}
            variant="contained"
            size="medium"
            color="secondary"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
