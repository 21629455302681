import axios from "axios";
import { constant, loadState } from "../hooks/helper";
const { path } = constant;

class DataService {
  async post(arg, endpoint) {
    const userToken = loadState().token;
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token-docmed': userToken
      }
    };
    return axios
      .post(endpoint, arg, axiosConfig)
      .then(({ data }) => {
        console.log("data", data);
        return data;
      })
      .catch((error) => {
        const data = error?.code;
        if (data === "ERR_NETWORK") {
          return "Something went wrong, please check your internet connection and try again";
        }
        return error.response === undefined
          ? "Something went wrong, please check your internet connection and try again"
          : error.response.data;
      });
  }

  async postFormData(formData, endpoint) {
    const userToken = loadState().token;
    let axiosConfig = {
      headers: {
        'x-access-token-docmed': userToken,
        "Content-Type": "multipart/form-data"
      }
    };

    return axios
      .post(endpoint, formData, axiosConfig)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        const data = error?.code;
        if (data === "ERR_NETWORK") {
          return "Something went wrong, please check your internet connection and try again";
        }
        return error.response === undefined
          ? "Something went wrong, please check your internet connection and try again"
          : error.response.data;
      });
  }

  async get(endpoint) {
    const userToken = loadState().token;
    let axiosConfig = {
      headers: {
        'x-access-token-docmed': userToken,
        'Content-Type': 'application/json'

      }
    };
    return axios
      .get(endpoint,axiosConfig)
      .then(({ data }) => {
        console.log("data", data);
        if (data.status.description === "Unauthorized"){
          sessionStorage.removeItem("id_token");
          window.location = "/login"
        }
        else {
          return data;
        }
      })
      .catch((error) => {
        const data = error?.code;
        if (data === "ERR_NETWORK") {
          return "Something went wrong, please check your internet connection and try again";
        }
        return error.response === undefined
          ? "Something went wrong, please check your internet connection and try again"
          : error.response.data;
      });
  }
}
export default new DataService();
