import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  Grid,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Select,
  FormControl,
  FormGroup,
  Checkbox,
  Button,
  Avatar,
  TableRow,Fade,
  TableCell,
  Tabs,
  Tab,
  Paper,
} from "@material-ui/core";
// data

// Model
import { spectacleFormElements } from "../inventory_constant";

import TableDesign from "../../common/table/TableDesign";

import useStyles from "./styles";
// components

import moment from "moment";
import PageTitle from "../../PageTitle/PageTitle";
import LocationList from "./LocationList";
import TransactionList from "./TransactionList";
import MaterialsList from "./MaterialsList";
import ManageProcedureCost from "./ManageProcedureCost";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  currentUser,
  postFlagBooleanVariable,
  loadState,
} from "../../../hooks/helper";
import {
  postSpectacleLense,
  getProcedureCode,
  getManufacturerDetail,getSupplierDetail,
  getInvtService,
  getServiceMaster,getSpectacleService
} from "../../../services/ServiceLayer";
import { ToastContainer, toast } from "react-toastify";
import InventoryValidation from "../../../hooks/InventoryValidation";
import "react-toastify/dist/ReactToastify.css";
import ManageLocalProcedure from "./ManageLocalProcedure";
export default function AddSpectacleLens() {
  const history = useHistory();
  const { state } = useLocation();
  const selectedRow = state?.selectedRow;
  const eventType = state?.event;

  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var [activeTabId, setActiveTabId] = useState(0);
  const [listData, setListData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModel, setOpenModel] = React.useState(false);
  const [modelShow, setModalShow] = useState(false);
  const [selectclient, setselectclient] = useState(null);
  const [profitValue, setProfitValue] = useState(0);
  const [grossPercentage, setGrossPercentage] = useState(0);
  const [actionType, setActionType] = useState("ADDSPC");
  const [prodecureCodeData, setProdecureCodeData] = useState([]);
  const [invtUinitData, setInvtUnitData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [returnableData, setReturnableData] = useState([]);
  const [invSourceData, setInvSourceData] = useState([]);
  const [inventoryValue, setInventoryValue] = useState(0);
  const [manufacturerData, setManufacturerData] = useState([]);
  const [structureList, setStructureList] = useState([]);
  const [inventoryReadValue, setInventoryReadValue] = useState();
  const [selectedProcedureCodes, setSelectedProcedureCodes] = useState([]);
  const [frameMaterialData, setFrameMaterialData] = useState([]);
  const [isActionExit, setIsActionExit] = useState(false);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [frameBrandData, setFrameBrandData] = useState([]);
  // Initialize selectedProcedureCodes as an empty array

  // call meta data
  const metaData = loadState().metaData;
  const readAccessData = loadState().menu;

  useEffect(() => {
    console.log("readAccessData", readAccessData);
    const inventorySection = readAccessData.find(
      (item) => item.label === "Inventory"
    );
    if (inventorySection) {
      const inventoryChildren = inventorySection.children;

      const inventoryReadAcess = inventoryChildren.find(
        (child) => child.label === "Inventory"
      ).read_access;
      setInventoryReadValue(inventoryReadAcess);
    }
  }, [readAccessData]);
  useEffect(() => {
    console.log("loadState", metaData);
    setListData(metaData);
  }, []);
  useEffect(() => {
    console.log("loadState", metaData);
    setListData(metaData);
  }, []);

  useEffect(() => {
    if (listData.length > 0) {
      const invtUinitData = listData[0].InventoryUnit;
      const invSourceData = listData[0].InventorySource;
      const frameMaterialData = listData[0].FrameMaterial;
      const frameBrandData =listData[0].frameBrand;
      const returnableData = listData[0].InventoryReturn;
      setInvtUnitData(invtUinitData);
      setInvSourceData(invSourceData);
      setFrameMaterialData(frameMaterialData);
      setFrameBrandData(frameBrandData);
      setReturnableData(returnableData)
    }
  }, [listData]);

  useEffect(() => {
    getServiceMaster(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSTR&structure_id=0`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setStructureList(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
    getInvtService(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSRV`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setProdecureCodeData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
    getManufacturerDetail(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELMAN&manufacturer=0`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setManufacturerData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });

    getSupplierDetail(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSUP&supplier_id=0`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setSupplierData(response?.data);
      } else {
        toast.error(response);
        // setIsLoader(false);
      }
    });
  }, []);

  // In your useEffect to update selectedProcedureCodes from prodecureCodeData
  useEffect(() => {
    // Extract procedure_code values from prodecureCodeData
    const procedureCodes = prodecureCodeData.map((row) => row?.procedure_code);

    // Initialize selectedProcedureCodes with the Service Codes
    setSelectedProcedureCodes(procedureCodes);
  }, [prodecureCodeData]);

  useEffect(() => {
    console.log("selectedProcedureCodes", selectedProcedureCodes);
  }, [selectedProcedureCodes]);

  var classes = useStyles();

  const addProcedure = () => {
    // console.log("add client called");
    setOpenModel(true);
    setAnchorEl(null);
    setselectclient(null);
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpenModel(false);
  };
  const procedureIds = selectedProcedureCodes
    .map((procedure) => procedure?.service_id)
    .join(",");
  // Calculate the sum of retail_price values
  const retailPriceSum = selectedProcedureCodes.reduce((sum, procedure) => {
    // Parse retail_price as a float (assuming it's in string format)
    const retailPrice = parseFloat(procedure?.retail_price) || 0;

    // Add the retailPrice to the running sum
    return sum + retailPrice;
  }, 0);

  // Calculate the sum of item_cost values
  const itemCostSum = selectedProcedureCodes.reduce((sum, procedure) => {
    // Parse item_cost as a float (assuming it's in string format)
    const itemCost = parseFloat(procedure?.item_cost) || 0;

    // Add the itemCost to the running sum
    return sum + itemCost;
  }, 0);

  useEffect(() => {
    console.log("procedureIds", procedureIds);
    console.log("retailPriceSum", retailPriceSum);
    setSptLensDetail({
      ...sptLensDetail,
      retail_price: retailPriceSum,
      item_cost: itemCostSum,
      service_id: procedureIds,
    });
  }, [retailPriceSum, itemCostSum, procedureIds]);

  const [sptLensDetail, setSptLensDetail] = useState({
    spec_id: "0",
    location_id: userData?.location_id,
    spec_brand_id:'',
    spec_lens_name: "",
    spec_code:"",
    structure_id: "",
    material_id: "",
    vsp_code: "",
    spec_sku: "",
    unit_id: "",
    is_od: "",
    is_os: "",
    is_ou: "",
    inv_source:'',
    spec_tax1: "",
    spec_tax2: "",
    qty_purchased: "",
    qty_sold: "",
    qty_inventory: "",
    manufacturer_id: "",
    supplier_id:"",
    returnable_id:"",
    retail_price: "",
    item_cost: "",
    is_commission_prod: "",
    commission_amount: "0",
    notes: "",
    service_id:"",
    is_returnable: "",
    is_active:'Y',
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: actionType,
  });

  const [error, checkValidator, resetValidator, resetAllValidator, isError] =
  InventoryValidation({
    spec_brand_id: sptLensDetail?.spec_brand_id,
    spec_lens_name:sptLensDetail?.spec_lens_name,
    retail_price:sptLensDetail?.retail_price,
    item_cost:sptLensDetail?.item_cost,
  
  });

  useEffect(() => {
    if (selectedRow) {
      console.log("selectedRow",selectedRow);
      setSptLensDetail({
        spec_id: eventType === "Copy" ? "0" : selectedRow?.spec_id,
        location_id: userData?.location_id,
        spec_brand_id:selectedRow?.spec_brand_id,
        material_id: selectedRow?.material_id,
        material_name: selectedRow?.material_name,
        spec_lens_name: selectedRow?.spec_lens_name,
        material_brand: selectedRow?.material_brand,
        spec_code:selectedRow?.spec_code,
        vsp_code: selectedRow?.vsp_code,
        inv_source:selectedRow?.inv_source,
        supplier_id:selectedRow?.supplier_id,
        returnable_id:selectedRow?.returnable_id,
        is_od: selectedRow?.is_od,
        is_os: selectedRow?.is_os,
        is_ou: selectedRow?.is_ou,
        spec_sku: selectedRow?.spec_sku,
        unit_id: selectedRow?.unit_id,
      
        spec_color: selectedRow?.spec_color,
        is_returnable: selectedRow?.is_returnable,
        structure_id: selectedRow?.structure_id,
        structure_type: selectedRow?.structure_type,
        spiff: selectedRow?.spiff,
        notes: selectedRow?.notes,
        qty_purchased: selectedRow?.qty_purchased,
        qty_sold: selectedRow?.qty_sold,
        qty_inventory: selectedRow?.qty_inventory,
        retail_price: selectedRow?.retail_price,
        item_cost: selectedRow?.item_cost,
        is_commission_prod: selectedRow?.is_commission_prod,
        commission_amount: selectedRow?.commission_amount,
        manufacturer_id: selectedRow?.manufacturer_id,
        spec_tax1: selectedRow?.spec_tax1,
        spec_tax2: selectedRow?.spec_tax2,

        updated_by: currentUser(),

        access_location: "access_location",
        opt_type: "UPDSPC",
        opt_type: eventType === "Copy" ? "AADSPC" : "UPDSPC",
      });
      
      getSpectacleService(
        `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSRV&spec_id=${selectedRow?.spec_id}`
      ).then((response) => {
        console.log("response", response);
        if (response?.data) {
          setIsLoader(false);
          setSelectedProcedureCodes(response?.data);
        } else {
          setIsLoader(false);
        }
      });
    }
  }, [selectedRow]);
  useEffect(() => {
   
      const retailPrice = parseFloat(sptLensDetail.retail_price) || 0;
      const itemCost = parseFloat(sptLensDetail.item_cost) || 0;
      const commissionAmount = parseFloat(sptLensDetail.commission_amount) || 0;
  
      const calculatedProfit = retailPrice - (itemCost + commissionAmount);
      setProfitValue(calculatedProfit);
  
      //
      const calculatedGrossPercentage = (commissionAmount / retailPrice) * 100;
      setGrossPercentage(calculatedGrossPercentage);
    
   
  }, [
    sptLensDetail.retail_price,
    sptLensDetail.item_cost,
    sptLensDetail.commission_amount
  ]);
  const handleCommissionTypeChange = (e) => {
    console.log("Commission Type Change Triggered");
    const newComissionType = e.target.value;
    console.log("New Commission Type:", newComissionType);

    setSptLensDetail({
      ...sptLensDetail,
      is_commission_prod: newComissionType,
      commission_amount: newComissionType === "Y" ? "" : "",
    });
  };
  const clearForm = () => {
    // Define a function to clear the form fields
    setSptLensDetail({
      spec_id: "0",
      location_id: userData?.location_id,
      speclens_style: "",
      material_id: "",
      spec_brand_id:'',
      material_name: "",
      spec_lens_name: "",
      material_brand: "",
      product_name: "",
      style_name: "",
      // vsp_code: "",
      vsp_code_description: "",
      is_od: "",
      is_os: "",
      is_ou: "",
      spec_sku: "",
      unit_id: "",
      spec_color: "",
      is_returnable: "",
      structure_id: "",
      structure_type: "",
      spiff: "",
      notes: "",
      qty_purchased: "",
      qty_sold: "",
      qty_inventory: "",
      retail_price: "",
      item_cost: "",
      is_commission_prod: "Y",
     
      commission_amount: "0",
      manufacturer_id: "",
      spec_tax1: "",
      spec_tax2: "",

      updated_by: currentUser(),

      access_location: "",
      opt_type: actionType,
    });
    setSelectedProcedureCodes([]);
  };
  const goBack = () => {
    setTimeout(() => {
      history.push(`/app/inventory/${activeTabVal}`); 
    }, 3000); 
  };

  useEffect(()=>{
    if (!isError() && error !== null && isActionCalled) {
      postSpectacleLense(sptLensDetail).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Spectacle Lens added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          clearForm();
          if(isActionExit){
            goBack();
          }
          
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActionExit(false);
        }
      });
      setIsActionExit(false);
    }else{
      setIsActionCalled(false);
    }
    setIsActionCalled(false);
    setIsActionExit(false);
  },[isActionCalled])
  const handleSaveAndMore = () => {
    console.log("sptLensDetail", sptLensDetail);
    checkValidator(); // Check for validation errors
    setIsActionCalled(true);
    // clearForm(); // Clear form fields
  };

  const handleSaveAndExit = () => {
    console.log("sptLensDetail", sptLensDetail);
    checkValidator(); // Check for validation errors
    setIsActionExit(true);
    setIsActionCalled(true);
    
  };
  const activeTabVal = 1;
  // setIsActionExit(true);
  const handleCancel = () => {
    history.push(`/app/inventory/${activeTabVal}`)
  };
  useEffect(() => {
    // Your existing code here ...

    // Calculate the inventory value
    const purchased = parseFloat(sptLensDetail.qty_purchased) || 0;
    const sold = parseFloat(sptLensDetail.qty_sold) || 0;
    const inventoryValue = purchased - sold;
    setInventoryValue(inventoryValue);
  }, [
    sptLensDetail.retail_price,
    sptLensDetail.item_cost,
    sptLensDetail.commission_amount,
    sptLensDetail.qty_purchased, // Add qty_purchased here
    sptLensDetail.qty_sold, // Add qty_sold here
    
  ]);
  const tableHeadings = [
    {
      label: "Service",
    },
    {
      label: "Retails Price",
    },
    {
      label: "Item Cost",
    },
  ];
  return (
    <>
      <ToastContainer />
      <ManageLocalProcedure
        prodecureCodeData={prodecureCodeData}
        open={openModel}
        title={selectclient ? "Edit Procedure" : "New Procedure"}
        onHide={hideHandle}
        setModalShow={setModalShow}
      />
      <PageTitle
        title="Manage Spectacle Lens"
        subtitle="Inventory >"
        urlLink={`/app/inventory/${activeTabVal}`}
      />
      {/* <TableWidget noBodyPadding> */}
      <Grid
        container
        spacing={2}
        className={classes.formBox}
        style={{
          border: "1px solid #DED9D9",
          marginBottom: "16px",
          padding: "16px",
        }}
      >
        <Grid item md={9}>
          <Grid container spacing={1}>
            {spectacleFormElements.map((element, i) => {
              const isHidden =
                (element.key === "item_cost" && inventoryReadValue === "Y") ||
                (element.key === "profit" && inventoryReadValue === "Y") ||
                (element.key === "is_commission_prod" &&
                  inventoryReadValue === "Y") ||
                (element.key === "commission_amount" &&
                  inventoryReadValue === "Y") ||
                (element.key === "gross_percentage" &&
                  inventoryReadValue === "Y") ||
                (element.key === "spec_tax1" &&
                  inventoryReadValue === "Y") ||
                (element.key === "spec_tax2" &&
                  inventoryReadValue === "Y") ||
                (element.key === "grp_cost" && inventoryReadValue === "Y") ||
                (element.key === "spiff" && inventoryReadValue === "Y");
              return (
                <>
                  {!isHidden && (
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={`${classes.textGroup} ${
                        element.title ? classes.highlightedItem : ""
                      }`}
                      style={{ border: "1px solid #DED9D9" }}
                    >
                      <Typography
                        className={classes.formHeading}
                        style={{ fontWeight: "500", color: "#4E97FD" }}
                      >
                        {element.label}{" "}
                        {element.required === "true" ? (
                          <span className={classes.required}>*</span>
                        ) : null}
                      </Typography>
                      {element.type ? (
                        <>
                          {element.type === "select" &&
                          element.key !== "procedure_code" ? (
                            <FormControl
                              fullWidth
                              className={classes.dropdownFormControl}
                            >
                              <Select
                                disabled={inventoryReadValue === "Y"}
                                name={element.key}
                                className={classes.selectDropdown}
                                placeholder={element.placeHolder}
                                value={
                                  sptLensDetail && sptLensDetail[element.key]
                                    ? sptLensDetail &&
                                      sptLensDetail[element.key]
                                    : ""
                                }
                                onChange={(e) => {
                                  console.log("selected");
                                  setSptLensDetail({
                                    ...sptLensDetail,
                                    [element.key]: e.target.value,
                                  });
                                  resetValidator(e.target.name);
                                }}
                                isInvalid={error && error[element.key]}
                              >
                                <MenuItem disabled value="">
                                  Select
                                </MenuItem>
                                {element.key === "unit_id" && invtUinitData
                                  ? invtUinitData.map((item, index) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key === "manufacturer_id" &&
                                    manufacturerData
                                  ? manufacturerData.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.manufacturer_id}
                                      >
                                        {item.manufacturer_name}
                                      </MenuItem>
                                    )): element.key === "inv_source" &&
                                    invSourceData
                                  ? invSourceData.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                    : element.key === "material_id" &&
                                    frameMaterialData
                                  ? frameMaterialData.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    )) :element.key === "supplier_id" &&
                                    supplierData
                                  ? supplierData.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.supplier_id}
                                      >
                                        {item.supplier_name}
                                      </MenuItem>
                                    ))
                                  : element.key === "structure_id" &&
                                    structureList
                                  ? structureList.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.structure_id}
                                      >
                                        {item.structure_name}
                                      </MenuItem>
                                    )): element.key ==="spec_brand_id" && frameBrandData ? frameBrandData.map((item,index)=>(
                                      <MenuItem
                                      key={item.attribute_id}
                                      value={item.attribute_id}
                                    >
                                      {item.attribute_val}
                                    </MenuItem>
                                    )): element.key ==="returnable_id" && returnableData ? returnableData.map((item,index)=>(
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.options.map((item) => (
                                      <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                              </Select>
                              <Fade in={error}>
                            <Typography
                              color="secondary"
                              className={classes.errorMessage}
                            >
                              {error && error[element.key]}
                            </Typography>
                          </Fade>
                            </FormControl>
                          ) : element.type === "select" &&
                            element.key === "procedure_code" ? (
                            <>
                              <Autocomplete
                              size="small"
                              defaultValue={[]}
                                multiple
                                options={prodecureCodeData} // Replace with your list of options
                                getOptionLabel={(option) =>
                                  option?.service_code || ""
                                }
                                value={selectedProcedureCodes[0] === undefined ? [] : selectedProcedureCodes }
                                onChange={(event, newValue) => {
                                  setSelectedProcedureCodes(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    fullWidth 
                                  />
                                )}
                              />
                            </>
                          ) : element.type === "is_commission_prod" ? (
                            <FormControl
                              disabled={inventoryReadValue === "Y"}
                              fullWidth
                              className={classes.dropdownFormControl}
                            >
                              <Select
                                name="is_commission_prod"
                                className={classes.selectDropdown}
                                placeholder="Select"
                                value={sptLensDetail.is_commission_prod}
                                onChange={handleCommissionTypeChange} // Connect the function here
                              >
                                <MenuItem value="Y">Yes</MenuItem>
                                <MenuItem value="N">No</MenuItem>
                              </Select>
                            </FormControl>
                          ) : element.type === "text" ? (
                            <>
                            <TextField
                              required
                              name={element.key}
                              placeholder={element.placeHolder}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                                onKeyPress: element.onKeyPress,
                              }}
                              value={
                                element.key === "profit"
                                  ? profitValue.toFixed(2)
                                  : element.key === "gross_percentage"
                                  ? grossPercentage.toFixed(2)
                                  : element.key === "qty_inventory"
                                  ? inventoryValue.toFixed(3)
                                  : sptLensDetail && sptLensDetail[element.key] 
                              }
                              onChange={(e) => {
                                setSptLensDetail({
                                  ...sptLensDetail,
                                  [element.key]: e.target.value,
                                });
                                resetValidator(e.target.name);
                              }}
                              isInvalid={error && error[element.key]}
                              disabled={ element.key === "spec_sku" || 
                                element.key === "profit" ||
                                element.key === "gross_percentage" ||
                                element.key === "qty_inventory" ||
                                inventoryReadValue === "Y"
                              }
                            />
                            <Fade in={error}>
                            <Typography
                              color="secondary"
                              className={classes.errorMessage}
                            >
                              {error && error[element.key]}
                            </Typography>
                          </Fade>
                          </>
                          ) : element.type === "commission_amount" ? (
                            <TextField
                              type="text"
                              className={classes.txtForm}
                              disabled={
                                sptLensDetail.is_commission_prod !== "Y" ||
                                inventoryReadValue === "Y"
                              } // Enable only when is_commission_prod is "yes"
                              value={sptLensDetail.commission_amount}
                              fullWidth
                              variant="outlined"
                              size="small"
                              inputProps={{
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                                maxLength: element.maxLength,
                                onKeyPress: element.onKeyPress,
                              }}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                setSptLensDetail({
                                  ...sptLensDetail,
                                  commission_amount: isNaN(value) ? "" : value,
                                });
                              }}
                            />
                          ) : element.type === "date" ||
                            element.type === "datetime-local" ? (
                            <TextField
                              id={element.key}
                              name={element.key}
                              placeholder={element.placeHolder}
                              type={element.type}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                min: `${currentDateTime}`,
                                max: `${currentDate}`,
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          ) : element.type === "radio" ? (
                            <RadioGroup row defaultValue="">
                              {element.radioValue.map((item) => (
                                <FormControlLabel
                                  size="small"
                                  style={{ padding: "0px 6px" }}
                                  value={item}
                                  control={<Radio />}
                                  label={item}
                                  className={classes.smallRadioButton}
                                />
                              ))}
                            </RadioGroup>
                          ) : element.type === "checkbox" ? (
                            <>
                              <Checkbox
                                name={element.key}
                                checked={
                                  sptLensDetail[element.key] !== "N"
                                    ? sptLensDetail[element.key]
                                    : false
                                }
                                onChange={(e) => {
                                  const value = postFlagBooleanVariable(
                                    e.target.checked
                                  );
                                  setSptLensDetail({
                                    ...sptLensDetail,
                                    [element.key]: value,
                                  });
                                }}
                              />
                              {/* {element.checklbl} */}
                            </>
                          ) : (
                            <TextField
                              disabled={inventoryReadValue === "Y"}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              placeholder="Type here..."
                              variant="outlined"
                              multiline
                              rows={3}
                              inputProps={{
                                style: {
                                  fontSize: "12px",
                                },
                                maxLength: element.maxLength,
                              }}
                              value={
                                sptLensDetail && sptLensDetail[element.key]
                              }
                              onChange={(e) => {
                                setSptLensDetail({
                                  ...sptLensDetail,
                                  [element.key]: e.target.value,
                                });
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <Typography
                            className={classes.formHeading}
                            style={{
                              fontWeight: "600",

                              color: "#4E97FD",
                            }}
                          >
                            {element.title}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  )}
                </>
              );
            })}
          </Grid>
        </Grid>
        <Grid item md={3}>
          <TableDesign
            {...{
              title: "List of All Clients",
              tableHeadings,
            }}
          >
            {selectedProcedureCodes && selectedProcedureCodes.length ? (
              selectedProcedureCodes.map((row, i) => {
                if (row)
                  return (
                    <TableRow key={i} className={classes.tableBodyCell}>
                      <TableCell
                        align="left"
                        className={classes.tableBodyCell}
                        style={{ cursor: "pointer" }}
                      >
                        {row.service_name}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ cursor: "pointer" }}
                        className={classes.tableBodyCell}
                      >
                        {row.retail_price}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ cursor: "pointer" }}
                        className={classes.tableBodyCell}
                      >
                        {row.item_cost}
                      </TableCell>
                    </TableRow>
                  );
              })
            ) : (
              <TableRow>
                <TableCell colSpan="8">No Result Found</TableCell>
              </TableRow>
            )}
          </TableDesign>
        </Grid>
      </Grid>
      <div className={classes.footerBox}>
         {/* <Button
              variant="outlined"
              className={classes.leftButton}
              style={{ marginRight: "12px" }}
              onClick={clearForm}
            >
              clear
            </Button> */}
        <Button
          variant="outlined"
          className={classes.leftButton}
          style={{ marginRight: "12px" }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        {inventoryReadValue === "N" && (
          <>
            <Button
              variant="contained"
              color="secondary"
              className={classes.rightButton}
              onClick={handleSaveAndExit}
              style={{ marginRight: "12px" }}
            >
              save & Exit
            </Button>
            {!selectedRow && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.rightButton}
                onClick={handleSaveAndMore}
              >
                save & More
              </Button>
            )}
          </>
        )}
      </div>

      {selectedRow && (
        <>
          <Paper className={classes.iconsContainer}>
            <Tabs TabIndicatorProps={{
            style: { background: "#4E97FD",width:"150px"},
          }}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              value={activeTabId}
              onChange={(e, id) => setActiveTabId(id)}
              className={classes.iconsBar}
            >
              <Tab label="Locations" classes={{ root: classes.tab }} />
              <Tab label="Transactions" classes={{ root: classes.tab }} />
            </Tabs>
          </Paper>
          {activeTabId === 0 && <LocationList />}
          {activeTabId === 1 && <TransactionList />}
          {activeTabId === 2 && <MaterialsList />}
        </>
      )}
    </>
  );
}
