import { FullscreenExit } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  dashedBorder: {
    border: "1px dashed",
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  required:{
    color:'red'
  },
  errorMessage:{
    fontSize:'14px',
    color:'red',
    textAlign:'left'
  },
  eyeIcon: {
    position: 'relative',
    height: '35px',
    padding: '4px 10px',
    cursor: 'pointer',
    zIndex:'999',
    marginTop:'-40px',
    left:'90%'
  },
  text: {
    marginBottom: theme.spacing(-1),
    marginTop:theme.spacing(2),
  },
  btnGroup:{
    display:'flex',
    justifyContent:'flex-end',
    paddingTop:theme.spacing(4),
    paddingBottom:theme.spacing(2),
},
leftButton:{
  fontFamily:'Poppins',
  fontSize:'14px',
  fontWeight:400,
  textTransform:'none',
  height:'38px',
  borderRadius:'2px'
},
rightButton:{
  fontFamily:'Poppins',
  fontSize:'14px',
  fontWeight:500,
  marginLeft:theme.spacing(2),
  textTransform:'none',
  height:'38px',
  borderRadius:'2px'
},
}));
