import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { MoreVert as MoreIcon, Lens } from "@material-ui/icons";
import TableDesign from "../../components/common/table/TableDesign";
import TableWidget from "../../components/Widget/TableWidget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { getCustomData } from "../../services/ServiceLayer";
import { dateFormatConversion,loadState } from "../../hooks/helper";
import ManageCustomData from "./ManageCustomData";
import {
  TableRow,
  Grid,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import useStyles from "./styles";
import DeleteCustomPopup from "./DeleteCustomPopup";

const tableHeadings = [
  {
    label: "S. No.",
  },
  {
    label: "Data Name",
    align: "left !important",
  },
  {
    label: "Table Name",
    align: "left !important",
  },
  {
    label: "Associated Role",
    align: "left !important",
  },
  {
    label: "Data Type",
    align: "left !important",
  },
  {
    label: "Data Default Value",
    align: "left !important",
  },

  {
    label: "Status",
    align: "left !important",
  },
  {
    label: "Access Location",
    align: "left !important",
  },
  {
    label: "Action",
  },
];

export default function CustomData() {
  const userData = loadState().user;
  const userRole =loadState().user.user_role;
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectclient, setselectclient] = useState(null);
  const [customDataList, setCustomDataList] = useState([]);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [selectRow, setSelectRow] = useState(null);
  const [title, setTitle] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const history = useHistory();
  // const navigate = useNavigate();

  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const createUpdateClient = (client) => {
    console.log("add client called");
    setselectclient(client);
    // setModalShow(true);
    setTitle("Edit Custom Data");
    // setShowResult(true);
    setOpen(true);
    setAnchorEl(null);
  };

  const handleEdit = () => {
    history.push(`/app/client-edit`);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  var classes = useStyles();
  const addFile = () => {
    console.log("add client called");
    setselectclient(null);
    // setModalShow(true);
    setTitle("Add Client");
    // setShowResult(true);
    setOpen(true);
    setAnchorEl(null);
  };
  const addFileLink = () => {
    setOpen(true);
    setTitle("Edit Custom Data");
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpen(false);
    setDataUpdated(true);
  };
  useEffect(() => {
    getCustomData(`patient_id=0&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELREL`).then((response) => {
      console.log(response);
      setCustomDataList(response.data);
    });
  }, []);
  useEffect(() => {
    getCustomData(`patient_id=0&location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELREL`).then((response) => {
      console.log(response);
      setCustomDataList(response.data);
      setDataUpdated(false);
    });
  }, [dataUpdated]);

  const deleteEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);

    setselectclient(rec);
    setDeleteModalShow(true);
    setTitle("Delete Staff");
  };

  return (
    <div className="container">
      <DeleteCustomPopup
        onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        selectclient={selectclient}
        setDeleteModalShow={setDeleteModalShow}
      />

      <ManageCustomData
        open={open}
        onHide={hideHandle}
        title={"Add Custom Data"}
        selectclient={selectclient}
        setModalShow={setModalShow}
      />

      <PageTitle
        title="Custom Data"
        button3="Add Custom Data"
        onClick3={addFile}
      />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {customDataList && customDataList.length ? (
                customDataList.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                          style={{ cursor: "pointer" }}
                        >
                          {row.data_name}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.associated_tbl_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {row.associated_role}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.data_type}
                        </TableCell>
                       
                        <TableCell className={classes.tableBodyCell}>
                          {row.data_default_val}
                        </TableCell>
                        
                        <TableCell className={classes.tableBodyCell}>
                          {row.data_status}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.access_location}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>

                          <Menu
                            className={classes.menuBox}
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => createUpdateClient(selectRow)}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              Edit
                            </MenuItem>
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => deleteEventHandler(selectRow)}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </div>
  );
}
