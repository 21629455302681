import React, { useState, useEffect } from "react";
import useStyles from "../../styles";
import {
  Grid,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Select,
  FormControl,
  FormGroup,
  Checkbox,
  Button,
  Avatar,
  Tabs,
  Tab,
  Paper,
  InputBase,
  FormLabel,
} from "@material-ui/core";
import classNames from "classnames";
import { contactFormElements } from "../order_constant";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableForService from "./TableForService";
import ExsitingFrameOrder from "../../FrameOrd/ExsitingFrameOrder";
import ExsitingContactOrder from "../../ContactOrd/ExsitingContactOrder";
export default function ContactData() {
  var [isSearchOpen, setSearchOpen] = useState(true);
  var classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const selectFrameBySku = () => {
    // history.push(`/app/admin-details`);
    setOpen(true);
  };
  const selectPrevFrame = () => {
    setOpen1(true);
  };
  const hideHandle = () => {
    setOpen(false);
    setOpen1(false);
  };
  const duplicateValue = () => {
    // setSpectDataDetail({
    //   ...spectDataDetail,
    //   os_sphere: spectDataDetail.od_sphere,
    // });
  };
  return (
    <div style={{ display: "block" }}>
      <ExsitingContactOrder
        open={open1}
        onHide={hideHandle}
        title={"Select Exsiting Contact Order"}
      />
      <Grid
        container
        spacing={1}
        style={{
          marginBottom: "16px",
        }}
      >
        {contactFormElements.map((element, i) => {
          return (
            <>
            <Grid
              item
              xs={12}
              md={element.mdSize}
              className={`${classes.textGroup} ${
                element.title ? classes.highlightedItem : ""
              }`}
              style={{ border: "1px solid #DED9D9" }}
            >
              <Typography
                className={classes.formHeading}
                style={{
                  fontWeight: "500",
                  color: "#4E97FD",
                  position: "relative",
                }}
              >
                {element.label}{" "}
                {element.required === "true" ? (
                  <span className={classes.required}>*</span>
                ) : null}&nbsp;{element.copyBtn === "true" && (
                  <Button
                    style={{
                      float: "right",
                      position: "absolute",
                      top: "20px",
                    }}
                    classes={{ root: classes.button }}
                    variant="contained"
                    color="secondary"
                    onClick={duplicateValue}
                  >
                    =
                  </Button>
                )}
              </Typography>
              {element.type ? (
                <>
                  {element.type === "select" &&
                  element.key !== "procedure_code" ? (
                    <FormControl
                      fullWidth
                      className={classes.dropdownFormControl}
                    >
                      <Select
                        name={element.key}
                        className={classes.selectDropdown}
                        placeholder={element.placeHolder}
                      >
                        <MenuItem disabled value="">
                          Select
                        </MenuItem>
                        {element.options.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : element.type === "text" ? (
                    <div style={{ display: "flex" }}>
                      <TextField
                        required
                        name={element.key}
                        placeholder={element.placeHolder}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                          maxLength: element.maxLength,
                          onKeyPress: element.onKeyPress,
                        }}
                      />
                      {element.key === "sku" && (
                        <Button
                          // onClick={selectFrameBySku}
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                        >
                          Lens 
                        </Button>
                      )}
                    </div>
                  ) : element.type === "commission_amount" ? (
                    <TextField
                      type="text"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      size="small"
                      inputProps={{
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                        maxLength: element.maxLength,
                        onKeyPress: element.onKeyPress,
                      }}
                    />
                  ) : element.type === "date" ||
                    element.type === "datetime-local" ? (
                    <TextField
                      id={element.key}
                      name={element.key}
                      placeholder={element.placeHolder}
                      type={element.type}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ) : element.type === "radio" ? (
                    <RadioGroup row defaultValue="">
                      {element.radioValue.map((item) => (
                        <FormControlLabel
                          size="small"
                          style={{ padding: "0px 6px" }}
                          value={item}
                          control={<Radio />}
                          label={item}
                          className={classes.smallRadioButton}
                        />
                      ))}
                    </RadioGroup>
                  ) : element.type === "checkbox" ? (
                    <>
                      <Checkbox name={element.key} />
                    </>
                  ): element.type === "button" ? (
                    <>
                      <Button
                        classes={{ root: classes.button }}
                        variant="contained"
                        color="secondary"
                        onClick={selectPrevFrame}
                      >
                        Lens 
                      </Button>
                    </>
                  ) : (
                    <TextField
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      placeholder="Type here..."
                      variant="outlined"
                      multiline
                      rows={3}
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <Typography
                    className={classes.formHeading}
                    style={{
                      fontWeight: "600",

                      color: "#4E97FD",
                    }}
                  >
                    {element.title}
                  </Typography>
                </>
              )}
            </Grid>
            
            </>
          );
        })}
      </Grid>
      
    </div>
  );
}
