import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  title:{
    color:'#2A2338',
    fontFamily:'Poppins',
    fontWeight:500,
    fontSize:'16px',
    padding:"6px",
  },
  widgetWrapper: {
    // display: "flex",
    // minHeight: "100%",
    height:"auto",
    borderRadius: '7px'
    
  },
  widgetHeader: {
    // padding: theme.spacing(2),
    // paddingBottom: theme.spacing(1),
   
    
  },
  widgetRoot: {
    boxShadow: theme.customShadows.widget,
  },
  widgetBody: {
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  noPadding: {
    padding: 0,
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "hidden",
  },
  moreButton: {
    margin: -theme.spacing(1),
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.text.hint,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "rgba(255, 255, 255, 0.35)",
    },
  },
}));
