import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

import { Grid, Typography, InputBase } from "@material-ui/core";
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Button,
} from "@material-ui/core";

import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";
import classNames from "classnames";
import TableDesign from "../../common/table/TableDesign";
import TableWidget from "../../Widget/TableWidget";
import { dateFormatConversion } from "../../../hooks/helper";
// data
import mock from "../frame";
import useStyles from "../styles";
import ManageUploadCsv from "./ManageUploadCsv";
export default function FrameModify() {
  const history = useHistory();
  var classes = useStyles();
  var [isSearchOpen, setSearchOpen] = useState(true);
  const searchInputRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  //
  const [modalShow, setModalShow] = useState(false);
  const [title, setTitle] = useState(null);
  const [selectclient, setselectclient] = useState(null);

  const [toggleActivateModalShow, setToggleActivateModalShow] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const uploadCsvFile = () => {
    console.log("add client called");
    setselectclient(null);
    // setModalShow(true);
    setTitle("Add Client");
    // setShowResult(true);
    setOpen(true);
    setAnchorEl(null);
  };
  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpen(false);
    setToggleActivateModalShow(false);
  };
  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Name (SKU)",
      // align: "left !important",
    },
    {
      label: "Collection (Brand)",
    },
    {
      label: "Color (Color Code)",
    },
    {
      label: "Frame Type (Rim Type)",
    },
    {
      label: "Shape (Material)",
    },
    {
      label: "Frame Usage (Bridge)",
    },
    {
      label: "Gender (Source)",
    },
    {
      label: "Retail Price (Status)",
    },
    {
      label: "Availablity (Recall Date)",
    },
    {
      label: "Arrival Date (Best Seller)",
    },
    {
      label: "Warranty Till (Return Allowed)",
    },
    {
      label: "Discount (Discount Untill)",
    },
    {
      label: "Action",
    },
  ];
  return (
    <>
      <ManageUploadCsv
        open={open}
        onHide={hideHandle}
        title={"Upload CSV File"}
        selectclient={selectclient}
        setModalShow={setModalShow}
      />
      <div className={classes.pageTitleContainer}>
        <Typography className={classes.typo} weight="medium">
          Modify
        </Typography>
        <div className={classes.groupBtn}>
          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
            <div
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
              onClick={() => setSearchOpen(!isSearchOpen)}
            >
              {/* <SearchIcon classes={{ root: classes.headerIcon }} /> */}
            </div>
            <InputBase
              ref={searchInputRef}
              placeholder="Search by last name"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
          </div>
          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
            <div
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
            >
              {/* <SearchIcon classes={{ root: classes.headerIcon }} /> */}
            </div>
            <InputBase
              ref={searchInputRef}
              placeholder="Search by phone Number"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
          </div>
          <Button
            classes={{ root: classes.button }}
            variant="contained"
            color="secondary"
            style={{ marginRight: "4px" }}
          >
            Search
          </Button>
          <Button
            classes={{ root: classes.button }}
            variant="contained"
            color="secondary"
            onClick={uploadCsvFile}
          >
            {/* <img className={classes.editicons} src={editIcons} /> */}
            Upload CSV
          </Button>
        </div>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {mock && mock?.length ? (
                mock.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {i + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          onClick={() =>
                            history.push(
                              `/app/patient-details/${row.patient_id}`
                            )
                          }
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          {`${row.first_name}`}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {` ${row.last_name} `}
                        </TableCell>
                        <TableCell
                          className={classes.tableBodyCell}
                          style={{ textAlign: "left" }}
                        >
                          {`${row.email}`}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.contact_cell}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {dateFormatConversion(row.dateofbirth)}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.isActivated ? (
                            row.status === "ACTIVE" ? (
                              <span
                                style={{ color: "#10940D", fontWeight: 500 }}
                              >
                                Active
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>Inactive</span>
                            )
                          ) : (
                            <span style={{ color: "#F8A80C" }}>Pending</span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            id="basic-button"
                            aria-controls={openDown ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openDown ? "true" : undefined}
                          >
                            <MoreIcon />
                          </IconButton>
                          <Menu
                            className={classes.menuBox}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem>Edit</MenuItem>
                            <MenuItem>View details</MenuItem>
                            <MenuItem>Delete</MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
      <div className={classes.pageTitleContainer} style={{ marginTop: "16px" }}>
        <Typography className={classes.typo} weight="medium"></Typography>
        <div className={classes.groupBtn}>
          <Button
            classes={{ root: classes.button }}
            variant="contained"
            color="secondary"
            style={{ marginRight: "4px" }}
          >
            Update Recieving
          </Button>
          <Button
            classes={{ root: classes.button }}
            variant="contained"
            color="secondary"
          >
            {/* <img className={classes.editicons} src={editIcons} /> */}
            Sync Inventory
          </Button>
        </div>
      </div>
    </>
  );
}
