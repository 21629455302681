import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Fade,
  Button,
} from "@material-ui/core";

import moment from "moment";
import { formAdditionalElements } from "./Patient_Constant";

import NpiLookupSearch from "../../../../hooks/NpiLookUp";

import useStyles from "../styles";
import { Box } from "@mui/material";
export default function Additional({
  patientProfileData,
  setPatientProfileData,
  error,
  resetValidator,
  ethinicityValues,
  raceValues,
  applicableRoleValues,
  disabilityValues,
  veteranValues,
}) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [physicianData, setPhysicianData] = useState(null);

  const selectedPatient = (data) => {
    console.log("Selected Patinet Data " + data);
    setPhysicianData(data);
    setOpen(false);
    if (data) {
      setPatientProfileData({
        ...patientProfileData,
        primary_physician: data[0],
        physician_address: data[2],
        physician_city: data[3],
        physician_state: data[4],
        physician_zip: data[5],
      });
    }
  };

  const searchPhysician = () => {
    setOpen(true);
    setModalShow(true);
  };
  return (
    <>
      <NpiLookupSearch
        open={open}
        onHide={(e) => {
          selectedPatient(e);
        }}
        title={"Search physician Data"}
        setModalShow={setModalShow}
      />
      <Grid container spacing={2} className={classes.formBox}>
        {formAdditionalElements.map((element, i) => {
          return (
            <>
              <Grid
                item
                xs={12}
                md={2}
                lg={2}
                sm={12}
                className={classes.textGroup}
              >
                <Typography className={classes.formHeading}>
                  {element.label}{" "}
                  {element.required === "true" ? (
                    <span className={classes.required}>*</span>
                  ) : null}
                </Typography>

                {element.type === "select" ? (
                  <>
                    <FormControl
                      fullWidth
                      className={classes.dropdownFormControl}
                    >
                      <Select
                        name={element.key}
                        className={classes.selectDropdown}
                        placeholder={element.placeHolder}
                        value={
                          patientProfileData &&
                            patientProfileData[element.key] === null
                            ? ""
                            : patientProfileData &&
                            patientProfileData[element.key]
                        }
                        onChange={async (e) => {
                          setPatientProfileData({
                            ...patientProfileData,
                            [element.key]: e.target.value,
                          });
                          resetValidator(e.target.name);
                        }}
                        isInvalid={error && error[element.key]}
                      >
                        <MenuItem disabled value="">
                          Select
                        </MenuItem>
                        {element.key == "ethnicity" && ethinicityValues
                          ? ethinicityValues.map((item) => (
                            <MenuItem
                              key={item.attribute_id}
                              value={item.attribute_id}
                            >
                              {item.attribute_val}
                            </MenuItem>
                          ))
                          : element.key == "race" && raceValues
                            ? raceValues.map((item) => (
                              <MenuItem
                                key={item.attribute_id}
                                value={item.attribute_id}
                              >
                                {item.attribute_val}
                              </MenuItem>
                            ))
                            : element.key == "is_disability" && disabilityValues
                              ? disabilityValues.map((item) => (
                                <MenuItem
                                  key={item.attribute_id}
                                  value={item.attribute_id}
                                >
                                  {item.attribute_val}
                                </MenuItem>
                              ))
                              : element.key == "is_veteran_status" && veteranValues
                                ? veteranValues.map((item) => (
                                  <MenuItem
                                    key={item.attribute_id}
                                    value={item.attribute_id}
                                  >
                                    {item.attribute_val}
                                  </MenuItem>
                                ))
                                : element.key == "applicable_role_id" &&
                                  applicableRoleValues
                                  ? applicableRoleValues.map((item) => (
                                    <MenuItem
                                      key={item.attribute_id}
                                      value={item.attribute_id}
                                    >
                                      {item.attribute_val}
                                    </MenuItem>
                                  ))
                                  : element.key == "physician_state" && element.options
                                    ? element.options.map((item) => (
                                      <MenuItem key={item} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))
                                    : null}
                      </Select>
                    </FormControl>
                    <Fade in={error}>
                      <Typography
                        color="secondary"
                        className={classes.errorMessage}
                      >
                        {error && error[element.key]}
                      </Typography>
                    </Fade>
                  </>
                ) : element.type === "textarea" ? (
                  <>
                    <TextField
                      name={element.key}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      placeholder="Type here..."
                      variant="outlined"
                      multiline
                      rows={3}
                      inputProps={{
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      value={
                        patientProfileData &&
                          patientProfileData[element.key] === null
                          ? ""
                          : patientProfileData &&
                          patientProfileData[element.key]
                      }
                      onChange={async (e) => {
                        setPatientProfileData({
                          ...patientProfileData,
                          [element.key]: e.target.value,
                        });
                        resetValidator(e.target.name);
                      }}
                      isInvalid={error && error[element.key]}
                    />
                    <Fade in={error}>
                      <Typography
                        color="secondary"
                        className={classes.errorMessage}
                      >
                        {error && error[element.key]}
                      </Typography>
                    </Fade>
                  </>
                ) : element.type === "date" ||
                  element.type === "datetime-local" ? (
                  <>
                    <TextField
                      id={element.key}
                      name={element.key}
                      placeholder={element.placeHolder}
                      type={element.type}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      inputProps={{
                        min: `${currentDateTime}`,
                        max: `${currentDate}`,
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        patientProfileData &&
                          patientProfileData[element.key] === null
                          ? ""
                          : patientProfileData &&
                          patientProfileData[element.key]
                      }
                      onChange={async (e) => {
                        setPatientProfileData({
                          ...patientProfileData,
                          [element.key]: e.target.value,
                        });
                        resetValidator(e.target.name);
                      }}
                      onKeyDown={(e) => {
                        e.preventDefault(); // Prevent manual typing
                      }}
                      isInvalid={error && error[element.key]}
                    />
                    <Fade in={error}>
                      <Typography
                        color="secondary"
                        className={classes.errorMessage}
                      >
                        {error && error[element.key]}
                      </Typography>
                    </Fade>
                  </>
                ) : element.type === "button" ? (
                  <Button
                    classes={{ root: classes.button }}
                    variant="contained"
                    color="secondary"
                    onClick={searchPhysician}
                    style={{
                      width: "100%",
                      fontSize: "10px",
                      marginTop: "26px",
                    }}
                  >
                    {element.btnName}
                  </Button>
                ) : (
                  <>
                    <TextField
                      type={element.type}
                      name={element.key}
                      placeholder={element.placeHolder}
                      size="small"
                      className={classes.txtForm}
                      fullWidth
                      variant="outlined"
                      inputProps={{
                        style: {
                          height: "12px",
                          fontSize: "12px",
                        },
                        maxLength: element.maxLength,

                        onKeyPress: element.onKeyPress, // Add the event handler
                      }}
                      value={
                        patientProfileData &&
                          patientProfileData[element.key] === null
                          ? ""
                          : patientProfileData &&
                          patientProfileData[element.key]
                      }
                      onChange={async (e) => {
                        setPatientProfileData({
                          ...patientProfileData,
                          [element.key]: e.target.value,
                        });
                        resetValidator(e.target.name);
                      }}
                      isInvalid={error && error[element.key]}
                    />
                    <Fade in={error}>
                      <Typography
                        color="secondary"
                        className={classes.errorMessage}
                      >
                        {error && error[element.key]}
                      </Typography>
                    </Fade>
                  </>
                )}
              </Grid>
            </>
          );
        })}
      </Grid>
    </>
  );
}
