import {
  handleKeyPress,handleKeyPressPaste,
  handleAlphNumKeyPress,handleAlphNumKeyPressPaste,
  handleAlphNumberKeyPress,handleAlphNumberKeyPressPaste,
  handleAddressKeyPress,handleAddressKeyPressPaste
} from "../../../../hooks/helper";

export const formEmployeElements = [
  {
    key: "occupation",
    label: "Occuption",
    placeHolder: "Enter Occuption",
    type: "text",
    mdSize: "3",
    maxLength: "15",
    onKeyPress:handleAddressKeyPress,
    onPaste:handleAlphNumKeyPressPaste
  },
  {
    key: "employer",
    label: "Employer",
    placeHolder: "Enter Employer",
    type: "text",
    mdSize: "3",
    maxLength: "50",
    onKeyPress:handleAddressKeyPress,
    onPaste:handleAlphNumKeyPressPaste
  },
  {
    key: "employer_address_line1",
    label: "Address Line 1",
    placeHolder: "Type here",
    type: "text",
    mdSize: "3",
    maxLength: "50",
    onKeyPress:handleAddressKeyPress,
    onPaste:handleAlphNumKeyPressPaste
  },
  {
    key: "employer_address_line2",
    label: "Address Line 2",
    placeHolder: "Enter Address Line 2",
    type: "text",
    mdSize: "3",
    maxLength: "50",
    onKeyPress:handleAddressKeyPress,
    onPaste:handleAlphNumKeyPressPaste
  },
  {
    key: "employer_zip",
    label: "Zip",
    placeHolder: "Enter Zip",
    type: "text",
    mdSize: "3",
    onKeyPress: handleKeyPress,
    onPaste:handleKeyPressPaste,
    maxLength: "5",
  },
  {
    key: "employer_city",
    label: "City",
    placeHolder: "Enter City",
    type: "text",
    mdSize: "3",
    maxLength: "50",
    onKeyPress:handleAddressKeyPress,
    onPaste:handleAlphNumKeyPressPaste
  },

  {
    key: "employer_state",
    label: "State",
    placeHolder: "Select",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "3",
  },
 
  {
    key: "employer_con",
    label: "Employer Phone",
    placeHolder: "Enter Employer Phone",
    type: "text",
    mdSize: "3",
    onKeyPress: handleKeyPress,
    onPaste:handleKeyPressPaste,
    maxLength: "10",
  },
];

export const formGaurantorElements = [
  {
    key: "guarantor_type",
    label: "Type",
    placeHolder: "select",
    type: "select",
    options: ["Self", "Patient", "Family", "Other"],
    mdSize: "2",
  },
  {
    key: "guarantor_relationship",
    label: "Relationship",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "guarantor_first_name",
    label: "First Name",
    placeHolder: "Enter First Name",
    type: "text",
    mdSize: "2",
    maxLength: "15",
    onKeyPress:handleAddressKeyPress,
    onPaste:handleAlphNumKeyPressPaste
  },
  {
    key: "guarantor_middle_name",
    label: "Middle Name",
    placeHolder: "Enter Middle Name",
    type: "text",
    mdSize: "2",
    maxLength: "15",
    onKeyPress:handleAddressKeyPress,
    onPaste:handleAlphNumKeyPressPaste
  },
  {
    key: "guarantor_last_name",
    label: "Last Name",
    placeHolder: "Enter Last Name",
    type: "text",
    mdSize: "2",
    maxLength: "15",
    onKeyPress:handleAddressKeyPress,
    onPaste:handleAlphNumKeyPressPaste
  },
  {
    key: "guarantor_suffix",
    label: "Suffix",
    placeHolder: "Enter Suffix",
    type: "text",
    mdSize: "2",
    maxLength: "5",
    onKeyPress:handleAddressKeyPress,
    onPaste:handleAlphNumKeyPressPaste
  },
  {
    key: "guarantor_dob",
    label: "Date of Birth",
    placeHolder: "select",
    type: "date",
    mdSize: "2",
  },
  {
    key: "guarantor_gender",
    label: "Gender",
    placeHolder: "select",
    type: "select",
    options: ["Male", "Female", "Unknown"],
    mdSize: "2",
  },
  {
    key: "guarantor_ssn",
    label: "SSN",
    placeHolder: "Enter SSN",
    type: "text",
    mdSize: "2",
    maxLength: "11",
    onKeyPress: handleKeyPress,
    onPaste:handleKeyPressPaste,
  },
  {
    key: "guarantor_address_line1",
    label: "Address Line 1",
    placeHolder: "Type here..",
    type: "text",
    mdSize: "2",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste:handleAddressKeyPressPaste
  },
  {
    key: "guarantor_address_line2",
    label: "Address Line 2",
    placeHolder: "Type here..",
    type: "text",
    mdSize: "2",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste:handleAddressKeyPressPaste
  },
  {
    key: "guarantor_zip",
    label: "Zip",
    placeHolder: "Enter Zip",
    type: "text",
    mdSize: "2",
    onKeyPress: handleKeyPress,
    onPaste:handleKeyPressPaste,
    maxLength: "5",
  },
  {
    key: "guarantor_city",
    label: "City",
    placeHolder: "Enter City",
    type: "text",
    mdSize: "2",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
    onPaste:handleAddressKeyPressPaste
  },
  {
    key: "guarantor_state",
    label: "State",
    placeHolder: "select",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "2",
  },
  {
    key: "guarantor_con",
    label: "Mobile Phone",
    placeHolder: "Type here..",
    type: "text",
    mdSize: "2",
    onKeyPress: handleKeyPress,
    onPaste:handleKeyPressPaste,
    maxLength: "10",
  },
  {
    key: "guarantor_home_con",
    label: "Home Phone",
    placeHolder: "Type here..",
    type: "text",
    mdSize: "2",
    onKeyPress: handleKeyPress,
    onPaste:handleKeyPressPaste,
    maxLength: "10",
  },
  {
    key: "guarantor_office_con",
    label: "Office Phone",
    placeHolder: "Type here..",
    type: "text",
    mdSize: "2",
    onKeyPress: handleKeyPress,
    onPaste:handleKeyPressPaste,
    maxLength: "10",
  },
  // {
  //   key: "gaurantor_bill",
  //   label: "Bill Gaurantor",
  //   placeHolder: "Type here..",
  //   type: "text",
  //   mdSize: "2",
  //   maxLength: "30",
  // },
];

export const formReferralElements = [
  {
    key: "referral_source",
    label: "Source",
    placeHolder: "select",
    type: "select",
    options: [
      "Patient",
      "Doctor",
      "Social Media",
      "Flyer",
      "Coupon",
      "Mail",
      "Practice Neighbour",
      "Insurance Provider Network Search",
      "Other",
      "None",
    ],
    mdSize: "2",
  },
  {
    key: "referral_first_name",
    label: "First Name",
    placeHolder: "Enter First Name",
    type: "text",
    mdSize: "2",
    maxLength: "15",
    onKeyPress: handleAlphNumberKeyPress,
    onPaste:handleAlphNumberKeyPressPaste,
  },
  {
    key: "referral_middle_name",
    label: "Middle Name",
    placeHolder: "Enter Middle Name",
    type: "text",
    mdSize: "2",
    maxLength: "15",
    onKeyPress: handleAlphNumberKeyPress,
    onPaste:handleAlphNumberKeyPressPaste,
  },
  {
    key: "referral_last_name",
    label: "Last Name",
    placeHolder: "Enter Last Name",
    type: "text",
    mdSize: "2",
    maxLength: "15",
    onKeyPress: handleAlphNumberKeyPress,
    onPaste:handleAlphNumberKeyPressPaste,
  },
  {
    key: "referral_suffix",
    label: "Suffix",
    placeHolder: "Enter Suffix",
    type: "text",
    mdSize: "2",
    maxLength: "5",
    onKeyPress: handleAlphNumberKeyPress,
    onPaste:handleAlphNumberKeyPressPaste,
  },
  {
    key: "referral_dob",
    label: "Date of Birth",
    placeHolder: "select",
    type: "date",
    mdSize: "2",
  },
  {
    key: "referral_gender",
    label: "Gender",
    placeHolder: "select",
    type: "select",
    options: ["Male", "Female", "Unknown"],
    mdSize: "2",
  },
  {
    key: "referral_con",
    label: "Phone No",
    placeHolder: "Enter No",
    type: "text",
    mdSize: "2",
    maxLength: "10",
    onKeyPress: handleKeyPress,
  },
  {
    key: "referral_con_type",
    label: "Phone Type",
    placeHolder: "select",
    type: "select",
    options: ["Mobile","Home Landline","Office Landline"],
    mdSize: "2",
  },
  {
    key: "referral_email",
    label: "Email",
    placeHolder: "Enter Email",
    type: "text",
    mdSize: "2",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
  
  },
  {
    key: "referral_thanks_note",
    label: "Thanks Send",
    placeHolder: "select",
    type: "select",
    options: ["Pending","Sent Via Email","Sent Via Post","Sent Via SMS"],
    mdSize: "2",
  },
  {
    key: "referral_address_line1",
    label: "Address Line 1",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    maxLength: "50",
    onKeyPress:handleAddressKeyPress
  },
  {
    key: "referral_address_line2",
    label: "Address Line 2",
    placeHolder: "type here",
    type: "text",
    mdSize: "2",
    maxLength: "50",
    onKeyPress:handleAddressKeyPress
  },
  {
    key: "referral_zip",
    label: "Zip",
    placeHolder: "Enter Zip",
    type: "text",
    mdSize: "2",
    maxLength: "5",
    onKeyPress: handleKeyPress,
  },
  {
    key: "referral_city",
    label: "City",
    placeHolder: "Enter City",
    type: "text",
    mdSize: "2",
    maxLength: "50",
    onKeyPress:handleAlphNumberKeyPress
  },
  {
    key: "referral_state",
    label: "State",
    placeHolder: "select",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "2",
  },
 
];

export const formGuardianElements = [
  {
    value:'btn1',
    btnName:'Search with existing patient',
    type:'button',
    mdSize:'3',
    btnClick:'searchguardian'
  },
  {
    key: "guardian_relationship",
    label: "Relationship",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "guardian_first_name",
    label: "First Name",
    placeHolder: "Enter First Name",
    type: "text",
    mdSize: "2",
    maxLength: "15",
    onKeyPress:handleAlphNumKeyPress,
  },
  {
    key: "guardian_middle_name",
    label: "Middle Name",
    placeHolder: "Enter Middle Name",
    type: "text",
    mdSize: "2",
    maxLength: "15",
    onKeyPress:handleAlphNumKeyPress,
  },
  {
    key: "guardian_last_name",
    label: "Last Name",
    placeHolder: "Enter Last Name",
    type: "text",
    mdSize: "2",
    maxLength: "15",
    onKeyPress:handleAlphNumKeyPress,
  },
  {
    key: "guardian_suffix",
    label: "Suffix",
    placeHolder: "Enter Suffix",
    type: "text",
    mdSize: "1",
    maxLength: "5",
    onKeyPress: handleAlphNumberKeyPress,
  },

  {
    key: "guardian_dob",
    label: "Date of Birth",
    placeHolder: "select",
    type: "date",
    mdSize: "2",
  },
  {
    key: "guardian_gender",
    label: "Gender",
    placeHolder: "select",
    type: "select",
    options: ["Male", "Female", "Unknown"],
    mdSize: "1",
  },
  {
    key: "guardian_ssn",
    label: "SSN",
    placeHolder: "Enter SSN",
    type: "text",
    mdSize: "2",
    maxLength: "9",
    onKeyPress: handleKeyPress,
  },
  {
    key: "guardian_email",
    label: "Email",
    placeHolder: "Enter Email",
    type: "text",
    mdSize: "2",
    maxLength: "50",
    onKeyPress: handleAddressKeyPress,
  },
  {
    key: "guardian_address_line1",
    label: "Address Line 1",
    placeHolder: "Type here",
    type: "text",
    mdSize: "3",
    maxLength: "50",
    onKeyPress:handleAddressKeyPress
  },
  {
    key: "guardian_address_line2",
    label: "Address Line 2",
    placeHolder: "Type here",
    type: "guardian_text",
    mdSize: "3",
    maxLength: "50",
    onKeyPress:handleAddressKeyPress
  },
  {
    key: "guardian_city",
    label: "City",
    placeHolder: "Enter City",
    type: "text",
    mdSize: "1",
    maxLength: "30",
    onKeyPress: handleAlphNumberKeyPress,
  },
  {
    key: "guardian_state",
    label: "State",
    placeHolder: "select",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "1",
  },
  {
    key: "guardian_zip",
    label: "Zip",
    placeHolder: "Enter Zip",
    type: "text",
    mdSize: "1",
    maxLength: "5",
    onKeyPress: handleKeyPress,
  },
  {
    key: "guardian_con",
    label: "Mobile Phone",
    placeHolder: "Enter Phone",
    type: "text",
    mdSize: "2",
    maxLength: "10",
    onKeyPress: handleKeyPress,
  },
  {
    key: "guardian_home_con",
    label: "Home Phone",
    placeHolder: "Enter Phone",
    type: "text",
    mdSize: "2",
    maxLength: "10",
    onKeyPress: handleKeyPress,
  },
  {
    key: "guardian_office_con",
    label: "Office Phone",
    placeHolder: "Enter Phone",
    type: "text",
    mdSize: "2",
    maxLength: "10",
    onKeyPress: handleKeyPress,
  },
  {
    value:'btn2',
    btnName:'Search with existing patient',
    type:'button',
    mdSize:'3'
  },
  {
    key: "guardian1_relationship",
    label: "Relationship",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "guardian1_first_name",
    label: "First Name",
    placeHolder: "Enter First Name",
    type: "text",
    mdSize: "2",
    maxLength: "15",
    onKeyPress:handleAlphNumKeyPress,
  },
  {
    key: "guardian1_middle_name",
    label: "Middle Name",
    placeHolder: "Enter Middle Name",
    type: "text",
    mdSize: "2",
    maxLength: "15",
    onKeyPress:handleAlphNumKeyPress,
  },
  {
    key: "guardian1_last_name",
    label: "Last Name",
    placeHolder: "Enter Last Name",
    type: "text",
    mdSize: "2",
    maxLength: "15",
    onKeyPress:handleAlphNumKeyPress,
  },
  {
    key: "guardian1_suffix",
    label: "Suffix",
    placeHolder: "Enter Suffix",
    type: "text",
    mdSize: "1",
    maxLength: "5",
    onKeyPress: handleAlphNumberKeyPress,
  },

  {
    key: "guardian1_dob",
    label: "Date of Birth",
    placeHolder: "select",
    type: "date",
    mdSize: "2",
  },
  {
    key: "guardian1_gender",
    label: "Gender",
    placeHolder: "select",
    type: "select",
    options: ["Male", "Female", "Unknown"],
    mdSize: "1",
  },
  {
    key: "guardian1_ssn",
    label: "SSN",
    placeHolder: "Enter SSN",
    type: "text",
    mdSize: "2",
    maxLength: "9",
    onKeyPress: handleKeyPress,
  },
  {
    key: "guardian1_email",
    label: "Email",
    placeHolder: "Enter Email",
    type: "text",
    mdSize: "2",
    maxLength: "50",
    onKeyPress: handleAlphNumberKeyPress,

  },
  {
    key: "guardian1_address_line1",
    label: "Address Line 1",
    placeHolder: "Type here",
    type: "text",
    mdSize: "3",
    maxLength: "50",
    onKeyPress:handleAddressKeyPress
  },
  {
    key: "guardian1_address_line2",
    label: "Address Line 2",
    placeHolder: "Type here",
    type: "guardian_text",
    mdSize: "3",
    maxLength: "50",
    onKeyPress:handleAddressKeyPress
  },
  {
    key: "guardian1_city",
    label: "City",
    placeHolder: "Enter City",
    type: "text",
    mdSize: "1",
    maxLength: "30",
    onKeyPress: handleAlphNumberKeyPress,

  },
  {
    key: "guardian1_state",
    label: "State",
    placeHolder: "select",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "1",
  },
  {
    key: "guardian1_zip",
    label: "Zip",
    placeHolder: "Enter Zip",
    type: "text",
    mdSize: "1",
    maxLength: "5",
    onKeyPress: handleKeyPress,
  },
  {
    key: "guardian1_con",
    label: "Mobile Phone",
    placeHolder: "Enter Phone",
    type: "text",
    mdSize: "2",
    maxLength: "10",
    onKeyPress: handleKeyPress,
    onPaste:handleKeyPressPaste,

  },
  {
    key: "guardian1_home_con",
    label: "Home Phone",
    placeHolder: "Enter Phone",
    type: "text",
    mdSize: "2",
    maxLength: "10",
    onKeyPress: handleKeyPress,
  },
  {
    key: "guardian1_office_con",
    label: "Office Phone",
    placeHolder: "Enter Phone",
    type: "text",
    mdSize: "2",
    maxLength: "10",
    onKeyPress: handleKeyPress,
  },
];
