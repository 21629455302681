import React, { useState, useEffect } from "react";
import { Button, Grid, Menu, MenuItem, Typography } from "@material-ui/core";

import { dateFormatConversion, loadState } from "../../../hooks/helper";

import TableWidget from "../../Widget/TableWidget";

import TableDesign from "../../common/table/TableDesign";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
// styles
import useStyles from "../styles";
import MedicationChart from './MedicationChart'

import { getAllergyHistoryData, getPatientExternalDil } from "../../../services/ServiceLayer";

import moment from "moment";

export default function Dialation(props) {
  const tableHeadings = [
   
    {
      label: "Eye",
    },
    {
      label: "Medication",
    },
    {
      label: "Counsel",
    },
    {
      label: "Administered By",
    },
    {
      label: "Comment",
    },
    {
      label: "Date & Time",
    },
  ];
  const { patientId} = props;
  

  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [dilData, setDilData] = useState([]);
  useEffect(() => {
    getPatientExternalDil(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELDIL`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setDilData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);
  return (
    <>
      <Grid container spacing={4} style={{ width: "100%" }}>
        <Grid item xs={12}>
        <div style={{background:'#5DB3D9',padding:'10px',color:'#fff',textAlign:'center',borderRadius:'5px',marginBottom:'2px'}}>Dialation</div>
          {/* <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {dilData && dilData?.length ? (
                dilData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        
                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                          OD <br/>
                          <span style={{ color: "#0586FB" }}>OS</span>
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.dialation_med_od} <br/>
                          <span style={{ color: "#0586FB" }}>{row?.dialation_med_os}</span>
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.dialation_counsel_od} <br/>
                          <span style={{ color: "#0586FB" }}>{row?.dialation_counsel_od}</span>
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.dialation_staff_od} <br/>
                          <span style={{ color: "#0586FB" }}> {row?.dialation_staff_os}</span>
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {row?.dialation_notes_od} <br/>
                          <span style={{ color: "#0586FB" }}>{row?.dialation_notes_os}</span>
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                        {moment(row?.dialation_dt_od).utc().format('DD-MM-YYYY')} <br/>
                          <span style={{ color: "#0586FB" }}>{moment(row?.dialation_dt_os).utc().format('DD-MM-YYYY')}</span>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget> */}
          <MedicationChart />
        </Grid>
      </Grid>
    </>
  );
}
