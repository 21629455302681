import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import {
  TableRow,
  Grid,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";

import { MoreVert as MoreIcon, Lens } from "@material-ui/icons";
// components
import PageTitle from "../../PageTitle/PageTitle";
import TableWidget from "../../Widget/TableWidget";
import { getCompanyList } from "../../../services/ServiceLayer";
import { dateFormatConversion,loadState } from "../../../hooks/helper";
import useStyles from "./styles";
import TableDesign from "../../common/table/TableDesign";
import ManageCompany from "./ManageCompany";
import ManageCompanyLocation from "./ManageCompanyLocation";
import DeletePopup from "./DeletePopup";
export default function CompanyList() {

  const userData = loadState().user;
  const userRole =loadState().user.user_role;
  const history = useHistory();
  // const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectRow, setSelectRow] = useState(null);
  const [dataUpdated, setDataUpdated] = useState(false);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCompanyDetails = (row) => {
    const companyId = row.comp_id;
    console.log("row", row);
    console.log("companyId", companyId);
    history.push(`/app/company-details/${companyId}`);
  };
  // table Header
  const tableHeadings = [
    {
      label: "Company ID",
    },
    {
      label: "Name",
      align: "left !important",
    },
    {
      label: "Email",
      align: "left !important",
    },
    {
      label: "Contact No.",
    },
    {
      label: "Created on",
    },

    {
      label: "Status",
    },
    {
      label: "Action",
    },
  ];

  var classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  //
  const [modalShow, setModalShow] = useState(false);
  const [selectclient, setselectclient] = useState(null);
  const [title, setTitle] = useState(null);

  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const [companyList, setCompanyList] = useState([]);
  //searching
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const filteredCompanyList = companyList.filter((company) =>
    company.comp_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const addClient = () => {
    console.log("add client called");
    setselectclient(null);
    setTitle("Add Client");
    setOpen(true);
    setAnchorEl(null);
  };

  const editClient = (selectedCmp) => {
    console.log("add client called");
    setselectclient(selectedCmp);
    setTitle("Modified Company");
    setOpen(true);
    setAnchorEl(null);
  };
  const addLocationEventHandler = (rec) => {
    console.log("add client called");
    setOpen1(true);
    setAnchorEl(null);
    setTitle("Add Location");
    setselectclient(rec);
  };
  const deleteEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);

    setselectclient(rec);
    setDeleteModalShow(true);
    setTitle("Delete Company");
  };
  const hideHandle = () => {
    console.log("hidehandle called");

    setOpen(false);
    setOpen1(false);

    setDataUpdated(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    getCompanyList(`location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELCMP`).then((response) => {
      const filteredCompanyList = response.data.filter(
        (company) => company.is_company === "Y"
      );
      setCompanyList(filteredCompanyList);
    });
  }, []);
  useEffect(() => {
    getCompanyList(`location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELCMP`).then((response) => {
      const filteredCompanyList = response.data.filter(
        (company) => company.is_company === "Y"
      );
      setCompanyList(filteredCompanyList);
      setDataUpdated(false);
    });
  }, [dataUpdated]);

  return (
    <>
      <ManageCompany
        open={open}
        onHide={hideHandle}
        title={selectclient === null ? "Add Company" : "Update Company"}
        selectclient={selectclient}
        setModalShow={setModalShow}
      />
      <DeletePopup
        onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        selectclient={selectclient}
        setDeleteModalShow={setDeleteModalShow}
      />
      <ManageCompanyLocation
        open={open1}
        onHide={hideHandle}
        title={"Add Location"}
        selectclient={selectclient}
        setModalShow={setModalShow}
      />
      <PageTitle
        title="Company Registration"
        button={companyList.length === 0 ? "New Company Registration" : undefined}
        
        search="true"
        onClick={addClient}
        onChange={handleChange}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {filteredCompanyList && filteredCompanyList.length ? (
                filteredCompanyList.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell className={classes.tableBodyCell}>
                          {row.comp_id}
                        </TableCell>

                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          {row.comp_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.email_id}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {row.phone}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          {dateFormatConversion(row.created_dt)}
                        </TableCell>

                        <TableCell className={classes.tableBodyCell}>
                          {row.is_active ? (
                            row.is_active === "Y" ? (
                              <span
                                style={{ color: "#10940D", fontWeight: 500 }}
                              >
                                Active
                              </span>
                            ) : (
                              <span style={{ color: "#F8A80C" }}>Pending</span>
                            )
                          ) : (
                            <span style={{ color: "red" }}>Inactive</span>
                          )}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          <IconButton
                            onClick={(e) => {
                              handleClick(e);
                              setSelectRow(row);
                            }}
                          >
                            <MoreIcon />
                          </IconButton>

                          <Menu
                            className={classes.menuBox}
                            anchorEl={anchorEl}
                            open={openDown}
                            onClose={handleClose}
                          >
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => handleCompanyDetails(selectRow)}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              View Details
                            </MenuItem>
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => addLocationEventHandler(selectRow)}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              Add Location
                            </MenuItem>
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => editClient(selectRow)}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              Update
                            </MenuItem>
                            <MenuItem
                              className={classes.menuItem}
                              onClick={() => deleteEventHandler(selectRow)}
                            >
                              <Lens
                                style={{
                                  height: "4px",
                                  width: "4px",
                                  marginRight: "4px",
                                }}
                              />{" "}
                              Delete
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
