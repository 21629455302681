import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  
  Switch,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../common/modal/Popup";
// styles
import useStyles from "../styles";

import moment from "moment";
import {
  currentUser,

  loadState,
} from "../../../hooks/helper";
import {
  postPatientAssessmentDiagnosis,

} from "../../../services/ServiceLayer";
import { toast } from "react-toastify";
import useListData from "../../common/allMetaData/useListData";

export default function ManageDiagnosis(props) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  //
  const formElements = [
    {
      key: "icd_id",
      label: "ICD Code",
      placeHolder: "text",
      type: "select",
      options:[],
      mdSize: "3",
    },
    {
      key: "eye_id",
      label: "Eyes",
      placeHolder: "select",
      type: "select",
      options: [],
      mdSize: "3",
    },
    {
      key: "diag_status",
      label: "Status",
      placeHolder: "select",
      type: "select",
      options: [],
      mdSize: "3",
    },

    {
      key: "start_dt",
      label: "Start Date",
      type: "date",
      mdSize: "3",
    },
    {
      key: "end_dt",
      label: "End Date",
      type: "date",
      mdSize: "3",
    },
    {
      key: "diag_notes",
      label: "Notes",
      placeHolder: "text",
      type: "text",

      mdSize: "9",
    },
  ];
  console.log("props-24", props);
  const { selectclient, patientId,problemList, setIsUpdated } = props;
  const userData = loadState().user;
  
  const [actionType, setActionType] = useState("ADDASM");
  console.log("selectclient", selectclient);

  var classes = useStyles();
  const listData = useListData();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const { examEyeType,examAssessmentFollowUpStatus } = listData;
  const [isActionCalled, setIsActionCalled] = useState(false);
  

  const [diagnosisDetails, setDiagnosisDetails] = useState({
    pat_exdig_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    icd_id: "",
    eye_id: "",
    diag_status: "",
    start_dt: currentDate,
    end_dt: currentDate,
    diag_notes: "",
    updated_by: currentUser(),
    access_location: "",
    opt_type: actionType,
  });
  const clearForm = () => {
    setDiagnosisDetails({
      pat_exdig_id: 0,
      patient_id: patientId,
      location_id: userData?.location_id,
      icd_id: "",
      eye_id: "",
      diag_status: "",
      start_dt: currentDate,
      end_dt: currentDate,
      diag_notes: "",
      updated_by: currentUser(),
      access_location: "",
      opt_type: actionType,
    });
  };
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setDiagnosisDetails({
        pat_exdig_id: 0,
        patient_id: patientId,
        location_id: userData?.location_id,
        icd_id: "",
        eye_id: "",
        diag_status: "",
        start_dt: currentDate,
        end_dt: currentDate,
        diag_notes: "",
        updated_by: currentUser(),
        access_location: "",
        opt_type: actionType,
      });
    } else {
      setDiagnosisDetails({
        pat_exdig_id: selectclient?.pat_exdig_id ,
        patient_id: patientId,
        location_id: userData?.location_id,
        icd_id: selectclient?.problem_id
          ? selectclient?.problem_id
          : selectclient?.icd_id,
        eye_id: selectclient?.eye_id,
        diag_status: selectclient?.diag_status,
        start_dt: moment(selectclient?.start_dt).format("YYYY-MM-DD"),
        end_dt: moment(selectclient?.end_dt).format("YYYY-MM-DD"),
        diag_notes: selectclient?.diag_notes,
        updated_by: currentUser(),
        access_location: selectclient?.access_location ? selectclient?.access_location :'',
        opt_type: "UPDASM",
      });
    }
  }, [selectclient]);
  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
    
  };
  useEffect(() => {
    // if (!isError() && error !== null && isActionCalled) {
    if (isActionCalled) {
      postPatientAssessmentDiagnosis(diagnosisDetails).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Patient diagnosis added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          clearForm();
          props.onHide();
          setIsActionCalled(false);
          setIsUpdated(true);
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    // } else {
    //   setIsActionCalled(false);
    // }

    setIsActionCalled(false);
    
  }, [isActionCalled]);

  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
  };

  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleSubmit}
            >
              Save
              {/* {selectclient ? "Update" : "Add"} */}
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={2}
              className={classes.formBox}
              // style={{ marginBottom: "4px" }}
            >
              {formElements.map((element, i) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={classes.textGroup}
                    >
                      {element.label && (
                        <Typography
                          style={{ fontSize: "13px" }}
                          className={classes.formHeading}
                        >
                          {element.label}{" "}
                          {element.required === "true" ? (
                            <span className={classes.required}>*</span>
                          ) : null}
                        </Typography>
                      )}
                      {element.type ? (
                        <>
                          {element.type === "select" ? (
                            <FormControl
                              fullWidth
                              className={classes.dropdownFormControl}
                            >
                              <Select
                                name={element.key}
                                className={classes.selectDropdown}
                                placeholder={element.placeHolder}
                                value={
                                  diagnosisDetails &&
                                  diagnosisDetails[element.key]
                                    ? diagnosisDetails &&
                                      diagnosisDetails[element.key]
                                    : ""
                                }
                                onChange={(e) => {
                                  console.log("selected", e.target.value);

                                  setDiagnosisDetails({
                                    ...diagnosisDetails,
                                    [element.key]: e.target.value,
                                  });

                                  // resetValidator(e.target.name);
                                }}
                              >
                                <MenuItem disabled value="">
                                  Select
                                </MenuItem>
                                {element.key==='icd_id' && problemList 
                                ?problemList.map((item)=>(
                                  <MenuItem
                                        key={item.problem_id}
                                        value={item?.problem_id}
                                      >
                                        {item.icd_10}
                                      </MenuItem>
                                )):element.key === "eye_id" && examEyeType
                                  ? examEyeType.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    )):element.key === "diag_status" && examAssessmentFollowUpStatus
                                    ? examAssessmentFollowUpStatus.map((item) => (
                                        <MenuItem
                                          key={item.attribute_id}
                                          value={item.attribute_id}
                                        >
                                          {item.attribute_val}
                                        </MenuItem>
                                      ))
                                  : element.options.map((item) => (
                                      <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                              </Select>
                            </FormControl>
                          ) : element.type === "text" ? (
                            <TextField
                              required
                              name={element.key}
                              placeholder={element.placeHolder}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                                maxLength: element.maxLength,
                                onKeyPress: element.onKeyPress,
                                onPaste: element.onpaste,
                              }}
                              value={
                                diagnosisDetails &&
                                diagnosisDetails[element.key]
                                  ? diagnosisDetails &&
                                    diagnosisDetails[element.key]
                                  : ""
                              }
                              onChange={(e) => {
                                console.log("selected");
                                setDiagnosisDetails({
                                  ...diagnosisDetails,
                                  [element.key]: e.target.value,
                                });
                                // resetValidator(e.target.name);
                              }}
                            />
                          ) : element.type === "date" ||
                            element.type === "datetime-local" ? (
                            <TextField
                              id={element.key}
                              name={element.key}
                              placeholder={element.placeHolder}
                              type={element.type}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                min: `${currentDateTime}`,
                                max: `${currentDate}`,
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              onKeyDown={(e) => {
                                e.preventDefault(); // Prevent manual typing
                              }}
                              value={
                                diagnosisDetails &&
                                diagnosisDetails[element.key]
                                  ? diagnosisDetails &&
                                    diagnosisDetails[element.key]
                                  : ""
                              }
                              onChange={(e) => {
                                console.log("selected");
                                setDiagnosisDetails({
                                  ...diagnosisDetails,
                                  [element.key]: e.target.value,
                                });
                                // resetValidator(e.target.name);
                              }}
                            />
                          ) : element.type === "textarea" ? (
                            <TextField
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              placeholder="Type here..."
                              variant="outlined"
                              multiline
                              rows={3}
                              inputProps={{
                                style: {
                                  fontSize: "12px",
                                },
                                maxLength: element.maxLength,
                                onKeyPress: element.onKeyPress,
                                onPaste: element.onpaste,
                              }}
                              value={
                                diagnosisDetails &&
                                diagnosisDetails[element.key]
                                  ? diagnosisDetails &&
                                    diagnosisDetails[element.key]
                                  : ""
                              }
                              onChange={(e) => {
                                console.log("selected");
                                setDiagnosisDetails({
                                  ...diagnosisDetails,
                                  [element.key]: e.target.value,
                                });
                                // resetValidator(e.target.name);
                              }}
                            />
                          ) : element.type === "button" ? (
                            <Button
                              classes={{ root: classes.button }}
                              variant="contained"
                              color="secondary"
                              style={{
                                width: "100%",
                                fontSize: "10px",
                                marginTop: "26px",
                              }}
                            >
                              {element.btnName}
                            </Button>
                          ) : (
                            <Switch />
                          )}
                        </>
                      ) : (
                        <>
                          <Typography
                            className={classes.formHeading}
                            style={{
                              fontWeight: "600",

                              color: "#4E97FD",
                            }}
                          >
                            {element.title}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
