export const inventorySchema = {
  service_name: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter service name",
    },
  },
  brand_id: {
    type: "string",
    required: true,
    properties: {
      empty: "Please select brand",
    },
  },
  frame_model_name: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter frame model",
    },
  },
  frame_name: {
    type: "string",
    required: true,
    properties: {
      empty: "Please enter frame name",
    },
  },
  frame_type_id:{
    type:'string',
    required: true,
    properties: {
      empty: "Please select frame type",
    },
  },
  retail_price:{
    type:"string",
    required: true,
    properties: {
      empty: "Please enter retails price",
      positive: "Retails price must be at least 1",
      number: "Please enter number only",
    },
  },
  item_cost:{
    type:"string",
    required: true,
    properties: {
      empty: "Please enter item cost",
      positive: "Cost must be at least 1",
      number: "Please enter number only",
    },
  },
  spec_brand_id:{
    type:"string",
    required: true,
    properties: {
      empty: "Please enter brand name",
    },
  },
  spec_lens_name:{
    type:"string",
    required: true,
    properties: {
      empty: "Please enter lens name",
    },
  },
  lens_name:{
    type:"string",
    required: true,
    properties: {
      empty: "Please enter lens name",
    },
  },
  brand_name:{
    type:"string",
    required: true,
    properties: {
      empty: "Please enter brand name",
    },
  },
 

};
