import React, { useState, useEffect } from "react";
import { Button, Grid, Menu, MenuItem, Typography } from "@material-ui/core";

import { dateFormatConversion, loadState } from "../../../hooks/helper";
import MedicationChart from './MedicationChart'
import TableWidget from "../../Widget/TableWidget";

import TableDesign from "../../common/table/TableDesign";
import { TableRow, TableCell, IconButton } from "@material-ui/core";
// styles
import useStyles from "../styles";

import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";
import { getAllergyHistoryData } from "../../../services/ServiceLayer";

import moment from "moment";

export default function Contact(props) {
  const tableHeadings = [
   
    {
      label: "Contact",
      secondaryLabel: "Rx Date",
    },
    {
      label: "OD",
      secondaryLabel: "OS",
    },
    {
      label: "BC",
      secondaryLabel: "BC",
    },
    {
      label: "SPH",
      secondaryLabel: "SPH",
    },
    {
      label: "CYL",
      secondaryLabel: "CYL",
    },
    {
      label: "Axis",
      secondaryLabel: "Axis",
    },
    {
      label: "Add",
      secondaryLabel: "Add",
    },

    {
      label: "DVA",
      secondaryLabel: "DVA",
    },
    {
      label: "NVA",
      secondaryLabel: "NVA",
    },
    {
      label: "Color",
      secondaryLabel: "Tint",
    },
    {
      label: "Material",
      secondaryLabel: "Blend",
    },
    {
      label: "Lens Status",
      secondaryLabel: "Rep. Sch.",
    },
    {
      label: "Start Date",
      secondaryLabel: "End Date",
    },
  ];
  const { patientId, allergyOutcome, allergySeverity, allergyReaction } = props;
  console.log("props", props);
  const [open, setOpen] = React.useState(false);
  const [selectRow, setSelectRow] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const [selectclient, setselectclient] = useState(null);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [allergyData, setAllergyData] = useState([]);
  const [title, setTitle] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  useEffect(() => {
    getAllergyHistoryData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELALE`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setAllergyData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);
  useEffect(() => {
    getAllergyHistoryData(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELALE`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setAllergyData(response?.data);
        setDataUpdated(false);
      } else {
        // setIsLoader(false);
      }
    });
  }, [dataUpdated]);
  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setOpen(false);
    setDataUpdated(true);
    setselectclient(null);
  };
  const addVitalsModel = () => {
    setOpen(true);
  };
  const handleEdit = (rec) => {
    setOpen(true);
    setAnchorEl(null);
    setselectclient(rec);
    setTitle("Edit Problem");
  };
  const deleteEventHandler = (rec) => {
    setOpen(false);
    setAnchorEl(null);
    setselectclient(rec);
    setDeleteModalShow(true);
    setTitle("Delete Problem");
  };
  return (
    <>
      {/* <div
        className={classes.pageTitleContainer}
        style={{ marginBottom: "6px",marginTop: "10px", borderTop:'1px solid #6666'  }}
      >
        <Typography className={classes.typo} style={{fontSize:'14px'}} weight="medium">
          Contact
        </Typography>
      </div> */}

      <Grid container spacing={4} style={{ width: "100%" }}>
        <Grid item xs={12}>
        <div style={{background:'#5DB3D9',padding:'10px',color:'#fff',textAlign:'center',borderRadius:'5px',marginBottom:'2px'}}>Contact</div>
          {/* <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
                height: "40px",
                fontSize: "10px",
              }}
            >
              {allergyData && allergyData?.length ? (
                allergyData.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                       

                        <TableCell
                          align="left"
                          style={{ cursor: "pointer" }}
                          className={classes.tableBodyCell}
                        >
                        
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                         
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                         
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                         
                        </TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget> */}
          
<MedicationChart />
        </Grid>
      </Grid>
    </>
  );
}
