import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Fade,
} from "@material-ui/core";
import { TableRow, TableCell, MenuItem } from "@material-ui/core";
import TableDesign from "../common/table/TableDesign";
// data
import ManageUpdateSchedule from "./ManageUpdateSchedule";
import {
  dateFormatConversion,
  calculateAgeFromDate,
  ssnFormat,

} from "../../hooks/helper";
// Model
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../common/modal/Popup";
// styles
import useStyles from "./styles";
import {
  getPatientsSearch,
  postScheduleData,
  getschScheduletype,
  getCityStateByZip,
} from "../../services/ServiceLayer";
import moment from "moment";
import {
  scheduleFieldValidation,
  currentUser,
  loadState,
} from "../../hooks/helper";
import {
  tableHeadings,
  tableHeadings1,
  formElements,
} from "./Schedule_Constant";
import DeletePopup from "./DeletePopup";
import ScheduleValidation from "../../hooks/ScheduleValidation";
export default function ManageSchedule(props) {
  const [selectedDate, setSelectedDate] = useState();
  const locationId = props?.selectedLocation
    ? props?.selectedLocation
    : loadState()?.user?.location_id;
  console.log("locationId", locationId);
  const [age, setAge] = useState({ years: 0, months: 0 });
  useEffect(() => {
    console.log("selectedDate", selectedDate);

    const calculatedAge = calculateAgeFromDate(selectedDate);
    setAge(calculatedAge);
  }, [selectedDate]);
  console.log("props-24", props);
  const resourceId = props.resources[0]?.id;
  console.log(resourceId); // Output: "1"
  toast.configure();
  const {
    setUpdateDataSchedule,
    setUpdateDragSchedule,
    updateDragSchedule,
  } = props;

  const { selectSchedule, selectedLocation } = props;
  console.log("selectclient", selectSchedule);
  console.log("selectedLocation", selectedLocation);
  var classes = useStyles();
  // list meta data
  const [listData, setListData] = useState([]);

  const [raceValues, setRaceValues] = useState([]);
  const [genderValues, setGenderValues] = useState([]);
  const [reasonValues, setReasonValues] = useState([]);
  const [insuranceValues, setInsuranceValues] = useState([]);
  const [addInsuranceValues, setAddInsuranceValues] = useState([]);
  // list end meta data
  // disable past dates
  const [selectedLastName, setSelectedLastName] = useState();
  const [selectedMobileNumber, setSelectedMobileNumber] = useState("");
  // delete model
  const [open, setOpen] = React.useState(false);
  const [selectclient, setselectclient] = useState("");
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [title, setTitle] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [radioValue, setRadioValue] = useState("New");
  const [modalShow1, setModalShow1] = useState(false);
  const [isReschedule, setIsReschedule] = useState(false);

  const [maxWidth, setMaxWidth] = React.useState("md");
  // const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [selectPatientSchedule, setSelectPatientSchedule] = useState("");
  const [patientSearchList, setPatientSearchList] = useState("");
  const minDate = moment().subtract(150, "years").format("YYYY-MM-DD");
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");

  const buttonText =
    props.isReschedule === true ? "Update Appointment" : "Add Schedule";
  const schedulePatientData = selectSchedule
    ? selectSchedule.selectedPatientData
    : {};
  const address = schedulePatientData
    ? `${schedulePatientData.billing_address_line1}, ${schedulePatientData.billing_address_line2}  ${schedulePatientData.billing_city}, ${schedulePatientData.billing_state}, ${schedulePatientData.billing_country}, ${schedulePatientData.billing_zip}, `
    : "";
  //
  const [scheduleDetail, setScheduleDetail] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    dateofbirth: "",
    gender: "",
    reasonOfVisit: "",
    contact_home: '',
    contact_cell: "",
    email: "",
    billing_address_line1: "",
    billing_city: "",
    billing_state: "",
    billing_country: "",
    billing_zip: "",
    ssn: "",
    insurance_id: "",
    additionalInsurance: "",
    start: "",
    end: "",
    notes: "",
  });

  const [
    error,
    checkValidator,
    resetValidator,
    resetAllValidator,
    isError,
  ] = ScheduleValidation({
    first_name:
      scheduleDetail?.first_name === undefined ? "" : scheduleDetail.first_name,
    last_name:
      scheduleDetail?.last_name === undefined ? "" : scheduleDetail.last_name,
    contact_home:
      scheduleDetail?.contact_home === undefined
        ? ""
        : scheduleDetail.contact_home,
    contact_cell:
      scheduleDetail?.contact_cell === undefined
        ? ""
        : scheduleDetail.contact_cell,
    email: scheduleDetail?.email === undefined ? "" : scheduleDetail.email,
    billing_zip:
      scheduleDetail?.billing_zip === undefined
        ? ""
        : scheduleDetail.billing_zip,
    end: scheduleDetail?.end === undefined ? "" : scheduleDetail.end,
    reasonOfVisit:
      scheduleDetail?.reasonOfVisit === undefined
        ? ""
        : scheduleDetail?.reasonOfVisit,
    insurance_id:
      scheduleDetail?.insurance_id === undefined
        ? ""
        : scheduleDetail?.insurance_id,
  });

  // call meta data
  const metaData = loadState().metaData;

  useEffect(() => {
    console.log("loadState", metaData);
    setListData(metaData);
  }, []);
  // get  schedule type
  useEffect(() => {
    getschScheduletype().then((response) => {
      console.log("scheduleType", response);
      setReasonValues(response?.data);
    });
  }, []);
  useEffect(() => {
    console.log("listData", listData);
    if (listData.length > 0) {
      const raceValues = listData[0].race;
      const insuranceValues = listData[0].insurance;
      const genderValues = listData[0].gender;
      const addInsuranceValues = listData[0].additionalInsurance;
      setRaceValues(raceValues);
      setInsuranceValues(insuranceValues);
      setGenderValues(genderValues);
      setAddInsuranceValues(addInsuranceValues);
    }
  }, [listData]);
  // call meta data

  const formatPhoneNumber = (number) => {
    if (!number) return '';
    const cleaned = ('' + number).replace(/\D/g, '');
    if (cleaned.length > 5) {
      const match = cleaned.match(/^(\d{5})(\d*)$/);
      if (match) {
        return `${match[1]}-${match[2]}`;
      }
    }
    return cleaned;
  };


  useEffect(() => {
    console.log("selectclient useEffect", selectSchedule);
    console.log("schedulePatientData", schedulePatientData);

    if (selectSchedule?.schedulePatientData === "") {
      // setSelectedDate(moment().format("YYYY-MM-DD"));
      setScheduleDetail({
        first_name: "",
        middle_name: "",
        last_name: "",
        dateofbirth: "",
        gender: "",
        contact_home: '',
        contact_cell: "",
        email: "",
        billing_address_line1: "",
        billing_city: "",
        billing_state: "",
        billing_country: "",
        billing_zip: "",
        ssn: "",
        title: "",
        insurance_id: "",
        additionalInsurance: "",
        start: "",
        end: "",
        reasonOfVisit: "",
        notes: "",
      });
    } else {
      setSelectedDate(selectSchedule?.selectedPatientData?.dateofbirth);
      setScheduleDetail({
        patient_id: selectSchedule?.selectedPatientData?.patient_id,
        first_name: selectSchedule?.selectedPatientData?.first_name,
        middle_name: selectSchedule?.selectedPatientData?.middle_name,
        last_name: selectSchedule?.selectedPatientData?.last_name,
        dateofbirth: moment(
          selectSchedule?.selectedPatientData?.dateofbirth
        ).format("YYYY-MM-DD"),
        gender: selectSchedule?.selectedPatientData?.gender,
        title: selectSchedule?.selectedPatientData?.title,
        // contact_home: selectSchedule?.selectedPatientData?.contact_home,
        // contact_cell: selectSchedule?.selectedPatientData?.contact_cell,
        contact_home: formatPhoneNumber(selectSchedule?.selectedPatientData?.contact_home),
        contact_cell: formatPhoneNumber(selectSchedule?.selectedPatientData?.contact_cell),
        email: selectSchedule?.selectedPatientData?.email,
        billing_address_line1:
          selectSchedule?.selectedPatientData?.billing_address_line1,
        billing_address_line2:
          selectSchedule?.selectedPatientData?.billing_address_line2,
        billing_city: selectSchedule?.selectedPatientData?.billing_city,
        billing_state: selectSchedule?.selectedPatientData?.billing_state,
        billing_country: selectSchedule?.selectedPatientData?.billing_country,
        billing_zip: selectSchedule?.selectedPatientData?.billing_zip,
        // reasonOfVisit: selectSchedule?.selectedPatientData?.title,
        notes: selectSchedule?.selectedPatientData?.schedule_notes,
        ssn: selectSchedule?.selectedPatientData?.ssn,
        start: moment(selectSchedule?.start).format("YYYY-MM-DDTHH:mm:ss"),
        end: moment(selectSchedule?.end).format("YYYY-MM-DDTHH:mm:ss"),
        insurance_id: selectSchedule?.selectedPatientData?.insurance_id,
        additionalInsurance:
          selectSchedule?.selectedPatientData?.add_insurance_id,
        reasonOfVisit: selectSchedule?.selectedPatientData?.schedule_type_id,
      });
    }
  }, [selectSchedule]);

  const deleteEventHandler = () => {
    setOpen(false);
    setAnchorEl(null);
    props.onHide();

    setselectclient(selectSchedule);
    setDeleteModalShow(true);
    setTitle("Cancel Schedule");
  };
  const providerName = ` Provider Name : ${selectSchedule === null || selectSchedule === undefined
    ? ""
    : selectSchedule.doctorName
      ? selectSchedule.doctorName
      : selectSchedule.name
    }`;

  const clear = () => {
    setScheduleDetail({
      first_name: "",
      middle_name: "",
      last_name: "",
      dateofbirth: "",
      gender: "",
      contact_home: '',
      contact_cell: "",
      email: "",
      billing_address_line1: "",
      billing_address_line2: "",
      billing_city: "",
      billing_state: "",
      billing_country: "",
      billing_zip: "",
      ssn: "",
      title: "",
      insurance_id: "",
      additionalInsurance: "",
      start: "",
      end: "",
      reasonOfVisit: "",
      notes: "",
    });
    resetAllValidator();
  };
  const hideHandler = () => {
    console.log("hide handler called");

    setSelectedLastName(null);
    setSelectedMobileNumber("");
    setRadioValue("New");
    clear();
    // window.location.reload();
    setPatientSearchList("");
    props.onHide();
  };
  useEffect(() => {
    if (updateDragSchedule) {
      console.log("updateDragSchedule", updateDragSchedule);
      handleSubmit();
      setUpdateDragSchedule(false);
    }
  }, [updateDragSchedule]);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    checkValidator();
    setIsActionCalled(true);
  };

  useEffect(() => {
    if (!isError() && error !== null && isActionCalled) {
      const optType =
        selectSchedule && selectSchedule.patient_id !== undefined
          ? "RESCH"
          : "ADDSCH";

      const isReschedule = optType === "RESCH" ? "Y" : "N";
      const postData = {
        schedule_id: selectSchedule?.id || "0",
        start_time: scheduleDetail.start,
        end_time: scheduleDetail.end,
        patient_id: selectSchedule?.patient_id || 0,
        first_name: scheduleDetail.first_name,
        middle_name: scheduleDetail.middle_name || "",
        last_name: scheduleDetail.last_name,
        gender: scheduleDetail.gender || "",
        dob: scheduleDetail.dateofbirth || "",
        contact_home: scheduleDetail.contact_home || "",
        contact_cell: scheduleDetail.contact_cell || "",
        email_id: scheduleDetail.email || "",
        insurance_id: scheduleDetail.insurance_id || "",
        address1: scheduleDetail.billing_address_line1 || "",
        address2: scheduleDetail.billing_address_line2 || "",
        city: scheduleDetail.billing_city || "",
        state: scheduleDetail.billing_state || "",
        country: scheduleDetail.billing_country || "",
        zip: scheduleDetail.billing_zip || "",
        ssn: scheduleDetail.ssn || "",
        additional_insurance: scheduleDetail.additionalInsurance || 0,
        schedule_type_id: scheduleDetail.reasonOfVisit || "0",
        schedule_notes: scheduleDetail.notes || "",
        location_id: locationId,
        physician_id: resourceId,
        updated_by: currentUser(),
        is_confirmed: "",
        is_rescheduled: isReschedule,
        is_canceled: "",
        is_waitlist: "",
        is_scheduled: "",
        is_autoschedule: "",
        access_location: "1",
        opt_type: optType,
      };

      postScheduleData(postData).then((response) => {
        if (response?.status?.status === true) {
          clear();
          setRadioValue("New");
          toast.success(
            optType === "DELSCH"
              ? "The appointment was successfully canceled."
              : "Appointment Successfully Added.",
            { position: toast.POSITION.TOP_CENTER }
          );
          props.onHide();
          setIsActionCalled(false);
          setUpdateDataSchedule(true);
        } else {
          const errorMessage = response?.status?.description || scheduleFieldValidation(scheduleDetail);
          toast.error(errorMessage, { position: toast.POSITION.TOP_CENTER });
        }
      });
    }
    setIsActionCalled(false);
  }, [isActionCalled]);


  // search patient list
  const handleSearch = () => {
    getPatientsSearch(
      `contact_cell=${selectedMobileNumber === undefined || selectedMobileNumber === ""
        ? "0"
        : selectedMobileNumber
      }&location_id=${locationId === undefined || locationId === "" ? "0" : locationId
      }&lastName=${selectedLastName === undefined || selectedLastName === ""
        ? ""
        : selectedLastName
      }`
    ).then((response) => {
      console.log(response);
      if (response?.status.description === "") {
        setPatientSearchList(response?.data);
      } else {
        toast.error(response?.status.description, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const createUpdateClient = (patient) => {
    console.log("patient", patient);
    setModalShow1(true);

    setSelectPatientSchedule([patient, selectSchedule]);
    // setShow1(true);
    setOpen1(true);
    props.onHide();
  };
  const hideHandle1 = () => {
    setOpen1(false);
    // setSelectPatientSchedule("");
    setPatientSearchList("");
    setSelectedLastName(null);
    setSelectedMobileNumber("");
    resetAllValidator();
    setRadioValue("New");
    setModalShow1(false);
  };

  const hideHandle = () => {
    console.log("hidehandle called");
    // setModalShow(false);
    setDeleteModalShow(false);
    setOpen(false);
    props.onHide();
  };
  const { onHide, ...props1 } = props;
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (scheduleDetail?.billing_zip) {
      if (scheduleDetail?.billing_zip.length >= 5) {
        getCityStateByZip(`zip=${scheduleDetail?.billing_zip}`)
          .then((response) => {
            console.log("response-810", response);
            if (response?.status.description === "") {
              setSuggestions(response?.data);
              setScheduleDetail({
                ...scheduleDetail,
                billing_city: response?.data[0]?.city,
                billing_state: response?.data[0]?.state_id,
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching city and state:", error);
            // Handle error if necessary
          });
      } else {
        // Clear city and state if zip code is less than 5 characters
        setScheduleDetail({
          ...scheduleDetail,
          billing_city: "",
          billing_state: "",
        });
      }
    }
  }, [scheduleDetail?.billing_zip]);

  return (
    <>
      <DeletePopup
        onHide={hideHandle}
        deleteModalShow={deleteModalShow}
        selectclient={selectclient}
        setDeleteModalShow={setDeleteModalShow}
      />
      <ManageUpdateSchedule
        open={open1}
        onHide1={hideHandle1}
        title={providerName}
        selectPatientSchedule={selectPatientSchedule}
        isReschedule={isReschedule}
        setModalShow1={setModalShow1}
        setUpdateDataSchedule={setUpdateDataSchedule}
        resourceId={resourceId}
        selectedLocation={selectedLocation}
        setUpdateDragSchedule={setUpdateDragSchedule}
      />
      <Popup
        {...props1}
        onHide={hideHandler}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        title={providerName}
        footer={
          <>
            {radioValue === "New" ? (
              <>
                <Button
                  className={classes.fillBtn}
                  variant="outlined"
                  size="medium"
                  color="secondary"
                  onClick={hideHandler}
                >
                  Close
                </Button>

                <Button
                  className={classes.fillBtn}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={handleSubmit}
                >
                  {buttonText}
                </Button>

                {props.isReschedule === true && (
                  <Button
                    className={classes.fillBtn}
                    variant="contained"
                    size="medium"
                    color="secondary"
                    onClick={() => deleteEventHandler()}
                  >
                    Cancel Appointment
                  </Button>
                )}
              </>
            ) : (
              ""
            )}
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid container spacing={1}>
              {!props.isReschedule === true ? (
                <>
                  <Grid item xs={12} md={12} style={{ display: "flex" }}>
                    <Typography
                      style={{ marginTop: "8px", marginRight: "16px" }}
                      className={classes.formHeading}
                    >
                      Patient Type
                    </Typography>
                    <RadioGroup
                      row
                      className={classes.radioGroup}
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        checked={radioValue === "New"}
                        value="New"
                        control={<Radio />}
                        label="New"
                        onClick={(e) => {
                          console.log("clicked down");
                          setRadioValue(e.target.value);
                        }}
                      />
                      <FormControlLabel
                        checked={radioValue === "Exisiting"}
                        value="Exisiting"
                        control={<Radio />}
                        label="Exisiting"
                        onClick={(e) => {
                          console.log("clicked down");
                          setRadioValue(e.target.value);
                        }}
                      />
                    </RadioGroup>
                  </Grid>
                </>
              ) : (
                ""
              )}
              {radioValue === "New" ? (
                <>
                  {formElements.map((element, i) => {
                    // form popup
                    return (
                      <>
                        {element.type === "select" ? (
                          <Grid
                            item
                            lg={4}
                            xs={12}
                            md={element.mdSize}
                            className={classes.textGroup}
                          >
                            <Typography className={classes.formHeading}>
                              {element.label}
                              {element.required === "true" ? (
                                <span className={classes.required}>*</span>
                              ) : null}
                            </Typography>
                            <FormControl
                              fullWidth
                              className={classes.dropdownFormControl}
                            >
                              <Select
                                name={element.key}
                                className={classes.selectDropdown}
                                style={{ fontSize: "12px" }}
                                placeholder={element.placeHolder}
                                // defaultValue={scheduleDetail && scheduleDetail[element.key]}
                                value={
                                  scheduleDetail && scheduleDetail[element.key]
                                    ? scheduleDetail &&
                                    scheduleDetail[element.key]
                                    : ""
                                }
                                onChange={(e) => {
                                  if (element.key === "reasonOfVisit") {
                                    const selectedReason = e.target.value;
                                    const selectedDuration = reasonValues.find(
                                      (reason) =>
                                        reason.schedule_type_id ===
                                        selectedReason
                                    )?.schedule_duration;

                                    if (selectedDuration) {
                                      const updatedEndTime = moment(
                                        scheduleDetail.start
                                      )
                                        .add(selectedDuration, "minutes")
                                        .format("YYYY-MM-DDTHH:mm:ss");

                                      setScheduleDetail({
                                        ...scheduleDetail,
                                        end: updatedEndTime,
                                        [element.key]: selectedReason,
                                      });
                                    }
                                  } else {
                                    setScheduleDetail({
                                      ...scheduleDetail,
                                      [element.key]: e.target.value,
                                    });
                                  }
                                  resetValidator(e.target.name);
                                }}
                                isInvalid={error && error[element.key]}
                              >
                                <MenuItem disabled value="">
                                  Select
                                </MenuItem>
                                {element.key == "gender" && genderValues
                                  ? genderValues.map((item) => (
                                      <MenuItem
                                        key={item.attribute_id}
                                        value={item.attribute_id}
                                      >
                                        {item.attribute_val}
                                      </MenuItem>
                                    ))
                                  : element.key == "reasonOfVisit" &&
                                    reasonValues
                                    ? reasonValues.map((item) => (
                                      <MenuItem
                                        key={item.schedule_type_id}
                                        value={item.schedule_type_id}
                                      >
                                        {item.schedule_name}
                                      </MenuItem>
                                    ))
                                    : element.key == "insurance_id" &&
                                      insuranceValues
                                      ? insuranceValues.map((item) => (
                                        <MenuItem
                                          disabled={
                                            selectSchedule?.selectedPatientData
                                              ?.insurance_id &&
                                              selectSchedule?.selectedPatientData
                                                ?.insurance_id
                                              ? true
                                              : false
                                          }
                                          key={item.attribute_id}
                                          value={item.attribute_id}
                                        >
                                          {item.attribute_val}
                                        </MenuItem>
                                      ))
                                      : element.key == "additionalInsurance" &&
                                        addInsuranceValues
                                        ? addInsuranceValues.map((item) => (
                                          <MenuItem
                                            disabled={
                                              selectSchedule?.selectedPatientData
                                                ?.add_insurance_id &&
                                                selectSchedule?.selectedPatientData
                                                  ?.add_insurance_id
                                                ? true
                                                : false
                                            }
                                            key={item.attribute_id}
                                            value={item.attribute_id}
                                          >
                                            {item.attribute_val}
                                          </MenuItem>
                                        ))
                                        : (element.key == "billing_state" &&
                                          element.options) ||
                                          (element.key == "title" && element.options)
                                          ? element.options.map((item) => (
                                            <MenuItem key={item} value={item}>
                                              {item}
                                            </MenuItem>
                                          ))
                                          : (element.key == "billing_country" &&
                                            element.options) ||
                                            (element.key == "billing_country" && element.options)
                                            ? element.options.map((item) => (
                                              <MenuItem key={item} value={item}>
                                                {item}
                                              </MenuItem>
                                            ))
                                            : null}
                              </Select>
                            </FormControl>
                            <Fade in={error}>
                              <Typography
                                color="secondary"
                                className={classes.errorMessage}
                              >
                                {error && error[element.key]}
                              </Typography>
                            </Fade>
                          </Grid>
                        ) : element.type === "text" &&
                          element.key !== "billing_zip" ? (
                          <Grid
                            item
                            lg={4}
                            xs={12}
                            md={element.mdSize}
                            className={classes.textGroup}
                          >
                            <Typography className={classes.formHeading}>
                              {element.label}
                              {element.required === "true" ? (
                                <span className={classes.required}>*</span>
                              ) : null}
                            </Typography>
                            <TextField
                              required
                              name={element.key}
                              placeholder={element.placeHolder}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              inputProps={{
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                                maxLength: element.maxLength,
                                ...(element.key === "ssn" && {
                                  onInput: (e) => {
                                    e.target.value = ssnFormat(e.target.value);
                                  },
                                }),
                                onKeyPress: element.onKeyPress, // Add the event handler
                                onPaste: element.onPaste,
                              }}
                              value={
                                scheduleDetail && scheduleDetail[element.key]
                              }
                              onChange={(e) => {
                                setScheduleDetail({
                                  ...scheduleDetail,
                                  [element.key]: e.target.value,
                                });
                                resetValidator(e.target.name);
                              }}
                              isInvalid={error && error[element.key]}
                            />
                            <Fade in={error}>
                              <Typography
                                color="secondary"
                                className={classes.errorMessage}
                              >
                                {error && error[element.key]}
                              </Typography>
                            </Fade>
                          </Grid>
                        ) : element.type === "text" &&
                          element.key === "billing_zip" ? (
                          <Grid
                            item
                            lg={4}
                            xs={12}
                            md={element.mdSize}
                            className={classes.textGroup}
                          >
                            <Typography className={classes.formHeading}>
                              {element.label}
                              {element.required === "true" ? (
                                <span className={classes.required}>*</span>
                              ) : null}
                            </Typography>
                            <TextField
                              id={element.key}
                              name={element.key}
                              placeholder={element.placeHolder}
                              type={element.type}
                              size="small"
                              inputProps={{
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                                maxLength: element.maxLength,
                              }}
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              value={
                                scheduleDetail && scheduleDetail[element.key]
                              }
                              onChange={(e) => {
                                setScheduleDetail({
                                  ...scheduleDetail,
                                  [element.key]: e.target.value,
                                });
                                resetValidator(e.target.name);
                              }}
                              isInvalid={error && error[element.key]}
                            />
                            <Fade in={error}>
                              <Typography
                                color="secondary"
                                className={classes.errorMessage}
                              >
                                {error && error[element.key]}
                              </Typography>
                            </Fade>
                          </Grid>
                        ) : element.type === "date" ? (
                          <Grid
                            item
                            lg={4}
                            xs={12}
                            md={3}
                            className={classes.textGroup}
                          >
                            {/* Name */}
                            <Typography className={classes.formHeading}>
                              {element.label}{" "}
                              <span style={{ color: "red", fontWeight: "500" }}>
                                ({age.years} yr {age.months} mon )
                              </span>
                              {element.required === "true" ? (
                                <span className={classes.required}>*</span>
                              ) : null}
                            </Typography>
                            <TextField
                              id={element.key}
                              name={element.key}
                              placeholder={element.placeHolder}
                              type={element.type}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              value={
                                scheduleDetail && scheduleDetail[element.key]
                              }
                              onChange={(e) => {
                                setScheduleDetail({
                                  ...scheduleDetail,
                                  [element.key]: e.target.value,
                                });
                                resetValidator(e.target.name);
                                setSelectedDate(e.target.value);
                              }}
                              // onKeyDown={(e) => {
                              //   e.preventDefault(); // Prevent manual typing
                              // }}
                              inputProps={{
                                min: `${minDate}`,
                                max: `${currentDate}`, // Set the maximum time to 18:00
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              isInvalid={error && error[element.key]}
                            />
                            <Fade in={error}>
                              <Typography
                                color="secondary"
                                className={classes.errorMessage}
                              >
                                {error && error[element.key]}
                              </Typography>
                            </Fade>
                          </Grid>
                        ) : element.type === "datetime-local" ? (
                          <Grid
                            item
                            lg={4}
                            xs={12}
                            md={3}
                            className={classes.textGroup}
                          >
                            {/* Name */}
                            <Typography className={classes.formHeading}>
                              {element.label}
                              {element.required === "true" ? (
                                <span className={classes.required}>*</span>
                              ) : null}
                            </Typography>
                            <TextField
                              id={element.key}
                              name={element.key}
                              placeholder={element.placeHolder}
                              type={element.type}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              variant="outlined"
                              value={
                                scheduleDetail && scheduleDetail[element.key]
                              }
                              onChange={(e) => {
                                const selectedEndTime = e.target.value;
                                // const selectedStartTime = scheduleDetail.start;

                                const selectedStartTime = moment(
                                  e.target.value,
                                  "YYYY-MM-DDTHH:mm"
                                );
                                const timeDifference = moment(
                                  scheduleDetail.end,
                                  "YYYY-MM-DDTHH:mm"
                                ).diff(
                                  moment(
                                    scheduleDetail.start,
                                    "YYYY-MM-DDTHH:mm"
                                  )
                                );
                                const updatedEndTime = selectedStartTime
                                  .clone()
                                  .add(timeDifference);
                                const endTime = moment(
                                  selectedEndTime,
                                  "YYYY-MM-DDTHH:mm"
                                );
                                const startTime = moment(
                                  selectedStartTime,
                                  "YYYY-MM-DDTHH:mm"
                                );
                                const endHour = endTime.hour();
                                const startHour = startTime.hour();

                                const maxHour = 18; // Maximum hour allowed (6 PM)

                                if (
                                  endHour > maxHour ||
                                  (endHour === maxHour && endTime.minute() > 0)
                                ) {
                                  alert(
                                    "Please select a time before 18:00 (6 PM)."
                                  );
                                  return; // Prevent updating the state if time is after 6 PM
                                }
                                setScheduleDetail({
                                  ...scheduleDetail,
                                  start: e.target.value,
                                  end: updatedEndTime.format(
                                    "YYYY-MM-DDTHH:mm"
                                  ),
                                });
                                resetValidator(e.target.name);
                              }}
                              onKeyDown={(e) => {
                                e.preventDefault(); // Prevent manual typing
                              }}
                              inputProps={{
                                min: `${currentDateTime}`,
                                max: `${currentDate}`, // Set the maximum time to 18:00
                                style: {
                                  height: "12px",
                                  fontSize: "12px",
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              isInvalid={error && error[element.key]}
                            />
                            <Fade in={error}>
                              <Typography
                                color="secondary"
                                className={classes.errorMessage}
                              >
                                {error && error[element.key]}
                              </Typography>
                            </Fade>
                          </Grid>
                        ) : (
                          <Grid
                            item
                            xs={12}
                            md={12}
                            className={classes.textGroup}
                          >
                            <Typography className={classes.formHeading}>
                              {element.label}
                              {element.required === "true" ? (
                                <span className={classes.required}>*</span>
                              ) : null}
                            </Typography>
                            <TextField
                              name={element.key}
                              size="small"
                              className={classes.txtForm}
                              fullWidth
                              placeholder="Type here..."
                              variant="outlined"
                              multiline
                              rows={3}
                              inputProps={{
                                style: {
                                  fontSize: "12px",
                                },
                              }}
                              value={scheduleDetail && scheduleDetail.notes}
                              onChange={(e) => {
                                setScheduleDetail({
                                  ...scheduleDetail,
                                  notes: e.target.value,
                                });
                                resetValidator(e.target.name);
                              }}
                              isInvalid={error && error[element.key]}
                            />
                            <Fade in={error}>
                              <Typography
                                color="secondary"
                                className={classes.errorMessage}
                              >
                                {error && error[element.key]}
                              </Typography>
                            </Fade>
                          </Grid>
                        )}
                      </>
                    );
                  })}
                </>
              ) : (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={5} className={classes.textGroup}>
                      {/* org name */}

                      <TextField
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        placeholder="Enter Last Name"
                        variant="outlined"
                        value={selectedLastName}
                        onChange={(e) => {
                          console.log("selectedLocation", e.target.value);
                          setSelectedLastName(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={5} className={classes.textGroup}>
                      <TextField
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        placeholder="Enter Contact Number"
                        variant="outlined"
                        value={selectedMobileNumber}
                        onChange={(e) => {
                          console.log("selectedLocation", e.target.value);
                          setSelectedMobileNumber(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={2} className={classes.textGroup}>
                      <Button
                        variant="contained"
                        size="medium"
                        color="secondary"
                        onClick={handleSearch}
                      >
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                  <div style={{ marginTop: "10px", width: "inherit" }}>
                    <>
                      {patientSearchList ? (
                        <TableDesign
                          {...{
                            title: "List of All Clients",
                            tableHeadings,
                            tableHeadings1,
                          }}
                        >
                          {patientSearchList && patientSearchList.length ? (
                            patientSearchList.map((row, i) => {
                              if (row)
                                return (
                                  <TableRow
                                    onClick={() => createUpdateClient(row)}
                                    key={i}
                                    className={classes.tableBodyCell}
                                  >
                                    <TableCell
                                      className={classes.tableBodyCell}
                                    >
                                      {i + 1}
                                    </TableCell>

                                    <TableCell
                                      align="left"
                                      className={classes.tableBodyCell}
                                      style={{
                                        cursor: "pointer",
                                        textAlign: "left",
                                        paddingLeft: "10px",
                                      }}
                                    >
                                      {row.first_name}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className={classes.tableBodyCell}
                                      style={{
                                        cursor: "pointer",
                                        textAlign: "left",
                                        paddingLeft: "10px",
                                      }}
                                    >
                                      {row.last_name}
                                    </TableCell>

                                    <TableCell
                                      align="left"
                                      style={{ cursor: "pointer" }}
                                      className={classes.tableBodyCell}
                                    >
                                      {row.contact_cell}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      style={{ cursor: "pointer" }}
                                      className={classes.tableBodyCell}
                                    >
                                      {dateFormatConversion(row.dateofbirth)}
                                    </TableCell>
                                    {radioValue === "Exisiting Schedule" ? (
                                      <TableCell
                                        align="left"
                                        style={{ cursor: "pointer" }}
                                        className={classes.tableBodyCell}
                                      >
                                        Appointment Date
                                      </TableCell>
                                    ) : null}

                                    <TableCell
                                      align="left"
                                      style={{ cursor: "pointer" }}
                                      className={classes.tableBodyCell}
                                    >
                                      Active
                                    </TableCell>
                                  </TableRow>
                                );
                            })
                          ) : (
                            <TableRow>
                              <TableCell colSpan="8">No Result Found</TableCell>
                            </TableRow>
                          )}
                        </TableDesign>
                      ) : (
                        ""
                      )}
                    </>
                  </div>
                </>
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
