import {
  handleKeyPress,
  handleKeyPressPaste,
  handleAlphNumKeyPress,
  handleAlphNumKeyPressPaste,
  handleAlphNumberKeyPress,
  handleAlphNumberKeyPressPaste,
  handleAddressKeyPress,
  handleAddressKeyPressPaste,
} from "../../../hooks/helper";
export const formProblemElements = [
  {
    key: "problem",
    label: "Problem",
    placeHolder: "Select",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "startDate",
    label: "Start Date",
    placeHolder: "select",
    type: "date",
    mdSize: "2",
  },
  {
    key: "endDate",
    label: "End Date",
    placeHolder: "select",
    type: "date",
    mdSize: "2",
  },
  {
    key: "icd_code",
    label: "ICD Code",
    placeHolder: "Enter Text",
    type: "text",
    mdSize: "2",
    onKeyPress: handleKeyPress,
    onPaste: handleKeyPressPaste,

    maxLength: "10",
    // required: "true",
  },
  {
    key: "status",
    label: "Status",
    placeHolder: "select",
    type: "select",
    options: [],
    mdSize: "2",
  },
];
export const examSection = [
  {
    label: "Exam Type",
    mdSize: "2",
  },
  {
    label: "OD",
    mdSize: "5",
    // copyBtn:'Copy OD to OS'
  },
  {
    label: "OS",
    mdSize: "5",
    // copyBtn:'Copy OS to OD'
  },
  {
    label: "Eyelid",
    mdSize: "2",
  },
  {
    key: "eyelid_od",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  {
    key: "eyelid_os",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  // ----
  {
    label: "Lacrimal System",
    mdSize: "2",
  },
  {
    key: "lacrimalsys_od",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  {
    key: "lacrimalsys_os",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  // ----
  {
    label: "Cornea",
    mdSize: "2",
  },
  {
    key: "cornea_od",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  {
    key: "cornea_os",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  // ----
  {
    label: "Conjunctiva",
    mdSize: "2",
  },
  {
    key: "conjunctiva_od",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  {
    key: "conjunctiva_os",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  // ----
  {
    label: "Anterior Chamber",
    mdSize: "2",
  },
  {
    key: "anteriorchamber_od",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  {
    key: "anteriorchamber_os",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  // ----
  {
    label: "Iris",
    mdSize: "2",
  },
  {
    key: "ac_iris_od",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  {
    key: "ac_iris_os",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  // ----
  {
    label: "Tear Film",
    mdSize: "2",
  },
  {
    key: "tearfilm_od",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  {
    key: "tearfilm_os",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  // ----
  {
    label: "Sclera",
    mdSize: "2",
  },
  {
    key: "sclera_od",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  {
    key: "sclera_os",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  // ----
  {
    label: "Preauricular Node",
    mdSize: "2",
  },
  {
    key: "preauricularnode_od",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  {
    key: "preauricularnode_os",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  {
    label: "Orbits, Globe",
    mdSize: "2",
  },
  {
    key: "orbits_od",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  {
    key: "orbits_os",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  // {
  //   label: "Face",
  //   mdSize: "2",
  // },
  // {
  //   key: "face_od",
  //   label: "",
  //   type: "select",
  //   options: [""],
  //   mdSize: "5",
  // },
  // {
  //   key: "face_os",
  //   label: "",
  //   type: "select",
  //   options: [""],
  //   mdSize: "5",
  // },
  // {
  //   label: "Fat Prolapse",
  //   mdSize: "2",
  // },
  // {
  //   key: "fatprolapse_od",
  //   label: "",
  //   type: "select",
  //   options: [""],
  //   mdSize: "5",
  // },
  // {
  //   key: "fatprolapse_os",
  //   label: "",
  //   type: "select",
  //   options: [""],
  //   mdSize: "5",
  // },
  {
    label: "Pupil",
    mdSize: "2",
  },
  {
    label: "",
    btnlbl: "add pupil",
    type: "button",
    mdSize: "10",
  },
  // {
  //   label: "",
  //   btnlbl: "add pupil",
  //   type: "button",
  //   mdSize: "5",
  // },
  // {
  //   label: "Binocular Function/Seereopsis",
  //   mdSize: "2",
  // },
  // {
  //   key: "binocular_function_od",
  //   label: "",
  //   type: "select",
  //   options: [""],
  //   mdSize: "5",
  // },
  // {
  //   key: "binocular_function_os",
  //   label: "",
  //   type: "select",
  //   options: [""],
  //   mdSize: "5",
  // },
];
export const internalExamSection = [
  {
    label: "Exam Type",
    mdSize: "2",
  },
  {
    label: "OD",
    mdSize: "5",
  },
  {
    label: "OS",
    mdSize: "5",
  },
  {
    label: "Lens",
    mdSize: "2",
  },
  {
    key: "lens_od",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  {
    key: "lens_os",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  // ----
  {
    label: "Vitreous",
    mdSize: "2",
  },
  {
    key: "vitreous_od",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  {
    key: "vitreous_os",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  // ----
  {
    label: "Optic Disc",
    mdSize: "2",
  },
  {
    key: "opticdisc_od",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  {
    key: "opticdisc_os",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  // ----
  {
    label: "CD Ratio",
    mdSize: "2",
  },
  {
    key: "cdratio_od",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  {
    key: "cdratio_os",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  // ----
  {
    label: "Vessels",
    mdSize: "2",
  },
  {
    key: "vessels_od",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  {
    key: "vessels_os",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  // ----
  {
    label: "AV Ratio",
    mdSize: "2",
  },
  {
    key: "avratio_od",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  {
    key: "avratio_os",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  // ----
  {
    label: "Macular",
    mdSize: "2",
  },
  {
    key: "macula_od",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  {
    key: "macula_os",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  // ----
  {
    label: "Retina",
    mdSize: "2",
  },
  {
    key: "retina_od",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  {
    key: "retina_os",
    label: "",
    type: "select",
    options: [""],
    mdSize: "5",
  },
  {
    label: "Retina Notes",
    mdSize: "2",
  },
  {
    key: "retina_notes_od",
    label: "",
    type: "text",
    placeHolder:'Enter Text',
    mdSize: "5",
  },
  {
    key: "retina_notes_os",
    label: "",
    placeHolder:'Enter Text',
    type: "text",
   
    mdSize: "5",
  },
  // ----
];
export const iopForm = [
  {
    label: "OD",
    mdSize: "1",
    // copyBtn: "true",
  },
  {
    key: "iop_method_od",
    label: "Method",
    type: "select",
    options: ["Yes", "No", "Unknown"],
    mdSize: "2",
  },
  {
    key: "iop_measure_od",
    label: "Measure (mm Hg)",
    type: "select",
    options: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
    ],
    mdSize: "2",
  },
  {
    key: "iop_reliability_od",
    label: "Reliability (mm Hg)",
    type: "select",
    options: [
      "0.1",
      "0.2",
      "0.3",
      "0.4",
      "0.5",
      "0.6",
      "0.7",
      "0.8",
      "0.10",
      "1",
      "1.1",
      "1.2",
      "1.3",
      "1.4",
      "1.5",
      "1.6",
      "1.7",
      "1.8",
      "1.10",
      "2",
      "2.1",
      "2.2",
      "2.3",
      "2.4",
      "2.5",
      "2.6",
      "2.7",
      "2.10",
      "3",
      "3.1",
      "3.2",
      "3.3",
      "3.4",
      "3.5",
      "3.6",
      "3.7",
      "3.10",
      "4",
      "4.1",
      "4.2",
      "4.3",
      "4.4",
      "4.5",
      "4.6",
      "4.7",
      "4.8",
      "4.9",
      "4.10",
      "5",
    ],
    mdSize: "2",
  },
  {
    key: "iop_other_od",
    label: "Other",
    type: "text",
    mdSize: "5",
    maxLength:'50'
  },
  // {
  //   key: "btnLookup",
  //   label: "",
  //   placeHolder: "",
  //   type: "button",
  //   mdSize: "2",
  //   btnlbl: "Copy OD to OS",
  //   onClick: true,
  // },
  {
    label: "OS",
    mdSize: "1",
  },
  {
    key: "iop_method_os",

    label: "",
    type: "select",
    options: ["Yes", "No", "Unknown"],
    mdSize: "2",
  },
  {
    key: "iop_measure_os",
    label: "",
    type: "select",
    options: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
    ],
    mdSize: "2",
  },
  {
    key: "iop_reliability_os",
    label: "",
    type: "select",
    options: [
      "0.1",
      "0.2",
      "0.3",
      "0.4",
      "0.5",
      "0.6",
      "0.7",
      "0.8",
      "0.10",
      "1",
      "1.1",
      "1.2",
      "1.3",
      "1.4",
      "1.5",
      "1.6",
      "1.7",
      "1.8",
      "1.10",
      "2",
      "2.1",
      "2.2",
      "2.3",
      "2.4",
      "2.5",
      "2.6",
      "2.7",
      "2.10",
      "3",
      "3.1",
      "3.2",
      "3.3",
      "3.4",
      "3.5",
      "3.6",
      "3.7",
      "3.10",
      "4",
      "4.1",
      "4.2",
      "4.3",
      "4.4",
      "4.5",
      "4.6",
      "4.7",
      "4.8",
      "4.9",
      "4.10",
      "5",
    ],
    mdSize: "2",
  },
  {
    key: "iop_other_os",
    label: "",
    type: "text",
    mdSize: "5",
    maxLength:'50'
  },
  // {
  //   key: "btnLookup",
  //   label: "",
  //   placeHolder: "",
  //   type: "button",
  //   mdSize: "2",
  //   btnlbl: "Copy OS to OD",
  //   onClick: true,
  // },
];
export const dialationForm = [
  {
    label: "OD",
    mdSize: "1",
    // copyBtn: "true",
    // btnLabel: "copy OD to OS",
  },

  {
    key: "dialation_med_od",
    label: "Medication",
    type: "select",
    options: [""],
    mdSize: "2",
  },
  {
    key: "dialation_counsel_od",
    label: "Counsel",
    type: "select",
    options: [""],
    mdSize: "2",
  },
  {
    key: "dialation_staff_od",
    label: "Administered By",
    placeHolder: "type",
    type: "select",
    options: [""],
    mdSize: "2",
  },
  {
    key: "dialation_notes_od",
    label: "Comment",
    type: "text",
    mdSize: "3",
    maxLength:'50'
  },
  {
    key: "dialation_dt_od",
    label: "Date & Time",
    type: "datetime-local",
    mdSize: "2",
  },

  {
    label: "OS",
    mdSize: "1",
    // copyBtn: "true",
    // btnLabel: "copy OS to OD",
  },
  {
    key: "dialation_med_os",
    label: "",
    type: "select",
    options: [""],
    mdSize: "2",
  },
  {
    key: "dialation_counsel_os",
    label: "",
    type: "select",
    options: [""],
    mdSize: "2",
  },
  {
    key: "dialation_staff_os",
    label: "",
    placeHolder: "type",
    type: "select",
    options: [""],
    mdSize: "2",
  },
  {
    key: "dialation_notes_os",
    label: "",
    type: "text",
    mdSize: "3",
    maxLength:'50'
  },
  {
    key: "dialation_dt_os",
    label: "",
    type: "datetime-local",
    mdSize: "2",
  },
  {
    key: "dialation_flag",
    label: "Declined Dilation",
    type: "checkbox",
    mdSize: "2",
  },
  {
    key: "dialation_notes",
    label: "Notes",
    type: "text",
    mdSize: "10",
    maxLength:'50'
  },
];
export const pachymetryForm = [
  {
    label: "OD",
    mdSize: "1",
    
  },
 
  {
    key:'inp_pachy_microns_od',
    label: "Microns (μm)",
    type: "text",
    placeHolder:'text',
    mdSize: "2",
  },
  {
    key:'pachy_testdt_od',
    label: "Test Date",
    type: "date",
    mdSize: "2",
  },

  {
    key:'pachy_notes_od',
    label: "Comment",
    type: "text",
    mdSize: "4",
  },
  {
    key:'pachy_icd_od',
    label: "ICD 10 Code",
    type: "select",
    options: [""],
    mdSize: "3",
  },

  {
    label: "OS",
    mdSize: "1",
    
  },
  {
    key:'pachy_microns_os',
    label: "",
    type: "text",
    placeHolder:'text',
    mdSize: "2",
  },
  {
    key:'pachy_testdt_os',
    label: "",
    type: "date",
    mdSize: "2",
  },
 
  {
    key:'pachy_notes_os',
    label: "",
    type: "text",
    mdSize: "4",
  },
  {
    key:'pachy_icd_os',
    label: "",
    type: "select",
    options: [""],
    mdSize: "3",
  },
];
export const notesForm = [
  {
    key: "internal_notes",
    label: "Internal Notes",
    placeHolder: "Type here",
    type: "textarea",
    mdSize: "6",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
  {
    key: "external_notes",
    label: "External Notes",
    placeHolder: "Type here",
    type: "textarea",
    mdSize: "6",
    onKeyPress: handleAddressKeyPress,
    onPaste: handleAddressKeyPressPaste,
  },
];

export const formAdditionalElements = [
  {
    key: "ethnicity",
    label: "Ethinicity",
    placeHolder: "Enter Ethinicity",
    type: "select",
    options: [],
    mdSize: "1",
  },
  {
    key: "race",
    label: "Race",
    placeHolder: "Enter Race",
    type: "select",
    options: [],
    mdSize: "2",
  },
  {
    key: "hobbies",
    label: "Hobbies",
    placeHolder: "Enter Hobbies",
    type: "text",
    mdSize: "2",
  },
  {
    value: "btn1",
    btnName: "Search Physician",
    type: "button",
    mdSize: "3",
    btnClick: "searchguardian",
  },
  {
    key: "primary_physician",
    label: "Primary Physician",
    placeHolder: "Enter Primary Physician",
    type: "text",
    mdSize: "2",
  },
  {
    key: "physician_address",
    label: "Physician Address",
    placeHolder: "Enter Physician Address",
    type: "text",
    mdSize: "2",
  },
  {
    key: "physician_city",
    label: "City",
    placeHolder: "Enter City",
    type: "text",
    mdSize: "1",
  },
  {
    key: "physician_state",
    label: "State",
    placeHolder: "select",
    type: "select",
    options: [
      "AK",
      "AL",
      "AR",
      "AS",
      "AZ",
      "CA",
      "CO",
      "CT",
      "DC",
      "DE",
      "FL",
      "GA",
      "GU",
      "HI",
      "IA",
      "ID",
      "IL",
      "IN",
      "KS",
      "KY",
      "LA",
      "MA",
      "MD",
      "ME",
      "MI",
      "MN",
      "MO",
      "MS",
      "MT",
      "NC",
      "ND",
      "NE",
      "NH",
      "NJ",
      "NM",
      "NV",
      "NY",
      "OH",
      "OK",
      "OR",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VA",
      "VI",
      "VT",
      "WA",
      "WI",
      "WV",
      "WY",
    ],
    mdSize: "1",
  },
  {
    key: "physician_zip",
    label: "Zip",
    placeHolder: "Enter Zip",
    type: "text",
    mdSize: "1",
    onKeyPress: handleKeyPress,
    onPaste: handleKeyPress,
    maxLength: "5",
  },
  {
    key: "notes",
    label: "General Notes",
    placeHolder: "Enter General Notes",
    type: "textarea",
    mdSize: "4",
  },
  {
    key: "imp_shrt_notes",
    label: "Alert",
    placeHolder: "Enter Important Short Notes",
    type: "text",
    mdSize: "3",
  },
  {
    key: "applicable_role_id",
    label: "Applicable Role ",
    placeHolder: "select",
    type: "select",
    options: ["Reception", "Doctor", "Admin", "Optician"],
    mdSize: "2",
  },
  {
    key: "is_disability",
    label: "Disability",
    placeHolder: "select",
    type: "select",
    options: [
      "Blindness",
      "Low-vision",
      "Leprosy Cured persons",
      "Hearing Impairment (deaf and hard of hearing)",
      "Locomotor Disability",
      "Dwarfism",
      "Intellectual Disability",
      "Mental Illness",
      "Autism Spectrum Disorder",
      "Cerebral Palsy",
      "Muscular Dystrophy",
      "Chronic Neurological conditions",
      "Specific Learning Disabilities",
      "Multiple Sclerosis",
      "Speech and Language disability",
      "Thalassemia",
      "Hemophilia",
      "Sickle Cell disease",
      "Multiple Disabilities including deaf-blindness",
      "Acid Attack victims",
      "Parkinson’s disease",
      "Other",
    ],
    mdSize: "1",
  },
  {
    key: "is_veteran_status",
    label: "Veteran Status",
    placeHolder: "select",
    type: "select",
    options: [
      "Protected Veteran",
      "Other Veteran",
      "Not a Protected Veteran",
      "Active Military",
    ],
    mdSize: "2",
  },
];
export const formPatientStatusElements = [
  {
    key: "patient_status",
    label: "Patient Status",
    type: "select",
    options: ["Active", "Inactive"],
    mdSize: "2",
  },
  {
    key: "patient_inactive_reason",
    label: "Inactivity Reason",
    type: "select",
    options: ["Change city/state", "Change country", "Change Practice"],
    mdSize: "2",
  },
  {
    key: "inactive_date",
    label: "Inactive Date",
    type: "date",
    mdSize: "2",
  },
  {
    key: "deceased_reason",
    label: "Deceased Reason",
    type: "select",
    options: ["Change city/state", "Change country", "Change Practice"],
    mdSize: "2",
  },
  {
    key: "deceased_date",
    label: "Deceased Date",
    type: "date",
    mdSize: "2",
  },
  {
    key: "deceased_date_received",
    label: "Date",
    type: "date",
    mdSize: "2",
  },
];
export const formEmergencyElements = [
  {
    key: "first_emergency_contact_name",
    label: "First Emergency contact Full Name",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "first_emergency_contact_con",
    label: "First Emergency Phone",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
    onKeyPress: handleKeyPress,
    onPaste: handleKeyPressPaste,
    maxLength: "10",
  },
  {
    key: "sec_emergency_contact_name",
    label: "Second Emergency Contact Full Name",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
  },
  {
    key: "sec_emergency_contact_con",
    label: "Second Emergency Phone",
    placeHolder: "type here",
    type: "text",
    mdSize: "3",
    onKeyPress: handleKeyPress,
    onPaste: handleKeyPressPaste,
    maxLength: "10",
  },
];
