import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../common/modal/Popup";
// styles
import useStyles from "./styles";
import moment from "moment";
import { toast } from "react-toastify";
import {
  postScheduleMetaData,
  getCompanyStoreData,
} from "../../../services/ServiceLayer";
import {
  currentUser,
  postFlagBooleanVariable,
  getFlagBooleanVariable,
  loadState,
} from "../../../hooks/helper";
export default function ManageTaxRate(props) {
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  //
  toast.configure();

  console.log("props-24", props);
  const { selectclient } = props;
  console.log("selectclient", selectclient);
  const [isShow, setIsShow] = useState(false);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  //
  var classes = useStyles();
  const [company, setCompany] = React.useState("");
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedStore, setSelectedStore] = useState("");
  const [storeList, setStoreList] = useState([]);
  const [isSun, setIsSun] = useState(false);
  const [isMon, setIsMon] = useState(false);
  const [isTue, setIsTue] = useState(false);
  const [isWed, setIsWed] = useState(false);
  const [isThu, setIsThu] = useState(false);
  const [isFri, setIsFri] = useState(false);
  const [isSat, setIsSat] = useState(false);
  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
    setIsActionCalled(false);
    setIsSun(false);
    setIsMon(false);
    setIsTue(false);
    setIsWed(false);
    setIsThu(false);
    setIsFri(false);
    setIsSat(false);
    setSelectedStore("");
    setScheduleMetaDetail({
      comp_id: "",
      store_id: "",
      duration: 0,
      sunday_start_time: "",
      sunday_end_time: "",
      is_sunday_working: "",
      monday_start_time: "",
      monday_end_time: "",
      is_monday_working: "",
      tuesday_start_time: "",
      tuesday_end_time: "",
      is_tuesday_working: "",
      wedday_start_time: "",
      wedday_end_time: "",
      is_wedday_working: "",
      thrusday_start_time: "",
      thrusday_end_time: "",
      is_thrusday_working: "",
      friday_start_time: "",
      friday_end_time: "",
      is_friday_working: "",
      satday_start_time: "",
      satday_end_time: "",
      is_satday_working: "",
    });
  };
  useEffect(() => {
    getCompanyStoreData(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELLOC`
    ).then((response) => {
      console.log(response);
      setCompanyList(response.data);
    });
  }, []);
  const handleCompanySelect = (event) => {
    console.log("event", event);
    const selectedCompanyName = event.target.value;
    setSelectedCompany(event.target.value);
    setSelectedCompany(selectedCompanyName);
    const selectedCompanyData = companyList.find(
      (company) => company.comp_id === selectedCompanyName
    );
    if (selectedCompanyData) {
      console.log("selectedCompanyData", selectedCompanyData);
      console.log("selectedCompanyData", selectedCompanyData?.store_info);
      setStoreList(selectedCompanyData?.store_info);
    } else {
      setStoreList([]);
    }
  };
  useEffect(() => {
    if (selectclient) {
      setSelectedStore(selectclient?.store_id);
      handleCompanySelect({ target: { value: selectclient.comp_id } });
    }
  }, [selectclient]);
  useEffect(() => {
    console.log("selectedCompany", selectedCompany);
    console.log("selectedStore", selectedStore);
  }, [selectedCompany, selectedStore]);

  const [scheduleMetaDetail, setScheduleMetaDetail] = useState({
    schedule_mapping_id: 1,
    comp_id: selectedCompany,
    store_id: selectedStore,
    store_name: "",
    duration: 0,
    sunday_start_time: "",
    sunday_end_time: "",
    is_sunday_working: "",
    monday_start_time: "",
    monday_end_time: "",
    is_monday_working: "",
    tuesday_start_time: "",
    tuesday_end_time: "",
    is_tuesday_working: "",
    wedday_start_time: "",
    wedday_end_time: "",
    is_wedday_working: "",
    thrusday_start_time: "",
    thrusday_end_time: "",
    is_thrusday_working: "",
    friday_start_time: "",
    friday_end_time: "",
    is_friday_working: "",
    satday_start_time: "",
    satday_end_time: "",
    is_satday_working: "",
    is_active: "Y",
    updated_by: currentUser(),
    access_location: "",
    opt_type: "ADDSCH",
  });
  const submitFormData = () => {
    // checkValidator();
    setIsActionCalled(true);
  };
  useEffect(() => {
    console.log("isActionCalled", isActionCalled);
    // console.log("error", error);
    console.log("scheduleMetaDetail", scheduleMetaDetail);
    if (isActionCalled) {
      if (
        scheduleMetaDetail.comp_id !== "" &&
        scheduleMetaDetail.store_id !== "" &&
        scheduleMetaDetail.duration !== 0
      ) {
        postScheduleMetaData(scheduleMetaDetail).then((response) => {
          if (response?.status?.status) {
            toast.success("Metadata added successfully.", {
              position: toast.POSITION.TOP_RIGHT,
            });
            hideHandler();
          } else {
            toast.error("Something wrong please try after some time", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
      } else {
        toast.error(
          "Please select company, location and duration to proceed.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    } else {
      setIsActionCalled(false);
    }
    setIsActionCalled(false);
  }, [isActionCalled]);
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);
    if (selectclient === null) {
      setScheduleMetaDetail({
        schedule_mapping_id: 1,
        comp_id: selectedCompany,
        store_id: selectedStore,
        store_name: "",
        duration: 0,
        sunday_start_time: "",
        sunday_end_time: "",
        is_sunday_working: "N",
        monday_start_time: "",
        monday_end_time: "",
        is_monday_working: "N",
        tuesday_start_time: "",
        tuesday_end_time: "",
        is_tuesday_working: "N",
        wedday_start_time: "",
        wedday_end_time: "",
        is_wedday_working: "N",
        thrusday_start_time: "",
        thrusday_end_time: "",
        is_thrusday_working: "N",
        friday_start_time: "",
        friday_end_time: "",
        is_friday_working: "N",
        satday_start_time: "",
        satday_end_time: "",
        is_satday_working: "N",
        is_active: "Y",
        updated_by: currentUser(),
        access_location: "",
        opt_type: "ADDSCH",
      });
    } else {
      setScheduleMetaDetail({
        schedule_mapping_id: 1,
        comp_id: selectclient?.comp_id,
        store_id: selectclient?.store_id,
        store_name: selectclient?.store_name,
        duration: selectclient?.duration,
        sunday_start_time: selectclient?.sunday_start_time.trim(),
        sunday_end_time: selectclient?.sunday_end_time.trim(),
        is_sunday_working: selectclient?.is_sunday_working,
        monday_start_time: selectclient?.monday_start_time.trim(),
        monday_end_time: selectclient?.monday_end_time.trim(),
        is_monday_working: selectclient?.is_monday_working,
        tuesday_start_time: selectclient?.tuesday_start_time.trim(),
        tuesday_end_time: selectclient?.tuesday_end_time.trim(),
        is_tuesday_working: selectclient?.is_tuesday_working,
        wedday_start_time: selectclient?.wedday_start_time.trim(),
        wedday_end_time: selectclient?.wedday_end_time.trim(),
        is_wedday_working: selectclient?.is_wedday_working,
        thrusday_start_time: selectclient?.thrusday_start_time.trim(),
        thrusday_end_time: selectclient?.thrusday_end_time.trim(),
        is_thrusday_working: selectclient?.is_thrusday_working,
        friday_start_time: selectclient?.friday_start_time.trim(),
        friday_end_time: selectclient?.friday_end_time.trim(),
        is_friday_working: selectclient?.is_friday_working,
        satday_start_time: selectclient?.satday_start_time.trim(),
        satday_end_time: selectclient?.satday_end_time.trim(),
        is_satday_working: selectclient?.is_satday_working,
        is_active: "Y",
        updated_by: currentUser(),
        access_location: "",
        opt_type: "ADDSCH",
      });

      setIsSun(selectclient?.is_sunday_working)
      setIsMon(selectclient?.is_monday_working)
      setIsTue(selectclient?.is_tuesday_working)
      setIsWed(selectclient?.is_wedday_working)
      setIsThu(selectclient?.is_thrusday_working)
      setIsFri(selectclient?.is_friday_working)
      setIsSat(selectclient?.is_satday_working)
    }
  }, [selectclient]);
  const { onHide, ...props1 } = props;
  useEffect(() => {
    setScheduleMetaDetail((prevScheduleMetaDetail) => ({
      ...prevScheduleMetaDetail,
      comp_id: selectedCompany,
      store_id: selectedStore,
    }));
    console.log("selectedCompany", selectedCompany);
    console.log("selectedStore", selectedStore);
  }, [selectedCompany, selectedStore]);

  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={submitFormData}
            >
              Add
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={1}
              className={classes.formBox}
              style={{ marginBottom: "4px" }}
            >
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  Company Name
                </Typography>
                <FormControl
                  fullWidth
                  className={classes.dropdownFormControl}
                  style={{ border: "0px" }}
                >
                  <Select
                    disabled={selectclient}
                    name="comp_id"
                    className={classes.selectDropdown}
                    value={
                      selectedCompany
                        ? selectedCompany
                        : scheduleMetaDetail?.comp_id
                    }
                    onChange={handleCompanySelect}
                    style={{ height: "32px" }}
                  >
                    <MenuItem disabled value="">
                      Select
                    </MenuItem>
                    {companyList.map((company) => (
                      <MenuItem key={company.comp_id} value={company.comp_id}>
                        {company.company_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  Location Name
                </Typography>
                <FormControl fullWidth className={classes.dropdownFormControl}>
                  <Select
                    disabled={selectclient}
                    name="store_id "
                    className={classes.selectDropdown}
                    value={selectedStore}
                    onChange={(event) => {
                      setSelectedStore(event.target.value);
                      console.log("selectedStore", event.target.value);
                    }}
                    style={{ height: "32px" }}
                  >
                    <MenuItem disabled value="">
                      Select
                    </MenuItem>
                    {storeList.map((store) => (
                      <MenuItem key={store.store_id} value={store.store_id}>
                        {store.store_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  Duration
                </Typography>
                <FormControl fullWidth className={classes.dropdownFormControl}>
                  <Select
                    name="duration"
                    className={classes.selectDropdown}
                    value={scheduleMetaDetail?.duration}
                    onChange={(e) => {
                      console.log("selected");
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        duration: e.target.value,
                      });
                    }}
                    style={{ height: "32px" }}
                  >
                    <MenuItem disabled value="">
                      Select
                    </MenuItem>
                    <MenuItem value="15">15 min</MenuItem>
                    <MenuItem value="30">30 min</MenuItem>
                    <MenuItem value="45">45 min</MenuItem>
                    <MenuItem value="60">60 min</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              className={classes.formBox}
              style={{ marginTop: "4px", marginBottom: "4px" }}
            >
              <Grid item xs={12} md={3} className={classes.textGroup}>
                <Typography className={classes.formHeading}>Day</Typography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  Start Time
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  End Time
                </Typography>
              </Grid>
              <Grid item xs={12} md={1} className={classes.textGroup}>
                <Typography className={classes.formHeading}>Closed</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.formBox}>
              <Grid item xs={12} md={3} className={classes.textGroup}>
                <Typography className={classes.formHeading}>Sunday</Typography>
                <Typography className={classes.formHeading}></Typography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <TextField
                  disabled={isSun === "Y"  ? true : false}
                  type="time"
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder=""
                  variant="outlined"
                  inputProps={{
                    // min:  scheduleMetaDetail?.sunday_end_time,
                    max: scheduleMetaDetail?.sunday_end_time,
                    style: {
                      height: "12px",
                      fontSize: "12px",
                    },
                  }}
                  name="sunday_start_time"
                  value={scheduleMetaDetail?.sunday_start_time}
                  onChange={(e) => {
                    const { value } = e.target;

                    if (
                      scheduleMetaDetail.sunday_end_time &&
                      value > scheduleMetaDetail.sunday_end_time
                    ) {
                      // Set end time to be not less than start time
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        sunday_start_time: scheduleMetaDetail.sunday_end_time,
                      });
                    } else {
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        sunday_start_time: value,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <TextField
                  disabled={isSun === "Y"  ? true : false}
                  type="time"
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder=""
                  variant="outlined"
                  inputProps={{
                    // min:scheduleMetaDetail?.sunday_start_time,
                    max: scheduleMetaDetail?.sunday_end_time,
                    style: {
                      height: "12px",
                      fontSize: "12px",
                    },
                  }}
                  name="sunday_end_time"
                  value={scheduleMetaDetail?.sunday_end_time}
                  onChange={(e) => {
                    const { value } = e.target;

                    if (
                      scheduleMetaDetail.sunday_start_time &&
                      value < scheduleMetaDetail.sunday_start_time
                    ) {
                      // Set end time to be not less than start time
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        sunday_end_time: scheduleMetaDetail.sunday_start_time,
                      });
                    } else {
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        sunday_end_time: value,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={1} className={classes.textGroup}>
                <Checkbox
                  name="is_sunday_working"
                  checked={
                    scheduleMetaDetail?.is_sunday_working !== "N"
                      ? scheduleMetaDetail?.is_sunday_working.trim()
                      : false
                  }
                  onChange={(e) => {
                    const value = postFlagBooleanVariable(e.target.checked);
                    setScheduleMetaDetail({
                      ...scheduleMetaDetail,
                      is_sunday_working: value,
                      sunday_start_time: "",
                      sunday_end_time: "",
                    });
                    if (scheduleMetaDetail?.is_sunday_working === "N") {
                      setIsSun("Y");
                    } else {
                      setIsSun("N");
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.formBox}>
              <Grid item xs={12} md={3} className={classes.textGroup}>
                <Typography className={classes.formHeading}>Monday</Typography>
                <Typography className={classes.formHeading}></Typography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <TextField
                  disabled={isMon === "Y"  ? true : false}
                  type="time"
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder=""
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "12px",
                      fontSize: "12px",
                    },
                  }}
                  name="monday_start_time"
                  value={scheduleMetaDetail?.monday_start_time}
                  onChange={(e) => {
                    const { value } = e.target;

                    if (
                      scheduleMetaDetail.monday_end_time &&
                      value > scheduleMetaDetail.monday_end_time
                    ) {
                      // Set end time to be not less than start time
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        monday_start_time: scheduleMetaDetail.monday_end_time,
                      });
                    } else {
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        monday_start_time: value,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <TextField
                  disabled={isMon === "Y" ? true : false}
                  type="time"
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder=""
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "12px",
                      fontSize: "12px",
                    },
                  }}
                  name="monday_end_time"
                  value={scheduleMetaDetail?.monday_end_time}
                  onChange={(e) => {
                    const { value } = e.target;

                    if (
                      scheduleMetaDetail.monday_start_time &&
                      value < scheduleMetaDetail.monday_start_time
                    ) {
                      // Set end time to be not less than start time
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        monday_end_time: scheduleMetaDetail.monday_start_time,
                      });
                    } else {
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        monday_end_time: value,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={1} className={classes.textGroup}>
                <Checkbox
                  name="is_monday_working"
                  checked={
                    scheduleMetaDetail?.is_monday_working !== "N"
                      ? scheduleMetaDetail?.is_monday_working
                      : false
                  }
                  // checked={postFlagBooleanVariable(selectclient?.is_sunday_working)}
                  onChange={(e) => {
                    const value = postFlagBooleanVariable(e.target.checked);
                    setScheduleMetaDetail({
                      ...scheduleMetaDetail,
                      is_monday_working: value,
                      monday_end_time: "",
                      monday_start_time: "",
                    });
                    console.log(
                      "is_monday_working",
                      scheduleMetaDetail?.is_monday_working
                    );
                    if (scheduleMetaDetail?.is_monday_working === "N") {
                      setIsMon("Y");
                    } else {
                      setIsMon("N");
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.formBox}>
              <Grid item xs={12} md={3} className={classes.textGroup}>
                <Typography className={classes.formHeading}>Tuesday</Typography>
                <Typography className={classes.formHeading}></Typography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <TextField
                  disabled={isTue === "Y"  ? true : false}
                  type="time"
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder=""
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "12px",
                      fontSize: "12px",
                    },
                  }}
                  name="tuesday_start_time"
                  value={scheduleMetaDetail?.tuesday_start_time}
                  onChange={(e) => {
                    const { value } = e.target;

                    if (
                      scheduleMetaDetail.tuesday_end_time &&
                      value > scheduleMetaDetail.tuesday_end_time
                    ) {
                      // Set end time to be not less than start time
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        tuesday_start_time: scheduleMetaDetail.tuesday_end_time,
                      });
                    } else {
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        tuesday_start_time: value,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <TextField
                  disabled={isTue === "Y"  ? true : false}
                  type="time"
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder=""
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "12px",
                      fontSize: "12px",
                    },
                  }}
                  name="tuesday_end_time"
                  value={scheduleMetaDetail?.tuesday_end_time}
                  onChange={(e) => {
                    const { value } = e.target;

                    if (
                      scheduleMetaDetail.tuesday_start_time &&
                      value < scheduleMetaDetail.tuesday_start_time
                    ) {
                      // Set end time to be not less than start time
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        tuesday_end_time: scheduleMetaDetail.tuesday_start_time,
                      });
                    } else {
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        tuesday_end_time: value,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={1} className={classes.textGroup}>
                <Checkbox
                  name="is_tuesday_working"
                  checked={
                    scheduleMetaDetail?.is_tuesday_working !== "N"
                      ? scheduleMetaDetail?.is_tuesday_working
                      : false
                  }
                  onChange={(e) => {
                    const value = postFlagBooleanVariable(e.target.checked);
                    setScheduleMetaDetail({
                      ...scheduleMetaDetail,
                      is_tuesday_working: value,
                      tuesday_start_time: "",
                      tuesday_end_time: "",
                    });
                    if (scheduleMetaDetail?.is_tuesday_working === "N") {
                      setIsTue("Y");
                    } else {
                      setIsTue("N");
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.formBox}>
              <Grid item xs={12} md={3} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  Wednesday
                </Typography>
                <Typography className={classes.formHeading}></Typography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <TextField
                  disabled={isWed === "Y"  ? true : false}
                  type="time"
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder=""
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "12px",
                      fontSize: "12px",
                    },
                  }}
                  name="wedday_start_time"
                  value={scheduleMetaDetail?.wedday_start_time}
                  onChange={(e) => {
                    const { value } = e.target;

                    if (
                      scheduleMetaDetail.wedday_end_time &&
                      value > scheduleMetaDetail.wedday_end_time
                    ) {
                      // Set end time to be not less than start time
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        wedday_start_time: scheduleMetaDetail.wedday_end_time,
                      });
                    } else {
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        wedday_start_time: value,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <TextField
                  disabled={isWed === "Y"  ? true : false}
                  type="time"
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder=""
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "12px",
                      fontSize: "12px",
                    },
                  }}
                  name="wedday_end_time"
                  value={scheduleMetaDetail?.wedday_end_time}
                  onChange={(e) => {
                    const { value } = e.target;

                    if (
                      scheduleMetaDetail.wedday_start_time &&
                      value < scheduleMetaDetail.wedday_start_time
                    ) {
                      // Set end time to be not less than start time
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        wedday_end_time: scheduleMetaDetail.wedday_start_time,
                      });
                    } else {
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        wedday_end_time: value,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={1} className={classes.textGroup}>
                <Checkbox
                  name="is_wedday_working"
                  checked={
                    scheduleMetaDetail?.is_wedday_working !== "N"
                      ? scheduleMetaDetail?.is_wedday_working
                      : false
                  }
                  onChange={(e) => {
                    const value = postFlagBooleanVariable(e.target.checked);
                    setScheduleMetaDetail({
                      ...scheduleMetaDetail,
                      is_wedday_working: value,
                      wedday_start_time: "",
                      wedday_end_time: "",
                    });
                    if (scheduleMetaDetail?.is_wedday_working === "N") {
                      setIsWed("Y");
                    } else {
                      setIsWed("N");
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.formBox}>
              <Grid item xs={12} md={3} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  Thursday
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <TextField
                  disabled={isThu === "Y"  ? true : false}
                  type="time"
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder=""
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "12px",
                      fontSize: "12px",
                    },
                  }}
                  name="thrusday_start_time"
                  value={scheduleMetaDetail?.thrusday_start_time}
                  onChange={(e) => {
                    const { value } = e.target;

                    if (
                      scheduleMetaDetail.thrusday_end_time &&
                      value > scheduleMetaDetail.thrusday_end_time
                    ) {
                      // Set end time to be not less than start time
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        thrusday_start_time:
                          scheduleMetaDetail.thrusday_end_time,
                      });
                    } else {
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        thrusday_start_time: value,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <TextField
                  disabled={isThu === "Y"  ? true : false}
                  type="time"
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder=""
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "12px",
                      fontSize: "12px",
                    },
                  }}
                  name="thrusday_end_time"
                  value={scheduleMetaDetail?.thrusday_end_time}
                  onChange={(e) => {
                    const { value } = e.target;

                    if (
                      scheduleMetaDetail.thrusday_start_time &&
                      value < scheduleMetaDetail.thrusday_start_time
                    ) {
                      // Set end time to be not less than start time
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        thrusday_end_time:
                          scheduleMetaDetail.thrusday_start_time,
                      });
                    } else {
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        thrusday_end_time: value,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={1} className={classes.textGroup}>
                <Checkbox
                  name="is_thrusday_working"
                  checked={
                    scheduleMetaDetail?.is_thrusday_working !== "N"
                      ? scheduleMetaDetail?.is_thrusday_working
                      : false
                  }
                  onChange={(e) => {
                    const value = postFlagBooleanVariable(e.target.checked);
                    setScheduleMetaDetail({
                      ...scheduleMetaDetail,
                      is_thrusday_working: value,
                      thrusday_start_time: "",
                      thrusday_end_time: "",
                    });
                    if (scheduleMetaDetail?.is_thrusday_working === "N") {
                      setIsThu("Y");
                    } else {
                      setIsThu("N");
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.formBox}>
              <Grid item xs={12} md={3} className={classes.textGroup}>
                <Typography className={classes.formHeading}>Friday</Typography>
                <Typography className={classes.formHeading}></Typography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <TextField
                  disabled={isFri === "Y"  ? true : false}
                  type="time"
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder=""
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "12px",
                      fontSize: "12px",
                    },
                  }}
                  name="friday_start_time"
                  value={scheduleMetaDetail?.friday_start_time}
                  onChange={(e) => {
                    const { value } = e.target;

                    if (
                      scheduleMetaDetail.friday_end_time &&
                      value > scheduleMetaDetail.friday_end_time
                    ) {
                      // Set end time to be not less than start time
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        friday_start_time: scheduleMetaDetail.friday_end_time,
                      });
                    } else {
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        friday_start_time: value,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <TextField
                  disabled={isFri === "Y"  ? true : false}
                  type="time"
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder=""
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "12px",
                      fontSize: "12px",
                    },
                  }}
                  name="friday_end_time"
                  value={scheduleMetaDetail?.friday_end_time}
                  onChange={(e) => {
                    const { value } = e.target;

                    if (
                      scheduleMetaDetail.friday_start_time &&
                      value < scheduleMetaDetail.friday_start_time
                    ) {
                      // Set end time to be not less than start time
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        friday_end_time: scheduleMetaDetail.friday_start_time,
                      });
                    } else {
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        friday_end_time: value,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={1} className={classes.textGroup}>
                <Checkbox
                  name="is_friday_working"
                  checked={
                    scheduleMetaDetail?.is_friday_working !== "N"
                      ? scheduleMetaDetail?.is_friday_working
                      : false
                  }
                  onChange={(e) => {
                    const value = postFlagBooleanVariable(e.target.checked);
                    setScheduleMetaDetail({
                      ...scheduleMetaDetail,
                      is_friday_working: value,
                      friday_start_time: "",
                      friday_end_time: "",
                    });
                    if (scheduleMetaDetail?.is_friday_working === "N") {
                      setIsFri("Y");
                    } else {
                      setIsFri("N");
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.formBox}>
              <Grid item xs={12} md={3} className={classes.textGroup}>
                <Typography className={classes.formHeading}>
                  Saturday
                </Typography>
                <Typography className={classes.formHeading}></Typography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                <TextField
                  disabled={isSat === "Y"  ? true : false}
                  type="time"
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder=""
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "12px",
                      fontSize: "12px",
                    },
                  }}
                  name="satday_start_time"
                  value={scheduleMetaDetail?.satday_start_time}
                  onChange={(e) => {
                    const { value } = e.target;

                    if (
                      scheduleMetaDetail.satday_end_time &&
                      value > scheduleMetaDetail.satday_end_time
                    ) {
                      // Set end time to be not less than start time
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        satday_start_time: scheduleMetaDetail.satday_end_time,
                      });
                    } else {
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        satday_start_time: value,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} className={classes.textGroup}>
                  <TextField
                  disabled={isSat === "Y"  ? true : false}
                  type="time"
                  size="small"
                  className={classes.txtForm}
                  fullWidth
                  placeholder=""
                  variant="outlined"
                  inputProps={{
                    style: {
                      height: "12px",
                      fontSize: "12px",
                    },
                  }}
                  name="satday_end_time"
                  value={scheduleMetaDetail?.satday_end_time}
                  onChange={(e) => {
                    const { value } = e.target;

                    if (
                      scheduleMetaDetail.satday_start_time &&
                      value < scheduleMetaDetail.satday_start_time
                    ) {
                      // Set end time to be not less than start time
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        satday_end_time: scheduleMetaDetail.satday_start_time,
                      });
                    } else {
                      setScheduleMetaDetail({
                        ...scheduleMetaDetail,
                        satday_end_time: value,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={1} className={classes.textGroup}>
                <Checkbox
                  name="is_satday_working"
                  checked={
                    scheduleMetaDetail?.is_satday_working !== "N"
                      ? scheduleMetaDetail?.is_satday_working
                      : false
                  }
                  onChange={(e) => {
                    const value = postFlagBooleanVariable(e.target.checked);
                    setScheduleMetaDetail({
                      ...scheduleMetaDetail,
                      is_satday_working: value,
                      satday_start_time: "",
                      satday_end_time: "",
                    });
                    if (scheduleMetaDetail?.is_satday_working === "N") {
                      setIsSat("Y");
                    } else {
                      setIsSat("N");
                    }
                  }}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
