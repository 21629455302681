import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  formBox: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  avatar: {
    width: "64px",
    height: "64px",
  },
  noteIcons: {
    position: "absolute",
    right: "10px",
    marginRight: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  formHeading: {
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(0.5),
    color: "#2A2338",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 500,
    textAlign: "left",
    flexGrow: 1,
    paddingLeft: "3px",
    paddingRight: "4px",
  },
  formHeadingValue: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    color: "#2A2338",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 500,
    textAlign: "left",
  },

  txtForm: {
    padding: "0px 4px",
    width: "auto",
    // marginTop: theme.spacing(1),
  },
  dropdownFormControl: {
    // marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    height: "32px", // Set the desired height
    width: "170px", // Set the desired width
  },
  selectDropdown: {
    border: "1px solid #DADFEB",
    padding: "4px 10px",
    borderRadius: "5px",
    marginTop: theme.spacing(0),
    height: "32px",
    background: "#fff",
    "&:before": {
      border: theme.palette.primary.light,
    },
    "&:after": {
      border: theme.palette.primary.main,
    },
    "&:hover:before": {
      border: `${theme.palette.primary.light} !important`,
    },
  },
  textGroup: {
    display: "flex-row",
    justifyContent: "flex-start",
  },
  textInlineGroup: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "10px",
  },
  modelFooter: {
    padding: theme.spacing(2),
  },
  blueButton: {
    background: "#0445AF",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
    marginTop: theme.spacing(2),
    "&:hover": {
      background: "#0078D4",
      color: "#fff",
    },
  },
  gridBox: {
    // display:"flex",
    // justifyItems:"center",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  btnGroup: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  buttonGroup: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  rightButton: {
    marginLeft: theme.spacing(2),
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 500,
    textTransform: "none",
    height: "38px",
    borderRadius: "2px",
  },
  leftBluetButton: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,

    textTransform: "none",
    height: "38px",
    borderRadius: "2px",
    background: "#0445AF",
    color: "#fff",
    "&:hover": {
      background: "#0078D4",
      color: "#fff",
    },
  },
  largeBtn: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 500,
    height: "42px",
    textTransform: "none",
    borderRadius: "2px",
    color: "#fff",
    "&:hover": {
      background: "#038C71",
      color: "#fff",
    },
    "&:focus": {
      background: "#037962",
      color: "#fff",
    },
    "&:active": {
      background: "#037962",
      color: "#fff",
    },
  },
  widgetBox: {
    marginTop: theme.spacing(2),
  },
  passwordBox: {
    display: "flex",
    alignItems: "center",
  },
  Link: {
    color: "#0445AF",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
  AssignHeading: {
    marginBottom: theme.spacing(-2),
    marginTop: theme.spacing(2),
  },
  icons: {
    marginRight: theme.spacing(2),
  },
  footerBox: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
  },
  cancelBtn: {
    marginRight: theme.spacing(2),
    background: "#fff",
  },
  // page title
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
    // marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      display: "inline-block",
    },
  },
  typo: {
    color: "#2A2338",
    fontWeight: 600,
    fontFamily: "Poppins",
    fontSize: "20px !important",
  },
  button: {
    boxShadow: theme.customShadows.widget,
    textTransform: "none",
    "&:active": {
      boxShadow: theme.customShadows.widgetWide,
    },
    height: "32px",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 500,
    borderRadius: "2px",
    padding: "6px, 20px, 6px, 20px",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },
  button1: {
    boxShadow: theme.customShadows.widget,
    textTransform: "none",
    "&:active": {
      boxShadow: theme.customShadows.widgetWide,
    },
    backgroundColor: "#3CCB97",
    color: "#fff",
    marginRight: theme.spacing(2),
    height: "32px",
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: 500,
    borderRadius: "4px",
    padding: "6px, 20px, 6px, 20px",
  },
  typoGray: {
    color: "#ABAFB7",
  },
  icons: {
    // width:'12px',
    // height:'44px'
    // marginTop:theme.spacing(2),
    // paddingTop:theme.spacing(2)
  },
  groupBtn: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "inline-grid",
    },
  },
  search: {
    position: "relative",
    borderRadius: "5px",
    border: "1px solid #0078D4",
    paddingLeft: theme.spacing(1.5),
    width: 48,
    // backgroundColor: fade(theme.palette.common.black, 0),
    transition: theme.transitions.create(["background-color", "width"]),
    "&:hover": {
      cursor: "pointer",
      background: "#fff",
    },
    "&:active": {
      border: "1px solid #0078D4",
    },
    "&:focus": {
      border: "1px solid #0078D4",
    },
    marginRight: theme.spacing(2),
    background: "#fff",
    height: "32px",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  searchFocused: {
    // backgroundColor: fade(theme.palette.common.black, 0.08),
    width: "100%",
    border: "1px solid #0078D4",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
    background: "#fff",
    "&:active": {
      border: "1px solid #0078D4",
    },
    "&:focus": {
      border: "1px solid #0078D4",
    },
  },
  searchIcon: {
    width: 36,
    left: 0,
    color: "#0078D4",
    border: "1px solid #0078D4",
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: theme.transitions.create("right"),
    "&:hover": {
      cursor: "pointer",
    },
    // backgroundColor:'#fff'
  },
  searchIconOpened: {
    right: theme.spacing(1.25),
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
   
  },
  inputInput: {
    // height: 36,
   
    paddingRight: 36 + theme.spacing(1.25),
    width: "100%",
    paddingLeft: "10px",
    fontSize: "14px",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  downloadImg: {
    marginRight: theme.spacing(1),
  },
  editicons: {
    marginRight: theme.spacing(0.5),
  },
  // page title
}));
