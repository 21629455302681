import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  Switch,
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../common/modal/Popup";
// styles
import useStyles from "../styles";

import moment from "moment";
import {
  currentUser,
  handleHeightKeyPress,
  handleKeyPress,
  handleKeyPressPaste,
  loadState,
} from "../../../hooks/helper";
import { toast } from "react-toastify";
import { postVitalData } from "../../../services/ServiceLayer";

export default function ManageVitals(props) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  //
  const formElements = [
    {
      key: "pat_weight_lbs",
      label: "Weight (lbs)",
      placeHolder: "Enter weight",
      type: "text",
      mdSize: "3",
      maxLength: "5",
      onKeyPress: handleKeyPress,
      onPaste: handleKeyPressPaste,
    },
    {
      key: "pat_height",
      label: "Height (feet & Inch)",
      placeHolder: "Enter height",
      type: "text",
      mdSize: "3",
      maxLength: "4",
      onKeyPress: handleKeyPress,
      onPaste: handleKeyPressPaste,
    },
    {
      key: "pat_bp_systolic",
      label: "BP (Systolic) mmHg",
      placeHolder: "Enter bp",
      type: "text",
      mdSize: "3",
      maxLength: "5",
      onKeyPress: handleKeyPress,
      onPaste: handleKeyPressPaste,
    },
    {
      key: "pat_bp_diastolic",
      label: "BP (Diastolic) mmHg",
      placeHolder: "Enter bp",
      type: "text",
      mdSize: "3",
      maxLength: "5",
      onKeyPress: handleKeyPress,
      onPaste: handleKeyPressPaste,
    },
    {
      key: "pat_pulse",
      label: "Pulse (Per Min)",
      placeHolder: "Enter pulse",
      type: "text",
      mdSize: "3",
      maxLength: "5",
      onKeyPress: handleKeyPress,
      onPaste: handleKeyPressPaste,
    },
    {
      key: "pat_temp",
      label: "Temperature (°F)",
      placeHolder: "Enter temperature",
      type: "text",
      mdSize: "3",
      maxLength: "5",
      onKeyPress: handleKeyPress,
      onPaste: handleKeyPressPaste,
    },
    {
      key: "pat_respiration",
      label: "Respiration (Per Min)",
      placeHolder: "Enter respiration",
      type: "text",
      mdSize: "3",
      maxLength: "5",
      onKeyPress: handleKeyPress,
      onPaste: handleKeyPressPaste,
    },
    {
      key: "pat_oxyg_sat",
      label: "Oxygen Saturation (% age)",
      placeHolder: "Enter oxygen",
      type: "text",
      mdSize: "3",
      maxLength: "5",
      onKeyPress: handleKeyPress,
      onPaste: handleKeyPressPaste,
    },
    {
      key: "pat_bmi",
      label: "BMI (lb/in x 703)",
      placeHolder: "Enter respiration",
      type: "text",
      mdSize: "3",
      maxLength: "4",
      onKeyPress: handleKeyPress,
      onPaste: handleKeyPressPaste,
    },
    {
      key: "pat_bmi_status",
      label: "BMI status",
      placeHolder: "select",
      type: "select",
      options: [
        "Anorexia",
        "Underweight",
        "Ideal range",
        "Marginally overweight range",
        "Overweight range",
        "Very overweight or Obese range",
      ],
      mdSize: "3",
    },
  ];
  console.log("props-24", props);
  const { selectclient, patientId } = props;
  console.log("selectclient", selectclient);
  const [isShow, setIsShow] = useState(false);
  //
  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [actionType, setActionType] = useState("ADDVIT");
  const [open, setOpen] = React.useState(false);
  const [isActionCalled, setIsActionCalled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState("");
  const [isBmi, setIsBmi] = useState("");
  const [isBgcolor, setIsBgcolor] = useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [vitalsDetail, setVitalsDetail] = useState({
    pat_vit_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    pat_weight_lbs: "",
    pat_height: "",
    pat_bp_systolic: "",
    pat_bp_diastolic: "",
    pat_pulse: "",
    pat_temp: "",
    pat_respiration: "",
    pat_oxyg_sat: "",
    pat_bmi: "",
    pat_bmi_status: "",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: actionType,
  });
  const clearForm = () => {
    setVitalsDetail({
      pat_vit_id: 0,
      patient_id: patientId,
      location_id: userData?.location_id,
      pat_weight_lbs: "",
      pat_height: "",
      pat_bp_systolic: "",
      pat_bp_diastolic: "",
      pat_pulse: "",
      pat_temp: "",
      pat_respiration: "",
      pat_oxyg_sat: "",
      pat_bmi: "",
      pat_bmi_status: "",
      updated_by: currentUser(),
      access_location: "access_location",
      opt_type: actionType,
    });
  };
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setVitalsDetail({
        pat_vit_id: 0,
        patient_id: patientId,
        location_id: userData?.location_id,
        pat_weight_lbs: "",
        pat_height: "",
        pat_bp_systolic: "",
        pat_bp_diastolic: "",
        pat_pulse: "",
        pat_temp: "",
        pat_respiration: "",
        pat_oxyg_sat: "",
        pat_bmi: "",
        pat_bmi_status: "",
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: actionType,
      });
    } else {
      setVitalsDetail({
        pat_vit_id: selectclient?.pat_vit_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        pat_weight_lbs: selectclient?.pat_weight_lbs,
        pat_height: selectclient?.pat_height,
        pat_bp_systolic: selectclient?.pat_bp_systolic,
        pat_bp_diastolic: selectclient?.pat_bp_diastolic,
        pat_pulse: selectclient?.pat_pulse,
        pat_temp: selectclient?.pat_temp,
        pat_respiration: selectclient?.pat_respiration,
        pat_oxyg_sat: selectclient?.pat_oxyg_sat,
        pat_bmi: selectclient?.pat_bmi,
        pat_bmi_status: selectclient?.pat_bmi_status,
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: "UPDVIT",
      });
    }
  }, [selectclient]);
  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    // if (!isError() && error !== null && isActionCalled) {
    if (isActionCalled) {
      postVitalData(vitalsDetail).then((response) => {
        console.log(response);
        if (response.status.status) {
          toast.success("Vital added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          clearForm();
          props.onHide();
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    // } else {
    //   setIsActionCalled(false);
    // }

    setIsActionCalled(false);
  }, [isActionCalled]);
  useEffect(() => {
    if (vitalsDetail?.pat_weight_lbs && vitalsDetail?.pat_height) {
      const heightInIncCal =
        Number(vitalsDetail?.pat_height) *
        12 *
        (Number(vitalsDetail?.pat_height) * 12);
      const weightInLb = Number(vitalsDetail?.pat_weight_lbs);
      const div = weightInLb / heightInIncCal;

      const val = (div * 703).toFixed(2);
      setIsBmi(val);
    }
  }, [vitalsDetail?.pat_weight_lbs, vitalsDetail?.pat_height]);

  useEffect(() => {
    if (isBmi > 0 && isBmi < 17.5) {
      setVitalsDetail({
        ...vitalsDetail,
        pat_bmi: isBmi,
        pat_bmi_status: "Anorexia",
      });
      setIsBgcolor("#FF6962");
    } else if (isBmi > 17.51 && isBmi < 20.7) {
      setVitalsDetail({
        ...vitalsDetail,
        pat_bmi: isBmi,
        pat_bmi_status: "Underweight",
      });
      setIsBgcolor("#FFE189");
    } else if (isBmi > 20.71 && isBmi < 26.4) {
      setVitalsDetail({
        ...vitalsDetail,
        pat_bmi: isBmi,
        pat_bmi_status: "Ideal range",
      });
      setIsBgcolor("#8BD57C");
    } else if (isBmi > 26.41 && isBmi < 27.8) {
      setVitalsDetail({
        ...vitalsDetail,
        pat_bmi: isBmi,
        pat_bmi_status: "Marginally overweight range",
      });
      setIsBgcolor("#FFB44E");
    } else if (isBmi > 27.81 && isBmi < 31.1) {
      setVitalsDetail({
        ...vitalsDetail,
        pat_bmi: isBmi,
        pat_bmi_status: "Overweight range",
      });
      setIsBgcolor("#FF6962");
    } else if (isBmi > 31.1) {
      setVitalsDetail({
        ...vitalsDetail,
        pat_bmi: isBmi,
        pat_bmi_status: "Very overweight or Obese range",
      });
      setIsBgcolor("#FF6962");
    } else {
      setVitalsDetail({
        ...vitalsDetail,
        pat_bmi: isBmi,
        pat_bmi_status: "",
      });
    }
  }, [isBmi]);

  // Function to validate height value
const validateHeight = (value) => {
  if (value === '') return true;

  const [feet, inches] = value.split('.');
  const decimalPart = parseInt(inches, 10) || 0;

  return parseFloat(value) <= 9.11 && decimalPart <= 11;
};

  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        // fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={handleSubmit}
            >
            {selectclient ? "Update" : 'Add'} 
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={2}
              className={classes.formBox}
              style={{ marginBottom: "4px" }}
            >
              {formElements.map((element, i) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={classes.textGroup}
                    >
                      <Typography
                        style={{ fontSize: "13px" }}
                        className={classes.formHeading}
                      >
                        {element.label}{" "}
                        {element.required === "true" ? (
                          <span className={classes.required}>*</span>
                        ) : null}
                      </Typography>

                      {element.type === "select" ? (
                        <FormControl
                          disabled={element.key === "bmi_status"}
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            style={{
                              backgroundColor: isBgcolor ? isBgcolor : "none",
                              color: isBgcolor ? "white" : "none",
                            }}
                            name={element.key}
                            className={classes.selectDropdown}
                            placeholder={element.placeHolder}
                            value={
                              vitalsDetail && vitalsDetail[element.key]
                                ? vitalsDetail && vitalsDetail[element.key]
                                : ""
                            }
                            onChange={(e) => {
                              console.log("selected", e.target.value);
                              setVitalsDetail({
                                ...vitalsDetail,
                                [element.key]: e.target.value,
                              });
                            }}
                          >
                            <MenuItem value="">Select</MenuItem>
                            {element.options.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : element.type === "text" ? (
                        <TextField
                          disabled={element.key === "bmi"}
                          required
                          name={element.key}
                          placeholder={element.placeHolder}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                            onPaste: element.onPaste,
                            onKeyPress: element.onKeyPress,
                            maxLength: element.maxLength,
                          }}
                          value={
                            vitalsDetail && vitalsDetail[element.key]
                              ? vitalsDetail && vitalsDetail[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected", e.target.value);
                            const value = e.target.value;

                            // Check if the input value is less than or equal to 500
                            if (
                              element.key === "pat_weight_lbs" &&
                              (value === "" || parseInt(value, 10) <= 500)
                            ) {
                              setVitalsDetail({
                                ...vitalsDetail,
                                [element.key]: value,
                              });
                            } else if (
                              element.key === "pat_height" &&
                              validateHeight(value)
                            ) {
                              setVitalsDetail({
                                ...vitalsDetail,
                                [element.key]: value,
                              });
                            } else if (
                              element.key === "pat_bp_systolic" &&
                              (value === "" || parseInt(value, 10) <= 120)
                            ) {
                              setVitalsDetail({
                                ...vitalsDetail,
                                [element.key]: value,
                              });
                            }
                            else if (
                              element.key === "pat_bp_diastolic" &&
                              (value === "" || parseInt(value, 10) <= 120)
                            ) {
                              setVitalsDetail({
                                ...vitalsDetail,
                                [element.key]: value,
                              });
                            }
                            else if (
                              element.key === "pat_pulse" &&
                              (value === "" || parseInt(value, 10) <= 205)
                            ) {
                              setVitalsDetail({
                                ...vitalsDetail,
                                [element.key]: value,
                              });
                            }
                            else if (
                              element.key === "pat_temp" &&
                              (value === "" || parseInt(value, 10) <= 212)
                            ) {
                              setVitalsDetail({
                                ...vitalsDetail,
                                [element.key]: value,
                              });
                            }
                            else if (
                              element.key === "pat_respiration" &&
                              (value === "" || parseInt(value, 10) <= 40)
                            ) {
                              setVitalsDetail({
                                ...vitalsDetail,
                                [element.key]: value,
                              });
                            }
                            else if (
                              element.key === "pat_oxyg_sat" &&
                              (value === "" || parseInt(value, 10) <= 100)
                            ) {
                              setVitalsDetail({
                                ...vitalsDetail,
                                [element.key]: value,
                              });
                            }
                          }}
                        />
                      ) : element.type === "date" ||
                        element.type === "datetime-local" ? (
                        <TextField
                          disabled
                          id={element.key}
                          name={element.key}
                          placeholder={element.placeHolder}
                          type={element.type}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            min: `${currentDateTime}`,
                            max: `${currentDate}`,
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onKeyDown={(e) => {
                            e.preventDefault(); // Prevent manual typing
                          }}
                          value={
                            vitalsDetail && vitalsDetail[element.key]
                              ? vitalsDetail && vitalsDetail[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected", e.target.value);
                            setVitalsDetail({
                              ...vitalsDetail,
                              [element.key]: e.target.value,
                            });
                          }}
                        />
                      ) : element.type === "textarea" ? (
                        <TextField
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          placeholder="Type here..."
                          variant="outlined"
                          multiline
                          rows={3}
                          inputProps={{
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          value={
                            vitalsDetail && vitalsDetail[element.key]
                              ? vitalsDetail && vitalsDetail[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected", e.target.value);
                            setVitalsDetail({
                              ...vitalsDetail,
                              [element.key]: e.target.value,
                            });
                          }}
                        />
                      ) : element.type === "button" ? (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                          style={{
                            width: "100%",
                            fontSize: "10px",
                            marginTop: "26px",
                          }}
                        >
                          {element.btnName}
                        </Button>
                      ) : (
                        <Switch />
                      )}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
