import React, { useState, useEffect } from "react";
import { Grid, InputBase, Typography } from "@material-ui/core";
import { TableRow, TableCell } from "@material-ui/core";
import TableDesign from "../../../common/table/TableDesign";

// data
import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";

import "react-toastify/dist/ReactToastify.css";

import { DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../common/modal/Popup";
// styles
import useStyles from "../styles";
import { getInvtSpectacle } from "../../../../services/ServiceLayer";

import { loadState } from "../../../../hooks/helper";

import TableWidget from "../../../Widget/TableWidget";
import classNames from "classnames";
export default function ExsitingServiceOrder(props) {
  const [maxWidth, setMaxWidth] = React.useState("md");
  var classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const [isLoader, setIsLoader] = useState(false);
  const [specticleListData, setSpecticleListData] = useState([]);
  var [isSearchOpen, setSearchOpen] = useState(true);

  const openDown = Boolean(anchorEl);
  const [selectedRow, setSelectedRow] = useState(null);

  const [dataUpdated, setDataUpdated] = useState(false);

  const [inventoryReadValue, setInventoryReadValue] = useState();

  const readAccessData = loadState().menu;
  useEffect(() => {
    console.log("readAccessData", readAccessData);
    const inventorySection = readAccessData.find(
      (item) => item.label === "Inventory"
    );
    if (inventorySection) {
      const inventoryChildren = inventorySection.children;

      const inventoryReadAcess = inventoryChildren.find(
        (child) => child.label === "Inventory"
      ).read_access;
      setInventoryReadValue(inventoryReadAcess);
    }
  }, [readAccessData]);

  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  useEffect(() => {
    getInvtSpectacle(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSPC`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setIsLoader(false);
        setSpecticleListData(response?.data);
      } else {
        setIsLoader(false);
      }
    });
    setDataUpdated(false);
  }, [dataUpdated]);
  useEffect(() => {
    getInvtSpectacle(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELSPC`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        setIsLoader(false);
        setSpecticleListData(response?.data);
      } else {
        setIsLoader(false);
      }
    });
  }, []);
  // const open = Boolean(anchorEl);

  const tableHeadings = [
    {
      label: "#",
    },
    {
      label: "Lens Name ",
    },
    {
      label: "SKU",
    },

    {
      label: "Material ",
    },
    {
      label: "Retail Price",
    },

    {
      label: "Status",
    },
    {
      label: "Action",
    },
  ];
  const handleRowClick = (row) => {
    setSelectedRow(row);
  };

  //searching
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const filteredDataList = specticleListData.filter((item) =>
    item.spec_lens_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const hideHandler = () => {
    onHide();
  };
  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={<></>}
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <div className={classes.pageTitleContainer}>
              <Typography className={classes.typo} weight="medium">
                <div
                  className={classNames(classes.search, {
                    [classes.searchFocused]: isSearchOpen,
                  })}
                >
                  <div
                    className={classNames(classes.searchIcon, {
                      [classes.searchIconOpened]: isSearchOpen,
                    })}
                    onClick={() => setSearchOpen(!isSearchOpen)}
                  >
                    <SearchIcon classes={{ root: classes.headerIcon }} />
                  </div>
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    onChange={handleChange}
                  />
                </div>
              </Typography>
            </div>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TableWidget noBodyPadding>
                  <TableDesign
                    {...{
                      title: "List of All Clients",
                      tableHeadings,
                    }}
                  >
                    {filteredDataList && filteredDataList.length ? (
                      filteredDataList.map((row, i) => {
                        if (row)
                          return (
                            <TableRow
                              onClick={() => handleRowClick(row)}
                              key={i}
                              className={classes.tableBodyCell}
                            >
                              <TableCell className={classes.tableBodyCell}>
                                {i + 1}
                              </TableCell>

                              <TableCell
                                align="left"
                                className={classes.tableBodyCell}
                                style={{ cursor: "pointer" }}
                              >
                                {row.spec_lens_name}
                              </TableCell>
                              <TableCell className={classes.tableBodyCell}>
                                {row.spec_sku}
                              </TableCell>

                              <TableCell className={classes.tableBodyCell}>
                                {row.material_val}
                              </TableCell>

                              <TableCell className={classes.tableBodyCell}>
                                {row.retail_price}
                              </TableCell>

                              <TableCell className={classes.tableBodyCell}>
                                {row.is_active === "Y" ? (
                                  <span style={{ color: "green" }}>Active</span>
                                ) : (
                                  <span style={{ color: "red" }}>Inactive</span>
                                )}
                              </TableCell>
                              <TableCell className={classes.tableBodyCell}>
                                <span
                                  onClick={() => handleRowClick(row)}
                                  style={{
                                    cursor: "pointer",
                                    border: "1px solid #5DB3D9",
                                    borderRadius: "25px",
                                    padding: "6px 10px",
                                    color: "#5db3d9",
                                  }}
                                >
                                  Select
                                </span>
                              </TableCell>
                            </TableRow>
                          );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan="8">No Result Found</TableCell>
                      </TableRow>
                    )}
                  </TableDesign>
                </TableWidget>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
