import React, { useState, useEffect } from "react";

import {
  Typography,
  Button,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Popup from "../../common/modal/Popup";
import alertIcon from "./alertIcon.svg";
// styles
import useStyles from "./styles";
import { toast } from "react-toastify";
import moment from "moment";
import { postDoctorData } from "../../../services/ServiceLayer";
import { currentUser, postFlagBooleanVariable } from "../../../hooks/helper";
export default function DeletePopup({
  onHide,
  deleteModalShow,
  setDeleteModalShow,
  selectclient,
}) {
  const [open, setOpen] = React.useState(false);
  var classes = useStyles();
  const [actionType, setActionType] = useState("DELSTF");

  const [isActionCalled, setIsActionCalled] = useState(false);
  toast.configure();
  const hideHandler = () => {
    console.log("hide handler called");
    //  props.onHide();
    setDeleteModalShow(false);
    setOpen(false);
  };
  const [doctorDetail, setDoctorDetail] = useState({
    staff_id: 0,
    // user_group: "",
    location_id: 0,
    first_name: "",
    middle_name: "",
    last_name: "",
    joining_date: "",
    ssn: 0,
    dl: "",
    departmnet_id: "",
    hospital_type_id: "",
    employee_type: "",
    specialist: "",
    medical_reg_no: "",
    date_of_birth: "",
    gender: "",
    pref_language: "",
    npi_no: "",
    user_name: "xxxxxx",
    email: "",
    contact_cell: "",
    contact_home: "",
    contact_office: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    zip: 0,
    country: "",
    work_monday: "N",
    work_tuesday: "N",
    work_wednesday: "N",
    work_thursday: "N",
    work_friday: "N",
    work_saturday: "N",
    work_sunday: "N",
    start_time: "",
    end_time: "",
    access_location: "",
    is_active: "Y",
    updated_by: currentUser(),
    opt_type: "ADDSTF",
  });
  useEffect(() => {
    console.log("selectclient useEffect", selectclient);

    if (selectclient === null) {
      setDoctorDetail({
        staff_id: 0,
        location_id: "",
        // user_group: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        joining_date: "",
        ssn: 0,
        dl: "",
        departmnet_id: "",
        hospital_type_id: "",
        employee_type: "",
        specialist: "",
        medical_reg_no: "",
        date_of_birth: "",
        gender: "",
        pref_language: "",
        npi_no: "",
        user_name: "xxxxxx",
        email: "",
        contact_cell: "",
        contact_home: "",
        contact_office: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        zip: 0,
        country: "",
        work_monday: "N",
        work_tuesday: "N",
        work_wednesday: "N",
        work_thursday: "N",
        work_friday: "N",
        work_saturday: "N",
        work_sunday: "N",
        start_time: "",
        end_time: "",
        access_location: "",
        is_active: "Y",
        updated_by: currentUser(),
        opt_type: "DELSTF",
      });
    } else {
      // const map1 = selectclient?.locations?.map((x) => x._id);
      // const map2 = selectclient?.wardTypes?.map((x) => x);
      setDoctorDetail({
        staff_id: selectclient?.staff_id,
        location_id: selectclient?.location_id,
        first_name: selectclient?.first_name,
        // user_group: selectclient?.staff_id,
        middle_name: selectclient?.middle_name,
        last_name: selectclient?.last_name,
        joining_date: moment(selectclient?.joining_date).format("YYYY-MM-DD"),
        ssn: selectclient?.ssn,
        dl: selectclient?.dl,
        departmnet_id: selectclient?.department_id,
        hospital_type_id: selectclient?.hospital_type_id,
        employee_type: selectclient?.employee_type,
        specialist: selectclient?.specialist,
        medical_reg_no: selectclient?.medical_reg_no,
        date_of_birth: moment(selectclient?.date_of_birth).format("YYYY-MM-DD"),
        gender: selectclient?.gender,
        pref_language: selectclient?.pref_language,
        npi_no: selectclient?.npi_no,
        user_name: selectclient?.user_name,
        email: selectclient?.email,
        contact_cell: selectclient?.contact_cell,
        contact_home: selectclient?.contact_home,
        contact_office: selectclient?.contact_office,
        address_line1: selectclient?.address_line1,
        address_line2: selectclient?.address_line2,
        city: selectclient?.city,
        state: selectclient?.state,
        zip: selectclient?.zip,
        country: selectclient?.country,
        work_monday: selectclient?.work_monday.trim(),
        work_tuesday: selectclient?.work_tuesday.trim(),
        work_wednesday: selectclient?.work_wednesday.trim(),
        work_thursday: selectclient?.work_thursday.trim(),
        work_friday: selectclient?.work_friday.trim(),
        work_saturday: selectclient?.work_saturday.trim(),
        work_sunday: selectclient?.work_sunday.trim(),
        start_time: selectclient?.start_time,
        end_time: selectclient?.end_time,
        access_location: selectclient?.access_location,
        is_active: "Y",
        updated_by: "shahsnak",
        opt_type: actionType,
      });
    }
  }, [selectclient]);

  const deleteFormData = () => {
    setIsActionCalled(true);
    setDoctorDetail({
      ...doctorDetail,
      opt_type: "DELSTF",
    });
  };

  useEffect(() => {
    console.log("isActionCalled", isActionCalled);

    if (isActionCalled) {
      postDoctorData(doctorDetail).then((response) => {
        console.log("actionType", actionType);

        console.log(response);
        if (response.status.status) {
          toast.success("Staff deleted successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });

          onHide();
          setDeleteModalShow(false);
          setOpen(false);
          setIsActionCalled(false);
        } else {
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsActionCalled(false);
        }
      });
      setIsActionCalled(false);
    } else {
      setIsActionCalled(false);
    }
  }, [isActionCalled]);

  return (
    <>
      <Popup
        onHide={() => onHide()}
        open={deleteModalShow}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              No
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={deleteFormData}
            >
              Yes
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formBox}>
            <img src={alertIcon} />
            <Typography className={classes.formActiveHeading}>
              Are you sure! You want to Delete this staff?
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
