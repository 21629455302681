import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  Typography,
  Link,
  InputBase,
  TextField,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

// components
import Widget from "../../../components/Widget/Widget";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { getCompanyLocationByCompId } from "../../../services/ServiceLayer";
import { dateFormatConversion, loadState } from "../../../hooks/helper";
import useStyles from "../../../components/Patient/Detail/styles";
import TableWidget from "../../../components/Widget/TableWidget";
import TableDesign from "../../../components/common/table/TableDesign";
import DeleteLocationPop from "../../../components/Settings/Company/DeleteLocationPopup";
// import { invtOrderListElements } from "./order_constant";
import { toast } from "react-toastify";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import * as Icons from "@material-ui/icons";
import classNames from "classnames";
import {
  MoreVert as MoreIcon,
  Search as SearchIcon,
  Lens,
} from "@material-ui/icons";
import ReactToPrint from "react-to-print";
import { CSVLink } from "react-csv";
import ManageOrder from "./ManageOrder";

import {
  getMetaDataList,
} from "../../../services/ServiceLayer";

export default function InvtOrderHistory() {
  const history = useHistory();
  const userLocation = loadState().user?.location_id;
  const userRole = loadState().user?.user_role;
  var classes = useStyles();
  var [isSearchOpen, setSearchOpen] = useState(true);
  const [selectMetaDataList, setSelectMetaDataList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const userData = loadState().user;

  // table Header
  const tableHeadings = [
    {
      label: "seq_id",
      align: "left !important",
    },
    {
      label: "grp_meta_key",
      align: "left !important",
    },
    {
      label: "grp_meta_name",
      align: "left !important",
    },

    {
      label: "grpsub_meta_key",
      align: "left !important",
    },
    {
      label: "grpsub_meta_name",
      align: "left !important",
    },
    {
      label: "grp_attribute_key",
      align: "left !important",
    },
    {
      label: "grp_attribute_val",
      align: "left !important",
    },
    {
      label: "meta_sort_order Date",
      align: "left !important",
    },
    {
      label: "is_active",
      align: "left !important",
    },
    {
      label: "Action",
      align: "left !important",
    },
  ];

  const [dataUpdated, setDataUpdated] = useState(false);
  //

  const [open, setOpen] = React.useState(false);

  toast.configure();

  useEffect(() => {
    getMetaDataList(
      `location_id=${userData?.location_id}&user_role=${userRole}&opt_type=SELMET`
    ).then((response) => {
      if (response?.status?.status) {
        const data = response?.data;
        // setResources(resource);
        setSelectMetaDataList(data.ORDTYP);
        // setIsLoader(false);
      } else {
        toast.error("Something went wrong Please try again letter", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  }, []);
  
  const addOrder = () => {
    // history.push(`/app/admin-details`);
    setOpen(true);
  };
  const hideHandle = () => {
    setOpen(false);
  };
  return (
    <>
      <ManageOrder open={open} onHide={hideHandle} title={"Select Patient"} />
      <div className={classes.pageTitleContainer}>
        <Typography className={classes.typo} weight="medium">
          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen
            })}
          >
            <div
              className={classNames(classes.searchIcon, {
                [classes.searchIconOpened]: isSearchOpen,
              })}
              onClick={() => setSearchOpen(!isSearchOpen)}
            >
              <SearchIcon classes={{ root: classes.headerIcon }} />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
          </div>
        </Typography>

      </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TableWidget noBodyPadding>
            <TableDesign
              {...{
                title: "List of All Clients",
                tableHeadings,
              }}
            >
              {selectMetaDataList && selectMetaDataList.length ? (
                selectMetaDataList.map((row, i) => {
                  if (row)
                    return (
                      <TableRow key={i} className={classes.tableBodyCell}>
                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                        >
                          {row.seq_id}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.grp_meta_key}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.grp_meta_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.tableBodyCell}
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                        >
                          {row.grpsub_meta_key}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.grpsub_meta_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.grp_attribute_key}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.grp_attribute_val}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.meta_sort_order}
                        </TableCell>
                       
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        >
                          {row.payment_status === "Pending" ? (
                            <>
                              <span
                                style={{
                                  background: "orange",
                                  borderRadius: "5px",
                                  padding: "4px 10px",
                                  color: "white",
                                }}
                              >
                                {row.payment_status}
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                style={{
                                  background: "green",
                                  borderRadius: "5px",
                                  padding: "4px 10px",
                                  color: "white",
                                }}
                              >
                                {row.payment_status}
                              </span>
                            </>
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            paddingLeft: "12px",
                            fontSize: "12px",
                          }}
                          className={classes.tableBodyCell}
                        ></TableCell>
                      </TableRow>
                    );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="8">No Result Found</TableCell>
                </TableRow>
              )}
            </TableDesign>
          </TableWidget>
        </Grid>
      </Grid>
    </>
  );
}
