import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useParams,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/Dashboard/dashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";

import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";

// context
import { useLayoutState } from "../../context/LayoutContext";

import Profile from "../../pages/profile/Profile";

import ChangePassword from "../../pages/profile/ChangePassword";

import Schedule from "../../pages/Schedule/schedule";
import Patient from "../../pages/patient/Patient";
import PatientDetails from "../../pages/patient/PatientDetails";
import PatientEdit from "../../pages/patient/PatientEdit";
import AddPatient from "../../pages/patient/Cases/PatientSteps";
import CompanyRegistration from "../../pages/settingsPage/CompanyRegistration";
import DoctorRegistration from "../../pages/settingsPage/DoctorRegistration";
import DoctorRegistrationForm from "../../pages/settingsPage/DoctorRegistrationForm";
import MenuAccess from "../../pages/settingsPage/MenuAccess";
import TaxRate from "../../pages/settingsPage/TaxRate";
import CustomData from "../../pages/settingsPage/CustomData";
import ScheduleDataList from "../Settings/ScheduleMeta/ScheduleDataList";
import HistoryConfig from "../Settings/History/HistoryConfig";
import Inventory from "../../pages/inventory/Inventory";
import ViewInventory from "../../pages/inventory/ViewInventory";
import FrameUploadCsv from "../../pages/inventory/FrameUploadCsv";
import ViewGlass from "../../pages/inventory/ViewGlass";
import Receiving from "../../pages/inventory/Receiving";
import CompnayDetails from "../../pages/settingsPage/CompnayDetails";
import TaxEdit from "../../pages/settingsPage/TaxEdit";
import MenuAccessEdit from "../../pages/settingsPage/MenuAccessEdit";

import NewFrame from "../../pages/inventory/newFrame";
import FrameDetails from "../../pages/inventory/frameDetails";
import NewService from "../../pages/inventory/newService";
import ServiceDetails from "../../pages/inventory/serviceDetails";
import NewSpectacle from "../../pages/inventory/newSpectacle";
import SpectacleDetails from "../../pages/inventory/spectacleDetails";
import NewContact from "../../pages/inventory/newContact";
import ContactDetails from "../../pages/inventory/contactDetails";
import newRecall from "../../pages/inventory/newRecall";
import Order from "../../pages/inventory/Order";

import OrderGroupData from "../Inventory/Order/SingleOrder/MainOrder";
import InvtOrderHistory from "../Inventory/Order/InvtOrderHistory";
import newOthers from "../../pages/inventory/newOthers";
import serviceType from "../../pages/inventory/serviceType";
import MetaData from "../../pages/metaData/MetaData";
import AddMetaData from "../../pages/metaData/AddMetaData";

import NewExam from "../../pages/exam/NewExam";
import ExamList from "../../pages/exam/ExamList";
import { loadState } from "../../hooks/helper";
function Layout(props) {
  var classes = useStyles();
  const [patientReadValue, setPatientReadValue] = useState();
  // global
  var layoutState = useLayoutState();
  const { activeTabVal, patient_id } = useParams();

  const isTabValPresent = !!activeTabVal;
  const readAccessData = loadState().menu;

  useEffect(() => {
    console.log("readAccessData-layout", readAccessData);
    if (readAccessData) {
      const patientsSection = readAccessData.find(
        (item) => item.label === "Patients"
      );

      if (patientsSection) {
        const patientChildren = patientsSection.children;
        const patientListReadAccess = patientChildren.find(
          (child) => child.label === "Patient List"
        ).menu_access;
        const addPatientReadAccess = patientChildren.find(
          (child) => child.label === "ADD New Patient"
        ).menu_access;
        console.log("Patient List Read Access:", patientListReadAccess);
        console.log("ADD New Patient Read Access:", addPatientReadAccess);
        setPatientReadValue(addPatientReadAccess);
      }
    }
  }, [readAccessData]);

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} patientId={patient_id} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/app/dashboard" component={Dashboard} />
            <Route path="/app/schedule" component={Schedule} />
            <Route path="/app/patient" component={Patient} />
            <Route
              path="/app/patient-details/:patient_id"
              component={PatientDetails}
            />

            {/* {patientReadValue && patientReadValue === "Y" && ( */}

            <Route
              path="/app/edit-patient/:patient_id"
              render={(props) => (
                <PatientEdit {...props} patientReadValue={patientReadValue} />
              )}
            // component={PatientEdit}
            />
            <Route
              path="/app/add-patient"
              render={(props) => (
                <AddPatient {...props} patientReadValue={patientReadValue} />
              )}
            />

            {/* )} */}
            {/* <Route path="/app/add-patient" render={(props) => <AddPatient {...props} patientReadValue={patientReadValue} />}  /> */}

            <Route path="/app/companys" component={CompanyRegistration} />
            <Route
              path="/app/company-details/:comp_id"
              component={CompnayDetails}
            />
            <Route path="/app/doctors" component={DoctorRegistration} />

            <Route path="/app/add-doctors" component={DoctorRegistrationForm} />
            {/* <Route path="/app/staff-details" component={DoctorRegistrationForm} /> */}
            <Route path="/app/menu" component={MenuAccess} />
            <Route
              path="/app/menu-edit/:user_id/:user_role/:menu_name/:employee_name"
              component={MenuAccessEdit}
            />
            <Route path="/app/history-config" component={HistoryConfig} />
            <Route path="/app/tax-rate" component={TaxRate} />
            <Route
              path="/app/tax-edit/:comp_id/:store_id"
              component={TaxEdit}
            />
            <Route path="/app/custom-data" component={CustomData} />
            <Route path="/app/schedule-data" component={ScheduleDataList} />
            <Route path="/app/typography" component={Typography} />
            <Route path="/app/tables" component={Tables} />
            <Route path="/app/notifications" component={Notifications} />
            <Route path="/app/meta-data" component={MetaData} />
            <Route path="/app/add-metadata/" component={AddMetaData} />
            <Route
              path="/app/add-metadata/:grp_meta_name"
              component={AddMetaData}
            />
            <Route path={`/app/inventory/`} component={Inventory} />
            <Route path="/app/Exam" component={ExamList} />
            <Route path="/app/add-Exam/:patient_id" component={NewExam} />
            {isTabValPresent && (
              <Route
                path={`/app/inventory/:activeTabVal`}
                component={Inventory}
              />
            )}
            <Route path="/app/Orders-List" component={InvtOrderHistory} />
            <Route path="/app/New-Orders" component={Order} />
            {/* <Route path="/app/order-details/:patient_id" component={OrderGroup} /> */}
            <Route
              path="/app/order-details/:patient_id"
              component={OrderGroupData}
            />
            <Route path="/app/manage-other" component={newOthers} />
            <Route path="/app/service" component={serviceType} />
            <Route path="/app/manage-service" component={NewService} />
            <Route
              path="/app/service-details/:service_id"
              component={ServiceDetails}
            />
            <Route path="/app/manage-frame" component={NewFrame} />
            <Route
              path="/app/frame-details/:frame_id"
              component={FrameDetails}
            />
            <Route path="/app/manage-spectacle-lens" component={NewSpectacle} />
            <Route path="/app/manage-recall" component={newRecall} />
            <Route
              path="/app/spectacle-details/:spec_id"
              component={SpectacleDetails}
            />
            <Route path="/app/manage-contact-lens" component={NewContact} />
            <Route
              path="/app/contact-details/:contlens_id"
              component={ContactDetails}
            />
            <Route path="/app/view-inventory" component={ViewInventory} />
            <Route path="/app/frame-modify" component={FrameUploadCsv} />
            <Route path="/app/view-glass" component={ViewGlass} />
            <Route path="/app/inventory-receiving" component={Receiving} />

            <Route path="/app/profile" component={Profile} />
            <Route
              path="/app/change-password/:user_id/:userName"
              component={ChangePassword}
            />

            <Route
              exact
              path="/app/ui"
              render={() => <Redirect to="/app/ui/icons" />}
            />
            <Route path="/app/ui/maps" component={Maps} />
            <Route path="/app/ui/icons" component={Icons} />
            <Route path="/app/ui/charts" component={Charts} />
            <Route>
              <Redirect to="/app/dashboard" />
            </Route>
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
