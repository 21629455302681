import React, { useEffect, useState } from "react";

import {
  Grid,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Select,
  FormControl,
  FormGroup,
  Checkbox,
  Button,
  IconButton,
  Menu,
} from "@material-ui/core";
// data
import { MoreVert as MoreIcon, Search as SearchIcon } from "@material-ui/icons";

// Model
import { dialationForm } from "./External_Constant";
import useStyles from "../styles";
// components

import moment from "moment";
import { currentUser, loadState, postFlagBooleanVariable } from "../../../hooks/helper";
import { toast } from "react-toastify";
import { getPatientExternalDil, postExternalDialation } from "../../../services/ServiceLayer";
export default function Dialation({
  examExternalCounsel,
  examExternalDilation,
  patientId,
  doctorList,setDialationBtnUnable,dialationBtnUnable
}) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  var classes = useStyles();
  const userData = loadState().user;
  const userRole = loadState().user.user_role;
  const [isActionCalled, setIsActionCalled] = useState(false);
  console.log("userData", userData);
  const [dialationDetails, setDialationDetails] = useState({
    pat_extdil_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    dialation_med_od: "",
    dialation_counsel_od: "",
    dialation_staff_od: userData?.user_id,
    dialation_notes_od: "",
    dialation_dt_od: "12-12-9999",
    dialation_med_os: "",
    dialation_counsel_os: "",
    dialation_staff_os: userData?.user_id,
    dialation_notes_os: "",
    dialation_dt_os: "12-12-9999",
    dialation_flag:'Y',
    dialation_notes:'',
    updated_by: "Y",
    access_location: "Y",
    opt_type: "ADDEXT",
  });
  const handleSubmit = () => {
    // checkValidator(); // Check for validation errors
    setIsActionCalled(true);
  };
  useEffect(() => {
    // if (!isError() && error !== null && isActionCalled) {
    if (isActionCalled) {
      postExternalDialation(dialationDetails).then((response) => {
        console.log(response);
        if (response?.status?.status) {
          toast.success("Dialation added successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setDialationBtnUnable(false);
          // props.onHide();
        } else {
          toast.error(response?.status?.description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
    

    setIsActionCalled(false);
  }, [isActionCalled]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDown = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCheckboxChange = (copy, paste, e) => {
    console.log("copy", copy);
    console.log("copy to", paste);
    setAnchorEl(null);
    setDialationBtnUnable(true);
    setDialationDetails({
      ...dialationDetails,
      [`dialation_med_${paste}`]: dialationDetails?.[`dialation_med_${copy}`],
      [`dialation_counsel_${paste}`]: dialationDetails?.[`dialation_counsel_${copy}`],
      [`dialation_staff_${paste}`]: dialationDetails?.[`dialation_staff_${copy}`],
      [`dialation_notes_${paste}`]: dialationDetails?.[`dialation_notes_${copy}`],
      [`dialation_dt_${paste}`]: dialationDetails?.[`dialation_dt_${copy}`],
     
    });
  };
  const [showDesc, setShowDesc] = useState(false);
  const[showDiscard, setShowDiscard] = useState(false);
  const [dilData, setDilData] = useState([]);
  useEffect(() => {
    getPatientExternalDil(
      `location_id=${userData?.location_id}&user_role=${userRole}&patient_id=${patientId}&opt_type=SELDIL`
    ).then((response) => {
      console.log("response", response);
      if (response?.data) {
        // setIsLoader(false);
        setDilData(response?.data);
      } else {
        // setIsLoader(false);
      }
    });
  }, []);
  
  const [discardUpdate, setDiscardUpdate] = useState(false);
  useEffect(() => {
    if (dilData && dilData.length > 0) {
      setDialationDetails({
        pat_extdil_id: dilData[0]?.pat_ext_id,
        patient_id: patientId,
        location_id: userData?.location_id,
        dialation_med_od: dilData[0]?.dialation_med_od,
        dialation_counsel_od: dilData[0]?.dialation_counsel_od,
        dialation_staff_od: dilData[0]?.dialation_staff_od,
        dialation_notes_od: dilData[0]?.dialation_notes_od,
        dialation_dt_od: dilData[0]?.dialation_dt_od,
        dialation_med_os: dilData[0]?.dialation_med_os,
        dialation_counsel_os: dilData[0]?.dialation_counsel_os,
        dialation_staff_os: dilData[0]?.dialation_staff_os,
        dialation_notes_os: dilData[0]?.dialation_notes_os,
        dialation_dt_os:dilData[0]?.dialation_dt_os,
        dialation_flag:dilData[0]?.is_dialation_flag,
        dialation_notes:dilData[0]?.dialation_notes,
        updated_by: currentUser(),
        access_location: 'access_location',
        opt_type: "UPDEXT",
      });
      setDiscardUpdate(false);
    }
  }, [dilData,discardUpdate]);
  const clearForm=()=>{
    setDialationDetails({
      pat_extdil_id: 0,
    patient_id: patientId,
    location_id: userData?.location_id,
    dialation_med_od: "",
    dialation_counsel_od: "",
    dialation_staff_od: userData?.user_id,
    dialation_notes_od: "",
    dialation_dt_od: "12-12-9999",
    dialation_med_os: "",
    dialation_counsel_os: "",
    dialation_staff_os: userData?.user_id,
    dialation_notes_os: "",
    dialation_dt_os: "12-12-9999",
    dialation_flag:'',
    dialation_notes:'',
    updated_by: "Y",
    access_location: "Y",
    opt_type: "ADDEXT",
    })
    setDialationBtnUnable(false);
    setShowDiscard(false);
  }
  const [sortedDilationItems, setSortedDilationItems] = useState([]);
  const [sortedCounselItems, setSortedCounselItems] = useState([]);
  const [sortedDoctorItems, setSortedDoctorItems] = useState([]);

  // Define the sorting function outside of useEffect so it can be reused
  const sortItems = (items, valueKey) => {
    return items.sort((a, b) => {
      const isNumA = /^\d/.test(a[valueKey]);
      const isNumB = /^\d/.test(b[valueKey]);
      if (isNumA && !isNumB) return -1;
      if (!isNumA && isNumB) return 1;
      return a[valueKey].localeCompare(b[valueKey]);
    });
  };

  // Use Effect to sort items when they are available or updated
  useEffect(() => {
    if (examExternalDilation) {
      setSortedDilationItems(sortItems([...examExternalDilation], 'attribute_val'));
    }
    if (examExternalCounsel) {
      setSortedCounselItems(sortItems([...examExternalCounsel], 'attribute_val'));
    }
    if (doctorList) {
      setSortedDoctorItems(sortItems([...doctorList], 'first_name'));
    }
  }, [examExternalDilation, examExternalCounsel, doctorList]);

  return (
    <>
      {/* <TableWidget noBodyPadding> */}
      <Grid
        container
        spacing={2}
        className={classes.formBox}
        style={{ border: "1px solid #DED9D9" }}
      >
        <Grid
          item
          xs={12}
          md={12}
          className={classes.textGroup}
          style={{ padding: "0px", display: "flex", justifyContent: "end" }}
        >
          <IconButton
            id="basic-button"
            aria-controls={openDown ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openDown ? "true" : undefined}
            onClick={(e) => {
              handleClick(e);
            }}
          >
            <MoreIcon />
          </IconButton>

          <Menu
            style={{ fontSize: "12px" }}
            className={classes.menuBox}
            id="basic-menu"
            anchorEl={anchorEl}
            open={openDown}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              style={{ fontSize: "12px" }}
              onClick={() => handleCheckboxChange("od", "os")}
            >
              Copy OD to OS
            </MenuItem>

            <MenuItem
              style={{ fontSize: "12px" }}
              onClick={() => handleCheckboxChange("os", "od")}
            >
              Copy OS to OD
            </MenuItem>
          </Menu>
        </Grid>
        {dialationForm.map((element, i) => {
          return (
            <>
              <Grid
                item
                xs={12}
                md={element.mdSize}
                className={classes.textGroup}
                // style={{ border: "1px solid #DED9D9" }}
              >
                {element.label && (
                  <Typography
                    className={classes.formHeading}
                    style={{
                      fontWeight: "500",
                      color: "#4E97FD",
                      marginTop: "0px",
                    }}
                  >
                    {element.label}{" "}
                    {element.required === "true" ? (
                      <span className={classes.required}>*</span>
                    ) : null}
                    {element.copyBtn === "true" && (
                      <Button
                        style={{
                          float: "right",
                          position: "relative",
                          fontSize: "10px",
                          borderRadius: "2px",
                          // top: "20px",
                        }}
                        classes={{ root: classes.button }}
                        variant="contained"
                        color="secondary"
                        // onClick={duplicateValue}
                      >
                        {element.btnLabel}
                      </Button>
                    )}
                  </Typography>
                )}

                {element.type ? (
                  <>
                    {element.type === "select" ? (
                      <FormControl
                        fullWidth
                        className={classes.dropdownFormControl}
                      >
                        <Select
                          name={element.key}
                          className={classes.selectDropdown}
                          placeholder={element.placeHolder}
                          value={
                            dialationDetails &&
                            dialationDetails[element.key] === null
                              ? ""
                              : dialationDetails &&
                                dialationDetails[element.key]
                          }
                          onChange={async (e) => {
                            setDialationDetails({
                              ...dialationDetails,
                              [element.key]: e.target.value,
                            });
                            setDialationBtnUnable(true);
                            setShowDiscard(true);
                          }}
                        >
                          <MenuItem disabled value="">
                            Select
                          </MenuItem>
                          {(element.key === "dialation_med_od" || element.key === "dialation_med_os") ? (
        sortedDilationItems.map((item) => (
          <MenuItem key={item.attribute_id} value={item.attribute_id}>
            {item.attribute_val}
          </MenuItem>
        ))
      ) : ((element.key === "dialation_counsel_od" || element.key === "dialation_counsel_os") && examExternalCounsel) ? (
        examExternalCounsel.map((item) => (
          <MenuItem key={item.attribute_id} value={item.attribute_id}>
            {item.attribute_val}
          </MenuItem>
        ))
      ) :(element.key === "dialation_staff_od" ||
                                element.key === "dialation_staff_os") &&
                              doctorList
                            ? doctorList.map((item) => (
                                <MenuItem
                                  key={item.staff_id}
                                  value={item.staff_id}
                                >
                                  {item.first_name} {item.middle_name}{" "}
                                  {item.last_name}
                                </MenuItem>
                              ))
                            : element.options.map((item) => (
                                <MenuItem value={item}>{item}</MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    ) : element.type === "text" ? (
                      <TextField
                        required
                        name={element.key}
                        placeholder={element.placeHolder}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                          maxLength: element.maxLength,
                          onKeyPress: element.onKeyPress,
                          onPaste: element.onpaste,
                        }}
                        value={
                          dialationDetails &&
                          dialationDetails[element.key] === null
                            ? ""
                            : dialationDetails && dialationDetails[element.key]
                        }
                        onChange={async (e) => {
                          setDialationDetails({
                            ...dialationDetails,
                            [element.key]: e.target.value,
                          });
                          setDialationBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                    ) : element.type === "date" ||
                      element.type === "datetime-local" ? (
                      <TextField
                        id={element.key}
                        name={element.key}
                        placeholder={element.placeHolder}
                        type={element.type}
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          min: `${currentDateTime}`,
                          max: `${currentDate}`,
                          style: {
                            height: "12px",
                            fontSize: "12px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={
                          dialationDetails &&
                          dialationDetails[element.key] === null
                            ? ""
                            : dialationDetails && moment(dialationDetails[element.key]).utc().format("YYYY-MM-DD hh:mm") 
                        }
                        onChange={async (e) => {
                          setDialationDetails({
                            ...dialationDetails,
                            [element.key]: e.target.value,
                          });
                          setDialationBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                    ) : element.type === "radio" ? (
                      <RadioGroup row defaultValue="">
                        {element.radioValue.map((item) => (
                          <FormControlLabel
                            size="small"
                            style={{ padding: "0px 6px" }}
                            value={item}
                            control={<Radio />}
                            label={item}
                            className={classes.smallRadioButton}
                          />
                        ))}
                      </RadioGroup>
                    ) : element.type === "checkbox" ? (
                      <>
                        <Checkbox
                            name={element.key}
                            checked={
                              dialationDetails[element.key] !== "N" ? true : false
                            }
                            onChange={(e) => {
                              const value = postFlagBooleanVariable(
                                e.target.checked
                              );
                              setDialationDetails({
                                ...dialationDetails,
                                [element.key]: value,
                              });
                            }}
                          />
                      </>
                    ) : element.type === "button" ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                          // onClick={selectPrevFrame}
                        >
                          {element.btnlbl}
                        </Button>
                      </div>
                    ) : (
                      <TextField
                        size="small"
                        className={classes.txtForm}
                        fullWidth
                        placeholder="Type here..."
                        variant="outlined"
                        multiline
                        rows={3}
                        inputProps={{
                          style: {
                            fontSize: "12px",
                          },
                          maxLength: element.maxLength,
                          onKeyPress: element.onKeyPress,
                          onPaste: element.onpaste,
                        }}
                        value={
                          dialationDetails &&
                          dialationDetails[element.key] === null
                            ? ""
                            : dialationDetails && dialationDetails[element.key]
                        }
                        onChange={async (e) => {
                          setDialationDetails({
                            ...dialationDetails,
                            [element.key]: e.target.value,
                          });
                          setDialationBtnUnable(true);
                          setShowDiscard(true);
                        }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Typography
                      className={classes.formHeading}
                      style={{
                        fontWeight: "600",

                        color: "#4E97FD",
                      }}
                    >
                      {element.title}
                    </Typography>
                  </>
                )}
              </Grid>
            </>
          );
        })}
        <Grid className={classes.btnGroup} item xs={12} style={{ display: "flex", justifyContent: "end" }}>
        {showDiscard && (
            <Button
              className={classes.btnleftOutline}
              variant="outlined"
              style={{ marginRight: "10px", fontSize: "12px" }}
              size="medium"
              onClick={clearForm}
            >
              Discard
            </Button>
          )}
          <Button disabled={!dialationBtnUnable}
            style={{ display: "flex", float: "right" }}
            onClick={handleSubmit}
            className={classes.btnlRightDark}
            variant="contained"
            size="medium"
            color="secondary"
          >
            Save
          </Button>
        </Grid>
      </Grid>

      {/* </TableWidget> */}
    </>
  );
}
