export const manageOrderSchema = {

    quantity: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter quantity"
        }
    },
    item_insurance_due:{
        type: "string",
        required: true,
        properties: {
            empty: "Please enter insurance due amount"
        }
    },
    item_insurance_paid:{
        type: "string",
        required: true,
        properties: {
            empty: "Please enter insurance paid amount"
        }
    },
    item_discount:{
        type: "string",
        required: true,
        properties: {
            empty: "Please enter discount amount"
        }
    },
    shipping_address_1: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter shipping address 1",
           
        }
    },
    shipping_address_2: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter shipping address 2",
        }
    },
    state: {
        type: "string",
        required: true,
        properties: {
            empty: "Please select state",
        }
    },
    city: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter city",
        }
    },
    zip: {
        type: "string",
        required: true,
        properties: {
            empty: "Please enter zip code",
        }
    },
    
}