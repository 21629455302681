import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";

import moment from "moment";
import useStyles from "./styles";
import { formBillingElements } from "./Patient_Constant";
import { Box } from "@mui/material";

export default function AddressBilling({
  patientData,
  setPatientData,
  addressStatus,
}) {
  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");

  var classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.formBox}>
      {formBillingElements.map((element, i) => {
        return (
          <>
            {patientData ? (
              <Grid
                item
                xs={12}
                lg={2}
                sm={2}
                md={2}
                className={classes.textGroup}
              >
                <Typography className={classes.formHeading}>
                  {element.label}{" "}
                  {element.required === "true" ? (
                    <span className={classes.required}>*</span>
                  ) : null}
                </Typography>

                {element.type === "select" ? (
                  <FormControl
                    // fullWidth
                    sx={{
                      '&.MuiFormControl-fullWidth': {
                        width: 'auto',
                      }
                    }}
                    className={classes.dropdownFormControl}
                  >
                    <Select
                      name={element.key}
                      className={classes.selectDropdown}
                      placeholder={element.placeHolder}
                      value={
                        patientData && patientData[element.key] === null
                          ? ""
                          : patientData && patientData[element.key]
                      }
                      disabled
                      onChange={async (e) => {
                        setPatientData({
                          ...patientData,
                          [element.key]: e.target.value,
                        });
                      }}
                    >
                      <MenuItem value="">Select</MenuItem>
                      {(element.key == "billing_address_verified" &&
                          addressStatus) ||
                        (element.key == "shipping_address_verified" && addressStatus)
                          ? addressStatus.map((item) => (
                              <MenuItem
                                key={item.attribute_id}
                                value={item.attribute_id}
                              >
                                {item.attribute_val}
                              </MenuItem>
                            ))
                          : (element.key == "billing_state" &&
                              element.options) ||
                            (element.key == "shipping_state" &&
                              element.options)
                          ? element.options.map((item) => (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            ))
                          : null}
                    </Select>
                  </FormControl>
                ) : element.type === "text" ? (
                  <TextField
                    disabled
                    required
                    name={element.key}
                    placeholder={element.placeHolder}
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: {
                        height: "12px",
                        fontSize: "12px",
                      },
                      maxLength: element.maxLength,

                      onKeyPress: element.onKeyPress, // Add the event handler
                    }}
                    value={
                      patientData && patientData[element.key] === null
                        ? ""
                        : patientData && patientData[element.key]
                    }
                    onChange={async (e) => {
                      setPatientData({
                        ...patientData,
                        [element.key]: e.target.value,
                      });
                    }}
                  />
                ) : element.type === "date" ||
                  element.type === "datetime-local" ? (
                  <TextField
                    disabled
                    id={element.key}
                    name={element.key}
                    placeholder={element.placeHolder}
                    type={element.type}
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      min: `${currentDateTime}`,
                      max: `${currentDate}`,
                      style: {
                        height: "12px",
                        fontSize: "12px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      patientData && patientData[element.key] === null
                        ? ""
                        : moment(
                            patientData && patientData[element.key]
                          ).format("YYYY-MM-DD")
                    }
                    onChange={async (e) => {
                      setPatientData({
                        ...patientData,
                        [element.key]: e.target.value,
                      });
                    }}
                  />
                ) : (
                  <TextField
                    disabled
                    size="small"
                    className={classes.txtForm}
                    fullWidth
                    placeholder="Type here..."
                    variant="outlined"
                    multiline
                    rows={3}
                    inputProps={{
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    value={
                      patientData && patientData[element.key] === null
                        ? ""
                        : patientData && patientData[element.key]
                    }
                    onChange={async (e) => {
                      setPatientData({
                        ...patientData,
                        [element.key]: e.target.value,
                      });
                    }}
                  />
                )}
              </Grid>
            ) : null}
          </>
        );
      })}
    </Grid>
  );
}
