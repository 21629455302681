import React, { useEffect,useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,Switch
} from "@material-ui/core";
// Model
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import Popup from "../../../common/modal/Popup";
import { currentUser } from "../../../../hooks/helper";
// styles
import useStyles from "../styles";
import moment from "moment";
import { postCustomData } from "../../../../services/ServiceLayer";
import { toast } from "react-toastify";
export default function ManageCustomData(props) {

  const currentDate = moment().format("YYYY-MM-DD");
  const currentDateTime = moment().format("YYYY-MM-DDThh:mm");
  //
  const formElements = [
    // {
    //   key: "date",
    //   label: "Date & Time",
    //   placeHolder: "select",
    //   type: "date",
    //   mdSize: "4",
    // },
    {
      key: "data_name",
      label: "Field Name",
      placeHolder: "type here",
      type: "text",
      mdSize: "6",
    },
    // {
    //   key: "role",
    //   label: "Associate Role",
    //   placeHolder: "select",
    //   type: "select",
    //   options: ["Doctor","Nurse","Admin"],
    //   mdSize: "4",
    // },
    // {
    //   key: "number",
    //   label: "Number",
    //   placeHolder: "type here",
    //   type: "text",
    //   mdSize: "4",
    // },
    {
      key: "data_default_val",
      label: "Data Value",
      placeHolder: "type here",
      type: "text",
     
      mdSize: "6",
    },
    // {
    //   key: "data_type",
    //   label: "Data Type",
    //   placeHolder: "select",
    //   type: "select",
    //   options: ["Boolean","AlphaNumeric"],
    //   mdSize: "4",
    // },
    // {
    //   key: "status",
    //   label: "Status",
    //   placeHolder: "select",
    //   type: "switch",
    //   // options: ["Active","Inactive"],
    //   mdSize: "4",
    // },
    
    // {
    //   key: "mandatory",
    //   label: "Mandatory",
    //   placeHolder: "select",
    //   type: "switch",
    //   // options: ["true","false"],
    //   mdSize: "4",
    // },
    // {
    //   key: "editable",
    //   label: "Editable",
    //   placeHolder: "select",
    //   type: "switch",
    //   // options: ["true","false"],
    //   mdSize: "4",
    // },
  ];
  console.log("props-24", props);
  const { selectclient, setDataUpdated } = props;
  console.log("selectclient", selectclient);
  const [isShow, setIsShow] = useState(false);
  //
  var classes = useStyles();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isActionCalled, setIsActionCalled] = useState(false);

  const hideHandler = () => {
    console.log("hide handler called");
    props.onHide();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [customDetail, setCustomDetail] = useState({
    patient_id: 0,
    custome_data_id:0,
    data_name: "",
    associated_tbl_id: 0,
    associated_tbl_name: "",
    associated_role_id: 1,
    data_type: "",
    data_type_id: 0,
    data_default_val: "",
    data_status: "N",
    data_mandotory: "N",
    data_priority: "N",
    is_active: "N",
    updated_by: currentUser(),
    access_location: "access_location",
    opt_type: "PADDCSD",
  });
  useEffect(() => {
    console.log("selectclient",selectclient);
    if (selectclient === null) {
      setCustomDetail({
        patient_id: 0,
        custome_data_id:0,
        data_name: "",
        associated_tbl_id: 0,
        associated_tbl_name: "",
        associated_role_id: 1,
        data_type: "",
        data_type_id: 0,
        data_default_val: "",
        data_status: "N",
        data_mandotory: "N",
        data_priority: "N",
        is_active: "N",
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: "PADDCSD",
      });
    } else {
      setCustomDetail({
        patient_id: selectclient?.patient_id,
        custome_data_id:selectclient?.custome_data_id,
        data_name: selectclient?.data_name,
        associated_tbl_id: selectclient?.associated_tbl_id,
        associated_tbl_name: selectclient?.associated_tbl_name,
        associated_role_id: selectclient?.associated_role_id,
        data_type: selectclient?.data_type,
        data_type_id: selectclient?.data_type_id,
        data_default_val: selectclient?.data_default_val,
        data_status: selectclient?.data_status,
        data_mandotory: selectclient?.data_mandotory,
        data_priority: selectclient?.data_priority,
        is_active: selectclient?.is_active,
        updated_by: currentUser(),
        access_location: selectclient?.access_location,

        opt_type: "PADDCSD",
      });
    }
  }, [selectclient]);
  const submitFormData = () => {
    // checkValidator();
    setIsActionCalled(true);
  };
  useEffect(() => {
    console.log("isActionCalled", isActionCalled);
    // console.log("error", error);
    if (isActionCalled) {
      const postObject = {
        patient_id: customDetail?.patient_id,
        custome_data_id: customDetail.custome_data_id,
        data_name: customDetail.data_name,
        associated_tbl_id: customDetail.associated_tbl_id,
        associated_tbl_name: "",
        associated_role_id: customDetail.associated_role_id,
        data_type: customDetail.data_type,
        data_type_id: customDetail.data_type_id,
        data_default_val: customDetail.data_default_val,
        data_status: customDetail.data_status,
        data_mandotory: customDetail.data_mandotory,
        data_priority: customDetail.data_priority,
        is_active: customDetail.is_active,
        updated_by: currentUser(),
        access_location: "access_location",
        opt_type: "PADDCSD",
      };

      postCustomData(postObject).then((response) => {
        console.log(response);
        if (response?.status?.status) {
          toast.success("Custom data updated successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setDataUpdated(true);
          props.onHide();
        }
        setIsActionCalled(false);
        setCustomDetail({});
      });
    } else {
      setIsActionCalled(false);
      setCustomDetail({});
    }
    setIsActionCalled(false);
  }, [isActionCalled]);

  const { onHide, ...props1 } = props;
  return (
    <>
      <Popup
        {...props1}
        onHide={hideHandler}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        footer={
          <>
            <Button
              className={classes.outlineBtn}
              variant="outlined"
              size="medium"
              onClick={hideHandler}
            >
              Cancel
            </Button>

            <Button
              className={classes.fillBtn}
              variant="contained"
              size="medium"
              color="secondary"
              onClick={submitFormData}
            >
              Update
            </Button>
          </>
        }
      >
        <DialogContent>
          <DialogContentText className={classes.formAddBox}>
            <Grid
              container
              spacing={2}
              className={classes.formBox}
              style={{ marginBottom: "4px" }}
            >
              {formElements.map((element, i) => {
                return (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={element.mdSize}
                      className={classes.textGroup}
                    >
                      <Typography className={classes.formHeading}>
                        {element.label}{" "}
                        {element.required === "true" ? (
                          <span className={classes.required}>*</span>
                        ) : null}
                      </Typography>

                      {element.type === "select" ? (
                        <FormControl
                          fullWidth
                          className={classes.dropdownFormControl}
                        >
                          <Select
                            name={element.key}
                            style={{ fontSize: '12px' }}
                            className={classes.selectDropdown}
                            placeholder={element.placeHolder}
                            value={
                              customDetail && customDetail[element.key]
                                ? customDetail && customDetail[element.key]
                                : ""
                            }
                            onChange={(e) => {
                              console.log("selected");
                              setCustomDetail({
                                ...customDetail,
                                [element.key]: e.target.value,
                              });
                            }}
                          >
                            <MenuItem value="">Select</MenuItem>
                            {element.options.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : element.type === "text" ? (
                        <TextField
                          required
                          name={element.key}
                          placeholder={element.placeHolder}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                          }}
                          value={
                            customDetail && customDetail[element.key]
                              ? customDetail && customDetail[element.key]
                              : ""
                          }
                          onChange={(e) => {
                            console.log("selected");
                            setCustomDetail({
                              ...customDetail,
                              [element.key]: e.target.value,
                            });
                          }}
                          disabled={element.key==="data_name"}
                        />
                      ) : element.type === "date" ||
                        element.type === "datetime-local" ? (
                        <TextField
                          id={element.key}
                          name={element.key}
                          placeholder={element.placeHolder}
                          type={element.type}
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          variant="outlined"
                          inputProps={{
                            min: `${currentDateTime}`,
                            max: `${currentDate}`,
                            style: {
                              height: "12px",
                              fontSize: "12px",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onKeyDown={(e) => {
                            e.preventDefault(); // Prevent manual typing
                          }}
                        />
                      ) : element.type === "textarea" ? (
                        <TextField
                          size="small"
                          className={classes.txtForm}
                          fullWidth
                          placeholder="Type here..."
                          variant="outlined"
                          multiline
                          rows={3}
                          inputProps={{
                            style: {
                              fontSize: "12px",
                            },
                          }}
                        />
                      ) : element.type === "button" ? (
                        <Button
                          classes={{ root: classes.button }}
                          variant="contained"
                          color="secondary"
                          style={{
                            width: "100%",
                            fontSize: "10px",
                            marginTop: "26px",
                          }}
                        >
                          {element.btnName}
                        </Button>
                      ) : (
                        <Switch  />
                      )}
                    </Grid>
                  </>
                );
              })}
             
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Popup>
    </>
  );
}
